// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ICamMessage } from '../../../../../../../../commonout/interfaces/charts.model';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { BulbicamChartComponent } from '../haploChart.component';

@Component({
    selector: 'recording-tool',
    template: require('./recording-tool.component.html'),
    styles: [require('./recording-tool.component.scss')],
})
export class RecordingToolComponent extends BulbicamChartComponent implements OnInit, OnDestroy {
    private data: Subject<ICamMessage[]> = new Subject();
    private videoSrc: string;
    private subscriptions: Array<Subscription> = [];
    constructor(private bulbicamService: BulbicamService, private configService: ConfigService, private authService: AuthenticationService) {
        super();
    }
    ngOnInit() {
        this.subscriptions.push(
            this.data.subscribe(async (d) => {
                if (d && d.length > 0) {
                    const filename = d.find((d) => d.target).target || false;
                    if (filename) this.videoSrc = `${this.configService.backendUrl}/haplo/video/${d.find((d) => d.target).target}?token=${this.authService.currentUser.token}`;
                }
            })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public setEdits() {}
    public addData(data: ICamMessage[]) {
        this.data.next(data);
    }
    public clearData() {
        this.videoSrc = null;
        this.data.next(null);
    }
}
