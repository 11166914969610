import * as jsPDF from 'jspdf';
import { ITonometryNidekTonorefIIIMeasuredData } from '../../../../../../../common/interfaces/measuredData/NIDEK/TonorefIII/tonometry-nidek-tonorefIII.measuredData.interface';
import { TonometryTonorefIIIMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/TonorefIII/tonometryTonorefIIIMeasuredData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { TonometryTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/tonometryTonorefIIITestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class TonometryTonorefIIIMeasuredDataFrontend extends TonometryTonorefIIIMeasuredData implements MeasuredDataFrontend {
    public remarks: TonometryTonorefIIITestRemarksFrontend;
    public OD: {
        measurement: RegisterEditFieldFrontend<string>;
        mmHg: RegisterEditFieldFrontend<string>;
        kPa: RegisterEditFieldFrontend<string>;
    }[];
    public OS: {
        measurement: RegisterEditFieldFrontend<string>;
        mmHg: RegisterEditFieldFrontend<string>;
        kPa: RegisterEditFieldFrontend<string>;
    }[];
    constructor() {
        super();
        this.OD = [];
        this.OS = [];
    }
    public setModel(model: ITonometryNidekTonorefIIIMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        model.OD.forEach(od => {
            this.OD.push({
                measurement: new RegisterEditFieldFrontend<string>().setModel(od.measurement),
                kPa: new RegisterEditFieldFrontend<string>().setModel(od.kPa),
                mmHg: new RegisterEditFieldFrontend<string>().setModel(od.mmHg),
            });
        });
        model.OS.forEach(os => {
            this.OS.push({
                measurement: new RegisterEditFieldFrontend<string>().setModel(os.measurement),
                kPa: new RegisterEditFieldFrontend<string>().setModel(os.kPa),
                mmHg: new RegisterEditFieldFrontend<string>().setModel(os.mmHg),
            });
        });
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table: any = [];
        if (this.OD.length > 0) {
            table.push({
                column1: { colSpan: 3, content: 'OD', styles: { halign: 'center' } },
                column2: '',
                column3: '',
            });
            table.push({
                column1: '',
                column2: 'kPa',
                column3: 'mmHg',
            });
        }
        this.OD.forEach(od => {
            table.push({
                column1: od.measurement.value,
                column2: od.kPa.value,
                column3: od.mmHg.value,
            });
        });
        const startYposition: number = pager.line,
            pageNumber: number = document.internal.getCurrentPageInfo().pageNumber;
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: document.internal.pageSize.getWidth() / 2, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        const endYposition: number = (document as any).lastAutoTable.finalY;
        document.setPage(pageNumber);
        table = [];
        if (this.OS.length > 0) {
            table.push({
                column1: { colSpan: 3, content: 'OS', styles: { halign: 'center' } },
                column2: '',
                column3: '',
            });
            table.push({
                column1: '',
                column2: 'kPa',
                column3: 'mmHg',
            });
        }
        this.OS.forEach(os => {
            table.push({
                column1: os.measurement.value,
                column2: os.kPa.value,
                column3: os.mmHg.value,
            });
        });
        (document as any).autoTable({
            body: table,
            startY: startYposition,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: document.internal.pageSize.getWidth() / 2 },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY > endYposition ? (document as any).lastAutoTable.finalY : endYposition;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        this.OD.forEach(od => {
            formattedTest.OD.values += od.measurement.value + ' - ' + ((od.mmHg.value as string) || 'x') + '/' + (od.kPa.value || 'x') + ';\n';
        });
        this.OS.forEach(os => {
            formattedTest.OS.values += os.measurement.value + ' - ' + ((os.mmHg.value as string) || 'x') + '/' + (os.kPa.value || 'x') + ';\n';
        });
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
