import { AntSegmentForus3NethraClassicTest } from '../../../../../../../../commonout/classes/tests/FORUS/3NethraClassic/antSegmentForus3NethraClassicTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { AntSegmentForusTestRemarksComponent } from '../../../../_components';
import { AntSegmentForus3NethraClassicTestRemarksFrontend } from '../../../test-remarks/FORUS/3NethraClassic/antSegmentForus3NethraClassicTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class AntSegmentForus3NethraClassicTestFrontend extends AntSegmentForus3NethraClassicTest {
    remarks: AntSegmentForus3NethraClassicTestRemarksFrontend;
    public readonly remarksComponent: typeof AntSegmentForusTestRemarksComponent = AntSegmentForusTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new AntSegmentForus3NethraClassicTestRemarksFrontend();
    }
}

export interface AntSegmentForus3NethraClassicTestFrontend extends TestFrontend {}
applyMixins(AntSegmentForus3NethraClassicTestFrontend, [TestFrontend]);
