import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DEVICE } from '../../../../../../../commonout/enum/device';
import { TestService } from '../../../_services/examination/test.service';
import { SocketService } from '../../../_services/general/socket.service';

@Component({
    selector: 'upload',
    template: require('./upload.component.html'),
    styles: [require('./upload.component.scss')],
})
export class UploadComponent implements OnInit, OnDestroy {
    @Input() public examinationID: string;
    private uploads: Map<DEVICE, string[]> = new Map<DEVICE, string[]>();
    private subscriptions: Array<Subscription> = [];
    private form: FormGroup;
    constructor(private formBuilder: FormBuilder, private socketService: SocketService, private testService: TestService) {}
    ngOnInit() {
        this.form = this.formBuilder.group({});
        this.socketService.socket.on('DeviceResultsList', (device: DEVICE, timeMarks: string[]) => {
            this.form.addControl(device, new FormControl(''));
            this.form.reset();
            this.uploads.set(device, timeMarks);
        });
    }
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    public async uploadData(device: DEVICE, timeMark: string): Promise<void> {
        this.testService.upload(this.examinationID, device, timeMark).subscribe();
    }
    public hasSomethingToUpload(): boolean {
        let result: boolean = false;
        this.uploads.forEach((v, k) => {
            if (v.length > 0) result = true;
        });
        return result;
    }
}
