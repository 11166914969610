import { MeasuredData } from '../../measuredData.model';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IAcaMeasuredData } from '../../../../interfaces/measuredData/NIDEK/NT530P/aca-nidek-nt530p.measuredData.interface';
import { TestRemarks } from '../../../testRemark.class';
import { SmartImage } from '../../../smartImage.class';

export abstract class AcaMeasuredData implements MeasuredData, IAcaMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: SmartImage;
    public OS: SmartImage;
    public remarks: TestRemarks;
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_NT530P;
        this.testType = TEST_TYPE.ANTERIOR_CHAMBER_ANGLE;
    }
    public setModel(model: IAcaMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.OD.model = model.OD;
        this.OS.model = model.OS;
    }
    public getModel(): IAcaMeasuredData {
        const model: IAcaMeasuredData = {
            sourceDevice: this.sourceDevice,
            sourceFileName: this.sourceFileName,
            testType: this.testType,
            OD: this.OD.model,
            OS: this.OS.model,
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.isEmpty() && this.OS.isEmpty();
    }
}
