import { SaccadeMergedBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/saccadeMergedBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { MemorySaccadeTestChartComponent, SaccadeMergedTestControlsComponent, SaccadeMergedTestRemarksComponent } from '../../../_components';
import { SaccadeMergedBulbicamTestChartComponent } from '../../../_components/tests/charts/SaccadeMerged/SaccadeMergedWrapper/saccade-merged-test-chart.component';
import { SaccadeMergedBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/saccadeMergedBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class SaccadeMergedBulbicamTestFrontend extends SaccadeMergedBulbicamTest {
    remarks: SaccadeMergedBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof SaccadeMergedTestRemarksComponent = SaccadeMergedTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof SaccadeMergedBulbicamTestChartComponent = SaccadeMergedBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof SaccadeMergedTestControlsComponent = SaccadeMergedTestControlsComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new SaccadeMergedBulbicamTestRemarksFrontend();
    }
}

export interface SaccadeMergedBulbicamTestFrontend extends TestFrontend {}
applyMixins(SaccadeMergedBulbicamTestFrontend, [TestFrontend]);
