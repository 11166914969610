import { PachymetryTonorefIIITest } from '../../../../../../../../commonout/classes/tests/NIDEK/TonorefIII/pachymetryTonorefIITest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PachymetryTonorefIIITestRemarksComponent } from '../../../../_components';
import { PachymetryTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/pachymetryTonorefIIITestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PachymetryTonorefIIITestFrontend extends PachymetryTonorefIIITest {
    remarks: PachymetryTonorefIIITestRemarksFrontend;
    public readonly remarksComponent: typeof PachymetryTonorefIIITestRemarksComponent = PachymetryTonorefIIITestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PachymetryTonorefIIITestRemarksFrontend();
    }
}

export interface PachymetryTonorefIIITestFrontend extends TestFrontend {}
applyMixins(PachymetryTonorefIIITestFrontend, [TestFrontend]);
