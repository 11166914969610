import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROLE } from '../../../../../../commonout/enum/role';
import { IUser } from '../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../commonout/interfaces/userFrontend.interface';
import { UserFrontend } from '../../_models/userFrontend.class';
import { ConfigService } from './config.service';
import { SocketService } from './socket.service';

@Injectable()
export class AuthenticationService {
    private readonly chars: string;
    private readonly lookup: Uint8Array;
    public currentUser: UserFrontend<any>;

    constructor(private configService: ConfigService, private httpClient: HttpClient, private socketService: SocketService, private inj: Injector) {
        this.chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
        this.lookup = new Uint8Array(256);
        for (let i = 0; i < this.chars.length; i++) {
            this.lookup[this.chars.charCodeAt(i)] = i;
        }
        this.currentUser = new UserFrontend();
        let model: IUser<any> & IUserFrontend = JSON.parse(localStorage.getItem('currentUser'));
        if (model && model.token) {
            this.currentUser.setModel(model);
        }
    }
    public refreshToken(): Observable<void> {
        const url = `${this.configService.backendUrl}/refresh-token/${this.currentUser.refreshToken}`;
        return this.httpClient.get(url).pipe(
            map((payload: { newToken: string }) => {
                if (payload) this.currentUser.token = payload.newToken;
                else throw 'session timeout';
            })
        );
    }
    public loginWithPassword(credentials: { username: string; password: string }): Observable<void> {
        return this.httpClient.post<{ token: string; refreshToken: string }>(this.configService.backendUrl + '/auth/login', credentials).pipe(
            map((response: { token: string; refreshToken: string }) => {
                this.currentUser.tokens = response;
                localStorage.setItem('currentUser', JSON.stringify(this.currentUser.getModel()));
                this.socketService.connect(this.currentUser.token);
            })
        );
    }
    public logout(): void {
        this.socketService.disconnect();
        this.httpClient.post(this.configService.backendUrl + '/logout', null).subscribe();
        localStorage.removeItem('currentUser');
        this.currentUser = new UserFrontend();
        this.inj.get(Router).navigate(['/auth']);
    }
    public inviteUser(invitationCredentials: { email: string; role: ROLE }): Observable<void> {
        return this.httpClient.post<void>(this.configService.backendUrl + '/users/invite', invitationCredentials);
    }
    public findInvitationCredentialsByToken(token: string): Observable<{ email: string; role: ROLE }> {
        return this.httpClient.get<{ email: string; role: ROLE }>(`${this.configService.backendUrl}/auth/get-credentials/${token}`);
    }
    public restorePassword(username: string): Observable<{ message: string }> {
        return this.httpClient.get<{ message: string }>(this.configService.backendUrl + '/forgot-password/' + username);
    }
    public restorePasswordTokenIsValid(token: string): Observable<{ message: string }> {
        return this.httpClient.get<{ message: string }>(this.configService.backendUrl + '/check-restore-token/' + token);
    }
    public loginWithU2F(credentials: { username: string; password: string }): Observable<PublicKeyCredentialRequestOptions> {
        return this.httpClient.post<PublicKeyCredentialRequestOptions>(this.configService.backendUrl + '/webAuth/login', credentials);
    }
    public sendWebAuthResponse(encodedpublicKeyCredential: any): Observable<{ token: string; refreshToken: string }> {
        return this.httpClient.post<{ token: string; refreshToken: string }>(this.configService.backendUrl + '/webAuth/responce', encodedpublicKeyCredential);
    }
    public publicKeyCredentialToJSON(pubKeyCred: Credential): any {
        if (pubKeyCred instanceof Array) {
            let arr = [];
            for (let i of pubKeyCred) arr.push(this.publicKeyCredentialToJSON(i));
            return arr;
        }
        if (pubKeyCred instanceof ArrayBuffer) {
            return this.encode(pubKeyCred as any);
        }
        if (pubKeyCred instanceof Object) {
            let obj = {};
            for (let key in pubKeyCred) {
                obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key]);
            }
            return obj;
        }
        return pubKeyCred;
    }
    public encode(arraybuffer: ArrayBuffer): string {
        let bytes: Uint8Array = new Uint8Array(arraybuffer),
            i: number,
            len: number = bytes.length,
            base64url: string = '';

        for (i = 0; i < len; i += 3) {
            base64url += this.chars[bytes[i] >> 2];
            base64url += this.chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
            base64url += this.chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
            base64url += this.chars[bytes[i + 2] & 63];
        }

        if (len % 3 === 2) {
            base64url = base64url.substring(0, base64url.length - 1);
        } else if (len % 3 === 1) {
            base64url = base64url.substring(0, base64url.length - 2);
        }

        return base64url;
    }
    public decode(base64string: string): ArrayBuffer {
        let bufferLength: number = base64string.length * 0.75,
            len: number = base64string.length,
            i: number,
            p: number = 0,
            encoded1,
            encoded2,
            encoded3,
            encoded4;

        let bytes = new Uint8Array(bufferLength);

        for (i = 0; i < len; i += 4) {
            encoded1 = this.lookup[base64string.charCodeAt(i)];
            encoded2 = this.lookup[base64string.charCodeAt(i + 1)];
            encoded3 = this.lookup[base64string.charCodeAt(i + 2)];
            encoded4 = this.lookup[base64string.charCodeAt(i + 3)];

            bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
            bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
            bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
        }

        return bytes.buffer;
    }
}
