import { Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SmartImageFrontend } from '../../../../_models/smartImageFrontend.class';
import { FileService } from '../../../../_services/general/file.service';

@Component({
    selector: 'long-image-carousel',
    template: require('./long-image-carousel.component.html'),
    styles: [require('./long-image-carousel.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LongImageCarouselComponent),
            multi: true,
        },
    ],
})
export class LongImageCarouselComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() private readonly isDeletable: boolean = false;
    @Input() private readonly isRotateble: boolean = false;
    @Input() private readonly formControlName: string;
    @Input() private readonly supportZoom: boolean = false;
    @Output() delete: EventEmitter<string> = new EventEmitter<string>();
    @Output() isBusyEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    private isBusy: boolean = false;
    private value: SmartImageFrontend[] = [];
    private activeImage: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private fullScreen: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private onChange = (_: any) => {};
    private onTouched = () => {};
    private disabled: boolean = false;
    private subscriptions: Subscription[] = [];

    //zoom staff
    private zoomModeEnabled: boolean = false;
    private readonly ZOOM_WINDOW_WIDTH: number = 800;
    private readonly SLIDE_WIDTH_HEIGHT: number = 400;
    private readonly TARGET_WIDTH_HEIGHT: number = 100;
    private selectedSlide: HTMLDivElement;
    private selectedSlideDomRect: DOMRect;
    private unlisten: Function[] = [];
    @ViewChild('zoomWindow') private zoomWindow: ElementRef<HTMLDivElement>;
    @ViewChild('zoomedImage') private zoomedImage: ElementRef<HTMLDivElement>;
    @ViewChild('target') private target: ElementRef<HTMLDivElement>;
    @ViewChild('sliderBlock') private sliderBlock: ElementRef<HTMLDivElement>;
    @ViewChildren('slide') private slides: QueryList<ElementRef<HTMLDivElement>>;

    constructor(private fileService: FileService, private renderer: Renderer2, private ngZone: NgZone) {
        this.isBusyEvent.subscribe((v: boolean) => (this.isBusy = v));
    }
    ngOnInit(): void {
        this.subscriptions.push(this.delete.subscribe(() => this.activeImage.next(null)));
        this.ngZone.runOutsideAngular(() => {
            this.unlisten.push(this.renderer.listen('document', 'mousemove', (e) => this.zoomMoveHandler(e)));
        });
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.unlisten.forEach((f) => f());
    }
    async writeValue(data: SmartImageFrontend | SmartImageFrontend[]): Promise<void> {
        this.isBusyEvent.emit(true);
        if (!data) return;
        if (!Array.isArray(data)) data = [data];
        const prevImagesCount: number = this.value.length || this.value.length;
        await Promise.all(data.map((i) => i.download(this.fileService)));
        this.value = data;
        this.isBusyEvent.emit(false);
        if (this.value.length === 0) {
            this.activeImage.next(null);
            return;
        }
        if (this.activeImage.value === null) {
            this.activeImage.next(0);
            return;
        }
        if (this.activeImage.value > this.value.length - 1) {
            this.activeImage.next(null);
            return;
        }
        if (prevImagesCount && prevImagesCount === this.value.length) return;
        this.activeImage.next(0);
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    private async rotate(smartImage: SmartImageFrontend): Promise<void> {
        this.isBusyEvent.emit(true);
        await smartImage.rotate90deg();
        await smartImage.update(this.fileService);
        this.onChange(this.value);
    }
    onWheel(event: WheelEvent): void {
        this.zoomModeEnabled = false;
        (<Element>event.currentTarget).parentElement.scrollLeft += event.deltaY > 0 ? 400 : -400;
        event.preventDefault();
    }
    isInView() {
        const slideWrapperBoundingClientRect: DOMRect = this.slides.toArray()[this.activeImage.value].nativeElement.parentElement.getBoundingClientRect(),
            sliderBlockBoundingClientRect: DOMRect = this.sliderBlock.nativeElement.getBoundingClientRect();
        if (slideWrapperBoundingClientRect.left < sliderBlockBoundingClientRect.left || slideWrapperBoundingClientRect.right > sliderBlockBoundingClientRect.right) {
            this.slides.toArray()[this.activeImage.value].nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }
    checkPosition(i: number): boolean {
        if (i === null || this.slides.length === 0) return;
        this.selectedSlide = this.slides.toArray()[this.activeImage.value].nativeElement;
        this.selectedSlideDomRect = this.selectedSlide.getBoundingClientRect();
        const toLeft: number = this.selectedSlideDomRect.left,
            toRight: number = document.body.clientWidth - toLeft - this.SLIDE_WIDTH_HEIGHT,
            margin: number = 20,
            leftShift: number = toRight > toLeft ? toLeft + this.SLIDE_WIDTH_HEIGHT + margin : toLeft - this.ZOOM_WINDOW_WIDTH - margin;
        this.renderer.setStyle(this.zoomWindow.nativeElement, 'left', leftShift + 'px');
        return true;
    }
    private zoomMoveHandler(e: any): void {
        if (!this.supportZoom || !this.zoomModeEnabled) return;
        let layerX: number, layerY: number;
        if (e.x < this.selectedSlideDomRect.left || e.x > this.selectedSlideDomRect.right || e.y < this.selectedSlideDomRect.top || e.y > this.selectedSlideDomRect.bottom) {
            this.ngZone.run(() => {
                this.zoomModeEnabled = false;
            });
            return;
        } else if (e.srcElement.className.includes('target')) {
            layerX = e.clientX - this.selectedSlideDomRect.left;
            layerY = e.clientY - this.selectedSlideDomRect.top;
        } else if (e.srcElement.className.includes('slide')) {
            layerX = e.layerX;
            layerY = e.layerY;
        } else {
            return;
        }
        let rawTargetLeft: number = this.selectedSlideDomRect.left + layerX - this.TARGET_WIDTH_HEIGHT / 2,
            rawTargetTop: number = this.selectedSlideDomRect.top + layerY - this.TARGET_WIDTH_HEIGHT / 2,
            targetLeft: number,
            targetTop: number;
        if (rawTargetLeft < this.selectedSlideDomRect.left) {
            targetLeft = this.selectedSlideDomRect.left;
        } else if (rawTargetLeft > this.selectedSlideDomRect.left + this.SLIDE_WIDTH_HEIGHT - this.TARGET_WIDTH_HEIGHT) {
            targetLeft = this.selectedSlideDomRect.left + this.SLIDE_WIDTH_HEIGHT - this.TARGET_WIDTH_HEIGHT;
        } else {
            targetLeft = rawTargetLeft;
        }
        if (rawTargetTop < this.selectedSlideDomRect.top) {
            targetTop = this.selectedSlideDomRect.top;
        } else if (rawTargetTop > this.selectedSlideDomRect.top + this.SLIDE_WIDTH_HEIGHT - this.TARGET_WIDTH_HEIGHT) {
            targetTop = this.selectedSlideDomRect.top + this.SLIDE_WIDTH_HEIGHT - this.TARGET_WIDTH_HEIGHT;
        } else {
            targetTop = rawTargetTop;
        }
        this.renderer.setStyle(this.target.nativeElement, 'left', targetLeft + 'px');
        this.renderer.setStyle(this.target.nativeElement, 'top', targetTop + 'px');

        const leftPers: number = targetLeft - this.selectedSlideDomRect.left,
            topPers: number = targetTop - this.selectedSlideDomRect.top;

        this.renderer.setStyle(this.zoomedImage.nativeElement, 'left', '-' + (this.zoomWindow.nativeElement.clientWidth * leftPers) / 100 + 'px');
        this.renderer.setStyle(this.zoomedImage.nativeElement, 'top', '-' + (this.zoomWindow.nativeElement.clientHeight * topPers) / 100 + 'px');
    }
}
