// @ts-nocheck
import * as _ from 'lodash'
// import * as d3 from 'd3'
import { RunStart, DataPoint, TestStart } from './Protocol'
import { STIMULI_IMAGES, USE_FAKE_DATA, PRECISION_BR } from '../constants'
import { UiState } from './UiState'


export class State {
  nextId = 0
  dataSets: DataSet[] = []
  minz = 0

  ui = new UiState()

  fill(newDataSets: DataSet[]) {
    this.dataSets = newDataSets
  }

  onTestStart(x: TestStart) {
    this.minz = x.minz || 60
  }

  onRunStart(given: RunStart) {
    this.dataSet?.finalize()

    let one = new DataSet(this.nextId, this) // nesting...
    one.stimuli = given.stimuli
    this.nextId += 1
    this.dataSets.push(one)
  }

  onDataPoint(given: DataPoint) {
    this.dataSet?.addPoint(given)
  }

  onTestStop() {
    this.dataSet?.finalize()
  }

  onGoBack() {
    this.dataSet?.goBack()
  }

  get dataSet() {
    return _.last(this.dataSets)
  }

  get viewDataSets() {
    this.dataSets.forEach(x => {
      let show = true
      if (this.ui.focusRun != null) {
        show = this.ui.focusRun === x.id
      }
      x.show = show
    })

    return this.dataSets
  }
}

interface ParentContext {
  minz: number
}

abstract class BaseDataSet<D> {
  constructor(public id: number, public parent: ParentContext) {}

  dataPoints: D[] = []

  abstract preprocessPoint(d: D): void

  addPoint(x: D) {
    x = { ...x }
    this.preprocessPoint(x)
    this.dataPoints.push(x)
  }
}

export class DataSet extends BaseDataSet<DataPoint> {
  startTime?: number
  stimuli?: number
  show = true

  prevStimDistance?: number
  // startedWayBackStimDistance?: number
  isWayBack = false

  approximation: DataPoint[] = []
  distancing: DataPoint[] = []

  break?: DataPoint
  recovery?: DataPoint

  maxPd?: number

  get number() {
    return this.id + 1
  }

  get minz() {
    return this.parent.minz
  }

  viewKeyLines() {
    let result: { y: number, text: string, isBreak?: boolean }[] = []
    let br = this.break
    let { recovery } = this
    if (br) {
      result.push({
        // y: -this.break.stimuli_dist + this.minz,
        y: br.time,
        text: br.stimuli_dist.toFixed(PRECISION_BR),
        isBreak: true,
      })
    }
    if (recovery) {
      result.push({
        // y: this.recovery.stimuli_dist - this.minz,
        y: recovery.time,
        text: recovery.stimuli_dist.toFixed(PRECISION_BR),
      })
    }
    return result
  }

  viewPolygons() {
    let result: Poly[] = []

    if (this.iOsAreaPoints.length) {
      let points = this.iOsAreaPoints
      let minX = _.min(points.map(x => x.x)) || 0
      let minY = _.min(points.map(x => x.y)) || 0
      let maxY = _.max(points.map(x => x.y)) || 0
      points.push({
        x: minX,
        y: maxY,
      })
      points.push({
        x: minX,
        y: minY,
      })
      result.push({ points })
    }

    if (this.iOdAreaPoints.length) {
      let points = this.iOdAreaPoints.map(p => ({ ...p, x: -p.x }))
      let maxX = _.max(points.map(x => x.x)) || 0
      let minY = _.min(points.map(x => x.y)) || 0
      let maxY = _.max(points.map(x => x.y)) || 0
      points.push({
        x: maxX,
        y: maxY,
      })
      points.push({
        x: maxX,
        y: minY,
      })
      result.push({ points })
    }

    return result
  }

  preprocessPoint(x: DataPoint) {
    this.applyStartTime(x)
    this.applyToSeconds(x)

    this.addDerivedStuff(x)
    // this.addRelatedStuff()

    // if (this.prevStimDistance == null) this.prevStimDistance = x.stimuli_dist
    // if (this.prevStimDistance < x.stimuli_dist && !this.isWayBack) {
    //   this.isWayBack = true
    //   // this.startedWayBackStimDistance = this.prevStimDistance
    // }
    // if (!this.isWayBack && x.stimuli_dist <= this.minz) {
    //   this.isWayBack = true
    // }
    if (this.isWayBack) {
      this.distancing.push(x)
    } else {
      this.approximation.push(x)
    }

    if (this.isWayBack) {
      let delta = x.stimuli_dist - this.minz
      x.fake_dist = delta
    } else {
      x.fake_dist = -(x.stimuli_dist - this.minz)
    }
    // this.prevStimDistance = x.stimuli_dist
  }

  addDerivedStuff(x: DataPoint) {
    if (USE_FAKE_DATA) return
    x.eyes_dist = x.ppdistance || 0
    x.stimuli_dist = x.z || 0

    x.od_pd = x.ODdiameter
    x.os_pd = x.OSdiameter
    x.time = x.timestamp
    // x.defined = !!(x.ppdistance && x.z)
  }

  applyStartTime(x: DataPoint) {
    if (this.startTime == null) {
      this.startTime = x.timestamp
    }
    x.timestamp -= this.startTime || 0
  }

  applyToSeconds(x: DataPoint) {
    x.timestamp /= 10_000_000
  }

  finalize(): void {
    // const tOS = 'OS'
    // const tOD = 'OD'

    // // join os into previous od omg
    // let prev: DataPoint|undefined
    // this.dataPoints.forEach(x => {
    //   if (prev && x.target === tOD && prev.target === tOS) {
    //     x.osPoint = prev

    //     x.od_pd = x.diameter
    //     x.os_pd = x.osPoint.diameter || 0
    //   }
    //   prev = x
    // })
    // this.dataPoints = this.dataPoints.filter(x => x.target === tOD)

    let there = (x: DataPoint) => x.eyes_dist && x.eyes_dist > 0
    let breakPoint = _.minBy(this.approximation.filter(there), x => x.eyes_dist)
    let recoveryPoint = _.minBy(this.distancing.filter(there), x => x.eyes_dist)

    if (breakPoint) this.break = breakPoint
    if (recoveryPoint) this.recovery = recoveryPoint

    this.maxPd = _.max(
      this.dataPoints.map(x =>
        Math.max(x.od_pd, x.os_pd)
      )
    )

    // console.log(this.approximation, this.distancing)

    let br = this.break as DataPoint
    let recovery = this.recovery as DataPoint
    if (!br || !recovery) return

    let relevantPoints = this.dataPoints.filter(x => {
      return x.timestamp <= recovery.timestamp && x.timestamp >= br.timestamp
    })

    let iOsAreaPoints = relevantPoints.map(p => {
      let x = p.os_pd
      let y = p.time
      return { x, y }
    }).filter(x => x.x)

    let iOdAreaPoints = relevantPoints.map(p => {
      let x = p.od_pd
      let y = p.time
      return { x, y }
    }).filter(x => x.x)

    this.iOsAreaPoints = iOsAreaPoints
    this.iOdAreaPoints = iOdAreaPoints

    if (false) { // not used now
      this.iOsArea = calcArea(iOsAreaPoints)
      this.iOdArea = calcArea(iOdAreaPoints)
    }

    this.fillDistanceLines()
    this.calculate()
  }
  iOsAreaPoints: XY[] = []
  iOdAreaPoints: XY[] = []
  iOsArea = 0
  iOdArea = 0

  pupilZOD?: number
  pupilZOS?: number
  calculate() {
    let getStuff = (eye: string) => {
      let pd = (x: DataPoint) => {
        let value = x[`${eye}_pd`]
        return value
      }
      let pdOrClosest = (x: DataPoint) => {
        let value = x[`${eye}_pd`]
        if (value) return value

        let relevantPoints = this.dataPoints.filter(pd)
        let pointsAround = _.sortBy(relevantPoints, p => Math.abs(p.time - x.time))
        let closest = pointsAround[0]
        if (closest) {
          return pd(closest)
        } else {
          return null // ...
        }
      }
      let startPoint = this.dataPoints.find(x => pd(x))
      let start = startPoint ? pd(startPoint) : 0
      let breakk = this.break ? pdOrClosest(this.break) : 0
      let recovery = this.recovery ? pdOrClosest(this.recovery) : 0

      let atBreakStimDist = this.break ? this.break.stimuli_dist : 0
      let atRecStimDist = this.recovery ? this.recovery.stimuli_dist : 0

      let period = [] as DataPoint[]
      if (true) {
        let { brPoint, recPoint } = this as any
        if (brPoint && recPoint) {
          period = this.dataPoints.filter(x =>
            x.time >= brPoint.time && x.time <= recPoint.time &&
            pd(x)
          )
        }
      }
      let maxPoint = _.maxBy(period, (x:any) => pd(x))
      let max = maxPoint ? pd(maxPoint) : 0
      let atMaxStimDist = maxPoint ? maxPoint.stimuli_dist : 0

      return {
        start,
        breakk,
        recovery,
        max,
        atMaxStimDist,
        atBreakStimDist,
        atRecStimDist,
      }
    }
    if (true) {
      let { start, breakk, recovery, max,
        atMaxStimDist,
        atRecStimDist,
        atBreakStimDist,
      } = getStuff('os')
      this.odExportData = {
        start,
        breakk,
        recovery,
        max,
        atMaxStimDist,
        atRecStimDist,
        atBreakStimDist,
      }
      this.pupilZOS = start - breakk +
        max - breakk +
        max - recovery
    }
    if (true) {
      let { start, breakk, recovery, max,
        atMaxStimDist,
        atRecStimDist,
        atBreakStimDist,
      } = getStuff('od')
      this.osExportData = {
        start,
        breakk,
        recovery,
        max,
        atMaxStimDist,
        atRecStimDist,
        atBreakStimDist,
      }
      this.pupilZOD = start - breakk +
        max - breakk +
        max - recovery
    }
  }
  odExportData?: ExportDataEye
  osExportData?: ExportDataEye

  distanceLines: AxisLine[] = []

  get brPoint() {
    return this.break
  }
  get recPoint() {
    return this.recovery
  }

  fillDistanceLines() {
    this.distanceLines = []

    let addLine = (point: DataPoint|undefined) => {
      if (!point) return
      let value = point.time
      // let text = Math.floor(point.eyes_dist) + ''
      let text = point.stimuli_dist.toFixed(PRECISION_BR)
      this.distanceLines.push({
        value,
        text
      })
    }

    let SEEK = [
      250,
      150,
      100,
      75,
      75,
      100,
      150,
      250,
    ]
    let wayBackIndex = 4 // from it direction has to be different
    let searches: Search[] = SEEK.map((x, i) => {
      let isWayBack = i >= wayBackIndex
      return {
        seek: x,
        delta: undefined,// as number|undefined,
        point: undefined,// as DataPoint|undefined,
        done: false,
        isWayBack,
      }
    })
    let search: Search|undefined = searches[0]
    interface Search {
      seek: number
      delta?: number
      point?: DataPoint
      done: boolean
      isWayBack: boolean
    }

    let closestPoint = _.minBy(this.dataPoints, x => x.stimuli_dist)
    if (!closestPoint) return

    for (let point of this.dataPoints) {
      if (!search) break

      let isDecreasing = point.time <= closestPoint.time

      if (search.isWayBack && isDecreasing) continue

      let delta = Math.abs(point.stimuli_dist - search.seek)
      if (search.delta == null || delta < search.delta) {
        search.point = point
        search.delta = delta
      }
      if (search.delta != null && search.delta < delta) { // going away
        search.done = true
        search = searches.find(x => !x.done)
      }
    }

    searches.forEach(x => {
      if (x.done) {
        addLine(x.point)
      }
    })
  }

  fillDistanceLinesOld() {
    this.distanceLines = []

    let maxDistPoint = _.maxBy(this.dataPoints, x => x.eyes_dist)
    if (!maxDistPoint) return
    let br = this.break
    let { recovery } = this
    if (!br || !recovery) return
    let minDistPoint = _.minBy([br, recovery], x => x.eyes_dist)
    if (!minDistPoint) return

    // let midDist = (maxDistPoint.eyes_dist - minDistPoint.eyes_dist) / 2
    // midDist = Math.floor(midDist)

    let addLine = (point: DataPoint|undefined) => {
      if (!point) return
      let value = point.time
      // let text = Math.floor(point.eyes_dist) + ''
      let text = point.stimuli_dist.toFixed(PRECISION_BR)
      this.distanceLines.push({
        value,
        text
      })
    }

    let firstPoint = this.dataPoints[0]
    let lastPoint = this.dataPoints[this.dataPoints.length - 1]

    let findNearest = (value: number, xs: DataPoint[], fun: (d: DataPoint) => number) => {
      let found = _.minBy(xs, x => Math.abs(fun(x) - value))
      return found
    }
    let medianPoint = (from: DataPoint, to: DataPoint) => {
      let midTime = from.time + (to.time - from.time) / 2
      let found = findNearest(midTime, this.dataPoints, x => x.time)
      return found
    }

    addLine(br)
    addLine(recovery)
    addLine(medianPoint(firstPoint, br))
    addLine(medianPoint(br, recovery))
    addLine(medianPoint(recovery, lastPoint))
    // addLine(findNearest(midDist, this.distancing))
    // addLine(findNearest(midDist, this.approximation))
  }

  get stimuliData() {
    let { stimuli } = this
    if (stimuli == null) return []
    return [
      { image: STIMULI_IMAGES.get(stimuli) }
    ]
  }

  goBack() {
    this.isWayBack = true
  }
}

interface XY {
  x: number
  y: number
}
interface Poly {
  points: XY[]
}

// calculates area from min(y) to the line
// I assume :y only grows
// Also all zero :x are filtered-out
//
function calcArea(xs: XY[]) {
  let minX = _.min(xs.map(x => x.x)) || 0
  let prev: XY|undefined
  let area = 0

  xs.forEach(p => {
    if (prev) {
      let { x, y } = p
      let dy = y - prev.y
      let avgX = (x + prev.x) / 2 - minX

      area += avgX * dy
    }
    prev = p
  })
  return area
}

export interface AxisLine {
  value: number
  text: string
}

interface ExportDataEye {
  start: number
  breakk: number
  recovery: number
  max: number
  atMaxStimDist: number
  atRecStimDist: number
  atBreakStimDist: number
}
