// @ts-nocheck
//////////////
// let xs = []
// let xs = [[3,3]]
// let xs = [[1,1],[2,2],[6,0]]
// let approx = new LinearSegmentsApproximation()
// xs.forEach(x => approx.addPoint(x[0],x[1]))
// console.log(approx.getManyYs([0,1,2,3,4,5,6,7]))

type Point = [number, number];
type Interval = { from: Point | null; to: Point | null };

export class LinearSegmentsApproximation {
    private points: Point[] = [];

    addPoint (x: number, y: number) {
        this.points.push([x, y]);
    }

    // xs are ordered, all values are numbers
    getManyYs (xs: number[]): number[] {
        let xsStack = [...xs];

        let head = () => xsStack[0];
        let take = () => xsStack.shift();

        let DEFAULT_VALUE = 0;

        // each point belongs to only one interval [from,to) from a group right now
        let intervalApplies = (interval: Interval, x: number) => {
            let { from, to } = interval;

            if (from && to) {
                return from[0] <= x && x < to[0];
            } else if (!from && to) {
                return x < to[0];
            } else if (from && !to) {
                return from[0] <= x;
            } else if (!from && !to) {
                return false;
                // it does not mean infinite interval, it means no interval at all!
                // it provides no value like some empty sets or so
                // so this deceptive "infinite" thing is not there
            }
        };

        let ys = {};
        for (let interval of this.getAllIntervals()) {
            while (intervalApplies(interval, head())) {
                let x = take() as number;
                ys[x] = this.calcY(interval, x);
            }
        }

        return xs.map((x: number) => ys[x] || DEFAULT_VALUE);
    }

    getAllIntervals (): Interval[] {
        let result: Interval[] = [];
        let previous: Point | null = null;
        for (let point of this.points) {
            result.push({ from: previous, to: point });
            previous = point;
        }
        result.push({ from: previous, to: null });
        return result;
    }

    calcY (interval: Interval, x: number) {
        let { from, to } = interval;

        // typescript likes this structure more than multiple "if (a && !b) => return abc" lines
        if (!from) {
            if (!to) return 0; // no values => default = zero
            from = [0, 0]; // implicit starting point
        }
        if (!to) {
            if (from) return from[1]; // implicit last point value into infinity to the right
            return 0; // -- already handled just above
        }

        let deltaX = to[0] - from[0];
        let deltaY = to[1] - from[1];
        if (deltaX === 0) return (from[1] + to[1]) / 2;

        let coef = deltaY / deltaX;

        let givenDeltaX = x - from[0];
        let y = coef * givenDeltaX + from[1];
        return y;
    }

    // getY(x:number) {
    //   let interval = this.getIntervalForX(x)
    //   return this.calcY(interval, x)
    // }
    // getIntervalForX(x: number) {
    //   let previous: Point|null = null
    //   for (let point of this.points) {
    //     if (point[0] >= x) {
    //       return { from: previous, to: point }
    //     }
    //     previous = point
    //   }
    //   return { from: previous, to: null } // implicit ending point
    // }
}
