import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../_services/general/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(private authService: AuthenticationService) {}

    addAuthHeader(request: HttpRequest<any>) {
        if (request.body instanceof Blob) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.currentUser.token}`,
                },
            });
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.currentUser.token}`,
                    'Content-Type': 'application/json',
                },
            });
        }
        return request;
    }

    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable((observer) => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            return this.authService.refreshToken().pipe(
                tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next();
                })
            );
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Handle request
        request = this.addAuthHeader(request);

        // Handle response
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    return this.refreshToken().pipe(
                        switchMap(() => {
                            request = this.addAuthHeader(request);
                            return next.handle(request);
                        }),
                        catchError((error) => {
                            console.log(error);
                            this.refreshTokenInProgress = false;
                            this.authService.logout();
                            return EMPTY;
                        })
                    );
                }

                return throwError(error);
            })
        );
    }
}
