import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
    selector: 'patient-search',
    template: require('./patient-search.component.html'),
    styles: [require('./patient-search.component.scss')],
})
export class PatientSearchComponent implements OnInit, OnDestroy {
    private form: FormGroup;
    private searchTerms: BehaviorSubject<{ id: string; dateOfBirth: Date; ssn: string }>;
    private isSearchPerformed: boolean;
    private maxDatepickerDate: Date;
    constructor(private formBuilder: FormBuilder) {
        this.searchTerms = new BehaviorSubject<{ id: string; dateOfBirth: Date; ssn: string }>({
            id: null,
            dateOfBirth: null,
            ssn: null,
        });
        this.isSearchPerformed = false;
        this.maxDatepickerDate = new Date();
        this.form = this.formBuilder.group({
            id: '',
            dateOfBirth: null,
            ssn: '',
        });
    }
    ngOnInit(): void {
        this.form.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged<{ id: string; dateOfBirth: Date; ssn: string }>((v1, v2) => {
                    return v1.dateOfBirth === v2.dateOfBirth && v1.id === v2.id && v1.ssn === v2.ssn;
                }),
                tap((terms) => {
                    this.isSearchPerformed = !!terms.id || !!terms.dateOfBirth || !!terms.ssn;
                })
            )
            .subscribe((terms) => {
                this.searchTerms.next(terms);
            });
        let savedTerms: any = localStorage.getItem('searchTerms');
        if (savedTerms) {
            savedTerms = JSON.parse(savedTerms);
            savedTerms.dateOfBirth = savedTerms.dateOfBirth ? new Date(savedTerms.dateOfBirth) : savedTerms.dateOfBirth;
            setTimeout(() => {
                this.form.setValue(savedTerms, { emitEvent: false });
                this.isSearchPerformed = !!savedTerms.id || !!savedTerms.dateOfBirth || !!savedTerms.ssn;
            }, 0);
            this.searchTerms.next(savedTerms);
        } else {
            this.form.reset({ emitEvent: false });
        }
    }
    ngOnDestroy(): void {}
    public get searchTermsSource(): BehaviorSubject<{ id: string; dateOfBirth: Date; ssn: string }> {
        return this.searchTerms;
    }
    public set patientRegisterId(registerID: string) {
        this.form.controls['id'].setValue(registerID);
    }
}
