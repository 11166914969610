import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { MaculaScanTopconTritonMeasuredDataFrontend } from '../../../measuredData/TOPCON/TRITON/maculaScanTopconTritonDataFrontend.class';

export class MaculaScanTopconTritonTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof MaculaScanTopconTritonMeasuredDataFrontend = MaculaScanTopconTritonMeasuredDataFrontend;
    public measurements: MaculaScanTopconTritonMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
