import * as jsPDF from 'jspdf';
import { IKeratometryNidekTonorefIIIMeasuredData } from '../../../../../../../common/interfaces/measuredData/NIDEK/TonorefIII/keratometry-nidek-tonorefIII.measuredData.interface';
import { KeratometryTonorefIIIMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/TonorefIII/keratometryTonorefIIIData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { KeratometryTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/keratometryTonorefIIITestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class KeratometryTonorefIIIMeasuredDataFrontend extends KeratometryTonorefIIIMeasuredData implements MeasuredDataFrontend {
    public remarks: KeratometryTonorefIIITestRemarksFrontend;
    public OD: {
        KM: {
            diameter: RegisterEditFieldFrontend<string>;
            R1Median: {
                mm: RegisterEditFieldFrontend<string>;
                diopter: RegisterEditFieldFrontend<string>;
                axis: RegisterEditFieldFrontend<string>;
            };
            R2Median: {
                mm: RegisterEditFieldFrontend<string>;
                diopter: RegisterEditFieldFrontend<string>;
                axis: RegisterEditFieldFrontend<string>;
            };
        }[];
        corneal: {
            number: RegisterEditFieldFrontend<string>;
            size: RegisterEditFieldFrontend<string>;
        }[];
    };
    public OS: {
        KM: {
            diameter: RegisterEditFieldFrontend<string>;
            R1Median: {
                mm: RegisterEditFieldFrontend<string>;
                diopter: RegisterEditFieldFrontend<string>;
                axis: RegisterEditFieldFrontend<string>;
            };
            R2Median: {
                mm: RegisterEditFieldFrontend<string>;
                diopter: RegisterEditFieldFrontend<string>;
                axis: RegisterEditFieldFrontend<string>;
            };
        }[];
        corneal: {
            number: RegisterEditFieldFrontend<string>;
            size: RegisterEditFieldFrontend<string>;
        }[];
    };
    constructor() {
        super();
    }
    public setModel(model: IKeratometryNidekTonorefIIIMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        model.OD.KM.forEach(odkm => {
            this.OD.KM.push({
                diameter: new RegisterEditFieldFrontend<string>().setModel(odkm.diameter),
                R1Median: {
                    mm: new RegisterEditFieldFrontend<string>().setModel(odkm.R1Median.mm),
                    diopter: new RegisterEditFieldFrontend<string>().setModel(odkm.R1Median.diopter),
                    axis: new RegisterEditFieldFrontend<string>().setModel(odkm.R1Median.axis),
                },
                R2Median: {
                    mm: new RegisterEditFieldFrontend<string>().setModel(odkm.R2Median.mm),
                    diopter: new RegisterEditFieldFrontend<string>().setModel(odkm.R2Median.diopter),
                    axis: new RegisterEditFieldFrontend<string>().setModel(odkm.R2Median.axis),
                },
            });
        });
        model.OD.corneal.forEach(odc => {
            this.OD.corneal.push({
                number: new RegisterEditFieldFrontend<string>().setModel(odc.number),
                size: new RegisterEditFieldFrontend<string>().setModel(odc.size),
            });
        });
        model.OS.KM.forEach(oskm => {
            this.OS.KM.push({
                diameter: new RegisterEditFieldFrontend<string>().setModel(oskm.diameter),
                R1Median: {
                    mm: new RegisterEditFieldFrontend<string>().setModel(oskm.R1Median.mm),
                    diopter: new RegisterEditFieldFrontend<string>().setModel(oskm.R1Median.diopter),
                    axis: new RegisterEditFieldFrontend<string>().setModel(oskm.R1Median.axis),
                },
                R2Median: {
                    mm: new RegisterEditFieldFrontend<string>().setModel(oskm.R2Median.mm),
                    diopter: new RegisterEditFieldFrontend<string>().setModel(oskm.R2Median.diopter),
                    axis: new RegisterEditFieldFrontend<string>().setModel(oskm.R2Median.axis),
                },
            });
        });
        model.OS.corneal.forEach(osc => {
            this.OS.corneal.push({
                number: new RegisterEditFieldFrontend<string>().setModel(osc.number),
                size: new RegisterEditFieldFrontend<string>().setModel(osc.size),
            });
        });
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table: any = [];
        if (this.OD.KM.length > 0)
            table.push({
                column1: { colSpan: 4, content: 'OD Median', styles: { halign: 'center' } },
                column2: '',
                column3: '',
                column4: '',
            });
        this.OD.KM.forEach(odkm => {
            table.push({
                column1: 'Diameter-' + odkm.diameter.value,
                column2: 'mm',
                column3: 'Diopter',
                column4: 'Axis',
            });
            table.push({
                column1: 'R1',
                column2: odkm.R1Median.mm.value,
                column3: odkm.R1Median.diopter.value,
                column4: odkm.R1Median.axis.value,
            });
            table.push({
                column1: 'R2',
                column2: odkm.R2Median.mm.value,
                column3: odkm.R2Median.diopter.value,
                column4: odkm.R2Median.axis.value,
            });
        });
        if (this.OD.corneal.length > 0)
            table.push({
                column1: { colSpan: 4, content: 'OD Corneal', styles: { halign: 'center' } },
                column2: '',
                column3: '',
                column4: '',
            });
        this.OD.corneal.forEach(odc => {
            table.push({
                column1: { colSpan: 2, content: '', styles: { halign: 'center' } },
                column2: '',
                column3: { colSpan: 2, content: 'Size', styles: { halign: 'center' } },
                column4: '',
            });
            table.push({
                column1: { colSpan: 2, content: odc.number.value, styles: { halign: 'center' } },
                column2: '',
                column3: { colSpan: 2, content: odc.size.value, styles: { halign: 'center' } },
                column4: '',
            });
        });
        const startYposition: number = pager.line,
            pageNumber: number = document.internal.getCurrentPageInfo().pageNumber;
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: document.internal.pageSize.getWidth() / 2, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        const endYposition: number = (document as any).lastAutoTable.finalY;
        document.setPage(pageNumber);
        table = [];
        if (this.OS.KM.length > 0)
            table.push({
                column1: { colSpan: 4, content: 'OS Median', styles: { halign: 'center' } },
                column2: '',
                column3: '',
                column4: '',
            });
        this.OS.KM.forEach(oskm => {
            table.push({
                column1: 'Diameter-' + oskm.diameter.value,
                column2: 'mm',
                column3: 'Diopter',
                column4: 'Axis',
            });
            table.push({
                column1: 'R1',
                column2: oskm.R1Median.mm.value,
                column3: oskm.R1Median.diopter.value,
                column4: oskm.R1Median.axis.value,
            });
            table.push({
                column1: 'R2',
                column2: oskm.R2Median.mm.value,
                column3: oskm.R2Median.diopter.value,
                column4: oskm.R2Median.axis.value,
            });
        });
        if (this.OS.corneal.length > 0)
            table.push({
                column1: { colSpan: 4, content: 'OS Corneal', styles: { halign: 'center' } },
                column2: '',
                column3: '',
                column4: '',
            });
        this.OS.corneal.forEach(osc => {
            table.push({
                column1: { colSpan: 2, content: '', styles: { halign: 'center' } },
                column2: '',
                column3: { colSpan: 2, content: 'Size', styles: { halign: 'center' } },
                column4: '',
            });
            table.push({
                column1: { colSpan: 2, content: osc.number.value, styles: { halign: 'center' } },
                column2: '',
                column3: { colSpan: 2, content: osc.size.value, styles: { halign: 'center' } },
                column4: '',
            });
        });
        (document as any).autoTable({
            body: table,
            startY: startYposition,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: document.internal.pageSize.getWidth() / 2 },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY > endYposition ? (document as any).lastAutoTable.finalY : endYposition;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        this.OD.KM.forEach(odkm => {
            formattedTest.OD.values +=
                'Φ-' +
                odkm.diameter.value +
                '; R1-' +
                (odkm.R1Median.axis.value || 'x') +
                '/' +
                (odkm.R1Median.diopter.value || 'x') +
                '/' +
                (odkm.R1Median.mm.value || 'x') +
                ' R2-' +
                (odkm.R2Median.axis.value || 'x') +
                '/' +
                (odkm.R2Median.diopter.value || 'x') +
                '/' +
                (odkm.R2Median.mm.value || 'x') +
                ';\n';
        });
        this.OD.corneal.forEach(odc => {
            formattedTest.OD.values += 'Corneal-' + (odc.number.value || 'x') + '/' + (odc.size.value || 'x') + ';\n';
        });
        this.OS.KM.forEach(oskm => {
            formattedTest.OS.values +=
                'Φ-' +
                oskm.diameter.value +
                '; R1-' +
                (oskm.R1Median.axis.value || 'x') +
                '/' +
                (oskm.R1Median.diopter.value || 'x') +
                '/' +
                (oskm.R1Median.mm.value || 'x') +
                ' R2-' +
                (oskm.R2Median.axis.value || 'x') +
                '/' +
                (oskm.R2Median.diopter.value || 'x') +
                '/' +
                (oskm.R2Median.mm.value || 'x') +
                ';\n';
        });
        this.OS.corneal.forEach(osc => {
            formattedTest.OS.values += 'Corneal-' + (osc.number.value || 'x') + '/' + (osc.size.value || 'x') + ';\n';
        });
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
