import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { IGrade } from '../../../../../../commonout/interfaces/grade.interface';
import { TestFrontend } from '../../_models/tests/testFrontend.class';

@Injectable()
export class ModalService {
    // simple questions modal
    private modalQuestionSource = new Subject<string>();
    questionAnnounced$ = this.modalQuestionSource.asObservable();
    private answerSource = new Subject<boolean>();

    // chart modal
    private chartDataSource = new Subject<{ haplotestDataID: string; patientID: string; examinationID: string; test: TestFrontend }>();
    chartDataSource$ = this.chartDataSource.asObservable();

    // confirmation modal
    private confirmationTextSource = new Subject<string>();
    confirmationText$ = this.confirmationTextSource.asObservable();
    private confirmationSource = new Subject<boolean>();

    // feedback modal
    private showFeedbackSignalSource = new Subject<IGrade>();
    showFeedbackSignalSource$ = this.showFeedbackSignalSource.asObservable();
    public feedbackDoneSignal = new Subject<IGrade[]>();
    public feedbackScrollEvent = new Subject<'up' | 'down'>();

    public provideFeedback(payload: IGrade): Promise<IGrade[]> {
        this.showFeedbackSignalSource.next(payload);
        return this.feedbackDoneSignal
            .asObservable()
            .pipe(first())
            .toPromise();
    }

    showChart(data: { haplotestDataID: string; patientID: string; examinationID: string; test: TestFrontend }): void {
        this.chartDataSource.next(data);
    }

    askUser(question: string): Promise<boolean> {
        this.modalQuestionSource.next(question);
        return this.answerSource
            .asObservable()
            .pipe(first())
            .toPromise();
    }

    confirm(text: string): Promise<boolean> {
        this.confirmationTextSource.next(text);
        return this.confirmationSource
            .asObservable()
            .pipe(first())
            .toPromise();
    }

    set answer(answer: boolean) {
        this.answerSource.next(answer);
    }

    set confirmation(confirmation: boolean) {
        this.confirmationSource.next(confirmation);
    }
}
