// @ts-nocheck
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICamMessage } from '../../../../../../../../commonout/interfaces/charts.model';
import { INystagmusParams } from '../../../../../../../common/interfaces/measuredData/BulbiCAM/nystagmusEvaliationBulbicam.measuredData.interface';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { Diagram } from './diagram/Diagram';

@Component({
    selector: 'nystagmus-test-chart',
    template: require('./nystagmus-test-chart.component.html'),
    styles: [require('../charts.scss'), require('./nystagmus-test-chart.component.scss')],
})
export class NystagmusBulbicamTestChartComponent extends BulbicamChartComponent {
    @ViewChild('targetElement') nystagmusChart: ElementRef;
    @ViewChild('targetContainer') targetContainer: ElementRef;
    diagram?: Diagram;

    edits: any[] = [];
    public setEdits(got: any): void {
        // console.log('got setEdits:', got)
        this.diagram.edits = got;
    }

    constructor(private bulbicamService: BulbicamService, private authenticationService: AuthenticationService, private conf: ConfigService) {
        super();
        this.bulbicamService.nystagmusStaff.createTest = () => {
            this.clearData(true);
            this.refresh();
        };
    }

    // I assume inputData is already set...
    ngAfterViewInit() {
        let target = this.nystagmusChart.nativeElement;
        let targetContainer = this.targetContainer.nativeElement;

        this.initChart(target, targetContainer);

        if (this.inputData) {
            this.addData(this.inputData);
            this.diagram.refresh();
        } else {
        }
        this.refresh();
        this.bulbicamService.nystagmusStaff.startParams = ((): INystagmusParams[] => {
            return this.startParams;
        }).bind(this);
    }

    initChart(targetElement: HTMLElement, targetContainer: HTMLElement) {
        log('initChart');
        let theme = 'any';
        let { conf } = this;
        let apiPrefix = conf.backendUrl;
        // let apiPrefix = `/api/v1/haplo`
        this.diagram = new Diagram({
            svgTarget: targetElement,
            targetContainer,
            theme,
            updateStartParams: (data: any) => {
                this.startParams = data;
            },
            requestToken: this.authenticationService.currentUser.token,
            apiPrefix,

            edits: this.edits,
            addEdit: (x: any) => this.addEdit(x),
        });
    }

    addData(data: ICamMessage[]) {
        this.bulbicamService.nystagmusStaff.chartCleared = false;
        this.diagram.addData(data);
        this.diagram.refresh();
    }

    refresh = () => {
        this.diagram.refresh();
    };

    clearData(signal: boolean) {
        this.bulbicamService.nystagmusStaff.chartCleared = signal;
        this.diagram.reset();
    }

    startParams: INystagmusParams[];

    get exportData() {
        return this.diagram.exportData();
    }
}

function log(x: string | object) {
    return;
    if (typeof x === 'string') x = `nystagmus.${x}`;
    console.log(x);
}
