import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { axisBottom, axisLeft, Line, line, scaleLinear, select, Selection } from 'd3';
import * as d3 from 'd3';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import { IPupil20TestCamMessage, TestResultCoordinates } from '../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { PupilDiameterChartService } from '../../../../../_services/chartServices/pupilDiameterChartService';
import * as _ from 'lodash';

export enum LINE_COLOR {
    OD = 'orange',
    OS = 'aqua',
}

@Component({
    selector: 'pupil-diameter-chart',
    template: require('./pupil-diameter-chart.component.html'),
    styles: [require('./pupil-diameter-chart.component.scss')],
})
export class PupilDiameterChartComponent implements AfterViewInit, OnInit, OnDestroy {
    public framesSource: Subject<IPupil20TestCamMessage> = new Subject<IPupil20TestCamMessage>();
    public data: IPupil20TestCamMessage[][] = [];
    public round: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private realTimeRound: number = -1;
    private lineOD: { x: number; y: number }[] = [];
    private lineOS: { x: number; y: number }[] = [];
    private movingLineOD: { x: number; y: number }[] = [];
    private movingLineOS: { x: number; y: number }[] = [];
    private realTimeLineOD: { x: number; y: number }[] = [];
    private realTimeLineOS: { x: number; y: number }[] = [];
    @ViewChild('pupilDiameterChart') private svg: ElementRef;
    private readonly CHART_HEIGHT = 450;
    private readonly RESIZE_WIDTH_FACTOR = 0.8;
    private padding: { left: number; top: number; right: number; bottom: number } = { left: 200, top: 40, right: 40, bottom: 40 };
    private subscriptions: Subscription[] = [];
    private readonly ROUND_TEST_LENGTH: number = 25;
    private readonly REAL_TIME_DISPLAY_RANGE = 100;
    private readonly MAX_Y_AXIS = 6;
    private readonly MIN_Y_AXIS = -2;
    private startTimestamp: number = undefined;
    private lineGenerator: Line<{ oculus: OCULUS; x: number; y: number }>;
    private highlightLineGenerator: Line<{ oculus: OCULUS; x: number; y: number }>;
    private pupilDiameterChart: Selection<SVGGElement, unknown, null, undefined>;
    private xAxisGenerator: any;
    private yAxisGenerator: any;
    private latencies: { run: number; xValue: number; oculus: OCULUS }[] = [];

    constructor(private chartService: PupilDiameterChartService) {
        this.subscriptions.push(
            this.framesSource.subscribe(frame => {
                const localFrame = { ...frame };
                switch (localFrame.message_type) {
                    case MESSAGE_TYPE.START_TEST:
                        break;
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        this.realTimeRound++;
                        this.data.push([]);
                        this.data[this.data.length - 1].push(localFrame);
                        this.drawPupilDiameterLine(this.realTimeRound, localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(localFrame);
                        this.drawPupilDiameterLine(this.realTimeRound, localFrame);
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        this.data[this.data.length - 1].push(localFrame);
                        this.drawPupilDiameterLine(this.realTimeRound, localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        this.round.next(0);
                        this.data[this.data.length - 1].push(localFrame);
                        this.drawPupilDiameterLine(this.realTimeRound, localFrame);
                        break;
                    default:
                        this.data[this.data.length - 1].push(localFrame);
                        this.drawPupilDiameterLine(this.realTimeRound, localFrame);
                        break;
                }
            })
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.round.pipe(skip(1)).subscribe(r => {
                if (r === null) return;
                this.data[r].forEach(frame => {
                    switch (frame.message_type) {
                        case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                            this.redrawChartLines(r);

                            this.startTimestamp = frame.timestamp / 10000;
                            this.lineOD = [];
                            this.lineOS = [];
                            this.movingLineOD = [];
                            this.movingLineOS = [];
                            break;
                        case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                            this.fillDisplayMovingAverageLines(r);
                            this.updatelines(r);
                            this.updateLatencies(r);

                            this.fillPupilDiameterLines(r);
                            break;
                        case MESSAGE_TYPE.DATA_PACKAGE:
                            this.lineOD.push({ x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp), y: frame.measurementOD });
                            this.lineOS.push({ x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp), y: frame.measurementOS });
                            break;
                        case MESSAGE_TYPE.STOP_TEST:
                            break;
                        default:
                            break;
                    }
                });
            })
        );
    }

    private redrawChartLines(r: number) {
        this.pupilDiameterChart.selectAll('.lineOD').remove();
        this.pupilDiameterChart.selectAll('.lineOS').remove();
        this.pupilDiameterChart.selectAll('.avgLineOD').remove();
        this.pupilDiameterChart.selectAll('.avgLineOS').remove();

        this.pupilDiameterChart
            ?.append('path')
            .attr('class', `Round${r} lineOD`)
            .attr('fill', 'none')
            .attr('stroke', LINE_COLOR.OD)
            .attr('stroke-width', '0.5px');
        this.pupilDiameterChart
            ?.append('path')
            .attr('class', `Round${r} lineOS`)
            .attr('fill', 'none')
            .attr('stroke', LINE_COLOR.OS)
            .attr('stroke-width', '0.5px');
        this.pupilDiameterChart
            ?.append('path')
            .attr('class', `Round${r} avgLineOD`)
            .attr('fill', 'none')
            .attr('stroke', LINE_COLOR.OD)
            .attr('stroke-width', '0.5px');
        this.pupilDiameterChart
            ?.append('path')
            .attr('class', `Round${r} avgLineOS`)
            .attr('fill', 'none')
            .attr('stroke', LINE_COLOR.OS)
            .attr('stroke-width', '0.5px');
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    ngAfterViewInit() {
        const SVG = select(this.svg.nativeElement);
        const SVGwidth = this.svg.nativeElement.getBoundingClientRect().width 
            ? this.svg.nativeElement.getBoundingClientRect().width 
            : window.innerWidth * this.RESIZE_WIDTH_FACTOR;
        const SVGHeight = this.svg.nativeElement.getBoundingClientRect().height
            ? this.svg.nativeElement.getBoundingClientRect().height
            : this.CHART_HEIGHT;

        this.xAxisGenerator = scaleLinear()
            .domain([0, this.ROUND_TEST_LENGTH])
            .range([0, SVGwidth - this.padding.left - this.padding.right]);
        this.yAxisGenerator = scaleLinear()
            .domain([this.MAX_Y_AXIS, this.MIN_Y_AXIS])
            .range([0, SVGHeight - this.padding.top - this.padding.bottom]);

        SVG.append('g')
            .attr('class', 'xAxis')
            .attr('transform', `translate(${this.padding.left},${SVGHeight - this.padding.top})`)
            .attr('color', 'white')
            .call(axisBottom(this.xAxisGenerator).ticks(25));

        SVG.append('g')
            .attr('class', 'yAxis')
            .attr('transform', `translate(${this.padding.left},${this.padding.top})`)
            .attr('color', 'white')
            .call(axisLeft(this.yAxisGenerator).ticks(6));

        this.pupilDiameterChart = SVG.append('g')
            .attr('class', 'pupilDiameterChart')
            .attr('transform', `translate(${this.padding.left},${this.padding.top})`);
        this.lineGenerator = line<{ oculus: OCULUS; x: number; y: number }>()
            .x(d => this.xAxisGenerator(d.x))
            .y(d => this.yAxisGenerator(d.y));
        this.highlightLineGenerator = line<{ oculus: OCULUS; x: number; y: number }>()
            .x(d => this.xAxisGenerator(d.x))
            .y(d => this.yAxisGenerator(d.y));

        this.pupilDiameterChart
            .append('text')
            .text('px')
            .attr('class', 'runTimeLegends')
            .attr('fill', 'white')
            .attr('transform', `translate(${-this.padding.right},${0})`);
        this.pupilDiameterChart
            .append('text')
            .text('sec')
            .attr('class', 'runTimeLegends')
            .attr('fill', 'white')
            .attr('transform', `translate(${SVGwidth - this.padding.left - this.padding.right},${SVGHeight - this.padding.top})`);

        this.lineGenerator.defined((d, i) => d.y !== 0 && d.y > this.MIN_Y_AXIS && d.y < this.MAX_Y_AXIS);
        this.highlightLineGenerator.defined((d, i) => d.y !== 0);
    }
    @HostListener('window:resize', ['$event']) public onResize() {
        select(this.svg.nativeElement)
            .selectAll('*')
            .remove();
        this.ngAfterViewInit();
        if (this.lineOD.length > 0 && this.lineOS.length > 0) {
            this.pupilDiameterChart
                .append('path')
                .attr('class', `Round${this.round.value} lineOD`)
                .attr('fill', 'none')
                .attr('stroke', LINE_COLOR.OD)
                .attr('stroke-width', '0.5px');
            this.pupilDiameterChart
                .append('path')
                .attr('class', `Round${this.round.value} lineOS`)
                .attr('fill', 'none')
                .attr('stroke', LINE_COLOR.OS)
                .attr('stroke-width', '0.5px');
            this.pupilDiameterChart
                ?.append('path')
                .attr('class', `Round${this.round.value} avgLineOD`)
                .attr('fill', 'none')
                .attr('stroke', LINE_COLOR.OD)
                .attr('stroke-width', '0.5px');
            this.pupilDiameterChart
                ?.append('path')
                .attr('class', `Round${this.round.value} avgLineOS`)
                .attr('fill', 'none')
                .attr('stroke', LINE_COLOR.OS)
                .attr('stroke-width', '0.5px');

            this.pupilDiameterChart
                .select(`.Round${this.round.value}.lineOD`)
                .datum(this.lineOD)
                .attr('d', this.lineGenerator);
            this.pupilDiameterChart
                .select(`.Round${this.round.value}.lineOS`)
                .datum(this.lineOS)
                .attr('d', this.lineGenerator);
            this.pupilDiameterChart
                ?.select(`.Round${this.round.value}.avgLineOD`)
                .datum(this.movingLineOD)
                .attr('d', this.lineGenerator);
            this.pupilDiameterChart
                ?.select(`.Round${this.round.value}.avgLineOS`)
                .datum(this.movingLineOS)
                .attr('d', this.lineGenerator);
        }
    }
    public clear(): void {
        this.round.next(null);
        this.lineOD = [];
        this.lineOS = [];
        this.realTimeLineOD = [];
        this.realTimeLineOS = [];
        this.latencies = [];
        this.data = [];
        this.startTimestamp = null;
        this.realTimeRound = -1; 
        this.pupilDiameterChart.selectAll('.lightLine').remove();
        this.pupilDiameterChart.selectAll('.lineOD').remove();
        this.pupilDiameterChart.selectAll('.lineOS').remove();
        this.pupilDiameterChart.selectAll('.avgLineOD').remove();
        this.pupilDiameterChart.selectAll('.avgLineOS').remove();
        this.pupilDiameterChart.selectAll('.latency').remove();
    }

    public clearChart(): void {
        this.round.next(null);
        this.realTimeLineOD = [];
        this.realTimeLineOS = [];
        this.lineOD = [];
        this.lineOS = [];
        this.startTimestamp = null;
        this.pupilDiameterChart.selectAll('.lightLine').remove();
        this.pupilDiameterChart.selectAll('.lineOD').remove();
        this.pupilDiameterChart.selectAll('.lineOS').remove();
        this.pupilDiameterChart.selectAll('.avgLineOD').remove();
        this.pupilDiameterChart.selectAll('.avgLineOS').remove();
        this.pupilDiameterChart.selectAll('.latency').remove();
    }

    private updatelines(index: number): void {
        this.pupilDiameterChart
            ?.select(`.Round${index}.lineOD`)
            .datum(this.lineOD)
            .attr('d', this.lineGenerator);
        this.pupilDiameterChart
            ?.select(`.Round${index}.lineOS`)
            .datum(this.lineOS)
            .attr('d', this.lineGenerator);
    }
    public set highlight(targets: TestResultCoordinates[]) {
        targets.forEach((target, i) => {
            let datum: { x: number; y: number }[];
            if (target.deltaSingleOculus === true) {
                const startTS: number = this.data[target.run][0].timestamp / 10000,
                    endTS: number = moment(new Date(startTS))
                        .add(this.ROUND_TEST_LENGTH, 'seconds')
                        .valueOf(),
                    tsCnt: number = endTS - startTS,
                    xValue: number = ((target.xValue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
                    deltaXvalue = ((target.deltaXvalue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
                    yValue: number = this.yAxisGenerator.domain()[0];
                datum = [
                    { x: xValue, y: yValue },
                    { x: deltaXvalue, y: yValue },
                    { x: deltaXvalue, y: yValue },
                ];
            } else if (target.deltaSingleOculus === false) {
                const startTS: number = this.data[target.run][0].timestamp / 10000,
                    endTS: number = moment(new Date(startTS))
                        .add(this.ROUND_TEST_LENGTH, 'seconds')
                        .valueOf(),
                    tsCnt: number = endTS - startTS,
                    xValue: number = ((target.xValue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
                    xRange: number = ((target.xRange / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
                    yValue: number = this.yAxisGenerator.domain()[0];
                datum = [
                    { x: xValue, y: -5 },
                    { x: xValue, y: yValue },
                    { x: xRange, y: yValue },
                    { x: xValue, y: yValue },
                ];
                if (target.deltaYvalue) {
                    datum = datum.concat([
                        { x: xValue, y: target.deltaYvalue },
                        { x: xRange, y: target.deltaYvalue },
                    ]);
                }
            } else if (target.xValueOD && target.xValueOS) {
                const startTS: number = this.data[target.run][0].timestamp / 10000,
                    endTS: number = moment(new Date(startTS))
                        .add(this.ROUND_TEST_LENGTH, 'seconds')
                        .valueOf(),
                    tsCnt: number = endTS - startTS,
                    xValueOD: number = ((target.xValueOD / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
                    xValueOS: number = ((target.xValueOS / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
                    xValue: number = ((target.xSecondValue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt;

                const datumOD = [
                    { x: xValueOD, y: target.yValue },
                    { x: xValueOD, y: this.MAX_Y_AXIS },
                ];
                const datumOS = [
                    { x: xValueOS, y: target.yValue },
                    { x: xValueOS, y: this.MAX_Y_AXIS },
                ];

                datum = [
                    { x: xValue, y: target.yValue },
                    { x: xValue, y: this.MAX_Y_AXIS },
                ];

                this.pupilDiameterChart
                    .append('path')
                    .attr('class', `level`)
                    .attr('fill', 'none')
                    .attr('stroke', 'white')
                    .attr('stroke-width', '1px')
                    .datum(datumOD)
                    .attr('d', this.highlightLineGenerator);

                this.pupilDiameterChart
                    .append('path')
                    .attr('class', `level`)
                    .attr('fill', 'none')
                    .attr('stroke', 'white')
                    .attr('stroke-width', '1px')
                    .datum(datumOS)
                    .attr('d', this.highlightLineGenerator);
            } else {
                const startTS: number = this.data[target.run][0].timestamp / 10000,
                    endTS: number = moment(new Date(startTS))
                        .add(this.ROUND_TEST_LENGTH, 'seconds')
                        .valueOf(),
                    tsCnt: number = endTS - startTS,
                    xValue: number = ((target.xValue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt;

                datum = [
                    { x: xValue, y: target.yValue },
                    { x: xValue, y: this.MAX_Y_AXIS },
                ];

                if (i === 0 && target.xAreaValues) {
                    const datumOD: { x: number; y: number }[] = [];
                    const datumOS: { x: number; y: number }[] = [];

                    target.yAreaValuesOD.forEach((yValue, i) => {
                        const xAreaValue = ((target.xAreaValues[i] / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt;
                        datumOD.push({
                            x: xAreaValue,
                            y: yValue,
                        });
                        datumOS.push({
                            x: xAreaValue,
                            y: target.yAreaValuesOS[i],
                        });
                    });

                    const areaGenerator = d3
                        .area<{ x: number; y0: number; y: number }>()
                        .x(d => this.xAxisGenerator(d.x))
                        .y0(p => this.yAxisGenerator(this.MIN_Y_AXIS))
                        .y1(d => this.yAxisGenerator(d.y));

                    this.pupilDiameterChart
                        .append('path')
                        .attr('fill', 'rgb(255 165 0 / 50%)')
                        .attr('stroke', 'white')
                        .attr('stroke-width', '0')
                        .datum(datumOD)
                        .attr('class', 'area')
                        .attr('d', areaGenerator);
                    this.pupilDiameterChart
                        .append('path')
                        .attr('fill', 'rgb(0 255 255 / 50%)')
                        .attr('stroke', 'white')
                        .attr('stroke-width', '0')
                        .datum(datumOS)
                        .attr('class', 'area')
                        .attr('d', areaGenerator);
                }
            }

            if (datum) {
                this.pupilDiameterChart
                    .append('path')
                    .attr('class', `level`)
                    .attr('fill', 'none')
                    .attr('stroke', 'white')
                    .attr('stroke-width', '1px')
                    .datum(datum)
                    .attr('d', this.highlightLineGenerator);
            }
        });
    }
    public set clearHighlight(signal: void) {
        this.pupilDiameterChart.selectAll('.level').remove();
        this.pupilDiameterChart.selectAll('.area').remove();
    }
    public set latency(target: { run: number; xValue: number; oculus: OCULUS }) {
        this.latencies.push(target);
        this.updateLatencies(target.run);
    }
    public set clearLatencies(event: any) {
        this.latencies = [];
    }
    public set drawLightLines(ligthLinesCoordinates: { lightCoordinatesOD: TestResultCoordinates[]; lightCoordinatesOS: TestResultCoordinates[] }) {
        ligthLinesCoordinates.lightCoordinatesOD.forEach((coors, i) => {
            // TODO delete
            if (coors.run === 0) {
                this.drawLightLine(coors, LINE_COLOR.OD);
                this.drawLightLine(ligthLinesCoordinates.lightCoordinatesOS[i], LINE_COLOR.OS);
            }
        });
    }
    private drawLightLine(coors: TestResultCoordinates, lineColor: LINE_COLOR) {
        let datum: { x: number; y: number }[];

        const startTS: number = this.data[coors.run][0].timestamp / 10000,
            endTS: number = moment(new Date(startTS))
                .add(this.ROUND_TEST_LENGTH, 'seconds')
                .valueOf(),
            tsCnt: number = endTS - startTS,
            xValue: number = ((coors.xValue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt,
            deltaXvalue = ((coors.deltaXvalue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt;

        datum = [
            { x: xValue, y: coors.yValue },
            { x: deltaXvalue, y: coors.yValue },
            { x: deltaXvalue, y: coors.yValue },
        ];

        this.pupilDiameterChart
            .append('path')
            .attr('class', `lightLine`)
            .attr('fill', 'none')
            .attr('stroke-dasharray', '2, 2')
            .attr('stroke', lineColor)
            .attr('stroke-width', '1px')
            .datum(datum)
            .attr('d', this.lineGenerator);
    }

    private updateLatencies(index: number): void {
        this.pupilDiameterChart.selectAll('.latency').remove();
        this.latencies
            .filter(l => l.run === index)
            .forEach(l => {
                let datum: { x: number; y: number }[];
                const startTS: number = this.data[l.run][0].timestamp / 10000,
                    endTS: number = moment(new Date(startTS))
                        .add(this.ROUND_TEST_LENGTH, 'seconds')
                        .valueOf(),
                    tsCnt: number = endTS - startTS,
                    xValue: number = ((l.xValue / 10000 - startTS) * this.ROUND_TEST_LENGTH) / tsCnt;
                datum = [
                    { x: xValue, y: this.MIN_Y_AXIS },
                    { x: xValue, y: this.MAX_Y_AXIS },
                ];
                // this.pupilDiameterChart
                //     .append('path')
                //     .attr('class', `latency`)
                //     .attr('fill', 'none')
                //     .attr('stroke', l.oculus === OCULUS.OD ? LINE_COLOR.OD : LINE_COLOR.OS)
                //     .attr('stroke-width', '0.5px')
                //     .attr('stroke-dasharray', '3 10')
                //     .datum(datum)
                //     .attr('d', this.lineGenerator);
            });
    }

    private fillDisplayMovingAverageLines(round: number) {
        const data = _.cloneDeep(this.data);

        this.interpolateGap(data[round], 0);
        this.lineOD = [];
        this.lineOS = [];
        const startTimestamp = data[round][0].timestamp / 10000;
        const ma = 20;
        data[round] = data[round].filter(frame => frame.measurementODwithoutZeros && frame.measurementOSwithoutZeros);
        data[round].forEach((d, i, collector) => {
            if (i >= ma) {
                d.movingAverageOD =
                    collector
                        .slice(i - ma, i + ma)
                        .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                        .reduce((partial_sum, a) => partial_sum + a.measurementODwithoutZeros, 0) /
                    collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
                d.movingAverageOS =
                    collector
                        .slice(i - ma, i + ma)
                        .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                        .reduce((partial_sum, a) => partial_sum + a.measurementOSwithoutZeros, 0) /
                    collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
            }
        });

        const fd: number = 10;
        data[round].forEach((d, i, collector) => {
            if (i >= fd / 2 && i < collector.length - fd / 2) {
                const firstDeviationOD = (collector[i - fd / 2].movingAverageOD - collector[i + fd / 2].movingAverageOD) / (fd + 1);
                const firstDeviationOS = (collector[i - fd / 2].movingAverageOS - collector[i + fd / 2].movingAverageOS) / (fd + 1);

                if (!firstDeviationOD || !firstDeviationOS) return;

                d.firstDeviationOD = firstDeviationOD;
                d.firstDeviationOS = firstDeviationOS;
            }
        });

        const sma: number = 20;

        data[round].forEach(frame => {
            if (!frame.firstDeviationOD) frame.firstDeviationOD = 0;
            else if (!frame.firstDeviationOS) frame.firstDeviationOS = 0;
            return frame;
        });

        data[round].forEach((d, i, collector) => {
            if (i >= sma + ma) {
                d.secondMovingAverageOD =
                    collector
                        .slice(i - sma, i + sma)
                        // .filter(frame => frame.firstDeviationOD)
                        .reduce((partial_sum, a) => partial_sum + a?.firstDeviationOD, 0) /
                    (sma * 2);
                d.secondMovingAverageOS =
                    collector
                        .slice(i - sma, i + sma)
                        // .filter(frame => frame.firstDeviationOD)
                        .reduce((partial_sum, a) => partial_sum + a?.firstDeviationOS, 0) /
                    (sma * 2);
            }
        });

        const multiplyFactor = 500;

        data[round]
            .filter(f => f.secondMovingAverageOD && f.secondMovingAverageOS)
            .forEach(frame => {
                this.lineOD.push({ x: this.chartService.getXvalue(startTimestamp, frame.timestamp), y: frame.secondMovingAverageOD * multiplyFactor });
                this.lineOS.push({ x: this.chartService.getXvalue(startTimestamp, frame.timestamp), y: frame.secondMovingAverageOS * multiplyFactor });
            });
    }

    private drawPupilDiameterLine(round: number, frame: IPupil20TestCamMessage): void {
        const startTimestamp = this.data[round][0].timestamp / 10000;
        
        if (frame.measurementOD) {
            this.realTimeLineOD.push({ x: this.chartService.getXvalue(startTimestamp, frame.timestamp), 
                y: frame.measurementOD });

            if (this.realTimeLineOD.length === this.REAL_TIME_DISPLAY_RANGE) {
                this.pupilDiameterChart
                    .append('path')
                    .attr('class', `Round${round} lineOD`)
                    .attr('fill', 'none')
                    .attr('stroke', LINE_COLOR.OD)
                    .attr('stroke-width', '0.5px')
                    .datum(this.realTimeLineOD)
                    .attr('d', this.lineGenerator);

                this.realTimeLineOD = [];
            }
        }
        if (frame.measurementOS) {
            this.realTimeLineOS.push({ x: this.chartService.getXvalue(startTimestamp, frame.timestamp), 
                y: frame.measurementOS });
        
            if (this.realTimeLineOS.length === this.REAL_TIME_DISPLAY_RANGE) {
                this.pupilDiameterChart
                    .append('path')
                    .attr('class', `Round${round} lineOS`)
                    .attr('fill', 'none')
                    .attr('stroke', LINE_COLOR.OS)
                    .attr('stroke-width', '0.5px')
                    .datum(this.realTimeLineOS)
                    .attr('d', this.lineGenerator);
                
                this.realTimeLineOS = [];
            }
        }
    }

    private fillPupilDiameterLines(round: number): void {
        const startTimestamp = this.data[round][0].timestamp / 10000;

        this.data[round]
            .filter(f => f.measurementOD !== undefined && f.measurementOS !== undefined)
            .forEach(frame => {
                this.movingLineOD.push({ x: this.chartService.getXvalue(startTimestamp, frame.timestamp), y: frame.measurementOD });
                this.movingLineOS.push({ x: this.chartService.getXvalue(startTimestamp, frame.timestamp), y: frame.measurementOS });
            });

        this.pupilDiameterChart
            ?.select(`.Round${round}.avgLineOD`)
            .datum(this.movingLineOD)
            .attr('d', this.lineGenerator);
        this.pupilDiameterChart
            ?.select(`.Round${round}.avgLineOS`)
            .datum(this.movingLineOS)
            .attr('d', this.lineGenerator);
    }

    private interpolateGap(data: IPupil20TestCamMessage[], round: number) {
        let firstZeroIndex: number, lastZeroIndex: number, isZeroFound: boolean;
        const cutStart: number = Number.parseInt('20');
        const cutEnd: number = Number.parseInt('50');

        data.forEach((forOD, indexForOD) => {
            if (forOD.measurementOD > 0 && !isZeroFound && !forOD.measurementODwithoutZeros) {
                forOD.measurementODwithoutZeros = forOD.measurementOD;
            } else if (forOD.measurementOD > 0 && isZeroFound) {
                lastZeroIndex = indexForOD;
                isZeroFound = false;
                if (data[firstZeroIndex - cutStart]?.measurementOD) {
                    const startNormalIndex: number = firstZeroIndex - cutStart;
                    const endNormalIndex: number = lastZeroIndex + cutEnd <= data.length - 1 ? lastZeroIndex + cutEnd : data.length - 1;

                    const startNormalODvalue: number = data[startNormalIndex].measurementOD;

                    let endNormalODvalue: number = data[endNormalIndex]?.measurementOD;
                    if (!endNormalODvalue) {
                        const endElement = data.slice(endNormalIndex).find(frame => frame.measurementOD);
                        endNormalODvalue = endElement ? endElement.measurementOD : data[data.length - 1]?.measurementOD;
                    }

                    const valuesDiff: number = endNormalODvalue - startNormalODvalue,
                        stepsWithZeros: number = endNormalIndex - startNormalIndex,
                        oneStepAverage: number = valuesDiff / stepsWithZeros;
                    for (let j = startNormalIndex, k = 0; j < endNormalIndex; j++, k++) {
                        data[j].measurementODwithoutZeros = startNormalODvalue + oneStepAverage * k;
                    }
                }
            } else if (forOD.measurementOD === 0 && !isZeroFound) {
                isZeroFound = true;
                firstZeroIndex = indexForOD;
            }
        });

        firstZeroIndex = undefined;
        lastZeroIndex = undefined;
        isZeroFound = undefined;
        data.forEach((forOS, indexForOS) => {
            if (forOS.measurementOS > 0 && !isZeroFound && !forOS.measurementOSwithoutZeros) {
                forOS.measurementOSwithoutZeros = forOS.measurementOS;
            } else if (forOS.measurementOS > 0 && isZeroFound) {
                lastZeroIndex = indexForOS;
                isZeroFound = false;
                if (data[firstZeroIndex - cutStart]?.measurementOS) {
                    const startNormalIndex: number = firstZeroIndex - cutStart;
                    const endNormalIndex: number = lastZeroIndex + cutEnd <= data.length - 1 ? lastZeroIndex + cutEnd : data.length - 1;

                    const startNormalOSvalue: number = data[startNormalIndex].measurementOS;

                    let endNormalOSvalue: number = data[endNormalIndex]?.measurementOS;
                    if (!endNormalOSvalue) {
                        const endElement = data.slice(endNormalIndex).find(frame => frame.measurementOS);
                        endNormalOSvalue = endElement ? endElement.measurementOS : data[data.length - 1]?.measurementOS;
                    }

                    const valuesDiff: number = endNormalOSvalue - startNormalOSvalue,
                        stepsWithZeros: number = endNormalIndex - startNormalIndex,
                        oneStepAverage: number = valuesDiff / stepsWithZeros;
                    for (let j = startNormalIndex, k = 0; j < endNormalIndex; j++, k++) {
                        data[j].measurementOSwithoutZeros = startNormalOSvalue + oneStepAverage * k;
                    }
                }
            } else if (forOS.measurementOS === 0 && !isZeroFound) {
                isZeroFound = true;
                firstZeroIndex = indexForOS;
            } else if (isZeroFound && indexForOS === data.length - 1) {
                if (data[firstZeroIndex - cutStart]?.measurementOS) {
                    const startNormalIndex: number = firstZeroIndex - cutStart;
                    const endNormalIndex: number = data.length - 1;

                    const startNormalOSvalue: number = data[startNormalIndex].measurementOS;

                    for (let j = startNormalIndex, k = 0; j < endNormalIndex; j++, k++) {
                        data[j].measurementOSwithoutZeros = startNormalOSvalue;
                    }
                }
            }
        });

        return data;
    }
}
