import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { IPtosisBulbicamMeasuredData } from '../../../interfaces/measuredData/BulbiCAM/ptosis-bulbicam.measuredData.interface';
import { CamTest } from '../../haplotest.class';
import { RegisterEditField } from '../../registerEditField.class';
import { MeasuredData } from '../measuredData.model';

export abstract class PtosisBulbicamMeasuredData implements MeasuredData, IPtosisBulbicamMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public haplotests: CamTest[];
    public severity: {
        OD: RegisterEditField<string[]>;
        OS: RegisterEditField<string[]>;
    };
    public palpebralFissureHeight: {
        OD: RegisterEditField<number>;
        OS: RegisterEditField<number>;
    };
    public mro1: {
        OD: RegisterEditField<number>;
        OS: RegisterEditField<number>;
    };
    public mro2: {
        OD: RegisterEditField<number>;
        OS: RegisterEditField<number>;
    };
    public proptosis: {
        OD: RegisterEditField<number>;
        OS: RegisterEditField<number>;
    };
    public chinElevation: RegisterEditField<string>;
    public facialAsymetry: RegisterEditField<string>;

    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.PTOSIS_EVALUATION;
        this.haplotests = [];
    }
    public abstract setModel(model: IPtosisBulbicamMeasuredData): void;
    public getModel(): IPtosisBulbicamMeasuredData {
        let model: IPtosisBulbicamMeasuredData = {
            sourceFileName: this.sourceFileName,
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            haplotests: this.haplotests.map(htm => htm.model),
            severity: {
                OD: this.severity.OD.getModel(),
                OS: this.severity.OS.getModel(),
            },
            palpebralFissureHeight: {
                OD: this.palpebralFissureHeight.OD.getModel(),
                OS: this.palpebralFissureHeight.OS.getModel(),
            },
            mro1: {
                OD: this.mro1.OD.getModel(),
                OS: this.mro1.OS.getModel(),
            },
            mro2: {
                OD: this.mro2.OD.getModel(),
                OS: this.mro2.OS.getModel(),
            },
            proptosis: {
                OD: this.proptosis.OD.getModel(),
                OS: this.proptosis.OS.getModel(),
            },
            chinElevation: this.chinElevation.getModel(),
            facialAsymetry: this.facialAsymetry.getModel(),
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.haplotests?.length === 0 &&
            this.chinElevation.isEmpty() &&
            this.facialAsymetry.isEmpty() &&
            this.mro1.OD.isEmpty() &&
            this.mro1.OS.isEmpty() &&
            this.mro2.OD.isEmpty() &&
            this.mro2.OS.isEmpty() &&
            this.palpebralFissureHeight.OD.isEmpty() &&
            this.palpebralFissureHeight.OS.isEmpty() &&
            this.proptosis.OD.isEmpty() &&
            this.proptosis.OS.isEmpty() &&
            this.severity.OD.isEmpty() &&
            this.severity.OS.isEmpty()
        );
    }
}
