import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROLE } from '../../../../../../commonout/enum/role';
import { AboutComponent, PageNotFoundComponent } from '../../_components';
import { AuthGuard, RoleGuard, RouterGuard } from '../../_guards';

const routes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [AuthGuard, RoleGuard, RouterGuard],
        data: {
            expectedRole: [ROLE.ADMIN, ROLE.DOCTOR, ROLE.STAFF],
        },
    },
    {
        path: 'dashboard-admin',
        loadChildren: '../admin.module/admin.module#AdminModule',
        canLoad: [AuthGuard, RoleGuard],
        data: {
            expectedRole: [ROLE.ADMIN],
        },
    },
    {
        path: 'dashboard-staff',
        loadChildren: '../user.module/user.module#UserModule',
        canLoad: [AuthGuard, RoleGuard],
        data: {
            expectedRole: [ROLE.DOCTOR, ROLE.STAFF],
        },
    },
    {
        path: 'calibration',
        loadChildren: '../calibration.module/calibration.module#CalibrationModule',
        canLoad: [AuthGuard, RoleGuard],
        data: {
            expectedRole: [ROLE.DOCTOR, ROLE.STAFF],
        },
    },
    {
        path: 'communicator',
        loadChildren: '../communication.module/communication.module#CommunicationModule',
        canLoad: [AuthGuard, RoleGuard],
        data: {
            expectedRole: [ROLE.DOCTOR, ROLE.STAFF],
        },
    },

    {
        path: 'about',
        component: AboutComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
            expectedRole: [ROLE.ADMIN, ROLE.DOCTOR, ROLE.STAFF],
        },
    },
    {
        path: 'auth',
        loadChildren: '../auth.module/auth.module#AuthModule',
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
