// @ts-nocheck
// copied from src/assets/css/_colors.scss
let colors = {
    dark: '#033148',
    'alt-dark': '#0a425d',
    'medium-dark': '#053c5a',

    light: '#ffffff',
    'alt-light': '#efefef',
    'medium-light': '#f7f7f7',

    'text-dark': '#abbec5',
    'text-light': '#5c6970',

    'light-blue': '#009abe',
    green: '#5bcf8f',
    red: '#e1584d',
    orange: '#f29c39',

    'disabled-dark': '#999999',
    'disabled-light': '#707070',
    'disabled-medium': '#899ca3',

    placeholder: '#505050',
};
export { colors };
