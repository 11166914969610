import { ICamMessage } from "../../../commonout/interfaces/charts.model";

export interface IPursuitSaccadesCamMessage extends ICamMessage {  
    eyeODx?: number;
    eyeODy?: number;
    eyeOSx?: number;
    eyeOSy?: number;
    eyeOS?: number;
    stimuliODx?: number;
    stimuliODy?: number;
    stimuliOSx?: number;
    stimuliOSy?: number;
    stimuliOS?: number;
    pointX?: number;
    pointY?: number;
    calibrationAngleOS?: number;
    seconds?: number;
    pupilvelocity?: number;
    calibrationGlintData?: {
        calibationGlintData: number;
        pixelDifference: number;
        firstPointYOfTrial: number;
        ratio: number;
    }
    testResults?: IPursuitSaccadeTestResults;
    ratio?: number;
}

export interface IChartData {
    chartType?: string;
    framesData?: IPursuitSaccadesCamMessage[];
    movementFrames?: IPursuitSaccadesCamMessage[];
    separatedFrames?: SeparatedFrames;
    horizontalVelocityFrames?: IPursuitSaccadesCamMessage[];
    verticalVelocityFrames?: IPursuitSaccadesCamMessage[];
    pursuitSaccadesTestResults?: IPursuitSaccadeTestResults;
    verticalFrames?: IPursuitSaccadesCamMessage[];
    verticalResults?: ISaccadeResult[];
    horizontalFrames?: IPursuitSaccadesCamMessage[];
    horizontalResults?: ISaccadeResult[];
}

export interface IPursuitSaccadeTestResults {
    accept?: number;
    errorPatient?: number;
    errorSystem?: number;
    rangeTestResults?: IRangeTestResult[];
    horizontalVelocityFrames?: {
        time?: number;
        pupilVelocity?: number;
    }[];
    verticalVelocityFrames?: {
        time?: number;
        pupilVelocity?: number;
    }[],
    horizontalCalibratedFrames?: {
        calibratedCoor: number,
        time: number
    }[];
    verticalCalibratedFrames?: {
        calibratedCoor: number,
        time: number
    }[]
}

export interface IRangeTestResult {
    id?: number;
    type?: CHART_SUBTYPE;
    angle?: number;
    accept?: number;
    errorPatient?: number;
    errorSystem?: number;
    saccadesTestResults?: ISaccadeResult[];
}

export interface ISaccadeResult {
    type?: RANGE_TYPE;
    latency?: number;
    peakVelocity?: number;
    amplitude? : number;
    result?: SACCADE_RESULT;
}

export enum SACCADE_RESULT {
    ACCEPT = 'accepted',
    ERROR_PATIENT = 'error patient',
    ERROR_SYSTEM = 'error system',
}

export enum CHART_SUBTYPE {
    VERTICAL = 'Vertical',
    HORIZONTAL = 'Horizontal'
}

export enum RANGE_TYPE {
    DOWNWARDS = 'Downwards',
    UPWARDS = 'Upwards',
    LEFT_TO_RIGHT = 'Left to right',
    RIGHT_TO_LEFT = 'Right to left',
    AVERAGE = 'Average'
}

export interface SeparatedFrames { horizontalFrames: IPursuitSaccadesCamMessage[], verticalFrames: IPursuitSaccadesCamMessage[] }
