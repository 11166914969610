// @ts-nocheck
import * as d3 from 'd3';

export function getColor (id: number, max: number) {
    id += 1;
    max += 2;
    let space = d3.interpolateRainbow;

    let colorScale = d3.scaleSequential(space).domain([0, max]);
    return colorScale(id);
}
