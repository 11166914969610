// @ts-nocheck
import * as _ from 'lodash';
import * as d3 from 'd3';
import chroma from 'chroma-js';
import { sliceMaBothSides, doMaBothSides, doMa2, doMa2x2 } from './ma';

import { RunStart, DataPoint, TestStart, TestStop, TestInfo } from './Protocol';
import { USE_FAKE_DATA, EYE_OD, EYE_OS, I_ACUITY, I_CONTRAST, I_FREQ, I_CONTRAST2 } from '../constants';
import { UiState } from './UiState';

// import { Timing } from './timing'

import { getFFT, getSampleRate } from './fft';

import { Brand as B } from '../../../lib';
import { timestamp } from 'rxjs/operators';

const ALOT = 10_000;

let swap = (xs: number[]) => {
    return [xs[1], xs[0]];
};

let getY = (group: any, point: any) => {
    let y = group.yParam(point);
    y = group.scale(y);
    return y;
};

// 0.015 -> 2.0
// 0.9   -> 0.2
// let contrastF
// if (true) {
//   let x1 = 0.9
//   let y1 = 0.2
//   let x2 = 0.015
//   let y2 = 2.0
//   let b = (y2*x1 - y1*x2) / (y1 - y2)
//   let a = (x1 + b) / y1
//   // NOTE: error somewhere, not used anyway
//   contrastF = (x:number) => a * x + b
// }
enum LINE_POINT_TYPE {
    SEEN = 'seen',
    UNSEEN = 'unseen'
}

export interface LinePoint {
    backgroundColorLux: string,
    brightPixelColorLux: string,
    brightBorderColorLux: string,
    darkPixelColorLux: string,
    darkBorderColorLux: string,
    pointType: LINE_POINT_TYPE,
    pixels: number,
    itemMeasured: number,
    hertz: number,
    test: string,
    timestamp: number,
    oculus: string,
} 


export const ACUITY = {
    type: I_ACUITY,
    range: [0, 1],
    rangeOutput: [0, 1],
    viewRange: swap([1, -0.3]), // on the diagram
    arrow: 'logMAR',
    scale: 0 as any,
    yParam: (x: any) => x.logmar,
};
export const CONTRAST = {
    type: I_CONTRAST,
    // range: [0, 1],
    // rangeOutput: [contrastF(0), contrastF(1)]
    // range: [0.015, 0.9],
    // rangeOutput: [contrastF(0.015), contrastF(0.9)]

    // range: [0.015, 0.9],
    // rangeOutput: [2.0, 0.2],
    // viewRange: swap([0.2, 2.0]), // on the diagram

    range: [0.015, 0.9],
    rangeOutput: [0.015, 0.9],
    // viewRange: swap([1, 0]), // on the diagram
    viewRange: swap([1, 0.001]), // on the diagram

    arrow: 'logCon',
    scale: 0 as any,
    yParam: (x: any) => x.logmar,
};
export const CONTRAST2 = {
    type: I_CONTRAST2,
    range: [0.015, 0.9],
    rangeOutput: [0.015, 0.9],
    // viewRange: swap([1, 0]), // on the diagram
    viewRange: swap([1, 0.001]), // on the diagram

    arrow: 'logCon',
    scale: 0 as any,
    yParam: (x: any) => x.logmar,
};
export const PUPIL = {
    viewRange: CONTRAST.viewRange.map((x) => x * 10),
};
export const FREQ = {
    type: I_FREQ,
    range: [1, 10],
    rangeOutput: [1, 10],
    viewRange: swap([1, 10]), // on the diagram
    arrow: 'Hz',
    scale: 0 as any,
    yParam: (x: any) => x.frequency,
};
[ACUITY, CONTRAST, FREQ, CONTRAST2].forEach((x) => {
    x.scale = d3
        .scaleLinear()
        .domain(x.range)
        .range(x.rangeOutput);
});
let getGroup = (x: any) => {
    if (x.type === I_ACUITY) return ACUITY;
    if (x.type === I_CONTRAST) return CONTRAST;
    if (x.type === I_FREQ) return FREQ;
    if (x.type === I_CONTRAST2) return CONTRAST2;
    throw 'unknown type';
};

const MIN_FREQ = 0.1;
const FREQ_MA = 0;

const M = 0.1; // interval margin
const EMPTY_LINE = [
    { x: 0, y: 0, sign: '' },
    { x: 0, y: 0, sign: '' },
];

const USE_NEXT_MA = true; // use the fastest version
const USE_DO_MA = true; // ignore this if the param above is enabled

export class State {
    nextId = 0;
    dataSets: DataSet[] = [];
    minz = 0;
    startTime = 0;
    startTimestamp = 0;
    runLength = 0;
    info: string[] = [];
    showRapdLines = false;
    done = false;

    showFixedContrast = false;

    hover = 'r';

    ma1 = 500;
    ma2 = 500;
    ma3 = 1;

    threshold = 50;
    thresholdDomain = [0, 800];

    ui = new UiState();

    acuityInterval = [0 + M, 40 + ALOT];
    contrastInterval = [40 + M + ALOT, 101 + ALOT];
    contrastInterval2 = [40 + M + ALOT, 101 + ALOT];
    amdInterval = [101 + M + ALOT, 1000 + ALOT];

    testsExist = {
        visualAcuity: false,
        contrastWithoutBleach: false,
        contrastWithBleach: false,
        bleachRecovery: false,
    };

    chosenEyes = {
        OD: false,
        OS: false,
    };

    fill(newDataSets: DataSet[]) {
        this.dataSets = newDataSets;
    }

    onTestStart(x: TestStart) {
        this.testsExist = {
            visualAcuity: false,
            contrastWithoutBleach: false,
            contrastWithBleach: false,
            bleachRecovery: false,
        };

        this.chosenEyes = {
            OD: false,
            OS: false,
        };

        this.startTime = toSeconds(x.timestamp);
        this.startTimestamp = x.timestamp / 10000;
        this.runLength = x.runLength;
    }

    onRunStart(given: RunStart) {
        this.dataSet?.finalize(given);
        this.finalizeIntervals();

        if (this.dataSet !== undefined) {
            const type = this.dataSet.type;
            const eye = this.dataSet.eye;

            if (type === 0) {
                this.testsExist.visualAcuity = true;
            } else if (type === 1) {
                this.testsExist.contrastWithoutBleach = true;
            } else if (type === 2) {
                this.testsExist.contrastWithBleach = true;
            } else if (type === 3) {
                this.testsExist.bleachRecovery = true;
            }

            if (eye === 0) {
                this.chosenEyes.OD = true;
            } else if (eye === 1) {
                this.chosenEyes.OS = true;
            }
        }

        let one = new DataSet(this.nextId, this, toSeconds(given.timestamp), given.eye);
        // nesting...s

        this.nextId += 1;
        this.dataSets.push(one);

        if (!given.training && !this.actualZeroTime) {
            this.actualZeroTime = toSeconds(given.timestamp) - this.startTime;
        }
    }
    actualZeroTime = 0;

    onDataPoint(given: DataPoint) {
        // omg 2<>3
        if (given.type === 3 && !this.showFixedContrast) {
            this.showFixedContrast = true;
        }

        this.dataSet?.addPoint(given);
    }

    onTestStop(given: TestStop) {
        this.dataSet?.finalize(given);
        this.finalize();
        this.done = true;
    }

    onSequenceStop(val) {
        // for (let i = this.dataSet.dataPoints.length - 1; i >= 0; i--) {
        //     this.dataSet.dataPoints[i].seen = val.seen;
        // }

        this.dataSet.dataPoints[this.dataSet.dataPoints.length - 1].seen = val.seen;
    }

    onTestInfo(given: TestInfo) {
        this.info.push(...given.info);
    }

    get dataSet() {
        return _.last(this.dataSets);
    }

    get viewDataSets() {
        return this.dataSets;
    }

    get viewPupilSegments() {
        let odLines = this.dataSets.map((ds) => {
            return [
                { x: ds.avgOdTaken[0], y: ds.avgOd },
                { x: ds.avgOdTaken[1], y: ds.avgOd },
            ];
        });

        let osLines = this.dataSets.map((ds) => {
            return [
                { x: ds.avgOsTaken[0], y: ds.avgOs },
                { x: ds.avgOsTaken[1], y: ds.avgOs },
            ];
        });

        let series: any[] = [];
        let id = 0;
        odLines.forEach((x) => {
            series.push({ id, dataPoints: x, color: 0 });
            id += 1;
        });
        osLines.forEach((x) => {
            series.push({ id, dataPoints: x, color: 1 });
            id += 1;
        });

        return series;
    }

    viewMainLines(which: number | null = null) {
        let parse = (x: DataSet) => {
            let points = x.dataPoints;
            // let first = points[0]
            // let mid = x.maybeLostTrackPoint
            // let last = _.last(points)
            // if (!first || !last || !mid) return null
            // let points1 = [
            //   { x: first.time_test, y: 0 },
            //   { x: last.time_test, y: this.runLength },
            // ]
            let first = x.startPoint as XY;
            let mid = x.finishPoint as XY;
            if (!first || !mid) return null;
            let dx = 0; //-this.actualZeroTime
            let points1 = [first, mid];
            // let points2 = [
            //   { x: mid.time_test+dx, y: mid.time_run },
            //   { x: last.time_test+dx, y: this.runLength },
            // ]
            let color = EYE_COLORS[x.eye];
            let line1 = {
                dataPoints: points1,
                color,
            };
            // let line2 = {
            //   dataPoints: points2,
            //   color: chroma('gray').alpha(0.25),
            // }
            // let lines = [line1, line2]
            let lines = [line1];
            return lines;
        };
        let ds = this.dataSets;
        if (which != null) ds = ds.filter((x) => x.type === which);

        return _.flatten(
            _.compact(
                ds.map((x) => {
                    let lines = parse(x);
                    if (!lines) return null;
                    return lines.map((line, i) => {
                        return {
                            ...line,
                            id: x.id * 2 + i,
                        };
                    });
                })
            )
        );
    }

    viewLostMarks(which: number | null = null) {
        let parse = (x: DataSet) => {
            let color = EYE_COLORS[x.eye];
            // let seen = true;
            let point = x.maybeLostTrackPoint;

            if (!point) return null;

            return {
                dataPoints: [point], //{ x: point.time_test, y: point.time_run }],
                color,
                // seen,
            };
        };

        let ds = this.dataSets;

        if (which != null) ds = ds.filter((x) => x.type === which);

        const lostMarksArray = _.compact(
            ds.map((x) => {
                let got = parse(x);
                if (!got) return null;

                return {
                    ...got,
                    id: x.id,
                };
            })
        );

        return lostMarksArray;
    }

    applyMaOverPD() {
        // return ['pod', 'pos'].map((field,i) => {
        // })

        let dataPoints: DataPoint[] = [];

        this.dataSets.forEach((x) => {
            x.dataPoints.forEach((p) => {
                dataPoints.push(p);
            });
        });

        // dataPoints.forEach(x => {
        //   x.mapod = x.pod
        //   x.mapos = x.pos
        // });return // XXX

        if (USE_NEXT_MA) {
            doMa2x2(dataPoints, 50, 'pod', 'mapod', 'pos', 'mapos');
        } else {
            if (USE_DO_MA) {
                doMaBothSides(dataPoints, 50, (p, xs) => {
                    p.mapod = avg(xs.filter((x) => x.pod).map((x) => x.pod));
                    p.mapos = avg(xs.filter((x) => x.pos).map((x) => x.pos));
                });
            } else {
                let slices = sliceMaBothSides(dataPoints, 50);
                slices.forEach((xs, i) => {
                    let p = dataPoints[i];
                    p.mapod = avg(xs.filter((x) => x.pod).map((x) => x.pod));
                    p.mapos = avg(xs.filter((x) => x.pos).map((x) => x.pos));
                });
            }
        }
    }

    get viewPupilLines() {
        return ['mapod', 'mapos'].map((field, i) => {
            let dataPoints: XY[] = [];
            this.dataSets.forEach((x) => {
                x.dataPoints.forEach((p) => {
                    let value = p[field] || 0;

                    if (value == 0) return;
                    dataPoints.push({
                        x: p.time_test,
                        y: p[field] > 1.5 ? p[field] : 1.5,
                    });
                });
            });
            // let slices = sliceMaBothSides(dataPoints, 50)

            let dataPointsMa = dataPoints;
            // let dataPointsMa = slices.map((xs,i) => {
            //   let p = dataPoints[i]
            //   return {
            //     ...p,
            //     y: avg(xs.map(x => x.y))
            //   }
            // })
            return {
                id: i,
                dataPoints: dataPointsMa,
            };
        });
    }

    recomputeLast() {
        let ds = _.last(this.dataSets);
        if (!ds) return;
        // ds.applyMAs(this.ma1, this.ma2, this.ma3)
        ds.updateSeen();
    }
    // NOTE: duplicates

    recompute() {
        // this.dataSets.forEach(x => {
        //   x.applyMAs(this.ma1, this.ma2, this.ma3)
        // })
        this.recomputeSeen();
    }

    recomputeSeen() {
        this.dataSets.forEach((x) => {
            x.updateSeen();
        });
    }

    state = this;

    finalize() {
        // let acuity = _.take(this.dataSets, 4)
        // let contrast = _.take(this.dataSets, 6)
        // let amd = _.take(this.dataSets, 6)
        let lostMarks = this.state.viewLostMarks(null);

        let ods = lostMarks.filter((x) => x.color === B.green).map((d) => d.dataPoints[0]);
        let oss = lostMarks.filter((x) => x.color === B.orange).map((d) => d.dataPoints[0]);
        [
            { points: ods, id: 0 },
            { points: oss, id: 1 },
        ].forEach(({ points, id }, i) => {
            let acuity = points.filter((x: any) => x.type === I_ACUITY); //points.filter(withinX(this.state.acuityInterval))
            let contrast = points.filter((x: any) => x.type === I_CONTRAST); //withinX(this.state.contrastInterval))
            let amd = points.filter((x: any) => x.type === I_FREQ); // withinX(this.state.amdInterval))
            let contrast2 = points.filter((x: any) => x.type === I_CONTRAST2);
            [acuity, contrast, amd, contrast2].forEach((linePoints, j) => {
                if (j === 2) {
                    const { y, minX, maxX, sign } = this.calculateContrastStrobeResult(linePoints);
                    let aLine = [
                        { x: minX, y, sign },
                        { x: maxX, y, sign },
                    ];
                }
                else {
                    const { y, minX, maxX, sign } = this.calculateResult(linePoints);

                    let aLine = [
                        { x: minX, y, sign },
                        { x: maxX, y, sign },
                    ];
                }

                let key2 = ['od', 'os'][i];
                let key1 = ['acuity', 'contrast', 'amd', 'contrast2'][j];
                
                if (ods.length === 0 && i === 0) {
                    const notTestedResults = aLine.map(el => ({ x: NaN, y: NaN, sign: '' }));
                    const notTestedExportResults = 'N/A';
                    this.results[key1][key2] = notTestedResults;
                    this.exportResults[key1][key2] = notTestedExportResults;
                }
                else if (oss.length === 0 && i === 1) {
                    const notTestedResults = aLine.map(el => ({ x: NaN, y: NaN, sign: '' }));
                    const notTestedExportResults = 'N/A';
                    this.results[key1][key2] = notTestedResults;
                    this.exportResults[key1][key2] = notTestedExportResults;
                }
                else {
                    let exportALine: string = '';
                    if (j === 2) {
                        exportALine = aLine[0].y !== null 
                            ? aLine[0].sign + aLine[0].y.toFixed(2).padStart(5, '0') 
                            : aLine[0].sign;
                    }
                    else {
                        exportALine = aLine[0].y !== null ? aLine[0].sign + aLine[0].y.toFixed(3) : aLine[0].sign;
                    }
                    this.results[key1][key2] = aLine;
                    this.exportResults[key1][key2] = exportALine;
                    const exportLinePoints = linePoints.map(p => ({
                        backgroundColorLux: p.BGColorLux && p.BGColorLux !== 0 
                            ? p.BGColorLux.toFixed(3) 
                            : 'no data',
                        brightPixelColorLux: p.brightPixelColorLux && p.brightPixelColorLux !== 0 
                            ? p.brightPixelColorLux.toFixed(3) 
                            : 'no data',
                        brightBorderColorLux: p.brightRimColorLux && p.brightRimColorLux !== 0 
                            ? p.brightRimColorLux.toFixed(3) 
                            : 'no data',
                        darkPixelColorLux: p.darkPixelColorLux && p.darkPixelColorLux !== 0 
                            ? p.darkPixelColorLux.toFixed(3) 
                            : 'no data',
                        darkBorderColorLux: p.darkRimColorLux && p.darkRimColorLux !== 0 
                            ? p.darkRimColorLux.toFixed(3) 
                            : 'no data',
                        pointType: p.seen === true ? LINE_POINT_TYPE.SEEN : LINE_POINT_TYPE.UNSEEN,
                        pixels: p.targetSize,
                        itemMeasured: p.y,
                        hertz: p.frequency,
                        test: this.getTestType(p.type),
                        timestamp: this.getMiliseconds(this.startTimestamp, p.timestamp),
                        oculus: p.eye === 0 ? 'OD' : 'OS',
                    }) as LinePoint)
                    this.exportResults.linePoints = this.exportResults.linePoints?.concat(exportLinePoints);
                }
            });
        });

        this.finalizeRapd();
        this.applyMaOverPD();
        // this.finalizeFreq()
        this.finalizeIntervals();
    }

    private calculateResult(linePoints: (DataPoint & XY)[]): { y: number, minX: number, maxX: number, sign: string } {
        const seenPoints = linePoints.filter((el) => el.seen === true);
                   
        const bestValueY = _.min(linePoints.map((x) => x.y));
        const bestPoints = seenPoints.filter(f => f.y === bestValueY);
        const countBestSeens = bestPoints.length;

        if (countBestSeens >= 4) {
            let y = bestValueY;
            let minX = _.min(bestPoints.map((x) => x.x)) || 0;
            let maxX = _.max(bestPoints.map((x) => x.x)) || 0;
            let sign = '<=';

            return { y, minX, maxX, sign };
        }

        // rule 2
        else if (seenPoints.length >= 3) {
            const sortedLinePoints = seenPoints.sort((a, b) => a.y - b.y).slice(0, 3);
            let y = d3.mean(sortedLinePoints.map((x) => x.y)) || 0;
            let minX = _.min(sortedLinePoints.map((x) => x.x)) || 0;
            let maxX = _.max(sortedLinePoints.map((x) => x.x)) || 0;
            let sign = '';

            return { y, minX, maxX, sign };
        }

        // rule 3
        else if (seenPoints.length === 2) {
            const worstValueY = _.max(linePoints.map((x) => x.y));
            let y = worstValueY;
            let minX = _.min(seenPoints.map((x) => x.x)) || 0;
            let maxX = _.max(seenPoints.map((x) => x.x)) || 0;
            let sign = '>=';

            return { y, minX, maxX, sign };
        }

        // rule 4
        else {
            let y = null;
            let minX = _.min(seenPoints.map((x) => x.x)) || 0;
            let maxX = _.max(seenPoints.map((x) => x.x)) || 0;
            let sign = 'N/A';

            return { y, minX, maxX, sign };
        }
    }

    private calculateContrastStrobeResult(linePoints: (DataPoint & XY)[])
    : { y: number, minX: number, maxX: number, sign: string } {
        const seenPoints = linePoints.filter((el) => el.seen === true);
        const bestValueY = _.max(linePoints.map((x) => x.y));
        const bestPoints = seenPoints.filter(f => f.y === bestValueY);
        const countBestSeens = bestPoints.length;

        if (countBestSeens >= 4) {
            let y = bestValueY;
            let minX = _.min(bestPoints.map((x) => x.x)) || 0;
            let maxX = _.max(bestPoints.map((x) => x.x)) || 0;
            let sign = '>=';

            return { y, minX, maxX, sign };
        }

        // rule 2
        else if (seenPoints.length >= 3) {
            const sortedLinePoints = seenPoints.sort((a, b) => b.y - a.y).slice(0, 3);
            let y = d3.mean(sortedLinePoints.map((x) => x.y)) || 0;
            let minX = _.min(sortedLinePoints.map((x) => x.x)) || 0;
            let maxX = _.max(sortedLinePoints.map((x) => x.x)) || 0;
            let sign = '';

            return { y, minX, maxX, sign };
        }

        // rule 3
        else if (seenPoints.length === 2) {
            const worstValueY = _.min(linePoints.map((x) => x.y));
            let y = worstValueY;
            let minX = _.min(seenPoints.map((x) => x.x)) || 0;
            let maxX = _.max(seenPoints.map((x) => x.x)) || 0;
            let sign = '<=';

            return { y, minX, maxX, sign };
        }

        // rule 4
        else {
            let y = null;
            let minX = _.min(seenPoints.map((x) => x.x)) || 0;
            let maxX = _.max(seenPoints.map((x) => x.x)) || 0;
            let sign = 'N/A';

            return { y, minX, maxX, sign };
        }
    }

    finalizeIntervals() {
        let ds = this.dataSets;
        let acuity = ds.filter((x) => x.type === I_ACUITY);
        let contrast = ds.filter((x) => x.type === I_CONTRAST);
        let amd = ds.filter((x) => x.type === I_FREQ);
        let contrast2 = ds.filter((x) => x.type === I_CONTRAST2);

        let getInterval = (xs: DataSet[]) => {
            let points = _.flatten(xs.map((x) => x.dataPoints));
            let min = _.minBy(points, (x) => x.time_test);
            let max = _.maxBy(points, (x) => x.time_test);
            if (!min || !max) return [0, 0];
            return [min.time_test, max.time_test];
        };
        this.acuityInterval = getInterval(acuity);
        this.contrastInterval = getInterval(contrast);
        this.amdInterval = getInterval(amd);
        this.contrastInterval2 = getInterval(contrast2);
    }

    results = {
        acuity: {
            type: I_ACUITY,
            od: EMPTY_LINE,
            os: EMPTY_LINE,
        },
        contrast: {
            type: I_CONTRAST,
            od: EMPTY_LINE,
            os: EMPTY_LINE,
        },
        contrast2: {
            type: I_CONTRAST2,
            od: EMPTY_LINE,
            os: EMPTY_LINE,
        },
        amd: {
            type: I_FREQ,
            od: EMPTY_LINE,
            os: EMPTY_LINE,
        },
        rapd: {
            value: 0,
            dysf: 0,
            table: [] as RapdInfo[],
            rapdLine: [
                { x: 0, y: 0 },
                { x: 0, y: 0 },
                { x: 0, y: 0 },
            ],
        },
    };

    exportResults = {
        acuity: {
            type: I_ACUITY,
            od: '',
            os: '',
        },
        contrast: {
            type: I_CONTRAST,
            od: '',
            os: '',
        },
        contrast2: {
            type: I_CONTRAST2,
            od: '',
            os: '',
        },
        amd: {
            type: I_FREQ,
            od: '',
            os: '',
        },
        rapd: {
            value: 0,
            dysf: 0,
            table: [] as RapdInfo[],
            rapdLine: [
                { x: 0, y: 0 },
                { x: 0, y: 0 },
                { x: 0, y: 0 },
            ],
        },
        linePoints: [] as LinePoint[]
    };

    get acOd() {
        return this.results.acuity.od;
    }
    get acOs() {
        return this.results.acuity.os;
    }
    get coOd() {
        return this.results.contrast.od;
    }
    get coOs() {
        return this.results.contrast.os;
    }
    get amOd() {
        return this.results.amd.od;
    }
    get amOs() {
        return this.results.amd.os;
    }
    get co2Od() {
        return this.results.contrast2.od;
    }
    get co2Os() {
        return this.results.contrast2.os;
    }

    get exportAcOd() {
        return this.exportResults.acuity.od;
    }
    get exportAcOs() {
        return this.exportResults.acuity.os;
    }
    get exportCoOd() {
        return this.exportResults.contrast.od;
    }
    get exportCoOs() {
        return this.exportResults.contrast.os;
    }
    get exportAmOd() {
        return this.exportResults.amd.od;
    }
    get exportAmOs() {
        return this.exportResults.amd.os;
    }
    get exportCo2Od() {
        return this.exportResults.contrast2.od;
    }
    get exportCo2Os() {
        return this.exportResults.contrast2.os;
    }
    get exportLinePoints(): LinePoint[] {
        return this.exportResults.linePoints;
    }

    freqPointsOD: XY[] = [];
    freqPointsOS: XY[] = [];

    finalizeFreq() {
        let relevantParts = _.drop(this.dataSets, 4 + 6);

        // relevantParts = _.drop(relevantParts, 3)
        // relevantParts = _.take(relevantParts, 1)

        // let ods = relevantParts.filter(x => x.eye === EYE_OD && x.avgBoth > 0)
        let dataPoints = _.flatten(relevantParts.map((x) => x.dataPoints));
        // console.log(dataPoints.length)
        // dataPoints = _.drop(dataPoints, 5000)
        // dataPoints = _.take(dataPoints, 2048*2)
        let times = dataPoints.map((x) => x.time_test);
        let sampleRate = getSampleRate(times);

        let pushPoints = (from: string, to: string) => {
            let values = dataPoints.map((x) => x[from]);
            let fft = getFFT(values, sampleRate);
            this[to] = [] as XY0[];
            fft.spectrum.forEach((v: number, i: number) => {
                let x = fft.getBandFrequency(i);
                let y = v;
                if (x >= MIN_FREQ) this[to].push({ x, y, x0: x, y0: y });
            });
        };

        pushPoints('pod', 'freqPointsOD');
        pushPoints('pos', 'freqPointsOS');

        const RANGE = [1.9, 2.1]; // the peak is not precisely positioned

        if (true) {
            let source = this.freqPointsOD;

            let stuff = source.filter((x) => x.x > RANGE[0] && x.x < RANGE[1]);
            let peaky = _.maxBy(stuff, (x) => x.y) || { x: 0, y: 0 };

            this.freqPeakOD = peaky;
        }
        if (true) {
            let source = this.freqPointsOS;

            let stuff = source.filter((x) => x.x > RANGE[0] && x.x < RANGE[1]);
            let peaky = _.maxBy(stuff, (x) => x.y) || { x: 0, y: 0 };

            this.freqPeakOS = peaky;
        }

        let wing = FREQ_MA;
        if (wing) {
            doMa2(this.freqPointsOD, wing, 'y0', 'y');
            doMa2(this.freqPointsOS, wing, 'y0', 'y');
        }

        // console.log(fft)
        // _.times(100, i => {
        //   console.log(fft.getBandFrequency(i).toFixed(2), fft.spectrum[i].toFixed(1))
        // })
        // let line = []
    }
    freqPeakOD = { x: 0, y: 0 };
    freqPeakOS = { x: 0, y: 0 };

    finalizeRapd() {
        let rapd1;
        let rapd2;

        if (true) {
            // let relevantParts = _.drop(this.dataSets, 4)
            // relevantParts = _.take(relevantParts, 6)
            let relevantParts = this.dataSets.filter((x) => x.type === I_CONTRAST);
            rapd1 = this.getRapd(relevantParts);
        }
        // if (true) {
        //   let relevantParts = _.drop(this.dataSets, 4+6)
        //   rapd2 = this.getRapd(relevantParts)
        // }

        // if (rapd1.value > rapd2.value) {
        this.results.rapd = rapd1;
        // } else {
        //   this.results.rapd = rapd2
        // }
    }

    getRapd(relevantParts: DataSet[]) {
        let result: { value: number; dysf: number; rapdLine: XY[]; table: RapdTable } = {
            value: 0,
            dysf: 0,
            rapdLine: [],
            table: [],
        };

        let pairs: { a: DataSet; b: DataSet; rapd?: number }[] = [];
        let part: any;
        relevantParts.forEach((x, i) => {
            if (i % 2 === 0) {
                part = { a: x };
            } else {
                pairs.push({ ...part, b: x });
            }
        });

        let table: RapdTable = [];

        pairs.forEach((x) => {
            let { a, b } = x;

            let i = a.id;
            let textA = Math.floor(i / 2) + 0 + '';
            let ii = b.id;
            let textB = Math.floor(ii / 2) + 0 + '';

            let rapd = Math.abs(a.avgBoth - b.avgBoth);

            let diffA = a.avgOd - a.avgOs;
            let diffB = b.avgOd - b.avgOs;

            let dysf = diffA - diffB;

            x.rapd = rapd;

            if (diffA && diffB && a.avgOd && a.avgOs && b.avgOd && b.avgOs) {
                table.push({
                    header: `${textA}/${textB}`,
                    rapd,
                    dysf,
                });
            }
        });

        result.table = table;
        result.value = Math.max(...table.map((x) => x.rapd)) || 0;
        result.dysf = Math.max(...table.map((x) => x.dysf)) || 0;

        let rapdPair = pairs.find((x) => x.rapd === result.value);
        if (rapdPair) {
            let { a, b } = rapdPair;
            let p1 = a as any;
            let p2 = b as any;
            result.rapdLine = [
                { x: p1.endTime - this.startTime, y: p1.avgBoth },
                { x: p2.endTime - this.startTime, y: p1.avgBoth },
                { x: p2.endTime - this.startTime, y: p2.avgBoth },
            ];
        }

        return result;

        // let ods = relevantParts.filter(x => x.eye === EYE_OD && x.avgBoth > 0)
        // let oss = relevantParts.filter(x => x.eye === EYE_OS && x.avgBoth > 0)
        // let minOd = _.minBy(ods, x => x.avgBoth)
        // let minOs = _.minBy(oss, x => x.avgBoth)
        // let maxOd = _.maxBy(ods, x => x.avgBoth)
        // let maxOs = _.maxBy(oss, x => x.avgBoth)
        // if (!minOd || !minOs || !maxOd || !maxOs) return result

        // let diff1 = Math.abs(minOd.avgBoth - maxOs.avgBoth)
        // let diff2 = Math.abs(minOs.avgBoth - maxOd.avgBoth)

        // let p1
        // let p2
        // if (diff1 > diff2) {
        //   result.value = diff1
        //   p1 = minOd
        //   p2 = maxOs
        // } else {
        //   result.value = diff2
        //   p1 = minOs
        //   p2 = maxOd
        // }
        // result.rapdLine = [
        //   { x: p1.endTime - this.startTime, y: p1.avgBoth },
        //   { x: p2.endTime - this.startTime, y: p1.avgBoth },
        //   { x: p2.endTime - this.startTime, y: p2.avgBoth },
        // ]
        // return result
    }

    getRapdOld(relevantParts: DataSet[]) {
        let result: { value: number; rapdLine: XY[] } = {
            value: 0,
            rapdLine: [],
        };

        let ods = relevantParts.filter((x) => x.eye === EYE_OD && x.avgBoth > 0);
        let oss = relevantParts.filter((x) => x.eye === EYE_OS && x.avgBoth > 0);
        let minOd = _.minBy(ods, (x) => x.avgBoth);
        let minOs = _.minBy(oss, (x) => x.avgBoth);
        let maxOd = _.maxBy(ods, (x) => x.avgBoth);
        let maxOs = _.maxBy(oss, (x) => x.avgBoth);
        if (!minOd || !minOs || !maxOd || !maxOs) return result;

        let diff1 = Math.abs(minOd.avgBoth - maxOs.avgBoth);
        let diff2 = Math.abs(minOs.avgBoth - maxOd.avgBoth);

        let p1;
        let p2;
        if (diff1 > diff2) {
            result.value = diff1;
            p1 = minOd;
            p2 = maxOs;
        } else {
            result.value = diff2;
            p1 = minOs;
            p2 = maxOd;
        }
        result.rapdLine = [
            { x: p1.endTime - this.startTime, y: p1.avgBoth },
            { x: p2.endTime - this.startTime, y: p1.avgBoth },
            { x: p2.endTime - this.startTime, y: p2.avgBoth },
        ];
        return result;
    }

    private getTestType(type: number): string {
        switch(type) {
            case 0:
                return 'acolapt_visual_acuity';
            case 1: 
                return 'acolapt_var_contrast_no_strobe';
            case 2: 
                return 'acolapt_var_contrast_fix_strobe';
            case 3:
                return 'acolapt_fix_contrast_var_strobe';
            default:
                return '';    
        }
    }

    public getMiliseconds(startTs: number, currentRawTs: number): number {
        const currentTs: number = currentRawTs / 10000;
        return Math.abs((currentTs - startTs));
    }
}

interface ParentContext {
    startTime: number;
    threshold: number;
    recomputeLast: () => void;
}

abstract class BaseDataSet<D extends { type: number }> {
    constructor(public id: number, public parent: ParentContext, public startTime: number, public eye: number) {}
    endTime?: number;

    dataPoints: D[] = [];

    abstract preprocessPoint(d: D): void;

    type?: number;

    addPoint(x: D) {
        x = { ...x };
        this.preprocessPoint(x);
        this.dataPoints.push(x);

        if (this.type == null) this.type = x.type;
    }
}

export class DataSet extends BaseDataSet<DataPoint> {
    show = true;
    lostTrackPoint?: DataPoint;

    // get maybeLostTrackPoint() {
    //   return this.lostTrackPoint || this.dataPoints[0]
    // }
    get maybeLostTrackPoint() {
        return this.finishPoint;
    }

    get startRelTest() {
        return this.startTime - this.parent.startTime;
    }
    get endRelTest() {
        if (!this.endTime) return;
        return this.endTime - this.parent.startTime;
    }

    finalize(point: { timestamp: number }) {
        this.endTime = toSeconds(point.timestamp);
        this.parent.recomputeLast();
        this.calcPD();
        // this.updateSeen()
        this.finalizeStartEnd();
    }

    startPoint?: DataPoint & XY;
    finishPoint?: DataPoint & XY;
    finalizeStartEnd() {
        let firstPoint = this.dataPoints[0];
        let lastPoint = _.last(this.dataPoints);

        if (firstPoint) {
            let point = firstPoint;
            let group = getGroup(point);
            let y = getY(group, point);
            let x = point.time_test;
            this.startPoint = { ...point, x, y };
        }
        if (lastPoint) {
            let point = lastPoint;
            let group = getGroup(point);
            let y = getY(group, point);
            if (point.type === 3) {
                y = y;
            }
            let x = point.time_test;
            this.finishPoint = { ...point, x, y };
        }
    }

    avgOd = 0;
    avgOs = 0;
    avgBoth = 0;

    avgOsTaken: number[] = [0, 0];
    avgOdTaken: number[] = [0, 0];

    calcPD() {
        const SLICE_LEN = 200;
        const TAKE_FROM_THE_END = 1 / 2;

        let endTime = this.endTime;
        if (!endTime) return; // impossible

        let deltaTime = endTime - this.startTime;
        let pdFromTime = endTime - this.parent.startTime - deltaTime * TAKE_FROM_THE_END;

        let relevantPoints = this.dataPoints.filter((x) => x.time_test >= pdFromTime);

        // console.log(relevantPoints);

        // NOTE: can have another algorithm to take more than 200 points also
        //
        let slicesCount = Math.floor(relevantPoints.length / SLICE_LEN);
        let usedPoints: DataPoint[] = [];
        this.avgOd = 0;
        this.avgOs = 0;
        for (let i = 0; i <= slicesCount - 1; i++) {
            let from = i * SLICE_LEN;
            let to = (i + 1) * SLICE_LEN;
            let points = relevantPoints.slice(from, to);
            if (points.length === SLICE_LEN) {
                if (this.avgOd === 0) {
                    let key = 'pod';
                    let values = points.map((x) => x[key]);
                    let blinks = values.find((x) => x === 0); // NOTE: roughly, there can be decreaced diameter around blinks
                    if (!blinks && minMaxFit(values)) {
                        this.avgOd = d3.mean(values) || 0;
                        this.avgOdTaken = [points[0].time_test, points[points.length - 1].time_test];
                    }
                }
                if (this.avgOs === 0) {
                    let key = 'pos';
                    let values = points.map((x) => x[key]);
                    let blinks = values.find((x) => x === 0); // NOTE: roughly, there can be decreaced diameter around blinks
                    if (!blinks && minMaxFit(values)) {
                        this.avgOs = d3.mean(values) || 0;
                        this.avgOsTaken = [points[0].time_test, points[points.length - 1].time_test];
                    }
                }
            }
        }

        function minMaxFit(values: number[]) {
            let min = _.minBy(values) || 0;
            let max = _.maxBy(values) || 0;
            return ((max - min) / max) * 100 <= 8;
        }

        this.avgBoth = (this.avgOd + this.avgOs) / 2;

        if (!this.avgOd || !this.avgOs) this.avgBoth = 0; // so that it is ignored for rapd (no data for one eye wtf)
    }

    calcPDOld() {
        let endTime = this.endTime;
        if (!endTime) return; // impossible

        let deltaTime = endTime - this.startTime;
        let pdFromTime = endTime - this.parent.startTime - deltaTime / 4;

        let relevantPoints = this.dataPoints.filter((x) => x.time_test > pdFromTime);
        this.avgOd = d3.mean(relevantPoints.filter((x) => x.pod > 0).map((x) => x.pod)) || 0;
        this.avgOs = d3.mean(relevantPoints.filter((x) => x.pos > 0).map((x) => x.pos)) || 0;
        this.avgBoth = (this.avgOd + this.avgOs) / 2;

        if (!this.avgOd || !this.avgOs) this.avgBoth = 0; // so that it is ignored for rapd (no data for one eye wtf)
    }

    updateSeen() {
        this.dataPoints.forEach((x) => this.updateStateOfPoint(x));

        let threshold = this.parent.threshold;

        let lostPoint: DataPoint | undefined;

        let points = [...this.dataPoints];

        points.reverse();

        for (let x of points) {
            let value = x.vv3;
            if (value < threshold) {
                lostPoint = x;
                break;
            }
        }

        this.lostTrackPoint = lostPoint;
    }

    preprocessPoint(x: DataPoint) {
        x.time_run = toSeconds(x.timestamp) - this.startTime;
        x.time_test = toSeconds(x.timestamp) - this.parent.startTime;
        this.minimalSetupForPoint(x);
        if (USE_FAKE_DATA) return;
    }

    minimalSetupForPoint(x: DataPoint) {
        // x.vv3 = x.vv1 * x.vv2
        this.updateStateOfPoint(x);
    }
    updateStateOfPoint(x: DataPoint) {
        x.seenPoint = {
            x: x.time_test,
            y: x.vv3,
        };
    }

    mapPoints<T>(f: (x: DataPoint) => T): IDataSet<T> {
        return {
            id: this.id,
            dataPoints: this.dataPoints.map((x) => f(x)),
        };
    }


    get viewv1() {
        return this.mapPoints(v1);
    }
    get viewv2() {
        return this.mapPoints(v2);
    }
    get viewv3() {
        return this.mapPoints(v3);
    }

    // applyMAs(ma1: number, ma2: number, ma3: number) {
    //   // let tail1: DataPoint[] = []
    //   // let tail2: DataPoint[] = []
    //   // let tail3: DataPoint[] = []
    //   // this.dataPoints.forEach(x => {
    //   //   tail1.push(x)
    //   //   tail2.push(x)
    //   //   tail3.push(x)
    //   //   if (tail1.length > ma1) tail1.shift()
    //   //   if (tail2.length > ma2) tail2.shift()
    //   //   if (tail3.length > ma3) tail3.shift()

    //   //   let vv1 = avg(tail1.map(x => x.v1))
    //   //   let vv2 = avg(tail2.map(x => x.v2))
    //   //   x.v3 = vv1 * vv2
    //   //   let vv3 = avg(tail3.map(x => x.v3))

    //   //   x.vv1 = vv1
    //   //   x.vv2 = vv2
    //   //   x.vv3 = vv3
    //   // })

    //   // let timing = new Timing('MA:v1')
    //   if (true) {
    //     let wing = Math.floor(ma1 / 2)
    //     if (USE_NEXT_MA) {
    //       doMa2(this.dataPoints, wing, 'v1', 'vv1')
    //     } else {
    //       if (USE_DO_MA) {
    //         doMaBothSides(this.dataPoints, wing, (point, xs) => {
    //           point.vv1 = avg(xs.map(x => x.v1))
    //           // point.vv2 = avg(xs.map(x => x.v2))
    //         })
    //       } else {
    //         let slices = sliceMaBothSides(this.dataPoints, wing)
    //         slices.forEach((xs, i) => {
    //           let point = this.dataPoints[i]
    //           point.vv1 = avg(xs.map(x => x.v1))
    //           // point.vv2 = avg(xs.map(x => x.v2))
    //         })
    //       }
    //     }
    //   }
    //   // timing.stop()

    //   if (true) {
    //     let wing = Math.floor(ma2 / 2)
    //     if (USE_NEXT_MA) {
    //       doMa2(this.dataPoints, wing, 'v2', 'vv2')
    //     } else {
    //       if (USE_DO_MA) {
    //         doMaBothSides(this.dataPoints, wing, (point, xs) => {
    //           point.vv2 = avg(xs.map(x => x.v2))
    //         })
    //       } else {
    //         let slices = sliceMaBothSides(this.dataPoints, wing)
    //         slices.forEach((xs, i) => {
    //           let point = this.dataPoints[i]
    //           point.vv2 = avg(xs.map(x => x.v2))
    //         })
    //       }
    //     }
    //   }

    //   this.dataPoints.forEach(x => {
    //     x.v3 = x.vv1 * x.vv2
    //     x.vv3 = x.v3
    //   })

    //   // this.dataPoints.forEach(x => x.v3 = x.vv1 * x.vv2)
    //   // if (true) {
    //   //   let wing = Math.floor(ma3 / 2)
    //   //   let slices = sliceMaBothSides(this.dataPoints, wing)
    //   //   slices.forEach((xs, i) => {
    //   //     let point = this.dataPoints[i]
    //   //     point.vv3 = avg(xs.map(x => x.v3))
    //   //   })
    //   // }
    // }
}

function avg(xs: number[]) {
    let avg = d3.mean(xs) || 0;
    return avg;
}

function v1(x: DataPoint) {
    return { y: x.vv1, x: x.time_test };
}
function v2(x: DataPoint) {
    return { y: x.vv2, x: x.time_test };
}
function v3(x: DataPoint) {
    return { y: x.vv3, x: x.time_test };
}

interface IDataSet<DP> {
    id: number;
    dataPoints: DP[];
}

// timestamp field that I receive from the cam app
//
function toSeconds(timestamp: number) {
    return timestamp / 10_000_000;
}

const EYE_COLORS = [B.green, B.orange];

interface XY {
    x: number;
    y: number;
}

function withinX(interval: number[]) {
    return (d: { x: number }) => {
        return d.x >= interval[0] && d.x < interval[1];
    };
}

interface XY0 {
    x: number;
    y: number;

    // for moving averages for example

    x0: number;
    y0: number;
}

interface RapdInfo {
    header: string;
    rapd: number;
    dysf: number;
}
type RapdTable = RapdInfo[];
