import { NystagmusEvaluationBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/nystagmusEvaluationBulbicamMeasuredDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class NystagmusEvaluationBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof NystagmusEvaluationBulbicamMeasuredDataFrontend = NystagmusEvaluationBulbicamMeasuredDataFrontend;
    public measurements: NystagmusEvaluationBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
