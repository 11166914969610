import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CALIBRATION_TEST } from '../../../../../../../commonout/enum/calibration.command.enum';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { ConfigService } from '../../../_services/general/config.service';
import { SocketService } from '../../../_services/general/socket.service';

@Injectable()
export abstract class CalibrationTestComponent implements OnDestroy {
    protected abstract calibrationType: CALIBRATION_TEST;
    protected subscriptions: Subscription[];
    protected bindedMessageHandler: Function;
    constructor(public bulbicamService: BulbicamService, protected socketService: SocketService, protected configService: ConfigService) {
        this.subscriptions = [];
        this.bindedMessageHandler = this.messageHandler.bind(this);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.customOnDestroy();
    }
    protected abstract messageHandler(data: any): Promise<void>;

    saveResults(): void {}

    startTest(): void {}

    submitTest(): void {}

    protected abstract customOnDestroy(): void;
}
