import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { ITonometryMeasuredData } from '../../../../interfaces/measuredData/NIDEK/NT530P/tonometry-nidek-nt530p.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class TonometryMeasuredData implements MeasuredData, ITonometryMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: RegisterEditField<string>;
    public OS: RegisterEditField<string>;
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_NT530P;
        this.testType = TEST_TYPE.TONOMETRY;
    }
    public setModel(model: ITonometryMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.OD.setModel(model.OD);
        this.OS.setModel(model.OS);
    }
    public getModel(): ITonometryMeasuredData {
        let model: ITonometryMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.getModel(),
            OS: this.OS.getModel(),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.isEmpty() && this.OS.isEmpty();
    }
}
