import { AcaNidekNT530PTest } from '../../../../../../../../commonout/classes/tests/NIDEK/NT530P/acaNidekNT530PTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { AcaTestRemarksComponent } from '../../../../_components';
import { AcaTestRemarksFrontend } from '../../../test-remarks/NIDEK/NT530P/acaTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class AcaNidekNt530PTestFrontend extends AcaNidekNT530PTest {
    remarks: AcaTestRemarksFrontend;
    public readonly remarksComponent: typeof AcaTestRemarksComponent = AcaTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new AcaTestRemarksFrontend();
    }
}

export interface AcaNidekNt530PTestFrontend extends TestFrontend {}
applyMixins(AcaNidekNt530PTestFrontend, [TestFrontend]);
