import { IDC_ITEM_TYPE } from '../../enums/idc-item-type.enum';
import { IIDCComment } from '../../interfaces/idc-list-item.interface';
import { IdcListItem } from './IdcListItem.model';

export class IdcCommentItem extends IdcListItem implements IIDCComment {
    info: string;
    comment: string;
    constructor() {
        super();
        this.type = IDC_ITEM_TYPE.COMMENT;
    }
    public get model(): IIDCComment {
        return {
            comment: this.comment,
            creator: this.creator,
            deleted: this.deleted,
            info: this.info,
            testType: this.testType,
            timestamp: this.timestamp,
            type: this.type,
        };
    }
    public set model(model: IIDCComment) {
        this.comment = model.comment;
        this.creator = model.creator;
        this.deleted = model.deleted;
        this.info = model.info;
        this.testType = model.testType;
        this.timestamp = model.timestamp;
    }
}
