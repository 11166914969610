import { IvueOctv3217Image } from '../../../../common/models/iVueOctv3217Image.class';
import { FileService } from '../_services/general/file.service';
import { SmartImageFrontend } from './smartImageFrontend.class';

export class IvueOctv3217ImageFrontend extends IvueOctv3217Image {
    public image: SmartImageFrontend;
    constructor() {
        super();
        this.image = new SmartImageFrontend();
    }
    public async fetchImage(fileService: FileService): Promise<void> {
        try {
            if (this.image.isDownloaded()) return;
            await this.image.download(fileService);
        } catch (error) {
            console.log(error);
        }
    }
}
