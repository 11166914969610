import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { DryEyeForus3NethraClassicMeasuredDataFrontend } from '../../../measuredData/FORUS/3NethraClassic/dryEyeForus3NethraClassicDataFrontend.class';

export class DryEyeForus3NethraClassicTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof DryEyeForus3NethraClassicMeasuredDataFrontend = DryEyeForus3NethraClassicMeasuredDataFrontend;
    public measurements: DryEyeForus3NethraClassicMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
