import { PupillaryEvaluationNidekArk1sTest } from '../../../../../../../../commonout/classes/tests/NIDEK/ARK1S/pupillaryEvaluationNidekArk1sTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PupillaryEvaluationArk1sTestRemarksComponent } from '../../../../_components';
import { PupillaryEvaluationArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/pupillaryEvaluationArk1sTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PupillaryEvaluationNidekArk1sTestFrontend extends PupillaryEvaluationNidekArk1sTest {
    remarks: PupillaryEvaluationArk1sTestRemarksFrontend;
    public readonly remarksComponent: typeof PupillaryEvaluationArk1sTestRemarksComponent = PupillaryEvaluationArk1sTestRemarksComponent;
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    constructor() {
        super();
        this.remarks = new PupillaryEvaluationArk1sTestRemarksFrontend();
    }
}

export interface PupillaryEvaluationNidekArk1sTestFrontend extends TestFrontend {}
applyMixins(PupillaryEvaluationNidekArk1sTestFrontend, [TestFrontend]);
