import { Component, forwardRef, Input, Output, EventEmitter, OnDestroy, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, Subscription, range, of } from 'rxjs';
import { MaculaScanFrontend } from '../../../../_models/maculaScanFrontend';
import { concatMap, delay, map, filter } from 'rxjs/operators';

enum PLAY_BUTTONS {
    PLAY = 'PLAY',
    PAUSE = 'PAUSE',
    STOP = 'STOP',
}

@Component({
    selector: 'image-sequence-player',
    template: require('./image-sequence-player.component.html'),
    styles: [require('./image-sequence-player.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageSequencePlayerComponent),
            multi: true,
        },
    ],
})
export class ImageSequencePlayerComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() private readonly formControlName: string;
    @Output() delete: EventEmitter<string> = new EventEmitter<string>();
    private value: MaculaScanFrontend;
    private activeImage: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private onChange = (_: any) => {};
    private onTouched = () => {};
    private disabled: boolean = false;
    private status: BehaviorSubject<PLAY_BUTTONS> = new BehaviorSubject<PLAY_BUTTONS>(PLAY_BUTTONS.STOP);
    private PLAY_BUTTONS: typeof PLAY_BUTTONS = PLAY_BUTTONS;
    private subscriptions: Subscription[] = [];
    private statusSubscription: Subscription;

    @ViewChild('canvas') private canvas: ElementRef<HTMLCanvasElement>;
    private context: CanvasRenderingContext2D;
    private yPosition: number;
    private requestedAnimationFrameID: number;

    constructor(private ngZone: NgZone) {}
    ngOnInit(): void {
        this.subscriptions.push(
            this.status.subscribe((status) => {
                switch (status) {
                    case PLAY_BUTTONS.PLAY: {
                        this.statusSubscription = range(this.activeImage.value, this.value.imagesSequence.length - 1)
                            .pipe(concatMap((val) => of(val).pipe(delay(50))))
                            .subscribe(
                                (val) => this.activeImage.next(val),
                                null,
                                () => this.status.next(PLAY_BUTTONS.STOP)
                            );
                        break;
                    }
                    case PLAY_BUTTONS.PAUSE: {
                        this.statusSubscription?.unsubscribe();
                        break;
                    }
                    case PLAY_BUTTONS.STOP: {
                        this.statusSubscription?.unsubscribe();
                        this.activeImage.next(0);
                        break;
                    }
                }
            })
        );
        this.ngZone.runOutsideAngular(() => this.animate());
        this.subscriptions.push(
            this.activeImage
                .pipe(
                    filter(() => this.value?.imagesSequence?.length > 0),
                    map((activeImage) => {
                        const percentage: number = (activeImage * 100) / this.value.imagesSequence.length; //,
                        this.yPosition = (this.context.canvas.height * percentage) / 100;
                    })
                )
                .subscribe(() => this.animate())
        );
        this.context = this.canvas.nativeElement.getContext('2d');
        this.context.strokeStyle = 'green';
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.statusSubscription?.unsubscribe();
        cancelAnimationFrame(this.requestedAnimationFrameID);
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    writeValue(data: MaculaScanFrontend): void {
        if (data.imagesSequence.length > 0) {
            this.value = data;
            this.activeImage.next(0);
        }
    }
    private move(val: number) {
        this.status.next(PLAY_BUTTONS.STOP);
        this.activeImage.next(val);
    }
    private animate() {
        if (!this.context) return;
        this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
        this.context.beginPath();
        this.context.moveTo(0, this.yPosition);
        this.context.lineTo(this.context.canvas.width, this.yPosition);
        this.context.stroke();
        this.requestedAnimationFrameID = requestAnimationFrame(() => this.animate.bind(this));
    }
}
