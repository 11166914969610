// @ts-nocheck
export const USE_FAKE_DATA = false
export const PRECISION = 1
export const PRECISION_BR = 0 // break,recovery

let animal = (x: string) => `/frontend/src/assets/images/Animals images/${x}.png`
export const STIMULI_IMAGES = new Map()
STIMULI_IMAGES.set(0, animal('Cow')) // the order from figma - not reliable likely
STIMULI_IMAGES.set(1, animal('Rabbit'))
STIMULI_IMAGES.set(2, animal('Elephant'))
STIMULI_IMAGES.set(3, animal('Panda'))
STIMULI_IMAGES.set(4, animal('Monkey'))
STIMULI_IMAGES.set(5, animal('Sheep'))
STIMULI_IMAGES.set(6, animal('Cock'))
STIMULI_IMAGES.set(7, animal('Cat'))
STIMULI_IMAGES.set(8, animal('Pig'))
STIMULI_IMAGES.set(9, animal('Dog')) // remaining...
