/**
 * Provides types of devices that HUB application can deal with
 * Each device produce list of tests (listed in commonout/enum/test-type.ts)
 */
export enum DEVICE {
    DEFAULT_TEST = 'DEFAULT TEST', // for tests which are not depend on some device
    HAPLO = 'HAPLO',
    NIDEK_AFC330 = 'NIDEK AFC330',
    NIDEK_ARK1S = 'NIDEK ARK1S',
    NIDEK_LM7P = 'NIDEK LM7P',
    NIDEK_NT530P = 'NIDEK NT530P',
    NIDEK_RS330 = 'NIDEK RS330',
    NIDEK_TONOREFIII = 'NIDEK TONOREFIII',
    FORUS_3NETHRA = 'FORUS 3NETHRA',
    TOPCON_TRITON = 'TOPCON TRITON',
    TOPCON_MAESTRO = 'TOPCON MAESTRO',
    HAAG_SLITLAMP = 'HAAG SLITLAMP',
    NEXTSIGHT_NEXY = 'NEXTSIGHT NEXY',
    IVUE_OCT = 'IVue OCT',
    IVUE_OCT_v3217 = 'IVUE OCT v3.2.1.7',
}
