import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { MaculaScanNidekRS330MeasuredDataFrontend } from '../../../measuredData/NIDEK/RS330/maculaScanNidekRS330DataFrontend.class';

export class MaculaScanNidekRs330TestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof MaculaScanNidekRS330MeasuredDataFrontend = MaculaScanNidekRS330MeasuredDataFrontend;
    public measurements: MaculaScanNidekRS330MeasuredDataFrontend[];
    constructor() {
        super();
    }
}
