import { Component, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ROLE } from '../../../../../../../../commonout/enum/role';
import { ITechStaffRoleProperties } from '../../../../../../../../commonout/interfaces/roleProperties/techStaffRoleProperties.interface';
import { turnPageAnimation } from '../../../../_animations';
import { UserService } from '../../../../_services/user/user.service';
import { UsersListComponent } from '../users-list.component';

@Component({
    selector: 'staff-list',
    template: require('./staff-list.component.html'),
    styles: [require('../users-list.component.scss')],
    animations: [turnPageAnimation],
})
export class StaffListComponent extends UsersListComponent<ITechStaffRoleProperties> {
    constructor(userService: UserService, formBuilder: FormBuilder, renderer: Renderer2) {
        super(ROLE.STAFF, userService, formBuilder, renderer);
    }
    buildSearchForm(): void {
        this.searchForm = this.formBuilder.group({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            role: this.role,
        });
    }
}
