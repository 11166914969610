import { OCULUS } from '../enums/oculus.enum';
import { IVUE_OCT_PROCEDURES } from '../enums/ivue-oct-procedures';
import { IIvueOCTImage } from '../interfaces/measuredData/IVUE/OCT/3dscan-ivue-oct.measuredData.interface';
import { SmartImage } from './smartImage.class';

export abstract class IvueOctImage implements IIvueOCTImage {
    public filename: string;
    public createdAt: number;
    public procedure: IVUE_OCT_PROCEDURES;
    public oculus: OCULUS;
    public image: SmartImage;
    constructor() {}
    public setModel(model: IIvueOCTImage) {
        this.createdAt = model.createdAt;
        this.procedure = model.procedure;
        this.oculus = model.oculus;
        this.image.model = model.image;
    }
    public get model(): IIvueOCTImage {
        let model: IIvueOCTImage = {
            filename: this.filename,
            createdAt: this.createdAt,
            procedure: this.procedure,
            oculus: this.oculus,
            image: this.image.model,
        };
        return model;
    }
}
