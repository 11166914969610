import { RecordingToolBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/recordingToolBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { RecordingToolBulbicamTestRemarksComponent, RecordingToolComponent, RecordingToolTestControlsComponent } from '../../../_components';
import { RecordingToolBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/recordingToolBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class RecordingToolBulbicamTestFrontend extends RecordingToolBulbicamTest {
    remarks: RecordingToolBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof RecordingToolBulbicamTestRemarksComponent = RecordingToolBulbicamTestRemarksComponent;
    public readonly bulbicamChartComponent = RecordingToolComponent;
    public readonly bulbicamControlComponent: typeof RecordingToolTestControlsComponent = RecordingToolTestControlsComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new RecordingToolBulbicamTestRemarksFrontend();
    }
}

export interface RecordingToolBulbicamTestFrontend extends TestFrontend {}
applyMixins(RecordingToolBulbicamTestFrontend, [TestFrontend]);
