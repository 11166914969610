import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { I3DScanIvueOctV3217MeasuredData } from '../../../../interfaces/measuredData/IVUE/OCT/3dscan-ivue-oct_v3217.measuredData.interface';
import { IvueOctv3217Image } from '../../../iVueOctv3217Image.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class ThreeDScanIvueOctv3217MeasuredData implements MeasuredData, I3DScanIvueOctV3217MeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public images: IvueOctv3217Image[];
    constructor() {
        this.sourceDevice = DEVICE.IVUE_OCT_v3217;
        this.testType = TEST_TYPE.THREED_SCAN;
        this.images = [];
    }
    public abstract setModel(model: I3DScanIvueOctV3217MeasuredData): void;
    public getModel(): I3DScanIvueOctV3217MeasuredData {
        let model: I3DScanIvueOctV3217MeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            images: this.images.map((i) => i.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.images.length === 0;
    }
}
