import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import { IPursuitSaccadesCamMessage, IChartData } from '../../../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { CHART_HEIGHT, TICKS_COLOR, TICKS_FONT_SIZE, AXIS_COLOR, DASHED_LINES_COLOR } from '../../chartStyles.constants';
import { VELOCITY_Y_SCALE_MAX_VALUE, X_TIMESCALE_MAX_VALUE, ILine } from '../../generic-types';

const VELOCITY_TRESHOLD = 30;

@Component({
  selector: 'app-saccade-velocity-chart',
  template: require('./saccade-velocity-chart.component.html'),
  styles: [require('./saccade-velocity-chart.component.scss')]
})
export class SmoothSaccadeVelocityChartComponent implements OnInit, AfterViewInit {
    @ViewChild('chart') private svgElement: ElementRef;
    @Input() public data: IPursuitSaccadesCamMessage[];
    @Input() public width = 1400;
    @Input() public height = CHART_HEIGHT;
    @Input() public margin = 50;
    @Input() public elementId: string;
    @Input() public velocityLineColor: string;

    private svg: d3.Selection<SVGElement, unknown, null, undefined>;
    private svgInner: d3.Selection<SVGElement, unknown, null, undefined>;
    private yScale: ScaleLinear<number, number>;
    private xScale: ScaleLinear<number, number>;
    private points: [number, number][] = [];

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.initializeAxises();
    }

    public buildRecordedChart(data: IChartData): void {
        const frames = data.movementFrames;

        this.height = CHART_HEIGHT;

        this.configureSVG();
        this.initializeChart(frames);
        this.drawLineOnChart(frames, { id: 'line', color: this.velocityLineColor});
        this.drawTresholdDashedLine();
        this.drawProportionDashedLines([ ...frames ]);
    }

    public buildRecordedPointsOnMovementChart(data: IPursuitSaccadesCamMessage[]): void {
        this.drawLineOnMovementChart(data, { id: 'velocityline', color: 'red' });
    }

    public showDashedLines(frames: IPursuitSaccadesCamMessage[]): void {
        this.drawProportionDashedLines(frames);
    }

    private initializeAxises(): void {
        this.configureSVG();

        this.yScale = d3
          .scaleLinear()
          .domain([VELOCITY_Y_SCALE_MAX_VALUE, 0])
          .range([0, this.height - 2 * this.margin]);

        this.xScale = d3
          .scaleLinear()
          .domain([0, X_TIMESCALE_MAX_VALUE]);

        const distanceAxisY = this.svgInner
          .append('g')
          .attr('id', `${this.elementId}y-axisDistance`)
          .style('transform', 'translate(' + this.margin.toString() + 'px,  0)');

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', `${this.elementId}x-axis`)
          .style('transform', 'translate(0, ' + (this.height - this.margin * 2).toString() + 'px)');

        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'velocityChartPoints');

        this.width = this.svgElement.nativeElement.getBoundingClientRect().width;

        this.xScale.range([this.margin, this.width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxis = d3
          .axisLeft(this.yScale);

        distanceAxisY
          .call(yAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        distanceAxisY
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private configureSVG() {
        this.svg = d3
          .select(this.svgElement.nativeElement as SVGElement)
          .attr('height', this.height)
          .attr('width', '100%')
          .attr('id', 'velocityChart');

        this.svgInner = this.svg
          .append('g')
          .attr('id', 'velocityChartContent')
          .style('transform', 'translate(' + this.margin.toString() + 'px,  10px)');
    }

    private initializeChart(data: IPursuitSaccadesCamMessage[]): void {
        d3.select(`${this.elementId}y-axisDistance`).remove();
        d3.select(`${this.elementId}x-axis`).remove();

        this.yScale = d3
          .scaleLinear()
          .domain([VELOCITY_Y_SCALE_MAX_VALUE, 0])
          .range([0, this.height - 2 * this.margin]);

        this.xScale = d3
          .scaleLinear()
          .domain([d3.min(data, d => d.pointX), d3.max(data, d => d.pointX)]);

        const distanceAxisY = this.svgInner
          .append('g')
          .attr('id', `${this.elementId}y-axisDistance`)
          .style('transform', 'translate(' + this.margin.toString() + 'px,  0)');

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', `${this.elementId}x-axis`)
          .style('transform', 'translate(0, ' + (this.height - this.margin * 2).toString() + 'px)');

        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'velocityChartPoints');

        const width = this.svgElement.nativeElement.getBoundingClientRect().width
          ? this.svgElement.nativeElement.getBoundingClientRect().width
          : this.width;

        this.xScale.range([this.margin, width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxis = d3
          .axisLeft(this.yScale);

        distanceAxisY
          .call(yAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        distanceAxisY
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private drawLineOnChart(data: IPursuitSaccadesCamMessage[],
    lineStyle: ILine): void {
        this.points = data.map(d => [
          this.xScale(d.pointX),
          this.yScale(d.pupilvelocity),
        ]);

        const line = d3
        .line()
        .x(d => d[0])
        .y(d => d[1])
        .curve(d3.curveMonotoneX);

        this.svgInner
          .append('path')
          .attr('id', lineStyle.id)
          .attr('d', line(this.points))
          .style('fill', 'none')
          .style('stroke', lineStyle.color)
          .style('stroke-width', '0.5px');

        this.points = [];
    }

    private drawLineOnMovementChart(data: IPursuitSaccadesCamMessage[],
    lineStyle: ILine): void {
        this.yScale = d3
          .scaleLinear()
          .domain([d3.max(data, d => d.pupilvelocity), 0])
          .range([0, this.height - 2 * this.margin]);

        this.width = (d3.select('#movementChart').node() as HTMLElement).getBoundingClientRect().width;

        this.xScale = d3
          .scaleLinear()
          .domain(d3.extent(data, d => d.pointX))
          .range([this.margin, this.width - 2 * this.margin]);

        this.points = data.map(d => [
          this.xScale(d.pointX),
          this.yScale(d.pupilvelocity),
        ]);

        const line = d3
        .line()
        .x(d => d[0])
        .y(d => d[1])
        .curve(d3.curveMonotoneX);

        d3.select('#chartPoints')
          .append('path')
          .attr('id', lineStyle.id)
          .attr('d', line(this.points))
          .style('fill', 'none')
          .style('stroke', lineStyle.color)
          .style('stroke-width', '1px')
          .style('opacity', 0.5);

        this.points = [];
    }

    private drawProportionDashedLines(data: IPursuitSaccadesCamMessage[]): void {
      while (data.length > 1) {
          const startIndex = data.findIndex(f => f.calibrationGlintData);
          const slicedFrames = data.slice(startIndex);
          let endIndex = slicedFrames
            .findIndex(f => f.calibrationGlintData?.calibationGlintData !== slicedFrames[0].calibrationGlintData?.calibationGlintData)
            + startIndex;
          if (startIndex === -1) { break; }

          if (endIndex < startIndex) {
              endIndex = data.length - 1;
          }

          this.svgInner.append("line")
            .attr('id', 'velocityDashedLine')
            .attr("x1", this.xScale(data[startIndex].pointX))
            .attr("y1", 0)
            .attr("x2", this.xScale(data[startIndex].pointX))
            .attr("y2", this.height - this.margin * 2)
            .style("stroke-dasharray", ("3, 3"))
            .style("stroke-width", 2)
            .style("stroke", DASHED_LINES_COLOR)
            .style("fill", "none");

          this.svgInner.append("line")
            .attr('id', 'velocityDashedLine')
            .attr("x1", this.xScale(data[endIndex].pointX))
            .attr("y1", 0)
            .attr("x2", this.xScale(data[endIndex].pointX))
            .attr("y2", this.height - this.margin * 2)
            .style("stroke-dasharray", ("3, 3"))
            .style("stroke-width", 2)
            .style("stroke", DASHED_LINES_COLOR)
            .style("fill", "none");

          data.splice(0, endIndex);
      }
    }

    private drawTresholdDashedLine(): void {
        this.svgInner.append("line")
          .attr('id', 'velocityTresholdDashedLine')
          .attr("x1", this.width - this.margin * 2)
          .attr("y1", this.yScale(VELOCITY_TRESHOLD))
          .attr("x2", this.margin)
          .attr("y2", this.yScale(VELOCITY_TRESHOLD))
          .style("stroke-dasharray", ("3, 3"))
          .style("stroke-width", 2)
          .style("stroke", "green")
          .style("fill", "none");
    }
}
