import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IKeratometryNidekTonorefIIIMeasuredData } from '../../../../interfaces/measuredData/NIDEK/TonorefIII/keratometry-nidek-tonorefIII.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class KeratometryTonorefIIIMeasuredData implements MeasuredData, IKeratometryNidekTonorefIIIMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: {
        KM: {
            diameter: RegisterEditField<string>;
            R1Median: {
                mm: RegisterEditField<string>;
                diopter: RegisterEditField<string>;
                axis: RegisterEditField<string>;
            };
            R2Median: {
                mm: RegisterEditField<string>;
                diopter: RegisterEditField<string>;
                axis: RegisterEditField<string>;
            };
        }[];
        corneal: {
            number: RegisterEditField<string>;
            size: RegisterEditField<string>;
        }[];
    };
    public OS: {
        KM: {
            diameter: RegisterEditField<string>;
            R1Median: {
                mm: RegisterEditField<string>;
                diopter: RegisterEditField<string>;
                axis: RegisterEditField<string>;
            };
            R2Median: {
                mm: RegisterEditField<string>;
                diopter: RegisterEditField<string>;
                axis: RegisterEditField<string>;
            };
        }[];
        corneal: {
            number: RegisterEditField<string>;
            size: RegisterEditField<string>;
        }[];
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_TONOREFIII;
        this.testType = TEST_TYPE.KERATOMETRY;
        this.OD = {
            KM: [],
            corneal: [],
        };
        this.OS = {
            KM: [],
            corneal: [],
        };
    }
    public abstract setModel(model: IKeratometryNidekTonorefIIIMeasuredData): void;
    public getModel(): IKeratometryNidekTonorefIIIMeasuredData {
        let model: IKeratometryNidekTonorefIIIMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: {
                KM: [],
                corneal: [],
            },
            OS: {
                KM: [],
                corneal: [],
            },
        };
        this.OD.KM.forEach(odkm => {
            model.OD.KM.push({
                diameter: odkm.diameter.getModel(),
                R1Median: {
                    mm: odkm.R1Median.mm.getModel(),
                    diopter: odkm.R1Median.diopter.getModel(),
                    axis: odkm.R1Median.axis.getModel(),
                },
                R2Median: {
                    mm: odkm.R2Median.mm.getModel(),
                    diopter: odkm.R2Median.diopter.getModel(),
                    axis: odkm.R2Median.axis.getModel(),
                },
            });
        });
        this.OD.corneal.forEach(odc => {
            model.OD.corneal.push({
                number: odc.number.getModel(),
                size: odc.size.getModel(),
            });
        });
        this.OS.KM.forEach(oskm => {
            model.OS.KM.push({
                diameter: oskm.diameter.getModel(),
                R1Median: {
                    mm: oskm.R1Median.mm.getModel(),
                    diopter: oskm.R1Median.diopter.getModel(),
                    axis: oskm.R1Median.axis.getModel(),
                },
                R2Median: {
                    mm: oskm.R2Median.mm.getModel(),
                    diopter: oskm.R2Median.diopter.getModel(),
                    axis: oskm.R2Median.axis.getModel(),
                },
            });
        });
        this.OS.corneal.forEach(osc => {
            model.OS.corneal.push({
                number: osc.number.getModel(),
                size: osc.size.getModel(),
            });
        });
        return model;
    }
    public isEmpty(): boolean {
        return (
            !this.OD.KM.some(
                odkm =>
                    !odkm.diameter.isEmpty() &&
                    !odkm.R1Median.axis.isEmpty() &&
                    !odkm.R1Median.diopter.isEmpty() &&
                    !odkm.R1Median.mm.isEmpty() &&
                    !odkm.R2Median.axis.isEmpty() &&
                    !odkm.R2Median.diopter.isEmpty() &&
                    !odkm.R2Median.mm.isEmpty()
            ) &&
            !this.OD.corneal.some(odc => !odc.number.isEmpty && !odc.size.isEmpty()) &&
            !this.OS.KM.some(
                oskm =>
                    !oskm.diameter.isEmpty() &&
                    !oskm.R1Median.axis.isEmpty() &&
                    !oskm.R1Median.diopter.isEmpty() &&
                    !oskm.R1Median.mm.isEmpty() &&
                    !oskm.R2Median.axis.isEmpty() &&
                    !oskm.R2Median.diopter.isEmpty() &&
                    !oskm.R2Median.mm.isEmpty()
            ) &&
            !this.OS.corneal.some(osc => !osc.number.isEmpty && !osc.size.isEmpty())
        );
    }
}
