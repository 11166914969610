import { Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../../../commonout/enum/calibration.command.enum';
import { ICalibrationResult } from '../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { ILuxCalibration, ILuxValue } from '../../../../../../../../commonout/interfaces/calibration/luxCalibration.interface';
import { OCULUS } from '../../../../../../../common/enums/oculus.enum';
import { VisualFieldMergedChartService } from '../../../../_services/chartServices/visualFieldMergedChartService';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { CalibrationTestComponent } from '../calibration-test-component';

interface RGB {
    r: number;
    g: number;
    b: number;
}

interface RGBCalibration {
    od: RGB[];
    os: RGB[];
}

enum State {
    Init = 'init',
    Checked = 'checked',
    Start = 'start',
    Stop = 'stop',
}

const rgbLowOD: { A: number; B: number; C: number } = {
    A: 0.006,
    B: -0.0355,
    C: 5.73,
};

const rgbHighOD: { A: number; B: number; C: number } = {
    A: 0.0108,
    B: -0.94,
    C: 45,
};

const rgbOnScreenOD: number = 516;

const rgbLowOS: { A: number; B: number; C: number } = {
    A: 0.0079,
    B: -0.0786,
    C: 5.85,
};

const rgbHighOS: { A: number; B: number; C: number } = {
    A: 0.0101,
    B: -0.516,
    C: 25.6,
};

const rgbOnScreenOS: number = 551;
const N = 9;

@Component({
    selector: 'lux-calibration',
    template: require('./lux-calibration.component.html'),
    styles: [require('./lux-calibration.component.scss')],
})
export class LuxCalibrationComponent extends CalibrationTestComponent {
    customOnDestroy(): void {}
    calibrationType: CALIBRATION_TEST;
    protected messageHandler(): Promise<void> {
        throw new Error('Method not implemented.');
    }
    scaleCoefs = { od: 5.973207636, os: 6.346086537 };
    redColor = '#EB5757';
    yellowColor = '#F2C94C';
    blueColor = '#2D9CDB';

    getRGB(eye: string, luxValue: number) {
        const coefs = {
            od: { a: 6.54e-3, b: -2.03e-1, c: 7.58 },
            os: { a: 6.83e-3, b: -2.81e-1, c: 1.07e1 },
        };
        const { a, b, c } = coefs[eye];
        const sqrt = Math.sqrt(b * b - 4 * a * (c - luxValue));

        return (-b + (sqrt > 0 ? sqrt : 0)) / (2 * a);
    }
    get stateList(): typeof State {
        return State;
    }
    rgbValues: RGBCalibration = {
        od: [{ r: 0, g: 0, b: 0 }],
        os: [{ r: 0, g: 0, b: 0 }],
    };

    startValue = {
        BackgroundOS: 0,
        BackgroundOD: 0,
    };

    results: {
        resultValues: number[];
        timestamp: any;
    }[] = [];

    luxtableRGBValues: number[] = [];
    luxtableValuesOD: {
        rgb: number;
        lux: number;
    }[] = [];
    luxtableValuesOS: {
        rgb: number;
        lux: number;
    }[] = [];

    currentState: State = State.Init;
    isTestRunning = false;
    @ViewChild('svgChart') svgChart: ElementRef;

    margin = 20;

    svg: any;
    svgInnerOS: any;
    svgInnerOD: any;

    yScale: any;
    xScaleOS: any;
    xScaleOD: any;
    yAxisOD: any;
    xAxisOD: any;
    yAxisOS: any;
    xAxisOS: any;

    activeElement: number = null;

    form: FormGroup = this.fb.group({
        eyePairOD: this.fb.array([]),
        eyePairOS: this.fb.array([]),
    });

    activePair: { index: number; target: string } = { index: 0, target: 'od' };
    activePairIndex: number = 0;
    activePairTarget: number = 0; // od = 0, os = 1

    @ViewChildren('luxValueOD') luxInputsOD: QueryList<ElementRef>;
    @ViewChildren('luxValueOS') luxInputsOS: QueryList<ElementRef>;

    constructor(
        bulbicamService: BulbicamService,
        socketService: SocketService,
        private fb: FormBuilder,
        private vfmService: VisualFieldMergedChartService,
        configService: ConfigService
    ) {
        super(bulbicamService, socketService, configService);
        for (let i = 0; i < N; i++) {
            const eyeFormOD = this.fb.group({
                lux: null,
            });
            const eyeFormOS = this.fb.group({
                lux: null,
            });

            this.eyePairOD.push(eyeFormOD);
            this.eyePairOS.push(eyeFormOS);
        }
        for (let i = 1; i < N; i++) {
            if (i === 1) {
                this.rgbValues.od[i] = { r: 31, g: 31, b: 31 };
                this.rgbValues.os[i] = { r: 31, g: 31, b: 31 };
            } else if (i > 1 && i <= 8) {
                const rgbValue = this.rgbValues.od[i - 1].r;
                this.rgbValues.od[i] = { r: rgbValue + 32, g: rgbValue + 32, b: rgbValue + 32 };
                this.rgbValues.os[i] = { r: rgbValue + 32, g: rgbValue + 32, b: rgbValue + 32 };
            } else if (i === N) {
                this.rgbValues.od[i] = { r: 0, g: 0, b: 0 };
                this.rgbValues.os[i] = { r: 0, g: 0, b: 0 };
            }
        }

        this.luxtableRGBValues = Array.from(Array(128).keys()).map(d => d * 2);
        this.luxtableRGBValues.push(255);

        const subscriber = this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.LUX_CALIBRATION).subscribe(res => {
            this.results = res.map((el: ICalibrationResult<ILuxCalibration>) => {
                return {
                    resultValues: el.results.calibrations.map((d: ILuxValue) => d.lux_value),
                    timestamp: el.createdAt,
                };
            });
        });
        this.subscriptions.push(subscriber);
    }

    move(direction: number = 1) {
        this.drawSingleDot();
        this.drawSingleScaledDot();
        this.activePairIndex = this.activePairIndex + direction;
        if (this.activePairIndex === N) {
            this.activePairIndex = 0;
            this.activePairTarget = (this.activePairTarget + 1) % 2;
        } else if (this.activePairIndex === -1) {
            this.activePairIndex = N - 1;
            this.activePairTarget = (this.activePairTarget + 1) % 2;
        }
        this.sendSettings();
        if (this.activePairTarget === 0) {
            this.luxInputsOD.find((_, index) => index === this.activePairIndex).nativeElement.focus();
        } else {
            this.luxInputsOS.find((_, index) => index === this.activePairIndex).nativeElement.focus();
        }
    }

    initChart(width: number = 300, height: number): void {
        this.svg = d3
            .select(this.svgChart?.nativeElement)
            .attr('id', 'svgLux')
            .attr('width', width)
            .attr('height', height);

        this.svgInnerOD = this.svg
            .append('g')
            .attr('width', width / 2 - this.margin)
            .style('transform', `translate(${this.margin}px, ${this.margin}px)`);
        this.svgInnerOS = this.svg
            .append('g')
            .attr('width', width / 2 - this.margin)
            .style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yScale = d3
            .scaleLinear()
            .domain([100, 0])
            .range([2 * this.margin, 300 - this.margin]);

        this.xScaleOS = d3
            .scaleLinear()
            .domain([0, 260])
            .range([width / 2 + 2 * this.margin, width - 2 * this.margin]);

        this.xScaleOD = d3
            .scaleLinear()
            .domain([0, 260])
            .range([2 * this.margin, width / 2 - 2 * this.margin]);

        this.yAxisOS = this.svgInnerOS
            .append('g')
            .attr('id', 'y-axis-os')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${2 * this.margin}px, -20px)`);

        this.xAxisOS = this.svgInnerOS
            .append('g')
            .attr('id', 'x-axis-os')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(0px, ${height - 50 - 2 * this.margin}px)`);

        this.yAxisOD = this.svgInnerOD
            .append('g')
            .attr('id', 'y-axis-od')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${width / 2 + 2 * this.margin}px, -20px)`);

        this.xAxisOD = this.svgInnerOD
            .append('g')
            .attr('id', 'x-axis-od')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${0}px, ${height - 50 - 2 * this.margin}px)`);

        const yAxis = d3.axisLeft(this.yScale);
        const xAxisOS = d3.axisBottom(this.xScaleOS);
        const xAxisOD = d3.axisBottom(this.xScaleOD);

        this.yAxisOS.call(yAxis);
        this.xAxisOS.call(xAxisOS);
        this.yAxisOD.call(yAxis);
        this.xAxisOD.call(xAxisOD);

        this.svgInnerOS
            .append('text')
            .attr('id', 'text-label')
            .text('OS')
            .attr('dx', this.xScaleOS(125))
            .attr('dy', this.yScale(100))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(-25px, -30px)`);

        this.svgInnerOD
            .append('text')
            .attr('id', 'text-label')
            .text('OD')
            .attr('dx', this.xScaleOD(125))
            .attr('dy', this.yScale(100))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(-25px, -30px)`);

        this.svgInnerOS
            .append('text')
            .attr('id', 'text-label')
            .text('RGB')
            .attr('dx', this.xScaleOS(255))
            .attr('dy', this.yScale(0))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(0px, 20px)`);

        this.svgInnerOS
            .append('text')
            .attr('id', 'text-label')
            .text('Lux')
            .attr('dx', this.xScaleOS(-13))
            .attr('dy', this.yScale(55))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(5px, -150px)`);

        this.svgInnerOD
            .append('text')
            .attr('id', 'text-label')
            .text('RGB')
            .attr('dx', this.xScaleOD(255))
            .attr('dy', this.yScale(0))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(0px, 20px)`);

        this.svgInnerOD
            .append('text')
            .attr('id', 'text-label')
            .text('Lux')
            .attr('dx', this.xScaleOD(-13))
            .attr('dy', this.yScale(55))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(5px, -150px)`);
    }

    drawSingleDot(range: number[] = []) {
        const results: number[] = [
            ...this.eyePairOD.value.map((d: { lux: number | string }) => {
                if (typeof d.lux === 'string') {
                    return d.lux.replace(',', '.');
                } else {
                    return d.lux;
                }
            }),
            ...this.eyePairOS.value.map((d: { lux: number | string }) => {
                if (typeof d.lux === 'string') {
                    return d.lux.replace(',', '.');
                } else {
                    return d.lux;
                }
            }),
        ];

        this.svg.selectAll('.circle-os').remove();
        this.svg.selectAll('.circle-od').remove();
        if (!range.length) {
            range = [Math.max(...results) + 15, 0];
        }

        this.yScale = d3
            .scaleLinear()
            .domain(range)
            .range([2 * this.margin, 300 - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxisOS.call(yAxis);

        this.yAxisOD.call(yAxis);

        for (let i = 0; i < N * 2; i++) {
            if (!results[i]) continue;
            if (i === 0) {
                this.svgInnerOD
                    .append('circle')
                    .attr('id', `circle-od-${i}`)
                    .attr('class', `circle-od`)
                    .attr('cx', this.xScaleOD(this.rgbValues.od[i].r))
                    .attr('cy', this.yScale(results[i]))
                    .attr('r', 3)
                    .attr('stroke', this.yellowColor)
                    .attr('fill', '#03272b')
                    .attr('stroke-width', '2px')
                    .attr('font-size', '12px')
                    .style('transform', `translate(0px, -20px)`);
            }

            if (i > 0 && i < N) {
                this.svgInnerOD
                    .append('circle')
                    .attr('id', `circle-od-${i}`)
                    .attr('class', `circle-od`)
                    .attr('cx', this.xScaleOD(this.rgbValues.od[i].r))
                    .attr('cy', this.yScale(results[i]))
                    .attr('r', 3)
                    .attr('stroke', this.yellowColor)
                    .attr('fill', '#03272b')
                    .attr('stroke-width', '2px')
                    .attr('font-size', '12px')
                    .style('transform', `translate(0px, -20px)`);
            }

            if (i >= N && i < N * 2) {
                this.svgInnerOS
                    .append('circle')
                    .attr('id', `circle-os-${i}`)
                    .attr('class', `circle-os`)
                    .attr('cx', this.xScaleOS(this.rgbValues.os[i - N].r))
                    .attr('cy', this.yScale(results[i]))
                    .attr('r', 3)
                    .attr('stroke', this.yellowColor)
                    .attr('fill', '#03272b')
                    .attr('stroke-width', '2px')
                    .attr('font-size', '12px')
                    .style('transform', `translate(0px, -20px)`);
            }
        }
    }

    drawSingleScaledDot() {
        const results: number[] = [
            ...this.eyePairOD.value.map((d: { lux: number | string }) => {
                if (typeof d.lux === 'string') {
                    return d.lux.replace(',', '.');
                } else {
                    return d.lux;
                }
            }),
            ...this.eyePairOS.value.map((d: { lux: number | string }) => {
                if (typeof d.lux === 'string') {
                    return d.lux.replace(',', '.');
                } else {
                    return d.lux;
                }
            }),
        ];

        for (let i = 0; i < N * 2; i++) {
            if (!results[i]) continue;
            if (i === 0) {
                this.svgInnerOD
                    .append('circle')
                    .attr('id', `scale-circle-od-${i}`)
                    .attr('class', `circle-od`)
                    .attr('cx', this.xScaleOD(this.rgbValues.od[i].r))
                    .attr('cy', this.yScale(results[i] * this.scaleCoefs.od))
                    .attr('r', 3)
                    .attr('stroke', this.blueColor)
                    .attr('fill', '#03272b')
                    .attr('stroke-width', '2px')
                    .attr('font-size', '12px')
                    .style('transform', `translate(0px, -20px)`);
            }

            if (i > 0 && i < N) {
                this.svgInnerOD
                    .append('circle')
                    .attr('id', `scale-circle-od-${i}`)
                    .attr('class', `circle-od`)
                    .attr('cx', this.xScaleOD(this.rgbValues.od[i].r))
                    .attr('cy', this.yScale(results[i] * this.scaleCoefs.od))
                    .attr('r', 3)
                    .attr('stroke', this.blueColor)
                    .attr('fill', '#03272b')
                    .attr('stroke-width', '2px')
                    .attr('font-size', '12px')
                    .style('transform', `translate(0px, -20px)`);
            }

            if (i >= N && i < N * 2) {
                this.svgInnerOS
                    .append('circle')
                    .attr('id', `scale-circle-os-${i}`)
                    .attr('class', `circle-os`)
                    .attr('cx', this.xScaleOS(this.rgbValues.os[i - N].r))
                    .attr('cy', this.yScale(results[i] * this.scaleCoefs.os))
                    .attr('r', 3)
                    .attr('stroke', this.blueColor)
                    .attr('fill', '#03272b')
                    .attr('stroke-width', '2px')
                    .attr('font-size', '12px')
                    .style('transform', `translate(0px, -20px)`);
            }
        }
    }

    drawResults(luxValues: number[]) {
        const [odRawValues, osRawValues] = [[...luxValues.slice(0, N)], [...luxValues.slice(N, N * 2)]];

        if (!odRawValues[odRawValues.length - 1] || !osRawValues[osRawValues.length - 1]) {
            console.error('cannot build teoretical curve');
            return;
        }

        const odPercentage = (odRawValues[odRawValues.length - 1] * this.scaleCoefs.od) / rgbOnScreenOD;
        const osPercentage = (osRawValues[osRawValues.length - 1] * this.scaleCoefs.os) / rgbOnScreenOS;

        const rgbODValues = this.calculateValues(odPercentage, 'OD');
        const rgbOSValues = this.calculateValues(osPercentage, 'OS');

        const [odValuesTemp, osValuesTemp] = [this.rgbValues.od.slice(0, N).map(el => el.r), this.rgbValues.os.slice(0, N).map(el => el.r)];

        const odResults = this.calculateResultValues(rgbODValues, odValuesTemp);
        const osResults = this.calculateResultValues(rgbOSValues, osValuesTemp);

        this.luxtableValuesOD = this.calculateResultValues(rgbODValues, this.luxtableRGBValues);
        this.luxtableValuesOS = this.calculateResultValues(rgbOSValues, this.luxtableRGBValues);

        const range = [Math.max(odRawValues[odRawValues.length - 1] * this.scaleCoefs.od, osRawValues[osRawValues.length - 1] * this.scaleCoefs.os) + 15, 0];

        this.svg.selectAll('#path-od').remove();
        this.svg.selectAll('#path-os').remove();
        this.drawSingleDot(range);
        this.drawSingleScaledDot();

        this.yScale = d3
            .scaleLinear()
            .domain(range)
            .range([2 * this.margin, 300 - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxisOS.call(yAxis);

        this.yAxisOD.call(yAxis);

        const lineDefined = d3.line().defined(function(d) {
            return d[1] !== null;
        });

        const pointsOD: [number, number][] = odResults.map(d => [this.xScaleOD(d.rgb), this.yScale(d.lux)]);

        const pointsOS: [number, number][] = osResults.map(d => [this.xScaleOS(d.rgb), this.yScale(d.lux)]);

        this.svgInnerOD
            .append('path')
            .attr('id', `path-od`)
            .style('fill', 'none')
            .style('stroke', this.redColor)
            .style('stroke-width', '3px')
            .style('transform', `translate(0px, -20px)`)
            .attr('d', lineDefined(pointsOD));

        this.svgInnerOS
            .append('path')
            .attr('id', `path-os`)
            .style('fill', 'none')
            .style('stroke', this.redColor)
            .style('stroke-width', '3px')
            .style('transform', `translate(0px, -20px)`)
            .attr('d', lineDefined(pointsOS));
    }

    calculateValues(percentage: number, type: 'OD' | 'OS') {
        let resultArray: {
            A: number;
            B: number;
            C: number;
        }[] = [];

        let lowRGBValues: {
            A: number;
            B: number;
            C: number;
        };

        let highRGBValues: {
            A: number;
            B: number;
            C: number;
        };

        if (type === 'OD') {
            lowRGBValues = {
                A: percentage * rgbLowOD.A,
                B: percentage * rgbLowOD.B,
                C: percentage * rgbLowOD.C,
            };

            highRGBValues = {
                A: percentage * rgbHighOD.A,
                B: percentage * rgbHighOD.B,
                C: percentage * rgbHighOD.C,
            };
        } else {
            lowRGBValues = {
                A: percentage * rgbLowOS.A,
                B: percentage * rgbLowOS.B,
                C: percentage * rgbLowOS.C,
            };

            highRGBValues = {
                A: percentage * rgbLowOS.A,
                B: percentage * rgbLowOS.B,
                C: percentage * rgbLowOS.C,
            };
        }

        resultArray.push(lowRGBValues, highRGBValues);
        return resultArray;
    }

    calculateResultValues(coefs: { A: number; B: number; C: number }[], values: number[]) {
        let resultArray: {
            rgb: number;
            lux: number;
        }[] = [];

        for (let el of values) {
            if (el <= 64) {
                resultArray.push({
                    rgb: el,
                    lux: coefs[0].A * Math.pow(el, 2) + coefs[0].B * el + coefs[0].C,
                });
            } else {
                resultArray.push({
                    rgb: el,
                    lux: coefs[1].A * Math.pow(el, 2) + coefs[1].B * el + coefs[1].C,
                });
            }
        }

        return resultArray;
    }

    get eyePairOD() {
        return this.form.controls['eyePairOD'] as FormArray;
    }

    get eyePairOS() {
        return this.form.controls['eyePairOS'] as FormArray;
    }

    subscriptions: Subscription[] = [];

    getBGColor(rgbValue: number) {
        let hex = rgbValue.toString(16);
        hex = hex.length == 1 ? '0' + hex : hex;

        return '#' + hex + hex + hex;
    }

    getColor(rgbValue: number) {
        if (rgbValue > 128) {
            return '#03272B';
        }

        return '#FAFAFA';
    }

    sendSettings(): void {
        const subscriber = this.bulbicamService
            .sendCalibrationCommand({
                command: CALIBRATION_COMMAND.BACKGROUND,
                test: CALIBRATION_TEST.LUX_CALIBRATION,
                BackgroundOS: this.activePairTarget ? this.rgbValues.os[this.activePairIndex].r : 0,
                BackgroundOD: !this.activePairTarget ? this.rgbValues.od[this.activePairIndex].r : 0,
            })
            .subscribe();
        this.subscriptions.push(subscriber);
    }

    saveResults(): void {
        let valuesArray: number[] = [];
        [...this.eyePairOD.value, ...this.eyePairOS.value].forEach((el: any, i: number) => {
            valuesArray.push(+el.lux.replace(',', '.'));
        });

        this.drawResults(valuesArray);

        let luxtableStringOD = '';
        let luxtableStringOS = '';

        this.luxtableValuesOD.forEach(el => {
            luxtableStringOD += `${el.rgb}; ${el.lux}\n`;
        });

        this.luxtableValuesOS.forEach(el => {
            luxtableStringOS += `${el.rgb}; ${el.lux}\n`;
        });

        const writeFileODSubscriber = this.bulbicamService.saveCalibrationSettingsToDrive(luxtableStringOD, 'luxtableod.csv').subscribe();
        const writeFileOSSubscriber = this.bulbicamService.saveCalibrationSettingsToDrive(luxtableStringOS, 'luxtableos.csv').subscribe();

        const results = valuesArray.map((d: number, i: number) => {
            if (i < N) {
                return {
                    order: i,
                    lux_value: d,
                    OD: this.rgbValues.od[i].r,
                    OS: this.rgbValues.os[0].r,
                };
            } else {
                return {
                    order: i,
                    lux_value: d,
                    OD: this.rgbValues.od[0].r,
                    OS: this.rgbValues.os[i - N].r,
                };
            }
        });

        const saveSubscriber = this.bulbicamService
            .saveCalibrationSetting({
                test: CALIBRATION_TEST.LUX_CALIBRATION,
                createdAt: Date.now(),
                results: {
                    calibrations: results,
                    maxLuxValue: Math.min(this.vfmService.getOnscreenLuxValue(valuesArray[8], OCULUS.OD), this.vfmService.getOnscreenLuxValue(valuesArray[17], OCULUS.OS)).toFixed(
                        0
                    ),
                },
            })
            .pipe(
                concatMap(() => {
                    return this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.LUX_CALIBRATION);
                })
            )
            .subscribe(value => {
                this.results = value.map((el: ICalibrationResult<ILuxCalibration>) => {
                    return {
                        resultValues: el.results.calibrations.map((d: ILuxValue) => d.lux_value),
                        timestamp: el.createdAt,
                    };
                });
                this.activeElement = this.results.length - 1;
            });

        const sendSubscriber = this.bulbicamService
            .sendCalibrationCommand({
                command: CALIBRATION_COMMAND.STOP,
                test: CALIBRATION_TEST.LUX_CALIBRATION,
            })
            .subscribe();

        this.subscriptions.push(saveSubscriber, sendSubscriber, writeFileODSubscriber, writeFileOSSubscriber);
        this.eyePairOD.disable();
        this.eyePairOS.disable();
        this.currentState = State.Stop;
    }

    toggleTest() {
        if (this.currentState === State.Start) {
            const stopSubscriber = this.bulbicamService
                .sendCalibrationCommand({
                    command: CALIBRATION_COMMAND.STOP,
                    test: CALIBRATION_TEST.LUX_CALIBRATION,
                })
                .subscribe();
            this.subscriptions.push(stopSubscriber);
            this.eyePairOD.disable();
            this.eyePairOS.disable();
            this.currentState = State.Stop;

            this.eyePairOD.controls.forEach((control: FormGroup) => {
                control.setValue({ lux: '' });
            });
            this.eyePairOS.controls.forEach((control: FormGroup) => {
                control.setValue({ lux: '' });
            });
        } else {
            this.activePairIndex = 0;
            this.activeElement = null;
            const startSubscriber = this.bulbicamService
                .sendCalibrationCommand({
                    command: CALIBRATION_COMMAND.START,
                    test: CALIBRATION_TEST.LUX_CALIBRATION,
                    BackgroundOS: this.rgbValues.os[0].r,
                    BackgroundOD: this.rgbValues.od[0].r,
                })
                .subscribe();
            this.subscriptions.push(startSubscriber);
            this.clearChart();
            this.clearForm();

            if (this.currentState === State.Init) {
                const width = this.svgChart?.nativeElement?.getBoundingClientRect()?.width;
                const height = this.svgChart?.nativeElement?.getBoundingClientRect()?.height;

                this.initChart(width, height);
            }
            this.currentState = State.Start;

            this.startValue = {
                BackgroundOS: this.rgbValues.os[this.activePairIndex].r,
                BackgroundOD: this.rgbValues.od[this.activePairIndex].r,
            };

            this.eyePairOD.enable();
            this.eyePairOS.enable();

            this.currentState = State.Start;
        }
        this.isTestRunning = !this.isTestRunning;
    }

    changeField(index: number) {
        if (this.eyePairOD.disabled) {
            return;
        } else {
            this.activePairIndex = index;

            this.drawSingleDot();
            this.drawSingleScaledDot();
            this.sendSettings();

            if (this.activePairTarget === 0) {
                this.luxInputsOD.find((_, i) => i === this.activePairIndex).nativeElement.focus();
            } else {
                this.luxInputsOS.find((_, i) => i === this.activePairIndex).nativeElement.focus();
            }
        }
    }

    clearForm() {
        while (this.eyePairOD.length !== 0 && this.eyePairOS.length !== 0) {
            this.eyePairOD.removeAt(0);
            this.eyePairOS.removeAt(0);
        }
        for (let i = 0; i < N; i++) {
            const eyeFormOD = this.fb.group({
                lux: null,
            });
            const eyeFormOS = this.fb.group({
                lux: null,
            });

            this.eyePairOD.push(eyeFormOD);
            this.eyePairOS.push(eyeFormOS);
        }
    }

    setInputValues(el: { resultValues: number[]; timestamp: any }, index: number) {
        this.activeElement = index;
        if (this.currentState === State.Start) {
            const subscriber = this.bulbicamService
                .sendCalibrationCommand({
                    command: CALIBRATION_COMMAND.STOP,
                    test: CALIBRATION_TEST.LUX_CALIBRATION,
                })
                .subscribe();
            this.subscriptions.push(subscriber);

            this.eyePairOD.disable();
            this.eyePairOS.disable();
            this.currentState = State.Stop;
        }
        if (this.currentState === State.Init) {
            const width = this.svgChart?.nativeElement?.getBoundingClientRect()?.width;
            const height = this.svgChart?.nativeElement?.getBoundingClientRect()?.height;
            this.eyePairOD.disable();
            this.eyePairOS.disable();

            this.initChart(width, height);
        }

        this.currentState = State.Checked;

        for (let i = 0; i < N * 2; i++) {
            const value = el?.resultValues?.[i] || 0;

            if (i < N) {
                this.eyePairOD.controls[i].setValue({ lux: value });
            } else {
                this.eyePairOS.controls[i - N].setValue({ lux: value });
            }
        }

        this.drawResults(el.resultValues);
    }

    deleteInputResults(i: number) {
        const deleteSubscriber = this.bulbicamService
            .deleteCalibrationSetting(CALIBRATION_TEST.LUX_CALIBRATION, this.results[i].timestamp)
            .pipe(
                concatMap(() => {
                    return this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.LUX_CALIBRATION);
                })
            )
            .subscribe(value => {
                this.results = value.map((el: ICalibrationResult<ILuxCalibration>) => {
                    return {
                        resultValues: el.results.calibrations.map((d: ILuxValue) => d.lux_value),
                        timestamp: el.createdAt,
                    };
                });
            });

        this.subscriptions.push(deleteSubscriber);
        this.clearForm();
        this.clearChart();

        this.eyePairOD.disable();
        this.eyePairOS.disable();
    }

    clearChart() {
        if (this.currentState !== State.Init) {
            this.svg.selectAll('.circle-os')?.remove();
            this.svg.selectAll('.circle-od')?.remove();
            this.svg.selectAll('#path-od')?.remove();
            this.svg.selectAll('#path-os')?.remove();
        }
    }
}

// TODO (html) columns size
// TODO (svg) resize all svg according to screen size (example visual field merged)
// TODO (ts) remove inner subscriptions
