import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ROLE } from '../../../../../../../commonout/enum/role';
import { PatientFrontend } from '../../../_models';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
    selector: 'patients-overview',
    template: require('./patients-overview.component.html'),
    styles: [require('./patients-overview.component.scss')],
})
export class PatientsOverviewComponent implements OnInit, OnDestroy {
    private singlePatient: PatientFrontend;
    private subscriptions: Subscription[];
    private ROLE: typeof ROLE = ROLE;
    @Input() isSearchPerformed: boolean;
    @Input() examinations: Observable<ExaminationFrontend[]>;
    @Output() registerIdClicked = new EventEmitter<string>();
    @ViewChild(PaginatorComponent) public paginatorComponent: PaginatorComponent;
    constructor(private authService: AuthenticationService, private router: Router, private examinationService: ExaminationService) {
        this.subscriptions = [];
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.examinations.subscribe((exams) => {
                if (exams.length > 0) {
                    // defining if all examinations are belong to single patient;
                    let patientID: string = exams[0].patientId,
                        isAllTheSame: boolean = true;
                    for (let index = 0; index < exams.length; index++) {
                        const exam = exams[index];
                        if (patientID !== exam.patientId) {
                            isAllTheSame = false;
                            break;
                        }
                    }
                    this.singlePatient = isAllTheSame ? exams[0].patient : null;
                } else {
                    this.singlePatient = null;
                }
            })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    private async create(): Promise<void> {
        if (this.singlePatient && this.isSearchPerformed) {
            const examination: ExaminationFrontend = await this.examinationService.create(this.singlePatient._id).toPromise();
            this.router.navigate(['/dashboard-staff/test-list', examination._id]);
        } else {
            this.router.navigate(['/dashboard-staff/patient-details']);
        }
    }
    private transformID(id: string): string {
        if (!id) {
            return;
        }
        let middle = Math.ceil(id.length / 2);
        let s1 = id.slice(0, middle);
        let s2 = id.slice(middle);
        return `${s1}\n${s2}`;
    }
}
