import { SaccadeMergedBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/saccadesMergedBulbicamDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class SaccadeMergedBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof SaccadeMergedBulbicamMeasuredDataFrontend = SaccadeMergedBulbicamMeasuredDataFrontend;
    public measurements: SaccadeMergedBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
