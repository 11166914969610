// @ts-nocheck
// import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import * as d3 from 'd3';
// import * as _ from 'lodash';
// import { TranslateService } from '../../../../_services/general/translate.service';
// import { BulbicamChartComponent, IChartMargin } from '../haploChart.component';
// import { MESSAGE_TYPE } from '../../../../../../../../commonout/interfaces/charts.model';
import { DrawAnimated } from '../../lib/DrawAnimated';
import { Variable } from '../../lib/new/Variable';
import { Target } from '../../lib/types';
import { ageControlData, AgeControlWhisker } from './ageControlData';
import { directionOptionsOD, directionOptionsOS, FromTo } from './directionOptions';
import { DirectionsPreview, PointStuff, typeObj } from './DirectionsPreview';
import { getOutlierInfo } from './getOutlierInfo';
import { GroupBox, d3, DirectedLayout, drawCross, MESSAGE_TYPE, GroupOption, GroupOptionArrow, Scale, translate, WhiskerPosition, Whiskers, _ } from './lib';
import { LogData } from './LogData';
import { NewState } from './NewState';
import { Popup } from './Popup';
import { applyFont2, axisStyle } from './style';
import { ChartBg } from '../../lib';
import { Legend } from './Legend';

const OD = 0;
const OS = 1;

const TICKS_X_COUNT = 20;

const WHISKERS_TBD = 5; // (including and) after this many points you see whiskers
// const ODD_EYE = 2

const DIRECTIONS_TITLE = 'Directions';

const ALL_DIRECTIONSOD = Object.keys(directionOptionsOD);
const ALL_DIRECTIONSOS = Object.keys(directionOptionsOS);

const MORE_SPACE = 200;
const Y_STEP = 100;
const CLEAR_SELECTION = 'Clear Selection';
const NO_CONTROL_SELECTED: { name: string; items: AgeControlWhisker[] } = { name: '', items: [] };
const NO_DIRECTION_SELECTED: { name: string; items: FromTo[] } = { name: '', items: [] };

let screen = window.screen; // ts be content

let defaultStyle = {
    text: { color: '#abbec5' },
    zoomBtn: { color: '#0bb6e9' },
    bgDot: { color: '#009abe' },
    tooltip: { color: 'rgb(10,66,93)' },
    tooltipprimaryText: { color: 'rgb(0,154,190)' },
    tooltipValueText: { color: 'rgb(171,190,197)' },
};

interface ISaccadesData {
    distance: number;
    velocity: number;
    direction: number;
}

type PointData = {
    distance: number;
    velocity: number;
    direction: number;
    category: string;

    // for preview
    eye: number;
    targettype: number;
    targetx: number;
    targety: number;

    // own
    isOutlier: boolean;
    originalIsOutlier: boolean;
};

function isOutlier(d: PointData) {
    return d.isOutlier === true;
    // return d.direction === 6;
}

interface DiagramParams {
    svg: any;
    theme: string;
    // textColor: string
    // globals: any
    translateService: any;

    edits: any[];
    addEdit: (a: any) => Promise<any[]>;
}

const ALL_EYES = ['OD', 'OS', 'OS2', 'OD2'];

class State {
    // private activeEye = 'All';
    // private activeEyes = ALL_EYES;
    eyesChoices = { OD: true, OS: true, OD2: true, OS2: true };
    ageControlGroup = NO_CONTROL_SELECTED;
    directionChosenOD = NO_DIRECTION_SELECTED;
    directionChosenOS = NO_DIRECTION_SELECTED;

    cummulativeData: WhiskerPosition[] = [];
    addedPoints: PointData[] = [];
    directionsChoicesOD: { [k: string]: boolean } = Object.keys(directionOptionsOD).reduce((a, v) => {
        // if (v === 'Lateral') return { ...a, [v]: false };
        return { ...a, [v]: true };
    }, {});
    directionsChoicesOS: { [k: string]: boolean } = Object.keys(directionOptionsOS).reduce((a, v) => {
        // if (v === 'Lateral') return { ...a, [v]: false };
        return { ...a, [v]: true };
    }, {});

    activeWhisker?: WhiskerPosition;

    previewPoints: { side: number; x: number; y: number; color: number }[] = [];

    formStatus = new Variable<number | undefined>(undefined);
    popupVisible = false;
    popupFixed = false;
    selectedPointData: any;
    selectedPointItself: any;
    drawAnimated = new Variable<DrawAnimated | undefined>(undefined);
    drawAnimated2 = new Variable<DrawAnimated | undefined>(undefined);
    originalData: any[] = [];

    logData = new LogData({
        edits: this.diagram.params.edits,
        addEdit: this.diagram.params.addEdit,
        // edits: [],
        // addEdit: (x: any) => {
        //   this.logData.edits.push(x)
        //   return Promise.resolve(this.logData.edits)
        // },
    });

    markedPoints: PointStuff[] = [];

    constructor(private diagram: Diagram) {}
}

export class Diagram {
    style = defaultStyle;

    // config
    private grayPointColor = '#555';
    // private colors = ['#eee', '#f83', '#e44', '#ff5', '#5f0', '#88f', '#00f', '#2D9CDB'];
    private colorNames = ['White', 'Orange', 'Red', 'Yellow', 'Green', 'Blue1', 'Blue2', 'DarkTurquoise'];
    private horizontalScale = 38;
    private verticalScale = 1200;
    private tooltipWidth = 170;
    private tooltipHeight = 60;

    state = new State(this);

    // whatever
    // @ViewChild('saccadeChart') saccadeChart: ElementRef;
    // private lodash: typeof _ = _;
    private margin: any; ///
    private height: number = 0;
    private width: number = 0;
    private chart: any;
    private g: any;
    private x: any;
    private y: any;
    private xAxis: any;
    private yAxis: any;
    private gX: any;
    private gY: any;
    private gBg: any;
    private gLegend: any;
    private zoom: any;
    // private tooltipGroup: any;
    private pointView: any;
    private translate: any = 0;
    private scale: any = 1;

    // gs, children
    private gComboBox: any; // Target;
    private gComboBox2: any; // Target;
    private gPreview: any; // Target;
    private eyeChooser: any; //GroupBox;
    private directionChooserOD: any; //GroupBox;
    private directionChooserOS: any; //GroupBox;
    private controlChooser: any; //GroupBox;
    private controlWhiskers: any; //Whiskers;
    private cummulativeWhiskers: any; //Whiskers;
    private gControlData: any; //Target;
    private gCummulative: any; //Target;

    constructor(public params: DiagramParams) {
        this.zoomed = this.zoomed.bind(this);
        this.createChart();
        this.initChildren();
    }

    // setEdits(xs: any) {
    //   this.state.logData.edits = [...xs]
    // }

    get theme() {
        return this.params.theme;
    }
    // get textColor() { return this.params.textColor }
    // get globals() { return this.params.globals } // XXX: mess used for styling
    get translateService() {
        return this.params.translateService;
    }
    // constructor(private translateService: TranslateService) {
    //     super();
    //     this.theme = globals.theme;
    //     this.textColor = this.theme === 'dark' ? this.globals.textDark : this.globals.textLight;
    // }

    // ngOnInit() {
    //     this.createChart();

    //     if (this.inputData) {
    //         this.addData(this.inputData);
    //     }
    // }

    get activeEyes() {
        let result: string[] = [];
        for (let key in this.state.eyesChoices) {
            let value = this.state.eyesChoices[key];
            if (value) {
                result.push(key);
            }
        }
        if (result.length === ALL_EYES.length) result.push('All');
        return result;
    }
    // all filtered
    isUsedForWhiskersPoint(d: PointData) {
        let { activeEyes } = this;

        if (isOutlier(d)) return false;

        let showEye = activeEyes.includes(d.category);
        let otherPoint = d.eye > 1;
        let showDirection = this.directionMatches(d);

        let result = showEye && showDirection && !otherPoint;
        return result;
    }
    // used for whiskers are hidden here
    isVisiblePoint(d: PointData) {
        if (d.isOutlier) return false;

        let { activeEyes } = this;
        let { activeWhisker } = this.state;

        let xs = this.state.cummulativeData.map((x) => x.x);

        let showAnyway = !activeWhisker ? false : d.distance === activeWhisker.x;

        let usedInWhiskers = xs.includes(d.distance);
        let showEye = activeEyes.includes(d.category);
        // let otherPoint = d.eye > 1;

        let showDirection = this.directionMatches(d);
        let result = showEye && showDirection && (!usedInWhiskers || showAnyway);
        // let result = this.isUsedPoint(d) && !usedInWhiskers;
        return result;
    }

    updateVisibility() {
        d3.selectAll('.All').style('display', (d: any /*PointData*/) => {
            if (this.isVisiblePoint(d)) {
                return 'inherit';
            } else {
                return 'none';
            }
        });
    }
    createChart() {
        /*
            Drawing the skeleton of the chart, lines, legend and buttons
        */
        let vbHeight = (screen.height * 3) / 5 + MORE_SPACE;

        this.chart = d3
            .select(this.params.svg || '#saccadeChart')
            .attr('viewBox', '0 0 ' + screen.width + ' ' + vbHeight)
            .attr('preserveAspectRatio', 'xMinYMin meet');

        let rightSpace = 450 + 50;

        this.margin = {
            top: 50, //screen.height * (10 / 100),
            // right: screen.width * (10 / 100) + 200,
            right: rightSpace,
            bottom: 0,
            left: screen.width * (5 / 100),
        };

        this.width = screen.width - this.margin.left - this.margin.right;
        this.height = vbHeight - this.margin.top - 100; // screen.height * (60 / 100) - this.margin.top + MORE_SPACE;

        // Attaching the zoom function to the chart
        this.zoom = d3
            .zoom()
            .scaleExtent([1, 40])
            .translateExtent([
                [0, 0],
                [this.width, this.height],
            ])
            .extent([
                [0, 0],
                [this.width, this.height],
            ])
            .on('zoom', this.zoomed);

        // Range of data which will be represented on the chart
        this.x = d3
            .scaleLinear()
            .domain([0, this.horizontalScale])
            .range([0, this.width]);

        this.y = d3
            .scaleLinear()
            .domain([this.verticalScale, 0])
            .range([0, this.height]);

        // Preparing the values for the X and Y axis
        this.xAxis = d3
            .axisBottom(this.x)
            .ticks(TICKS_X_COUNT)
            // .ticks(((this.width + 2) / (this.height + 2 - screen.height * (10 / 100))) * 10)
            .tickSize(this.height)
            .tickPadding(10);

        this.yAxis = d3
            .axisRight(this.y)
            .tickValues(d3.range(0, this.verticalScale + 1, Y_STEP))
            .tickSize(0)
            .tickPadding(-30 - 20);

        // Positioning the chart
        this.g = this.chart.append('g').attr('transform', 'translate(' + this.margin.left + ',' + 1.5 * this.margin.top + ')');

        this.gBg = this.g.append('g').attr('class', 'gBg');

        this.gLegend = this.g.append('g').attr('class', 'gLegend');

        this.gCummulative = this.g.append('g');

        // Drawing the axes of the chart
        this.gX = this.g
            .append('g')
            .attr('class', 'axis axis--x')
            .call(this.xAxis);

        this.gY = this.g
            .append('g')
            .attr('class', 'axis axis--y')
            .call(this.yAxis);

        axisStyle(this.gX);
        axisStyle(this.gY);

        // let yStep = Math.abs(this.y(Y_STEP) - this.y(0));
        // d3.selectAll('.axis--x').style('stroke-dasharray', '2 ' + (yStep - 2));
        // d3.selectAll('.axis--x').style('stroke-dasharray', '0 99999')
        d3.selectAll('.axis--x .tick line').style('visibility', 'hidden');
        d3.selectAll('.axis--x path').style('visibility', 'hidden');
        d3.selectAll('.axis--y path').style('visibility', 'hidden');

        // Adding labels for the axes
        let label1 = this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width - 30 - 20 + 10) + ', ' + (this.height + 35 + 15 + 5 - 14) + ')')
            .style('text-anchor', 'middle')
            // .style('fill', this.style.text.color)
            .text(_.upperCase(this.translateService.instant('Saccade')) + ' (DEG)');
        applyFont2(label1);

        let label2 = this.g
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -70 - 15 + 5)
            .attr('x', 0 - this.height / 2)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            // .style('fill', this.style.text.color)
            .text(_.upperCase(this.translateService.instant('Peak Velocity')) + ' (DEG/S)');
        applyFont2(label2);

        /*********
         LEGEND
         *********/

        // < > v ^ (0..3) (4,5 stuff, 6 - outlier)
        /*
        // Left to right
        this.g
            .append('line')
            .style('stroke', this.colors[0])
            .attr('x1', this.width + 30)
            .attr('y1', 20)
            .attr('x2', this.width + 60)
            .attr('y2', 20)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[0])
            .attr('x1', this.width + 50)
            .attr('y1', 10)
            .attr('x2', this.width + 60)
            .attr('y2', 20)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[0])
            .attr('x1', this.width + 50)
            .attr('y1', 30)
            .attr('x2', this.width + 60)
            .attr('y2', 20)
            .attr('stroke-width', 2);

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 50)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Horizontal Saccades'));

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 65)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Left to Right'));

        // Right to left
        this.g
            .append('line')
            .style('stroke', this.colors[1])
            .attr('x1', this.width + 30)
            .attr('y1', 120)
            .attr('x2', this.width + 60)
            .attr('y2', 120)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[1])
            .attr('x1', this.width + 30)
            .attr('y1', 120)
            .attr('x2', this.width + 40)
            .attr('y2', 130)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[1])
            .attr('x1', this.width + 30)
            .attr('y1', 120)
            .attr('x2', this.width + 40)
            .attr('y2', 110)
            .attr('stroke-width', 2);

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 150)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Horizontal Saccades'));

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 165)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Right to left'));

        // Downwards
        this.g
            .append('line')
            .style('stroke', this.colors[2])
            .attr('x1', this.width + 45)
            .attr('y1', 200)
            .attr('x2', this.width + 45)
            .attr('y2', 230)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[2])
            .attr('x1', this.width + 35)
            .attr('y1', 220)
            .attr('x2', this.width + 45)
            .attr('y2', 230)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[2])
            .attr('x1', this.width + 55)
            .attr('y1', 220)
            .attr('x2', this.width + 45)
            .attr('y2', 230)
            .attr('stroke-width', 2);

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 250)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Vertical Saccades'));

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 265)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Downwards'));

        // Upwards
        this.g
            .append('line')
            .style('stroke', this.colors[3])
            .attr('x1', this.width + 45)
            .attr('y1', 300)
            .attr('x2', this.width + 45)
            .attr('y2', 330)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[3])
            .attr('x1', this.width + 45)
            .attr('y1', 300)
            .attr('x2', this.width + 35)
            .attr('y2', 310)
            .attr('stroke-width', 2);

        this.g
            .append('line')
            .style('stroke', this.colors[3])
            .attr('x1', this.width + 45)
            .attr('y1', 300)
            .attr('x2', this.width + 55)
            .attr('y2', 310)
            .attr('stroke-width', 2);

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 350)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Vertical Saccades'));

        this.g
            .append('text')
            .attr('transform', 'translate(' + (this.width + 30) + ', 365)')
            .style('text-anchor', 'start')
            .style('fill', this.style.text.color)
            .text(this.translateService.instant('Upwards'));
*/
        /*******
         ZOOM
         *******/
        /*
        let somedY = -18;
        let textdY = -35 + somedY;
        let buttondY = -58 + somedY;

        this.g
            .append('text')
            .attr('id', 'zoomPercent')
            .attr('transform', 'translate(' + (this.width - 120) + `, ${textdY})`)
            .style('text-anchor', 'middle')
            .style('fill', this.style.text.color)
            .text('100%');

        // Positioning zoom buttons
        this.g
            .append('g')
            .classed('svgButton', true)
            .attr('transform', 'translate(' + (this.width - 50) + ` , ${buttondY}) scale(0.35, 0.35)`)
            .append('g')
            .style('stroke-width', '4')
            .style('fill', 'rgba(0,0,0,0)')
            .style('stroke', this.style.zoomBtn.color)
            .append('path')
            .attr('d', 'M 25 50 L 75 50 M 50 25 L 50 75 M 50 5 A 45 45 0 0 0 50 95 A 45 45 0 1 0 5 50')
            .on('click', () => {
                this.zoomIn();
            });

        this.g
            .append('g')
            .classed('svgButton', true)
            .attr('transform', 'translate(' + (this.width - 95) + ` , ${buttondY}) scale(0.35, 0.35)`)
            .append('g')
            .style('stroke-width', '4')
            .style('fill', 'rgba(0,0,0,0)')
            .style('stroke', this.style.zoomBtn.color)
            .append('path')
            .attr('d', 'M 25 50 L 75 50 M 50 25 M 50 5 A 45 45 0 0 0 50 95 A 45 45 0 1 0 5 50')
            .on('click', () => {
                this.zoomOut();
            });
            */

        const chartBox = this.g; //.append('g')
        // const chartBox = this.g
        //     .append('svg')
        //     .attr('width', this.width)
        //     .attr('height', this.height);

        /*
        this.tooltipGroup = chartBox.append('g');

        this.tooltipGroup
            .append('rect')
            .attr('x', 5)
            .attr('y', -5)
            .attr('width', this.tooltipWidth)
            .attr('height', this.tooltipHeight)
            .style('fill', 'none')
            .style('stroke', 'none')
            .style('stroke-dasharray', this.makeCorners(10, this.tooltipWidth, this.tooltipHeight));

        this.tooltipGroup
            .append('text')
            .attr('transform', 'translate(15, 20)')
            .style('text-anchor', 'start')
            .attr('class', 'layerText')
            // .style('fill', this.style.tooltipLabelText.color)
            .style('display', 'none')
            .text(this.translateService.instant('Saccade') + ': ');

        this.tooltipGroup
            .append('text')
            .attr('id', 'saccade')
            .attr('transform', 'translate(75, 20)')
            .style('text-anchor', 'start')
            .attr('class', 'layerText')
            // .style('fill', this.style.text.color);

        this.tooltipGroup
            .append('text')
            .attr('transform', 'translate(15, 45)')
            .style('text-anchor', 'start')
            .attr('class', 'layerText')
            // .style('fill', this.style.tooltipLabelText.color)
            .style('display', 'none')
            .text(this.translateService.instant('Peak velocity') + ': ');

        this.tooltipGroup
            .append('text')
            .attr('id', 'peak')
            .attr('transform', 'translate(105, 45)')
            .style('text-anchor', 'start')
            .attr('class', 'layerText')
            // .style('fill', this.style.text.color);

        this.tooltipGroup
            .append('text')
            .attr('id', 'type')
            .attr('transform', 'translate(15, 70)')
            .style('text-anchor', 'start')
            .style('fill', this.style.tooltipprimaryText.color);
*/

        this.pointView = chartBox.append('g');

        this.gComboBox = this.g.append('g').attr('transform', translate({ x: this.width + 25, y: 200 + 30 }));
        this.gComboBox2 = this.g.append('g').attr('transform', translate({ x: this.width + 25, y: 200 + 30 + 270 + 10 }));
        // .attr('transform', translate({ y: -70 }))
        this.gControlData = this.g.append('g');
        this.gPreview = this.g.append('g').attr('transform', translate({ x: this.width + 40, y: 217 }));

        this.chart
            .call(this.zoom)
            .on('dblclick.zoom', null)
            .on('wheel.zoom', null);

        ///////////////////////////////////////
        this.popupPlace = this.g.append('g').attr('class', 'popupPlace');
        let popupDelta = -10;
        this.popupX = this.width + 25 + popupDelta;
        this.popupY = 200 + 30 + popupDelta;

        ChartBg({
            target: this.gBg,
            ticksCountX: TICKS_X_COUNT,
            scale: {
                x: this.x,
                y: this.y,
            },
        });

        let legendPoint = {
            x: this.x.range()[1],
            y: this.y.range()[0],
        };

        Legend({
            point: legendPoint,
            target: this.gLegend,
        });
    }
    popupPlace!: Target;
    popupX!: number;
    popupY!: number;

    // Helper functions that control zooming
    zoomed() {
        const t = d3.event.transform;
        this.translate = t.x / t.k;
        this.scale = t.k;
        this.g.select('#zoomPercent').text(Math.floor(t.k * 100) + '%');

        this.pointView.attr('transform', 'scale(' + t.k + ',1) translate(' + this.translate + ',0)');
        this.pointView.selectAll('ellipse').attr('rx', 4 / t.k);

        this.gX.call(this.xAxis.scale(t.rescaleX(this.x)));
    }

    zoomIn() {
        const currentScale = d3.zoomTransform(this.chart.node());
        const nextScale = currentScale.k + 0.1;
        this.zoom.scaleTo(this.chart, nextScale);
    }

    zoomOut() {
        const currentScale = d3.zoomTransform(this.chart.node());
        const nextScale = currentScale.k - 0.1;
        this.zoom.scaleTo(this.chart, nextScale);
    }

    /*
        Makes corners for the tooltip
    */
    makeCorners(cornerSize: any, width: any, height: any) {
        return (
            cornerSize +
            ',' +
            (width - 2 * cornerSize) +
            ',' +
            2 * cornerSize +
            ',' +
            (height - 2 * cornerSize) +
            ',' +
            2 * cornerSize +
            ',' +
            (width - 2 * cornerSize) +
            ',' +
            2 * cornerSize +
            ',' +
            (height - 2 * cornerSize) +
            ',' +
            cornerSize
        );
    }

    addPoint(data: PointData) {
        let eye = data.category;
        this.state.addedPoints.push(data);
        let target;

        if (isOutlier(data)) {
            target = this.pointView
                .selectAll('.ellipses')
                .data([data])
                .enter()
                .append('g');

            let move = (d: PointData) => translate({ x: this.x(d.distance), y: this.y(d.velocity) });

            target
                .append('circle')
                .attr('r', 10)
                .attr('fill', 'transaparent')
                .attr('stroke-opacity', 0)
                .attr('transform', (d: PointData) => move(d));

            target
                .append('path')
                .attr('d', drawCross)
                .attr('transform', (d: PointData) => move(d) + ' scale(7)')
                .style('stroke-width', 2)
                .style('vector-effect', 'non-scaling-stroke');
        } else {
            target = this.pointView
                .selectAll('.ellipses')
                .data([data])
                .enter()
                .append('ellipse')
                .attr('cx', (d: any) => {
                    return this.x(d.distance);
                })
                .attr('cy', (d: any) => {
                    return this.y(d.velocity);
                })
                .attr('rx', 4)
                .attr('ry', 4);
        }

        target
            // .style('stroke', (d: any) => {
            //     return this.colors[d.direction];
            // })
            .attr('class', (d: any) => eye + ' All ' + 's' + this.colorNames[d.direction])
            .style('display', 'none')
            .style('fill', 'transparent')
            .style('stroke-width', 2)
            .style('vector-effect', 'non-scaling-stroke')
            .on('mousedown', (d: any) => {
                this.mouseDown(d);
            })
            .on('mouseenter', (d: any) => {
                const x = this.x.invert(d3.mouse(d3.event.currentTarget)[0]);
                const y = this.y.invert(d3.mouse(d3.event.currentTarget)[1]);

                d3.selectAll('.All').style('stroke-opacity', (dInside: any) => {
                    if (_.isEqual(d, dInside)) {
                        return 1;
                    }

                    return 0.2;
                });

                const dX = this.x(this.horizontalScale / this.scale) - (this.x(d.distance) + this.translate);
                const dY = -(this.y(this.verticalScale) - this.y(d.velocity));

                let popupMarginX = 10;
                const actualX = d3.mouse(d3.event.currentTarget)[0];
                const actualY = d3.mouse(d3.event.currentTarget)[1];
                this.popupX = actualX + popupMarginX;
                // this.popupY = actualY
                // let omg = this.theme === 'dark' ? this.globals.altDark : this.globals.altLight
                // let omg = this.style.tooltip.color;
                /*
                this.tooltipGroup
                    .attr('transform', () => {
                        if (dX <= this.tooltipWidth && dY <= this.tooltipHeight) {
                            return 'translate(' + ((this.x(x) + this.translate) * this.scale - this.tooltipWidth - 10) + ',' + (this.y(y) + 10) + ')';
                        } else if (dX <= this.tooltipWidth) {
                            return 'translate(' + ((this.x(x) + this.translate) * this.scale - this.tooltipWidth - 10) + ',' + (this.y(y) - this.tooltipHeight - 10) + ')';
                        } else if (dY <= this.tooltipHeight) {
                            return 'translate(' + (this.x(x) + this.translate) * this.scale + ',' + (this.y(y) + 10) + ')';
                        } else {
                            return 'translate(' + (this.x(x) + this.translate) * this.scale + ',' + (this.y(y) - this.tooltipHeight) + ')';
                        }
                    })
                    .select('rect')
                    .attr('class', 'layerBg')
                    .style('fill', null)
                    .style('stroke', null)
                    // .style('fill', omg)
                    // .style('stroke', omg);
                */

                /*
                this.tooltipGroup.selectAll('text').style('display', 'inherit');
                this.tooltipGroup.select('#saccade').text(Math.round(d.distance * 10) / 10 + ' deg');
                this.tooltipGroup.select('#peak').text(Math.round(d.velocity) + ' deg/s');
                */
                this.mouseEnter(d);
            })
            .on('mouseleave', () => {
                d3.selectAll('.All').style('stroke-opacity', 1);

                /*
                this.tooltipGroup
                    .select('rect')
                    .style('fill', 'none')
                    .style('stroke', 'none');

                this.tooltipGroup.selectAll('text').style('display', 'none');
                */
                this.mouseLeave();
            });
    }

    closePopup() {
        this.state.popupFixed = false;
        this.state.popupVisible = false;
        this.state.markedPoints = [];
        this.state.formStatus.set(undefined);
        this.renderPopup();
    }

    mouseDown(x: SelectedPoint) {
        this.closePopup();
        this.mouseEnter(x);
        this.state.popupFixed = true;
        this.renderPopup();
    }

    mouseEnter(x: SelectedPoint) {
        if (this.state.popupVisible) return;

        let data = this.newState.getPointData(x);
        this.state.selectedPointData = data;
        this.state.selectedPointItself = x;
        this.state.markedPoints = [x];
        this.state.popupVisible = true;
        this.renderPopup();
        this.scheduleRendering();
    }

    mouseLeave() {
        if (!this.state.popupFixed) this.closePopup();
    }

    renderPopup() {
        // let velocity = this.state.selectedPointItself.velocity.toFixed(0)
        let srt = 0;
        if (this.state.selectedPointData) {
            srt = this.state.selectedPointData.srt;
        }

        let velocity = Math.round(this.state.selectedPointItself.velocity);
        let title = `SRT: ${srt} ms\nPeak velocity: ${velocity} deg/s`;
        let id = this.state.selectedPointItself.timestamp;
        let isOutlier = this.state.selectedPointItself.isOutlier;
        let originalIsOutlier = this.state.selectedPointItself.originalIsOutlier;
        Popup({
            show: this.state.popupVisible,
            target: this.popupPlace,
            centerColor: 'green',
            formStatus: this.state.formStatus,
            closeCrossClick: () => this.closePopup(),
            x: this.popupX,
            y: this.popupY,
            pointData: this.state.selectedPointData,
            title,
            drawAnimated: this.state.drawAnimated,
            drawAnimated2: this.state.drawAnimated2,
            refresh: () => this.renderPopup(),
            id,
            isOutlier,
            originalIsOutlier,
            changePoint: (id, value) => {
                this.changePoint(id, this.state.selectedPointItself, value);
            },
            logItems: this.state.logData.logItems(id),
        });
    }

    changePoint(id: any, point: any, value: any) {
        // point.isOutlier = value === 1
        let oldValue = point.isOutlier ? 1 : 0;
        this.state.logData.recordEdit(id, oldValue, value).then(() => {
            this.rebuildChart();
        });
    }

    rebuildChart() {
        this.closePopup();
        this.destroy();
        let originalData = this.state.originalData;
        // let prevLog = this.state.logData.edits
        let { activeWhisker } = this.state;
        this.state = new State(this);
        this.newState = new NewState();
        this.createChart();
        this.initChildren();

        // this.state.logData.edits = [...prevLog]
        this.addData(originalData);

        let whiskerX = activeWhisker ? activeWhisker.x : null;
        if (whiskerX && this.cummulativeWhiskers) {
            this.state.activeWhisker = this.cummulativeWhiskers.items.find((x: any) => x.x === whiskerX);
            this.scheduleRendering();
        }
    }

    destroy() {
        this.chart.selectAll('*').remove();
    }

    newState = new NewState();
    addData(frames: any) {
        this.state.originalData.push(...frames.map((x: any) => ({ ...x })));
        this.newState.inputHandler(frames);

        frames.forEach((element) => {
            if (element.message_type === 14) {
                typeObj.type = element.fromTest;
                typeObj.count = element.dotsCount;
            }
        });

        const length = frames.length;
        let tempFrame: any;

        typeObj.od = false;
        typeObj.os = false;

        for (let i = 0; i < length; i++) {
            tempFrame = { ...frames[i] };

            if (tempFrame.message_type === MESSAGE_TYPE.DATA_PACKAGE) {
                const { eye, seen, targettype /*distance, velocity, direction*/ } = tempFrame;

                // let originalIsOutlier = tempFrame.direction === 6
                // let fromLogIsOutlier = this.state.logData.getValue(tempFrame.timestamp)

                // let isOutlier: boolean
                // if (fromLogIsOutlier != null) {
                //   isOutlier = fromLogIsOutlier === 1
                // } else {
                //   isOutlier = originalIsOutlier
                // }
                let outlierInfo = getOutlierInfo(tempFrame, this.state.logData);

                let isSeen = seen > 2;
                let specialPoint = eye === 2;

                let eyeText = '';
                if (targettype === OD && eye === OS) {
                    eyeText = 'OD2';
                }
                if (targettype === OS && eye === OD) {
                    eyeText = 'OS2';
                }
                // if (eye === 'OS2') return 'OD (stimuli: OS)';
                // if (eye === 'OD2') return 'OS (stimuli: OD)';

                if (!eyeText) {
                    if (targettype === OD) {
                        eyeText = 'OD';
                    } else if (targettype === OS) {
                        eyeText = 'OS';
                    }
                }

                if (!specialPoint) {
                    // use own coloring
                    if (Math.abs(tempFrame.targetx) === 6 && Math.abs(tempFrame.targety) === 6) {
                        tempFrame.direction = 0;
                    } else if (tempFrame.targety > 10) {
                        tempFrame.direction = 3;
                    } else if (tempFrame.targety < -10) {
                        tempFrame.direction = 2;
                    } else {
                        tempFrame.direction = 1;
                    }
                }

                if (!specialPoint) {
                    this.addToPreview(tempFrame);
                }

                if (specialPoint || isSeen) {
                    if (typeObj.type === 'NEUROFIELD') {
                        if (typeObj.count === 16) {
                            if (tempFrame.targety === 0 && tempFrame.targetx < 0) {
                                tempFrame.direction = 1;
                            }
                            if (tempFrame.targety === 0 && tempFrame.targetx > 0) {
                                tempFrame.direction = 0;
                            }
                            if (tempFrame.targetx === 0 && tempFrame.targety > 0) {
                                tempFrame.direction = 3;
                            }
                            if (tempFrame.targetx === 0 && tempFrame.targety < 0) {
                                tempFrame.direction = 2;
                            }
                            if (tempFrame.targetx < 0 && tempFrame.targety > 0) {
                                tempFrame.direction = 7;
                            }
                            if (tempFrame.targetx > 0 && tempFrame.targety < 0) {
                                tempFrame.direction = 4;
                            }
                            if (tempFrame.targetx > 0 && tempFrame.targety > 0) {
                                tempFrame.direction = 5;
                            }
                            if (tempFrame.targetx < 0 && tempFrame.targety < 0) {
                                tempFrame.direction = 6;
                            }
                        } else if (typeObj.count === 64) {
                            if ((tempFrame.targetx === 3 || tempFrame.targetx === -3) && tempFrame.targety > 0) {
                                tempFrame.direction = 3;
                            }
                            if ((tempFrame.targety === 3 || tempFrame.targety === -3) && tempFrame.targetx < 0) {
                                tempFrame.direction = 1;
                            }
                            if ((tempFrame.targety === 3 || tempFrame.targety === -3) && tempFrame.targetx > 0) {
                                tempFrame.direction = 0;
                            }
                            if ((tempFrame.targetx < -3 && tempFrame.targety > 3) || (tempFrame.targetx === -3 && tempFrame.targety === 3)) {
                                tempFrame.direction = 7;
                            }
                            if ((tempFrame.targetx === 3 || tempFrame.targetx === -3) && tempFrame.targety < 0) {
                                tempFrame.direction = 2;
                            }
                            if ((tempFrame.targetx > 3 && tempFrame.targety < -3) || (tempFrame.targetx === 3 && tempFrame.targety === -3)) {
                                tempFrame.direction = 4;
                            }
                            if ((tempFrame.targetx < -3 && tempFrame.targety < -3) || (tempFrame.targetx === -3 && tempFrame.targety === -3)) {
                                tempFrame.direction = 6;
                            }
                            if ((tempFrame.targetx > 3 && tempFrame.targety > 3) || (tempFrame.targetx === 3 && tempFrame.targety === 3)) {
                                tempFrame.direction = 5;
                            }
                        }
                    }

                    this.addPoint({
                        ...tempFrame,
                        category: eyeText,
                        // isOutlier,
                        // originalIsOutlier,
                        ...outlierInfo,
                    });
                }
            }
        }

        this.refresh();
    }

    addToPreview(data: PointData) {
        let previewPoint = { side: data.targettype, x: data.targetx, y: data.targety, color: data.direction };

        if (previewPoint.side !== undefined && previewPoint.side === 0) {
            typeObj.od = true;
        }

        if (previewPoint.side !== undefined && previewPoint.side === 1) {
            typeObj.os = true;
        }

        this.state.previewPoints.push(previewPoint);
    }

    eyeToTitle(eye: string) {
        if (eye === 'OD') return 'OD';
        if (eye === 'OS') return 'OS';
        if (eye === 'OS2') return 'OD (stimuli: OS)';
        if (eye === 'OD2') return 'OS (stimuli: OD)';
        if (eye === 'All') return 'All';
        return '?';
    }

    // getEyeTitle() {
    //   return this.eyeToTitle(this.activeEye)
    // }
    // getControlTitle() {
    //   return this.ageControlGroup.name || 'Control Groups'
    // }
    // getDirectionTitle() {
    //   return this.directionChosen.name
    // }

    initChildren() {
        let comboHeight = 50; // not sure why difference (maybe random)
        let optionHeight = 30; // style related? (no need for default yet)

        // comboProps is a bit magic now
        let comboProps = {
            height: comboHeight,
            theme: this.theme,
            // isOpened: true,
            alwaysOpened: true,
        };
        let layout = new DirectedLayout('x', 'width', 0, 200, 15, comboProps);
        let layout2 = new DirectedLayout('x', 'width', 0, 200, 15, comboProps);

        let tickScale = 20;
        let makeEyeOption = (eye: string) => {
            return new GroupOption({
                text: this.eyeToTitle(eye),
                textAlign: 'left',
                handler: () => this.handleEyeChooser(eye),
                height: optionHeight,
                theme: this.theme,
                getChecked: () => this.activeEyes.includes(eye),
                tickScale,
            });
        };
        let makeControlOption = (key: string) => {
            let value = ageControlData[key];
            let textAlign = key === CLEAR_SELECTION ? 'center' : 'left';
            let getChecked: any = () => this.state.ageControlGroup.name === key;

            if (key === CLEAR_SELECTION) getChecked = undefined;

            return new GroupOption({
                text: key,
                textAlign,
                handler: () => {
                    this.handleControlChooser(key, value);
                },
                height: optionHeight,
                theme: this.theme,
                getChecked,
                tickScale,
            });
        };

        let makeDirectionOption = (name: string) => {
            return new GroupOptionArrow({
                text: name,
                textAlign: 'left',
                handler: () => {
                    this.handleDirectionChooser(name);
                },
                height: optionHeight,
                theme: this.theme,
                doNotClose: true,
                getChecked: () => this.isDirectionChecked(name),
                tickScale,
            });
        };
        // let makeOption = (abc: string) => {
        //     return new GroupOption({
        //         text: abc,
        //         handler: () => { console.log(typeof abc) },
        //         height: optionHeight,
        //         theme: this.theme,
        //     })
        // }
        let fakeOption = () => {
            return new GroupOption({
                text: '',
                handler: () => {},
                height: optionHeight,
                theme: this.theme,
            });
        };

        this.eyeChooser = new GroupBox({
            ...layout.take(),
            area: { top: -230, left: 245 },
            title: 'Eyes Type', //this.getEyeTitle(),
            children: ['All', 'OD', 'OS', 'OD2', 'OS2'].map(makeEyeOption).concat([1, 2].map(fakeOption)),
        });

        this.directionChooserOD = new GroupBox({
            ...layout.take(),
            area: { top: 180, left: 245 },
            children: ['AllOD'].concat(Object.keys(directionOptionsOD)).map(makeDirectionOption),
        });
        this.directionChooserOS = new GroupBox({
            ...layout.take(),
            area: { top: 180, left: 14 },
            children: ['AllOS'].concat(Object.keys(directionOptionsOS)).map(makeDirectionOption),
        });

        layout2.take();
        // this.controlChooser = new GroupBox({
        //     ...layout2.take(),
        //     title: 'Control data', //this.getControlTitle(),
        //     children: Object.keys(ageControlData)
        //         .concat(CLEAR_SELECTION)
        //         .map(makeControlOption),
        // });

        let movedScale = (scale: Scale, dx: number) => scale.copy().range([scale.range()[0] + dx, scale.range()[1] + dx]);

        this.controlWhiskers = new Whiskers({
            x: movedScale(this.x, -20),
            arrowdx: +20 - 2,
            y: this.y,
            // halfWidth: 0.25,
            theme: this.theme,
            halfWidth: 0.1,
            noMinLine: true,
            noMaxLine: true,
            style: {
                // whisker: { color: '#f0a' },
                // whisker: { color: '#aaf' },
                // whisker: { color: '#33e' },
                whisker: { color: '#66a' },
                // whisker: { color: '#555' },
                whiskerMean: {
                    color: '#fee',
                    scaleInside: 0,
                    scaleOutside: 0,
                },
            },
        });
        this.cummulativeWhiskers = new Whiskers({
            x: this.x,
            y: this.y,
            halfWidth: 0.25,
            theme: this.theme,
            onMouseDown: (whisker) => {
                if (this.state.activeWhisker && this.state.activeWhisker.x === whisker.x) {
                    this.state.activeWhisker = undefined;
                } else {
                    this.state.activeWhisker = whisker;
                }
                this.scheduleRendering();
            },
        });
    }
    scheduleRendering() {
        window.requestAnimationFrame(() => {
            this.render();
        });
    }

    isDirectionChecked(name: string) {
        if (name.match(/OD/)) {
            if (name === 'AllOD') {
                let result = !Object.values(this.state.directionsChoicesOD).includes(false);

                return result;
            }

            return this.state.directionsChoicesOD[name];
        }

        if (name.match(/OS/)) {
            if (name === 'AllOS') {
                let result = !Object.values(this.state.directionsChoicesOS).includes(false);

                return result;
            }

            return this.state.directionsChoicesOS[name];
        }
    }
    selectAllEyes() {
        ALL_EYES.forEach((eye) => {
            this.state.eyesChoices[eye] = true;
        });
    }
    unSelectAllEyes() {
        ALL_EYES.forEach((eye) => {
            this.state.eyesChoices[eye] = false;
        });
    }
    handleEyeChooser(eye: string) {
        if (eye === 'All') {
            let isChecked = this.activeEyes.includes(eye);
            if (isChecked) {
                this.unSelectAllEyes();
            } else {
                this.selectAllEyes();
            }
        } else {
            this.state.eyesChoices[eye] = !this.state.eyesChoices[eye];
            // this.activeEye = eye;
        }
        this.refresh();
    }
    handleControlChooser(key: string, value: AgeControlWhisker[]) {
        if (key === CLEAR_SELECTION) {
            this.state.ageControlGroup = NO_CONTROL_SELECTED;
        } else {
            this.state.ageControlGroup = {
                name: key,
                items: value,
            };
        }
        this.refresh();
    }
    selectAllDirections(eye: string) {
        if (eye === 'OD') {
            ALL_DIRECTIONSOD.forEach((eye) => {
                this.state.directionsChoicesOD[eye] = true;
            });
        }

        if (eye === 'OS') {
            ALL_DIRECTIONSOS.forEach((eye) => {
                this.state.directionsChoicesOS[eye] = true;
            });
        }
    }
    unSelectAllDirections(eye: string) {
        if (eye === 'OD') {
            ALL_DIRECTIONSOD.forEach((eye) => {
                this.state.directionsChoicesOD[eye] = false;
            });
        }

        if (eye === 'OS') {
            ALL_DIRECTIONSOS.forEach((eye) => {
                this.state.directionsChoicesOS[eye] = false;
            });
        }
    }
    handleDirectionChooser(name: string) {
        if (name.match(/OD/)) {
            if (name === 'AllOD') {
                if (this.isDirectionChecked(name)) {
                    this.unSelectAllDirections('OD');
                } else {
                    this.selectAllDirections('OD');
                }
            } else {
                this.state.directionsChoicesOD[name] = !this.state.directionsChoicesOD[name];
            }
        }

        if (name.match(/OS/)) {
            if (name === 'AllOS') {
                if (this.isDirectionChecked(name)) {
                    this.unSelectAllDirections('OS');
                } else {
                    this.selectAllDirections('OS');
                }
            } else {
                this.state.directionsChoicesOS[name] = !this.state.directionsChoicesOS[name];
            }
        }

        this.refresh();
    }
    directionMatches(d: PointData) {
        let { itemsOD } = this.state.directionChosenOD;
        let { itemsOS } = this.state.directionChosenOS;
        let value = d.direction;

        if (d.category === 'OD' || d.category === 'OD2') {
            return this.intervalsCover(itemsOD, value);
        }

        if (d.category === 'OS' || d.category === 'OS2') {
            return this.intervalsCover(itemsOS, value);
        }
    }
    intervalsCover(items: FromTo[], value: number) {
        for (let item of items) {
            let { from, to } = item;

            if (value >= from && value <= to) return true;
        }
        return false;
    }
    recalcDirectionChosen() {
        let itemsOD: FromTo[] = [];
        let itemsOS: FromTo[] = [];

        for (let key in this.state.directionsChoicesOD) {
            let isActive = this.state.directionsChoicesOD[key];
            if (!isActive) continue;
            let xs = directionOptionsOD[key];
            itemsOD.push(...xs);
        }
        let name = DIRECTIONS_TITLE;

        this.state.directionChosenOD = { name, itemsOD };

        for (let key in this.state.directionsChoicesOS) {
            let isActive = this.state.directionsChoicesOS[key];
            if (!isActive) continue;
            let xs = directionOptionsOS[key];
            itemsOS.push(...xs);
        }

        this.state.directionChosenOS = { name, itemsOS };
    }
    recalcCummulativeWhiskers() {
        let usedPoints = this.state.addedPoints.filter((x) => this.isUsedForWhiskersPoint(x));

        let groups: Map<number, PointData[]> = new Map();
        for (let data of usedPoints) {
            let { distance } = data;
            if (groups.has(distance)) {
                groups.get(distance)?.push(data);
            } else {
                groups.set(distance, []);
                groups.get(distance)?.push(data);
            }
        }
        let result: WhiskerPosition[] = [];
        for (let [key, xs] of Array.from(groups.entries())) {
            if (xs.length >= WHISKERS_TBD) {
                let yValues = xs.map((x) => x.velocity);
                yValues = _.sortBy(yValues);

                let [min, max]: [any, any] = d3.extent(yValues);
                let v25: any = d3.quantile(yValues, 0.25);
                let v50: any = d3.quantile(yValues, 0.5);
                let v75: any = d3.quantile(yValues, 0.75);

                let ys: [number, number, number, number, number] = [min, v25, v50, v75, max];
                let meany: any = d3.mean(yValues);

                let whisker = { x: key, ys, meany };
                result.push(whisker);
            }
        }
        this.state.cummulativeData = result;
    }
    updateCalculated() {
        this.recalcDirectionChosen();
        this.recalcCummulativeWhiskers();
    }
    updateChildren() {
        // if (this.eyeChooser) this.eyeChooser.title = this.getEyeTitle();
        // if (this.controlChooser) this.controlChooser.title = this.getControlTitle();
        // if (this.directionChooser) this.directionChooser.title = this.getDirectionTitle();

        if (this.controlWhiskers) this.controlWhiskers.items = this.state.ageControlGroup.items;

        if (this.cummulativeWhiskers) this.cummulativeWhiskers.items = this.state.cummulativeData;
    }
    renderChildren() {
        this.gComboBox.selectAll('g').remove();

        this.updateVisibility();

        this.eyeChooser?.render(this.gComboBox);
        this.controlChooser?.render(this.gComboBox2);
        this.controlWhiskers?.render(this.gControlData);
        this.cummulativeWhiskers?.render(this.gCummulative);

        if (typeObj.od) {
            this.directionChooserOD?.render(this.gComboBox);
        }

        if (typeObj.os) {
            this.directionChooserOS?.render(this.gComboBox);
        }
    }
    refresh() {
        this.updateCalculated();
        this.updateChildren();
        this.renderChildren();

        let usedPoints = this.state.addedPoints.filter((x) => this.isUsedForWhiskersPoint(x));
        let preview = new DirectionsPreview({ colorNames: this.colorNames, usedPoints, markedPoints: this.state.markedPoints });
        preview.render(this.gPreview, this.state.previewPoints);

        let directionTitle = (xTranslate: string) => {
            this.gPreview
                .selectAll('text')
                .data([null])
                .join('text')
                .text('DIRECTIONS')
                .attr('font-size', 16)
                .attr('font-weight', 700)
                .attr('transform', translate({ x: xTranslate, y: 232 }));
        };

        if (!typeObj.os) {
            directionTitle(283);
        } else if (!typeObj.od) {
            directionTitle(53);
        } else {
            directionTitle(168);
        }
    }
    render() {
        this.refresh();
    }
    clearState() {
        d3.selectAll('.All').remove();
        this.state = new State(this);
    }
}

interface SelectedPoint {
    targettype: number;
    targetx: number;
    targety: number;
}
