// @ts-nocheck
export enum Choice {
  OD,
  OS,
  Both,
}

export class CornerButtons {
  choice = Choice.Both

  availability = {
    od: true,
    os: true,
    both: true,
  }

  get showOD() {
    return this.choice === Choice.OD || this.choice === Choice.Both
  }

  get showOS() {
    return this.choice === Choice.OS || this.choice === Choice.Both
  }

  get showOne() {
    return this.choice !== Choice.Both
  }
  get showSingle() { return this.showOne }

  get showTwo() {
    return this.choice === Choice.Both
  }
  get showBoth() { return this.showTwo }

  get eyesChosen() {
    let result: string[] = []
    if (this.showOD) result.push('OD')
    if (this.showOS) result.push('OS')
    return result
  }
}
