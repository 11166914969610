import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IDryEyeForus3NethraClassicMeasuredData } from '../../../../interfaces/measuredData/FORUS/3NethraClassic/dry-eye-forus3Nethra-classic.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { SmartImage } from '../../../smartImage.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class DryEyeForus3NethraClassicMeasuredData implements MeasuredData, IDryEyeForus3NethraClassicMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public eyelids: {
        OS: RegisterEditField<string>;
        OD: RegisterEditField<string>;
    };
    public meibomianGlands: {
        OS: RegisterEditField<string>;
        OD: RegisterEditField<string>;
    };
    public conjuctiva: {
        OS: RegisterEditField<string>;
        OD: RegisterEditField<string>;
    };
    public palpebral: {
        OS: RegisterEditField<string>;
        OD: RegisterEditField<string>;
    };
    public bulbar: {
        OS: RegisterEditField<string>;
        OD: RegisterEditField<string>;
    };
    public tearFilm: {
        OS: RegisterEditField<string>;
        OD: RegisterEditField<string>;
    };
    public images: {
        OD: SmartImage[];
        OS: SmartImage[];
    };
    constructor() {
        this.testType = TEST_TYPE.DRY_EYE_EVALUATION;
        this.sourceDevice = DEVICE.FORUS_3NETHRA;
    }
    public abstract setModel(model: IDryEyeForus3NethraClassicMeasuredData): void;
    public getModel(): IDryEyeForus3NethraClassicMeasuredData {
        const model: IDryEyeForus3NethraClassicMeasuredData = {
            sourceFileName: this.sourceFileName,
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            eyelids: {
                OS: this.eyelids.OD.getModel(),
                OD: this.eyelids.OS.getModel(),
            },
            meibomianGlands: {
                OS: this.meibomianGlands.OD.getModel(),
                OD: this.meibomianGlands.OS.getModel(),
            },
            conjuctiva: {
                OS: this.conjuctiva.OD.getModel(),
                OD: this.conjuctiva.OS.getModel(),
            },
            palpebral: {
                OS: this.palpebral.OD.getModel(),
                OD: this.palpebral.OS.getModel(),
            },
            bulbar: {
                OS: this.bulbar.OD.getModel(),
                OD: this.bulbar.OS.getModel(),
            },
            tearFilm: {
                OS: this.tearFilm.OD.getModel(),
                OD: this.tearFilm.OS.getModel(),
            },
            images: {
                OD: this.images.OD.map(i => i.model),
                OS: this.images.OS.map(i => i.model),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.images.OD.length === 0 &&
            this.images.OS.length === 0 &&
            this.bulbar.OD.isEmpty() &&
            this.bulbar.OS.isEmpty() &&
            this.conjuctiva.OD.isEmpty() &&
            this.conjuctiva.OS.isEmpty() &&
            this.eyelids.OD.isEmpty() &&
            this.eyelids.OS.isEmpty() &&
            this.meibomianGlands.OD.isEmpty() &&
            this.meibomianGlands.OS.isEmpty() &&
            this.palpebral.OD.isEmpty() &&
            this.palpebral.OS.isEmpty() &&
            this.tearFilm.OD.isEmpty() &&
            this.tearFilm.OS.isEmpty()
        );
    }
}
