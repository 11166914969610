/**
 * After patient's measurement investigation user can:
 * - tag measurement with some keyword in order to find it later
 * - comment measurement results
 * - create IDC10 diagnosis
 * All these entities will be stored in DB and could be showed on UI in one component.
 * This enum provides type of entity so UI can determine how to show it: as a tag, comment or IDC10 diagnosis
 */
export enum IDC_ITEM_TYPE {
    DIAGNOSIS = 'DIAGNOSIS',
    TAG = 'TAG',
    COMMENT = 'COMMENT',
}
