// @ts-nocheck
import * as d3 from 'd3';
import { Target } from '..';
import { getStyle, RawPoint } from './util';
import { Scale2D } from '../types';
import { IDataSet } from './AChart';
import * as d3Regression from 'd3-regression';


const styleDefault = {
	lineSize: 3,
	class: '',
	round: false,
	dasharray: '',
	opacity: '100%',
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {};

interface Params<D> {
	makeSeries: (a: string, c: Target) => Target;
	positioning: (p: D, b: IDataSet<D>) => RawPoint;
	coloring: (p: D, b: IDataSet<D>) => string;
	scale: Scale2D;
	filter?: (d: any) => boolean;
	filtering: (d: any) => boolean;
	defined?: (d: any) => boolean;
	regressionQuad?: boolean;
	regressionLinear?: boolean;
	regressionLoess?: number | boolean;
	target: Target;
	styleParams?: {
		theme?: string;
		override?: Partial<Style>;
	};
}


export function ChartLines<D>(p: Params<D>) {
	let style = getStyle(styleDefault, themeOverrides, p.styleParams);
	let filter = p.filter || (() => true);
	let gSeries = p.makeSeries('chart-lines-series', p.target);
	let preProcessing = (xs: RawPoint[]) => xs;

	if (p.regressionQuad || p.regressionLinear || p.regressionLoess) {
		let regressionGenerator: any;

		if (p.regressionQuad) {
			regressionGenerator = d3Regression
				.regressionQuad()
				.x((p: RawPoint) => p.x)
				.y((p: RawPoint) => p.y);
		}
		if (p.regressionLinear) {
			regressionGenerator = d3Regression
				.regressionLinear()
				.x((p: RawPoint) => p.x)
				.y((p: RawPoint) => p.y);
		}
		if (p.regressionLoess) {
			regressionGenerator = d3Regression
				.regressionLoess()
				.x((p: RawPoint) => p.x)
				.y((p: RawPoint) => p.y);
			if (typeof p.regressionLoess === 'number') {
				regressionGenerator = regressionGenerator.bandwidth(p.regressionLoess);
			}
		}

		preProcessing = (points: RawPoint[]) => {
			let got = regressionGenerator(points) as any;
			let result = got.map(([x, y]: [number, number]) => ({ x, y }));
			return result;
		};
	}

	let defined = p.defined ? p.defined : (x: any) => true;

	let lineGenerator = d3
		.line<RawPoint>()
		.x((d) => p.scale.x(d.x))
		.y((d) => p.scale.y(d.y))
		.defined(defined);

	let klass = style.class; 

	let got = gSeries
		.selectAll('.paths')
		.data((d) => {
			return [{ ...d, filteredPoints: d.dataPoints.filter(p.filtering).filter((x: any) => filter({ dataPoint: x })) }];
		})
		.join('path')
		.attr('class', `paths ${klass ? klass : ''}`)
		.attr('name', 'fixation-dots')
		.attr('d', (d) => {
			let stuff = preProcessing(d.filteredPoints.map((x: any) => p.positioning(x, d)));
			return lineGenerator(stuff);
		})
		.attr('stroke-width', style.lineSize)
		.attr('fill', 'none')
		.attr('stroke', (d) => (d.filteredPoints.length ? p.coloring(d.filteredPoints[0], d) : 'gray'))
		.attr('stroke-dasharray', style.dasharray)
		.attr('opacity', style.opacity);

	if (style.round) {
		// not cancelalable this way, styles are static anyway
		got.attr('stroke-linejoin', 'round').attr('stroke-linecap', 'round');
	}
}
