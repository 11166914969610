// @ts-nocheck
export class Memo {
  cache = {} as any

  access<T>(key: string, fun: () => T): T {
    let cached = this.cache[key] as T
    if (cached != null) {
      return cached
    } else {
      let value = fun()
      this.cache[key] = value
      return value
    }
  }

  invalidate(...keys: string[]) {
    if (keys.length === 0) return this.cache = {}
    keys.forEach(x => {
      this.cache[x] = undefined
    })
  }

  memo(name: string, otherFn: any) {
    return (...a: any) => {
      return this.access(name, () => otherFn(...a))
    }
  }
}
