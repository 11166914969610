import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { from } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { IPupillaryEvaluation2BulbicamMeasuredData } from '../../../../../../common/interfaces/measuredData/BulbiCAM/pupillaryEvaluation2-bulbicam.measuredData.interface';
import { PupillaryEvaluation2BulbicamMeasuredData } from '../../../../../../common/models/measuredData/BulbiCAM/pupillaryEvaluation2BulbicamData.model';
import { MyPupil20BulbicamTestChartComponent } from '../../../_components';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { Pager } from '../../../_services/examination/export.service';
import { FileService } from '../../../_services/general/file.service';
import { BulbicamTestFrontend } from '../../haplotestFrontend.class';
import { RegisterEditFieldFrontend } from '../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../tableReportTestMode';
import { PupillaryEvaluation2BulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/pupillaryEvaluation2BulbicamTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../measuredDataFrontend';

export class PupillaryEvaluation2BulbicamMeasuredDataFrontend extends PupillaryEvaluation2BulbicamMeasuredData implements MeasuredDataFrontend {
    public remarks: PupillaryEvaluation2BulbicamTestRemarksFrontend;
    public haplotests: BulbicamTestFrontend[];
    public OD: {
        size: {
            type: RegisterEditFieldFrontend<string>;
        };
        reactionToLight: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
        rapo: {
            type: RegisterEditFieldFrontend<string>;
        };
        pupilMorphology: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
    };
    public OS: {
        size: {
            type: RegisterEditFieldFrontend<string>;
        };
        reactionToLight: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
        rapo: {
            type: RegisterEditFieldFrontend<string>;
        };
        pupilMorphology: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
    };
    public pupilSize: {
        OD: RegisterEditFieldFrontend<number>;
        OS: RegisterEditFieldFrontend<number>;
    };
    constructor() {
        super();
        this.pupilSize = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.OD = {
            size: {
                type: new RegisterEditFieldFrontend(),
            },
            reactionToLight: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
            rapo: {
                type: new RegisterEditFieldFrontend(),
            },
            pupilMorphology: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
        };
        this.OS = {
            size: {
                type: new RegisterEditFieldFrontend(),
            },
            reactionToLight: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
            rapo: {
                type: new RegisterEditFieldFrontend(),
            },
            pupilMorphology: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
        };
    }
    public setModel(model: IPupillaryEvaluation2BulbicamMeasuredData) {
        this.haplotests = model.haplotests.map(htm => {
            const ht = new BulbicamTestFrontend();
            ht.model = htm;
            return ht;
        });
        this.pupilSize.OD.setModel(model.pupilSize.OD);
        this.pupilSize.OS.setModel(model.pupilSize.OS);
        this.OD.size.type.setModel(model.OD.size.type);
        this.OD.reactionToLight.type.setModel(model.OD.reactionToLight.type);
        this.OD.reactionToLight.select.setModel(model.OD.reactionToLight.select);
        this.OD.rapo.type.setModel(model.OD.rapo.type);
        this.OD.pupilMorphology.type.setModel(model.OD.pupilMorphology.type);
        this.OD.pupilMorphology.select.setModel(model.OD.pupilMorphology.select);
        this.OS.size.type.setModel(model.OS.size.type);
        this.OS.reactionToLight.type.setModel(model.OS.reactionToLight.type);
        this.OS.reactionToLight.select.setModel(model.OS.reactionToLight.select);
        this.OS.rapo.type.setModel(model.OS.rapo.type);
        this.OS.pupilMorphology.type.setModel(model.OS.pupilMorphology.type);
        this.OS.pupilMorphology.select.setModel(model.OS.pupilMorphology.select);
    }
    public async toPDF(
        pdf: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        let table: any = [];
        table.push({
            column1: '',
            column2: { colSpan: 2, content: 'OD', styles: { halign: 'center' } },
            column3: '',
            column4: { colSpan: 2, content: 'OS', styles: { halign: 'center' } },
            column5: '',
        });
        table.push({
            column1: 'Size mm (Bulbicam)',
            column2: this.OD.size.type.value,
            column3: this.pupilSize.OD.value,
            column4: this.OS.size.type.value,
            column5: this.pupilSize.OS.value,
        });
        table.push({
            column1: 'Reaction to Light',
            column2: this.OD.reactionToLight.type.value,
            column3: this.OD.reactionToLight.select.value,
            column4: this.OS.reactionToLight.type.value,
            column5: this.OS.reactionToLight.select.value,
        });
        table.push({
            column1: 'RAPD',
            column2: this.OD.rapo.type.value,
            column3: '',
            column4: this.OS.rapo.type.value,
            column5: '',
        });
        table.push({
            column1: 'Pupil Morphology',
            column2: this.OD.pupilMorphology.type.value,
            column3: this.OD.pupilMorphology.select.value,
            column4: this.OS.pupilMorphology.type.value,
            column5: this.OS.pupilMorphology.select.value,
        });
        const dataURLs: any[] = [];
        if (this.haplotests.length > 0) {
            await from(this.haplotests)
                .pipe(
                    concatMap(async haplotest => {
                        const componentFactory: ComponentFactory<MyPupil20BulbicamTestChartComponent> = componentFactoryResolver.resolveComponentFactory(
                            MyPupil20BulbicamTestChartComponent
                        );
                        const componentRef = viewContainerRef.createComponent(componentFactory);
                        const chart: MyPupil20BulbicamTestChartComponent = componentRef.instance;
                        const responce = await bulbicamService.getHaplotestData(haplotest.haplotestData);
                        await new Promise<void>(async res => {
                            chart.addData(responce.data);
                            setTimeout(() => {
                                res();
                            }, 1500);
                        });
                        const dataUrl = await new Promise<any>(res => {
                            // toPng(chart.pupilChart.nativeElement, { quality: 1, backgroundColor: '#0a425d' }).then(function(dataUrl) {
                            //     viewContainerRef.clear();
                            //     res(dataUrl);
                            // });
                        });
                        dataURLs.push(dataUrl);
                        table.push({
                            column1: { content: 'Measurement created at: ' + new Date(haplotest.createdAt).toLocaleString().toString(), colSpan: 5 },
                            column2: '',
                            column3: '',
                            column4: '',
                            column5: '',
                        });
                        table.push({
                            column1: { colSpan: 5, styles: { minCellHeight: 100 } },
                            column2: '',
                            column3: '',
                            column4: '',
                            column5: '',
                        });
                    }),
                    toArray()
                )
                .toPromise();
        }
        ///
        (pdf as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: <CellHookHandler>(data => {
                if (data.section === 'body' && data.row.index > 4 && data.row.index % 2 === 0 && data.column.dataKey === 'column1') {
                    pdf.addImage(dataURLs[(data.row.index - 6) / 2], 'PNG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                }
            }),
            didDrawPage: () => {},
        });
        pager.line = (pdf as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values = '' + this.pupilSize.OD.value || 'x';
        formattedTest.OS.values = '' + this.pupilSize.OS.value || 'x';
        if (this.haplotests.length > 0) {
            formattedTest.haplotest = this.haplotests[this.haplotests.length - 1].haplotestData;
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
