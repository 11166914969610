import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { AcaMeasuredDataFrontend } from '../../../measuredData/NIDEK/NT530P/acaDataFrontend.class';

export class AcaTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof AcaMeasuredDataFrontend = AcaMeasuredDataFrontend;
    public measurements: AcaMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
