import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment';
import { BlockingType } from '../../../../../commonout/enum/blockingType.enum';
import { GENDER } from '../../../../../commonout/enum/gender';
import { RACE } from '../../../../../commonout/enum/race';
import { IExamination } from '../../../../../commonout/interfaces/examination';
import { IUser } from '../../../../../commonout/interfaces/user';
import { OCULUS } from '../../../../common/enums/oculus.enum';
import { IDC_ITEM_TYPE } from '../../../../common/enums/idc-item-type.enum';
import { IPatient } from '../../../../common/interfaces/patient.interface';
import { IdcCommentItem } from '../../../../common/models/idc/idc-comment-item.model';
import { IdcDiagnosisItem } from '../../../../common/models/idc/idc-diagnosis-item.model';
import { IdcTagItem } from '../../../../common/models/idc/idc-tag-item.model';
import { IdcListItem } from '../../../../common/models/idc/IdcListItem.model';
import { Patient } from '../../../../common/models/patient.model';
import { Pager } from '../_services/examination/export.service';
import { ExaminationFrontend } from './examinationFrontend.class';
import { RegisterEditFieldFrontend } from './registerEditFieldFrontend.class';

export class PatientFrontend extends Patient {
    _id?: string;
    registerID: RegisterEditFieldFrontend<string>;
    createdAt: number;
    gender: RegisterEditFieldFrontend<GENDER>;
    race: RegisterEditFieldFrontend<RACE>;
    dateOfBirth: RegisterEditFieldFrontend<number>;
    pupilPupilDistance: RegisterEditFieldFrontend<number>;
    lensValues: {
        sphere: {
            OD: RegisterEditFieldFrontend<number>;
            OS: RegisterEditFieldFrontend<number>;
        };
        cylinder: {
            OD: RegisterEditFieldFrontend<number>;
            OS: RegisterEditFieldFrontend<number>;
        };
        axis: {
            OD: RegisterEditFieldFrontend<number>;
            OS: RegisterEditFieldFrontend<number>;
        };
    };
    city: RegisterEditFieldFrontend<string>;
    country: RegisterEditFieldFrontend<string>;
    phone: RegisterEditFieldFrontend<string>;
    email: RegisterEditFieldFrontend<string>;
    occupation: RegisterEditFieldFrontend<string>;
    maritalStatus: RegisterEditFieldFrontend<string[]>;
    ssn: RegisterEditFieldFrontend<string>;
    insuranceCompany: RegisterEditFieldFrontend<string>;
    insurancePlan: RegisterEditFieldFrontend<string>;
    ocularMedication: RegisterEditFieldFrontend<string>;
    ocularInvestigation: RegisterEditFieldFrontend<string>;
    pastMedicalHistory: {
        selectValue: RegisterEditFieldFrontend<string[]>;
        additionalTextValue: RegisterEditFieldFrontend<string>;
    };
    systemicMedication: RegisterEditFieldFrontend<string>;
    recentInvestigation: RegisterEditFieldFrontend<string>;
    familyHistory: {
        selectValue: RegisterEditFieldFrontend<string[]>;
        additionalTextValue: RegisterEditFieldFrontend<string>;
    };
    birthHistory: {
        selectValue: RegisterEditFieldFrontend<string[]>;
        additionalTextValue: RegisterEditFieldFrontend<string>;
    };
    personalHistory: RegisterEditFieldFrontend<string>;
    allergyHistory: {
        selectValue: RegisterEditFieldFrontend<string[]>;
        additionalTextValue: RegisterEditFieldFrontend<string>;
    };
    mainComplaints: {
        complaint: RegisterEditFieldFrontend<string>;
        oculus: RegisterEditFieldFrontend<OCULUS>;
        duration: RegisterEditFieldFrontend<string>;
    }[];
    pastOcularHistory: {
        condition: RegisterEditFieldFrontend<string>;
        oculus: RegisterEditFieldFrontend<OCULUS>;
        duration: RegisterEditFieldFrontend<string>;
    }[];
    examinations: ExaminationFrontend[];
    diagnosis: RegisterEditFieldFrontend<string>;
    ICD: IdcListItem[];

    constructor() {
        super();
        this.registerID = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'register ID', moment().format('YYMMDDHHmmss'));
        this.createdAt = new Date().valueOf();
        this.gender = new RegisterEditFieldFrontend<GENDER>(BlockingType.AFTER_DAY_WITH_COMMIT, 'gender', null);
        this.race = new RegisterEditFieldFrontend<RACE>(BlockingType.AFTER_DAY_WITH_COMMIT, 'race', null);
        this.dateOfBirth = new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'date of birth', null);
        this.pupilPupilDistance = new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'pupil pupil distance', null);
        this.lensValues = {
            sphere: {
                OD: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Lens value sphere OD', null),
                OS: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Lens value sphere OS', null),
            },
            cylinder: {
                OD: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Lens value cylinder OD', null),
                OS: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Lens value cylinder OS', null),
            },
            axis: {
                OD: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Lens value axis OD', null),
                OS: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Lens value axis OS', null),
            },
        };
        this.city = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'city', '');
        this.country = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'country', localStorage.getItem('defaultCountry'));
        this.phone = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'phone', '');
        this.email = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'email', '');
        this.occupation = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'occupation', '');
        this.maritalStatus = new RegisterEditFieldFrontend<string[]>(BlockingType.AFTER_DAY_WITH_COMMIT, 'marital status', []);
        this.ssn = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'ssn', '');
        this.insuranceCompany = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'insurance company', '');
        this.insurancePlan = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'insurance plan', '');
        this.ocularMedication = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'ocular medication', '');
        this.ocularInvestigation = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'ocular investigation', '');
        this.pastMedicalHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>(BlockingType.AFTER_DAY_WITH_COMMIT, 'selected values', []),
            additionalTextValue: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Past medical information text value', ''),
        };
        this.systemicMedication = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'systemic medication', '');
        this.recentInvestigation = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'recent investigation', '');
        this.familyHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>(BlockingType.AFTER_DAY_WITH_COMMIT, 'selected values', []),
            additionalTextValue: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Family information text value', ''),
        };
        this.birthHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>(BlockingType.AFTER_DAY_WITH_COMMIT, 'selected values', []),
            additionalTextValue: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Birth information additional text value', ''),
        };
        this.personalHistory = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'personal information', '');
        this.allergyHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>(BlockingType.AFTER_DAY_WITH_COMMIT, 'selected values', []),
            additionalTextValue: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Allergy information text value', ''),
        };
        this.mainComplaints = [];
        this.pastOcularHistory = [];
        this.diagnosis = new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Patient diagnosis', '');
        this.ICD = [];
    }

    public setModel(model: IPatient): void {
        this._id = model._id;
        this.registerID = new RegisterEditFieldFrontend<string>().setModel(model.registerID);
        this.createdAt = model.createdAt;
        this.gender = new RegisterEditFieldFrontend<GENDER>().setModel(model.gender);
        this.race = new RegisterEditFieldFrontend<RACE>().setModel(model.race);
        this.dateOfBirth = new RegisterEditFieldFrontend<number>().setModel(model.dateOfBirth);
        this.pupilPupilDistance = new RegisterEditFieldFrontend<number>().setModel(model.pupilPupilDistance);
        this.lensValues = {
            sphere: {
                OD: new RegisterEditFieldFrontend<number>().setModel(model.lensValues.sphere.OD),
                OS: new RegisterEditFieldFrontend<number>().setModel(model.lensValues.sphere.OS),
            },
            cylinder: {
                OD: new RegisterEditFieldFrontend<number>().setModel(model.lensValues.cylinder.OD),
                OS: new RegisterEditFieldFrontend<number>().setModel(model.lensValues.cylinder.OS),
            },
            axis: {
                OD: new RegisterEditFieldFrontend<number>().setModel(model.lensValues.axis.OD),
                OS: new RegisterEditFieldFrontend<number>().setModel(model.lensValues.axis.OS),
            },
        };
        this.city = new RegisterEditFieldFrontend<string>().setModel(model.city);
        this.country = new RegisterEditFieldFrontend<string>().setModel(model.country);
        this.phone = new RegisterEditFieldFrontend<string>().setModel(model.phone);
        this.email = new RegisterEditFieldFrontend<string>().setModel(model.email);
        this.occupation = new RegisterEditFieldFrontend<string>().setModel(model.occupation);
        this.maritalStatus = new RegisterEditFieldFrontend<string[]>().setModel(model.maritalStatus);
        this.ssn = new RegisterEditFieldFrontend<string>().setModel(model.ssn);
        this.insuranceCompany = new RegisterEditFieldFrontend<string>().setModel(model.insuranceCompany);
        this.insurancePlan = new RegisterEditFieldFrontend<string>().setModel(model.insurancePlan);
        this.ocularMedication = new RegisterEditFieldFrontend<string>().setModel(model.ocularMedication);
        this.ocularInvestigation = new RegisterEditFieldFrontend<string>().setModel(model.ocularInvestigation);
        this.pastMedicalHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>().setModel(model.pastMedicalHistory.selectValue),
            additionalTextValue: new RegisterEditFieldFrontend<string>().setModel(model.pastMedicalHistory.additionalTextValue),
        };
        this.systemicMedication = new RegisterEditFieldFrontend<string>().setModel(model.systemicMedication);
        this.recentInvestigation = new RegisterEditFieldFrontend<string>().setModel(model.recentInvestigation);
        this.familyHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>().setModel(model.familyHistory.selectValue),
            additionalTextValue: new RegisterEditFieldFrontend<string>().setModel(model.familyHistory.additionalTextValue),
        };
        this.birthHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>().setModel(model.birthHistory.selectValue),
            additionalTextValue: new RegisterEditFieldFrontend<string>().setModel(model.birthHistory.additionalTextValue),
        };
        this.personalHistory = new RegisterEditFieldFrontend<string>().setModel(model.personalHistory);
        this.allergyHistory = {
            selectValue: new RegisterEditFieldFrontend<string[]>().setModel(model.allergyHistory.selectValue),
            additionalTextValue: new RegisterEditFieldFrontend<string>().setModel(model.allergyHistory.additionalTextValue),
        };
        this.mainComplaints = model.mainComplaints.map(mcModel => {
            return {
                complaint: new RegisterEditFieldFrontend<string>().setModel(mcModel.complaint),
                oculus: new RegisterEditFieldFrontend<OCULUS>().setModel(mcModel.oculus),
                duration: new RegisterEditFieldFrontend<string>().setModel(mcModel.duration),
            };
        });
        this.pastOcularHistory = model.pastOcularHistory.map(pohModel => {
            return {
                condition: new RegisterEditFieldFrontend<string>().setModel(pohModel.condition),
                oculus: new RegisterEditFieldFrontend<OCULUS>().setModel(pohModel.oculus),
                duration: new RegisterEditFieldFrontend<string>().setModel(pohModel.duration),
            };
        });
        model.examinations?.forEach((examModel: IExamination) => {
            const examination: ExaminationFrontend = new ExaminationFrontend();
            examination.setModel(examModel);
            this.examinations.push(examination);
        });
        this.diagnosis = new RegisterEditFieldFrontend<string>().setModel(model.diagnosis);
        this.ICD = model.ICD.map(m => {
            let item: IdcListItem;
            switch (m.type) {
                case IDC_ITEM_TYPE.COMMENT:
                    item = new IdcCommentItem();
                    break;
                case IDC_ITEM_TYPE.DIAGNOSIS:
                    item = new IdcDiagnosisItem();
                    break;
                case IDC_ITEM_TYPE.TAG:
                    item = new IdcTagItem();
                    break;
                default:
                    break;
            }
            item.model = m;
            return item;
        });
    }
    public update(data: any, editor: IUser<any>) {
        this.registerID.registerEdit(data.registerID, editor);
        this.gender.registerEdit(data.gender, editor);
        this.race.registerEdit(data.race, editor);
        this.dateOfBirth.registerEdit(data.dateOfBirth ? moment(data.dateOfBirth, 'DD.MM.YYYY').valueOf() : null, editor);
        this.city.registerEdit(data.city, editor);
        this.country.registerEdit(data.country, editor);
        this.phone.registerEdit(data.phone, editor);
        this.email.registerEdit(data.email, editor);
        this.occupation.registerEdit(data.occupation, editor);
        this.maritalStatus.registerEdit(data.maritalStatus, editor);
        this.ssn.registerEdit(data.ssn, editor);
        this.insuranceCompany.registerEdit(data.insuranceCompany, editor);
        this.insurancePlan.registerEdit(data.insurancePlan, editor);
        this.ocularMedication.registerEdit(data.ocularMedication, editor);
        this.ocularInvestigation.registerEdit(data.ocularInvestigation, editor);
        this.pastMedicalHistory.selectValue.registerEdit(data.pastMedicalHistory.selectValue, editor);
        this.pastMedicalHistory.additionalTextValue.registerEdit(data.pastMedicalHistory.additionalTextValue, editor);
        this.systemicMedication.registerEdit(data.systemicMedication, editor);
        this.recentInvestigation.registerEdit(data.recentInvestigation, editor);
        this.familyHistory.selectValue.registerEdit(data.familyHistory.selectValue, editor);
        this.familyHistory.additionalTextValue.registerEdit(data.familyHistory.additionalTextValue, editor);
        this.birthHistory.selectValue.registerEdit(data.birthHistory.selectValue, editor);
        this.birthHistory.additionalTextValue.registerEdit(data.birthHistory.additionalTextValue, editor);
        this.personalHistory.registerEdit(data.personalHistory, editor);
        this.allergyHistory.selectValue.registerEdit(data.allergyHistory.selectValue, editor);
        this.allergyHistory.additionalTextValue.registerEdit(data.allergyHistory.additionalTextValue, editor);
        this.mainComplaints = data.mainComplaints.map((mc: any) => {
            return {
                complaint: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Main Complaints complaint', mc.complaint),
                oculus: new RegisterEditFieldFrontend<OCULUS>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Main Complaints oculus', mc.oculus),
                duration: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Main Complaints duration', mc.duration),
            };
        });
        this.pastOcularHistory = data.pastOcularHistory.map((poh: any) => {
            return {
                condition: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Past Ocular History condition', poh.condition),
                oculus: new RegisterEditFieldFrontend<OCULUS>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Past Ocular History oculus', poh.oculus),
                duration: new RegisterEditFieldFrontend<string>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Past Ocular History duration', poh.duration),
            };
        });
        this.diagnosis.registerEdit(data.diagnosis, editor);
    }
    public toPDF(document: jsPDF, pager: Pager): void {
        let head = [];
        head.push({
            field_key: 'Gender',
            field_value: this.gender.value,
        });
        head.push({
            field_key: 'Race',
            field_value: this.race.value,
        });
        head.push({
            field_key: 'Date of birth',
            field_value: this.dateOfBirth.value,
        });
        head.push({
            field_key: 'Insurance company',
            field_value: this.insuranceCompany.value,
        });
        head.push({
            field_key: 'Insurance plan',
            field_value: this.insurancePlan.value,
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: { colSpan: 3, content: 'Main complaints', styles: { halign: 'center' } },
            field_value1: '',
            field_value2: '',
        });
        head.push({
            field_key: 'Complaint',
            field_value1: 'Oculus',
            field_value2: 'Duration',
        });
        this.mainComplaints.forEach(mc => {
            head.push({
                field_key: mc.complaint.value,
                field_value1: mc.oculus.value,
                field_value2: mc.duration.value,
            });
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: { colSpan: 3, content: 'Past ocular information', styles: { halign: 'center' } },
            field_value1: '',
            field_value2: '',
        });
        head.push({
            field_key: 'Condition',
            field_value1: 'Oculus',
            field_value2: 'Duration',
        });
        this.pastOcularHistory.forEach(poh => {
            head.push({
                field_key: poh.condition.value,
                field_value1: poh.oculus.value,
                field_value2: poh.duration.value,
            });
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: 'Ocular medication',
            field_value: this.ocularMedication.value,
        });
        head.push({
            field_key: 'Ocular investigation',
            field_value: this.ocularInvestigation.value,
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: { colSpan: 2, content: 'Past medical information', styles: { halign: 'center' } },
            field_value: '',
        });
        head.push({
            field_key: this.pastMedicalHistory.selectValue.value,
            field_value: this.pastMedicalHistory.additionalTextValue.value,
        });
        head.push({
            field_key: 'Systemic medication',
            field_value: this.systemicMedication.value,
        });
        head.push({
            field_key: 'Recent investigation',
            field_value: this.recentInvestigation.value,
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: { colSpan: 2, content: 'Family information', styles: { halign: 'center' } },
            field_value: '',
        });
        head.push({
            field_key: this.familyHistory.selectValue.value,
            field_value: this.familyHistory.additionalTextValue.value,
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: { colSpan: 2, content: 'Birth information', styles: { halign: 'center' } },
            field_value: '',
        });
        head.push({
            field_key: this.birthHistory.selectValue.value,
            field_value: this.birthHistory.additionalTextValue.value,
        });
        head.push({
            field_key: 'Personal information',
            field_value: this.personalHistory.value,
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        head = [];
        head.push({
            field_key: { colSpan: 2, content: 'Allergy information', styles: { halign: 'center' } },
            field_value: '',
        });
        head.push({
            field_key: this.allergyHistory.selectValue.value,
            field_value: this.allergyHistory.additionalTextValue.value,
        });
        pager.addLine(document.getTextDimensions('A').h);
        (document as any).autoTable({
            head: head,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
}
