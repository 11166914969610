import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DEVICE } from '../../../../../../../commonout/enum/device';
import { TEST_STAGE } from '../../../../../../../commonout/enum/testStage.enum';
import { IExamination } from '../../../../../../../commonout/interfaces/examination';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { TestFrontend } from '../../../_models/tests/testFrontend.class';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { ExportService } from '../../../_services/examination/export.service';
import { ConfigService } from '../../../_services/general/config.service';
import { ModalService } from '../../../_services/general/modal.service';
import { SocketService } from '../../../_services/general/socket.service';

@Component({
    selector: 'test-list',
    template: require('./test-list.component.html'),
    styles: [require('./test-list.component.scss')],
    animations: [
        trigger('detailsAnimation', [
            state(
                'true',
                style({
                    display: 'flex',
                    opacity: '1',
                    height: 'initial',
                })
            ),
            state(
                'false',
                style({
                    display: 'none',
                    opacity: '0',
                    height: '0px',
                    overflow: 'hidden',
                })
            ),
            transition('false => true', animate('500ms cubic-bezier(0,0,0.25,1)')),
            transition('true => false', animate('500ms ease-in')),
        ]),
    ],
})
export class TestListComponent implements OnInit, AfterViewInit, OnDestroy {
    private subscriptions: Subscription[];
    private examination: ExaminationFrontend;
    private DEVICE: typeof DEVICE = DEVICE;
    private tests: BehaviorSubject<TestFrontend[]>;
    @ViewChild('scr') scrollable: ElementRef;
    @ViewChild('chartToPdf', { read: ViewContainerRef }) chartToPdf: ViewContainerRef;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private examinationService: ExaminationService,
        private configService: ConfigService,
        private socketService: SocketService,
        private exportService: ExportService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private bulbicamService: BulbicamService,
        private modalService: ModalService
    ) {
        this.subscriptions = [];
        this.examination = new ExaminationFrontend();
        this.tests = new BehaviorSubject<TestFrontend[]>([]);
    }
    ngAfterViewInit(): void {
        this.modalService.feedbackScrollEvent.subscribe((direction: 'up' | 'down') => {
            this.scrollable.nativeElement.scrollTop += direction === 'down' ? 40 : -40;
        });
    }
    public async ngOnInit(): Promise<void> {
        const examinationId = this.route.snapshot.params['examId'];
        if (!examinationId) return;
        const examinationModel: IExamination = await this.examinationService.getExaminationById(examinationId).toPromise();
        if (!examinationModel) return;
        this.examination.setModel(examinationModel);
        this.subscriptions.push(
            this.configService.isDevTestsShown.pipe(distinctUntilChanged()).subscribe(isDevTestsShown => {
                this.tests.next(
                    this.examination.tests
                        .filter(t => isDevTestsShown || t.stage === TEST_STAGE.SHOWN)
                        .sort((t1, t2) => {
                            return ExaminationFrontend.deviceSorter(t1, t2) || ExaminationFrontend.sorterByStage(t1, t2) || ExaminationFrontend.customSorter(t1, t2);
                        })
                );
            })
        );
        this.subscriptions.push(this.socketService.emit('startWatchingResults', this.examination.createdAt).subscribe());
        this.bulbicamService.collectProSaccadesRatio(this.examination);
    }
    ngOnDestroy(): void {
        this.socketService.emit('stopWatchingResults').subscribe();
        this.subscriptions.forEach(s => s.unsubscribe());
        this.socketService.socket?.off('DeviceResultsList');
        this.bulbicamService.proSaccadesRatioValue = [];
    }
    private export(): void {
        this.exportService[this.configService.exportSource](this.examination, this.componentFactoryResolver, this.chartToPdf, this.bulbicamService);
    }
    private async delete(): Promise<void> {
        const resp: boolean = await this.modalService.askUser('Are you sure you want to delete examination?');
        if (resp) {
            await this.examinationService.delete(this.examination._id).toPromise();
            this.router.navigate(['/']);
        }
    }
}
