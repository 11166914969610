import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { OcularRefractionArk1sMeasuredDataFrontend } from '../../../measuredData/NIDEK/ARK1S/ocularRefractionArk1sDataFrontend.class';

export class OcularRefractionArk1sTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof OcularRefractionArk1sMeasuredDataFrontend = OcularRefractionArk1sMeasuredDataFrontend;
    public measurements: OcularRefractionArk1sMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
