import * as jsPDF from 'jspdf';
import { IDryEyeForus3NethraClassicMeasuredData } from '../../../../../../../common/interfaces/measuredData/FORUS/3NethraClassic/dry-eye-forus3Nethra-classic.measuredData.interface';
import { DryEyeForus3NethraClassicMeasuredData } from '../../../../../../../common/models/measuredData/FORUS/3NethraClassic/dryEyeForus3NethraClassicData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { DryEyeForus3NethraClassicTestRemarksFrontend } from '../../../test-remarks/FORUS/3NethraClassic/dryEyeTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class DryEyeForus3NethraClassicMeasuredDataFrontend extends DryEyeForus3NethraClassicMeasuredData implements MeasuredDataFrontend {
    public remarks: DryEyeForus3NethraClassicTestRemarksFrontend;
    public eyelids: {
        OS: RegisterEditFieldFrontend<string>;
        OD: RegisterEditFieldFrontend<string>;
    };
    public meibomianGlands: {
        OS: RegisterEditFieldFrontend<string>;
        OD: RegisterEditFieldFrontend<string>;
    };
    public conjuctiva: {
        OS: RegisterEditFieldFrontend<string>;
        OD: RegisterEditFieldFrontend<string>;
    };
    public palpebral: {
        OS: RegisterEditFieldFrontend<string>;
        OD: RegisterEditFieldFrontend<string>;
    };
    public bulbar: {
        OS: RegisterEditFieldFrontend<string>;
        OD: RegisterEditFieldFrontend<string>;
    };
    public tearFilm: {
        OS: RegisterEditFieldFrontend<string>;
        OD: RegisterEditFieldFrontend<string>;
    };
    public images: {
        OD: SmartImageFrontend[];
        OS: SmartImageFrontend[];
    };
    constructor() {
        super();
        this.eyelids = {
            OS: new RegisterEditFieldFrontend(),
            OD: new RegisterEditFieldFrontend(),
        };
        this.meibomianGlands = {
            OS: new RegisterEditFieldFrontend(),
            OD: new RegisterEditFieldFrontend(),
        };
        this.conjuctiva = {
            OS: new RegisterEditFieldFrontend(),
            OD: new RegisterEditFieldFrontend(),
        };
        this.palpebral = {
            OS: new RegisterEditFieldFrontend(),
            OD: new RegisterEditFieldFrontend(),
        };
        this.bulbar = {
            OS: new RegisterEditFieldFrontend(),
            OD: new RegisterEditFieldFrontend(),
        };
        this.tearFilm = {
            OS: new RegisterEditFieldFrontend(),
            OD: new RegisterEditFieldFrontend(),
        };
        this.images = {
            OD: [],
            OS: [],
        };
    }
    public setModel(model: IDryEyeForus3NethraClassicMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.eyelids.OS.setModel(model.eyelids.OD);
        this.eyelids.OD.setModel(model.eyelids.OS);
        this.meibomianGlands.OS.setModel(model.meibomianGlands.OD);
        this.meibomianGlands.OD.setModel(model.meibomianGlands.OS);
        this.conjuctiva.OS.setModel(model.conjuctiva.OD);
        this.conjuctiva.OD.setModel(model.conjuctiva.OS);
        this.palpebral.OS.setModel(model.palpebral.OD);
        this.palpebral.OD.setModel(model.palpebral.OS);
        this.bulbar.OS.setModel(model.bulbar.OD);
        this.bulbar.OD.setModel(model.bulbar.OS);
        this.tearFilm.OS.setModel(model.tearFilm.OD);
        this.tearFilm.OD.setModel(model.tearFilm.OS);
        this.images = {
            OD: model.images.OD.map(m => {
                const i = new SmartImageFrontend();
                i.model = m;
                return i;
            }),
            OS: model.images.OS.map(m => {
                const i = new SmartImageFrontend();
                i.model = m;
                return i;
            }),
        };
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        this.images.OD.forEach(si => formattedTest.OD.images.push(si.imageUrl));
        this.images.OS.forEach(si => formattedTest.OS.images.push(si.imageUrl));
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.images.OD.map(si => si.download(fileService)));
        await Promise.all(this.images.OS.map(si => si.download(fileService)));
    }
}
