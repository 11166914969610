import { TEST_RESULT } from '../../../commonout/enum/test-result.enum';
import { ITestRemarks } from '../interfaces/test-remark.interface';
import { MeasuredData } from './measuredData/measuredData.model';
import { RegisterEditField } from './registerEditField.class';
import { Test } from './test.class';
/**
 * A class that describes all kind of possible data provided by user based on some type of pationt's test results.
 */
export abstract class TestRemarks implements ITestRemarks {
    public measurements: MeasuredData[]; // information that describes sertain properties about data taken from external device.
    public result: RegisterEditField<string>; // type of grade that Doctor should provide after investigation of test measurement. This field is used if user provides single result for both eyes.
    public result_new: {
        // type of grade that Doctor should provide after investigation of test measurement. This field is used if user should provide result for each eye.
        OD: RegisterEditField<TEST_RESULT>;
        OS: RegisterEditField<TEST_RESULT>;
    };
    public diagnosis: RegisterEditField<string>; // this type of information user can provide in each test. It related to all measurements in test.
    public advice: RegisterEditField<string>; // this type of information user can provide in each test. It related to all measurements in test.
    public laboratoryRequested: RegisterEditField<string>; // this type of information user can provide in each test. It related to all measurements in test.
    public medicinesPrescribed: RegisterEditField<string>; // this type of information user can provide in each test. It related to all measurements in test.
    public test: Test; // reference to test class this remarks related to
    abstract measuredDataType: any; // the function that returns inctance of some particular measured data type. A concrete type is assigned in a class that extends this abstract class
    constructor() {}
    public setModel(model: ITestRemarks): void {
        this.measurements = model.measurements.map(um => {
            const md = new this.measuredDataType();
            md.remarks = this;
            md.setModel(um);
            return md;
        });
        this.result.setModel(model.result);
        this.result_new.OD.setModel(model.result_new.OD);
        this.result_new.OS.setModel(model.result_new.OS);
        this.diagnosis.setModel(model.diagnosis);
        this.advice.setModel(model.advice);
        this.laboratoryRequested.setModel(model.laboratoryRequested);
        this.medicinesPrescribed.setModel(model.medicinesPrescribed);
    }
    public getModel(): ITestRemarks {
        const model: ITestRemarks = {
            measurements: this.measurements.map(um => um.getModel()),
            result: this.result.getModel(),
            result_new: {
                OD: this.result_new.OD.getModel(),
                OS: this.result_new.OS.getModel(),
            },
            diagnosis: this.diagnosis.getModel(),
            advice: this.advice.getModel(),
            laboratoryRequested: this.laboratoryRequested.getModel(),
            medicinesPrescribed: this.medicinesPrescribed.getModel(),
        };
        return model;
    }
}
