import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'dashboard-admin',
    template: require('./dashboard-admin.component.html'),
    styles: [require('./dashboard-admin.component.scss')],
})
export class DashboardAdminComponent implements OnInit {
    title = 'admin dashboard';
    constructor(private router: Router) {}
    ngOnInit() {
        let url = this.router.url.split('/');
        this.changeTitle(url);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                url = event.url.split('/');
                this.changeTitle(url);
            }
        });
    }
    private changeTitle(url: string[]) {
        if (url.length < 3) {
            this.title = 'admin dashboard';
        } else {
            this.title = 'admin ' + url[2];
        }
    }
}
