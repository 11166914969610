import { TEST_TYPE } from '../enum/test-type';
import { IGrade } from '../interfaces/grade.interface';
import { ITechStaffRoleProperties } from '../interfaces/roleProperties/techStaffRoleProperties.interface';
import { IUser } from '../interfaces/user';

export class Grade implements IGrade {
    _id: string;
    examinationID: string;
    testType: TEST_TYPE;
    measurementCreatedAt: number;
    createdAt: number;
    recordingGrade: string | number;
    clinicalGrade: string | number;
    freeze: boolean;
    crash: boolean;
    comment: string;
    author: IUser<ITechStaffRoleProperties>;
    constructor() {
        return this;
    }
    public setModel(model: IGrade): Grade {
        this._id = model._id;
        this.examinationID = model.examinationID;
        this.testType = model.testType;
        this.measurementCreatedAt = model.measurementCreatedAt;
        this.createdAt = model.createdAt;
        this.recordingGrade = model.recordingGrade;
        this.clinicalGrade = model.clinicalGrade;
        this.freeze = model.freeze;
        this.crash = model.crash;
        this.comment = model.comment;
        this.author = model.author;
        return this;
    }
    /**
     *
     * @param type type of grade about what function should return if it exists
     */
    public hasGrade(type: 'recordingGrade' | 'clinicalGrade'): boolean {
        // possible values of recording and clinical grades are:
        // typeof g[type] === 'number'
        // or
        // (typeof g[type] === 'string' && (<string>g[type]).length > 0)
        return typeof this[type] !== 'undefined' && (typeof this[type] === 'number' || (typeof this[type] === 'string' && this[type].toString().length > 0));
    }
}
