// @ts-nocheck
import * as _ from 'lodash';
import * as d3 from 'd3';
import { DataPoint, StartSequence, isSpvDataPoint, isPosDataPoint, phaseName, PosDataPoint, StopSequence } from './Protocol';
import { Variable } from '../lib/Variable';
import { Settings } from './Settings';
import { ManualStorage } from './ManualStorage';
import { CornerButtons } from './CornerButtons';

interface Dragging {
    // started: number[]
    // dotId: string
    // dotInitialPosition: number[]
}

interface StateParams {
    updateStartParams: (x: any) => void;
}

interface XY {
    x: number;
    y: number;
}

export interface Rect {
    bl: XY; // bottom-left
    tr: XY; // top-right
}

export interface RectSelection {
    start: XY;
    finish?: XY;
    done: boolean;
}

export class State {
    constructor(private p: StateParams) {}

    squares = {
        showMenu: false,
    };

    lengthInSeconds: number;

    cornerButtons = new CornerButtons();

    showBigVideo = false;

    manualStorage = new ManualStorage();

    dragHandleSec4 = 0;
    dragHandleSec34 = 0;
    dragHandleSec64 = 0;
    dragHandleV1sec4 = 0;
    dragHandleV2sec4 = 0;
    dragHandleV1sec34 = 0;
    dragHandleV2sec34 = 0;
    dragHandleV1sec64 = 0;
    dragHandleV2sec64 = 0;
    drag1sec4 = 0.25;
    drag2sec4 = 0.75;
    drag1sec34 = 0.25;
    drag2sec34 = 0.75;
    drag1sec64 = 0.25;
    drag2sec64 = 0.75;
    drag1Vsec4 = 0.33;
    drag2Vsec4 = 0.66;
    drag3Vsec4 = 0.33;
    drag4Vsec4 = 0.66;
    drag1Vsec34 = 0.33;
    drag2Vsec34 = 0.66;
    drag3Vsec34 = 0.33;
    drag4Vsec34 = 0.66;
    drag1Vsec64 = 0.33;
    drag2Vsec64 = 0.66;
    drag3Vsec64 = 0.33;
    drag4Vsec64 = 0.66; // numbers are relative to the whole scale domain

    lastTauchedTracker1 = 'drag1sec4';
    lastTauchedTracker2 = 'drag2sec4';
    lastTauchedTracker1V = 'drag1Vsec4';
    lastTauchedTracker2V = 'drag2Vsec4';

    lastTouchedSaved = '';
    lastTauchedTracker1VSaved = '';
    seconds = 4;
    chosenRange: number;
    reduce = true;

    amountOfDataText = 'full data';
    posOrRawOD = 'RAW';
    posOrRawOS = 'RAW';

    initializeSaved = true;

    updateRotationOD(n: number) {
        this.settings.updateRotationOD(n);
        this.syncRotationFromOD();
    }
    updateRotationOS(n: number) {
        this.settings.updateRotationOS(n);
        this.syncRotationFromOS();
    }
    syncRotationFromOD() {
        if (!this.cornerButtons.showBoth) return;
        this.settings.rotationOS = this.settings.rotationOD;
    }
    syncRotationFromOS() {
        if (!this.cornerButtons.showBoth) return;
        this.settings.rotationOD = this.settings.rotationOS;
    }

    recordManualChanges(eye: string) {
        this.manualStorage.record(this.manualRecord(eye));
    }

    getPlace(eye: string) {
        let activeSquare = this.mapRecordingToSquare(this.activeRecording);
        let place = { ...activeSquare, eye };
        return place;
    }

    manualRecord(eye: string) {
        let place = this.getPlace(eye);

        let range: number;
        let domainX: number[];
        let domainY: number[];

        if (this.lastTauchedTracker1 === 'drag1sec4') {
            domainX = this.uiXDomain4;
            domainY = this.uiYDomain4;
            range = this.lastTauchedTracker1;
        }

        if (this.lastTauchedTracker1 === 'drag1sec34') {
            domainX = this.uiXDomain34;
            domainY = this.uiYDomain34;
            range = this.lastTauchedTracker1;
        }

        if (this.lastTauchedTracker1 === 'drag1sec64') {
            domainX = this.uiXDomain64;
            domainY = this.uiYDomain64;
            range = this.lastTauchedTracker1;
        }

        let x1 = this.uiXScale(this[this.lastTauchedTracker1], domainX);
        let x2 = this.uiXScale(this[this.lastTauchedTracker2], domainX);

        let period = Math.abs(x1 - x2);

        let frequency = 1 / period;

        let y1 = this.uiYScale(this[this.lastTauchedTracker1V], domainY);
        let y2 = this.uiYScale(this[this.lastTauchedTracker2V], domainY);
        let amplitude = Math.abs(y1 - y2);

        let axis = eye === 'OD' ? this.settings.rotationOD : this.settings.rotationOS;

        this.lastTauchedTracker1VSaved = this.lastTauchedTracker1V;

        const start = [];
        const finish = [];

        if (this.rectSelection) {
            start.push(this.rectSelection.start.x);
            start.push(this.rectSelection.start.y);
            finish.push(this.rectSelection.finish.x);
            finish.push(this.rectSelection.finish.y);
        }

        let value = {
            frequency,
            amplitude,
            axis,
            range,
            lastTauchedTracker1: this[this.lastTauchedTracker1],
            lastTauchedTracker2: this[this.lastTauchedTracker2],
            lastTauchedTracker1V: this[this.lastTauchedTracker1V],
            lastTauchedTracker2V: this[this.lastTauchedTracker2V],
            lastTauchedTracker1VDefinition: this.lastTauchedTracker1V,
            uiXDomain: domainX,
            uiYDomain: domainY,
            start,
            finish,
            // type: '-',
        };

        return { place, value };
    }
    // handles store these, because they are calculated
    // on rendering by d3 now (not perfect you know)
    uiXDomain4 = [0, 0];
    uiYDomain4 = [0, 0];
    uiXDomain34 = [0, 0];
    uiYDomain34 = [0, 0];
    uiXDomain64 = [0, 0];
    uiYDomain64 = [0, 0];
    uiXScale(n: number, domainX: number[]) {
        let domain = domainX;
        let delta = Math.abs(domain[0] - domain[1]);
        let start = Math.min(domain[0], domain[1]);
        return start + delta * n;
    }
    uiYScale(n: number, domainY: number[]) {
        let domain = domainY;
        let delta = Math.abs(domain[0] - domain[1]);
        let start = Math.min(domain[0], domain[1]);
        return start + delta * n;
    }

    // NOTE: refactoring: can be scoped as cornerButtons
    rectSelection: RectSelection | null = null;

    get inProgressRectSelection() {
        let { rectSelection } = this;
        if (!rectSelection) return false;
        return !rectSelection.done;
    }
    startRectSelection(x: number, y: number) {
        this.rectSelection = {
            start: { x, y },
            done: false,
        };
    }
    updateRectSelection(x: number, y: number) {
        let { rectSelection } = this;
        if (!rectSelection) return;
        if (rectSelection.done) return;
        rectSelection.finish = { x, y };
    }
    finishRectSelection() {
        let { rectSelection } = this;
        if (!rectSelection) return;
        if (rectSelection.done) return;
        rectSelection.done = true;
    }
    get viewRectSelection() {
        let rectSelection: any = this.rectSelection;
        if (!rectSelection) return [];
        if (!rectSelection.finish) return [];

        let xx1 = rectSelection.start.x;
        let xx2 = rectSelection.finish.x;

        let yy1 = rectSelection.start.y;
        let yy2 = rectSelection.finish.y;

        let minMax = (a: number, b: number) => {
            if (a > b) return [b, a];
            return [a, b];
        };

        let [x1, x2] = minMax(xx1, xx2);
        let [y1, y2] = minMax(yy1, yy2);

        return [
            {
                bl: { x: x1, y: y1 },
                tr: { x: x2, y: y2 },
            },
        ];
    }

    // enums will do after refactoring
    previousMode = -1; // start-params-configuration(1) vs diagram(2)
    showConfig = true; // same as above but boolean, current mode (earlier code)

    configActiveSection = 1; // preset(1), manual(2)

    nextId = 0;
    dataSets: DataSet[] = [];

    settings = new Settings();

    dotDragging: Dragging | null = null;
    get willDrag() {
        return this.configActiveSection === 2;
    }
    moveDraggedPoint(x: number, y: number) {
        if (this.configValue.mod) {
            this.configManualPoints.od = [x, y];
        } else if (this.configValue.mos) {
            this.configManualPoints.os = [x, y];
        }
    }

    doUpdateStartParams() {
        this.p.updateStartParams(this.startParams);
    }

    get startParams() {
        let dots = [] as any;
        let stimulus = this.configValue.dot ? 0 : 1;
        let large = this.configValue.medium ? 0 : 1;

        if (this.configActiveSection === 1) {
            Object.entries(this.configPredefinedPoints).forEach(([k, data], i) => {
                if (!this.configValue[k]) return;
                let { od, os, name } = data;
                let xod = od[0];
                let yod = od[1];
                let xos = os[0];
                let yos = os[1];
                dots.push({
                    name,
                    xos,
                    yos,
                    xod,
                    yod,
                    stimulus,
                    large,
                });
            });
        } else {
            if (this.configValue.mod) {
                let xod = this.configManualPoints.od[0];
                let yod = this.configManualPoints.od[1];
                dots.push({
                    name: `OD: ${xod}/${yod}`, // NOTE: used for "match" later
                    xod,
                    yod,
                    stimulus,
                    large,
                });
            } else {
                let xos = this.configManualPoints.os[0];
                let yos = this.configManualPoints.os[1];
                dots.push({
                    name: `OS: ${xos}/${yos}`,
                    xos,
                    yos,
                    stimulus,
                    large,
                });
            }
        }

        return dots;
    }

    configValue = {
        // manual
        mod: true,
        mos: false,

        // predefined, 1-first column
        p1up: false,
        p1down: false,
        p1central: true,
        p1left: false,
        p1right: false,

        p2up: true,
        p2down: true,
        p2central: true,
        p2left: true,
        p2right: true,

        // stimuli
        dot: true,
        pediatric: false,

        medium: true,
        large: false,
    };

    // configDomain = {
    //   os: {
    //     x: [-15,30],
    //     y: [-15,13],
    //   },
    //   od: {
    //     x: [-30,15],
    //     y: [-15,13],
    //   }
    // }

    configDomainManual = {
        os: {
            x: [-10, 20],
            y: [-10, 10],
        },
        od: {
            x: [-20, 10],
            y: [-10, 10],
        },
    };
    configDomain = this.configDomainManual;

    configManualPoints = {
        od: [0, 0],
        os: [0, 0],
    };

    // od: [x, y], ...
    configPredefinedPoints = {
        p1up: {
            name: 'Up Gaze Near',
            od: [0, 5],
            os: [0, 5],
            otherNames: ['testdot#0', 'UpGazeNear'],
            square: {
                n: 1,
                x: 1,
                y: 0,
            },
        },
        p1down: {
            name: 'Down Gaze Near',
            od: [0, -5],
            os: [0, -5],
            otherNames: ['testdot#1', 'DownGazeNear'],
            square: {
                n: 1,
                x: 1,
                y: 2,
            },
        },
        p1central: {
            name: 'Central Gaze Near',
            od: [-25, 0],
            os: [25, 0],
            otherNames: ['testdot#2', 'CentralGazeNear'],
            square: {
                n: 1,
                x: 1,
                y: 1,
            },
        },
        p1left: {
            name: 'Left Gaze Near',
            od: [-20, 0],
            os: [-5, 0],
            otherNames: ['testdot#3', 'LeftGazeNear'],
            square: {
                n: 1,
                x: 0,
                y: 1,
            },
        },
        p1right: {
            name: 'Right Gaze Near',
            od: [5, 0],
            os: [20, 0],
            otherNames: ['RightGazeNear'] as string[],
            square: {
                n: 1,
                x: 2,
                y: 1,
            },
        },

        p2up: {
            name: 'Up Gaze Far',
            od: [0, 10],
            os: [0, 10],
            otherNames: ['UpGazeFar'] as string[],
            square: {
                n: 0,
                x: 1,
                y: 0,
            },
        },
        p2down: {
            name: 'Down Gaze Far',
            od: [0, -10],
            os: [0, -10],
            otherNames: ['DownGazeFar'] as string[],
            square: {
                n: 0,
                x: 1,
                y: 2,
            },
        },
        p2central: {
            name: 'Central Gaze Far',
            od: [0, 0],
            os: [0, 0],
            otherNames: ['CentralGazeFar'] as string[],
            square: {
                n: 0,
                x: 1,
                y: 1,
            },
        },
        p2left: {
            name: 'Left Gaze Far',
            od: [-10, 0],
            os: [-10, 0],
            otherNames: ['LeftGazeFar'] as string[],
            square: {
                n: 0,
                x: 0,
                y: 1,
            },
        },
        p2right: {
            name: 'Right Gaze Far',
            od: [10, 0],
            os: [10, 0],
            otherNames: ['RightGazeFar'] as string[],
            square: {
                n: 0,
                x: 2,
                y: 1,
            },
        },
    };

    changeConfigValue(name: string) {
        if (name === 'dot' || name === 'pediatric') {
            this.configValue['dot'] = false;
            this.configValue['pediatric'] = false;
            this.configValue[name] = true;
            return;
        }

        if (name === 'medium' || name === 'large') {
            this.configValue['medium'] = false;
            this.configValue['large'] = false;
            this.configValue[name] = true;
            return;
        }

        if (name === 'mod' || name === 'mos') {
            this.configValue['mod'] = false;
            this.configValue['mos'] = false;
            this.configValue[name] = true;

            this.configActiveSection = 2;
            return;
        }
        this.configActiveSection = 1;

        let value = this.configValue[name];
        if (value == undefined) return console.error('bad name given');
        this.configValue[name] = !value;
    }

    selectedPoint = new Variable<DataPoint | null>(null);
    selectedChart = new Variable<number | null>(null);

    // unselectedSeries = new MySet<number>();
    selectedSeriesIndex = 0;
    get chosenSeries() {
        // return this.dataSets.filter((x) => !this.unselectedSeries.has(x.id))

        return this.dataSets.filter((x, i) => i === this.selectedSeriesIndex).map((x) => this.applyTransformations(x, this.seconds));
    }

    // NOTE: decided to use more specific language
    get activeRecording() {
        return this.dataSets.filter((x, i) => i === this.selectedSeriesIndex)[0];
    }
    get recordings() {
        return this.dataSets;
    }

    setSeries(id: number) {
        this.selectedSeriesIndex = id;
    }
    isSelectedSeries(id: number) {
        return this.selectedSeriesIndex === id;
    }
    get maxId() {
        return this.nextId - 1; // _.last(this.dataSets)?.id || 0
    }

    // affects time-series charts, on each rendering
    applyTransformations(x: any, seconds: number) {
        // let { dataPoints } = x
        let { medianOdX, medianOdY, medianOsX, medianOsY } = x;

        let trackingPoints = x.getTrackingPoints();

        // ! reduced amount of data dependong on the used range
        const trackingPointsFiltered = [];
        if ((seconds === 34 || seconds === 64) && this.reduce) {
            for (let i = trackingPoints.length - 1; i >= 0; i--) {
                if (i % 10 === 0) trackingPointsFiltered.push(trackingPoints[i]);
            }

            trackingPoints = trackingPointsFiltered;
        }

        let domainY = new DomainCollector();
        // let domainOdV = new DomainCollector()
        // let domainOsH = new DomainCollector()
        // let domainOsV = new DomainCollector()

        let isRelevant = (x: any) => true;
        let rectSelection = this.rectSelection as StartFinish; //...

        if (rectSelection && rectSelection.finish && (rectSelection as any).done) {
            isRelevant = (pair: any) => {
                let { start, finish } = rectSelection;
                if (true) {
                    let x = pair.ODvPoint.x;
                    let y = pair.ODvPoint.y;
                    if (!within(rectSelection, { x, y })) {
                        return false;
                    }
                }
                if (true) {
                    let x = pair.OSvPoint.x;
                    let y = pair.OSvPoint.y;
                    if (!within(rectSelection, { x, y })) {
                        return false;
                    }
                }
                return true;
            };
        }

        // dataPoints = dataPoints.map((x:any) => {

        let dataPoints = [];
        for (let i = trackingPoints.length - 1; i >= 0; i--) {
            let x = trackingPoints[i];
            // if (!isPosDataPoint(x)) return
            let od = [x.ODvPoint.y, x.ODhPoint.y] as [number, number];
            let os = [x.OSvPoint.y, x.OShPoint.y] as [number, number];
            // uses median as the center
            od[0] -= medianOdY; // XXX: vertical, why it is first then
            od[1] -= medianOdX;
            os[0] -= medianOsY;
            os[1] -= medianOsX;
            // to degrees
            // od[0] /= degtopx
            // od[1] /= degtopx
            // os[0] /= degtopx
            // os[1] /= degtopx
            // to minutes
            // od[0] *= 60
            // od[1] *= 60
            // os[0] *= 60
            // os[1] *= 60
            let ignore = od[0] == 0 || od[1] == 0 || os[0] == 0 || os[1] == 0; // XXX fix for one-eyed tests to allow closed eye
            od = rotateVector(od, this.settings.rotationOD);
            os = rotateVector(os, this.settings.rotationOS);
            let { ODvPoint, ODhPoint, OSvPoint, OShPoint } = x;
            let update = {
                ODvPoint: { ...ODvPoint, y: od[0] },
                ODhPoint: { ...ODhPoint, y: od[1] },
                OSvPoint: { ...OSvPoint, y: os[0] },
                OShPoint: { ...OShPoint, y: os[1] },
            } as any;
            let updated = { ...x, ...update };
            if (!isRelevant(x) && seconds === this.chosenRange) {
                updated.odPresent = false;
                updated.osPresent = false;
                x.odPresent = false;
                x.osPresent = false;
            }
            if (updated.odPresent) {
                domainY.see(update.ODhPoint.y);
                domainY.see(update.ODvPoint.y);
            }
            if (updated.osPresent) {
                domainY.see(update.OShPoint.y);
                domainY.see(update.OSvPoint.y);
            }
            dataPoints.push(updated);
        }

        dataPoints = _.compact(dataPoints);

        let trackingPointsProcessed = [];
        for (let i = trackingPoints.length - 1; i >= 0; i--) {
            let point = trackingPoints[i];

            let rotatePoint = ({ x, y }: any, alpha: number) => {
                let vec = [x, y];
                // vec = rotateVector([x, y], alpha)
                x = vec[0];
                y = vec[1];
                return { x, y };
            };
            let { od, os } = point;
            od = rotatePoint(od, this.settings.rotationOD);
            os = rotatePoint(os, this.settings.rotationOS);
            trackingPointsProcessed.push({ ...point, od, os });
        }
        trackingPoints = trackingPointsProcessed;

        return { ...x, dataPoints, trackingPoints, domainY };
    }

    gotStartSequence(x: StartSequence) {
        let id = this.nextId;
        this.nextId += 1;
        let neu = new DataSet(id, x);
        neu.stimuli = x.name;
        if (/OD:/.test(x.name)) neu.noOS = true;
        if (/OS:/.test(x.name)) neu.noOD = true;

        this.dataSets.push(neu);
    }

    gotStopSequence(x: StopSequence) {
        let place = _.last(this.dataSets);
        if (!place) return;
        place.filename = x.filename;
        place.originalfilename = x.originalfilename;

        place.degtopxHOS = x.degtopxHOS || 1;
        place.degtopxHOD = x.degtopxHOD || 1;
        place.degtopxVOS = x.degtopxVOS || 1;
        place.degtopxVOD = x.degtopxVOD || 1;

        place.changeToDegrees();

        // place.degtopx = x.degtopx

        let positions = place.dataPoints.filter((x) => x.ownType === 'pos') as PosDataPoint[];
        let medianOdX = d3.median(positions.map((x) => x.ODhPoint.y));
        let medianOdY = d3.median(positions.map((x) => x.ODvPoint.y));
        let medianOsX = d3.median(positions.map((x) => x.OShPoint.y));
        let medianOsY = d3.median(positions.map((x) => x.OSvPoint.y));
        place.medianOdX = medianOdX || 0;
        place.medianOdY = medianOdY || 0;
        place.medianOsX = medianOsX || 0;
        place.medianOsY = medianOsY || 0;
    }
    gotDataPoint(x: DataPoint) {
        let place = _.last(this.dataSets);
        // if (!place) return console.log(`protocol error: adding into empty data set`) // protocol vs state
        if (!place) {
            this.gotStartSequence({
                message_type: 15,
                timestamp: x.timestamp,
                name: 'random-point',
                // stimuli: 0,
            });
        }
        if (true) {
            let place = _.last(this.dataSets) as any;
            // these methods are like internal part of protocol

            place.addPoint(x);
        }
    }

    willRestartTraceAnimation = true;
    // willRemoveTrace = false

    // clearSrt() {
    //   this.selectedSrt = null
    //   if (!this.srtFixation) this.startAnimation()
    // }
    // setSrt(dataPoint: SrtPoint) {
    //   this.selectedSrt = dataPoint
    //   if (!this.srtFixation) this.startAnimation()
    // }
    startedAnimation() {
        this.willRestartTraceAnimation = false;
        // this.willRemoveTrace = false
    }
    startAnimation() {
        this.willRestartTraceAnimation = true;
    }

    get tracesView() {
        let series = this.chosenSeries[0];

        if (!series) return [];
        return [
            {
                id: 0,
                dataPoints: series.trackingPoints,
            },
        ];
    }

    mapRecordingToSquare(recording: { name: string }) {
        let found = Object.values(this.configPredefinedPoints).find((x) => {
            return x.name === recording.name || x.otherNames.includes(recording.name);
        });

        let { name } = recording;
        if (found) {
            return {
                ...found.square,
                name,
            };
        } else {
            // manual point -> center of the first 9-squares
            return {
                x: 1,
                y: 1,
                n: 0,
                name,
            };
        }
    }

    getPointByFrame(frame: number, duration: number = 0) {
        let place = this.activeRecording;
        if (!place) throw 'cant getPointByFrame';
        return place.getPointByFrame(frame, duration);
    }

    getFrameByTime(time: number) {
        let place = this.activeRecording;
        if (!place) throw 'cant getFrameByTime';
        return place.getFrameByTime(time);
    }

    // getVideoFrames() {
    //   let place = this.activeRecording
    //   if (!place) throw 'cant getVideoFrames'
    //   let frames = place.dataPoints.filter(x => isPosDataPoint(x)) as PosDataPoint[]
    //   frames = frames.filter(x =>
    //     x.index >= this.settings.videoPosition
    //   )
    //   return frames
    // }
}

abstract class BaseDataSet<D, DD> {
    constructor(public id: number, public startPoint: StartSequence) {}

    dataPoints: DD[] = [];

    abstract preprocessPoints(d: D): DD;

    addPoint(x: D) {
        x = { ...x };
        let xx = this.preprocessPoints(x);

        this.dataPoints.push(xx);
    }
}

// it is not really DataPoint->DataPoint mapping, incoming type has all added stuff defined already
export class DataSet extends BaseDataSet<DataPoint, DataPoint> {
    startTime?: number;
    // stimuli!: number
    filename = 'no-filename-provided.mp4';
    originalfilename: string | undefined = undefined;
    stimuli = 'not-known-point-type';
    noOS = false;
    noOD = false;
    // degtopx = 0
    degtopxHOS = 1;
    degtopxHOD = 1;
    degtopxVOS = 1;
    degtopxVOD = 1;

    medianOdX = 0;
    medianOdY = 0;
    medianOsX = 0;
    medianOsY = 0;

    getTrackingPoints() {
        return this.dataPoints
            .filter((x) => isPosDataPoint(x))
            .map((x: any) => {
                return {
                    od: x.ODtrackingPoint,
                    os: x.OStrackingPoint,
                    time: x.timestamp,
                    ...x,
                };
            });
    }

    lastUsedPositionPointIndex = -1;

    preprocessPoints(x: DataPoint) {
        let xx = this.applyStartTime(x);
        let xxx = this.applyToSeconds(xx);

        let x4 = this.addDerivedStuff(xxx);
        let x5 = this.addRelatedStuff(x4);
        return x5;
    }

    applyStartTime(x: DataPoint) {
        if (this.startTime == null) {
            this.startTime = x.timestamp;
        }
        x.timestamp -= this.startTime || 0;
        return x;
    }

    applyToSeconds(x: DataPoint) {
        x.timestamp /= 10_000_000;

        return x;
    }

    addDerivedStuff(x: DataPoint) {
        if (isSpvDataPoint(x)) {
            x.spvPoint = { x: x.timestamp, y: x.spv };
            x.ownType = 'spv';
        } else if (isPosDataPoint(x)) {
            // add indexation
            this.lastUsedPositionPointIndex += 1;
            x.index = this.lastUsedPositionPointIndex;

            posPointAddDerivedStuff(x);

            x.ownType = 'pos';

            x.odPresent = !(x.h_od === 0 || x.v_od === 0) && !this.noOD;
            x.osPresent = !(x.h_os === 0 || x.v_os === 0) && !this.noOS;
        } else {
            x.phasePoint = { x: x.timestamp, y: 0, text: phaseName(x) };
            x.ownType = 'phase';
        }
        return x;
    }

    addRelatedStuff(x: DataPoint) {
        x.dataSet = this;
        return x;
    }

    get name() {
        return this.stimuli;
    }

    getPointByFrame(frame: number, duration: number = 0) {
        frame = Math.floor(frame);

        let point = this.dataPoints.find((x) => {
            if (!isPosDataPoint(x)) return;
            if (duration) {
                let scale = (this.dataPoints.length - 1) / duration;
                scale = scale < 2 ? scale : 2;
                
                return x.index === Math.floor(frame * scale);
            }
            return x.index === frame;
        });
        if (!point) throw 'cant getPointByFrame: no point';        
        return point;
    }

    getFrameByTime(time: number) {
        let lastPoint = null as any;
        const scale = this.dataPoints.length / 834 > 2 ? 2 : this.dataPoints.length / 834;
        if (this.dataPoints) {
            this.dataPoints.find((x) => {
                if (!isPosDataPoint(x)) return;
                if (x.timestamp <= time) lastPoint = x;
                if (x.timestamp > time / scale) return true; // done
            });
            if (!lastPoint) throw 'cant getFrameByTime: no lastPoint';
            return lastPoint;
        }
    }

    // could use posPoints view?
    changeToDegrees() {
        let { degtopxHOS, degtopxHOD, degtopxVOS, degtopxVOD } = this;

        if (this.dataPoints) {
            this.dataPoints.forEach((x) => {
                if (!isPosDataPoint(x)) return;

                x.h_od /= degtopxHOD;
                x.h_os /= degtopxHOS;

                x.h_od_pos /= degtopxHOD;
                x.h_os_pos /= degtopxHOS;

                x.v_od /= degtopxVOD;
                x.v_os /= degtopxVOS;

                x.v_od_pos /= degtopxVOD;
                x.v_os_pos /= degtopxVOS;

                x.h_od *= -1;
                x.h_os *= -1; // mirroring horizontal axis
                x.h_od_pos *= -1;
                x.h_os_pos *= -1; // mirroring horizontal axis
                x.v_od *= -1;
                x.v_os *= -1; // mirroring vertical axis
                x.v_od_pos *= -1;
                x.v_os_pos *= -1; // mirroring vertical axis

                posPointAddDerivedStuff(x);
            });
        }
    }
}

function rotateVector([x, y]: any, alpha: any) {
    alpha = (-alpha * Math.PI) / 180; // TODO: wtf is the direction, is zero does no rotation

    let cos = Math.cos(alpha);
    let sin = Math.sin(alpha);

    let result = [x * cos - y * sin, x * sin + y * cos];
    return result as [number, number];
}

function posPointAddDerivedStuff(x: any) {
    x.ODhPoint = { x: x.timestamp, y: x.h_od };
    x.ODvPoint = { x: x.timestamp, y: x.v_od };
    x.ODtPoint = { x: x.timestamp, y: x.t_od };

    x.OShPoint = { x: x.timestamp, y: x.h_os };
    x.OSvPoint = { x: x.timestamp, y: x.v_os };
    x.OStPoint = { x: x.timestamp, y: x.t_os };

    x.ODtrackingPoint = {
        x: x.h_od,
        y: x.v_od,
    };
    x.OStrackingPoint = {
        x: x.h_os,
        y: x.v_os,
    };
}

class DomainCollector {
    constructor(public min: null | number = null, public max: null | number = null) {}

    see(n: number) {
        if (this.min == null) {
            this.min = n;
        } else {
            if (this.min > n) this.min = n;
        }

        if (this.max == null) {
            this.max = n;
        } else {
            if (this.max < n) this.max = n;
        }
    }

    // cover(other: DomainCollector) {
    //   let aMin = this.min
    //   let bMin = other.min

    //   let aMax = this.max
    //   let bMax = other.max

    //   let min: number|null
    //   if (aMin == null && bMin == null) {
    //     min = null
    //   } else if (aMin == null) {
    //     min = bMin
    //   } else if (bMin == null) {
    //     min = aMin
    //   } else {
    //     min = Math.min(aMin, bMin)
    //   }

    //   let max: number|null
    //   if (aMax == null && bMax == null) {
    //     max = null
    //   } else if (aMax == null) {
    //     max = bMax
    //   } else if (bMax == null) {
    //     max = aMax
    //   } else {
    //     max = Math.min(aMax, bMax)
    //   }

    //   return new DomainCollector(min, max)
    // }
}

interface XY {
    x: number;
    y: number;
}
interface StartFinish {
    start: XY;
    finish: XY;
}

function within(rect: StartFinish, xy: XY) {
    let { x, y } = xy;
    let start = {
        x: Math.min(rect.start.x, rect.finish.x),
        y: Math.min(rect.start.y, rect.finish.y),
    };
    let finish = {
        x: Math.max(rect.start.x, rect.finish.x),
        y: Math.max(rect.start.y, rect.finish.y),
    };
    let xWithin = start.x <= x && finish.x >= x;
    // let result = start.y <= y && finish.y >= y && start.x <= x && finish.x >= x
    let result = xWithin;
    return result;
}
