import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TRANSLATIONS } from '../../_languages/translation'; // import our injection token
import { ConfigService } from '../../_services/general/config.service';

@Injectable()
export class TranslateService {
    public onLangChanged: EventEmitter<string> = new EventEmitter<string>();
    private _defaultLang: string;
    private _fallback: boolean;
    private PLACEHOLDER = '%';

    constructor(@Inject(TRANSLATIONS) private _translations: any, private configService: ConfigService) {
        this._defaultLang = 'en';
        this._currentLang = this.configService.localization;
        this.onLangChanged.emit(this.configService.localization);
    }

    private _currentLang: string;

    public get currentLang() {
        return this._currentLang || this._defaultLang;
    }

    public enableFallback(enable: boolean) {
        this._fallback = enable;
    }

    public use(lang: string): void {
        this.onLangChanged.emit(lang);
        this._currentLang = lang;
    }

    instant(key: string, words?: string | string[]): string {
        const translation: string = this.translate(key);

        if (!words) {
            return translation;
        }

        return this.replace(translation, words);
    }

    instantArray(keys: string[]): string[] {
        return keys.map((key) => this.translate(key));
    }

    replace(word = '', words: string | string[] = ''): string {
        let translation: string = word;

        const values: string[] = [].concat(words);
        values.forEach((e, i) => {
            translation = translation.replace(this.PLACEHOLDER.concat(i as any), e);
        });

        return translation;
    }

    private translate(key: string): string {
        const translation = key;

        if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
            return this._translations[this.currentLang][key];
        }

        if (!this._fallback) {
            return translation;
        }

        if (this._translations[this._defaultLang] && this._translations[this._defaultLang][key]) {
            return this._translations[this._defaultLang][key];
        }

        return translation;
    }
}
