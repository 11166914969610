// @ts-nocheck
import * as _ from 'lodash';
import { Point } from './Point';

const ERROR_NO_SPACE = 'taking non-existing space';

// you start with fixed area and cut it down into specific sub-areas
// construct new  rectangles with takeABC
// then read needed stuff from them with cornerABC, rangeABC
//
// NOTE: top and bottom are relative to preview (so x.bottom > x.top coordinates-wise, since the top-left corner is 0,0)
// NOTE: I'll try with corners and then see if there is a need for gaps (is gap a part of the rect (to have corner positions altered)?)

type Delta = number | ((arg0: Layout) => number);
type PointFunction = (arg0: Layout) => Point;

class Layout {
    left: number;
    top: number;
    right: number;
    bottom: number;
    name: string;

    initialLeft: number;
    initialTop: number;
    initialRight: number;
    initialBottom: number;
    initialWidth: number;
    initialHeight: number;

    areas: Layout[];
    pointFunction?: PointFunction;

    static xywh(x: number, y: number, w: number, h: number, name: string = '') {
        return new Layout(x, y, x + w, y + h, name);
    }

    constructor(left: number, top: number, right: number, bottom: number, name: string = '') {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        this.name = name;

        this.initialLeft = left;
        this.initialTop = top;
        this.initialRight = right;
        this.initialBottom = bottom;

        this.initialWidth = this.width();
        this.initialHeight = this.height();

        this.areas = [];
    }

    width(): number {
        return this.right - this.left;
    }

    height(): number {
        return this.bottom - this.top;
    }

    // used for preview
    listAreas(): Layout[] {
        let got: Layout[] = [this];
        got.push(..._.flatten(this.areas.map((x) => x.listAreas())));
        return got.filter((x) => x.existing());
    }

    existing(): boolean {
        return this.width() > 0 && this.height() > 0 && !!this.name;
    }

    copyRect(name: string): Layout {
        return new Layout(this.left, this.top, this.right, this.bottom, name);
    }
    copy() {
        return this.copyRect('copy');
    }

    // adds pads to width from both sides
    padX(pad: number) {
        return new Layout(this.left - pad, this.top, this.right + pad, this.bottom, this.name);
    }

    centeredWithW(w: number) {
        let left = this.cx - w / 2;
        let right = this.cx + w / 2;
        return new Layout(left, this.top, right, this.bottom, this.name);
        // return this.padX(w*2 - this.w)
    }

    centeredWithH(h: number) {
        let top = this.cy - h / 2;
        let bottom = this.cy + h / 2;
        return new Layout(this.left, top, this.right, bottom, this.name);
    }

    // adds pads to height from both sides
    padY(pad: number) {
        return new Layout(this.left, this.top - pad, this.right, this.bottom + pad, this.name);
    }

    calcDelta(delta: Delta): number {
        if (typeof delta === 'function') {
            return Math.floor(delta(this));
        } else {
            return delta;
        }
    }

    takeTop(delta: Delta, name: string = ''): Layout {
        delta = this.calcDelta(delta);
        let cut = this.copyRect(name);
        this.areas.push(cut);

        this.top += delta;

        cut.bottom = this.top;

        this.checkErrors(name);
        return cut;
    }

    takeRight(delta: Delta, name: string = ''): Layout {
        delta = this.calcDelta(delta);
        let cut = this.copyRect(name);
        this.areas.push(cut);

        this.right -= delta;
        cut.left = this.right;

        this.checkErrors(name);
        return cut;
    }

    takeLeft(delta: Delta, name: string = ''): Layout {
        delta = this.calcDelta(delta);
        let cut = this.copyRect(name);
        this.areas.push(cut);

        this.left += delta;
        cut.right = this.left;

        this.checkErrors(name);
        return cut;
    }

    takeBottom(delta: Delta, name: string = ''): Layout {
        delta = this.calcDelta(delta);
        let cut = this.copyRect(name);
        this.areas.push(cut);

        this.bottom -= delta;
        cut.top = this.bottom;

        this.checkErrors(name);
        return cut;
    }

    takeAll(name: string): Layout {
        if (!this.existing()) this.logError(ERROR_NO_SPACE, name);

        let cut = this.copyRect(name);
        this.areas.push(cut);

        this.left = this.right;
        this.top = this.bottom;

        return cut;
    }

    // stuff useful for input to D3/svg

    get viewBoxParams() {
        return [this.initialLeft, this.initialTop, this.initialRight, this.initialBottom];
    }
    viewBox(): string {
        return `${this.initialLeft} ${this.initialTop} ${this.initialRight} ${this.initialBottom}`;
    }
    rangeX(): [number, number] {
        return [this.left, this.right];
    }
    rangeXSwap(): [number, number] {
        return [this.right, this.left];
    }
    rangeY(): [number, number] {
        return [this.top, this.bottom];
    }
    rangeYSwap(): [number, number] {
        return [this.bottom, this.top];
    }
    rangeYr(): [number, number] {
        return this.rangeYSwap();
    }

    // get center() {
    //   return {
    //     x: this.centerX(),
    //     y: this.centerY(),
    //   }
    // }

    centerX(): number {
        return (this.right + this.left) / 2;
    }
    centerY(): number {
        return (this.bottom + this.top) / 2;
    }
    centerPoint(): Point {
        let x = this.centerX();
        let y = this.centerY();
        return new Point(x, y);
    }

    // maybe non-getters will be removed
    get cx() {
        return this.centerX();
    }
    get cy() {
        return this.centerY();
    }
    get midx() {
        return this.centerX();
    }
    get mid() {
        return this.centerPoint();
    }
    get midy() {
        return this.centerY();
    }
    get tl() {
        return this.cornerTL();
    }
    get br() {
        return this.cornerBR();
    }
    get tr() {
        return this.cornerTR();
    }
    get bl() {
        return this.cornerBL();
    }
    get w() {
        return this.width();
    }
    get h() {
        return this.height();
    }

    get ml() {
        let x = this.left;
        let y = this.midy;
        return new Point(x, y);
    }
    get tm() {
        let x = this.midx;
        let y = this.top;
        return new Point(x, y);
    }

    get l() {
        return this.left;
    }
    get t() {
        return this.top;
    }
    get r() {
        return this.right;
    }
    get b() {
        return this.bottom;
    }

    // top-left
    cornerTL(): Point {
        let y = this.top;
        let x = this.left;
        return new Point(x, y);
    }
    cornerTR(): Point {
        let y = this.top;
        let x = this.right;
        return new Point(x, y);
    }
    cornerBL(): Point {
        let y = this.bottom;
        let x = this.left;
        return new Point(x, y);
    }
    cornerBR(): Point {
        let y = this.bottom;
        let x = this.right;
        return new Point(x, y);
    }

    // resizing final area

    shrinkToProportions(exampleWidth: number, exampleHeight: number, alignToTop = true) {
        let currentWidth = this.width();
        let currentHeight = this.height();

        let wantedRatio = exampleWidth / exampleHeight;
        let currentRatio = currentWidth / currentHeight;

        if (wantedRatio > currentRatio) {
            // gotta shrink height:
            // - get wanted height
            // - get delta-height
            // - move TL corner down by the half delta
            // - similar move to BR corner up
            let wantedWidth = currentWidth;
            let wantedHeight = wantedWidth / wantedRatio;
            let deltaHeightStep = (currentHeight - wantedHeight) / 2;

            let { left, top, right, bottom, name } = this;
            let deltaTop;
            let deltaBottom;
            if (alignToTop) {
                deltaTop = 0;
                deltaBottom = -deltaHeightStep * 2;
            } else {
                deltaTop = deltaHeightStep;
                deltaBottom = -deltaHeightStep;
            }
            return new Layout(left, top + deltaTop, right, bottom + deltaBottom, `${name} resized`);
        }
        if (wantedRatio < currentRatio) {
            let wantedHeight = currentHeight;
            let wantedWidth = wantedHeight * wantedRatio;
            let deltaWidthStep = (currentWidth - wantedWidth) / 2;

            let { left, top, right, bottom, name } = this;
            return new Layout(left + deltaWidthStep, top, right - deltaWidthStep, bottom, `${name} resized`);
        }
        // if (wantedRatio === currentRatio) return this // no change
        return this;
    }

    slide(dx: number, dy: number) {
        return new Layout(this.left + dx, this.top + dy, this.right + dx, this.bottom + dy, this.name);
    }

    // convenience

    setPoint(f: PointFunction) {
        this.pointFunction = f;
    }
    getPoint(): Point {
        if (!this.pointFunction) this.logError('no setPoint(function) provided', 'point()');
        if (!this.pointFunction) return this.cornerTL();
        return this.pointFunction(this);
    }
    get point(): Point {
        return this.getPoint();
    }
    set pointF(x: PointFunction) {
        this.setPoint(x);
    }

    // privateish

    checkErrors(anotherName: string) {
        if (this.left > this.right) this.logError(ERROR_NO_SPACE, anotherName);
        if (this.top > this.bottom) this.logError(ERROR_NO_SPACE, anotherName);
    }
    logError(x: string, anotherName: string) {}
}

export { Layout };
