// @ts-nocheck
import * as _ from 'lodash';
import { translate, getNewClass } from './util';
import { Target, Scale } from './types';
import { drawStar, drawArrow } from './drawable';

interface WhiskersParams {
    x: Scale;
    y: Scale;
    halfWidth: number;
    theme: string;
    twoBoxes?: boolean;
    noMinLine?: boolean;
    noMaxLine?: boolean;
    style?: WhiskersStyle;
    arrowdx?: number; // "pixels"
    onMouseDown?: (a: WhiskerPosition) => void;
}

let defaultStyle = {
    // whisker: { color: '#ccc' },
    // whisker: { color: '#fff', klass: 'sWhite' },
    whisker: { color: '', colorKlass: 'White2' },
    whiskerMean: {
        color: '#333',
        scaleInside: 6,
        scaleOutside: 8,
    },
    // whiskerBg: { color: '#' },
    // whiskerBorder: { color: 'white' },
    // whiskerLine: { color: 'white' },
    // whiskerLineBg: { color: '#333' },
};
let dark = defaultStyle;
// let dark = {
//   whiskerBg: { color: '#333' },
//   whiskerBorder: { color: 'white' },
//   whiskerLine: { color: 'white' },
//   whiskerLineBg: { color: '#333' },
// }
const OMG_STYLE = { default: defaultStyle, dark };
function getStyle(theme: string) {
    return OMG_STYLE[theme] || OMG_STYLE.default;
}
type WhiskersStyle = ReturnType<typeof getStyle>;

export interface WhiskerPosition {
    x: number;
    // x: 0..38 (deg)
    ys: [number, number, number, number, number];
    // ys: [min, 25%, 50%, 75%, max] (y: 0..1200 (deg/s))
    meany: number;
}

export class Whiskers {
    klass: string;
    target?: Target;
    style: WhiskersStyle;
    items: WhiskerPosition[] = [];

    get selector() {
        return `.${this.klass}`;
    }
    get x() {
        return this.props.x;
    }
    get y() {
        return this.props.y;
    }
    get halfWidth() {
        return this.props.halfWidth;
    }

    constructor(private props: WhiskersParams) {
        this.klass = getNewClass('whiskers');
        this.style = getStyle(props.theme);
        if (props.style) {
            // let style = _.clone(this.style)
            let style = JSON.parse(JSON.stringify(this.style));
            this.style = _.merge(style, props.style);
        }
    }

    render = (givenTarget: Target | undefined = undefined) => {
        let atMin = 0;
        let at25 = 1;
        let at50 = 2;
        let at75 = 3;
        let atMax = 4;

        if (!this.target) this.target = givenTarget;
        let { target, halfWidth, items } = this;
        if (!target) return console.log('no target for Whiskers');

        let barRadius = this.x(halfWidth) - this.x(0);
        let lineRadius = 0.5;
        let lineRadius50 = 2; // central h-line

        let outsideColor = this.style.whisker.color;
        let outsideClass = ` s${this.style.whisker.colorKlass} f${this.style.whisker.colorKlass}`;

        let insideColor = this.style.whiskerMean.color;

        let useStyle = (color: string) => (t: Target) => t.attr('stroke', color).attr('fill', color);
        let useHandlers = (t: Target) => {
            let handler = this.props.onMouseDown;
            if (!handler) {
                t.on('mousedown', null);
                t.attr('cursor', 'default');
                return;
            }
            t.on('mousedown', handler);
            t.attr('cursor', 'pointer');
        };

        // central rect
        target
            .selectAll(this.selector + '-r1')
            .data(items)
            .join('rect')
            .attr('class', this.klass + '-r1' + outsideClass)
            .attr('x', (d) => this.x(d.x) - barRadius)
            .attr('y', (d) => this.y(d.ys[at75]))
            .attr('width', barRadius * 2)
            .attr('height', (d) => this.y(d.ys[at25]) - this.y(d.ys[at75]))
            .call(useHandlers)
            .call(useStyle(outsideColor));

        // outside lines
        // central
        target
            .selectAll(this.selector + '-o1')
            .data(items)
            .join('rect')
            .attr('class', this.klass + '-o1' + outsideClass)
            .attr('x', (d) => this.x(d.x) - lineRadius)
            .attr('y', (d) => this.y(d.ys[atMax]))
            .attr('width', lineRadius * 2)
            .attr('height', (d) => this.y(d.ys[atMin]) - this.y(d.ys[atMax]))
            .call(useStyle(outsideColor));

        // min
        target
            .selectAll(this.selector + '-o2')
            .data(this.props.noMinLine ? [] : items)
            .join('rect')
            .attr('class', this.klass + '-o2' + outsideClass)
            .attr('x', (d) => this.x(d.x) - barRadius)
            .attr('y', (d) => this.y(d.ys[atMin]) - lineRadius)
            .attr('width', barRadius * 2)
            .attr('height', (d) => lineRadius * 2)
            .call(useStyle(outsideColor));

        // max
        target
            .selectAll(this.selector + '-o3')
            .data(this.props.noMaxLine ? [] : items)
            .join('rect')
            .attr('class', this.klass + '-o3' + outsideClass)
            .attr('x', (d) => this.x(d.x) - barRadius)
            .attr('y', (d) => this.y(d.ys[atMax]) - lineRadius)
            .attr('width', barRadius * 2)
            .attr('height', (d) => lineRadius * 2)
            .call(useStyle(outsideColor));

        // inside rect lines
        // target
        //   .selectAll(this.selector+'-i1')
        //   .data(items)
        //   .join('rect')
        //     .attr('class', this.klass+'-i1')
        //     .attr('x', d => this.x(d.x) - lineRadius)
        //     .attr('y', d => this.y(d.ys[at75]))
        //     .attr('width', lineRadius * 2)
        //     .attr('height', d => this.y(d.ys[at25]) - this.y(d.ys[at75]))
        //     .call(useStyle(insideColor))

        // median
        let frameRadius = 1;
        target
            .selectAll(this.selector + '-i2frame')
            .data(items)
            .join('rect')
            .attr('class', this.klass + '-i2frame' + outsideClass)
            .attr('x', (d) => this.x(d.x) - barRadius)
            .attr('y', (d) => this.y(d.ys[at50]) - (lineRadius50 + frameRadius))
            .attr('width', barRadius * 2)
            .attr('height', (d) => (lineRadius50 + frameRadius) * 2)
            .call(useHandlers)
            .call(useStyle(outsideColor));

        target
            .selectAll(this.selector + '-i2')
            .data(items)
            .join('rect')
            .attr('class', this.klass + '-i2')
            .attr('x', (d) => this.x(d.x) - barRadius)
            .attr('y', (d) => this.y(d.ys[at50]) - lineRadius50)
            .attr('width', barRadius * 2)
            .attr('height', (d) => lineRadius50 * 2)
            .call(useHandlers)
            .call(useStyle(insideColor));

        // mean
        let widthOutside = 0.5;
        let widthInside = 0.25;
        let { scaleInside, scaleOutside } = this.style.whiskerMean;
        target
            .selectAll(this.selector + '-x1')
            .data(items)
            .join('path')
            .attr('class', this.klass + '-x1' + outsideClass)
            .attr('d', drawStar)
            .attr(
                'transform',
                (d) =>
                    translate({
                        x: this.x(d.x),
                        y: this.y(d.meany),
                    }) + ` scale(${scaleOutside})`
            )
            .attr('stroke-width', widthOutside)
            .call(useHandlers)
            .call(useStyle(outsideColor));
        target
            .selectAll(this.selector + '-x2')
            .data(items)
            .join('path')
            .attr('class', this.klass + '-x2')
            .attr('d', drawStar)
            .attr(
                'transform',
                (d) =>
                    translate({
                        x: this.x(d.x),
                        y: this.y(d.meany),
                    }) + ` scale(${scaleInside})`
            )
            .attr('stroke-width', widthInside)
            .call(useHandlers)
            .call(useStyle(insideColor));

        let arrows = !this.props.arrowdx
            ? []
            : _.flatten(
                  items.map((w) => {
                      let x0 = w.x;
                      let y0 = w.ys[0];
                      let ymax = w.ys[atMax];
                      let dx = this.props.arrowdx || 0;
                      let tipdx = 5;

                      x0 = this.x(x0);
                      y0 = this.y(y0);
                      ymax = this.y(ymax);

                      let them = [{ x0, y0, dx, tipdx }];
                      them.push({ x0, y0: ymax, dx, tipdx });
                      return them;
                  })
              );
        target
            .selectAll(this.selector + '-arr1')
            .data(arrows)
            .join('path')
            .attr('class', this.klass + `-arr1 s${this.style.whisker.colorKlass}`)
            .attr('d', drawArrow)
            .attr('stroke', this.style.whisker.color)
            .attr('stroke-width', 2)
            .attr('fill', 'none');

        /*
        let lineRadius = 2
        let lineRadius50 = 1

        let useStyleRect = (t: Target) =>
          t
            .attr('stroke', this.style.whiskerBorder.color)
            .attr('fill', this.style.whiskerBg.color);

        let useStyleLine = (t: Target) =>
          t
            .attr('stroke', this.style.whiskerLine.color)
            .attr('fill', this.style.whiskerLineBg.color);

        let useStyleLine50 = (t: Target) =>
          t
            .attr('stroke', this.style.whiskerLineBg.color)
            .attr('fill', this.style.whiskerLineBg.color);

        target
          .selectAll(this.selector+'-r1')
          .data(items)
          .join('rect')
            .attr('class', this.klass+'-r1')
            .attr('x', d => this.x(d.x) - barRadius)
            .attr('y', d => this.y(d.ys[at75]))
            .attr('width', barRadius * 2)
            .attr('height', d => this.y(d.ys[at25]) - this.y(d.ys[at75]))
            .call(useStyleRect)

        target
          .selectAll(this.selector+'-l1')
          .data(items)
          .join('rect')
            .attr('class', this.klass+'-l1')
            .attr('x', d => this.x(d.x) - lineRadius)
            .attr('y', d => this.y(d.ys[atMax]))
            .attr('width', lineRadius * 2)
            .attr('height', d => this.y(d.ys[atMin]) - this.y(d.ys[atMax]))
            .call(useStyleLine)

        target
          .selectAll(this.selector+'-l2')
          .data(items)
          .join('rect')
            .attr('class', this.klass+'-l2')
            .attr('x', d => this.x(d.x) - barRadius)
            .attr('y', d => this.y(d.ys[atMin]) - lineRadius)
            .attr('width', barRadius * 2)
            .attr('height', d => lineRadius * 2)
            .call(useStyleLine)

        target
          .selectAll(this.selector+'-l4')
          .data(items)
          .join('rect')
            .attr('class', this.klass+'-l4')
            .attr('x', d => this.x(d.x) - barRadius)
            .attr('y', d => this.y(d.ys[atMax]) - lineRadius)
            .attr('width', barRadius * 2)
            .attr('height', d => lineRadius * 2)
            .call(useStyleLine)

        target
          .selectAll(this.selector+'-l3')
          .data(items)
          .join('rect')
            .attr('class', this.klass+'-l3')
            .attr('x', d => this.x(d.x) - barRadius)
            .attr('y', d => this.y(d.ys[at50]) - lineRadius50)
            .attr('width', barRadius * 2)
            .attr('height', d => lineRadius50 * 2)
            .call(useStyleLine50)
*/
    };
}
