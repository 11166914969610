import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { IOrder } from '../../../../../../commonout/interfaces/order.interface';
import { IUserAction } from '../../../../../common/interfaces/user-action.interface';
import { IActionSearchTerms } from '../../../../../common/interfaces/action-search-term.interface';

@Injectable()
export class ActionService {
    constructor(private configService: ConfigService, private http: HttpClient) {}

    search(term: IActionSearchTerms, order: IOrder, page: number): Observable<{ actions: IUserAction[]; more: boolean }> {
        const url = `${this.configService.backendUrl}/action/find`;
        return this.http.post<{ actions: IUserAction[]; more: boolean }>(url, { term, order, page });
    }
}
