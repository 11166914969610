import { IDC_ITEM_TYPE } from '../../enums/idc-item-type.enum';
import { IIDCTag } from '../../interfaces/idc-list-item.interface';
import { IdcListItem } from './IdcListItem.model';

export class IdcTagItem extends IdcListItem implements IIDCTag {
    info: string;
    tag: string;
    constructor() {
        super();
        this.type = IDC_ITEM_TYPE.TAG;
    }
    public get model(): IIDCTag {
        return {
            info: this.info,
            tag: this.tag,
            creator: this.creator,
            deleted: this.deleted,
            testType: this.testType,
            timestamp: this.timestamp,
            type: this.type,
        };
    }
    public set model(model: IIDCTag) {
        this.info = model.info;
        this.tag = model.tag;
        this.creator = model.creator;
        this.deleted = model.deleted;
        this.testType = model.testType;
        this.timestamp = model.timestamp;
    }
}
