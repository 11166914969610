import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LensometryNidekLm7pTestFrontend } from '../../../../../../_models/tests/NIDEK/LM7P/lensometryNidekLm7pTestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'lensometry-test-remarks',
    template: require('./lensometry-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./lensometry-test-remarks.component.scss')],
})
export class LensometryTestRemarksComponent extends TestRemarksComponent {
    test: LensometryNidekLm7pTestFrontend;
    private readonly lensType: string[] = ['Single vision', 'Bifocals', 'Progressives'];
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            dataBlock: this.formBuilder.array([]),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map(m => m.downloadMediaData(this.fileService)));
        this.remarksForm.patchValue(
            {
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
        this.remarksForm.setControl(
            'dataBlock',
            this.formBuilder.array(
                this.test.remarks.measurements
                    .map(m => {
                        return this.formBuilder.group({
                            sourceFileName: m.sourceFileName,
                            OD: this.formBuilder.group({
                                Sphere: { value: m.OD.Sphere.value, disabled: true },
                                Cylinder: { value: m.OD.Cylinder.value, disabled: true },
                                Axis: { value: m.OD.Axis.value, disabled: true },
                                SE: { value: m.OD.SE.value, disabled: true },
                                ADD: { value: m.OD.ADD.value, disabled: true },
                                ADD2: { value: m.OD.ADD2.value, disabled: true },
                                NearSphere: { value: m.OD.NearSphere.value, disabled: true },
                                NearSphere2: { value: m.OD.NearSphere2.value, disabled: true },
                                Prism: { value: m.OD.Prism.value, disabled: true },
                                PrismBase: { value: m.OD.PrismBase.value, disabled: true },
                                PrismX: { value: m.OD.PrismX.value, disabled: true },
                                PrismY: { value: m.OD.PrismY.value, disabled: true },
                                UVTransmittance: { value: m.OD.UVTransmittance.value, disabled: true },
                                ConfidenceIndex: { value: m.OD.ConfidenceIndex.value, disabled: true },
                                lensType: { value: m.OD.lensType.value, disabled: false },
                                glassStatus: { value: m.OD.glassStatus.value, disabled: false },
                                remarks: { value: m.OD.remarks.value, disabled: false },
                            }),
                            OS: this.formBuilder.group({
                                Sphere: { value: m.OS.Sphere.value, disabled: true },
                                Cylinder: { value: m.OS.Cylinder.value, disabled: true },
                                Axis: { value: m.OS.Axis.value, disabled: true },
                                SE: { value: m.OS.SE.value, disabled: true },
                                ADD: { value: m.OS.ADD.value, disabled: true },
                                ADD2: { value: m.OS.ADD2.value, disabled: true },
                                NearSphere: { value: m.OS.NearSphere.value, disabled: true },
                                NearSphere2: { value: m.OS.NearSphere2.value, disabled: true },
                                Prism: { value: m.OS.Prism.value, disabled: true },
                                PrismBase: { value: m.OS.PrismBase.value, disabled: true },
                                PrismX: { value: m.OS.PrismX.value, disabled: true },
                                PrismY: { value: m.OS.PrismY.value, disabled: true },
                                UVTransmittance: { value: m.OS.UVTransmittance.value, disabled: true },
                                ConfidenceIndex: { value: m.OS.ConfidenceIndex.value, disabled: true },
                                lensType: { value: m.OS.lensType.value, disabled: false },
                                glassStatus: { value: m.OS.glassStatus.value, disabled: false },
                                remarks: { value: m.OS.remarks.value, disabled: false },
                            }),
                        });
                    })
                    .sort(this.sort)
            )
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {
        this.test.remarks.measurements.forEach(m => {
            const formItem: any = rawFormValue.dataBlock.find((item: any) => item.sourceFileName === m.sourceFileName);
            if (!formItem) throw 'Error managing formarray in ' + this.test.name;
            m.OD.lensType.registerEdit(formItem.OD.lensType, this.authenticationService.currentUser.getModel(true));
            m.OD.glassStatus.registerEdit(formItem.OD.glassStatus, this.authenticationService.currentUser.getModel(true));
            m.OD.remarks.registerEdit(formItem.OD.remarks, this.authenticationService.currentUser.getModel(true));
            m.OS.lensType.registerEdit(formItem.OS.lensType, this.authenticationService.currentUser.getModel(true));
            m.OS.glassStatus.registerEdit(formItem.OS.glassStatus, this.authenticationService.currentUser.getModel(true));
            m.OS.remarks.registerEdit(formItem.OS.remarks, this.authenticationService.currentUser.getModel(true));
        });
        this.test.remarks.diagnosis.registerEdit(rawFormValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(rawFormValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(rawFormValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(rawFormValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
