import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_TYPE } from '../../../../../../../../../commonout/enum/test-type';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'functional-screening-test',
    template: require('./functional-screening-test-controls.component.html'),
    styles: [require('./functional-screening-test-controls.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class FunctionalScreeningTestControlsComponent extends BulbicamControlComponent implements OnInit {
    public controls: FormGroup;
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService, public formBuilder: FormBuilder) {
        super(bulbicamService, configService, socketService, authService);
    }
    ngOnInit(): void {
        this.controls = this.formBuilder.group({
            oculus: [OCULUS.OD, Validators.required],
        });
    }
    public start(): void {
        this.pendingResponse = true;
        this.controls.disable();
        const rawFormValue: {
            oculus: OCULUS;
            username: string;
            command: TEST_COMMAND;
            testType: TEST_TYPE;
        } = this.controls.getRawValue();
        rawFormValue.command = TEST_COMMAND.START;
        rawFormValue.testType = this.test.type;
        rawFormValue.username = this.authService.currentUser.username;
        this.bulbicamService
            .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, rawFormValue)
            .subscribe(() => {
                this.test.status = TEST_STATUS.IN_PROGRESS;
                this.pendingResponse = false;
            });
    }
    public enable(): void {
        this.controls.enable();
    }
    public disable(): void {}
}
