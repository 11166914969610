import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IPupillaryEvaluationArk1sMeasuredData } from '../../../../interfaces/measuredData/NIDEK/ARK1S/pupillaryEvaluation-nidek-ark1s.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class PupillaryEvaluationArk1sMeasuredData implements MeasuredData, IPupillaryEvaluationArk1sMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: {
        size: {
            type: RegisterEditField<string>;
        };
        reactionToLight: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
        rapo: {
            type: RegisterEditField<string>;
        };
        pupilMorphology: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
    };
    public OS: {
        size: {
            type: RegisterEditField<string>;
        };
        reactionToLight: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
        rapo: {
            type: RegisterEditField<string>;
        };
        pupilMorphology: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
    };
    public pupilSize: {
        OD: RegisterEditField<string>;
        OS: RegisterEditField<string>;
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_ARK1S;
        this.testType = TEST_TYPE.PUPILLARY_EVALUATION;
    }
    public abstract setModel(model: IPupillaryEvaluationArk1sMeasuredData): void;
    public getModel(): IPupillaryEvaluationArk1sMeasuredData {
        let model: IPupillaryEvaluationArk1sMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: {
                size: {
                    type: this.OD.size.type.getModel(),
                },
                reactionToLight: {
                    type: this.OD.reactionToLight.type.getModel(),
                    select: this.OD.reactionToLight.select.getModel(),
                },
                rapo: {
                    type: this.OD.rapo.type.getModel(),
                },
                pupilMorphology: {
                    type: this.OD.pupilMorphology.type.getModel(),
                    select: this.OD.pupilMorphology.select.getModel(),
                },
            },
            OS: {
                size: {
                    type: this.OS.size.type.getModel(),
                },
                reactionToLight: {
                    type: this.OS.reactionToLight.type.getModel(),
                    select: this.OS.reactionToLight.select.getModel(),
                },
                rapo: {
                    type: this.OS.rapo.type.getModel(),
                },
                pupilMorphology: {
                    type: this.OS.pupilMorphology.type.getModel(),
                    select: this.OS.pupilMorphology.select.getModel(),
                },
            },
            pupilSize: {
                OD: this.pupilSize.OD.getModel(),
                OS: this.pupilSize.OS.getModel(),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.OD.pupilMorphology.select.isEmpty() &&
            this.OD.pupilMorphology.type.isEmpty() &&
            this.OD.rapo.type.isEmpty() &&
            this.OD.reactionToLight.select.isEmpty() &&
            this.OD.reactionToLight.type.isEmpty() &&
            this.OD.size.type.isEmpty() &&
            this.OS.pupilMorphology.select.isEmpty() &&
            this.OS.pupilMorphology.type.isEmpty() &&
            this.OS.rapo.type.isEmpty() &&
            this.OS.reactionToLight.select.isEmpty() &&
            this.OS.reactionToLight.type.isEmpty() &&
            this.OS.size.type.isEmpty() &&
            this.pupilSize.OD.isEmpty() &&
            this.pupilSize.OS.isEmpty()
        );
    }
}
