import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GENDER } from '../../../../../../commonout/enum/gender';
import { IPatient } from '../../../../../common/interfaces/patient.interface';
import { ConfigService } from '../general/config.service';

@Injectable()
export class PatientService {
    constructor(private configService: ConfigService, private http: HttpClient) {}

    getPatient(id: string): Observable<IPatient> {
        const url = `${this.configService.backendUrl}/patients/one/${id}`;
        return this.http.get<IPatient>(url);
    }

    getPatientByRegisterId(registerID: string): Observable<any> {
        const url = `${this.configService.backendUrl}/patients/registerId`;
        return this.http.post(url, { registerID }).pipe(map(response => response));
    }

    create(patient: IPatient): Observable<IPatient> {
        return this.http.post<IPatient>(this.configService.backendUrl + '/patients/create', patient);
    }

    update(patient: IPatient): Observable<IPatient> {
        const url = `${this.configService.backendUrl}/patients/update`;
        return this.http.post<IPatient>(url, patient);
    }

    public async fetchDefaultCountry(): Promise<void> {
        let defaultCountry: string = localStorage.getItem('defaultCountry');
        if (!defaultCountry) {
            const url = `${this.configService.backendUrl}/patients/defaultCountry`;
            defaultCountry = await (await this.http.get<{ defaultCountry: string }>(url).toPromise()).defaultCountry;
            localStorage.setItem('defaultCountry', defaultCountry);
        }
    }

    public getPatientsByQuery(query: { startDate: number; endDate: number; gender: GENDER; startAge: number; endAge: number }): Observable<{ cnt: number }> {
        const url = `${this.configService.backendUrl}/patients/query`;
        return this.http.post<{ cnt: number }>(url, query);
    }
}
