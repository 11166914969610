import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IIDCListItem } from '../../../../../../../common/interfaces/idc-list-item.interface';

@Injectable()
export abstract class IdcListItemComponent implements OnInit, OnDestroy {
    public onSave: (listItem: IIDCListItem) => void;
    public onCancel: (listItem: IIDCListItem) => void;
    public onDelete: (listItem: IIDCListItem) => void;
    public listItem: IIDCListItem;
    public form: FormGroup;
    private subscriptions: Array<Subscription> = [];
    constructor() {}
    ngOnInit() {}
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
