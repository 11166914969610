export function calculate(values1: Array<number>, values2: Array<number>) {
	if (values1.length !== 0 && values2.length !== 0) {
	  if (values1.length == values2.length) {
  
		const total = values1.length;
		const values1_average = simple(values1);
		const values2_average = simple(values2);
  
		let sum_values_average = 0;
		let sx = 0;
		let sy = 0;
  
		for (var index = 0, l = total; index < l; index ++) {
		  const value1 = values1[index];
		  const value2 = values2[index];
  
		  let x = value1 - values1_average;
		  let y = value2 - values2_average;
  
		  sum_values_average += (x * y);
  
		  sx += Math.pow(x, 2);
		  sy += Math.pow(y, 2);
		}
  
		let n = total - 1;
  
		sx = sx / n;
		sy = sy / n;
  
		sx = Math.sqrt(sx);
		sy = Math.sqrt(sy);
  
		const correlation = (sum_values_average / (n * sx * sy)).toFixed(9);
  
		return parseFloat(correlation);
	  }
	}
  
	return null;
};

function simple (values: Array<number>) {
    if (values.length !== 0) {
      let total = 0;

      for (let i = 0, l = values.length; i < l; i ++) {
        const value = values[i];
        total += value;
      }

      const average = (total / values.length).toFixed(8);

      return parseFloat(average);
    }

    return 0;
}

function isNumeric (obj: any) {
    obj = typeof(obj) === "string" ? obj.replace(/,/g, "") : obj;
    return !isNaN(parseFloat(obj)) && isFinite(obj) && Object.prototype.toString.call(obj).toLowerCase() !== "[object array]";
}
