import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PtosisBulbicamTestFrontend } from '../../../../../_models/tests/BulbiCAM/ptosisBulbicamTestFrontend.class';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { ExaminationService } from '../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { FileService } from '../../../../../_services/general/file.service';
import { ModalService } from '../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { ChartWrapperComponent } from '../../../charts/chart-wrapper/chart-wrapper.component';
import { TestRemarksComponent } from '../../testRemarksComponent';

@Component({
    selector: 'ptosis-test-remarks',
    template: require('./ptosis-test-remarks.component.html'),
    styles: [require('../../common-remarks.scss'), require('./ptosis-test-remarks.component.scss')],
})
export class PtosisTestRemarksComponent extends TestRemarksComponent {
    @ViewChild('chart') chart: ChartWrapperComponent;
    test: PtosisBulbicamTestFrontend;
    private readonly severity: string[] = ['Mild', 'Moderate', 'Severe'];
    private readonly list: string[] = ['Absent', 'Present'];
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService,
        private bulbicamService: BulbicamService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);

        this.remarksForm = this.formBuilder.group({
            severity: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            palpebralFissureHeight: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            mro1: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            mro2: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            proptosis: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            chinElevation: '',
            facialAsymetry: '',
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.remarksForm.setValue(
            {
                severity: {
                    OD: this.test.remarks.measurements[0].severity.OD.value,
                    OS: this.test.remarks.measurements[0].severity.OS.value,
                },
                palpebralFissureHeight: {
                    OD: this.test.remarks.measurements[0].palpebralFissureHeight.OD.value,
                    OS: this.test.remarks.measurements[0].palpebralFissureHeight.OS.value,
                },
                mro1: {
                    OD: this.test.remarks.measurements[0].mro1.OD.value,
                    OS: this.test.remarks.measurements[0].mro1.OS.value,
                },
                mro2: {
                    OD: this.test.remarks.measurements[0].mro2.OD.value,
                    OS: this.test.remarks.measurements[0].mro2.OS.value,
                },
                proptosis: {
                    OD: this.test.remarks.measurements[0].proptosis.OD.value,
                    OS: this.test.remarks.measurements[0].proptosis.OS.value,
                },
                chinElevation: this.test.remarks.measurements[0].chinElevation.value,
                facialAsymetry: this.test.remarks.measurements[0].facialAsymetry.value,
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
    }
    public convertFormToModel(): void {
        const formGroupValue = this.remarksForm.value;
        this.test.remarks.measurements[0].chinElevation.registerEdit(formGroupValue.chinElevation, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].facialAsymetry.registerEdit(formGroupValue.facialAsymetry, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].mro1.OD.registerEdit(formGroupValue.mro1.OD, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].mro1.OS.registerEdit(formGroupValue.mro1.OS, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].mro2.OD.registerEdit(formGroupValue.mro2.OD, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].mro2.OS.registerEdit(formGroupValue.mro2.OS, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].palpebralFissureHeight.OD.registerEdit(formGroupValue.palpebralFissureHeight.OD, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].palpebralFissureHeight.OS.registerEdit(formGroupValue.palpebralFissureHeight.OS, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].proptosis.OD.registerEdit(formGroupValue.proptosis.OD, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].proptosis.OS.registerEdit(formGroupValue.proptosis.OS, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].severity.OD.registerEdit(formGroupValue.severity.OD, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].severity.OS.registerEdit(formGroupValue.severity.OS, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.diagnosis.registerEdit(formGroupValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(formGroupValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(formGroupValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(formGroupValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
    public isTestEmpty(): boolean {
        return this.chart.selected === null;
    }
}
