import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { IpdNidekTonorefIIIMeasuredDataFrontend } from '../../../measuredData/NIDEK/TonorefIII/IpdNidekTonorefIIIMeasuredDataFrontend.class';

export class IpdNidekTonorefIIITestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof IpdNidekTonorefIIIMeasuredDataFrontend = IpdNidekTonorefIIIMeasuredDataFrontend;
    public measurements: IpdNidekTonorefIIIMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
