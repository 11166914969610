import { PursuitAndSaccadesBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/pursuitAndSaccadesBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { PursuitAndSaccadesStartStopControlComponent, PursuitAndSaccadesTestRemarksComponent, SmoothSaccadeMergedTestChartComponent } from '../../../_components';
import { PursuitAndSaccadesBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/pursuitAndSaccadesBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class PursuitAndSaccadesBulbicamTestFrontend extends PursuitAndSaccadesBulbicamTest {
    remarks: PursuitAndSaccadesBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof PursuitAndSaccadesTestRemarksComponent = PursuitAndSaccadesTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof SmoothSaccadeMergedTestChartComponent = SmoothSaccadeMergedTestChartComponent;
    public readonly bulbicamControlComponent: typeof PursuitAndSaccadesStartStopControlComponent = PursuitAndSaccadesStartStopControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PursuitAndSaccadesBulbicamTestRemarksFrontend();
    }
}

export interface PursuitAndSaccadesBulbicamTestFrontend extends TestFrontend {}
applyMixins(PursuitAndSaccadesBulbicamTestFrontend, [TestFrontend]);
