import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ROLE } from '../../../../../../../commonout/enum/role';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ConfigService } from '../../../_services/general/config.service';

@Component({
    selector: 'menu',
    template: require('./menu.component.html'),
    styles: [require('./menu.component.scss')],
})
export class MenuComponent implements OnInit, OnDestroy {
    @Output() homeLink = new EventEmitter<string>();
    private subscriptions: Array<Subscription> = [];
    constructor(private configService: ConfigService, private authService: AuthenticationService) {}
    ngOnInit() {
        this.subscriptions.push(
            this.authService.currentUser.source.subscribe(currentUser => {
                switch (currentUser.role) {
                    case ROLE.ADMIN:
                        this.homeLink.emit('dashboard-admin');
                        break;
                    case ROLE.DOCTOR:
                        this.homeLink.emit('dashboard-staff');
                        break;
                    case ROLE.STAFF:
                        this.homeLink.emit('dashboard-staff');
                        break;
                    default:
                        this.homeLink.emit('404');
                        break;
                }
            })
        );
    }
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    private logout() {
        this.authService.logout();
    }
}
