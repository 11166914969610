import { VisualFieldMergedBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/visualFieldMergedBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { VisualFieldMergedBulbicamTestChartComponent, VisualFieldMergedTestControlsComponent, VisualFieldMergedTestRemarksComponent } from '../../../_components';
import { VisualFieldMergedBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/visualFieldMergedBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class VisualFieldMergedBulbicamTestFrontend extends VisualFieldMergedBulbicamTest {
    remarks: VisualFieldMergedBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof VisualFieldMergedTestRemarksComponent = VisualFieldMergedTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof VisualFieldMergedBulbicamTestChartComponent = VisualFieldMergedBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof VisualFieldMergedTestControlsComponent = VisualFieldMergedTestControlsComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new VisualFieldMergedBulbicamTestRemarksFrontend();
    }
}

export interface VisualFieldMergedBulbicamTestFrontend extends TestFrontend {}
applyMixins(VisualFieldMergedBulbicamTestFrontend, [TestFrontend]);
