import { Component } from '@angular/core';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { IDC_ITEM_TYPE } from '../../../../../../../../common/enums/idc-item-type.enum';
import { IdcDiagnosisItem } from '../../../../../../../../common/models/idc/idc-diagnosis-item.model';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'recordingTool-test',
    template: require('./recordingTool-test-controls.component.html'),
    styles: [require('./recordingTool-test-controls.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class RecordingToolTestControlsComponent extends BulbicamControlComponent {
    public enable(): void {}
    public disable(): void {}
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService) {
        super(bulbicamService, configService, socketService, authService);
    }
    public start(): void {
        this.pendingResponse = true;
        const payload: {
            diagnosis: string;
            IDC: string[];
            username: string;
        } = {
            diagnosis: this.test.remarks.diagnosis.value as string,
            IDC: this.test.examination.patient.ICD.filter(m => m.type === IDC_ITEM_TYPE.DIAGNOSIS).map((d: IdcDiagnosisItem) => d.model.code),
            username: this.authService.currentUser.username,
        };
        this.bulbicamService.sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, payload).subscribe(() => {
            this.test.status = TEST_STATUS.IN_PROGRESS;
            this.pendingResponse = false;
        });
    }
}
