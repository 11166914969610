import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TestResultCoordinates, PeakVelocityResults } from '../../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';

@Component({
  selector: 'peak-velocity-table',
  template: require('./peak-velocity-table.component.html'),
  styles: [require('./peak-velocity-table.component.scss')]
})
export class PeakVelocityTableComponent implements OnInit {
    @Output() highlight = new EventEmitter<TestResultCoordinates[]>();
    @Output() clearHighlight = new EventEmitter<void>();

    @ViewChild('table') table: ElementRef;
    @ViewChildren('tdOD') itemsOD: QueryList<ElementRef>;
    @ViewChildren('tdOS') itemsOS: QueryList<ElementRef>;
    @ViewChildren('tdDifference') itemsDifference: QueryList<ElementRef>;
    @ViewChildren('dataCellOD') dataCellsOD: QueryList<ElementRef>;
    @ViewChildren('dataCellOS') dataCellsOS: QueryList<ElementRef>;
    @ViewChildren('dataCellDifference') dataCellsDifference: QueryList<ElementRef>;
    
    public testResults$ = new Subject<PeakVelocityResults>();

    private subscriptions: Subscription[] = [];

    private readonly padding: { left: number; top: number; right: number; bottom: number } = { left: 200, top: 40, right: 40, bottom: 40 };
    private readonly PADDING = 5; 
    private readonly BORDER_SIZE = 0.5; 

    constructor(private renderer: Renderer2) { }

    ngOnInit() {
        this.subscriptions.push(this.testResults$.subscribe(results => {
            this.renderer.setStyle(this.table.nativeElement, 'width', `${(results.tableWidth + this.padding.left).toFixed(1)}px`);
            this.fillTable(results);         
        }));
    }

    public fillTable(testResults: PeakVelocityResults): void {
        const dataCellsOS = this.dataCellsOS.toArray();
        const dataCellsDifference = this.dataCellsDifference.toArray();
        const itemsOS = this.itemsOS.toArray();
        const itemsDifference = this.itemsDifference.toArray();

        // the count of html elements is the same
        this.dataCellsOD?.forEach((item, i) => {
            const coors = testResults.testResultCoors[i];
            
            const coodrinatesOD: string = JSON.stringify(coors);

            const coodrinatesOS: string = JSON.stringify(coors);

            const coodrinatesDifference: string = JSON.stringify(coors);

            this.renderer.setAttribute(item.nativeElement, 'coodrinates', coodrinatesOD);
            this.renderer.setAttribute(dataCellsOS[i].nativeElement, 'coodrinates', coodrinatesOS);
            this.renderer.setAttribute(dataCellsDifference[i].nativeElement, 'coodrinates', coodrinatesDifference);
        
            this.renderer.setProperty(item.nativeElement, 'innerHTML', 
                `${testResults.peakVelocityOD[i].toFixed(1)}`);
            this.renderer.setProperty(dataCellsOS[i].nativeElement, 'innerHTML', 
                `${testResults.peakVelocityOS[i].toFixed(1)}`);
            this.renderer.setProperty(dataCellsDifference[i].nativeElement, 'innerHTML', 
                `${testResults.differencePeakVelocity[i].toFixed(1)}`);

        });

        const centralCellClass = 'central-cell';

        this.itemsOD?.forEach((element, i) => {
            const width = (<string>element.nativeElement.className).includes(centralCellClass)
                ? testResults.tableCellWidths[i] - this.PADDING * 2
                : testResults.tableCellWidths[i] - this.PADDING * 2 - this.BORDER_SIZE;

            this.renderer.setStyle(element.nativeElement, 'width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsOS[i].nativeElement, 'width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsDifference[i].nativeElement, 'width', 
                `${(width).toFixed(1)}px`);

            this.renderer.setStyle(element.nativeElement, 'min-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsOS[i].nativeElement, 'min-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsDifference[i].nativeElement, 'min-width', 
                `${(width).toFixed(1)}px`);

            this.renderer.setStyle(element.nativeElement, 'max-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsOS[i].nativeElement, 'max-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsDifference[i].nativeElement, 'max-width', 
                `${(width).toFixed(1)}px`);
        });
    }

    onMouseEnter(event: MouseEvent): void {
        const coordinates: TestResultCoordinates[] = [];

        (event.target as Element).childNodes.forEach(td => {
            if ((td as Element).attributes.getNamedItem('coodrinates')) {
                const tdCoordinates = JSON.parse((td as Element).attributes.getNamedItem('coodrinates').value);
                coordinates.push(tdCoordinates);
            }
        });

        // making second line for highliting latency range
        const firstLineCoors = coordinates[0];
        coordinates.push({
            xValue: firstLineCoors.xSecondValue,
            yValue: firstLineCoors.yValue,
            run: firstLineCoors.run,
            oculus: firstLineCoors.oculus
        });

        if (coordinates.length === 0) return;

        this.highlight.emit(coordinates);
    }

    onMouseLeave(): void {
        this.clearHighlight.emit();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
