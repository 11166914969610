import { trigger, state, style, transition, animate, query, stagger, animateChild } from '@angular/animations';

export const categoryDropdownAnimation = trigger('dropdown', [
    transition('opened => closed', [
        query(
            '.subcategory',
            style({
                'min-height': '60px',
                height: '{{height}}',
                'border-width': '1px',
                opacity: 1,
            }),
            { optional: true },
        ),

        query(
            '.subcategory',
            stagger(75, [
                animate(
                    150,
                    style({
                        'min-height': 0,
                        height: 0,
                        'border-width': 0,
                        opacity: 0,
                    }),
                ),
            ]),
            { optional: true },
        ),
    ], { params: { height: '60px' } }),
    transition('closed => opened', [
        query(
            '.subcategory',
            style({
                'min-height': 0,
                height: 0,
                'border-width': 0,
                opacity: 0,
            }),
            { optional: true },
        ),

        query(
            '.subcategory',
            stagger(75, [
                animate(
                    150,
                    style({
                        'min-height': '60px',
                        height: '{{height}}',
                        'border-width': '1px',
                        opacity: 1,
                    }),
                ),
            ]),
            { optional: true },
        ),
    ], { params: { height: '60px' } }),
]);

export const cropCategoriesAnimation = trigger('cropdown', [
    state(
        'opened',
        style({
            'min-height': '60px',
            height: '100%',
            'border-width': '1px',
            opacity: 1,
        }),
    ),
    state(
        'closed',
        style({
            'min-height': 0,
            height: 0,
            'border-width': 0,
            opacity: 0,
        }),
    ),
    transition('opened <=> closed', [query('@*', [animateChild()], { optional: true })]),
]);

export const chartDropdownAnimation = trigger('chartDropdown', [
    state(
        'opened',
        style({
            display: 'flex',
        }),
    ),
    state(
        'closed',
        style({
            display: 'none',
        }),
    ),
]);

export const analysisDropdownAnimation = trigger('analysisDropdown', [
    state(
        'hideAnalysis',
        style({
            opacity: 0,
            'margin-top': '-100%',
        }),
    ),
    state(
        'showAnalysis',
        style({
            opacity: 1,
            'margin-top': 0,
        }),
    ),
    transition('hideAnalysis => showAnalysis', animate('500ms cubic-bezier(0,0,0.25,1)')),
    transition('showAnalysis => hideAnalysis', animate('500ms ease-in')),
]);
