// @ts-nocheck
import * as d3 from 'd3';
import * as _ from 'lodash';

import { Layout } from './Layout';
import { Target } from './types';
import { getStyle, doTranslate, makeLines, nonInteractive } from './util';
import { Brand } from './Brand';
// import { makeSpans } from '../util'

// styling

const styleDefault = {
    fontColor: '',
    // fontKlass: '',
    fontSize: 18,
    fontWeight: 400 as number | string,
    // fontFamily: 'Orbitron',
    fontFamily: 'DM Sans',
    opacity: '100%',
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {
    bright: {
        fontColor: '#123',
    },
    'brand-bright': {
        fontFamily: Brand.Font,
        fontWeight: 400,
    },
    'brand-dark': {
        fontFamily: Brand.Font,
        fontWeight: 400,

        fontColor: Brand.notDark,
    },
};

// interface

interface Params {
    text: string;
    alignLeft?: boolean;
    alignRight?: boolean;
    alignTop?: boolean;
    alignBottom?: boolean;
    dy?: number;
    show?: boolean;
    drawables?: Drawable[];
    onClick?: () => void;

    i?: number;
    ghost?: boolean;

    onRightClick?: () => void;

    target: Target;
    area: Layout;
    styleParams?: {
        theme?: string;
        override?: Partial<Style>;
    };
}
interface Drawable {
    x?: number;
    y?: number;
    draw: (t: Target) => void;
}

let defaultProps = {
    i: 0,
    ghost: false,
};

// function

export function TextBlock(given: Params) {
    let p = { ...defaultProps, ...given };
    let styleParams = p.styleParams || { theme: '' };
    let style = getStyle(styleDefault, themeOverrides, styleParams);

    let dy = p.dy || style.fontSize;
    let show = p.show == null ? true : p.show;
    let text = show ? p.text : null;
    let drawables = p.drawables || [];

    let point;
    let middleish = !p.alignTop && !p.alignBottom;
    if (middleish) {
        if (p.alignLeft) {
            point = p.area.centerPoint().withX(p.area.left);
        } else if (p.alignRight) {
            point = p.area.centerPoint().withX(p.area.right);
        } else {
            point = p.area.centerPoint();
        }
    } else if (p.alignBottom) {
        if (p.alignLeft) {
            point = p.area.cornerBL();
        } else if (p.alignRight) {
            point = p.area.cornerBR();
        } else {
            point = p.area.centerPoint().withY(p.area.bottom);
        }
    } else {
        // top
        if (p.alignLeft) {
            point = p.area.cornerTL();
        } else if (p.alignRight) {
            point = p.area.cornerTR();
        } else {
            point = p.area.centerPoint().withY(p.area.top);
        }
    }
    let anchor = 'middle';
    if (p.alignLeft) anchor = 'start';
    if (p.alignRight) anchor = 'end';
    let baseline = 'middle';
    if (p.alignTop) baseline = 'hanging';
    if (p.alignBottom) baseline = 'baseline';
    if (p.alignBottom) dy *= -1;

    let gText = p.target
        .selectAll(`.text-block-${p.i}`)
        .data(_.compact([text]))
        .join('g')
        .attr('class', `text-block-${p.i}`)
        // .text((d) => d)
        .call(makeLines(p.text, dy, drawables, p.alignBottom))
        .call(doTranslate(point))

        .selectAll('text')

        .attr('text-anchor', anchor)
        .attr('dominant-baseline', baseline)

        .style('fill', () => style.fontColor)
        .style('font-family', style.fontFamily)
        .style('font-size', () => style.fontSize)
        .style('font-weight', () => style.fontWeight)
        .attr('opacity', style.opacity);

    let onClick: any = p.onClick;
    let onRightClick: any = p.onRightClick;
    if (onClick || onRightClick) {
        gText.on('mousedown', () => {
            let isRightButton = d3.event.which === 3;
            if (isRightButton) {
                onRightClick && onRightClick();
            } else {
                onClick && onClick();
            }
        });
        gText.attr('cursor', 'pointer');
    }

    if (p.ghost) {
        gText.call(nonInteractive);
    }

    if (onRightClick) {
        gText.on('contextmenu', () => {
            // d3.event.stopPropagation()
            d3.event.preventDefault();
        });
        // gText.on('mousedown', () => onRightClick())
        // gText.attr('cursor', 'pointer')
    }

    // if (style.fontKlass) {
    //     gText.attr('class', style.fontKlass)
    // }
}
