import { IMaculaScan } from '../../../../common/interfaces/maculaScan.interface';
import { MaculaScan } from '../../../../common/models/maculaScan.class';
import { FileService } from '../_services/general/file.service';
import { SmartImageFrontend } from './smartImageFrontend.class';

export class MaculaScanFrontend extends MaculaScan {
    constructor() {
        super();
    }
    public setModel(model: IMaculaScan): void {
        this.imagesSequence = model.imagesSequence.map(m => {
            const i = new SmartImageFrontend();
            i.model = m;
            return i;
        });
        this.oculus = model.oculus;
        this.createdAt = model.createdAt;
    }
    public async downloadMedia(fileService: FileService): Promise<void> {
        if (this.isDownloaded()) return;
        let responce: object[] = await fileService.getFilesContentByFilesID(this.imagesSequence.map(si => si.imageID));
        responce.forEach(object => {
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    const imageUrl: string = object[key];
                    let index: number = this.imagesSequence.findIndex(si => si.imageID === key);
                    this.imagesSequence[index].imageUrl = imageUrl;
                }
            }
        });
        this.sort();
    }
    public get smartImages(): SmartImageFrontend[] {
        this.sort();
        return this.imagesSequence as SmartImageFrontend[];
    }
    private isDownloaded(): boolean {
        for (const si of this.imagesSequence) {
            if (!si.imageUrl) {
                return false;
            }
        }
        return true;
    }
    private sort(): void {
        this.imagesSequence.sort((si1: SmartImageFrontend, si2: SmartImageFrontend) => {
            const index1: number = Number.parseInt(si1.remark.value),
                index2: number = Number.parseInt(si2.remark.value);
            if (index1 < index2) {
                return -1;
            }
            if (index1 > index2) {
                return 1;
            }
            return 0;
        });
    }
}
