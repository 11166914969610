import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ThreeDScanIvueOctv3217TestFrontend } from '../../../../../../_models/tests/IVUE/OCT/threeDScanIvueOctv3217TestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'three-d-scan-ivue-oct-v3217-test-remarks',
    template: require('./three-d-scan-ivue-oct-v3217-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./three-d-scan-ivue-oct-v3217-test-remarks.component.scss')],
})
export class ThreeDScanIvueOctv3217TestRemarksComponent extends TestRemarksComponent {
    test: ThreeDScanIvueOctv3217TestFrontend;
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            dataBlock: this.formBuilder.array([]),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map(m => m.downloadMediaData(this.fileService)));
        this.remarksForm.patchValue(
            {
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
        this.remarksForm.setControl(
            'dataBlock',
            this.formBuilder.array(
                this.test.remarks.measurements
                    .map(m => {
                        return this.formBuilder.group({
                            images: { value: m.images.map(i => i.image), disabled: false },
                        });
                    })
                    .sort(this.sort)
            )
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {
        this.test.remarks.diagnosis.registerEdit(rawFormValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(rawFormValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(rawFormValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(rawFormValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
