import { PursuitAndSaccadesBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/pursuitAndSaccadesBulbicamDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class PursuitAndSaccadesBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PursuitAndSaccadesBulbicamMeasuredDataFrontend = PursuitAndSaccadesBulbicamMeasuredDataFrontend;
    public measurements: PursuitAndSaccadesBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
