// @ts-nocheck
import * as d3 from 'd3';

export function getColor (id: number, max: number) {
    id += 1;
    max += 2;
    // let space = d3.interpolateOranges
    // let space = d3.interpolateCool
    // let space = d3.interpolateWarm
    let space = d3.interpolateRainbow;

    let colorScale = d3.scaleSequential(space).domain([0, max]);
    return colorScale(id);
}
// export function getColor(id: number, max: number) {
//   let colorScale: any = d3.scaleOrdinal(d3.schemeAccent)
//   // let colorScale: any = d3.scaleOrdinal(d3.schemeSet2)
//   // let colorScale: any = d3.scaleOrdinal(d3.schemeTableau10)
//   console.log(colorScale)
//   console.log(id, colorScale(id))
//       // .domain([0, max])
//   return colorScale(id)
// }
