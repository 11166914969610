import * as jsPDF from 'jspdf';
import { IWebCamImportsMeasuredData } from '../../../../../common/interfaces/measuredData/webCamImports.measuredData.interface';
import { WebCamImportsMeasuredData } from '../../../../../common/models/measuredData/webCamImportsData.model';
import { Pager } from '../../_services/examination/export.service';
import { FileService } from '../../_services/general/file.service';
import { SmartImageFrontend } from '../smartImageFrontend.class';
import { ITableReportTestFormat } from '../tableReportTestMode';
import { WebCamImportsTestRemarksFrontend } from '../test-remarks/webCamImportsTestRemarksFrontend.class';
import { MeasuredDataFrontend } from './measuredDataFrontend';

export class WebCamImportsMeasuredDataFrontend extends WebCamImportsMeasuredData implements MeasuredDataFrontend {
    public remarks: WebCamImportsTestRemarksFrontend;
    public images: SmartImageFrontend[];
    constructor() {
        super();
    }
    public setModel(model: IWebCamImportsMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.images = model.images.map((m) => {
            const i = new SmartImageFrontend();
            i.model = m;
            return i;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OU.images = this.images.map((i) => i.imageUrl);
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.images.map((si: SmartImageFrontend) => si.download(fileService)));
    }
}
