/**
 * Described HUB application environment
 * This setting is in config file
 * For example, type of compilation depends on this property, also type of error delivery to developer, and some more stuff
 */
export enum ENVIRONMENTS {
    DEVELOPMENT = 'DEVELOPMENT',
    PRODUCTION = 'PRODUCTION',
    TEST = 'TEST',
}
