import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { ITonometryNidekTonorefIIIMeasuredData } from '../../../../interfaces/measuredData/NIDEK/TonorefIII/tonometry-nidek-tonorefIII.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class TonometryTonorefIIIMeasuredData implements MeasuredData, ITonometryNidekTonorefIIIMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: {
        measurement: RegisterEditField<string>;
        mmHg: RegisterEditField<string>;
        kPa: RegisterEditField<string>;
    }[];
    public OS: {
        measurement: RegisterEditField<string>;
        mmHg: RegisterEditField<string>;
        kPa: RegisterEditField<string>;
    }[];
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_TONOREFIII;
        this.testType = TEST_TYPE.TONOMETRY;
    }
    public abstract setModel(model: ITonometryNidekTonorefIIIMeasuredData): void;
    public getModel(): ITonometryNidekTonorefIIIMeasuredData {
        let model: ITonometryNidekTonorefIIIMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: [],
            OS: [],
        };
        this.OD.forEach(m => {
            model.OD.push({
                measurement: m.measurement.getModel(),
                mmHg: m.mmHg.getModel(),
                kPa: m.kPa.getModel(),
            });
        });
        this.OS.forEach(m => {
            model.OS.push({
                measurement: m.measurement.getModel(),
                mmHg: m.mmHg.getModel(),
                kPa: m.kPa.getModel(),
            });
        });
        return model;
    }
    public isEmpty(): boolean {
        return (
            !this.OD.some(m => !m.measurement.isEmpty() && !m.kPa.isEmpty() && !m.mmHg.isEmpty()) &&
            !this.OS.some(m => !m.measurement.isEmpty() && !m.mmHg.isEmpty() && !m.kPa.isEmpty())
        );
    }
}
