import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import chroma from 'chroma-js';
import * as d3 from 'd3';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import { VisualFieldMergedChartService } from '../../../../_services/chartServices/visualFieldMergedChartService';
import { TranslateService } from '../../../../_services/general/translate.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { DrawAnimated } from '../lib';
import { Layout } from '../lib/Layout';
import { TextBlock } from '../lib/new/TextBlock';
import {
    BooleanParams,
    ColorGroup,
    Group,
    ID,
    IFixationPoint,
    initSelected,
    InputStatus,
    instantColor,
    IRawData,
    ITargetData,
    Lasso,
    MyEdit,
    Params,
    PCParams,
    PLParams,
    PSParams,
    RawPoint,
    SettingsOption,
    ShowState,
    Target,
    XY,
} from './const';

const open = `<path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 8 4.13401 12 8 12C11.866 12 15 8 15 8C15 8 11.866 4 8 4C4.13401 4 1 8 1 8ZM2.33571 8C2.66213 8.34098 3.11732 8.7789 3.66987 9.2151C4.86829 10.1612 6.39898 11 8 11C9.60102 11 11.1317 10.1612 12.3301 9.2151C12.8827 8.7789 13.3379 8.34098 13.6643 8C13.3379 7.65902 12.8827 7.2211 12.3301 6.7849C11.1317 5.83884 9.60102 5 8 5C6.39898 5 4.86829 5.83884 3.66987 6.7849C3.11732 7.2211 2.66213 7.65902 2.33571 8Z" fill="#03272B"/><circle cx="8.00037" cy="8.00001" r="2.25428" fill="#03272B"/>`;
const close = `<path fill-rule="evenodd" clip-rule="evenodd" d="M3.41238 6.42864C2.90812 6.20338 2.31673 6.42955 2.09147 6.93381C1.87929 7.40878 2.06763 7.96105 2.51162 8.21185L1.88297 9.46914C1.75948 9.71613 1.85959 10.0165 2.10658 10.14C2.35357 10.2635 2.65391 10.1633 2.7774 9.91636L3.44778 8.57559C3.66441 8.64802 3.9179 8.72689 4.20266 8.80556C4.42764 8.86773 4.67337 8.93011 4.93697 8.98922L4.58719 10.3884C4.52022 10.6563 4.6831 10.9278 4.951 10.9947C5.2189 11.0617 5.49037 10.8988 5.55734 10.6309L5.92102 9.17611C6.41405 9.25277 6.94599 9.30977 7.50417 9.33176V11.1586C7.50417 11.4347 7.72802 11.6586 8.00417 11.6586C8.28031 11.6586 8.50417 11.4347 8.50417 11.1586V9.33178C9.03737 9.31081 9.54665 9.25788 10.0209 9.18638L10.3821 10.6309C10.449 10.8988 10.7205 11.0617 10.9884 10.9947C11.2563 10.9277 11.4192 10.6563 11.3522 10.3884L11.0061 9.00387C11.2947 8.94046 11.5627 8.87288 11.8064 8.80556C12.0883 8.72766 12.3396 8.64956 12.5548 8.57773L13.2234 9.9149C13.3469 10.1619 13.6473 10.262 13.8942 10.1385C14.1412 10.015 14.2413 9.71467 14.1179 9.46768L13.4916 8.21512C13.9397 7.96588 14.1307 7.41085 13.9176 6.93381C13.6926 6.43022 13.102 6.20417 12.5982 6.42794L12.5966 6.42864L12.5844 6.43393C12.572 6.43925 12.5514 6.44796 12.5231 6.45957C12.4665 6.48281 12.3791 6.51763 12.2641 6.56023C12.0339 6.64553 11.695 6.76139 11.2737 6.87779C10.4268 7.1118 9.26953 7.34168 8.00451 7.34168C6.73948 7.34168 5.58222 7.1118 4.73529 6.87779C4.31398 6.76139 3.97509 6.64553 3.74488 6.56023C3.51496 6.47504 3.41238 6.42864 3.41238 6.42864Z" fill="#03272B"/>`;
export interface IVisualFieldMergedTestCamMessage {
    chartTypeString: string;
    message_type: number;
    timestamp: number;
    targetx: number;
    targety: number;
    seen: number;
    targettype: number; //oculus
    srt: number;
}

type DeltaCoords = { x?: number; y?: number };
type Point = { x: number; y: number };

let screen = window.screen;

@Component({
    selector: 'visual-field-merged-test-chart',
    template: require('./visual-field-merged-test-chart.component.html'),
    styles: [require('./visual-field-merged-test-chart.component.scss')],
})
export class VisualFieldMergedBulbicamTestChartComponent extends BulbicamChartComponent implements OnInit, OnDestroy {
    @ViewChild('visualFieldChart') visualFieldChart: ElementRef;
    private subscriptions: Subscription[] = [];

    doTranslate(p: any) {
        return (t: any) => {
            let pp = p as any;

            if (typeof pp.left === 'number') {
                pp = { x: pp.left, y: pp.top };
            }
            t.attr('transform', this.translate(pp));
        };
    }

    translate(...points: DeltaCoords[]): string {
        let reducer = (a: Point, b: DeltaCoords) => ({ x: a.x + (b.x || 0), y: a.y + (b.y || 0) });
        let point = points.reduce(reducer, { x: 0, y: 0 });
        return 'translate(' + point.x + ', ' + point.y + ')';
    }

    // preset values start
    drawTick = 'M-0.42791666666666667,0.03791666666666671 L-0.16250000000000003,0.30333333333333334 L0.44958333333333333,-0.30874999999999997';
    DOT_RADIUS = 14;
    R = 30 / 2; // radius of main points
    CORNER_TEXT = {
        fontWeight: 600,
        fontSize: 16,
    };
    COLOR_GROUPS = instantColor;
    MIN_SEEN = 3; // 1,2 - unseen,nodata
    iconColor = '#03272b';
    tooltipLineWidth = 1;
    missingDataColors = ['#c4c4c4', '#909090', '#5f5f5f', '#333333', '#000'];
    timestampScale = 10000;
    tooltipWidth = 650;
    tooltipHeight = 650;
    minRawDataValue = -75;
    maxRawDataValue = 75;
    throttleRefresh = 100; // ms
    refresh = _.throttle(() => {
        this.render();
    }, this.throttleRefresh);
    // preset values end

    // settings start
    groups: Group[] = [];
    selected = new Set<number>();
    nextGroupId = 0;
    nextId = 0;

    optionShowSRT = {
        id: this.nextId += 1,
        name: 'SRT',
    };
    optionShowTAT = {
        id: this.nextId += 1,
        name: 'TAT',
    };
    optionShowZeiss = {
        id: this.nextId += 1,
        name: 'Zeiss',
    };
    options: SettingsOption[] = [this.optionShowSRT, this.optionShowTAT, this.optionShowZeiss];

    get editedGroup() {
        return this.groups.find(x => x.editing);
    }
    get lasso() {
        if (!this.editedGroup) {
            return;
        }
        return this.editedGroup.lasso;
    }
    get showSRT() {
        return this.selected.has(this.optionShowSRT.id) as boolean;
    }
    get showTAT() {
        return this.selected.has(this.optionShowTAT.id) as boolean;
    }
    get showZeiss() {
        return this.selected.has(this.optionShowZeiss.id) as boolean;
    }

    resetSettings() {
        this.groups = [];
        this.selected = new Set<number>();
        this.nextGroupId = 0;
        this.nextId = 0;
        this.optionShowSRT = {
            id: this.nextId += 1,
            name: 'SRT',
        };
        this.optionShowTAT = {
            id: this.nextId += 1,
            name: 'TAT',
        };
        this.optionShowZeiss = {
            id: this.nextId += 1,
            name: 'Zeiss',
        };
        this.options = [this.optionShowSRT, this.optionShowTAT, this.optionShowZeiss];
    }

    addGroup(given: Omit<Group, 'id'>) {
        let id = this.nextGroupId;
        this.nextGroupId += 1;
        let group = { ...given, id };
        this.groups.push(group);
    }

    resetGroups() {
        this.groups.forEach(x => {
            x.editing = false;
            x.lasso = undefined;
            x.selection = undefined;
        });
    }

    changeGroupEditing(groupId: ID, enabled?: boolean, second?: boolean) {
        if (enabled === undefined) {
            let got = this.groups.find(x => x.id === groupId);
            if (!got) {
                return;
            }
            enabled = !got.editing;
        }

        // only one can be enabled at a time
        if (enabled) {
            this.groups.forEach(x => this.changeGroupEditing(x.id, false, true));
        }

        let got = this.groups.find(x => x.id === groupId);
        if (!got) {
            return;
        }

        got.editing = enabled;
        got.lasso = undefined;
        if (!second && !enabled) {
            got.selection = undefined;
        }
    }

    stopLasso() {
        if (!this.editedGroup) {
            return;
        }
        this.editedGroup.lasso = undefined;
    }

    stopEditing() {
        if (!this.editedGroup) {
            return;
        }
        this.editedGroup.editing = false;
    }

    startLasso(x: number, y: number) {
        if (!this.editedGroup) {
            return;
        }
        this.editedGroup.lasso = { xFrom: x, yFrom: y, xTo: x, yTo: y };
    }

    updateLasso(x: number, y: number) {
        if (!this.lasso) return;

        this.lasso.xTo = x;
        this.lasso.yTo = y;
    }

    selectPoints(points: any) {
        if (!this.editedGroup) {
            return;
        }

        if (!this.editedGroup.lasso) {
            this.editedGroup.lasso = { xFrom: 0, xTo: 0, yFrom: 0, yTo: 0 };
        }

        this.editedGroup.selection = {
            points,
            lasso: { ...this.editedGroup.lasso },
        };
    }

    // toggle(id: number) {
    //     this.selected = new MySet<number>();
    //     this.selected.toggle(id);
    // }
    // settings end

    // state start
    tooltipStatus = ShowState.HIDDEN;

    get isShowingTooltip() {
        return this.tooltipStatus !== ShowState.HIDDEN;
    }
    get isFixedTooltip() {
        return this.tooltipStatus === ShowState.FIXED;
    }

    stateFixTooltip() {
        if (this.tooltipStatus === ShowState.SHOWN) {
            this.tooltipStatus = ShowState.FIXED;
        }
    }
    stateHideTooltip() {
        this.tooltipStatus = ShowState.HIDDEN;
    }
    stateShowTooltip() {
        this.tooltipStatus = ShowState.SHOWN;
    }
    //state end

    formStatus?: InputStatus;

    // rawInputData: any = [];

    private drawAnimated?: DrawAnimated;

    private margin: any;
    private height: any;
    private width: any;
    private chart: any;
    private tooltipGroup: any;
    private gGroup: any;
    private gPopup: any;
    private gPopupControls: any;
    private x: any;
    private y: any;
    private rawDataLine: any;

    private odSeenUnseen: any;
    private osSeenUnseen: any;

    gResultTable: any;
    osChartOffset!: [number, number];
    odChartOffset!: [number, number];

    params: Params;

    get squareSize() {
        return this.width / 3 - this.margin.left / 2;
    }

    gLassoOS: any; // Target
    gLassoOD: any; // Target

    gSelectionOS: any; // Target
    gSelectionOD: any; // Target

    nextGOD: any;
    nextGOS: any;

    gPrevOD: any;
    gPrevOS: any;

    pointXScale: any;
    pointYScale: any;

    get noEntries() {
        return {
            OD: [],
            OS: [],
        } as any;
    }
    entries: any = this.noEntries;

    prevPopupUpdParams: [any, any, any, any, any] = [null, null, null, null, null];

    constructor(private vsService: VisualFieldMergedChartService, private translateService: TranslateService) {
        super();
    }

    edits: any[] = [];

    ngOnInit(): void {
        this.selected.add(this.optionShowSRT.id);
        this.params = {
            svg: this.visualFieldChart.nativeElement,
            emitChange: (x: any) => this.emitChange(x),
            edits: this.edits,
            addEdit: (x: any) => this.addEdit(x),
        };
        this.initStuff();

        if (this.inputData) {
            this.addData(this.inputData);
        }

        const sub = this.vsService.chartData$.subscribe(d => {
            if (d) {
                this.drawTest(d);
            }
        });
        this.subscriptions.push(sub);
    }

    drawTest(data: any) {
        for (let dot of data.renderResults) {
            if (dot.type === 'OD') {
                this.odSeenUnseen(
                    [
                        {
                            ...dot.entry,
                            type: 'OD',
                        },
                    ],
                    dot.data,
                    dot.fixationPoints
                );
            } else if (dot.type === 'OS') {
                this.osSeenUnseen(
                    [
                        {
                            ...dot.entry,
                            type: 'OS',
                        },
                    ],
                    dot.data,
                    dot.fixationPoints
                );
            }
        }

        this.entries = data.entries;

        this.renderResultTable();
        this.refresh();
        this.vsService.isDataRendered$.next(true);
    }

    emmitForm(data: any) {
        this.setSelected(data);
    }

    addData(frames: any): void {
        this.vsService.addData([{ srt: this.showSRT, tat: this.showTAT, edits: this.edits }, ...frames]);
    }

    setEdits(got: IChartEdit[]): void {
        this.edits = got;
    }

    clearData(signal: boolean): void {
        this.clearState();
    }

    createSubChart(xOffset: number, yOffset: number, width: number, height: number, title: string) {
        const gPrev = this.chart.append('g').attr('transform', `translate(${xOffset}, ${yOffset})`);
        const g = this.chart.append('g').attr('transform', `translate(${xOffset}, ${yOffset + 150})`);
        const nextG = this.chart.append('g').attr('transform', `translate(${xOffset}, ${yOffset})`);

        if (title === 'OD') {
            this.nextGOD = nextG;
            this.gPrevOD = gPrev;
        } else if (title === 'OS') {
            this.nextGOS = nextG;
            this.gPrevOS = gPrev;
        }

        // Range of data which will be represented on the chart
        const x = d3
            .scaleLinear()
            .domain([-32, 32])
            .range([0, width]);

        const y = d3
            .scaleLinear()
            .domain([27, -27])
            .range([0, width]);

        this.pointXScale = x;
        this.pointYScale = y;

        // Preparing the values for the X and Y axis
        const xAxisBottom = d3
            .axisBottom(x)
            .tickValues(d3.range(-30, 31, 5))
            .tickSize(-6)
            .tickPadding(15);

        const xAxisTop = d3.axisTop(x).tickSize(-6);

        const yAxisLeft = d3
            .axisLeft(y)
            .tickValues(d3.range(-25, 26, 5))
            .tickSize(-6)
            .tickPadding(15);

        const yAxisRight = d3.axisRight(y).tickSize(-6);

        // Drawing the axes of the chart
        g.append('g')
            .attr('transform', 'translate(0,' + width + ')')
            .attr('class', 'axis axis--x-bottom')
            .call(xAxisBottom)
            //axisStyle
            .call((t: any) => {
                t.style('font-family', 'DM Sans');
                t.selectAll('text').style('font-size', `${18}px`);
            });

        g.append('g')
            .attr('class', 'axis axis--x-top')
            .call(xAxisTop)
            .call((t: any) => {
                t.style('font-family', 'DM Sans');
                t.selectAll('text').style('font-size', `${18}px`);
            });

        g.append('g')
            .attr('class', 'axis axis--y-left')
            .call(yAxisLeft)
            .call((t: any) => {
                t.style('font-family', 'DM Sans');
                t.selectAll('text').style('font-size', `${18}px`);
            });

        g.append('g')
            .attr('transform', 'translate(' + width + ', 0)')
            .attr('class', 'axis axis--y-right')
            .call(yAxisRight)
            .call((t: any) => {
                t.style('font-family', 'DM Sans');
                t.selectAll('text').style('font-size', `${18}px`);
            });

        // Title of the subchart
        g.append('text')
            .attr('transform', 'translate(' + width / 2 + ', -280)')
            .style('text-anchor', 'middle')
            // .attr('class', 'fBorder')
            .style('font-size', '24px')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text(this.translateService.instant(title));

        g.append('text')
            .attr('transform', 'translate(' + width / 2 + ', -20)')
            .style('text-anchor', 'middle')
            .style('font-size', '16px')
            .style('font-weight', '700')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text('Saccadic reaction time (srt)');

        // Rectagonal around the chart
        g.append('rect')
            .attr('fill', 'none')
            .attr('class', 'sBorder')
            .attr('width', width)
            .attr('height', height);

        // Vertical central line
        g.append('line')
            .attr('class', 'sBorder')
            .attr('x1', width / 2)
            .attr('y1', 0)
            .attr('x2', width / 2)
            .attr('y2', height)
            .attr('stroke-width', 1);

        // Horizontal central line
        g.append('line')
            .attr('class', 'sBorder')
            .attr('x1', 0)
            .attr('y1', height / 2)
            .attr('x2', width)
            .attr('y2', height / 2)
            .attr('stroke-width', 1);

        g.append('text')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .attr('transform', 'translate(' + width / 2 + ',' + (height + 52) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '16px')
            .text(this.translateService.instant('degrees'));

        return (data: ITargetData[], rawData: IRawData[], fixationPoints: IFixationPoint[]) => {
            d3.selectAll('.' + data[0].type + '-' + data[0].xCoor + '-' + data[0].yCoor).remove();

            g.selectAll('.rects')
                .data(data)
                .enter()
                .append('circle')
                .attr('class', 'them-rects ' + data[0].type + '-' + data[0].xCoor + '-' + data[0].yCoor)
                .attr('cx', (d: ITargetData) => {
                    return x(d.xCoor); //  - 12;
                })
                .attr('cy', (d: ITargetData) => {
                    return y(d.yCoor); // - 12 - 15;
                })
                .attr('r', this.R)
                .style('fill', (d: any) => {
                    if (d.status === 1) {
                        if (d.tempFrame.maxdistance === 0) {
                            return '#000';
                        }

                        if (d.tempFrame.maxdistance > 0 && d.tempFrame.maxdistance < 20) {
                            return '#C4C4C4';
                        }

                        if (d.tempFrame.maxdistance >= 20 && d.tempFrame.maxdistance < 30) {
                            return '#909090';
                        }

                        if (d.tempFrame.maxdistance >= 30 && d.tempFrame.maxdistance < 40) {
                            return '#5F5F5F';
                        }

                        if (d.tempFrame.maxdistance >= 40) {
                            return '#333333';
                        }
                    }
                    return d.colorRect;
                })
                .on('mouseenter', (d: ITargetData) => {
                    this.handleOnTargetMouseEnter({ x, y, xOffset, yOffset, d }, rawData, fixationPoints);
                })
                .on('mousedown', (d: ITargetData) => {
                    this.hideTooltip();
                    this.oldHideTooltip();
                    this.handleOnTargetMouseEnter({ x, y, xOffset, yOffset, d }, rawData, fixationPoints);
                    this.stateFixTooltip();
                })
                .on('mouseleave', () => {
                    this.handleOnTargetMouseLeave();
                });

            const NO_ICONS = false;
            if (!NO_ICONS) {
                g.selectAll('.rectIcons')
                    .data(data)
                    .enter()
                    .append('g')
                    .attr('class', 'them-icons ' + data[0].type + '-' + data[0].xCoor + '-' + data[0].yCoor)
                    .html((d: any) => (d.seenValue === 3 ? open : close))
                    .attr('transform', (d: ITargetData) => {
                        const { targetXOffset, targetYOffset } = d.icon;
                        return `translate(${x(d.xCoor) - targetXOffset}, ${y(d.yCoor) - targetYOffset}) scale(1.35, 1.4)`;
                    })
                    .style('fill', this.iconColor)
                    //nonInteractive
                    .call((x: any) => x.attr('pointer-events', 'none'));
            }
        };
    }

    handleOnTargetMouseEnter(data: { x: any; y: any; xOffset: number; yOffset: number; d: ITargetData }, rawData: IRawData[], fixationPoints: IFixationPoint[]) {
        // already showing
        if (this.isShowingTooltip) {
            return;
        }
        this.showTooltip();

        const { x, y, xOffset, yOffset, d } = data;

        this.popupShowStatus(this.tooltipGroup.select('#itemStatus'), d.status, d.originalStatus, d.timestamp, d.tempFrame);

        let angle = Math.atan2(-d.yCoor, d.xCoor);
        if (angle < 0) {
            angle += Math.PI * 2;
        }
        angle = (angle / Math.PI) * 180;
        let angle1 = angle - 30;
        let angle2 = angle + 30;
        this.tooltipGroup.select('#sectorLine1').style('transform', `rotate(${angle1}deg)`);
        this.tooltipGroup.select('#sectorLine2').style('transform', `rotate(${angle2}deg)`);

        this.tooltipGroup
            .attr('transform', () => {
                let xTooltipOffset = x(d.xCoor) + xOffset + 25;
                let yTooltipOffset = y(d.yCoor) + yOffset - this.tooltipHeight;

                if (d.yCoor > 0) {
                    yTooltipOffset = y(d.yCoor) + yOffset;
                }

                let xx = xTooltipOffset;
                let yy = yTooltipOffset;

                if (yy < 0) {
                    yy = 0;
                }

                if (yy + this.tooltipHeight > this.height) {
                    yy = this.height - this.tooltipHeight;
                }
                if (xTooltipOffset + this.tooltipWidth >= screen.width) {
                    xx = xTooltipOffset - this.tooltipWidth - 50; //25
                }

                return `translate(${xx}, ${yy})`;
            })
            .style('opacity', 1);

        let delta = d.actualDelta;
        // this.setPopupText(`SRT: ${d.actualSrt} ms\nTAT: ${d.actualTat} ms\nΔ: ${delta} ms`);
        this.setPopupText(`SRT: ${d.actualSrt} ms`);

        this.tooltipGroup
            .select('#tooltipVertical')
            .attr('class', 'sBorder')
            .style('stroke', null);
        this.tooltipGroup
            .select('#tooltipHorizontal')
            .attr('class', 'sBorder')
            .style('stroke', null);

        let scaledData = rawData.map(x => {
            return {
                ...x,
                timestamp: x.timestamp / this.timestampScale,
            };
        });
        if (this.drawAnimated) {
            this.drawAnimated.destroy();
        }
        this.tooltipGroup
            .select('#tooltipLineShadow')
            .datum(rawData)
            .attr('d', this.rawDataLine);
        this.drawAnimated = new DrawAnimated(scaledData, data => {
            if (this.tooltipGroup.empty()) return false;
            this.tooltipGroup
                .select('#tooltipLine')
                .datum(data)
                .attr('d', this.rawDataLine);

            this.tooltipGroup
                .select('#tooltipDots')
                .selectAll('circle')
                .data(data)
                .join('circle')
                .attr('r', 2)
                .attr('cx', (d: XY) => this.x(d.x))
                .attr('cy', (d: XY) => this.y(d.y));
            return true;
        });
        this.drawAnimated.start();

        this.tooltipGroup
            .select('#tooltipFocusPoint')
            .selectAll('circle')
            .data(fixationPoints)
            .join('circle')
            .attr('cx', (d: any) => this.x(d.x))
            .attr('cy', (d: any) => this.y(d.y))
            .attr('r', 3)
            .attr('fill', (d: any) => d.color);
    }

    popupShowStatus(target: any, status: number, originalStatus: number, id: number, tempFrame: any) {
        this.prevPopupUpdParams = [target, status, originalStatus, id, tempFrame];
        let fill = this.vsService.color[status];

        this.tooltipGroup.select('#missingDataInfo').remove();

        if (status === 1) {
            this.tooltipGroup
                .append('g')
                .attr('id', 'missingDataInfo')
                .attr('transform', 'translate(350, 100)');

            this.tooltipGroup
                .select('#missingDataInfo')
                .append('text')
                .text(`Max distance & angle: ${tempFrame.maxdistance ? tempFrame.maxdistance.toFixed(1) : 0}/${tempFrame.maxangle ? tempFrame.maxangle.toFixed(1) : 0}`)
                .attr('font-size', 18);
            this.tooltipGroup
                .select('#missingDataInfo')
                .append('text')
                .text(`Sector Samples: ${tempFrame.samplesinsector ? tempFrame.samplesinsector.toFixed(1) : 0}`)
                .attr('transform', 'translate(0, 20)')
                .attr('font-size', 18);
            this.tooltipGroup
                .select('#missingDataInfo')
                .append('text')
                .text(`Fixations: ${tempFrame.fixations ? tempFrame.fixations.toFixed(1) : 0}`)
                .attr('transform', 'translate(0, 40)')
                .attr('font-size', 18);

            if (tempFrame.maxdistance === 0) {
                fill = '#000';
            }

            if (tempFrame.maxdistance > 0 && tempFrame.maxdistance < 20) {
                fill = '#C4C4C4';
            }

            if (tempFrame.maxdistance >= 20 && tempFrame.maxdistance < 30) {
                fill = '#909090';
            }

            if (tempFrame.maxdistance >= 30 && tempFrame.maxdistance < 40) {
                fill = '#5F5F5F';
            }

            if (tempFrame.maxdistance >= 40) {
                fill = '#333333';
            }
        }
        target
            .selectAll('circle')
            .data([null])
            .join('circle')
            .attr('r', 7)
            .attr('fill', fill);
        this.renderPopupControls(status, originalStatus, id);
    }

    showTooltip() {
        this.formStatus = undefined;
        this.stateShowTooltip();
        //makeInteractive
        this.tooltipGroup.call((x: any) => x.attr('pointer-events', 'auto'));
        this.tooltipGroup.raise()
    }
    hideTooltip() {
        if (this.drawAnimated) {
            this.drawAnimated.destroy();
        }
        this.stateHideTooltip();
        this.tooltipGroup.call((x: any) => x.attr('pointer-events', 'none'));
    }

    renderPopupControls(status: number, originalStatus: number, id: number) {
        if (this.formStatus != null) status = this.formStatus;

        this.popupControls({
            target: this.gPopupControls,
            status,
            originalStatus,
            id,
            makeSeen: () => {
                this.setFormStatus(InputStatus.SEEN - 1);
            },
            makeUnseen: () => {
                this.setFormStatus(InputStatus.UNSEEN - 1);
            },
            makeNoData: () => {
                this.setFormStatus(InputStatus.NODATA - 1);
            },
        });
        this.popupSave({
            target: this.gPopup,
            clean: this.formStatus == null,
            onClick: () => {
                if (this.formStatus != null) {
                    this.changePoint(id, this.formStatus + 1);
                } else {
                    this.closeCrossClick();
                }
            },
        });
        this.popupLog({
            target: this.gPopup,
            items: this.logItems(id),
        });
    }

    logItems(id: number): string[] {
        let items = this.edits.filter(x => x.messageTimestamp === id);
        items = _.sortBy(items, x => x.date);
        return items.map(x => this.formatLogItem(x));
    }

    formatLogItem(x: IChartEdit): string {
        let f = (x: any) => this.formatStatus(parseInt(x));
        let text = '';
        text = text.concat(`${f(x.previousValue)} → ${f(x.currentValue)}`);
        text = text.concat(', ');
        text = text.concat((moment as any)(x.date).format('YY-MM-DD HH:mm'));
        text = text.concat(' by ');
        text = text.concat(x.editor);
        return text;
    }

    formatStatus(n: number): string {
        n = n - 1;
        if (n === 0) return 'Unseen';
        if (n === 1) return 'Nodata';
        if (n === 2) return 'Seen';
        return 'unknown';
    }

    emitChange(delta: any) {
        console.log(delta);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.vsService.clearData();
    }

    createChart() {
        let vbHeight = (screen.height * 2.5) / 2;
        this.chart = d3
            .select(this.params.svg)
            .attr('viewBox', '0 0 ' + screen.width + ' ' + (vbHeight + 135))
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .call((x: Target) =>
                x.attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            );
        this.margin = {
            top: 100, //vbHeight * (5 / 100),
            right: screen.width * (5 / 100),
            bottom: 0,
            left: screen.width * (5 / 100),
        };
        this.width = screen.width - this.margin.left - this.margin.right;
        this.height = vbHeight; // - this.margin.top

        this.chart
            .append('text')
            .attr('x', this.margin.top)
            .attr('y', 20)
            .style('font-size', '16px')
            .style('text-anchor', 'start')
            //applyFont
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text('');

        let legendText = ['Unseen', 'Missing data'].concat(this.COLOR_GROUPS.map(x => x[0]));
        let legendColor = [...this.vsService.color];
        let legendIcons = [...this.vsService.icons];

        legendText = this.shift(legendText, 2);
        legendColor = this.shift(legendColor, 2);
        legendIcons = this.shift(legendIcons, 2);

        let spacePosition = legendText.length - 2;
        (legendText as string[]).splice(spacePosition, 0, '');
        (legendColor as string[]).splice(spacePosition, 0, '#fff');
        (legendIcons as any[]).splice(spacePosition, 0, {
            noIcon: true,
            path: [],
            legendXOffset: 0,
            legendYOffset: 0,
            targetXOffset: 0,
            targetYOffset: 0,
        });

        legendText.splice(10, 1);

        const legend = this.chart
            .selectAll('.legend')
            .data(d3.range(legendText.length))
            .enter()
            .append('g')
            .attr('class', 'legend')
            .attr('transform', (d: any, i: number) => {
                let dy = 16 * i * 2 + this.margin.top * 1.5;
                return 'translate(0,' + dy + ')';
            });

        legend
            .append('circle')
            .attr('cx', this.width - 25)
            .attr('cy', (d: any, i: number) => {
                if (i === 9) return 30;
                return 30 / 2;
            })
            .attr('r', 30 / 2)
            .style('fill', (d: any, i: number) => {
                let { noIcon } = legendIcons[i] as any;
                if (noIcon) return 'transparent';
                return legendColor[i];
            });

        legend
            .append('g')
            .html((d: any, i: number) => {
                if (i < 8) return open;
                else return close;
            })
            .attr('transform', (d: any, i: number) => {
                const { legendYOffset } = legendIcons[i];
                if (i === 9) return `translate(${this.width - 35}, ${17 + legendYOffset}) scale(1.3,1.3)`;
                return `translate(${this.width - 35}, ${3 + legendYOffset}) scale(1.3,1.3)`;
            })
            .attr('fill', this.iconColor);

        legend
            .append('text')
            .attr('x', (d: any, i: any) => {
                let { noIcon } = legendIcons[i] as any;
                if (noIcon) return this.width - 30;
                return this.width;
            })
            .attr('y', (d: any, i: number) => {
                if (i === 9) return 34;
                return 22;
            })
            .style('font-size', '16px')
            .style('text-anchor', 'start')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text((d: any, i: number) => {
                return this.translateService.instant(legendText[i]) || legendText[i];
            });

        const missingDataTexts = ['< 20', '20 - 30', '30 - 40', '> 40', 'No fixation'];

        const missingData = this.chart
            .selectAll('.missingLegend')
            .data(d3.range(missingDataTexts.length))
            .enter()
            .append('g')
            .attr('class', 'legend')
            .attr('transform', (d: any, i: number) => {
                return 'translate(0,' + (16 * i * 2 + this.margin.top * 1.5 + 377) + ')';
            });

        missingData
            .append('circle')
            .attr('cx', this.width - 25)
            .attr('cy', this.DOT_RADIUS + 5)
            .attr('r', this.DOT_RADIUS)
            .style('fill', (d: any, i: any) => {
                return this.missingDataColors[i];
            });

        missingData
            .append('text')
            .attr('x', (d: any, i: any) => {
                let { noIcon } = legendIcons[i] as any;
                if (noIcon) return this.width - 30;
                return this.width;
            })
            .attr('y', 22 + 1.5)
            .style('font-size', '16px')
            .style('text-anchor', 'start')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text((d: any, i: number) => {
                return missingDataTexts[i];
            });

        missingData
            .append('g')
            .html((d: any, i: number) => {
                return close;
            })
            .attr('transform', (d: any, i: number) => {
                const { legendYOffset } = legendIcons[i];
                let icon = legendIcons[i];
                return `translate(${this.width - 35}, ${5 + legendYOffset}) ${icon.scale ? `scale(${icon.scale})` : ''}`;
            })
            .attr('fill', this.iconColor);

        this.x = d3
            .scaleLinear()
            .domain([this.minRawDataValue, this.maxRawDataValue])
            .range([0, this.tooltipWidth]);

        this.y = d3
            .scaleLinear()
            .domain([this.minRawDataValue, this.maxRawDataValue])
            .range([0, this.tooltipHeight]);

        this.rawDataLine = d3
            .line()
            .defined((d: any) => {
                if (d.x == null) return false;
                if (d.y == null) return false;
                if (Number.isNaN(d.x)) return false;
                if (Number.isNaN(d.y)) return false;
                return true;
            })
            .x((d: any) => {
                return this.x(d.x);
            })
            .y((d: any) => {
                return this.y(d.y);
            });
    }

    fillGroups() {
        if (this.groups.length > 0) return;

        let xOS = this.osChartOffset[0];
        let xOD = this.odChartOffset[0];
        let y = this.osChartOffset[1]; // common
        let size = this.squareSize;
        let padding = 20;

        let initials = {
            editing: false as any,
            lasso: undefined as any,
            selection: undefined as any,
        };
        const colorBottomLeft = '#05B8CC';
        const colorBottomRight = '#AB82FF';
        let bottomLeft = (x: number) => {
            return {
                color: colorBottomLeft,
                position: { x: x + padding, y: y + size - padding },
            };
        };
        let bottomRight = (x: number) => {
            return {
                color: colorBottomRight,
                position: { x: x + size - padding, y: y + size - padding },
            };
        };
        let topRight = (x: number) => {
            return {
                color: '#0276FD',
                position: { x: x + size - padding, y: y + padding },
            };
        };
        let topLeft = (x: number) => {
            return {
                color: '#0276FD',
                position: { x: x + padding, y: y + padding },
            };
        };

        let xs = [
            {
                eye: 'OS',
                other: { alignTop: true, alignBottom: false },
                ...topLeft(xOS),
                ...initials,
                color: '#05B8CC',
            },
            {
                eye: 'OS',
                other: { alignRight: true, alignTop: true, alignBottom: false },
                ...topRight(xOS),
                ...initials,
            },
            {
                eye: 'OS',
                ...bottomLeft(xOS),
                ...initials,
            },
            {
                eye: 'OS',
                other: { alignRight: true },
                ...bottomRight(xOS),
                ...initials,
            },
            {
                eye: 'OD',
                other: { alignLeft: true, alignTop: true, alignBottom: false },
                ...topLeft(xOD),
                ...initials,
            },
            {
                eye: 'OD',
                other: { alignRight: true, alignTop: true, alignBottom: false },
                ...topRight(xOD),
                ...initials,
                color: '#05B8CC',
            },
            {
                eye: 'OD',
                ...bottomLeft(xOD),
                ...initials,
                color: colorBottomRight,
            },
            {
                eye: 'OD',
                other: { alignRight: true },
                ...bottomRight(xOD),
                ...initials,
                color: colorBottomLeft,
            },
        ];
        xs.forEach(x => this.addGroup(x));
    }

    initStuff() {
        this.createChart();
        this.osChartOffset = [1.5 * this.margin.left, 1.5 * this.margin.top];
        this.odChartOffset = [4 * this.margin.left + this.width / 3, 1.5 * this.margin.top];
        this.fillGroups();

        this.osSeenUnseen = this.createSubChart(this.osChartOffset[0], this.osChartOffset[1], this.squareSize, this.squareSize, 'OS');

        this.odSeenUnseen = this.createSubChart(this.odChartOffset[0], this.odChartOffset[1], this.squareSize, this.squareSize, 'OD');

        this.gResultTable = this.chart
            .selectAll('.resultTable')
            .data([null])
            .join('g')
            .attr('class', 'resultTable');

        this.gGroup = this.chart
            .selectAll('.gGroup')
            .data([null])
            .join('g')
            .attr('class', 'gGroup')
            .attr('transform', `translate(0, 150)`);

        this.tooltipGroup = this.chart
            .append('g')
            .style('opacity', 0)
            .call((x: any) => x.attr('pointer-events', 'none'));

        let tooltipRoundCorner = 10;
        this.tooltipGroup
            .append('rect')
            .attr('id', 'tooltip')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.tooltipWidth)
            .attr('height', this.tooltipHeight)
            .attr('rx', tooltipRoundCorner)
            .attr('ry', tooltipRoundCorner)
            .attr('class', 'layerBg');

        let crossMargin = 25;
        let crossTopMargin = 50 + 50;
        // Vertical central line
        this.tooltipGroup
            .append('line')
            .attr('id', 'tooltipVertical')
            .attr('x1', this.tooltipWidth / 2)
            .attr('y1', crossTopMargin)
            .attr('x2', this.tooltipWidth / 2)
            .attr('y2', this.tooltipHeight - crossMargin)
            .attr('stroke-width', 1)
            .style('stroke', 'none');

        let crossHairCenterX = this.tooltipWidth / 2; //+ deltaTop
        let crossHairCenterY = this.tooltipHeight / 2; //+ deltaTop

        // Horizontal central line
        this.tooltipGroup
            .append('line')
            .attr('id', 'tooltipHorizontal')
            .attr('x1', crossMargin)
            .attr('y1', crossHairCenterY)
            .attr('x2', this.tooltipWidth - crossMargin)
            .attr('y2', crossHairCenterY)
            .attr('stroke-width', 1)
            .style('stroke', 'none');

        // sector
        let sectorLength = this.tooltipWidth - crossMargin * 2 - crossHairCenterX;
        sectorLength *= 0.5;
        let sectorX2 = crossHairCenterX + sectorLength;
        this.tooltipGroup
            .append('line')
            .attr('id', 'sectorLine1')
            .attr('x1', crossHairCenterX)
            .attr('y1', crossHairCenterY)
            .attr('x2', sectorX2)
            .attr('y2', crossHairCenterY)
            .attr('stroke-width', 1)
            .attr('class', 'sWhite')
            .style('transform-origin', `${crossHairCenterX}px ${crossHairCenterY}px`)
            .style('transform', 'rotate(0deg)');

        this.tooltipGroup
            .append('line')
            .attr('id', 'sectorLine2')
            .attr('x1', crossHairCenterX)
            .attr('y1', crossHairCenterY)
            .attr('x2', sectorX2)
            .attr('y2', crossHairCenterY)
            .attr('stroke-width', 1)
            .attr('class', 'sWhite')
            .style('transform-origin', `${crossHairCenterX}px ${crossHairCenterY}px`)
            .style('transform', 'rotate(180deg)');

        this.tooltipGroup
            .append('circle')
            .attr('r', 10)
            .attr('cx', this.tooltipWidth / 2)
            .attr('cy', this.tooltipHeight / 2)
            .style('stroke', 'none')
            .style('fill', this.vsService.color[this.vsService.color.length - 1]);

        let textX = this.tooltipWidth / 2 - 50;
        let textY = 30 + 11;
        this.tooltipGroup
            .append('g')
            .attr('transform', `translate(${textX}, ${textY})`)
            .attr('id', 'srtValue');
        this.tooltipGroup
            .append('g')
            .attr('transform', `translate(${textX - 16}, ${textY - 7})`)
            .attr('id', 'itemStatus');

        this.tooltipGroup
            .append('path')
            .attr('id', 'tooltipLineShadow')
            .attr('class', 'sWhite2')
            .attr('fill', 'none')
            .attr('stroke', 'none')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 0.6); //this.tooltipLineWidth - 0.3)

        this.tooltipGroup
            .append('path')
            .attr('id', 'tooltipLine')
            .attr('class', 'sYellow2')
            .attr('fill', 'none')
            .attr('stroke', 'none')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', this.tooltipLineWidth);

        this.tooltipGroup
            .append('g')
            .attr('id', 'tooltipDots')
            .attr('class', 'fYellow2')
            .attr('stroke', 'none');

        this.tooltipGroup.append('g').attr('id', 'tooltipFocusPoint');

        this.tooltipGroup
            .append('circle')
            .attr('id', 'tooltipFocusPoint-1')
            .attr('fill', 'none');
        this.tooltipGroup
            .append('circle')
            .attr('id', 'tooltipFocusPoint-2')
            .attr('fill', 'none');

        let crossPadding = 15 + 10;
        let crossRadius = 10;
        let crossG = this.tooltipGroup
            .append('g')
            .attr('transform', `translate(${this.tooltipWidth - crossRadius - crossPadding}, ${crossPadding + crossRadius})`)
            .attr('id', 'tooltipCloseCross');

        crossG
            .append('path')
            .attr('class', 'sText')
            .attr('stroke-width', 2)
            .attr('fill', 'none')
            .attr('d', this.drawCrossWithRadius(crossRadius));

        let areaRadius = crossRadius + 10;
        crossG
            .append('rect')
            .style('opacity', 0)
            .attr('x', -areaRadius)
            .attr('y', -areaRadius)
            .attr('width', 2 * areaRadius)
            .attr('height', 2 * areaRadius)
            .on('mousedown', () => {
                this.closeCrossClick();
            })
            .attr('cursor', 'pointer');
        this.gPopup = this.tooltipGroup.append('g').attr('class', 'popup-controls');
        
        this.gPopupControls = this.tooltipGroup.append('foreignObject')
        .attr('x', 170)
        .attr('y', 50)
        .attr('width', 500)
        .attr('height', 100)
        .append('xhtml:div')
        .style('display', 'flex');
        
        this.renderPopupControls(-1, -1, -1);
    }

    setSelected(data: any) {
        this.chart.selectAll('.selected').remove();
        data = this.getSelected(data);
        const arr: string[] = [];
        Object.keys(data).forEach((k: any) => arr.push(k + ': ' + data[k]));

        const selected = this.chart
            .selectAll('.selected')
            .data(d3.range(11))
            .enter()
            .append('g')
            .attr('class', 'selected')
            .attr('transform', (d: any, i: number) => {
                return 'translate(0,' + (16 * i * 2 + this.margin.top * 1.5 + 600) + ')';
            });
        selected
            .append('text')
            .attr('x', (d: any, i: any) => {
                return this.width - 100;
            })
            .attr('y', -5)
            .style('font-size', '16px')
            .style('text-anchor', 'start')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text((d: any, i: number) => {
                return this.translateService.instant(arr[i]) || arr[i];
            });
    }

    getSelected = (arr: any) => {
        if (!arr) return initSelected;
        return {
            Training: arr.training ? 'ON' : 'OFF',
            Onset: (arr.onsetType || '') + ' ' + (arr.onsetTime || 0) + 'ms',
            Flicker: arr.stFlickerStatus ? arr.stFlickerFrequency : 'OFF',
            Background: [arr.bgr || 0, arr.bgg || 0, arr.bgb || 0],
            Stimulus: [arr.str || 0, arr.stg || 0, arr.stb || 0],
            'Additional waiting': arr.onsetAdditionalWaitingStatus ? arr.onsetAdditionalWaitingLength : 'OFF',
            'Green Dot': arr.greenDotAnimationStatus ? 'Concentric' : 'Classic',
            'Animation color': arr.color ? 'ON' : 'OFF',
            'Animation movement': arr.movement ? 'ON' : 'OFF',
            'Animation size': arr.size ? 'ON' : 'OFF',
            'Animation reading': arr.reading ? 'ON' : 'OFF',
        };
    };

    getSeenFromLog(id: number) {
        let edits = this.edits || [];
        let log = edits.filter(x => x.messageTimestamp === id);
        log = _.sortBy(log, x => x.date);
        let entry = _.last(log);
        if (!entry) return null;
        return entry.currentValue;
    }

    getColoringIndexForSrt(srt: number): number {
        let group = this.srtToColorGroup(srt);
        return this.COLOR_GROUPS.indexOf(group) + 2;
    }

    srtToColorGroup(color: number) {
        return this.COLOR_GROUPS.find(x => x[1](color)) as ColorGroup; // always has value...
    }

    renderResultTable() {
        let OD = this.getNumbers(this.entries.OD);
        let OS = this.getNumbers(this.entries.OS);

        let text1 = this.makeText(this.entries.OS);
        let text2 = this.makeText(this.entries.OD);

        let target1 = this.gResultTable
            .selectAll('.a1')
            .data([null])
            .join('g')
            .attr('class', 'a1')
            .call((x: any) => x.attr('pointer-events', 'none'));

        let target2 = this.gResultTable
            .selectAll('.a2')
            .data([null])
            .join('g')
            .attr('class', 'a2')
            .call((x: any) => x.attr('pointer-events', 'none'));

        let y = this.osChartOffset[1] - 75;
        let x1 = this.osChartOffset[0] + 265;
        let x2 = this.odChartOffset[0] + this.squareSize - 265;
        let area1 = new Layout(x1, y, x1, y, '');
        let area2 = new Layout(x2, y, x2, y, '');

        TextBlock({
            text: text1,
            area: area1,
            target: target1,
            styleParams: {
                override: {
                    fontColor: '#DCE2E1',
                    fontWeight: 400,
                    fontSize: 32,
                },
            },
        });
        TextBlock({
            text: text2,
            area: area2,
            target: target2,
            styleParams: {
                override: {
                    fontColor: '#DCE2E1',
                    fontWeight: 400,
                    fontSize: 32,
                },
            },
        });
        this.setTotals(this.entries);
    }

    getNumbers = (items: any) => {
        items = items.filter((x: any) => x.seenValue >= this.MIN_SEEN); // NOTE: excluding unseen/nodata

        let srts = items.map((x: any) => x.srt as number);

        let n = srts.length;
        let sum = _.sumBy(srts, x => +x) || 0;

        let mean = sum / n;
        if (!isFinite(mean)) mean = 0;

        let sorted = _.sortBy(srts);
        let q25 = d3.quantile(sorted, 0.25) || 0;
        let q50 = d3.quantile(sorted, 0.5) || 0;
        let q75 = d3.quantile(sorted, 0.75) || 0;

        let squared = (x: number) => x * x;
        let std = Math.sqrt((1 / n) * _.sumBy(srts, x => squared(+x - mean)));

        let cov = std / mean;
        if (!isFinite(cov)) {
            cov = 0;
        }

        return {
            mean,
            q25,
            q50,
            q75,
            cov,
        };
    };

    makeText = (items: any, y?: number, sel?: boolean) => {
        if (items.length < 4 && y && y < 300 && !sel) return 'N/A';
        if (items.length < 4 && !sel) return '\n\n\n\nN/A';
        let f = (x: number) => x.toFixed(0);
        let fCov = (x: number) => x.toFixed(3);
        let d = this.getNumbers(items);
        return `25%:  ${f(d.q25)}\n50%:  ${f(d.q50)}\nMEAN: ${f(d.mean)}\n75%: ${f(d.q75)}\nCOV: ${fCov(d.cov)}`;
    };

    setTotals(entries: any) {
        this.removeTotals();

        if (entries.OS.length === 0 && entries.OD.length === 0) {
            return;
        }

        const lowLimit = 300; //limit for inconclusive
        const res = {
            OD: new Array(8).fill(0),
            OS: new Array(8).fill(0),
        };
        const inconclusive = {
            OD: new Array(4).fill(0),
            OS: new Array(4).fill(0),
        };
        const unseen = {
            OD: [0],
            OS: [0],
        };

        const val = this.COLOR_GROUPS.map(k => {
            let num = k[0]
                .replace('<', '')
                .replace('>', '')
                .trim();
            if (num.split('-')[1]) {
                return parseInt(num.split('-')[1]);
            } else {
                return parseInt(num);
            }
        });
        const lowVal = [20, 30, 40, lowLimit];

        const getIndex = (arr: any, one: any) => {
            for (let j = 0; j < arr.length; j++) {
                if (one < arr[j]) {
                    return j;
                }
            }
        };

        Object.keys(entries).forEach(k => {
            let one = entries[k];
            for (let i = 0; i < one.length; i++) {
                if (one[i].seenValue === 1) {
                    unseen[k][0] += 1;
                }
                if (one[i].seenValue === 2) {
                    let ind2 = getIndex(lowVal, one[i].tempFrame.maxdistance);
                    inconclusive[k][ind2] += 1;
                }
                if (one[i].seenValue === 3) {
                    let ind = getIndex(val, one[i].srt);
                    if (ind === undefined) ind = res[k].length - 1;
                    res[k][ind] += 1;
                }
            }
        });

        const len = {
            OD: entries.OD.length,
            OS: entries.OS.length,
        };
        const getPer = (result: any, t: any) => {
            const tot = result[t].reduce((acc: any, k: any) => acc + k, 0);
            if (!len[t]) {
                return 0;
            }
            return (tot * 100) / len[t];
        };

        this.addTotalHead('totalHead', this.width - 110, 145);
        this.addList(res, 'total', 70, -280);
        this.addList(unseen, 'unseen', 70, 25);
        this.addList(inconclusive, 'inconclusive', 70, 100);

        const tot = [
            {
                OD: getPer(res, 'OD'),
                OS: getPer(res, 'OS'),
                x: 100,
                y: -25,
            },
            {
                OD: getPer(unseen, 'OD'),
                OS: getPer(unseen, 'OS'),
                x: 100,
                y: 55,
            },
            {
                OD: getPer(inconclusive, 'OD'),
                OS: getPer(inconclusive, 'OS'),
                x: 100,
                y: 260,
            },
        ];

        if (tot[0].OS > 0) tot[0].OS = 100 - tot[1].OS - tot[2].OS;
        if (tot[0].OD > 0) tot[0].OD = 100 - tot[1].OD - tot[2].OD;

        tot.forEach((k, i) => {
            this.chart
                .selectAll('.tot' + i)
                .data(d3.range(1))
                .enter()
                .append('g')
                .attr('class', 'tot' + i)
                .attr('transform', (d: any, i: number) => {
                    let dy = 16 * i * 2 + this.margin.top * 1.5;
                    dy += 300;
                    return 'translate(0,' + dy + ')';
                })
                .append('text')
                .attr('y', k.y)
                .attr('x', this.width - k.x - 100)
                .style('font-size', '16px')
                .style('text-decoration', 'overline')
                .style('text-anchor', 'start')
                .call((t: any) => t.style('font-family', 'DM Sans'))
                .text(`${k.OS.toFixed(2)}%  --  ${k.OD.toFixed(2)}%`)
                .style('gap', '50px');
        });
    }

    addList(res: any, className: any, x = 70, y: any) {
        const list = this.chart
            .selectAll('.' + className)
            .data(d3.range(res.OD.length))
            .enter()
            .append('g')
            .attr('class', className)
            .attr('transform', (d: any, i: number) => {
                let dy = 16 * i * 2 + this.margin.top * 1.5;
                dy += 300;
                return 'translate(0,' + dy + ')';
            });

        const resList = d3.range(res.OD.length).map(d => ({
            od: res['OD'][d], os: res['OS'][d]
        }));

        [1].forEach((k, i, arr) => {
            list.append('text')
                .attr('y', y)
                .attr('x', this.width - x - 110)
                .style('font-size', '16px')
                .style('text-anchor', 'start')
                .style('word-spacing', (i: number) => {
                    return resList[i]['od'] > 9 ? '50px' : resList[i]['od'] === 1 ? '65px' : '60px'
                })
                .call((t: any) => t.style('font-family', 'DM Sans'))
                .text((d: any, i: number) => {
                    return `${resList[i]['od']} ${resList[i]['os']}`
                });
        });
    }

    addTotalHead(className: any, x: any, y: any) {
        this.chart
            .selectAll('.' + className)
            .data(d3.range(1))
            .enter()
            .append('g')
            .append('text')
            .attr('class', className)
            .attr('x', x - 70)
            .attr('y', y)
            .style('font-size', '18px')
            .style('font-weight', 'bold')
            .style('text-anchor', 'start')
            .style('word-spacing', '45px')
            .call((t: any) => t.style('font-family', 'DM Sans'))
            .text('OS                   OD');
    }

    removeTotals() {
        const cl = ['totalHead', 'total', 'unseen', 'inconclusiveHead', 'inconclusive', 'tot0', 'tot1', 'tot2'];
        for (let i = 0; i < cl.length; i++) this.chart.selectAll('.' + cl[i]).remove();
    }

    render() {
        this.renderLayers();
        this.renderGroups();
        this.renderSelections();
        this.drawLasso();
    }

    renderLayers() {
        let group = this.editedGroup;
        let showOD = group && group.eye === 'OD';
        let showOS = group && group.eye === 'OS';
        [
            { title: 'OD', g: this.nextGOD, gBelow: this.gPrevOD, show: showOD },
            { title: 'OS', g: this.nextGOS, gBelow: this.gPrevOS, show: showOS },
        ].forEach(x => {
            let { g, gBelow, title, show } = x;
            let klass = `rect-layer-${title}`;

            g.selectAll(`.${klass}`)
                .data([null])
                .join('rect')
                .attr('class', klass)
                .attr('width', this.squareSize)
                .attr('height', this.squareSize)
                .attr('fill', 'transparent')
                .attr('display', show ? undefined : 'none')
                .on('mousemove', (x: any) => this.rectLayerMouseMove(title))
                .on('mousedown', (x: any) => this.rectLayerMouseDown(title))
                .on('mouseup', (x: any) => this.rectLayerMouseUp(title));

            if (title === 'OS') {
                if (!this.gLassoOS) {
                    this.gLassoOS = gBelow;
                }
                if (!this.gSelectionOS) {
                    this.gSelectionOS = gBelow;
                }
            } else {
                if (!this.gLassoOD) {
                    this.gLassoOD = gBelow;
                }
                if (!this.gSelectionOD) {
                    this.gSelectionOD = gBelow;
                }
            }
        });
    }

    renderGroups() {
        let target = this.gGroup;
        let points = { OD: [] as any[], OS: [] as any[] };

        this.groups.forEach(group => {
            let klass = `id-${group.id}`;
            let { x, y } = group.position;
            let area = new Layout(x, y, x, y, '');
            let params = {
                alignLeft: true,
                alignBottom: true,
            };
            let text = 'select';
            if (group.editing) {
                text = `* drag mouse over area you want to select *`;
            }
            let { eye, id, selection } = group;

            if (selection && !group.editing && selection.points.length > 0) {
                let detailsText = this.getDetails(eye, selection.points, y);
                text = `${detailsText}`;
            }
            if (selection) {
                points[eye] = [...points[eye], ...selection.points];
            }

            let g = target
                .selectAll(`.${klass}`)
                .data([null])
                .join('g')
                .attr('class', klass);

            TextBlock({
                text,
                area,
                target: g,
                onClick: () => {
                    this.changeGroupEditing(id);
                    this.refresh();
                },
                styleParams: {
                    override: {
                        fontColor: group.color,
                        ...this.CORNER_TEXT,
                    },
                },
                ...params,
                ...(group.other || {}),
            });
            target.call((x: Target) =>
                x.attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            );
        });
    }

    renderSelections() {
        let xs = this.groups.filter(x => x.selection);

        let xsOD = xs.filter(x => x.eye === 'OD');
        let xsOS = xs.filter(x => x.eye === 'OS');

        let l = (x: any) => x.selection.lasso;
        let draw = (target: any, xs: any) => {
            target
                .selectAll('.selection')
                .data(xs)
                .join('rect')
                .attr('class', 'selection')
                .attr('fill', (d: any) =>
                    chroma(d.color)
                        .alpha(0.5)
                        .toString()
                )
                .attr('stroke', (d: any) => d.color)
                .attr('stroke-width', 3)
                .attr('stroke-dasharray', '3 10')
                .attr('x', (d: any) => Math.min(l(d).xFrom, l(d).xTo))
                .attr('y', (d: any) => Math.min(l(d).yFrom, l(d).yTo))
                .attr('width', (d: any) => Math.abs(l(d).xTo - l(d).xFrom))
                .attr('height', (d: any) => Math.abs(l(d).yTo - l(d).yFrom))
                .call((x: any) => x.attr('pointer-events', 'none'));
        };
        draw(this.gSelectionOS, xsOS);
        draw(this.gSelectionOD, xsOD);
    }

    drawLasso() {
        let group = this.editedGroup as Group;
        let lasso = group && group.lasso;
        let isOD = group && group.eye === 'OD';
        let isOS = group && group.eye === 'OS';

        let ODxs = isOD ? [lasso] : [];
        let OSxs = isOS ? [lasso] : [];

        ODxs = _.compact(ODxs);
        OSxs = _.compact(OSxs);

        let draw = (target: any, xs: any) => {
            target
                .selectAll('.lasso')
                .data(xs)
                .join('rect')
                .attr('class', 'lasso')
                .attr('fill', 'transparent')
                .attr('stroke', group && group.color)
                .attr('stroke-width', 3)
                .attr('stroke-dasharray', '3 10')
                .attr('x', (d: any) => Math.min(d.xFrom, d.xTo))
                .attr('y', (d: any) => Math.min(d.yFrom, d.yTo))
                .attr('width', (d: any) => Math.abs(d.xTo - d.xFrom))
                .attr('height', (d: any) => Math.abs(d.yTo - d.yFrom))
                .call((x: any) => x.attr('pointer-events', 'none'));
        };

        draw(this.gLassoOS, OSxs);
        draw(this.gLassoOD, ODxs);
    }

    getDetails(eye: string, points?: any, y?: number) {
        if (!points) points = this.entries[eye];
        return this.makeText(points, y, true);
    }

    rectLayerMouseMove(eye: string) {
        let x = d3.mouse(d3.event.currentTarget)[0];
        let y = d3.mouse(d3.event.currentTarget)[1];

        this.updateLasso(x, y);
        this.drawLasso();
    }
    rectLayerMouseUp(eye: string) {
        let lasso = this.lasso as Lasso;
        if (!lasso) return;

        let points = this.getSeen(eye);
        let pointsCoords = points.map((d: any) => ({ ...d, xcoord: this.pointXScale(d.xCoor), ycoord: this.pointYScale(d.yCoor) }));

        let selectedPoints = pointsCoords.filter((x: any) => this.within(x, lasso));

        this.selectPoints(selectedPoints);
        this.stopLasso();
        this.stopEditing();
        this.refresh();
    }

    rectLayerMouseDown(eye: string) {
        let x = d3.mouse(d3.event.currentTarget)[0];
        let y = d3.mouse(d3.event.currentTarget)[1];
        this.startLasso(x, y);
        this.refresh();
    }

    within(point: { xcoord: number; ycoord: number }, lasso: { xFrom: number; yFrom: number; xTo: number; yTo: number }) {
        let x1 = Math.min(lasso.xFrom, lasso.xTo);
        let x2 = Math.max(lasso.xFrom, lasso.xTo);

        let y1 = Math.min(lasso.yFrom, lasso.yTo);
        let y2 = Math.max(lasso.yFrom, lasso.yTo);

        x1 -= this.R;
        y1 -= this.R;

        x2 += this.R;
        y2 += this.R;

        let x = point.xcoord;
        let y = point.ycoord;

        let xMatch = x > x1 && x < x2;
        let yMatch = y > y1 && y < y2;
        return xMatch && yMatch;
    }

    getSeen(eye: string, f?: any, points?: any) {
        if (!f) f = (x: any) => x;

        let items = points || this.entries[eye];
        items = items.filter((x: any) => x.seenValue === 3);
        return items.map(f);
    }

    popupControls(p: PCParams) {
        let id = 0;

        let statusSeen = p.status > 1;
        let statusUnseen = p.status === 0;
        let statusNoData = p.status === 1;

        let originalStatusSeen = p.originalStatus > 1;
        let originalStatusUnseen = p.originalStatus === 0;
        let originalStatusNoData = p.originalStatus === 1;

        let dy = 40 + 10;
        let dx = 10;

        let SeenText = `Seen*`;
        let UnseenText = `Unseen`;
        let NoDataText = `No data`;

        let optionsWrapper = p.target.append('div').style('position', 'absolute').style('display', 'flex');

        let fieldRefs: any[] = [];

        for(let i = 0; i < 3; i++) {
            let labelField = optionsWrapper.append('xhtml:div').style('width', '100px').style('margin-right', '15px').on('click', (e: any, d: any) => {
                let radioOption = labelField.select('input').property('value');

                fieldRefs.filter(fieldRef => fieldRef !== labelField).forEach(fieldRef => {
                    fieldRef.select('input').property('checked', false);
                });

                return radioOption === "Seen*" ? this.setFormStatus(InputStatus.SEEN - 1) : 
                radioOption === "Unseen" ? this.setFormStatus(InputStatus.UNSEEN - 1) : 
                this.setFormStatus(InputStatus.NODATA - 1);
            })
            .append('xhtml:label').attr('class', 'radiobutton type1').style('left', 0);

            labelField.append('xhtml:input').attr('type', 'radio').property('value', i === 0 ? SeenText : i === 1 ? UnseenText : NoDataText)
            .property('checked', i === 0 ? statusSeen : i === 1 ? statusUnseen : statusNoData);
            labelField.append('xhtml:span').style('margin-right', '10px');
            labelField.append('xhtml:a').text(i === 0 ? SeenText : i === 1 ? UnseenText : NoDataText);
            fieldRefs.push(labelField);
        }
    }

    popupSave(p: PSParams) {
        // let { right, bottom } = p.rect
        // let left = right - BUTTON_WIDTH
        // let top = bottom - BUTTON_HEIGHT

        let deltax = p.clean ? 70 : 0;

        // let left = 535
        let left = 510;
        let top = 600 - 6;
        let right = left;
        let bottom = top;

        let area = new Layout(left, top, right, bottom, '');

        let id = 0;

        let text = 'Save';

        this.booleanControl({
            text,
            checked: false,
            target: this.getPopupTarget(++id, p.target, 'popup-save'),
            area,
            onClick: p.onClick,
        });
    }

    popupLog(p: PLParams) {
        let id = 0;
        let items = p.items;

        let left = 30 - 5;
        let top = 625;
        let right = left;
        let bottom = top;

        let area = new Layout(left, top, right, bottom, '');

        TextBlock({
            text: items.join('\n'),
            target: this.getPopupTarget(++id, p.target, 'popup-log'),
            alignBottom: true,
            alignLeft: true,
            area,
            styleParams: {
                override: {
                    fontSize: 12,
                },
            },
        });
    }

    oldHideTooltip() {
        this.tooltipGroup.style('opacity', 0);

        this.tooltipGroup.select('#tooltipVertical').style('stroke', 'none');
        this.tooltipGroup.select('#tooltipHorizontal').style('stroke', 'none');

        this.tooltipGroup
            .select('#tooltipLine')
            .attr('fill', 'none')
            .attr('stroke', 'none');

        this.tooltipGroup.select('#tooltipFocusPoint').attr('fill', 'none');
        this.tooltipGroup.select('#tooltipFocusPoint-1').attr('fill', 'none');
        this.tooltipGroup.select('#tooltipFocusPoint-2').attr('fill', 'none');
    }

    handleOnTargetMouseLeave() {
        // no need to remove
        if (this.isFixedTooltip) {
            return;
        }
        this.hideTooltip();
        this.oldHideTooltip();
    }

    setPopupText(text: string) {
        TextBlock({
            text,
            alignLeft: true,
            alignTop: true,
            target: this.tooltipGroup.select('#srtValue'),
            area: new Layout(0, -14, 0, 0, ''),
        });
    }

    setFormStatus(given: InputStatus) {
        this.formStatus = given;
        this.refrceshPopup();
    }

    refrceshPopup() {
        this.popupShowStatus(...this.prevPopupUpdParams);
    }

    changePoint(id: number, inputStatus: number) {
        let got = this.vsService.rawInputData.filter((x: any) => {
            return x.timestamp === id;
        });
        if (got.length > 1) {
            console.error('found too many points');
        }
        let point = got[0];
        if (!point) {
            console.error('very unexpected');
        }
        let change = {
            timestamp: id,
            userSeen: inputStatus,
        };

        let oldValue = point?.userSeen || point?.seen;

        Object.assign(point, change);
        this.params.emitChange(change);

        this.recordEdit(id, oldValue, inputStatus).then(() => {
            this.rebuildFromRecorded();
        });
    }

    recordEdit(id: number, from: number, to: number) {
        let record = {
            messageTimestamp: id,
            fieldName: 'userSeen',
            previousValue: from,
            currentValue: to,
        };

        return this.sAddEdit(record)
            .then((edits: IChartEdit[]) => {
                this.edits = edits;
            })
            .catch(() => {
                console.error('addEdit returned an error');
            });
    }

    sAddEdit(edit: MyEdit): Promise<IChartEdit[]> {
        return this.params.addEdit(edit);
    }

    rebuildFromRecorded() {
        let data = this.vsService.rawInputData;

        let edits = this.edits;
        // let settings = this.settings;
        this.clearState();
        // this.settings = settings;
        this.edits = edits;

        this.removeUI();
        this.initStuff();
        this.addData(data);
    }

    clearState() {
        this.vsService.chartData$.next(null);
        this.entries = this.noEntries;
        this.vsService.rawInputData = [];
        this.params.edits = [];
        this.closeCrossClick();
        d3.selectAll('.them-rects').remove();
        d3.selectAll('.them-icons').remove();
        let groups = this.groups;
        // this.settings = new Settings()
        this.selected = new Set<number>();
        this.resetSettings();
        this.selected.add(this.optionShowSRT.id);

        this.tooltipStatus = ShowState.HIDDEN;
        this.groups = groups;
        this.resetGroups();
        this.refresh();
    }

    closeCrossClick() {
        this.hideTooltip();
        this.oldHideTooltip();
    }

    removeUI() {
        this.chart = d3
            .select(this.params.svg)
            .selectAll('*')
            .remove();
    }

    shift(array: any[], n: number, reversed = true) {
        return reversed ? [...array.slice(n), ...array.slice(0, n)] : [...array.slice(array.length - n), ...array.slice(0, array.length - n)];
    }

    getPopupTarget(id: number, target: any, name: string = '') {
        return target
            .selectAll(name ? `.g-${name}-${++id}` : `.g-${id}`)
            .data([null])
            .join('g')
            .attr('class', name ? `.g-${name}-${++id}` : `.g-${id}`);
    }

    booleanControl(p: BooleanParams) {
        // let style = { ...styleDefault, ...style }
        let styleParams = { theme: '' };
        let color = p.color || '';

        this.option({
            items: [{}],

            isChecked: () => p.checked,
            onClick: p.onClick,
            coloring: () => color,
            naming: () => p.text,

            noCheckBox: p.noCheckBox,

            newType: true,
            radio: p.radio,

            target: p.target,
            area: p.area,
            styleParams,
            fontSize: p.fontSize,
        });
    }

    option(given: any) {
        let defaultProps = {
            tickSize: 0.1,
            noCheckBox: false,
            radio: false,
        };
        let p = { ...defaultProps, ...given };
        let fontSize = p.fontSize || 24;

        type IndexedD = {
            index: number;
        };
        type FullD = IndexedD & {
            position: RawPoint;
            color: string;
            text: string;
            isChecked: boolean;
        };

        let width = p.area.width();
        let height = 50;

        // let positioningX = (d: IndexedD) => 0
        // let positioningY = (d: IndexedD) => d.index * height
        let positioning = (d: IndexedD) => ({
            x: 0,
            y: d.index * height,
        });

        let items: FullD[] = p.items.map((x: any, i: any) => {
            let withIndex = {
                ...x,
                index: i,
            };
            return {
                ...withIndex,
                position: positioning(withIndex),
                color: p.coloring(x),
                text: p.naming(x),
                isChecked: p.isChecked(x),
            };
        });

        let target = p.target
            .selectAll('.option')
            .data(items)
            .join('g')
            .attr('class', 'option')
            .attr('cursor', (d: { text: any }) => (d.text ? 'pointer' : 'default'))
            .call(this.doTranslate(p.area.cornerTL()))
            .on('mousedown', (d: any) => p.onClick(d))
            .call((x: Target) =>
                x.attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;')
            );

        target
            .selectAll('rect')
            .data((d: any) => [d])
            .join('rect')
            .attr('x', (d: { position: { x: any } }) => d.position.x)
            .attr('y', (d: { position: { y: any } }) => d.position.y)
            .attr('width', width)
            .attr('height', height)

            // .attr('stroke', 'orange')
            // .attr('fill', 'yellow')
            .attr('fill', 'transparent')
            .attr('stroke-opacity', 'transparent');

        let tickScaleNumber = 30; // this.params.tickScale || 30;
        let tickScale = `scale(${tickScaleNumber})`;

        let move = (a: { x: number; y: number }, b: { x: number; y: number }) => ({ x: a.x + b.x, y: a.y + b.y });

        let tickDelta = {
            x: height / 2,
            y: height / 2,
        };
        let textDelta = {
            x: (height / 2) * 2,
            y: height / 2,
        };

        let colorClass = (gotColor: string) => {
            let klass = 'sText';
            if (gotColor) klass = '';
            return klass;
        };

        if (p.newType) {
            let tickDelta = {
                x: 20,
                y: 13,
            };

            let checkedness = (d: any) => {
                let checked = p.isChecked(d);
                return checked ? 'on' : 'off';
            };

            let icon = p.radio ? 'radio' : 'tick';

            target
                .selectAll('.checkbox')
                .data((d: any) => (p.noCheckBox ? [] : [d]))
                .attr('class', (d: any) => `checkbox ${colorClass(p.coloring(d))}`)
                .attr('transform', (d: any) => this.translate(move(d.position, tickDelta)))
                .attr('stroke', 'gray')
                .attr('fill', 'gray');
        } else {
            target
                .selectAll('.checkbox')
                .data((d: any) => _.compact([p.isChecked(d) ? d : null]))
                .join('path')
                .attr('class', (d: any) => `checkbox ${colorClass(p.coloring(d))}`)
                .attr('d', this.drawTick)
                .attr('fill', 'none')
                .attr('stroke', p.coloring) //, maxId), //'orange')//this.params.tickColor ?? this.style.optionTick.color)
                .attr('stroke-width', p.tickSize) //this.style.optionTick.size)
                .attr('transform', (d: { position: { x: number; y: number } }) => this.translate(move(d.position, tickDelta)) + ' ' + tickScale);
        }

        target
            .selectAll('text')
            .data((d: any) => [d])
            .join('text')
            .text((d: { text: any }) => d.text)
            // .style('fill', '#fff') // this.style.comboText.color)
            .style('font-size', fontSize) /// this.style.comboText.size)
            .attr('alignment-baseline', 'middle')
            .attr('transform', (d: { position: { x: number; y: number } }) => this.translate(move(d.position, textDelta)))
            .attr('class', 'diagram-link-text');
        // .call(position);
    }

    drawCrossWithRadius(r: number) {
        return `M-${r},-${r} L${r},${r} M-${r},${r} L${r},-${r}`;
    }
}
