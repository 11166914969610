// @ts-nocheck
import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target, Layout } from '..';
import { getStyle } from './util';
// import { Scale2D } from '../types'
import { translate } from '../util';

// styling

const styleDefault = {
    // dotRadius: 3,
    // lineSize: 3,
    // fillColor: 'rgba(0,0,0,0)',
    // dasharray: undefined as string|undefined,
    fontSize: 16,
    // fontSize: 24,
    fontWeight: 400 as number | string,
    fontColor: undefined as any, //string|undefined,
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {
    // bright: {
    // },
};

// type Shape = 'dot' | 'square' | 'hline'

interface Bg {
    wr?: number; // width radius...
    hr?: number;
}

interface UIText {
    text: string;
    chosen?: boolean;
    bg?: Bg;
    render?: (t: Target) => void;
    onClick?: () => void;
    onEnter?: () => void;
    onExit?: () => void;
    class?: string;

    image?: string;
    width?: number;
    height?: number;
}

type Row = string | UIText;

interface VColumn {
    at: number;
    rows: Row[];
}

interface Params {
    // gDataPoints: Target
    // makeStructure: (a: string, b: string, c: Target) => ({ gSeries: Target, gDataPoints: Target })
    // scale: Scale2D
    domainX: any;

    rowHeaders: Row[];
    columns: VColumn[];

    alignLeft?: boolean;

    // onEnter?: (d: any) => void
    // onExit?: () => void

    // filter?: (d: any) => boolean

    // shape: (t: Target) => void
    // shape?: Shape

    target: Target;
    area: Layout;
    styleParams?: {
        theme?: string;
        override?: Partial<Style>;
    };
}

let defaultProps = {
    showRects: false,
    addKlass: '',
    rx: 50, // width/2
    ry: 20, // height/2
    r: 0, // rect corner radius
};

// function

// vertical table - like if you could use vertical lines on a chart with description as like a first column
//
export function VTable(given: Params & Partial<typeof defaultProps>) {
    let p = { ...defaultProps, ...given };
    // export function VTable(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

    let domainY = [0, 1];

    let scaleX = d3
        .scaleLinear()
        .domain(p.domainX)
        .range(p.area.rangeX());
    let scaleY = d3
        .scaleLinear()
        .domain(domainY)
        .range(p.area.rangeY());

    // let yScale = d3.scaleLinear().domain(domainY)
    let headersData = p.rowHeaders.map((given: any, i) => {
        // let onClick: any = undefined
        let text: any = undefined;
        // let klass: any = undefined
        if (typeof given === 'string') {
            text = given;
        } else {
            // text = given.text
            // onClick = given.onClick
            // klass = given.class
            return { ...given, i };
        }
        return { text, i }; //onClick, class: klass }
    });

    // let xScale = d3.scaleLinear().domain(domainX)
    // let yScale = d3.scaleLinear().domain(domainY)
    // let scale = {
    //   x: p.zeroAtTheRight ? xScale.range(p.area.rangeXSwap()) : xScale.range(p.area.rangeX()),
    //   y: p.zeroAtTheTop ? yScale.range(p.area.rangeY()) : yScale.range(p.area.rangeYSwap()),
    // };

    let yStep = 1 / headersData.length; // + 1)
    let full = scaleY(1) - scaleY(0);

    let headers = p.target
        .selectAll('.header')
        .data(headersData)
        .join('g')
        .attr('class', 'header')
        .attr('transform', (d) => translate({ x: scaleX(0), y: scaleY(0) }));
    // .attr('transform', d => translate({ x: scaleX(0), y: p.scale.y.range()[0] }))
    // .attr('transform', d => translate({ x: p.scale.x(d.point.x), y: p.scale.y.range()[0] }))
    // .attr('transform', d => translate({ x: p.scale.x(d.point.x), y: p.scale.y.range()[0] }))

    headers
        .selectAll('.render')
        .data((d: any) => [d])
        .join('g')
        .attr('transform', (d) => {
            let part = d.i * yStep;
            let omg = full * part;
            let result = `translate(0, ${omg})`;
            return result;
        })
        .attr('class', 'render')
        .each(function(d, n, gs) {
            let items = d.render ? [d] : [];
            d3.select(this)
                .selectAll('.render2')
                .data(items)
                .join('g')
                .attr('class', 'render2')
                .each(function(d, n, gs) {
                    d.render(d3.select(this));
                });
        });

    let getClass = (d: any) => {
        let other = d.onClick || d.onEnter ? 'diagram-link-text' : '';
        return `${d.class} ${other}`;
    };

    headers
        .selectAll('text')
        .data((d: any) => [d])
        .join('text')
        .text((d: any) => d.text)
        .attr('y', (d: any) => {
            let part = d.i * yStep;
            let omg = full * part; //scaleY(d.i * yStep)
            return omg;
        })
        // .style('fill', style.fontColor)
        // .style('font-family', style.fontFamily)
        // .attr('text-anchor', 'end') //() => p.alignLeft ? 'start' : 'middle')
        .attr('text-anchor', 'start') //() => p.alignLeft ? 'start' : 'middle')
        .attr('dominant-baseline', 'hanging') //() => p.alignTop ? 'hanging' : 'middle')
        .style('font-size', style.fontSize)
        .style('font-weight', style.fontWeight)
        .attr('class', (d: any) => getClass(d))
        .on('mousedown', (d: any) => d.onClick && d.onClick())
        .on('mouseenter', (d: any) => d.onEnter && d.onEnter())
        .on('mouseleave', (d: any) => d.onExit && d.onExit())
        .attr('cursor', (d: any) => (d.onClick ? 'pointer' : 'default'));

    // console.log(p.columns)
    let columns = p.target
        .selectAll('.column')
        .data(p.columns)
        .join('g')
        .attr('class', 'column')
        .attr('transform', (d) => translate({ x: scaleX(d.at), y: scaleY(0) }));

    let getImagesData = (d: any) => d.rows.filter((x: any) => x && typeof x.image !== 'undefined');
    // function prepareImageData(xs: any[]) {
    //   return xs.map((x,i) =>
    //     ({ ...x, i })
    //   )
    // }
    columns
        .selectAll('image')
        .data(getImagesData)
        .join('image')
        .attr('href', (d: any) => d.image) //'/butterfly.png')
        .attr('width', (d: any) => d.width || 50)
        .attr('height', (d: any) => d.height || 50)
        .attr('x', (d: any) => d.x ?? -25)
        .attr('y', (d: any) => d.y || 0)

        .on('mousedown', (d: any) => d.onClick && d.onClick())
        .on('mouseenter', (d: any) => d.onEnter && d.onEnter())
        .on('mouseleave', (d: any) => d.onExit && d.onExit())
        .attr('cursor', (d: any) => (d.onClick ? 'pointer' : 'default'))
        .attr('class', (d: any) => (d.onClick ? 'diagram-link-image' : ''));

    // .attr('y', (d:any) => {
    //   let part = d.i * yStep
    //   let omg =  full * part //scaleY(d.i * yStep)
    //   return omg
    // })
    // .data((d:any) => d.rows.map((given:any,i:any) => {
    //   let onClick: any = undefined
    //   let text: any = undefined
    //   let klass: any = undefined
    //   if (typeof given === 'string') {
    //     text = given
    //   } else {
    //     // text = given.text
    //     // onClick = given.onClick
    //     // klass = given.class
    //     return { ...given, i }
    //   }
    //   return { text, i, onClick, class: klass }
    // }))
    // .join('image')

    let rows = columns
        .selectAll('.row')
        .data((d: any) =>
            d.rows
                .map((given: any, i: any) => {
                    // let onClick: any = undefined
                    let text: any = undefined;
                    // let klass: any = undefined
                    if (typeof given === 'string') {
                        text = given;
                    } else {
                        // text = given.text
                        // onClick = given.onClick
                        // klass = given.class
                        return { ...given, i };
                    }
                    return { text, i }; //, onClick, class: klass }
                })
                .filter((x: any) => typeof x.text !== 'undefined')
        )
        .join('g')
        .attr('class', (d: any) => `row ${d.class || ''} ${p.addKlass}`);

    if (p.showRects) {
        let wr = (d: UIText) => (d.bg && d.bg.wr ? d.bg.wr : p.rx);
        let hr = (d: UIText) => (d.bg && d.bg.hr ? d.bg.hr : p.ry);
        let getKlass = (d: any) => {
            if (d.bg) return 'sContrast fLayer';
            if (d.chosen) return 'sContrast';
            return '';
        };
        rows.selectAll('rect')
            .data((d) => [d])
            .join('rect')
            .attr('stroke-width', 1)
            .attr('x', (d: any) => -wr(d))
            .attr('width', (d: any) => 2 * wr(d))
            .attr('y', (d: any) => -hr(d) / 2) // hanging
            .attr('height', (d: any) => 2 * hr(d))
            .attr('rx', p.r)
            .attr('fill', 'transparent')
            // .attr('class', 'sContrast')
            // .style('display', (d:any) => d.chosen ? 'inline' : 'none')
            .attr('class', getKlass)

            .on('mousedown', (d: any) => d.onClick && d.onClick())
            .on('mouseenter', (d: any) => d.onEnter && d.onEnter())
            .on('mouseleave', (d: any) => d.onExit && d.onExit())
            .attr('cursor', (d: any) => (d.onClick ? 'pointer' : 'default'));
    }

    rows.selectAll('.render')
        .data((d: any) => [d])
        .join('g')
        .attr('transform', (d) => {
            let part = d.i * yStep;
            let omg = full * part;
            let result = `translate(0, ${omg})`;
            return result;
        })
        .attr('class', 'render')
        .each(function(d, n, gs) {
            let items = d.render ? [d] : [];
            d3.select(this)
                .selectAll('.render2')
                .data(items)
                .join('g')
                .attr('class', 'render2')
                .each(function(d, n, gs) {
                    d.render(d3.select(this));
                });
        });

    rows.selectAll('text')
        .data((d) => [d])
        .join('text')
        .text((d: any) => d.text)
        .attr('y', (d: any) => {
            let part = d.i * yStep;
            let omg = full * part; //scaleY(d.i * yStep)
            return omg;
        })
        .attr('class', (d: any) => getClass(d))
        .style('fill', style.fontColor)
        // .style('font-family', style.fontFamily)
        // .attr('text-anchor', 'end') //() => p.alignLeft ? 'start' : 'middle')
        // .attr('text-anchor', 'start') //() => p.alignLeft ? 'start' : 'middle')
        .attr('text-anchor', p.alignLeft ? 'start' : 'middle')
        .attr('dominant-baseline', 'hanging') //() => p.alignTop ? 'hanging' : 'middle')
        .style('font-size', style.fontSize)
        .style('font-weight', style.fontWeight)
        .on('mousedown', (d: any) => d.onClick && d.onClick())
        .on('mouseenter', (d: any) => d.onEnter && d.onEnter())
        .on('mouseleave', (d: any) => d.onExit && d.onExit())
        .attr('cursor', (d: any) => (d.onClick ? 'pointer' : 'default'));

    // .attr(')
    // .attr('display', (d:any) => d.chosen ? 'none' : 'inline')

    // let columns = p.target
    //   .selectAll('.columns')
    //   .data((d) => _.compact([filter(d) ? d : null]))
    //   .join('g')
    //   .attr('class', 'texts')
    //   .attr('transform', d => translate({ x: p.scale.x(d.point.x), y: p.scale.y.range()[0] }))
    // //     // (d) => p.scale.x(d.point.x))
    // //     // .attr('y', (d) => p.scale.y.range()[0])

    //   texts
    //     .selectAll('text')
    //     .data((d:any) => [d])
    //     .join('text')
    //     .attr('x', (d) => p.scale.x(d.point.x))
    //     .attr('y', (d) => p.scale.y.range()[0])
    //     // .style('transform', 'rotate(-90deg)')
    //     .text((d:any) => d.point.text)

    // headers
    //   .selectAll('.row')
    //   .data(d => { d])

    // let filter = p.filter || (() => true)

    // let { gDataPoints } = p.makeStructure('vtable-series', 'vtable-points', p.target)

    // let filling: any = style.fillColor === 'fill' ? (d: any) => d.color : style.fillColor
    // let points
    // let texts: any

    // let shape = p.shape || 'dot'

    // if (shape === 'dot') {
    //   points = gDataPoints
    //     .selectAll('.dots')
    //     .data((d) => _.compact([filter(d) ? d : null]))
    //     .join('circle')
    //     .attr('class', 'dots')

    //     .attr('cx', (d) => p.scale.x(d.point.x))
    //     .attr('cy', (d) => p.scale.y(d.point.y))

    //     .attr('r', style.dotRadius)
    //     .attr('stroke-width', style.lineSize)
    //     .attr('fill', filling)
    //     .attr('stroke', (d) => d.color);
    // }
    // if (shape === 'square') {
    //   points = gDataPoints
    //     .selectAll('.dots')
    //     .data((d) => _.compact([filter(d) ? d : null]))
    //     .join('rect')
    //     .attr('class', 'dots')

    //     .attr('x', (d) => p.scale.x(d.point.x) - style.dotRadius)
    //     .attr('y', (d) => p.scale.y(d.point.y) - style.dotRadius)

    //     .attr('height', style.dotRadius * 2)
    //     .attr('width', style.dotRadius * 2)

    //     .attr('stroke-width', style.lineSize)
    //     .attr('fill', filling)
    //     .attr('stroke', (d) => d.color);
    // }
    // if (shape === 'hline') {
    //   points = gDataPoints
    //     .selectAll('.dots')
    //     .data((d) => _.compact([filter(d) ? d : null]))
    //     .join('line')
    //     .attr('class', 'dots')

    //     .attr('x1', (d) => p.scale.x(d.point.x))
    //     .attr('x2', (d) => p.scale.x(d.point.x))

    //     .attr('y1', (d) => p.scale.y.range()[0])
    //     .attr('y2', (d) => p.scale.y.range()[1])

    //     // .attr('height', style.dotRadius * 2)
    //     // .attr('width', style.dotRadius * 2)

    //     .attr('stroke-width', style.lineSize)
    //     .attr('fill', filling)
    //     .attr('stroke', (d) => d.color)
    //     .attr('stroke-dasharray', style.dasharray || '')

    //   // let { gDataPoints: gTexts } = p.makeStructure('chart-dots-series', 'chart-dots-points-text', p.target)
    //   // texts = gTexts
    //   texts = gDataPoints
    //     .selectAll('.texts')
    //     .data((d) => _.compact([filter(d) ? d : null]))
    //     .join('g')
    //     .attr('class', 'texts')
    //     .attr('transform', d => translate({ x: p.scale.x(d.point.x), y: p.scale.y.range()[0] }))
    //     // (d) => p.scale.x(d.point.x))
    //     // .attr('y', (d) => p.scale.y.range()[0])

    //   texts
    //     .selectAll('text')
    //     .data((d:any) => [d])
    //     .join('text')

    //     // .attr('x', (d) => p.scale.x(d.point.x))
    //     // .attr('y', (d) => p.scale.y.range()[0])

    //     .style('transform', 'rotate(-90deg)')

    //     .text((d:any) => d.point.text)

    //     // .attr('height', style.dotRadius * 2)
    //     // .attr('width', style.dotRadius * 2)

    //     // .attr('stroke-width', style.lineSize)
    //     // .attr('fill', filling)
    //     // .attr('stroke', (d) => d.color)
    //     .style('fill', (d:any) => d.color)
    //     // .attr('stroke-dasharray', style.dasharray || '')
    // }

    // let { onEnter, onExit } = p
    // if (onEnter) {
    //   points.on('mouseenter', (d) => {
    //     onEnter && onEnter(d)
    //   })
    // }
    // if (onExit) {
    //   points.on('mouseleave', () => {
    //     onExit && onExit()
    //   })
    // }
}

// export function circleShape(t: Target, style: Style) {
//   t
//     .selectAll('circle')
//     .data([null])
//     .join('circle')

//     .attr('cx', (d) => p.scale.x(d.point.x))
//     .attr('cy', (d) => p.scale.y(d.point.y))
//     .attr('r', style.dotRadius)
// }
