// @ts-nocheck
import * as d3 from 'd3';
import { Target, Area } from '..';


interface Props {
    value: string;
    onChange: (value: string) => void;

    target: Target;
    area: Area;
}

let defaultProps = {
  fontFamily: 'DM Sans',
  fontSize: 20,
}

type FullProps = Props & Partial<typeof defaultProps>

export function TextInput(given: FullProps) {
    let p = { ...defaultProps, ...given }

    let outerW = p.area.width()
    let outerH = p.area.height()

    let target = p.target
        .selectAll('foreignObject')
        .data([null])
        .join('foreignObject')
        .attr('x', p.area.left)
        .attr('y', p.area.top)
        .attr('width', `${outerW}px`)
        .attr('height', `${outerH}px`)
        .selectAll('div')
        .data([null])
        .join('xhtml:div')
        .style('width', '100%')
        .style('height', '100%')

    target
        .selectAll('input')
        .data([null])
        .join('xhtml:input')
        .attr('type', 'text')
        .style('width', '100%')
        .style('height', '100%')
        .style('font-size', `${p.fontSize}px`)
        .style('font-family', p.fontFamily)
        .style('color', `#123`)
        .property('value', p.value)
        .on('mousedown', () => d3.event.stopPropagation())
        .on('mousemove', () => d3.event.stopPropagation())
        .on('mouseup', () => d3.event.stopPropagation())
        .on('change', (x) => {
            p.onChange(d3.event.target.value)
        })
}
