import { DarkAdaptationAmdBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/darkAdaptationAmdBulbicamMeasuredDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class DarkAdaptationAmdBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof DarkAdaptationAmdBulbicamMeasuredDataFrontend = DarkAdaptationAmdBulbicamMeasuredDataFrontend;
    public measurements: DarkAdaptationAmdBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
