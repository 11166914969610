import { TonometryTonorefIIITest } from '../../../../../../../../commonout/classes/tests/NIDEK/TonorefIII/tonometryTonorefIIITest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { TonometryTonorefIIITestRemarksComponent } from '../../../../_components/';
import { TonometryTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/tonometryTonorefIIITestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class TonometryTonorefIIITestFrontend extends TonometryTonorefIIITest {
    remarks: TonometryTonorefIIITestRemarksFrontend;
    public readonly remarksComponent: typeof TonometryTonorefIIITestRemarksComponent = TonometryTonorefIIITestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new TonometryTonorefIIITestRemarksFrontend();
    }
}

export interface TonometryTonorefIIITestFrontend extends TestFrontend {}
applyMixins(TonometryTonorefIIITestFrontend, [TestFrontend]);
