import { AntisaccadeBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/antisaccadeBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { AntisaccadeTestChartComponent, AntisaccadeTestRemarksComponent, CommonStartStopControlComponent } from '../../../_components';
import { AntisaccadeTestRemarksFrontend } from '../../test-remarks/BulbiCAM/antisaccadeTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class AntisaccadeBulbicamTestFrontend extends AntisaccadeBulbicamTest {
    remarks: AntisaccadeTestRemarksFrontend;
    public readonly remarksComponent: typeof AntisaccadeTestRemarksComponent = AntisaccadeTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof AntisaccadeTestChartComponent = AntisaccadeTestChartComponent;
    public readonly bulbicamControlComponent: typeof CommonStartStopControlComponent = CommonStartStopControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new AntisaccadeTestRemarksFrontend();
    }
}

export interface AntisaccadeBulbicamTestFrontend extends TestFrontend {}
applyMixins(AntisaccadeBulbicamTestFrontend, [TestFrontend]);
