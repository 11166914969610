// @ts-nocheck
import { Area } from '../lib/Area'
import { Target } from '../lib/types'
import { drawArrow } from '../lib/drawable'


interface Props {
  rotationDeg: number

  area: Area
  target: Target
}

let defaultProps = {
  tip: 6,
}

export function Compass(given: Props & Partial<typeof defaultProps>) {
  let p = { ...defaultProps, ...given }

  let x0 = p.area.left
  let y0 = p.area.midy
  let dx = p.area.w
  let tipdx = p.tip

  if (true) {
    p.target
      .selectAll('.xarrow')
      .data([null])
      .join('path')
      .attr('class', 'xarrow fCompass1 sCompass1')
      .attr('stroke-width', 2)
      .attr('d', drawArrow({
        x0,
        y0,
        dx,
        // tipdx: 0,
        tipdx,
      }))
  }

  if (true) {
    p.target
      .selectAll('.yarrow')
      .data([null])
      .join('path')
      .attr('class', 'yarrow fCompass2 sCompass2')
      .attr('stroke-width', 2)
      .attr('d', drawArrow({
        x0,
        y0,
        dx,
        tipdx,
      }))
      .attr('transform', `rotate(-90 ${p.area.midx} ${p.area.midy})`)
  }

  p.target
    .attr('transform', `rotate(${p.rotationDeg} ${p.area.midx} ${p.area.midy})`)
}
