import { AntSegmentHaagSlitlampTest } from '../../../../../../../../commonout/classes/tests/HAAG/SLITLAMP/antSegmentHaagSlitlampTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { AntSegmentHaagSlitlampTestRemarksComponent } from '../../../../_components';
import { AntSegmentHaagSlitlampTestRemarksFrontend } from '../../../test-remarks/HAAG/SLITLAMP/antSegmentHaagSlitlampTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class AntSegmentHaagSlitlampTestFrontend extends AntSegmentHaagSlitlampTest {
    remarks: AntSegmentHaagSlitlampTestRemarksFrontend;
    public readonly remarksComponent: typeof AntSegmentHaagSlitlampTestRemarksComponent = AntSegmentHaagSlitlampTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new AntSegmentHaagSlitlampTestRemarksFrontend();
    }
}

export interface AntSegmentHaagSlitlampTestFrontend extends TestFrontend {}
applyMixins(AntSegmentHaagSlitlampTestFrontend, [TestFrontend]);
