import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import JSZip from 'jszip';
import { last, sortBy } from 'lodash';
import moment from 'moment';
import { from } from 'rxjs';
import { concatMap, filter, map, toArray } from 'rxjs/operators';
import { TestClassProvider } from '../../../../../../commonout/classes/testClass.provider';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRegisterEditField } from '../../../../../../commonout/interfaces/register-edit-field.interface';
import { OCULUS } from '../../../../../common/enums/oculus.enum';
import { IDC_ITEM_TYPE } from '../../../../../common/enums/idc-item-type.enum';
import { ONSET_TYPE } from '../../../../../common/enums/visualfieldMergedOnsetType.enum';
import { IIDCDiagnosis } from '../../../../../common/interfaces/idc-list-item.interface';
import { ExportPupilTestResults, IPupil20TestCamMessage } from '../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { IPursuitSaccadeTestResults } from '../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ISmoothPursuitTestCamMessage, StimuliResults } from '../../../../../common/interfaces/smoothPursuitTestMessage.interface';
import { CamTest } from '../../../../../common/models/haplotest.class';
import { DarkAdaptationAmdBulbicamTestChartComponent } from '../../_components';
import { VALUE_KEYS } from '../../_components/tests/charts/nystagmus-test/diagram/helpers/ManualStorage';
import { MESSAGE_TYPE } from '../../_components/tests/charts/saccade-test/diagram/lib';
import { SUB_TYPES } from '../../_components/tests/charts/SaccadeMerged/SaccadeMergedWrapper/saccade-merged-test-chart.component';
import { ExaminationFrontend } from '../../_models/examinationFrontend.class';
import { BulbicamTestFrontend } from '../../_models/haplotestFrontend.class';
import { TestFrontend } from '../../_models/tests/testFrontend.class';
import { AntiSaccadeChartService } from '../chartServices/antiSaccadeChartService';
import { FixationChartService } from '../chartServices/fixationChartService';
import { FunctionalScreeningChartService } from '../chartServices/functionalScreeningChartService';
import { MemorySaccadeChartService } from '../chartServices/memorySaccadeChartService';
import { MyPupil20TestChartService } from '../chartServices/myPupil20TestChartService';
import { ProSaccadeChartService } from '../chartServices/proSaccadeChartService';
import { SaccadeChartService } from '../chartServices/pursuitSaccadesServices/saccadesTestServices/saccadesChartService';
import { SmoothPursuitChartService } from '../chartServices/pursuitSaccadesServices/smoothPursuitTestServices/smoothPursuitChartService';
import { VisualFieldMergedChartService } from '../chartServices/visualFieldMergedChartService';
import { FileService } from '../general/file.service';
import { BulbicamService } from './bulbiCam.service';

@Injectable()
export class ExportService {
    // to pdf part
    private readonly END_ROW = '\r\n';
    public counter: number = 0;
    // to pdf part

    // compressed tsv part
    private readonly commonHeaderBeginning: string = 'patient_ID\tssn\texamination_ID\ttest_ID';
    // compressed tsv part

    constructor(
        private fileService: FileService,
        private classesProvider: TestClassProvider,
        private fixationChartService: FixationChartService,
        private pupil20TestChartService: MyPupil20TestChartService,
        private smoothPursuitChartService: SmoothPursuitChartService,
        private saccadesService: SaccadeChartService,
        private antiSaccadeChartService: AntiSaccadeChartService,
        private memorySaccadeChartService: MemorySaccadeChartService,
        private proSaccadeChartService: ProSaccadeChartService,
        private visualFieldChartService: VisualFieldMergedChartService,
        private functionalScreeningChartService: FunctionalScreeningChartService
    ) {}

    public async toPDF(
        examination: ExaminationFrontend,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        const document: jsPDF = new jsPDF({
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
            }),
            logo: HTMLImageElement = await this.fileService.logo,
            pager = new Pager(document, logo),
            skipToPdfTests: [{ type: TEST_TYPE; device: DEVICE }] = [{ type: TEST_TYPE.MACULA_SCAN, device: DEVICE.NIDEK_RS330 }];
        examination.patient.toPDF(document, pager);
        pager.nextPage();
        from(examination.tests)
            .pipe(
                filter(test => !skipToPdfTests.some(skip => test.type === skip.type && test.device === skip.device)),
                map(test => {
                    this.counter++;
                    return test;
                }),
                concatMap(test => test.toPDF(document, pager, this.fileService, componentFactoryResolver, viewContainerRef, bulbicamService)),
                map(() => this.counter--),
                toArray()
            )
            .subscribe(done => {
                for (let index = 0; index < document.internal.getNumberOfPages(); index++) {
                    document.setPage(index + 1);
                    pager.addHeader(index + 1, examination.patient.registerID.value as string);
                    pager.addFooter(index + 1);
                }
                document.save('Report.PDF');
            });
    }
    public async examsReport(
        startRange: Date,
        endRange: Date,
        report: {
            createdAt: number;
            patient: {
                registerID: IRegisterEditField<string>;
                ssn: IRegisterEditField<string>;
                dateOfBirth: IRegisterEditField<number>;
            };
        }[]
    ): Promise<void> {
        const document: jsPDF = new jsPDF({
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
            }),
            logo: HTMLImageElement = await this.fileService.logo,
            pager = new Pager(document, logo);
        let table: any[] = [];
        table.push({
            column0: {
                colSpan: 5,
                content: 'Examinations report from: ' + moment(startRange).format('YYYY-MM-DD') + ' to: ' + moment(endRange).format('YYYY-MM-DD'),
            },
            column1: '',
            column2: '',
            column3: '',
            column4: '',
        });
        table.push({
            column0: 'Order number',
            column1: 'Examination register ID',
            column2: 'Patient register ID',
            column3: 'SSN',
            column4: 'Date of birth',
        });
        from(report)
            .pipe(
                map((exam, index) => {
                    table.push({
                        column0: index + 1,
                        column1: { content: moment(exam.createdAt).format('YYYYMMDD HHmmss'), styles: { halign: 'left' } },
                        column2: { content: exam.patient.registerID.value, styles: { halign: 'left' } },
                        column3: { content: exam.patient.ssn.value, styles: { halign: 'left' } },
                        column4: { content: moment(exam.patient.dateOfBirth.value).format('DD MM YYYY'), styles: { halign: 'left' } },
                    });
                    return exam;
                }),
                toArray()
            )
            .subscribe(done => {
                (document as any).autoTable({
                    body: table,
                    startY: pager.line,
                    pageBreak: 'auto',
                    theme: 'grid',
                    margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                    didDrawPage: () => {},
                });
                document.save('Report.PDF');
            });
    }
    public isTsvExportAvailable(test: TestFrontend): boolean {
        switch (test.type) {
            case TEST_TYPE.FUNCTIONAL_SCREENING:
            case TEST_TYPE.VISUAL_FIELD_MERGED:
            case TEST_TYPE.DARK_ADAPTAION_AMD:
            case TEST_TYPE.PTOSIS_EVALUATION:
            case TEST_TYPE.PURSUITS_AND_SACCADES:
            case TEST_TYPE.PUPILLARY_EVALUATION2:
            case TEST_TYPE.NYSTAGMUS_EVALUATION:
            case TEST_TYPE.SACCADE_MERGED:
            case TEST_TYPE.FIXATION_TEST:
                return true;
            default:
                return false;
        }
    }
    /**
     *
     * @param test Test object as a exported data sourse
     * @param measurementIndex Index of CAM measurement
     * @returns ZIP object with data
     */
    public async toCompressedTsv(test: TestFrontend, camMeasurements?: BulbicamTestFrontend[]): Promise<{ content: Blob; filename: string }[]> {
        const camMeasurementsToExport: CamTest[] = camMeasurements || test.remarks.measurements[0]['haplotests'];
        if (camMeasurementsToExport.length === 0) return [];
        switch (test.type) {
            case TEST_TYPE.FUNCTIONAL_SCREENING:
                return this.functionalScreening(test, camMeasurementsToExport);
            case TEST_TYPE.VISUAL_FIELD_MERGED:
                return this.visualFieldMergedZip(test, camMeasurementsToExport);
            case TEST_TYPE.DARK_ADAPTAION_AMD:
                return this.acodapt(test, camMeasurementsToExport);
            case TEST_TYPE.PTOSIS_EVALUATION:
                return this.ptosisZip(test, camMeasurementsToExport);
            case TEST_TYPE.PURSUITS_AND_SACCADES:
                return this.pursuitsAndSaccades(test, camMeasurementsToExport);
            case TEST_TYPE.PUPILLARY_EVALUATION2:
                return this.pupil20Zip(test, camMeasurementsToExport);
            case TEST_TYPE.NYSTAGMUS_EVALUATION:
                return this.nystagmusZip(test, camMeasurementsToExport);
            case TEST_TYPE.SACCADE_MERGED:
                return this.saccadesMerged(test, camMeasurementsToExport);
            case TEST_TYPE.FIXATION_TEST:
                return this.fixationTest(test, camMeasurementsToExport);
            default:
                console.error(`No test type ${test.type} prepared for export.`);
                return [];
        }
    }

    private async fixationTest(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            this.fixationChartService.setCamData(camTest.rawData);
                            const results = this.fixationChartService.export();
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\ttrial\tsaccades(deg)\tpeak_velocity(deg/s)\tfixation(ms)\taccepted\n`;
                                        results.trialsInfo.forEach(tr => {
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${tr.trial}\t${tr.saccade?.toFixed(2) ||
                                                'no_data'}\t${tr.peak_velocity?.toFixed(0) || 'no_data'}\t${tr.fixation.toFixed(0)}\t${tr.status.replace(/\s/g, '_')}\n`;
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export_statistics.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tpercentile_25%_saccades(deg)\tpercentile_25%_peak_velocity(deg/s)\tpercentile_25%_fixation(ms)\tpercentile_50%_saccades(deg)\tpercentile_50%_peak_velocity(deg/s)\tpercentile_50%_fixation(ms)\tpercentile_75%_saccades(deg)\tpercentile_75%_peak_velocity(deg/s)\tpercentile_75%_fixation(ms)\tmean_saccades(deg)\tmean_peak_velocity(deg/s)\tmean_fixation(ms)\tcovariance_saccades\tcovariance_peak_velocity\tcovariance_fixation\tmax_fixation\tpupil_percentile5%(mm)\tpupil_mean(mm)\tpupil_percentile95%(mm)\ttreshhold_level\tmoving_average\tglint_N_Minus\n`;
                                        const d = results.details;
                                        content += `${this.getCommonBeginning(test, camTest, false)}\t${d.percentile_25_deg.toFixed(2)}\t${d.percentile_25_degs.toFixed(
                                            0
                                        )}\t${d.percentile_25_ms.toFixed(0)}\t${d.percentile_50_deg.toFixed(2)}\t${d.percentile_50_degs.toFixed(0)}\t${d.percentile_50_ms.toFixed(
                                            0
                                        )}\t${d.percentile_75_deg.toFixed(2)}\t${d.percentile_75_degs.toFixed(0)}\t${d.percentile_75_ms.toFixed(0)}\t${d.mean_deg.toFixed(
                                            2
                                        )}\t${d.mean_degs.toFixed(0)}\t${d.mean_ms.toFixed(0)}\t${d.cov_deg.toFixed(2)}\t${d.cov_degs.toFixed(2)}\t${d.cov_ms.toFixed(
                                            2
                                        )}\t${d.max_fixation.toFixed(0)}\t${d.pupil_distance_5.toFixed(2)}\t${d.pupil_distance_mean.toFixed(2)}\t${d.pupil_distance_95.toFixed(
                                            2
                                        )}\t${results.thresold}\t${results.moving_average}\t${results.glintNMinus}\n`;
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async nystagmusZip(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            // what if manual????
                            const getActualFromLog = function(id: number, eye: string, edits: IChartEdit[]) {
                                let keys = VALUE_KEYS.map(x => `${eye}${x}`);
                                let log = edits.filter(x => x.messageTimestamp === id);
                                let midLog = sortBy(log, x => x.date);
                                let stuff = [...midLog];
                                stuff.reverse();
                                let clearFlag = `${eye}-clear`;
                                let lastClear = stuff.find(x => x.fieldName === clearFlag) as any;
                                if (lastClear) {
                                    let remove = true;
                                    midLog = midLog.filter(x => {
                                        if (!remove) return true;
                                        if (x.fieldName === clearFlag && x.currentValue === lastClear.currentValue) remove = false;
                                        return false;
                                    });
                                }
                                let results: any = {};
                                keys.forEach(key => {
                                    let smallLog = midLog.filter(x => x.fieldName === key);
                                    let entry = last(smallLog);
                                    let value = entry ? entry.currentValue : null;
                                    results[key] = value;
                                });
                                return results;
                            };
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_rotatedDataPoints.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tstimulus_location\tstimulus_distance\ttime(sec)\thorizontal_OD\tvertical_OD\tOD_horizontal_deg\tOD_vertical_deg\thorizontal_OS\tvertical_OS\tOS_horizontal_deg\tOS_vertical_deg\n`,
                                            stimulusLocation: string,
                                            stimulusDistance: string,
                                            degtopxHOD: number,
                                            degtopxHOS: number,
                                            degtopxVOD: number,
                                            degtopxVOS: number;
                                        camTest.rawData.forEach((m: any, i, arr: any[]) => {
                                            if (m.message_type === MESSAGE_TYPE.START_SEQUENCE) {
                                                stimulusLocation = m.name
                                                    .split(' ')
                                                    .slice(0, -1)
                                                    .join(' ');
                                                stimulusDistance = m.name.split(' ')[m.name.split(' ').length - 1];
                                                for (let index = i + 1; index < arr.length; index++) {
                                                    if (arr[index].message_type === MESSAGE_TYPE.CONFIGURATION_PARAMS) {
                                                        degtopxHOD = arr[index].degtopxHOD;
                                                        degtopxHOS = arr[index].degtopxHOS;
                                                        degtopxVOD = arr[index].degtopxVOD;
                                                        degtopxVOS = arr[index].degtopxVOS;
                                                        break;
                                                    }
                                                }
                                            } else if (m.message_type === MESSAGE_TYPE.DATA_PACKAGE) {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${stimulusLocation}\t${stimulusDistance}\t${m.secs}\t${m.h_od}\t${
                                                    m.v_od
                                                }\t${m.h_od * degtopxHOD}\t${m.v_od * degtopxVOD}\t${m.h_os}\t${m.v_os}\t${m.h_os * degtopxHOS}\t${m.v_os * degtopxVOS}\n`;
                                            }
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_resultsExport.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tdistance(cm)\tlocation\toculus\tfrequency(Hz)\tamplitude(degree)\taxis\ttype\n`;
                                        camTest.rawData
                                            .filter(m => m.message_type === MESSAGE_TYPE.START_SEQUENCE)
                                            .forEach((m: any, i, arr) => {
                                                const location = m.name.split(' ')[0];
                                                const distance = m.name.split(' ')[m.name.split(' ').length - 1];
                                                const OD: {
                                                    ODfrequency: number;
                                                    ODamplitude: number;
                                                    ODaxis: number;
                                                    ODtype: number;
                                                } = getActualFromLog(m.timestamp, 'OD', camTest.edits);
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${distance}\t${location}\tOD\t${OD.ODfrequency?.toFixed(2) ||
                                                    'no data'}\t${OD.ODamplitude?.toFixed(2) || 'no data'}\t${OD.ODaxis?.toFixed(2) || 'no data'}\t${OD.ODtype || 'no data'}\n`;
                                                const OS: {
                                                    OSfrequency: number;
                                                    OSamplitude: number;
                                                    OSaxis: number;
                                                    OStype: number;
                                                } = getActualFromLog(m.timestamp, 'OS', camTest.edits);
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${distance}\t${location}\tOS\t${OS.OSfrequency?.toFixed(2) ||
                                                    'no data'}\t${OS.OSamplitude?.toFixed(2) || 'no data'}\t${OS.OSaxis?.toFixed(2) || 'no data'}\t${OS.OStype || 'no data'}\n`;
                                            });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async ptosisZip(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        const lensOD: number = +test.examination.patient.lensValues.sphere.OD.value;
        const lensOS: number = +test.examination.patient.lensValues.sphere.OS.value;

        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `patient_ID\tssn\texamination_ID\ttest_ID\tMRD1_OD\tMRD2_OD\tpupil_diameter_OD\tMRD1_OS\tMRD2_OS\tpupil_diameter_OS\ttrial\n`;
                                        camTest.rawData.forEach((imageSet, i) => {
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${(
                                                (imageSet.ODCenterY - imageSet.ODTopY) / 14.5 -
                                                ((imageSet.ODCenterY - imageSet.ODTopY) / 14.5) * Math.abs(lensOD) * (lensOD > 0 ? 0.0232 : 0.0178)
                                            ).toFixed(1)}\t${(
                                                (imageSet.ODBottomY - imageSet.ODCenterY) / 14.5 -
                                                ((imageSet.ODBottomY - imageSet.ODCenterY) / 14.5) * Math.abs(lensOD) * (lensOD > 0 ? 0.0232 : 0.0178)
                                            ).toFixed(1)}\t${(imageSet.ODSize / 14.5 - (imageSet.ODSize / 14.5) * Math.abs(lensOD) * (lensOD > 0 ? 0.0232 : 0.0178)).toFixed(
                                                1
                                            )}\t${(
                                                (imageSet.OSCenterY - imageSet.OSTopY) / 14.5 -
                                                ((imageSet.OSCenterY - imageSet.OSTopY) / 14.5) * Math.abs(lensOS) * (lensOS > 0 ? 0.0232 : 0.0178)
                                            ).toFixed(1)}\t${(
                                                (imageSet.OSBottomY - imageSet.OSCenterY) / 14.5 -
                                                ((imageSet.OSBottomY - imageSet.OSCenterY) / 14.5) * Math.abs(lensOS) * (lensOS > 0 ? 0.0232 : 0.0178)
                                            ).toFixed(1)}\t${(imageSet.OSSize / 14.5 - (imageSet.OSSize / 14.5) * Math.abs(lensOS) * (lensOS > 0 ? 0.0232 : 0.0178)).toFixed(
                                                1
                                            )}\t${i + 1}\n`;
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async pupil20Zip(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            this.pupil20TestChartService.setCamData(camTest.rawData as IPupil20TestCamMessage[]);
                            const exportData: ExportPupilTestResults = this.pupil20TestChartService.export();
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_area_results.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tstart_time\tend_time\tareaOD\tareaOS\tarea_diff_ODOS\tround\n`;
                                        exportData.pupilTestResults.forEach((data, round) => {
                                            data.areaResults.forEach(a => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${a.startTime}\t${a.endTime}\t${a.areaOD}\t${a.areaOS}\t${
                                                    a.areaDifference
                                                }\t${round + 1}\n`;
                                            });
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_pupil_diameter_results.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tstart_time\tend_time\tdiameterOD\tdiameterOS\tdiameter_diff_ODOS\tdiameter_change_OD\tdiameter_change_OS\tdiameter_change_diff_ODOS\tround\n`;
                                        exportData.pupilTestResults.forEach((data, round) => {
                                            data.pupilDiameterResults.forEach(d => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${d.startTime}\t${d.endTime}\t${d.averageDiameterOD}\t${
                                                    d.averageDiameterOS
                                                }\t${d.differenceDiameter}\t${d.diameterChangeOD ? d.diameterChangeOD : 'no data'}\t${
                                                    d.diameterChangeOS ? d.diameterChangeOS : 'no data'
                                                }\t${d.differenceDiameterChange ? d.differenceDiameterChange : 'no data'}\t${round + 1}\n`;
                                            });
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_peak_velocity.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tpeak_velocity_OD(mm/sec)\ttime_of_peak_velocity_OD(sec)\tpeak_velocity_OS(mm/sec)\ttime_of_peak_velocity_OS(sec)\tpeak_velocity_diff_ODOS\tround\n`;
                                        exportData.pupilTestResults.forEach((data, round) => {
                                            data.peakVelocityResults.forEach(v => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${v.peakVelocityOD}\t${v.timeOD}\t${v.peakVelocityOS}\t${v.timeOS}\t${
                                                    v.differencePeakVelocity
                                                }\t${round + 1}\n`;
                                            });
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_latency.tsv`,
                                ((): string => {
                                    try {
                                        let content = `${this.commonHeaderBeginning}\tstart_time(sec)\tlatency_OD(ms)\tlatency_OS(ms)\tlatency_diff_ODOS\tend_timeOD\tend_timeOS\tround\n`;
                                        exportData.pupilTestResults.forEach((data, round) => {
                                            data.latencyResults.forEach(l => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${l.startTime}\t${l.latencyOD}\t${l.latencyOS}\t${
                                                    l.differenceLatency
                                                }\t${l.endTimeOD}\t${l.endTimeOS}\t${round + 1}\n`;
                                            });
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_RAPD_diam_change.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\tshifted_Odd_x\ty\toculus\tround\n`;
                                        exportData.pupilDotChartResult.shiftedDotsOdd.forEach(
                                            d => (content += `${this.getCommonBeginning(test, camTest, false)}\t${d.x}\t${d.y}\t${d.oculus}\t${d.round}\n`)
                                        );

                                        content += `${this.commonHeaderBeginning}\teven_x\ty\toculus\tround\n`;
                                        exportData.pupilDotChartResult.dotsEven.forEach(
                                            d => (content += `${this.getCommonBeginning(test, camTest, false)}\t${d.x}\t${d.y}\t${d.oculus}\t${d.round}\n`)
                                        );
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_RAPDlog.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\toculus\tRAPD\n`;
                                        content += `${this.getCommonBeginning(test, camTest, false)}\t${exportData.pupilDotChartResult.dataRAPD.oculus}\t${
                                            exportData.pupilDotChartResult.dataRAPD.value
                                        }`;
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            this.addRawExport(this.pupil20TestChartService.getRawExport(), zip, test, camTest);
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async pursuitsAndSaccades(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            const target: TEST_TYPE = camTest.rawData.find(m => m.target).target as TEST_TYPE;
                            if (target === TEST_TYPE.SMOOTH_PURSUIT) {
                                this.smoothPursuitChartService.setCamData(camTest.rawData as ISmoothPursuitTestCamMessage[]);
                                const results: StimuliResults[] = this.smoothPursuitChartService.export();
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\tstimuli_n\toculus\ttime_shift(sec)\tphase_shift(deg)\tgain(%)\tstart_time(sec)\tend_time(sec)\n`;
                                            results.forEach(r => {
                                                r.windowsResultOD.forEach(od => {
                                                    content += `${this.getCommonBeginning(test, camTest, false)}\t${r.stimuliName.replace(/\s/g, '_')}\tOD\t${od.timeShift.toFixed(
                                                        2
                                                    )}\t${od.phaseShift.toFixed(2)}\t${od.gain.toFixed(2)}\t${od.startTime.toFixed(2)}\t${od.endTime.toFixed(2)}\n`;
                                                });
                                                r.windowsResultOS.forEach(os => {
                                                    content += `${this.getCommonBeginning(test, camTest, false)}\t${r.stimuliName.replace(/\s/g, '_')}\tOS\t${os.timeShift.toFixed(
                                                        2
                                                    )}\t${os.phaseShift.toFixed(2)}\t${os.gain.toFixed(2)}\t${os.startTime.toFixed(2)}\t${os.endTime.toFixed(2)}\n`;
                                                });
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                this.addRawExport(this.smoothPursuitChartService.getRawExport(), zip, test, camTest, target);
                            } else if (target === TEST_TYPE.SACCADES) {
                                this.saccadesService.setCamData(camTest.rawData);
                                const results: IPursuitSaccadeTestResults = this.saccadesService.export();
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\taxis\ttype\tlatency(m/s)\tpeak_velocity(deg/s)\taccuracy(deg)\taccepted\n`;
                                            results.rangeTestResults.forEach(rtr => {
                                                rtr.saccadesTestResults.forEach(str => {
                                                    content += `${this.getCommonBeginning(test, camTest, false)}\t${rtr.type}${rtr.angle}\t${str.type?.replace(
                                                        /\s/g,
                                                        '_'
                                                    )}\t${str.latency?.toFixed(0)}\t${str.peakVelocity?.toFixed(0)}\t${str.amplitude?.toFixed(0)}\t${str.result?.replace(
                                                        /\s/g,
                                                        '_'
                                                    )}\n`;
                                                });
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_horizontal_calibrated_frames_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\tvalue\ttime\n`;
                                            results.horizontalCalibratedFrames.forEach(fr => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${fr.calibratedCoor}\t${fr.time}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_horizontal_velocity_frames_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\tvalue\ttime\n`;
                                            results.horizontalVelocityFrames.forEach(fr => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${fr.pupilVelocity}\t${fr.time}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_vertical_calibrated_frames_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\tvalue\ttime\n`;
                                            results.verticalCalibratedFrames.forEach(fr => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${fr.calibratedCoor}\t${fr.time}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_vertical_velocity_frames_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\tvalue\ttime\n`;
                                            results.verticalVelocityFrames.forEach(fr => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${fr.pupilVelocity}\t${fr.time}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                this.addRawExport(this.saccadesService.getRawExport(), zip, test, camTest, target);
                            }
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async saccadesMerged(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            const target: SUB_TYPES = camTest.rawData.find(m => m.target).target as SUB_TYPES;
                            if (target === SUB_TYPES.MEMORY_SACCADE) {
                                this.memorySaccadeChartService.setCamData(camTest.rawData);
                                const results = this.memorySaccadeChartService.export();
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\ttrial\tlatency(ms)\taccuracy(%)\tstatus\n`;
                                            results.trialsInfo.forEach(tr => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${results.details.eye}\t${tr.trial + 1}\t${tr.latency?.toFixed(0) ||
                                                    'no_data'}\t${tr.accuracy?.toFixed(0) || 'no_data'}\t${tr.status.replace(/\s/g, '_')}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export_statistics.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\tpercentile_25%_latency(ms)\tpercentile_25%_accuracy(%)\tpercentile_50%_latency(ms)\tpercentile_50%_accuracy(%)\tpercentile_75%_latency(ms)\tpercentile_75%_accuracy(%)\tmean(ms)\tmean(%)\tcovariance(l)\tcovariance(%)\ttotal_attempts\n`;
                                            const d = results.details;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${
                                                d.eye
                                            }\t${d.percentile_25_ms.toFixed()}\t${d.percentile_25_percent.toFixed(0)}\t${d.percentile_50_ms.toFixed(
                                                0
                                            )}\t${d.percentile_50_percent.toFixed(0)}\t${d.percentile_75_ms.toFixed(0)}\t${d.percentile_75_percent.toFixed(0)}\t${d.mean_ms.toFixed(
                                                0
                                            )}\t${d.mean_percent.toFixed(0)}\t${d.cov_ms.toFixed(3)}\t${d.cov_percent.toFixed(3)}\t${d.total_attempts}\n`;
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_errors.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\tnum_correct\tnum_inhibitory_error\tnum_calc_na\tnum_no_response\tnum_trials\n`;
                                            const d = results.details;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${d.correct}\t${d.error}\t${d.na}\t${d.noResponse}\t${
                                                d.total_attempts
                                            }\n`;
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                            } else if (target === SUB_TYPES.ANTI_SACCADE) {
                                this.antiSaccadeChartService.setCamData(camTest.rawData);
                                const results = this.antiSaccadeChartService.export();
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\ttrial\tlatency(ms)\tamplitude(%)\tpeakVelocity(deg/s)\ttype\n`;
                                            results.trialsResults.forEach(tr => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${results.eye}\t${tr.trial}\t${tr.latency?.toFixed(0) ||
                                                    'no_data'}\t${tr.amplitude?.toFixed(0) || 'no_data'}\t${tr.peakVelocity?.toFixed(0) || 'no_data'}\t${tr.type.replace(
                                                    /\s/g,
                                                    '_'
                                                )}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export_statistics.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\ttype\tpercentile_25%\tpercentile_50%\tpercentile_75%\tmean\tcovariance\tattempts\tcorrect_attempts\n`;
                                            const l = results.details.latencyInfo;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${results.eye}\tlatency\t${l.p25.toFixed(0)}\t${l.p50.toFixed(
                                                0
                                            )}\t${l.p75.toFixed(0)}\t${l.mean.toFixed(0)}\t${l.cov.toFixed(3)}\t${results.details.total}\t${results.details.successful}\n`;
                                            const a = results.details.amplitudeInfo;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${results.eye}\tamplitude\t${a.p25.toFixed(0)}\t${a.p50.toFixed(
                                                0
                                            )}\t${a.p75.toFixed(0)}\t${a.mean.toFixed(0)}\t${a.cov.toFixed(3)}\t${results.details.total}\t${results.details.successful}\n`;
                                            const pv = results.details.peakVelInfo;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${results.eye}\tpeak_velocity\t${pv.p25.toFixed(0)}\t${pv.p50.toFixed(
                                                0
                                            )}\t${pv.p75.toFixed(0)}\t${pv.mean.toFixed(0)}\t${pv.cov.toFixed(3)}\t${results.details.total}\t${results.details.successful}\n`;
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export_error_rates.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\terror_rate(%)\tantiSaccade(%)\tproSaccade(%)\tcorrectedAntiSaccade(%)\tnoResponse(%)\tnoCalibration(%)\tattempts\n`;
                                            const e = results.details;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${results.eye}\t${e.errorRate}\t${e.antiSaccades[1]}\t${
                                                e.proSaccades[1]
                                            }\t${e.correctedAnti[1]}\t${e.noResponse[1]}\t${e.calibrationNA[1]}\t${e.total}\n`;
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                            } else if (target === SUB_TYPES.PRO_SACCADE) {
                                this.proSaccadeChartService.setCamData(camTest.rawData);
                                const results = this.proSaccadeChartService.export();
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\ttrial\tlatency(ms)\taccuracy(%)\tpeak_velocity(deg/s)\taccepted\n`;
                                            results.trials.forEach((tr, i) => {
                                                content += `${this.getCommonBeginning(test, camTest, false)}\t${results.eye}\t${tr.trial}\t${tr.latency?.toFixed(0) ||
                                                    'no_data'}\t${tr.accuracy?.toFixed(1) || 'no_data'}\t${tr.peak_velocity?.toFixed(1) || 'no_data'}\t${tr.is_accepted.replace(
                                                    /\s/g,
                                                    '_'
                                                )}\n`;
                                            });
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                                zip.file(
                                    `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_${target}_export_statistics.tsv`,
                                    ((): string => {
                                        try {
                                            let content: string = `${this.commonHeaderBeginning}\toculus\tpercentile_25%_latency(ms)\tpercentile_25%_accuracy(%)\tpercentile_25%_peak_velocity(deg/s)\tpercentile_50%_latency(ms)\tpercentile_50%_accuracy(%)\tpercentile_50%_peak_velocity(deg/s)\tpercentile_75%_latency(ms)\tpercentile_75%_accuracy(%)\tpercentile_75%_peak_velocity(deg/s)\tmean_latency(ms)\tmean_accuracy(%)\tmean_peak_velocity(deg/s)\tcovariance(lat)\tcovariance(acc)\tcovariance(peakV)\n`;
                                            const d = results.details;
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${
                                                results.eye
                                            }\t${d.latency_percentile_25.toFixed()}\t${d.accuracy_percentile_25.toFixed(0)}\t${d.peak_velocity_percentile_25.toFixed(
                                                0
                                            )}\t${d.latency_percentile_50.toFixed(0)}\t${d.accuracy_percentile_50.toFixed(0)}\t${d.peak_velocity_percentile_50.toFixed(
                                                0
                                            )}\t${d.latency_percentile_75.toFixed(0)}\t${d.accuracy_percentile_75.toFixed(0)}\t${d.peak_velocity_percentile_75.toFixed(
                                                0
                                            )}\t${d.latency_mean.toFixed(0)}\t${d.accuracy_mean.toFixed(0)}\t${d.peak_velocity_mean.toFixed(0)}\t${d.latency_cov.toFixed(
                                                3
                                            )}\t${d.accuracy_cov.toFixed(3)}\t${d.peak_velocity_cov.toFixed(3)}\n`;
                                            return content;
                                        } catch (error) {
                                            return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                        }
                                    })()
                                );
                            }
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async acodapt(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            const chartComponent: DarkAdaptationAmdBulbicamTestChartComponent = new DarkAdaptationAmdBulbicamTestChartComponent();
                            chartComponent.addData(camTest.rawData, true);
                            const {
                                exportAcOd,
                                exportAcOs,
                                exportCoOd,
                                exportCoOs,
                                exportAmOd,
                                exportAmOs,
                                exportCo2Od,
                                exportCo2Os,
                                exportLinePoints,
                            } = chartComponent.diagram.state;
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_points.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\ttimestamp(ms)\toculus\tseen/unseen\tT\titem_measured\tdark_pixel\tdark_border\tbright_pixel\tbright_border\tbackground(lux)\tpixels\thertz\n`;
                                        exportLinePoints.forEach(p => {
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${p.timestamp.toFixed(0)}\t${p.oculus}\t${p.pointType}\t${
                                                p.test
                                            }\t${p.itemMeasured.toFixed(3)}\t${p.darkPixelColorLux}\t${p.darkBorderColorLux}\t${p.brightPixelColorLux}\t${
                                                p.brightBorderColorLux
                                            }\t${p.backgroundColorLux}\t${p.pixels}\t${p.hertz.toFixed(3)}\n`;
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${
                                            this.commonHeaderBeginning
                                        }\tvisual_acuity_OD(logMAR)\tvisual_acuity_OS(logMAR)\tcontrast_sensitivity_no_bleach_OD(logCon)\tcontrast_sensitivity_no_bleach_OS(logCon)\tcontrast_sensitivity_2Hzbleach_OD(logCon)\tcontrast_sensitivity_2Hzbleach_OS(logCon)\tcontrast_sensitivity_var_freq_bleach_OD(Hz)\tcontrast_sensitivity_var_freq_bleach_OS(Hz)\n${this.getCommonBeginning(
                                            test,
                                            camTest,
                                            false
                                        )}\t${exportAcOd}\t${exportAcOs}\t${exportCoOd}\t${exportCoOs}\t${exportCo2Od}\t${exportCo2Os}\t${exportAmOd}\t${exportAmOs}\n`;
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async visualFieldMergedZip(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        const target_seen = (data: number): 'unseen' | 'invalid' | 'seen' => {
            switch (data) {
                case 1:
                    return 'unseen';
                case 2:
                    return 'invalid';
                case 3:
                    return 'seen';
            }
        };
        const srt = (flag: 'unseen' | 'invalid' | 'seen', srt: number): number | string => {
            switch (flag) {
                case 'seen':
                    return srt;
                case 'unseen':
                case 'invalid':
                    return 'NaN';
            }
        };

        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            this.visualFieldChartService.setEdits(camTest.edits);
                            this.visualFieldChartService.setCamData(camTest.rawData);
                            const exportData = this.visualFieldChartService.export();
                            //
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_testSettings.tsv`,
                                ((): string => {
                                    try {
                                        const startMessage: {
                                            oculus: OCULUS;
                                            stimuliPositions: number;
                                            training: boolean;
                                            diameter: number;
                                            bgr: number;
                                            bgg: number;
                                            bgb: number;
                                            backgroundLuxlevel: number;
                                            str: number;
                                            stg: number;
                                            stb: number;
                                            fullIntensityLuxLevel: number;
                                            onsetType: ONSET_TYPE;
                                            onsetTime: number;
                                            onsetAdditionalWaitingStatus: boolean;
                                            onsetAdditionalWaitingLength: number;
                                            stFlickerStatus: boolean;
                                            stFlickerFrequency: number;
                                            greenDotAnimationStatus: boolean;
                                            greenDotAnimationConcentric: number;
                                            color: boolean;
                                            movement: boolean;
                                            size: boolean;
                                            reading: boolean;
                                            isDilated: boolean;
                                        } = exportData.setting;
                                        console.log(startMessage);

                                        let content: string = `${
                                            this.commonHeaderBeginning
                                        }\tsph_OD\tshp_OS\tcyl_OD\tcyl_OS\taxis_OD\taxis_OS\tCAM_lens_OD\tCAM_lens_OS\tpupil_distance\tLH(mm)\tis_dilated\tn_of_stimuliPositions\toculus\tstimulus_diameter\tadd_waiting\tgreen_dot_animation\tcolor_checkbox\tmovement_checkbox\tsize_checkbox\treading_checkbox\tRGB_background_R\tRGB_background_G\tRGB_background_B\tbackground_intensity(lux)\tfull_intensity_stimulus_R\tfull_intensity_stimulus_G\tfull_intensity_stimulus_B\tfull_intensity_stimulus(lux)\tstimulus_size\tonset\tonset_time\ttraining_option\tstimulus_flicker(Hz)\n${this.getCommonBeginning(
                                            test,
                                            camTest,
                                            false
                                        )}\t${test.examination.patient.lensValues.sphere.OD.value}\t${test.examination.patient.lensValues.sphere.OS.value}\t${test.examination
                                            .patient.lensValues.cylinder.OD.value || 'no data'}\t${test.examination.patient.lensValues.cylinder.OS.value || 'no data'}\t${test
                                            .examination.patient.lensValues.axis.OD.value || 'no data'}\t${test.examination.patient.lensValues.axis.OS.value || 'no data'}\t${
                                            test.examination.lenses.OD.value
                                        }\t${test.examination.lenses.OS.value}\t${test.examination.patient.pupilPupilDistance.value}\t${
                                            test.examination.lenses.lensholder.value
                                        }\t${startMessage.isDilated || 'no data'}\t${startMessage.stimuliPositions}\t${startMessage.oculus}\t${startMessage.diameter}\t${
                                            startMessage.onsetAdditionalWaitingStatus
                                        } ${startMessage.onsetAdditionalWaitingLength}\t${startMessage.greenDotAnimationStatus} ${startMessage.greenDotAnimationConcentric}\t${
                                            startMessage.color
                                        }\t${startMessage.movement}\t${startMessage.size}\t${startMessage.reading}\t${startMessage.bgr}\t${startMessage.bgg}\t${
                                            startMessage.bgb
                                        }\t${startMessage.backgroundLuxlevel}\t${startMessage.str}\t${startMessage.stg}\t${startMessage.stb}\t${
                                            startMessage.fullIntensityLuxLevel
                                        }\t${startMessage.size}\t${startMessage.onsetType}\t${startMessage.onsetTime}\t${startMessage.training}\t${startMessage.stFlickerStatus} ${
                                            startMessage.stFlickerFrequency
                                        }\n`;
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_srt_export.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${this.commonHeaderBeginning}\ttarget_x\ttarget_y\tsrt(ms)\ttarget_seen_unseen_invalid\toculus\n`;
                                        exportData.results.forEach(message => {
                                            content += `${this.getCommonBeginning(test, camTest, false)}\t${message.targetx}\t${message.targety}\t${srt(
                                                target_seen(message.seen),
                                                message.srt
                                            )}\t${target_seen(message.seen)}\t${message.targettype ? 'OS' : 'OD'}\n`;
                                        });
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private async functionalScreening(test: TestFrontend, camTests: CamTest[]): Promise<{ content: Blob; filename: string }[]> {
        return from(camTests)
            .pipe(
                concatMap(async camTest => {
                    let zip = this.getCommonFiles(test, camTest);
                    if (camTest.rawData) {
                        try {
                            this.functionalScreeningChartService.clearData();
                            this.functionalScreeningChartService.setCamData(camTest.rawData);
                            const calculatedValues = this.functionalScreeningChartService.export();
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.tsv`,
                                ((): string => {
                                    try {
                                        let content: string = `${
                                            this.commonHeaderBeginning
                                        }\tlensOD\tlensOS\tposition_OD\tposition_OS\tglint_OD(%)\tglint_OS(%)\tglint_noise_horOD(px)\tglint_noise_horOS(px)\tglint_noise_verOD(px)\tglint_noise_verOS(px)\tpupil_OD(%)\tpupil_OS(%)\tpupil_size_noiseOD(mm)\tpupil_size_noiseOS(mm)\tpupil_centre_noise_OD_hor(px)\tpupil_centre_noise_OS_hor(px)\tpupil_centre_noise_OD_ver(px)\tpupil_centre_noise_OS_ver(px)\tDPG_candidates_90%ile_OD\tDPG_candidates_90%ile_OS\tDPG_candidates_50%ile_OD\tDPG_candidates_50%ile_OS\tDPG_candidates_10%ile_OD\tDPG_candidates_10%ile_OS\tBPG_candidates_90%ile_OD\tBPG_candidates_90%ile_OS\tBPG_candidates_50%ile_OD\tBPG_candidates_50%ile_OS\tBPG_candidates_10%ile_OD\tBPG_candidates_10%ile_OS\tDP_contrast_90%ile_OD\tDP_contrast_90%ile_OS\tDP_contrast_50%ile_OD\tDP_contrast_50%ile_OS\tDP_contrast_10%ile_OD\tDP_contrast_10%ile_OS\tBP_contrast_90%ile_OD\tBP_contrast_90%ile_OS\tBP_contrast_50%ile_OD\tBP_contrast_50%ile_OS\tBP_contrast_10%ile_OD\tBP_contrast_10%ile_OS\tMS_st_1_OD\tMS_st_1_OS\tMS_st_2_OD\tMS_st_2_OS\tMS_st_3_OD\tMS_st_3_OS\tMS_st_4_OD\tMS_st_4_OS\tbpg_size_OD\tbpg_size_OS\tdpg_size_OD\tdpg_size_OS\tpx_deg_OD(px)\tpx_deg_OS(px)\tdilated_neg_pos\tdilated(%)\tIOL(%)\tblink_freq(s/blink)\tEM_st_1_OD(%)\tEM_st_1_OS(%)\tEM_st_2_OD(%)\tEM_st_2_OS(%)\tEM_st_3_OD(%)\tEM_st_3_OS(%)\tEM_st_4_OD(%)\tEM_st_4_OS(%)\tpupil_dist\tphoria_OD(px)\tphoria_OS(px)\tstrabismus_OD(px)\tstrabismus_OS(px)\tpupil_test_grade\tVF_test_grade\tpurs_sacc_grade\tnystagmus_grade\n${this.getCommonBeginning(
                                            test,
                                            camTest,
                                            false
                                        )}\t${calculatedValues.lensOD}\t${calculatedValues.lensOS}\t${calculatedValues.position.OD}\t${calculatedValues.position.OS}\t${
                                            calculatedValues.glintsQuantityOD
                                        }\t${calculatedValues.glintsQuantityOS}\t${calculatedValues.averGlintNoiseHorOD}\t${calculatedValues.averGlintNoiseHorOS}\t${
                                            calculatedValues.averGlintNoiseVerOD
                                        }\t${calculatedValues.averGlintNoiseVerOS}\t${calculatedValues.pupilSizeNoiseOD}\t${calculatedValues.pupilSizeNoiseOS}\t${
                                            calculatedValues.pupilCentreHorNoiseOD
                                        }\t${calculatedValues.pupilCentreHorNoiseOS}\t${calculatedValues.pupilCentreVerNoiseOD}\t${calculatedValues.pupilCentreVerNoiseOS}\t${
                                            calculatedValues.dpgCand90ileOD
                                        }\t${calculatedValues.dpgCand90ileOS}\t${calculatedValues.dpgCand50ileOD}\t${calculatedValues.dpgCand50ileOS}\t${
                                            calculatedValues.dpgCand10ileOD
                                        }\t${calculatedValues.dpgCand10ileOS}\t${calculatedValues.bpgCand90ileOD}\t${calculatedValues.bpgCand90ileOS}\t${
                                            calculatedValues.bpgCand50ileOD
                                        }\t${calculatedValues.bpgCand50ileOS}\t${calculatedValues.bpgCand10ileOD}\t${calculatedValues.bpgCand10ileOS}\t${
                                            calculatedValues.dpContr90ileOD
                                        }\t${calculatedValues.dpContr90ileOS}\t${calculatedValues.dpContr50ileOD}\t${calculatedValues.dpContr50ileOS}\t${
                                            calculatedValues.dpContr10ileOD
                                        }\t${calculatedValues.dpContr10ileOS}\t${calculatedValues.bpContr90ileOD}\t${calculatedValues.bpContr90ileOS}\t${
                                            calculatedValues.bpContr50ileOD
                                        }\t${calculatedValues.bpContr50ileOS}\t${calculatedValues.bpContr10ileOD}\t${calculatedValues.bpContr10ileOS}\t${
                                            calculatedValues.pupilsQuantityOD
                                        }\t${calculatedValues.pupilsQuantityOS}\t${calculatedValues.maskSlippages.stage1.OD}\t${calculatedValues.maskSlippages.stage1.OS}\t${
                                            calculatedValues.maskSlippages.stage2.OD
                                        }\t${calculatedValues.maskSlippages.stage2.OS}\t${calculatedValues.maskSlippages.stage3.OD}\t${calculatedValues.maskSlippages.stage3.OS}\t${
                                            calculatedValues.maskSlippages.stage4.OD
                                        }\t${calculatedValues.maskSlippages.stage4.OS}\t${calculatedValues.BPGSize.OD}\t${calculatedValues.BPGSize.OS}\t${
                                            calculatedValues.DPGSize.OD
                                        }\t${calculatedValues.DPGSize.OS}\t${calculatedValues.pixelPerDegOD}\t${calculatedValues.pixelPerDegOS}\t${
                                            calculatedValues.zeroDivadedBy4 < 90 ? 'negative' : 'positive'
                                        }\t${calculatedValues.zeroDivadedBy4}\t${calculatedValues.IOL}\t${Number.parseFloat(calculatedValues.blinkingFrequency)}\t${
                                            calculatedValues.motility.stage1.OD
                                        }\t${calculatedValues.motility.stage1.OS}\t${calculatedValues.motility.stage2.OD}\t${calculatedValues.motility.stage2.OS}\t${
                                            calculatedValues.motility.stage3.OD
                                        }\t${calculatedValues.motility.stage3.OS}\t${calculatedValues.motility.stage4.OD}\t${calculatedValues.motility.stage4.OS}\t${
                                            calculatedValues.pupilDistance
                                        }\t${calculatedValues.phoria.OD.zeroToFirst}\t${calculatedValues.phoria.OS.zeroToFirst}\t${calculatedValues.phoria.OD.secondToThird}\t${
                                            calculatedValues.phoria.OS.secondToThird
                                        }\t${
                                            calculatedValues.zeroDivadedBy4 > 90
                                                ? 'red'
                                                : (calculatedValues.pupilsQuantityOD + calculatedValues.pupilsQuantityOS) / 2 < 85
                                                ? 'orange'
                                                : 'green'
                                        }\t${
                                            (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2 > 95
                                                ? 'green'
                                                : (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2 < 80
                                                ? 'red'
                                                : 'orange'
                                        }\t${
                                            (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2 > 95
                                                ? 'green'
                                                : (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2 < 80
                                                ? 'red'
                                                : 'orange'
                                        }\t${
                                            (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2 > 95
                                                ? 'green'
                                                : (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2 < 80
                                                ? 'red'
                                                : 'orange'
                                        }`;
                                        return content;
                                    } catch (error) {
                                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                                    }
                                })()
                            );
                            const inputForRaw: IRawExportData = {
                                rows: [],
                            };
                            camTest.rawData
                                .filter(cm => cm.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                                .forEach((cm, i, arr) => {
                                    inputForRaw.rows.push({
                                        time: (cm.timestamp - arr[0].timestamp) / 10000,
                                        pupil: {
                                            OD: {
                                                x: cm.pupilxOD,
                                                y: cm.pupilyOD,
                                                diameter: cm.measurementOD,
                                                dpg: {
                                                    x: cm.dpgxOD,
                                                    y: cm.dpgyOD,
                                                },
                                                bpg: {
                                                    x: cm.bpgxOD,
                                                    y: cm.bpgyOD,
                                                },
                                            },
                                            OS: {
                                                x: cm.pupilxOS,
                                                y: cm.pupilyOS,
                                                diameter: cm.measurementOS,
                                                dpg: {
                                                    x: cm.dpgxOS,
                                                    y: cm.dpgyOS,
                                                },
                                                bpg: {
                                                    x: cm.bpgxOD,
                                                    y: cm.bpgyOD,
                                                },
                                            },
                                        },
                                        stimulus: {
                                            OD: {
                                                x: null,
                                                y: null,
                                                diameter: null,
                                                r: null,
                                                g: null,
                                                b: null,
                                            },
                                            OS: {
                                                x: null,
                                                y: null,
                                                diameter: null,
                                                r: null,
                                                g: null,
                                                b: null,
                                            },
                                        },
                                        background: {
                                            OD: {
                                                r: null,
                                                g: null,
                                                b: null,
                                            },
                                            OS: {
                                                r: null,
                                                g: null,
                                                b: null,
                                            },
                                        },
                                    });
                                });
                            this.addRawExport(inputForRaw, zip, test, camTest);
                        } catch (error) {
                            zip.file(
                                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_error_report.tsv`,
                                ((): string => {
                                    return `Error has been occurred during ${this.getTestName(test, false)} test export\n${JSON.stringify(error.stack)}`;
                                })()
                            );
                        }
                    }
                    const content = await new Promise<Blob>(res => {
                        zip.generateAsync({ type: 'blob' }).then(c => {
                            res(c);
                        });
                    });
                    return {
                        content: content,
                        filename: `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_export.zip`,
                    };
                }),
                toArray()
            )
            .toPromise();
    }
    private getCommonFiles(test: TestFrontend, camTest: CamTest): JSZip {
        const zip = new JSZip(),
            rawData: ICamMessage[] = camTest.rawData,
            metadata = rawData && rawData[0].metadata,
            feedbacks = camTest.grades;
        if (metadata) {
            zip.file(
                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_CAM_modules.tsv`,
                ((): string => {
                    try {
                        let array = metadata.split('\n');
                        let array1: {
                            headName: string;
                            content: string;
                        }[] = [];
                        array.forEach(s => {
                            let hv = s.split(' : ');
                            if (hv[0] && hv[1])
                                array1.push({
                                    headName: hv[0],
                                    content: hv[1],
                                });
                        });
                        let content = `${this.commonHeaderBeginning}\t`;
                        for (let index = 0; index < array1.length; index++) {
                            const element = array1[index];
                            content += `${element.headName}`;
                            if (index === array1.length - 1) {
                                content += '\n';
                            } else {
                                content += '\t';
                            }
                        }
                        content += `${this.getCommonBeginning(test, camTest, false)}\t`;
                        for (let index = 0; index < array1.length; index++) {
                            const element = array1[index];
                            content += `${element.content}\t`;
                            if (index === array1.length - 1) {
                                content += '\t';
                            }
                        }
                        return content;
                    } catch (error) {
                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                    }
                })()
            );
        }
        if (feedbacks?.length > 0) {
            zip.file(
                `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_feedbacks.tsv`,
                ((): string => {
                    try {
                        let content: string = `${this.commonHeaderBeginning}\tcreated_at\trecording_grade\tclinical_grade\tapp_crashed\tapp_freezed\tcomment\tauthor`;
                        feedbacks.forEach(f => {
                            content += `\n${this.getCommonBeginning(test, camTest, false)}\t${moment(f.createdAt).format('MDDyyHHmm')}\t${f.recordingGrade}\t${f.clinicalGrade}\t${
                                f.crash
                            }\t${f.freeze}\t${f.comment?.replace(/(\r\n|\n|\r)/gm, '') || ''}\t${f.author.username}`;
                        });
                        return content;
                    } catch (error) {
                        return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                    }
                })()
            );
        }
        zip.file(
            `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_sysInfo.tsv`,
            ((): string => {
                try {
                    return `${
                        this.commonHeaderBeginning
                    }\ttest_date_dd/mm/yyyy\ttime_zone\ttest_time(24)\ttest_type\tbulbiCam_version\tbulbiHub_version\toperating_system\tcamera_serial_no\texaminer_username\n${this.getCommonBeginning(
                        test,
                        camTest,
                        false
                    )}\t${moment(camTest.createdAt).format('M/DD/yy')}\t${test.examination.timeZone || 'no_time_zone'}\t${moment(camTest.createdAt).format(
                        'HH:mm'
                    )}\t${this.getTestName(test, false)}\t${test.examination.CAMversion || 'no_bulbiCam_version'}\t${test.examination.HUBversion || 'no_bulbiHub_version'}\t${
                        camTest.OSversion
                    }\tno_camera_serial_no\t${camTest.username}`;
                } catch (error) {
                    return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                }
            })()
        );
        zip.file(
            `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}_patientInfo.tsv`,
            ((): string => {
                try {
                    let content: string = `${this.commonHeaderBeginning}\tgender\trace/ethnicity\n${this.getCommonBeginning(test, camTest, false)}\t${
                        test.examination.patient.gender.value
                    }\t${test.examination.patient.race.value}\t${test.examination.patient.diagnosis.value}\n\npast_ocular_history\n`;

                    test.examination.patient.pastOcularHistory.map(poh => {
                        content += `${poh.condition.value}\t${poh.oculus.value}\t${poh.duration.value}\n`;
                    });

                    content += `\n\nIDC10_diagnoses\n`;
                    test.examination.patient.ICD.filter(data => data.type === IDC_ITEM_TYPE.DIAGNOSIS).map(d => {
                        content += `${((d as unknown) as IIDCDiagnosis).code}\t${((d as unknown) as IIDCDiagnosis).bestMatchText}\n`;
                    });
                    return content;
                } catch (error) {
                    return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                }
            })()
        );
        return zip;
    }
    private getCommonBeginning(test: TestFrontend, camTest: CamTest, isFile: boolean): string {
        const delimiter: string = isFile ? '_' : '\t';
        return `${test.examination.patient.registerID.value}${delimiter}${test.examination.patient.ssn.value}${delimiter}${test.examination.creationDateTime}${delimiter}${moment(
            camTest.createdAt
        ).format('MDDyyHHmm')}`;
    }
    private getTestName(test: TestFrontend, isFilename: boolean): string {
        let name = this.classesProvider.testsProperties.find(t => t.device === test.device && t.type === test.type).name;
        if (test.type === TEST_TYPE.PUPILLARY_EVALUATION2) return 'Pupil2';
        else return isFilename ? name.replace(' ', '_') : name;
    }
    private addRawExport(input: IRawExportData, zip: JSZip, test: TestFrontend, camTest: CamTest, subtype?: string): void {
        const nd = function(input: number | null): string {
            return typeof input === 'number' ? input.toString() : 'no data';
        };
        zip.file(
            `${this.getCommonBeginning(test, camTest, true)}_${this.getTestName(test, true)}${subtype ? '_' + subtype : ''}_raw.tsv`,
            ((): string => {
                try {
                    let content: string = `time\tOD_pup_x\tOD_pup_y\tOD_pup_d\tOD_dpg_x\tOD_dpg_y\tOD_bpg_x\tOD_bpg_y\tOS_pup_x\tOS_pup_y\tOS_pup_d\tOS_dpg_x\tOS_dpg_y\tOS_bpg_x\tOS_bpg_y\tst_OD_x\tst_OD_y\tst_OD_d\tst_OD_r\tst_OD_g\tst_OD_b\tst_OS_x\tst_OS_y\tst_OS_d\tst_OS_r\tst_OS_g\tst_OS_b\tback_OD_r\tback_OD_g\tback_OD_b\tback_OS_r\tback_OS_g\tback_OS_b\n`;
                    input.rows.forEach(r => {
                        content += `${r.time}\t${nd(r.pupil.OD.x)}\t${nd(r.pupil.OD.y)}\t${nd(r.pupil.OD.diameter)}\t${nd(r.pupil.OD.dpg.x)}\t${nd(r.pupil.OD.dpg.y)}\t${nd(
                            r.pupil.OD.bpg.x
                        )}\t${nd(r.pupil.OD.bpg.y)}\t${nd(r.pupil.OS.x)}\t${nd(r.pupil.OS.y)}\t${nd(r.pupil.OS.diameter)}\t${nd(r.pupil.OS.dpg.x)}\t${nd(r.pupil.OS.dpg.y)}\t${nd(
                            r.pupil.OS.bpg.x
                        )}\t${nd(r.pupil.OS.bpg.y)}\t${nd(r.stimulus.OD.x)}\t${nd(r.stimulus.OD.y)}\t${nd(r.stimulus.OD.diameter)}\t${nd(r.stimulus.OD.r)}\t${nd(
                            r.stimulus.OD.g
                        )}\t${nd(r.stimulus.OD.b)}\t${nd(r.stimulus.OS.x)}\t${nd(r.stimulus.OS.y)}\t${nd(r.stimulus.OS.diameter)}\t${nd(r.stimulus.OS.r)}\t${nd(
                            r.stimulus.OS.g
                        )}\t${nd(r.stimulus.OS.b)}\t${nd(r.background.OD.r)}\t${nd(r.background.OD.g)}\t${nd(r.background.OD.b)}\t${nd(r.background.OS.r)}\t${nd(
                            r.background.OS.g
                        )}\t${nd(r.background.OS.b)}\n`;
                    });
                    return content;
                } catch (error) {
                    return `Error has been occurred\n${JSON.stringify(error.stack)}`;
                }
            })()
        );
    }
}

export class Pager {
    private document: jsPDF;
    private currentPage: number;
    private currentLine: number;
    private usersFontSize: number;
    private readonly pageHeight: number;
    private readonly pageWidth: number;
    private readonly logo: HTMLImageElement;
    private readonly logoRatio: number;
    private readonly logoHeight: number;
    private readonly logowidth: number;
    private readonly titleFontSize: number;
    private readonly headerHeight: number;
    private readonly footerHeight: number;
    constructor(document: jsPDF, logo: HTMLImageElement) {
        this.document = document;
        this.logo = logo;
        this.currentPage = 1;
        this.currentLine = 0;
        this.usersFontSize = 12;
        this.pageHeight = this.document.internal.pageSize.getHeight();
        this.pageWidth = this.document.internal.pageSize.getWidth();
        this.logoRatio = 5.882352941;
        this.logoHeight = 5;
        this.logowidth = this.logoHeight * this.logoRatio;
        this.titleFontSize = 24;
        this.headerHeight = 30;
        this.footerHeight = 15;
        this.currentLine = this.headerHeight;
    }
    public get line(): number {
        return this.currentLine;
    }
    public set line(height: number) {
        if (this.pageHeight > height + this.footerHeight + this.document.getLineHeight()) {
            this.currentLine = height;
        } else {
            this.nextPage();
        }
    }
    public set fontSize(size: number) {
        this.document.setFontSize(size);
    }
    public set fontStyle(style: string) {
        this.document.setFontStyle(style);
    }
    public setTextColor(r: any, g: number, b: number) {
        this.document.setTextColor(r, g, b);
    }
    public addLine(height: number): void {
        if (this.pageHeight > this.currentLine + this.footerHeight + height) {
            this.currentLine += height;
        } else {
            this.nextPage();
        }
    }
    public nextPage(): void {
        this.document.addPage();
        this.currentPage += 1;
        this.currentLine = 0;
        this.usersFontSize = this.document.getLineHeight();
        this.currentLine = this.headerHeight + this.usersFontSize;
    }
    public addHeader(index: number, patientID: string): void {
        if (index === 1) {
            this.document.setFont('helvetica');
            this.document.setFontStyle('bold');
            this.document.setFontSize(this.titleFontSize);
            this.document.text('Test report', this.pageWidth / 2, this.titleFontSize, {
                align: 'center',
            });
        }
        this.document.setFontSize(8);
        this.document.text('Patient ID: ' + patientID, 5, 8);
        this.document.addImage(this.logo, 'png', this.pageWidth - this.logowidth - 5, 5, this.logowidth, this.logoHeight);
        this.document.line(5, this.headerHeight, this.pageWidth - 5, this.headerHeight);
    }
    public addFooter(index: number): void {
        this.document.setFontSize(6);
        this.document.line(5, this.pageHeight - this.footerHeight, this.pageWidth - 5, this.pageHeight - this.footerHeight);
        this.document.text('Page: ' + index, this.pageWidth - 5, this.pageHeight - this.footerHeight / 2, {
            align: 'right',
        });
        this.document.setFontSize(this.usersFontSize);
    }
    public get margin() {
        return {
            top: this.headerHeight,
            right: 5,
            bottom: this.footerHeight,
            left: 5,
        };
    }
}
