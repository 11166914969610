import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'convergence-start-stop-control',
    template: require('./convergence-test-control.component.html'),
    styles: [require('./convergence-test-control.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class ConvergenceStartStopControlComponent extends BulbicamControlComponent {
    public enable(): void {}
    public disable(): void {}
    public trials: FormControl = new FormControl(2);
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService) {
        super(bulbicamService, configService, socketService, authService);
    }
    private start(type: string): void {
        this.pendingResponse = true;
        this.bulbicamService
            .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, {
                type: type,
                trials: this.trials.value,
                username: this.authService.currentUser.username,
            })
            .subscribe(() => {
                this.pendingResponse = false;
            });
    }
}
