// @ts-nocheck
import * as _ from 'lodash';
import { Target, Scale } from './types';
import { translate } from './util';

export const drawTriangle = 'M 1 0 L -0.5 0.8660254 L -0.5 -0.8660254 z';

export const sdLine = (xScale: Scale) => (d: { sd: number }) => {
    let tickY = 5;
    let ax = xScale(0) - xScale(-d.sd / 2);
    let bx = -ax;
    return `M ${ax} ${-tickY} L ${ax} ${tickY} M ${ax} 0 L ${bx} 0 M ${bx} ${-tickY} L ${bx} ${tickY}`;
};

export const drawArrowTip = (size: number) => {
    let result = '';
    let m = (x: number, y: number) => (result += `M ${x} ${y}`);
    let l = (x: number, y: number) => (result += `L ${x} ${y}`);
    m(-size, -size);
    l(0, 0);
    l(-size, size);

    return result;
};

export const drawArrow = (params: { x0: number; y0: number; dx: number; tipdx: number; tipdy?: number }) => {
    let { x0, y0, dx, tipdx } = params;
    let tipdy = params.tipdy ?? (params.tipdx * 1) / 3;

    let result = '';
    let m = (x: number, y: number) => (result += `M ${x} ${y}`);
    let l = (x: number, y: number) => (result += `L ${x} ${y}`);
    m(x0, y0);

    l(x0 + dx - tipdx, y0);

    l(x0 + dx - tipdx, y0 - tipdy);
    l(x0 + dx, y0);
    l(x0 + dx - tipdx, y0 + tipdy);

    l(x0 + dx - tipdx, y0);

    // l(x0 + dx, y0);

    // l(x0 + dx, y0);
    // l(x0 + dx - tipdx, y0 - tipdy);

    // l(x0 + dx - tipdx, y0 + tipdy);
    // l(x0 + dx, y0);
    // // l(x0, y0);

    result += ' z';

    // m(x0 + dx, y0);
    // l(x0 + dx - tipdx, y0 + tipdy);

    return result;
};
// something different
export const drawArrows1 = () => {
    let result = '';
    let m = (x: number, y: number) => (result += `M ${x} ${y}`);
    let l = (x: number, y: number) => (result += `L ${x} ${y}`);
    m(10, 5);
    l(15, 0);
    l(10, -5);
    l(10, 5);
    return result;
};
export const drawArrows2 = () => {
    let result = '';
    let m = (x: number, y: number) => (result += `M ${x} ${y}`);
    let l = (x: number, y: number) => (result += `L ${x} ${y}`);
    m(-10, 5);
    l(-15, 0);
    l(-10, -5);
    l(-10, 5);
    return result;
};

interface DrawPreview {
    colors: string[];
    strokeWidth: number;
    timesCount: number;
    timesStep: number;
}
export const drawPreview = ({ colors, strokeWidth, timesCount, timesStep }: DrawPreview) => {
    let previewY = -2;
    let previewLineLength = 15;
    let rotate = 'rotate(25)';
    return (target: Target) => {
        let dy = previewLineLength;
        let d = `M0,${-dy} 0,${dy}`;

        _.times(timesCount, (i) => {
            let dx = i * timesStep;
            target
                .selectAll('.preview' + i)
                .data([null])
                .join('path')
                .attr('class', 'preview' + i)
                .attr('d', d)
                .attr('stroke-width', strokeWidth)
                .attr('stroke', colors[i] || colors[0])
                .attr('transform', translate({ x: dx, y: previewY }) + ' ' + rotate);
        });
    };
};

export const drawTick = 'M-0.42791666666666667,0.03791666666666671 L-0.16250000000000003,0.30333333333333334 L0.44958333333333333,-0.30874999999999997';
export const drawCross = 'M-1,-1 L1,1 M-1,1 L1,-1';
let d = 1.4142135;
export const drawStar = `M-1,-1 L1,1 M-1,1 L1,-1 M${-d},0 L${d},0`;

export const drawCrossWithRadius = (r: number) => {
    return `M-${r},-${r} L${r},${r} M-${r},${r} L${r},-${r}`;
};

export function drawBird({ length }: { length: number }) {
    let result = '';
    let m = (x: number, y: number) => (result += `M ${x} ${y}`);
    let l = (x: number, y: number) => (result += `L ${x} ${y}`);
    m(-length, -length);
    l(0, 0);
    l(length, -length);
    return result;
}
