// @ts-nocheck
import { Layout } from './lib';

// eslint-disable-next-line
import { layoutPreview } from './lib';

const GAP = 50
const G = GAP
const MARGIN = 50
const HEADER = 30

// const W = window.screen.width
// const H = window.screen.height
const USED_HEIGHT = 3/4
const W = 1920
const H = 1080 * USED_HEIGHT

const LOGMAR_W = 400

const LEGEND_W = 100
const LEGEND_H = 60

const TEXT_H = 100

export function makeLayout() {
  let layout = new Layout(0, 0, W, H, 'layout');
  layout.takeLeft(MARGIN);
  layout.takeRight(MARGIN);
  layout.takeTop(MARGIN);
  layout.takeBottom(MARGIN);

  let text = layout.takeBottom(TEXT_H)

  let animals = layout.takeLeft(G+G, 'animals')

  let header = layout.takeTop(HEADER, 'header')
  header.takeBottom(G)

  let diagram = layout.takeAll('diagram')
  let logmarImage = diagram.takeRight(LOGMAR_W + G, 'logmarImage')
  logmarImage.takeLeft(G)

  let legend = diagram.copy()
  let l = legend.r - LEGEND_W
  let b = legend.t + LEGEND_H
  let t = legend.t
  let r = legend.r
  legend = new Layout(l, t, r, b, '')

  return {
    layout,

    diagram,
    logmarImage,
    header,
    animals,
    text,

    legend,
  }
}

// // layoutPreview(makeLayout().layout, { fontSize: 50, forceStrength: -10000, forceDistance: 100 });
