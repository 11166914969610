import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { AntSegmentForus3NethraClassicMeasuredDataFrontend } from '../../../measuredData/FORUS/3NethraClassic/antSegmentForus3NethraClassicDataFrontend.class';

export class AntSegmentForus3NethraClassicTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof AntSegmentForus3NethraClassicMeasuredDataFrontend = AntSegmentForus3NethraClassicMeasuredDataFrontend;
    public measurements: AntSegmentForus3NethraClassicMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
