import { FunctionalScreeningBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/functionalScreeningBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { FunctionalScreeningBulbicamTestChartComponent, FunctionalScreeningBulbicamTestRemarksComponent, FunctionalScreeningTestControlsComponent } from '../../../_components';
import { FunctionalScreeningBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/functionalScreeningBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class FunctionalScreeningBulbicamTestFrontend extends FunctionalScreeningBulbicamTest {
    remarks: FunctionalScreeningBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof FunctionalScreeningBulbicamTestRemarksComponent = FunctionalScreeningBulbicamTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof FunctionalScreeningBulbicamTestChartComponent = FunctionalScreeningBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof FunctionalScreeningTestControlsComponent = FunctionalScreeningTestControlsComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new FunctionalScreeningBulbicamTestRemarksFrontend();
    }
}

export interface FunctionalScreeningBulbicamTestFrontend extends TestFrontend {}
applyMixins(FunctionalScreeningBulbicamTestFrontend, [TestFrontend]);
