import * as jsPDF from 'jspdf';
import { TonometryMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/NT530P/tonometryData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { TonometryTestRemarksFrontend } from '../../../test-remarks/NIDEK/NT530P/tonometryTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class TonometryMeasuredDataFrontend extends TonometryMeasuredData implements MeasuredDataFrontend {
    public remarks: TonometryTestRemarksFrontend;
    public OD: RegisterEditFieldFrontend<string>;
    public OS: RegisterEditFieldFrontend<string>;
    constructor() {
        super();
        this.OD = new RegisterEditFieldFrontend();
        this.OS = new RegisterEditFieldFrontend();
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table = [];
        table.push({
            eye: 'OD mm of Hg',
            value: this.OD.value,
        });
        table.push({
            eye: 'OS mm of Hg',
            value: this.OS.value,
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: (document.internal.pageSize.getWidth() / 3) * 2, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values = '' + this.OD.value || 'x';
        formattedTest.OS.values = '' + this.OS.value || 'x';
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
