import { MeasuredData } from '../../measuredData.model';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { I3DScanIvueOctMeasuredData } from '../../../../interfaces/measuredData/IVUE/OCT/3dscan-ivue-oct.measuredData.interface';
import { IvueOctImage } from '../../../iVueOctImage.class';

export abstract class ThreeDScanIvueOctMeasuredData implements MeasuredData, I3DScanIvueOctMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public images: IvueOctImage[];
    constructor() {
        this.sourceDevice = DEVICE.IVUE_OCT;
        this.testType = TEST_TYPE.THREED_SCAN;
        this.images = [];
    }
    public abstract setModel(model: I3DScanIvueOctMeasuredData): void;
    public getModel(): I3DScanIvueOctMeasuredData {
        let model: I3DScanIvueOctMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            images: this.images.map((i) => i.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.images.length === 0;
    }
}
