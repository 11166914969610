import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { IPupillaryEvaluation2BulbicamMeasuredData } from '../../../interfaces/measuredData/BulbiCAM/pupillaryEvaluation2-bulbicam.measuredData.interface';
import { CamTest } from '../../haplotest.class';
import { RegisterEditField } from '../../registerEditField.class';
import { MeasuredData } from '../measuredData.model';

export abstract class PupillaryEvaluation2BulbicamMeasuredData implements MeasuredData, IPupillaryEvaluation2BulbicamMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public haplotests: CamTest[];
    public OD: {
        size: {
            type: RegisterEditField<string>;
        };
        reactionToLight: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
        rapo: {
            type: RegisterEditField<string>;
        };
        pupilMorphology: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
    };
    public OS: {
        size: {
            type: RegisterEditField<string>;
        };
        reactionToLight: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
        rapo: {
            type: RegisterEditField<string>;
        };
        pupilMorphology: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
    };
    public pupilSize: {
        OD: RegisterEditField<number>;
        OS: RegisterEditField<number>;
    };
    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.PUPILLARY_EVALUATION2;
        this.haplotests = [];
    }
    public abstract setModel(model: IPupillaryEvaluation2BulbicamMeasuredData): void;
    public getModel(): IPupillaryEvaluation2BulbicamMeasuredData {
        let model: IPupillaryEvaluation2BulbicamMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            haplotests: this.haplotests.map(htm => htm.model),
            OD: {
                size: {
                    type: this.OD.size.type.getModel(),
                },
                reactionToLight: {
                    type: this.OD.reactionToLight.type.getModel(),
                    select: this.OD.reactionToLight.select.getModel(),
                },
                rapo: {
                    type: this.OD.rapo.type.getModel(),
                },
                pupilMorphology: {
                    type: this.OD.pupilMorphology.type.getModel(),
                    select: this.OD.pupilMorphology.select.getModel(),
                },
            },
            OS: {
                size: {
                    type: this.OS.size.type.getModel(),
                },
                reactionToLight: {
                    type: this.OS.reactionToLight.type.getModel(),
                    select: this.OS.reactionToLight.select.getModel(),
                },
                rapo: {
                    type: this.OS.rapo.type.getModel(),
                },
                pupilMorphology: {
                    type: this.OS.pupilMorphology.type.getModel(),
                    select: this.OS.pupilMorphology.select.getModel(),
                },
            },
            pupilSize: {
                OD: this.pupilSize.OD.getModel(),
                OS: this.pupilSize.OS.getModel(),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.OD.pupilMorphology.select.isEmpty() &&
            this.OD.pupilMorphology.type.isEmpty() &&
            this.OD.rapo.type.isEmpty() &&
            this.OD.reactionToLight.select.isEmpty() &&
            this.OD.reactionToLight.type.isEmpty() &&
            this.OD.size.type.isEmpty() &&
            this.OS.pupilMorphology.select.isEmpty() &&
            this.OS.pupilMorphology.type.isEmpty() &&
            this.OS.rapo.type.isEmpty() &&
            this.OS.reactionToLight.select.isEmpty() &&
            this.OS.reactionToLight.type.isEmpty() &&
            this.OS.size.type.isEmpty() &&
            this.pupilSize.OD.isEmpty() &&
            this.pupilSize.OS.isEmpty() &&
            this.haplotests.length === 0
        );
    }
}
