// @ts-nocheck
import {
  Target,
  Area,
  translate,
  randomColor,
} from '..'

interface Props<P> {
  target: Target
  parts: P[]

  onHover: (part: P) => void
  onLeave: (part: P) => void

  debug?: boolean
}

interface Part {
  area: Area
}

export function HoverLayers<T extends Part>(p: Props<T>) {
  let klass = 'layer'

  p.target
    .selectAll(`.${klass}`)
    .data(p.parts)
    .join('rect')
    .attr('class', klass)
    .attr('width', d => d.area.width())
    .attr('height', d => d.area.height())
    .on('mousemove', d => p.onHover(d))
    .on('mouseleave', d => p.onLeave(d))
    .attr('transform', d => translate({ x: d.area.left, y: d.area.top }))
    .attr('fill', 'transparent')
    .attr('stroke', p.debug ? randomColor() : 'none')
}
