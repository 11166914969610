import { NextsightNexyImageFrontend } from '../../../nextsightNexyImageFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { IPostSegmentNextsightNexyMeasuredData } from '../../../../../../../common/interfaces/measuredData/NEXTSIGHT/NEXY/post-segment-nextsight-nexy.measuredData.interface';
import { PostSegmentNextsightNexyMeasuredData } from '../../../../../../../common/models/measuredData/NEXTSIGHT/NEXY/postSegmentNextsightNexyData.model';
import { OCULUS } from '../../../../../../../common/enums/oculus.enum';
import { CellHookHandler } from 'jspdf-autotable';
import { PostSegmentNextsightNexyTestRemarksFrontend } from '../../../test-remarks/NEXTSIGHT/NEXY/postSegmentNextsightNexyTestRemarksFrontend.class';

export class PostSegmentNextsightNexyMeasuredDataFrontend extends PostSegmentNextsightNexyMeasuredData implements MeasuredDataFrontend {
    public remarks: PostSegmentNextsightNexyTestRemarksFrontend;
    public images: NextsightNexyImageFrontend[];
    constructor() {
        super();
        this.images = [];
    }
    public setModel(model: IPostSegmentNextsightNexyMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.yearOfBirth = model.yearOfBirth;
        this.gender = model.gender;
        this.race = model.race;
        this.name = model.name;
        this.lastName = model.lastName;
        this.code = model.code;
        this.images = model.images.map(m => {
            const i = new NextsightNexyImageFrontend();
            i.setModel(m);
            return i;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        let table = [];
        let ODimages: NextsightNexyImageFrontend[] = this.images.filter(i => i.eye === OCULUS.OD),
            OSimages: NextsightNexyImageFrontend[] = this.images.filter(i => i.eye === OCULUS.OS),
            length: number = ODimages.length > OSimages.length ? ODimages.length : OSimages.length;
        if (ODimages.length > 0 || OSimages.length > 0) {
            for (let index = 0; index < length; index++) {
                const ODimage: NextsightNexyImageFrontend = ODimages[index],
                    OSimage: NextsightNexyImageFrontend = OSimages[index];
                if (ODimage) {
                    await ODimage.fetchImage(fileService);
                }
                if (OSimage) {
                    await OSimage.fetchImage(fileService);
                }

                let image: NextsightNexyImageFrontend = ODimage || OSimage,
                    ratio: number = await new Promise<number>(res => {
                        let img = new Image();
                        img.onload = () => {
                            let height = img.height;
                            let width = img.width;
                            res(width / height);
                        };
                        img.src = image.image.imageContent;
                    });
                table.push({
                    OD: {
                        content: '',
                        styles: {
                            minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                            minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                        },
                    },
                    OS: {
                        content: '',
                        styles: {
                            minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                            minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                        },
                    },
                });
                table.push({
                    OD: { content: ODimages[index] ? ODimages[index].image.remark.value : '', styles: { halign: 'left' } },
                    OS: { content: OSimages[index] ? OSimages[index].image.remark.value : '', styles: { halign: 'left' } },
                });
            }
            let ODindex: number = 0,
                OSindex: number = 0;
            (document as any).autoTable({
                body: table,
                startY: pager.line,
                pageBreak: 'auto',
                theme: 'grid',
                margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                didDrawCell: <CellHookHandler>(data => {
                    if (data.section === 'body' && data.row.index % 2 === 0 && data.column.dataKey === 'OD' && ODimages[ODindex]) {
                        document.addImage(ODimages[ODindex].image.imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                        ODindex++;
                    } else if (data.section === 'body' && data.row.index % 2 === 0 && data.column.dataKey === 'OS' && OSimages[OSindex]) {
                        document.addImage(OSimages[OSindex].image.imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                        OSindex++;
                    }
                }),
                didDrawPage: () => {},
            });
            pager.line = (document as any).lastAutoTable.finalY;
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: this.images.filter(i => i.eye === OCULUS.OD).map(i => i.image.imageUrl),
            },
            OS: {
                values: '',
                images: this.images.filter(i => i.eye === OCULUS.OS).map(i => i.image.imageUrl),
            },
            OU: {
                values: '',
                images: [],
            },
        };
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.images.map(ni => ni.fetchImage(fileService)));
    }
}
