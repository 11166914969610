import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../../_services/general/modal.service';

@Component({
    selector: 'confirmation',
    template: require('./confirmation.component.html'),
    styles: [require('./confirmation.component.scss')],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
    @HostBinding('class.isVisible') text: string;
    private subscription: Subscription[] = [];

    constructor(private modalService: ModalService) {}

    ngOnInit() {
        this.subscription.push(this.modalService.confirmationText$.subscribe((t) => (this.text = t)));
    }

    ngOnDestroy() {
        this.subscription.map((s) => s.unsubscribe());
    }

    ok(): void {
        this.modalService.confirmation = true;
        this.text = null;
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeHandler() {
        if (this.text) {
            this.ok();
        }
    }
}
