import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PachymetryTonorefIIIMeasuredDataFrontend } from '../../../measuredData/NIDEK/TonorefIII/pachymetryTonorefIIIMeasuredDataFrontend.class';

export class PachymetryTonorefIIITestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PachymetryTonorefIIIMeasuredDataFrontend = PachymetryTonorefIIIMeasuredDataFrontend;
    public measurements: PachymetryTonorefIIIMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
