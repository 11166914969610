import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { KeratometryArk1sMeasuredDataFrontend } from '../../../measuredData/NIDEK/ARK1S/keratometryArk1sDataFrontend.class';

export class KeratometryArk1sTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof KeratometryArk1sMeasuredDataFrontend = KeratometryArk1sMeasuredDataFrontend;
    public measurements: KeratometryArk1sMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
