import { ThreeDScanIvueOct_v3217Test } from '../../../../../../../../commonout/classes/tests/IVUE/OCT/3dscanIvueOct_v3217Test';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { ThreeDScanIvueOctv3217TestRemarksComponent } from '../../../../_components';
import { ThreeDScanIcueOctv3217TestRemarksFrontend } from '../../../test-remarks/IVUE/OCT/ThreeDScanIvueOctv3217TestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class ThreeDScanIvueOctv3217TestFrontend extends ThreeDScanIvueOct_v3217Test {
    remarks: ThreeDScanIcueOctv3217TestRemarksFrontend;
    public readonly remarksComponent: typeof ThreeDScanIvueOctv3217TestRemarksComponent = ThreeDScanIvueOctv3217TestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new ThreeDScanIcueOctv3217TestRemarksFrontend();
    }
}

export interface ThreeDScanIvueOctv3217TestFrontend extends TestFrontend {}
applyMixins(ThreeDScanIvueOctv3217TestFrontend, [TestFrontend]);
