import { IChartEdit } from '../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../commonout/interfaces/charts.model';
import { IGrade } from '../../../commonout/interfaces/grade.interface';
import { IBulbicamTest } from '../../../commonout/interfaces/camTest.interface';

export class CamTest implements IBulbicamTest {
    public metadata: string;
    public createdAt: number;
    public haplotestData: string;
    public edits: IChartEdit[];
    public subtype: string;
    public username: string;
    public OSversion: string;
    public rawData?: ICamMessage[];
    public grades?: IGrade[];
    constructor() {
        this.createdAt = Date.now();
        this.haplotestData = '';
        this.edits = [];
    }
    set model(model: IBulbicamTest) {
        this.metadata = model.metadata;
        this.createdAt = model.createdAt;
        this.haplotestData = model.haplotestData;
        this.edits = model.edits || [];
        this.subtype = model.subtype || '';
        this.username = model.username;
        this.OSversion = model.OSversion;
        if (model.rawData) {
            this.rawData = model.rawData;
        }
        if (model.grades) {
            this.grades = model.grades;
        }
    }
    get model(): IBulbicamTest {
        const model: IBulbicamTest = {
            haplotestData: this.haplotestData,
            createdAt: this.createdAt,
            metadata: this.metadata,
            edits: this.edits,
            subtype: this.subtype,
            username: this.username,
            OSversion: this.OSversion,
        };
        return model;
    }
}
