// @ts-nocheck
import * as _ from 'lodash';
import * as d3 from 'd3';
import chroma from 'chroma-js';

import {
    Header,
    Memo,
    AData,
    AChart2,
    ChartLines,
    AxisLeft,
    AxisRight,
    AxisBottom,
    AxisTop,
    TextBlock,
    ClearTextBlock,
    ChartDots,
    VTable,
    XY,
    Line,
    Target,
    Circle,
    HoverLayers,
    Area,
    ghost,
    drawPreview,
    Render,
    NumberInput,
    BooleanControl,
    Rectangle,
    Brand as B,
} from '../../lib';

import { Place, Targets, State, Protocol, makeLayout, FakeData } from './index';
import { BaseDiagram } from './abstract';
// import { DataSet } from './data/State'
import { DataPoint } from './data/Protocol';
import { getColor } from './ui/colorScale';
import { USE_FAKE_DATA, OD, TRAIN_OD, OS, TRAIN_OS, LOGMAR_TO_VIRTUAL, LOGMAR_VIRTUAL_DOMAIN, LOGMAR_TICKS, I_ACUITY, I_CONTRAST, I_FREQ, I_CONTRAST2 } from './constants';
import { translate, doTranslate, nonSelectable } from '../../lib/util';
import { DataSet, ACUITY, CONTRAST, FREQ, PUPIL, CONTRAST2, lastValues } from './data/State';

import { style, text } from 'd3';

// const STIM_AXIS = 'shown numbers: stimulus distance, mm.'
const STIM_AXIS = 'stimulus distance, mm.';
// const INITIAL_TEST_DURATION = 60 // sec

const GRAY = 'gray';

const EMPTY_LINE = [
    { x: 0, y: 0, sign: '' },
    { x: 0, y: 0, sign: '' },
];

export interface Props {
    place: Place;
}

// NOTE: refactoring ideas
// - split targets from svg setup or even modes setup
// - ui state / data state though
// - find where to use memo, where to use invalidate

let ROW_SIZE = 50; // table

export class Diagram extends BaseDiagram<State, Protocol> {
    cache = new Memo();
    memoize(name: string, fn: any) {
        return this.cache.access(name, fn);
    }

    linkIsActivated: boolean = false;

    context = this.params === undefined ? null : this.renderingContext();

    reset() {
        this.state = new State();
        this.protocol = new Protocol(this.state);
    }

    renderingContext() {
        let lo = makeLayout();
        let mode = 0;
        let g = new Targets({
            place: this.params.place,
            layout: lo.layout,
            state: { previousMode: 0 },
            mode,
        });
        let svg = this.params.place.svgTarget;

        let ds = this.state.viewDataSets;

        return {
            lo,
            g,
            ds,
            svg,
        };
    }

    // prepareOtherContext() {
    //   this.seenChartAData = this.makeSeenChartAData
    // }
    seenChartAData: any;

    // get makeSeenChartAData() {
    //   let { lo, g, ds } = this.context

    //   let points = _.flatten(ds.map(x => x.dataPoints))
    //   let series = [{
    //     id: 0,
    //     dataPoints: points
    //   }]
    //   let aData = AData<any>({
    //     series,
    //     positioning: x => this.establishZero(x.seenPoint),
    //     coloring: x => '',
    //   })
    //   return aData
    // }

    renderSeenChart() {
        let { lo, g, ds } = this.context;

        // let ctx = this.seenChartAData

        let points = _.flatten(ds.map((x) => x.dataPoints));

        let series = [
            {
                id: 0,
                dataPoints: points,
            },
        ];
        let aData = AData<any>({
            series,
            nice: false,
            positioning: (x) => this.establishZero(x.seenPoint),
            coloring: (x) => '',
        });
        this.seenChartAData = aData;

        if (this.state.done) {
            let ctx = AChart2<any>({
                aData,
                target: g.g,
                area: lo.seen,
            });

            let classColor = this.state.ui.showPupil ? 'sWhite2' : 'sTransparent';

            ChartLines({
                ...ctx,
                // styleParams: { override: { class: 'sContrast', } },
                styleParams: { override: { class: classColor, lineSize: 0.5 } },
    
                target: g.g,
            });
    
            AxisTop({
                ...ctx,
                // grid: true,
                ticks: 0,
                // moveOut: 5,
    
                area: lo.seen,
                target: g.g,
                // styleParams: { override: { fontColor: GRAY } },
            });
        }
        // if (this.state.ui.showPupil) {
        //   let x = g.g
        // } else {
        // AxisRight({
        //   ...ctx,
        //   area: lo.seen,
        //   target: g.g,
        //   styleParams: { override: { fontColor: GRAY } },
        // })
        // }
        // AxisBottom({
        //   ...ctx,
        //   grid: true,
        //   // ticks: 0,
        //   // moveOut: 5,

        //   area: lo.seen,
        //   target: g.g,
        // })
        
        // TextBlock({
        //   text: '↓loosing target',
        //   alignLeft: true,
        //   alignBottom: true,
        //   area: lo.seen,
        //   target: g.g,
        // })
        // let series2 = [{
        //   id: 0,
        //   dataPoints: points.map(x => x.seenPoint)
        // }]
        // let r3 = new Render({
        //   series: series2,
        //   area: lo.seen,
        //   g,
        // })
        // r3.aLine({ y: this.state.threshold, size: 1 })
    }

    establishZero = (x: XY) => {
        return x;
        // return {
        //   ...x,
        //   x: x.x - this.state.actualZeroTime
        // }
    };

    mainLinesAData: any;
    acuityAData: any;
    contrastAData: any;
    contrastAData2: any;
    amdAData: any;
    logmarVirtualScale: any;
    renderMainLines() {
        let { lo, g, ds } = this.context;
        let area = lo.main;

        this.logmarVirtualScale = d3
            .scaleLinear()
            .domain(LOGMAR_VIRTUAL_DOMAIN)
            .range([lo.main.rangeY()]);

        let series = this.state.viewMainLines();
        let aData = AData<any>({
            series,
            positioning: (x) => this.establishZero(x),
            coloring: (x, ds: any) => ds.color,
            // yFrom: 0,
            // nice: false,
            // xFrom: 0,
            // xBaseDomain: [0, INITIAL_TEST_DURATION],
        });
        aData = { ...aData, xScale: this.seenChartAData.xScale };

        let ctx = AChart2<any>({
            aData,
            target: g.g,
            area,
        });
        // let upperAData = aData
        // NOTE: worst code ever

        [ACUITY, CONTRAST, FREQ, CONTRAST2].forEach((group) => {
            const marginFactor = 310;

            let i = group.type;

            let series = this.state.viewMainLines(group.type);

            if (i === I_ACUITY) {
                series = series.map((x) => {
                    return {
                        ...x,
                        dataPoints: x.dataPoints.map((x) => {
                            return {
                                ...x,
                                y: LOGMAR_TO_VIRTUAL(x.y),
                            };
                        }),
                    };
                });
            }
            let aData = AData<any>({
                series,
                positioning: (x) => this.establishZero(x),
                coloring: (x, ds: any) => ds.color,
                yFrom: group.viewRange[0],
                yTo: group.viewRange[1],
                // yFrom: 0,
                // nice: false,
                // xFrom: 0,
                // xBaseDomain: [0, INITIAL_TEST_DURATION],
            });
            aData = { ...aData, xScale: this.seenChartAData.xScale };

            if (i === I_ACUITY) {
                aData = { ...aData, yScale: this.logmarVirtualScale };
                this.acuityAData = aData;
            } else if (i === I_CONTRAST) {
                let yScale = d3
                    .scalePow() // NOTE: copy-paste
                    .domain(group.viewRange)
                    .range(area.rangeY())
                    .exponent(0.25);
                aData = {
                    ...aData,
                    yScale,
                };
                // aData = { ...aData,
                //   yScale: this.contrastAData.yScale,
                // }
                this.contrastAData = aData;
            } else if (i === I_CONTRAST2) {
                let yScale = d3
                    .scalePow() // NOTE: copy-paste
                    .domain(group.viewRange)
                    .range(area.rangeY())
                    .exponent(0.25);
                aData = {
                    ...aData,
                    yScale,
                };
                this.contrastAData2 = aData;
            } else if (i === I_FREQ) {
                this.amdAData = aData;
            }
            if (this.state.done) {
                let ctx = AChart2<any>({
                    aData,
                    target: g.g,
                    area,
                });
            }
            

            // ChartLines({
            //     ...ctx,
            //     target: g.g,
            // });
            if (i === I_FREQ) {
                let dx = aData.xScale(this.state.amdInterval[0]) - aData.xScale(0);

                if (!this.state.showFixedContrast) {
                    AxisLeft({
                        ...ctx,
                        arrow: group.arrow,
                        fromTest: 'ACOLAPT',
                        scale: {
                            y: d3
                                .scaleLinear()
                                .domain([5, 0])
                                .range(area.rangeYr()),
                        },
                        area: area.slide(marginFactor * 3, 0),
                        target: g.g,
                    });
                } else if (this.state.testsExist.bleachRecovery) {
                    AxisLeft({
                        ...ctx,
                        arrow: group.arrow,
                        fromTest: 'ACOLAPT',
                        scale: {
                            y: d3
                                .scaleLinear()
                                .domain([5, 0])
                                .range(area.rangeYr()),
                        },
                        area: area.slide(dx, 0),
                        target: g.g,
                    });
                }
                // AxisRight({
                //   ...ctx,
                //   area: area, //.slide(-i * 60, 0),
                //   target: g.g,
                //   arrow: group.arrow,
                // })
            } else if ((i === I_CONTRAST) || (i === I_CONTRAST2)) {
                if (this.state.testsExist.contrastWithoutBleach) {
                    let interval;
                    if (i === I_CONTRAST) {
                        interval = this.state.contrastInterval;
                    } else {
                        interval = this.state.contrastInterval2;
                    }
    
                    let dx = aData.xScale(interval[0]) - aData.xScale(0);
    
                    AxisLeft({
                        ...ctx,
                        arrow: group.arrow,
                        fromTest: 'ACOLAPT',
                        scale: {
                            y: d3
                                .scaleLinear()
                                .domain([0, 1.5])
                                .range(area.rangeYr()),
                        },
                        area: area.slide(dx, 0),
                        target: g.g,
                    });
                }
                else {
                    if (i === I_CONTRAST) {
                        AxisLeft({
                            ...ctx,
                            arrow: group.arrow,
                            fromTest: 'ACOLAPT',
                            scale: {
                                y: d3
                                    .scaleLinear()
                                    .domain([0, 1.5])
                                    .range(area.rangeYr()),
                            },
                            area: area.slide(marginFactor, 0),
                            target: g.g,
                        });
                    }
                    else if (i === I_CONTRAST2) {
                        AxisLeft({
                            ...ctx,
                            arrow: group.arrow,
                            fromTest: 'ACOLAPT',
                            scale: {
                                y: d3
                                    .scaleLinear()
                                    .domain([0, 1.5])
                                    .range(area.rangeYr()),
                            },
                            area: area.slide(marginFactor * 2, 0),
                            target: g.g,
                        });
                    }
                }
                
            } else if (i === I_ACUITY) {
                if (this.state.testsExist.visualAcuity) {
                    AxisLeft({
                        ...ctx,
                        arrow: group.arrow,
                        fromTest: 'ACOLAPT',
                        scale: {
                            y: d3
                                .scaleLinear()
                                .domain([0, 1.5])
                                .range(area.rangeYr()),
                        },
                        area: area.slide(-i * 60, 0),
                        target: g.g,
                    });
                }
                else {
                    AxisLeft({
                        ...ctx,
                        arrow: group.arrow,
                        fromTest: 'ACOLAPT',
                        scale: {
                            y: d3
                                .scaleLinear()
                                .domain([0, 1.5])
                                .range(area.rangeYr()),
                        },
                        area: area.slide(-i * 60, 0),
                        target: g.g,
                    });
                }
            }
        });

        if (!this.state.done) {
            AxisBottom({
                ...ctx,
                grid: true,
                scale: {
                    x: d3
                        .scaleLinear()
                        .domain([0, 350])
                        .range(area.rangeX()),
                },
                arrow: 'test time, sec',
    
                area,
                target: g.g,
            });
            const startMarginX = -445;
            const textMarginFactorX = 300;
            const textMarginFactorY = -400;
    
            let texts = [
                {
                    text: `Visual acuity`, //\nNorm > 0.9`,
                    area: area.slide(startMarginX, textMarginFactorY),
                    target: g.g,
                    remove: !this.state.testsExist.visualAcuity,
                    styleParams: { override: { fontWeight: 900, fontSize: 19 } },
                },
                {
                    text: `Contrast sensitivity`, //\nNorm > 1.8`,
                    area: area.slide(startMarginX + textMarginFactorX, textMarginFactorY),
                    target: g.g,
                    remove: !this.state.testsExist.contrastWithoutBleach,
                    styleParams: { override: { fontWeight: 900, fontSize: 19 } },
                },
                {
                    text: `Contrast sensitivity\nVariable frequency strobe`, //\nNorm > 1.8`,
                    area: area.slide(startMarginX + textMarginFactorX * 2, textMarginFactorY),
                    target: g.g,
                    remove: !this.state.testsExist.contrastWithoutBleach,
                    styleParams: { override: { fontWeight: 900, fontSize: 19 } },
                },
                {
                    text: `Contrast sensitivity\n2.0 Hz strobe`, //\nNorm > 1.8`,
                    area: area.slide(startMarginX + textMarginFactorX * 3, textMarginFactorY),
                    target: g.g,
                    remove: !this.state.testsExist.contrastWithoutBleach,
                    styleParams: { override: { fontWeight: 900, fontSize: 19 } },
                },
            ];
    
            let dy = 30;
    
            texts.forEach((textInfo) => {
                TextBlock({
                    ...textInfo,
                    dy
                });
            });
        }

        AxisLeft({
            ...ctx,
            arrow: 'Pupil diameter(mm)',
            fromTest: 'ACOLAPT',
            scale: {
                y: d3
                    .scaleLinear()
                    .domain([1, 7.5])
                    .range(area.rangeYr()),
            },
            area: area.slide(1250, 0),
            target: g.g,
        });

        AxisBottom({
            ...ctx,
            grid: true,
            // arrow: 'test time, sec',

            area,
            target: g.g,
        });

        // ChartLines({
        //   ...ctx,
        //   target: g.g,
        // })
        // AxisLeft({
        //     ...ctx,

        //     // grid: true,
        //     // arrow: 'each stimulus time, sec',
        //     scale: {
        //         y: d3
        //             .scaleLinear()
        //             .domain([0, 1])
        //             .range(area.rangeYr()),
        //     } as any,
        //     // arrow: 'logmar',
        //     ticks: 0,

        //     area,
        //     target: g.g,
        // });

        // AxisRight({
        //   ...ctx,

        //   // arrow: 'each stimulus time, sec',
        //   // scale: {
        //   //   y: d3.scaleLinear().domain([0, 1]).range(area.rangeYr())
        //   // } as any,
        //   // arrow: 'pupil,mm',
        //   // grid: true,
        //   // ticks: 0,

        //   area,
        //   target: g.g,
        // })
        

        this.mainLinesAData = aData;
    }

    renderLostMarks() {
        let { lo, g, ds } = this.context;
        let parentAData = this.mainLinesAData;

        // let series = this.state.viewLostMarks()
        // let aData = AData<any>({
        //   series,
        //   positioning: x => this.establishZero(x),
        //   coloring: (x,ds: any) => ds.color,
        // })
        // aData = { ...aData,
        //   yScale: parentAData.yScale,
        //   xScale: parentAData.xScale,
        // }
        // let ctx = AChart2<any>({
        //   aData,
        //   target: g.g,
        //   area: lo.main,
        // })
        // NOTE: worst code ever
        [ACUITY, CONTRAST, FREQ, CONTRAST2].forEach((group) => {
            let i = group.type;
            let series = this.state.viewLostMarks(group.type);

            // if (i === I_ACUITY) {
            //     series = series.map((x) => {
            //         return {
            //             ...x,
            //             dataPoints: x.dataPoints.map((x) => {
            //                 return {
            //                     ...x,
            //                     y: LOGMAR_TO_VIRTUAL(x.y),
            //                 };
            //             }),
            //         };
            //     });
            // }

            let aData = AData<any>({
                series,
                positioning: (x) => this.establishZero(x),
                coloring: (x, ds: any) => ds.color,
                yFrom: group.viewRange[0],
                yTo: group.viewRange[1],
            });
            aData = {
                ...aData,
                // yScale: parentAData.yScale,
                xScale: parentAData.xScale,
                // yScale: this.logmarVirtualScale,
            };

            if (i === I_ACUITY) {
                aData = {
                    ...aData,
                    yScale: d3
                        .scaleLinear()
                        .domain([0, 1.5])
                        .range([lo.main.rangeY()]),
                };
            } else if (i === I_CONTRAST) {
                aData = {
                    ...aData,
                    yScale: d3
                        .scaleLinear()
                        .domain([0, 1.5])
                        .range([lo.main.rangeY()]),
                };
            } else if (i === I_FREQ) {
                aData = {
                    ...aData,
                    yScale: d3
                        .scaleLinear()
                        .domain([5, 0])
                        .range([lo.main.rangeY()]),
                };
            } else if (i === I_CONTRAST2) {
                aData = {
                    ...aData,
                    yScale: d3
                        .scaleLinear()
                        .domain([0, 1.5])
                        .range([lo.main.rangeY()]),
                };
            }

            if (this.state.done) {
                let ctx = AChart2<any>({
                    aData,
                    target: g.g,
                    area: lo.main,
                });

                ChartDots({
                    ...ctx,
                    target: g.g,
                    styleParams: {
                        override: {
                            // dotRadius: 10, lineSize: 7,
                            dotRadius: 8,
                            fillColor: 'fill',
                        },
                    },
                });
            }

            
        });
    }

    renderRndInfo() {
        let { lo, g, ds } = this.context;

        const target = g.g.append('g').attr('class', 'text-wrapper');
        target
            .append('text')
            .text('Show doctor info')
            .attr('class', 'diagram-link-text')
            .attr('transform', 'translate(150, 1060)')
            .style('font-size','24px')
            .on('mousedown', d => this.onRndInfoClick(target));
    }

    onRndInfoClick(target) {
        this.linkIsActivated = !this.linkIsActivated;
        let { lo, g, ds, svg } = this.context;
        if (this.linkIsActivated) {
            let text = '';
            if (this.state.info) {
                text = this.state.info.join('\n');
            }

            lo.info.top += 100;
            TextBlock({
                text,
                target: g.g,
                area: lo.info,
                alignLeft: true,
                alignTop: true,
                class: 'text-settings'
            });
        }
        else {
            ClearTextBlock({
                text,
                target: g.g,
                area: lo.info,
                alignLeft: true,
                alignTop: true,
                class: '.text-settings'
            });
            lo.info.top -= 100;
        }
    }

    renderSections() {
        let { lo, g, ds } = this.context;
        let relatedAData = this.mainLinesAData;

        let { xScale } = relatedAData;
        let area = lo.sections;
        let yScale = d3
            .scaleLinear()
            .domain([0, 1])
            .range(area.rangeY());
        yScale.clamp(true);

        interface Section {
            eye: number;
            tl: XY;
            br: XY;
            text: string;
        }

        let sections = [] as Section[];
        let fx = this.establishZero;
        ds.forEach((x, i) => {
            let topY = x.eye === OD || x.eye === TRAIN_OD ? 0 : 0.5;
            let bottomY = topY + 0.5;
            // let start = x.dataPoints[0]
            // let end = _.last(x.dataPoints)
            // if (!start || !end) return

            let startX = x.startRelTest;
            let endX = x.endRelTest;

            if (!endX) return;

            let text = Math.floor(i / 2) + 0 + '';
            sections.push({
                eye: x.eye,
                tl: fx({ x: startX, y: topY }),
                br: fx({ x: endX, y: bottomY }),
                text,
            });

            if (x.eye === TRAIN_OD) {
                topY += 0.5;
                bottomY = topY + 0.5;
                (_.last(sections) as Section).text = 'train';
                sections.push({
                    eye: x.eye,
                    tl: fx({ x: startX, y: topY }),
                    br: fx({ x: endX, y: bottomY }),
                    text: 'bleach',
                });
            }

            if (x.eye === TRAIN_OS) {
                topY -= 0.5;
                bottomY = topY + 0.5;
                (_.last(sections) as Section).text = 'train';
                sections.push({
                    eye: x.eye,
                    tl: fx({ x: startX, y: topY }),
                    br: fx({ x: endX, y: bottomY }),
                    text: 'bleach',
                });
            }
        });

        let classBySectionEyes = (n: number) => {
            let sClass = 'sLayer2';
            let fClass = 'fLayer';
            // if (n === TRAIN_OD || n === TRAIN_OS) {
            //   fClass = 'fGreen2'
            // }

            return `${sClass} ${fClass}`;
        };

        let target = g.g;
        let gs = target
            .selectAll('.sections')
            .data(sections)
            .join('g')
            .attr('class', 'sections');
        gs.selectAll<any, Section>('rect')
            .data((d) => [d])
            .join('rect')
            .attr('x', (d) => xScale(d.tl.x))
            .attr('y', (d) => yScale(d.tl.y))
            .attr('width', (d) => xScale(d.br.x) - xScale(d.tl.x))
            .attr('height', (d) => yScale(d.br.y) - yScale(d.tl.y))
            .attr('class', (d) => classBySectionEyes(d.eye));
        // gs.selectAll<any, Section>('text')
        //     .data((d) => [d])
        //     .join('text')
        //     .text((d) => d.text)
        //     .attr('transform', (d) =>
        //         translate({
        //             x: xScale((d.tl.x + d.br.x) / 2),
        //             y: yScale((d.tl.y + d.br.y) / 2),
        //         })
        //     )
        //     .attr('text-anchor', 'middle')
        //     .attr('dominant-baseline', 'middle');
    }

    renderSecLegend() {
        let { lo, g, ds } = this.context;
        let area = lo.left;

        let topArea = area.takeTop((x) => x.h / 2);

        if (this.state.chosenEyes.OD) {
            renderThis('OD', B.green, topArea);
        }

        if (this.state.chosenEyes.OS) {
            renderThis('OS', B.orange, area);
        }

        function renderThis(text: string, color: string, area: Area) {
            TextBlock({
                text,
                target: g.g,
                area,
            });

            let point = area.mid.dx(30);
            let target = g.g.call(doTranslate(point));
            target.call(
                drawPreview({
                    colors: [color],
                    strokeWidth: 3,
                    timesCount: 1,
                    timesStep: 0,
                })
            );
        }
    }

    applyPoints(fun: (p: XY) => XY, ds: DS<XY>) {
        return {
            ...ds,
            dataPoints: ds.dataPoints.map(fun),
        };
    }

    // renderRndCharts() {
    //   let { lo, g, ds } = this.context

    //   let r1 = new Render({
    //     series: ds.map(x => this.applyPoints(this.establishZero, x.viewv1)),
    //     area: lo.chart1,
    //     g,
    //   })
    //   r1.axisLeft()
    //   r1.axisBottom({ grid: true })
    //   r1.lines({ size: 1 })

    //   let r2 = new Render({
    //     series: ds.map(x => this.applyPoints(this.establishZero, x.viewv2)),
    //     area: lo.chart2,
    //     g,
    //   })
    //   r2.axisLeft()
    //   r2.axisBottom({ grid: true })
    //   r2.lines({ size: 1 })

    //   let r3 = new Render({
    //     series: ds.map(x => this.applyPoints(this.establishZero, x.viewv3)),
    //     area: lo.chart3,
    //     g,
    //   })
    //   r3.axisLeft()
    //   r3.axisBottom({ grid: true })
    //   r3.lines({ size: 1 })
    //   r3.aLine({ y: this.state.threshold, size: 1 })
    // }

    renderPupilLines() {
        let { lo, g, ds } = this.context;
        let series = this.state.viewPupilLines;

        let series2 = series.map((x) => this.applyPoints(this.establishZero, x));
        // if (!this.state.ui.showPupil) series2 = []

        let pupilScale = d3
            .scaleLinear()
            .domain([1, 7.5])
            .range(lo.main.rangeY());

        let r = new Render({
            series: series2,
            area: lo.main,
            g,
        });

        r.coloring((p: any, d: DS<XY>) => (d.id === 0 ? B.green : B.orange));
        r.updateAData();
        r.aData.xScale = this.mainLinesAData.xScale;
        // r.aData.yScale = this.mainLinesAData.yScale
        r.aData.yScale = pupilScale;
        r.updateCtx();
        // odd...

        this.pupilAData = r.aData;

        r.lines({ size: 1 });
        // if (this.state.ui.showPupil)
        // let ticks = this.state.ui.showPupil ? undefined : 0
        // let arrow = this.state.ui.showPupil ? 'pupil, mm' : undefined
        // r.axisRight({ arrow, ticks })
    }
    pupilAData: any;

    renderRndMA() {
        let { lo, g, ds } = this.context;

        NumberInput({
            value: this.state.ma1,
            step: 100,
            min: 1,
            onChange: (x) => {
                this.state.ma1 = x;
                this.recompute();
            },
            target: g.g,
            area: lo.ma1.centeredWithH(30),
        });
        NumberInput({
            value: this.state.ma2,
            step: 100,
            min: 1,
            onChange: (x) => {
                this.state.ma2 = x;
                this.recompute();
            },
            target: g.g,
            area: lo.ma2.centeredWithH(30),
        });
        // NumberInput({
        //   value: this.state.ma3,
        //   step: 100,
        //   min: 1,
        //   onChange: x => {
        //     this.state.ma3 = x
        //     this.recompute()
        //   },
        //   target: g.g,
        //   area: lo.ma3.centeredWithH(30),
        // })
    }

    renderRndThresholdControl() {
        let { lo, g, ds } = this.context;

        NumberInput({
            value: this.state.threshold,
            step: 10,
            min: 0,
            onChange: (x) => {
                this.state.threshold = x;
                this.recomputeSeen();
            },
            target: g.g,
            area: lo.threshold, //.centeredWithH(30),
        });
    }

    renderSettings() {
        let { lo, g, ds } = this.context;

        let classColor = this.state.ui.showPupil ? 'paths sWhite2' : 'paths sTransparent';
        d3.select(`.chart-lines-series`).select('path').attr('class', classColor);

        BooleanControl({
            text: 'Show lost indicators',
            checked: this.state.ui.showPupil,
            onClick: () => {
                this.state.ui.showPupil = !this.state.ui.showPupil;
                this.renderSettings();
            },
            target: g.g,
            area: lo.showPupil, //chart0,
        });
    }

    recompute() {
        this.state.recompute();
        this.refresh();
    }

    recomputeSeen() {
        this.state.recomputeSeen();
        this.refresh();
    }

    renderSegmentsLines() {
        let { lo, g, ds } = this.context;

        let { acuity, contrast, amd, contrast2 } = this.state.results;

        let prepare = (xs: XY[], i: number) => {
            if (i === I_ACUITY) {
                return xs.map((x) => {
                    return {
                        ...x,
                        y: LOGMAR_TO_VIRTUAL(x.y),
                    };
                });
            }
            return xs;
        };
        [acuity, contrast, amd, contrast2].forEach((resultsPart) => {
            let i = resultsPart.type;
            ['od', 'os'].forEach((eye, eyeId) => {
                let aLine = resultsPart[eye];
                let series = [
                    {
                        id: eyeId,
                        dataPoints: prepare(aLine, i),
                    },
                ];
                let series2 = series.map((x) => this.applyPoints(this.establishZero, x));
                let r = new Render({
                    series: series2,
                    area: lo.main,
                    g,
                });

                r.coloring((p: any, d: DS<XY>) => (d.id === 0 ? B.green : B.orange));
                r.updateAData();
                r.aData.xScale = this.mainLinesAData.xScale;
                // if (i === 0) {
                //   aData = { ...aData,
                //     yScale: this.acuityAData.yScale,
                //   }
                // } else if (i === 1) {
                //   aData = { ...aData,
                //     yScale: this.contrastAData.yScale,
                //   }
                // } else {
                //   aData = { ...aData,
                //     yScale: this.amdAData.yScale,
                //   }
                // }
                if (i === I_ACUITY) {
                    r.aData.yScale = this.acuityAData.yScale;
                } else if (i === I_CONTRAST || i === I_CONTRAST2) {
                    r.aData.yScale = this.contrastAData.yScale;
                } else if (i === I_FREQ) {
                    r.aData.yScale = this.amdAData.yScale;
                }
                // r.aData.yScale = this.mainLinesAData.yScale
                r.updateCtx();
                // odd...
                // r.lines({ size: 2, dasharray: '5 5' });
            });
        });

        // let lostMarks = this.state.viewLostMarks
        // let ods = lostMarks.filter(x => x.color === 'green')
        //   .map(d => d.dataPoints[0])
        // let oss = lostMarks.filter(x => x.color === 'orange')
        //   .map(d => d.dataPoints[0])

        // ;[{ points: ods, id: 0 }, { points: oss, id: 1 }]
        // .forEach(({ points, id }) => {
        //   let acuity = points.filter(withinX(this.state.acuityInterval))
        //   let contrast = points.filter(withinX(this.state.contrastInterval))
        //   let amd = points.filter(withinX(this.state.amdInterval))

        //   ;[acuity, contrast, amd].forEach(linePoints => {
        //     let minX = _.min(linePoints.map(x => x.x)) || 0
        //     let maxX = _.max(linePoints.map(x => x.x)) || 0
        //     let y = d3.mean(linePoints.map(x => x.y)) || 0
        //     let aLine = [
        //       { x: minX, y },
        //       { x: maxX, y },
        //     ]
        //     let series = [
        //       {
        //         id,
        //         dataPoints: aLine,
        //       }
        //     ]
        //     let series2 = series.map(x => this.applyPoints(this.establishZero, x))
        //     let r = new Render({
        //       series: series2,
        //       area: lo.main,
        //       g,
        //     })
        //     r.coloring((p:any,d:DS<XY>) => d.id === 0 ? 'green' : 'orange')
        //     r.updateAData()
        //     r.aData.xScale = this.mainLinesAData.xScale
        //     r.aData.yScale = this.mainLinesAData.yScale
        //     r.updateCtx()
        //     // odd...
        //     r.lines({ size: 2, dasharray: '5 5' })
        //   })
        // })
    }

    renderHighlights() {
        let { lo, g, ds } = this.context;

        let target = g.gPopup;
        let area = lo.main;
        let parentAData = this.mainLinesAData;

        let lines = this.state.showRapdLines ? this.state.results.rapd.rapdLine : [];
        let series = [
            {
                id: 0,
                dataPoints: lines,
            },
        ];

        let positioning = (x: any) => x;
        let coloring = (x: XY) => 'white';

        let aData: any = AData<XY>({
            series,
            positioning,
            coloring,
        });
        aData = {
            ...aData,
            // yScale: parentAData.yScale,
            yScale: this.pupilAData.yScale,
            xScale: parentAData.xScale,
        };
        let ctx = AChart2<XY>({
            aData,

            target,
            area,
        });
        ChartLines({
            ...ctx,

            target,
            styleParams: {
                override: {
                    lineSize: 4,
                    class: 'sContrast',
                },
            },
        });
    }

    renderHover() {
        // console.log(this.state.hover)
        this.renderRight();
    }

    hoverAcuity = () => {
        this.state.hover = 'a';
        this.renderHover();
    };
    hoverContrast = () => {
        this.state.hover = 'c';
        this.renderHover();
    };
    hoverDark = () => {
        this.state.hover = 'd';
        this.renderHover();
    };

    showPupilRatio = () => {
        this.state.hover = 'r';
        this.renderHover();
    };
    showRapdLines = () => {
        this.state.hover = 'r';
        this.state.showRapdLines = true;
        this.renderHighlights();
        this.renderHover();
    };
    hideRapdLines = () => {
        this.state.showRapdLines = false;
        this.renderHighlights();
    };

    renderBottomPanels() {
        let { lo, g, ds } = this.context;

        let fromArea = (a: Area) => {
            return {
                target: g.g,
                from: { x: a.l, y: a.t },
                to: { x: a.r, y: a.b },
            };
        };

        // let rects = [
        //     fromArea(lo.rapdResult),
        //     // fromArea(lo.flickerResult),
        // ];

        // rects.forEach((rect, i) => {
        //     Rectangle({
        //         ...rect,
        //         styleParams: { override: { lineKlass: 'fLayer sLayer2' } },
        //     });

        //     let { target } = rect;
        //     let rectEl = target.selectAll('rect');
        //     if (i === 0) {
        //         // rapd
        //         rectEl.on('mouseover', this.showRapdLines);
        //         rectEl.on('mouseout', this.hideRapdLines);
        //     }
        //     if (i === 1) {
        //         // rapd
        //         rectEl.on('mouseover', this.showPupilRatio);
        //     }
        // });

        let slideX = 0;
        let slideY = -30; //-50
        let dy = 50;
        let fontSize = 22;

        // let { acOd, acOs, coOd, coOs, amOd, amOs } = this.state

        let dysf = this.state.results.rapd.dysf;
        let rapd = this.state.results.rapd.value;
        let fliod = this.state.freqPeakOD;
        let flios = this.state.freqPeakOS;

        let f = (x: number) => x.toFixed(2);
        let texts = [
            {
                // text: `Pupli RAPD LOG\nLight on OD - Light on OS: ${f(rapd)}\nNorm < 0.15`,
                text: `Pupli RAPD: ${f(rapd)}\nPupilomotor dysfunction: ${f(dysf)}`, //\nNorm < 0.15`,
                area: lo.rapdResult.slide(slideX, slideY),
            },
            // {
            //   text: `Pupli Flicker\nOD: ${f(fliod.y)}(${f(fliod.x)}hz), OS: ${f(flios.y)}(${f(fliod.x)}hz)`, //\nNorm: 0.03 < x < 0.35`,
            //   area: lo.flickerResult.slide(slideX, slideY),
            // },
        ];

        let textTargets = texts.map(() => g.g);
        texts.forEach((textInfo, i) => {
            TextBlock({
                ...textInfo,
                dy,
                // alignLeft: true,
                target: textTargets[i],
                styleParams: { override: { fontSize } },
            });
        });
        textTargets.forEach((t) => t.call(ghost));
    }

    renderTopPanels() {
        let { lo, g, ds } = this.context;
        let parentAData = this.mainLinesAData;
        let x = parentAData.xScale;
        let hide = !this.state.done;

        let g1r = g.g;
        let g2r = g.g;
        let g3r = g.g;
        let g4r = g.g;
        let g1 = g.g;
        let g12 = g.g;
        let g13 = g.g;
        let g2 = g.g;
        let g22 = g.g;
        let g23 = g.g;
        let g3 = g.g;
        let g32 = g.g;
        let g33 = g.g;
        let g4 = g.g;
        let g42 = g.g;
        let g43 = g.g;

        let area = lo.topPart;
        let y = d3
            .scaleLinear()
            .domain([0, 1])
            .range(area.rangeY());

        let G = 10; // gap/padding/margin
        let y0 = { y: y(0) };
        let y1 = { y: y(1) };

        let ac = this.state.acuityInterval;
        let co = this.state.contrastInterval;
        let am = this.state.amdInterval;
        let co2 = this.state.contrastInterval2;

        let rects = [
            {
                from: { x: x(ac[0]), ...y0 },
                to: { x: x(ac[1]) - G, ...y1 },
                target: g1r,
                n: I_ACUITY,
                remove: !this.state.testsExist.visualAcuity,
            },
            {
                from: { x: x(co[0]) + G, ...y0 },
                to: { x: x(co[1]) - G, ...y1 },
                target: g2r,
                n: I_CONTRAST,
                remove: !this.state.testsExist.contrastWithoutBleach,
            },
            {
                from: { x: x(am[0]) + G, ...y0 },
                to: { x: x(am[1]), ...y1 },
                target: g3r,
                n: I_FREQ,
                remove: !this.state.testsExist.bleachRecovery,
            },
            {
                from: { x: x(co2[0]) + G, ...y0 },
                to: { x: x(co2[1]) - G, ...y1 },
                target: g4r,
                n: I_CONTRAST2,
                remove: !this.state.testsExist.contrastWithBleach,
            },
        ].filter((x) => !x.remove);
        if (hide) rects = [];

        rects.forEach((rect) => {
            let i = rect.n;
            Rectangle({
                ...rect,
                // target: g.g,
                styleParams: { override: { lineColor: 'transparent' } },
            });

            let { target } = rect;

            let rectEl = target.selectAll('rect');
            if (i === I_ACUITY) rectEl.on('mouseover', this.hoverAcuity);
            if (i === I_CONTRAST) rectEl.on('mouseover', this.hoverContrast);
            if (i === I_FREQ) rectEl.on('mouseover', this.hoverDark);
            if (i === I_CONTRAST2) rectEl.on('mouseover', this.hoverContrast);
        });

        let slideX = 0;
        let slideY = -30; //-50
        let dy = 30;
        let fontSize = 16;

        const { exportAcOd, exportAcOs, exportCoOd, exportCoOs, exportAmOd, exportAmOs, exportCo2Od, exportCo2Os } = this.state;
        let f = (x: number, n: number = 1, sign: string = '') => sign + x.toFixed(n);
        let ff = (points: XY[], n: number = 1, sign: string = '') => {
            return f(points[0].y, n, sign);
        };

        let texts = [
            {
                text: `Visual acuity`, //\nNorm > 0.9`,
                area: new Area(x(ac[0]), y(0), x(ac[1]), y(1)).slide(slideX, slideY),
                target: g1,
                remove: !this.state.testsExist.visualAcuity,
                styleParams: { override: { fontWeight: 900, fontSize: 19 } },
            },
            {
                text: `OD: ${exportAcOd}`, //\nNorm > 0.9`,
                area: new Area(x(ac[0]), y(0), x(ac[1]), y(1)).slide(this.state.chosenEyes.OS ? (0 - (x(ac[1]) - x(ac[0])) / 4) - 5 : slideX, slideY + 75),
                target: g12,
                remove: !this.state.testsExist.visualAcuity || !this.state.chosenEyes.OD,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#81c53d' } },
            },
            {
                text: `OS: ${exportAcOs}`, //\nNorm > 0.9`,
                area: new Area(x(ac[0]), y(0), x(ac[1]), y(1)).slide(this.state.chosenEyes.OD ? (x(ac[1]) - x(ac[0])) / 4 : slideX, slideY + 75),
                target: g13,
                remove: !this.state.testsExist.visualAcuity || !this.state.chosenEyes.OS,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#f2994a' } },
            },
            {
                text: `Contrast sensitivity`, //\nNorm > 1.8`,
                area: new Area(x(co[0]), y(0), x(co[1]), y(1)).slide(slideX, slideY),
                target: g2,
                remove: !this.state.testsExist.contrastWithoutBleach,
                styleParams: { override: { fontWeight: 900, fontSize: 19 } },
            },
            {
                text: `OD: ${exportCoOd}`, //\nNorm > 1.8`,
                area: new Area(x(co[0]), y(0), x(co[1]), y(1)).slide(this.state.chosenEyes.OS ? 0 - (x(co[1]) - x(co[0])) / 4 : slideX, slideY + 75),
                target: g22,
                remove: !this.state.testsExist.contrastWithoutBleach || !this.state.chosenEyes.OD,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#81c53d' } },
            },
            {
                text: `OS: ${exportCoOs}`, //\nNorm > 1.8`,
                area: new Area(x(co[0]), y(0), x(co[1]), y(1)).slide(this.state.chosenEyes.OD ? (x(co[1]) - x(co[0])) / 4 : slideX, slideY + 75),
                target: g23,
                remove: !this.state.testsExist.contrastWithoutBleach || !this.state.chosenEyes.OS,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#f2994a' } },
            },
            {
                // text: `Contrast sensitivity\nVariable frequency bleach`, //\nNorm < 0.5`,
                text: `Contrast sensitivity\nVariable frequency strobe`, //\nNorm < 0.5`,
                area: new Area(x(am[0]), y(0), x(am[1]), y(1)).slide(slideX, slideY),
                target: g3,
                remove: !this.state.testsExist.bleachRecovery,
                styleParams: { override: { fontWeight: 900, fontSize: 19 } },
            },
            {
                text: `OD: ${exportAmOd}`, //\nNorm < 0.5`,
                area: new Area(x(am[0]), y(0), x(am[1]), y(1)).slide(this.state.chosenEyes.OS ? 0 - (x(am[1]) - x(am[0])) / 4 : slideX, slideY + 75),
                target: g32,
                remove: !this.state.testsExist.bleachRecovery || !this.state.chosenEyes.OD,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#81c53d' } },
            },
            {
                text: `OS: ${exportAmOs}`, //\nNorm < 0.5`,
                area: new Area(x(am[0]), y(0), x(am[1]), y(1)).slide(this.state.chosenEyes.OD ? (x(am[1]) - x(am[0])) / 4 : slideX, slideY + 75),
                target: g33,
                remove: !this.state.testsExist.bleachRecovery || !this.state.chosenEyes.OS,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#f2994a' } },
            },
            {
                // text: `Contrast sensitivity\n2.0 Hz bleach`, //\nNorm > 1.8`,
                text: `Contrast sensitivity\n2.0 Hz strobe`, //\nNorm > 1.8`,
                area: new Area(x(co2[0]), y(0), x(co2[1]), y(1)).slide(slideX, slideY),
                target: g4,
                remove: !this.state.testsExist.contrastWithBleach,
                styleParams: { override: { fontWeight: 900, fontSize: 19 } },
            },
            {
                text: `OD: ${exportCo2Od}`, //\nNorm > 1.8`,
                area: new Area(x(co2[0]), y(0), x(co2[1]), y(1)).slide(this.state.chosenEyes.OS ? 0 - (x(co2[1]) - x(co2[0])) / 4 : slideX, slideY + 75),
                target: g42,
                remove: !this.state.testsExist.contrastWithBleach || !this.state.chosenEyes.OD,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#81c53d' } },
            },
            {
                text: `OS: ${exportCo2Os}`, //\nNorm > 1.8`,
                area: new Area(x(co2[0]), y(0), x(co2[1]), y(1)).slide(this.state.chosenEyes.OD ? (x(co2[1]) - x(co2[0])) / 4 : slideX, slideY + 75),
                target: g43,
                remove: !this.state.testsExist.contrastWithBleach || !this.state.chosenEyes.OS,
                styleParams: { override: { fontWeight: 900, fontSize: 19, fontColor: '#f2994a' } },
            },
        ].filter((x) => !x.remove);

        // if (hide) texts = [];

        texts.forEach((textInfo) => {
            TextBlock({
                ...textInfo,
                dy,
                // alignLeft: true,
                // target: g.g,
                // styleParams: { override: { fontWeight: 900, fontSize: 19 } },
            });
        });
        texts.forEach((t) => t.target.call(ghost));
    }

    renderRight() {
        this.clearRight();
        if (this.state.hover === 'r') this.renderRightR();
        if (this.state.hover === 'a') this.renderRightA();
        if (this.state.hover === 'c') this.renderRightC();
        if (this.state.hover === 'd') this.renderRightD();
    }

    clearRight() {
        let { lo, g, ds } = this.context;
        g.gRight1.selectAll('*').remove();
        g.gRight2.selectAll('*').remove();
        g.gRight3.selectAll('*').remove();
    }

    renderRightA() {
        const D = {
            dx: -250,
            dy: 340,
            transform: 'scale(1.5, 2.3)',
        };
        let target = this.context.g.gRight1;
        let area = this.context.lo.rightPart1;
        target
            .selectAll('image')
            .data([null])
            .join('image')
            .attr('href', '/frontend/src/assets/images/logmar.jpg')
            .attr('width', area.w)
            .attr('height', area.h)
            .attr('x', area.l + D.dx)
            .attr('y', area.t + D.dy)
            .style('transform-origin', '50% 50%')
            .style('transform', D.transform);
    }
    renderRightC() {
        const CONTRAST_IMG = {
            dx: -250,
            dy: 340,
            // transform: '', //'scale(1.0, 2.0)',
            transform: 'scale(1.5, 2.0)',
        };
        let D = CONTRAST_IMG;
        let target = this.context.g.gRight1;
        let area = this.context.lo.rightPart1;
        target
            .selectAll('image')
            .data([null])
            .join('image')
            .attr('href', '/frontend/src/assets/images/contrast.png')
            // .attr('width', 272)
            // .attr('height', 622)
            .attr('width', area.w)
            .attr('height', area.h)
            // .attr('height', area.h)
            .attr('x', area.l + D.dx)
            .attr('y', area.t + D.dy)
            .style('transform-origin', '50% 50%')
            .style('transform', D.transform);
    }
    renderRightD() {}

    renderRightR() {
        let { lo, g, ds } = this.context;
        let area = lo.rightPart1;

        let stuff = [
            {
                area: lo.rightPart1,
                target: g.gRight1,
                series: [
                    {
                        id: 0,
                        dataPoints: this.state.freqPointsOD,
                    },
                ],
            },
            {
                area: lo.rightPart2,
                target: g.gRight2,
                series: [
                    {
                        id: 1,
                        dataPoints: this.state.freqPointsOS,
                    },
                ],
            },
        ];
        stuff = [];

        stuff.forEach(({ area, series, target }) => {
            let aData = AData<any>({
                series,
                positioning: (x) => x,
                coloring: (x, ds: any) => (ds.id === 0 ? B.green : B.orange),
                xFrom: 0,
                xTo: 10,
            });
            let ctx = AChart2<any>({
                aData,
                target,
                area,
            });
            ChartLines({
                ...ctx,
                target,
                styleParams: {
                    override: {
                        // class: 'sContrast',
                        lineSize: 1,
                    },
                },
            });
            AxisLeft({
                ...ctx,
                grid: true,
                area,
                target,
            });
            AxisBottom({
                ...ctx,
                ticks: 5,
                grid: true,
                area,
                target,
            });
        });
    }

    renderPupilUsedValues() {
        let { lo, g, ds } = this.context;
        let area = lo.main;
        let target = g.g;
        let series = this.state.viewPupilSegments;
        // if (!this.state.ui.showPupil) series = []
        // let parentAData = this.mainLinesAData
        let parentAData = this.pupilAData;

        let aData = AData<any>({
            series,
            positioning: (x) => x,
            coloring: (x, ds: any) => (ds.color === 0 ? B.green : B.orange),
        });
        aData = { ...aData, xScale: parentAData.xScale, yScale: parentAData.yScale };
        let ctx = AChart2<any>({
            aData,
            target,
            area,
        });
        ChartLines({
            ...ctx,
            target,
            styleParams: {
                override: {
                    lineSize: 5,
                },
            },
        });
    }

    renderRapdTable() {
        let { lo, g, ds } = this.context;
        let area = lo.right3;
        let target = g.g;
        let columns = this.state.results.rapd.table;

        let f = (x: number) => x.toFixed(2);

        let table = {
            rowHeaders: ['seg', 'rapd', 'dysf'], //`${mark2}  Set stimuli manually`, '', `OD`, `OS`],
            columns: columns.map((x, i) => {
                return {
                    at: i + 1,
                    // text: x.header,
                    rows: [x.header, f(x.rapd), f(x.dysf)],
                };
            }),
        };

        VTable({
            domainX: [0, columns.length - 1 + 1],
            ...table,

            target,
            area,

            styleParams: {
                // outdated way
                override: {
                    // fontSize: 26,
                },
            },
        });
    }

    render() {
        if (USE_FAKE_DATA) {
            this.memoize('onceRender', () => {
                new FakeData().fill(this.protocol);
                return 1;
            });
        }

        d3.select('.troot').remove();

        this.context = this.renderingContext();
        // this.prepareOtherContext()
        this.renderSeenChart();
        this.renderMainLines();
        this.renderLostMarks();
        if (this.state.done) {
            this.renderRndInfo();
            this.renderSections();
            this.renderSecLegend();
            // this.renderRndCharts()
            // this.renderRndMA()
            // this.renderRndThresholdControl()
            this.renderPupilLines();
            this.renderSettings();
            this.renderSegmentsLines();
            this.renderTopPanels();
            this.renderBottomPanels();
            this.renderRight();
            this.renderPupilUsedValues();
            // this.renderRapdTable();
        }
    }

    get exportData(): ExportData {
        return {
            rows: this.worstCodeCopied(),
        };
    }

    worstCodeCopied() {
        let rows = [] as any[];
        [ACUITY, CONTRAST, CONTRAST2].forEach((group, index) => {
            let i = group.type;
            let series = this.state.viewMainLines(group.type);

            let part = index;

            series.forEach((serie) => {
                let [from, to]: [any, any] = serie.dataPoints as any;
                if (!from || !to) return; // jik

                let eye = from.eye;
                let startAt = from.time_test;
                let stopAt = to.time_test;
                let startValue = from.y;
                let stopValue = to.y;

                rows.push({
                    part,
                    eye,
                    startAt,
                    stopAt,
                    startValue,
                    stopValue,
                });
            });
        });
        return rows;
    }
}

export interface ExportData {}

interface DS<T> {
    id: number;
    dataPoints: T[];
}
