import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLE } from '../../../../../../../../commonout/enum/role';
import { IDoctorRoleProperties } from '../../../../../../../../commonout/interfaces/roleProperties/doctorRoleProperties.interface';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { UserService } from '../../../../_services/user/user.service';
import { UserDetailsComponent } from '../user-details.component';

@Component({
    selector: 'doctor-details',
    template: require('./doctor-details.component.html'),
    styles: [require('../user-details.component.scss')],
    host: { class: 'columns is-vcentered' },
})
export class DoctorDetailsComponent extends UserDetailsComponent<IDoctorRoleProperties> implements OnInit {
    constructor(route: ActivatedRoute, router: Router, location: Location, authService: AuthenticationService, userService: UserService, formBuilder: FormBuilder) {
        super(ROLE.DOCTOR, formBuilder, route, userService, location, authService, router);
    }
    buildForm(): void {
        this.form = this.formBuilder.group(
            {
                role: [this.user.role, Validators.required],
                firstName: [this.user.roleProperties.firstName, Validators.required],
                lastName: [this.user.roleProperties.lastName, Validators.required],
                email: [this.user.roleProperties.email, [Validators.required, Validators.email]],
                phone: [this.user.roleProperties.phone, [Validators.required, Validators.pattern(/^\+?[\d()\-\s]*$/)]],
                company: [this.user.roleProperties.company, Validators.required],
                password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')]],
                passwordConfirmation: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')]],
            },
            {
                validator: (group: any) => {
                    return group.controls.password.value === group.controls.passwordConfirmation.value
                        ? null
                        : {
                              areEqual: true,
                          };
                },
            }
        );
    }
    public fillRoleProps(rawFormValue: any): IDoctorRoleProperties {
        return {
            firstName: rawFormValue.firstName,
            lastName: rawFormValue.lastName,
            email: rawFormValue.email,
            phone: rawFormValue.phone,
            company: rawFormValue.company,
        };
    }
}
