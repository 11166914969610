// @ts-nocheck
// import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target } from './types';
import { doTranslate, getStyle, RawPoint } from './util';

// styling

const styleDefault = {
    fillColor: 'transparent',
    lineColor: '#eee',
    lineKlass: '',
    lineSize: 1,
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {
    bright: {
        // lineColor: '#123',
    },
};

// interface

interface Params {
    from: RawPoint;
    to: RawPoint;

    target: Target;
    // area: Layout;
    styleParams?: {
        theme?: string;
        override?: Partial<Style>;
    };
}

// function

export function Rectangle(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams);

    let x1 = Math.min(p.from.x, p.to.x);
    let x2 = Math.max(p.from.x, p.to.x);

    let y1 = Math.min(p.from.y, p.to.y);
    let y2 = Math.max(p.from.y, p.to.y);

    p.target
        .selectAll('.rectangle')
        .data([null])
        .join('rect')
        .attr('class', 'rectangle ' + style.lineKlass)

        .call(doTranslate({ x: x1, y: y1 }))
        .attr('width', x2 - x1)
        .attr('height', y2 - y1)

        .attr('fill', style.fillColor)
        .attr('stroke-width', style.lineSize)
        .attr('stroke', style.lineColor);
}
