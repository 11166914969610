import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import saveAs from 'file-saver';
import { BehaviorSubject, range, Subscription } from 'rxjs';
import { concatMap, delay, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { GENDER } from '../../../../../../commonout/enum/gender';
import { ROLE } from '../../../../../../commonout/enum/role';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IUser } from '../../../../../../commonout/interfaces/user';
import { IBulkExportPayload } from '../../../../../common/interfaces/bulkExportPayload.interface';
import { ExaminationFrontend } from '../../_models/examinationFrontend.class';
import { ExaminationService } from '../../_services/examination/examination.service';
import { ExportService } from '../../_services/examination/export.service';
import { TestService } from '../../_services/examination/test.service';
import { UserService } from '../../_services/user/user.service';
import { BulbicamService } from '../../_services/examination/bulbiCam.service';
import { DatepickerComponent } from '../general/custom-inputs/datepicker/datepicker.component';
import { CamTest } from '../../../../../common/models/haplotest.class';
import JSZip from 'jszip';
import { ConfigService } from '../../_services/general/config.service';
import { IIDCDiagnosis } from '../../../../../common/interfaces/idc-list-item.interface';
import { IdcDiagnosisItem } from '../../../../../common/models/idc/idc-diagnosis-item.model';
const ECT = require('@whoicd/icd11ect');

@Component({
    selector: 'bulk-export',
    template: require('./bulk-export.component.html'),
    styles: [require('./bulk-export.component.scss')],
})
export class BulkExportComponent implements OnInit {
    private filterForm: FormGroup;
    private oldFormValues: IBulkExportPayload;
    private readonly maxDate: Date = new Date();
    public maxDateForStartDatePicker: Date = new Date();
    public minDateForEndDatePicker: Date;
    private open: boolean = true; // to aviod bug when changing max date value of min datepicker fires value change event
    private readonly gender: GENDER | string[] = ['', GENDER.FEMALE, GENDER.MALE, GENDER.OTHER];
    public camTests: { name: string; type: TEST_TYPE }[];
    public users: IUser<ROLE.STAFF>[];
    public pendingResponce: boolean = false;
    private readonly ageRange: string[] = (() => {
        let arr: string[] = [];
        arr.push('');
        for (let index = 0; index < 121; index++) {
            arr.push(index.toString());
        }
        return arr;
    })();
    @ViewChild('endPicker') private endPicker: DatepickerComponent;
    public authorsSelected: boolean;
    public expectedExamsCnt: number = 0;
    public expectedTestsCnt: number = 0;
    public expectedTestsToExportCnt: number = 0;
    public processedTestsCnt: number = 0;
    public processedTestsToExportCnt: number = 0;
    public vizualizerExamsWidth: string;
    public vizualizerTestsWidth: string;
    public vizualizerExportedTestsWidth: string;
    public isDiagnosisExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly callbacks: {
        selectedEntityFunction: Function;
    } = {
        selectedEntityFunction: this.selectedIDCDiagnosisEntityHandler.bind(this),
    };
    public ICDdescription: string;
    private subscriptions: Array<Subscription> = [];

    constructor(
        private formBuilder: FormBuilder,
        private testService: TestService,
        private userService: UserService,
        private examinationService: ExaminationService,
        private exportService: ExportService,
        private bulbicamService: BulbicamService,
        private configService: ConfigService
    ) {
        const camTests = this.testService.getTestsByDevice(DEVICE.HAPLO).map(th => {
            return { name: th.name, type: th.type };
        });
        let testTypesCheckboxGroup = {};
        camTests.forEach(ct => (testTypesCheckboxGroup[ct.type] = false));
        this.camTests = camTests;

        this.filterForm = this.formBuilder.group(
            {
                startDate: [null, Validators.required],
                endDate: [null, Validators.required],
                gender: '',
                startAge: '',
                endAge: '',
                testTypes: this.formBuilder.group(testTypesCheckboxGroup),
                ICD10: '',
                feedbackTypes: this.formBuilder.group({
                    recordingGrades: this.formBuilder.group({
                        1: true,
                        2: true,
                        3: true,
                        4: true,
                        5: true,
                        dont_know: true,
                        not_relevant: true,
                        just_testing: true,
                    }),
                    clinicalGrades: this.formBuilder.group({
                        misleading: true,
                        not_helpful: true,
                        helpful: true,
                        high: true,
                        very_high: true,
                        doubtful_value: true,
                        dont_know: true,
                        not_relevant: true,
                    }),
                }),
                excludeCAMdata: false,
            },
            {
                validator: (control: FormGroup): ValidationErrors | null => {
                    const rawFormValue: IBulkExportPayload = control.getRawValue();
                    let selectedTestTypes: TEST_TYPE[] = [];
                    for (const testType in rawFormValue.testTypes) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.testTypes, testType)) {
                            const isSelected: boolean = rawFormValue.testTypes[testType];
                            if (isSelected) selectedTestTypes.push(TEST_TYPE[testType]);
                        }
                    }

                    const selectedAuthorsIDs: string[] = [];
                    for (const userID in rawFormValue.users) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.users, userID)) {
                            const userSelected = rawFormValue.users[userID];
                            if (userSelected) selectedAuthorsIDs.push(userID);
                        }
                    }

                    const selectedGradeTypes: {
                        recordingGrades: string[];
                        clinicalGrades: string[];
                    } = {
                        recordingGrades: [],
                        clinicalGrades: [],
                    };
                    for (const grade in rawFormValue.feedbackTypes.recordingGrades) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.feedbackTypes.recordingGrades, grade)) {
                            const recordingGradeSelected = rawFormValue.feedbackTypes.recordingGrades[grade];
                            if (recordingGradeSelected) selectedGradeTypes.recordingGrades.push(grade);
                        }
                    }
                    for (const grade in rawFormValue.feedbackTypes.clinicalGrades) {
                        if (Object.prototype.hasOwnProperty.call(rawFormValue.feedbackTypes.clinicalGrades, grade)) {
                            const clinicalGradeSelected = rawFormValue.feedbackTypes.clinicalGrades[grade];
                            if (clinicalGradeSelected) selectedGradeTypes.clinicalGrades.push(grade);
                        }
                    }

                    if (selectedAuthorsIDs.length === 0 && (selectedGradeTypes.clinicalGrades.length > 0 || selectedGradeTypes.recordingGrades.length > 0)) {
                        this.authorsSelected = false;
                    } else {
                        this.authorsSelected = true;
                    }

                    if (selectedTestTypes.length === 0) {
                        return {
                            noneTestTypeSelected: true,
                        };
                    }

                    if (selectedAuthorsIDs.length > 0 && selectedGradeTypes.clinicalGrades.length === 0 && selectedGradeTypes.recordingGrades.length === 0) {
                        return {
                            noneGradeTypesSelected: true,
                        };
                    }

                    return null;
                },
            }
        );

        this.userService
            .getUsers<any>()
            .toPromise()
            .then(users => {
                const usrs: IUser<ROLE.STAFF>[] = users.filter(user => user.role === ROLE.STAFF);
                let usersCheckboxGroup = {};
                usrs.forEach(u => (usersCheckboxGroup[u._id] = true));
                this.filterForm.setControl('users', this.formBuilder.group(usersCheckboxGroup));
                this.users = usrs;
            });
    }
    ngOnInit(): void {
        this.oldFormValues = { ...this.filterForm.value };
        this.filterForm.valueChanges.subscribe(value => {
            const controlName: string = Object.keys(value).find(k => value[k] != this.oldFormValues[k]);
            this.oldFormValues = { ...this.filterForm.value };
            if (controlName === 'startDate') {
                this.minDateForEndDatePicker = value[controlName];
                if (this.open) this.endPicker.open();
            } else if (controlName === 'endDate') {
                this.open = false;
                this.maxDateForStartDatePicker = value[controlName];
                setTimeout(() => {
                    this.open = true;
                }, 0);
            }
        });
        this.subscriptions.push(
            this.isDiagnosisExpanded.pipe(delay(0)).subscribe(state => {
                if (state) {
                    ECT.Handler.configure(this.configService.ICD, this.callbacks);
                    ECT.Handler.bind(1);
                }
            })
        );
    }

    private async selectedIDCDiagnosisEntityHandler(listItem: IIDCDiagnosis): Promise<void> {
        const listItemModel: IdcDiagnosisItem = new IdcDiagnosisItem();
        listItemModel.model = listItem;
        this.filterForm.get('ICD10').setValue(listItemModel.code);
        this.ICDdescription = listItemModel.title;
        this.isDiagnosisExpanded.next(false);
    }

    public selectAll(event: boolean): void {
        let testTypesCheckboxGroup = {};
        this.camTests.forEach(ct => (testTypesCheckboxGroup[ct.type] = event));
        this.filterForm.get('testTypes').setValue(testTypesCheckboxGroup);
    }

    public async export() {
        this.pendingResponce = true;
        this.filterForm.disable();
        let expectedExamsCnt = await this.examinationService.bulkExport(this.filterForm.getRawValue()).toPromise();
        this.expectedExamsCnt = expectedExamsCnt.examsCnt;
        await range(0, expectedExamsCnt.examsCnt)
            .pipe(
                concatMap(() => this.examinationService.getNextExamForBulkExport()),
                map(result => {
                    this.vizualizerExamsWidth = `${((result.length * 100) / this.expectedExamsCnt).toFixed(0)}%`;
                    const examination: ExaminationFrontend = new ExaminationFrontend();
                    examination.setModel(result.exam);
                    return examination.tests;
                }),
                toArray(),
                mergeMap(tests => {
                    this.expectedTestsCnt = 0;
                    this.processedTestsCnt = 0;
                    tests.forEach(tsts => (this.expectedTestsCnt += tsts.length));
                    return tests;
                }),
                mergeMap(tests => tests),
                concatMap(async t => {
                    if (this.exportService.isTsvExportAvailable(t) && !this.filterForm.get('excludeCAMdata').value)
                        await Promise.all(
                            t.remarks.measurements[0]['haplotests'].map(async (ht: CamTest) => {
                                const responce = await this.bulbicamService.getHaplotestData(ht.haplotestData);
                                ht.rawData = responce.data;
                            })
                        );
                    this.processedTestsCnt++;
                    this.vizualizerTestsWidth = `${(((this.expectedTestsCnt - this.processedTestsCnt) * 100) / this.expectedTestsCnt).toFixed(0)}%`;
                    return t;
                }),
                toArray(),
                mergeMap(tests => {
                    this.expectedTestsToExportCnt = tests.length;
                    this.processedTestsToExportCnt = 0;
                    return tests;
                }),
                concatMap(t => {
                    this.processedTestsToExportCnt++;
                    this.vizualizerExportedTestsWidth = `${(((this.expectedTestsToExportCnt - this.processedTestsToExportCnt) * 100) / this.expectedTestsToExportCnt).toFixed(0)}%`;
                    return this.exportService.toCompressedTsv(t);
                }),
                filter(contents => contents.length > 0),
                mergeMap((contents: { content: Blob; filename: string }[]) => contents),
                toArray(),
                tap((contents: { content: Blob; filename: string }[]) => {
                    const zip = new JSZip();
                    contents.forEach(c => {
                        zip.file(c.filename, c.content);
                    });
                    zip.generateAsync({ type: 'blob' }).then(content => {
                        saveAs(content, 'export.zip');
                    });
                })
            )
            .toPromise();
        this.filterForm.enable();
        this.pendingResponce = false;
    }
}
