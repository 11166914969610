import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import { IPursuitSaccadesCamMessage } from '../../../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { CHART_HEIGHT, TICKS_COLOR, TICKS_FONT_SIZE, AXIS_COLOR, REAL_TIME_LINE_COLOR } from '../../chartStyles.constants';
import { ILine } from '../../generic-types';

@Component({
  selector: 'app-saccade-real-time-chart',
  template: require('./saccade-real-time-chart.component.html'),
  styles: [require('../../saccade-pursuit-charts-styles.scss')]
})
export class SmoothSaccadeRealTimeChartComponent implements AfterViewInit {
    @ViewChild('chart') private svgElement: ElementRef<SVGElement>;
    @Input() public data: IPursuitSaccadesCamMessage[];
    @Input() public width = 1500;
    @Input() public height = CHART_HEIGHT;
    @Input() public margin = 50;

    private svg: d3.Selection<SVGElement, unknown, null, undefined>;
    private svgInner: d3.Selection<SVGElement, unknown, null, undefined>;
    private yScale: ScaleLinear<number, number>;
    private xScale: ScaleLinear<number, number>;
    private lastPoint: [number, number][] = [];
    private lastPointX: number;
    
    private readonly AXIS_X_MAX_VALUE = 60;
    private readonly MAX_Y_SCALE_VALUE = 300;
    private readonly MIN_Y_SCALE_VALUE = 180;

    constructor() { }

    ngAfterViewInit(): void {
        this.initializeChart();
    }

    public async buildRealTimeChart(data: IPursuitSaccadesCamMessage[]) {
        this.height = CHART_HEIGHT;

        if (d3.select('#realTimeChartContent').empty() ) {
            this.lastPoint = [];
            this.lastPointX = 0;
            this.initializeChart();
        }
        this.drawPoints(data);
    }

    public hideChart() {
        d3.select('#realTimeTitle').remove();
        d3.select('#realTimeChart').selectAll('*').remove();
        d3.select('#realTimeChart').attr('height', 0);
    }

    private initializeChart(): void {
        this.svg = d3
          .select(this.svgElement.nativeElement)
          .attr('height', this.height)
          .attr('width', '100%')
          .attr('id', 'realTimeChart');

        this.svgInner = this.svg
          .append('g')
          .attr('id', 'realTimeChartContent')
          .style('transform', 'translate(' + (this.margin.toString() + 'px, ' + this.margin.toString()) + 'px)');

        this.yScale = d3
          .scaleLinear()
          .domain([this.MAX_Y_SCALE_VALUE, this.MIN_Y_SCALE_VALUE])
          .range([0, this.height - 2 * this.margin]);

        const yAxisDistance = this.svgInner
          .append('g')
          .attr('id', 'y-axisDistance')
          .style('transform', 'translate(' + (this.margin).toString() + 'px,  0)');

        this.xScale = d3
          .scaleLinear()
          .domain([0, this.AXIS_X_MAX_VALUE]);

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', 'x-axis')
          .style('transform', 'translate(0, ' + (this.height / 2 - this.margin).toString() + 'px)');

        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'realTimeChartPoints');

        this.width = this.svgElement.nativeElement.getBoundingClientRect().width
          ? this.svgElement.nativeElement.getBoundingClientRect().width
          : this.width;

        this.xScale.range([this.margin, this.width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxis = d3
          .axisLeft(this.yScale).tickValues([]);

        yAxisDistance
          .call(yAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        yAxisDistance
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private drawPoints(data: IPursuitSaccadesCamMessage[]): void {
        if (data.length > 0) {
            if (this.lastPoint.length != 0) {
              const firstTime: [number, number] =
                [
                  this.xScale(data[0].pointX),
                  this.yScale(data[0].eyeOSy)
                ]
              this.lastPoint.push(firstTime);
            }
    
            this.drawLineOnChart(this.lastPoint, { id: 'line', color: REAL_TIME_LINE_COLOR });
    
            this.lastPoint = [];
    
            const points: [number, number][] = data.map(d => [
              this.xScale(d.pointX),
              this.yScale(d.eyeOSy),
            ]);
    
            this.lastPoint.push(points[points.length - 1]);
    
            this.drawLineOnChart(points, { id: 'line', color: REAL_TIME_LINE_COLOR });
            this.setChartBackgroundColor(data);
        }
    }

    private setChartBackgroundColor(data: IPursuitSaccadesCamMessage[]) {
        const firstPointX = this.lastPointX ?? data[0].pointX;
        const width = this.xScale(data[data.length - 1].pointX) - this.xScale(firstPointX);
        const pointX = this.xScale(firstPointX);

        this.svgInner
            .append("rect")
            .attr("x", pointX)
            .attr("y", 0)
            .attr("width", width)
            .attr("height", this.height - 2 * this.margin)
            .attr("fill", "red")
            .attr("opacity", 0.2);

        this.lastPointX = data[data.length - 1].pointX;
    }

    private drawLineOnChart(points: [number, number][],
    lineStyle: ILine): void {
        const line = d3
          .line()
          .x(d => d[0])
          .y(d => d[1])
          .curve(d3.curveMonotoneX);

        this.svgInner
          .append('path')
          .attr('id', lineStyle.id)
          .attr('d', line(points))
          .style('fill', 'none')
          .style('stroke', lineStyle.color)
          .style('stroke-width', '2px');
    }
}
