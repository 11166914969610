import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { scaleLinear, Selection } from 'd3';
import { Line } from 'd3-shape';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ENVIRONMENTS } from '../../../../../../../../../commonout/enum/environments';
import { MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import {
    AreaResults,
    AreaTableResults,
    AREA_RESULT_TYPE,
    ExportAreaResults,
    ExportLatencyResults,
    ExportPeakVelocityResults,
    ExportPupilDiameterResults,
    ExportTableTestResults,
    IPupil20TestCamMessage,
    LatencyResults,
    PeakVelocityResults,
    PupilDiameterResults,
    SHIFT_TYPE,
    TestResultCoordinates,
} from '../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { MyPupil20TestChartService } from '../../../../../_services/chartServices/myPupil20TestChartService';
import { Pupil20TableChartService } from '../../../../../_services/chartServices/pupil20TableChartService';
import { AreaTableComponent, LIMIT_TYPE } from './area-table/area-table.component';
import { LatencyTableComponent } from './latency-table/latency-table.component';
import { PeakVelocityTableComponent } from './peak-velocity-table/peak-velocity-table.component';
import { PupilDiameterTableComponent } from './pupil-diameter-table/pupil-diameter-table.component';

@Component({
    selector: 'pupil20table',
    template: require('./pupil2.0-table.component.html'),
    styles: [require('./pupil2.0-table.component.scss')],
})
export class Pupil20TableComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public mode: ENVIRONMENTS;
    private readonly ENVIRONMENTS: typeof ENVIRONMENTS = ENVIRONMENTS;
    @Output() drawLightLines = new EventEmitter<{
        lightCoordinatesOD: TestResultCoordinates[];
        lightCoordinatesOS: TestResultCoordinates[];
    }>();
    @Output() highlight = new EventEmitter<TestResultCoordinates[]>();
    @Output() clearHighlight = new EventEmitter<void>();
    @Output() clearLatencies = new EventEmitter<void>();
    @Output() latency = new EventEmitter<{ run: number; xValue: number; oculus: OCULUS }>();

    @ViewChild('test') private svg: ElementRef;
    @ViewChild('pupilDiameterTable') public pupilDiameterTableComponent: PupilDiameterTableComponent;
    @ViewChild('latencyTable') public latencyTableComponent: LatencyTableComponent;
    @ViewChild('peakVelocityTable') public peakVelocityTableComponent: PeakVelocityTableComponent;
    @ViewChild('areaTable') public areaTableComponent: AreaTableComponent;

    public pupilDiameterResults: PupilDiameterResults = {
        averageDiameterOD: [],
        averageDiameterOS: [],
        differenceDiameter: [],
        endTime: [],
        startTime: [],
        shiftTypes: [],
        testResultCoors: [],
        tableCellWidths: [],
        tableWidth: 0,
    };

    public latencyResult: LatencyResults = {
        latencyOD: [],
        latencyOS: [],
        differenceLatency: [],
        endTimeOD: [],
        endTimeOS: [],
        startTime: [],
        testResultCoors: [],
        tableCellWidths: [],
        tableWidth: 0,
    };

    public peakVelocityResult: PeakVelocityResults = {
        peakVelocityOD: [],
        peakVelocityOS: [],
        differencePeakVelocity: [],
        testResultCoors: [],
        tableCellWidths: [],
        timePassed: [],
        tableWidth: 0,
    };

    public areaResult: AreaResults = {
        areaOD: [],
        areaOS: [],
        startTime: [],
        endTime: [],
        areaDifference: [],
        testResultCoors: [],
        tableCellWidths: [],
    };

    public areaTableResults: AreaTableResults = {
        areaResults: null,
        resultType: AREA_RESULT_TYPE.NO_CONTENT,
    };

    public ligthLinesCoordinates: {
        lightCoordinatesOD: TestResultCoordinates[];
        lightCoordinatesOS: TestResultCoordinates[];
    } = {
        lightCoordinatesOD: [],
        lightCoordinatesOS: [],
    };

    public testCoordinates: TestResultCoordinates = {
        run: null,
        deltaSingleOculus: null,
        xValue: null,
        deltaXvalue: null,
        xRange: null,
        yValue: null,
        deltaYvalue: null,
        oculus: null,
        xSecondValue: null,
    };

    public showResultTables = false;

    public exportPupilDiameterResults: ExportPupilDiameterResults[] = [];

    public exportLatencyResult: ExportLatencyResults[] = [];

    public exportPeakVelocityResult: ExportPeakVelocityResults[] = [];

    public exportAreaResult: ExportAreaResults[] = [];

    private width: number;
    private diameterLineGenerator: Line<{ x: number; y: number }>;
    private deviationsLineGenerator: Line<{ x: number; y: number }>;
    private deviationsChart: Selection<SVGGElement, unknown, null, undefined>;

    private startTimestamp: number = undefined;
    private startFrame: IPupil20TestCamMessage;
    private startBlockTime: number;
    private xAxisGenerator: any;
    private widthPerSecond: number;
    private pupilDiameterBlock: IPupil20TestCamMessage[] = [];
    private pupilDiameterBlocks: IPupil20TestCamMessage[][] = [];
    private counter = 1;
    private dilationLeftLimit = 1;
    private dilationRightLimit = 1;
    private constrictionLeftLimit = 0.5;
    private constrictionRightLimit = 0.5;
    private blocks: {
        round?: number;
        dilationRanges?: {
            dilationBlocks?: IPupil20TestCamMessage[];
        }[];
        constrictionsRanges?: {
            constrictionsBlocks?: IPupil20TestCamMessage[];
        }[];
    }[] = [];
    private dilationBlocks: IPupil20TestCamMessage[] = [];
    private constrictionsBlocks: IPupil20TestCamMessage[] = [];
    private dilationRanges: {
        dilationBlocks?: IPupil20TestCamMessage[];
    }[] = [];
    private constrictionRanges: {
        constrictionsBlocks?: IPupil20TestCamMessage[];
    }[] = [];
    private blocksCounter = 0;
    private markers: {
        OD: number[];
        OS: number[];
    }[] = [];
    //end test mode

    public run: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private padding: { left: number; top: number; right: number; bottom: number } = { left: 200, top: 40, right: 40, bottom: 40 };
    @ViewChildren('measure') itemsList: QueryList<ElementRef>;
    public framesSource: Subject<IPupil20TestCamMessage> = new Subject<IPupil20TestCamMessage>();
    private readonly ROUND_TEST_LENGTH: number = 25;
    private readonly LAST_VALID_BLOCK = 26;
    private readonly MAX_ROUND = 2;
    private numbersDilationBlocks: number[] = [1, 2, 3, 6, 7, 8, 11, 12, 13];
    private numbersConstrictionBlocks: number[] = [4, 5, 9, 10, 14, 15];
    private endsDilationRanges: number[] = [4, 9, 14];
    private endsConstrictionRanges: number[] = [6, 11, 16];
    private CUT_AROUND_ZERO_RANGE: FormControl = new FormControl({ value: '10', disabled: false });
    private MOVING_AVERAGE_SIZE: FormControl = new FormControl({ value: '20', disabled: false });
    private SECOND_MOVING_AVERAGE_SIZE: FormControl = new FormControl({ value: '20', disabled: false });
    private FIRST_DEVIATION_GAP: FormControl = new FormControl({ value: '10', disabled: false });
    private SECOND_DEVIATION_GAP: FormControl = new FormControl({ value: '10', disabled: false });
    private validBlocks: number[] = [1, 4, 6, 9, 11, 14, 16, 18, 21, 23, 25];
    private coordinatesValidBlocks: number[] = [3, 5, 8, 10, 13, 15, 17, 20, 22];
    private startBlocks: number[] = [1, 4, 6, 9, 11, 14, 16, 18, 21, 23];
    private endBlocks: number[] = [3, 5, 8, 10, 13, 15, 17, 20, 22, 25, 25];
    private currentPoint: IPupil20TestCamMessage[] = [];
    private data: IPupil20TestCamMessage[][];
    private round = 0;
    private subscriptions: Subscription[] = [];
    private tmpPrevDeltaAverage: { timestamp: number; averageValueOD: number; averageValueOS: number } = {
        averageValueOD: null,
        averageValueOS: null,
        timestamp: null,
    };
    public export = {};

    private readonly AREA_HOVER_LINE_END_POINT_Y = -12.5;
    private readonly LATENCY_HOVER_LINE_END_POINT_Y = -7.5;
    private readonly VECOLITY_HOVER_LINE_END_POINT_Y = -10;

    constructor(private renderer: Renderer2, private chartService: Pupil20TableChartService, private pupilTestChartService: MyPupil20TestChartService) {
        this.subscriptions.push(
            this.framesSource.subscribe(frame => {
                let localFrame = { ...frame };
                switch (localFrame.message_type) {
                    case MESSAGE_TYPE.START_TEST:
                        this.clearPupilTestResults();
                        this.data = [];
                        this.pupilDiameterBlocks = [];
                        break;
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        this.startTimestamp = localFrame.timestamp / 10000;
                        this.data.push([]);
                        // this.run.next(this.data.length - 1);
                        this.currentPoint = [];

                        this.data[this.data.length - 1].push(localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        this.startTimestamp = null;
                        this.pupilDiameterBlocks.push(this.pupilDiameterBlock);
                        this.pupilDiameterBlock = [];
                        this.blocks.push({
                            round: this.blocks.length,
                            dilationRanges: this.dilationRanges,
                            constrictionsRanges: this.constrictionRanges,
                        });
                        this.dilationRanges = [];
                        this.constrictionRanges = [];
                        this.blocksCounter = 0;
                        break;
                    case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                        this.startFrame = localFrame;
                        this.data[this.data.length - 1].push(localFrame);
                        this.startBlockTime = localFrame.timestamp / 10000;

                        this.pupilDiameterBlock.push(localFrame);

                        this.blocksCounter++;
                        if (this.endsDilationRanges.includes(this.blocksCounter)) {
                            this.dilationRanges.push({ dilationBlocks: this.dilationBlocks });
                            this.dilationBlocks = [];
                            this.constrictionsBlocks = [];
                        }
                        if (this.endsConstrictionRanges.includes(this.blocksCounter)) {
                            this.constrictionRanges.push({ constrictionsBlocks: this.constrictionsBlocks });
                            this.dilationBlocks = [];
                            this.constrictionsBlocks = [];
                        }

                        break;
                    case MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(localFrame);
                        const currentBlockTime = this.chartService.getXvalue(this.startBlockTime, localFrame.timestamp);
                        const factorBySecond = currentBlockTime / 1;
                        const blockWidth = this.widthPerSecond * factorBySecond;

                        this.pupilDiameterBlock.push(localFrame);

                        if (this.pupilDiameterResults.tableCellWidths.length < this.ROUND_TEST_LENGTH) {
                            this.pupilDiameterResults.tableCellWidths.push(blockWidth);
                        }

                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        this.data[this.data.length - 1].push(localFrame);

                        this.calculateTestResults(0);

                        if (this.mode === ENVIRONMENTS.TEST) {
                            this.applyRun(this.data.length - 1);
                        }

                        if (this.pupilDiameterResults.averageDiameterOD.length !== 0) {
                            this.pupilDiameterResults.tableWidth = this.width;
                            this.pupilDiameterTableComponent?.testResults$.next(this.pupilDiameterResults);
                        }

                        if (this.latencyTableComponent.itemsDifference.length !== 0) {
                            this.latencyResult.tableWidth = this.width;
                            this.latencyTableComponent?.testResults$.next(this.latencyResult);
                        }

                        if (this.peakVelocityTableComponent.itemsDifference.length !== 0) {
                            this.peakVelocityResult.tableWidth = this.width;
                            this.peakVelocityTableComponent?.testResults$.next(this.peakVelocityResult);
                        }

                        if (this.areaTableComponent.itemsDifference.length !== 0) {
                            this.areaTableComponent?.testResults$.next(this.areaTableResults);
                        }
                        this.showResultTables = true;

                        // this.drawLightLines.emit(this.ligthLinesCoordinates);

                        break;
                    default:
                        if (this.numbersDilationBlocks.includes(this.blocksCounter)) {
                            this.dilationBlocks.push(localFrame);
                        } else if (this.numbersConstrictionBlocks.includes(this.blocksCounter)) {
                            this.constrictionsBlocks.push(localFrame);
                        }
                        this.data[this.data.length - 1].push(localFrame);
                        this.pupilDiameterBlock.push(localFrame);
                        break;
                }
            })
        );
    }

    private calculateTestResults(round: number) {
        this.interpolateGap(this.data[round], round);
        this.interpolateGap(this.pupilDiameterBlocks[round], round);

        let ma: number = Number.parseInt(this.MOVING_AVERAGE_SIZE.value);

        this.data[round].forEach((d, i, collector) => {
            if (i >= ma) {
                d.movingAverageOD =
                    collector
                        .slice(i - ma, i + ma)
                        .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                        .reduce((partial_sum, a) => partial_sum + a.measurementODwithoutZeros, 0) /
                    collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
                d.movingAverageOS =
                    collector
                        .slice(i - ma, i + ma)
                        .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                        .reduce((partial_sum, a) => partial_sum + a.measurementOSwithoutZeros, 0) /
                    collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
            }
        });

        this.pupilDiameterBlocks[round].forEach((d, i, collector) => {
            if (i >= ma) {
                d.movingAverageOD =
                    collector
                        .slice(i - ma, i + ma)
                        .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                        .reduce((partial_sum, a) => partial_sum + a.measurementODwithoutZeros, 0) /
                    collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
                d.movingAverageOS =
                    collector
                        .slice(i - ma, i + ma)
                        .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                        .reduce((partial_sum, a) => partial_sum + a.measurementOSwithoutZeros, 0) /
                    collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
            }
        });

        this.measure(this.pupilDiameterBlocks, round);
        this.calculateLatencyOnComleteTest(round);
        this.calculateArea(round);
    }

    ngAfterViewInit(): void {
        const SVGwidth = this.svg.nativeElement.getBoundingClientRect().width;
        this.xAxisGenerator = scaleLinear()
            .domain([0, this.ROUND_TEST_LENGTH])
            .range([0, SVGwidth - this.padding.left - this.padding.right]);

        this.width = SVGwidth - this.padding.left - this.padding.right;
        this.widthPerSecond = this.width / this.ROUND_TEST_LENGTH;

        // if (this.mode === ENVIRONMENTS.TEST) {
        // const SVG = select(this.svg.nativeElement),
        //     SVGwidth = this.svg.nativeElement.getBoundingClientRect().width,
        //     SVGHeight = this.svg.nativeElement.getBoundingClientRect().height;
        // const xAxisGenerator = scaleLinear()
        //     .domain([0, this.ROUND_TEST_LENGTH])
        //     .range([0, SVGwidth - this.padding.left - this.padding.right]);
        // const yAxisGeneratorDiameter = scaleLinear()
        //     .domain([6, -1])
        //     .range([0, SVGHeight - this.padding.top - this.padding.bottom]);
        // const yAxisGeneratorDeviations = scaleLinear()
        //     .domain([0.01, -0.01])
        //     .range([0, SVGHeight - this.padding.top - yAxisGeneratorDiameter(0)]);
        // SVG.append('g')
        //     .attr('class', 'xAxis')
        //     .attr('transform', `translate(${this.padding.left},${this.padding.top + yAxisGeneratorDiameter(0)})`)
        //     .attr('color', 'white')
        //     .call(axisBottom(xAxisGenerator).ticks(25));
        // SVG.append('g')
        //     .attr('class', 'yAxisDiameter')
        //     .attr('transform', `translate(${this.padding.left},${this.padding.top})`)
        //     .attr('color', 'white')
        //     .call(axisLeft(yAxisGeneratorDiameter).ticks(5));
        // // SVG.append('g')
        // //     .attr('class', 'yAxisDeviations')
        // //     .attr('transform', `translate(${this.padding.left},${SVGHeight - this.padding.top - yAxisGeneratorDiameter(4)})`)
        // //     .attr('color', 'white')
        // //     .call(axisLeft(yAxisGeneratorDeviations).ticks(5));
        // this.deviationsChart = SVG.append('g')
        //     .attr('class', 'deviationsChart')
        //     .attr('transform', `translate(${this.padding.left},${this.padding.top})`);
        // this.diameterLineGenerator = line<{ x: number; y: number }>()
        //     .x(d => xAxisGenerator(d.x))
        //     .y(d => yAxisGeneratorDiameter(d.y));
        // this.deviationsLineGenerator = line<{ x: number; y: number }>()
        //     .x(d => xAxisGenerator(d.x))
        //     .y(d => yAxisGeneratorDeviations(d.y));
        // }
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.run.subscribe(run => {
                this.clearPupilTestResults();

                if (run === null) return;

                this.round = run;
                this.calculateTestResults(run);

                if (this.pupilDiameterResults.averageDiameterOD.length !== 0) {
                    this.pupilDiameterResults.tableWidth = this.width;
                    this.pupilDiameterTableComponent.testResults$.next(this.pupilDiameterResults);
                }

                if (this.latencyTableComponent.itemsDifference.length !== 0) {
                    this.latencyResult.tableWidth = this.width;
                    this.latencyTableComponent.testResults$.next(this.latencyResult);
                }

                if (this.peakVelocityTableComponent.itemsDifference.length !== 0) {
                    this.peakVelocityResult.tableWidth = this.width;
                    this.peakVelocityTableComponent.testResults$.next(this.peakVelocityResult);
                }

                if (this.areaTableComponent.itemsDifference.length !== 0) {
                    this.areaTableComponent.testResults$.next(this.areaTableResults);
                }

                this.showResultTables = true;

                // this.drawLightLines.emit(this.ligthLinesCoordinates);
                // this.applyRun(run);
            })
        );
        this.subscriptions.push(
            combineLatest<Observable<string>, Observable<string>, Observable<string>, Observable<string>>([
                this.CUT_AROUND_ZERO_RANGE.valueChanges.pipe(debounceTime(300)),
                this.MOVING_AVERAGE_SIZE.valueChanges.pipe(debounceTime(300)),
                this.FIRST_DEVIATION_GAP.valueChanges.pipe(debounceTime(300)),
                this.SECOND_DEVIATION_GAP.valueChanges.pipe(debounceTime(300)),
            ]).subscribe(([CUT_AROUND_ZERO_RANGE, MOVING_AVERAGE_SIZE, FIRST_DEVIATION_GAP, SECOND_DEVIATION_GAP]) => {
                if (this.data) {
                    this.clearLatencies.emit();
                    this.calculateLatencyOnComleteTest(this.round);
                    this.applyRun(this.run.value);
                }
            })
        );
        this.subscriptions.push(
            this.pupilTestChartService.frameData$.subscribe(frame => {
                if (frame === null) return;
                let localFrame = { ...frame };
                switch (localFrame.message_type) {
                    case MESSAGE_TYPE.START_TEST:
                        this.data = [];
                        this.blocks = [];
                        this.pupilDiameterBlocks = [];
                        this.dilationRanges = [];
                        this.constrictionRanges = [];
                        this.blocksCounter = 0;
                        break;
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        this.startTimestamp = localFrame.timestamp / 10000;
                        this.data.push([]);
                        this.currentPoint = [];
                        this.data[this.data.length - 1].push(localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        this.startTimestamp = null;
                        this.blocks.push({
                            round: this.blocks.length,
                            dilationRanges: this.dilationRanges,
                            constrictionsRanges: this.constrictionRanges,
                        });
                        this.pupilDiameterBlocks.push(this.pupilDiameterBlock);

                        this.pupilDiameterBlock = [];
                        this.dilationRanges = [];
                        this.constrictionRanges = [];
                        this.blocksCounter = 0;
                        break;
                    case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                        this.startFrame = localFrame;
                        this.data[this.data.length - 1].push(localFrame);
                        this.pupilDiameterBlock.push(localFrame);
                        this.startBlockTime = localFrame.timestamp / 10000;

                        this.blocksCounter++;
                        if (this.endsDilationRanges.includes(this.blocksCounter)) {
                            this.dilationRanges.push({ dilationBlocks: this.dilationBlocks });
                            this.dilationBlocks = [];
                            this.constrictionsBlocks = [];
                        }
                        if (this.endsConstrictionRanges.includes(this.blocksCounter)) {
                            this.constrictionRanges.push({ constrictionsBlocks: this.constrictionsBlocks });
                            this.dilationBlocks = [];
                            this.constrictionsBlocks = [];
                        }

                        break;
                    case MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(localFrame);
                        this.pupilDiameterBlock.push(localFrame);

                        const currentBlockTime = this.chartService.getXvalue(this.startBlockTime, localFrame.timestamp);
                        const factorBySecond = currentBlockTime / 1;
                        const blockWidth = this.widthPerSecond * factorBySecond;

                        if (this.pupilDiameterResults.tableCellWidths.length < this.ROUND_TEST_LENGTH) {
                            this.pupilDiameterResults.tableCellWidths.push(blockWidth);
                        }

                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        this.data[this.data.length - 1].push(localFrame);

                        const pupilTestResults: ExportTableTestResults[] = [];

                        // data for export
                        for (let i = 0; i < this.MAX_ROUND; i++) {
                            this.clearPupilTestResults();
                            this.calculateTestResults(i);

                            pupilTestResults.push({
                                round: i + 1,
                                pupilDiameterResults: this.exportPupilDiameterResults,
                                latencyResults: this.exportLatencyResult,
                                areaResults: this.exportAreaResult,
                                peakVelocityResults: this.exportPeakVelocityResult,
                            });
                        }

                        this.pupilTestChartService.pupilTestResults$.next(pupilTestResults);

                        break;
                    default:
                        this.pupilDiameterBlock.push(localFrame);
                        if (this.numbersDilationBlocks.includes(this.blocksCounter)) {
                            this.dilationBlocks.push(localFrame);
                        } else if (this.numbersConstrictionBlocks.includes(this.blocksCounter)) {
                            this.constrictionsBlocks.push(localFrame);
                        }
                        this.data[this.data.length - 1].push(localFrame);
                        break;
                }
            })
        );
        this.CUT_AROUND_ZERO_RANGE.setValue(50);
        this.MOVING_AVERAGE_SIZE.setValue(20);
        this.FIRST_DEVIATION_GAP.setValue(10);
        this.SECOND_DEVIATION_GAP.setValue(10);
    }

    private applyRun(run: number): void {
        const lineODremovedZeros: { x: number; y: number }[] = [],
            lineOSremovedZeros: { x: number; y: number }[] = [],
            lineODmovingAverage: { x: number; y: number }[] = [],
            lineOSmovingAverage: { x: number; y: number }[] = [],
            lineODfd: { x: number; y: number }[] = [],
            lineOSfd: { x: number; y: number }[] = [],
            lineODsd: { x: number; y: number }[] = [],
            lineOSsd: { x: number; y: number }[] = [];
        // this.deviationsChart.selectAll('.withoutZeros').remove();
        // this.deviationsChart.selectAll('.movingAverage').remove();
        // this.deviationsChart.selectAll('.firstDeviation').remove();
        // this.deviationsChart.selectAll('.secondDeviation').remove();
        // this.deviationsChart.selectAll('.latency').remove();
        this.startTimestamp = this.data[run][0].timestamp / 10000;
        this.data[run].forEach(frame => {
            switch (frame.message_type) {
                case MESSAGE_TYPE.DATA_PACKAGE:
                    if (frame.measurementODwithoutZeros)
                        lineODremovedZeros.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.measurementODwithoutZeros,
                        });
                    if (frame.measurementOSwithoutZeros)
                        lineOSremovedZeros.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.measurementOSwithoutZeros,
                        });

                    if (frame.movingAverageOD)
                        lineODmovingAverage.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.movingAverageOD,
                        });
                    if (frame.movingAverageOS)
                        lineOSmovingAverage.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.movingAverageOS,
                        });

                    if (frame.firstDeviationOD)
                        lineODfd.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.firstDeviationOD,
                        });
                    if (frame.firstDeviationOS)
                        lineOSfd.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.firstDeviationOS,
                        });

                    if (frame.secondDeviationOD)
                        lineODsd.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.secondDeviationOD,
                        });
                    if (frame.secondDeviationOS)
                        lineOSsd.push({
                            x: this.chartService.getXvalue(this.startTimestamp, frame.timestamp),
                            y: frame.secondDeviationOS,
                        });
                    break;
                default:
                    break;
            }
        });
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} withoutZeros OD`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'green')
        //     .attr('stroke-width', '1px')
        //     .datum(lineODremovedZeros)
        //     .attr('d', this.diameterLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} withoutZeros OS`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'yellow')
        //     .attr('stroke-width', '1px')
        //     .datum(lineOSremovedZeros)
        //     .attr('d', this.diameterLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} movingAverage OD`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'green')
        //     .attr('stroke-width', '1px')
        //     .datum(lineODmovingAverage)
        //     .attr('d', this.diameterLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} movingAverage OS`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'yellow')
        //     .attr('stroke-width', '1px')
        //     .datum(lineOSmovingAverage)
        //     .attr('d', this.diameterLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} firstDeviation OD`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'green')
        //     .attr('stroke-width', '1px')
        //     .datum(lineODfd)
        //     .attr('d', this.deviationsLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} firstDeviation OS`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'yellow')
        //     .attr('stroke-width', '1px')
        //     .datum(lineOSfd)
        //     .attr('d', this.deviationsLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} secondDeviation OD`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'green')
        //     .attr('stroke-width', '1px')
        //     .datum(lineODsd)
        //     .attr('d', this.deviationsLineGenerator);
        // this.deviationsChart
        //     .append('path')
        //     .attr('class', `round${run} secondDeviation OS`)
        //     .attr('fill', 'none')
        //     .attr('stroke', 'yellow')
        //     .attr('stroke-width', '1px')
        //     .datum(lineOSsd)
        //     .attr('d', this.deviationsLineGenerator);

        this.markers[run].OD.forEach(ts =>
            this.deviationsChart
                .append('path')
                .attr('class', `latency`)
                .attr('fill', 'none')
                .attr('stroke', 'green')
                .attr('stroke-width', '1px')
                .attr('stroke-dasharray', '3 10')
                .datum([
                    {
                        x: this.chartService.getXvalue(this.startTimestamp, ts),
                        y: -1,
                    },
                    {
                        x: this.chartService.getXvalue(this.startTimestamp, ts),
                        y: 6,
                    },
                ])
                .attr('d', this.diameterLineGenerator)
        );
        this.markers[run].OS.forEach(ts =>
            this.deviationsChart
                .append('path')
                .attr('class', `latency`)
                .attr('fill', 'none')
                .attr('stroke', 'yellow')
                .attr('stroke-width', '1px')
                .attr('stroke-dasharray', '3 10')
                .datum([
                    {
                        x: this.chartService.getXvalue(this.startTimestamp, ts),
                        y: -1,
                    },
                    {
                        x: this.chartService.getXvalue(this.startTimestamp, ts),
                        y: 6,
                    },
                ])
                .attr('d', this.diameterLineGenerator)
        );
    }

    private measure(data: IPupil20TestCamMessage[][], round: number): void {
        if (data[round].length === 0) return;

        const endTimes: number[] = [];
        const startTimes: number[] = [];

        this.startTimestamp = data[round][0].timestamp / 10000;
        this.pupilDiameterResults.shiftTypes = [];
        const filteredBlocks = data[round].filter(frame => frame.message_type === MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION);

        filteredBlocks.forEach((frame, i) => {
            this.currentPoint.push(frame);

            // taking every second valid block to calculate diameter
            if (this.validBlocks.includes(this.currentPoint.length) && this.currentPoint.length < this.LAST_VALID_BLOCK) {
                const startTime: number = this.chartService.getXvalue(this.startTimestamp, frame.timestamp);
                const startFrameIndex: number = data[round].findIndex(frame => this.chartService.getXvalue(this.startTimestamp, frame.timestamp) > startTime);

                const endIndex: number =
                    data[round].slice(startFrameIndex).findIndex(frame => frame.message_type === MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION) + startFrameIndex;
                const endFrameIndex: number = endIndex > startFrameIndex ? endIndex : data[round].length - 1;

                const measuredFrames: IPupil20TestCamMessage[] = data[round].slice(startFrameIndex, endFrameIndex);
                const averageDiameterOD: number = measuredFrames.filter(f => f.movingAverageOD).map(f => f.movingAverageOD)[0];
                const averageDiameterOS: number = measuredFrames.filter(f => f.movingAverageOS).map(f => f.movingAverageOS)[0];

                const lastElement = measuredFrames.find(f => f.message_type === MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION);
                const endTimestamp = lastElement ? lastElement.timestamp : measuredFrames[measuredFrames.length - 1].timestamp;

                const xValue = this.currentPoint.length !== this.validBlocks[this.validBlocks.length - 1] ? frame.timestamp : endTimestamp;
                const xRange = this.currentPoint.length !== this.validBlocks[this.validBlocks.length - 1] ? frame.timestamp : endTimestamp;
                const endTime = this.chartService.getXvalue(this.startTimestamp, endTimestamp);

                this.pupilDiameterResults.averageDiameterOD.push(averageDiameterOD);
                this.pupilDiameterResults.averageDiameterOS.push(averageDiameterOS);
                this.pupilDiameterResults.differenceDiameter.push(averageDiameterOD - averageDiameterOS);
                this.pupilDiameterResults.shiftTypes.push(SHIFT_TYPE.NO_CONTENT);

                this.exportPupilDiameterResults.push({
                    averageDiameterOD: averageDiameterOD,
                    averageDiameterOS: averageDiameterOS,
                    differenceDiameter: averageDiameterOD - averageDiameterOS,
                    startTime: null,
                    endTime: null,
                });

                this.pupilDiameterResults.testResultCoors.push({
                    run: round,
                    deltaSingleOculus: false,
                    xValue: xValue,
                    deltaXvalue: null,
                    xRange: xRange,
                    yValue: null,
                    deltaYvalue: null,
                });

                if (this.currentPoint.length === 15 || this.currentPoint.length === 25) {
                    this.ligthLinesCoordinates.lightCoordinatesOD.push({
                        run: round,
                        deltaSingleOculus: true,
                        xValue: xValue,
                        yValue: averageDiameterOD,
                        deltaXvalue: this.tmpPrevDeltaAverage.timestamp,
                        xRange: null,
                        deltaYvalue: this.tmpPrevDeltaAverage.averageValueOS,
                    });
                    this.ligthLinesCoordinates.lightCoordinatesOS.push({
                        run: round,
                        deltaSingleOculus: true,
                        xValue: xValue,
                        yValue: averageDiameterOS,
                        deltaXvalue: this.tmpPrevDeltaAverage.timestamp,
                        xRange: null,
                        deltaYvalue: this.tmpPrevDeltaAverage.averageValueOS,
                    });
                }

                if (this.currentPoint.length === 10 || this.currentPoint.length === 20) {
                    this.tmpPrevDeltaAverage.averageValueOD = averageDiameterOD;
                    this.tmpPrevDeltaAverage.averageValueOS = averageDiameterOS;
                    this.tmpPrevDeltaAverage.timestamp = endTimestamp;
                }
            }

            // calculate coordinates for all valid blocks
            if (this.coordinatesValidBlocks.includes(this.currentPoint.length)) {
                const startTime: number = this.chartService.getXvalue(this.startTimestamp, frame.timestamp);
                const startFrameIndex: number = data[round].findIndex(frame => this.chartService.getXvalue(this.startTimestamp, frame.timestamp) > startTime);

                const endIndex: number =
                    data[round].slice(startFrameIndex).findIndex(frame => frame.message_type === MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION) + startFrameIndex;
                const endFrameIndex: number = endIndex > startFrameIndex ? endIndex : data[round].length - 1;

                const measuredFrames: IPupil20TestCamMessage[] = data[round].slice(startFrameIndex, endFrameIndex);
                const lastElement = measuredFrames.find(f => f.message_type === MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION);
                const endTimestamp = lastElement ? lastElement.timestamp : measuredFrames[measuredFrames.length - 1].timestamp;
                const xValue = endTimestamp;
                const xRange = endTimestamp;

                this.pupilDiameterResults.testResultCoors.push({
                    run: round,
                    deltaSingleOculus: false,
                    xValue: xValue,
                    deltaXvalue: null,
                    xRange: xRange,
                    yValue: null,
                    deltaYvalue: null,
                });
            }

            if (this.endBlocks.includes(this.currentPoint.length)) {
                const startTime: number = this.chartService.getXvalue(this.startTimestamp, frame.timestamp);
                const startFrameIndex: number = data[round].findIndex(frame => this.chartService.getXvalue(this.startTimestamp, frame.timestamp) > startTime);

                const endIndex: number =
                    data[round].slice(startFrameIndex).findIndex(frame => frame.message_type === MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION) + startFrameIndex;
                const endFrameIndex: number = endIndex > startFrameIndex ? endIndex : data[round].length - 1;

                const measuredFrames: IPupil20TestCamMessage[] = data[round].slice(startFrameIndex, endFrameIndex);
                const lastElement = measuredFrames.find(f => f.message_type === MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION);
                const endTimestamp = lastElement ? lastElement.timestamp : measuredFrames[measuredFrames.length - 1].timestamp;
                const endTime = this.chartService.getXvalue(this.startTimestamp, endTimestamp);

                endTimes.push(endTime);
            }

            if (this.startBlocks.includes(this.currentPoint.length)) {
                const startTime: number = this.chartService.getXvalue(this.startTimestamp, frame.timestamp);

                startTimes.push(startTime);
            }

            // last block for export
            if (filteredBlocks.length - 1 === i) {
                const startTime: number = this.chartService.getXvalue(this.startTimestamp, frame.timestamp);
                const startFrameIndex: number = data[round].findIndex(frame => this.chartService.getXvalue(this.startTimestamp, frame.timestamp) > startTime);

                const endIndex: number =
                    data[round].slice(startFrameIndex).findIndex(frame => frame.message_type === MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION) + startFrameIndex;
                const endFrameIndex: number = endIndex > startFrameIndex ? endIndex : data[round].length - 1;

                const measuredFrames: IPupil20TestCamMessage[] = data[round].slice(startFrameIndex, endFrameIndex);
                const lastElement = measuredFrames.find(f => f.message_type === MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION);
                const endTimestamp = lastElement ? lastElement.timestamp : measuredFrames[measuredFrames.length - 1].timestamp;
                const endTime = this.chartService.getXvalue(this.startTimestamp, endTimestamp);

                startTimes.push(startTime);
                endTimes.push(endTime);
            }
        });

        this.exportPupilDiameterResults.forEach((r, i) => {
            if (i < endTimes.length) r.endTime = endTimes[i];

            if (i < startTimes.length) r.startTime = startTimes[i];
        });

        const initialLength = this.pupilDiameterResults.averageDiameterOD.length;
        const step = 2;
        const iterationCount = (initialLength - 1) * 2;
        const averageDiametersOD = this.pupilDiameterResults.averageDiameterOD;
        const averageDiametersOS = this.pupilDiameterResults.averageDiameterOS;
        const differenceDiameter = this.pupilDiameterResults.differenceDiameter;
        const shifTypes = this.pupilDiameterResults.shiftTypes;

        for (let i = 1; i < iterationCount; i = i + step) {
            const differenceValueOD = averageDiametersOD[i] - averageDiametersOD[i - 1];
            const differenceValueOS = averageDiametersOS[i] - averageDiametersOS[i - 1];
            const shifType = averageDiametersOD[i] > averageDiametersOD[i - 1] ? SHIFT_TYPE.UPGRADE : SHIFT_TYPE.DOWNGRADE;

            if (i + step < iterationCount) {
                averageDiametersOD.splice(i, 0, differenceValueOD);
                averageDiametersOS.splice(i, 0, differenceValueOS);
                differenceDiameter.splice(i, 0, differenceValueOD - differenceValueOS);
                shifTypes.splice(i, 0, shifType);

                const index = this.exportPupilDiameterResults.findIndex(p => !p.diameterChangeOD);
                this.exportPupilDiameterResults[index].diameterChangeOD = differenceValueOD;
                this.exportPupilDiameterResults[index].diameterChangeOS = differenceValueOS;
                this.exportPupilDiameterResults[index].differenceDiameterChange = differenceValueOD - differenceValueOS;
            } else {
                // last block should be calculated separatly
                averageDiametersOD[i] = differenceValueOD;
                averageDiametersOS[i] = differenceValueOS;
                differenceDiameter[i] = differenceValueOD - differenceValueOS;
                shifTypes[i] = shifType;

                const index = this.exportPupilDiameterResults.findIndex(p => !p.diameterChangeOD);
                this.exportPupilDiameterResults[index].diameterChangeOD = differenceValueOD;
                this.exportPupilDiameterResults[index].diameterChangeOS = differenceValueOS;
                this.exportPupilDiameterResults[index].differenceDiameterChange = differenceValueOD - differenceValueOS;
            }
        }
    }

    private calculateArea(round: number): void {
        const dilationLeftLimit = this.dilationLeftLimit;
        const dilationRightLimit = this.dilationRightLimit;
        const constrictionLeftLimit = this.constrictionLeftLimit;
        const constrictionRightLimit = this.constrictionRightLimit;

        this.blocks[round].dilationRanges.forEach((range, i) => {
            this.startTimestamp = this.data[this.blocks[round].round][0].timestamp / 10000;

            this.interpolateGap(range.dilationBlocks, round);
            this.interpolateGap(this.blocks[round].constrictionsRanges[i].constrictionsBlocks, round);

            const ma: number = Number.parseInt(this.MOVING_AVERAGE_SIZE.value);

            range.dilationBlocks.forEach((d, i, collector) => {
                if (i >= ma) {
                    d.movingAverageOD =
                        collector
                            .slice(i - ma, i + ma)
                            .filter(frame => frame.measurementODwithoutZeros)
                            .reduce((partial_sum, a) => partial_sum + a.measurementODwithoutZeros, 0) /
                        collector.slice(i - ma, i + ma).filter(frame => frame.measurementODwithoutZeros).length;
                    d.movingAverageOS =
                        collector
                            .slice(i - ma, i + ma)
                            .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                            .reduce((partial_sum, a) => partial_sum + a.measurementOSwithoutZeros, 0) /
                        collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
                }
            });
            this.blocks[round].constrictionsRanges[i].constrictionsBlocks.forEach((d, i, collector) => {
                if (i >= ma) {
                    d.movingAverageOD =
                        collector
                            .slice(i - ma, i + ma)
                            .filter(frame => frame.measurementODwithoutZeros)
                            .reduce((partial_sum, a) => partial_sum + a.measurementODwithoutZeros, 0) /
                        collector.slice(i - ma, i + ma).filter(frame => frame.measurementODwithoutZeros).length;
                    d.movingAverageOS =
                        collector
                            .slice(i - ma, i + ma)
                            .filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE)
                            .reduce((partial_sum, a) => partial_sum + a.measurementOSwithoutZeros, 0) /
                        collector.slice(i - ma, i + ma).filter(frame => frame.message_type === MESSAGE_TYPE.DATA_PACKAGE).length;
                }
            });

            const dilationResult = this.calculateAreaPerRange(range.dilationBlocks, dilationLeftLimit, dilationRightLimit);

            const constrictionFrames = this.blocks[round].constrictionsRanges[i].constrictionsBlocks;
            const constrictionResult = this.calculateAreaPerRange(constrictionFrames, constrictionLeftLimit, constrictionRightLimit);

            dilationResult.testResultCoors.run = this.blocks[round].round;
            constrictionResult.testResultCoors.run = this.blocks[round].round;

            this.areaResult.areaOD.push(dilationResult.areaOD);
            this.areaResult.areaOS.push(dilationResult.areaOS);
            this.areaResult.areaDifference.push(dilationResult.areaDifference);
            this.areaResult.testResultCoors.push(dilationResult.testResultCoors);

            this.exportAreaResult.push({
                areaOD: dilationResult.areaOD,
                areaOS: dilationResult.areaOS,
                areaDifference: dilationResult.areaDifference,
                startTime: dilationResult.startTime,
                endTime: dilationResult.endTime,
                leftLimit: dilationLeftLimit,
                rightLimit: dilationRightLimit,
            });

            this.areaResult.areaOD.push(constrictionResult.areaOD);
            this.areaResult.areaOS.push(constrictionResult.areaOS);
            this.areaResult.areaDifference.push(constrictionResult.areaDifference);
            this.areaResult.testResultCoors.push(constrictionResult.testResultCoors);

            this.exportAreaResult.push({
                areaOD: constrictionResult.areaOD,
                areaOS: constrictionResult.areaOS,
                areaDifference: constrictionResult.areaDifference,
                startTime: constrictionResult.startTime,
                endTime: constrictionResult.endTime,
                leftLimit: constrictionLeftLimit,
                rightLimit: constrictionRightLimit,
            });
        });
        this.areaResult.tableCellWidths = this.pupilDiameterResults.tableCellWidths;

        const isAcceptable = this.areaResult.areaOD.some(result => !result === null);

        this.areaTableResults.areaResults = this.areaResult;
        this.areaTableResults.resultType = isAcceptable ? AREA_RESULT_TYPE.ACCEPTABLE : AREA_RESULT_TYPE.NOT_ACCEPTABLE;
    }

    private calculateAreaPerRange(
        rangeFrames: IPupil20TestCamMessage[],
        leftLimit: number,
        rightLimit: number
    ): { areaOD: number; areaOS: number; areaDifference: number; testResultCoors: TestResultCoordinates; startTime: number; endTime: number } {
        const isLimitIndex = (frames: IPupil20TestCamMessage[], frame: IPupil20TestCamMessage, limit: number): boolean => {
            const startTimestamp = frames[0].timestamp / 10000;
            const currentTime = this.chartService.getXvalue(startTimestamp, frame.timestamp);

            return currentTime >= limit;
        };

        const startIndex = rangeFrames.findIndex((frame, i) => isLimitIndex(rangeFrames, frame, leftLimit));

        if (startIndex === -1) return { areaOD: null, areaOS: null, areaDifference: null, testResultCoors: this.testCoordinates, startTime: null, endTime: null };

        const slicedFrames = rangeFrames.slice(startIndex);

        const endIndex = slicedFrames.findIndex((frame, i) => isLimitIndex(slicedFrames, frame, rightLimit)) + startIndex;

        if (endIndex < startIndex) return { areaOD: null, areaOS: null, areaDifference: null, testResultCoors: this.testCoordinates, startTime: null, endTime: null };

        let diametersSummOD = 0;
        let diametersSummOS = 0;
        const xAreaValues: number[] = [];
        const yAreaValuesOD: number[] = [];
        const yAreaValuesOS: number[] = [];

        const length = rangeFrames.slice(startIndex, endIndex).filter(f => typeof f.movingAverageOD === 'number' && typeof f.movingAverageOS === 'number').length;

        rangeFrames
            .slice(startIndex, endIndex)
            .filter(f => typeof f.movingAverageOD === 'number' && typeof f.movingAverageOS === 'number')
            .forEach(frame => {
                diametersSummOD += frame.movingAverageOD;
                diametersSummOS += frame.movingAverageOS;
                yAreaValuesOD.push(frame.movingAverageOD);
                yAreaValuesOS.push(frame.movingAverageOS);
                xAreaValues.push(frame.timestamp);
            });

        const areaTableLineEnd = this.AREA_HOVER_LINE_END_POINT_Y;

        const areaOD = (diametersSummOD / length) * rightLimit;
        const areaOS = (diametersSummOS / length) * rightLimit;
        const areaDifference = areaOD - areaOS;
        const startTime = this.chartService.getXvalue(this.startTimestamp, rangeFrames[startIndex].timestamp);
        const endTime = this.chartService.getXvalue(this.startTimestamp, rangeFrames[endIndex].timestamp);
        const testResultCoors: TestResultCoordinates = {
            xValue: rangeFrames[startIndex].timestamp,
            xSecondValue: rangeFrames[endIndex].timestamp,
            yValue: areaTableLineEnd,
            xAreaValues: xAreaValues,
            yAreaValuesOS: yAreaValuesOS,
            yAreaValuesOD: yAreaValuesOD,
            oculus: OCULUS.OD,
        };

        return { areaOD, areaOS, areaDifference, testResultCoors, startTime, endTime };
    }

    private calculateLatencyOnComleteTest(round: number): void {
        this.markers = [];
        this.latencyResult = {
            latencyOD: [],
            latencyOS: [],
            differenceLatency: [],
            testResultCoors: [],
            tableCellWidths: [],
            endTimeOD: [],
            endTimeOS: [],
            startTime: [],
            tableWidth: this.width,
        };

        const fd: number = Number.parseInt(this.FIRST_DEVIATION_GAP.value);
        this.data[round].forEach((d, i, collector) => {
            if (i >= fd / 2 && i < collector.length - fd / 2) {
                const firstDeviationOD = (collector[i - fd / 2].movingAverageOD - collector[i + fd / 2].movingAverageOD) / (fd + 1);
                const firstDeviationOS = (collector[i - fd / 2].movingAverageOS - collector[i + fd / 2].movingAverageOS) / (fd + 1);

                if (!firstDeviationOD || !firstDeviationOS) return;

                d.firstDeviationOD = firstDeviationOD;
                d.firstDeviationOS = firstDeviationOS;
            }
        });

        const sma: number = Number.parseInt(this.SECOND_MOVING_AVERAGE_SIZE.value);
        const ma: number = Number.parseInt(this.MOVING_AVERAGE_SIZE.value);

        this.data[round].forEach(frame => {
            if (!frame.firstDeviationOD) frame.firstDeviationOD = 0;
            else if (!frame.firstDeviationOS) frame.firstDeviationOS = 0;
            return frame;
        });

        this.data[round].forEach((d, i, collector) => {
            if (i >= sma + ma) {
                d.secondMovingAverageOD =
                    collector
                        .slice(i - sma, i + sma)
                        // .filter(frame => frame.firstDeviationOD)
                        .reduce((partial_sum, a) => partial_sum + a?.firstDeviationOD, 0) /
                    (sma * 2);
                d.secondMovingAverageOS =
                    collector
                        .slice(i - sma, i + sma)
                        // .filter(frame => frame.firstDeviationOD)
                        .reduce((partial_sum, a) => partial_sum + a?.firstDeviationOS, 0) /
                    (sma * 2);
            }
        });

        this.data[round].forEach(frame => {
            if (!frame.movingAverageOD) frame.movingAverageOD = 0;
            else if (!frame.movingAverageOS) frame.movingAverageOS = 0;
            return frame;
        });

        const sd: number = Number.parseInt(this.SECOND_DEVIATION_GAP.value);
        this.data[round].forEach((d, i, collector) => {
            if (i >= sd && i < collector.length - 1 - sd) {
                const secondDeviationOD = collector[i - sd].secondMovingAverageOD - collector[i + sd].secondMovingAverageOD;
                const secondDeviationOS = collector[i - sd].secondMovingAverageOS - collector[i + sd].secondMovingAverageOS;

                if (!secondDeviationOD || !secondDeviationOS) return;

                d.secondDeviationOD = secondDeviationOD;
                d.secondDeviationOS = secondDeviationOS;
            }
        });

        // peak velocity calculation
        const pvGAP: number = 10;
        this.data[round].forEach((d, i, collector) => {
            if (typeof collector[i - pvGAP]?.movingAverageOD === 'number' && typeof collector[i + pvGAP]?.movingAverageOD === 'number') {
                d.velocityOD =
                    (collector[i + pvGAP].movingAverageOD - collector[i - pvGAP].movingAverageOD) /
                    ((collector[i + pvGAP].timestamp / 10000 - collector[i - pvGAP].timestamp / 10000) / 1000);
            }
            if (typeof collector[i - pvGAP]?.movingAverageOS === 'number' && typeof collector[i + pvGAP]?.movingAverageOS === 'number') {
                d.velocityOS =
                    (collector[i + pvGAP].movingAverageOS - collector[i - pvGAP].movingAverageOS) /
                    ((collector[i + pvGAP].timestamp / 10000 - collector[i - pvGAP].timestamp / 10000) / 1000);
            }
            d.timePassed = (d.timestamp - collector[0].timestamp) / 10000 / 1000;
        });

        let thirdBlock: IPupil20TestCamMessage[] = [],
            eighthBlock: IPupil20TestCamMessage[] = [],
            thirteenthBlock: IPupil20TestCamMessage[] = [],
            blocksCnt: IPupil20TestCamMessage[] = [];
        this.data[round].forEach(point => {
            if (point.message_type === MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION) blocksCnt.push(point);
            if (blocksCnt.length === 4 || blocksCnt.length === 5) thirdBlock.push(point);
            if (blocksCnt.length === 9 || blocksCnt.length === 10) eighthBlock.push(point);
            if (blocksCnt.length === 14 || blocksCnt.length === 15) thirteenthBlock.push(point);
        });
        this.markers.push({
            OD: [],
            OS: [],
        });
        this.calcBlock(thirdBlock, round);
        this.exportPeakVelocity(thirdBlock, round);
        this.calcBlock(eighthBlock, round);
        this.exportPeakVelocity(eighthBlock, round);
        this.calcBlock(thirteenthBlock, round);
        this.exportPeakVelocity(thirteenthBlock, round);

        this.latencyResult.tableCellWidths = this.pupilDiameterResults.tableCellWidths;

        this.peakVelocityResult.tableCellWidths = this.pupilDiameterResults.tableCellWidths;
    }

    private interpolateGap(data: IPupil20TestCamMessage[], round: number) {
        let firstZeroIndex: number, lastZeroIndex: number, isZeroFound: boolean;
        const cutStart: number = Number.parseInt('20');
        const cutEnd: number = Number.parseInt('50');

        data.forEach((forOD, indexForOD) => {
            if (forOD.measurementOD > 0 && !isZeroFound && !forOD.measurementODwithoutZeros) {
                forOD.measurementODwithoutZeros = forOD.measurementOD;
            } else if (forOD.measurementOD > 0 && isZeroFound) {
                lastZeroIndex = indexForOD;
                isZeroFound = false;
                if (data[firstZeroIndex - cutStart]?.measurementOD) {
                    const startNormalIndex: number = firstZeroIndex - cutStart;
                    const endNormalIndex: number = lastZeroIndex + cutEnd <= data.length - 1 ? lastZeroIndex + cutEnd : data.length - 1;

                    const startNormalODvalue: number = data[startNormalIndex].measurementOD;

                    let endNormalODvalue: number = data[endNormalIndex]?.measurementOD;
                    if (!endNormalODvalue) {
                        const endElement = data.slice(endNormalIndex).find(frame => frame.measurementOD);
                        endNormalODvalue = endElement ? endElement.measurementOD : data[data.length - 1]?.measurementOD;
                    }

                    const valuesDiff: number = endNormalODvalue - startNormalODvalue,
                        stepsWithZeros: number = endNormalIndex - startNormalIndex,
                        oneStepAverage: number = valuesDiff / stepsWithZeros;
                    for (let j = startNormalIndex, k = 0; j < endNormalIndex; j++, k++) {
                        data[j].measurementODwithoutZeros = startNormalODvalue + oneStepAverage * k;
                    }
                }
            } else if (forOD.measurementOD === 0 && !isZeroFound) {
                isZeroFound = true;
                firstZeroIndex = indexForOD;
            }
        });

        firstZeroIndex = undefined;
        lastZeroIndex = undefined;
        isZeroFound = undefined;
        data.forEach((forOS, indexForOS) => {
            if (forOS.measurementOS > 0 && !isZeroFound && !forOS.measurementOSwithoutZeros) {
                forOS.measurementOSwithoutZeros = forOS.measurementOS;
            } else if (forOS.measurementOS > 0 && isZeroFound) {
                lastZeroIndex = indexForOS;
                isZeroFound = false;
                if (data[firstZeroIndex - cutStart]?.measurementOS) {
                    const startNormalIndex: number = firstZeroIndex - cutStart;
                    const endNormalIndex: number = lastZeroIndex + cutEnd <= data.length - 1 ? lastZeroIndex + cutEnd : data.length - 1;

                    const startNormalOSvalue: number = data[startNormalIndex].measurementOS;

                    let endNormalOSvalue: number = data[endNormalIndex]?.measurementOS;
                    if (!endNormalOSvalue) {
                        const endElement = data.slice(endNormalIndex).find(frame => frame.measurementOS);
                        endNormalOSvalue = endElement ? endElement.measurementOS : data[data.length - 1]?.measurementOS;
                    }

                    const valuesDiff: number = endNormalOSvalue - startNormalOSvalue,
                        stepsWithZeros: number = endNormalIndex - startNormalIndex,
                        oneStepAverage: number = valuesDiff / stepsWithZeros;
                    for (let j = startNormalIndex, k = 0; j < endNormalIndex; j++, k++) {
                        data[j].measurementOSwithoutZeros = startNormalOSvalue + oneStepAverage * k;
                    }
                }
            } else if (forOS.measurementOS === 0 && !isZeroFound) {
                isZeroFound = true;
                firstZeroIndex = indexForOS;
            } else if (isZeroFound && indexForOS === data.length - 1) {
                if (data[firstZeroIndex - cutStart]?.measurementOS) {
                    const startNormalIndex: number = firstZeroIndex - cutStart;
                    const endNormalIndex: number = data.length - 1;

                    const startNormalOSvalue: number = data[startNormalIndex].measurementOS;

                    for (let j = startNormalIndex, k = 0; j < endNormalIndex; j++, k++) {
                        data[j].measurementOSwithoutZeros = startNormalOSvalue;
                    }
                }
            }
        });

        return data;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.pupilTestChartService.frameData$ = new BehaviorSubject(null);
    }
    private exportPeakVelocity(block: IPupil20TestCamMessage[], run: number): void {
        const minPeakVelocityOD = block.filter(d => d.secondMovingAverageOD).reduce((prev, curr) => (prev.secondMovingAverageOD > curr.secondMovingAverageOD ? prev : curr)),
            minPeakVelocityOS = block.filter(d => d.secondMovingAverageOS).reduce((prev, curr) => (prev.secondMovingAverageOS > curr.secondMovingAverageOS ? prev : curr));

        this.peakVelocityResult.peakVelocityOD.push(minPeakVelocityOD.velocityOD);
        this.peakVelocityResult.peakVelocityOS.push(minPeakVelocityOS.velocityOS);
        this.peakVelocityResult.differencePeakVelocity.push(minPeakVelocityOD.velocityOD - minPeakVelocityOS.velocityOS);
        this.peakVelocityResult.timePassed.push(minPeakVelocityOD.timePassed);

        this.exportPeakVelocityResult.push({
            peakVelocityOD: minPeakVelocityOD.velocityOD,
            peakVelocityOS: minPeakVelocityOD.velocityOS,
            differencePeakVelocity: minPeakVelocityOD.velocityOD - minPeakVelocityOS.velocityOS,
            timeOD: minPeakVelocityOD.timePassed,
            timeOS: minPeakVelocityOS.timePassed,
        });

        const peakVelocityLineEnd = this.VECOLITY_HOVER_LINE_END_POINT_Y;

        const coors: TestResultCoordinates = {
            run: run,
            xValue: minPeakVelocityOD.timestamp,
            xSecondValue: minPeakVelocityOS.timestamp,
            yValue: peakVelocityLineEnd,
            oculus: OCULUS.OD,
        };
        this.peakVelocityResult.testResultCoors.push(coors);
    }
    private calcBlock(block: IPupil20TestCamMessage[], run: number): void {
        const smallestSecondDeviationOD = block.filter(d => d.secondDeviationOD).reduce((prev, curr) => (prev.secondDeviationOD < curr.secondDeviationOD ? prev : curr)),
            smallestSecondDeviationOS = block.filter(d => d.secondDeviationOS).reduce((prev, curr) => (prev.secondDeviationOS < curr.secondDeviationOS ? prev : curr));

        let ODTime: number = (smallestSecondDeviationOD.timestamp - block[0].timestamp) / 10000,
            OSTime: number = (smallestSecondDeviationOS.timestamp - block[0].timestamp) / 10000;

        this.latencyResult.latencyOD.push(ODTime);
        this.latencyResult.latencyOS.push(OSTime);
        this.latencyResult.differenceLatency.push(ODTime - OSTime);

        this.exportLatencyResult.push({
            latencyOD: ODTime,
            latencyOS: OSTime,
            differenceLatency: ODTime - OSTime,
            endTimeOD: smallestSecondDeviationOD.timePassed,
            endTimeOS: smallestSecondDeviationOS.timePassed,
            startTime: block[0].timePassed,
        });

        const latencyTableLineEnd = this.LATENCY_HOVER_LINE_END_POINT_Y;

        const coors: TestResultCoordinates = {
            run: run,
            xValueOD: smallestSecondDeviationOD.timestamp,
            xValueOS: smallestSecondDeviationOS.timestamp,
            xSecondValue: block[0].timestamp,
            yValue: latencyTableLineEnd,
            oculus: OCULUS.OD,
        };
        this.latencyResult.testResultCoors.push(coors);
    }

    private clearPupilTestResults(): void {
        this.areaTableResults = {
            areaResults: null,
            resultType: AREA_RESULT_TYPE.NO_CONTENT,
        };
        this.areaResult = {
            areaOD: [],
            areaOS: [],
            areaDifference: [],
            testResultCoors: [],
            tableCellWidths: [],
            startTime: [],
            endTime: [],
        };
        this.pupilDiameterResults = {
            averageDiameterOD: [],
            averageDiameterOS: [],
            differenceDiameter: [],
            endTime: [],
            startTime: [],
            shiftTypes: [],
            testResultCoors: [],
            tableCellWidths: [],
            tableWidth: 0,
        };
        this.currentPoint = [];
        this.latencyResult = {
            latencyOD: [],
            latencyOS: [],
            differenceLatency: [],
            endTimeOD: [],
            endTimeOS: [],
            startTime: [],
            testResultCoors: [],
            tableCellWidths: [],
            tableWidth: 0,
        };
        this.peakVelocityResult = {
            peakVelocityOD: [],
            peakVelocityOS: [],
            differencePeakVelocity: [],
            testResultCoors: [],
            tableCellWidths: [],
            tableWidth: 0,
            timePassed: [],
        };
        this.exportAreaResult = [];
        this.exportLatencyResult = [];
        this.exportPeakVelocityResult = [];
        this.exportPupilDiameterResults = [];
        this.testCoordinates = {
            run: null,
            deltaSingleOculus: null,
            xValue: null,
            deltaXvalue: null,
            xRange: null,
            yValue: null,
            deltaYvalue: null,
            oculus: null,
            xSecondValue: null,
        };
    }

    public clear(): void {
        this.data = [];
        this.currentPoint = [];
        this.counter = 1;
        this.areaTableResults = {
            areaResults: null,
            resultType: AREA_RESULT_TYPE.NO_CONTENT,
        };
        this.itemsList.map(i => {
            this.renderer.removeAttribute(i.nativeElement, 'coodrinates');
            this.renderer.setProperty(i.nativeElement, 'innerHTML', ``);
        });
        this.pupilDiameterResults = {
            averageDiameterOD: [],
            averageDiameterOS: [],
            differenceDiameter: [],
            endTime: [],
            startTime: [],
            shiftTypes: [],
            testResultCoors: [],
            tableCellWidths: [],
            tableWidth: 0,
        };
        this.latencyResult = {
            latencyOD: [],
            latencyOS: [],
            differenceLatency: [],
            endTimeOD: [],
            endTimeOS: [],
            startTime: [],
            testResultCoors: [],
            tableCellWidths: [],
            tableWidth: 0,
        };
        this.dilationBlocks = [];
        this.constrictionsBlocks = [];
        this.blocks = [];
        this.blocksCounter = 0;
        this.showResultTables = false;
        if (this.mode === ENVIRONMENTS.TEST) {
            this.deviationsChart.selectAll('.withoutZeros').remove();
            this.deviationsChart.selectAll('.movingAverage').remove();
            this.deviationsChart.selectAll('.firstDeviation').remove();
            this.deviationsChart.selectAll('.secondDeviation').remove();
            this.deviationsChart.selectAll('.latency').remove();
            this.startTimestamp = null;
        }
    }
    onMouseEnter(coordinates: TestResultCoordinates[]): void {
        // const className: string = (event.target as Element).className;
        // if (!className.includes('highlighted') || !(event.target as Element).attributes.getNamedItem('coodrinates')) return;
        // const coodrinates: {
        //     run: number;
        //     deltaSingleOculus: boolean;
        //     xValue: number;
        //     deltaXvalue: number;
        //     xRange: number;
        //     yValue: number;
        //     deltaYvalue: number;
        // } = JSON.parse((event.target as Element).attributes.getNamedItem('coodrinates').value);
        this.highlight.emit(coordinates);
    }
    onMouseLeave(): void {
        this.clearHighlight.emit();
    }

    onLimitChange(limitObj: { value: number; limitType: LIMIT_TYPE }): void {
        this.areaResult = {
            areaOD: [],
            areaOS: [],
            areaDifference: [],
            testResultCoors: [],
            tableCellWidths: [],
            endTime: [],
            startTime: [],
        };

        switch (limitObj.limitType) {
            case LIMIT_TYPE.DILATION_LEFT_LIMIT:
                this.dilationLeftLimit = limitObj.value;
                break;
            case LIMIT_TYPE.DILATION_RIGHT_LIMIT:
                this.dilationRightLimit = limitObj.value;
                break;
            case LIMIT_TYPE.CONSTRICTION_LEFT_LIMIT:
                this.constrictionLeftLimit = limitObj.value;
                break;
            case LIMIT_TYPE.CONSTRICTION_RIGHT_LIMIT:
                this.constrictionRightLimit = limitObj.value;
                break;
        }

        this.calculateArea(this.round);

        if (this.areaTableComponent.itemsDifference.length !== 0) {
            this.areaTableComponent.testResults$.next(this.areaTableResults);
        }
    }
}
