import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../commonout/enum/calibration.command.enum';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { TEST_COMMAND } from '../../../../../../commonout/enum/test.command.enum';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../commonout/interfaces/charts.model';
import { IExamination } from '../../../../../../commonout/interfaces/examination';
import { INystagmusParams } from '../../../../../common/interfaces/measuredData/BulbiCAM/nystagmusEvaliationBulbicam.measuredData.interface';
import { IResponse } from '../../../../../common/interfaces/response.model';
import { ExaminationFrontend } from '../../_models/examinationFrontend.class';
import { FunctionalScreeningBulbicamTestFrontend } from '../../_models/tests/BulbiCAM/functionalScreeningTestFrontend.class';
import { ConfigService } from '../general/config.service';
import { SocketService } from '../general/socket.service';

@Injectable()
export class BulbicamService {
    public activeMeasurement: Subject<TEST_TYPE>;
    public isMetadataProvided: BehaviorSubject<boolean>;
    public lastCommand: BehaviorSubject<{ testType: TEST_TYPE; command: TEST_COMMAND }>;
    public nystagmusStaff: {
        startParams: () => INystagmusParams[];
        createTest: Function;
        chartCleared: boolean;
    } = {
        startParams: null,
        createTest: null,
        chartCleared: true,
    };
    public dataSource: Subject<{ type: TEST_TYPE; data: ICamMessage[] }>;
    private boundMessageListener: Function;
    private _proSaccadesRatio: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    constructor(private configService: ConfigService, private http: HttpClient, private socketService: SocketService) {
        this.boundMessageListener = this.messagesHandler.bind(this);
        this.isMetadataProvided = new BehaviorSubject<boolean>(false);
        this.lastCommand = new BehaviorSubject<{ testType: TEST_TYPE; command: TEST_COMMAND }>(null);
        this.activeMeasurement = new Subject<TEST_TYPE>();
        this.lastCommand.subscribe((command: { testType: TEST_TYPE; command: TEST_COMMAND }) => {
            if (!command) return;
            if (command.command === TEST_COMMAND.START) {
                this.socketService.socket.on(command.testType + 'ChartMessage', this.boundMessageListener);
            }
        });
        this.dataSource = new Subject<{ type: TEST_TYPE; data: ICamMessage[] }>();
    }
    private messagesHandler(messages: ICamMessage[]) {
        if (messages.find(m => m.message_type === MESSAGE_TYPE.START_TEST)) {
            this.activeMeasurement.next(this.lastCommand.value.testType);
        }
        if (messages.find(m => m.message_type === MESSAGE_TYPE.STOP_TEST)) {
            this.activeMeasurement.next(null);
            this.socketService.socket.off(this.lastCommand.value.testType + 'ChartMessage', this.boundMessageListener);
        }
    }
    public getLukaRegression(
        data: { x: any; y: any; oculus: any; round: any }[]
    ): Observable<{
        odd: {
            x1Value: number;
            y1Value: number;
            x2Value: number;
            y2Value: number;
        };
        even: {
            x1Value: number;
            y1Value: number;
            x2Value: number;
            y2Value: number;
        };
    }> {
        const url = `${this.configService.backendUrl}/haplo/getLukaRegression`;
        return this.http.post<{
            odd: {
                x1Value: number;
                y1Value: number;
                x2Value: number;
                y2Value: number;
            };
            even: {
                x1Value: number;
                y1Value: number;
                x2Value: number;
                y2Value: number;
            };
        }>(url, { data: data });
    }

    public sendExaminationMetadata(examinationID: string, isDilated: boolean): Observable<ICamMessage> {
        this.isMetadataProvided.next(false);
        const url = `${this.configService.backendUrl}/haplo/providemetadata/${examinationID}`;
        return this.http
            .post<ICamMessage>(url, { isDilated: isDilated })
            .pipe(
                tap(responce => {
                    if (responce.message_type === MESSAGE_TYPE.CONFIRMATION) {
                        this.isMetadataProvided.next(true);
                    } else {
                        // this.isMetadataProvided.next(true);
                        console.log('Unexpected message received from CAM on lensholder data request:');
                        console.log(responce);
                    }
                })
            );
    }

    public setStopMessage(examID: string, dataID: string, tsToCompare: Number, message: string): Observable<{ editedMessage: ICamMessage; newFileId: string }> {
        const url = `${this.configService.backendUrl}/haplo/stopmessage`;
        return this.http.post<{ editedMessage: ICamMessage; newFileId: string }>(url, { examID, dataID, tsToCompare, message });
    }

    sendCommand<T extends { username: string }>(
        examinationModel: IExamination,
        test_type: TEST_TYPE,
        device: DEVICE,
        command: TEST_COMMAND,
        socketIoId: string,
        payload: T
    ): Observable<void> {
        const url = `${this.configService.backendUrl}/haplo`,
            message = {
                model: examinationModel,
                testType: test_type,
                device,
                command,
                socketIoId,
                payload,
            };
        this.lastCommand.next({ testType: test_type, command: command });
        return this.http.post<void>(url, message);
    }

    getHaplotestData(id: string): Promise<IResponse> {
        const url = `${this.configService.backendUrl}/haplo/haplotest/${id}`;
        return this.http
            .post(url, null)
            .toPromise()
            .then(res => res)
            .catch(err => err);
    }

    public saveChartEdit(targetData: { examinationID: string; testType: TEST_TYPE; haploMeasurementID: number; edit: IChartEdit }): Observable<IChartEdit[]> {
        const url = `${this.configService.backendUrl}/haplo/edit`;
        return this.http.post<IChartEdit[]>(url, targetData);
    }
    public updateCamMessage<T>(targetData: { examinationID: string; testType: TEST_TYPE; haploMeasurementID: string; newMessage: T }): Observable<{ newID: string }> {
        const url = `${this.configService.backendUrl}/haplo/updateCamMessage`;
        return this.http.post<{ newID: string }>(url, targetData);
    }
    public deleteCamMessage(targetData: { examinationID: string; testType: TEST_TYPE; haploMeasurementID: string; messageToDeleteTS: number }): Observable<{ newID: string }> {
        const url = `${this.configService.backendUrl}/haplo/deleteCamMessage`;
        return this.http.post<{ newID: string }>(url, targetData);
    }
    /**
     * Дженерик функция которая в качестве типа принимает значение своего аргумента
     * @param payload   В пейлоаде должен быть !одноуровневый! JSON в котором обязательны следующие поля:
     *
     *                  command - чтото из интерфейса CALIBRATION_COMMAND (commonout/enum/calibration.command.enum.ts). По идее всегда будет 'START'.
     *
     *                  test - чтото из интерфейса CALIBRATION_TEST (commonout/enum/calibration.command.enum.ts). Туда нужно ложить типы тестов которые ты как нибудь с Антоном назовешь вместо представленных сейчас 'TEST1' и 'TEST2'.
     *
     *                  остальные ключи должны представлять собой значения элементов управления выбранных пользователем для конкретного вида теста.
     * @returns         Поскольку на данном этапе не предусматривается возврат каких либо данных, то функция возвращает Observable<void>
     */
    public sendCalibrationCommand<T extends { command: CALIBRATION_COMMAND; test: CALIBRATION_TEST }>(payload: T): Observable<void> {
        payload['socketIoId'] = this.socketService.id;
        const url = `${this.configService.backendUrl}/calibration`;
        return this.http.post<void>(url, payload);
    }
    /**
     * Дженерик тайп - тип значений в ожидаемом массиве
     * @param payload   Чтото из интерфейса CALIBRATION_TEST
     */
    public getCalibrationSettings<T extends { test: CALIBRATION_TEST; createdAt: number }>(type: CALIBRATION_TEST): Observable<T[]> {
        const url = `${this.configService.backendUrl}/calibration/get/${type}`;
        return this.http.get<T[]>(url);
    }
    /**
     * Дженерик функция которая в качестве типа принимает значение своего аргумента
     * @param payload   В пейлоаде должен быть JSON в котором обязательны следующие поля:
     *
     *                  test - чтото из интерфейса CALIBRATION_TEST (commonout/enum/calibration.command.enum.ts). Туда нужно ложить типы тестов которые ты как нибудь с Антоном назовешь вместо представленных сейчас 'TEST1' и 'TEST2'.
     *
     *                  createdAt - клади туда просто значение Date.now()
     *
     *                  остальные ключи должны представлять собой значения элементов управления выбранных пользователем для конкретного вида теста.
     * @returns         Поскольку на данном этапе не предусматривается возврат каких либо данных, то функция возвращает Observable<void>
     */
    public saveCalibrationSetting<T extends { test: CALIBRATION_TEST; createdAt: number }>(payload: T): Observable<void> {
        const url = `${this.configService.backendUrl}/calibration/save`;
        return this.http.post<void>(url, payload);
    }
    /**
     * @param payload   В пейлоаде должен быть !одноуровневый! JSON в котором обязательны следующие поля:
     *
     *                  test - чтото из интерфейса CALIBRATION_TEST (commonout/enum/calibration.command.enum.ts). Туда нужно ложить типы тестов которые ты как нибудь с Антоном назовешь вместо представленных сейчас 'TEST1' и 'TEST2'.
     *
     *                  остальные ключи должны представлять собой значения элементов управления выбранных пользователем для конкретного вида теста.
     * @returns         Поскольку на данном этапе не предусматривается возврат каких либо данных, то функция возвращает Observable<void>
     */
    public deleteCalibrationSetting(test: CALIBRATION_TEST, createdAt: number): Observable<void> {
        const url = `${this.configService.backendUrl}/calibration/delete`;
        return this.http.post<void>(url, { test, createdAt });
    }
    public saveCalibrationSettingsToDrive(content: string, fileName: string): Observable<void> {
        const url = `${this.configService.backendUrl}/calibration/savefile`;
        return this.http.post<void>(url, { content, fileName });
    }
    public async collectProSaccadesRatio(examination: ExaminationFrontend): Promise<void> {
        const functionalScreeningTest = examination.getTest<FunctionalScreeningBulbicamTestFrontend>(TEST_TYPE.FUNCTIONAL_SCREENING, DEVICE.HAPLO);
        if (!functionalScreeningTest) return;
        const functionalScreeningMeasurementsID: string[] = functionalScreeningTest.remarks.measurements[0].haplotests
            .sort((camTest1, camTest2) => camTest1.createdAt - camTest2.createdAt)
            .map(camTest => camTest.haplotestData);
        const url = `${this.configService.backendUrl}/haplo/getRatio`;
        const responce = await this.http.post<{ proSaccadesRatio: number[] }>(url, functionalScreeningMeasurementsID).toPromise();
        this._proSaccadesRatio.next(responce.proSaccadesRatio);
    }
    public hasProperRatio(): boolean {
        const value = this._proSaccadesRatio.value.slice();
        return !!value.pop();
    }
    public get proSaccadesRatioValue(): number[] {
        const value = this._proSaccadesRatio.value.slice();
        return value;
    }
    public set proSaccadesRatioValue(value: number[]) {
        this._proSaccadesRatio.next(value);
    }
}
