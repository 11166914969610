export enum RESPONSE_STATUS {
    SUCCESS,
    ERROR,
}
/**
 * The wrapper for any message from backend to frontend
 */
export interface IResponse {
    status: RESPONSE_STATUS;
    message: string; // any string metadata message
    data: any; // any data
}
