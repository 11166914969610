// @ts-nocheck
// // import { Layout as Area } from './lib';
import { Layout } from '../lib/Layout';
import * as _ from 'lodash'

// eslint-disable-next-line
// import { layoutPreview } from './lib';

const GAP = 50;
const MARGIN = 50;
const HEADER = 50;

const LEFT_SIZE = 200
const LEFT0_HEIGHT = 50+50
const LEFT1_HEIGHT = 200
const LEFT2_HEIGHT = 100

interface LayoutParams {
  dx: number
  dy: number
}
export function makeLayout(p: LayoutParams) {
  let width = window.screen.width
  let height = window.screen.height

  let layout = new Layout(0, 0, width, height, 'layout')

  layout.takeLeft(MARGIN);
  layout.takeRight(MARGIN);
  layout.takeTop(MARGIN);
  layout.takeBottom(MARGIN);

  // let partSize = (layout.w - GAP*2) / 3
  let partSize = (layout.w - GAP*3 - LEFT_SIZE) / 2
  let leftPart = layout.takeLeft(LEFT_SIZE, 'leftPart')
  layout.takeLeft(GAP)
  layout.takeLeft(GAP)
  let centerPart = layout.takeLeft(partSize, 'centerPart')
  layout.takeLeft(GAP)
  let rightPart = layout.takeLeft(partSize, 'rightPart')

  let ex = width * 2
  let ey = height * 2
  let empty = new Layout(ex, ey, ex, ey, 'empty');

  let leftHeader = leftPart.takeTop(HEADER)
  leftPart.takeTop(GAP)
  let centerHeader = centerPart.takeTop(HEADER)
  centerPart.takeTop(GAP)
  let rightHeader = rightPart.takeTop(HEADER)
  rightPart.takeTop(GAP)

  let leftZero = leftPart.takeTop(LEFT0_HEIGHT)
  leftPart.takeTop(GAP)
  let leftFirst = leftPart.takeTop(LEFT1_HEIGHT)
  leftPart.takeTop(GAP)
  let leftSecond = leftPart.takeTop(LEFT2_HEIGHT)

  // rects with dots
  let rectHeight = centerPart.w / p.dx * p.dy
  centerPart = centerPart.takeTop(rectHeight, 'centerPart')
  rightPart = rightPart.takeTop(rectHeight, 'rightPart')

  return {
    layout,
    empty,

    leftZero,
    leftFirst,
    leftSecond,
    centerPart,
    rightPart,

    // leftHeader,
    centerHeader,
    rightHeader,
  }
}

// layoutPreview(makeLayout({ dx: 1, dy: 1 }).layout, { fontSize: 50, forceStrength: -10000, forceDistance: 100 });
