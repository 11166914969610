import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { PostSegmentNidekRs330MeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/RS330/postSegmentNidekRs330Data.model';
import { IPostSegmentNidekRs330MeasuredData } from '../../../../../../../common/interfaces/measuredData/NIDEK/RS330/post-segment-nidek-rs330.measuredData.interface';
import { CellHookHandler } from 'jspdf-autotable';
import { PostSegmentNidekRs330TestRemarksFrontend } from '../../../test-remarks/NIDEK/RS330/postSegmentNidekRs330TestRemarksFrontend.class';

export class PostSegmentNidekRs330MeasuredDataFrontend extends PostSegmentNidekRs330MeasuredData implements MeasuredDataFrontend {
    public remarks: PostSegmentNidekRs330TestRemarksFrontend;
    public OD: SmartImageFrontend[];
    public OS: SmartImageFrontend[];
    constructor() {
        super();
        this.OD = [];
        this.OS = [];
    }
    public setModel(model: IPostSegmentNidekRs330MeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.OD = model.OD.map((m) => {
            const i = new SmartImageFrontend();
            i.model = m;
            return i;
        });
        this.OS = model.OS.map((m) => {
            const i = new SmartImageFrontend();
            i.model = m;
            return i;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        try {
            if (this.OD.length > 0 || this.OS.length > 0) {
                let head = [],
                    table = [];
                head.push({
                    OD: { content: 'OD', styles: { halign: 'center' } },
                    OS: { content: 'OS', styles: { halign: 'center' } },
                });
                await this.downloadMediaData(fileService);
                let length: number = this.OD.length > this.OS.length ? this.OD.length : this.OS.length;
                for (let index = 0; index < length; index++) {
                    let ratio: number = await new Promise<number>((res) => {
                        let img = new Image();
                        img.onload = () => {
                            let height = img.height;
                            let width = img.width;
                            res(width / height);
                        };
                        img.src = this.OD[index] ? this.OD[index].imageContent : this.OS[index].imageContent;
                    });
                    table.push({
                        OD: {
                            content: '',
                            styles: {
                                minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                                minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                            },
                        },
                        OS: {
                            content: '',
                            styles: {
                                minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                                minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                            },
                        },
                    });
                    table.push({
                        OD: { content: this.OD[index] ? this.OD[index].remark.value : '', styles: { halign: 'left' } },
                        OS: { content: this.OS[index] ? this.OS[index].remark.value : '', styles: { halign: 'left' } },
                    });
                }
                let ODindex: number = 0,
                    OSindex: number = 0;
                (document as any).autoTable({
                    head: head,
                    body: table,
                    startY: pager.line,
                    pageBreak: 'auto',
                    theme: 'grid',
                    margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                    didDrawCell: <CellHookHandler>((data) => {
                        if (data.section === 'body' && data.row.index % 2 === 0 && data.column.dataKey === 'OD' && this.OD[ODindex]) {
                            document.addImage(this.OD[ODindex].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                            ODindex++;
                        } else if (data.section === 'body' && data.row.index % 2 === 0 && data.column.dataKey === 'OS' && this.OS[OSindex]) {
                            document.addImage(this.OS[OSindex].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                            OSindex++;
                        }
                    }),
                    didDrawPage: () => {},
                });
                pager.line = (document as any).lastAutoTable.finalY;
            }
        } catch (error) {
            console.log(error);
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: this.OD.map((i) => i.imageUrl),
                ai: [],
            },
            OS: {
                values: '',
                images: this.OS.map((i) => i.imageUrl),
                ai: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.OD.map((si) => si.download(fileService)));
        await Promise.all(this.OS.map((si) => si.download(fileService)));
    }
}
