// @ts-nocheck
import { Target } from './types'
import { Area } from './Area'
// import * as d3 from 'd3'
import { dynamicTargets } from './util'
import { VTable } from './VTable'
import { Rectangle } from './Rectangle'
// import { List } from './List'
// import { Option } from './Option'

// simple flat structure
interface Props {
  items: string[]

  onChoice?: (i: string) => void

  target: Target
  area: Area // just used as a point? or a line?
}

// optionals/styling
let defaultProps = {
  direction: 'h',
  handleRadius: 15,
  handleTip: 13,
  curvitureDy: 10,
  curvitureDx: 10,

  class: 'fLayer sContrast',
  strokeWidth: 0.5,

  show: true,
  padX: 15,
  padY: 10,
}

export function Menu(given: Props & Partial<typeof defaultProps>) {
  let p = { ...defaultProps, ...given }
  let { target, area } = p

  let takeTarget = dynamicTargets(target)

  let area2 = area.padX(p.padX).padY(p.padY)

  if (!p.show) {
    target.selectAll('g').data([]).join('g')
    return
  }

  Rectangle({
    from: area2.tl,
    to: area2.br,

    target: takeTarget(),

    styleParams: {
      override: {
        lineKlass: 'sContrast fLayer',
      }
    },
  })

  VTable({
    domainX: [0,2],
    rowHeaders: p.items.map((text,i) => {
      return {
        text,
        onClick: () => {
          p.onChoice && p.onChoice(text)
        }
      }
    }),
    columns: [],
    target: takeTarget(),
    area,
  })

  // let ctx = List({
  //     // alwaysOpened: true,
  //     // borderless: true,

  //     target,
  //     area,
  //     styleParams: { // old decision to use data structure instead of functions, although typescript is friendlier with them I beleive
  //       override: {
  //         class: 'sContrast fLayer',
  //       },
  //     },
  // });
  // Option<string>({
  //     ...ctx,
  //     items: p.items,
  //     isChecked: item => false, //this.state.settings.selected.has(item.id),
  //     onClick: item => {
  //       console.log(item)
  //       // this.state.settings.selected.toggle(item.id)
  //       // this.refresh()
  //     },
  //     coloring: item => '#999', //getColor(item.id, this.state.maxId),
  //     naming: item => item,
  //     // styleParams,
  // })
}
