import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TestResultCoordinates, AreaResults, AreaTableResults, AREA_RESULT_TYPE } from '../../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';

export enum LIMIT_TYPE {
    DILATION_LEFT_LIMIT = 'dilationLeftLimit',
    DILATION_RIGHT_LIMIT = 'dilationRightLimit',
    CONSTRICTION_LEFT_LIMIT = 'constrictionLeftLimit',
    CONSTRICTION_RIGHT_LIMIT = 'constrictionRightLimit',
}

@Component({
  selector: 'area-table',
  template: require('./area-table.component.html'),
  styles: [require('./area-table.component.scss')]
})
export class AreaTableComponent implements OnInit, OnDestroy {
    @Output() calculateArea = new EventEmitter<{
        value: number,
        limitType: LIMIT_TYPE
    }>();
    @Output() highlight = new EventEmitter<TestResultCoordinates[]>();
    @Output() clearHighlight = new EventEmitter<void>();

    @ViewChild('table') table: ElementRef;
    @ViewChildren('tdOD') itemsOD: QueryList<ElementRef>;
    @ViewChildren('tdOS') itemsOS: QueryList<ElementRef>;
    @ViewChildren('tdDifference') itemsDifference: QueryList<ElementRef>;
    @ViewChildren('dataCellOD') dataCellsOD: QueryList<ElementRef>;
    @ViewChildren('dataCellOS') dataCellsOS: QueryList<ElementRef>;
    @ViewChildren('dataCellDifference') dataCellsDifference: QueryList<ElementRef>;
    
    public testResults$ = new Subject<AreaTableResults>();

    private subscriptions: Subscription[] = [];
    private limitObject: {
        value: number,
        limitType: LIMIT_TYPE
    };
    private lastPagraphElement: HTMLParagraphElement;

    private readonly PADDING = 5; 
    private readonly BORDER_SIZE = 0.5; 

    constructor(private renderer: Renderer2) { }

    ngOnInit() {
        this.subscriptions.push(this.testResults$.subscribe((result: AreaTableResults) => {
            if (result) {
                if (result.resultType === AREA_RESULT_TYPE.NOT_ACCEPTABLE && this.lastPagraphElement) {
                    // this.lastPagraphElement.hidden = false;
                }
                this.fillTable(result.areaResults);  
            }
        }));
    }

    public fillTable(testResults: AreaResults): void {
        const dataCellsOS = this.dataCellsOS.toArray();
        const dataCellsDifference = this.dataCellsDifference.toArray();
        const itemsOS = this.itemsOS.toArray();
        const itemsDifference = this.itemsDifference.toArray();

        // the count of html elements is the same
        this.dataCellsOD?.forEach((item, i) => {
            const coors = testResults.testResultCoors[i];
            
            const coodrinatesOD: string = JSON.stringify(coors);

            const coodrinatesOS: string = JSON.stringify(coors);

            const coodrinatesDifference: string = JSON.stringify(coors);

            this.renderer.setAttribute(item.nativeElement, 'coodrinates', coodrinatesOD);
            this.renderer.setAttribute(dataCellsOS[i].nativeElement, 'coodrinates', coodrinatesOS);
            this.renderer.setAttribute(dataCellsDifference[i].nativeElement, 'coodrinates', coodrinatesDifference);

            const areaOD = testResults.areaOD[i] ? testResults.areaOD[i].toFixed(1) : null;
            const areaOS = testResults.areaOS[i] ? testResults.areaOS[i].toFixed(1) : null;
            const areaDifference = testResults.areaDifference[i] ? testResults.areaDifference[i].toFixed(1) : null;

            this.renderer.setProperty(item.nativeElement, 'innerHTML', 
                `${areaOD}`);
            this.renderer.setProperty(dataCellsOS[i].nativeElement, 'innerHTML', 
                `${areaOS}`);
            this.renderer.setProperty(dataCellsDifference[i].nativeElement, 'innerHTML', 
                `${areaDifference}`);

        });

        const centralCellClass = 'central-cell';

        this.itemsOD?.forEach((element, i) => {
            const width = (<string>element.nativeElement.className).includes(centralCellClass)
                ? testResults.tableCellWidths[i] - this.PADDING * 2
                : testResults.tableCellWidths[i] - this.PADDING * 2 - this.BORDER_SIZE;

            this.renderer.setStyle(element.nativeElement, 'width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsOS[i].nativeElement, 'width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsDifference[i].nativeElement, 'width', 
                `${(width).toFixed(1)}px`);

            this.renderer.setStyle(element.nativeElement, 'min-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsOS[i].nativeElement, 'min-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsDifference[i].nativeElement, 'min-width', 
                `${(width).toFixed(1)}px`);

            this.renderer.setStyle(element.nativeElement, 'max-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsOS[i].nativeElement, 'max-width', 
                `${(width).toFixed(1)}px`);
            this.renderer.setStyle(itemsDifference[i].nativeElement, 'max-width', 
                `${(width).toFixed(1)}px`);
        });
    }

    onMouseEnter(event: MouseEvent): void {
        const coordinates: TestResultCoordinates[] = [];

        (event.target as Element).childNodes.forEach(td => {
            if ((td as Element).attributes.getNamedItem('coodrinates')) {
                const tdCoordinates = JSON.parse((td as Element).attributes.getNamedItem('coodrinates').value);
                coordinates.push(tdCoordinates);
            }
        });

        // making second line for highliting latency range
        const firstLineCoors = coordinates[0];
        coordinates.push({
            xValue: firstLineCoors.xSecondValue,
            yValue: firstLineCoors.yValue,
            yAreaValuesOD: firstLineCoors.yAreaValuesOD,
            yAreaValuesOS: firstLineCoors.yAreaValuesOS,
            xAreaValues: firstLineCoors.xAreaValues,
            run: firstLineCoors.run,
            oculus: firstLineCoors.oculus
        });

        if (coordinates.length === 0) return;

        this.highlight.emit(coordinates);
    }

    onMouseLeave(): void {
        this.clearHighlight.emit();
    }

    onLimitChange(event: Event, pElement: HTMLParagraphElement): void {
        // if (this.lastPagraphElement) {
        //     this.lastPagraphElement.hidden = true;
        // }
        // this.lastPagraphElement = pElement;

        const value = (event.target as HTMLInputElement).value;


        switch ((event.target as HTMLInputElement).name) {
            case LIMIT_TYPE.DILATION_LEFT_LIMIT:
                this.limitObject = {
                    value: parseFloat(value),
                    limitType: LIMIT_TYPE.DILATION_LEFT_LIMIT
                };
                pElement.hidden = parseFloat(value) < 0 ? false : true;
                break;
            case LIMIT_TYPE.DILATION_RIGHT_LIMIT:
                this.limitObject = {
                    value: parseFloat(value),
                    limitType: LIMIT_TYPE.DILATION_RIGHT_LIMIT
                };
                pElement.hidden = parseFloat(value) <= 0 ? false : true;
                break;
            case LIMIT_TYPE.CONSTRICTION_LEFT_LIMIT:
                this.limitObject = {
                    value: parseFloat(value),
                    limitType: LIMIT_TYPE.CONSTRICTION_LEFT_LIMIT
                };
                pElement.hidden = parseFloat(value) < 0 ? false : true;
                break;
            case LIMIT_TYPE.CONSTRICTION_RIGHT_LIMIT:
                this.limitObject = {
                    value: parseFloat(value),
                    limitType: LIMIT_TYPE.CONSTRICTION_RIGHT_LIMIT
                };
                pElement.hidden = parseFloat(value) <= 0 ? false : true;
                break;
        }
        
        this.calculateArea.emit(this.limitObject);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
