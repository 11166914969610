import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { IAcuity4KidsBulbicamMeasuredData } from '../../../interfaces/measuredData/BulbiCAM/acuity4kids-bulbicam.measuredData.interface';
import { CamTest } from '../../haplotest.class';
import { MeasuredData } from '../measuredData.model';

export abstract class Acuity4KidsBulbicamMeasuredData implements MeasuredData, IAcuity4KidsBulbicamMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public haplotests: CamTest[];
    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.ACUITY4KIDS;
        this.haplotests = [];
    }
    public abstract setModel(model: IAcuity4KidsBulbicamMeasuredData): void;
    public getModel(): IAcuity4KidsBulbicamMeasuredData {
        let model: IAcuity4KidsBulbicamMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            haplotests: this.haplotests.map(htm => htm.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.haplotests.length === 0;
    }
}
