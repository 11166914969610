import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { TonometryMeasuredDataFrontend } from '../../../measuredData/NIDEK/NT530P/tonometryDataFrontend.class';

export class TonometryTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof TonometryMeasuredDataFrontend = TonometryMeasuredDataFrontend;
    public measurements: TonometryMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
