export const mockDataTest2: {date: Date, bpdpPair: [number, number][]}[] = [
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
    {
        date: new Date(),
        bpdpPair: [[100, 100], [200, 200],[300, 300],[400, 400],[500, 500],[600, 600],[700, 700], [800, 800]],
    },
];

export const mockDataTest3: {date: Date, os: {range: [number, number], value: number}, od: {range: [number, number], value: number}}[] = [
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
    {
        date: new Date(),
        os: {
            range: [-1, 1],
            value: 100
        },
        od: {
            range: [-1, 1],
            value: 100
        },
    },
];

export const mockDataTest4: {
    date: Date,
    os: {average: number, min: number, max: number, ss: number},
    od: {average: number, min: number, max: number, ss: number}
}[] = [
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {average: 100, min: 100, max: 100, ss: 100},
        od: {average: 100, min: 100, max: 100, ss: 100},
    },
];

export const mockDataTest5: {
    date: Date,
    os: {halfThreshold: number, ss: number},
    od: {halfThreshold: number, ss: number},
}[] = [
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
    {
        date: new Date(),
        os: {halfThreshold: 100, ss: 100},
        od: {halfThreshold: 100, ss: 100},
    },
];