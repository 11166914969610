import { KeratometryTonorefIIITest } from '../../../../../../../../commonout/classes/tests/NIDEK/TonorefIII/keratometryTonorefIIITest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { KeratometryTonorefIIITestRemarksComponent } from '../../../../_components';
import { KeratometryTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/keratometryTonorefIIITestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class KeratometryTonorefIIITestFrontend extends KeratometryTonorefIIITest {
    remarks: KeratometryTonorefIIITestRemarksFrontend;
    public readonly remarksComponent: typeof KeratometryTonorefIIITestRemarksComponent = KeratometryTonorefIIITestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new KeratometryTonorefIIITestRemarksFrontend();
    }
}

export interface KeratometryTonorefIIITestFrontend extends TestFrontend {}
applyMixins(KeratometryTonorefIIITestFrontend, [TestFrontend]);
