import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IIpdNidekTonorefIIIMeasuredData } from '../../../../interfaces/measuredData/NIDEK/TonorefIII/ipd-nidek-tonorefIII.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class IpdNidekTonorefIIIMeasuredData implements MeasuredData, IIpdNidekTonorefIIIMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public IPD: {
        far: RegisterEditField<string>;
        near: RegisterEditField<string>;
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_TONOREFIII;
        this.testType = TEST_TYPE.INTER_PUPILLARY_DISTANCE;
    }
    public setModel(model: IIpdNidekTonorefIIIMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.IPD.far.setModel(model.IPD.far);
        this.IPD.near.setModel(model.IPD.near);
    }
    public getModel(): IIpdNidekTonorefIIIMeasuredData {
        const model: IIpdNidekTonorefIIIMeasuredData = {
            sourceDevice: this.sourceDevice,
            sourceFileName: this.sourceFileName,
            testType: this.testType,
            IPD: {
                far: this.IPD.far.getModel(),
                near: this.IPD.near.getModel(),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.IPD.far.isEmpty() && this.IPD.near.isEmpty();
    }
}
