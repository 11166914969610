// @ts-nocheck
import * as d3 from 'd3';
import { Target } from './types';
import { Area } from './Area';
import { TrackHandle } from './TrackHandle';
import { doTranslate } from './util';

// NOTE: I'm thinking about what can be refactored overall
// so there are comments on things I may change or I have decided on

// no nesting needed (even for styling?)
interface Props {
    // xScale + value |or| just a relative value 0..1 (still need xScale?)
    // percentages?
    // I think it makes sense to just have a value
    // and I can calculate it from anything including from scale-domain (it can be bigger than data domain)
    v1: number;
    v2: number;
    // v1 < v2 I guess
    valueScale: any; //...

    dragHandle: number;
    onDragStart: (n: number) => void;
    onDragEnd: (n: number) => void;
    onDrag: (n: number, value: number) => void;
    opacity?: string;

    area: Area;
    target: () => Target;
}

let defaultProps = {
    // class: ''
    direction: 'h', // horizontal
    padding: 1000, // how much to allow mouse to go off the area on long sides
    // on leave it stops dragging
    drawDx: 0,
    show: true,
};

export function TrackPair(given: Props & Partial<typeof defaultProps>) {
    let p = { ...defaultProps, ...given };
    let isHorizontal = p.direction === 'h';

    let g1 = p.target();
    let g2 = p.target();
    let gLayer = p.target();

    if (true) {
        let area = p.area;
        TrackHandle({
            value: p.v1,
            direction: p.direction,
            show: p.show,
            valueScale: p.valueScale,
            opacity: p.opacity,

            drawDx: p.drawDx,

            onDragStart: () => {
                p.onDragStart(1);
            },

            target: g1,
            area,
        });
    }

    if (true) {
        let area = p.area;
        TrackHandle({
            value: p.v2,
            direction: p.direction,
            show: p.show,
            valueScale: p.valueScale,
            opacity: p.opacity,

            drawDx: p.drawDx,

            onDragStart: () => {
                p.onDragStart(2);
            },

            target: g2,
            area,
        });
    }

    if (true) {
        let area = p.area;
        let present = p.dragHandle > 0 && p.show;

        if (isHorizontal) {
            area = area.padY(p.padding);
        } else {
            area = area.padX(p.padding);
        }

        gLayer
            .selectAll('rect')
            .data(present ? [null] : [])
            .join('rect')
            .attr('width', area.width())
            .attr('height', area.height())
            .call(doTranslate({ x: area.left, y: area.top }))
            .on('mousemove', (x: any) => {
                let pos = d3.mouse(d3.event.currentTarget);
                let dCoord = isHorizontal ? pos[0] : pos[1];
                let whole = isHorizontal ? area.w : area.h;
                let value = dCoord / whole;
                p.onDrag(p.dragHandle, value);
            })
            .on('mouseleave', (x: any) => p.onDragEnd(p.dragHandle))
            .on('mouseup', (x: any) => p.onDragEnd(p.dragHandle))
            .attr('cursor', 'grabbing')
            .attr('fill', 'transparent');
        // .attr('stroke', 'black')
    }
}
