import { AccommodationNidekArk1sTest } from '../../../../../../../../commonout/classes/tests/NIDEK/ARK1S/accommodationNidekArk1sTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { AccommodationTestRemarksComponent } from '../../../../_components';
import { AccommodationArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/accommodationArk1sTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class AccommodationNidekArk1sTestFrontend extends AccommodationNidekArk1sTest {
    remarks: AccommodationArk1sTestRemarksFrontend;
    public readonly remarksComponent: typeof AccommodationTestRemarksComponent = AccommodationTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new AccommodationArk1sTestRemarksFrontend();
    }
}

export interface AccommodationNidekArk1sTestFrontend extends TestFrontend {}
applyMixins(AccommodationNidekArk1sTestFrontend, [TestFrontend]);
