// @ts-nocheck
export function getOutlierInfo(point: any, logData: any) {
  if (!point) return {
    originalIsOutlier: null,
    userIsOutlier: null,
    isOutlier: null,
  }
  let originalIsOutlier: boolean = point.direction === 6
  let fromLogIsOutlier = logData.getValue(point.timestamp)

  let userIsOutlier: boolean|null = null
  let isOutlier: boolean
  if (fromLogIsOutlier != null) {
    isOutlier = fromLogIsOutlier === 1
    userIsOutlier = isOutlier
  } else {
    isOutlier = originalIsOutlier
  }

  return {
    originalIsOutlier, // initial value
    userIsOutlier, // if set - latest user value
    isOutlier, // latest actual value
    // can be null if no point given
  }
}
