import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import { MESSAGE_TYPE } from '../../../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../../../common/interfaces/rawExportData.interface';
import {
    CalibratedData,
    CorrelationData,
    GainChartData,
    GainChartStimuliData,
    ISmoothPursuitChartData,
    ISmoothPursuitTestCamMessage,
    SmoothPursuitPoint,
    StimuliResults,
    WindowData,
    WindowResult,
} from '../../../../../../../common/interfaces/smoothPursuitTestMessage.interface';
import { ChartService } from '../../chartService';
import { SaccadeParsingService } from '../saccadesTestServices/saccadesParsingService';
import { calculate } from './libraries/correlation';
import { xcorr } from './libraries/crossCorrelation';

@Injectable()
export class SmoothPursuitChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}

    charData$: BehaviorSubject<ISmoothPursuitChartData> = new BehaviorSubject({
        chartType: 'no-content',
    });
    averageRatio$ = new BehaviorSubject(0);

    public data: ISmoothPursuitTestCamMessage[] = [];

    private rawFrames: ISmoothPursuitTestCamMessage[] = [];
    private OD_stimuli: SmoothPursuitPoint[] = [];
    private OD_horizontal: SmoothPursuitPoint[] = [];
    private OD_vertical: SmoothPursuitPoint[] = [];
    private OS_stimuli: SmoothPursuitPoint[] = [];
    private OS_horizontal: SmoothPursuitPoint[] = [];
    private OS_vertical: SmoothPursuitPoint[] = [];
    private stagesStart = 0;
    private zeroOD: number;
    private zeroOS: number;
    private testResults: StimuliResults[] = [];

    private startTimestamp: number;
    private windowTime = 5;
    private readonly timeFactor = 1;
    private readonly PHASE_SHIFT_LIMIT = 180;
    private readonly MAX_DEGREE = 360;

    mapper = new Map([
        [0, 'First'],
        [1, 'Second'],
        [2, 'Third fast'],
    ]);

    constructor(private parsingService: SaccadeParsingService) {
        super();
    }

    public addData(frames: ISmoothPursuitTestCamMessage[]): Promise<void> {
        return new Promise<void>(resolve => {
            frames.forEach(async (frame, index) => {
                const localFrame = { ...frame };
                switch (localFrame.message_type) {
                    case MESSAGE_TYPE.START_TEST:
                        this.data.push(localFrame);
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        if (!this.startTimestamp) {
                            this.startTimestamp = localFrame.timestamp / 10000;

                            this.data.push(localFrame);
                        } else {
                            this.data.push(localFrame);
                        }
                        this.OD_stimuli.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.stimuliODx,
                            type: 6,
                            frequency: localFrame.frequency,
                        });
                        this.OD_horizontal.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.rawEyeODx,
                            type: 6,
                            frequency: localFrame.frequency,
                        });
                        this.OD_vertical.push({ time: this.getXvalue(this.startTimestamp, localFrame.timestamp), amplitude: localFrame.eyeODy, type: 6 });
                        this.OS_stimuli.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.stimuliOSx,
                            type: 6,
                            frequency: localFrame.frequency,
                        });
                        this.OS_horizontal.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.rawEyeOSx,
                            type: 6,
                            frequency: localFrame.frequency,
                        });
                        this.OS_vertical.push({ time: this.getXvalue(this.startTimestamp, localFrame.timestamp), amplitude: localFrame.eyeOSy, type: 6 });

                        if (index === frames.length - 1) {
                            this.charData$.next({
                                chartType: 'real-time',
                                frames: this.data,
                                horizontalOD: this.OD_horizontal,
                                horizontalOS: this.OS_horizontal,
                                stimuliOD: this.OD_stimuli,
                                stimuliOS: this.OS_stimuli,
                                verticalOD: this.OD_vertical,
                                verticalOS: this.OS_vertical,
                                zeroOD: this.zeroOD,
                                zeroOS: this.zeroOS,
                            });
                        }
                        if (this.stagesStart === 1) {
                            this.zeroOD = localFrame.rawEyeODx;
                            this.zeroOS = localFrame.rawEyeOSx;
                            this.stagesStart += 1;
                        }
                        break;
                    case MESSAGE_TYPE.START_SEQUENCE:
                        this.data.push(localFrame);
                        this.OD_stimuli.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.stimuliODx,
                            type: 15,
                            frequency: localFrame.frequency,
                        });
                        this.OD_horizontal.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.rawEyeODx,
                            type: 15,
                            frequency: localFrame.frequency,
                        });
                        this.OD_vertical.push({ time: this.getXvalue(this.startTimestamp, localFrame.timestamp), amplitude: localFrame.eyeODy, type: 15 });
                        this.OS_stimuli.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.stimuliOSx,
                            type: 15,
                            frequency: localFrame.frequency,
                        });
                        this.OS_horizontal.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: localFrame.rawEyeOSx,
                            type: 15,
                            frequency: localFrame.frequency,
                        });
                        this.OS_vertical.push({ time: this.getXvalue(this.startTimestamp, localFrame.timestamp), amplitude: localFrame.eyeOSy, type: 15 });
                        this.stagesStart += 1;
                        break;
                    case MESSAGE_TYPE.CONFIGURATION_PARAMS:
                        this.OD_stimuli.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: this.data[this.data.length - 1].stimuliODx,
                            type: 16,
                            frequency: localFrame.frequency,
                        });
                        this.OD_horizontal.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: this.data[this.data.length - 1].rawEyeODx,
                            type: 16,
                            frequency: localFrame.frequency,
                        });
                        this.OD_vertical.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: this.data[this.data.length - 1].eyeODy,
                            type: 16,
                        });
                        this.OS_stimuli.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: this.data[this.data.length - 1].stimuliOSx,
                            type: 16,
                            frequency: localFrame.frequency,
                        });
                        this.OS_horizontal.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: this.data[this.data.length - 1].rawEyeOSx,
                            type: 16,
                            frequency: localFrame.frequency,
                        });
                        this.OS_vertical.push({
                            time: this.getXvalue(this.startTimestamp, localFrame.timestamp),
                            amplitude: this.data[this.data.length - 1].eyeOSy,
                            type: 16,
                        });
                        this.data.push(localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        this.data.push(localFrame);
                        this.charData$.next({
                            frames: this.data,
                            chartType: 'real-time',
                        });
                        break;
                    default:
                        this.data.push(localFrame);
                        break;
                }
            });
        });
    }

    public setCamData(frames: ISmoothPursuitTestCamMessage[]): void {
        const { stimuliOD, stimuliOS, rawEyeOD, rawEyeOS } = this.parseFrames(frames);

        this.rawFrames = frames;

        const gainChartData = this.calculateGainChartData(stimuliOS, stimuliOD, rawEyeOD, rawEyeOS);

        this.testResults = gainChartData?.testResults;
    }

    public export(): StimuliResults[] {
        return this.testResults.length !== 0 ? this.testResults : [];
    }

    public getRawExport(): IRawExportData {
        const rawModel: IRawExportData = {
            rows: [],
        };

        if (this.rawFrames.length === 0) return rawModel;

        const startTimestamp = this.startTimestamp ? this.startTimestamp : this.rawFrames[0].timestamp / 10000;

        this.rawFrames.forEach(frame => {
            rawModel.rows.push({
                time: this.getXvalue(startTimestamp, frame.timestamp),
                pupil: {
                    OD: {
                        x: frame.rawEyeODx === 0 ? null : frame.rawEyeODx,
                        y: frame.rawEyeODy === 0 ? null : frame.rawEyeODy,
                        diameter: null,
                        dpg: {
                            x: null,
                            y: null,
                        },
                        bpg: {
                            x: null,
                            y: null,
                        },
                    },
                    OS: {
                        x: frame.rawEyeOSx === 0 ? null : frame.rawEyeOSx,
                        y: frame.rawEyeOSy === 0 ? null : frame.rawEyeOSy,
                        diameter: null,
                        dpg: {
                            x: null,
                            y: null,
                        },
                        bpg: {
                            x: null,
                            y: null,
                        },
                    },
                },
                stimulus: {
                    OD: {
                        x: frame.stimuliODx === 0 ? null : frame.stimuliODx,
                        y: frame.stimuliODy === 0 ? null : frame.stimuliODy,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                    OS: {
                        x: frame.stimuliOSx === 0 ? null : frame.stimuliOSx,
                        y: frame.stimuliOSy === 0 ? null : frame.stimuliOSy,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                },
                background: {
                    OD: {
                        r: null,
                        g: null,
                        b: null,
                    },
                    OS: {
                        r: null,
                        g: null,
                        b: null,
                    },
                },
            });
        });

        return rawModel;
    }

    public clearData(): void {
        this.data = [];
        this.OD_stimuli = [];
        this.OD_horizontal = [];
        this.OS_stimuli = [];
        this.OS_horizontal = [];
        this.OD_vertical = [];
        this.OS_vertical = [];
        this.zeroOD = undefined;
        this.zeroOS = undefined;
        this.stagesStart = 0;
        this.startTimestamp = 0;
        this.rawFrames = [];

        this.charData$.next({ chartType: 'cleared' });
    }

    public getChartData(frames: ISmoothPursuitTestCamMessage[]): void {
        const ratio = this.averageRatio$.value;

        if (ratio === 0 || typeof(ratio) !== 'number' || isNaN(ratio)) {
            const chartData: ISmoothPursuitChartData = {
                chartType: 'no-content',
            };
    
            this.charData$.next(chartData);
        }
        else {
            const { stimuliOD, stimuliOS, rawEyeOD, rawEyeOS } = this.parseFrames(frames);

            const eyeCloneOS = _.cloneDeep(rawEyeOS);
            const eyeCloneOD = _.cloneDeep(rawEyeOD);
    
            const { horizontalOD, horizontalOS } = this.calibrateAllStimulus(stimuliOD, eyeCloneOD, eyeCloneOS);
    
            const separatedFrames = this.separateStimuli(stimuliOD, stimuliOS, horizontalOD, horizontalOS);
    
            const gainChartData = this.calculateGainChartData(stimuliOS, stimuliOD, rawEyeOD, rawEyeOS);
    
            const chartData: ISmoothPursuitChartData = {
                chartType: 'recorded',
                frames: frames,
                gainChartData: gainChartData,
                calibratedData: separatedFrames,
                parsedStimuli: stimuliOD,
            };
    
            this.charData$.next(chartData);
        }
    }

    private calculateGainChartData(
        stimuliOS: SmoothPursuitPoint[],
        stimuliOD: SmoothPursuitPoint[],
        rawEyeOD: SmoothPursuitPoint[],
        rawEyeOS: SmoothPursuitPoint[]
    ): GainChartData {
        // caclulation for correlation
        const { eyeODx, eyeOSx } = this.removePatientBlinks(rawEyeOD, rawEyeOS);

        const separatedStimulus = this.separateStimuli(stimuliOD, stimuliOS, eyeODx, eyeOSx);

        separatedStimulus.forEach((stim, i) => {
            const { horizontalOD, horizontalOS } = this.calibrateOneStimuli(stim.stimuliAmplitudeOD, stim.eyeAmplitudeOD, stim.eyeAmplitudeOS);
            stim.eyeAmplitudeOD = horizontalOD;
            stim.eyeAmplitudeOS = horizontalOS;
        });

        const gainChartsData = this.caclucateCorelationData(separatedStimulus);

        return gainChartsData;
    }

    private parseFrames(
        frames: ISmoothPursuitTestCamMessage[]
    ): { stimuliOS: SmoothPursuitPoint[]; stimuliOD: SmoothPursuitPoint[]; rawEyeOD: SmoothPursuitPoint[]; rawEyeOS: SmoothPursuitPoint[] } {
        frames = this.parsingService.parseFrames(frames) as ISmoothPursuitTestCamMessage[];
        this.startTimestamp = frames[0].timestamp / 10000;

        // all records should be handle between 15 message type and 16
        const usableFrames = frames;

        const stimuliOSx: SmoothPursuitPoint[] = usableFrames.map(el => ({
            time: this.getXvalue(this.startTimestamp, el.timestamp),
            amplitude: el.stimuliOSx,
            frequency: el.frequency,
            type: this.mapMessageType(el.message_type),
        }));
        const stimuliODx: SmoothPursuitPoint[] = usableFrames.map(el => ({
            time: this.getXvalue(this.startTimestamp, el.timestamp),
            amplitude: el.stimuliODx,
            frequency: el.frequency,
            type: this.mapMessageType(el.message_type),
        }));
        const rawEyeODx: SmoothPursuitPoint[] = usableFrames.map(el => ({
            time: this.getXvalue(this.startTimestamp, el.timestamp),
            amplitude: el.rawEyeODx,
            frequency: el.frequency,
            type: this.mapMessageType(el.message_type),
        }));
        const rawEyeOSx: SmoothPursuitPoint[] = usableFrames.map(el => ({
            time: this.getXvalue(this.startTimestamp, el.timestamp),
            amplitude: el.rawEyeOSx,
            frequency: el.frequency,
            type: this.mapMessageType(el.message_type),
        }));

        return { stimuliOD: stimuliODx, stimuliOS: stimuliOSx, rawEyeOD: rawEyeODx, rawEyeOS: rawEyeOSx };
    }

    private caclucateCorelationData(separatedStimulus: CalibratedData[]): GainChartData {
        const correlationData: GainChartStimuliData[] = [];
        const stimulusResults: StimuliResults[] = [];
        let xAxisStart: number;

        separatedStimulus.forEach((st, index) => {
            const windowsResultOD: WindowResult[] = [];
            const windowsResultOS: WindowResult[] = [];

            const arrayOD = new Array(st.eyeAmplitudeOD, st.stimuliAmplitudeOD);
            const arrayOS = new Array(st.eyeAmplitudeOS, st.stimuliAmplitudeOS);
            if (!this.isArraysLengthSame(arrayOD) || !this.isArraysLengthSame(arrayOS)) {
                throw Error('Arrays length must be the same');
            }

            // depends on period we change time factor
            switch (index) {
                case 0:
                    this.windowTime = 5;
                    break;
                case 1:
                    this.windowTime = 2;
                    break;
                case 2: {
                    this.windowTime = 2;
                    const firstStimulistart = st.stimuliAmplitudeOS.findIndex(s => s.type === 15) + 1;
                    const secondStimulistart = st.stimuliAmplitudeOS.slice(firstStimulistart).findIndex(s => s.type === 15) + 1;

                    xAxisStart = secondStimulistart;
                    break;
                }
            }

            const start = !xAxisStart ? st.stimuliAmplitudeOS.findIndex(s => s.type === 15) + 1 : xAxisStart;
            let end = st.stimuliAmplitudeOS.findIndex(s => s.type === 16);
            end = end !== -1 ? end : st.stimuliAmplitudeOS.length - 1;

            let stimuliOS = st.stimuliAmplitudeOS.slice(start, end).filter(el => el.type !== 15 && el.type !== 16);
            let stimuliOD = st.stimuliAmplitudeOD.slice(start, end).filter(el => el.type !== 15 && el.type !== 16);
            let amplitudesEyeOS = st.eyeAmplitudeOS.slice(start, end).filter(el => el.type !== 15 && el.type !== 16);
            let amplitudesEyeOD = st.eyeAmplitudeOD.slice(start, end).filter(el => el.type !== 15 && el.type !== 16);

            // searching for start and end index of first window
            const firstCorrelation = this.calculateFirstWindowCorrelation(stimuliOD, stimuliOS, amplitudesEyeOD, amplitudesEyeOS);
            const correlationsBetween = this.calculateInbetweenWindowsCorrelation(stimuliOD, stimuliOS, amplitudesEyeOD, amplitudesEyeOS);

            const correlations: CorrelationData[] = [].concat(firstCorrelation, correlationsBetween);

            correlations.forEach(el => {
                windowsResultOD.push({
                    timeShift: el.timeShiftOD,
                    phaseShift: el.phaseShiftOD,
                    gain: el.pointOD.corelation * 100,
                    startTime: el.pointOD.startTime,
                    endTime: el.pointOD.endTime,
                });

                windowsResultOS.push({
                    timeShift: el.timeShiftOS,
                    phaseShift: el.phaseShiftOS,
                    gain: el.pointOS.corelation * 100,
                    startTime: el.pointOS.startTime,
                    endTime: el.pointOS.endTime,
                });
            });

            stimulusResults.push({
                stimuliName: this.mapper.get(index),
                windowsResultOD: windowsResultOD,
                windowsResultOS: windowsResultOS,
            });

            correlationData.push({
                xAxisStart: index !== 2 ? st.stimuliAmplitudeOD[0].time : st.stimuliAmplitudeOD?.find(s => s.type === 15)?.time,
                xAxisEnd: st.stimuliAmplitudeOD[st.stimuliAmplitudeOD.length - 1].time,
                firstWindow: firstCorrelation,
                windowsInBetween: correlationsBetween,
            });
        });

        return {
            stimulusData: correlationData,
            testResults: stimulusResults,
        };
    }

    private calculateFirstWindowCorrelation(
        stimuliOD: SmoothPursuitPoint[],
        stimuliOS: SmoothPursuitPoint[],
        amplitudesEyeOD: SmoothPursuitPoint[],
        amplitudesEyeOS: SmoothPursuitPoint[]
    ): CorrelationData {
        const sinusMinValueOD = Math.min(...stimuliOD.map(s => s.amplitude));
        const sinusMinValueOS = Math.min(...stimuliOS.map(s => s.amplitude));

        const startIndexOD = stimuliOD.findIndex(s => s.amplitude === sinusMinValueOD);
        const startIndexOS = stimuliOS.findIndex(s => s.amplitude === sinusMinValueOS);

        const endIndexOD = stimuliOD.findIndex(s => s.time > stimuliOD[startIndexOD].time + this.windowTime);
        const endIndexOS = stimuliOS.findIndex(s => s.time > stimuliOS[startIndexOS].time + this.windowTime);

        const firstWindowStimuliOD = stimuliOD.slice(startIndexOD, endIndexOD).map(s => s.amplitude);
        const firstWindowStimuliOS = stimuliOS.slice(startIndexOS, endIndexOS).map(s => s.amplitude);
        const firstWindowEyeOD = amplitudesEyeOD.slice(startIndexOD, endIndexOD).map(s => s.amplitude);
        const firstWindowEyeOS = amplitudesEyeOS.slice(startIndexOS, endIndexOS).map(s => s.amplitude);

        const xcorrOD = xcorr(firstWindowStimuliOD, firstWindowEyeOD);
        const xcorrOS = xcorr(firstWindowStimuliOD, firstWindowEyeOD);

        const index = xcorrOD.indexOf(Math.max(...xcorrOD));

        const slicedStimuliOD = stimuliOD.slice(startIndexOD, endIndexOD);
        const slicedStimuliOS = stimuliOS.slice(startIndexOS, endIndexOS);

        const tShiftAltOD = this.calculateTimeShift(xcorrOD, slicedStimuliOD);
        const tShiftAltOS = this.calculateTimeShift(xcorrOS, slicedStimuliOS);

        const frequency = slicedStimuliOD[0].frequency;

        const phaseShiftOD = this.calculatePhaseShift(tShiftAltOD, frequency);
        const phaseShiftOS = this.calculatePhaseShift(tShiftAltOS, frequency);

        const corrCoefficientOD = calculate(firstWindowStimuliOD, firstWindowEyeOD);
        const corrCoefficientOS = calculate(firstWindowStimuliOS, firstWindowEyeOS);

        const lastPointOD: WindowData = {
            corelation: corrCoefficientOD,
            time: this.calculateAverage(stimuliOD.slice(startIndexOD, endIndexOD).map(el => el.time)),
            startTime: stimuliOD[startIndexOD].time,
            endTime: stimuliOD[endIndexOD].time,
        };
        const lastPointOS: WindowData = {
            corelation: corrCoefficientOS,
            time: this.calculateAverage(stimuliOS.slice(startIndexOS, endIndexOS).map(el => el.time)),
            startTime: stimuliOS[startIndexOS].time,
            endTime: stimuliOS[endIndexOS].time,
        };

        return {
            pointOD: lastPointOD,
            pointOS: lastPointOS,
            timeShiftOD: tShiftAltOD,
            timeShiftOS: tShiftAltOS,
            phaseShiftOD: phaseShiftOD,
            phaseShiftOS: phaseShiftOS,
        };
    }

    private calculateInbetweenWindowsCorrelation(
        stimuliOD: SmoothPursuitPoint[],
        stimuliOS: SmoothPursuitPoint[],
        amplitudesEyeOD: SmoothPursuitPoint[],
        amplitudesEyeOS: SmoothPursuitPoint[]
    ): CorrelationData[] {
        let countWindows = 1;
        const correlations: CorrelationData[] = [];

        const sinusMinAmplitudeOD = Math.min(...stimuliOD.map(s => s.amplitude));
        const sinusMinAmplitudeOS = Math.min(...stimuliOS.map(s => s.amplitude));

        const firstMinimumFrameOD = stimuliOD.find(s => s.amplitude === sinusMinAmplitudeOD);
        const firstMinimumFrameOS = stimuliOS.find(s => s.amplitude === sinusMinAmplitudeOS);

        // calculating correlation for every window in between
        for (let i = 0; i < countWindows; i++) {
            const startIndexOD = stimuliOD.findIndex(s => s.time > firstMinimumFrameOD.time + (i + 1) * this.timeFactor);
            const startIndexOS = stimuliOS.findIndex(s => s.time > firstMinimumFrameOS.time + (i + 1) * this.timeFactor);

            const slicedStimuliOD = stimuliOD.slice(startIndexOD);
            const slicedStimuliOS = stimuliOS.slice(startIndexOS);

            const endIndexOD = slicedStimuliOD.findIndex(s => s.time > slicedStimuliOD[0].time + this.windowTime) + startIndexOD;
            const endIndexOS = slicedStimuliOS.findIndex(s => s.time > slicedStimuliOS[0].time + this.windowTime) + startIndexOS;

            if (startIndexOD !== -1 && endIndexOD > startIndexOD) {
                const firstWindowStimuliOD = stimuliOD.slice(startIndexOD, endIndexOD).map(s => s.amplitude);
                const firstWindowStimuliOS = stimuliOS.slice(startIndexOS, endIndexOS).map(s => s.amplitude);
                const firstWindowEyeOD = amplitudesEyeOD.slice(startIndexOD, endIndexOD).map(s => s.amplitude);
                const firstWindowEyeOS = amplitudesEyeOS.slice(startIndexOS, endIndexOS).map(s => s.amplitude);

                const xcorrOD = xcorr(firstWindowStimuliOD, firstWindowEyeOD);
                const xcorrOS = xcorr(firstWindowStimuliOD, firstWindowEyeOD);

                const slicedStimuliOD = stimuliOD.slice(startIndexOD, endIndexOD);
                const slicedStimuliOS = stimuliOS.slice(startIndexOS, endIndexOS);

                const tShiftAltOD = this.calculateTimeShift(xcorrOD, slicedStimuliOD);
                const tShiftAltOS = this.calculateTimeShift(xcorrOS, slicedStimuliOS);

                const frequency = slicedStimuliOD[0].frequency;

                const phaseShiftOD = this.calculatePhaseShift(tShiftAltOD, frequency);
                const phaseShiftOS = this.calculatePhaseShift(tShiftAltOS, frequency);

                const corrCoefficientOD = calculate(firstWindowStimuliOD, firstWindowEyeOD);
                const corrCoefficientOS = calculate(firstWindowStimuliOS, firstWindowEyeOS);

                const lastPointOD: WindowData = {
                    corelation: corrCoefficientOD,
                    time: this.calculateAverage(stimuliOD.slice(startIndexOD, endIndexOD).map(el => el.time)),
                    startTime: stimuliOD[startIndexOD].time,
                    endTime: stimuliOD[endIndexOD].time,
                };
                const lastPointOS: WindowData = {
                    corelation: corrCoefficientOS,
                    time: this.calculateAverage(stimuliOS.slice(startIndexOS, endIndexOS).map(el => el.time)),
                    startTime: stimuliOS[startIndexOS].time,
                    endTime: stimuliOS[endIndexOS].time,
                };

                correlations.push({
                    pointOD: lastPointOD,
                    pointOS: lastPointOS,
                    timeShiftOD: tShiftAltOD,
                    timeShiftOS: tShiftAltOS,
                    phaseShiftOD: phaseShiftOD,
                    phaseShiftOS: phaseShiftOS,
                });

                countWindows++;
            }
        }

        return correlations;
    }

    private calculateLastWindowCorrelation(
        stimuliOD: SmoothPursuitPoint[],
        stimuliOS: SmoothPursuitPoint[],
        amplitudesEyeOD: SmoothPursuitPoint[],
        amplitudesEyeOS: SmoothPursuitPoint[]
    ): CorrelationData {
        const startIndexOD = stimuliOD.findIndex(s => s.time > stimuliOD[stimuliOD.length - 1].time - this.windowTime);
        const startIndexOS = stimuliOS.findIndex(s => s.time > stimuliOS[stimuliOS.length - 1].time - this.windowTime);

        const endIndexOD = stimuliOD.length - 1;
        const endIndexOS = stimuliOS.length - 1;

        const firstWindowStimuliOD = stimuliOD.slice(startIndexOD, endIndexOD).map(s => s.amplitude);
        const firstWindowStimuliOS = stimuliOS.slice(startIndexOS, endIndexOS).map(s => s.amplitude);
        const firstWindowEyeOD = amplitudesEyeOD.slice(startIndexOD, endIndexOD).map(s => s.amplitude);
        const firstWindowEyeOS = amplitudesEyeOS.slice(startIndexOS, endIndexOS).map(s => s.amplitude);

        const corrCoefficientOD = calculate(firstWindowStimuliOD, firstWindowEyeOD);
        const corrCoefficientOS = calculate(firstWindowStimuliOS, firstWindowEyeOS);

        const xcorrOD = xcorr(firstWindowStimuliOD, firstWindowEyeOD);
        const xcorrOS = xcorr(firstWindowStimuliOD, firstWindowEyeOD);

        const slicedStimuliOD = stimuliOD.slice(startIndexOD, endIndexOD);
        const slicedStimuliOS = stimuliOS.slice(startIndexOS, endIndexOS);

        const tShiftAltOD = this.calculateTimeShift(xcorrOD, slicedStimuliOD);
        const tShiftAltOS = this.calculateTimeShift(xcorrOS, slicedStimuliOS);

        const frequency = slicedStimuliOD[0].frequency;

        const phaseShiftOD = this.calculatePhaseShift(tShiftAltOD, frequency);
        const phaseShiftOS = this.calculatePhaseShift(tShiftAltOS, frequency);

        const lastPointOD: WindowData = {
            corelation: corrCoefficientOD,
            time: this.calculateAverage(stimuliOD.slice(startIndexOD, endIndexOD).map(el => el.time)),
            startTime: stimuliOD[startIndexOD].time,
            endTime: stimuliOD[endIndexOD].time,
        };
        const lastPointOS: WindowData = {
            corelation: corrCoefficientOS,
            time: this.calculateAverage(stimuliOS.slice(startIndexOS, endIndexOS).map(el => el.time)),
            startTime: stimuliOS[startIndexOS].time,
            endTime: stimuliOS[endIndexOS].time,
        };

        return {
            pointOD: lastPointOD,
            pointOS: lastPointOS,
            timeShiftOD: tShiftAltOD,
            timeShiftOS: tShiftAltOS,
            phaseShiftOD: phaseShiftOD,
            phaseShiftOS: phaseShiftOS,
        };
    }

    private calibrateOneStimuli(
        stimuliODx: SmoothPursuitPoint[],
        horizontalOD: SmoothPursuitPoint[],
        horizontalOS: SmoothPursuitPoint[]
    ): { horizontalOD: SmoothPursuitPoint[]; horizontalOS: SmoothPursuitPoint[] } {
        const startFrequency: { x: number }[] = [];
        const finishFrequency: { x: number }[] = [];

        stimuliODx.forEach(e => {
            e.type === 15 ? startFrequency.push({ x: e.time }) : null;
            e.type === 16 ? finishFrequency.push({ x: e.time }) : null;
        });

        if (startFrequency.length === 0 && finishFrequency.length === 0) {
            return { horizontalOD, horizontalOS };
        } else if (finishFrequency.length === 0) {
            finishFrequency.push({ x: stimuliODx[stimuliODx.length - 1].time });
        }

        const ratio = this.averageRatio$.getValue();
        
        const beginingIndex = stimuliODx.findIndex(e => e.time === startFrequency[startFrequency.length - 1].x);

        const lastTenFramesBeforeStartOD = horizontalOD.filter((e, i) => i <= beginingIndex).slice(-10);
        const lastTenFramesBeforeStartOS = horizontalOS.filter((e, i) => i <= beginingIndex).slice(-10);

        const lastTenFramesNumsOD = lastTenFramesBeforeStartOD.map(e => e.amplitude);
        const lastTenFramesNumsOS = lastTenFramesBeforeStartOS.map(e => e.amplitude);

        const zeroHorizontalOD = lastTenFramesNumsOD.reduce((sum, el) => sum + el, 0) / lastTenFramesNumsOD.length;
        const zeroHorizontalOS = lastTenFramesNumsOS.reduce((sum, el) => sum + el, 0) / lastTenFramesNumsOS.length;

        const endIndexOD = stimuliODx.findIndex(el => el.time === finishFrequency[finishFrequency.length - 1].x);
        const endIndexOS = horizontalOS.findIndex(el => el.time === finishFrequency[finishFrequency.length - 1].x);

        horizontalOD.slice(0, endIndexOD).forEach(e => {
            e.amplitude = -(e.amplitude - zeroHorizontalOD) / ratio;
        });
        horizontalOS.slice(0, endIndexOS).forEach(e => {
            e.amplitude = -(e.amplitude - zeroHorizontalOS) / ratio;
        });

        return { horizontalOD, horizontalOS };
    }

    private calibrateAllStimulus(
        stimuliODx: SmoothPursuitPoint[],
        horizontalOD: SmoothPursuitPoint[],
        horizontalOS: SmoothPursuitPoint[]
    ): { horizontalOD: SmoothPursuitPoint[]; horizontalOS: SmoothPursuitPoint[] } {
        const startFrequencies: { x: number }[] = [];
        const finishFrequenies: { x: number }[] = [];

        const countCalibrationFrames = 10;

        stimuliODx.forEach(e => {
            e.type === 15 ? startFrequencies.push({ x: e.time }) : null;
            e.type === 16 ? finishFrequenies.push({ x: e.time }) : null;
        });

        if (startFrequencies.length === 0 && finishFrequenies.length === 0) {
            return { horizontalOD, horizontalOS };
        } else if (finishFrequenies.length === 0) {
            finishFrequenies.push({ x: stimuliODx[stimuliODx.length - 1].time });
        }

        const ratio = this.averageRatio$.getValue();

        if (ratio === 0) {
            throw Error('Pro saccades test havent done');
        }

        let startCalibrationIndex = 0;

        startFrequencies.forEach((startFrequency, i) => {
            let increment = 1;

            const beginingIndex = stimuliODx.findIndex(e => e.time === startFrequency.x);

            let lastTenFramesBeforeStartOD = horizontalOD.filter((e, i) => i <= beginingIndex).slice(-countCalibrationFrames);
            let lastTenFramesBeforeStartOS = horizontalOS.filter((e, i) => i <= beginingIndex).slice(-countCalibrationFrames);

            const findCalibrationData = () => {
                while (lastTenFramesBeforeStartOD.some(el => el.amplitude === 0)) {
                    increment++;

                    const start = countCalibrationFrames * increment;
                    const end = start - countCalibrationFrames;

                    // if all frames before are zeroes then take frames from previous stimuli
                    if (beginingIndex - start < startCalibrationIndex && i !== 0) {
                        const previousStimuliIndex = stimuliODx.findIndex(e => e.time === startFrequencies[i].x);

                        lastTenFramesBeforeStartOD = horizontalOD.filter((e, i) => i <= previousStimuliIndex).slice(-countCalibrationFrames);
                        lastTenFramesBeforeStartOS = horizontalOS.filter((e, i) => i <= previousStimuliIndex).slice(-countCalibrationFrames);

                        break;
                    }

                    lastTenFramesBeforeStartOD = horizontalOD.filter((e, i) => i <= beginingIndex).slice(-start, -end);
                    lastTenFramesBeforeStartOS = horizontalOS.filter((e, i) => i <= beginingIndex).slice(-start, -end);
                }
            };

            findCalibrationData();

            const lastTenFramesNumsOD = lastTenFramesBeforeStartOD.map(e => e.amplitude);
            const lastTenFramesNumsOS = lastTenFramesBeforeStartOS.map(e => e.amplitude);

            const zeroHorizontalOD = lastTenFramesNumsOD.reduce((sum, el) => sum + el, 0) / lastTenFramesNumsOD.length;
            const zeroHorizontalOS = lastTenFramesNumsOS.reduce((sum, el) => sum + el, 0) / lastTenFramesNumsOS.length;

            const endIndexOD = horizontalOD.findIndex(el => el.time === finishFrequenies[i].x);
            const endIndexOS = horizontalOS.findIndex(el => el.time === finishFrequenies[i].x);

            horizontalOD.slice(startCalibrationIndex, endIndexOD).forEach(e => {
                e.amplitude = -(e.amplitude - zeroHorizontalOD) / ratio;
            });
            horizontalOS.slice(startCalibrationIndex, endIndexOS).forEach(e => {
                e.amplitude = -(e.amplitude - zeroHorizontalOS) / ratio;
            });

            startCalibrationIndex = endIndexOD;
        });

        return { horizontalOD, horizontalOS };
    }

    private separateStimuli(
        stimuliOD: SmoothPursuitPoint[],
        stimuliOS: SmoothPursuitPoint[],
        horizontalOD: SmoothPursuitPoint[],
        horizontalOS: SmoothPursuitPoint[]
    ): CalibratedData[] {
        const startFrequency: { x: number }[] = [];
        const finishFrequency: { x: number }[] = [];

        stimuliOD.forEach(e => {
            e.type === 15 ? startFrequency.push({ x: e.time }) : null;
            e.type === 16 ? finishFrequency.push({ x: e.time }) : null;
        });

        const stimuliInfo: CalibratedData[] = [];

        if (startFrequency.length !== 0) {
            let stimuliODx = stimuliOD.filter(e => e.time < startFrequency[1].x);
            let horizontalODx = horizontalOD.filter(e => e.time < startFrequency[1].x);

            let stimuliOSx = stimuliOS.filter(e => e.time < startFrequency[1].x);
            let horizontalOSx = horizontalOS.filter(e => e.time < startFrequency[1].x);

            stimuliInfo.push({
                stimuliAmplitudeOD: stimuliODx,
                stimuliAmplitudeOS: stimuliOSx,
                eyeAmplitudeOD: horizontalODx,
                eyeAmplitudeOS: horizontalOSx,
            });

            stimuliODx = stimuliOD.filter(e => e.time > finishFrequency[0].x && e.time < startFrequency[2].x);
            horizontalODx = horizontalOD.filter(e => e.time > finishFrequency[0].x && e.time < startFrequency[2].x);

            stimuliOSx = stimuliOS.filter(e => e.time > finishFrequency[0].x && e.time < startFrequency[2].x);
            horizontalOSx = horizontalOS.filter(e => e.time > finishFrequency[0].x && e.time < startFrequency[2].x);

            stimuliInfo.push({
                stimuliAmplitudeOD: stimuliODx,
                stimuliAmplitudeOS: stimuliOSx,
                eyeAmplitudeOD: horizontalODx,
                eyeAmplitudeOS: horizontalOSx,
            });

            // left frames with message type 15 for further handle two stimulus
            stimuliODx = stimuliOD.filter(e => e.time >= startFrequency[2].x && e.type !== 16);
            horizontalODx = horizontalOD.filter(e => e.time >= startFrequency[2].x && e.type !== 16);

            stimuliOSx = stimuliOS.filter(e => e.time >= startFrequency[2].x && e.type !== 16);
            horizontalOSx = horizontalOS.filter(e => e.time >= startFrequency[2].x && e.type !== 16);

            const eyeCalibrationWaveOD = horizontalODx.filter(e => e.time >= startFrequency[2].x && e.time < startFrequency[3].x);
            const eyeCalibrationWaveOS = horizontalOSx.filter(e => e.time >= startFrequency[2].x && e.time < startFrequency[3].x);

            const stimuliCalibrationWaveOD = _.cloneDeep(stimuliODx).filter(e => e.time >= startFrequency[2].x && e.time < startFrequency[3].x);
            const stimuliCalibrationWaveOS = _.cloneDeep(stimuliOSx).filter(e => e.time >= startFrequency[2].x && e.time < startFrequency[3].x);

            stimuliCalibrationWaveOD.forEach(e => {
                e.amplitude = e.amplitude / 2;
            });
            stimuliCalibrationWaveOS.forEach(e => {
                e.amplitude = e.amplitude / 2;
            });

            let startingLastStageIndex = stimuliODx.slice(1).findIndex(e => e.type === 15);
            startingLastStageIndex = startingLastStageIndex ? startingLastStageIndex : 0;

            stimuliODx = stimuliODx.filter((e, i) => i >= startingLastStageIndex);
            stimuliOSx = stimuliOSx.filter((e, i) => i >= startingLastStageIndex);
            horizontalODx = horizontalODx.filter((e, i) => i >= startingLastStageIndex);
            horizontalOSx = horizontalOSx.filter((e, i) => i >= startingLastStageIndex);
            stimuliODx = [...stimuliCalibrationWaveOD, ...stimuliODx];
            stimuliOSx = [...stimuliCalibrationWaveOS, ...stimuliOSx];
            horizontalODx = [...eyeCalibrationWaveOD, ...horizontalODx];
            horizontalOSx = [...eyeCalibrationWaveOS, ...horizontalOSx];

            stimuliInfo.push({
                stimuliAmplitudeOD: stimuliODx,
                stimuliAmplitudeOS: stimuliOSx,
                eyeAmplitudeOD: horizontalODx,
                eyeAmplitudeOS: horizontalOSx,
            });
        }

        return stimuliInfo;
    }

    private removePatientBlinks(eyeODx: SmoothPursuitPoint[], eyeOSx: SmoothPursuitPoint[]) {
        const indexesOD = eyeODx
            .map((s, i) => {
                return s.amplitude === 0 ? i : null;
            })
            .filter(s => s !== null);
        const indexesOS = eyeOSx
            .map((s, i) => {
                return s.amplitude === 0 ? i : null;
            })
            .filter(s => s !== null);

        this.fillGap(indexesOD, eyeODx);
        this.fillGap(indexesOS, eyeOSx);

        return { eyeODx, eyeOSx };
    }

    private fillGap(indexesOD: number[], eyeX: SmoothPursuitPoint[]): void {
        let lastIndex = 0;
        let zeroesCount = 1;
        const fillingFrames: SmoothPursuitPoint[] = [];

        indexesOD.forEach(el => {
            if (el - 1 === lastIndex) {
                zeroesCount++;
            } else {
                if (zeroesCount > 1) {
                    if (lastIndex - zeroesCount > 0) {
                        const startElementBeforeGap = eyeX[lastIndex - zeroesCount].amplitude ? eyeX[lastIndex - zeroesCount] : eyeX[lastIndex - zeroesCount + 1];
                        const endElementAfterGap = eyeX[lastIndex + 1].type !== 16 ? eyeX[lastIndex + 1] : eyeX[lastIndex + 2];

                        // evenly fill the gap
                        const amplitudes = this.linspace(startElementBeforeGap.amplitude, endElementAfterGap.amplitude, zeroesCount);
                        const times = eyeX.slice(lastIndex - zeroesCount + 1, lastIndex + 1).map(el => el.time);
                        const frequencies = this.linspace(startElementBeforeGap.frequency, endElementAfterGap.frequency, zeroesCount);

                        for (let i = 0; i < zeroesCount; i++) {
                            fillingFrames.push({
                                time: times[i],
                                amplitude: amplitudes[i],
                                frequency: frequencies[i],
                                type: 6,
                            });
                        }

                        const startGapIndex = lastIndex - zeroesCount + 1;

                        eyeX.splice(startGapIndex, zeroesCount, ...fillingFrames);
                    }
                    // if zeroes start from the first frame
                    else if (lastIndex - zeroesCount < 0) {
                        const endElementAfterGap = eyeX[lastIndex + 1].type !== 16 ? eyeX[lastIndex + 1] : eyeX[lastIndex + 2];

                        const amplitudes = Array(zeroesCount).fill(endElementAfterGap.amplitude);
                        const times = eyeX.slice(0, lastIndex + 1).map(el => el.time);
                        const frequencies = Array(zeroesCount).fill(endElementAfterGap.frequency);

                        for (let i = 0; i < zeroesCount; i++) {
                            fillingFrames.push({
                                time: times[i],
                                amplitude: amplitudes[i],
                                frequency: frequencies[i],
                                type: 6,
                            });
                        }

                        eyeX.splice(0, zeroesCount, ...fillingFrames);
                    }
                    // if zeroes finish on the last frame
                    else if (lastIndex + 1 > eyeX.length - 1) {
                        const startElementBeforeGap = eyeX[lastIndex - zeroesCount].amplitude ? eyeX[lastIndex - zeroesCount] : eyeX[lastIndex - zeroesCount + 1];

                        const amplitudes = Array(zeroesCount).fill(startElementBeforeGap.amplitude);
                        const times = eyeX.slice(0, lastIndex + 1).map(el => el.time);
                        const frequencies = Array(zeroesCount).fill(startElementBeforeGap.frequency);

                        for (let i = 0; i < zeroesCount; i++) {
                            fillingFrames.push({
                                time: times[i],
                                amplitude: amplitudes[i],
                                frequency: frequencies[i],
                                type: 6,
                            });
                        }

                        eyeX.splice(0, zeroesCount, ...fillingFrames);
                    }
                }

                zeroesCount = 1;
                fillingFrames.length = 0;
            }

            lastIndex = el;
        });
    }

    private mapMessageType(messageType: MESSAGE_TYPE): number {
        switch (messageType) {
            case MESSAGE_TYPE.START_TEST:
                return 0;
            case MESSAGE_TYPE.DATA_PACKAGE:
                return 6;
            case MESSAGE_TYPE.START_SEQUENCE:
                return 15;
            case MESSAGE_TYPE.CONFIGURATION_PARAMS:
                return 16;
            case MESSAGE_TYPE.STOP_TEST:
                return 1;
        }
    }

    private isArraysLengthSame<T>(arrayOfArrays: Array<T[]>) {
        return arrayOfArrays.every(arr => arr.length === arrayOfArrays[0].length);
    }

    private radToDegree(rad: number) {
        return (rad * 360) / (2 * Math.PI);
    }

    private linspace(start: number, stop: number, count: number, endpoint = true) {
        const div = endpoint ? count - 1 : count;
        const step = (stop - start) / div;
        return Array.from({ length: count }, (_, i) => start + step * i);
    }

    private calculateTimeShift(xcorr: number[], stimuli: SmoothPursuitPoint[]) {
        const index = xcorr.indexOf(Math.max(...xcorr));
        const lastTime = stimuli[stimuli.length - 1].time - stimuli[0].time;
        const dt = this.linspace(-lastTime, lastTime, 2 * stimuli.length - 1);
        const tShiftAlt = dt[index];

        return tShiftAlt;
    }

    private calculatePhaseShift(tShiftAlt: number, frequency: number): number {
        const phaseShift = this.radToDegree(2 * Math.PI * (tShiftAlt / (1 / frequency)));

        if (phaseShift >= -this.PHASE_SHIFT_LIMIT && phaseShift <= this.PHASE_SHIFT_LIMIT) {
            return phaseShift;
        } else if (phaseShift < -this.PHASE_SHIFT_LIMIT) {
            return phaseShift + this.MAX_DEGREE;
        } else if (phaseShift > this.PHASE_SHIFT_LIMIT) {
            return phaseShift - this.MAX_DEGREE;
        }

        return null;
    }
}
