import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { AntSegmentTopconTritonMeasuredDataFrontend } from '../../../measuredData/TOPCON/TRITON/antSegmentTopconDataFrontend.class';

export class AntSegmentTopconTritonTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof AntSegmentTopconTritonMeasuredDataFrontend = AntSegmentTopconTritonMeasuredDataFrontend;
    public measurements: AntSegmentTopconTritonMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
