// @ts-nocheck
import * as d3 from 'd3';
import * as _ from 'lodash';
import * as lo from '../layout';
import { Target } from '../lib';
import { Diagram } from '../Diagram';
import { translate, noPaths } from '../lib';
import { sdLine, drawTriangle, useTitle } from '../lib';
import { errorPoints, successPoints } from '../fixedData';
import { Decorations } from './Decorations';

const SUCCESS = 'success/antisaccade';
const ERROR = 'error/saccade';

let applyTitle = (info: string) => (t: Target) => {
    let title = `Control point: ${info}`;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTitle(title)(t);
};
// let titleForControlPoint = (d: AgePoint) =>
// `Control point for age: ${d.age}`

interface Point {
    x: number;
    y: number;
}

interface AgePoint {
    age: number;
    mean: number;
}

export class RenderOnce {
    decoration: Decorations;

    constructor(private diagram: Diagram) {
        this.decoration = new Decorations(diagram);

        this.setupAxes();
        this.setupBGDots();
        this.setupFixedLines();

        this.setupBottomDots();
        this.setupBottomAxes();
        this.setupBottomData();
    }

    get g() {
        return this.diagram.g;
    }
    get style() {
        return this.diagram.style;
    }
    get scale() {
        return this.diagram.scale;
    }
    get correctMargin() {
        return this.diagram.state.correctMargin;
    }
    get incorrectMargin() {
        return this.diagram.state.incorrectMargin;
    }
    get line() {
        return this.diagram.line;
    }

    setupAxes() {
        let target = this.g.statics;
        let { scale } = this;

        let styleAxes = (x: Target) => {
            x.selectAll('text')
                .attr('font-size', this.style.axesText.size)
                .attr('fill', this.style.axesText.color);
        };

        let bottomAxis = d3
            .axisBottom(scale.x)
            .tickSize(0)
            // .tickFormat(x => x == 0 || x === 500 || x == 1000 ? `${x} ms` : x )
            .tickFormat((x) => (x === 0 || x === 1000 ? `${x} ms` : `${x}`));

        target
            .append('g')
            .attr('transform', translate(lo.topDistanceAxis.point))
            .call(bottomAxis)
            .call(noPaths)
            .call(styleAxes);

        let rightAxis = d3
            .axisRight(this.scale.y)
            .tickSize(0)
            .tickFormat((x) => (Math.abs(+x) === 10 ? `${Math.abs(+x)} deg` : `${Math.abs(+x)}`));

        // let yTicks = this.scale.y.ticks();
        // yTicks.push(this.correctMargin);
        // yTicks.push(this.incorrectMargin);
        // rightAxis.tickValues(yTicks);

        target
            .append('g')
            .attr('transform', translate(lo.topAngleAxis.point))
            .call(rightAxis)
            .call(noPaths)
            .call(styleAxes);

        let leftAxis = d3
            .axisLeft(scale.yPercent)
            .ticks(5)
            .tickSize(0)
            .tickFormat((x) => `${Math.abs(+x) * 100}%`);

        target
            .append('g')
            .attr('transform', translate(lo.topPercentAxis.point))
            .call(leftAxis)
            .call(noPaths)
            .call(styleAxes);
    }

    setupBGDots() {
        let target = this.g.statics;
        let { scale } = this;

        target
            .append('g')
            .selectAll('circle')
            .data(this.decoration.bgDots)
            .join('circle')
            .attr('r', this.style.bgDots.size)
            .attr('cx', (d) => scale.x(d[0]))
            .attr('cy', (d) => scale.y(d[1]))
            .attr('fill', this.style.bgDots.color)
            .attr('stroke-opacity', 0);
    }

    setupFixedLines() {
        let target1 = this.g.statics;
        let target = target1.append('g');
        let line = this.line.line;

        let fixed_line_style = (x: Target) => x.attr('stroke', this.style.fixedLine.color).attr('stroke-width', this.style.fixedLine.size);
        let dashed_line_style = (x: Target) =>
            x
                .attr('stroke', this.style.dashedLine.color)
                .attr('stroke-width', this.style.dashedLine.size)
                .attr('stroke-dasharray', this.style.dashedLine.dashes);

        target
            .append('path')
            .datum(this.decoration.hCentralLine)
            .attr('d', line)
            .call(fixed_line_style);
        target
            .selectAll('.abc')
            .data(this.decoration.bgVLines)
            .join('path')
            .attr('d', line)
            .call(dashed_line_style);
        target
            .selectAll('.def')
            .data(this.decoration.correctnessLines)
            .join('path')
            .attr('d', line)
            .call(dashed_line_style);
    }

    setupBottomAxes() {
        let target = this.g.statics;

        let styleAxes = (x: Target) => {
            x.selectAll('text')
                .attr('font-size', this.style.axesText.size)
                .attr('fill', this.style.axesText.color);
        };

        let hAxisFormat = (x: number) => (x === 1000 || x === 0 ? `${x} ms` : `${x}`);

        let bottomAxis = d3
            .axisBottom<number>(this.scale.bottomX)
            .tickSize(0)
            .tickFormat(hAxisFormat);

        target
            .append('g')
            .attr('transform', translate(lo.bottomSRTAxis.point))
            .call(bottomAxis)
            .call(noPaths)
            .call(styleAxes);

        let leftAxis = d3
            .axisLeft<number>(this.scale.bottomY)
            .ticks(10)
            .tickSize(0)
            .tickFormat((x) => (x === 5 || x === 100 ? `${x} years` : `${x}`));

        target
            .append('g')
            .attr('transform', translate(lo.bottomAgeAxis.point))
            .call(leftAxis)
            .call(noPaths)
            .call(styleAxes);

        let topAxis = d3
            .axisTop<number>(this.scale.bottomX)
            .tickSize(0)
            .tickFormat(hAxisFormat);

        target
            .append('g')
            .attr('transform', translate(lo.bottomTopAxis.point))
            .call(topAxis)
            .call(noPaths)
            .call(styleAxes);
    }

    setupBottomDots() {
        let target = this.g.statics;

        let dots = _.flatten(_.range(5, 100 + 5, 5).map((y) => _.range(0, 1000 + 20, 20).map((x) => [x, y])));

        target
            .append('g')
            .selectAll('circle')
            .data(dots)
            .join('circle')
            .attr('r', this.style.bgDots.size)
            .attr('cx', (d) => this.scale.bottomX(d[0]))
            .attr('cy', (d) => this.scale.bottomY(d[1]))
            .attr('fill', this.style.bgDots.color)
            .attr('stroke-opacity', 0);

        // v-lines
        let dashed_line_style = (x: Target) =>
            x
                .attr('stroke', this.style.dashedLine.color)
                .attr('stroke-width', this.style.dashedLine.size)
                .attr('stroke-dasharray', this.style.dashedLine.dashes);

        let line = d3
            .line<{ x: number; y: number }>()
            .x((d) => this.scale.bottomX(d.x))
            .y((d) => this.scale.bottomY(d.y));

        target
            .append('g')
            .selectAll('.abc')
            .data(this.decoration.bottomBgVLines)
            .join('path')
            .attr('d', line)
            .call(dashed_line_style);
    }

    setupBottomData() {
        let target = this.g.statics;

        let line = d3
            .line<AgePoint>()
            .x((d) => this.scale.bottomX(d.mean))
            .y((d) => this.scale.bottomY(d.age))
            .curve(d3.curveMonotoneY);

        let color = (d: AgePoint) => d.age / 100;
        let useColor = (d: AgePoint) => d3.interpolateBlues(color(d));

        target
            .append('path')
            .datum(successPoints)
            .attr('d', line)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.bottomLine.color)
            .attr('stroke-width', 1)
            .attr('pointer-events', 'none');

        let paths1 = target
            .append('g')
            .selectAll('g')
            .data(successPoints)
            .join('g')
            .attr('transform', (d) =>
                translate({
                    x: this.scale.bottomX(d.mean),
                    y: this.scale.bottomY(d.age),
                })
            );

        paths1
            .append('path')
            .attr('d', drawTriangle)
            .attr('fill', useColor)
            .attr('stroke-opacity', 0)
            .attr('transform', 'scale(15)')
            // .attr('pointer-events', 'auto')
            .call(applyTitle(SUCCESS));

        paths1
            .append('path')
            .attr('d', sdLine(this.scale.bottomX))
            .attr('fill', 'transparent')
            .attr('stroke', this.style.bottomLine.color)
            // .attr('pointer-events', 'auto')
            .call(applyTitle(SUCCESS));

        target
            .append('path')
            .datum(errorPoints)
            .attr('d', line)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.bottomLine.color)
            .attr('stroke-width', 1)
            .attr('pointer-events', 'none');

        let paths2 = target
            .append('g')
            .selectAll('g')
            .data(errorPoints)
            .join('g')
            .attr('transform', (d) =>
                translate({
                    x: this.scale.bottomX(d.mean),
                    y: this.scale.bottomY(d.age),
                })
            );

        paths2
            .append('circle')
            .attr('r', 10)
            .attr('fill', useColor)
            .attr('stroke-opacity', 0)
            .call(applyTitle(ERROR));

        paths2
            .append('path')
            .attr('d', sdLine(this.scale.bottomX))
            .attr('fill', 'transparent')
            .attr('stroke', this.style.bottomLine.color)
            .call(applyTitle(ERROR));
    }
}
