import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IUser } from '../../../../../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../../../../../commonout/interfaces/userFrontend.interface';
import { PupillaryEvaluationNidekArk1sTestFrontend } from '../../../../../../_models/tests/NIDEK/ARK1S/pupillaryEvaluationNidekArk1sTestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'pupillary-evaluation-ark1s-test-remarks',
    template: require('./pupillary-evaluation-ark1s-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./pupillary-evaluation-ark1s-test-remarks.component.scss')],
})
export class PupillaryEvaluationArk1sTestRemarksComponent extends TestRemarksComponent {
    test: PupillaryEvaluationNidekArk1sTestFrontend;
    private readonly radio: string[] = ['Normal', 'Abnormal'];
    private readonly reactionToLight: string[] = ['Brisk', 'Not reacting', 'Sluggish', 'Ill sustained', 'Slow tonic'];
    private readonly pupilMorphology: string[] = ['Sphincter atrophy', 'Seclusio pupillae', 'Occlusio pupillae', 'Polycoria', 'Sphincter tear'];
    private readonly rapo: string[] = ['Present', 'Absent'];
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            dataBlock: this.formBuilder.array([]),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map(m => m.downloadMediaData(this.fileService)));
        this.remarksForm.patchValue(
            {
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
        this.remarksForm.setControl(
            'dataBlock',
            this.formBuilder.array(
                this.test.remarks.measurements
                    .map(m => {
                        return this.formBuilder.group({
                            sourceFileName: { value: m.sourceFileName, disabled: true },
                            OD: this.formBuilder.group({
                                size: this.formBuilder.group({
                                    type: m.OD.size.type.value,
                                    pupilSizeOD: {
                                        value: m.pupilSize.OD.value,
                                        disabled: true,
                                    },
                                }),
                                reactionToLight: this.formBuilder.group({
                                    type: m.OD.reactionToLight.type.value,
                                    select: m.OD.reactionToLight.select.value,
                                }),
                                rapo: this.formBuilder.group({
                                    type: m.OD.rapo.type.value,
                                }),
                                pupilMorphology: this.formBuilder.group({
                                    type: m.OD.pupilMorphology.type.value,
                                    select: m.OD.pupilMorphology.select.value,
                                }),
                            }),
                            OS: this.formBuilder.group({
                                size: this.formBuilder.group({
                                    type: m.OS.size.type.value,
                                    pupilSizeOS: {
                                        value: m.pupilSize.OS.value,
                                        disabled: true,
                                    },
                                }),
                                reactionToLight: this.formBuilder.group({
                                    type: m.OS.reactionToLight.type.value,
                                    select: m.OS.reactionToLight.select.value,
                                }),
                                rapo: this.formBuilder.group({
                                    type: m.OS.rapo.type.value,
                                }),
                                pupilMorphology: this.formBuilder.group({
                                    type: m.OS.pupilMorphology.type.value,
                                    select: m.OS.pupilMorphology.select.value,
                                }),
                            }),
                        });
                    })
                    .sort(this.sort)
            )
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {
        this.test.remarks.measurements.forEach(m => {
            const formItem: any = rawFormValue.dataBlock.find((item: any) => item.sourceFileName === m.sourceFileName),
                user: IUser<any> & IUserFrontend = this.authenticationService.currentUser.getModel(true);
            if (!formItem) throw 'Error managing formarray in ' + this.test.name;
            m.OD.size.type.registerEdit(formItem.OD.size.type, user);
            m.OD.pupilMorphology.select.registerEdit(formItem.OD.pupilMorphology.select, user);
            m.OD.pupilMorphology.type.registerEdit(formItem.OD.pupilMorphology.type, user);
            m.OD.rapo.type.registerEdit(formItem.OD.rapo.type, user);
            m.OD.reactionToLight.select.registerEdit(formItem.OD.reactionToLight.select, user);
            m.OD.reactionToLight.type.registerEdit(formItem.OD.reactionToLight.type, user);
            m.OS.size.type.registerEdit(formItem.OS.size.type, user);
            m.OS.pupilMorphology.select.registerEdit(formItem.OS.pupilMorphology.select, user);
            m.OS.pupilMorphology.type.registerEdit(formItem.OS.pupilMorphology.type, user);
            m.OS.rapo.type.registerEdit(formItem.OS.rapo.type, user);
            m.OS.reactionToLight.select.registerEdit(formItem.OS.reactionToLight.select, user);
            m.OS.reactionToLight.type.registerEdit(formItem.OS.reactionToLight.type, user);
        });
        this.test.remarks.diagnosis.registerEdit(rawFormValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(rawFormValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(rawFormValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(rawFormValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
