import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IUser } from '../../../../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../../../../commonout/interfaces/userFrontend.interface';
import { PupillaryEvaluation2BulbicamTestFrontend } from '../../../../../_models/tests/BulbiCAM/pupillaryEvaluation2BulbicamTestFrontend.class';
import { ExaminationService } from '../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { FileService } from '../../../../../_services/general/file.service';
import { ModalService } from '../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../testRemarksComponent';

@Component({
    selector: 'pupillary-evaluation2-bulbicam-test-remarks',
    template: require('./pupillary-evaluation2-bulbicam-test-remarks.component.html'),
    styles: [require('../../common-remarks.scss'), require('./pupillary-evaluation2-bulbicam-test-remarks.component.scss')],
})
export class PupillaryEvaluation2BulbicamTestRemarksComponent extends TestRemarksComponent {
    test: PupillaryEvaluation2BulbicamTestFrontend;
    private readonly radio: string[] = ['Normal', 'Abnormal'];
    private readonly reactionToLight: string[] = ['Brisk', 'Not reacting', 'Sluggish', 'Ill sustained', 'Slow tonic'];
    private readonly pupilMorphology: string[] = ['Sphincter atrophy', 'Seclusio pupillae', 'Occlusio pupillae', 'Polycoria', 'Sphincter tear'];
    private readonly rapd: string[] = ['Present', 'Absent'];
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            OD: this.formBuilder.group({
                size: this.formBuilder.group({
                    type: '',
                    pupilSizeOD: {
                        value: '',
                        disabled: false,
                    },
                }),
                reactionToLight: this.formBuilder.group({
                    type: '',
                    select: '',
                }),
                rapo: this.formBuilder.group({
                    type: '',
                }),
                pupilMorphology: this.formBuilder.group({
                    type: '',
                    select: '',
                }),
            }),
            OS: this.formBuilder.group({
                size: this.formBuilder.group({
                    type: '',
                    pupilSizeOS: {
                        value: '',
                        disabled: false,
                    },
                }),
                reactionToLight: this.formBuilder.group({
                    type: '',
                    select: '',
                }),
                rapo: this.formBuilder.group({
                    type: '',
                }),
                pupilMorphology: this.formBuilder.group({
                    type: '',
                    select: '',
                }),
            }),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.remarksForm.setValue(
            {
                OD: {
                    size: {
                        type: this.test.remarks.measurements[0].OD.size.type.value,
                        pupilSizeOD: {
                            value: this.test.remarks.measurements[0].pupilSize.OD.value,
                            disabled: false,
                        },
                    },
                    reactionToLight: {
                        type: this.test.remarks.measurements[0].OD.reactionToLight.type.value,
                        select: this.test.remarks.measurements[0].OD.reactionToLight.select.value,
                    },
                    rapo: {
                        type: this.test.remarks.measurements[0].OD.rapo.type.value,
                    },
                    pupilMorphology: {
                        type: this.test.remarks.measurements[0].OD.pupilMorphology.type.value,
                        select: this.test.remarks.measurements[0].OD.pupilMorphology.select.value,
                    },
                },
                OS: {
                    size: {
                        type: this.test.remarks.measurements[0].OS.size.type.value,
                        pupilSizeOS: {
                            value: this.test.remarks.measurements[0].pupilSize.OS.value,
                            disabled: false,
                        },
                    },
                    reactionToLight: {
                        type: this.test.remarks.measurements[0].OS.reactionToLight.type.value,
                        select: this.test.remarks.measurements[0].OS.reactionToLight.select.value,
                    },
                    rapo: {
                        type: this.test.remarks.measurements[0].OS.rapo.type.value,
                    },
                    pupilMorphology: {
                        type: this.test.remarks.measurements[0].OS.pupilMorphology.type.value,
                        select: this.test.remarks.measurements[0].OS.pupilMorphology.select.value,
                    },
                },
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
    }
    public convertFormToModel(): void {
        const formGroupValue = this.remarksForm.value;
        const user: IUser<any> & IUserFrontend = this.authenticationService.currentUser.getModel(true);
        this.test.remarks.measurements[0].OD.pupilMorphology.select.registerEdit(formGroupValue.OD.pupilMorphology.select, user);
        this.test.remarks.measurements[0].OD.pupilMorphology.type.registerEdit(formGroupValue.OD.pupilMorphology.type, user);
        this.test.remarks.measurements[0].OD.rapo.type.registerEdit(formGroupValue.OD.rapo.type, user);
        this.test.remarks.measurements[0].OD.reactionToLight.select.registerEdit(formGroupValue.OD.reactionToLight.select, user);
        this.test.remarks.measurements[0].OD.reactionToLight.type.registerEdit(formGroupValue.OD.reactionToLight.type, user);
        this.test.remarks.measurements[0].OD.size.type.registerEdit(formGroupValue.OD.size.type, user);
        this.test.remarks.measurements[0].OS.pupilMorphology.select.registerEdit(formGroupValue.OS.pupilMorphology.select, user);
        this.test.remarks.measurements[0].OS.pupilMorphology.type.registerEdit(formGroupValue.OS.pupilMorphology.type, user);
        this.test.remarks.measurements[0].OS.rapo.type.registerEdit(formGroupValue.OS.rapo.type, user);
        this.test.remarks.measurements[0].OS.reactionToLight.select.registerEdit(formGroupValue.OS.reactionToLight.select, user);
        this.test.remarks.measurements[0].OS.reactionToLight.type.registerEdit(formGroupValue.OS.reactionToLight.type, user);
        this.test.remarks.measurements[0].OS.size.type.registerEdit(formGroupValue.OS.size.type, user);
        this.test.remarks.diagnosis.registerEdit(formGroupValue.diagnosis, user);
        this.test.remarks.advice.registerEdit(formGroupValue.advice, user);
        this.test.remarks.laboratoryRequested.registerEdit(formGroupValue.laboratoryRequested, user);
        this.test.remarks.medicinesPrescribed.registerEdit(formGroupValue.medicinesPrescribed, user);
    }
}
