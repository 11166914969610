import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import DailyIframe, { DailyCallOptions } from '@daily-co/daily-js';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ConfigService } from '../../../_services/general/config.service';

@Component({
    selector: 'communicator',
    template: require('./communicator.component.html'),
    styles: [require('./communicator.component.scss')],
})
export class CommunicatorComponent implements AfterViewInit, OnDestroy {
    @ViewChild('frame') private frame: ElementRef;
    public link: string;
    private subscriptions: Array<Subscription> = [];
    constructor(private authService: AuthenticationService, private configService: ConfigService) {}
    ngAfterViewInit(): void {
        this.link = this.configService.communicator.link;
        this.frame.nativeElement.setAttribute('allow', 'microphone; camera; autoplay; display-capture');
        const config: DailyCallOptions = {
            url: this.link,
            // token: '208306376d2abd1ef4f81e12d0dcdb72272b351fd8a97db8fd44afb08a03eafb',
            showFullscreenButton: true,
            showLeaveButton: true,
            showParticipantsBar: true,
            userName: `${this.authService.currentUser.roleProperties.firstName} ${this.authService.currentUser.roleProperties.lastName}`,
        };

        const callFrame = DailyIframe.wrap(this.frame.nativeElement, config);
        callFrame.on('left-meeting', () => {
            callFrame.destroy();
        });
        callFrame.join();
    }
    ngOnDestroy() {
        this.subscriptions.map((s: Subscription) => s.unsubscribe());
    }
    public copyToClipboard(): void {
        navigator.clipboard.writeText(this.link);
    }
}
