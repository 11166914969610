import { MeasuredData } from '../../measuredData.model';
import { IPostSegmentNextsightNexyMeasuredData } from '../../../../interfaces/measuredData/NEXTSIGHT/NEXY/post-segment-nextsight-nexy.measuredData.interface';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { NextsightNexyImage } from '../../../nextsightNexyImage.class';

export abstract class PostSegmentNextsightNexyMeasuredData implements MeasuredData, IPostSegmentNextsightNexyMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public yearOfBirth: number;
    public gender: string;
    public race: string;
    public name: string;
    public lastName: string;
    public code: string;
    public images: NextsightNexyImage[];
    constructor() {
        this.sourceDevice = DEVICE.NEXTSIGHT_NEXY;
        this.testType = TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION;
    }
    public abstract setModel(model: IPostSegmentNextsightNexyMeasuredData): void;
    public getModel(): IPostSegmentNextsightNexyMeasuredData {
        let model: IPostSegmentNextsightNexyMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            yearOfBirth: this.yearOfBirth,
            gender: this.gender,
            race: this.race,
            name: this.name,
            lastName: this.lastName,
            code: this.code,
            images: this.images.map((i) => i.model),
            sourceFileName: this.sourceFileName,
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.images.length === 0;
    }
}
