import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import '@whoicd/icd11ect/style.css';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IDC_ITEM_TYPE } from '../../../../../../common/enums/idc-item-type.enum';
import { IIDCDiagnosis } from '../../../../../../common/interfaces/idc-list-item.interface';
import { IPatient } from '../../../../../../common/interfaces/patient.interface';
import { IdcCommentItem } from '../../../../../../common/models/idc/idc-comment-item.model';
import { IdcDiagnosisItem } from '../../../../../../common/models/idc/idc-diagnosis-item.model';
import { IdcTagItem } from '../../../../../../common/models/idc/idc-tag-item.model';
import { IdcListItem } from '../../../../../../common/models/idc/IdcListItem.model';
import { PatientFrontend } from '../../../_models';
import { TestFrontend } from '../../../_models/tests/testFrontend.class';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ConfigService } from '../../../_services/general/config.service';
import { PatientService } from '../../../_services/patient/patient.service';
const ECT = require('@whoicd/icd11ect');

@Component({
    selector: 'ICD',
    template: require('./ICD.component.html'),
    styles: [require('./ICD.component.scss')],
})
export class ICDComponent implements OnInit, OnDestroy {
    @Input() public test: TestFrontend;
    @Input() public patient: PatientFrontend;
    private subscriptions: Array<Subscription> = [];
    public isDiagnosisExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly callbacks: {
        selectedEntityFunction: Function;
    } = {
        selectedEntityFunction: this.selectedIDCDiagnosisEntityHandler.bind(this),
    };

    constructor(private configService: ConfigService, private authService: AuthenticationService, private patientService: PatientService) {}
    ngOnInit() {
        this.subscriptions.push(
            this.isDiagnosisExpanded.pipe(delay(0)).subscribe(state => {
                if (state) {
                    ECT.Handler.configure(this.configService.ICD, this.callbacks);
                    ECT.Handler.bind(1);
                }
            })
        );
    }
    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    private get icdList(): IdcListItem[] {
        if (this.test) {
            return this.patient.ICD.filter(d => d.testType === this.test.type);
        } else {
            return this.patient.ICD;
        }
    }
    private async selectedIDCDiagnosisEntityHandler(listItem: IIDCDiagnosis): Promise<void> {
        const listItemModel: IdcDiagnosisItem = new IdcDiagnosisItem();
        listItemModel.model = listItem;
        if (this.patient.ICD.filter(item => item.type === IDC_ITEM_TYPE.DIAGNOSIS).some((diagnosis: IdcDiagnosisItem) => diagnosis.code === listItemModel.code)) return;
        this.listItemCommonHendler(listItemModel as any);
        await this.update();
    }
    private listItemCommonHendler(listItem: IdcListItem): void {
        listItem.timestamp = this.test ? +this.test.examination.createdAt : Date.now();
        // if test type exist that means item should be bound to certain test
        listItem.testType = this.test ? this.test.type : null;
        listItem.creator = this.authService.currentUser.getModel(true);
        this.patient.ICD.push(listItem);
    }
    private async update(): Promise<void> {
        const patientModel: IPatient = await this.patientService.update(this.patient.model).toPromise();
        this.patient.setModel(patientModel);
    }
    public addComment(): void {
        const commentListItem: IdcCommentItem = new IdcCommentItem();
        commentListItem.type = IDC_ITEM_TYPE.COMMENT;
        this.listItemCommonHendler(commentListItem);
    }
    public addTag(tag: string): void {
        const tagListItem: IdcTagItem = new IdcTagItem();
        tagListItem.type = IDC_ITEM_TYPE.TAG;
        tagListItem.tag = tag;
        this.listItemCommonHendler(tagListItem);
    }
    public async onDelete(listItem: IdcListItem): Promise<void> {
        const existingItem: IdcListItem = this.patient.ICD.find(item => item.timestamp === listItem.timestamp);
        existingItem.deleted = this.authService.currentUser.getModel(true);
        await this.update();
    }
    public onCancel(listItem: IdcListItem): void {
        const index: number = this.patient.ICD.findIndex(item => item.timestamp === listItem.timestamp);
        if (index === -1) return;
        this.patient.ICD.splice(index, 1);
    }
}
