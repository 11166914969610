import { AnimationEvent } from '@angular/animations';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { TestHepler } from '../../../../../commonout/classes/testClass.provider';
import { BlockingType } from '../../../../../commonout/enum/blockingType.enum';
import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { TEST_STAGE } from '../../../../../commonout/enum/testStage.enum';
import { IExamination } from '../../../../../commonout/interfaces/examination';
import ITest from '../../../../../commonout/interfaces/test';
import { Examination } from '../../../../common/models/examination.class';
import { PatientFrontend } from './patientFrontend.model';
import { RegisterEditFieldFrontend } from './registerEditFieldFrontend.class';
import { Acuity4KidsBulbicamTestFrontend } from './tests/BulbiCAM/acuity4KidsTestFrontend.class';
import { AntisaccadeBulbicamTestFrontend } from './tests/BulbiCAM/antisaccadeBulbicamTestFrontend.class';
import { ConvergenceBulbicamTestFrontend } from './tests/BulbiCAM/convergenceBulbicamTestFrontend.class';
import { DarkAdaptationAmdBulbicamTestFrontend } from './tests/BulbiCAM/darkAdaptationAmdTestFrontend.class';
import { FixationBulbicamTestFrontend } from './tests/BulbiCAM/fixationBulbicamTestFrontend.class';
import { FunctionalScreeningBulbicamTestFrontend } from './tests/BulbiCAM/functionalScreeningTestFrontend.class';
import { NystagmusEvaluationBulbicamTestFrontend } from './tests/BulbiCAM/nystagmusEvaluationTestFrontend.class';
import { PtosisBulbicamTestFrontend } from './tests/BulbiCAM/ptosisBulbicamTestFrontend.class';
import { PupillaryEvaluation2BulbicamTestFrontend } from './tests/BulbiCAM/pupillaryEvaluation2BulbicamTestFrontend.class';
import { PursuitAndSaccadesBulbicamTestFrontend } from './tests/BulbiCAM/pursuitAndSaccadesBulbicamTestFrontend.class';
import { RecordingToolBulbicamTestFrontend } from './tests/BulbiCAM/recordingToolTestFrontend.class';
import { SaccadeMergedBulbicamTestFrontend } from './tests/BulbiCAM/saccadeMergedBulbicamTestFrontend.class';
import { SaccadeBulbicamTestFrontend } from './tests/BulbiCAM/saccadesBulbicamTestFrontend.class';
import { VisualFieldMergedBulbicamTestFrontend } from './tests/BulbiCAM/visualFieldMergedBulbicamTestFrontend.class';
import { ColorTestFrontend } from './tests/colorTestFrontend.class';
import { AntSegmentForus3NethraClassicTestFrontend } from './tests/FORUS/3NethraClassic/antSegmentForus3NethraClassicTestFrontend.class';
import { DryEyeForus3NethraClassicTestFrontend } from './tests/FORUS/3NethraClassic/dryEyeForus3NethraClassicTestFrontend.class';
import { PostSegmentForus3NethraClassicTestFrontend } from './tests/FORUS/3NethraClassic/postSegmentForus3NethraClassicTestFrontend.class';
import { AntSegmentHaagSlitlampTestFrontend } from './tests/HAAG/SLITLAMP/antSegmentHaagSlitlampTestFrontend.class';
import { ThreeDScanIvueOctTestFrontend } from './tests/IVUE/OCT/threeDScanIvueOctTestFrontend.class';
import { ThreeDScanIvueOctv3217TestFrontend } from './tests/IVUE/OCT/threeDScanIvueOctv3217TestFrontend.class';
import { PostSegmentNextsightNexyTestFrontend } from './tests/NEXTSIGHT/NEXY/postSegmentNextsightNexyTestFrontend.class';
import { PostSegmentNidekAfc330TestFrontend } from './tests/NIDEK/AFC330/postSegmentNidekAfc330TestFrontend.class';
import { AccommodationNidekArk1sTestFrontend } from './tests/NIDEK/ARK1S/accommodationNidekArk1sTestFrontend.class';
import { IpdGlareContrastAcuityArk1sTestFrontend } from './tests/NIDEK/ARK1S/ipd-glare-contrast-acuityArk1sTestFrontend.class';
import { KeratometryNidekArk1sTestFrontend } from './tests/NIDEK/ARK1S/keratometryNidekArk1sTestFrontend.class';
import { OcularRefractionNidekArk1sTestFrontend } from './tests/NIDEK/ARK1S/ocularRefractionNidekArk1sTestFrontend.class';
import { PupillaryEvaluationNidekArk1sTestFrontend } from './tests/NIDEK/ARK1S/pupillaryEvaluationNidekArk1sTestFrontend.class';
import { LensometryNidekLm7pTestFrontend } from './tests/NIDEK/LM7P/lensometryNidekLm7pTestFrontend.class';
import { AcaNidekNt530PTestFrontend } from './tests/NIDEK/NT530P/acaNidekNt530PTestFrontend.class';
import { PachymetryNidekNt530PTestFrontend } from './tests/NIDEK/NT530P/pachymetryNidekNt530PTestFrontend.class';
import { TonometryNidekNt530pTestFrontend } from './tests/NIDEK/NT530P/tonometryNidekNt530pTestFrontend.class';
import { MaculaScanNidekRs330TestFrontend } from './tests/NIDEK/RS330/maculaScanNidekRs330TestFrontend.class';
import { PostSegmentNidekRs330TestFrontend } from './tests/NIDEK/RS330/postSegmentNidekRs330TestFrontend.class';
import { IpdNidekTonorefIIITestFrontend } from './tests/NIDEK/TonorefIII/ipdNidekTonorefIIITestFrontend.class';
import { KeratometryTonorefIIITestFrontend } from './tests/NIDEK/TonorefIII/keratometryTonorefIIITestFrontend.class';
import { OcularRefractionTonorefIIITestFrontend } from './tests/NIDEK/TonorefIII/ocularRefractionTonorefIIITestFrontend.class';
import { PachymetryTonorefIIITestFrontend } from './tests/NIDEK/TonorefIII/pachymetryTonorefIIITestFrontend.class';
import { PupillaryEvaluationTonorefIIITestFrontend } from './tests/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIITestFrontend.class';
import { TonometryTonorefIIITestFrontend } from './tests/NIDEK/TonorefIII/tonometryTonorefIIITestFrontend.class';
import { TestFrontend } from './tests/testFrontend.class';
import { AntSegmentTopconTritonTestFrontend } from './tests/TOPCON/TRITON/antSegmentTopconTritonTestFrontend.class';
import { MaculaScanTopconTritonTestFrontend } from './tests/TOPCON/TRITON/maculaScanTopconTritonTestFrontend.class';
import { PostSegmentTopconTritonTestFrontend } from './tests/TOPCON/TRITON/postSegmentTopconTritonTestFrontend.class';
import { WebCamImportsTestFrontend } from './tests/webCamImportsTestFrontend.class';

export class ExaminationFrontend extends Examination {
    public lenses: {
        lensholder: RegisterEditFieldFrontend<number>;
        OD: RegisterEditFieldFrontend<number>;
        OS: RegisterEditFieldFrontend<number>;
    };
    public tests: TestFrontend[];
    public patient: PatientFrontend;
    constructor() {
        super();
        this.lenses = {
            lensholder: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Haplo lensholder size', null),
            OD: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Haplo lens OD', null),
            OS: new RegisterEditFieldFrontend<number>(BlockingType.AFTER_DAY_WITH_COMMIT, 'Haplo lens OS', null),
        };
        this.tests = [];
        this.patient = new PatientFrontend();
    }
    public set test(testModel: ITest) {
        this.tests.forEach(t => {
            if (t.type === testModel.type && t.device === testModel.device) {
                t.setModel(testModel);
            }
        });
    }
    public static sorterByStage(t1: TestHepler, t2: TestHepler): number {
        if (t1.stage === TEST_STAGE.SHOWN && t2.stage === TEST_STAGE.HIDDEN) {
            return -1;
        } else if (t1.stage === TEST_STAGE.HIDDEN && t2.stage === TEST_STAGE.SHOWN) {
            return 1;
        } else {
            return 0;
        }
    }
    public static sorterByName(t1: TestHepler, t2: TestHepler): number {
        if (t1.name < t2.name) {
            return -1;
        } else if (t1.name > t2.name) {
            return 1;
        } else {
            return 0;
        }
    }
    public static customSorter(t1: TestHepler, t2: TestHepler): number {
        const compariser = function(t: TestHepler): number {
            switch (t.type) {
                case TEST_TYPE.DARK_ADAPTAION_AMD:
                    return 2;
                case TEST_TYPE.PUPILLARY_EVALUATION2:
                    return 4;
                case TEST_TYPE.NYSTAGMUS_EVALUATION:
                    return 5;
                case TEST_TYPE.PTOSIS_EVALUATION:
                    return 7;
                case TEST_TYPE.ACUITY4KIDS:
                    return 8;
                case TEST_TYPE.CONVERGENCE:
                    return 10;
                case TEST_TYPE.PUPILLARY_EVALUATION:
                    return 11;
                case TEST_TYPE.ANTISACCADE:
                    return 15;
                case TEST_TYPE.SACCADES:
                    return 16;
                default:
                    break;
            }
        };
        if (compariser(t1) < compariser(t2)) {
            return -1;
        } else if (compariser(t1) > compariser(t2)) {
            return 1;
        } else {
            return 0;
        }
    }
    public static deviceSorter(t1: TestHepler, t2: TestHepler): number {
        if (t1.device < t2.device) {
            return -1;
        } else if (t1.device > t2.device) {
            return 1;
        } else {
            return 0;
        }
    }
    public setModel(model: IExamination) {
        this._id = model._id;
        this.patientId = model.patientId;
        this.timeZone = model.timeZone;
        this.createdAt = model.createdAt;
        this.lastModified = model.lastModified;
        this.synchronizedAt = model.synchronizedAt;
        model.tests.forEach(testModel => {
            let test: TestFrontend = this.getTest(testModel.type, testModel.device);
            if (test) {
                test.setModel(testModel);
            } else {
                switch (testModel.device) {
                    case DEVICE.HAPLO: {
                        switch (testModel.type) {
                            case TEST_TYPE.ACUITY4KIDS:
                                test = new Acuity4KidsBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.ANTISACCADE:
                                test = new AntisaccadeBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.CONVERGENCE:
                                test = new ConvergenceBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.DARK_ADAPTAION_AMD:
                                test = new DarkAdaptationAmdBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.FUNCTIONAL_SCREENING:
                                test = new FunctionalScreeningBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.FIXATION_TEST:
                                test = new FixationBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.NYSTAGMUS_EVALUATION:
                                test = new NystagmusEvaluationBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.PTOSIS_EVALUATION:
                                test = new PtosisBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.FUNCTIONAL_SCREENING:
                                test = new FunctionalScreeningBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.PUPILLARY_EVALUATION2:
                                test = new PupillaryEvaluation2BulbicamTestFrontend();
                                break;
                            case TEST_TYPE.PURSUITS_AND_SACCADES:
                                test = new PursuitAndSaccadesBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.RECORDING_TOOL:
                                test = new RecordingToolBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.SACCADES:
                                test = new SaccadeBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.VISUAL_FIELD_MERGED:
                                test = new VisualFieldMergedBulbicamTestFrontend();
                                break;
                            case TEST_TYPE.SACCADE_MERGED:
                                test = new SaccadeMergedBulbicamTestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.FORUS_3NETHRA: {
                        switch (testModel.type) {
                            case TEST_TYPE.ANTERIOR_SEGMENT_EVALUATION:
                                test = new AntSegmentForus3NethraClassicTestFrontend();
                                break;
                            case TEST_TYPE.DRY_EYE_EVALUATION:
                                test = new DryEyeForus3NethraClassicTestFrontend();
                                break;
                            case TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION:
                                test = new PostSegmentForus3NethraClassicTestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.HAAG_SLITLAMP: {
                        test = new AntSegmentHaagSlitlampTestFrontend();
                        break;
                    }
                    case DEVICE.IVUE_OCT: {
                        test = new ThreeDScanIvueOctTestFrontend();
                        break;
                    }
                    case DEVICE.IVUE_OCT_v3217: {
                        test = new ThreeDScanIvueOctv3217TestFrontend();
                        break;
                    }
                    case DEVICE.NEXTSIGHT_NEXY: {
                        test = new PostSegmentNextsightNexyTestFrontend();
                        break;
                    }
                    case DEVICE.NIDEK_AFC330: {
                        test = new PostSegmentNidekAfc330TestFrontend();
                        break;
                    }
                    case DEVICE.NIDEK_ARK1S: {
                        switch (testModel.type) {
                            case TEST_TYPE.ACCOMMODATION:
                                test = new AccommodationNidekArk1sTestFrontend();
                                break;
                            case TEST_TYPE.INTER_PUPILLARY_DISTANCE:
                                test = new IpdGlareContrastAcuityArk1sTestFrontend();
                                break;
                            case TEST_TYPE.KERATOMETRY:
                                test = new KeratometryNidekArk1sTestFrontend();
                                break;
                            case TEST_TYPE.OCULAR_REFRACTION:
                                test = new OcularRefractionNidekArk1sTestFrontend();
                                break;
                            case TEST_TYPE.PUPILLARY_EVALUATION:
                                test = new PupillaryEvaluationNidekArk1sTestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.NIDEK_LM7P: {
                        test = new LensometryNidekLm7pTestFrontend();
                        break;
                    }
                    case DEVICE.NIDEK_NT530P: {
                        switch (testModel.type) {
                            case TEST_TYPE.ANTERIOR_CHAMBER_ANGLE:
                                test = new AcaNidekNt530PTestFrontend();
                                break;
                            case TEST_TYPE.PACHYMETRY:
                                test = new PachymetryNidekNt530PTestFrontend();
                                break;
                            case TEST_TYPE.TONOMETRY:
                                test = new TonometryNidekNt530pTestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.NIDEK_RS330: {
                        switch (testModel.type) {
                            case TEST_TYPE.MACULA_SCAN:
                                test = new MaculaScanNidekRs330TestFrontend();
                                break;
                            case TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION:
                                test = new PostSegmentNidekRs330TestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.NIDEK_TONOREFIII: {
                        switch (testModel.type) {
                            case TEST_TYPE.INTER_PUPILLARY_DISTANCE:
                                test = new IpdNidekTonorefIIITestFrontend();
                                break;
                            case TEST_TYPE.KERATOMETRY:
                                test = new KeratometryTonorefIIITestFrontend();
                                break;
                            case TEST_TYPE.OCULAR_REFRACTION:
                                test = new OcularRefractionTonorefIIITestFrontend();
                                break;
                            case TEST_TYPE.PACHYMETRY:
                                test = new PachymetryTonorefIIITestFrontend();
                                break;
                            case TEST_TYPE.PUPILLARY_EVALUATION:
                                test = new PupillaryEvaluationTonorefIIITestFrontend();
                                break;
                            case TEST_TYPE.TONOMETRY:
                                test = new TonometryTonorefIIITestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.TOPCON_TRITON: {
                        switch (testModel.type) {
                            case TEST_TYPE.ANTERIOR_SEGMENT_EVALUATION:
                                test = new AntSegmentTopconTritonTestFrontend();
                                break;
                            case TEST_TYPE.MACULA_SCAN:
                                test = new MaculaScanTopconTritonTestFrontend();
                                break;
                            case TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION:
                                test = new PostSegmentTopconTritonTestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    case DEVICE.DEFAULT_TEST: {
                        switch (testModel.type) {
                            case TEST_TYPE.COLOUR_VISION_TEST:
                                test = new ColorTestFrontend();
                                break;
                            case TEST_TYPE.WEBCAM_IMPORTS:
                                test = new WebCamImportsTestFrontend();
                                break;
                            default:
                                console.log('Test type ' + testModel.type + ' has to be added.');
                                break;
                        }
                        break;
                    }
                    default:
                        break;
                }
                if (test) {
                    test.setModel(testModel);
                    test.examination = this;
                    test.isExpanded = new Subject<AnimationEvent>();
                    this.tests.push(test);
                }
            }
        });
        this.tests.sort((t1, t2) => {
            return ExaminationFrontend.sorterByStage(t1, t2) || ExaminationFrontend.sorterByName(t1, t2);
        });
        this.lenses.lensholder.setModel(model.lenses.lensholder);
        this.lenses.OD.setModel(model.lenses.OD);
        this.lenses.OS.setModel(model.lenses.OS);
        this.enabledDevices = model.enabledDevices;
        this.doctorsSpeciality = model.doctorsSpeciality;
        this.language = model.language;
        this.consultationType = model.consultationType;
        this.doctorsLicence = model.doctorsLicence;
        this.CAMversion = model.CAMversion;
        this.HUBversion = model.HUBversion;
        if ((<any>model).patient) {
            this.patient.setModel((<any>model).patient);
        }
    }
    public async updateRequirements(requirements: { name: string; value: string[] }): Promise<void> {
        try {
            this[requirements.name] = requirements.value;
        } catch (error) {
            throw error;
        }
    }
    public isSynchronized(): boolean {
        //commonServerCommunicationService.connectedStatusSource.value
        if (this.synchronizedAt === null) return false;
        if (moment(this.synchronizedAt, 'YYMMDDHHmmss') < moment(this.lastModified, 'YYMMDDHHmmss')) return false;

        // it contain some completed tests
        // doctorsSpeciality, language, examinationType, doctorsLicence fields are completed
    }
}
