import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PostSegmentNextsightNexyMeasuredDataFrontend } from '../../../measuredData/NEXTSIGHT/NEXY/postSegmentNextsightNexyDataFrontend.class';

export class PostSegmentNextsightNexyTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PostSegmentNextsightNexyMeasuredDataFrontend = PostSegmentNextsightNexyMeasuredDataFrontend;
    public measurements: PostSegmentNextsightNexyMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
