import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IIpdGlareContrastAcuityArk1sMeasuredData } from '../../../../interfaces/measuredData/NIDEK/ARK1S/ipd-glare-contrast-acuity-nidek-ark1s.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { SmartImage } from '../../../smartImage.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class IpdGlareContrastAcuityArk1sMeasuredData implements MeasuredData, IIpdGlareContrastAcuityArk1sMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public IPD: {
        far: RegisterEditField<string>;
        near: RegisterEditField<string>;
    };
    public CGA: {
        OD: {
            bcva: RegisterEditField<string>;
            contrast: RegisterEditField<string>;
            glare: RegisterEditField<string>;
        };
        OS: {
            bcva: RegisterEditField<string>;
            contrast: RegisterEditField<string>;
            glare: RegisterEditField<string>;
        };
    };
    public OI: {
        OD: {
            COIA: RegisterEditField<string>;
            POI: RegisterEditField<string>;
            RetroImage: SmartImage;
        };
        OS: {
            COIA: RegisterEditField<string>;
            POI: RegisterEditField<string>;
            RetroImage: SmartImage;
        };
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_ARK1S;
        this.testType = TEST_TYPE.INTER_PUPILLARY_DISTANCE;
    }
    public setModel(model: IIpdGlareContrastAcuityArk1sMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.IPD.far.setModel(model.IPD.far);
        this.IPD.near.setModel(model.IPD.near);
        this.CGA.OD.bcva.setModel(model.CGA.OD.bcva);
        this.CGA.OD.contrast.setModel(model.CGA.OD.contrast);
        this.CGA.OD.glare.setModel(model.CGA.OD.glare);
        this.CGA.OS.bcva.setModel(model.CGA.OS.bcva);
        this.CGA.OS.contrast.setModel(model.CGA.OS.contrast);
        this.CGA.OS.glare.setModel(model.CGA.OS.glare);
        this.OI.OD.COIA.setModel(model.OI.OD.COIA);
        this.OI.OD.POI.setModel(model.OI.OD.POI);
        this.OI.OD.RetroImage.model = model.OI.OD.RetroImage;
        this.OI.OS.COIA.setModel(model.OI.OS.COIA);
        this.OI.OS.POI.setModel(model.OI.OS.POI);
        this.OI.OS.RetroImage.model = model.OI.OS.RetroImage;
    }
    public getModel(): IIpdGlareContrastAcuityArk1sMeasuredData {
        const model: IIpdGlareContrastAcuityArk1sMeasuredData = {
            sourceDevice: this.sourceDevice,
            sourceFileName: this.sourceFileName,
            testType: this.testType,
            IPD: {
                far: this.IPD.far.getModel(),
                near: this.IPD.near.getModel(),
            },
            CGA: {
                OD: {
                    bcva: this.CGA.OD.bcva.getModel(),
                    contrast: this.CGA.OD.contrast.getModel(),
                    glare: this.CGA.OD.glare.getModel(),
                },
                OS: {
                    bcva: this.CGA.OS.bcva.getModel(),
                    contrast: this.CGA.OS.contrast.getModel(),
                    glare: this.CGA.OS.glare.getModel(),
                },
            },
            OI: {
                OD: {
                    COIA: this.OI.OD.COIA.getModel(),
                    POI: this.OI.OD.POI.getModel(),
                    RetroImage: this.OI.OD.RetroImage.model,
                },
                OS: {
                    COIA: this.OI.OS.COIA.getModel(),
                    POI: this.OI.OS.POI.getModel(),
                    RetroImage: this.OI.OS.RetroImage.model,
                },
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.CGA.OD.bcva.isEmpty() &&
            this.CGA.OD.contrast.isEmpty() &&
            this.CGA.OD.glare.isEmpty() &&
            this.CGA.OS.bcva.isEmpty() &&
            this.CGA.OS.contrast.isEmpty() &&
            this.CGA.OS.glare.isEmpty() &&
            this.IPD.far.isEmpty() &&
            this.IPD.near.isEmpty() &&
            this.OI.OD.COIA.isEmpty() &&
            this.OI.OD.POI.isEmpty() &&
            this.OI.OD.RetroImage.isEmpty() &&
            this.OI.OS.COIA.isEmpty() &&
            this.OI.OS.POI.isEmpty() &&
            this.OI.OS.RetroImage.isEmpty()
        );
    }
}
