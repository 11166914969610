import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ICamMessage } from '../../../../../../../../../commonout/interfaces/charts.model';
import { ChartData, ProSaccadeChartService } from '../../../../../_services/chartServices/proSaccadeChartService';
import * as d3 from 'd3';
import { FocusDirective } from '../../../../../_directives';

@Component({
    selector: 'pro-saccade-test-chart',
    template: require('./pro-saccade-test-chart.component.html'),
    styles: [require('./pro-saccade-test-chart.component.scss')],
})
export class ProSaccadeTestChartComponent implements OnInit, AfterViewInit, OnDestroy {
    data: ChartData;
    averageRatio: number;
    accepted$ = new BehaviorSubject('');

    isFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    lockTab: boolean = false;
    focusedControl: number = 1;
    @ViewChildren(FocusDirective) controls: QueryList<FocusDirective>;

    @ViewChild('chart') private svgChart!: ElementRef;

    private isDataRendered = this.chartService.isDataRendered$;
    private subscriptions: Subscription[] = [];

    private width!: number;
    private height = 500;
    private chartHeight = 500;
    private velocityChartHeight = 250;
    private margin = 20;

    private svg: any;
    private svgInner: any[] = [];
    private yDegScale: any[] = [];
    private xScale: any[] = [];
    private xAxis: any[] = [];
    private yDegAxis: any[] = [];
    private yVelScale: any;
    private yVelAxis: any[] = [];
    private xVelAxis: any[] = [];
    private greenLineGroup: any[] = [];
    private redCircleGroup: any[] = [];
    private redLineGroupVelocity: any[] = [];
    private redLineGroup: any[] = [];
    private greenbg: any[] = [];
    private redbg: any[] = [];
    private duration = 60;
    private line: any;

    constructor(private chartService: ProSaccadeChartService) {}

    ngOnInit(): void {
        this.line = d3
            .line()
            .x((d) => d[0])
            .y((d) => d[1]);

        const sub = this.chartService.chartData$.subscribe((data) => {
            this.data = data;

            if (data && data.chartType === 'cleared') {
                this.refreshChart('cleared');
            }

            if (data && data.chartType === 'recorded') {
                this.drawRecordedChart(data);
            }

            if (data && data.chartType === 'real-time') {
                this.drawRealTimeChart(data);
            }
        });
        this.subscriptions.push(sub);
    }

    ngAfterViewInit(): void {
        this.width = this.svgChart.nativeElement.getBoundingClientRect().width || window.innerWidth - 100;
        this.initializeChart();
        this.drawAxis();
    }

    clearData() {
        this.chartService.clearData();
    }

    public async addData(frames: ICamMessage[]): Promise<void> {
        this.chartService.addData(frames);
    }

    public setCamData(frames: ICamMessage[]): void {
        this.chartService.getChartData(frames);
    }

    drawRealTimeChart(data: ChartData) {
        const [greenStart, greenEnd] = [data.realTimeDataCoordinates.findIndex((d) => d[2]), data.realTimeDataCoordinates.map((d) => d[2]).lastIndexOf(true)];

        const [redStart, redEnd] = [data.realTimeDataCoordinates.findIndex((d) => !d[2]), data.realTimeDataCoordinates.map((d) => d[2]).lastIndexOf(false)];

        if (greenStart !== -1 && greenEnd !== -1) {
            const greenWidth = (100 * (data.realTimeDataCoordinates[greenEnd][0] - data.realTimeDataCoordinates[greenStart][0])) / 60;
            this.greenbg.push(
                this.svgInner[0]
                    .append('rect')
                    .attr('x', this.xScale[0](data.realTimeDataCoordinates[greenStart][0]))
                    .attr('y', this.yDegScale[0](15))
                    .attr('width', `${greenWidth}%`)
                    .attr('height', this.chartHeight)
                    .attr('fill', 'green')
                    .attr('opacity', '0')
                    .attr('opacity', '0.1')
            );
        }

        if (redStart !== -1 && redEnd !== -1) {
            const redWidth = (100 * (data.realTimeDataCoordinates[redEnd][0] - data.realTimeDataCoordinates[redStart][0])) / 60;
            this.redbg.push(
                this.svgInner[0]
                    .append('rect')
                    .attr('x', this.xScale[0](data.realTimeDataCoordinates[redStart][0]))
                    .attr('y', this.yDegScale[0](15))
                    .attr('width', `${redWidth}%`)
                    .attr('height', this.chartHeight)
                    .attr('fill', 'red')
                    .attr('opacity', '0')
                    .attr('opacity', '0.1')
            );
        }

        // resize real time diagram if test duration more than 60 seconds
        if (data && data.data) {
            const [start, end] = [data.data[0].timestamp, data.data.slice(-1)[0].timestamp];

            const testDuration = start && end ? this.chartService.getXvalue(start / 10000, end) : 60;

            if (data.data && data.data.length && testDuration > this.duration) {
                this.duration = this.duration + 60;
                this.resizeChart(this.duration);
            }

            // draw real time diagram
            const rtlineGroup = this.svgInner[0]
                .append('g')
                .append('path')
                .attr('id', 'rtline')
                .style('fill', 'none')
                .style('stroke', '#fff')
                .style('stroke-width', '1px');

            const rtpoints: [number, number][] = data.realTimeDataCoordinates.map((d) => [this.xScale[0](d[0]), this.yDegScale[0](d[1])]);

            rtlineGroup.attr('d', this.line(rtpoints));

            // draw recorded chart after real time chart
            if (data.data && data.data.length && data.data[data.data.length - 1].message_type === 1) {
                this.refreshChart();
                this.setCamData(data.data);
            }
        }

        this.isDataRendered.next(true);
    }

    resizeChart(testDuration: number): void {
        d3.selectAll('#svgPro > *').remove();

        this.svgInner[0] = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yDegScale[0] = d3
            .scaleLinear()
            .domain([12, -12])
            .range([0, this.chartHeight]);

        this.xScale[0] = d3
            .scaleLinear()
            .domain([testDuration - 60, testDuration])
            .range([2 * this.margin, this.width - 2 * this.margin]);

        this.yDegAxis[0] = this.svgInner[0]
            .append('g')
            .attr('id', 'y-deg-axis')
            .attr('class', 'axis-white')
            .attr('stroke', 'white')
            .style('transform', `translate(${this.margin}px, 0)`);

        this.xAxis[0] = this.svgInner[0]
            .append('g')
            .attr('id', 'x-axis')
            .attr('class', 'axis-white')
            .attr('stroke', 'white')
            .style('transform', `translate(0, ${this.chartHeight / 2 - this.margin}px)`);

        const xAxis = d3.axisBottom(this.xScale[0]).tickValues([testDuration - 60, testDuration]);

        this.xAxis[0].call(xAxis);
    }

    drawRecordedChart(data: ChartData, isFunctionalScreeningTest=false) {
        if (data === null) {
            console.error('Eyetracking was failed. Test has bad raw data');
            return;
        }
        this.averageRatio = this.chartService.averageRatio$.getValue();
        d3.selectAll('#svgPro > *').remove();

        if (this.chartService.isAcceptedListChanged && !isFunctionalScreeningTest) {
            this.accepted$.next(JSON.stringify(data.trialInfo.map((d) => d.status)));
        }

        const chartsAmount = Math.ceil(data.testDuration / 60);

        this.initializeChart('recorded', data.testDuration);
        this.drawAxis('recorded', data.testDuration);

        for (let i = 0; i < chartsAmount; i++) {
            if (this.svgChart && data) {
                const points: [number, number][] = data.dataCoordinates
                    ?.filter((d) => d[0] > 60 * i && d[0] < 60 * (i + 1))
                    ?.map((d) => {
                        const xPoint = this.xScale[i](d[0]);
                        const yPoint = this.yDegScale[i](d[1]);

                        return [xPoint, yPoint];
                    });

                // draw recorded line
                this.svgInner[i]
                    .append('path')
                    .attr('id', `line${i}`)
                    .style('fill', 'none')
                    .style('stroke', 'grey')
                    .style('stroke-width', '1px')
                    .style('transform', `translate(0px, ${(this.chartHeight + this.velocityChartHeight) * i}px)`)
                    .attr('d', this.line(points));

                data.greenLinesCoordinates?.forEach((d) => {
                    this.greenLineGroup[i] = this.svgInner[i]
                        .append('path')
                        .attr('id', 'grn')
                        .style('fill', 'none')
                        .style('stroke', 'green')
                        .style('stroke-width', '2px')
                        .style('transform', `translate(0px, ${(this.chartHeight + this.velocityChartHeight) * i}px)`);

                    const greenPoints: [number, number][] = d
                        ?.filter((d) => d[0] > 60 * i && d[0] < 60 * (i + 1))
                        ?.map((value) => [this.xScale[i](value[0]), this.yDegScale[i](value[1])]);

                    this.greenLineGroup[i].attr('d', this.line(greenPoints));
                });

                data.redDotsCoordinates
                    ?.filter((d) => d[0] > 60 * i && d[0] < 60 * (i + 1))
                    ?.forEach((d) => {
                        this.redCircleGroup[i] = this.svgInner[i]
                            .append('circle')
                            .attr('id', 'circle')
                            .attr('cx', this.xScale[i](d[0]))
                            .attr('cy', this.yDegScale[i](d[1]))
                            .attr('r', 3)
                            .attr('stroke', 'red')
                            .attr('fill', 'red')
                            .style('transform', `translate(0px, ${(this.chartHeight + this.velocityChartHeight) * i}px)`);
                    });

                const chartArray = [-12, 12];
                const velocityArray = [0, 90];

                data.redLinesCoordinates
                    ?.filter((d) => d > 60 * i && d < 60 * (i + 1))
                    ?.forEach((d) => {
                        this.redLineGroup[i] = this.svgInner[i]
                            .append('path')
                            .attr('id', 'line')
                            .style('fill', 'none')
                            .style('stroke', 'red')
                            .style('stroke-dasharray', '5, 5')
                            .style('stroke-width', '0.5px')
                            .style('transform', `translate(0px, ${(this.chartHeight + this.velocityChartHeight) * i}px)`);

                        this.redLineGroupVelocity[i] = this.svgInner[i]
                            .append('path')
                            .attr('id', 'line')
                            .style('fill', 'none')
                            .style('stroke', 'red')
                            .style('stroke-dasharray', '5, 5')
                            .style('stroke-width', '1px')
                            .style('transform', `translate(0px, ${this.chartHeight + (this.chartHeight + this.velocityChartHeight) * i}px)`);

                        const redPointsChart: [number, number][] = chartArray.map((value) => [this.xScale[i](d), this.yDegScale[i](value)]);

                        const redPointsVelocity: [number, number][] = velocityArray.map((value) => [this.xScale[i](d), this.yVelScale(value)]);

                        this.redLineGroup[i].attr('d', this.line(redPointsChart));

                        this.redLineGroupVelocity[i].attr('d', this.line(redPointsVelocity));
                    });

                const svgVelocity = this.svgInner[i]
                    .append('g')
                    .append('path')
                    .attr('id', 'line')
                    .style('fill', 'none')
                    .style('stroke', '#fff')
                    .style('stroke-width', '0.3px')
                    .style('transform', `translate(0px, ${this.chartHeight + (this.chartHeight + this.velocityChartHeight) * i}px)`);

                const velocityPoints: [number, number][] = (data.velocityDiagramCoordinates || [])
                    .filter((d) => d[0] > 60 * i && d[0] < 60 * (i + 1))
                    .map((d) => [this.xScale[i](d[0]), this.yVelScale(d[1] > 90 ? 90 : d[1])]);

                svgVelocity.attr('d', this.line(velocityPoints));

                data.trialInfo
                    .filter((d) => d.timeCoord >= 60 * i && d.timeCoord < 60 + 60 * i)
                    .forEach((d) => {
                        this.svgInner[i]
                            .append('text')
                            .attr('dx', this.xScale[i](d.timeCoord + 0.25))
                            .attr('dy', this.yDegScale[i](11))
                            .attr('stroke', 'red')
                            .style('font-size', '10px')
                            .style('transform', 'translate(0, ' + (this.chartHeight + this.velocityChartHeight) * i + 'px)')
                            .text(`trial ${d.trial + 1}`);

                        if (isFunctionalScreeningTest && d.calibrationValue) {
                            this.svgInner[i]
                                .append('text')
                                .attr('dx', this.xScale[i](d.timeCoord + 0.25))
                                .attr('dy', this.yDegScale[i](-12))
                                .attr('stroke', 'yellow')
                                .style('font-size', '10px')
                                .style('transform', 'translate(0, ' + (this.chartHeight + this.velocityChartHeight) * i + 'px)')
                                .text(`${ d.calibrationValue.toFixed(2) }`);
                        }

                        if (d?.fn?.length) {
                            this.svgInner[i]
                                .append('circle')
                                .attr('id', 'line')
                                .attr('cx', this.xScale[i](d.fn[0][0]))
                                .attr('cy', this.yDegScale[i](d.fn[0][1]))
                                .attr('r', 3)
                                .attr('stroke', '#f717ff')
                                .attr('fill', '#f717ff')
                                .style('transform', `translate(0px, ${(this.chartHeight + this.velocityChartHeight) * i}px)`);

                            this.svgInner[i]
                                .append('text')
                                .attr('dx', this.xScale[i](d.fn[0][0]))
                                .attr('dy', this.yDegScale[i](d.fn[0][1] - 1))
                                .attr('stroke', 'white')
                                .style('font-size', '10px')
                                .style('transform', 'translate(0, ' + (this.chartHeight + this.velocityChartHeight) * i + 'px)')
                                .text(`f ${d.fn[0][2].toFixed(0)}%`);
                        }
                    });
                
                const calibrationValueList = data.trialInfo.map(d => d.calibrationValue).filter(d => !!d).sort((a, b) => a - b);
                const averageCalibrationValue = calibrationValueList[Math.floor(calibrationValueList.length / 2)];
                if (isFunctionalScreeningTest && averageCalibrationValue) {
                    this.svgInner[i]
                        .append('text')
                        .attr('dx', this.xScale[i](data.testDuration - 0.25))
                        .attr('dy', this.yDegScale[i](0.25))
                        .attr('stroke', 'yellow')
                        .style('font-size', '12px')
                        .style('transform', 'translate(0, ' + (this.chartHeight + this.velocityChartHeight) * i + 'px)')
                        .text(`${ averageCalibrationValue.toFixed(2) }`);
                }
            }
        }
    }

    private refreshChart(type: string = '', testDuration: number = 60): void {
        d3.selectAll('#svgPro > *').remove();
        this.svgInner = [];
        this.yDegScale = [];
        this.xScale = [];
        this.xAxis = [];
        this.yDegAxis = [];
        this.yVelScale = [];
        this.yVelAxis = [];
        this.xVelAxis = [];
        this.greenLineGroup = [];
        this.redCircleGroup = [];
        this.redLineGroupVelocity = [];
        this.redLineGroup = [];
        this.duration = 60;
        this.height = 0;
        this.greenbg = [];
        this.redbg = [];
        // this.initializeChart(testDuration);
        // this.drawAxis('no-content', testDuration);
        if (type === 'cleared') {
            d3.selectAll('#svgPro').attr('height', '0');
        }
    }

    initializeChart(chartType: string = 'no-content', testDuration: number = 60): void {
        const chartsAmount = Math.ceil(testDuration / 60);

        this.height = chartType === 'no-content' ? 0 : (this.chartHeight + this.velocityChartHeight) * chartsAmount;

        this.svg = d3
            .select(this.svgChart?.nativeElement)
            .attr('id', 'svgPro')
            .attr('height', this.height);

        for (let i = 0; i < chartsAmount; i++) {
            this.svgInner[i] = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

            this.yDegScale[i] = d3
                .scaleLinear()
                .domain([12, -12])
                .range([0, this.chartHeight - 2 * this.margin]);

            const [left, right] = [60 * i, chartsAmount === 1 ? testDuration : 60 + 60 * i];

            this.xScale[i] = d3
                .scaleLinear()
                .domain([left, right])
                .range([2 * this.margin, this.width - 2 * this.margin]);

            this.yDegAxis[i] = this.svgInner[i]
                .append('g')
                .attr('id', 'y-deg-axis')
                .attr('class', 'axis-white')
                .attr('stroke', 'white')
                .style('transform', 'translate(' + 2 * this.margin + 'px, ' + (this.chartHeight + this.velocityChartHeight) * i + 'px)');

            this.xAxis[i] = this.svgInner[i]
                .append('g')
                .attr('id', 'x-axis')
                .attr('class', 'axis-white')
                .attr('stroke', 'white')
                .style('transform', `translate(${0}px, ${this.chartHeight / 2 + (this.chartHeight + this.velocityChartHeight) * i - this.margin}px)`);

            this.yVelScale = d3
                .scaleLinear()
                .domain([90, 0])
                .range([0, this.velocityChartHeight - this.margin]);

            this.yVelAxis[i] = this.svgInner[i]
                .append('g')
                .attr('id', 'y-vel-axis')
                .attr('class', 'axis-white')
                .attr('stroke', 'white')
                .style('transform', `translate(${2 * this.margin}px, ${this.chartHeight + (this.chartHeight + this.velocityChartHeight) * i}px)`);

            this.xVelAxis[i] = this.svgInner[i]
                .append('g')
                .attr('id', 'x-axis')
                .attr('class', 'axis-white')
                .attr('stroke', 'white')
                .style('transform', `translate(0, ${(this.chartHeight + this.velocityChartHeight) * (i + 1) - this.margin}px)`);
        }
    }

    drawAxis(chartType: string = 'no-content', testDuration: number = 60): void {
        this.svg.attr('width', this.width);

        const chartsAmount = Math.ceil(testDuration / 60);

        for (let i = 0; i < chartsAmount; i++) {
            const [left, right] = [60 * i, chartsAmount === 1 ? testDuration : 60 + 60 * i];
            const xAxis = d3.axisBottom(this.xScale[i]).tickValues([left, right]);

            this.xAxis[i].call(xAxis);

            const yDegAxis = d3.axisLeft(this.yDegScale[i]).tickValues([-10, -5, 0, 5, 10]);

            if (chartType === 'recorded') {
                this.yDegAxis[i].call(yDegAxis);

                const xVelAxis = d3.axisBottom(this.xScale[i]);

                const yVelAxis = d3.axisLeft(this.yVelScale);

                this.xVelAxis[i].call(xVelAxis);
                this.yVelAxis[i].call(yVelAxis);

                const dashedLineGroup = this.svgInner[i]
                    .append('g')
                    .append('path')
                    .attr('id', 'line')
                    .style('fill', 'none')
                    .style('stroke', 'green')
                    .style('stroke-dasharray', '7, 7')
                    .style('stroke-width', '1px')
                    .style('transform', `translate(0, ${this.chartHeight + (this.chartHeight + this.velocityChartHeight) * i}px)`);

                const dashedPoints: [number, number][] = [left, right].map((d) => [this.xScale[i](d), this.yVelScale(30)]);
                dashedLineGroup.attr('d', this.line(dashedPoints));
            }
        }
    }

    updateAccepted(trialIndex: number, type: string) {        
        this.chartService.updateTrialInfo(trialIndex, type);
    }

    onFocused(focusedElement: number): void {
        this.focusedControl = focusedElement;
        this.controls.find(e => e.focus === focusedElement).elementRef.nativeElement.focus();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        d3.select('#svgPro').remove();
        this.data = { chartType: 'cleared' };
        this.chartService.chartData$.next(this.data);
    }
}
