import { WebCamImportsMeasuredDataFrontend } from '../measuredData/webCamImportsDataFrontend.class';
import { TestRemarkFrontend } from './testRemarkFrontend';

export class WebCamImportsTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof WebCamImportsMeasuredDataFrontend = WebCamImportsMeasuredDataFrontend;
    public measurements: WebCamImportsMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
