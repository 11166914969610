import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

interface PercentileInfo {
    p5?: number;
    p25?: number;
    p50?: number;
    p75?: number;
    p95?: number;
    mean?: number;
    cov?: number;
    measurementUnits?: string;
}

export interface DetailsInfo {
    totalSaccades?: number;
    noResponse?: number;
    notAvailable?: number;
    correct?: number;
    error?: number;
    latencyAvg?: number;
    latencySd?: number;
    latencyInfo?: PercentileInfo;
    amplitudeInfo?: PercentileInfo;
}

interface TrialInfo {
    trial?: number;
    type?: string;
    timeCoord?: number;
    latency?: number;
    accuracy?: number;
    isCalibrationSuccessful?: boolean;
}

export interface ChartData {
    eye?: string;
    isRendered?: boolean;
    chartType: string;
    realTimeDataCoordinates?: [number, number, boolean][];
    dataCoordinates?: [number, number][];
    greenLinesCoordinates?: [number, number][][];
    redLinesCoordinates?: number[];
    redDotsCoordinates?: [number, number][];
    velocityDiagramCoordinates?: [number, number][];
    detailsInfo?: DetailsInfo;
    trialInfo?: TrialInfo[];
    data?: ICamMessage[];
    testDuration?: number;
}

interface IChart {
    chartTypeString: string;
    calibratedx?: number;
    greenDotIndex?: number;
    redDotIndex?: number;
    message_type?: any;
    targettype?: number;
    target?: string;
    timestamp?: number;
    trial?: number;
    rawODx?: number;
    rawODy?: number;
    rawOSx?: number;
    rawOSy?: number;
    stage?: number;
}

@Injectable({
    providedIn: 'root',
})
export class MemorySaccadeChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}
    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }
    types = ['correct', 'error', 'na', 'no-response'];
    private data: ICamMessage[] = [];
    accepted: string[] = [];
    isAcceptedListChanged = false;
    realTimeStartPoint: ICamMessage | null = null;
    chartData$: BehaviorSubject<ChartData> = new BehaviorSubject({
        chartType: 'no-content',
    });
    testResults: ChartData | null;
    isDataRendered$ = new BehaviorSubject(false);

    constructor() {
        super();
    }

    public addData(frames: ICamMessage[]): Promise<void> {
        this.isDataRendered$.next(false);
        const targettype = this._selectRawType(frames.find(d => d?.targettype)?.targettype);
        const data = frames.filter(el => el[targettype]);

        this.data.push(...frames.filter(d => !d.training));
        if (data.length) {
            if (!this.realTimeStartPoint) {
                this.realTimeStartPoint = data.find(d => d[targettype]);
            }

            const realTimePoints: [number, number, boolean][] = this._calculateRealTimeCoordinates(data, this.realTimeStartPoint, targettype);
            this.chartData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: realTimePoints,
                data: this.data,
            });
        } else {
            this.chartData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: [],
                data: this.data,
            });
        }

        return new Promise((res, _) => {
            this.isDataRendered$.pipe(filter(data => Boolean(data))).subscribe(() => res());
        });
    }

    public setCamData(frames: ICamMessage[]): void {
        this.testResults = this.calculateChartResults(frames);
    }

    getChartData(frames: ICamMessage[]) {
        const dataResults = this.calculateChartResults(frames);

        this.chartData$.next(dataResults);
    }

    calculateChartResults(frames: ICamMessage[]): ChartData {
        frames = frames.filter(d => !d.training);

        if (frames[0].accepted) {
            this.accepted = JSON.parse(frames[0].accepted);
        } else {
            this.isAcceptedListChanged = true;
            this.accepted = [];
        }

        if (!frames.length) {
            console.error('Bad Data');
            return null;
        }
        const targettype = this._selectRawType(frames[1].targettype);
        const data = frames.slice(1, frames.length - 1).filter(el => el[targettype]);

        if (data.length) {
            const trials = frames.slice(-2)[0].trial;
            const testDuration = this.getXvalue(data[0].timestamp / 10000, data[data.length - 1].timestamp);

            const [greenPoints, redCirclePoints, redPoints] = this._calculateDotsCoordinates(frames, trials);
            const [calibratedCoordinates, trialInfoTemp] = this._calculateCalibratedCoordinates(frames, trials, targettype);
            const velocityCoordinates = this._calculateVelocityCoordinates(frames, calibratedCoordinates);
            const degPerSec = velocityCoordinates.map(d => d[1]);
            const trialInfo = this.calculateTrialInfo(frames, calibratedCoordinates, degPerSec, trialInfoTemp, trials);

            const detailsInfo = this.calculateDetails(trialInfo);

            const chartData = {
                chartType: 'recorded',
                dataCoordinates: calibratedCoordinates,
                greenLinesCoordinates: greenPoints,
                redLinesCoordinates: redPoints,
                redDotsCoordinates: redCirclePoints,
                testDuration: Math.ceil(testDuration),
                velocityDiagramCoordinates: velocityCoordinates,
                trialInfo: trialInfo,
                detailsInfo: detailsInfo,
                eye: targettype.slice(3, 5),
            };

            return chartData;
        } else {
            console.error('Bad Data');
            return null;
        }
    }

    public export() {
        if (!this.testResults) {
            console.error('No exported Data');
            return null;
        }
        const expertedData = {
            details: {
                eye: this.testResults.eye,
                percentile_25_ms: this.testResults.detailsInfo.latencyInfo.p25,
                percentile_50_ms: this.testResults.detailsInfo.latencyInfo.p50,
                percentile_75_ms: this.testResults.detailsInfo.latencyInfo.p75,
                percentile_25_percent: this.testResults.detailsInfo.amplitudeInfo.p25,
                percentile_50_percent: this.testResults.detailsInfo.amplitudeInfo.p50,
                percentile_75_percent: this.testResults.detailsInfo.amplitudeInfo.p75,
                mean_ms: this.testResults.detailsInfo.latencyInfo.mean,
                mean_percent: this.testResults.detailsInfo.amplitudeInfo.mean,
                cov_ms: this.testResults.detailsInfo.latencyInfo.cov,
                cov_percent: this.testResults.detailsInfo.amplitudeInfo.cov,
                total_attempts: this.testResults.detailsInfo.totalSaccades,
                correct: this.testResults.detailsInfo.correct,
                correct_latency_avg: this.testResults.detailsInfo.latencyAvg,
                correct_latency_sd: this.testResults.detailsInfo.latencySd,
                error: this.testResults.detailsInfo.error,
                noResponse: this.testResults.detailsInfo.noResponse,
                na: this.testResults.detailsInfo.notAvailable,
            },
            trialsInfo: this.testResults.trialInfo.map(d => ({
                trial: d.trial,
                latency: !['na', 'no-response'].includes(d.type) ? d.latency : null,
                accuracy: !['na', 'no-response'].includes(d.type) ? d.accuracy : null,
                status: d.type,
            })),
        };

        return expertedData;
    }

    public clearData(): void {
        this.isAcceptedListChanged = false;
        this.data = [];
        this.realTimeStartPoint = null;
        this.chartData$.next({
            chartType: 'cleared',
        });
    }

    private _calculateRealTimeCoordinates(data: ICamMessage[], realTimeStartPoint: ICamMessage, targettype: string): [number, number, boolean][] {
        const realTimePoints: [number, number, boolean][] = data.map(d => {
            const xPoint = this.getXvalue(realTimeStartPoint.timestamp / 10000, d.timestamp);
            const yRawPoint = this._calibrateRealTime(d, realTimeStartPoint[targettype], targettype);
            const yPoint = yRawPoint > 12 || yRawPoint < -12 ? 12 * Math.sign(yRawPoint) : yRawPoint;

            return [xPoint, yPoint, d.training];
        });

        return realTimePoints;
    }

    private _calculateDotsCoordinates(frames: ICamMessage[], trials: number): [[number, number][][], [number, number][], number[]] {
        const data = frames.slice(1, frames.length - 1);
        let greenCoordinates: [number, number][][] = [];
        let redCircleCoordinates: [number, number][] = [];
        let redCoordinates: number[] = [];
        const start = data[0].trial;
        for (let i = start; i <= trials; i++) {
            if (i !== trials) {
                const redLineIndex = data.findIndex(d => d.trial === i && d.stage === 1);
                const redPoint = this.getXvalue(data[0].timestamp / 10000, data[redLineIndex].timestamp);

                const redCirclePoints: [number, number] = [redPoint, this._calibrateDots(data[redLineIndex].redDotIndex)];

                redCircleCoordinates.push(redCirclePoints);
                redCoordinates.push(redPoint);
            }

            const greenPoints: [number, number][] = data
                .filter(el => el.trial === i && (el.stage === 0 || el.stage === 2))
                .map(d => [this.getXvalue(data[0].timestamp / 10000, d.timestamp), this._calibrateDots(d.greenDotIndex)]);

            greenCoordinates.push(greenPoints);
        }

        return [greenCoordinates, redCircleCoordinates, redCoordinates];
    }

    private _calculateCalibratedCoordinates(frames: ICamMessage[], trials: number, targettype: string): [[number, number][], TrialInfo[]] {
        let trialInfo: TrialInfo[] = [];
        let coordinates: [number, number][] = [];
        const data = frames.slice(1, -1);
        let currentRatio = 0;
        let closest = data.find(d => d[targettype]);
        let calibratingPoint = data[0];

        const startTrial = data[0].trial;

        let current: ICamMessage | null = null;
        let next: ICamMessage | null = null;

        for (let i = startTrial; i <= trials; i++) {
            trialInfo.push({ trial: i - startTrial });
            current = i === startTrial ? this.findCalibrated(data, i, targettype) : next;
            next = i !== trials ? this.findCalibrated(data, i + 1, targettype) : null;
            calibratingPoint = current ? current : calibratingPoint;

            const [firstInd, lastInd] = this._calculateSliceIndexes(data, i, startTrial);

            if (current && next) {
                currentRatio = this._calculateRatio(current, next, targettype);
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    isCalibrationSuccessful: true,
                };
            } else {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    isCalibrationSuccessful: false,
                };
            }

            if (!currentRatio) {
                coordinates.push(...data.slice(firstInd, lastInd).map(d => <[number, number]>[0, 0]));
                continue;
            }

            const points: [number, number][] = data.slice(firstInd, lastInd).map(d => {
                const xPoint = this.getXvalue(data[0].timestamp / 10000, d.timestamp);
                closest = d[targettype] ? d : closest;
                const yPointTemp = this._calibrateX(closest, calibratingPoint, currentRatio, targettype);
                const yPoint = yPointTemp > 12 || yPointTemp < -12 ? 12 * Math.sign(yPointTemp) : yPointTemp;

                return [xPoint, yPoint];
            });
            coordinates.push(...points);
        }
        return [coordinates, trialInfo];
    }

    private _calculateVelocityCoordinates(frames: ICamMessage[], calibrated: [number, number][]): [number, number][] {
        const data = frames.slice(1, frames.length - 1);
        const calibrabedX = calibrated.map(d => d[1]);
        const degpersec: number[] = [];

        for (let i = 0; i < data.length; i++) {
            const tempArray: number[] = calibrated
                .slice(i - 2 >= 0 ? i - 2 : 0, i + 3)
                .filter(d => d[1] !== null)
                .map(d => d[1]);

            const average = this.calculateAverage(tempArray);

            const timeSub = (data[i].timestamp / 10000 - data[i - 4 > 0 ? i - 5 : 0]?.timestamp / 10000) | 1;
            degpersec.push((Math.abs(average - calibrabedX[i - 4 > 0 ? i - 5 : 0]) / timeSub) * 1000);
        }

        const velocityCoords: [number, number][] = data.map((d, ind) => {
            const xPoint = this.getXvalue(data[0].timestamp / 10000, d.timestamp);
            const degValue = degpersec[ind];
            const yPoint = degValue || 0;

            return [xPoint, yPoint];
        });

        return velocityCoords;
    }

    private calculateTrialInfo(frames: ICamMessage[], calibrated: [number, number][], degPerSec: number[], trialInfo: TrialInfo[], trials: number): TrialInfo[] {
        const latency = [];
        const data = frames.slice(1, frames.length - 1);
        const startTrial = data[0].trial;

        for (let i = startTrial; i <= trials; i++) {
            const [firstInd, lastInd] = [data.findIndex(d => d.trial === i && d.stage === 1), i !== trials ? data.findIndex(d => d.trial === i + 1) : data.length - 1];
            if (trialInfo[i - startTrial].isCalibrationSuccessful) {
                for (let j = firstInd; j <= lastInd; j++) {
                    const currentItem = data[j];
                    const checkItem = data.find(el => el.trial === i && el.stage === 3);
                    const timeCoord = checkItem.timestamp;
                    const checkedTime = checkItem.timestamp / 10000;

                    if (degPerSec[j] >= 30) {
                        // inhibitory error area
                        if (
                            currentItem.stage === 1 ||
                            currentItem.stage === 2 ||
                            (currentItem.stage === 3 && currentItem.timestamp / 10000 >= checkedTime && currentItem.timestamp / 10000 <= checkedTime + 100)
                        ) {
                            const belowthreshold = degPerSec.slice(j).findIndex(d => d < 30) + j;
                            const greenCoord = this._calibrateDots(currentItem.greenDotIndex);
                            const redCoord = this._calibrateDots(currentItem.redDotIndex);
                            // check if first frame below threshold > 2deg in direction of flash
                            const checkSign = redCoord > greenCoord ? 1 : -1;
                            const check = checkSign > 0 ? calibrated[belowthreshold][1] > greenCoord + 2 * checkSign : calibrated[belowthreshold][1] < greenCoord + 2 * checkSign;
                            if (check) {
                                trialInfo[i - startTrial] = {
                                    ...trialInfo[i - startTrial],
                                    type: 'error',
                                    timeCoord: this.getXvalue(data[0].timestamp / 10000, timeCoord),
                                };
                                break;
                            } else {
                                j = belowthreshold - 1;
                                continue;
                            }
                        } else if (currentItem.stage === 3 && currentItem.timestamp / 10000 > checkedTime + 100) {
                            const latencyDur = Math.abs(checkedTime - currentItem.timestamp / 10000);
                            latency.push(latencyDur);

                            const tempInd = degPerSec.slice(j).findIndex(d => d < 30);
                            const tempAccuracy = calibrated[j + tempInd][1];
                            const accuracy = (Math.abs(tempAccuracy - this._calibrateDots(currentItem.greenDotIndex)) / 5) * 100;

                            trialInfo[i - startTrial] = {
                                ...trialInfo[i - startTrial],
                                type: 'correct',
                                latency: latencyDur,
                                accuracy: accuracy,
                                timeCoord: this.getXvalue(data[0].timestamp / 10000, timeCoord),
                            };
                            break;
                        }

                        if (currentItem.trial === i + 1) {
                            trialInfo[i - startTrial] = {
                                ...trialInfo[i - startTrial],
                                type: 'no-response',
                                timeCoord: this.getXvalue(data[0].timestamp / 10000, data.find(el => el.trial === i && el.stage === 0).timestamp) + 1,
                                latency: null,
                                accuracy: null,
                            };
                            break;
                        }
                    } else if (j === lastInd) {
                        trialInfo[i - startTrial] = {
                            ...trialInfo[i - startTrial],
                            type: 'no-response',
                            timeCoord: this.getXvalue(data[0].timestamp / 10000, data.find(el => el.trial === i && el.stage === 0).timestamp) + 1,
                            latency: null,
                            accuracy: null,
                        };
                    }
                }
            } else {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    type: 'na',
                    timeCoord: this.getXvalue(data[0].timestamp / 10000, data.find(el => el.trial === i && el.stage === 0).timestamp) + 1,
                    latency: null,
                    accuracy: null,
                };
            }
        }

        // last trial is unnecessary because has no red flash
        trialInfo.pop();

        return trialInfo;
    }

    private calculateDetails(trialInfo: TrialInfo[]): DetailsInfo {
        if (this.accepted.length) {
            this.accepted.forEach((d, ind) => {
                trialInfo[ind].type = d;
            });
        }

        const total = trialInfo.length;
        const noResponse = trialInfo.filter(d => d.type === 'no-response').length;
        const notAvailable = trialInfo.filter(d => d.type === 'na').length;
        const error = trialInfo.filter(d => d.type === 'error').length;
        const correct = trialInfo.filter(d => d.type === 'correct');
        const correctLength = correct.length;

        const latencyList = correct.filter(d => d.isCalibrationSuccessful).map(d => d.latency);
        const amplitudeList = correct.filter(d => d.isCalibrationSuccessful).map(d => d.accuracy);

        const latencyListSorted = latencyList.slice().sort((a, b) => a - b);
        const amplitudeListSorted = amplitudeList.slice().sort((a, b) => a - b);

        const [latencyAvg, latencySd] = [Math.round(this.calculateAverage(latencyList)), Math.round(this.calculateSD(latencyList))];

        const [amplitudeAvg, amplitudeSd] = [Math.round(this.calculateAverage(amplitudeList)), Math.round(this.calculateSD(amplitudeList))];

        const latencyInfo: PercentileInfo = {
            p25: this.getPercentile(latencyListSorted, 25),
            p50: this.getPercentile(latencyListSorted, 50),
            p75: this.getPercentile(latencyListSorted, 75),
            mean: latencyAvg,
            cov: latencySd / latencyAvg,
            measurementUnits: 'ms',
        };

        const amplitudeInfo: PercentileInfo = {
            p25: this.getPercentile(amplitudeListSorted, 25),
            p50: this.getPercentile(amplitudeListSorted, 50),
            p75: this.getPercentile(amplitudeListSorted, 75),
            mean: amplitudeAvg,
            cov: amplitudeSd / amplitudeAvg,
            measurementUnits: '%',
        };

        return {
            totalSaccades: total,
            error,
            correct: correctLength,
            notAvailable,
            noResponse,
            latencyInfo,
            amplitudeInfo,
            latencyAvg,
            latencySd,
        };
    }

    updateDetails(trialIndex: number, type: string): void {
        const chartDataValue = this.chartData$.getValue();

        if (!this.accepted.length) {
            this.accepted = chartDataValue.trialInfo.map(d => d.type);
        }

        this.isAcceptedListChanged = true;

        this.accepted[trialIndex] = type;

        chartDataValue.trialInfo[trialIndex] = {
            ...chartDataValue.trialInfo[trialIndex],
            type: this.accepted[trialIndex],
        };

        const updatedDetails = this.calculateDetails(chartDataValue.trialInfo);

        this.chartData$.next({
            ...chartDataValue,
            detailsInfo: updatedDetails,
        });
    }

    private _selectRawType(targettype: number): 'rawODx' | 'rawOSx' {
        return targettype === 1 ? 'rawOSx' : 'rawODx';
    }

    private _calibrateDots(index: number): number {
        if (index === 0) return 10;
        if (index === 1) return 5;
        if (index === 3) return -5;
        if (index === 4) return -10;
        return 0;
    }

    private _calculateRatio(currentPhase: ICamMessage, nextPhase: ICamMessage, targettype: string): number {
        if (!(currentPhase && nextPhase)) {
            return 0;
        }

        const sub = currentPhase[targettype] - nextPhase[targettype];
        const ratio = Math.abs(sub / 5);

        return ratio;
    }

    private _calibrateX(current: ICamMessage, raw: ICamMessage, ratio: number, targettype: string): number {
        const rawDeg = this._calibrateDots(raw.greenDotIndex);
        const rawX = raw[targettype];

        const calibratedX = rawDeg + (current[targettype] - rawX) / ratio;

        return calibratedX;
    }

    private _calculateSliceIndexes(data: ICamMessage[], trial: number, startTrial: number = 0): number[] {
        if (trial === startTrial) {
            return [0, data.findIndex(d => d.trial === trial + 1 && d.stage === 1)];
        }

        if (trial === data[data.length - 1].trial) {
            return [data.findIndex(d => d.trial === trial && d.stage === 1), data.length];
        }

        return [data.findIndex(d => d.trial === trial && d.stage === 1), data.findIndex(d => d.trial === trial + 1 && d.stage === 1)];
    }

    private _calibrateRealTime(current: ICamMessage, startPoint: number, targettype: string): number {
        const currentRaw = current[targettype];

        return 0.5 * (currentRaw - startPoint);
    }

    private findCalibrated(data: ICamMessage[], trial: number, targettype: string): ICamMessage | null {
        const temp = data.filter(d => d.trial === trial && d.stage === 0).slice(-1)[0];

        return temp[targettype] ? temp : null;
    }
}
