// @ts-nocheck
import * as _ from 'lodash';
import chroma from 'chroma-js';
import { applyColor, colors } from './lib';

//
// bright theme, defaults
//

let fontColor = '#333';

let defaultStyle = {
    trialLine: {
        color: '#666',
        // size: 0.5,
        size: 1, //0.5,
    },
    noResponseLine: {
        color: '#888',
        size: 5,
    },
    successLine: {
        color: '#00aa00',
        size: 5,
    },
    incorrectLine: {
        color: '#aa0000',
        size: 5,
    },
    correctedLine: {
        color: '#aa00aa',
        size: 5,
    },
    ageGroupLine: {
        // color: 'orange', ///
        size: 5,
    },
    bottomLine: {
        color: '#666',
        // size: 100, ///
    },
    comboText: {
        color: fontColor,
        size: 30,
    },
    comboBg: {
        color: 'white',
        klass: 'fBg',
    },
    comboBorder: {
        color: fontColor,
        klass: 'sBorder',
    },
    comboBird: {
        color: fontColor,
        length: 10,
        width: 1.5,
        klass: 'sBorder',
    },
    percentBubble: {
        // width: 85,
        width: 95,
    },
    percentBubbleText: {
        percentSign: '%',
        size: 30,
        weight: 600,
    },
    axesText: {
        color: fontColor,
        // color: '#aaa',
        size: 20,
    },
    topCornerText: {
        color: fontColor,
        // color: '#aaa',
        size: 30,
    },
    fixedLine: {
        color: '#669',
        size: 2,
    },
    dashedLine: {
        color: '#669',
        size: 2,
        dashes: '1 4',
    },
    bgDots: {
        size: 1,
        color: '#669',
    },
    handleLine: {
        // color: '#666',
        color: '',
        klass: 'sLayer2 fLayer2',
        size: 5,
    },
    handleLineText: {
        color: 'white',
        size: 25,
        weight: 600,
    },
    optionTick: {
        size: 0.1,
        // color: '#333',
        color: '',
        klass: 'sText',
    },
    sdLine: {
        size: 3,
    },
};
export type Style = typeof defaultStyle;

//
// dark theme
//

let dark = _.cloneDeep(defaultStyle);

// let darkFontColor = '#aaa';
let darkFontColor = colors['text-dark'];
let darkBorderColor = colors['light-blue'];

dark.trialLine.size = 1;

applyColor(dark.correctedLine, (x) => chroma(x).brighten(1));
applyColor(dark.incorrectLine, (x) => chroma(x).brighten(1));
applyColor(dark.trialLine, (x) => '#888');
// applyColor(dark.handleLine, (x) => '#999');
applyColor(dark.handleLineText, (x) => '#333');
applyColor(dark.topCornerText, (x) => '#aaa');
applyColor(dark.axesText, (x) => '#aaa');
applyColor(dark.comboText, (x) => darkFontColor);
applyColor(dark.comboBorder, (x) => darkBorderColor);
applyColor(dark.comboBird, (x) => darkBorderColor);
applyColor(dark.comboBg, (x) => colors['dark']);
applyColor(dark.optionTick, (x) => colors['light-blue']);
applyColor(dark.bottomLine, (x) => '#888');

export const STYLE: { [k: string]: Style } = {
    default: defaultStyle,
    dark,
};
