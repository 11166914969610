import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { IDeviceSettings } from '../../../../../../commonout/interfaces/deviceSetting.interface';
import { IUser } from '../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../commonout/interfaces/userFrontend.interface';
import { IResponse, RESPONSE_STATUS } from '../../../../../common/interfaces/response.model';
import { ConfigService } from './config.service';

@Injectable()
export class SocketService {
    public socket: SocketIOClient.Socket = null;

    constructor(private configServise: ConfigService) {}

    get id(): string {
        return this.socket.id;
    }

    emit(eventName: any, data: any = null): Observable<IResponse> {
        return new Observable<IResponse>((observer: any) => {
            if (this.socket) {
                this.socket.emit(eventName, data, (response: IResponse) => {
                    if (response.status === RESPONSE_STATUS.ERROR) {
                        observer.error(response);
                    } else {
                        observer.next(response);
                    }
                    observer.complete();
                });
            } else {
                observer.complete();
            }
        });
    }

    public load(): Promise<void> {
        return new Promise<void>(async (res, rej) => {
            try {
                const currentUser: IUser<any> & IUserFrontend = JSON.parse(localStorage.getItem('currentUser'));
                if (currentUser && currentUser.token) {
                    await this.connect(currentUser.token);
                }
                res();
            } catch (error) {
                rej(error);
            }
        });
    }

    public connect(token: string): Promise<void> {
        return new Promise((res, rej) => {
            try {
                const uri = `${this.configServise.backendSocketPoint + '?token=' + token}`;
                this.socket = io(uri).connect();
                this.socket.on('connect', () => {
                    console.log('Socket io client connected');
                    res();
                });
                this.socket.on('connect_error', (error: any) => console.log('Socket IO connect error: ' + error.message));
                this.socket.on('connect_timeout', (timeout: any) => console.log('Socket IO connect timeout' + timeout));
                this.socket.on('error', (error: any) => console.log(`Socket IO ERROR: "${error}"`));
                this.socket.on('disconnect', (reason: string) => console.log('Socket IO client disconnected with reason: ' + reason));
                this.socket.on('reconnect', (attemptNumber: any) => {
                    console.log('Socket IO reconnect: ' + attemptNumber);
                    res();
                });
                this.socket.on('reconnect_attempt', (attemptNumber: any) => console.log('Socket IO reconnect attempt: ' + attemptNumber));
                this.socket.on('reconnecting', (attemptNumber: any) => console.log('Socket IO reconnecting attempt: ' + attemptNumber));
                this.socket.on('reconnect_error', (error: any) => console.log('Socket IO reconnect error' + error.message));
                this.socket.on('reconnect_failed', () => console.log('Socket IO reconnect failed'));
                // this.socket.on('ping', () => console.log('Socket IO ping'));
                // this.socket.on('pong', (latency: any) => console.log('Socket IO pong latency: ' + latency));
                this.socket.on('connectedEnvironment', (settings: IDeviceSettings[]) => {
                    this.configServise.connectedEnvironment.next(settings);
                });
                this.socket.on('commonServerConnectionStatus', (isConnected: boolean) => {
                    this.configServise.commonServerConnectionStatus.next(isConnected);
                });
            } catch (error) {
                rej(error);
            }
        });
    }

    public disconnect(): void {
        if (this.socket) this.socket.disconnect();
        this.socket = null;
    }
}
