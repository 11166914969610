import { Component, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ROLE } from '../../../../../../../../commonout/enum/role';
import { IDoctorRoleProperties } from '../../../../../../../../commonout/interfaces/roleProperties/doctorRoleProperties.interface';
import { turnPageAnimation } from '../../../../_animations';
import { UserService } from '../../../../_services/user/user.service';
import { UsersListComponent } from '../users-list.component';

@Component({
    selector: 'doctor-list',
    template: require('./doctor-list.component.html'),
    styles: [require('../users-list.component.scss')],
    animations: [turnPageAnimation],
})
export class DoctorListComponent extends UsersListComponent<IDoctorRoleProperties> {
    constructor(userService: UserService, formBuilder: FormBuilder, renderer: Renderer2) {
        super(ROLE.DOCTOR, userService, formBuilder, renderer);
    }
    buildSearchForm(): void {
        this.searchForm = this.formBuilder.group({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            role: this.role,
        });
    }
}
