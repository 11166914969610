// @ts-nocheck

// import * as d3 from 'd3';
import * as _ from 'lodash';

import { Target } from '..';
import { Scale2D } from '../types'
import { nonInteractive } from '../util'


const defaults = {
  show: true,
  minStep: 50, // step from this to this*2 - space between dots

  dotRadius: 1.3,
  dotColor: '#355255',
}

interface OtherParams {
  scale: Scale2D

  target: Target

  ticksCountX?: number
  ticksCountY?: number
}


type Defaults = typeof defaults
type GivenParams = OtherParams & Partial<Defaults>


export function ChartBg(given: GivenParams) {
  let p = { ...defaults, ...given }

  // console.log(p.scale.x.ticks())
  let dots: XY[] = []

  if (p.show) {
    p.scale.x.ticks(p.ticksCountX).forEach(x => {
      p.scale.y.ticks(p.ticksCountY).forEach(y => {
        dots.push({ x, y })
      })
    })
  }

  p.target
    .selectAll('.chart-bg')
    .data(dots)
    .join('circle')
    .attr('class', 'chart-bg')
    .attr('r', p.dotRadius)
    .attr('cx', d => p.scale.x(d.x))
    .attr('cy', d => p.scale.y(d.y))
    // .attr('stroke', p.dotColor)
    .attr('fill', p.dotColor)
    // .attr('class', 'svg-crisp')

  nonInteractive(p.target)
}

interface XY {
  x: number,
  y: number,
}
