import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TestClassProvider } from '../../../../../../commonout/classes/testClass.provider';
import { CustomDateAdapter } from '../../_adapters/date.adapter';
import { AutocompleteComponent, DatepickerComponent, ImageCarouselComponent, MultiselectComponent, SingleselectComponent, TestFeedbackComponent } from '../../_components';
import { DoctorDetailsComponent } from '../../_components/users/user-details/doctor-details/doctor-details.component';
import { TechStaffDetailsComponent } from '../../_components/users/user-details/staff-details/techstaff-details.component';
import { AutofocusDirective, FocusDirective, ThrottleClickDirective } from '../../_directives';
import { CapitalizePipe, EnumToArrayPipe, ReversePipe, SanitizeHtmlPipe, SanitizeStylePipe, TranslatePipe } from '../../_pipes';
import { TestService } from '../../_services/examination/test.service';
import { MaterialModule } from '../material.module';

export const APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};

@NgModule({
    imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, DragDropModule],
    declarations: [
        AutofocusDirective,
        FocusDirective,
        ThrottleClickDirective,
        EnumToArrayPipe,
        SanitizeHtmlPipe,
        SanitizeStylePipe,
        TranslatePipe,
        CapitalizePipe,
        ReversePipe,
        SingleselectComponent,
        DatepickerComponent,
        MultiselectComponent,
        AutocompleteComponent,
        TechStaffDetailsComponent,
        DoctorDetailsComponent,
        TestFeedbackComponent,
        ImageCarouselComponent,
    ],
    exports: [
        AutofocusDirective,
        FocusDirective,
        ThrottleClickDirective,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        EnumToArrayPipe,
        SanitizeHtmlPipe,
        SanitizeStylePipe,
        TranslatePipe,
        CapitalizePipe,
        ReversePipe,
        SingleselectComponent,
        DatepickerComponent,
        MultiselectComponent,
        AutocompleteComponent,
        MatProgressBarModule,
        TechStaffDetailsComponent,
        DoctorDetailsComponent,
        DragDropModule,
        TestFeedbackComponent,
        ImageCarouselComponent,
    ],
    providers: [
        TestClassProvider,
        TestService,
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: APP_DATE_FORMATS,
        },
    ],
})
export class SharedModule {}
