import { IMaculaScan } from '../interfaces/maculaScan.interface';
import { SmartImage } from './smartImage.class';
import { OCULUS } from '../enums/oculus.enum';
/**
 * An abstract class that represents an object for holding results from devices (Topcon Triton and Nidek RS330 for now) which produce sequence of images (like video)
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 */
export abstract class MaculaScan implements IMaculaScan {
    public imagesSequence: SmartImage[]; // sequence of images
    public oculus: OCULUS; // an eye sequence of images related for
    public createdAt: string; // the date taken from filename
    constructor() {}
    public abstract setModel(model: IMaculaScan): void;
    public getModel(): IMaculaScan {
        const model: IMaculaScan = {
            createdAt: this.createdAt,
            oculus: this.oculus,
            imagesSequence: this.imagesSequence.map(i => i.model),
        };
        return model;
    }
}
