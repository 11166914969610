import { ISmartImage } from '../../saccadioweb/common/interfaces/smart-image.interface';
import { TEST_TYPE } from '../enum/test-type';
import { IExamination } from './examination';
/**
 * An interface that describes common properties of data packet from CAM.
 */
export interface ICamMessage {
    examinationModel?: IExamination;
    chartTypeString: TEST_TYPE; // тип теста
    message_type: MESSAGE_TYPE; // тип сообщения
    timestamp: number; // и так понятно
    exitType?: number;
    metadata?: string;
    target?: string; // OS/OD
    measurement?: number; // диаметр зрачка в Пьюпл тесте
    backgroundColor?: number; // цвет фона
    numtest?: number; // порядковый номер теста
    diameter?: number; // диаметр зрачка в Конвердженс тесте
    ppdistance?: number; // расстояние между зрачками
    age?: number;
    z?: number; // расстояние до виртуального видимого объекта
    targetx?: number;
    targety?: number;
    seen?: number;
    userSeen?: number;
    velocity?: number;
    direction?: number;
    lineType?: number;
    lineColor?: string;
    pulseduration?: number;
    pupilamplitude?: number;
    pupilvelocity?: number;
    pupillatency?: number;
    pupilsymmetry?: number;
    // Ptosis fields start
    ODBottomX?: number;
    ODBottomY?: number;
    ODCenterX?: number;
    ODCenterY?: number;
    ODSize?: number;
    ODTopX?: number;
    ODTopY?: number;
    OSBottomX?: number;
    OSBottomY?: number;
    OSCenterX?: number;
    OSCenterY?: number;
    OSSize?: number;
    OSTopX?: number;
    OSTopY?: number;
    picture?: string;
    smartImage?: ISmartImage;
    filename?: string;
    // Ptosis fields end
    //Saccades merged fields start
    threshold?: number;
    calibratedx?: number;
    greenDotIndex?: number;
    redDotIndex?: number;
    targettype?: number;
    trial?: number;
    rawODx?: number;
    rawODy?: number;
    rawOSx?: number;
    rawOSy?: number;
    stage?: number;
    targetDotX?: number;
    training?: boolean;
    ratio?: number;
    ODPupilSize?: number;
    OSPupilSize?: number;
    accepted?: string;
    moving_average?: number;
    glintNMinus?: number;
    //Saccades merged fields end
    //PursuitAndSaccades start
    eyeODx?: number;
    eyeODy?: number;
    eyeOSx?: number;
    eyeOSy?: number;
    eyeOS?: number;
    stimuliODx?: number;
    stimuliODy?: number;
    stimuliOSx?: number;
    stimuliOSy?: number;
    stimuliOS?: number;
    str?: number;
    stg?: number;
    stb?: number;
    bgr?: number;
    bgg?: number;
    bgb?: number;
    pointX?: number;
    pointY?: number;
    calibrationAngleOS?: number;
    seconds?: number;
    calibrationGlintData?: {
        calibationGlintData: number;
        pixelDifference: number;
        firstPointYOfTrial: number;
        ratio: number;
    };
    //PurstuitSaccades end
    //VisualFieldMerged start
    srt?: number;
    tat?: number;
    edits?: any[];
    peripheralTarget?: string;
    centralTarget?: string;
    backgroundGrayscalelevelOD?: number;
    backgroundGrayscalelevelOS?: number;
    //VisualFieldMerged end
    //FunctionalScreening start
    bpgxOD?: number; // bright glint horisontal OD
    bpgxOS?: number; // bright glint horisontal OS
    bpgyOD?: number; // bright glint vertical OD
    bpgyOS?: number; // bright glint vertical OS
    dpgxOD?: number; // dark glint horisontal OD
    dpgxOS?: number; // dark glint horisontal OS
    dpgyOD?: number; // dark glint vertical OD
    dpgyOS?: number; // dark glint vertical OS
    measurementOD?: number; // Pupil size OD
    measurementOS?: number; // Pupil size OS
    pupilxOD?: number; // Pupil position OD horizontal
    pupilxOS?: number; // Pupil position OS horizontal
    pupilyOD?: number; // Pupil position OD vertical
    pupilyOS?: number; // Pupil position OS vertical
    pupilOD?: string;
    pupilOS?: string;
    bpgOD?: string;
    bpgOS?: string;
    dpgOD?: string;
    dpgOS?: string;
    distToOptOS?: number;
    distToOptOD?: number;
    IOLOD?: boolean;
    IOLOS?: boolean;
    bpgAreaOD?: number;
    bpgAreaOS?: number;
    IPD?: number;
    dpgAreaOD?: number;
    dpgAreaOS?: number;
    bpgCandidatesOD?: number;
    bpgCandidatesOS?: number;
    dpgCandidatesOD?: number;
    dpgCandidatesOS?: number;
    BPcontrastOD?: number;
    BPcontrastOS?: number;
    DPcontrastOD?: number;
    DPcontrastOS?: number;
    //FunctionalScreening end
}

export interface ICalibrationData {
    calibationGlintData: number;
    pixelDifference: number;
    firstPointYOfTrial: number;
    ratio: number;
}

export enum MESSAGE_TYPE {
    START_TEST, // 0 начало теста
    STOP_TEST, // 1 конец теста
    START_PUPIL_DATA_TRANSMISSION, // 2 начало субтеста диаметра зрачка
    STOP_PUPIL_DATA_TRANSMISSION, // 3 конец субтеста диаметра зрачка
    START_BACKGROUND_DATA_TRANSMISSION, // 4 начало периода с определенным цветом фона
    STOP_BACKGROUND_DATA_TRANSMISSION, // 5 конец периода с определенным цветом фона
    DATA_PACKAGE, // 6 данные для субтеста диаметра зрачка и данные для установки фона
    START_CONVERGENCE_NPC_DATA_TRANSMISSION, // 7
    STOP_CONVERGENCE_NPC_DATA_TRANSMISSION, // 8
    START_CONVERGENCE_RECOVERY_DATA_TRANSMISSION, // 9
    STOP_CONVERGENCE_RECOVERY_DATA_TRANSMISSION, // 10
    START_CENTER_SHOWING, // 11
    STOP_CENTER_SHOWING, // 12
    START_PERIPHERAL_SHOWING, // 13
    STOP_PERIPHERAL_SHOWING, // 14
    CONFIRMATION = 'OK',
    ERROR = 'ERROR',
    START_SEQUENCE = 15, // used in acuity1,fly,cover
    CONFIGURATION_PARAMS = 16, // used in cover to pass device params and such
}

export enum BACKGROUND_COLOR {
    LIGHT,
    DARK,
    RELAX,
}

export interface ICamTestData {
    _id?: string;
    data: Array<ICamMessage>;
}
