import { PachymetryNidekNT530PTest } from '../../../../../../../../commonout/classes/tests/NIDEK/NT530P/pachymetryNidekNT530PTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PachymetryTestRemarksComponent } from '../../../../_components';
import { PachymetryTestRemarksFrontend } from '../../../test-remarks/NIDEK/NT530P/pachymetryTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PachymetryNidekNt530PTestFrontend extends PachymetryNidekNT530PTest {
    remarks: PachymetryTestRemarksFrontend;
    public readonly remarksComponent: typeof PachymetryTestRemarksComponent = PachymetryTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PachymetryTestRemarksFrontend();
    }
}

export interface PachymetryNidekNt530PTestFrontend extends TestFrontend {}
applyMixins(PachymetryNidekNt530PTestFrontend, [TestFrontend]);
