import { PtosisBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/ptosisBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { CommonStartStopControlComponent, PtosisBulbicamTestChartComponent, PtosisTestRemarksComponent } from '../../../_components';
import { PtosisBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/ptosisBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class PtosisBulbicamTestFrontend extends PtosisBulbicamTest {
    remarks: PtosisBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof PtosisTestRemarksComponent = PtosisTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof PtosisBulbicamTestChartComponent = PtosisBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof CommonStartStopControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PtosisBulbicamTestRemarksFrontend();
    }
}

export interface PtosisBulbicamTestFrontend extends TestFrontend {}
applyMixins(PtosisBulbicamTestFrontend, [TestFrontend]);
