import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { MaculaScanTopconTritonMeasuredData } from '../../../../../../../common/models/measuredData/TOPCON/TRITON/maculaScanTopconTritonData.model';
import { IMaculaScanTopconTritonMeasuredData } from '../../../../../../../common/interfaces/measuredData/TOPCON/TRITON/macula-scan-topcon-tritone.measuredData.interface';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { MaculaScanFrontend } from '../../../maculaScanFrontend';
import { CellHookHandler } from 'jspdf-autotable';
import { MaculaScanTopconTritonTestRemarksFrontend } from '../../../test-remarks/TOPCON/TRITON/maculaScanTopconTritonTestRemarksFrontend.class';

export class MaculaScanTopconTritonMeasuredDataFrontend extends MaculaScanTopconTritonMeasuredData implements MeasuredDataFrontend {
    public remarks: MaculaScanTopconTritonTestRemarksFrontend;
    public maculaScan: {
        OD: MaculaScanFrontend[];
        OS: MaculaScanFrontend[];
    };
    public reports: {
        OD: SmartImageFrontend[];
        OS: SmartImageFrontend[];
    };
    constructor() {
        super();
        this.maculaScan = {
            OD: [],
            OS: [],
        };
        this.reports = {
            OD: [],
            OS: [],
        };
    }
    public setModel(model: IMaculaScanTopconTritonMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.maculaScan.OD = model.maculaScan.OD.map((sm) => {
            const s = new MaculaScanFrontend();
            s.setModel(sm);
            return s;
        });
        this.maculaScan.OS = model.maculaScan.OS.map((sm) => {
            const s = new MaculaScanFrontend();
            s.setModel(sm);
            return s;
        });
        this.reports.OD = model.reports.OD.map((rm) => {
            const i = new SmartImageFrontend();
            i.model = rm;
            return i;
        });
        this.reports.OS = model.reports.OS.map((rm) => {
            const i = new SmartImageFrontend();
            i.model = rm;
            return i;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        try {
            if (this.reports.OD.length > 0 || this.reports.OS.length > 0) {
                let head = [],
                    table = [];
                head.push({
                    OD: { content: 'OD', styles: { halign: 'center' } },
                    OS: { content: 'OS', styles: { halign: 'center' } },
                });
                await this.downloadMediaData(fileService);
                let length: number = this.reports.OD.length > this.reports.OS.length ? this.reports.OD.length : this.reports.OS.length;
                for (let index = 0; index < length; index++) {
                    let ratio: number = await new Promise<number>((res) => {
                        let img = new Image();
                        img.onload = () => {
                            let height = img.height;
                            let width = img.width;
                            res(width / height);
                        };
                        img.src = this.reports.OD[index] ? this.reports.OD[index].imageContent : this.reports.OS[index].imageContent;
                    });
                    table.push({
                        OD: {
                            content: '',
                            styles: {
                                minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                                minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                            },
                        },
                        OS: {
                            content: '',
                            styles: {
                                minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                                minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                            },
                        },
                    });
                    table.push({
                        OD: { content: this.reports.OD[index] ? this.reports.OD[index].remark.value : '', styles: { halign: 'left' } },
                        OS: { content: this.reports.OS[index] ? this.reports.OS[index].remark.value : '', styles: { halign: 'left' } },
                    });
                }
                let ODindex: number = 0,
                    OSindex: number = 0;
                (document as any).autoTable({
                    head: head,
                    body: table,
                    startY: pager.line,
                    pageBreak: 'auto',
                    theme: 'grid',
                    margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                    didDrawCell: <CellHookHandler>((data) => {
                        if (data.section === 'body' && data.row.index % 2 === 0 && data.column.dataKey === 'OD' && this.reports.OD[ODindex]) {
                            document.addImage(this.reports.OD[ODindex].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                            ODindex++;
                        } else if (data.section === 'body' && data.row.index % 2 === 0 && data.column.dataKey === 'OS' && this.reports.OS[OSindex]) {
                            document.addImage(this.reports.OS[OSindex].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                            OSindex++;
                        }
                    }),
                    didDrawPage: () => {},
                });
                pager.line = (document as any).lastAutoTable.finalY;
            }
        } catch (error) {
            console.log(error);
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
                maculaImages: [],
                ai: [],
            },
            OS: {
                values: '',
                images: [],
                maculaImages: [],
                ai: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        for (const ms of this.maculaScan.OD) {
            formattedTest.OD.maculaImages = (ms as MaculaScanFrontend).smartImages.map((si) => si.imageUrl);
            break;
        }
        for (const ms of this.maculaScan.OS) {
            formattedTest.OS.maculaImages = (ms as MaculaScanFrontend).smartImages.map((si) => si.imageUrl);
            break;
        }
        this.reports.OD.forEach((si) => {
            formattedTest.OD.images.push(si.imageUrl);
        });
        this.reports.OS.forEach((si) => {
            formattedTest.OS.images.push(si.imageUrl);
        });
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.maculaScan.OD.map((ms: MaculaScanFrontend) => ms.downloadMedia(fileService)));
        await Promise.all(this.maculaScan.OS.map((ms: MaculaScanFrontend) => ms.downloadMedia(fileService)));
        await Promise.all(this.reports.OD.map((si: SmartImageFrontend) => si.download(fileService)));
        await Promise.all(this.reports.OS.map((si: SmartImageFrontend) => si.download(fileService)));
    }
}
