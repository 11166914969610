import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TestResultCoordinates, PupilDiameterResults, SHIFT_TYPE } from '../../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';

@Component({
  selector: 'pupil-diameter-table',
  template: require('./pupil-diameter-table.component.html'),
  styles: [require('./pupil-diameter-table.component.scss')]
})

export class PupilDiameterTableComponent implements OnInit, OnDestroy {

    @Output() highlight = new EventEmitter<TestResultCoordinates[]>();
    @Output() clearHighlight = new EventEmitter<void>();

    @ViewChild('wrapper') table: ElementRef;
    @ViewChildren('tdOD') itemsOD: QueryList<ElementRef>;
    @ViewChildren('tdOS') itemsOS: QueryList<ElementRef>;
    @ViewChildren('tdDifference') itemsDifference: QueryList<ElementRef>;
    @ViewChildren('dataCellOD') dataCellsOD: QueryList<ElementRef>;
    @ViewChildren('dataCellOS') dataCellsOS: QueryList<ElementRef>;
    @ViewChildren('dataCellDifference') dataCellsDifference: QueryList<ElementRef>;

    public testResults$ = new Subject<PupilDiameterResults>();
    public showTable = false;

    private subscriptions: Subscription[] = [];

    private readonly CELL_PADDING = 5; 
    private readonly BORDER_SIZE = 0.5; 
    private readonly padding: { left: number; top: number; right: number; bottom: number } = { left: 200, top: 40, right: 40, bottom: 40 };

    constructor(private renderer: Renderer2) { }

    ngOnInit() {
        this.subscriptions.push(this.testResults$.subscribe(results => {
            this.renderer.setStyle(this.table.nativeElement, 'width', `${(results.tableWidth + this.padding.left).toFixed(1)}px`);

            if (!results) return;

            this.fillTable(results);         
        }));
    }

    public fillTable(testResults: PupilDiameterResults): void {
        const itemsOS = this.itemsOS.toArray();
        const itemsDifference = this.itemsDifference.toArray();
        const dataCellsOS = this.dataCellsOS.toArray();
        const dataCellsDifference = this.dataCellsDifference.toArray();

        this.dataCellsOD.forEach((item, i) => {
            const coors = testResults.testResultCoors[i];
            
            const coodrinatesOD: string = JSON.stringify(coors);
            const coodrinatesOS: string = JSON.stringify(coors);
            const coodrinatesDifference: string = JSON.stringify(coors);

            this.renderer.setAttribute(item.nativeElement, 'coodrinates', coodrinatesOD);
            this.renderer.setAttribute(dataCellsOS[i].nativeElement, 'coodrinates', coodrinatesOS);
            this.renderer.setAttribute(dataCellsDifference[i].nativeElement, 'coodrinates', coodrinatesDifference);

            const cellContentOD = this.getContentTableCell(testResults.averageDiameterOD[i], testResults.shiftTypes[i]);
            const cellContentOS = this.getContentTableCell(testResults.averageDiameterOS[i], testResults.shiftTypes[i]);
            const cellDifference = this.getContentTableCell(testResults.differenceDiameter[i], testResults.shiftTypes[i]);
        
            this.renderer.setProperty(item.nativeElement, 'innerHTML', `${cellContentOD}`);
            this.renderer.setProperty(dataCellsOS[i].nativeElement, 'innerHTML', `${cellContentOS}`);
            this.renderer.setProperty(dataCellsDifference[i].nativeElement, 'innerHTML', `${cellDifference}`);
        });

        const emptyCellClass = 'empty-cell';

        // the count of html elements is the same
        this.itemsOD?.forEach((item, i) => {
            if (!(<string>item.nativeElement.className).includes(emptyCellClass)) {
                const width = testResults.tableCellWidths[i] - this.CELL_PADDING * 2 - this.BORDER_SIZE;

                this.setElementWidth(item, width);
                this.setElementWidth(itemsOS[i], width);
                this.setElementWidth(itemsDifference[i], width);
            }
            else {
                const width = testResults.tableCellWidths[i] - this.CELL_PADDING * 2;

                this.setElementWidth(item, width);
                this.setElementWidth(itemsOS[i], width);
                this.setElementWidth(itemsDifference[i], width);
            }
        });
    }

    private setElementWidth(item: ElementRef, width: number): void {
        this.renderer.setStyle(item.nativeElement, 'width', 
            `${(width).toFixed(1)}px`);
        this.renderer.setStyle(item.nativeElement, 'min-width', 
            `${(width).toFixed(1)}px`);
        this.renderer.setStyle(item.nativeElement, 'max-width', 
            `${(width).toFixed(1)}px`);
    }

    private getContentTableCell(content: number, shiftType: SHIFT_TYPE): string {
        switch(shiftType) {
            case SHIFT_TYPE.NO_CONTENT:
                return `◃${content?.toFixed(1)}`;
            case SHIFT_TYPE.DOWNGRADE:
                return `▿${content?.toFixed(1)}▹`;
            case SHIFT_TYPE.UPGRADE:
                return `▵${content?.toFixed(1)}▹`;
        }
    }

    onMouseEnter(event: MouseEvent): void {
        const coordinates: TestResultCoordinates[] = [];

        (event.target as Element).childNodes.forEach(td => {
            if ((td as Element).attributes.getNamedItem('coodrinates')) {
                const tdCoordinates = JSON.parse((td as Element).attributes.getNamedItem('coodrinates').value);
                coordinates.push(tdCoordinates);
            }
        });

        if (coordinates.length === 0) return;

        this.highlight.emit(coordinates);
    }

    onMouseLeave(): void {
        this.clearHighlight.emit();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
