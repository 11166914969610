import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { IVisualFieldMergedBulbicamMeasuredData } from '../../../interfaces/measuredData/BulbiCAM/visualField-merged-bulbicam.measuredData.interface';
import { CamTest } from '../../haplotest.class';
import { MeasuredData } from '../measuredData.model';

export abstract class VisualFieldMergedBulbicamMeasuredData implements MeasuredData, IVisualFieldMergedBulbicamMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public haplotests: CamTest[];
    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.VISUAL_FIELD_MERGED;
        this.haplotests = [];
    }
    public abstract setModel(model: IVisualFieldMergedBulbicamMeasuredData): void;
    public getModel(): IVisualFieldMergedBulbicamMeasuredData {
        let model: IVisualFieldMergedBulbicamMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            haplotests: this.haplotests.map(htm => htm.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.haplotests.length === 0;
    }
}
