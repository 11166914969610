import { Directive, Input, ViewContainerRef } from '@angular/core';
import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';

@Directive({
    selector: '[test-remarks]',
})
export class TestRemarksDirective {
    @Input() testType: TEST_TYPE;
    @Input() testDevice: DEVICE;
    @Input() testRef: string;

    constructor(public viewContainerRef: ViewContainerRef) {}
}
