import { MaculaScanTopconTritonTest } from '../../../../../../../../commonout/classes/tests/TOPCON/TRITON/maculaScanTopconTritonTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { MaculaScanTopconTritonTestRemarksComponent } from '../../../../_components';
import { MaculaScanTopconTritonTestRemarksFrontend } from '../../../test-remarks/TOPCON/TRITON/maculaScanTopconTritonTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class MaculaScanTopconTritonTestFrontend extends MaculaScanTopconTritonTest {
    remarks: MaculaScanTopconTritonTestRemarksFrontend;
    public readonly remarksComponent: typeof MaculaScanTopconTritonTestRemarksComponent = MaculaScanTopconTritonTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new MaculaScanTopconTritonTestRemarksFrontend();
    }
}

export interface MaculaScanTopconTritonTestFrontend extends TestFrontend {}
applyMixins(MaculaScanTopconTritonTestFrontend, [TestFrontend]);
