// @ts-nocheck
export class Variable<T> {
    constructor (public value: T, private changed?: (value: T) => void) {}
    get (): T {
        return this.value;
    }
    set (value: T) {
        this.value = value;
        if (this.changed) this.changed(value);
    }

    toggle() {
        let value = this.value as any
        if (value === true) {
            return this.set(false as any)
        } else if (value === false) {
            return this.set(true as any)
        }
    }
}

export class MySet<T> {
    private set = new Set<T>();

    constructor (public changed?: (a: MySet<T>) => void) {}

    toggle (item: T) {
        if (this.has(item)) {
            this.delete(item);
        } else {
            this.add(item);
        }
    }

    has (item: T): boolean {
        return this.set.has(item);
    }
    add (item: T) {
        this.set.add(item);
        if (this.changed) this.changed(this);
    }
    delete (item: T) {
        this.set.delete(item);
        if (this.changed) this.changed(this);
    }
}
