import { MeasuredData } from '../../measuredData.model';
import { IMaculaScanTopconTritonMeasuredData } from '../../../../interfaces/measuredData/TOPCON/TRITON/macula-scan-topcon-tritone.measuredData.interface';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { SmartImage } from '../../../smartImage.class';
import { MaculaScan } from '../../../maculaScan.class';

export abstract class MaculaScanTopconTritonMeasuredData implements MeasuredData, IMaculaScanTopconTritonMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public maculaScan: {
        OD: MaculaScan[];
        OS: MaculaScan[];
    };
    public reports: {
        OD: SmartImage[];
        OS: SmartImage[];
    };
    constructor() {
        this.sourceDevice = DEVICE.TOPCON_TRITON;
        this.testType = TEST_TYPE.MACULA_SCAN;
    }
    public abstract setModel(model: IMaculaScanTopconTritonMeasuredData): void;
    public getModel(): IMaculaScanTopconTritonMeasuredData {
        let model: IMaculaScanTopconTritonMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            maculaScan: {
                OD: this.maculaScan.OD.map((ms) => ms.getModel()),
                OS: this.maculaScan.OS.map((ms) => ms.getModel()),
            },
            reports: {
                OD: this.reports.OD.map((r) => r.model),
                OS: this.reports.OS.map((r) => r.model),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.maculaScan.OD.length === 0 && this.maculaScan.OS.length === 0 && this.reports.OD.length === 0 && this.reports.OS.length === 0;
    }
}
