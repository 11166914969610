import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CALIBRATION_TEST } from '../../../../../../../../../commonout/enum/calibration.command.enum';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { ICalibrationResult } from '../../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { ILuxCalibration } from '../../../../../../../../../commonout/interfaces/calibration/luxCalibration.interface';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import { ONSET_TYPE } from '../../../../../../../../common/enums/visualfieldMergedOnsetType.enum';
import { IVFMergedPayloadData } from '../../../../../../../../common/interfaces/vfMergedPayloadData.interface';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'visual-field-merged-test',
    template: require('./visual-field-merged-test-controls.component.html'),
    styles: [require('./visual-field-merged-test-controls.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class VisualFieldMergedTestControlsComponent extends BulbicamControlComponent implements OnInit {
    public controls: FormGroup;
    public OCULUS: typeof OCULUS = OCULUS;
    public stimuliPositions: number[] = [16, 26, 64];
    public onset: { type: string; length: number }[] = [
        {
            type: 'Instant',
            length: null,
        },
        {
            type: 'Linear',
            length: 3500,
        },
        {
            type: 'Logarithmic',
            length: 4000,
        },
    ];
    private readonly defaultbackgroundLuxlevel: number = 10;
    private readonly defaultfullIntensityLuxLevel: number = 300;
    public minLuxCalibrationValue: number;
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService, public formBuilder: FormBuilder) {
        super(bulbicamService, configService, socketService, authService);
    }
    ngOnInit(): void {
        this.getMinLuxCalibrationValue().then(x => {
            this.minLuxCalibrationValue = x;
            this.controls.get('backgroundLuxlevel').setValidators([Validators.required, Validators.min(6), Validators.max(x)]);
            this.controls.get('fullIntensityLuxLevel').setValidators([Validators.required, Validators.min(6), Validators.max(x)]);
            if (this.defaultbackgroundLuxlevel > x) this.controls.get('backgroundLuxlevel').setValue(x);
            if (this.defaultfullIntensityLuxLevel > x) this.controls.get('fullIntensityLuxLevel').setValue(x);
        });
        let onsetTime = {};
        this.onset.forEach(o => {
            onsetTime[o.type] = o.length;
        });
        this.controls = this.formBuilder.group({
            oculus: [OCULUS.OD, Validators.required],
            stimuliPositions: [16, Validators.required],
            training: false,
            diameter: [30, Validators.required],
            backgroundLuxlevel: [this.defaultbackgroundLuxlevel, Validators.required],
            fullIntensityLuxLevel: [this.defaultfullIntensityLuxLevel, Validators.required],
            onset: this.formBuilder.group({
                onsetType: [this.onset[1].type, Validators.required],
                onsetTime: this.formBuilder.group(onsetTime),
                additionalWaiting: this.formBuilder.group({
                    status: [true, Validators.required],
                    waitingLength: [500, Validators.required],
                }),
            }),
            stimulusFlicker: this.formBuilder.group({
                status: [false, Validators.required],
                frequency: [10, Validators.required],
            }),
            greenDotAnimation: this.formBuilder.group({
                status: [true, Validators.required],
                concentric: [800, Validators.required],
            }),
            color: true,
            movement: true,
            size: true,
            reading: true,
        });
    }
    public start(): void {
        this.pendingResponse = true;
        this.controls.disable();
        const rawFormValue: {
            oculus: OCULUS;
            stimuliPositions: number;
            training: boolean;
            diameter: number;
            backgroundLuxlevel: number;
            fullIntensityLuxLevel: number;
            onset: {
                onsetType: ONSET_TYPE;
                onsetTime: { Instant: null; Linear: 3500; Logarithmic: 4000 };
                additionalWaiting: {
                    status: boolean;
                    waitingLength: number;
                };
            };
            stimulusFlicker: {
                status: boolean;
                frequency: number;
            };
            greenDotAnimation: {
                status: boolean;
                concentric: number;
            };
            color: boolean;
            movement: boolean;
            size: boolean;
            reading: boolean;
        } = this.controls.getRawValue();
        let payload: IVFMergedPayloadData = {
            oculus: rawFormValue.oculus,
            stimuliPositions: rawFormValue.stimuliPositions,
            training: rawFormValue.training,
            diameter: rawFormValue.diameter,
            backgroundLuxlevel: rawFormValue.backgroundLuxlevel,
            fullIntensityLuxLevel: rawFormValue.fullIntensityLuxLevel,
            onsetType: rawFormValue.onset.onsetType,
            onsetTime: null,
            onsetAdditionalWaitingStatus: rawFormValue.onset.additionalWaiting.status,
            onsetAdditionalWaitingLength: rawFormValue.onset.additionalWaiting.waitingLength,
            stFlickerStatus: rawFormValue.stimulusFlicker.status,
            stFlickerFrequency: rawFormValue.stimulusFlicker.frequency,
            greenDotAnimationStatus: rawFormValue.greenDotAnimation.status,
            greenDotAnimationConcentric: rawFormValue.greenDotAnimation.concentric,
            color: rawFormValue.color,
            movement: rawFormValue.movement,
            size: rawFormValue.size,
            reading: rawFormValue.reading,
            command: null,
            testType: this.test.type,
            username: this.authService.currentUser.username,
        };
        if (this.test.status === TEST_STATUS.IN_PROGRESS && this.controls.get('training').value) {
            this.controls.get('training').setValue(false);
            payload.training = false;
            payload.command = 'STOP_TRAINING';
        } else {
            payload.command = TEST_COMMAND.START;
        }
        switch (payload.onsetType) {
            case ONSET_TYPE.INSTANT:
                delete payload.onsetTime;
                break;
            case ONSET_TYPE.LINEAR:
                payload.onsetTime = rawFormValue.onset.onsetTime.Linear;
                break;
            case ONSET_TYPE.LIGARITHMIC:
                payload.onsetTime = rawFormValue.onset.onsetTime.Logarithmic;
                break;
            default:
                break;
        }
        if (!payload.stFlickerStatus) {
            delete payload.stFlickerFrequency;
        }
        if (!payload.onsetAdditionalWaitingStatus) {
            delete payload.onsetAdditionalWaitingLength;
        }
        if (!payload.greenDotAnimationStatus) {
            delete payload.greenDotAnimationConcentric;
        }

        this.bulbicamService.sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, payload).subscribe(() => {
            this.test.status = TEST_STATUS.IN_PROGRESS;
            this.pendingResponse = false;
        });
    }
    public onStimulusFlickerClick(): void {
        if (this.controls.get('stimulusFlicker.status').value && this.controls.get('stimulusFlicker.frequency').value === null) {
            this.controls.get('stimulusFlicker.frequency').setValue(10);
        }
    }
    public onAdditionalWaitingClick(): void {
        if (this.controls.get('onset.additionalWaiting.status').value && this.controls.get('onset.additionalWaiting.waitingLength').value === null) {
            this.controls.get('onset.additionalWaiting.waitingLength').setValue(500);
        }
    }
    public onGreenDotAnimationClick(): void {
        if (!this.controls.get('greenDotAnimation.status').value) {
            this.controls.get('color').setValue(true);
            this.controls.get('movement').setValue(true);
            this.controls.get('size').setValue(true);
            this.controls.get('reading').setValue(true);
        } else {
            this.controls.get('color').setValue(false);
            this.controls.get('movement').setValue(false);
            this.controls.get('size').setValue(false);
            this.controls.get('reading').setValue(false);
        }

        if (this.controls.get('greenDotAnimation.status').value && this.controls.get('greenDotAnimation.concentric').value === null) {
            this.controls.get('greenDotAnimation.concentric').setValue(800);
        }
    }
    public enable(): void {
        this.controls.enable();
    }
    public disable(): void {
        this.controls.disable();
    }
    private async getMinLuxCalibrationValue(): Promise<number> {
        let luxResults: ICalibrationResult<ILuxCalibration>[] = await this.bulbicamService
                .getCalibrationSettings<ICalibrationResult<ILuxCalibration>>(CALIBRATION_TEST.LUX_CALIBRATION)
                .toPromise(),
            latestResult: ICalibrationResult<ILuxCalibration> = luxResults.sort((a, b) => b.createdAt - a.createdAt)[0];
        return latestResult?.results.maxLuxValue | 0;
    }
}
