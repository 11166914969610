import { animate, animateChild, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IUser } from '../../../../../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../../../../../commonout/interfaces/userFrontend.interface';
import { AntSegmentHaagSlitlampTestFrontend } from '../../../../../../_models/tests/HAAG/SLITLAMP/antSegmentHaagSlitlampTestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'ant-segment-haag-slitlamp-test-remarks',
    template: require('./ant-segment-haag-slitlamp-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./ant-segment-haag-slitlamp-test-remarks.component.scss')],
    animations: [
        trigger('dropdown', [transition('false <=> true', [query('@dropdownChild', stagger(75, [animateChild()]))])]),
        trigger('dropdownChild', [
            state('false', style({ opacity: 0, height: '0px' })),
            state(
                'true',
                style({
                    opacity: 1,
                    height: '60px',
                })
            ),
            transition('false <=> true', animate(150)),
        ]),
    ],
})
export class AntSegmentHaagSlitlampTestRemarksComponent extends TestRemarksComponent {
    test: AntSegmentHaagSlitlampTestFrontend;
    private readonly eyelidsEyeLashesAndLacrimalSystem = {
        eyelids: ['Baggy Lid', 'Blepharitis', 'Chalazion', 'Ectropion', 'Entropion', 'Lagophthalmos', 'Lid Edema', 'Lid Mass', 'Stye', 'Swelling', 'Other'],
        eyelashes: ['Blepharitis', 'Disitichiasis', 'Madarosis', 'Meibomitis', 'Poliosis', 'Trichiasis', 'Other'],
        lacrimalSystem: ['Dacryocystitis', 'Lacrimal Fistula', 'Lacrimal Sac Mass', 'Lacrimal Sac Swelling', 'ROPLAS Negative', 'ROPLAS Positive', 'Other'],
    };
    private readonly conjunctivaLimbusAndSclera = {
        conjunctiva: [
            'Bitot Spots',
            'Concretions',
            'Congestion',
            'Discharge',
            'Edema',
            'Follicles',
            'Foreign Body',
            'Papillae',
            'Pinguecula',
            'Pterygium',
            'Subconjunctival Hemorrhage',
            'Other',
        ],
        limbus: ['Dermoid', 'Herberts pits', 'Ischemia', 'Mass', 'Pannus', 'Scarring', 'Trantas Dots', 'Vascularisation', 'Other'],
        sclera: [
            'Avascularity',
            'Episcleritis',
            'Inferior Thinning',
            'Nasal Thinning',
            'Prominent Episcleral Vessels',
            'Scleritis',
            'Staphyloma',
            'Superior Thinning',
            'Temporal Thinning',
            'Other',
        ],
    };
    private readonly corneaAndAnteriorChamber = {
        cornea: [
            'Acute Hydrops',
            'Arcus',
            'Band Shaped Keratopathy',
            'Corneal Bullae',
            'DM folds',
            'Dystrophy',
            'Ectasia',
            'Edema',
            'Epithelial defect',
            'Fleischers ring',
            'Fluorescein stain',
            'Foreign body',
            'Guttata',
            'Haabs striae',
            'Haze',
            'Keratic precipitates',
            'Krukenberg spindles',
            'Leucoma',
            'Loose/protruding sutures',
            'Megalocornea',
            'Microcornea',
            'Opacity',
            'Post DSEK',
            'Post LASIK',
            'Post PK',
            'Post RK',
            'Prominent corneal nerves',
            'SPKs',
            'Scar',
            'Sutures intact',
            'Thinning',
            'Total corneal opacity',
            'Ulceration',
            'Vascularization',
            'Vogts striae',
            'Other',
        ],
        anteriorChamberDepth: ['Deep', 'Flat', 'Irregular', 'Normal', 'Shallow', 'Other'],
        vanHerrickGrading: ['1', '2', '3', '4', 'Slit', 'Other'],
        acCellsFlare: [
            'AC Cells 0',
            'AC Cells 1',
            'AC Cells 2',
            'AC Cells 3',
            'AC Cells 4',
            'AC Flare 0',
            'AC Flare 1',
            'AC Flare 2',
            'AC Flare 3',
            'AC Flare 4',
            'Trace',
            'Other',
        ],
        acFindings: ['Fibrin', 'Hyphema', 'Hypopyon', 'Inverse Hypopyon', 'Other'],
    };
    private readonly irisAndLens = {
        iris: [
            'Aniridia',
            'Coloboma',
            'Corectopia',
            'Foreign body',
            'Heterochromia',
            'Iridodonesis',
            'Iris pigments',
            'Iridodialysis',
            'Koeppe nodules',
            'Mass',
            'Peripheral anterior synechiae',
            'Peripheral iridectomy',
            'Polycoria',
            'Posterior synechiae',
            'Pseudoexfoliation',
            'Rubeosis',
            'Transillumination defects',
            'YAG PI patent',
            'YAG PI not patent',
            'Other',
        ],
        lensStatus: [
            'Aphakia',
            'Dislocation',
            'Microphakia',
            'Microspherophakia',
            'Pseudoexfoliation',
            'Pseudophakia with PCO',
            'Pseudophakia without PCO',
            'Spherophakia',
            'Subluxation',
            'Other',
        ],
        cataractGrading: ['NS 1', 'NS 2', 'NS 3', 'NS 4', 'PSC 1', 'PSC 2', 'PSC 3', 'PSC 4', 'Anterior cortical cataract', 'Posterior cortical cataract', 'Other'],
        otherCataractTypes: [
            'Anterior polar cataract',
            'Complicated cataract',
            'Congenital cataract',
            'Hypermature cataract',
            'Immature cataract',
            'Posterior polar cataract',
            'Total cataract',
            'Traumatic cataract',
            'Other',
        ],
    };
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            eyelidsEyeLashesAndLacrimalSystem: this.formBuilder.group({
                upperEyelids: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                lowerEyelids: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                upperEyelashes: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                lowerEyelashes: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                lacrimalSystem: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                result: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
            }),
            conjunctivaLimbusAndSclera: this.formBuilder.group({
                conjunctiva: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                limbus: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                sclera: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                result: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
            }),
            corneaAndAnteriorChamber: this.formBuilder.group({
                cornea: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                anteriorChamberDepth: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                vanHerrickGrading: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                acCellsFlare: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                acFindings: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                result: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
            }),
            irisAndLens: this.formBuilder.group({
                iris: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                lensStatus: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                cataractGrading: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                otherCataractTypes: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
                result: this.formBuilder.group({
                    OD: '',
                    OS: '',
                }),
            }),
            images: this.formBuilder.group({
                OD: [],
                OS: [],
            }),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.remarksForm.setValue(
            {
                eyelidsEyeLashesAndLacrimalSystem: {
                    upperEyelids: {
                        OD: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD.value,
                        OS: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS.value,
                    },
                    lowerEyelids: {
                        OD: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD.value,
                        OS: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS.value,
                    },
                    upperEyelashes: {
                        OD: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD.value,
                        OS: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS.value,
                    },
                    lowerEyelashes: {
                        OD: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD.value,
                        OS: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS.value,
                    },
                    lacrimalSystem: {
                        OD: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD.value,
                        OS: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS.value,
                    },
                    result: {
                        OD: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.result.OD.value,
                        OS: this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.result.OS.value,
                    },
                },
                conjunctivaLimbusAndSclera: {
                    conjunctiva: {
                        OD: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.conjunctiva.OD.value,
                        OS: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.conjunctiva.OS.value,
                    },
                    limbus: {
                        OD: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.limbus.OD.value,
                        OS: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.limbus.OS.value,
                    },
                    sclera: {
                        OD: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.sclera.OD.value,
                        OS: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.sclera.OS.value,
                    },
                    result: {
                        OD: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.result.OD.value,
                        OS: this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.result.OS.value,
                    },
                },
                corneaAndAnteriorChamber: {
                    cornea: {
                        OD: this.test.remarks.measurements[0].corneaAndAnteriorChamber.cornea.OD.value,
                        OS: this.test.remarks.measurements[0].corneaAndAnteriorChamber.cornea.OS.value,
                    },
                    anteriorChamberDepth: {
                        OD: this.test.remarks.measurements[0].corneaAndAnteriorChamber.anteriorChamberDepth.OD.value,
                        OS: this.test.remarks.measurements[0].corneaAndAnteriorChamber.anteriorChamberDepth.OS.value,
                    },
                    vanHerrickGrading: {
                        OD: this.test.remarks.measurements[0].corneaAndAnteriorChamber.vanHerrickGrading.OD.value,
                        OS: this.test.remarks.measurements[0].corneaAndAnteriorChamber.vanHerrickGrading.OS.value,
                    },
                    acCellsFlare: {
                        OD: this.test.remarks.measurements[0].corneaAndAnteriorChamber.acCellsFlare.OD.value,
                        OS: this.test.remarks.measurements[0].corneaAndAnteriorChamber.acCellsFlare.OS.value,
                    },
                    acFindings: {
                        OD: this.test.remarks.measurements[0].corneaAndAnteriorChamber.acFindings.OD.value,
                        OS: this.test.remarks.measurements[0].corneaAndAnteriorChamber.acFindings.OS.value,
                    },
                    result: {
                        OD: this.test.remarks.measurements[0].corneaAndAnteriorChamber.result.OD.value,
                        OS: this.test.remarks.measurements[0].corneaAndAnteriorChamber.result.OS.value,
                    },
                },
                irisAndLens: {
                    iris: {
                        OD: this.test.remarks.measurements[0].irisAndLens.iris.OD.value,
                        OS: this.test.remarks.measurements[0].irisAndLens.iris.OS.value,
                    },
                    lensStatus: {
                        OD: this.test.remarks.measurements[0].irisAndLens.lensStatus.OD.value,
                        OS: this.test.remarks.measurements[0].irisAndLens.lensStatus.OS.value,
                    },
                    cataractGrading: {
                        OD: this.test.remarks.measurements[0].irisAndLens.cataractGrading.OD.value,
                        OS: this.test.remarks.measurements[0].irisAndLens.cataractGrading.OS.value,
                    },
                    otherCataractTypes: {
                        OD: this.test.remarks.measurements[0].irisAndLens.otherCataractTypes.OD.value,
                        OS: this.test.remarks.measurements[0].irisAndLens.otherCataractTypes.OS.value,
                    },
                    result: {
                        OD: this.test.remarks.measurements[0].irisAndLens.result.OD.value,
                        OS: this.test.remarks.measurements[0].irisAndLens.result.OS.value,
                    },
                },
                images: {
                    OD: this.test.remarks.measurements[0].images.OD,
                    OS: this.test.remarks.measurements[0].images.OS,
                },
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
    }
    public convertFormToModel(): void {
        const formGroupValue = this.remarksForm.value,
            user: IUser<any> & IUserFrontend = this.authenticationService.currentUser.getModel(true);

        this.test.remarks.measurements[0].images.OD = formGroupValue.images.OD;
        this.test.remarks.measurements[0].images.OS = formGroupValue.images.OS;

        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.conjunctiva.OD.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.conjunctiva.OD, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.conjunctiva.OS.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.conjunctiva.OS, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.limbus.OD.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.limbus.OD, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.limbus.OS.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.limbus.OS, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.sclera.OD.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.sclera.OD, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.sclera.OS.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.sclera.OS, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.result.OD.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.result.OD, user);
        this.test.remarks.measurements[0].conjunctivaLimbusAndSclera.result.OS.registerEdit(formGroupValue.conjunctivaLimbusAndSclera.result.OS, user);

        this.test.remarks.measurements[0].corneaAndAnteriorChamber.cornea.OD.registerEdit(formGroupValue.corneaAndAnteriorChamber.cornea.OD, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.cornea.OS.registerEdit(formGroupValue.corneaAndAnteriorChamber.cornea.OS, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.anteriorChamberDepth.OD.registerEdit(formGroupValue.corneaAndAnteriorChamber.anteriorChamberDepth.OD, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.anteriorChamberDepth.OS.registerEdit(formGroupValue.corneaAndAnteriorChamber.anteriorChamberDepth.OS, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.vanHerrickGrading.OD.registerEdit(formGroupValue.corneaAndAnteriorChamber.vanHerrickGrading.OD, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.vanHerrickGrading.OS.registerEdit(formGroupValue.corneaAndAnteriorChamber.vanHerrickGrading.OS, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.acCellsFlare.OD.registerEdit(formGroupValue.corneaAndAnteriorChamber.acCellsFlare.OD, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.acCellsFlare.OS.registerEdit(formGroupValue.corneaAndAnteriorChamber.acCellsFlare.OS, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.acFindings.OD.registerEdit(formGroupValue.corneaAndAnteriorChamber.acFindings.OD, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.acFindings.OS.registerEdit(formGroupValue.corneaAndAnteriorChamber.acFindings.OS, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.result.OD.registerEdit(formGroupValue.corneaAndAnteriorChamber.result.OD, user);
        this.test.remarks.measurements[0].corneaAndAnteriorChamber.result.OS.registerEdit(formGroupValue.corneaAndAnteriorChamber.result.OS, user);

        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD.registerEdit(formGroupValue.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD, user);
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS.registerEdit(formGroupValue.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS, user);
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD.registerEdit(formGroupValue.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD, user);
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS.registerEdit(formGroupValue.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS, user);
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD.registerEdit(
            formGroupValue.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD,
            user
        );
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS.registerEdit(
            formGroupValue.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS,
            user
        );
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD.registerEdit(
            formGroupValue.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD,
            user
        );
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS.registerEdit(
            formGroupValue.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS,
            user
        );
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD.registerEdit(
            formGroupValue.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD,
            user
        );
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS.registerEdit(
            formGroupValue.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS,
            user
        );
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.result.OD.registerEdit(formGroupValue.eyelidsEyeLashesAndLacrimalSystem.result.OD, user);
        this.test.remarks.measurements[0].eyelidsEyeLashesAndLacrimalSystem.result.OS.registerEdit(formGroupValue.eyelidsEyeLashesAndLacrimalSystem.result.OS, user);

        this.test.remarks.measurements[0].irisAndLens.iris.OD.registerEdit(formGroupValue.irisAndLens.iris.OD, user);
        this.test.remarks.measurements[0].irisAndLens.iris.OS.registerEdit(formGroupValue.irisAndLens.iris.OS, user);
        this.test.remarks.measurements[0].irisAndLens.lensStatus.OD.registerEdit(formGroupValue.irisAndLens.lensStatus.OD, user);
        this.test.remarks.measurements[0].irisAndLens.lensStatus.OS.registerEdit(formGroupValue.irisAndLens.lensStatus.OS, user);
        this.test.remarks.measurements[0].irisAndLens.cataractGrading.OD.registerEdit(formGroupValue.irisAndLens.cataractGrading.OD, user);
        this.test.remarks.measurements[0].irisAndLens.cataractGrading.OS.registerEdit(formGroupValue.irisAndLens.cataractGrading.OS, user);
        this.test.remarks.measurements[0].irisAndLens.otherCataractTypes.OD.registerEdit(formGroupValue.irisAndLens.otherCataractTypes.OD, user);
        this.test.remarks.measurements[0].irisAndLens.otherCataractTypes.OS.registerEdit(formGroupValue.irisAndLens.otherCataractTypes.OS, user);
        this.test.remarks.measurements[0].irisAndLens.result.OD.registerEdit(formGroupValue.irisAndLens.result.OD, user);
        this.test.remarks.measurements[0].irisAndLens.result.OS.registerEdit(formGroupValue.irisAndLens.result.OS, user);

        this.test.remarks.diagnosis.registerEdit(formGroupValue.diagnosis, user);
        this.test.remarks.advice.registerEdit(formGroupValue.advice, user);
        this.test.remarks.laboratoryRequested.registerEdit(formGroupValue.laboratoryRequested, user);
        this.test.remarks.medicinesPrescribed.registerEdit(formGroupValue.medicinesPrescribed, user);
    }
}
