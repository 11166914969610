import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

interface PercentileInfo {
    p25: number;
    p50: number;
    p75: number;
    mean: number;
    cov?: number;
    measurementUnits?: string;
}

interface DetailsInfo {
    latencyInfo: PercentileInfo;
    amplitudeInfo: PercentileInfo;
    peakVelInfo: PercentileInfo;
    antiSaccades: [number, string];
    proSaccades: [number, string];
    correctedAnti: [number, string];
    noResponse: [number, string];
    calibrationNA: [number, string];
    successful: number;
    errorRate: number;
    total: number;
}

export interface TrialInfo {
    trial?: number;
    type?: string;
    latency?: number;
    sn?: [number, number, number][];
    fn?: [number, number, number];
    peakVelocity?: number;
    timeCoord?: number;
    isCalibrationSuccessful?: boolean;
}

export interface ChartData {
    isRendered?: boolean;
    chartType: string;
    realTimeDataCoordinates?: [number, number, boolean][];
    dataCoordinates?: [number, number][];
    greenLinesCoordinates?: [number, number][][];
    redLinesCoordinates?: number[];
    redDotsCoordinates?: [number, number][];
    velocityDiagramCoordinates?: [number, number][];
    trialInfo?: TrialInfo[];
    data?: ICamMessage[];
    detailsInfo?: DetailsInfo;
    testDuration?: number;
    eye?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AntiSaccadeChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}
    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }
    types = ['correct-anti', 'corrected-anti', 'incorrect-pro', 'na', 'no-response'];
    private data: ICamMessage[] = [];
    realTimeStartPoint: ICamMessage | null;
    chartData$: BehaviorSubject<ChartData> = new BehaviorSubject({
        chartType: 'no-content',
    });
    isDataRendered$ = new BehaviorSubject(false);
    averageRatio$ = new BehaviorSubject(0);
    accepted: string[] = [];
    isAcceptedListChanged = false;
    testResults: ChartData | null;

    constructor() {
        super();
    }

    public addData(frames: ICamMessage[]): Promise<void> {
        this.isDataRendered$.next(false);

        const targettype = this._selectRawType(frames.find(d => d?.targettype)?.targettype);
        const data = frames.filter(el => el[targettype]);

        this.data.push(...frames.filter(d => !d.training));

        if (data.length) {
            if (!this.realTimeStartPoint) {
                this.realTimeStartPoint = data.find(d => d[targettype]);
            }

            const realTimePoints: [number, number, boolean][] = this._calculateRealTimeCoordinates(data, this.realTimeStartPoint, targettype);
            this.chartData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: realTimePoints,
                data: this.data,
            });
        } else {
            this.chartData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: [],
                data: this.data,
            });
        }

        return new Promise((res, rej) => {
            this.isDataRendered$.pipe(filter(data => Boolean(data))).subscribe(() => res());
        });
    }

    public setCamData(frames: ICamMessage[]): void {
        this.testResults = this.calculateChartResults(frames);
    }

    getChartData(frames: ICamMessage[]) {
        const dataResults = this.calculateChartResults(frames);

        this.chartData$.next(dataResults);
    }

    calculateChartResults(frames: ICamMessage[]): ChartData | null {
        const ratio = this.averageRatio$.getValue() || frames[0].ratio;
        frames = frames.filter(d => !d.training);

        if (frames[0].accepted) {
            this.accepted = JSON.parse(frames[0].accepted);
        } else {
            this.isAcceptedListChanged = true;
            this.accepted = [];
        }

        if (!frames.length) {
            console.error('Bad Data');
            return null;
        }

        if (!ratio) {
            console.error('Cannot get ratio value from pro saccade test');
            return null;
        }

        const targettype = this._selectRawType(frames[1].targettype);
        const data = frames.slice(1, frames.length - 1).filter(el => el[targettype]);

        if (data.length) {
            const testDuration = this.getXvalue(data[0].timestamp / 10000, data[data.length - 1].timestamp);
            const trials = frames.slice(-2)[0].trial;

            const [greenPoints, redCirclePoints, redPoints] = this._calculateDotsCoordinates(frames, trials);
            const [calibratedCoordinates, trialInfoTemp] = this._calculateCalibratedCoordinates(frames, ratio, trials, targettype);
            const velocityCoordinates = this._calculateVelocityCoordinates(frames, calibratedCoordinates);
            const degPerSec = velocityCoordinates.map(d => d[1]);
            const trialInfo = this._calculateTrialInfo(frames, calibratedCoordinates, degPerSec, trialInfoTemp, trials);
            const details = this.calculateDetails(trialInfo);

            return {
                eye: targettype.slice(3, 5),
                chartType: 'recorded',
                dataCoordinates: calibratedCoordinates,
                greenLinesCoordinates: greenPoints,
                redLinesCoordinates: redPoints,
                redDotsCoordinates: redCirclePoints,
                testDuration: Math.ceil(testDuration),
                velocityDiagramCoordinates: velocityCoordinates,
                detailsInfo: details,
                trialInfo,
            };
        } else {
            console.error('Bad Data');
            return null;
        }
    }

    public export() {
        if (!this.testResults) {
            console.error('No exported Data');
            return null;
        }
        const exportedResult = {
            eye: this.testResults.eye,
            trialsResults: this.testResults.trialInfo.map(d => ({
                trial: d.trial + 1,
                latency: d.latency,
                amplitude: d.fn ? d.fn?.[2] : null,
                peakVelocity: d.peakVelocity,
                type: d.type,
            })),
            details: this.testResults.detailsInfo,
        };

        return exportedResult;
    }

    public clearData(): void {
        this.accepted = [];
        this.isAcceptedListChanged = false;
        this.data = [];
        this.realTimeStartPoint = null;
        this.averageRatio$ = new BehaviorSubject(0);
        this.chartData$.next({
            chartType: 'cleared',
        });
    }

    private _calculateRealTimeCoordinates(data: ICamMessage[], realTimeStartPoint: ICamMessage, targettype: string): [number, number, boolean][] {
        const realTimePoints: [number, number, boolean][] = data.map(d => {
            const xPoint = this.getXvalue(realTimeStartPoint.timestamp / 10000, d.timestamp);
            const yRawPoint = this._calibrateRealTime(d, realTimeStartPoint[targettype], targettype);
            const yPoint = yRawPoint;

            return [xPoint, yPoint, d.training];
        });

        return realTimePoints;
    }

    private _calculateDotsCoordinates(frames: ICamMessage[], trials: number): [[number, number][][], [number, number][], number[]] {
        const data = frames.slice(1, frames.length - 1);
        let greenCoordinates: [number, number][][] = [];
        let redCircleCoordinates: [number, number][] = [];
        let redCoordinates: number[] = [];
        const start = data[0].trial;
        for (let i = start; i <= trials; i++) {
            let greenPoints: [number, number][] = data
                .filter(el => el.trial === i && el.stage === 0)
                .map(d => [this.getXvalue(data[0].timestamp / 10000, d.timestamp), this._calibrateDots(d)]);
            greenCoordinates.push(greenPoints);

            greenPoints = data.filter(el => el.trial === i && el.stage === 1).map(d => [this.getXvalue(data[0].timestamp / 10000, d.timestamp), this._calibrateDots(d)]);

            greenCoordinates.push(greenPoints);

            const redCirclePoints: [number, number][] = [
                data.findIndex(el => el.trial === i && el.stage === 0),
                i === trials ? data.length - 1 : data.findIndex(el => el.trial === i + 1 && el.stage === 0),
            ].map((d, ind) => [this.getXvalue(data[0].timestamp / 10000, ind ? data[d - 1].timestamp : data[d].timestamp + 10000000), this._calibrateDots(data[ind ? d - 1 : d])]);

            redCircleCoordinates.push(...redCirclePoints);
        }
        redCoordinates.push(0);
        redCoordinates.push(...redCircleCoordinates.map(d => d[0]));

        return [greenCoordinates, redCircleCoordinates, redCoordinates];
    }

    private _calculateCalibratedCoordinates(frames: ICamMessage[], ratio: number, trials: number, targettype: string): [[number, number][], TrialInfo[]] {
        let coordinates: [number, number][] = [];
        let trialInfo: TrialInfo[] = [];
        const data = frames.slice(1, frames.length - 1);
        let closest = data.find(d => d[targettype]);
        let calibratingPoint = data[0];
        const startTrial = data[0].trial;

        for (let i = startTrial; i <= trials; i++) {
            trialInfo.push({ trial: i - startTrial });
            const [firstInd, lastInd] = this._calculateSliceIndexes(data, i, startTrial);

            let middle = this.findMiddleCalibrated(data, i, targettype);
            calibratingPoint = middle ? middle : calibratingPoint;

            if (middle) {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    isCalibrationSuccessful: true,
                };
            } else {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    isCalibrationSuccessful: false,
                };
            }

            if (!(ratio && middle)) {
                coordinates.push(...data.slice(firstInd, lastInd).map(d => <[number, number]>[0, 0]));
                continue;
            }

            const points: [number, number][] = data.slice(firstInd, lastInd).map(d => {
                const xPoint = this.getXvalue(data[0].timestamp / 10000, d.timestamp);
                closest = d[targettype] ? d : closest;
                const yPointTemp = this._calibrateX(closest, calibratingPoint, ratio, targettype);
                const yPoint = yPointTemp > 12 || yPointTemp < -12 ? 12 * Math.sign(yPointTemp) : yPointTemp;

                return [xPoint, yPoint];
            });
            coordinates.push(...points);
        }

        return [coordinates, trialInfo];
    }

    private _calculateVelocityCoordinates(frames: ICamMessage[], calibrated: [number, number][]): [number, number][] {
        const data = frames.slice(1, frames.length - 1);
        const calibrabedX = calibrated.map(d => d[1]);
        const degpersec: number[] = [];

        for (let i = 0; i < data.length; i++) {
            const tempArray: number[] = calibrated
                .slice(i - 2 >= 0 ? i - 2 : 0, i + 3)
                .filter(d => d[1] !== null)
                .map(d => d[1]);

            const average = this.calculateAverage(tempArray);

            const timeSub = (data[i].timestamp / 10000 - data[i - 4 > 0 ? i - 5 : 0]?.timestamp / 10000) | 1;
            degpersec.push((Math.abs(average - calibrabedX[i - 4 > 0 ? i - 5 : 0]) / timeSub) * 1000);
        }

        const velPoints: [number, number][] = data
            .filter((_, ind) => ind % 1 === 0)
            .map((d, ind) => {
                const xPoint = this.getXvalue(data[0].timestamp / 10000, d.timestamp);
                const degValue = degpersec[ind];
                const yPoint = degValue || 0;

                return [xPoint, yPoint];
            });

        return velPoints;
    }

    private _calculateTrialInfo(frames: ICamMessage[], calibrated: [number, number][], degPerSec: number[], trialInfo: TrialInfo[], trials: number): TrialInfo[] {
        const data = frames.slice(1, frames.length - 1);
        const startTrial = data[0].trial;

        for (let i = startTrial; i <= trials; i++) {
            const [firstInd, lastInd] = [data.findIndex(d => d.trial === i && d.stage === 1), data.map(d => d.trial).lastIndexOf(i)];
            const temp: [number, number][] = [];
            if (trialInfo[i - startTrial].isCalibrationSuccessful) {
                for (let j = firstInd; j < lastInd; j++) {
                    if (degPerSec[j] >= 30) {
                        const first = j - 1;
                        while (degPerSec[j] >= 30) {
                            j++;
                        }
                        temp.push([first, j]);
                    }
                }
            } else {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    type: 'na',
                    fn: null,
                    peakVelocity: null,
                    latency: null,
                };
            }
            const elem = data.find(d => d.trial === i);

            trialInfo[i - startTrial] = {
                ...trialInfo[i - startTrial],
                timeCoord: this.getXvalue(data[0].timestamp / 10000, elem.timestamp) + 1,
            };
            const tempInfo = [];
            if (temp.length) {
                let latency = this.getXvalue(data[firstInd - 1].timestamp / 10000, data[temp[0][0] - 1].timestamp) * 1000;
                for (let j = 0; j < temp.length; j++) {
                    const [first, last] = [temp[j][0], temp[j][1]];

                    if (!j) {
                        const fN: [number, number, number] = [
                            calibrated[last - 1][0],
                            calibrated[last - 1][1],
                            Math.abs((calibrated[last - 1][1] / this._calibrateDots(data[last - 1])) * 100),
                        ];
                        const peakVelocity: number = Math.max(...degPerSec.slice(first - 1, last + 1));

                        trialInfo[i - startTrial] = {
                            ...trialInfo[i - startTrial],
                            fn: fN,
                            peakVelocity: peakVelocity,
                            latency: latency,
                        };
                    }

                    if (this.checkAntiSaccade(Math.sign(data[last].targetDotX), calibrated[last][1])) {
                        tempInfo.push('anti');
                    } else if (Math.abs(calibrated[last][1]) >= 3) {
                        tempInfo.push('pro');
                    } else if (calibrated[last][1] > -3 && calibrated[last][1] < 3) {
                        tempInfo.push('no');
                    }

                    if (!j && tempInfo[j] === 'anti') {
                        trialInfo[i - startTrial] = {
                            ...trialInfo[i - startTrial],
                            type: 'correct-anti',
                        };
                        break;
                    } else if (j && tempInfo[0] === 'pro' && tempInfo[j] === 'anti') {
                        trialInfo[i - startTrial] = {
                            ...trialInfo[i - startTrial],
                            type: 'corrected-anti',
                        };
                        break;
                    }
                }

                if (tempInfo.length !== trials - startTrial + 1 && trialInfo[i - startTrial]?.type) {
                    continue;
                }

                if (tempInfo[0] === 'pro' && tempInfo.slice(1).every(d => d !== 'anti')) {
                    trialInfo[i - startTrial] = {
                        ...trialInfo[i - startTrial],
                        type: 'incorrect-pro',
                    };
                    continue;
                } else {
                    trialInfo[i - startTrial] = {
                        ...trialInfo[i - startTrial],
                        type: 'no-response',
                        fn: null,
                        peakVelocity: null,
                        latency: null,
                    };
                }
            } else {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    type: 'no-response',
                    fn: null,
                    peakVelocity: null,
                    latency: null,
                };
            }
        }

        return trialInfo;
    }

    private calculateDetails(trialInfo: TrialInfo[]): DetailsInfo {
        if (this.accepted.length) {
            this.accepted.forEach((d, ind) => {
                trialInfo[ind].type = d;
            });
        }

        const trials = trialInfo.length;
        const successfulTrials = trialInfo.filter(d => d.isCalibrationSuccessful && d.type !== 'na' && d.type !== 'no-response');
        const successfulTrialsLength = successfulTrials.length;
        const unsuccessfulTrials = trialInfo.filter(d => d.type === 'na').length;

        const antiSaccade = trialInfo.filter(d => d.type === 'correct-anti').length;
        const correctedAnti = trialInfo.filter(d => d.type === 'corrected-anti').length;
        const proSaccade = trialInfo.filter(d => d.type === 'incorrect-pro').length;
        const noResponse = trialInfo.filter(d => d.type === 'no-response').length;

        const latencyArray = successfulTrials.map(d => d.latency).sort((a, b) => a - b);
        const amplitudeArray = successfulTrials.map(d => d.fn?.[2]).sort((a, b) => a - b);
        const peakVelArray = successfulTrials.map(d => d.peakVelocity).sort((a, b) => a - b);

        const [latencyMean, amplitudeMean, peakVelMean] = [this.calculateAverage(latencyArray), this.calculateAverage(amplitudeArray), this.calculateAverage(peakVelArray)];

        const [latencySD, amplitudeSD, peakVelSD] = [this.calculateSD(latencyArray), this.calculateSD(amplitudeArray), this.calculateSD(peakVelArray)];

        const latencyInfo: PercentileInfo = {
            p25: this.getPercentile(latencyArray, 25),
            p50: this.getPercentile(latencyArray, 50),
            p75: this.getPercentile(latencyArray, 75),
            mean: latencyMean,
            cov: latencySD / latencyMean,
            measurementUnits: 'ms',
        };

        const amplitudeInfo: PercentileInfo = {
            p25: this.getPercentile(amplitudeArray, 25),
            p50: this.getPercentile(amplitudeArray, 50),
            p75: this.getPercentile(amplitudeArray, 75),
            mean: amplitudeMean,
            cov: amplitudeSD / amplitudeMean,
            measurementUnits: '%',
        };

        const peakVelInfo: PercentileInfo = {
            p25: this.getPercentile(peakVelArray, 25),
            p50: this.getPercentile(peakVelArray, 50),
            p75: this.getPercentile(peakVelArray, 75),
            mean: peakVelMean,
            cov: peakVelSD / peakVelMean,
            measurementUnits: 'deg/s',
        };

        return {
            latencyInfo,
            amplitudeInfo,
            peakVelInfo,
            antiSaccades: [antiSaccade, ((antiSaccade / trials) * 100).toFixed(0)],
            proSaccades: [proSaccade, ((proSaccade / trials) * 100).toFixed(0)],
            correctedAnti: [correctedAnti, ((correctedAnti / trials) * 100).toFixed(0)],
            noResponse: [noResponse, ((noResponse / trials) * 100).toFixed(0)],
            calibrationNA: [unsuccessfulTrials, ((unsuccessfulTrials / trials) * 100).toFixed(0)],
            successful: successfulTrialsLength,
            errorRate: ((successfulTrialsLength - antiSaccade) / successfulTrialsLength) * 100,
            total: trials,
        };
    }

    private _selectRawType(targettype: number): 'rawODx' | 'rawOSx' {
        return targettype === 1 ? 'rawOSx' : 'rawODx';
    }

    private _calibrateDots(raw: ICamMessage): number {
        if (raw.stage === 1) {
            return raw.targetDotX;
        }
        return 0;
    }

    private _calibrateX(current: ICamMessage, raw: ICamMessage, ratio: number, targettype: string): number {
        const rawDeg = this._calibrateDots(raw);
        const rawX = raw[targettype];

        const calibratedX = rawDeg + (current[targettype] - rawX) / ratio;

        return calibratedX;
    }

    private _calculateSliceIndexes(data: ICamMessage[], trial: number, startTrial: number = 0): number[] {
        const currentTrialFirstSnapshot = data.find(d => d.trial === trial);
        const nextTrialFirstSnapshot = data.find(d => d.trial === trial + 1);

        if (trial === startTrial) {
            return [
                0,
                data.findIndex(
                    d =>
                        d.trial === trial + 1 &&
                        Math.abs(d.timestamp / 10000 - nextTrialFirstSnapshot.timestamp / 10000) > 975 &&
                        Math.abs(d.timestamp / 10000 - nextTrialFirstSnapshot.timestamp / 10000) < 1025
                ),
            ];
        }

        if (trial === data[data.length - 1].trial) {
            return [
                data.findIndex(
                    d =>
                        d.trial === trial &&
                        Math.abs(d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000) > 975 &&
                        Math.abs(d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000) < 1025
                ),
                data.length,
            ];
        }

        return [
            data.findIndex(
                d =>
                    d.trial === trial &&
                    Math.abs(d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000) > 975 &&
                    Math.abs(d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000) < 1025
            ),
            data.findIndex(
                d =>
                    d.trial === trial + 1 &&
                    Math.abs(d.timestamp / 10000 - nextTrialFirstSnapshot.timestamp / 10000) > 975 &&
                    Math.abs(d.timestamp / 10000 - nextTrialFirstSnapshot.timestamp / 10000) < 1025
            ),
        ];
    }

    private _calibrateRealTime(current: ICamMessage, startPoint: number, targettype: string): number {
        const currentRaw = current[targettype];

        return 0.3 * (currentRaw - startPoint);
    }

    findMiddleCalibrated(data: ICamMessage[], trial: number, targettype: string): ICamMessage | null {
        const currentTrialFirstSnapshot = data.find(d => d.trial === trial && d.stage === 0);
        const result = data.find(
            d =>
                d.trial === trial &&
                d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000 > 975 &&
                d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000 < 1025
        );

        return result[targettype] ? result : null;
    }

    private checkAntiSaccade(direction: number, value: number): boolean {
        if (direction === 1) {
            return !!(value <= -3);
        }
        if (direction === -1) {
            return !!(value >= 3);
        }

        return false;
    }

    updateDetails(trialIndex: number, type: string): void {
        const chartDataValue = this.chartData$.getValue();

        if (!this.accepted.length) {
            this.accepted = chartDataValue.trialInfo.map(d => d.type);
        }

        this.isAcceptedListChanged = true;

        this.accepted[trialIndex] = type;

        chartDataValue.trialInfo[trialIndex] = {
            ...chartDataValue.trialInfo[trialIndex],
            type: this.accepted[trialIndex],
        };

        const updatedDetails = this.calculateDetails(chartDataValue.trialInfo);

        this.chartData$.next({
            ...chartDataValue,
            detailsInfo: updatedDetails,
        });
    }
}
