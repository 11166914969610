// @ts-nocheck
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TranslateService } from '../../../../_services/general/translate.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { Diagram } from './diagram/Diagram';

@Component({
    selector: 'convergence-test-chart',
    template: require('./convergence-test-chart.component.html'),
    styles: [require('../charts.scss')],
})
export class ConvergenceTestChartComponent extends BulbicamChartComponent implements OnInit {
    @ViewChild('vergenceChart') vergenceChart: ElementRef;

    diagram: Diagram;

    setEdits(x:any) {}

    constructor(private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        let params = {
          svg: this.vergenceChart.nativeElement
        }
        this.diagram = new Diagram(params, this.translateService, this.modalChart);

        if (this.inputData) {
            this.addData(this.inputData);
        }
    }
    addData(frames: any) {
        this.diagram.addData(frames);
    }
    clearData() {
        this.diagram.clearState();
    }
}
