import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { BulbicamChartComponent } from '../_components/tests/charts/haploChart.component';
import { TestFrontend } from '../_models/tests/testFrontend.class';

@Directive({
    selector: '[test-chart]',
})
export class TestChartDirective implements OnInit {
    @Input() test: TestFrontend;
    public instance: BulbicamChartComponent;
    constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}
    ngOnInit(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<BulbicamChartComponent>(this.test.bulbicamChartComponent);
        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent<BulbicamChartComponent>(componentFactory);
        this.instance = componentRef.instance;
    }
}
