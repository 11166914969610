// @ts-nocheck
// import * as _ from 'lodash'
// import { Target } from './types'

interface InTime {
  timestamp: number
}

export class DrawAnimated {
  interval = 10
  timeScale = 5

  destroyed = false

  constructor(
    private points: InTime[],
    private draw: (xs: InTime[]) => boolean,
  ) {
  }
  startTimeAbs = Math.min(...this.points.map(x => x.timestamp)) || 0
  stopDelta = (Math.max(...this.points.map(x => x.timestamp)) || 0) - this.startTimeAbs

  animationStartedAt!: number

  start() {
    if (this.destroyed) return
    this.animationStartedAt = this.currentTime()
    this.requestTick()
  }

  destroy() {
    this.destroyed = true
  }

  private tick() {
    if (this.destroyed) return
    let delta = this.animationDelta()
    let border = this.startTimeAbs + delta
    let points = this.points.filter(x => x.timestamp <= border)

    if (!this.draw(points)) return // done
    if (points.length === this.points.length) {
      // done
    } else {
      this.requestTick()
    }
  }

  // render(points: InTime[]) {
  // }

  private requestTick() {
    setTimeout(() => { this.tick() }, this.interval)
  }
  private animationDelta() {
    let delta = this.currentTime() - this.animationStartedAt
    return delta / this.timeScale
  }
  private currentTime() {
    return +new Date()
  }
}
