import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { PachymetryTonorefIIIMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/TonorefIII/pachymetryTonorefIIIMeasuredData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { PachymetryTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/pachymetryTonorefIIITestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class PachymetryTonorefIIIMeasuredDataFrontend extends PachymetryTonorefIIIMeasuredData implements MeasuredDataFrontend {
    public remarks: PachymetryTonorefIIITestRemarksFrontend;
    public OD: RegisterEditFieldFrontend<string>;
    public OS: RegisterEditFieldFrontend<string>;
    constructor() {
        super();
        this.OD = new RegisterEditFieldFrontend();
        this.OS = new RegisterEditFieldFrontend();
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table = [];
        table.push({
            microns: '',
            OD: 'OD',
            OS: 'OS',
        });
        table.push({
            microns: 'Average central corneal thickness (microns)',
            OD: this.OD.value,
            OS: this.OS.value,
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: <CellHookHandler>(data => {
                if (data.section === 'head') {
                    if (data.row.index === 0 || data.row.index === 1) {
                        data.cell.styles.fillColor = [74, 96, 117];
                    }
                }
            }),
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values = this.OD.value || 'x';
        formattedTest.OS.values = this.OS.value || 'x';
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
