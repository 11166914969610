import * as jsPDF from 'jspdf';
import { IPupillaryEvaluationNidekTonorefIIIMeasuredData } from '../../../../../../../common/interfaces/measuredData/NIDEK/TonorefIII/pupillaryEvaluation-nidek-tonorefIII.measuredData.interface';
import { PupillaryEvaluationTonorefIIIMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIIMeasuredData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { PupillaryEvaluationTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIITestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class PupillaryEvaluationTonorefIIIMeasuredDataFrontend extends PupillaryEvaluationTonorefIIIMeasuredData implements MeasuredDataFrontend {
    public remarks: PupillaryEvaluationTonorefIIITestRemarksFrontend;
    public OD: {
        pupilSize: {
            type: RegisterEditFieldFrontend<string>;
            sizeValue: RegisterEditFieldFrontend<string>;
        };
        reactionToLight: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
        rapo: {
            type: RegisterEditFieldFrontend<string>;
        };
        pupilMorphology: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
    };
    public OS: {
        pupilSize: {
            type: RegisterEditFieldFrontend<string>;
            sizeValue: RegisterEditFieldFrontend<string>;
        };
        reactionToLight: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
        rapo: {
            type: RegisterEditFieldFrontend<string>;
        };
        pupilMorphology: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
    };
    constructor() {
        super();
        this.OD = {
            pupilSize: {
                type: new RegisterEditFieldFrontend(),
                sizeValue: new RegisterEditFieldFrontend(),
            },
            reactionToLight: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
            rapo: {
                type: new RegisterEditFieldFrontend(),
            },
            pupilMorphology: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
        };
        this.OS = {
            pupilSize: {
                type: new RegisterEditFieldFrontend(),
                sizeValue: new RegisterEditFieldFrontend(),
            },
            reactionToLight: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
            rapo: {
                type: new RegisterEditFieldFrontend(),
            },
            pupilMorphology: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
        };
    }
    public setModel(model: IPupillaryEvaluationNidekTonorefIIIMeasuredData) {
        this.OD.pupilSize.type.setModel(model.OD.pupilSize.type);
        this.OD.pupilSize.sizeValue.setModel(model.OD.pupilSize.sizeValue);
        this.OD.reactionToLight.type.setModel(model.OD.reactionToLight.type);
        this.OD.reactionToLight.select.setModel(model.OD.reactionToLight.select);
        this.OD.rapo.type.setModel(model.OD.rapo.type);
        this.OD.pupilMorphology.type.setModel(model.OD.pupilMorphology.type);
        this.OD.pupilMorphology.select.setModel(model.OD.pupilMorphology.select);
        this.OS.pupilSize.type.setModel(model.OS.pupilSize.type);
        this.OS.pupilSize.sizeValue.setModel(model.OS.pupilSize.sizeValue);
        this.OS.reactionToLight.type.setModel(model.OS.reactionToLight.type);
        this.OS.reactionToLight.select.setModel(model.OS.reactionToLight.select);
        this.OS.rapo.type.setModel(model.OS.rapo.type);
        this.OS.pupilMorphology.type.setModel(model.OS.pupilMorphology.type);
        this.OS.pupilMorphology.select.setModel(model.OS.pupilMorphology.select);
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table = [];
        table.push({
            column1: '',
            column2: { colSpan: 2, content: 'OD', styles: { halign: 'center' } },
            column3: '',
            column4: { colSpan: 2, content: 'OS', styles: { halign: 'center' } },
            column5: '',
        });
        table.push({
            column1: 'Size mm (ARK1S)',
            column2: this.OD.pupilSize.type.value,
            column3: this.OD.pupilSize.sizeValue.value,
            column4: this.OS.pupilSize.type.value,
            column5: this.OS.pupilSize.sizeValue.value,
        });
        table.push({
            column1: 'Reaction to Light',
            column2: this.OD.reactionToLight.type.value,
            column3: this.OD.reactionToLight.select.value,
            column4: this.OS.reactionToLight.type.value,
            column5: this.OS.reactionToLight.select.value,
        });
        table.push({
            column1: 'RAPD',
            column2: this.OD.rapo.type.value,
            column4: this.OS.rapo.type.value,
        });
        table.push({
            column1: 'Pupil Morphology',
            column2: this.OD.pupilMorphology.type.value,
            column3: this.OD.pupilMorphology.select.value,
            column4: this.OS.pupilMorphology.type.value,
            column5: this.OS.pupilMorphology.select.value,
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values = '' + this.OD.pupilSize.sizeValue.value || 'x';
        formattedTest.OS.values = '' + this.OS.pupilSize.sizeValue.value || 'x';
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
