import { ThreeDScanIvueOCTv3217MeasuredDataFrontend } from '../../../measuredData/IVUE/OCT/3dscanIvueOctv3217DataFrontend';
import { TestRemarkFrontend } from '../../testRemarkFrontend';

export class ThreeDScanIcueOctv3217TestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof ThreeDScanIvueOCTv3217MeasuredDataFrontend = ThreeDScanIvueOCTv3217MeasuredDataFrontend;
    public measurements: ThreeDScanIvueOCTv3217MeasuredDataFrontend[];
    constructor() {
        super();
    }
}
