import { Component } from '@angular/core';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'common-start-stop-control',
    template: require('./common-start-stop-control.component.html'),
    styles: [require('./common-start-stop-control.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class CommonStartStopControlComponent extends BulbicamControlComponent {
    public enable(): void {}
    public disable(): void {}
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService) {
        super(bulbicamService, configService, socketService, authService);
    }
    private start(): void {
        this.pendingResponse = true;
        this.bulbicamService.sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, null).subscribe(() => {
            this.test.status = TEST_STATUS.IN_PROGRESS;
            this.pendingResponse = false;
        });
    }
}
