// @ts-nocheck
export const pause = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 14H9V6H7V14ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM11 14H13V6H11V14Z" fill="#DCE2E1"/>
</svg>`;

export const play = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 14.5L14 10L8 5.5V14.5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#DCE2E1"/>
</svg>`;

export const getAxis = (dots, data, top = 1050) => {
  dots.selectAll('.dots-init').remove();
  if(data.length < 1) return;

  const l = 6, lb = 12, step = 20.8, delim = 4;
  const arr = new Array(25).fill(1);

  const co: any = [
    {x1: 800, y1: top, x2: 800, y2: top + 500},
    {x1: 550, y1: top+250, x2: 1050, y2: top+250},
    {x1: 1500, y1: top, x2: 1500, y2: top + 500},
    {x1: 1250, y1: top+250, x2: 1750, y2: top+250}
  ];

  const range = {
    od: {
      x: [co[1].x1, co[1].x2], 
      y: [top, top + 500]
    },
    os: {
      x: [co[3].x1, co[3].x2], 
      y: [top, top + 500]
    }
  };

  for(let i = 0; i < arr.length; i++) {
    if(i % delim === 0) {
      co.push({x1: co[0].x1-lb, y1: co[0].y1+(i*step), x2: co[0].x2+lb, y2: co[0].y1+(i*step)});
      co.push({x1: co[1].x1+(i*step), y1: co[1].y1-lb, x2: co[1].x1+(i*step), y2: co[1].y1+lb});
      co.push({x1: co[2].x1-lb, y1: co[2].y1+(i*step), x2: co[2].x2+lb, y2: co[2].y1+(i*step)});
      co.push({x1: co[3].x1+(i*step), y1: co[3].y1-lb, x2: co[3].x1+(i*step), y2: co[3].y1+lb});
    }
    else {
      co.push({x1: co[0].x1-l, y1: co[0].y1+(i*step), x2: co[0].x2+l, y2: co[0].y1+(i*step)});
      co.push({x1: co[1].x1+(i*step), y1: co[1].y1-l, x2: co[1].x1+(i*step), y2: co[1].y1+l});
      co.push({x1: co[2].x1-l, y1: co[2].y1+(i*step), x2: co[2].x2+l, y2: co[2].y1+(i*step)});
      co.push({x1: co[3].x1+(i*step), y1: co[3].y1-l, x2: co[3].x1+(i*step), y2: co[3].y1+l});
    }
  }

  for(let i = 0; i < co.length; i++) {
    let one = dots.append('line').attr('class', 'dots-init').attr('stroke', '#ffffff');
    Object.keys(co[i]).forEach(k => one.attr(k, co[i][k]));
  }

  const getTitle = (x: number, y: number, text: string, fz: number) => {
    dots.append('text')
      .attr('x', x-15)
      .attr('y', y-10)
      .attr('class', 'dots-init')
      .style('font-family', 'DM Sans')
      .style('font-weight', 400)
      .style('font-size', fz+'px')
      .text(text);
  }

  getTitle(co[0].x1, co[0].y1, 'OD', 22);
  getTitle(co[2].x1, co[2].y1, 'OS', 22);
  getTitle(co[1].x1-30, co[1].y1+30, 'Horizontal', 12);
  getTitle(co[3].x1-30, co[3].y1+30, 'Horizontal', 12);
  getTitle(co[0].x1-45, co[0].y1+20, 'Vertical', 12);
  getTitle(co[2].x1-45, co[2].y1+20, 'Vertical', 12);

  dots.append('text')
    .attr('class', 'dots-init')
    .attr('x', co[1].x2+45)
    .attr('y', co[1].y1-70)
    .text('OD '+ data.length + ' samples')
  dots.append('text')
    .attr('class', 'dots-init')
    .attr('x', co[1].x2+45)
    .attr('y', co[1].y1-50)
    .text('OS '+ data.length + ' samples')

  const time = dots.append('text')
    .attr('class', 'dots-init')
    .attr('x', co[1].x2+70)
    .attr('y', co[1].y1+60)
    .text('0.00 sec')

  const playButton = dots.append('g')
    .attr('class', 'dots-init').html(play)
    .attr('transform', `translate(${co[1].x2+70}, ${co[1].y2-30}) scale(3)`)

  const playRect = dots.append('rect').attr('class', 'dots-init')
    .attr('x', co[1].x2+70).attr('y', co[1].y2-30)
    .attr('width', 60).attr('height', 60)
    .style('cursor', 'pointer').style('opacity', 0);

  return {time, range, playButton, playRect};
}

export const delay = (time: number) => {
  return new Promise(resolve => setTimeout(() => resolve(), time));
}