import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../../../commonout/enum/calibration.command.enum';
import { ICalibrationResult } from '../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { IPatternCalibration } from '../../../../../../../../commonout/interfaces/calibration/patternCalibration.interface';
import { SmartImageFrontend } from '../../../../_models/smartImageFrontend.class';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { FileService } from '../../../../_services/general/file.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { CalibrationTestComponent } from '../calibration-test-component';
import { mockDataTest2 } from '../mockData';

@Component({
    selector: 'pattern-test',
    template: require('./pattern-test.component.html'),
    styles: [require('./pattern-test.component.scss')],
})
export class PatternTestComponent extends CalibrationTestComponent {
    customOnDestroy(): void {}

    calibrationType: CALIBRATION_TEST = CALIBRATION_TEST.PATTERN_TEST;
    isTestDone: boolean = false;
    selectedIndex = -1;
    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.DATA_PACKAGE) {
            const smartImage: SmartImageFrontend = new SmartImageFrontend();
            smartImage.model = data.smartImage;
            this.image.setValue(smartImage);

            this.unSavedResult = {
                createdAt: +new Date(),
                image: smartImage,
            };
        }

        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.isTestRunning = false;
            this.isTestDone = true;
            this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        }
    }
    subscriptions: Subscription[] = [];
    image: FormControl;
    isTestRunning = false;

    constructor(bulbicamService: BulbicamService, socketService: SocketService, private fileService: FileService, configService: ConfigService) {
        super(bulbicamService, socketService, configService);
        this.image = new FormControl();

        const subscriber = this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.PATTERN_TEST).subscribe(res => {
            
            this.results = res.map((el: ICalibrationResult<IPatternCalibration>) => {
                return {
                    createdAt: el.createdAt,
                    result: {
                        patternImage: el.results.patternImage,
                    },
                };
            });
        });
        this.subscriptions.push(subscriber);
    }

    mockData: { date: Date; bpdpPair: [number, number][] }[] = mockDataTest2;
    results: {
        createdAt: number;
        result: IPatternCalibration;
    }[] = [];

    unSavedResult: {
        image: any;
        createdAt: number;
    };

    toggleTest(): void {
        if (this.isTestRunning) {
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: CALIBRATION_TEST.PATTERN_TEST,
                    command: CALIBRATION_COMMAND.STOP,
                })
                .subscribe();
            this.subscriptions.push(sub);
        } else {
            this.socketService.socket.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);

            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: CALIBRATION_TEST.PATTERN_TEST,
                    command: CALIBRATION_COMMAND.START,
                })
                .subscribe();
            this.subscriptions.push(sub);
        }

        this.isTestRunning = !this.isTestRunning;
        this.isTestDone = false;
        this.clearTempResult();
    }

    saveResults(): void {
        const smartImage: SmartImageFrontend = new SmartImageFrontend();

        smartImage.model = this.unSavedResult.image;
        smartImage.saveToDb(this.fileService);
        const sub = this.bulbicamService
            .saveCalibrationSetting({
                test: this.calibrationType,
                createdAt: +new Date(),
                results: {
                    patternImage: this.unSavedResult.image,
                },
            })
            .pipe(
                concatMap(_ => {
                    return this.bulbicamService.getCalibrationSettings(this.calibrationType);
                })
            )
            .subscribe(res => {
                this.results = res.map((el: ICalibrationResult<IPatternCalibration>) => {
                    return {
                        createdAt: el.createdAt,
                        result: {
                            patternImage: el.results.patternImage,
                        },
                    };
                });
            });
        this.subscriptions.push(sub);

        this.isTestDone = false;
        this.unSavedResult = null;
    }

    selectActive(index: number): void {
        this.selectedIndex = index;

        const smartImage: SmartImageFrontend = new SmartImageFrontend();

        smartImage.model = this.results[index].result.patternImage;
        smartImage.download(this.fileService).then(_ => {
            this.image.setValue(smartImage);
        });
    }

    clearTempResult() {
        if (this.unSavedResult) {
            this.unSavedResult = null;
        }
    }

    // deleteResult(index: number) {
    //     this.bulbicamService.deleteCalibrationSetting(
    //         this.calibrationType, this.results[index].createdAt
    //     ).pipe(
    //         concatMap(_ => {
    //             this.image.reset();
    //             this.image.setValue(null)
    //             return this.bulbicamService.getCalibrationSettings(this.calibrationType)
    //         })
    //     ).subscribe(res => {
    //         this.results = res.map((el: ICalibrationResult<IPatternCalibration>) => {
    //             return {
    //                 createdAt: el.createdAt,
    //                 result: {
    //                     patternImage: el.results.patternImage
    //                 }
    //             }
    //         });
    //     })
    // }
}
