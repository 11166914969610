import { PostSegmentForus3NethraClassicTest } from '../../../../../../../../commonout/classes/tests/FORUS/3NethraClassic/postSegmentForus3NethraClassicTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PostSegmentForusTestRemarksComponent } from '../../../../_components';
import { PostSegmentForus3NethraClassicTestRemarksFrontend } from '../../../test-remarks/FORUS/3NethraClassic/postSegmentForus3NathraClassicTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PostSegmentForus3NethraClassicTestFrontend extends PostSegmentForus3NethraClassicTest {
    remarks: PostSegmentForus3NethraClassicTestRemarksFrontend;
    public readonly remarksComponent: typeof PostSegmentForusTestRemarksComponent = PostSegmentForusTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PostSegmentForus3NethraClassicTestRemarksFrontend();
    }
}

export interface PostSegmentForus3NethraClassicTestFrontend extends TestFrontend {}
applyMixins(PostSegmentForus3NethraClassicTestFrontend, [TestFrontend]);
