import { MeasuredData } from '../../measuredData.model';
import { IAntSegmentForus3NethraClassicMeasuredData } from '../../../../interfaces/measuredData/FORUS/3NethraClassic/ant-segment-forus3Nethra-classic.measuredData.interface';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { SmartImage } from '../../../smartImage.class';
import { TestRemarks } from '../../../testRemark.class';

export abstract class AntSegmentForus3NethraClassicMeasuredData implements MeasuredData, IAntSegmentForus3NethraClassicMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: SmartImage[];
    public OS: SmartImage[];
    public remarks: TestRemarks;
    constructor() {
        this.sourceDevice = DEVICE.FORUS_3NETHRA;
        this.testType = TEST_TYPE.ANTERIOR_SEGMENT_EVALUATION;
    }
    public abstract setModel(model: IAntSegmentForus3NethraClassicMeasuredData): void;
    public getModel(): IAntSegmentForus3NethraClassicMeasuredData {
        let model: IAntSegmentForus3NethraClassicMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.map((si) => si.model),
            OS: this.OS.map((si) => si.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.length === 0 && this.OS.length === 0;
    }
}
