import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CalibrationChartService {
    generateChartData(maxValue: number = 1, arrayLength: number = 100): {timestamp: number, rawValue: number}[] {
        const date = +(new Date()) - 100;
        return Array.from({length: arrayLength}, (_, i) => i)
            .map(d => ({
                    timestamp: date + d,
                    rawValue: Math.random() * maxValue
                }
            )
        );
    }

    calibrateData(array: {timestamp: number, rawValue: number}[]): {timestamp: number, rawValue: number}[] {
        const timeFirstValue = array[0].timestamp;
        return array.map(d => ({
                timestamp: (d.timestamp - timeFirstValue) / array.length,
                rawValue: d.rawValue
            })
        );
    }
}


