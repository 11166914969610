import { DEVICE } from '../../enum/device';
import { TEST_TYPE } from '../../enum/test-type';
import { TEST_STAGE } from '../../enum/testStage.enum';

export class ColorTest {
    public readonly type: TEST_TYPE = TEST_TYPE.COLOUR_VISION_TEST;
    public readonly device: DEVICE = DEVICE.DEFAULT_TEST;
    public readonly name: string = 'Colour vision';
    public stage: TEST_STAGE;
}
