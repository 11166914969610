// @ts-nocheck
export type FromTo = { from: number; to: number };
type DirectionOptions = { [name: string]: FromTo[] };

// intervals including both values [from, to]
// points can be in multiple categories
// multiple intervals can be used

export const directionOptionsOD: DirectionOptions = {
    // 'All directions': [{from: -1000, to: 1000}],
    LateralOD: [{ from: -1, to: -1 }],
    MedialOD: [{ from: 1, to: 1 }],
    UpwardsOD: [{ from: 3, to: 3 }],
    DownwardsOD: [{ from: 2, to: 2 }],
    DiagonalOD: [{ from: 0, to: 0 }],
    'STD indicators OD': [{ from: 5, to: 5 }],
    GreenOD: [{ from: 4, to: 4 }],
    Blue2OD: [{ from: 6, to: 6 }],
    PurpleOD: [{ from: 7, to: 7 }],
};

export const directionOptionsOS: DirectionOptions = {
    // 'All directions': [{from: -1000, to: 1000}],
    LateralOS: [{ from: -1, to: -1 }],
    MedialOS: [{ from: 1, to: 1 }],
    UpwardsOS: [{ from: 3, to: 3 }],
    DownwardsOS: [{ from: 2, to: 2 }],
    DiagonalOS: [{ from: 0, to: 0 }],
    'STD indicators OS': [{ from: 5, to: 5 }],
    GreenOS: [{ from: 4, to: 4 }],
    Blue2OS: [{ from: 6, to: 6 }],
    PurpleOS: [{ from: 7, to: 7 }],
};
