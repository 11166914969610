export class AiDataHelperClass {
    public formatAi(data: any): string {
        return (
            '<span style="color:' +
            this.getDetails(data.refdr.result).color +
            '">' +
            (data.refdr.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.dr.result).color +
            '">' +
            (data.dr.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.glaucoma.result).color +
            '">' +
            (data.glaucoma.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.cataract.result).color +
            '">' +
            (data.cataract.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.dme.result).color +
            '">' +
            (data.dme.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.amd.result).color +
            '">' +
            (data.amd.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.highmyopia.result).color +
            '">' +
            (data.highmyopia.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.stroke.result).color +
            '">' +
            (data.stroke.confidence % 101 || 'n/a') +
            '</span> <span style="color:' +
            this.getDetails(data.gh.result).color +
            '">' +
            (data.gh.confidence % 101 || 'n/a')
        );
    }
    public getDetails(result: string) {
        switch (result) {
            case 'r0':
                return {
                    color: '#00d1b2',
                    result: 'No apparent retinopathy',
                    analysis: false,
                };
            case 'r1':
                return {
                    color: '#d42e50',
                    result: 'Mild NPDR',
                    analysis: true,
                };
            case 'r2':
                return {
                    color: '#d42e50',
                    result: 'Moderate NPDR',
                    analysis: true,
                };
            case 'r3':
                return {
                    color: '#d42e50',
                    result: 'Severe NPDR',
                    analysis: true,
                };
            case 'r4':
                return {
                    color: '#d42e50',
                    result: 'Severe PDR',
                    analysis: true,
                };
            case 'positive':
                return {
                    color: '#d42e50',
                    result: result,
                    analysis: true,
                };
            case 'ungradable':
                return {
                    color: '#999999',
                    result: result,
                    analysis: false,
                };
            default:
                return {
                    color: '#00d1b2',
                    result: result,
                    analysis: false,
                };
        }
    }
}
