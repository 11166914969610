import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KeratometryTonorefIIITestFrontend } from '../../../../../../_models/tests/NIDEK/TonorefIII/keratometryTonorefIIITestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'keratometry-tonorefIII-test-remarks',
    template: require('./keratometry-tonorefIII-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./keratometry-tonorefIII-test-remarks.component.scss')],
})
export class KeratometryTonorefIIITestRemarksComponent extends TestRemarksComponent {
    test: KeratometryTonorefIIITestFrontend;
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            dataBlock: this.formBuilder.array([]),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map(m => m.downloadMediaData(this.fileService)));
        this.remarksForm.patchValue(
            {
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
        this.remarksForm.setControl(
            'dataBlock',
            this.formBuilder.array(
                this.test.remarks.measurements.map(m => {
                    return this.formBuilder.group({
                        sourceFileName: { value: m.sourceFileName === null ? '' : m.sourceFileName, disabled: true },
                        OD: this.formBuilder.group({
                            KM: this.formBuilder.array(
                                m.OD.KM.map(odkm => {
                                    return this.formBuilder.group({
                                        diameter: { value: odkm.diameter.value, disabled: true },
                                        R1Median: this.formBuilder.group({
                                            mm: { value: odkm.R1Median.mm.value, disabled: true },
                                            diopter: { value: odkm.R1Median.diopter.value, disabled: true },
                                            axis: { value: odkm.R1Median.axis.value, disabled: true },
                                        }),
                                        R2Median: this.formBuilder.group({
                                            mm: { value: odkm.R2Median.mm.value, disabled: true },
                                            diopter: { value: odkm.R2Median.diopter.value, disabled: true },
                                            axis: { value: odkm.R2Median.axis.value, disabled: true },
                                        }),
                                    });
                                })
                            ),
                            corneal: this.formBuilder.array(
                                m.OD.corneal.map(odc => {
                                    return this.formBuilder.group({
                                        number: { value: odc.number.value, disabled: true },
                                        size: { value: odc.size.value, disabled: true },
                                    });
                                })
                            ),
                        }),
                        OS: this.formBuilder.group({
                            KM: this.formBuilder.array(
                                m.OS.KM.map(oskm => {
                                    return this.formBuilder.group({
                                        diameter: { value: oskm.diameter.value, disabled: true },
                                        R1Median: this.formBuilder.group({
                                            mm: { value: oskm.R1Median.mm.value, disabled: true },
                                            diopter: { value: oskm.R1Median.diopter.value, disabled: true },
                                            axis: { value: oskm.R1Median.axis.value, disabled: true },
                                        }),
                                        R2Median: this.formBuilder.group({
                                            mm: { value: oskm.R2Median.mm.value, disabled: true },
                                            diopter: { value: oskm.R2Median.diopter.value, disabled: true },
                                            axis: { value: oskm.R2Median.axis.value, disabled: true },
                                        }),
                                    });
                                })
                            ),
                            corneal: this.formBuilder.array(
                                m.OS.corneal.map(osc => {
                                    return this.formBuilder.group({
                                        number: { value: osc.number.value, disabled: true },
                                        size: { value: osc.size.value, disabled: true },
                                    });
                                })
                            ),
                        }),
                    });
                })
            )
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {
        this.test.remarks.diagnosis.registerEdit(rawFormValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(rawFormValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(rawFormValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(rawFormValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
