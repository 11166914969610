import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';

export abstract class ChartService {
    constructor() {}
    public abstract addData(frames: ICamMessage[]): Promise<void>;
    public abstract setCamData(frames: ICamMessage[]): void;
    public abstract export(): any;
    public abstract getRawExport(): IRawExportData;
    public abstract clearData(): void;
    public abstract setEdits(edits: IChartEdit[]): void;
    public getXvalue(startTs: number, currentRawTs: number): number {
        const currentTs: number = currentRawTs / 10000;
        return Math.abs((currentTs - startTs) / 1000);
    }

    public calculateAverage(array: number[]): number {
        if (!array.length) {
            return 0;
        }

        if (array.length === 1) {
            return array[0];
        }

        const sum = array.reduce((partialSum, acc) => partialSum + acc, 0);

        return sum / array.length;
    }

    // array should be sorted
    public getPercentile(array: number[], percentile: number): number {
        if (!array.length) {
            return 0;
        }

        if (percentile <= 0) {
            return array[0];
        } else if (percentile >= 100) {
            return array[array.length - 1];
        }

        const index = (percentile / 100) * array.length;
        const lower = Math.floor(index);

        if (lower == index) {
            return (array[index - 1] + array[index]) / 2;
        } else {
            return array[lower];
        }
    }

    public calculateSD(data: number[]): number {
        if (!data.length || data.length === 1) {
            return 0;
        }

        const mean = this.calculateAverage(data);
        const expArray = data.map(d => Math.pow(d - mean, 2));
        const sum = expArray.reduce((partialSum, acc) => partialSum + acc, 0);
        const sd = Math.pow(sum / (data.length - 1), 1 / 2);

        return sd;
    }
}
