// @ts-nocheck
import { Target } from '../types';
import { Area } from '../Area';
import * as d3 from 'd3';
import { doTranslate, TextAt, ghost } from '..';

// NOTE: I'm thinking about what can be refactored overall
// so there are comments on things I may change or I have decided on

// simple flat structure
interface Props {
    value: number;
    valueScale: any;

    onDragStart?: () => void;
    onDragEnd?: () => void;

    target: Target;
    area: Area; // just used as a point? or a line?
    opacity?: string;
}

const TO_FIXED = 2;

// optionals/styling
let defaultProps = {
    direction: 'h',
    handleRadius: 15,
    handleTip: 13,
    curvitureDy: 10,
    curvitureDx: 10,

    width: 53,
    height: 32,
    drawDx: 0,

    class: 'fBg sContrast',
    // class: 'fLayer sContrast',
    // class: 'sLayer fContrast',
    // class: 'sLayer fBorder',
    // class: 'fLayer sBorder',
    // class: 'sContrast fContrast',
    // class: 'sContrast fLayer',
    // strokeWidth: 2,
    strokeWidth: 0.5,

    show: true,
};

export function TrackHandle(given: Props & Partial<typeof defaultProps>) {
    let p = { ...defaultProps, ...given };

    let g = p.target
        .selectAll('.trackHandle')
        .data(p.show ? [null] : [])
        .join('g')
        .attr('class', 'trackHandle');

    // odd for cases with chart-related scale?
    let domain = [0, 1];
    let xScale = d3
        .scaleLinear()
        .domain(domain)
        .range(p.area.rangeX());
    let yScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range(p.area.rangeY());

    let klass = new Klass();

    // let path = new Path()

    // if (p.direction === 'h') {
    //   let curv = p.curvitureDy

    //   path.M(xScale(p.value) - p.handleRadius, yScale(0) + curv)
    //   path.L(xScale(p.value) - p.handleRadius, yScale(1) - p.handleTip)
    //   path.L(xScale(p.value), yScale(1))
    //   path.L(xScale(p.value) + p.handleRadius, yScale(1) - p.handleTip)
    //   path.L(xScale(p.value) + p.handleRadius, yScale(0) + curv)
    //   path.C(
    //     xScale(p.value) + p.curvitureDx, yScale(0),
    //     xScale(p.value) - p.curvitureDx, yScale(0),
    //     xScale(p.value) - p.handleRadius, yScale(0) + curv,
    //   )
    // } else {
    //   let v = p.value
    //   let r = p.handleRadius
    //   let tip = p.handleTip
    //   let curv = p.curvitureDx
    //   // let cDx = p.curvitureDx
    //   // let cDy = p.curvitureDy

    //   path.M(xScale(0) + curv, yScale(v) - r)
    //   path.L(xScale(1) - tip, yScale(v) - r)
    //   path.L(xScale(1), yScale(v))
    //   path.L(xScale(1) - tip, yScale(v) + r)
    //   path.L(xScale(0) + curv, yScale(v) + r)
    //   path.C(
    //     xScale(0), yScale(v) + p.curvitureDy,
    //     xScale(0), yScale(v) - p.curvitureDy,
    //     xScale(0) + curv, yScale(v) - r,
    //   )
    // }

    let x = 0;
    let y = 0;
    if (p.direction === 'h') {
        let dx = -p.width / 2;
        let dy = 10; //p.height

        x = xScale(p.value) + dx;
        y = yScale(0) + dy;
    } else {
        let dx = -10; // ???
        let dy = -p.height / 2;

        x = xScale(0) + dx;
        y = yScale(p.value) + dy;
    }

    x += p.drawDx;

    g.selectAll(klass.takeSelector)
        .data([null])
        .join('rect')
        .attr('rx', p.height / 2)
        .attr('class', `${p.class} ${klass.prevTaken}`)
        .attr('width', p.width)
        .attr('height', p.height)
        // .attr('fill', 'transparent')
        .attr('stroke-width', p.strokeWidth)
        .attr('cursor', 'grab')
        .on('mousedown', p.onDragStart || noop)
        .on('mouseup', p.onDragEnd || noop)
        .on('mouseleave', p.onDragEnd || noop)
        .call(
            doTranslate({
                x,
                y,
            })
        );

    let f = (x: number) => x.toFixed(TO_FIXED);

    x += p.width / 2;
    y += p.height / 2 + 2;

    TextAt({
        text: f(p.valueScale(p.value)),
        point: { x, y },
        target: g,
        opacity: p.opacity,
    });
    g.selectAll('text').call(ghost);
}

export function TrackHandleOld(given: Props & Partial<typeof defaultProps>) {
    let p = { ...defaultProps, ...given };

    let g = p.target
        .selectAll('.trackHandle')
        .data(p.show ? [null] : [])
        .join('g')
        .attr('class', 'trackHandle');

    // odd for cases with chart-related scale?
    let domain = [0, 1];
    let xScale = d3
        .scaleLinear()
        .domain(domain)
        .range(p.area.rangeX());
    let yScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range(p.area.rangeY());

    let klass = new Klass();

    let path = new Path();

    if (p.direction === 'h') {
        let curv = p.curvitureDy;

        path.M(xScale(p.value) - p.handleRadius, yScale(0) + curv);
        path.L(xScale(p.value) - p.handleRadius, yScale(1) - p.handleTip);
        path.L(xScale(p.value), yScale(1));
        path.L(xScale(p.value) + p.handleRadius, yScale(1) - p.handleTip);
        path.L(xScale(p.value) + p.handleRadius, yScale(0) + curv);
        path.C(xScale(p.value) + p.curvitureDx, yScale(0), xScale(p.value) - p.curvitureDx, yScale(0), xScale(p.value) - p.handleRadius, yScale(0) + curv);
    } else {
        let v = p.value;
        let r = p.handleRadius;
        let tip = p.handleTip;
        let curv = p.curvitureDx;
        // let cDx = p.curvitureDx
        // let cDy = p.curvitureDy

        path.M(xScale(0) + curv, yScale(v) - r);
        path.L(xScale(1) - tip, yScale(v) - r);
        path.L(xScale(1), yScale(v));
        path.L(xScale(1) - tip, yScale(v) + r);
        path.L(xScale(0) + curv, yScale(v) + r);
        path.C(xScale(0), yScale(v) + p.curvitureDy, xScale(0), yScale(v) - p.curvitureDy, xScale(0) + curv, yScale(v) - r);
    }

    g.selectAll(klass.takeSelector)
        .data([null])
        .join('path')
        .attr('d', path.d)
        .attr('class', `${p.class} ${klass.prevTaken}`)
        .attr('fill', 'transparent')
        .attr('stroke-width', p.strokeWidth)
        .attr('cursor', 'grab')
        .on('mousedown', p.onDragStart || noop)
        .on('mouseup', p.onDragEnd || noop)
        .on('mouseleave', p.onDragEnd || noop);
}

function noop() {}

class Klass {
    index = 0;
    get take() {
        this.index += 1;
        return this.prevTaken;
    }
    get prevTaken() {
        return this.klassFor(this.index - 1);
    }
    get takeSelector() {
        let klass = this.take;
        return `.${klass}`;
    }

    private klassFor(n: number) {
        return `klass-${n}`;
    }
}

class Path {
    d = '';

    // M - absolute coords version
    M(x: number, y: number) {
        this.d += `M${x} ${y} `;
    }
    m(x: number, y: number) {
        this.d += `m${x} ${y} `;
    }
    L(x: number, y: number) {
        this.d += `L${x} ${y} `;
    }
    l(x: number, y: number) {
        this.d += `l${x} ${y} `;
    }
    C(x1: number, y1: number, x2: number, y2: number, x: number, y: number) {
        this.d += `C${x1} ${y1} ${x2} ${y2} ${x} ${y}`;
    }
    c(x1: number, y1: number, x2: number, y2: number, x: number, y: number) {
        this.d += `c${x1} ${y1} ${x2} ${y2} ${x} ${y}`;
    }
}
