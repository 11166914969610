import { TestRemarkFrontend } from '../testRemarkFrontend';
import { AntisaccadeEvaluationMeasuredDataFrontend } from '../../measuredData/BulbiCAM/antisaccadeEvaluationDataFrontend.class';

export class AntisaccadeTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof AntisaccadeEvaluationMeasuredDataFrontend = AntisaccadeEvaluationMeasuredDataFrontend;
    public measurements: AntisaccadeEvaluationMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
