import * as jsPDF from 'jspdf';
import { OCULUS } from '../../../../../../../common/enums/oculus.enum';
import { ThreeDScanIvueOctv3217MeasuredData } from '../../../../../../../common/models/measuredData/IVUE/OCT/3dscanIvueOctv3217Data.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { IvueOctv3217ImageFrontend } from '../../../ivueOctv3217ResultFileFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { ThreeDScanIcueOctv3217TestRemarksFrontend } from '../../../test-remarks/IVUE/OCT/ThreeDScanIvueOctv3217TestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class ThreeDScanIvueOCTv3217MeasuredDataFrontend extends ThreeDScanIvueOctv3217MeasuredData implements MeasuredDataFrontend {
    public remarks: ThreeDScanIcueOctv3217TestRemarksFrontend;
    public images: IvueOctv3217ImageFrontend[];
    constructor() {
        super();
    }
    public setModel(model: ThreeDScanIvueOctv3217MeasuredData): void {
        this.images = model.images.map(model => {
            const image: IvueOctv3217ImageFrontend = new IvueOctv3217ImageFrontend();
            image.setModel(model);
            return image;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        try {
        } catch (error) {
            console.log(error);
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: this.images.filter(i => i.oculus === OCULUS.OD).map(i => i.image.imageUrl),
            },
            OS: {
                values: '',
                images: this.images.filter(i => i.oculus === OCULUS.OS).map(i => i.image.imageUrl),
            },
            OU: {
                values: '',
                images: [],
            },
        };
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.images.map(ni => ni.fetchImage(fileService)));
    }
}
