// @ts-nocheck

import { VTable } from './VTable';

// here I'm quickly using whatever is existing here
// that looks the right way
// to not overthink/refactor stuff

let defaultProps = {}

export function DiagramButton(given: Params & Partial<typeof defaultProps>) {
  let p = { ...defaultProps, ...given }

  return VTable({
    addKlass: 'diagram-button',
    showRects: true,
    domainX: [0, 0],
    rowHeaders: [''],
    columns: [{
      at: 0,
      rows: [{
        ...p,
      }],
    }],
    ...p,
  })
}
