import { KeratometryNidekArk1sTest } from '../../../../../../../../commonout/classes/tests/NIDEK/ARK1S/keratometryNidekArk1sTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { KeratometryTestRemarksComponent } from '../../../../_components';
import { KeratometryArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/keratometryArk1sTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class KeratometryNidekArk1sTestFrontend extends KeratometryNidekArk1sTest {
    remarks: KeratometryArk1sTestRemarksFrontend;
    public readonly remarksComponent: typeof KeratometryTestRemarksComponent = KeratometryTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new KeratometryArk1sTestRemarksFrontend();
    }
}

export interface KeratometryNidekArk1sTestFrontend extends TestFrontend {}
applyMixins(KeratometryNidekArk1sTestFrontend, [TestFrontend]);
