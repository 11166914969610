// @ts-nocheck
// import * as _ from 'lodash'
import { LinearSegmentsApproximation } from './lib';

const NO_RESPONSE = 'noResponse';
const GREEN = 'success';
const RED = 'error';
const PINK = 'corrected';

// incoming data point
interface Point {
    x: number;
    y: number;
    trial: number; //|string ///
}
interface Trial {
    trial: number;
    points: Point[];
}
type Predicate = (arg0: Point) => boolean;
type Predicates = {
    success: Predicate;
    error: Predicate;
};
export function emptyPercentLine (): PercentLine {
    return {
        success: [],
        error: [],
        corrected: [],
        noResponse: [],
        intersections: {
            success: {},
            error: {},
            corrected: {},
        },
    };
}
// type OneLine = {
//   [k: number]: Point
// }
interface PercentLine {
    success: ResultPoint[];
    error: ResultPoint[];
    corrected: ResultPoint[];
    noResponse: ResultPoint[];
    intersections: IntersectionGroups;
}
interface IntersectionGroups {
    success: Intersections;
    error: Intersections;
    corrected: Intersections;
}
export type Intersections = {
    [k: number]: ResultPoint;
};
interface ResultPoint {
    x: number;
    y: number;
}

// TODO: maybe some tests
//
// xs - resolution: needed points for the line, smaller(count) - faster
// fullCount - not used
// fullCount - to get percentages right even if there are absent trials
// predicates - object of functions to calculate lines for
//
export function percentLine (trials: Trial[], xs: number[], predicates: Predicates): PercentLine {
    // let foundAllPoints = [] // old part but used for intersections still
    let fullCount = Object.keys(trials).length;

    let intersections = {
        success: {},
        error: {},
        corrected: {},
        // correctionStart: {},
    };
    let registerIntersection = (trial: number, name: string, point: Point) => {
        // let trial = point.trial
        if (intersections[name][trial]) return;
        intersections[name][trial] = point;
    };

    type Statuses = {
        [k: string]: Status;
    };
    interface Status {
        success: number;
        error: number;
        corrected: number;
        noResponse: number;
    }
    let statuses: Statuses = {};
    xs.forEach((x: number) => {
        statuses[x] = {
            success: 0,
            error: 0,
            corrected: 0,
            noResponse: 0,
        };
    });

    trials.forEach(({ trial, points }) => {
        let trialStatus = NO_RESPONSE;

        let minY = 0;
        let maxXwithMinY = 0;
        let someWindow = 0.1; // NOTE: mess

        let registerMinMax = (point: Point) => {
            if (point.y < minY) {
                minY = point.y;
            }
            if (Math.abs(minY - point.y) < someWindow) {
                maxXwithMinY = point.x; // newer bigger values rewrite older ones
            }
        };
        let alterStatus = (point: Point) => {
            if (trialStatus === GREEN) {
                // no change possible
            } else if (trialStatus === PINK) {
                // no change possible
            } else if (trialStatus === NO_RESPONSE) {
                // can change to green or red
                if (predicates.success(point)) {
                    trialStatus = GREEN;
                    registerIntersection(trial, 'success', point);
                } else if (predicates.error(point)) {
                    trialStatus = RED;
                    registerIntersection(trial, 'error', point);
                }
            } else if (trialStatus === RED) {
                // can change to pink
                if (predicates.success(point)) {
                    trialStatus = PINK;
                    let deltaPoint = { ...point };
                    deltaPoint.x -= maxXwithMinY;
                    registerIntersection(trial, 'corrected', deltaPoint);
                }
            }
        };
        let registerNewStatus = (point: Point) => {
            statuses[point.x][trialStatus] += 1;
        };
        let register = (point: Point) => {
            alterStatus(point);
            registerNewStatus(point);
            registerMinMax(point);
        };

        let approx = new LinearSegmentsApproximation();
        points.forEach((p: Point) => approx.addPoint(p.x, p.y));
        let ys = approx.getManyYs(xs);
        xs.forEach((x: number, i: number) => {
            let y = ys[i];
            if (y == null) throw Error('unexpected: no y for x');
            register({ x, y, trial });
        });
    });

    let results: PercentLine = emptyPercentLine();

    Object.entries(statuses).forEach(([xValue, objStatuses]) => {
        Object.entries(objStatuses).forEach(([name, num]) => {
            let x = +xValue;
            let y = num / fullCount || 0;
            let resultPoint = { x, y };
            results[name].push(resultPoint);
        });
    });

    results.intersections = intersections;

    return results;
}
