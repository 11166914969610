import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { INystagmusParams } from '../../../../../../../../common/interfaces/measuredData/BulbiCAM/nystagmusEvaliationBulbicam.measuredData.interface';
import { NystagmusEvaluationBulbicamTestFrontend } from '../../../../../_models/tests/BulbiCAM/nystagmusEvaluationTestFrontend.class';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'nystagmus-start-stop-control',
    template: require('./nystagmus-start-stop-control.component.html'),
    styles: [require('./nystagmus-start-stop-control.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class NystagmusStartStopControlComponent extends BulbicamControlComponent {
    public enable(): void {}
    public disable(): void {}
    public test: NystagmusEvaluationBulbicamTestFrontend;
    private length: FormControl = new FormControl(4);
    @Output() public createMeasurement = new EventEmitter<string>();
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService) {
        super(bulbicamService, configService, socketService, authService);
    }
    private start(): void {
        this.pendingResponse = true;
        const payload: {
            nystagmusParams: INystagmusParams[];
            lengthInSeconds: number;
            username: string;
        } = {
            nystagmusParams: this.bulbicamService.nystagmusStaff.startParams(),
            lengthInSeconds: this.length.value,
            username: this.authService.currentUser.username,
        };
        this.bulbicamService.sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, payload).subscribe(() => {
            this.test.status = TEST_STATUS.IN_PROGRESS;
            this.pendingResponse = false;
        });
    }
    ngOnDestroy(): void {
        this.bulbicamService.nystagmusStaff.chartCleared = true;
    }
}
