import { FileService } from '../_services/general/file.service';
import { NextsightNexyImage } from '../../../../common/models/nextsightNexyImage.class';
import { SmartImageFrontend } from './smartImageFrontend.class';

export class NextsightNexyImageFrontend extends NextsightNexyImage {
    public image: SmartImageFrontend;
    constructor() {
        super();
        this.image = new SmartImageFrontend();
    }
    public async fetchImage(fileService: FileService): Promise<void> {
        try {
            if (this.image.isDownloaded()) return;
            await this.image.download(fileService);
        } catch (error) {
            console.log(error);
        }
    }
}
