// @ts-nocheck
import { Layout } from './lib';
// import * as _ from 'lodash'

// eslint-disable-next-line
import { layoutPreview } from './lib';

const GAP = 50;
// const G2 = GAP / 2
const MARGIN = 50;
const HEADER = 30


// const W = window.screen.width
// const H = window.screen.height
const USED_HEIGHT = 3/4
const MORE_HEIGHT = 150 // 12
const W = 1920
const H = 1080 * USED_HEIGHT + MORE_HEIGHT // so 9 runs fit
const RIGHT_CHART_RATIO = (x:any) => x.w * 3/5
const LEFT_PART_RATIO = 2/5

export function makeLayout() {
  let layout = new Layout(0, 0, W, H, 'layout');
  layout.takeLeft(MARGIN);
  layout.takeRight(MARGIN);
  layout.takeTop(MARGIN);
  layout.takeBottom(MARGIN);

  let LEFT_CHART_PARTITION = (x:any) => x.w/2 /*- GAP*/

  let charts = layout.takeRight(x => x.w * (1-LEFT_PART_RATIO), 'charts')
  let headers = charts.takeTop(HEADER, 'headers')
  charts.takeTop(HEADER)
  let leftHeader = headers.takeLeft(LEFT_CHART_PARTITION, 'leftHeader')
  headers.takeLeft(GAP)
  // headers.takeRight(GAP)
  // let rightHeader = headers.takeAll('rightHeader')
  let rightHeader = headers.takeRight(RIGHT_CHART_RATIO)

  let leftChart = charts.takeLeft(LEFT_CHART_PARTITION, 'leftChart')
  charts.takeLeft(GAP)
  // let rightChart = charts.takeAll('rightChart')
  // rightChart.takeRight(GAP)
  let rightChart = charts.takeRight(RIGHT_CHART_RATIO)

  layout.takeRight(GAP)
  layout.takeTop(GAP*2)
  let smallChart1 = layout.takeTop(x => x.h*1/2 - GAP, 'smallChart1')
  // let smallChart1 = layout.takeTop(x => x.w-GAP*1.5, 'smallChart1')
  smallChart1.takeLeft(GAP-10-10)
  smallChart1.takeRight(GAP)
  // smallChart1.takeTop(15)
  // smallChart1.takeTop(7)
  layout.takeTop(GAP) //*2
  let smallChart2 = layout.takeAll('smallChart2')

  let betweenCharts = Layout.xywh(leftChart.right, leftChart.top, rightChart.left - leftChart.right, leftChart.h)

  return {
    layout,

    smallChart1,
    smallChart2,

    leftHeader,
    rightHeader,

    leftChart,
    rightChart,
    betweenCharts,
  }
}

export type ChartLayout = ReturnType<typeof makeLayout>

// layoutPreview(makeLayout().layout, { fontSize: 50, forceStrength: -10000, forceDistance: 100 });
