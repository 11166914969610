// @ts-nocheck
// import * as d3 from 'd3';
import * as _ from 'lodash';

import { Target, Scale2D } from './types';
import { translate, nonInteractive, nonSelectable, getStyle } from './util';

// styling

const styleDefault = {
	dotRadius: 3,
	rx: 0,
	ry: 0,
	corner: 0,
	lineSize: 3,
	fillColor: 'rgba(0,0,0,0)',
	dasharray: undefined as string | undefined,
	class: '',

	fontSize: 18,
	fontWeight: 400 as number | string,
	fontColor: '',
	fontFamily: 'DM Sans',
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {
	// bright: {
	// },
};

type Shape = 'dot' | 'square' | 'hline' | 'wline'; // wline takes the whole width

interface Params {
	// gDataPoints: Target
	makeStructure: (a: string, b: string, c: Target) => { gSeries: Target; gDataPoints: Target };
	scale: Scale2D;

	onEnter?: (d: any) => void;
	onExit?: () => void;
	onClick?: (d: any) => void;
	onMove?: (d: any) => void;
	onUp?: (d: any) => void;

	filter?: (d: any) => boolean;

	// shape: (t: Target) => void
	shape?: Shape;
	withText?: boolean;

	target: Target;
	styleParams?: {
		theme?: string;
		override?: Partial<Style>;
	};
}

// function
export function ChartDots(p: Params) {
	let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

	let filter = p.filter || (() => true);

	let { gDataPoints } = p.makeStructure('chart-dots-series', 'chart-dots-points', p.target);

	let filling: any = style.fillColor === 'fill' ? (d: any) => (d.dataPoint.seen !== undefined && !d.dataPoint.seen ? 'transparent' : d.color) : style.fillColor;
	let points;
	let texts: any;

	let shape = p.shape || 'dot';
	let klass = style.class || '';
	let withText = p.withText || false;

	let renderText = () => {
		texts = gDataPoints
			.selectAll('.texts')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('g')
			.attr('class', 'texts')
			.attr('transform', (d) => translate({ x: p.scale.x(d.point.x), y: p.scale.y(d.point.y) }));
		// .attr('transform', d => translate({ x: p.scale.x(d.point.x), y: p.scale.y.range()[0] }))

		texts
			.selectAll('text')
			.data((d: any) => [d])
			.join('text')
			.text((d: any) => d.point.text)
			// .style('font-family', style.fontFamily)
			.attr('text-anchor', 'middle')
			.attr('dominant-baseline', 'middle')
			.attr('cursor', 'default')
			.call(nonInteractive)
			.call(nonSelectable)
			.style('font-size', style.fontSize)
			.style('font-weight', style.fontWeight)
			.style('font-family', style.fontFamily)
			.style('fill', style.fontColor);
	};

	if (shape === 'dot') {
		points = gDataPoints
			.selectAll('.dots')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('ellipse')
			.attr('class', `dots ${klass}`)
			.attr('cx', (d) => p.scale.x(d.point.x))
			.attr('cy', (d) => p.scale.y(d.point.y))
			.attr('rx', style.rx || style.dotRadius)
			.attr('ry', style.ry || style.dotRadius)
			.attr('stroke-width', style.lineSize)
			.attr('fill', filling)
			.attr('stroke', (d) => d.color);
		if (withText) renderText();
	}
	if (shape === 'square') {
		let w = style.rx ? style.rx * 2 : style.dotRadius * 2;
		let h = style.ry ? style.ry * 2 : style.dotRadius * 2;

		points = gDataPoints
			.selectAll('.dots')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('rect')
			.attr('class', `dots ${klass}`)

			.attr('x', (d) => p.scale.x(d.point.x) - w / 2)
			.attr('y', (d) => p.scale.y(d.point.y) - h / 2)

			.attr('width', w)
			.attr('height', h)
			.attr('rx', style.corner)

			.attr('stroke-width', style.lineSize)
			.attr('fill', filling)
			.attr('stroke', (d) => d.color);

		if (withText) renderText();
	}
	if (shape === 'hline') {
		points = gDataPoints
			.selectAll('.dots')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('line')
			.attr('class', `dots ${klass}`)

			.attr('x1', (d) => p.scale.x(d.point.x))
			.attr('x2', (d) => p.scale.x(d.point.x))

			.attr('y1', (d) => p.scale.y.range()[0])
			.attr('y2', (d) => p.scale.y.range()[1])

			// .attr('height', style.dotRadius * 2)
			// .attr('width', style.dotRadius * 2)

			.attr('stroke-width', style.lineSize)
			.attr('fill', filling)
			.style('stroke', (d) => d.color)
			.attr('stroke-dasharray', style.dasharray || '');

		// let { gDataPoints: gTexts } = p.makeStructure('chart-dots-series', 'chart-dots-points-text', p.target)
		// texts = gTexts
		texts = gDataPoints
			.selectAll('.texts')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('g')
			.attr('class', 'texts')
			.attr('transform', (d) => translate({ x: p.scale.x(d.point.x), y: p.scale.y.range()[0] }));
		// (d) => p.scale.x(d.point.x))
		// .attr('y', (d) => p.scale.y.range()[0])

		texts
			.selectAll('text')
			.data((d: any) => [d])
			.join('text')

			// .attr('x', (d) => p.scale.x(d.point.x))
			// .attr('y', (d) => p.scale.y.range()[0])

			.style('transform', 'rotate(-90deg)')

			.text((d: any) => d.point.text)

			// .attr('height', style.dotRadius * 2)
			// .attr('width', style.dotRadius * 2)

			// .attr('stroke-width', style.lineSize)
			// .attr('fill', filling)
			// .attr('stroke', (d) => d.color)
			.style('fill', (d: any) => d.color);
		// .attr('stroke-dasharray', style.dasharray || '')
	}
	if (shape === 'wline') {
		points = gDataPoints
			.selectAll('.dots')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('line')
			.attr('class', `dots ${klass}`)

			.attr('x1', (d) => p.scale.x.range()[0])
			.attr('x2', (d) => p.scale.x.range()[1])

			.attr('y1', (d) => p.scale.y(d.point.y))
			.attr('y2', (d) => p.scale.y(d.point.y))

			// .attr('height', style.dotRadius * 2)
			// .attr('width', style.dotRadius * 2)

			.attr('stroke-width', style.lineSize)
			.attr('fill', filling)
			.style('stroke', (d) => d.color)
			.attr('stroke-dasharray', style.dasharray || '');

		// let { gDataPoints: gTexts } = p.makeStructure('chart-dots-series', 'chart-dots-points-text', p.target)
		// texts = gTexts
		texts = gDataPoints
			.selectAll('.texts')
			.data((d) => _.compact([filter(d) ? d : null]))
			.join('g')
			.attr('class', 'texts')
			.attr('transform', (d) => translate({ x: p.scale.x.range()[0], y: p.scale.y(d.point.y) - 5 })); // TODO: style param?
		// (d) => p.scale.x(d.point.x))
		// .attr('y', (d) => p.scale.y.range()[0])

		texts
			.selectAll('text')
			.data((d: any) => [d])
			.join('text')
			.attr('text-anchor', 'end') // TODO: style param?

			// .attr('x', (d) => p.scale.x(d.point.x))
			// .attr('y', (d) => p.scale.y.range()[0])

			// .style('transform', 'rotate(-90deg)')

			.text((d: any) => d.point.text)

			// .attr('height', style.dotRadius * 2)
			// .attr('width', style.dotRadius * 2)

			// .attr('stroke-width', style.lineSize)
			// .attr('fill', filling)
			// .attr('stroke', (d) => d.color)
			.style('fill', (d: any) => d.color);
		// .attr('stroke-dasharray', style.dasharray || '')
	}

	let { onEnter, onExit, onClick, onMove, onUp } = p;
	if (onEnter) {
		points.on('mouseenter', (d) => {
			onEnter && onEnter(d);
		});
	}
	if (onExit) {
		points.on('mouseleave', () => {
			onExit && onExit();
		});
	}
	if (onClick) {
		points.on('mousedown', (d) => {
			onClick && onClick(d);
		});
	}
	if (onMove) {
		points.on('mousemove', (d) => {
			onMove && onMove(d);
		});
	}
	if (onUp) {
		points.on('mouseup', (d) => {
			onUp && onUp(d);
		});
	}
}