// @ts-nocheck
// square it seems (SQ)
export interface Place {
    n: number;
    x: number;
    y: number;
    eye: string;
}

interface Value {
    empty?: boolean;

    frequency: number;
    amplitude: number;
    axis: number;
    type?: string;
    range?: string;
    lastTauchedTracker1?: string;
    lastTauchedTracker2?: string;
    lastTauchedTracker1V?: string;
    lastTauchedTracker2V?: string;
    lastTauchedTracker1VDefinition?: string;
    uiXDomain?: number[];
    uiYDomain?: number[];
    start?: number[];
    finish?: number[];
    // bidirectional: number
}

export const VALUE_KEYS = [
    'frequency',
    'amplitude',
    'axis',
    'type',
    'range',
    'lastTauchedTracker1',
    'lastTauchedTracker2',
    'lastTauchedTracker1V',
    'lastTauchedTracker2V',
    'lastTauchedTracker1VDefinition',
    'uiXDomain',
    'uiYDomain',
    'start',
    'finish',
    // 'bidirectional',
];

export const OPTIONAL_FIELDS = ['type'];

const NO_VALUE = {
    empty: true,

    frequency: 0,
    amplitude: 0,
    axis: 0,
    // type: '',
};

interface Recording {
    place: Place;
    value: Value;
}

export class ManualStorage {
    entries: Recording[] = [];

    record(r: Recording) {
        this.entries.push(r);
    }

    getValue(place: Place): Value {
        let matching = this.entries.filter((x) => placeMatch(place, x.place));
        let got = matching[matching.length - 1];
        let result = got ? got.value : NO_VALUE;
        return { ...result };
    }
}

export function placeMatch(a: Place, b: Place) {
    return a.n === b.n && a.x === b.x && a.y === b.y && a.eye === b.eye;
}
