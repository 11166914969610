import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { TonometryTonorefIIIMeasuredDataFrontend } from '../../../measuredData/NIDEK/TonorefIII/tonometryTonorefIIIMeasuredDataFrontend.class';

export class TonometryTonorefIIITestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof TonometryTonorefIIIMeasuredDataFrontend = TonometryTonorefIIIMeasuredDataFrontend;
    public measurements: TonometryTonorefIIIMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
