import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IExamination } from '../../../../../../commonout/interfaces/examination';
import { IRegisterEditField } from '../../../../../../commonout/interfaces/register-edit-field.interface';
import ITest from '../../../../../../commonout/interfaces/test';
import { IPatientSearchTerms } from '../../../../../common/interfaces/patient.interface';
import { ExaminationFrontend } from '../../_models/examinationFrontend.class';
import { TestFrontend } from '../../_models/tests/testFrontend.class';
import { ConfigService } from '../general/config.service';

@Injectable()
export class ExaminationService {
    constructor(private configService: ConfigService, private http: HttpClient) {}

    public bulkExport(payload: any): Observable<{ examsCnt: number }> {
        const url = `${this.configService.backendUrl}/examinations/bulk-export`;
        return this.http.post<{ examsCnt: number }>(url, payload);
    }

    public getNextExamForBulkExport(): Observable<{ exam: IExamination; length: number }> {
        const url = `${this.configService.backendUrl}/examinations/bulk-export-get-next`;
        return this.http.get<{ exam: IExamination; length: number }>(url);
    }

    getExaminationById(id: string): Observable<IExamination> {
        const url = `${this.configService.backendUrl}/examinations/one/${id}`;
        return this.http.get<IExamination>(url);
    }

    create(patientId: string): Observable<ExaminationFrontend> {
        const url = `${this.configService.backendUrl}/examinations/create/${patientId}`;
        return this.http.get<IExamination>(url).pipe(
            map(model => {
                const e = new ExaminationFrontend();
                e.setModel(model);
                return e;
            })
        );
    }

    update(examination: IExamination): Observable<IExamination> {
        const url = `${this.configService.backendUrl}/examinations/update`;
        return this.http.post<IExamination>(url, JSON.stringify(examination));
    }

    updateTest(test: TestFrontend): Observable<ITest> {
        const url = `${this.configService.backendUrl}/examinations/updatetest`;
        return this.http.post<ITest>(url, { examinationID: test.examination._id, testModel: test.getModel() });
    }

    public delete(examinationID: string): Observable<void> {
        const url = `${this.configService.backendUrl}/examinations/delete/${examinationID}`;
        return this.http.get<void>(url);
    }

    public search(term: IPatientSearchTerms): Observable<{ examinations: ExaminationFrontend[]; examinationsCnt: number }> {
        return this.http.post<{ examinations: ExaminationFrontend[]; examinationsCnt: number }>(this.configService.backendUrl + '/examinations/search', term).pipe(
            map(responce => {
                return {
                    examinations: responce.examinations.map(model => {
                        const examination: ExaminationFrontend = new ExaminationFrontend();
                        examination.setModel(model);
                        return examination;
                    }),
                    examinationsCnt: responce.examinationsCnt,
                };
            })
        );
    }

    public countByPeriod(
        start: number,
        end: number
    ): Observable<
        {
            createdAt: number;
            patient: {
                registerID: IRegisterEditField<string>;
                ssn: IRegisterEditField<string>;
                dateOfBirth: IRegisterEditField<number>;
            };
        }[]
    > {
        return this.http.post<
            {
                createdAt: number;
                patient: {
                    registerID: IRegisterEditField<string>;
                    ssn: IRegisterEditField<string>;
                    dateOfBirth: IRegisterEditField<number>;
                };
            }[]
        >(this.configService.backendUrl + '/examinations/statistics/cnt-by-period', { start, end });
    }
}
