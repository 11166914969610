import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ROLE } from '../../../../../commonout/enum/role';
import { AuthenticationService } from '../_services/general/auth.service';

@Injectable()
export class RoleGuard implements CanActivate, CanLoad {
    constructor(private router: Router, private authService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const expectedRole: Array<ROLE> = route.data.expectedRole;
        if (expectedRole.includes(this.authService.currentUser.role)) {
            return true;
        }
        this.router.navigate(['auth']);
        return false;
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        const expectedRole: Array<ROLE> = route.data.expectedRole;
        if (expectedRole.includes(this.authService.currentUser.role)) {
            return true;
        }
        this.router.navigate(['auth']);
        return false;
    }
}
