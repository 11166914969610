import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { toPng } from 'html-to-image';
import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { from } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { ISaccadesBulbicamMeasuredData } from '../../../../../../common/interfaces/measuredData/BulbiCAM/saccades-bulbicam.measuredData.interface';
import { SaccadesBulbicamMeasuredData } from '../../../../../../common/models/measuredData/BulbiCAM/saccadesDataBulbicam.model';
import { SaccadeTestChartComponent } from '../../../_components';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { Pager } from '../../../_services/examination/export.service';
import { FileService } from '../../../_services/general/file.service';
import { BulbicamTestFrontend } from '../../haplotestFrontend.class';
import { ITableReportTestFormat } from '../../tableReportTestMode';
import { SaccadeBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/saccadeBulbicamTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../measuredDataFrontend';

export class SaccadesBulbicamMeasuredDataFrontend extends SaccadesBulbicamMeasuredData implements MeasuredDataFrontend {
    public remarks: SaccadeBulbicamTestRemarksFrontend;
    constructor() {
        super();
    }
    public setModel(model: ISaccadesBulbicamMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.haplotests = model.haplotests.map((htm) => {
            const ht = new BulbicamTestFrontend();
            ht.model = htm;
            return ht;
        });
    }
    public async toPDF(
        pdf: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        ///
        let table: any[] = [];
        const dataURLs: any[] = [];
        if (this.haplotests.length > 0) {
            await from(this.haplotests)
                .pipe(
                    concatMap(async (haplotest) => {
                        const componentFactory: ComponentFactory<SaccadeTestChartComponent> = componentFactoryResolver.resolveComponentFactory(SaccadeTestChartComponent);
                        const componentRef = viewContainerRef.createComponent(componentFactory);
                        const chart: SaccadeTestChartComponent = componentRef.instance;
                        const responce = await bulbicamService.getHaplotestData(haplotest.haplotestData);
                        await new Promise<void>(async (res) => {
                            chart.addData(responce.data);
                            setTimeout(() => {
                                res();
                            }, 1500);
                        });
                        const dataUrl = await new Promise<any>((res) => {
                            toPng(chart.saccadeChart.nativeElement, { quality: 1, backgroundColor: '#0a425d' }).then(function(dataUrl) {
                                viewContainerRef.clear();
                                res(dataUrl);
                            });
                        });
                        dataURLs.push(dataUrl);
                        table.push({
                            column1: { content: 'Measurement created at: ' + new Date(haplotest.createdAt).toLocaleString().toString() },
                        });
                        table.push({
                            column1: { styles: { minCellHeight: 100 } },
                        });
                    }),
                    toArray()
                )
                .toPromise();
        }
        ///
        (pdf as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: <CellHookHandler>((data) => {
                if (data.section === 'body' && data.row.index % 2 === 1 && data.column.dataKey === 'column1') {
                    pdf.addImage(dataURLs[(data.row.index - 1) / 2], 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                }
            }),
            didDrawPage: () => {},
        });
        pager.line = (pdf as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        if (this.haplotests.length > 0) {
            formattedTest.haplotest = this.haplotests[this.haplotests.length - 1].haplotestData;
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
