import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IAccommodationArk1sMeasuredData } from '../../../../interfaces/measuredData/NIDEK/ARK1S/accommodation-nidek-ark1s.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { SmartImage } from '../../../smartImage.class';
import { TestRemarks } from '../../../testRemark.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class AccommodationArk1sMeasuredData implements MeasuredData, IAccommodationArk1sMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public sphere: {
        OD: RegisterEditField<string>;
        OS: RegisterEditField<string>;
    };
    public image: {
        OD: SmartImage;
        OS: SmartImage;
    };
    public remarks: TestRemarks;
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_ARK1S;
        this.testType = TEST_TYPE.ACCOMMODATION;
    }
    public setModel(model: IAccommodationArk1sMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.sphere.OD.setModel(model.sphere.OD);
        this.sphere.OS.setModel(model.sphere.OS);
        this.image.OD.model = model.image.OD;
        this.image.OS.model = model.image.OS;
    }
    public getModel(): IAccommodationArk1sMeasuredData {
        let model: IAccommodationArk1sMeasuredData = {
            sourceDevice: this.sourceDevice,
            sourceFileName: this.sourceFileName,
            testType: this.testType,
            sphere: {
                OD: this.sphere.OD.getModel(),
                OS: this.sphere.OS.getModel(),
            },
            image: {
                OD: this.image.OD.model,
                OS: this.image.OS.model,
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.sphere.OD.isEmpty() && this.sphere.OS.isEmpty() && this.image.OD.isEmpty() && this.image.OS.isEmpty();
    }
}
