// @ts-nocheck
import { Layout } from './lib';

// eslint-disable-next-line
import { layoutPreview } from './lib';

// bottom chart area should be equal to the top

const GAP = 50;
const SMALL_GAP = 10;

export const layout = new Layout(0, 0, window.screen.width, 2 * window.screen.height * (2 / 3), 'layout');
layout.takeBottom(GAP);
layout.takeRight(GAP);

export const topChart = layout.takeTop((x: Layout) => x.height() / 2, 'topChart');
export const topHeader = topChart.takeTop(3 * GAP, 'topHeader');
topChart.takeTop(GAP);
topHeader.takeBottom(GAP);
topHeader.takeTop(GAP);

export const trialsCount = topHeader.takeLeft(2 * GAP, 'trialsCount');
trialsCount.pointF = (x: Layout) => x.cornerTR().dy(-GAP / 4); //.dy(GAP / 2)
// trialsCount.pointF = (x: Layout) => x.cornerTR().dy(-GAP / 2); //.dy(GAP / 2)
// trialsCount.takeTop(GAP)
export const buttonsPlace = topHeader.takeAll('buttonsPlace');
buttonsPlace.pointF = (x: Layout) => x.cornerTR();

let topLabels = topChart.takeLeft(GAP, 'topLabels');
topLabels.takeBottom(GAP);
export const topLabels1 = topLabels.takeTop((x: Layout) => x.height() / 2, 'topLabels1');
export const topLabels2 = topLabels1.takeBottom((x: Layout) => x.height() / 2, 'topLabels2');
export const topLabels3 = topLabels.takeTop((x: Layout) => x.height() / 2, 'topLabels3');
export const topLabels4 = topLabels.takeAll('topLabels4');

topLabels1.pointF = (x: Layout) => x.cornerBR().dx(-20);
topLabels2.pointF = (x: Layout) => x.cornerBR().dx(-20);
topLabels3.pointF = (x: Layout) => x.cornerBR().dx(-20);
topLabels4.pointF = (x: Layout) => x.cornerBR();

export const topPercentAxis = topChart.takeLeft(GAP, 'topPercentAxis');
export const topAngleAxis = topChart.takeRight(GAP, 'topAngleAxis');
export const topDistanceAxis = topChart.takeBottom(GAP, 'topDistanceAxis');

topDistanceAxis.pointF = (x: Layout) =>
    x
        .cornerTL()
        .dy(SMALL_GAP)
        .yOnly();
topAngleAxis.pointF = (x: Layout) =>
    x
        .cornerTL()
        .dx(SMALL_GAP)
        .xOnly();
topPercentAxis.pointF = (x: Layout) =>
    x
        .cornerTR()
        .dx(-SMALL_GAP)
        .xOnly();

layout.takeLeft(GAP);
export const betweenCharts = layout.takeTop(GAP, 'betweenCharts');
layout.takeRight(GAP);

// let bottomLabels = layout.takeLeft(GAP, 'bottomLabels')
// bottomLabels.takeBottom(GAP)
// NOTE: not used now

export const bottomSubjectPoints = layout.takeTop(GAP, 'bottomSubjectPoints');
export const bottomTopAxis = layout.takeTop(GAP, 'bottomTopAxis');

// let bottomSubjectAge = bottomSubjectPoints.takeLeft(GAP, 'bottomSubjectAge') // NOTE:unused
bottomSubjectPoints.takeLeft(GAP);

export const bottomAgeAxis = layout.takeLeft(GAP, 'bottomAgeAxis');
export const bottomSRTAxis = layout.takeBottom(GAP, 'bottomSRTAxis');

bottomSRTAxis.pointF = (x: Layout) =>
    x
        .cornerTL()
        .dy(SMALL_GAP)
        .yOnly();
bottomTopAxis.pointF = (x: Layout) =>
    x
        .cornerBL()
        .dy(-SMALL_GAP)
        .yOnly();
bottomAgeAxis.pointF = (x: Layout) =>
    x
        .cornerTR()
        .dx(-SMALL_GAP)
        .xOnly();

export const bottomChart = layout.takeAll('bottomChart');

// layoutPreview(layout, { fontSize: 50, forceStrength: -10000, forceDistance: 100 })
