import { MeasuredData } from '../../measuredData.model';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { SmartImage } from '../../../smartImage.class';
import { IPostSegmentNidekRs330MeasuredData } from '../../../../interfaces/measuredData/NIDEK/RS330/post-segment-nidek-rs330.measuredData.interface';

export abstract class PostSegmentNidekRs330MeasuredData implements MeasuredData, IPostSegmentNidekRs330MeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: SmartImage[];
    public OS: SmartImage[];
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_RS330;
        this.testType = TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION;
    }
    public abstract setModel(model: IPostSegmentNidekRs330MeasuredData): void;
    public getModel(): IPostSegmentNidekRs330MeasuredData {
        let model: IPostSegmentNidekRs330MeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.map((i) => i.model),
            OS: this.OS.map((i) => i.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.length === 0 && this.OS.length === 0;
    }
}
