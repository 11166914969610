import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { axisBottom, axisLeft, BaseType, line, Line, ScaleLinear, scaleLinear, select, Selection } from 'd3';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import { IPupil20TestCamMessage, PupilDotChartResults, RAPD } from '../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { MyPupil20TestChartService } from '../../../../../_services/chartServices/myPupil20TestChartService';

@Component({
    selector: 'pupil20-dot-chart',
    template: require('./pupil2.0-dot-chart.component.html'),
    styles: [require('./pupil2.0-dot-chart.component.scss')],
})
export class Pupil20DotChartComponent implements AfterViewInit, OnInit, OnDestroy {
    public framesSource: Subject<IPupil20TestCamMessage> = new Subject<IPupil20TestCamMessage>();
    private offset: FormControl = new FormControl({ value: '250', disabled: true });
    public data: IPupil20TestCamMessage[][] = [];
    @ViewChild('dotChart') private svg: ElementRef;
    private readonly CHART_HEIGHT = 450;
    private readonly RESIZE_WIDTH_FACTOR = 0.8;
    private subscriptions: Subscription[] = [];
    private readonly ROUND_TEST_LENGTH: number = 25;
    private readonly padding: { left: number; top: number; right: number; bottom: number } = { left: 40, top: 40, right: 40, bottom: 40 };
    private startTimestamp: number = undefined;
    private dotChart: Selection<SVGGElement, unknown, null, undefined>;
    private yAxisGenerator: ScaleLinear<number, number>;
    private xAxisGenerator: ScaleLinear<number, number>;
    private odd_even: IPupil20TestCamMessage[] = [];
    private dotsOdd: { x: number; y: number; round: number; oculus: OCULUS; isOdd: boolean; timestamp: number }[] = [];
    private shiftedDotsOdd: { x: number; y: number; round: number; oculus: OCULUS; isOdd: boolean; timestamp: number }[] = [];
    private dotsEven: { x: number; y: number; round: number; oculus: OCULUS; isOdd: boolean; timestamp: number }[] = [];
    private lineGenerator: Line<{ x: number; y: number }>;
    private shiftedLineOdd: { x: number; y: number }[];
    private lineEven: { x: number; y: number }[];
    @Output() public RAPD = new EventEmitter<string>();
    public _RAPD: RAPD;
    private dotChartTestResult: PupilDotChartResults = {
        shiftedDotsOdd: [],
        dotsEven: [],
        dataRAPD: {
            value: null,
            oculus: OCULUS.NONE,
        },
    };
    constructor(private chartService: MyPupil20TestChartService) {
        this.subscriptions.push(
            this.framesSource.subscribe(frame => {
                switch (frame.message_type) {
                    // this message comes when new run starts
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        // data is array of arrays. Each array in data will contain one test run from START_PUPIL_DATA_TRANSMISSION to STOP_PUPIL_DATA_TRANSMISSION
                        // in next line I add new empty array
                        this.data.push([]);
                        // startTimestamp is timestamp that will represent 0 value on x axis for each run
                        this.startTimestamp = frame.timestamp / 10000;
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        // odd_even array exist just to define if current dot belongs to odd or to even second
                        this.odd_even = [];
                        break;
                    // this message comes when run finish.
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        // run is finished so I clear this value
                        this.startTimestamp = null;
                        // run is finished so I clear this value
                        break;
                    case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        break;
                    // at this point we should measure size of pupil. This is end of senond
                    case MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION:
                        // at this point we have current pupil size. To drow dot we need to apply next formulas
                        // OD(Time+offset) - OD(Time+offset-1sec)
                        // OS(Time+offset) - OS(Time+offset-1sec)
                        // Because we can not add 250 ms offset to current point due to current point is real time, we need to find value of pupil at the end of previous period (1 second). So that we will be able to add time offset to it and retrieve pupil size value

                        // prevStopIndex is index in array of frame with STOP_BACKGROUND_DATA_TRANSMISSION message type (end of previous second)
                        const prevStopIndex: number = this.lastIndexOf(this.data[this.data.length - 1], MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION);
                        // this.odd_even.length > 3 means that at the start of test we have background light conditions that we have to skip akkording to HaPe.
                        if (this.odd_even.length > 3) {
                            // currentTS is timestamp of frame when the previous period was ended
                            const currentTS: number = this.data[this.data.length - 1][prevStopIndex].timestamp / 10000,
                                // goal1 is abstract timestamp that should be close to existing one that we will use as OX(Time+offset)
                                goal1: number = currentTS + Number.parseInt(this.offset.value),
                                // goal2 is abstract timestamp that should be close to existing one that we will use as OX(Time+offset-1sec)
                                goal2: number = goal1 - 1000,
                                // real frame closest to goal1 forward (in case there was blink - closest will be first with pupil size value more than 0). Actual OX(Time+offset) value is here
                                searchedFrame1: IPupil20TestCamMessage = this.data[this.data.length - 1].find(f =>
                                    f.timestamp / 10000 >= goal1 && f.measurementOD && f.measurementOS ? true : false
                                ),
                                // real frame closest to goal2 forward (in case there was blink - closest will be first with pupil size value more than 0). Actual OX(Time+offset-1sec) value is here
                                searchedFrame2: IPupil20TestCamMessage = this.data[this.data.length - 1].find(f =>
                                    f.timestamp / 10000 >= goal2 && f.measurementOD && f.measurementOS ? true : false
                                );
                            if (!searchedFrame1 || !searchedFrame2) {
                                console.log('never');
                                return;
                            }
                            // xValue is value in seconds between 0 and 25 for x axis. Function getXvalue converts timestamp to such value
                            const xValue: number = this.chartService.getXvalue(this.startTimestamp, searchedFrame1.timestamp),
                                // yValueOD is OD value for y axis. Is difference between searchedFrame1 and searchedFrame2 according to HaPe's formula OD(Time+offset) - OD(Time+offset-1sec)
                                yValueOD: number = searchedFrame1.measurementOD - searchedFrame2.measurementOD,
                                // yValueOS is OS value for y axis. Is difference between searchedFrame1 and searchedFrame2 according to HaPe's formula OS(Time+offset) - OS(Time+offset-1sec)
                                yValueOS: number = searchedFrame1.measurementOS - searchedFrame2.measurementOS;
                            // this condition depends on if we are going to render chart or only to calculate values for export
                            if (this.xAxisGenerator) {
                                // xPosition position on screen in pixels for xaxis
                                const xPosition: number = this.xAxisGenerator(xValue),
                                    // yPosition position on screen in pixels for y axis for OD
                                    yPositionOD: number = this.yAxisGenerator(yValueOD),
                                    // yPosition position on screen in pixels for y axis for OS
                                    yPositionOS: number = this.yAxisGenerator(yValueOS);
                                this.dotChart
                                    .append('circle')
                                    .attr(
                                        'class',
                                        `dot OD ${this.data.length - 1} run ${this.odd_even.length} block. From ${this.chartService.getXvalue(
                                            this.startTimestamp,
                                            frame.timestamp
                                        )}`
                                    )
                                    .attr('fill', this.getColor(OCULUS.OD, this.data.length, this.isOdd()))
                                    .attr('stroke', this.getColor(OCULUS.OD, this.data.length, this.isOdd()))
                                    .attr('stroke-width', 1)
                                    .attr('r', this.isOdd() ? 1 : 3)
                                    .style('opacity', this.isOdd() ? 0 : 1)
                                    .attr('cx', xPosition)
                                    .attr('cy', yPositionOD);
                                this.dotChart
                                    .append('circle')
                                    .attr(
                                        'class',
                                        `dot OS ${this.data.length - 1} run ${this.odd_even.length} block. From ${this.chartService.getXvalue(
                                            this.startTimestamp,
                                            frame.timestamp
                                        )}`
                                    )
                                    .attr('fill', this.getColor(OCULUS.OS, this.data.length, this.isOdd()))
                                    .attr('stroke', this.getColor(OCULUS.OS, this.data.length, this.isOdd()))
                                    .attr('stroke-width', 1)
                                    .attr('r', this.isOdd() ? 1 : 3)
                                    .style('opacity', this.isOdd() ? 0 : 1)
                                    .attr('cx', xPosition)
                                    .attr('cy', yPositionOS);
                            }
                            if (this.isOdd()) {
                                // odd
                                this.dotsOdd.push({
                                    x: xValue,
                                    y: yValueOD,
                                    oculus: OCULUS.OD,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                                this.dotsOdd.push({
                                    x: xValue,
                                    y: yValueOS,
                                    oculus: OCULUS.OS,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                                const shiftedOddXValue: number = xValue + (this.odd_even.length < 14 ? -1 : 1);
                                this.shiftedDotsOdd.push({
                                    x: shiftedOddXValue,
                                    y: yValueOD,
                                    oculus: OCULUS.OD,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000 + (this.odd_even.length < 14 ? -1000 : 1000),
                                });
                                this.shiftedDotsOdd.push({
                                    x: shiftedOddXValue,
                                    y: yValueOS,
                                    oculus: OCULUS.OS,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000 + (this.odd_even.length < 14 ? -1000 : 1000),
                                });
                                // this condition depends on if we are going to render chart or only to calculate values for export
                                this.dotChart
                                    ?.append('circle')
                                    .attr('class', `shifted dot OD ${this.data.length - 1} run ${this.odd_even.length} block. From ${shiftedOddXValue}`)
                                    .attr('fill', this.getColor(OCULUS.OD, this.data.length, this.isOdd()))
                                    .attr('stroke', this.getColor(OCULUS.OD, this.data.length, this.isOdd()))
                                    .attr('stroke-width', 1)
                                    .attr('r', 3)
                                    .attr('cx', this.xAxisGenerator(shiftedOddXValue))
                                    .attr('cy', this.yAxisGenerator(yValueOD));
                                // this condition depends on if we are going to render chart or only to calculate values for export
                                this.dotChart
                                    ?.append('circle')
                                    .attr('class', `shifted dot OS ${this.data.length - 1} run ${this.odd_even.length} block. From ${shiftedOddXValue}`)
                                    .attr('fill', this.getColor(OCULUS.OS, this.data.length, this.isOdd()))
                                    .attr('stroke', this.getColor(OCULUS.OS, this.data.length, this.isOdd()))
                                    .attr('stroke-width', 1)
                                    .attr('r', 3)
                                    .attr('cx', this.xAxisGenerator(shiftedOddXValue))
                                    .attr('cy', this.yAxisGenerator(yValueOS));
                            } else {
                                // even
                                this.dotsEven.push({
                                    x: xValue,
                                    y: yValueOD,
                                    oculus: OCULUS.OD,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                                this.dotsEven.push({
                                    x: xValue,
                                    y: yValueOS,
                                    oculus: OCULUS.OS,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                            }
                        }
                        this.data[this.data.length - 1].push(frame);
                        this.odd_even.push(frame);
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1]?.push(frame);
                        let RAPD: number = null;

                        const crossZeroOddTs: number = this.calculateShiftedOdd(),
                            crossZeroEvenTs: number = this.calculateEven();

                        this.dotChart
                            ?.append('path')
                            .attr('class', `line Odd`)
                            .attr('fill', 'none')
                            .attr('stroke', 'white')
                            .attr('stroke-width', '2px')
                            .style('opacity', '0.2')
                            .datum(this.shiftedLineOdd)
                            .attr('d', this.lineGenerator);

                        this.dotChart
                            ?.append('path')
                            .attr('class', `line Even`)
                            .attr('fill', 'none')
                            .attr('stroke', 'white')
                            .attr('stroke-width', '2px')
                            .style('opacity', '0.2')
                            .datum(this.lineEven)
                            .attr('d', this.lineGenerator);
                        if (Math.abs(crossZeroOddTs - crossZeroEvenTs) <= 2) {
                            const averageCrossZero: number = (crossZeroOddTs + crossZeroEvenTs) / 2;
                            RAPD = (averageCrossZero - 14) * 0.15;
                            this.RAPD.next(`RAPD ${RAPD >= 0 ? 'OD' : 'OS'}: ${Math.abs(RAPD).toFixed(2)}`);
                            this._RAPD = {
                                oculus: RAPD >= 0 ? OCULUS.OD : OCULUS.OS,
                                value: Math.abs(RAPD).toFixed(2),
                            };
                        } else {
                            this.RAPD.next('RAPD n.a.');
                            this._RAPD = {
                                oculus: null,
                                value: 'n.a.',
                            };
                        }
                        break;
                    default:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1]?.push(frame);
                        break;
                }
            })
        );

        this.subscriptions.push(
            this.chartService.dotChartFrameData$.subscribe(frame => {
                if (frame === null) return;
                switch (frame.message_type) {
                    case MESSAGE_TYPE.START_TEST:
                        this.data = [];
                        this.dotsEven = [];
                        this.dotsOdd = [];
                        this.shiftedDotsOdd = [];
                        this.startTimestamp = null;
                        break;
                    // this message comes when new run starts
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        // data is array of arrays. Each array in data will contain one test run from START_PUPIL_DATA_TRANSMISSION to STOP_PUPIL_DATA_TRANSMISSION
                        // in next line I add new empty array
                        this.data.push([]);
                        // startTimestamp is timestamp that will represent 0 value on x axis for each run
                        this.startTimestamp = frame.timestamp / 10000;
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        // odd_even array exist just to define if current dot belongs to odd or to even second
                        this.odd_even = [];
                        break;
                    // this message comes when run finish.
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        // run is finished so I clear this value
                        this.startTimestamp = null;
                        // run is finished so I clear this value
                        break;
                    case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        break;
                    // at this point we should measure size of pupil. This is end of senond
                    case MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION:
                        // at this point we have current pupil size. To drow dot we need to apply next formulas
                        // OD(Time+offset) - OD(Time+offset-1sec)
                        // OS(Time+offset) - OS(Time+offset-1sec)
                        // Because we can not add 250 ms offset to current point due to current point is real time, we need to find value of pupil at the end of previous period (1 second). So that we will be able to add time offset to it and retrieve pupil size value

                        // prevStopIndex is index in array of frame with STOP_BACKGROUND_DATA_TRANSMISSION message type (end of previous second)
                        const prevStopIndex: number = this.lastIndexOf(this.data[this.data.length - 1], MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION);
                        // this.odd_even.length > 3 means that at the start of test we have background light conditions that we have to skip akkording to HaPe.
                        if (this.odd_even.length > 3) {
                            // currentTS is timestamp of frame when the previous period was ended
                            const currentTS: number = this.data[this.data.length - 1][prevStopIndex].timestamp / 10000,
                                // goal1 is abstract timestamp that should be close to existing one that we will use as OX(Time+offset)
                                goal1: number = currentTS + Number.parseInt(this.offset.value),
                                // goal2 is abstract timestamp that should be close to existing one that we will use as OX(Time+offset-1sec)
                                goal2: number = goal1 - 1000,
                                // real frame closest to goal1 forward (in case there was blink - closest will be first with pupil size value more than 0). Actual OX(Time+offset) value is here
                                searchedFrame1: IPupil20TestCamMessage = this.data[this.data.length - 1].find(f =>
                                    f.timestamp / 10000 >= goal1 && f.measurementOD && f.measurementOS ? true : false
                                ),
                                // real frame closest to goal2 forward (in case there was blink - closest will be first with pupil size value more than 0). Actual OX(Time+offset-1sec) value is here
                                searchedFrame2: IPupil20TestCamMessage = this.data[this.data.length - 1].find(f =>
                                    f.timestamp / 10000 >= goal2 && f.measurementOD && f.measurementOS ? true : false
                                );
                            if (!searchedFrame1 || !searchedFrame2) {
                                console.log('never');
                                return;
                            }
                            // xValue is value in seconds between 0 and 25 for x axis. Function getXvalue converts timestamp to such value
                            const xValue: number = this.chartService.getXvalue(this.startTimestamp, searchedFrame1.timestamp),
                                // yValueOD is OD value for y axis. Is difference between searchedFrame1 and searchedFrame2 according to HaPe's formula OD(Time+offset) - OD(Time+offset-1sec)
                                yValueOD: number = searchedFrame1.measurementOD - searchedFrame2.measurementOD,
                                // yValueOS is OS value for y axis. Is difference between searchedFrame1 and searchedFrame2 according to HaPe's formula OS(Time+offset) - OS(Time+offset-1sec)
                                yValueOS: number = searchedFrame1.measurementOS - searchedFrame2.measurementOS;
                            // this condition depends on if we are going to render chart or only to calculate values for export
                            if (this.xAxisGenerator) {
                                // xPosition position on screen in pixels for xaxis
                                const xPosition: number = this.xAxisGenerator(xValue),
                                    // yPosition position on screen in pixels for y axis for OD
                                    yPositionOD: number = this.yAxisGenerator(yValueOD),
                                    // yPosition position on screen in pixels for y axis for OS
                                    yPositionOS: number = this.yAxisGenerator(yValueOS);
                            }
                            if (this.isOdd()) {
                                // odd
                                this.dotsOdd.push({
                                    x: xValue,
                                    y: yValueOD,
                                    oculus: OCULUS.OD,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                                this.dotsOdd.push({
                                    x: xValue,
                                    y: yValueOS,
                                    oculus: OCULUS.OS,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                                const shiftedOddXValue: number = xValue + (this.odd_even.length < 14 ? -1 : 1);
                                this.shiftedDotsOdd.push({
                                    x: shiftedOddXValue,
                                    y: yValueOD,
                                    oculus: OCULUS.OD,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000 + (this.odd_even.length < 14 ? -1000 : 1000),
                                });
                                this.shiftedDotsOdd.push({
                                    x: shiftedOddXValue,
                                    y: yValueOS,
                                    oculus: OCULUS.OS,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000 + (this.odd_even.length < 14 ? -1000 : 1000),
                                });
                            } else {
                                // even
                                this.dotsEven.push({
                                    x: xValue,
                                    y: yValueOD,
                                    oculus: OCULUS.OD,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                                this.dotsEven.push({
                                    x: xValue,
                                    y: yValueOS,
                                    oculus: OCULUS.OS,
                                    isOdd: this.isOdd(),
                                    round: this.data.length,
                                    timestamp: searchedFrame1.timestamp / 10000,
                                });
                            }
                        }
                        this.data[this.data.length - 1].push(frame);
                        this.odd_even.push(frame);
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1].push(frame);
                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1]?.push(frame);
                        let RAPD: number = null;

                        const crossZeroOddTs: number = this.calculateShiftedOdd(),
                            crossZeroEvenTs: number = this.calculateEven();

                        if (Math.abs(crossZeroOddTs - crossZeroEvenTs) <= 2) {
                            const averageCrossZero: number = (crossZeroOddTs + crossZeroEvenTs) / 2;
                            RAPD = (averageCrossZero - 14) * 0.15;
                            this._RAPD = {
                                oculus: RAPD >= 0 ? OCULUS.OD : OCULUS.OS,
                                value: Math.abs(RAPD).toFixed(2),
                            };
                        } else {
                            this._RAPD = {
                                oculus: null,
                                value: 'n.a.',
                            };
                        }
                        this.dotChartTestResult = {
                            shiftedDotsOdd: this.shiftedDotsOdd,
                            dotsEven: this.dotsEven,
                            dataRAPD: this._RAPD,
                        };

                        break;
                    default:
                        // here I push current frame to frames storage
                        this.data[this.data.length - 1]?.push(frame);
                        break;
                }

                this.chartService.pupilDotChartResult$.next(this.dotChartTestResult);
            })
        );
    }
    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.chartService.pupilDotChartResult$ = new BehaviorSubject(null);
    }
    @HostListener('window:resize', ['$event']) public onResize() {
        select(this.svg.nativeElement)
            .selectAll('*')
            .remove();
        this.ngAfterViewInit();
        if (this.shiftedDotsOdd.length > 0 && this.dotsEven.length > 0) {
            this.shiftedDotsOdd.concat(this.dotsEven).forEach(dot => {
                this.dotChart
                    .append('circle')
                    .attr('class', 'dot')
                    .attr('fill', this.getColor(dot.oculus, dot.round, dot.isOdd))
                    .attr('stroke', this.getColor(dot.oculus, dot.round, dot.isOdd))
                    .attr('stroke-width', 1)
                    .attr('r', 3)
                    .attr('cx', this.xAxisGenerator(dot.x))
                    .attr('cy', this.yAxisGenerator(dot.y));
            });
            this.dotChart
                .append('path')
                .attr('class', `line Odd`)
                .attr('fill', 'none')
                .attr('stroke', 'white')
                .attr('stroke-width', '2px')
                .style('opacity', '0.2')
                .datum(this.shiftedLineOdd)
                .attr('d', this.lineGenerator);
            this.dotChart
                .append('path')
                .attr('class', `line Even`)
                .attr('fill', 'none')
                .attr('stroke', 'white')
                .attr('stroke-width', '2px')
                .style('opacity', '0.2')
                .datum(this.lineEven)
                .attr('d', this.lineGenerator);
        }
    }
    ngAfterViewInit() {
        const SVG = select(this.svg.nativeElement);
        const SVGwidth = this.svg.nativeElement.getBoundingClientRect().width 
            ? this.svg.nativeElement.getBoundingClientRect().width 
            : window.innerWidth * this.RESIZE_WIDTH_FACTOR;
        const SVGHeight = this.svg.nativeElement.getBoundingClientRect().height
            ? this.svg.nativeElement.getBoundingClientRect().height
            : this.CHART_HEIGHT;

        this.xAxisGenerator = scaleLinear()
            .domain([0, this.ROUND_TEST_LENGTH])
            .range([0, SVGwidth - this.padding.left - this.padding.right]);
        this.yAxisGenerator = scaleLinear()
            .domain([1.2, -1.2])
            .range([0, SVGHeight - this.padding.top - this.padding.bottom]);

        this.dotChart = SVG.append('g')
            .attr('class', 'dotChart')
            .attr('transform', `translate(${this.padding.left},${this.padding.top})`);

        SVG.append('g')
            .attr('class', 'xAxis')
            .attr('transform', `translate(${this.padding.left},${SVGHeight - this.padding.bottom})`)
            .attr('color', 'white')
            .call(axisBottom(this.xAxisGenerator).ticks(25))
            .call(selection => {
                selection.selectAll('.tick').each((d: any, index: number, nodes: BaseType[]) => {
                    this.dotChart
                        .append('line')
                        .attr('x1', this.xAxisGenerator(d))
                        .attr('y1', this.yAxisGenerator(-1.2))
                        .attr('x2', this.xAxisGenerator(d))
                        .attr('y2', this.yAxisGenerator(1.2))
                        .attr('stroke', 'white')
                        .style('opacity', '0.2');
                });
            });

        SVG.append('g')
            .attr('class', 'yAxis')
            .attr('transform', `translate(${this.padding.left},${this.padding.top})`)
            .attr('color', 'white')
            .call(axisLeft(this.yAxisGenerator).ticks(24));

        this.dotChart
            .append('line')
            .attr('x1', this.xAxisGenerator(0))
            .attr('y1', this.yAxisGenerator(0))
            .attr('x2', this.xAxisGenerator(25))
            .attr('y2', this.yAxisGenerator(0))
            .attr('stroke', 'white')
            .style('opacity', '0.2');

        this.lineGenerator = line<{ x: number; y: number }>()
            .x(d => this.xAxisGenerator(d.x))
            .y(d => this.yAxisGenerator(d.y));
    }
    public export<T>(isTcv?: boolean): T {
        if (isTcv) {
            return ({
                shifterOdd: this.shiftedDotsOdd.map(d => {
                    return {
                        x: Number.parseFloat(d.x.toFixed(2)).toString(),
                        y: Number.parseFloat(d.y.toFixed(2)).toString(),
                        oculus: d.oculus as any,
                        round: d.round as any,
                        timestamp: d.timestamp as any,
                    };
                }),
                even: this.dotsEven.map(d => {
                    return {
                        x: Number.parseFloat(d.x.toFixed(2)).toString(),
                        y: Number.parseFloat(d.y.toFixed(2)).toString(),
                        oculus: d.oculus as any,
                        round: d.round as any,
                        timestamp: d.timestamp as any,
                    };
                }),
            } as unknown) as T;
        } else {
            const data: { x: string; y: string; oculus: string; round: string; timestamp: string }[] = [
                { x: 'shiftedOdd', y: 'dots', oculus: 'OCULUS', round: 'round', timestamp: 'timestamp' },
            ]
                .concat(
                    this.shiftedDotsOdd.map(d => {
                        return {
                            x: Number.parseFloat(d.x.toFixed(2)).toString(),
                            y: Number.parseFloat(d.y.toFixed(2)).toString(),
                            oculus: d.oculus as any,
                            round: d.round as any,
                            timestamp: d.timestamp as any,
                        };
                    })
                )
                .concat([{ x: 'Even', y: 'dots', oculus: 'OCULUS', round: 'round', timestamp: 'timestamp' }])
                .concat(
                    this.dotsEven.map(d => {
                        return {
                            x: Number.parseFloat(d.x.toFixed(2)).toString(),
                            y: Number.parseFloat(d.y.toFixed(2)).toString(),
                            oculus: d.oculus as any,
                            round: d.round as any,
                            timestamp: d.timestamp as any,
                        };
                    })
                );
            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data),
                wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'RAPD Log');

            XLSX.writeFile(wb, 'Pupil20.xlsx');
        }
    }
    public clear(): void {
        this.dotChart.selectAll('.dot').remove();
        this.dotChart.selectAll('.line').remove();
        this.dotChart.selectAll('.RAPD').remove();
        this.data = [];
        this.dotsEven = [];
        this.dotsOdd = [];
        this.shiftedDotsOdd = [];
        this.startTimestamp = null;
        this.RAPD.next('');
    }
    private calculateShiftedOdd(): number {
        this.shiftedDotsOdd = this.shiftedDotsOdd
            .sort((a, b) => a.x - b.x)
            .map(d => {
                return {
                    x: d.x,
                    y: d.y,
                    oculus: d.oculus,
                    isOdd: d.isOdd,
                    round: d.round,
                    timestamp: d.timestamp,
                };
            });
        const sumX: number = this.shiftedDotsOdd.map(d => d.x).reduce((partial_sum, a) => partial_sum + a, 0),
            sumY: number = this.shiftedDotsOdd.map(d => d.y).reduce((partial_sum, a) => partial_sum + a, 0),
            sumXY: number = this.shiftedDotsOdd.map(d => d.x * d.y).reduce((partial_sum, a) => partial_sum + a, 0),
            sumXX: number = this.shiftedDotsOdd.map(d => d.x * d.x).reduce((partial_sum, a) => partial_sum + a, 0),
            a: number = (sumY * sumXX - sumX * sumXY) / (this.shiftedDotsOdd.length * sumXX - sumX * sumX),
            b: number = (this.shiftedDotsOdd.length * sumXY - sumX * sumY) / (this.shiftedDotsOdd.length * sumXX - sumX * sumX),
            fy = function(x: number): number {
                return a + b * x;
            },
            fx = function(y: number): number {
                return (y - a) / b;
            },
            crossingZeroLineTime: number = fx(0);
        this.shiftedLineOdd = [
            {
                x: 1,
                y: fy(1),
            },
            {
                x: 25.5,
                y: fy(25.5),
            },
        ];

        return crossingZeroLineTime;
    }
    private calculateEven(): number {
        this.dotsEven = this.dotsEven
            .sort((a, b) => a.x - b.x)
            .map(d => {
                return {
                    x: d.x,
                    y: d.y,
                    logX: null,
                    logY: null,
                    oculus: d.oculus,
                    isOdd: d.isOdd,
                    round: d.round,
                    timestamp: d.timestamp,
                };
            });
        const sumX: number = this.dotsEven.map(d => d.x).reduce((partial_sum, a) => partial_sum + a, 0),
            sumY: number = this.dotsEven.map(d => d.y).reduce((partial_sum, a) => partial_sum + a, 0),
            sumXY: number = this.dotsEven.map(d => d.x * d.y).reduce((partial_sum, a) => partial_sum + a, 0),
            sumXX: number = this.dotsEven.map(d => d.x * d.x).reduce((partial_sum, a) => partial_sum + a, 0),
            a: number = (sumY * sumXX - sumX * sumXY) / (this.dotsEven.length * sumXX - sumX * sumX),
            b: number = (this.dotsEven.length * sumXY - sumX * sumY) / (this.dotsEven.length * sumXX - sumX * sumX),
            fy = function(x: number): number {
                return a + b * x;
            },
            fx = function(y: number): number {
                return (y - a) / b;
            },
            crossingZeroLineTime: number = fx(0);
        this.lineEven = [
            {
                x: 1,
                y: fy(1),
            },
            {
                x: 25.5,
                y: fy(25.5),
            },
        ];

        return crossingZeroLineTime;
    }
    private calculateOdd(): void {
        this.dotsOdd = this.dotsOdd
            .sort((a, b) => a.x - b.x)
            .map(d => {
                return {
                    x: d.x,
                    y: d.y,
                    logX: null,
                    logY: null,
                    oculus: d.oculus,
                    isOdd: d.isOdd,
                    round: d.round,
                    timestamp: d.timestamp,
                };
            });
        const sumX: number = this.dotsOdd.map(d => d.x).reduce((partial_sum, a) => partial_sum + a, 0);
        const sumY: number = this.dotsOdd.map(d => d.y).reduce((partial_sum, a) => partial_sum + a, 0);
        const sumXY: number = this.dotsOdd.map(d => d.x * d.y).reduce((partial_sum, a) => partial_sum + a, 0);
        const sumXX: number = this.dotsOdd.map(d => d.x * d.x).reduce((partial_sum, a) => partial_sum + a, 0);
        const a: number = (sumY * sumXX - sumX * sumXY) / (this.dotsOdd.length * sumXX - sumX * sumX);
        const b: number = (this.dotsOdd.length * sumXY - sumX * sumY) / (this.dotsOdd.length * sumXX - sumX * sumX);
        const fy = function(x: number): number {
            return a + b * x;
        };
        let lineOdd: { x: number; y: number }[] = [
            {
                x: 1,
                y: fy(1),
            },
            {
                x: 25.5,
                y: fy(25.5),
            },
        ];
        this.dotChart
            .append('path')
            .attr('class', `line Odd`)
            .attr('fill', 'none')
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .style('opacity', '0')
            .datum(lineOdd)
            .attr('d', this.lineGenerator);
    }
    private getColor(oculus: OCULUS, round: number, isOdd: boolean): string {
        // green: OD
        // dark   #1b5e20 #827717 #4caf50   even
        // light  #e8f5e9 #dcedc8 #f0f4c3   odd

        // yellow: OS
        // dark   #f57f17 #e65100 #ff9800   even
        // light  #fffde7 #ffecb3 #ffff8d   odd
        let color: string;

        switch (round) {
            case 1:
                if (oculus === OCULUS.OD) {
                    if (isOdd) {
                        // odd OD - light green 1
                        color = '#c5e1a5';
                    } else {
                        // even OD - dark green 1
                        color = '#689f38';
                    }
                } else {
                    if (isOdd) {
                        // odd OD - light yellow 1
                        color = '#fff59d';
                    } else {
                        // even OS - dark yellow 1
                        color = '#fbc02d';
                    }
                }
                break;
            case 2:
                if (oculus === OCULUS.OD) {
                    if (isOdd) {
                        // odd OD - light green 2
                        color = '#aed581';
                    } else {
                        // even OD - dark green 2
                        color = '#558b2f';
                    }
                } else {
                    if (isOdd) {
                        // odd OD - light yellow 2
                        color = '#fdd835';
                    } else {
                        // even OS - dark yellow 2
                        color = '#f9a825';
                    }
                }
                break;
            case 3:
                if (oculus === OCULUS.OD) {
                    if (isOdd) {
                        // odd OD - light green 3
                        color = '#9ccc65';
                    } else {
                        // even OD - dark green 3
                        color = '#4caf50';
                    }
                } else {
                    if (isOdd) {
                        // odd OD - light yellow 3
                        color = '#ffee58';
                    } else {
                        // even OS - dark yellow 3
                        color = '#f57f17';
                    }
                }
                break;
            default:
                break;
        }
        return color;
    }
    private lastIndexOf(array: IPupil20TestCamMessage[], key: MESSAGE_TYPE): number {
        for (let i = array.length - 1; i >= 0; i--) {
            if (array[i].message_type === key) return i;
        }
        return -1;
    }
    private isOdd(): boolean {
        return this.odd_even.length % 2 ? true : false;
    }
}
