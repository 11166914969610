import { Acuity4KidsBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/acuity4KidsBulbicamMeasuredDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class Acuity4KidsBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof Acuity4KidsBulbicamMeasuredDataFrontend = Acuity4KidsBulbicamMeasuredDataFrontend;
    public measurements: Acuity4KidsBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
