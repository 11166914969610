import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../commonout/interfaces/charts.model';
import { OCULUS } from '../../../../../common/enums/oculus.enum';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { checkMarkIcon, COLOR_MISSING_DATA, COLOR_UNSEEN, IFixationPoint, instantColor, ITargetData, xIcon } from '../../_components/tests/charts/VisualFieldMerged/const';
import { ChartService } from './chartService';

type ColorGroup = [string, (x: number) => boolean, string];

@Injectable({
    providedIn: 'root',
})
export class VisualFieldMergedChartService extends ChartService {
    edits: any = null;
    public setEdits(edits: IChartEdit[]): void {
        this.edits = edits;
    }
    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }

    public getOnscreenLuxValue(ofScreenValue: number, oculus: OCULUS) {
        const scaleCoefs = { od: 5.973207636, os: 6.346086537 };
        if (oculus === OCULUS.OD) {
            return ofScreenValue * scaleCoefs.od;
        } else if (oculus === OCULUS.OS) {
            return ofScreenValue * scaleCoefs.os;
        }

        return 0;
    }

    testResults: {
        setting?: any;
        results?: {
            targetx?: number;
            targety?: number;
            srt?: number;
            seen?: number;
            targettype?: number;
        }[];
    } | null = {};

    chartData$ = new BehaviorSubject(null);
    isDataRendered$ = new BehaviorSubject(false);

    public addData(frames: ICamMessage[]): Promise<void> {
        this.isDataRendered$.next(false);
        const results = [];
        const length = frames.length;
        let tempFrame: any;
        let fixationPoint: IFixationPoint = { x: 0, y: 0, color: 'rgba(0,0,0,0)' };
        let fixationPoints: IFixationPoint[] = [];

        for (let i = 1; i < length; i++) {
            tempFrame = { ...frames[i] };

            if (tempFrame.targetx) tempFrame.targetx = parseInt(tempFrame.targetx);
            if (tempFrame.targety) tempFrame.targety = parseInt(tempFrame.targety);

            if (tempFrame.metadata) {
                continue;
            }

            // NOTE: own devenv
            if (tempFrame.chartTypeString === 'SACCADES') continue;

            this.rawInputData.push({ ...tempFrame });

            if (tempFrame.message_type === MESSAGE_TYPE.START_PERIPHERAL_SHOWING) {
                this.data = [];
                this.tempX = tempFrame.targetx;
                this.tempY = tempFrame.targety;
            }

            if (tempFrame.message_type === MESSAGE_TYPE.DATA_PACKAGE) {
                if (tempFrame.lineType === 1) {
                    fixationPoint = { x: tempFrame.x, y: tempFrame.y, color: tempFrame.lineColor };
                    fixationPoint.color = '#eb5757'; // design
                    fixationPoints.push(fixationPoint);
                } else {
                    this.data.push(tempFrame);
                }
            }

            if (tempFrame.message_type === MESSAGE_TYPE.STOP_PERIPHERAL_SHOWING) {
                let seenValue = tempFrame.userSeen || tempFrame.seen;

                let logSeen = frames[0].edits ? this.getSeenFromLog(frames[0].edits, tempFrame.timestamp) : tempFrame.seen;
                if (logSeen != null) seenValue = logSeen;

                let targetTypeIndex = seenValue - 1;

                let originalSeenValue = tempFrame.seen;
                let originalTypeIndex = originalSeenValue - 1;

                let srtValue = 0;
                if (frames[0].srt) {
                    srtValue = tempFrame.srt;
                } else if (frames[0].tat) {
                    srtValue = tempFrame.tat;
                }

                if (seenValue === 3) {
                    targetTypeIndex = this.getColoringIndexForSrt(srtValue);
                }

                let entry = {
                    xCoor: this.tempX,
                    yCoor: this.tempY,
                    srt: srtValue,
                    actualSrt: tempFrame.srt,
                    actualTat: tempFrame.tat,
                    actualDelta: Math.abs((tempFrame.tat ?? 0) - (tempFrame.srt ?? 0)),
                    colorRect: this.color[targetTypeIndex],
                    icon: this.icons[targetTypeIndex],
                    status: targetTypeIndex,
                    originalStatus: originalTypeIndex,
                    timestamp: tempFrame.timestamp,
                    tempFrame,
                    seenValue,
                };

                let eyeName = ['OD', 'OS'][tempFrame.targettype];
                let place = this.entries[eyeName];
                if (place) {
                    let { xCoor, yCoor } = entry;
                    // no duplicates:
                    place = place.filter((x: any) => !(x.xCoor === xCoor && x.yCoor === yCoor));
                    this.entries[eyeName] = place;

                    place.push(entry);
                }

                results.push({
                    type: tempFrame.targettype ? 'OS' : 'OD',
                    entry: entry,
                    data: this.data,
                    fixationPoints: fixationPoints,
                });

                fixationPoints = [];
                this.data = [];
            }
        }

        this.chartData$.next({
            renderResults: results,
            entries: this.entries,
        });

        return new Promise((res, rej) => {
            this.isDataRendered$.pipe(filter(data => Boolean(data))).subscribe(() => res());
        });
    }
    public setCamData(frames: ICamMessage[]): void {
        let results = [];
        if (this.edits) {
            frames[0] = { ...frames[0], edits: this.edits };
        }
        for (let frame of frames) {
            if (frame.message_type === MESSAGE_TYPE.START_TEST) {
                const startMessage = frame;
                this.testResults = {
                    setting: startMessage,
                };
            }

            if (frame.message_type === MESSAGE_TYPE.STOP_PERIPHERAL_SHOWING) {
                let seenValue = frame.userSeen || frame.seen;

                let logSeen = frames[0].edits ? this.getSeenFromLog(frames[0].edits, frame.timestamp) : frame.seen;
                if (logSeen != null) seenValue = logSeen;

                let srtValue = 0;
                if (frame.srt) {
                    srtValue = frame.srt;
                } else if (frame.tat) {
                    srtValue = frame.tat;
                }

                results.push({
                    targetx: frame.targetx,
                    targety: frame.targety,
                    srt: srtValue,
                    seen: seenValue,
                    targettype: frame.targettype,
                });
            }
        }

        this.testResults = {
            ...this.testResults,
            results: results,
        };
    }
    public export() {
        return this.testResults;
    }
    public clearData(): void {
        this.testResults = null;
        this.edits = null;
        this.rawInputData = [];
        this.chartData$ = new BehaviorSubject(null);
        //throw new Error("Method not implemented.");
    }

    get noEntries() {
        return {
            OD: [],
            OS: [],
        } as any;
    }
    entries: any = this.noEntries;

    COLOR_GROUPS = instantColor;

    icons = [xIcon, xIcon].concat(this.COLOR_GROUPS.map(() => checkMarkIcon));
    color = [COLOR_UNSEEN, COLOR_MISSING_DATA].concat(this.COLOR_GROUPS.map(x => x[2]));

    data: ITargetData[] = [];
    rawInputData: any = [];

    tempX: any;
    tempY: any;

    getSeenFromLog(edits: any[], id: number) {
        // let edits = this.edits || [];
        let log = edits.filter(x => x.messageTimestamp === id);
        log = _.sortBy(log, x => x.date);
        let entry = _.last(log);
        if (!entry) return null;
        return entry.currentValue;
    }

    getColoringIndexForSrt(srt: number): number {
        let group = this.srtToColorGroup(srt);
        return this.COLOR_GROUPS.indexOf(group) + 2;
    }

    srtToColorGroup(color: number) {
        return this.COLOR_GROUPS.find(x => x[1](color)) as ColorGroup; // always has value...
    }
}
