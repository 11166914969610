// @ts-nocheck
import { TextBlock, XY, Target, Area } from '..';

interface Params {
    text: string;
    point: XY;
    target: Target;
    opacity?: string;
    // allows more...
}

export function TextAt(p: Params) {
    let toArea = (x: XY) => {
        let l = x.x;
        let t = x.y;
        let r = x.x;
        let b = x.y;
        return new Area(l, t, r, b, '');
    };
    return TextBlock({
        ...p,
        area: toArea(p.point),
        styleParams: {
            override: {
                ...(p as any),
                opacity: p.opacity,
            },
        },
    });
}
