import { Component, forwardRef, Input, ViewChildren, ElementRef, QueryList, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'multiselect',
    template: require('./multiselect.component.html'),
    styles: [require('./multiselect.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectComponent),
            multi: true,
        },
    ],
})
export class MultiselectComponent implements ControlValueAccessor {
    @ViewChildren('listItem') itemsList: QueryList<ElementRef>;
    @Output() isFocused = new EventEmitter<boolean>();
    @Output() tabLocker = new EventEmitter<boolean>();
    @Input() private label: string;
    @Input() private counter: boolean;
    @Input() private inputClass: string = 'normalInput';
    private _list: string[];
    private value: string[] = [];
    private preselected: number = null; // index of presentedValue
    private opened: boolean = false;
    private listener: any = null;
    private nativeElement: any;
    private disabled: boolean = false;
    private onChange = (_: any) => {};
    private onTouched = () => {};

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.nativeElement = this.elementRef.nativeElement;
    }
    @HostListener('focus', ['$event']) private onFocus(event: FocusEvent) {
        this.isFocused.emit(true);
        if (!this.listener) this.listener = this.renderer.listen(this.elementRef.nativeElement, 'keyup', this.keyupEventListener.bind(this));
    }
    @HostListener('blur', ['$event']) private onBlur(event: FocusEvent) {
        if (this.opened) {
            this.opened = false;
            this.tabLocker.emit(this.opened);
        }
        this.isFocused.emit(false);
        this.listener();
        this.listener = null;
    }
    @Input() set list(list: string[]) {
        this._list = list;
    }
    writeValue(value: string[]): void {
        if (Array.isArray(value)) {
            this.value = value;
        } else {
            this.value = [];
        }
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    select(value: string, stopPropagation?: boolean) {
        this.onTouched();
        let index: number = this.value.findIndex((v) => v === value);
        if (index !== -1) {
            this.value.splice(index, 1);
        } else {
            this.value.push(value);
        }
        if (!stopPropagation) {
            this.onChange(this.value);
        }
    }
    private keyupEventListener(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (event.key === 'ArrowUp') {
                if (this.preselected === 0) {
                    this.preselected = this._list.length - 1;
                } else {
                    this.preselected--;
                }
                // scroll selected item to container view
                let targetItem: any = this.itemsList.find((item) => item.nativeElement.id.toLowerCase() === this._list[this.preselected].toLowerCase()).nativeElement,
                    container: any = targetItem.parentNode;
                if (container.scrollTop > targetItem.offsetTop || container.offsetHeight < targetItem.offsetTop) {
                    container.scrollTop = targetItem.offsetTop;
                }
                // scroll selected item to container view
            } else if (event.key === 'ArrowDown') {
                if (this.preselected === this._list.length - 1) {
                    this.preselected = 0;
                } else {
                    this.preselected++;
                }
                // scroll selected item to container view
                let targetItem: any = this.itemsList.find((item) => item.nativeElement.id.toLowerCase() === this._list[this.preselected].toLowerCase()).nativeElement,
                    container: any = targetItem.parentNode;
                if (container.offsetHeight < targetItem.offsetTop + targetItem.offsetHeight) {
                    container.scrollTop = targetItem.offsetTop + targetItem.offsetHeight - container.offsetHeight;
                } else if (!targetItem.offsetTop) {
                    container.scrollTop = 0;
                }
                // scroll selected item to container view
            }
        } else if (event.code === 'Space') {
            this.select(this._list[this.preselected]);
        } else if (event.key === 'Enter') {
            this.opened = !this.opened;
            this.tabLocker.emit(this.opened);
        } else if (event.key === 'Escape') {
            if (this.opened) {
                this.opened = false;
                this.tabLocker.emit(this.opened);
            }
        }
    }
}
