import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../../../commonout/interfaces/charts.model';
import { ChartData, FixationChartService } from '../../../../_services/chartServices/fixationChartService';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { BulbicamChartComponent } from '../haploChart.component';

@Component({
    selector: 'fixation-test-chart',
    template: require('./fixation-test-chart.component.html'),
    styles: [require('./fixation-test-chart.component.scss')],
})
export class FixationBulbicamTestChartComponent extends BulbicamChartComponent implements OnInit, AfterViewInit, OnDestroy {
    form = this.fb.group({
        range: [null],
        nMinus: [null],
    });

    data: any;
    proSaccadeAverageRatio: number;

    @ViewChild('fixationChart') svgChart: ElementRef;
    private isDataRendered = this.chartService.isDataRendered$;
    private subscriptions: Subscription[] = [];

    private width!: number;
    private height = 0;
    private pupilChartHeight = 200;
    private chartHeight = 300;
    private velocityChartHeight = 250;
    private margin = 20;

    private maxSubChartDur = 10;
    private threshold = 15;

    private svg: any;
    private svgInner: any[] = [];
    private yDegScale: any[] = [];
    private xScale: any[] = [];
    private xAxis: any[] = [];
    private yDegAxis: any[] = [];
    private ySizeScale: any;
    private ySizeAxis: any[] = [];
    private xSizeAxis: any[] = [];
    private yVelScale: any;
    private yVelAxis: any[] = [];
    private xVelAxis: any[] = [];
    private line: any;
    private lineDefined: any;

    formSub: Subscription;

    constructor(private chartService: FixationChartService, private fb: FormBuilder, private bulbicamService: BulbicamService) {
        super();
    }

    ngOnInit(): void {
        this.line = d3
            .line()
            .x(d => d[0])
            .y(d => d[1]);

        this.lineDefined = d3.line().defined(d => d[1] !== null);

        const sub = this.chartService.charData$.subscribe(data => {
            this.data = data;

            if (data.chartType === 'cleared') {
                this.refreshChart('cleared');
            }

            if (data.chartType === 'recorded') {
                this.drawRecordedChart(data);
            }

            if (data.chartType === 'real-time') {
                this.drawRealTimeChart(data);
            }
        });
        this.subscriptions.push(sub);
    }

    ngAfterViewInit(): void {
        this.width = this.svgChart.nativeElement.getBoundingClientRect().width || window.innerWidth - 100;
        this.initializeChart();
        this.drawAxis();
    }

    public addData(frames: any): void {
        this.chartService.addData(frames);
    }
    public setEdits(edits: IChartEdit[]): void {}

    public clearData(signal: boolean): void {
        this.chartService.clearData();
    }
    setCamData(frames: ICamMessage[]): void {
        this.formSub?.unsubscribe();
        if (frames[0].threshold) {
            this.threshold = frames[0].threshold;
        }
        if (frames[0].moving_average && frames[0].glintNMinus) {
            this.form.get('range').setValue(frames[0].moving_average);
            this.form.get('nMinus').setValue(frames[0].glintNMinus);
        } else {
            this.form.get('range').setValue(2);
            this.form.get('nMinus').setValue(5);
        }
        if (frames[0].ratio) {
            this.proSaccadeAverageRatio = frames[0].ratio;
            this.chartService.averageRatio$.next(this.proSaccadeAverageRatio);
        } else {
            if (this.bulbicamService.hasProperRatio()) {
                this.proSaccadeAverageRatio = this.bulbicamService.proSaccadesRatioValue[this.bulbicamService.proSaccadesRatioValue.length - 1];
                this.chartService.averageRatio$.next(this.proSaccadeAverageRatio);
            }
        }
        this.formSub = this.form.valueChanges
            .pipe(
                debounceTime(500),
                filter(d => d.range !== null && d.nMinus !== null)
            )
            .subscribe(d => {
                this.chartService.clearData();
                this.chartService.getChartData(frames, d.range, d.nMinus);
            });

        const sub = this.chartService.averageRatio$
            .pipe(
                filter(d => !!d && this.form.value.range !== null && this.form.value.nMinus),
                take(1)
            )
            .subscribe(_ => {
                this.chartService.getChartData(frames, this.form.value.range, this.form.value.nMinus);
            });

        this.subscriptions.push(sub);
    }

    drawRealTimeChart(data: ChartData) {
        if (data && data.data) {
            const testDuration = this.chartService.getXvalue(data.data[0].timestamp / 10000, data.data.slice(-1)[0].timestamp);
            if (data.data && data.data.length && testDuration > this.maxSubChartDur) {
                this.maxSubChartDur = this.maxSubChartDur + 10;
                this.resizeChart(this.maxSubChartDur);
            }

            // draw real time diagram
            const rtlineGroup = this.svgInner[0]
                .append('g')
                .append('path')
                .attr('id', 'rtline')
                .style('fill', 'none')
                .style('stroke', '#fff')
                .style('stroke-width', '1px');

            const rtpoints: [number, number][] = (data.realTimeDataCoordinates || []).map(d => [this.xScale[0](d[0]), this.yDegScale[0](d[1])]);

            rtlineGroup.attr('d', this.line(rtpoints));

            // draw recorded chart after real time chart
            if (data.data && data.data.length && data.data[data.data.length - 1].message_type === 1) {
                this.refreshChart();
                this.setCamData(data.data);
            }
        }

        this.isDataRendered.next(true);
    }

    drawRecordedChart(data: ChartData) {
        if (data === null) {
            console.error('Eyetracking was failed. Test has bad raw data');
            return;
        }
        d3.selectAll('#svgFixation > *').remove();

        const accepted = data.detailsInfo.infoList.map(d => d.isAccepted);
        // if (this.chartService.isAcceptedListChanged) {

        this.updateCamMessage({
            ...data.data[0],
            accepted: JSON.stringify(accepted),
            threshold: this.threshold,
            ratio: this.proSaccadeAverageRatio,
            moving_average: this.form.value.range,
            glintNMinus: this.form.value.nMinus,
        });
        // }

        const trials = data.trialInfo.length;
        const subCharts = Math.ceil(data.trialInfo[0].trialDur / this.maxSubChartDur);
        // const chartsAmount = trials * subCharts;

        this.initializeChart('recorded', trials, data.trialInfo[0].trialDur, [data.pupilSizeInfo.minPupilSize[0] - 0.5, data.pupilSizeInfo.maxPupilSize[0] + 0.5]);
        this.drawAxis('recorded', trials, data.trialInfo[0].trialDur);

        let count = 0;

        for (let i = 0; i < trials; i++) {
            for (let j = 0; j < subCharts; j++) {
                const saccades = data.trialInfo[i].sn.filter(d => j * this.maxSubChartDur <= d.right[0] && d.right[0] < (j + 1) * this.maxSubChartDur);

                const dots = [[0, this.threshold]];
                this.svgInner[i * subCharts + j]
                    .selectAll('svg')
                    .data(dots)
                    .enter()
                    .append('circle')
                    .attr('id', 'moved')
                    .attr('r', 7.5)
                    .attr('cx', (d: [number, number]) => {
                        return this.xScale[i * subCharts + j](d[0]);
                    })
                    .attr('cy', (d: [number, number]) => {
                        return this.yVelScale(d[1]);
                    })
                    .style('cursor', 'pointer')
                    .attr('stroke', 'red')
                    .attr('stroke-width', '0')
                    .style('fill', 'green')
                    .style(
                        'transform',
                        `translate(0px, ${this.pupilChartHeight +
                            this.chartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                    )
                    .call(
                        d3
                            .drag()
                            .on('start', d => {
                                d3.selectAll('#moved').attr('stroke-width', '3px');
                            })
                            .on('drag', d => {
                                d[0] = this.xScale[i * subCharts + j].invert(d3.event.x - 500 - (i * subCharts + j) * 750);
                                d[1] = this.yVelScale.invert(d3.event.y - 500 - (i * subCharts + j) * 750);
                                if (d[1] <= 15 || d[1] > 75) {
                                    return;
                                }
                                d3.selectAll('#moved').attr('cy', this.yVelScale(d[1]));

                                d3.selectAll('#dashed-line').attr(
                                    'd',
                                    d3
                                        .line()
                                        .x(d => d[0])
                                        .y(d => d[1])([
                                        [this.xScale[i * subCharts + j](0), this.yVelScale(d[1])],
                                        [this.xScale[i * subCharts + j](10), this.yVelScale(d[1])],
                                    ])
                                );

                                d3.selectAll('#threshold-value')
                                    .attr('dy', this.yVelScale(d[1]))
                                    .text(`${Math.round(d[1])}`);
                            })
                            .on('end', d => {
                                this.threshold = d[1] > 75 ? 75 : d[1] < 15 ? 15 : d[1];
                                this.chartService.updateTrialInfo(this.threshold);
                                d3.selectAll('#moved').attr('stroke-width', '0');
                            })
                    );

                for (let k = 0; k < saccades.length; k += 1) {
                    if (saccades[k].left[0] === null && saccades[k].right[0] === null) {
                        continue;
                    }
                    if (!(k % 5)) {
                        this.svgInner[i * subCharts + j]
                            .append('text')
                            .attr('dx', this.xScale[i * subCharts + j](saccades[k].left[0]))
                            .attr('dy', this.yDegScale[i * subCharts + j](5) + 60)
                            .attr('stroke', `${k % 2 ? 'red' : 'green'}`)
                            .attr('stroke-width', '1px')
                            .attr('font-size', '12px')
                            .style(
                                'transform',
                                `translate(0, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                            )
                            .text(`s${count + k + 1}`);
                    }

                    this.svgInner[i * subCharts + j]
                        .append('circle')
                        .attr('class', 'circle')
                        .attr('cx', this.xScale[i * subCharts + j](saccades[k].left[0]))
                        .attr('cy', this.yDegScale[i * subCharts + j](saccades[k].left[1]) + 60)
                        .attr('r', 2)
                        .attr('stroke', `${k % 2 ? 'red' : 'green'}`)
                        .attr('fill', `${k % 2 ? 'red' : 'green'}`)
                        .style(
                            'transform',
                            `translate(0, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                        );

                    this.svgInner[i * subCharts + j]
                        .append('circle')
                        .attr('class', 'circle')
                        .attr('cx', this.xScale[i * subCharts + j](saccades[k].right[0]))
                        .attr('cy', this.yDegScale[i * subCharts + j](saccades[k].right[1]) + 60)
                        .attr('r', 2)
                        .attr('stroke', `${k % 2 ? 'red' : 'green'}`)
                        .attr('fill', `${k % 2 ? 'red' : 'green'}`)
                        .style(
                            'transform',
                            `translate(0, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                        );
                }

                count += saccades.length;

                const sizeCoords = data.trialInfo[i].pupilSizeCoords.filter(d => j * this.maxSubChartDur < d[0] && d[0] < (j + 1) * this.maxSubChartDur);
                const pupilPoints: [number, number][] = sizeCoords?.map(d => {
                    const xPoint = this.xScale[i * subCharts + j](d[0]);
                    const yPoint = d[1] > 0 ? this.ySizeScale(d[1]) : null;

                    return [xPoint, yPoint];
                });

                this.svgInner[i * subCharts + j]
                    .append('text')
                    .attr('dx', this.xScale[i * subCharts + j](0))
                    .attr('dy', this.ySizeScale(data.pupilSizeInfo.maxPupilSize[1]) + 10)
                    .attr('stroke', '#F2994A')
                    .attr('stroke-width', '1px')
                    .attr('font-size', '16px')
                    .style('transform', `translate(-55px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`)
                    .text(`OS - Pupil Size`);

                this.svgInner[i * subCharts + j]
                    .append('path')
                    .attr('id', `line${i * subCharts + j}`)
                    .style('fill', 'none')
                    .style('stroke', 'orange')
                    .style('stroke-width', '1px')
                    .style('transform', `translate(0px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j) + 60}px)`)
                    .attr('d', this.lineDefined(pupilPoints));

                this.svgInner[i * subCharts + j]
                    .append('circle')
                    .attr('class', 'circle')
                    .attr('cx', this.xScale[i * subCharts + j](0))
                    .attr('cy', this.ySizeScale(data.pupilSizeInfo.minPupilSize[1]) + 60)
                    .attr('r', 2)
                    .attr('stroke', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .attr('fill', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .style('transform', `translate(-20px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`);
                this.svgInner[i * subCharts + j]
                    .append('circle')
                    .attr('class', 'circle')
                    .attr('cx', this.xScale[i * subCharts + j](0))
                    .attr('cy', this.ySizeScale(data.pupilSizeInfo.maxPupilSize[1]) + 60)
                    .attr('r', 2)
                    .attr('stroke', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .attr('fill', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .style('transform', `translate(-20px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`);

                this.svgInner[i * subCharts + j]
                    .append('line')
                    .attr('x1', this.xScale[i * subCharts + j](0))
                    .attr('y1', this.ySizeScale(data.pupilSizeInfo.maxPupilSize[1]) + 60)
                    .attr('x2', this.xScale[i * subCharts + j](0))
                    .attr('y2', this.ySizeScale(data.pupilSizeInfo.minPupilSize[1]) + 60)
                    .attr('stroke', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .style('transform', `translate(-20px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`);

                this.svgInner[i * subCharts + j]
                    .append('text')
                    .attr('dx', this.xScale[i * subCharts + j](0))
                    .attr('dy', this.ySizeScale(data.pupilSizeInfo.maxPupilSize[1]) + 60)
                    .attr('stroke', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .attr('stroke-width', '1px')
                    .attr('font-size', '10px')
                    .style('transform', `translate(-55px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`)
                    .text(`${data.pupilSizeInfo.maxPupilSize[1]}mm`);

                this.svgInner[i * subCharts + j]
                    .append('text')
                    .attr('dx', this.xScale[i * subCharts + j](0))
                    .attr('dy', this.ySizeScale(data.pupilSizeInfo.minPupilSize[1]) + 60)
                    .attr('stroke', data.pupilSizeInfo.selectedEye ? '#ffd580' : '#00ff00')
                    .attr('stroke-width', '1px')
                    .attr('font-size', '10px')
                    .style('transform', `translate(-55px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`)
                    .text(`${data.pupilSizeInfo.minPupilSize[1]}mm`);

                this.svgInner[i * subCharts + j]
                    .append('circle')
                    .attr('class', 'circle')
                    .attr('cx', this.xScale[i * subCharts + j](0))
                    .attr('cy', this.yDegScale[i * subCharts + j](0.5) + 60)
                    .attr('r', 2)
                    .attr('stroke', `#fff`)
                    .attr('fill', `#fff`)
                    .style(
                        'transform',
                        `translate(-20px, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                    );
                this.svgInner[i * subCharts + j]
                    .append('circle')
                    .attr('class', 'circle')
                    .attr('cx', this.xScale[i * subCharts + j](0))
                    .attr('cy', this.yDegScale[i * subCharts + j](-0.5) + 60)
                    .attr('r', 2)
                    .attr('stroke', `#fff`)
                    .attr('fill', `#fff`)
                    .style(
                        'transform',
                        `translate(-20px, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                    );

                this.svgInner[i * subCharts + j]
                    .append('text')
                    .attr('dx', this.xScale[i * subCharts + j](0))
                    .attr('dy', this.yDegScale[i * subCharts + j](0) - 90)
                    .attr('stroke', 'white')
                    .attr('stroke-width', '1px')
                    .attr('font-size', '16px')
                    .style(
                        'transform',
                        `translate(-55px, ${this.pupilChartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                    )
                    .text('OS - Horizontal Movement');

                this.svgInner[i * subCharts + j]
                    .append('line')
                    .attr('x1', this.xScale[i * subCharts + j](0))
                    .attr('y1', this.yDegScale[i * subCharts + j](0.5) + 60)
                    .attr('x2', this.xScale[i * subCharts + j](0))
                    .attr('y2', this.yDegScale[i * subCharts + j](-0.5) + 60)
                    .attr('stroke', 'white')
                    .style(
                        'transform',
                        `translate(-20px, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                    );

                this.svgInner[i * subCharts + j]
                    .append('text')
                    .attr('dx', this.xScale[i * subCharts + j](0) - 60)
                    .attr('dy', this.yDegScale[i * subCharts + j](0))
                    .attr('stroke', 'white')
                    .attr('stroke-width', '1px')
                    .attr('font-size', '10px')
                    .style(
                        'transform',
                        `translate(-80px, ${this.pupilChartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j) +
                            220}px) rotate(270deg)`
                    )
                    .text('1deg');

                const calibrated = data.trialInfo[i].calibratedCoords.filter(d => j * this.maxSubChartDur < d[0] && d[0] < (j + 1) * this.maxSubChartDur);
                const points: [number, number][] = calibrated?.map(d => {
                    const xPoint = this.xScale[i * subCharts + j](d[0]);
                    const yPoint = d[1] !== null ? this.yDegScale[i * subCharts + j](d[1]) : null;

                    return [xPoint, yPoint];
                });

                this.svgInner[i * subCharts + j]
                    .append('text')
                    .attr('dx', this.xScale[i * subCharts + j](0))
                    .attr('dy', this.yDegScale[i * subCharts + j](0))
                    .attr('stroke', 'white')
                    .attr('stroke-width', '1px')
                    .attr('font-size', '16px')
                    .style(
                        'transform',
                        `translate(-55px, ${this.pupilChartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j) + 140}px)`
                    )
                    .text('OS - Velocity of Horizontal Movements');

                this.svgInner[i * subCharts + j]
                    .append('path')
                    .attr('id', `line${i * subCharts + j}`)
                    .style('fill', 'none')
                    .style('stroke', '#fff')
                    .style('stroke-width', '1px')
                    .style('transform', `translate(0px, ${this.pupilChartHeight + (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j) + 60}px)`)
                    .attr('d', this.lineDefined(points));

                const svgVelocity = this.svgInner[i * subCharts + j]
                    .append('g')
                    .append('path')
                    .attr('id', 'line')
                    .style('fill', 'none')
                    .style('stroke', '#fff')
                    .style('stroke-width', '0.3px')
                    .style(
                        'transform',
                        `translate(0px, ${this.pupilChartHeight +
                            this.chartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * subCharts + j)}px)`
                    );

                const velCoords = data.trialInfo[i].velocityCoords.filter(d => j * this.maxSubChartDur < d[0] && d[0] < (j + 1) * this.maxSubChartDur);
                const velocityPoints: [number, number][] = velCoords.map(d => [this.xScale[i * subCharts + j](d[0]), this.yVelScale(d[1] > 90 ? 90 : d[1])]);

                svgVelocity.attr('d', this.lineDefined(velocityPoints));
            }
        }
    }

    private initializeChart(chartType: string = 'no-content', trials: number = 1, trialDur: number = 1, pupilSize: [number, number] = [0, 2]): void {
        //todo
        const lastSubchartDur = Math.ceil(trialDur / this.maxSubChartDur);
        const chartsAmount = trials * lastSubchartDur;
        this.height = (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * chartsAmount;

        this.svg = d3
            .select(this.svgChart?.nativeElement)
            .attr('id', 'svgFixation')
            .attr('height', this.height);

        for (let i = 0; i < trials; i++) {
            for (let j = 0; j < lastSubchartDur; j++) {
                this.svgInner[i * lastSubchartDur + j] = this.svg
                    .append('svg')
                    .style('width', '100%')
                    .style('transform', `translate(${this.margin}px, ${this.margin}px)`);

                const [left, right] = [
                    j * this.maxSubChartDur,
                    // scale to test duration
                    // trialDur < this.maxSubChartDur ?
                    //     trialDur :
                    //     j === lastSubchartDur - 1 ? trialDur :(j + 1) * this.maxSubChartDur
                    (j + 1) * this.maxSubChartDur,
                ];

                this.ySizeScale = d3
                    .scaleLinear()
                    .domain([pupilSize[1], pupilSize[0]])
                    .range([0, this.pupilChartHeight - 2 * this.margin]);

                this.yDegScale[i * lastSubchartDur + j] = d3
                    .scaleLinear()
                    .domain([6, -6])
                    .range([0, this.chartHeight - 2 * this.margin]);

                this.xScale[i * lastSubchartDur + j] = d3
                    .scaleLinear()
                    .domain([left, right])
                    .range([4 * this.margin, this.width - 2 * this.margin]);

                this.ySizeAxis[i * lastSubchartDur + j] = this.svgInner[i * lastSubchartDur + j]
                    .append('g')
                    .attr('id', 'y-size-axis')
                    .attr('class', 'axis-white')
                    .attr('stroke', 'white')
                    .style('transform', `translate(${4 * this.margin}px, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j)}px)`);

                this.yDegAxis[i * lastSubchartDur + j] = this.svgInner[i * lastSubchartDur + j]
                    .append('g')
                    .attr('id', 'y-deg-axis')
                    .attr('class', 'axis-white')
                    .attr('stroke', 'white')
                    .style(
                        'transform',
                        `translate(${4 * this.margin}px, ${this.pupilChartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j)}px)`
                    );

                this.xAxis[i * lastSubchartDur + j] = this.svgInner[i * lastSubchartDur + j]
                    .append('g')
                    .attr('id', 'x-axis')
                    .attr('class', 'axis-white')
                    .attr('stroke', 'white')
                    .style(
                        'transform',
                        `translate(0, ${this.pupilChartHeight +
                            this.chartHeight / 2 +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j) -
                            this.margin}px)`
                    );

                this.yVelScale = d3
                    .scaleLinear()
                    .domain([90, 0])
                    .range([0, this.velocityChartHeight - this.margin]);

                this.yVelAxis[i * lastSubchartDur + j] = this.svgInner[i * lastSubchartDur + j]
                    .append('g')
                    .attr('id', 'y-vel-axis')
                    .attr('class', 'axis-white')
                    .attr('stroke', 'white')
                    .style(
                        'transform',
                        `translate(${4 * this.margin}px, ${this.pupilChartHeight +
                            this.chartHeight +
                            (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j)}px)`
                    );

                this.xVelAxis[i * lastSubchartDur + j] = this.svgInner[i * lastSubchartDur + j]
                    .append('g')
                    .attr('id', 'x-vel-axis')
                    .attr('class', 'axis-white')
                    .attr('stroke', 'white')
                    .style('transform', `translate(0, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j + 1) - this.margin}px)`);

                this.xSizeAxis[i * lastSubchartDur + j] = this.svgInner[i * lastSubchartDur + j]
                    .append('g')
                    .attr('id', 'x-size-axis')
                    .attr('class', 'axis-white')
                    .attr('stroke', 'white')
                    .style('transform', `translate(0, ${(this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j + 1) - this.margin}px)`);
            }
        }
    }

    private drawAxis(chartType: string = 'no-content', trials: number = 1, trialDur: number = 1): void {
        this.svg.attr('width', this.width);

        const lastSubchartDur = Math.ceil(trialDur / this.maxSubChartDur);
        const chartsAmount = trials * lastSubchartDur;

        for (let i = 0; i < trials; i++) {
            for (let j = 0; j < lastSubchartDur; j++) {
                const [left, right] = [
                    j * this.maxSubChartDur,
                    // trialDur < this.maxSubChartDur ?
                    //     trialDur :
                    //     j === lastSubchartDur - 1 ? trialDur :(j + 1) * this.maxSubChartDur
                    (j + 1) * this.maxSubChartDur,
                ];

                if (chartType === 'recorded') {
                    const xVelAxis = d3.axisBottom(this.xScale[i * lastSubchartDur + j]).tickFormat((d: number) => (!(d % Math.floor(right / 2)) ? `${d}` : ''));

                    const yVelAxis = d3.axisLeft(this.yVelScale);

                    this.xVelAxis[i * lastSubchartDur + j].call(xVelAxis);
                    this.yVelAxis[i * lastSubchartDur + j].call(yVelAxis);

                    const dashedLineGroup = this.svgInner[i * lastSubchartDur + j]
                        .append('g')
                        .append('path')
                        .attr('id', 'dashed-line')
                        .style('fill', 'none')
                        .style('stroke', 'green')
                        .style('stroke-dasharray', '7, 7')
                        .style('stroke-width', '1px')
                        .style(
                            'transform',
                            `translate(0, ${this.pupilChartHeight +
                                this.chartHeight +
                                (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j)}px)`
                        );

                    this.svgInner[i * lastSubchartDur + j]
                        .append('text')
                        .attr('id', 'threshold-value')
                        .text(`${this.threshold.toFixed(0)}`)
                        .attr('dx', this.xScale[i * lastSubchartDur + j](0))
                        .attr('dy', this.yVelScale(Math.floor(this.threshold - 1)))
                        .attr('stroke', 'green')
                        .attr('stroke-width', '1px')
                        .attr('font-size', '12px')
                        .style(
                            'transform',
                            `translate(-40px, ${this.pupilChartHeight +
                                this.chartHeight +
                                (this.pupilChartHeight + this.chartHeight + this.velocityChartHeight) * (i * lastSubchartDur + j)}px)`
                        );

                    const dashedPoints: [number, number][] = [left, right].map(d => [this.xScale[i * lastSubchartDur + j](d), this.yVelScale(this.threshold)]);
                    dashedLineGroup.attr('d', this.line(dashedPoints));
                }
            }
        }
    }

    private refreshChart(type: string = '', testDuration: number = 10): void {
        d3.selectAll('#svgFixation > *').remove();
        this.data = {};
        this.svgInner = [];
        this.yDegScale = [];
        this.xScale = [];
        this.xAxis = [];
        this.yDegAxis = [];
        this.yVelScale = null;
        this.ySizeScale = null;
        this.ySizeAxis = [];
        this.xSizeAxis = [];
        this.yVelAxis = [];
        this.xVelAxis = [];
        this.height = this.chartHeight;
        this.maxSubChartDur = 10;
        this.threshold = 15;
        this.initializeChart('cleared', 1, testDuration);
        this.drawAxis('cleared', 1, testDuration);
    }

    resizeChart(testDuration: number): void {
        d3.selectAll('#svgFixation > *').remove();

        this.svgInner[0] = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yDegScale[0] = d3
            .scaleLinear()
            .domain([6, -6])
            .range([0, this.chartHeight]);

        this.xScale[0] = d3
            .scaleLinear()
            .domain([testDuration - 10, testDuration])
            .range([2 * this.margin, this.width - 2 * this.margin]);

        this.yDegAxis[0] = this.svgInner[0]
            .append('g')
            .attr('id', 'y-deg-axis')
            .attr('class', 'axis-white')
            .attr('stroke', 'white')
            .style('transform', `translate(${this.margin}px, 0)`);

        this.xAxis[0] = this.svgInner[0]
            .append('g')
            .attr('id', 'x-axis')
            .attr('class', 'axis-white')
            .attr('stroke', 'white')
            .style('transform', `translate(0, ${this.chartHeight / 2 - this.margin}px)`);
    }

    updateFixationAcceptance(fixationIndex: number): void {
        this.chartService.updateTrialInfoList(fixationIndex);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        d3.select('#svgFixation').remove();
        this.data = { chartType: 'cleared' };
        this.chartService.charData$.next(this.data);
        this.chartService.averageRatio$.next(0);
    }
}
