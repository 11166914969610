/**
 * Each type of test result should be graded by user as "Normal" or "Abnormal"
 * Such grade could be applied for
 * - each eye independently - DOUBLE option
 * - single grade for both eyes - SINGLE
 * - or no grade should be provided at all - NONE
 */
export enum TEST_RESULT_TYPE {
    NONE = 'NONE',
    SINGLE = 'SINGLE',
    DOUBLE = 'DOUBLE',
}
