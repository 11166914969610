import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SocketService } from '../../../_services/general/socket.service';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'login-password',
    template: require('./login-password.component.html'),
    styles: [require('./login-password.component.scss')],
})
export class LoginPasswordComponent implements OnInit, OnDestroy {
    private authForm: FormGroup;
    private error: string;
    private subscriptions: Subscription[];

    constructor(private router: Router, private fb: FormBuilder, private authService: AuthenticationService, private socketService: SocketService) {
        this.subscriptions = [];
    }

    ngOnInit() {
        this.authForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    ngOnDestroy() {
        this.subscriptions.map((subscr) => subscr && subscr.unsubscribe());
    }

    login() {
        try {
            this.authForm.disable();
            const rawFormValue = this.authForm.getRawValue();
            this.subscriptions.push(
                this.authService
                    .loginWithPassword(rawFormValue)
                    .pipe(
                        catchError((error) => {
                            this.error = error.error;
                            return of();
                        })
                    )
                    .subscribe(async () => {
                        localStorage.setItem('greeting', 'true');
                        this.router.navigate(['/']);
                    })
            );
        } catch (error) {
            this.error = error;
        } finally {
            this.authForm.enable();
        }
    }
}
