import { concatMap } from "rxjs/operators";
import { ICamMessage } from "../../../../../../../../../commonout/interfaces/charts.model";
import { IPursuitSaccadesCamMessage } from "../../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface";

const COUNT_OF_SKIPPED = 15;

export class VelocityVerticalComputingService {

    private velocityArray: number[] = [];
    private firstCalibrationIndex: number;
    private secondCalibrationIndex: number;
    private resultFrames: IPursuitSaccadesCamMessage[] = [];
    private isReverse = false;

    public computeVelocityData(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] {
        this.resultFrames = [];
        this.firstCalibrationIndex = 0;
        this.secondCalibrationIndex = 0;
        this.velocityArray = [];
        this.isReverse = false;

        this.computeFirstCalibrationRange(frames);

        for (let index = 0; index < frames.length; index++) {
            if (index + COUNT_OF_SKIPPED < frames.length) {
                if (this.secondCalibrationIndex === index) {

                    if (this.isLastStimuli(frames, index)) {
                        const slicedFrames = frames.slice(0, index);

                        this.resultFrames = this.resultFrames.concat(slicedFrames);
                        frames.splice(0, index - 1);
                        index = 0;

                        this.isReverse = true;
                    }
                    else {
                        const slicedFrames = frames.splice(0, index);

                        this.resultFrames = this.resultFrames.concat(slicedFrames);
                        index = 0;
                    }
                }

                if (index === 0) {
                    
                    if (this.secondCalibrationIndex !== -1){
                        this.firstCalibrationIndex = index;
                        this.secondCalibrationIndex = frames.slice(1)
                            .findIndex(f => f.stimuliOS !== frames[1].stimuliOS);
                    }
                    if (this.secondCalibrationIndex === -1) {
                        this.firstCalibrationIndex = index;
                        this.secondCalibrationIndex = frames.length - 1;
                    }
                }

              
                const calibrationAngle = this.computeHorizontalCalibrationData(frames[this.firstCalibrationIndex],
                    frames[this.secondCalibrationIndex], frames[index], this.isReverse);
                const secondDotAngle = this.computeHorizontalCalibrationData(frames[this.firstCalibrationIndex],
                    frames[this.secondCalibrationIndex], frames[index + COUNT_OF_SKIPPED], this.isReverse);

                if (!frames[index].calibrationAngleOS) { frames[index].calibrationAngleOS = calibrationAngle; }
        
                const time = frames[index].pointX - frames[index + COUNT_OF_SKIPPED].pointX;
        
                const velocity = this.computeVelocityByHorizontalMovement(time, calibrationAngle, secondDotAngle);

                this.velocityArray.push(velocity);
            }
            else {
                const calibrationAngle = this.computeHorizontalCalibrationData(frames[this.firstCalibrationIndex],
                    frames[this.secondCalibrationIndex], frames[index], this.isReverse);
                frames[index].calibrationAngleOS = calibrationAngle;

                if (this.secondCalibrationIndex === index) {
                    const slicedFrames = frames.splice(0, index + 1);
                    this.resultFrames = this.resultFrames.concat(slicedFrames);
                }
                this.velocityArray.push(0);
            }
        }

        this.resultFrames.forEach((frame, index) => {
            frame.pupilvelocity = this.velocityArray[index];
        });


        return this.resultFrames;
    }

    private isLastStimuli(frames: IPursuitSaccadesCamMessage[], index: number): boolean {
        const lastFrames = frames.slice(index);

        this.secondCalibrationIndex = lastFrames
            .findIndex(f => f.stimuliOS !== lastFrames[0].stimuliOS);

        if (this.secondCalibrationIndex === -1) {
            return true
        }
        return false;
    }

    private getFirstCalibrationIndex(frame: ICamMessage, frames: IPursuitSaccadesCamMessage[]) {
        return frames.findIndex(f => f.stimuliOS !== frame.stimuliOS);
    }

    private getSecondCalibrationIndex(frames: IPursuitSaccadesCamMessage[], firstCalibrationIndex: number) {
        return frames.slice(firstCalibrationIndex)
            .findIndex(f => f.stimuliOS !== frames[firstCalibrationIndex ].stimuliOS);
    }

    private computeHorizontalCalibrationData(firstCalibrationDot: ICamMessage,
        secondCalibrationDot: ICamMessage, 
        currentFrame: ICamMessage, 
        isReverse: boolean) {

        const ratio = (firstCalibrationDot.eyeOS - secondCalibrationDot.eyeOS)
            / (firstCalibrationDot.stimuliOS - secondCalibrationDot.stimuliOS);

        const resultRatio = isReverse === false
            ? ratio
            : Math.abs(ratio);

        const calibrationData = resultRatio !== 0
            ? firstCalibrationDot.stimuliOS +
            (currentFrame.eyeOS - firstCalibrationDot.eyeOS) / resultRatio
            : 0;
            
        const calibrationAngle = isReverse === false
            ? calibrationData
            : Math.abs(calibrationData);

        return calibrationAngle;
    }

    private computeFirstCalibrationRange(frames: IPursuitSaccadesCamMessage[]): void {
        for (let index = 0; index < frames.length; index++) {
            if (index === this.firstCalibrationIndex && index !== 0) {
                const slicedFrames = frames.splice(0, index);
                this.resultFrames = this.resultFrames.concat(slicedFrames);
                break;
            }

            this.firstCalibrationIndex = this.getFirstCalibrationIndex(frames[index], frames) - 1;
            if (this.firstCalibrationIndex < 0) { break; }

            this.secondCalibrationIndex = this.getSecondCalibrationIndex(frames, this.firstCalibrationIndex + 1)
                + this.firstCalibrationIndex;

            const calibrationAngle = this.computeHorizontalCalibrationData(frames[this.firstCalibrationIndex],
                frames[this.secondCalibrationIndex], frames[index], this.isReverse);
            const secondDotAngle = this.computeHorizontalCalibrationData(frames[this.firstCalibrationIndex],
                frames[this.secondCalibrationIndex], frames[index + COUNT_OF_SKIPPED], this.isReverse);

            frames[index].calibrationAngleOS = calibrationAngle;
    
            const time = frames[index].pointX - frames[index + COUNT_OF_SKIPPED].pointX;
    
            const velocity = this.computeVelocityByHorizontalMovement(time, calibrationAngle, secondDotAngle);

            this.velocityArray.push(velocity);
        }
    }

    private computeVelocityByHorizontalMovement(time: number,
        calibrationAngle: number, secondDotAngle: number): number {
        return time !== 0
            ? Math.abs((secondDotAngle - calibrationAngle) / time)
            : 0;
    }
}
