// @ts-nocheck
export const errorPoints = [
    { age: 100, mean: 330, sd: 20 },
    { age: 70, mean: 200, sd: 20 },
    { age: 25, mean: 100, sd: 20 },
    { age: 7, mean: 470, sd: 20 },
];

export const successPoints = [
    { age: 100, mean: 380, sd: 40 },
    { age: 70, mean: 320, sd: 40 },
    { age: 25, mean: 280, sd: 40 },
    { age: 7, mean: 600, sd: 40 },
];

export const successPercentLinesByAge = {
    7: [
        { x: 0, y: 0 },
        { x: 500, y: 0.5 },
        { x: 1000, y: 0.6 },
    ],
    25: [
        { x: 0, y: 0 },
        { x: 250, y: 0.6 },
        { x: 500, y: 0.7 },
        { x: 1000, y: 0.8 },
    ],
    70: [
        { x: 0, y: 0 },
        { x: 250, y: 0.5 },
        { x: 500, y: 0.6 },
        { x: 1000, y: 0.7 },
    ],
    100: [
        { x: 0, y: 0 },
        { x: 250, y: 0.4 },
        { x: 500, y: 0.5 },
        { x: 1000, y: 0.6 },
    ],
};

export const errorPercentLinesByAge = {
    7: [
        { x: 0, y: 0 },
        { x: 500, y: 0.4 },
        { x: 1000, y: 0.4 },
    ],
    25: [
        { x: 0, y: 0 },
        { x: 250, y: 0.1 },
        { x: 500, y: 0.2 },
        { x: 1000, y: 0.2 },
    ],
    70: [
        { x: 0, y: 0 },
        { x: 250, y: 0.2 },
        { x: 500, y: 0.3 },
        { x: 1000, y: 0.3 },
    ],
    100: [
        { x: 0, y: 0 },
        { x: 250, y: 0.2 },
        { x: 500, y: 0.3 },
        { x: 1000, y: 0.4 },
    ],
};
