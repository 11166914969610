import { DEVICE } from '../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../commonout/enum/test-type';
import { IWebCamImportsMeasuredData } from '../../interfaces/measuredData/webCamImports.measuredData.interface';
import { SmartImage } from '../smartImage.class';
import { MeasuredData } from './measuredData.model';

export abstract class WebCamImportsMeasuredData implements MeasuredData, IWebCamImportsMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public images: SmartImage[];
    constructor() {
        this.sourceDevice = DEVICE.DEFAULT_TEST;
        this.testType = TEST_TYPE.WEBCAM_IMPORTS;
    }
    public abstract setModel(model: IWebCamImportsMeasuredData): void;
    public getModel(): IWebCamImportsMeasuredData {
        let model: IWebCamImportsMeasuredData = {
            sourceFileName: this.sourceFileName,
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            images: this.images.map((i) => i.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.images.length === 0;
    }
}
