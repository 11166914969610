// @ts-nocheck
import * as _ from 'lodash'
// import * as d3 from 'd3'
import chroma from 'chroma-js'

import {
  Header, Memo,
  AData, AChart2, ChartLines,
  AxisLeft, AxisRight, AxisBottom,
  ChartDots,
  VTable,
  XY,
  Line,
  Target,
  Circle,
  HoverLayers,
  Area,
  ghost,
  ChartBg,
} from '../../lib'

import { Place, Targets, State, Protocol, makeLayout, FakeData } from './index'
import { BaseDiagram } from './abstract'
// import { DataSet } from './data/State'
import { DataPoint } from './data/Protocol'
import { getColor } from './ui/colorScale'
import { USE_FAKE_DATA, PRECISION, PRECISION_BR } from './constants'
import { translate } from '../../lib/util'
import { DataSet } from './data/State'

// const STIM_AXIS = 'shown numbers: stimulus distance, mm.'
const STIM_AXIS = 'stimulus distance, mm.'

export interface Props {
  place: Place
}

// NOTE: refactoring ideas
// - split targets from svg setup or even modes setup
// - ui state / data state though
// - find where to use memo, where to use invalidate

let ROW_SIZE = 50 // table

export class Diagram extends BaseDiagram<State, Protocol> {
  cache = new Memo()
  memoize(name: string, fn: any) {
    return this.cache.access(name, fn)
  }

  context = this.renderingContext()

  reset() {
    this.state = new State()
    this.protocol = new Protocol(this.state)
  }

  renderingContext() {
    let lo = makeLayout()

    let mode = 0
    let g = new Targets({
      place: this.params.place,
      layout: lo.layout,
      state: { previousMode: 0 },
      mode,
    })

    let ds = this.state.viewDataSets

    return {
      lo,
      g,
      ds,
    }
  }

  renderHeaders() {
    let { lo, g } = this.context

    Header({
      text: 'GAZE',
      area: lo.leftHeader,
      target: g.default,
    })
    Header({
      text: 'PUPILS',
      area: lo.rightHeader,
      target: g.default,
    })

    // Header({
    //   text: 'OD',
    //   area: lo.rightChart.copy().takeTop(50).takeLeft(60),
    //   target: g.default,
    //   styleParams: { override: { fontSize: 24 } },
    // })
    // Header({
    //   text: 'OS',
    //   area: lo.rightChart.copy().takeTop(50).takeRight(60),
    //   target: g.default,
    //   styleParams: { override: { fontSize: 24 } },
    // })
  }

  gazeAData: any
  renderGazeChart() {
    let { lo, g, ds } = this.context

    let series = ds
    .filter(x => x.show)
    .map(x => {
      let { dataPoints } = x
      let updated = halven(dataPoints.concat([{ break: true } as any], reverseLine(dataPoints)))
      return {
        ...x,
        coloring: getColor(x.id, ds.length),
        dataPoints: updated,
      }
    })
    let positioning = (point: DataPoint) => {
      if (point.break) return { x: 0, y: 0, break: true }
      let x = point.eyes_dist
      let y = point.time
      return { x, y }
    }
    let coloring = (point: DataPoint, set: any) => set.coloring

    let area = lo.leftChart
    let aData: any = AData<any>({
      series,
      positioning,
      coloring,
    })
    this.gazeAData = aData
    let ctx = AChart2<DataPoint>({
      aData,
      target: g.default,
      area,
      zeroAtTheRight: true,
    })
    Line({
      from: area.tl.withX(area.cx),
      to: area.bl.withX(area.cx),

      target: g.default,
    })
    ChartLines({
      ...ctx,
      defined: (d) => !d.break && d.x,
      target: g.default,
      styleParams: { override: {
        lineSize: 1,
      }},
    })
    let focusDataSet = ds.find(x => x.id === this.state.ui.focusRun) as DataSet
    let axisLeftParams = {
      ...ctx,
      grid: true,
      area,
      target: g.default,
    }
    if (!focusDataSet) {
      // AxisLeft({
      //   ...axisLeftParams,
      //   arrow: STIM_AXIS,
      //   ownTicks: () => focusDataSet.distanceLines,
      // })
    // } else {
      AxisLeft({
        ...axisLeftParams,
        ticks: 5,
        arrow: 'time, sec.',
      })
    }
    AxisBottom({
      ...ctx,
      grid: true,
      noText: true,
      noTicks: true,
      arrow: 'convergence angle',

      area,
      target: g.default,
    })
  }

  renderKeyLines() {
    let { lo, g, ds } = this.context

    let series = ds
    .filter(x => x.show)
    .map(dataSet => {
      let dataPoints = dataSet.viewKeyLines()
      return {
        ...dataSet,
        coloring: getColor(dataSet.id, ds.length),
        dataPoints,
      }
    })
    let coloring = (point: DataPoint, set: any) => set.coloring

    let positioning = (x:any) => ({ ...x, text: undefined })
    let runs = [
      { area: lo.leftChart, positioning, hide: false, showDots: false },
      { area: lo.rightChart, positioning, hide: false, showDots: false, },
      { area: lo.betweenCharts, positioning, hide: this.state.ui.focusRun == null, showDots: false },
    ]

    runs.forEach(({ area, positioning, hide, showDots }) => {
      let s = hide ? [] : series
      let aData: any = AData<any>({
        series: s,
        positioning,
        coloring,
      })
      aData = { ...aData, yScale: this.gazeAData.yScale }

      let ctx = AChart2<DataPoint>({
        aData,
        target: g.default,
        area,
        showDots,
        zeroAtTheRight: true,
      })
      ChartDots({
        ...ctx,
        shape: 'wline',
        target: g.default,

        styleParams: {
          override: {
            lineSize: 1,
            // dasharray: '10 1',
            class: 'svg-crisp',
          },
        }
      })
    })
  }

  pdAData: any
  renderPDChart() {
    let { lo, g, ds } = this.context

    let maxPd = Math.max(...ds.map(x => x.maxPd || 0)) || 0

    let series = ds
    .filter(x => x.show)
    .map(x => {
      let { dataPoints } = x
      let updated = halven(dataPoints.concat([{ break: true } as any], reverseLine(dataPoints.map(x => ({ ...x, reverse: true })))))
      return {
        ...x,
        coloring: getColor(x.id, ds.length),
        dataPoints: updated,
      }
    })
    let positioning = (point: DataPoint) => {
      if (point.break) return { x: 0, y: 0, break: true }
      let x = point.reverse ? -point.od_pd : point.os_pd
      let y = point.time
      return { x, y }
    }
    let coloring = (point: DataPoint, set: any) => set.coloring

    let area = lo.rightChart
    let aData: any = AData<any>({
      series,
      positioning,
      coloring,
      xFrom: -maxPd,
      xTo:    maxPd,
    })
    aData = { ...aData, yScale: this.gazeAData.yScale }
    this.pdAData = aData
    let ctx = AChart2<DataPoint>({
      aData,
      target: g.default,
      area,
      // showDots: false,
      // zeroAtTheRight: true,
    })
    Line({
      from: area.tl.withX(area.cx),
      to: area.bl.withX(area.cx),

      target: g.default,
    })
    ChartLines({
      ...ctx,
      defined: (d) => !d.break && d.x,
      target: g.default,
      styleParams: { override: {
        lineSize: 1,
      }},
    })
    let focusDataSet = ds.find(x => x.id === this.state.ui.focusRun) as DataSet
    let rightAxisParams = {
        ...ctx,
        grid: true,
        area,
        target: g.default,
        arrow: ''
    }
    if (focusDataSet) {
      AxisRight({
        ...rightAxisParams,
        ownTicks: () => focusDataSet.distanceLines,
        arrow: STIM_AXIS,
      })
    } else {
      let item = ds[0]
      AxisRight({
        ...rightAxisParams,
        ownTicks: () => item ? item.distanceLines : [],
        arrow: STIM_AXIS,
      })
    //   AxisRight({
    //     ...rightAxisParams,
    //     ticks: 5,
    //     arrow: 'time, sec.',
    //   })
    }
    // AxisRight({
    //   ...ctx,
    //   grid: true,
    //   ownTicks: () => ownTicks,
    //   // rawTickFormat: (x,y) => {
    //   //   return (Math.abs(x) + this.minz) + ''
    //   // },

    //   area,
    //   target: g.default,
    // })
    let moreStuff = {} as any
    if (maxPd < 4) moreStuff = { ticks: 5 }
    AxisBottom({
      ...ctx,
      grid: true,
      // noText: true,
      // noTicks: true,
      rawTickFormat: (x,y) => {
        return Math.abs(x) + ''
      },
      ...moreStuff,

      area,
      target: g.default,
      arrowReverse: 'diameter, mm.',
    })
  }

  get minz() {
    return this.state.minz
  }

  renderTable2() {
    let { g, ds } = this.context

    let breaks = ds.map(x => x.break?.stimuli_dist)
    let recoveries = ds.map(x => x.recovery?.stimuli_dist)
    let pdod = ds.map(x => x.pupilZOD || 0)
    let pdos = ds.map(x => x.pupilZOS || 0)

    let meanbr = _.mean(breaks).toFixed(PRECISION_BR)
    let meanrec = _.mean(recoveries).toFixed(PRECISION_BR)
    let meanpdod = _.mean(pdod).toFixed(PRECISION)
    let meanpdos = _.mean(pdos).toFixed(PRECISION)

    let HEADER = [
      'MEAN',
      meanbr,
      meanrec,
      meanpdod,
      meanpdos,
    ]
    let h = (a: any[]) => {
      let header = HEADER.shift()
      return [header].concat(a) as any
    }
    let target = g.default
    let area = this.table2Area

    let table = {
      rowHeaders: [HEADER.shift() || ''],
      columns: [
        { at: 1,  rows: h([]) },
        { at: 2,  rows: h([]) },
        { at: 3.1,    rows: h([]) },
        { at: 4.15,  rows: h([]) },
      ],
    }

    VTable({
      domainX: [0, 4.95],
      ...table,

      target,
      area,
    })
  }

  renderTable() {
    let { g, ds } = this.context

    let HEADER = [
      'TRIAL',
      'BREAK,mm',
      'RECOVERY,mm',
      // 'IOD,mm*s',
      // 'IOS,mm*s',
      'PUPIL∑OD,mm',
      'PUPIL∑OS,mm',
    ]
    let h = (a: any[]) => {
      let header = HEADER.shift()
      return [header].concat(a) as any
    }

    let target = g.default
    let area = this.tableArea

    let rowHeaders = ds
      .map((x, i) => {
        let text = (i + 1) + ''
        return {
          text,
          render(g: Target) {
            let color = getColor(i, ds.length)
            Circle({
              center: { x: 30, y: 7, },
              radius: 8,
              target: g,
              styleParams: { override: {
                fillColor: color,
                lineColor: 'transparent',
              }},
            })
            g.selectAll('image')
              .data(x.stimuliData)
              .join('image')
              .attr('x', -15 + 60)
              .attr('y', -15 + 5)
              .attr('width', 30)
              .attr('height', 30)
              .attr('href', d => d.image) //'/frontend/src/assets/images/Animals images/Cat.png')
          },
        }
      })
    let breaks = ds.map(x => x.break?.stimuli_dist.toFixed(PRECISION_BR))
    let recoveries = ds.map(x => x.recovery?.stimuli_dist.toFixed(PRECISION_BR))
    // let iod = ds.map(x => x.iOdArea.toFixed(PRECISION))
    // let ios = ds.map(x => x.iOsArea.toFixed(PRECISION))
    let pdod = ds.map(x => x.pupilZOD?.toFixed(PRECISION) || '')
    let pdos = ds.map(x => x.pupilZOS?.toFixed(PRECISION) || '')

    let table = {
      rowHeaders: h(rowHeaders),
      columns: [
        { at: 1,  rows: h(breaks) },
        { at: 2,  rows: h(recoveries) },
        // { at: 3-0.05,    rows: h(iod) },
        // { at: 3.7,  rows: h(ios) },
        { at: 3.1,    rows: h(pdod) },
        { at: 4.15,  rows: h(pdos) },
      ],
    }

    VTable({
      domainX: [0, 4.95],
      ...table,

      target,
      area,
    })
  }


  renderDotsChart() {
    let { lo, g, ds } = this.context

    let series = ds.map(dataSet => {
      let dataPoints = [] as XYT[]

      if (dataSet.break) {
        let x = dataSet.id + 1
        let y = dataSet.break.stimuli_dist
        let text = 'B'
        dataPoints.push({
          x, y, text
        })
      }

      if (dataSet.recovery) {
        let x = dataSet.id + 1
        let y = dataSet.recovery.stimuli_dist
        let text = 'R'
        dataPoints.push({
          x, y, text
        })
      }

      return {
        ...dataSet,
        dataPoints,
        coloring: getColor(dataSet.id, ds.length),
      }
    })

    let positioning = (point: XYT) => point
    let coloring = (point: XYT, set: any) => set.coloring

    let area = lo.smallChart1
    let aData: any = AData<any>({
      series,
      positioning,
      coloring,
    })
    let ctx = AChart2<DataPoint>({
      aData,
      target: g.default,
      area,
    })
    // ChartBg({
    //   ...ctx,
    //   target: g.default,
    // })
    AxisLeft({
      ...ctx,
      grid: true,
      ticks: 5,
      arrow: 'stimulis distance, mm.',

      area,
      target: g.default,
    })
    AxisBottom({
      ...ctx,
      grid: true,
      ownTicks: () => _.times(ds.length, x => ({ value: x+1, text: (x+1)+'' })),
      arrow: 'trial',

      area,
      target: g.default,
    })

    let prevAData = aData
    if (true) {
      let line1 = [] as XY[]
      let line2 = [] as XY[]
      ds.forEach(dataSet => {
        if (dataSet.break) {
          let x = dataSet.id + 1
          let y = dataSet.break.stimuli_dist
          line1.push({
            x, y
          })
        }

        if (dataSet.recovery) {
          let x = dataSet.id + 1
          let y = dataSet.recovery.stimuli_dist
          line2.push({
            x, y
          })
        }
      })

      let series = [] as any
      series.push({
        id: 0,
        dataPoints: line1,
      })
      series.push({
        id: 1,
        dataPoints: line2,
      })

      let aData: any = AData<any>({
        series,
        positioning,
        coloring: (x:any) => '',
      })
      aData = { ...aData,
        yScale: prevAData.yScale,
        xScale: prevAData.xScale,
      }
      let ctx = AChart2<DataPoint>({
        aData,
        target: g.default,
        area,
      })
      ChartLines({
        ...ctx,
        target: g.default,

        styleParams: {
          override: {
            lineSize: 1,
            class: 'sContrast',
          },
        }
      })
    }

    ChartDots({
      ...ctx,
      target: g.default,
      withText: true,

      styleParams: {
        override: {
          // dotRadius: 7,
          dotRadius: 10,
          fillColor: 'fill',
          fontSize: 15,
          fontColor: '#123',
          fontWeight: 800,
        },
      }
    })
  }

  renderNumbersBetweenCharts() {
    let { lo, g, ds } = this.context

    let series = ds
    .filter(x => x.show)
    .map(dataSet => {
      let dataPoints = dataSet.viewKeyLines()
        .map(x => ({ ...x, x: 1 }))
        .map(x => {
          let prefix = ''
          if (x.isBreak) {
            prefix = 'B'
          } else {
            prefix = 'R'
          }
          return {
            ...x,
            text: `${prefix}${dataSet.number}: ${x.text}mm`,
          }
        })
      return {
        ...dataSet,
        coloring: getColor(dataSet.id, ds.length),
        dataPoints,
      }
    })

    let area = lo.betweenCharts
    let positioning = _.identity
    let coloring = (point: DataPoint, set: any) => set.coloring

    let aData: any = AData<any>({
      series,
      positioning,
      coloring,

      xFrom: 0,
      xTo: 2,
    })
    aData = { ...aData, yScale: this.gazeAData.yScale }
    let ctx = AChart2<DataPoint>({
      aData,
      target: g.default,
      area,
      showDots: false,
    })
    ChartDots({
      ...ctx,
      shape: 'square',
      withText: true,
      target: g.default,
      styleParams: { override: {
        rx: 65,
        ry: 15,
        corner: 15,
        // fillColor: 'fill',
        // fontColor: '#123',
        fontColor: '#dce2e1',
        lineSize: 1,
        class: 'fBg',
      }},
    })
  }

  get tableArea() {
    let { lo, ds } = this.context
    let area = lo.smallChart2.copy().takeTop(ds.length * ROW_SIZE)
    return area
  }
  get table2Area() {
    let SIZE = 50

    let { lo, ds } = this.context
    let area = lo.smallChart2.copy()
    area.takeTop(ds.length * ROW_SIZE)

    return area.takeTop(SIZE)
  }

  renderTableLayer() {
    let { g, ds } = this.context

    let area = this.tableArea
    let row_size = area.h / (ds.length + 1)

    let parts = _.times(ds.length, i => {
      let a = Area.xywh(
        area.left,
        area.top + i * row_size,
        area.w - 30,
        row_size,
      ).slide(-15, row_size * 2/3)
      return {
        area: a,
        id: i,
      }
    })

    HoverLayers({
      parts,
      target: g.default,
      onHover: x => {
        let { id } = x
        if (this.state.ui.focusRun !== id) {
          this.state.ui.focusRun = id
          this.refresh()
        }
      },
      onLeave: x => {
        let id = undefined
        if (this.state.ui.focusRun !== id) {
          this.state.ui.focusRun = id
          this.refresh()
        }
      },
    })

    let target = g.default
    let focused = parts.filter(x => x.id === this.state.ui.focusRun)
    target
      .selectAll('rect')
      .data(focused)
      .join('rect')
      .attr('transform', d => translate(d.area.tl))
      .attr('width', d => d.area.w)
      .attr('height', d => d.area.h)
      .attr('class', 'sContrast fNone')
      .call(ghost)
  }

  renderDotsChartLayer() {
    let { lo, g, ds } = this.context

    let area = lo.smallChart1
    let colSize = area.w / (ds.length - 1)

    let parts = _.times(ds.length, i => {
      let a = Area.xywh(
        area.left + i * colSize,
        area.top,
        colSize,
        area.h,
      )
      .slide(-colSize/2, 15)
      .padY(15)
      return {
        area: a,
        id: i,
      }
    })

    HoverLayers({
      parts,
      target: g.default,
      onHover: x => {
        let { id } = x
        if (this.state.ui.focusRun !== id) {
          this.state.ui.focusRun = id
          this.refresh()
        }
      },
      onLeave: x => {
        let id = undefined
        if (this.state.ui.focusRun !== id) {
          this.state.ui.focusRun = id
          this.refresh()
        }
      },
    })

    let target = g.default
    let focused = parts.filter(x => x.id === this.state.ui.focusRun)
    if (focused.length) {
      let f = focused[0] as any
      f.area = f.area.centeredWithW(50)
    }
    target
      .selectAll('rect')
      .data(focused)
      .join('rect')
      .attr('transform', d => translate(d.area.tl))
      .attr('width', d => d.area.w)
      .attr('height', d => d.area.h)
      .attr('class', 'sContrast fNone')
      .call(ghost)
  }

  getPolygons() {
    let relevant = this.context.ds.find(x => x.id === this.state.ui.focusRun)
    if (!relevant) return []

    let color = getColor(this.state.ui.focusRun || 0, this.context.ds.length)
    color = chroma(color).alpha(0.3).toString()
    return relevant.viewPolygons().map(x => ({
      ...x,
      color,
    }))
  }

  renderPupilAreas() {
    let { g } = this.context
    let { xScale, yScale } = this.pdAData

    // let area = lo.rightChart
    let target = g.default

    let data = this.getPolygons()

    target
      .selectAll('polygon')
      .data(data)
      .join('polygon')
      // .attr('points', (d:any) => d.pointsStr)
      .attr('points', (d) => d.points.map(p => [xScale(p.x), yScale(p.y)].join(',')).join(' '))
      .style('fill', d => d.color)
  }

  render() {
    if (USE_FAKE_DATA) {
      this.memoize('onceRender', () => {
        new FakeData().fill(this.protocol)
        return 1
      })
    }

    this.context = this.renderingContext()
    this.renderHeaders()
    this.renderGazeChart()
    this.renderPDChart()
    this.renderKeyLines()
    this.renderTable()
    this.renderTable2()
    this.renderDotsChart()
    this.renderNumbersBetweenCharts()
    this.renderTableLayer()
    this.renderDotsChartLayer()
    this.renderPupilAreas()
  }

  get exportData(): ExportData {
    let runs = this.state.dataSets
    let gets = (eye: string, field: string) => {
      return runs.map(x => x[`${eye}ExportData`][field])
    }

    return {
      od: {
        start: gets('od', 'start'),
        breakk: gets('od', 'breakk'),
        recovery: gets('od', 'recovery'),
        max: gets('od', 'max'),
        atMaxStimDist: gets('od', 'atMaxStimDist'),
        atBreakStimDist: gets('od', 'atBreakStimDist'),
        atRecStimDist: gets('od', 'atRecStimDist'),
      },
      os: {
        start: gets('os', 'start'),
        breakk: gets('os', 'breakk'),
        recovery: gets('os', 'recovery'),
        max: gets('os', 'max'),
        atMaxStimDist: gets('os', 'atMaxStimDist'),
        atBreakStimDist: gets('os', 'atBreakStimDist'),
        atRecStimDist: gets('os', 'atRecStimDist'),
      },
    }
  }
}

export interface ExportData {
  od: ExportDataEye
  os: ExportDataEye
}
interface ExportDataEye {
  start: number[]
  breakk: number[]
  recovery: number[]
  max: number[]
  atMaxStimDist: number[]
  atBreakStimDist: number[]
  atRecStimDist: number[]
}

function reverseLine(xs: DataPoint[]) {
  xs = [...xs]
  xs.reverse()
  return xs.map(x => ({ ...x, eyes_dist: -x.eyes_dist }))
}
function halven(xs: DataPoint[]) {
  return xs.map(x => ({ ...x, eyes_dist: x.eyes_dist / 2 }))
}

interface XYT {
  x: number
  y: number
  text: string
}
