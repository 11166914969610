import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PostSegmentNidekAfc330MeasuredDataFrontend } from '../../../measuredData/NIDEK/AFC330/postSegmentNidekAfc330DataFrontend.class';

export class PostSegmentNidekAfc330TestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PostSegmentNidekAfc330MeasuredDataFrontend = PostSegmentNidekAfc330MeasuredDataFrontend;
    public measurements: PostSegmentNidekAfc330MeasuredDataFrontend[];
    constructor() {
        super();
    }
}
