// @ts-nocheck
import * as _ from 'lodash'
import * as d3 from 'd3'
import chroma from 'chroma-js'

import {
  Header, Memo,
  AData, AChart2, ChartLines,
  AxisLeft, AxisRight, AxisBottom, AxisTop,
  TextBlock,
  ChartDots,
  ChartImages,
  VTable,
  XY,
  Line,
  Target,
  Circle,
  HoverLayers,
  Area,
  ghost,
  // drawPreview,
  Render,
  NumberInput,
  BooleanControl,
  Rectangle,
  Brand as B,
} from '../../lib'

import { Place, Targets, State, Protocol, makeLayout, FakeData } from './index'
import { BaseDiagram } from './abstract'
// import { DataSet } from './data/State'
import { DataPoint } from './data/Protocol'
import { getColor } from './ui/colorScale'
import { LOGMAR_TO_VIRTUAL, LOGMAR_VIRTUAL_DOMAIN, LOGMAR_TICKS,
// ,I_ACUITY, I_CONTRAST, I_FREQ, I_CONTRAST2,
  PATIENT_TYPE_WITH_SMILES,
  ANIMALS_DOMAIN,
  SMILES_DOMAIN,
  SMILE_IMG_INDEX,
} from './constants'
import { translate, doTranslate } from '../../lib/util'
// import { DataSet, ACUITY, CONTRAST, FREQ, PUPIL, CONTRAST2 } from './data/State'

// const STIM_AXIS = 'shown numbers: stimulus distance, mm.'
const STIM_AXIS = 'stimulus distance, mm.'
// const INITIAL_TEST_DURATION = 60 // sec

const GRAY = 'gray'

export interface Props {
  place: Place
}

// NOTE: refactoring ideas
// - split targets from svg setup or even modes setup
// - ui state / data state though
// - find where to use memo, where to use invalidate

let ROW_SIZE = 50 // table

export class Diagram extends BaseDiagram<State, Protocol> {
  cache = new Memo()
  memoize(name: string, fn: any) {
    return this.cache.access(name, fn)
  }

  context = this.renderingContext()

  reset() {
    this.state = new State()
    this.protocol = new Protocol(this.state)
  }

  renderingContext() {
    let lo = makeLayout()

    let useSmiles = this.state.patientType === PATIENT_TYPE_WITH_SMILES
    let yDomain = useSmiles ? SMILES_DOMAIN : ANIMALS_DOMAIN

    let mode = 0
    let g = new Targets({
      place: this.params.place,
      layout: lo.layout,
      state: { previousMode: 0 },
      mode,
    })

    let ds = this.state.viewDataSets

    return {
      lo,
      g,
      ds,

      useSmiles,
      yDomain,
    }
  }

  renderImages() {
    let { lo, g, ds } = this.context
    let { useSmiles, yDomain } = this.context

    let points
    if (useSmiles) {
      points = [{ image: SMILE_IMG_INDEX, time_test: 0 }]
    } else {
      points = _.times(yDomain[1]+1, i => {
        return { image: i, time_test: 0 }
      })
    }

    let series = [{
      id: 0,
      dataPoints: points,
    }]

    let aData = AData<any>({
      yFrom: yDomain[1],
      yTo: yDomain[0],

      series,
      positioning: p => {
        return {
          x: p.time_test,
          y: p.image,
        }
      },
      coloring: p => {
        return B.orange
      },
    })
    let ctx = AChart2<any>({
      aData,
      target: g.g,
      area: lo.diagram,
    })
    ChartImages({
      ...ctx,
      target: g.g,
      style: {
        dx: -40,
        forceX: 50,
      },
    })
  }

  chartAData: any = null
  renderChart() {
    let { lo, g, ds } = this.context
    let { useSmiles, yDomain } = this.context

    let points = _.flatten(ds.map(x => x.dataPoints))
    let series = [{
      id: 0,
      dataPoints: points,
    }]

    let aData = AData<any>({
      yFrom: yDomain[1],
      yTo: yDomain[0],

      series,
      positioning: p => {
        return {
          x: p.time_test,
          y: p.image, //size,
        }
      },
      coloring: p => {
        if (p.eye === 0 && !p.seen) return '#040'
        if (p.eye === 1 && !p.seen) return '#564017'

        if (p.eye === 0) return B.green
        return B.orange
      },
    })
    this.chartAData = aData

    // let logmarVirtualScale = d3.scaleLinear()
    //   .domain(LOGMAR_VIRTUAL_DOMAIN)
    //   .range(lo.diagram.rangeY())
    // aData = {
    //   ...aData,
    //   yScale: logmarVirtualScale,
    // }

    let ctx = AChart2<any>({
      aData,
      target: g.g,
      area: lo.diagram,
    })

    // ChartDots({
    //   ...ctx,
    //   target: g.g,
    //   styleParams: {
    //     override: {
    //       dotRadius: 8, lineSize: 5,
    //       // dotRadius: 8, fillColor: 'fill',
    //     }
    //   }
    // })

    AxisBottom({
      ...ctx,
      grid: true,
      // ticks: 0,
      // moveOut: 5,

      area: lo.diagram,
      target: g.g,
    })
    AxisLeft({
      ...ctx,
      grid: true,
      noText: true,
      ticks: yDomain[1] + 1,
      // ticks: 0,
      // moveOut: 5,

      area: lo.diagram,
      target: g.g,
    })

    this.renderSeen()
    this.renderUnseen()
  }

  renderSeen() {
    let { lo, g, ds } = this.context
    let { useSmiles, yDomain } = this.context

    let points = _.flatten(ds.map(x => x.dataPoints))
      .filter(x => x.seen)

    let series = [{
      id: 0,
      dataPoints: points,
    }]

    let aData = AData<any>({
      yFrom: yDomain[1],
      yTo: yDomain[0],

      series,
      positioning: p => {
        return {
          x: p.time_test,
          y: p.image, //size,
        }
      },
      coloring: p => {
        if (p.eye === 0 && !p.seen) return '#040'
        if (p.eye === 1 && !p.seen) return '#564017'

        if (p.eye === 0) return B.green
        return B.orange
      },
    })
    aData = { ...aData,
      xScale: this.chartAData.xScale,
      yScale: this.chartAData.yScale,
    }

    let ctx = AChart2<any>({
      aData,
      target: g.g,
      area: lo.diagram,
      showDots: false,
    })

    ChartDots({
      ...ctx,
      target: g.g,
      styleParams: {
        override: {
          ...this.dotsStyle,
          fillColor: 'fill',
        }
      }
    })
  }

  renderUnseen() {
    let { lo, g, ds } = this.context
    let { useSmiles, yDomain } = this.context

    let points = _.flatten(ds.map(x => x.dataPoints))
      .filter(x => !x.seen)

    let series = [{
      id: 0,
      dataPoints: points,
    }]

    let aData = AData<any>({
      yFrom: yDomain[1],
      yTo: yDomain[0],

      series,
      positioning: p => {
        return {
          x: p.time_test,
          y: p.image, //size,
        }
      },
      coloring: p => {
        // if (p.eye === 0 && !p.seen) return '#040'
        // if (p.eye === 1 && !p.seen) return '#564017'

        if (p.eye === 0) return B.green
        return B.orange
      },
    })
    aData = { ...aData,
      xScale: this.chartAData.xScale,
      yScale: this.chartAData.yScale,
    }

    let ctx = AChart2<any>({
      aData,
      target: g.g,
      area: lo.diagram,
      showDots: false,
    })

    ChartDots({
      ...ctx,
      target: g.g,
      styleParams: {
        override: {
          ...this.dotsStyle,
          // fillColor: 'fill',
        }
      }
    })
  }

  dotsStyle = {
    dotRadius: 8, lineSize: 5,
  }

  renderLogmarImage() {
    let { lo, g, ds } = this.context

    const D = {
      // dx: -250,
      // dy: 340,
      dx: 0,
      dy: 0,
      // transform: 'scale(1.5, 2.3)',
      transform: 'scale(1, 2)',
    }
    let target = g.g
    let area = lo.logmarImage
    target.selectAll('image')
      .data([null])
      .join('image')
      .attr('href', '/frontend/src/assets/images/logmar.jpg')
      .attr('width', area.w)
      .attr('height', area.h)
      .attr('x', area.l + D.dx)
      .attr('y', area.t + D.dy)
      .style('transform-origin', '50% 50%')
      .style('transform', D.transform)
  }

  renderHeader() {
    let { lo, g, ds } = this.context

    Header({
      text: _.upperCase(this.state.patientType || ''),
      target: g.g,
      area: lo.header,
    })
  }

  renderLegend() {
    let { lo, g, ds } = this.context
    let area = lo.legend.slide(3, 15)
    let topArea = area.takeTop(x => x.h / 2)
    let area3 = area.slide(0, 30)
    let that = this

    renderThis('OD', B.green, topArea)
    renderThis('OS', B.orange, area)
    renderThis('unseen', 'gray', area3)

    function renderThis(text: string, color: string, area: Area) {
      TextBlock({
        text,
        target: g.g,
        area: area.slide(-40, 0),
        alignRight: true,
      })

      let point = area.mid.dx(25)
      let target = g.g.call(doTranslate(point))

      let fillColor = color === 'gray' ? 'transparent' : color

      Circle({
        center: { x: 5, y: -2 },
        radius: that.dotsStyle.dotRadius,
        target,
        styleParams: {
          override: {
            lineColor: color,
            lineSize: that.dotsStyle.lineSize,
            fillColor,
          },
        }
      })
      // target.call(
      //   drawPreview({
      //     colors: [color],
      //     strokeWidth: 4,
      //     timesCount: 1,
      //     timesStep: 0,
      //   })
      // )
    }
  }

  renderText() {
    let { lo, g, ds } = this.context

    TextBlock({
      text: this.state.text,
      target: g.g,
      alignLeft: true,
      alignBottom: true,
      area: lo.text,
    })
  }

  render() {
    this.context = this.renderingContext()
    this.renderImages()
    this.renderChart()
    this.renderLogmarImage()
    this.renderHeader()
    this.renderLegend()
    this.renderText()
  }

  get exportData(): ExportData {
    return {}
  }
}

type ExportData = any
