// @ts-nocheck
import * as _ from 'lodash';
function generateRandom () {
    let xs = [8.485280990600586, 15.29705810546875, 17.492855072021484, 21.21320343017578, 22.847318649291992, 27.166154861450195];
    return xs.map((x) => {
        let ys = _.sortBy(_.times(5, () => _.random(0, 1000))) as [number, number, number, number, number];
        let meany = _.random(_.min(ys) || 0, _.max(ys) || 1000);
        return { x, ys, meany };
    });
}
export interface AgeControlWhisker {
    x: number;
    // x: 0..38 (deg)
    ys: [number, number, number, number, number];
    // ys: [min, 25%, 50%, 75%, max] (y: 0..1200 (deg/s))
    meany: number;
}
type AgeControlData = { [k: string]: AgeControlWhisker[] };
export const ageControlData: AgeControlData = {
    20: generateRandom(),
    40: generateRandom(),
    60: generateRandom(),
    80: generateRandom(),
    100: generateRandom(),
    // 20: [
    //     { x: 3, ys: [0, 100, 200, 300, 400], meany: 250 },
    //     { x: 15, ys: [100, 200, 300, 400, 500], meany: 300 },
    //     { x: 30, ys: [200, 300, 400, 500, 600], meany: 550 },
    // ],
    // 50: [
    //     { x: 3, ys: [100, 200, 300, 400, 500], meany: 350 },
    //     { x: 15, ys: [100, 200, 300, 400, 500], meany: 450 },
    //     { x: 30, ys: [100, 200, 300, 400, 500], meany: 250 },
    // ],
    // 80: [
    //     { x: 3, ys: [200, 300, 400, 500, 600], meany: 550 },
    //     { x: 15, ys: [200, 300, 400, 500, 600], meany: 450 },
    //     { x: 30, ys: [200, 300, 400, 500, 600], meany: 350 },
    // ],
};
