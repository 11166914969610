import { AnimationEvent } from '@angular/animations';
import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { Subject } from 'rxjs';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import ITest from '../../../../../../commonout/interfaces/test';
import { applyMixins } from '../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../common/enums/test-result-type.enum';
import { Test } from '../../../../../common/models/test.class';
import { BulbicamService } from '../../_services/examination/bulbiCam.service';
import { Pager } from '../../_services/examination/export.service';
import { FileService } from '../../_services/general/file.service';
import { ExaminationFrontend } from '../examinationFrontend.class';
import { TestRemarkFrontend } from '../test-remarks/testRemarkFrontend';

export abstract class TestFrontend {
    public remarks: TestRemarkFrontend;
    public examination: ExaminationFrontend;
    public abstract readonly remarksComponent?: any;
    public abstract readonly bulbicamChartComponent?: any;
    public abstract readonly bulbicamControlComponent?: any;
    public abstract readonly bulbicamSequenceControlComponent?: any;
    public abstract readonly possible_results: string[];
    public abstract readonly result_type: TEST_RESULT_TYPE;
    public isExpanded: Subject<AnimationEvent>;
    constructor() {}
    public setModel(model: ITest): void {
        this.status = model.status;
        this.stage = model.stage;
        this.remarks.setModel(model.remarks);
        this.remarks.test = this;
    }
    public get haploVersions(): string {
        switch (this.type) {
            case TEST_TYPE.CONVERGENCE:
            case TEST_TYPE.PUPILLARY_EVALUATION:
            case TEST_TYPE.SACCADES: {
                if ((this.remarks.measurements[0] as any).haplotests.length > 0) {
                    return (this.remarks.measurements[0] as any).haplotests[0].getVersions();
                } else {
                    return '';
                }
            }
            default:
                return '';
        }
    }
    public async toPDF(
        document: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        try {
            if (!this.isRemarksExist()) return;
            pager.addLine(document.getTextDimensions('A').h * 1.5);
            let head = [];
            head.push({
                column1: { content: 'Test name:', styles: { halign: 'left' } },
                column2: { content: this.name, styles: { halign: 'right' } },
            });
            (document as any).autoTable({
                head: head,
                startY: pager.line,
                pageBreak: 'auto',
                theme: 'grid',
                margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                didParseCell: <CellHookHandler>(data => {
                    if (data.section === 'head') {
                        if (data.row.index === 0 || data.row.index === 1) {
                            data.cell.styles.fillColor = [74, 96, 117];
                        }
                    }
                }),
                didDrawPage: () => {},
            });
            pager.line = (document as any).lastAutoTable.finalY;
            await this.remarks.toPDF(document, pager, fileService, componentFactoryResolver, viewContainerRef, bulbicamService);
        } catch (error) {
            console.log(error);
        }
    }
}

export interface TestFrontend extends Test {}
applyMixins(TestFrontend, [Test]);
