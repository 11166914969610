import { FixationBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/fixationBulbicamDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class FixationBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof FixationBulbicamMeasuredDataFrontend = FixationBulbicamMeasuredDataFrontend;
    public measurements: FixationBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
