import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../_services/general/auth.service';
import { map, catchError } from 'rxjs/operators';
import { ROLE } from '../../../../../commonout/enum/role';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private router: Router, private authService: AuthenticationService) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        let token: string = route.paramMap.get('token');
        // if token exists then somebody wants to register
        if (token) {
            return this.authService.findInvitationCredentialsByToken(token).pipe(
                map((credentials) => {
                    switch (credentials.role) {
                        case ROLE.STAFF:
                            this.router.navigate(['/auth/register/techstaff'], { queryParams: credentials });
                            break;
                        case ROLE.DOCTOR:
                            this.router.navigate(['/auth/register/doctor'], { queryParams: credentials });
                            break;
                        default:
                            break;
                    }
                    return false;
                }),
                catchError((error) => of(false))
            );
        } else {
            if (this.authService.currentUser.token) {
                return true;
            }
            // not logged in so redirect to login page
            this.router.navigate(['/auth']);
        }
        return false;
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        if (this.authService.currentUser.token) {
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/auth']);
        return false;
    }
}
