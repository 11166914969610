import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { OcularRefractionTonorefIIIMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/TonorefIII/ocularRefractionTonorefIIIData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { OcularRefractionTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/ocularRefractionArk1sTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class OcularRefractionTonorefIIIMeasuredDataFrontend extends OcularRefractionTonorefIIIMeasuredData implements MeasuredDataFrontend {
    public remarks: OcularRefractionTonorefIIITestRemarksFrontend;
    public visualAcuity: {
        withoutGlasses: {
            OD: {
                distance: RegisterEditFieldFrontend<string>;
                near: RegisterEditFieldFrontend<string>;
                workingDistance: RegisterEditFieldFrontend<string>;
            };
            OS: {
                distance: RegisterEditFieldFrontend<string>;
                near: RegisterEditFieldFrontend<string>;
                workingDistance: RegisterEditFieldFrontend<string>;
            };
        };
        withGlasses: {
            OD: {
                distance: RegisterEditFieldFrontend<string>;
                near: RegisterEditFieldFrontend<string>;
                workingDistance: RegisterEditFieldFrontend<string>;
            };
            OS: {
                distance: RegisterEditFieldFrontend<string>;
                near: RegisterEditFieldFrontend<string>;
                workingDistance: RegisterEditFieldFrontend<string>;
            };
        };
        contactLens: {
            OD: {
                distance: RegisterEditFieldFrontend<string>;
                near: RegisterEditFieldFrontend<string>;
                workingDistance: RegisterEditFieldFrontend<string>;
            };
            OS: {
                distance: RegisterEditFieldFrontend<string>;
                near: RegisterEditFieldFrontend<string>;
                workingDistance: RegisterEditFieldFrontend<string>;
            };
        };
    };
    public duochromeTest: {
        OD: {
            response: RegisterEditFieldFrontend<string[]>;
            fogging: RegisterEditFieldFrontend<string[]>;
        };
        OS: {
            response: RegisterEditFieldFrontend<string[]>;
            fogging: RegisterEditFieldFrontend<string[]>;
        };
    };
    public objectiveRefraction: {
        OD: {
            sphere: RegisterEditFieldFrontend<string>;
            cylinder: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
        };
        OS: {
            sphere: RegisterEditFieldFrontend<string>;
            cylinder: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
        };
    };
    public subjectiveRefraction: {
        OD: {
            sphere: RegisterEditFieldFrontend<string>;
            cylinder: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
            distanceVisualAcuity: RegisterEditFieldFrontend<string>;
        };
        OS: {
            sphere: RegisterEditFieldFrontend<string>;
            cylinder: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
            distanceVisualAcuity: RegisterEditFieldFrontend<string>;
        };
    };
    public add: {
        OD: {
            dsph: RegisterEditFieldFrontend<string>;
            nva: RegisterEditFieldFrontend<string>;
            cm: RegisterEditFieldFrontend<string>;
            preference: RegisterEditFieldFrontend<string[]>;
        };
        OS: {
            dsph: RegisterEditFieldFrontend<string>;
            nva: RegisterEditFieldFrontend<string>;
            cm: RegisterEditFieldFrontend<string>;
            preference: RegisterEditFieldFrontend<string[]>;
        };
    };
    public finalPrescription: {
        OD: {
            sphere: RegisterEditFieldFrontend<string>;
            cylinder: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
            distanceVisualAcuity: RegisterEditFieldFrontend<string>;
            dsph: RegisterEditFieldFrontend<string>;
            cylinderNear: RegisterEditFieldFrontend<string>;
            axisNear: RegisterEditFieldFrontend<string>;
            nva: RegisterEditFieldFrontend<string>;
            cm: RegisterEditFieldFrontend<string>;
        };
        OS: {
            sphere: RegisterEditFieldFrontend<string>;
            cylinder: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
            distanceVisualAcuity: RegisterEditFieldFrontend<string>;
            dsph: RegisterEditFieldFrontend<string>;
            cylinderNear: RegisterEditFieldFrontend<string>;
            axisNear: RegisterEditFieldFrontend<string>;
            nva: RegisterEditFieldFrontend<string>;
            cm: RegisterEditFieldFrontend<string>;
        };
    };
    constructor() {
        super();
        this.objectiveRefraction = {
            OD: {
                sphere: new RegisterEditFieldFrontend(),
                cylinder: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
            },
            OS: {
                sphere: new RegisterEditFieldFrontend(),
                cylinder: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
            },
        };
        this.subjectiveRefraction = {
            OD: {
                sphere: new RegisterEditFieldFrontend(),
                cylinder: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
                distanceVisualAcuity: new RegisterEditFieldFrontend(),
            },
            OS: {
                sphere: new RegisterEditFieldFrontend(),
                cylinder: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
                distanceVisualAcuity: new RegisterEditFieldFrontend(),
            },
        };
        this.visualAcuity = {
            withoutGlasses: {
                OD: {
                    distance: new RegisterEditFieldFrontend(),
                    near: new RegisterEditFieldFrontend(),
                    workingDistance: new RegisterEditFieldFrontend(),
                },
                OS: {
                    distance: new RegisterEditFieldFrontend(),
                    near: new RegisterEditFieldFrontend(),
                    workingDistance: new RegisterEditFieldFrontend(),
                },
            },
            withGlasses: {
                OD: {
                    distance: new RegisterEditFieldFrontend(),
                    near: new RegisterEditFieldFrontend(),
                    workingDistance: new RegisterEditFieldFrontend(),
                },
                OS: {
                    distance: new RegisterEditFieldFrontend(),
                    near: new RegisterEditFieldFrontend(),
                    workingDistance: new RegisterEditFieldFrontend(),
                },
            },
            contactLens: {
                OD: {
                    distance: new RegisterEditFieldFrontend(),
                    near: new RegisterEditFieldFrontend(),
                    workingDistance: new RegisterEditFieldFrontend(),
                },
                OS: {
                    distance: new RegisterEditFieldFrontend(),
                    near: new RegisterEditFieldFrontend(),
                    workingDistance: new RegisterEditFieldFrontend(),
                },
            },
        };
        this.duochromeTest = {
            OD: {
                response: new RegisterEditFieldFrontend(),
                fogging: new RegisterEditFieldFrontend(),
            },
            OS: {
                response: new RegisterEditFieldFrontend(),
                fogging: new RegisterEditFieldFrontend(),
            },
        };
        this.add = {
            OD: {
                dsph: new RegisterEditFieldFrontend(),
                nva: new RegisterEditFieldFrontend(),
                cm: new RegisterEditFieldFrontend(),
                preference: new RegisterEditFieldFrontend(),
            },
            OS: {
                dsph: new RegisterEditFieldFrontend(),
                nva: new RegisterEditFieldFrontend(),
                cm: new RegisterEditFieldFrontend(),
                preference: new RegisterEditFieldFrontend(),
            },
        };
        this.finalPrescription = {
            OD: {
                sphere: new RegisterEditFieldFrontend(),
                cylinder: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
                distanceVisualAcuity: new RegisterEditFieldFrontend(),
                dsph: new RegisterEditFieldFrontend(),
                cylinderNear: new RegisterEditFieldFrontend(),
                axisNear: new RegisterEditFieldFrontend(),
                nva: new RegisterEditFieldFrontend(),
                cm: new RegisterEditFieldFrontend(),
            },
            OS: {
                sphere: new RegisterEditFieldFrontend(),
                cylinder: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
                distanceVisualAcuity: new RegisterEditFieldFrontend(),
                dsph: new RegisterEditFieldFrontend(),
                cylinderNear: new RegisterEditFieldFrontend(),
                axisNear: new RegisterEditFieldFrontend(),
                nva: new RegisterEditFieldFrontend(),
                cm: new RegisterEditFieldFrontend(),
            },
        };
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let head = [],
            table = [];
        head.push({
            eyes: { colSpan: 10, content: 'Visual acuity (Decimal)', styles: { halign: 'center' } },
            noglassDV: '',
            noglassNV: '',
            noglassWD: '',
            glassDV: '',
            glassNV: '',
            glassWD: '',
            lensDV: '',
            lensNV: '',
            lensWD: '',
        });
        head.push({
            eyes: '',
            noglassDV: { colSpan: 3, content: 'Without glass', styles: { halign: 'center' } },
            noglassNV: '',
            noglassWD: '',
            glassDV: { colSpan: 3, content: 'With glass', styles: { halign: 'center' } },
            glassNV: '',
            glassWD: '',
            lensDV: { colSpan: 3, content: 'Contact lens', styles: { halign: 'center' } },
            lensNV: '',
            lensWD: '',
        });
        head.push({
            eyes: '',
            noglassDV: 'DV',
            noglassNV: 'NV',
            noglassWD: 'WD',
            glassDV: 'DV',
            glassNV: 'NV',
            glassWD: 'WD',
            lensDV: 'DV',
            lensNV: 'NV',
            lensWD: 'WD',
        });
        table.push({
            eyes: 'OD',
            noglassDV: this.visualAcuity.withoutGlasses.OD.distance.value,
            noglassNV: this.visualAcuity.withoutGlasses.OD.near.value,
            noglassWD: this.visualAcuity.withoutGlasses.OD.workingDistance.value,
            glassDV: this.visualAcuity.withGlasses.OD.distance.value,
            glassNV: this.visualAcuity.withGlasses.OD.near.value,
            glassWD: this.visualAcuity.withGlasses.OD.workingDistance.value,
            lensDV: this.visualAcuity.contactLens.OD.distance.value,
            lensNV: this.visualAcuity.contactLens.OD.near.value,
            lensWD: this.visualAcuity.contactLens.OD.workingDistance.value,
        });
        table.push({
            eyes: 'OS',
            noglassDV: this.visualAcuity.withoutGlasses.OS.distance.value,
            noglassNV: this.visualAcuity.withoutGlasses.OS.near.value,
            noglassWD: this.visualAcuity.withoutGlasses.OS.workingDistance.value,
            glassDV: this.visualAcuity.withGlasses.OS.distance.value,
            glassNV: this.visualAcuity.withGlasses.OS.near.value,
            glassWD: this.visualAcuity.withGlasses.OS.workingDistance.value,
            lensDV: this.visualAcuity.contactLens.OS.distance.value,
            lensNV: this.visualAcuity.contactLens.OS.near.value,
            lensWD: this.visualAcuity.contactLens.OS.workingDistance.value,
        });
        (document as any).autoTable({
            head: head,
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: <CellHookHandler>(data => {
                if (data.section === 'head') {
                    if (data.row.index === 0 || data.row.index === 1) {
                        data.cell.styles.fillColor = [74, 96, 117];
                    }
                }
            }),
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
        ////////////
        head = [];
        table = [];
        head.push({
            eyes: { colSpan: 4, content: 'Autorefraction', styles: { halign: 'center' } },
            DSph: '',
            DCyl: '',
            Axis: '',
        });
        head.push({
            eyes: '',
            DSph: 'DSph',
            DCyl: 'DCyl',
            Axis: 'Axis',
        });
        table.push({
            eyes: 'OD',
            DSph: this.objectiveRefraction.OD.sphere.value,
            DCyl: this.objectiveRefraction.OD.cylinder.value,
            Axis: this.objectiveRefraction.OD.axis.value,
        });
        table.push({
            eyes: 'OS',
            DSph: this.objectiveRefraction.OS.sphere.value,
            DCyl: this.objectiveRefraction.OS.cylinder.value,
            Axis: this.objectiveRefraction.OS.axis.value,
        });
        (document as any).autoTable({
            head: head,
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
        ////////
        head = [];
        table = [];
        head.push({
            eyes: '',
            AccDSph: { colSpan: 4, content: 'Acceptance', styles: { halign: 'left' } },
            AccDCyl: '',
            AccAxis: '',
            AccBCVA: '',
            Responce: { colSpan: 2, content: 'Duochrome', styles: { halign: 'left' } },
            Fogging: '',
            AddDSph: { colSpan: 4, content: 'Add', styles: { halign: 'left' } },
            AddBCVA: '',
            AddWDcm: '',
            Addpreference: '',
        });
        head.push({
            eyes: '',
            AccDSph: 'DSph',
            AccDCyl: 'DCyl',
            AccAxis: 'Axis',
            AccBCVA: 'BCVA',
            Responce: 'Responce',
            Fogging: 'Fogging',
            AddDSph: 'DSph',
            AddBCVA: 'BCVA',
            AddWDcm: 'WD (cm)',
            Addpreference: 'Preference',
        });
        table.push({
            eyes: 'OD',
            AccDSph: this.subjectiveRefraction.OD.sphere.value,
            AccDCyl: this.subjectiveRefraction.OD.cylinder.value,
            AccAxis: this.subjectiveRefraction.OD.axis.value,
            AccBCVA: this.subjectiveRefraction.OD.distanceVisualAcuity.value,
            Responce: this.duochromeTest.OD.response.value,
            Fogging: this.duochromeTest.OD.fogging.value,
            AddDSph: this.add.OD.dsph.value,
            AddBCVA: this.add.OD.nva.value,
            AddWDcm: this.add.OD.cm.value,
            Addpreference: this.add.OD.preference.value,
        });
        table.push({
            eyes: 'OS',
            AccDSph: this.subjectiveRefraction.OS.sphere.value,
            AccDCyl: this.subjectiveRefraction.OS.cylinder.value,
            AccAxis: this.subjectiveRefraction.OS.axis.value,
            AccBCVA: this.subjectiveRefraction.OS.distanceVisualAcuity.value,
            Responce: this.duochromeTest.OS.response.value,
            Fogging: this.duochromeTest.OS.fogging.value,
            AddDSph: this.add.OS.dsph.value,
            AddBCVA: this.add.OS.nva.value,
            AddWDcm: this.add.OS.cm.value,
            Addpreference: this.add.OS.preference.value,
        });
        (document as any).autoTable({
            head: head,
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
        ////////
        head = [];
        table = [];
        head.push({
            eyes: { colSpan: 10, content: 'Final Glass Prescription', styles: { halign: 'center' } },
            DistDSph: '',
            DistDCyl: '',
            DistAxis: '',
            DistBCVA: '',
            NearDSph: '',
            NearDCyl: '',
            NearAxis: '',
            NearBCVA: '',
            WDcm: '',
        });
        head.push({
            eyes: '',
            DistDSph: { colSpan: 4, content: 'Distance', styles: { halign: 'left' } },
            DistDCyl: '',
            DistAxis: '',
            DistBCVA: '',
            NearDSph: { colSpan: 4, content: 'Near', styles: { halign: 'left' } },
            NearDCyl: '',
            NearAxis: '',
            NearBCVA: '',
            WDcm: '',
        });
        head.push({
            eyes: '',
            DistDSph: 'DSph',
            DistDCyl: 'DCyl',
            DistAxis: 'Axis',
            DistBCVA: 'BCVA',
            NearDSph: 'DSph',
            NearDCyl: 'DCyl',
            NearAxis: 'Axis',
            NearBCVA: 'BCVA',
            WDcm: 'WD (cm)',
        });
        table.push({
            eyes: 'OD',
            DistDSph: this.finalPrescription.OD.sphere.value,
            DistDCyl: this.finalPrescription.OD.cylinder.value,
            DistAxis: this.finalPrescription.OD.axis.value,
            DistBCVA: this.finalPrescription.OD.distanceVisualAcuity.value,
            NearDSph: this.finalPrescription.OD.dsph.value,
            NearDCyl: this.finalPrescription.OD.cylinderNear.value,
            NearAxis: this.finalPrescription.OD.axisNear.value,
            NearBCVA: this.finalPrescription.OD.nva.value,
            WDcm: this.finalPrescription.OD.cm.value,
        });
        table.push({
            eyes: 'OS',
            DistDSph: this.finalPrescription.OS.sphere.value,
            DistDCyl: this.finalPrescription.OS.cylinder.value,
            DistAxis: this.finalPrescription.OS.axis.value,
            DistBCVA: this.finalPrescription.OS.distanceVisualAcuity.value,
            NearDSph: this.finalPrescription.OS.dsph.value,
            NearDCyl: this.finalPrescription.OS.cylinderNear.value,
            NearAxis: this.finalPrescription.OS.axisNear.value,
            NearBCVA: this.finalPrescription.OS.nva.value,
            WDcm: this.finalPrescription.OS.cm.value,
        });
        (document as any).autoTable({
            head: head,
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values =
            (this.objectiveRefraction.OD.sphere.value || 'x') +
            '/' +
            (this.objectiveRefraction.OD.cylinder.value || 'x') +
            '/' +
            (this.objectiveRefraction.OD.axis.value || 'x') +
            '/' +
            (this.add.OD.dsph.value || 'x') +
            '/' +
            (this.add.OD.nva.value || 'x');
        formattedTest.OS.values =
            (this.objectiveRefraction.OS.sphere.value || 'x') +
            '/' +
            (this.objectiveRefraction.OS.cylinder.value || 'x') +
            '/' +
            (this.objectiveRefraction.OS.axis.value || 'x') +
            '/' +
            (this.add.OS.dsph.value || 'x') +
            '/' +
            (this.add.OS.nva.value || 'x');
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
