import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PostSegmentTopconTritonMeasuredDataFrontend } from '../../../measuredData/TOPCON/TRITON/postSegmentTopconTritonDataFrontend.class';

export class PostSegmentTopconTritonTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PostSegmentTopconTritonMeasuredDataFrontend = PostSegmentTopconTritonMeasuredDataFrontend;
    public measurements: PostSegmentTopconTritonMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
