// @ts-nocheck
import { Layout } from './lib';

// eslint-disable-next-line
import { layoutPreview } from './lib';

const GAP = 50;
const G = GAP;
const MARGIN = 50;
const HEADER = 50;

const LEFT_WIDTH = 100;
const RND_BLOCK_HEIGHT = 1000 + 300;
const SEEN_HEIGHT = 100;
const SECTIONS_HEIGHT = 100;
const TOP_SIZE = 200;

const RIGHTMOST = 400;
const RIGHT_H = 400;

// const W = window.screen.width
// const H = window.screen.height
const USED_HEIGHT = 3 / 4;
const MORE_HEIGHT = RND_BLOCK_HEIGHT;
const W = 1920;
const H = 1080 * USED_HEIGHT + MORE_HEIGHT + TOP_SIZE;

export function makeLayout() {
    let layout = new Layout(0, 0, W, 1400, 'layout');
    layout.bottom = H;
    layout.takeLeft(MARGIN);
    layout.takeRight(MARGIN);
    layout.takeTop(MARGIN);
    layout.takeBottom(MARGIN);

    let rightPart = layout.takeRight(RIGHTMOST, 'rightPart');
    rightPart.takeLeft(G);

    let topPart = layout.takeTop(TOP_SIZE, 'topPart');
    topPart.takeBottom(G);

    layout.takeRight(G * 2);

    rightPart.takeTop(TOP_SIZE);

    // let right = layout.takeRight(x => x.w*1/4, 'right')
    // layout.takeRight(G*3)
    // let rightTop = right.takeTop(x => x.h - RND_BLOCK_HEIGHT + 4, 'rightTop') // ui

    // let nChart = 4
    // let chartSize = (rightTop.h - (nChart-1)*G) / nChart
    // let chart0 = rightTop.takeTop(chartSize, 'chart0')
    // rightTop.takeTop(G)
    // let chart1 = rightTop.takeTop(chartSize, 'chart1')
    // rightTop.takeTop(G)
    // let chart2 = rightTop.takeTop(chartSize, 'chart2')
    // rightTop.takeTop(G)
    // let chart3 = rightTop.takeTop(chartSize, 'chart3')

    // let MA = 100
    // let ma1 = chart1.takeRight(MA, 'ma1'); chart1.takeRight(G)
    // let ma2 = chart2.takeRight(MA, 'ma2'); chart2.takeRight(G)
    // let ma3 = chart3.takeRight(MA, 'ma3'); chart3.takeRight(G)

    let top = layout.takeTop((x) => x.h - RND_BLOCK_HEIGHT, 'top');

    // let seen = top.takeBottom(SEEN_HEIGHT, 'seen')
    // seen.takeLeft(LEFT_WIDTH)
    // top.takeBottom(GAP)

    let sections = top.takeBottom(SECTIONS_HEIGHT, 'sections');
    let left = sections.takeLeft(LEFT_WIDTH, 'left');
    top.takeBottom(GAP);

    let main = top.takeAll('main');
    main.takeLeft(LEFT_WIDTH);

    let seen = main;

    let info = layout.takeAll('info');
    info.takeLeft(LEFT_WIDTH);
    info.takeTop(GAP);

    let controls = info.copy();
    let restStuff = controls.takeLeft((x) => x.w - 150);
    // let threshold = info.takeTop(30, 'threshold')
    // threshold.takeLeft(x => x.w - 150)
    let showPupil = controls.takeTop(50, 'showPupil').slide(-115, 0);

    let ma1 = controls.takeTop(30, 'ma1');
    // threshold.takeLeft(x => x.w - 150)
    let ma2 = controls.takeTop(30, 'ma2');
    // threshold.takeLeft(x => x.w - 150)
    let threshold = controls.takeTop(30, 'threshold');
    // threshold.takeLeft(x => x.w - 150)

    topPart.left = main.left;
    topPart.right = main.right;

    let panelSize = 400;
    restStuff.takeRight(150);
    let rapdResult = restStuff.takeRight(panelSize).takeTop(TOP_SIZE - G);
    restStuff.takeRight(20);
    // let flickerResult = restStuff.takeRight(panelSize).takeTop(TOP_SIZE - G)
    let flickerResult = rapdResult.copy().slide(0, rapdResult.h + 21);

    // rightPart.takeBottom(x => x.h - main.h)
    // rightPart.takeBottom(x => x.h - RIGHT_H * 2 - G)
    let rightPart1 = rightPart.takeTop(RIGHT_H, 'rightPart1');
    rightPart.takeTop(G);
    let rightPart2 = rightPart.takeTop(RIGHT_H, 'rightPart2');
    rightPart.takeTop(G * 1.5);
    let rightPart3 = rightPart.takeTop(100, 'rightPart3');
    return {
        layout,

        topPart,
        main,
        sections,
        seen,
        info,
        left,

        // chart0,
        // chart1,
        // chart2,
        // chart3,

        showPupil,
        ma1,
        ma2,
        // ma3,

        threshold,

        rapdResult,
        flickerResult,

        rightPart1,
        rightPart2,
        right3: rightPart3,
    };
}

export type ChartLayout = ReturnType<typeof makeLayout>;

// layoutPreview(makeLayout().layout, { fontSize: 50, forceStrength: -10000, forceDistance: 100 });
