import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IKeratometryArk1sMeasuredData } from '../../../../interfaces/measuredData/NIDEK/ARK1S/keratometry-nidek-ark1s.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class KeratometryArk1sMeasuredData implements MeasuredData, IKeratometryArk1sMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public R1: {
        OD: {
            mm: RegisterEditField<string>;
            diopter: RegisterEditField<string>;
            axis: RegisterEditField<string>;
        };
        OS: {
            mm: RegisterEditField<string>;
            diopter: RegisterEditField<string>;
            axis: RegisterEditField<string>;
        };
    };
    public R2: {
        OD: {
            mm: RegisterEditField<string>;
            diopter: RegisterEditField<string>;
            axis: RegisterEditField<string>;
        };
        OS: {
            mm: RegisterEditField<string>;
            diopter: RegisterEditField<string>;
            axis: RegisterEditField<string>;
        };
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_ARK1S;
        this.testType = TEST_TYPE.KERATOMETRY;
    }
    public setModel(model: IKeratometryArk1sMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.R1.OD.mm.setModel(model.R1.OD.mm);
        this.R1.OD.diopter.setModel(model.R1.OD.diopter);
        this.R1.OD.axis.setModel(model.R1.OD.axis);
        this.R1.OS.mm.setModel(model.R1.OS.mm);
        this.R1.OS.diopter.setModel(model.R1.OS.diopter);
        this.R1.OS.axis.setModel(model.R1.OS.axis);
        this.R2.OD.mm.setModel(model.R2.OD.mm);
        this.R2.OD.diopter.setModel(model.R2.OD.diopter);
        this.R2.OD.axis.setModel(model.R2.OD.axis);
        this.R2.OS.mm.setModel(model.R2.OS.mm);
        this.R2.OS.diopter.setModel(model.R2.OS.diopter);
        this.R2.OS.axis.setModel(model.R2.OS.axis);
    }
    public getModel(): IKeratometryArk1sMeasuredData {
        let model: IKeratometryArk1sMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            R1: {
                OD: {
                    mm: this.R1.OD.mm.getModel(),
                    diopter: this.R1.OD.diopter.getModel(),
                    axis: this.R1.OD.axis.getModel(),
                },
                OS: {
                    mm: this.R1.OS.mm.getModel(),
                    diopter: this.R1.OS.diopter.getModel(),
                    axis: this.R1.OS.axis.getModel(),
                },
            },
            R2: {
                OD: {
                    mm: this.R2.OD.mm.getModel(),
                    diopter: this.R2.OD.diopter.getModel(),
                    axis: this.R2.OD.axis.getModel(),
                },
                OS: {
                    mm: this.R2.OS.mm.getModel(),
                    diopter: this.R2.OS.diopter.getModel(),
                    axis: this.R2.OS.axis.getModel(),
                },
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.R1.OD.axis.isEmpty() &&
            this.R1.OD.diopter.isEmpty() &&
            this.R1.OD.mm.isEmpty() &&
            this.R1.OS.axis.isEmpty() &&
            this.R1.OS.diopter.isEmpty() &&
            this.R1.OS.mm.isEmpty() &&
            this.R2.OD.axis.isEmpty() &&
            this.R2.OD.diopter.isEmpty() &&
            this.R2.OD.mm.isEmpty() &&
            this.R2.OS.axis.isEmpty() &&
            this.R2.OS.diopter.isEmpty() &&
            this.R2.OS.mm.isEmpty()
        );
    }
}
