import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PostSegmentNidekRs330MeasuredDataFrontend } from '../../../measuredData/NIDEK/RS330/postSegmentNidekRs330DataFrontend.class';

export class PostSegmentNidekRs330TestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PostSegmentNidekRs330MeasuredDataFrontend = PostSegmentNidekRs330MeasuredDataFrontend;
    public measurements: PostSegmentNidekRs330MeasuredDataFrontend[];
    constructor() {
        super();
    }
}
