import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { AccommodationArk1sMeasuredDataFrontend } from '../../../measuredData/NIDEK/ARK1S/accommodationArk1sDataFrontend.class';

export class AccommodationArk1sTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof AccommodationArk1sMeasuredDataFrontend = AccommodationArk1sMeasuredDataFrontend;
    public measurements: AccommodationArk1sMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
