import { IEdit } from '../../../commonout/interfaces/edit.interface';

export class Edit<T> implements IEdit<T> {
    date: number;
    editorId: string;
    editor: string;
    fieldName: string;
    previousValue: T;
    currentValue: T;
    constructor(model?: IEdit<T>) {
        if (model) {
            this.date = model.date;
            this.editorId = model.editorId;
            this.fieldName = model.fieldName;
            this.previousValue = model.previousValue;
            this.currentValue = model.currentValue;
        }
    }
    public get model(): IEdit<T> {
        return { ...this };
    }
}
