import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { IPupillaryEvaluationArk1sMeasuredData } from '../../../../../../../common/interfaces/measuredData/NIDEK/ARK1S/pupillaryEvaluation-nidek-ark1s.measuredData.interface';
import { PupillaryEvaluationArk1sMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/ARK1S/pupillaryEvaluation-nidek-ark1s.measuredData.model';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { PupillaryEvaluationArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/pupillaryEvaluationArk1sTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class PupillaryEvaluationArk1sMeasuredDataFrontend extends PupillaryEvaluationArk1sMeasuredData implements MeasuredDataFrontend {
    public remarks: PupillaryEvaluationArk1sTestRemarksFrontend;
    public OD: {
        size: {
            type: RegisterEditFieldFrontend<string>;
        };
        reactionToLight: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
        rapo: {
            type: RegisterEditFieldFrontend<string>;
        };
        pupilMorphology: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
    };
    public OS: {
        size: {
            type: RegisterEditFieldFrontend<string>;
        };
        reactionToLight: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
        rapo: {
            type: RegisterEditFieldFrontend<string>;
        };
        pupilMorphology: {
            type: RegisterEditFieldFrontend<string>;
            select: RegisterEditFieldFrontend<string[]>;
        };
    };
    public pupilSize: {
        OD: RegisterEditFieldFrontend<string>;
        OS: RegisterEditFieldFrontend<string>;
    };
    constructor() {
        super();
        this.pupilSize = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.OD = {
            size: {
                type: new RegisterEditFieldFrontend(),
            },
            reactionToLight: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
            rapo: {
                type: new RegisterEditFieldFrontend(),
            },
            pupilMorphology: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
        };
        this.OS = {
            size: {
                type: new RegisterEditFieldFrontend(),
            },
            reactionToLight: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
            rapo: {
                type: new RegisterEditFieldFrontend(),
            },
            pupilMorphology: {
                type: new RegisterEditFieldFrontend(),
                select: new RegisterEditFieldFrontend(),
            },
        };
    }
    public setModel(model: IPupillaryEvaluationArk1sMeasuredData) {
        this.pupilSize.OD.setModel(model.pupilSize.OD);
        this.pupilSize.OS.setModel(model.pupilSize.OS);
        this.OD.size.type.setModel(model.OD.size.type);
        this.OD.reactionToLight.type.setModel(model.OD.reactionToLight.type);
        this.OD.reactionToLight.select.setModel(model.OD.reactionToLight.select);
        this.OD.rapo.type.setModel(model.OD.rapo.type);
        this.OD.pupilMorphology.type.setModel(model.OD.pupilMorphology.type);
        this.OD.pupilMorphology.select.setModel(model.OD.pupilMorphology.select);
        this.OS.size.type.setModel(model.OS.size.type);
        this.OS.reactionToLight.type.setModel(model.OS.reactionToLight.type);
        this.OS.reactionToLight.select.setModel(model.OS.reactionToLight.select);
        this.OS.rapo.type.setModel(model.OS.rapo.type);
        this.OS.pupilMorphology.type.setModel(model.OS.pupilMorphology.type);
        this.OS.pupilMorphology.select.setModel(model.OS.pupilMorphology.select);
    }
    public async toPDF(
        pdf: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        let table = [];
        table.push({
            column1: '',
            column2: { colSpan: 2, content: 'OD', styles: { halign: 'center' } },
            column3: '',
            column4: { colSpan: 2, content: 'OS', styles: { halign: 'center' } },
            column5: '',
        });
        table.push({
            column1: 'Size mm (Ark1s)',
            column2: this.OD.size.type.value,
            column3: this.pupilSize.OD.value,
            column4: this.OS.size.type.value,
            column5: this.pupilSize.OS.value,
        });
        table.push({
            column1: 'Reaction to Light',
            column2: this.OD.reactionToLight.type.value,
            column3: this.OD.reactionToLight.select.value,
            column4: this.OS.reactionToLight.type.value,
            column5: this.OS.reactionToLight.select.value,
        });
        table.push({
            column1: 'RAPD',
            column2: this.OD.rapo.type.value,
            column4: this.OS.rapo.type.value,
        });
        table.push({
            column1: 'Pupil Morphology',
            column2: this.OD.pupilMorphology.type.value,
            column3: this.OD.pupilMorphology.select.value,
            column4: this.OS.pupilMorphology.type.value,
            column5: this.OS.pupilMorphology.select.value,
        });
        ///
        (pdf as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: <CellHookHandler>(data => {}),
            didDrawPage: () => {},
        });
        pager.line = (pdf as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values = '' + this.pupilSize.OD.value || 'x';
        formattedTest.OS.values = '' + this.pupilSize.OS.value || 'x';
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
