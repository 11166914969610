import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { MESSAGE_TYPE } from '../../../../../../commonout/interfaces/charts.model';
import { ExportPupilTestResults, ExportTableTestResults, IPupil20TestCamMessage, PupilDotChartResults } from '../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

enum PUPIL_TEST_STAGES {
    START_TEST = 'START_TEST',
    FIRST_STAGE = 'FIRST_STAGE',
    SECOND_STAGE = 'SECOND_STAGE',
    THIRD_STAGE = 'THIRD_STAGE',
    FOURTH_STAGE = 'FOURTH_STAGE',
    FIFTH_STAGE = 'FIFTH_STAGE',
    STOP_TEST = 'STOP_TEST',
}

@Injectable()
export class MyPupil20TestChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}

    public frameData$: BehaviorSubject<IPupil20TestCamMessage> = new BehaviorSubject(null);
    public dotChartFrameData$: BehaviorSubject<IPupil20TestCamMessage> = new BehaviorSubject(null);
    public pupilTestResults$: BehaviorSubject<ExportTableTestResults[]> = new BehaviorSubject(null);
    public pupilDotChartResult$: BehaviorSubject<PupilDotChartResults> = new BehaviorSubject(null);

    public exportedPupilTestResults: ExportPupilTestResults;
    public pupilTestResults: ExportTableTestResults[] = [];
    public pupilDotChartResult: PupilDotChartResults;
    private testStage: PUPIL_TEST_STAGES;
    private rawFirstStagesFrames: IPupil20TestCamMessage[] = [];
    private rawRapdFrames: IPupil20TestCamMessage[] = [];
    private rawFrames: IPupil20TestCamMessage[] = [];
    private data: IPupil20TestCamMessage[][] = [];
    private startTimestamp: number;

    constructor() {
        super();
    }

    public addData(frames: IPupil20TestCamMessage[]): Promise<void> {
        return new Promise<void>(resolve => {
            frames.forEach(frame => {
                const localFrame = { ...frame };
                switch (localFrame.message_type) {
                    case MESSAGE_TYPE.START_TEST:
                        break;
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        this.startTimestamp = localFrame.timestamp / 10000;

                        this.data.push([]);
                        this.data[this.data.length - 1].push(localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(localFrame);
                        // this.startTimestamp = null;
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        this.data[this.data.length - 1].push(localFrame);
                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        this.data[this.data.length - 1].push(localFrame);
                        break;
                    default:
                        this.data[this.data.length - 1].push(localFrame);
                        break;
                }
            });
        });
    }

    public setCamData(frames: IPupil20TestCamMessage[]): void {
        const rawModel: IRawExportData = {
            rows: [],
        };
        this.rawFrames = frames;

        frames.forEach(frame => {
            switch (frame.message_type) {
                case MESSAGE_TYPE.START_TEST:
                    this.testStage = PUPIL_TEST_STAGES.START_TEST;
                    break;
                case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                    switch (this.testStage) {
                        case PUPIL_TEST_STAGES.START_TEST:
                            this.testStage = PUPIL_TEST_STAGES.FIRST_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.FIRST_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.SECOND_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.SECOND_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.THIRD_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.THIRD_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.FOURTH_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.FOURTH_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.FIFTH_STAGE;
                            break;
                        default:
                            break;
                    }
                    break;
                case MESSAGE_TYPE.STOP_TEST:
                    this.testStage = PUPIL_TEST_STAGES.STOP_TEST;
                    break;
                default:
                    break;
            }
            switch (this.testStage) {
                case PUPIL_TEST_STAGES.START_TEST:
                    this.frameData$.next(frame);
                    this.dotChartFrameData$.next(frame);
                    this.rawFirstStagesFrames.push(frame);
                    break;
                case PUPIL_TEST_STAGES.FIRST_STAGE:
                case PUPIL_TEST_STAGES.SECOND_STAGE:
                    this.frameData$.next(frame);
                    this.rawFirstStagesFrames.push(frame);
                    break;
                case PUPIL_TEST_STAGES.THIRD_STAGE:
                    this.dotChartFrameData$.next(frame);
                    this.rawRapdFrames.push(frame);
                    break;
                case PUPIL_TEST_STAGES.FOURTH_STAGE:
                    this.dotChartFrameData$.next(frame);
                    this.rawRapdFrames.push(frame);
                    break;
                case PUPIL_TEST_STAGES.FIFTH_STAGE:
                    this.dotChartFrameData$.next(frame);
                    this.rawRapdFrames.push(frame);
                    break;
                case PUPIL_TEST_STAGES.STOP_TEST:
                    this.frameData$.next(frame);
                    this.dotChartFrameData$.next(frame);
                    break;
                default:
                    break;
            }
        });

        this.pupilTestResults$.subscribe(results => {
            if (results === null) return;
            this.pupilTestResults = results;
        });
        this.pupilDotChartResult$.subscribe(results => {
            if (results === null) return;
            this.pupilDotChartResult = results;
        });

        const startTimestamp = this.startTimestamp ? this.startTimestamp : this.rawFrames[0].timestamp / 10000;

        const rawFirstStagesModel = this.rawFirstStagesFrames.length !== 0 ? this.getRawModel(this.rawFirstStagesFrames, startTimestamp) : rawModel;

        const rawRapdModel = this.rawRapdFrames.length !== 0 ? this.getRawModel(this.rawRapdFrames, startTimestamp) : rawModel;

        this.exportedPupilTestResults = {
            pupilTestResults: this.pupilTestResults,
            pupilDotChartResult: this.pupilDotChartResult,
            rawFirstStagesFrames: rawFirstStagesModel,
            rawRapdFrames: rawRapdModel,
        };
    }

    public export(): ExportPupilTestResults {
        return this.exportedPupilTestResults;
    }

    public getRawExport(): IRawExportData {
        const rawModel: IRawExportData = {
            rows: [],
        };

        if (this.rawFrames.length === 0) return rawModel;

        const startTimestamp = this.startTimestamp ? this.startTimestamp : this.rawFrames[0].timestamp / 10000;

        this.rawFrames.forEach(frame => {
            rawModel.rows.push({
                time: this.getMiliseconds(startTimestamp, frame.timestamp),
                pupil: {
                    OD: {
                        x: frame.pupilxOD === 0 ? null : frame.pupilxOD,
                        y: frame.pupilyOD === 0 ? null : frame.pupilyOD,
                        diameter: frame.measurementOD === 0 ? null : frame.measurementOD,
                        dpg: {
                            x: frame.dpgxOD,
                            y: frame.dpgyOD,
                        },
                        bpg: {
                            x: frame.bpgxOD,
                            y: frame.bpgyOD,
                        },
                    },
                    OS: {
                        x: frame.pupilxOS === 0 ? null : frame.pupilxOS,
                        y: frame.pupilyOS === 0 ? null : frame.pupilyOS,
                        diameter: frame.measurementOS,
                        dpg: {
                            x: frame.dpgxOS,
                            y: frame.dpgyOS,
                        },
                        bpg: {
                            x: frame.bpgxOS,
                            y: frame.bpgyOS,
                        },
                    },
                },
                stimulus: {
                    OD: {
                        x: null,
                        y: null,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                    OS: {
                        x: null,
                        y: null,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                },
                background: {
                    OD: {
                        r: frame.backgroundColorOD,
                        g: frame.backgroundColorOD,
                        b: frame.backgroundColorOD,
                    },
                    OS: {
                        r: frame.backgroundColorOS,
                        g: frame.backgroundColorOS,
                        b: frame.backgroundColorOS,
                    },
                },
            });
        });

        return rawModel;
    }

    public clearData(): void {
        this.rawFirstStagesFrames = [];
        this.rawRapdFrames = [];
        this.rawFrames = [];
        this.startTimestamp = null;
    }

    public getChartData(frames: IPupil20TestCamMessage[]): void {
        frames.forEach(frame => {
            switch (frame.message_type) {
                case MESSAGE_TYPE.START_TEST:
                    this.testStage = PUPIL_TEST_STAGES.START_TEST;
                    break;
                case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                    switch (this.testStage) {
                        case PUPIL_TEST_STAGES.START_TEST:
                            this.testStage = PUPIL_TEST_STAGES.FIRST_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.FIRST_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.SECOND_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.SECOND_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.THIRD_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.THIRD_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.FOURTH_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.FOURTH_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.FIFTH_STAGE;
                            break;
                        default:
                            break;
                    }
                    break;
                case MESSAGE_TYPE.STOP_TEST:
                    this.testStage = PUPIL_TEST_STAGES.STOP_TEST;
                    break;
                default:
                    break;
            }
            switch (this.testStage) {
                // case PUPIL_TEST_STAGES.START_TEST:
                //     this.firstDiameterChart.framesSource.next(frame);
                //     this.pupil20TableComponent.framesSource.next(frame);
                //     this.pupil20DotChartComponent.framesSource.next(frame);
                //     this.secondDiameterChart.framesSource.next(frame);
                //     this.secondBacklightChart.framesSource.next(frame);
                //     this.thirdDiameterChart.framesSource.next(frame);
                //     this.fourthDiameterChart.framesSource.next(frame);
                //     break;
                // case PUPIL_TEST_STAGES.FIRST_STAGE:
                // case PUPIL_TEST_STAGES.SECOND_STAGE:
                //     this.firstDiameterChart.framesSource.next(frame);
                //     this.pupil20TableComponent.framesSource.next(frame);
                //     break;
                // case PUPIL_TEST_STAGES.THIRD_STAGE:
                //     this.secondDiameterChart.framesSource.next(frame);
                //     this.secondBacklightChart.framesSource.next(frame);
                //     this.pupil20DotChartComponent.framesSource.next(frame);
                //     break;
                // case PUPIL_TEST_STAGES.FOURTH_STAGE:
                //     this.thirdDiameterChart.framesSource.next(frame);
                //     this.pupil20DotChartComponent.framesSource.next(frame);
                //     break;
                // case PUPIL_TEST_STAGES.FIFTH_STAGE:
                //     this.fourthDiameterChart.framesSource.next(frame);
                //     this.pupil20DotChartComponent.framesSource.next(frame);
                //     break;
                // case PUPIL_TEST_STAGES.STOP_TEST:
                //     this.firstDiameterChart.framesSource.next(frame);
                //     this.pupil20TableComponent.framesSource.next(frame);
                //     this.pupil20DotChartComponent.framesSource.next(frame);
                //     this.secondDiameterChart.framesSource.next(frame);
                //     this.secondBacklightChart.framesSource.next(frame);
                //     this.thirdDiameterChart.framesSource.next(frame);
                //     this.fourthDiameterChart.framesSource.next(frame);
                //     break;
                default:
                    break;
            }
        });

        // this.pupilTestResults = this.pupil20TableComponent?.pupilTestResults;
    }

    public getRawModel(frames: IPupil20TestCamMessage[], startTimestamp: number): IRawExportData {
        const rawModel: IRawExportData = {
            rows: [],
        };

        if (frames.length === 0) return rawModel;

        frames.forEach(frame => {
            rawModel.rows.push({
                time: this.getMiliseconds(startTimestamp, frame.timestamp),
                pupil: {
                    OD: {
                        x: frame.pupilxOD === 0 ? null : frame.pupilxOD,
                        y: frame.pupilyOD === 0 ? null : frame.pupilyOD,
                        diameter: frame.measurementOD === 0 ? null : frame.measurementOD,
                        dpg: {
                            x: frame.dpgxOD,
                            y: frame.dpgyOD,
                        },
                        bpg: {
                            x: frame.bpgxOD,
                            y: frame.bpgyOD,
                        },
                    },
                    OS: {
                        x: frame.pupilxOS === 0 ? null : frame.pupilxOS,
                        y: frame.pupilyOS === 0 ? null : frame.pupilyOS,
                        diameter: frame.measurementOS,
                        dpg: {
                            x: frame.dpgxOS,
                            y: frame.dpgyOS,
                        },
                        bpg: {
                            x: frame.bpgxOS,
                            y: frame.bpgyOS,
                        },
                    },
                },
                stimulus: {
                    OD: {
                        x: null,
                        y: null,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                    OS: {
                        x: null,
                        y: null,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                },
                background: {
                    OD: {
                        r: frame.backgroundColorOD,
                        g: frame.backgroundColorOD,
                        b: frame.backgroundColorOD,
                    },
                    OS: {
                        r: frame.backgroundColorOS,
                        g: frame.backgroundColorOS,
                        b: frame.backgroundColorOS,
                    },
                },
            });
        });

        return rawModel;
    }

    public getMiliseconds(startTs: number, currentRawTs: number): number {
        const currentTs: number = currentRawTs / 10000;
        return Math.abs(currentTs - startTs);
    }
}
