import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IOcularRefractionNidekTonorefIIIMeasuredData } from '../../../../interfaces/measuredData/NIDEK/TonorefIII/ocular-refraction-nidek-tonorefIII.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class OcularRefractionTonorefIIIMeasuredData implements MeasuredData, IOcularRefractionNidekTonorefIIIMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public visualAcuity: {
        withoutGlasses: {
            OD: {
                distance: RegisterEditField<string>;
                near: RegisterEditField<string>;
                workingDistance: RegisterEditField<string>;
            };
            OS: {
                distance: RegisterEditField<string>;
                near: RegisterEditField<string>;
                workingDistance: RegisterEditField<string>;
            };
        };
        withGlasses: {
            OD: {
                distance: RegisterEditField<string>;
                near: RegisterEditField<string>;
                workingDistance: RegisterEditField<string>;
            };
            OS: {
                distance: RegisterEditField<string>;
                near: RegisterEditField<string>;
                workingDistance: RegisterEditField<string>;
            };
        };
        contactLens: {
            OD: {
                distance: RegisterEditField<string>;
                near: RegisterEditField<string>;
                workingDistance: RegisterEditField<string>;
            };
            OS: {
                distance: RegisterEditField<string>;
                near: RegisterEditField<string>;
                workingDistance: RegisterEditField<string>;
            };
        };
    };
    public duochromeTest: {
        OD: {
            response: RegisterEditField<string[]>;
            fogging: RegisterEditField<string[]>;
        };
        OS: {
            response: RegisterEditField<string[]>;
            fogging: RegisterEditField<string[]>;
        };
    };
    public objectiveRefraction: {
        OD: {
            sphere: RegisterEditField<string>;
            cylinder: RegisterEditField<string>;
            axis: RegisterEditField<string>;
        };
        OS: {
            sphere: RegisterEditField<string>;
            cylinder: RegisterEditField<string>;
            axis: RegisterEditField<string>;
        };
    };
    public subjectiveRefraction: {
        OD: {
            sphere: RegisterEditField<string>;
            cylinder: RegisterEditField<string>;
            axis: RegisterEditField<string>;
            distanceVisualAcuity: RegisterEditField<string>;
        };
        OS: {
            sphere: RegisterEditField<string>;
            cylinder: RegisterEditField<string>;
            axis: RegisterEditField<string>;
            distanceVisualAcuity: RegisterEditField<string>;
        };
    };
    public add: {
        OD: {
            dsph: RegisterEditField<string>;
            nva: RegisterEditField<string>;
            cm: RegisterEditField<string>;
            preference: RegisterEditField<string[]>;
        };
        OS: {
            dsph: RegisterEditField<string>;
            nva: RegisterEditField<string>;
            cm: RegisterEditField<string>;
            preference: RegisterEditField<string[]>;
        };
    };
    public finalPrescription: {
        OD: {
            sphere: RegisterEditField<string>;
            cylinder: RegisterEditField<string>;
            axis: RegisterEditField<string>;
            distanceVisualAcuity: RegisterEditField<string>;
            dsph: RegisterEditField<string>;
            cylinderNear: RegisterEditField<string>;
            axisNear: RegisterEditField<string>;
            nva: RegisterEditField<string>;
            cm: RegisterEditField<string>;
        };
        OS: {
            sphere: RegisterEditField<string>;
            cylinder: RegisterEditField<string>;
            axis: RegisterEditField<string>;
            distanceVisualAcuity: RegisterEditField<string>;
            dsph: RegisterEditField<string>;
            cylinderNear: RegisterEditField<string>;
            axisNear: RegisterEditField<string>;
            nva: RegisterEditField<string>;
            cm: RegisterEditField<string>;
        };
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_TONOREFIII;
        this.testType = TEST_TYPE.OCULAR_REFRACTION;
    }
    public setModel(model: IOcularRefractionNidekTonorefIIIMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.visualAcuity.withoutGlasses.OD.distance.setModel(model.visualAcuity.withoutGlasses.OD.distance);
        this.visualAcuity.withoutGlasses.OD.near.setModel(model.visualAcuity.withoutGlasses.OD.near);
        this.visualAcuity.withoutGlasses.OD.workingDistance.setModel(model.visualAcuity.withoutGlasses.OD.workingDistance);
        this.visualAcuity.withoutGlasses.OS.distance.setModel(model.visualAcuity.withoutGlasses.OS.distance);
        this.visualAcuity.withoutGlasses.OS.near.setModel(model.visualAcuity.withoutGlasses.OS.near);
        this.visualAcuity.withoutGlasses.OS.workingDistance.setModel(model.visualAcuity.withoutGlasses.OS.workingDistance);
        this.visualAcuity.withGlasses.OD.distance.setModel(model.visualAcuity.withGlasses.OD.distance);
        this.visualAcuity.withGlasses.OD.near.setModel(model.visualAcuity.withGlasses.OD.near);
        this.visualAcuity.withGlasses.OD.workingDistance.setModel(model.visualAcuity.withGlasses.OD.workingDistance);
        this.visualAcuity.withGlasses.OS.distance.setModel(model.visualAcuity.withGlasses.OS.distance);
        this.visualAcuity.withGlasses.OS.near.setModel(model.visualAcuity.withGlasses.OS.near);
        this.visualAcuity.withGlasses.OS.workingDistance.setModel(model.visualAcuity.withGlasses.OS.workingDistance);
        this.visualAcuity.contactLens.OD.distance.setModel(model.visualAcuity.contactLens.OD.distance);
        this.visualAcuity.contactLens.OD.near.setModel(model.visualAcuity.contactLens.OD.near);
        this.visualAcuity.contactLens.OD.workingDistance.setModel(model.visualAcuity.contactLens.OD.workingDistance);
        this.visualAcuity.contactLens.OS.distance.setModel(model.visualAcuity.contactLens.OS.distance);
        this.visualAcuity.contactLens.OS.near.setModel(model.visualAcuity.contactLens.OS.near);
        this.visualAcuity.contactLens.OS.workingDistance.setModel(model.visualAcuity.contactLens.OS.workingDistance);
        this.duochromeTest.OD.response.setModel(model.duochromeTest.OD.response);
        this.duochromeTest.OD.fogging.setModel(model.duochromeTest.OD.fogging);
        this.duochromeTest.OS.response.setModel(model.duochromeTest.OS.response);
        this.duochromeTest.OS.fogging.setModel(model.duochromeTest.OS.fogging);
        this.objectiveRefraction.OD.sphere.setModel(model.objectiveRefraction.OD.sphere);
        this.objectiveRefraction.OD.cylinder.setModel(model.objectiveRefraction.OD.cylinder);
        this.objectiveRefraction.OD.axis.setModel(model.objectiveRefraction.OD.axis);
        this.objectiveRefraction.OS.sphere.setModel(model.objectiveRefraction.OS.sphere);
        this.objectiveRefraction.OS.cylinder.setModel(model.objectiveRefraction.OS.cylinder);
        this.objectiveRefraction.OS.axis.setModel(model.objectiveRefraction.OS.axis);
        this.subjectiveRefraction.OD.sphere.setModel(model.subjectiveRefraction.OD.sphere);
        this.subjectiveRefraction.OD.cylinder.setModel(model.subjectiveRefraction.OD.cylinder);
        this.subjectiveRefraction.OD.axis.setModel(model.subjectiveRefraction.OD.axis);
        this.subjectiveRefraction.OD.distanceVisualAcuity.setModel(model.subjectiveRefraction.OD.distanceVisualAcuity);
        this.subjectiveRefraction.OS.sphere.setModel(model.subjectiveRefraction.OS.sphere);
        this.subjectiveRefraction.OS.cylinder.setModel(model.subjectiveRefraction.OS.cylinder);
        this.subjectiveRefraction.OS.axis.setModel(model.subjectiveRefraction.OS.axis);
        this.subjectiveRefraction.OS.distanceVisualAcuity.setModel(model.subjectiveRefraction.OS.distanceVisualAcuity);
        this.add.OD.dsph.setModel(model.add.OD.dsph);
        this.add.OD.nva.setModel(model.add.OD.nva);
        this.add.OD.cm.setModel(model.add.OD.cm);
        this.add.OD.preference.setModel(model.add.OD.preference);
        this.add.OS.dsph.setModel(model.add.OS.dsph);
        this.add.OS.nva.setModel(model.add.OS.nva);
        this.add.OS.cm.setModel(model.add.OS.cm);
        this.add.OS.preference.setModel(model.add.OS.preference);
        this.finalPrescription.OD.sphere.setModel(model.finalPrescription.OD.sphere);
        this.finalPrescription.OD.cylinder.setModel(model.finalPrescription.OD.cylinder);
        this.finalPrescription.OD.axis.setModel(model.finalPrescription.OD.axis);
        this.finalPrescription.OD.distanceVisualAcuity.setModel(model.finalPrescription.OD.distanceVisualAcuity);
        this.finalPrescription.OD.dsph.setModel(model.finalPrescription.OD.dsph);
        this.finalPrescription.OD.cylinderNear.setModel(model.finalPrescription.OD.cylinderNear);
        this.finalPrescription.OD.axisNear.setModel(model.finalPrescription.OD.axisNear);
        this.finalPrescription.OD.nva.setModel(model.finalPrescription.OD.nva);
        this.finalPrescription.OD.cm.setModel(model.finalPrescription.OD.cm);
        this.finalPrescription.OS.sphere.setModel(model.finalPrescription.OS.sphere);
        this.finalPrescription.OS.cylinder.setModel(model.finalPrescription.OS.cylinder);
        this.finalPrescription.OS.axis.setModel(model.finalPrescription.OS.axis);
        this.finalPrescription.OS.distanceVisualAcuity.setModel(model.finalPrescription.OS.distanceVisualAcuity);
        this.finalPrescription.OS.dsph.setModel(model.finalPrescription.OS.dsph);
        this.finalPrescription.OS.cylinderNear.setModel(model.finalPrescription.OS.cylinderNear);
        this.finalPrescription.OS.axisNear.setModel(model.finalPrescription.OS.axisNear);
        this.finalPrescription.OS.nva.setModel(model.finalPrescription.OS.nva);
        this.finalPrescription.OS.cm.setModel(model.finalPrescription.OS.cm);
    }
    public getModel(): IOcularRefractionNidekTonorefIIIMeasuredData {
        let model: IOcularRefractionNidekTonorefIIIMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            visualAcuity: {
                withoutGlasses: {
                    OD: {
                        distance: this.visualAcuity.withoutGlasses.OD.distance.getModel(),
                        near: this.visualAcuity.withoutGlasses.OD.near.getModel(),
                        workingDistance: this.visualAcuity.withoutGlasses.OD.workingDistance.getModel(),
                    },
                    OS: {
                        distance: this.visualAcuity.withoutGlasses.OS.distance.getModel(),
                        near: this.visualAcuity.withoutGlasses.OS.near.getModel(),
                        workingDistance: this.visualAcuity.withoutGlasses.OS.workingDistance.getModel(),
                    },
                },
                withGlasses: {
                    OD: {
                        distance: this.visualAcuity.withGlasses.OD.distance.getModel(),
                        near: this.visualAcuity.withGlasses.OD.near.getModel(),
                        workingDistance: this.visualAcuity.withGlasses.OD.workingDistance.getModel(),
                    },
                    OS: {
                        distance: this.visualAcuity.withGlasses.OS.distance.getModel(),
                        near: this.visualAcuity.withGlasses.OS.near.getModel(),
                        workingDistance: this.visualAcuity.withGlasses.OS.workingDistance.getModel(),
                    },
                },
                contactLens: {
                    OD: {
                        distance: this.visualAcuity.contactLens.OD.distance.getModel(),
                        near: this.visualAcuity.contactLens.OD.near.getModel(),
                        workingDistance: this.visualAcuity.contactLens.OD.workingDistance.getModel(),
                    },
                    OS: {
                        distance: this.visualAcuity.contactLens.OS.distance.getModel(),
                        near: this.visualAcuity.contactLens.OS.near.getModel(),
                        workingDistance: this.visualAcuity.contactLens.OS.workingDistance.getModel(),
                    },
                },
            },
            duochromeTest: {
                OD: {
                    response: this.duochromeTest.OD.response.getModel(),
                    fogging: this.duochromeTest.OD.fogging.getModel(),
                },
                OS: {
                    response: this.duochromeTest.OS.response.getModel(),
                    fogging: this.duochromeTest.OS.fogging.getModel(),
                },
            },
            objectiveRefraction: {
                OD: {
                    sphere: this.objectiveRefraction.OD.sphere.getModel(),
                    cylinder: this.objectiveRefraction.OD.cylinder.getModel(),
                    axis: this.objectiveRefraction.OD.axis.getModel(),
                },
                OS: {
                    sphere: this.objectiveRefraction.OS.sphere.getModel(),
                    cylinder: this.objectiveRefraction.OS.cylinder.getModel(),
                    axis: this.objectiveRefraction.OS.axis.getModel(),
                },
            },
            subjectiveRefraction: {
                OD: {
                    sphere: this.subjectiveRefraction.OD.sphere.getModel(),
                    cylinder: this.subjectiveRefraction.OD.cylinder.getModel(),
                    axis: this.subjectiveRefraction.OD.axis.getModel(),
                    distanceVisualAcuity: this.subjectiveRefraction.OD.distanceVisualAcuity.getModel(),
                },
                OS: {
                    sphere: this.subjectiveRefraction.OS.sphere.getModel(),
                    cylinder: this.subjectiveRefraction.OS.cylinder.getModel(),
                    axis: this.subjectiveRefraction.OS.axis.getModel(),
                    distanceVisualAcuity: this.subjectiveRefraction.OS.distanceVisualAcuity.getModel(),
                },
            },
            add: {
                OD: {
                    dsph: this.add.OD.dsph.getModel(),
                    nva: this.add.OD.nva.getModel(),
                    cm: this.add.OD.cm.getModel(),
                    preference: this.add.OD.preference.getModel(),
                },
                OS: {
                    dsph: this.add.OS.dsph.getModel(),
                    nva: this.add.OS.nva.getModel(),
                    cm: this.add.OS.cm.getModel(),
                    preference: this.add.OS.preference.getModel(),
                },
            },
            finalPrescription: {
                OD: {
                    sphere: this.finalPrescription.OD.sphere.getModel(),
                    cylinder: this.finalPrescription.OD.cylinder.getModel(),
                    axis: this.finalPrescription.OD.axis.getModel(),
                    distanceVisualAcuity: this.finalPrescription.OD.distanceVisualAcuity.getModel(),
                    dsph: this.finalPrescription.OD.dsph.getModel(),
                    cylinderNear: this.finalPrescription.OD.cylinderNear.getModel(),
                    axisNear: this.finalPrescription.OD.axisNear.getModel(),
                    nva: this.finalPrescription.OD.nva.getModel(),
                    cm: this.finalPrescription.OD.cm.getModel(),
                },
                OS: {
                    sphere: this.finalPrescription.OS.sphere.getModel(),
                    cylinder: this.finalPrescription.OS.cylinder.getModel(),
                    axis: this.finalPrescription.OS.axis.getModel(),
                    distanceVisualAcuity: this.finalPrescription.OS.distanceVisualAcuity.getModel(),
                    dsph: this.finalPrescription.OS.dsph.getModel(),
                    cylinderNear: this.finalPrescription.OS.cylinderNear.getModel(),
                    axisNear: this.finalPrescription.OS.axisNear.getModel(),
                    nva: this.finalPrescription.OS.nva.getModel(),
                    cm: this.finalPrescription.OS.cm.getModel(),
                },
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.add.OD.dsph.isEmpty() &&
            this.add.OD.nva.isEmpty() &&
            this.add.OD.cm.isEmpty() &&
            this.add.OD.preference.isEmpty() &&
            this.add.OS.dsph.isEmpty() &&
            this.add.OS.nva.isEmpty() &&
            this.add.OS.cm.isEmpty() &&
            this.add.OS.preference.isEmpty() &&
            this.duochromeTest.OD.fogging.isEmpty() &&
            this.duochromeTest.OS.fogging.isEmpty() &&
            this.duochromeTest.OD.response.isEmpty() &&
            this.duochromeTest.OS.response.isEmpty() &&
            this.finalPrescription.OD.axis.isEmpty() &&
            this.finalPrescription.OD.axisNear.isEmpty() &&
            this.finalPrescription.OD.cylinder.isEmpty() &&
            this.finalPrescription.OD.cylinderNear.isEmpty() &&
            this.finalPrescription.OD.distanceVisualAcuity.isEmpty() &&
            this.finalPrescription.OD.dsph.isEmpty() &&
            this.finalPrescription.OD.nva.isEmpty() &&
            this.finalPrescription.OD.sphere.isEmpty() &&
            this.finalPrescription.OD.cm.isEmpty() &&
            this.finalPrescription.OS.axis.isEmpty() &&
            this.finalPrescription.OS.axisNear.isEmpty() &&
            this.finalPrescription.OS.cylinder.isEmpty() &&
            this.finalPrescription.OS.cylinderNear.isEmpty() &&
            this.finalPrescription.OS.distanceVisualAcuity.isEmpty() &&
            this.finalPrescription.OS.dsph.isEmpty() &&
            this.finalPrescription.OS.nva.isEmpty() &&
            this.finalPrescription.OS.sphere.isEmpty() &&
            this.finalPrescription.OS.cm.isEmpty() &&
            this.objectiveRefraction.OD.axis.isEmpty() &&
            this.objectiveRefraction.OD.cylinder.isEmpty() &&
            this.objectiveRefraction.OD.sphere.isEmpty() &&
            this.objectiveRefraction.OS.axis.isEmpty() &&
            this.objectiveRefraction.OS.cylinder.isEmpty() &&
            this.objectiveRefraction.OS.sphere.isEmpty() &&
            this.subjectiveRefraction.OD.axis.isEmpty() &&
            this.subjectiveRefraction.OD.cylinder.isEmpty() &&
            this.subjectiveRefraction.OD.distanceVisualAcuity.isEmpty() &&
            this.subjectiveRefraction.OD.sphere.isEmpty() &&
            this.subjectiveRefraction.OS.axis.isEmpty() &&
            this.subjectiveRefraction.OS.cylinder.isEmpty() &&
            this.subjectiveRefraction.OS.distanceVisualAcuity.isEmpty() &&
            this.subjectiveRefraction.OS.sphere.isEmpty() &&
            this.visualAcuity.contactLens.OD.distance.isEmpty() &&
            this.visualAcuity.contactLens.OD.near.isEmpty() &&
            this.visualAcuity.contactLens.OD.workingDistance.isEmpty() &&
            this.visualAcuity.contactLens.OS.distance.isEmpty() &&
            this.visualAcuity.contactLens.OS.near.isEmpty() &&
            this.visualAcuity.contactLens.OS.workingDistance.isEmpty() &&
            this.visualAcuity.withGlasses.OD.distance.isEmpty() &&
            this.visualAcuity.withGlasses.OD.near.isEmpty() &&
            this.visualAcuity.withGlasses.OD.workingDistance.isEmpty() &&
            this.visualAcuity.withGlasses.OS.distance.isEmpty() &&
            this.visualAcuity.withGlasses.OS.near.isEmpty() &&
            this.visualAcuity.withGlasses.OS.workingDistance.isEmpty() &&
            this.visualAcuity.withoutGlasses.OD.distance.isEmpty() &&
            this.visualAcuity.withoutGlasses.OD.near.isEmpty() &&
            this.visualAcuity.withoutGlasses.OD.workingDistance.isEmpty() &&
            this.visualAcuity.withoutGlasses.OS.distance.isEmpty() &&
            this.visualAcuity.withoutGlasses.OS.near.isEmpty() &&
            this.visualAcuity.withoutGlasses.OS.workingDistance.isEmpty()
        );
    }
}
