import { ICamMessage } from '../../../commonout/interfaces/charts.model';
import { OCULUS } from '../enums/oculus.enum';
import { IRawExportData } from './rawExportData.interface';

export interface IPupil20TestCamMessage extends ICamMessage {
    message_type: number;
    timestamp: number;
    backgroundColorOD: number;
    backgroundColorOS: number;
    measurementOD: number;
    measurementOS: number;
    measurementODwithoutZeros: number;
    measurementOSwithoutZeros: number;
    firstDeviationOD: number;
    firstDeviationOS: number;
    secondDeviationOD: number;
    secondDeviationOS: number;
    movingAverageOD: number;
    movingAverageOS: number;
    secondMovingAverageOD: number;
    secondMovingAverageOS: number;
    movingAverageODwithoutZeros: number;
    movingAverageOSwithoutZeros: number;
    velocityOD: number;
    velocityOS: number;
    timePassed: number;
    pupilxOD: number;
    pupilxOS: number;
    pupilyOD: number;
    pupilyOS: number;
    dpgxOS: number;
    dpgxOD: number;
    dpgyOS: number;
    dpgyOD: number;
    bpgxOS: number;
    bpgxOD: number;
    bpgyOS: number;
    bpgyOD: number;
}

export interface PupilTestResults {
    round?: number;
    pupilDiamaterResults?: PupilDiameterResults;
    latencyResults?: LatencyResults;
    peakVelocityResults?: PeakVelocityResults;
    areaResults?: AreaResults;
}

export interface PupilDiameterResults {
    averageDiameterOD?: number[];
    averageDiameterOS?: number[];
    differenceDiameter?: number[];
    startTime?: number[];
    endTime?: number[];
    shiftTypes?: SHIFT_TYPE[];
    testResultCoors?: TestResultCoordinates[];
    tableCellWidths?: number[];
    tableWidth?: number;
}

export enum SHIFT_TYPE {
    DOWNGRADE,
    UPGRADE,
    NO_CONTENT,
}

export interface LatencyResults {
    latencyOD?: number[];
    latencyOS?: number[];
    differenceLatency?: number[];
    endTimeOD?: number[];
    endTimeOS?: number[];
    startTime?: number[];
    testResultCoors?: TestResultCoordinates[];
    tableCellWidths?: number[];
    tableWidth?: number;
}

export interface PeakVelocityResults {
    peakVelocityOD?: number[];
    peakVelocityOS?: number[];
    differencePeakVelocity?: number[];
    testResultCoors?: TestResultCoordinates[];
    tableCellWidths?: number[];
    tableWidth?: number;
    timePassed?: number[];
}

export interface AreaTableResults {
    areaResults: AreaResults;
    resultType: AREA_RESULT_TYPE;
}

export interface AreaResults {
    areaOD: number[];
    areaOS: number[];
    areaDifference: number[];
    startTime?: number[];
    endTime?: number[];
    testResultCoors?: TestResultCoordinates[];
    tableCellWidths?: number[];
    resultType?: AREA_RESULT_TYPE;
}

export enum AREA_RESULT_TYPE {
    ACCEPTABLE,
    NOT_ACCEPTABLE,
    NO_CONTENT,
}

export interface TestResultCoordinates {
    run?: number;
    deltaSingleOculus?: boolean;
    xValueOD?: number;
    xValueOS?: number;
    xValue?: number;
    deltaXvalue?: number;
    xRange?: number;
    yValue?: number;
    yAreaValuesOS?: number[];
    yAreaValuesOD?: number[];
    xAreaValues?: number[];
    deltaYvalue?: number;
    oculus?: OCULUS;
    xSecondValue?: number;
}

export interface PupilDotChartResults {
    shiftedDotsOdd: Dot[];
    dotsEven: Dot[];
    dataRAPD: RAPD;
}

export interface ExportPupilTestResults {
    pupilTestResults: ExportTableTestResults[];
    pupilDotChartResult: PupilDotChartResults;
    rawFirstStagesFrames: IRawExportData;
    rawRapdFrames: IRawExportData;
}

export interface ExportTableTestResults {
    round?: number;
    pupilDiameterResults?: ExportPupilDiameterResults[];
    latencyResults?: ExportLatencyResults[];
    peakVelocityResults?: ExportPeakVelocityResults[];
    areaResults?: ExportAreaResults[];
}

export interface ExportPupilDiameterResults {
    averageDiameterOD?: number;
    averageDiameterOS?: number;
    differenceDiameter?: number;
    diameterChangeOD?: number;
    diameterChangeOS?: number;
    differenceDiameterChange?: number;
    startTime?: number;
    endTime?: number;
    shiftTypes?: SHIFT_TYPE;
    testResultCoors?: TestResultCoordinates;
    tableCellWidths?: number;
    tableWidth?: number;
}

export interface ExportLatencyResults {
    latencyOD?: number;
    latencyOS?: number;
    differenceLatency?: number;
    endTimeOD?: number;
    endTimeOS?: number;
    startTime?: number;
    testResultCoors?: TestResultCoordinates;
    tableCellWidths?: number;
    tableWidth?: number;
}

export interface ExportPeakVelocityResults {
    peakVelocityOD?: number;
    peakVelocityOS?: number;
    differencePeakVelocity?: number;
    testResultCoors?: TestResultCoordinates;
    tableCellWidths?: number;
    tableWidth?: number;
    timeOD?: number;
    timeOS?: number;
}

export interface ExportAreaResults {
    areaOD: number;
    areaOS: number;
    areaDifference: number;
    startTime: number;
    endTime: number;
    leftLimit: number;
    rightLimit: number;
}

export interface Dot {
    x: number;
    y: number;
    round: number;
    oculus: OCULUS;
    isOdd: boolean;
    timestamp: number;
}

export interface RAPD {
    value: string;
    oculus: OCULUS;
}
