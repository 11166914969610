import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrder } from '../../../../../../commonout/interfaces/order.interface';
import { IUser } from '../../../../../../commonout/interfaces/user';
import { ConfigService } from '../general/config.service';

@Injectable()
export class UserService {
    constructor(private configService: ConfigService, private http: HttpClient) {}
    public getUsers<T>(): Observable<IUser<T>[]> {
        const url = `${this.configService.backendUrl}/users`;
        return this.http.get<IUser<T>[]>(url);
    }
    public getUser<T>(id: string): Observable<IUser<T>> {
        const url = `${this.configService.backendUrl}/users/one/${id}`;
        return this.http.get<IUser<T>>(url);
    }
    public registerKey(idORtoken: string): Observable<CredentialCreationOptions> {
        return this.http.get<CredentialCreationOptions>(`${this.configService.backendUrl}/webAuth/register-key/${idORtoken}`);
    }
    public createUser<T>(user: IUser<T>): Observable<IUser<T>> {
        const url = `${this.configService.backendUrl}/users/create`;
        return this.http.post<IUser<T>>(url, user);
    }
    public updateUser<T>(user: IUser<T>): Observable<IUser<T>> {
        const url = `${this.configService.backendUrl}/users/update`;
        return this.http.post<IUser<T>>(url, user);
    }

    search<T>(term: any, order?: IOrder, page?: number): Observable<{ users: IUser<T>[]; more: boolean }> {
        const url = `${this.configService.backendUrl}/users/find`;
        return this.http.post<{ users: IUser<T>[]; more: boolean }>(url, { term, order, page });
    }
}
