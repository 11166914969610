// @ts-nocheck
import * as d3 from 'd3';
// import * as d3Drag from 'd3-drag';

import * as _ from 'lodash';
import { translate, findClosePoint, useTitle, DirectedLayout } from './lib';
import { sdLine, drawTriangle, drawArrows1, drawArrows2, drawPreview } from './lib';
import { addInfoOld } from './lib';
import { STYLE, Style } from './style';

// setup
import { Scales } from './setup/Scales';
import { Lines } from './setup/Lines';
import { Targets } from './setup/Targets';
import { RenderOnce } from './setup/RenderOnce';

import * as lo from './layout';

import { percentLine, emptyPercentLine, Intersections } from './functions';

import { ComboBox, InfoButton } from './lib';
import { Option } from './lib';
import { Target } from './lib';

import { successPercentLinesByAge, errorPercentLinesByAge } from './fixedData';

import { DispersePoints, dispersePoints } from '../../lib/util'

const HANDLE_INFO_TEXT = '←Drag this handle\nto see percentages at a different time.';

// currently there is some split between outside and inside types
// not sure when to get rid of it yet
// probably when there is some other place to have filtering/type-checking of incoming stuff
export interface IncomingMessage {
    message_type?: number;
    type?: string;

    x?: number;
    y?: number;
    trial?: number;
    age?: number;
}
interface StartMessage {
    message_type: number;
    age?: number; // can be missing for old data
}

interface DismissFlag {
    trial: number;
}
interface TrialStep {
    x: number;
    y: number;
    trial: number;
}

// this "is" is not really changing what was expected (still gotta use "as")
function isTrialStep(x: IncomingMessage): x is TrialStep {
    let rightType = x.type === 'trialStep' || x.message_type === 6;
    return rightType && typeof x.x === 'number' && typeof x.y === 'number' && typeof x.trial === 'number';
}
function isDismissFlag(x: IncomingMessage): x is DismissFlag {
    let rightType = x.type === 'dismissFlag';
    return rightType && typeof x.trial === 'number';
}
function isStart(x: IncomingMessage): x is StartMessage {
    return x.message_type === 0;
}
function isFinish(x: IncomingMessage) {
    return x.message_type === 1;
}

interface Point {
    x: number;
    y: number;
}

interface DiagramParams {
    target?: HTMLElement;
    svgTarget?: HTMLElement;
    theme: string;
    // overwrite is something different than just style
}

class State {
    domainX = [0, 1000];
    domainY = [-10, 10];
    domainBottomX = [0, 1000];
    domainBottomY = [5, 100];
    subjectAge = 0;

    movableX = 500; // draggable vertical line

    practiceTrialsMaxIndex = 9; // [0-9] - 10 items
    correctMargin = 1.5;
    incorrectMargin = -1.5;

    approximationStep = 20;
    // own state
    trials: TrialStep[] = [];
    trialsCount = 0;
    // intersectionsStats =
    // \: {
    //     success: { mean: number; sd: number };
    //     error: { mean: number; sd: number };
    //     corrected: { mean: number; sd: number };
    // };
    intersectionsStats = {
        success: { mean: 0, sd: 0 },
        error: { mean: 0, sd: 0 },
        corrected: { mean: 0, sd: 0 },
    };

    hideRawData: boolean = false;
    hideAgeGroups: boolean = true;
    showNoResponse: boolean = true;
    showSuccess: boolean = true;
    showCorrected: boolean = true;
    showError: boolean = true;

    dismissedTrials: { [k: number]: boolean } = {};
    showRange = 'all'; // not enum since it is probably temporary

    // computed stuff
    countDismissed = 0;
    trialLines: Point[][] = [];
    derivedLines = emptyPercentLine();
    movableUsedX: number | null = null; // data-x is different from drag-x
    successMovableY = 0;
    errorMovableY = 0;
    noResponseMovableY = 0;
    correctedMovableY = 0;

    computedLinesResolution = { from: 0, to: 1000, step: this.approximationStep };
    usedXs = _.range(this.computedLinesResolution.from, this.computedLinesResolution.to + this.computedLinesResolution.step, this.computedLinesResolution.step);
}

export class Diagram {
    // configuration
    throttleRefresh = 500; // ms
    refresh: () => void;

    // too small will create non-smooth line due to
    // using linear approximation to get points at fixed step values

    state = new State();

    // construction
    target?: HTMLElement;
    svgTarget?: HTMLElement;
    style: Style;

    scale = new Scales(this);
    line = new Lines(this);

    g: Targets;
    renderOnce: RenderOnce;

    // children
    sourceChooser?: ComboBox;
    legendButton?: ComboBox;
    infoButton?: InfoButton;

    // related to initalization also
    didMovableDragEnable: boolean = false;

    constructor(params: DiagramParams) {
        let { target, svgTarget, theme } = params;

        this.style = STYLE[theme] || STYLE.default;

        if (target) {
            this.target = target;
        } else if (svgTarget) {
            this.svgTarget = svgTarget;
        }

        this.g = new Targets(this);
        this.initChildren();
        this.renderOnce = new RenderOnce(this);
        this.refresh = _.throttle(() => {
            this.recompute();
            this.render();
        }, this.throttleRefresh);
    }
    initChildren() {
        let sourceTitle = this.getSourceTitle();
        let optionHeight = 60;
        let comboProps = { height: 50, y: 0 };
        let comboWidth = 330;
        let comboGap = 25;
        let buttonWidth = 50;
        let layout = new DirectedLayout('x', 'width', 50 + comboWidth, comboWidth, comboGap, comboProps);
        layout.swapDirection = true;
        // x: -760,
        // width: 330,
        // height: 50,
        //
        // x: -380,
        // width: 330,
        // height: 50,
        let optionSettings = {
            height: optionHeight,
            textAlign: 'left',
            doNotClose: true,
            style: this.style,
        };
        let more = 1;
        let previewTrials = {
            x: 140 + 10 * more,
            render: drawPreview({
                colors: [this.style.trialLine.color],
                strokeWidth: this.style.trialLine.size,
                timesCount: 5,
                timesStep: 5,
            }),
        };
        let blues = d3.interpolateBlues;
        let previewAgeGroups = {
            x: 225 + 10 * more,
            render: drawPreview({
                colors: [blues(0.3), blues(0.6), blues(0.9)],
                strokeWidth: this.style.ageGroupLine.size,
                timesCount: 3,
                timesStep: 5,
            }),
        };
        let previewSuccess = {
            x: 248 + 13 * more,
            render: drawPreview({
                colors: [this.style.successLine.color],
                strokeWidth: this.style.successLine.size,
                timesCount: 1,
                timesStep: 5,
            }),
        };
        let previewNoResponse = {
            x: 240 + 18 * more,
            render: drawPreview({
                colors: [this.style.noResponseLine.color],
                strokeWidth: this.style.noResponseLine.size,
                timesCount: 1,
                timesStep: 5,
            }),
        };
        let previewCorrected = {
            x: 205 + 13 * more,
            render: drawPreview({
                colors: [this.style.correctedLine.color],
                strokeWidth: this.style.correctedLine.size,
                timesCount: 1,
                timesStep: 5,
            }),
        };
        let previewError = {
            x: 275 + 20 * more,
            render: drawPreview({
                colors: [this.style.incorrectLine.color],
                strokeWidth: this.style.incorrectLine.size,
                timesCount: 1,
                timesStep: 5,
            }),
        };

        this.infoButton = new InfoButton({
            ...InfoButton.defaults,
            ...layout.take({ width: buttonWidth - 5 }),
            parent: this.g.buttonsTarget,
            // x: 100 + 300,
            // y: 50,
            // width: 50,
            // height: 50,
        });
        this.legendButton = new ComboBox({
            ...layout.take(),
            title: 'legend',
            style: this.style,
            children: [
                new Option({ text: 'trials', handler: () => this.toggleHideRawData(), ...optionSettings, getChecked: () => !this.state.hideRawData, preview: previewTrials }),
                new Option({
                    text: 'age groups',
                    handler: () => this.toggleHideAgeGroups(),
                    ...optionSettings,
                    getChecked: () => !this.state.hideAgeGroups,
                    preview: previewAgeGroups,
                }),
                new Option({
                    text: 'no response',
                    handler: () => this.toggleShowNoResponse(),
                    ...optionSettings,
                    getChecked: () => this.state.showNoResponse,
                    preview: previewNoResponse,
                }),
                new Option({ text: 'anti-saccade', handler: () => this.toggleShowSuccess(), ...optionSettings, getChecked: () => this.state.showSuccess, preview: previewSuccess }),
                new Option({ text: 'saccade (error)', handler: () => this.toggleShowError(), ...optionSettings, getChecked: () => this.state.showError, preview: previewError }),
                new Option({
                    text: 'corrected',
                    handler: () => this.toggleShowCorrected(),
                    ...optionSettings,
                    getChecked: () => this.state.showCorrected,
                    preview: previewCorrected,
                }),
            ],
        });
        this.sourceChooser = new ComboBox({
            ...layout.take(),
            title: sourceTitle,
            style: this.style,
            children: [
                new Option({
                    text: 'all trials',
                    handler: () => this.doShowAllTrials(),
                    height: optionHeight,
                    style: this.style,
                }),
                new Option({
                    text: 'practice trials',
                    handler: () => this.doShowPracticeTrials(),
                    height: optionHeight,
                    style: this.style,
                }),
                new Option({
                    text: 'after practice',
                    handler: () => this.doShowCoreTrials(),
                    height: optionHeight,
                    style: this.style,
                }),
            ],
        });
        let centerBetween = lo.betweenCharts.centerPoint();
        let centerDy = lo.betweenCharts.height() / 2;
        addInfoOld(this.g.infoTarget, centerBetween.x, centerBetween.y - centerDy, 'Hover the mouse over points on the part below\nto see what they represent', '', {
            center: true,
        });
        addInfoOld(this.g.buttonsInfoTarget, this.legendButton.x, this.legendButton.y - 53, 'Click to show/hide the legend\n↓and to toggle visibility of lines');
        addInfoOld(this.g.buttonsInfoTarget, this.sourceChooser.x, this.sourceChooser.y - 53, 'Click to filter data:\n↓all/first 10/after 10');
    }

    trialsCurrent() {
        let { dismissedTrials, showRange } = this.state;

        let trials;
        if (showRange === 'all') {
            trials = this.state.trials;
        } else if (showRange === 'practice') {
            trials = this.state.trials.filter((x) => x.trial <= this.state.practiceTrialsMaxIndex);
        } else if (showRange === 'afterPractice') {
            trials = this.state.trials.filter((x) => x.trial > this.state.practiceTrialsMaxIndex);
        } else {
            throw Error('todo: free ranges!');
        }

        let count1 = Object.keys(_.groupBy(trials, (x) => x.trial)).length;
        trials = trials.filter((x) => !dismissedTrials[x.trial]);
        let count2 = Object.keys(_.groupBy(trials, (x) => x.trial)).length;
        this.state.countDismissed = count1 - count2;
        return trials;
    }

    maybeLog(given: IncomingMessage[]) {
        if (typeof window['doDebug'] === 'boolean' && window['doDebug']) {
            console.log(given);
        }
    }

    // external addData
    // does filtering
    addData(data: IncomingMessage[], noRecalc: boolean = false) {
        this.maybeLog(data);
        for (let x of data) {
            if (isTrialStep(x)) {
                this.handleTrialStep(x);
            } else if (isDismissFlag(x)) {
                this.handleDismissFlag(x);
            } else if (isStart(x)) {
                this.state.subjectAge = x.age || 0;
                this.clearState();
            } else if (isFinish(x)) {
                // noop
            } else {
                console.log('ignored message:', x);
            }
        }
        if (noRecalc === false) {
            this.recompute();
        }
    }

    handleTrialStep(given: TrialStep) {
        this.state.trials.push(given);
    }
    handleDismissFlag(given: DismissFlag) {
        this.state.dismissedTrials[given.trial] = true;
    }

    recompute() {
        let { usedXs } = this.state;

        let trialsObj = _.groupBy(this.trialsCurrent(), (x) => x.trial);
        this.state.trialLines = Object.values(trialsObj);
        this.state.trialsCount = Object.values(trialsObj).length;
        let trials = Object.entries(trialsObj).map(([trial, points]) => ({ trial: +trial, points }));

        let predicates = {
            success: (point: Point) => point.y > this.state.correctMargin,
            error: (point: Point) => point.y < this.state.incorrectMargin,
            noResponse: (point: Point) => point.y <= this.state.correctMargin && point.y >= this.state.incorrectMargin,
        };
        let derivedLines = percentLine(trials, usedXs, predicates);
        derivedLines.error = derivedLines.error.map((p) => ({ x: p.x, y: -p.y }));

        // NOTE: micro-optimizable (do not do things twice, also incremental calc)
        let getMean = (obj: Intersections) => d3.mean(Object.values(obj).map((p) => p.x));
        let getSD = (obj: Intersections) => d3.deviation(Object.values(obj).map((p) => p.x));
        let intersectionsStats = {
            success: { mean: 0, sd: 0 },
            error: { mean: 0, sd: 0 },
            corrected: { mean: 0, sd: 0 },
        };
        Object.keys(derivedLines.intersections).forEach((name) => {
            intersectionsStats[name] = {
                mean: getMean(derivedLines.intersections[name]),
                sd: getSD(derivedLines.intersections[name]),
            };
        });

        this.state.derivedLines = derivedLines;
        this.state.intersectionsStats = intersectionsStats;
    }

    renderTrialLines() {
        let target = this.g.topChartTrials;
        let { line } = this.line;

        let trialLines = this.state.trialLines;
        if (this.state.hideRawData) trialLines = [];

        target
            .selectAll('path')
            .data(trialLines)
            .join('path')
            .attr('d', line)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.trialLine.color)
            .attr('stroke-width', this.style.trialLine.size);
    }

    renderDerivedLines() {
        let target0 = this.g.topChartDerived0;
        let target1 = this.g.topChartDerived1;
        let target2 = this.g.topChartDerived2;
        let target3 = this.g.topChartDerived3;
        let { linePercent } = this.line;

        let derivedLines = this.state.derivedLines;

        let { noResponse, success, error, corrected } = derivedLines;

        if (this.state.showNoResponse === false) noResponse = [];
        if (this.state.showSuccess === false) success = [];
        if (this.state.showError === false) error = [];
        if (this.state.showCorrected === false) corrected = [];

        target0
            .selectAll('path')
            .data([noResponse])
            .join('path')
            .attr('d', linePercent)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.noResponseLine.color)
            .attr('stroke-width', this.style.noResponseLine.size);

        target1
            .selectAll('path')
            .data([success])
            .join('path')
            .attr('d', linePercent)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.successLine.color)
            .attr('stroke-width', this.style.successLine.size);

        target2
            .selectAll('path')
            .data([error])
            .join('path')
            .attr('d', linePercent)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.incorrectLine.color)
            .attr('stroke-width', this.style.incorrectLine.size);

        target3
            .selectAll('path')
            .data([corrected])
            .join('path')
            .attr('d', linePercent)
            .attr('fill', 'transparent')
            .attr('stroke', this.style.correctedLine.color)
            .attr('stroke-width', this.style.correctedLine.size);
    }

    renderMovableLine() {
        let target = this.g.movableLineTarget;
        let { movableX, movableUsedX } = this.state;

        let setCursor = (x: Target) => x.attr('cursor', 'grab');

        let line = d3
            .line<{ y: number }>()
            .x((d) => 0)
            .y((d) => this.scale.y(d.y));

        let lineData = [{ y: -10 }, { y: 12 }];

        let halfWidth = 50;
        let halfHeight = 20;

        let textY = this.scale.y.range()[1] - halfHeight * 3.0 + 8;
        let rectY = this.scale.y.range()[1] - halfHeight * 4.0;
        let arrowsY = this.scale.y.range()[1] - halfHeight * 3.0;
        let arrowsScale = 'scale(2)';
        let arrowsX = 30;

        let xData = { x: movableX };
        let xTextData = { x: movableUsedX == null ? movableX : movableUsedX };

        let darkColor = this.style.handleLine.color;
        let darkKlass = this.style.handleLine.klass;

        // NOTE: dirty scaleY usage everywhere below
        target.datum(xData).attr('transform', (d) => translate({ x: this.scale.x(d.x), y: 0 }));
        target
            .selectAll('.path0')
            .data([lineData])
            .join('path')
            .attr('class', 'path0 ' + darkKlass)
            .attr('d', line)
            .attr('stroke', darkColor)
            .attr('stroke-width', this.style.handleLine.size);
        let g = target
            .selectAll('g')
            .data([null])
            .join('g');

        g.selectAll('.path1')
            .data([null])
            .join('path')
            .attr('class', 'path1 ' + darkKlass)
            .attr('d', drawArrows1)
            .attr('fill', darkColor)
            .attr('transform', translate({ y: arrowsY, x: arrowsX }) + ' ' + arrowsScale);

        g.selectAll('.path2')
            .data([null])
            .join('path')
            .attr('class', 'path2 ' + darkKlass)
            .attr('d', drawArrows2)
            .attr('fill', darkColor)
            .attr('transform', translate({ y: arrowsY, x: -arrowsX }) + ' ' + arrowsScale);

        g.selectAll('rect')
            .data([null])
            .join('rect')
            .attr('x', 0 - halfWidth)
            .attr('y', rectY)
            .attr('width', halfWidth * 2)
            .attr('height', halfHeight * 2)
            .attr('class', darkKlass)
            .attr('fill', darkColor)
            .attr('stroke', darkColor)
            .attr('stroke-width', 2)
            .call(setCursor);

        let infoDx = 10;
        let infoDy = -5;

        let infoX = halfWidth + infoDx;
        let infoY = rectY + 0 * halfHeight * 2 + infoDy;

        addInfoOld(g, infoX, infoY, HANDLE_INFO_TEXT, 'handle-info');

        g.selectAll('.text')
            .data([xTextData])
            .join('text')
            .attr('class', 'text')
            .text((d) => `${d.x.toFixed(0)} ms`)
            .attr('fill', this.style.handleLineText.color)
            .attr('text-anchor', 'middle')
            .attr('font-size', this.style.handleLineText.size)
            .attr('font-weight', this.style.handleLineText.weight)
            .attr('y', textY)
            .call(setCursor);

        let setX = (value: number) => {
            this.state.movableX = value;
            window.requestAnimationFrame(() => {
                this.renderMovableLine();
            });
        };
        let onDrag = () => {
            let x = d3.event.x;
            x = this.scale.x.invert(x);

            let domain = this.scale.x.domain();
            if (x < domain[0]) x = domain[0];
            if (x > domain[1]) x = domain[1];
            setX(x);
        };

        type HasX = { x: number };

        if (!this.didMovableDragEnable) {
            this.didMovableDragEnable = true;
            // let subject = (d: Point) => ({ x: this.scale.x(d.x), y: d.y });
            let subject = (d: HasX) => ({ x: this.scale.x(d.x), y: 0 });
            let dragBehavior = d3
                .drag<SVGElement, HasX>()
                // .drag<SVGElement, HasX, d3Drag.SubjectPosition>()
                // .drag()
                .on('drag', onDrag)
                .subject(subject);
            // (d: HasX,i,g)=> {
            //   return subject(d)
            // });
            // .subject(subject);
            target.call(dragBehavior);
        }
        this.renderNumbers();
    }

    calculateMovablePoints() {
        let { movableX: xValue } = this.state;

        // I could just find corresponding x in resolution (usedXs) but why doing it again
        // when you can create a mess like this
        //
        type Point = { x: number; y: number };
        let points: Point[] = [];
        let point;

        point = findClosePoint(this.state.derivedLines.success, xValue);
        let success = point.y;
        points.push(point);

        point = findClosePoint(this.state.derivedLines.error, xValue);
        let error = -point.y;
        points.push(point);

        point = findClosePoint(this.state.derivedLines.noResponse, xValue);
        let noResponse = point.y;
        points.push(point);

        point = findClosePoint(this.state.derivedLines.corrected, xValue);
        let corrected = point.y;
        points.push(point);

        let movableUsedX = (points.find((p) => p != null && p.x != null) || { x: xValue }).x;

        this.state.movableUsedX = movableUsedX;
        this.state.successMovableY = success || 0;
        this.state.errorMovableY = error || 0;
        this.state.noResponseMovableY = noResponse || 0;
        this.state.correctedMovableY = corrected || 0;
    }

    renderNumbers() {
        this.calculateMovablePoints();

        let target = this.g.fixedNumbersTarget;
        let { movableX } = this.state;

        // NOTE: positioning (not in the layout block)
        let calcX = (d: NumberData): number => this.scale.x(d.x) + 10;
        let calcY = (d: NumberData): number => {
            return this.scale.yPercent(d.percent) - 10;
        };

        interface NumberData {
            color: string;
            x: number;
            percent: number;
            fixedX?: number;
            y?: number;
        }
        interface FullNumberData {
            color: string;
            x: number;
            percent: number;
            fixedX: number;
            y: number;
        }
        let makingNumbers: NumberData[] = [];

        if (this.state.showSuccess) {
            makingNumbers.push({
                x: movableX,
                percent: this.state.successMovableY,
                color: this.style.successLine.color,
            });
        }
        if (this.state.showCorrected) {
            makingNumbers.push({
                x: movableX,
                percent: this.state.correctedMovableY,
                color: 'purple',
            });
        }
        if (this.state.showNoResponse) {
            makingNumbers.push({
                x: movableX,
                percent: this.state.noResponseMovableY,
                color: '#333',
            });
        }
        if (this.state.showError) {
            makingNumbers.push({
                x: movableX,
                percent: -this.state.errorMovableY,
                color: this.style.incorrectLine.color,
            });
        }

        makingNumbers.forEach((one, i) => {
            one.x = calcX(one);
            one.fixedX = one.x;

            one.y = calcY(one);
            // simulation.force(i+'-y', d3.forceY(one.y))
        });
        let numbers: FullNumberData[] = makingNumbers.filter((one) => typeof one.y === 'number' && typeof one.fixedX === 'number') as FullNumberData[];

        // NOTE: can be done async?
        // NOTE: modifies the first argument
        dispersePoints(numbers, -10, 30, 10);

        let gs = target
            .selectAll('g')
            .data(numbers)
            .join('g')
            .attr('transform', (d) => translate(d));

        let configureRect = (x: Target) =>
            x
                .attr('x', -10)
                .attr('y', -30)
                .attr('width', this.style.percentBubble.width)
                .attr('height', 37)
                .attr('rx', 10)
                .attr('ry', 10)
                .attr('fill', 'rgba(255,255,255,0.8)');

        gs.selectAll('rect')
            .data([null])
            .join('rect')
            .call(configureRect);

        gs.selectAll('text')
            .data((d) => [d])
            .join('text')
            .attr('class', 'onLightText')

            // .selectAll('text')
            // .data(numbers)
            // .join('text')
            //   .attr('x', (d) => d.x)
            //   .attr('y', (d) => d.y)
            .attr('fill', (d) => d.color)
            .text((d) => `${Math.abs(d.percent * 100).toFixed(0)}${this.style.percentBubbleText.percentSign}`)
            .attr('font-size', this.style.percentBubbleText.size)
            .attr('font-weight', this.style.percentBubbleText.weight);
    }

    renderTrialsText() {
        let target = this.g.trialsTextTarget;
        let { trialsCount: number, countDismissed } = this.state;

        let percentDismissed = 0;
        if (number + countDismissed !== 0) percentDismissed = countDismissed / (number + countDismissed);
        let percentDismissedStr = `${(percentDismissed * 100).toFixed(0)}%`;

        let text1 = `Trials used: ${number}`;

        let percentText = countDismissed > 0 ? ` (${percentDismissedStr})` : '';
        let text2 = `Dismissed: ${countDismissed}${percentText}`;

        let text = target
            .selectAll('text')
            .data([null])
            // .data([{ number, percentDismissed }])
            .join('text')
            .attr('alignment-baseline', 'hanging')
            .attr('x', lo.trialsCount.point.x)
            .attr('y', lo.trialsCount.point.y)
            // .text((d) => `Trials used: ${d.number}, dismissed: ${d.percentDismissed}`)
            .attr('font-size', this.style.topCornerText.size)
            .attr('fill', this.style.topCornerText.color);
        text.selectAll('tspan')
            .data([text1, text2])
            .join('tspan')
            .text((d) => d)
            .attr('x', lo.trialsCount.point.x)
            .attr('dy', 30);
    }

    renderBottomSubjectPoints() {
        let target = this.g.bottomSubjectPointsTarget;
        let { intersectionsStats } = this.state;
        let yValue = this.state.subjectAge;
        if (yValue === 0) yValue = -5;

        interface InputPoint {
            mean: number;
            sd: number;
        }
        interface SDPoint {
            x: number;
            y: number;
            fixedX: number;
            sd: number;
        }
        let points: SDPoint[] = [];
        let addPoint = (d: InputPoint) => {
            let x = this.scale.bottomX(d.mean || 0);
            let y = this.scale.bottomY(yValue);
            let sd = d.sd || 0;
            points.push({ x: x, fixedX: x, y, sd });
        };
        addPoint(intersectionsStats.success);
        addPoint(intersectionsStats.error);
        addPoint(intersectionsStats.corrected);
        // dispersePoints(points, -10, 30, 10)
        let successPoint = points[0];
        let errorPoint = points[1];
        let correctedPoint = points[2];

        let disperseManually = 10;
        successPoint.y -= disperseManually;
        errorPoint.y += disperseManually;

        let correctTitle = 'Mean time before correct anti-saccade movement';
        let errorTitle = 'Mean time before incorrect saccade movement';
        let correctedTitle = 'Mean duration of correcting movement';

        target
            .selectAll('.ageLine')
            .data([{ fromX: this.scale.bottomX(0), toX: this.scale.bottomX(1000), y: this.scale.bottomY(yValue) }])
            .join('line')
            .attr('class', 'ageLine')
            .attr('x1', (d) => d.fromX)
            .attr('y1', (d) => d.y)
            .attr('x2', (d) => d.toX)
            .attr('y2', (d) => d.y)
            .attr('stroke', this.style.bottomLine.color);
        // .attr('stroke-width', 5)

        let paths1 = target
            .selectAll('.success')
            .data([successPoint])
            .join('g')
            .attr('class', 'success')
            .attr('transform', (d) => translate({ x: d.x, y: d.y }));

        paths1
            .selectAll('path')
            .data([null])
            .join('path')
            .attr('d', drawTriangle)
            .attr('fill', this.style.successLine.color)
            .attr('stroke-opacity', 0)
            .attr('transform', 'scale(15)')
            .call(useTitle(correctTitle));

        paths1
            .selectAll('.sd')
            .data((d) => {
                let sd = d.sd == null ? 0 : d.sd;
                return [{ sd }];
            })
            .join('path')
            .attr('class', 'sd')
            .attr('d', sdLine(this.scale.bottomX))
            .attr('fill', 'transparent')
            .attr('stroke-width', this.style.sdLine.size)
            .attr('stroke', this.style.bottomLine.color)
            .call(useTitle(correctTitle));

        let paths2 = target
            .selectAll('.error')
            .data([errorPoint])
            .join('g')
            .attr('class', 'error')
            .attr('transform', (d) => translate({ x: d.x, y: d.y }));

        paths2
            .selectAll('circle')
            .data([null])
            .join('circle')
            .attr('fill', this.style.incorrectLine.color)
            .attr('r', 10)
            .attr('cx', 0)
            .attr('cy', 0)
            .call(useTitle(errorTitle));

        paths2
            .selectAll('.sd')
            .data((d) => {
                let sd = d.sd == null ? 0 : d.sd;
                return [{ sd }];
            })
            .join('path')
            .attr('class', 'sd')
            .attr('d', sdLine(this.scale.bottomX))
            .attr('fill', 'transparent')
            .attr('stroke-width', this.style.sdLine.size)
            .attr('stroke', this.style.bottomLine.color)
            .call(useTitle(errorTitle));

        let paths3 = target
            .selectAll('.corrected')
            .data([correctedPoint])
            .join('g')
            .attr('class', 'corrected')
            .attr('transform', (d) => translate({ x: d.x, y: d.y }));

        paths3
            .selectAll('rect')
            .data([null])
            .join('rect')
            .attr('fill', this.style.correctedLine.color)
            .attr('width', 20)
            .attr('height', 20)
            .attr('transform', translate({ x: -10, y: -10 }))
            .call(useTitle(correctedTitle));
        // .attr('r', 10)
        // .attr('cx', 0)
        // .attr('cy', 0);

        paths3
            .selectAll('.sd')
            .data((d) => {
                let sd = d.sd == null ? 0 : d.sd;
                return [{ sd }];
            })
            .join('path')
            .attr('class', 'sd')
            .attr('d', sdLine(this.scale.bottomX))
            .attr('fill', 'transparent')
            .attr('stroke', this.style.bottomLine.color)
            .attr('stroke-width', this.style.sdLine.size)
            .call(useTitle(correctedTitle));
    }

    renderTopAgeGroups() {
        let target = this.g.topAgeGroupsTarget;
        let { linePercent } = this.line;

        let successData = Object.entries(successPercentLinesByAge).map(([k, v]) => ({ age: k, points: v }));
        let invertY = (p: Point) => ({ ...p, y: -p.y });
        let errorData = Object.entries(errorPercentLinesByAge).map(([k, v]) => ({ age: k, points: v.map(invertY) }));
        if (this.state.hideAgeGroups) {
            successData = [];
            errorData = [];
        }

        let useColor = (d: { age: number | string }) => d3.interpolateBlues(+d.age / 100);

        target
            .selectAll('.success')
            .data(successData)
            .join('path')
            .attr('class', 'success')
            .attr('d', (d) => linePercent(d.points))
            .attr('fill', 'transparent')
            .attr('stroke-width', 5)
            .attr('stroke', useColor);

        target
            .selectAll('.error')
            .data(errorData)
            .join('path')
            .attr('class', 'error')
            .attr('d', (d) => linePercent(d.points))
            .attr('fill', 'transparent')
            .attr('stroke-width', 5)
            .attr('stroke', 'purple')
            .attr('stroke', useColor);
    }

    scheduleRendering() {
        window.requestAnimationFrame(() => {
            this.render();
        });
    }

    toggleHideRawData() {
        this.state.hideRawData = !this.state.hideRawData;
        this.scheduleRendering();
    }

    toggleHideAgeGroups() {
        this.state.hideAgeGroups = !this.state.hideAgeGroups;
        this.scheduleRendering();
    }

    toggleShowNoResponse() {
        this.state.showNoResponse = !this.state.showNoResponse;
        this.scheduleRendering();
    }
    toggleShowSuccess() {
        this.state.showSuccess = !this.state.showSuccess;
        this.scheduleRendering();
    }
    toggleShowCorrected() {
        this.state.showCorrected = !this.state.showCorrected;
        this.scheduleRendering();
    }
    toggleShowError() {
        this.state.showError = !this.state.showError;
        this.scheduleRendering();
    }

    doShowAllTrials() {
        // console.log(+new Date())
        this.state.showRange = 'all';
        this.updateSourceTitle();
        this.recompute();
        this.scheduleRendering();
    }
    doShowPracticeTrials() {
        // console.log(+new Date())
        this.state.showRange = 'practice';
        this.updateSourceTitle();
        this.recompute();
        this.scheduleRendering();
    }
    doShowCoreTrials() {
        // console.log(+new Date())
        this.state.showRange = 'afterPractice';
        this.updateSourceTitle();
        this.recompute();
        this.scheduleRendering();
    }
    getSourceTitle() {
        let { showRange } = this.state;
        let sourceTitle = '';
        if (showRange === 'all') {
            sourceTitle = 'all trials';
        } else if (showRange === 'practice') {
            sourceTitle = 'practice trials';
        } else if (showRange === 'afterPractice') {
            sourceTitle = 'after practice';
        } else {
        }
        return sourceTitle;
    }
    updateSourceTitle() {
        if (!this.sourceChooser) return;
        this.sourceChooser.title = this.getSourceTitle();
    }
    renderButtons() {
        let target = this.g.buttonsTarget;
        target.attr('transform', translate(lo.buttonsPlace.point)); // TODO: move?
        this.g.buttonsInfoTarget.attr('transform', translate(lo.buttonsPlace.point)); // same

        this.sourceChooser?.render(target);
        this.legendButton?.render(target);
        this.infoButton?.render();
    }

    renderUpdates() {
        this.renderTrialLines();
        this.renderDerivedLines();
        this.renderMovableLine();
        this.renderTrialsText();
        this.renderBottomSubjectPoints();
        this.renderTopAgeGroups();
        this.renderButtons();
    }

    render() {
        this.renderUpdates();
    }

    clearState() {
        this.state = new State();
    }
}
