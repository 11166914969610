// @ts-nocheck
// constants should be fixed if exports to be compatible with the previous ones by the order of headers/columns

export const GROUPS_ORDER = [
  { match: { n: 1 }, name: '40cm' }, // 40cm/near (top squares)
  { match: { n: 0 }, name: '6m' },   // 6m/far    (bottom squares)
]

export const DOTS_ORDER = [
  { match: { x: 1, y: 1 }, name: 'Centre' }, // or manual dot...
  { match: { x: 0, y: 1 }, name: 'Left' },
  { match: { x: 2, y: 1 }, name: 'Right' },
  { match: { x: 1, y: 0 }, name: 'Up' },
  { match: { x: 1, y: 2 }, name: 'Down' },
]

export const VALUES_ORDER = [
  { key: 'frequency', title: 'FREQ' },
  { key: 'amplitude', title: 'AMP' },
  { key: 'axis', title: 'AXIS' },
  { key: 'type', title: 'TYPE' },
]

export const EYES_ORDER = [
  'OD',
  'OS'
]

export function processExportValue(key: string, value: any, empty: boolean) {
  if (empty) return 'EMPTY'

  if (key === 'type') {
    value = convertNyType(value)
  }
  return formatValue(value)
}

function formatValue(x: number) {
  if (x % 1 > 0) {
    return x.toFixed(2)
  } else {
    return x
  }
}

const CONVERT_NY_TYPE = [
  { from: '', to: 0 }, // unless .empty
  { from: 'jerk', to: 1 },
  { from: 'pendular', to: 2 },
]
const CONVERT_NY_TYPE_ELSE = 3

function convertNyType(type: any) {
  let found = CONVERT_NY_TYPE.find(x => x.from === type)
  if (found) {
    return found.to
  } else {
    return CONVERT_NY_TYPE_ELSE
  }
}
