import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as d3 from 'd3';
import { combineLatest, Subscription } from 'rxjs';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../../../commonout/enum/calibration.command.enum';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CalibrationChartService } from '../calibrationService';
import { mockDataTest4 } from '../mockData';
import { ITimingCalibration } from '../../../../../../../../commonout/interfaces/calibration/timingCalibration.interface';
import { concatMap } from 'rxjs/operators';
import { ICalibrationResult } from '../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { ConfigService } from '../../../../_services/general/config.service';

@Component({
    selector: 'timing-test',
    template: require('./timing-test.component.html'),
    styles: [require('./timing-test.component.scss')],
})
export class TimingTestComponent extends CalibrationTestComponent implements AfterViewInit {
    customOnDestroy(): void {}

    redColor = '#EB5757';
    yellowColor = '#F2C94C';
    blueColor = '#2D9CDB';
    calibrationType: CALIBRATION_TEST.SHORT_TIMING_TEST | CALIBRATION_TEST.LONG_TIMING_TEST;
    selectedIndex = -1;
    width: number;
    height: number;
    form = this.fb.group({
        testType: [{value: '', disabled: false}, Validators.required],
    });
    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.START) {
            this.unSavedResult = {
                createdAt: +new Date,
                result: {
                    calibrations: []
                }
            }
        }
        
        if (data.message_type === CALIBRATION_COMMAND.DATA_PACKAGE) {
            this.unSavedResult.result.calibrations.push(
                {
                    xPoint: data?.x,
                    yPoint: data?.y,
                    eye: data?.eye
                }
            );
        }

        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler)
            const [odSubArray, osSubArray] = [
                this.unSavedResult.result.calibrations.filter(d => d.eye === 0).map(d => d.xPoint),
                this.unSavedResult.result.calibrations.filter(d => d.eye === 1).map(d => d.xPoint),
            ];
            
            this.unSavedResult = {
                ...this.unSavedResult,
                result: {
                    calibrations: this.unSavedResult.result.calibrations,
                    osAverageValue: d3.mean(osSubArray),
                    osMinValue: Math.min(...osSubArray),
                    osMaxValue: Math.max(...osSubArray),
                    osSampleSize: osSubArray.length,
                    odAverageValue: d3.mean(odSubArray),
                    odMinValue: Math.min(...odSubArray),
                    odMaxValue: Math.max(...odSubArray),
                    odSampleSize: odSubArray.length,
                },
                isTemp: true
            }

            this.results.unshift(this.unSavedResult);
    
            this.drawChart(0);

            this.isTestRunning = false;
            this.isTestDone = true;
        }
    }
    public image: FormControl;
    
    results: {
        createdAt: number,
        result: ITimingCalibration,
        isTemp?: boolean;
        type?: CALIBRATION_TEST
    }[] = [];

    unSavedResult: {
        createdAt: number,
        result: ITimingCalibration,
        isTemp?: boolean,
        type?: CALIBRATION_TEST
    } | null = null;

    isTestRunning: boolean = false;
    isLongTestRunning: boolean = false;
    isTestDone = false;

    subscriptions: Subscription[] = [];
    mockData: {
        date: Date;
        os: { average: number; min: number; max: number; ss: number };
        od: { average: number; min: number; max: number; ss: number };
    }[] = mockDataTest4;
    mockChartData: { timestamp: number; rawValue: number }[];

    @ViewChild('svgChart') svgChart: ElementRef;

    margin = 20;

    svg: any;
    svgInner: any;

    yScale: any;
    xScale: any;
    yAxis: any;
    xAxis: any;

    constructor(bulbicamService: BulbicamService, socketService: SocketService, private service: CalibrationChartService, private fb: FormBuilder, configService: ConfigService) {
        super(bulbicamService, socketService, configService);
        this.mockChartData = this.service.generateChartData(0.1);
        this.mockChartData = this.service.calibrateData(this.mockChartData);

        const sub = combineLatest(
            this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.SHORT_TIMING_TEST),
            this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.LONG_TIMING_TEST)
        ).subscribe(res => {
            this.results = [...res[0], ...res[1]].map((el: ICalibrationResult<ITimingCalibration>) => {
                return {
                    createdAt: el.createdAt,
                    result: {
                        ...el.results
                    },
                    type: el.test
                }
            }).sort((a, b) => b.createdAt - a.createdAt);
        });
        this.subscriptions.push(sub);
    }

    ngAfterViewInit(): void {
        this.width = this.svgChart.nativeElement.getBoundingClientRect().width;
        this.height = this.svgChart.nativeElement.getBoundingClientRect().height;
        this.initChart(this.width, this.height);
    }

    initChart(width: number = 300, height: number): void {
        this.svg = d3
            .select(this.svgChart?.nativeElement)
            .attr('id', 'svgtest')
            .attr('width', width)
            .attr('height', height);

        this.svgInner = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yScale = d3
            .scaleLinear()
            .domain([1, 0])
            .range([2 * this.margin, height - this.margin]);

        this.xScale = d3
            .scaleLinear()
            .domain([0, 1])
            .range([2 * this.margin, width - 2 * this.margin]);

        this.yAxis = this.svgInner
            .append('g')
            .attr('id', 'y-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${2 * this.margin}px, -20px)`);

        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(0px, ${height - 2 * this.margin}px)`);

        const yAxis = d3.axisLeft(this.yScale);
        const xAxis = d3.axisBottom(this.xScale);

        this.yAxis.call(yAxis);
        this.xAxis.call(xAxis);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`Time Delay /ms`)
            .attr('dx', this.xScale(0.9))
            .attr('dy', this.yScale(0))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(0px, 15px)`);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`Samples`)
            .attr('dx', this.xScale(0))
            .attr('dy', this.yScale(1))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(-25px, -30px)`);
    }

    drawChart(index: number): void {
        d3.selectAll('#line').remove();
        const lineDefined = d3.line().defined(function(d) {
            return d[1] !== null;
        });

        const result = this.results[index].result;

        this.yScale = d3
            .scaleLinear()
            .domain([Math.max(...result.calibrations.map(d => d.yPoint)), 0])
            .range([2 * this.margin, this.height - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxis.call(yAxis);

        this.xScale = d3
            .scaleLinear()
            .domain([0, Math.max(...result.calibrations.map(d => d.xPoint))])
            .range([2 * this.margin, this.width - 2 * this.margin]);

        const xAxis = d3.axisBottom(this.xScale);

        this.xAxis.call(xAxis);

        const odPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 0).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yPoint)];
        })

        const osPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 1).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yPoint)];
        })

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.blueColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.yellowColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osPoints));
    }

    toggleTest() {
        if (this.form.get('testType').value === 'short') {
            this.calibrationType = CALIBRATION_TEST.SHORT_TIMING_TEST;
        } else {
            this.calibrationType = CALIBRATION_TEST.LONG_TIMING_TEST;
        }

        if (this.isTestRunning) {
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.STOP,
                })
                .subscribe();
            this.subscriptions.push(sub);
            this.form.enable();
        } else {
            this.socketService.socket.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: this.calibrationType,
                    command: CALIBRATION_COMMAND.START,
                })
                .subscribe();
            this.subscriptions.push(sub);
            this.form.disable();
        }

        this.isTestRunning = !this.isTestRunning;

        this.clearTempResult()
        
    }

    saveResults(): void {
        const sub = this.bulbicamService.saveCalibrationSetting({
            test: this.calibrationType,
            createdAt: +new Date(),
            results: {
                ...this.unSavedResult.result
            }
        }).pipe(
            concatMap(_ => {
                return combineLatest(
                    this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.SHORT_TIMING_TEST),
                    this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.LONG_TIMING_TEST)
                );
            })
        ).subscribe(res => {
            this.results = [...res[0], ...res[1]].map((el: ICalibrationResult<ITimingCalibration>) => {
                
                return {
                    createdAt: el.createdAt,
                    result: {
                        ...el.results
                    }
                }
                
            }).sort((a, b) => b.createdAt - a.createdAt);
        });
        this.subscriptions.push(sub);

        this.clearTempResult();
    }

    selectActive(index: number): void {
        if (this.results[index]?.isTemp) return;
        this.selectedIndex = index;
        this.drawChart(index);
    }

    clearTempResult() {
        if (this.unSavedResult) {
            this.unSavedResult = null;
            this.results.shift();
            this.selectedIndex--;
        }
    }
}
