import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { BehaviorSubject } from 'rxjs';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import {
    CHART_SUBTYPE,
    IChartData,
    IPursuitSaccadesCamMessage,
    IPursuitSaccadeTestResults,
    IRangeTestResult,
    ISaccadeResult,
} from '../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { IRawExportData } from '../../../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from '../../chartService';
import { SaccadeParsingService } from './saccadesParsingService';
import { VelocityVerticalComputingService } from './velocityComputingService/velocityComputingService';
import { VelocitySaccadeAnalyticsService } from './velocityComputingService/velocitySaccadeAnalyticsService';

@Injectable()
export class SaccadeChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}

    charData$: BehaviorSubject<IChartData> = new BehaviorSubject({
        chartType: 'no-content',
    });
    private rawFrames: IPursuitSaccadesCamMessage[] = [];
    private verticalSaccadesResults: ISaccadeResult[] = [];
    private horizontalSaccadesResults: ISaccadeResult[] = [];
    private testResults: IPursuitSaccadeTestResults;

    constructor(
        private smoothParsingService: SaccadeParsingService,
        private velocityService: VelocityVerticalComputingService,
        private saccadeAnalyticsService: VelocitySaccadeAnalyticsService
    ) {
        super();
    }

    public addData(frames: IPursuitSaccadesCamMessage[]): Promise<void> {
        return new Promise(resolve => {
            const parsedFrames = this.smoothParsingService.parseRealTimeFrames(frames);
            const tunedFrames = this.smoothParsingService.removeZeroElements(parsedFrames);
            const chartData: IChartData = {
                framesData: tunedFrames,
                chartType: 'real-time',
            };

            this.charData$.next(chartData);
            resolve();
        });
    }

    public setCamData(frames: IPursuitSaccadesCamMessage[]): void {
        const parsedFrames = this.smoothParsingService.parseFrames(frames) as IPursuitSaccadesCamMessage[];
        const tunedFrames = this.smoothParsingService.removeZeroElements(parsedFrames);

        const startTimestamp = parsedFrames[0].timestamp / 10000;

        this.rawFrames = parsedFrames;

        const { verticalFrames, horizontalFrames, velocityFrames } = this.computeVelocityFrames(parsedFrames);

        const calibrationVerticalFrames = this.smoothParsingService.removeHorizontalFrames([...velocityFrames]);
        const calibrationHorizontalFrames = this.smoothParsingService.removeVerticalFrames([...velocityFrames]);

        const calibratedVerticalFrames = this.saccadeAnalyticsService.computeFramesByCalibrationData([...calibrationVerticalFrames]);
        const calibratedHorizontalFrames = this.saccadeAnalyticsService.computeFramesByCalibrationData([...calibrationHorizontalFrames]);

        const parsedVerticalFrames = calibratedVerticalFrames.map(frame => ({
            calibratedCoor: frame.calibrationAngleOS,
            time: this.getXvalue(startTimestamp, frame.timestamp),
        }));
        const parsedHorizontalFrames = calibratedHorizontalFrames.map(frame => ({
            calibratedCoor: frame.calibrationAngleOS,
            time: this.getXvalue(startTimestamp, frame.timestamp),
        }));

        const frameWithTestResults = frames.find(f => f.testResults !== undefined);
        this.testResults = frameWithTestResults !== undefined ? frameWithTestResults.testResults : this.calculateTestResults(tunedFrames);

        this.testResults.rangeTestResults.forEach(rangeResult => {
            rangeResult.saccadesTestResults.forEach(saccadesResult => {
                if (saccadesResult.result === 'error patient' || saccadesResult.result === 'error system') {
                    saccadesResult.amplitude = null;
                    saccadesResult.latency = null;
                    saccadesResult.peakVelocity = null;
                    saccadesResult.type = null;
                }
            });
        });

        this.testResults.horizontalCalibratedFrames = parsedHorizontalFrames;
        this.testResults.verticalCalibratedFrames = parsedVerticalFrames;
    }

    public export(): IPursuitSaccadeTestResults {
        return this.testResults ? this.testResults : null;
    }

    public getRawExport(): IRawExportData {
        const rawModel: IRawExportData = {
            rows: [],
        };

        if (this.rawFrames.length === 0) return rawModel;

        const startTimestamp = this.rawFrames[0].timestamp / 10000;

        this.rawFrames.forEach(frame => {
            rawModel.rows.push({
                time: this.getXvalue(startTimestamp, frame.timestamp),
                pupil: {
                    OD: {
                        x: frame.eyeODx === 0 || frame.eyeODx === 1000 ? null : frame.eyeODx,
                        y: frame.eyeODy === 0 || frame.eyeODy === 1000 ? null : frame.eyeODy,
                        diameter: null,
                        dpg: {
                            x: null,
                            y: null,
                        },
                        bpg: {
                            x: null,
                            y: null,
                        },
                    },
                    OS: {
                        x: frame.eyeOSx === 0 || frame.eyeOSx === 1000 ? null : frame.eyeOSx,
                        y: frame.eyeOSy === 0 || frame.eyeOSy === 1000 ? null : frame.eyeOSy,
                        diameter: null,
                        dpg: {
                            x: null,
                            y: null,
                        },
                        bpg: {
                            x: null,
                            y: null,
                        },
                    },
                },
                stimulus: {
                    OD: {
                        x: frame.stimuliODx === 0 ? null : frame.stimuliODx,
                        y: frame.stimuliODy === 0 ? null : frame.stimuliODx,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                    OS: {
                        x: frame.stimuliOSx === 0 ? null : frame.stimuliOSx,
                        y: frame.stimuliOSy === 0 ? null : frame.stimuliOSx,
                        diameter: null,
                        r: null,
                        g: null,
                        b: null,
                    },
                },
                background: {
                    OD: {
                        r: null,
                        g: null,
                        b: null,
                    },
                    OS: {
                        r: null,
                        g: null,
                        b: null,
                    },
                },
            });
        });

        return rawModel.rows.length !== 0 ? rawModel : null;
    }

    public getChartData(frames: IPursuitSaccadesCamMessage[]): void {
        const parsedFrames = this.smoothParsingService.parseFrames(frames) as IPursuitSaccadesCamMessage[];
        const tunedFrames = this.smoothParsingService.removeZeroElements(parsedFrames);

        const { verticalFrames, horizontalFrames, velocityFrames } = this.computeVelocityFrames(parsedFrames);

        const calibrationVerticalFrames = this.smoothParsingService.removeHorizontalFrames([...velocityFrames]);
        const calibrationHorizontalFrames = this.smoothParsingService.removeVerticalFrames([...velocityFrames]);

        const calibratedVerticalFrames = this.saccadeAnalyticsService.computeFramesByCalibrationData([...calibrationVerticalFrames]);
        const calibratedHorizontalFrames = this.saccadeAnalyticsService.computeFramesByCalibrationData([...calibrationHorizontalFrames]);
        const calibratedFrames = this.saccadeAnalyticsService.computeFramesByCalibrationData([...velocityFrames]);

        const frameWithTestResults = frames.find(f => f.testResults !== undefined);
        this.testResults = frameWithTestResults !== undefined ? frameWithTestResults.testResults : this.calculateTestResults(tunedFrames);

        const movementFrames = [...calibratedFrames] as IPursuitSaccadesCamMessage[];
        movementFrames[0].testResults = this.testResults;

        this.testResults.rangeTestResults.forEach(f => {
            if (f.type === CHART_SUBTYPE.VERTICAL) {
                this.verticalSaccadesResults = this.verticalSaccadesResults.concat(f.saccadesTestResults);
            } else {
                this.horizontalSaccadesResults = this.horizontalSaccadesResults.concat(f.saccadesTestResults);
            }
        });

        const chartData: IChartData = {
            chartType: 'recorded',
            framesData: calibratedFrames,
            movementFrames: movementFrames,
            verticalVelocityFrames: verticalFrames,
            horizontalVelocityFrames: horizontalFrames,
            pursuitSaccadesTestResults: this.testResults,
            horizontalFrames: calibratedHorizontalFrames,
            horizontalResults: this.horizontalSaccadesResults,
            verticalFrames: calibratedVerticalFrames,
            verticalResults: this.verticalSaccadesResults,
        };

        this.charData$.next(chartData);
    }

    public clearData(): void {
        this.rawFrames = [];
        if (!d3.select('#velocityChartContent').empty()) {
            d3.selectAll('#velocityChartContent').remove();
            d3.selectAll('#velocityChart').attr('height', 0);

            d3.select('#verticalMovementChartContent').remove();
            d3.select('#verticalMovementTitle').style('display', 'none');
            d3.select('#verticalMovementChart').attr('height', 0);

            d3.select('#horizontalMovementChartContent').remove();
            d3.select('#horizontalMovementTitle').style('display', 'none');
            d3.select('#horizontalMovementChart').attr('height', 0);

            d3.select('#realTimeChartContent').remove();
            d3.select('#realTimeTitle').remove();
            d3.select('#realTimeChart').attr('height', 0);

            d3.select('#movementChartContent').remove();
            d3.select('#movementChartTitle').style('display', 'none');
            d3.select('#movementChart').attr('height', 0);

            d3.select('#saccadeTestResultsTable').style('display', 'none');

            this.verticalSaccadesResults = [];
            this.horizontalSaccadesResults = [];
        }
    }

    private computeVelocityFrames(
        frames: IPursuitSaccadesCamMessage[]
    ): { verticalFrames: IPursuitSaccadesCamMessage[]; horizontalFrames: IPursuitSaccadesCamMessage[]; velocityFrames: IPursuitSaccadesCamMessage[] } {
        const outputFrames = this.smoothParsingService.removeZeroElements([...frames]);

        const verticalFrames = this.smoothParsingService.removeHorizontalFrames([...outputFrames]);
        const horizontalFrames = this.smoothParsingService.removeVerticalFrames([...outputFrames]);

        this.smoothParsingService.tuneVerticalFrames(verticalFrames);
        this.smoothParsingService.tuneHorizontalFrames(horizontalFrames);

        const verticalArray = this.velocityService.computeVelocityData([...verticalFrames]);
        const horizontalArray = this.velocityService.computeVelocityData([...horizontalFrames]);
        const calibratedFrames = this.velocityService.computeVelocityData(outputFrames);

        const tunedVerticalFrames = this.smoothParsingService.tuneFrames(verticalArray);
        const tunedHorizontalFrames = this.smoothParsingService.tuneFrames(horizontalArray);

        const resultVerticalFrames = this.smoothParsingService.removeZeroElements(tunedVerticalFrames);
        const resultHorizontalFrames = this.smoothParsingService.removeZeroElements(tunedHorizontalFrames);

        return {
            verticalFrames: resultVerticalFrames,
            horizontalFrames: resultHorizontalFrames,
            velocityFrames: calibratedFrames,
        };
    }

    private calculateTestResults(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadeTestResults {
        const separatedTestResultsFrames = this.smoothParsingService.separateFrames([...frames]);

        this.smoothParsingService.tuneVerticalFrames(separatedTestResultsFrames.verticalFrames);
        this.smoothParsingService.tuneHorizontalFrames(separatedTestResultsFrames.horizontalFrames);

        const verticalTestFrames = this.velocityService.computeVelocityData([...separatedTestResultsFrames.verticalFrames]);
        const horizontalTestFrames = this.velocityService.computeVelocityData([...separatedTestResultsFrames.horizontalFrames]);

        const verticalTestResults = this.saccadeAnalyticsService.computeTestResults([...verticalTestFrames], CHART_SUBTYPE.VERTICAL);
        const horizontalTestResults = this.saccadeAnalyticsService.computeTestResults([...horizontalTestFrames], CHART_SUBTYPE.HORIZONTAL);

        const { accept, errorPatient, errorSystem } = this.calculateResults(horizontalTestResults, verticalTestResults);

        const rangeTestResults = verticalTestResults.concat(horizontalTestResults);

        const startTimestamp = frames[0].timestamp / 10000;
        const horizontalVelocityFrames = horizontalTestFrames.map(frame => ({ time: this.getXvalue(startTimestamp, frame.timestamp), pupilVelocity: frame.pupilvelocity }));
        const verticalVelocityFrames = verticalTestFrames.map(frame => ({ time: this.getXvalue(startTimestamp, frame.timestamp), pupilVelocity: frame.pupilvelocity }));

        const testResults: IPursuitSaccadeTestResults = {
            rangeTestResults: rangeTestResults,
            accept: accept,
            errorPatient: errorPatient,
            errorSystem: errorSystem,
            horizontalVelocityFrames: horizontalVelocityFrames,
            verticalVelocityFrames: verticalVelocityFrames,
        };

        return testResults;
    }

    private calculateResults(horizontalTestResults: IRangeTestResult[], verticalTestResults: IRangeTestResult[]): { accept: number; errorPatient: number; errorSystem: number } {
        let countAccept = 0;
        let countErrorPatient = 0;
        let countErrorSystem = 0;

        horizontalTestResults.forEach(interval => {
            countAccept += interval.accept;
            countErrorPatient += interval.errorPatient;
            countErrorSystem += interval.errorSystem;
        });

        verticalTestResults.forEach(interval => {
            countAccept += interval.accept;
            countErrorPatient += interval.errorPatient;
            countErrorSystem += interval.errorSystem;
        });

        return { accept: countAccept, errorPatient: countErrorPatient, errorSystem: countErrorSystem };
    }
}
