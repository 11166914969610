import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { axisBottom, scaleLinear, select, Selection } from 'd3';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';
import { IPupil20TestCamMessage } from '../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { BacklightChartService } from '../../../../../_services/chartServices/backLightChartService';

@Component({
    selector: 'backlight-chart',
    template: require('./backlight-chart.component.html'),
    styles: [require('./backlight-chart.component.scss')],
})
export class BacklightChartComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() public valuesLog: boolean = false;
    public framesSource: Subject<IPupil20TestCamMessage> = new Subject<IPupil20TestCamMessage>();
    public data: IPupil20TestCamMessage[][] = [];
    public round: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    @ViewChild('backlightChart') private svg: ElementRef;
    private readonly CHART_HEIGHT = 450;
    private readonly RESIZE_WIDTH_FACTOR = 0.8;
    private subscriptions: Subscription[] = [];
    private readonly padding: { left: number; top: number; right: number; bottom: number } = { left: 200, top: 40, right: 40, bottom: 40 };
    private readonly ROUND_TEST_LENGTH: number = 25;
    private startTimestamp: number = undefined;
    private backlightChart: Selection<SVGGElement, unknown, null, undefined>;
    private currentGroupOD: Selection<SVGGElement, unknown, null, undefined>;
    private currentGroupOS: Selection<SVGGElement, unknown, null, undefined>;
    private startRectPosition: number;
    private xAxisGenerator: any;
    constructor(private chartService: BacklightChartService) {}
    ngOnInit(): void {
        this.subscriptions.push(
            this.framesSource.subscribe(frame => {
                switch (frame.message_type) {
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        this.backlightChart.selectAll('.secondsBlockOD').remove();
                        this.backlightChart.selectAll('.secondsBlockOS').remove();
                        this.data.push([]);
                        this.startTimestamp = frame.timestamp / 10000;
                        this.data[this.data.length - 1].push(frame);
                        break;
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(frame);
                        this.round.next(this.data.length - 1);
                        this.startTimestamp = null;
                        break;
                    case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(frame);
                        this.startRectPosition = this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp));
                        this.currentGroupOD = this.backlightChart
                            .append('g')
                            .attr('class', `secondsBlockOD`)
                            .attr('transform', `translate(${this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp))},${0})`);
                        this.currentGroupOD
                            .append('rect')
                            .attr('class', `Round${this.data.length - 1} backlightRectOD`)
                            .attr('height', 25)
                            .attr('width', 0)
                            .attr('fill', this.getColor(frame.backgroundColorOD))
                            .attr('stroke', 'gray')
                            .attr('stroke-width', 1)
                            .attr('x', this.xAxisGenerator(0))
                            .attr('y', 0);
                        this.currentGroupOD
                            .append('text')
                            .attr('class', `Round${this.data.length - 1} backlightTextOD`)
                            .attr('font-size', 15)
                            .style('text-anchor', 'middle')
                            .style('fill', 'red')
                            .style('opacity', this.valuesLog ? 1 : 0)
                            .text(frame.backgroundColorOD)
                            .attr('x', this.xAxisGenerator(0.5))
                            .attr('y', 18);
                        this.currentGroupOS = this.backlightChart
                            .append('g')
                            .attr('class', `secondsBlockOS`)
                            .attr('transform', `translate(${this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp))},${25})`);
                        this.currentGroupOS
                            .append('rect')
                            .attr('class', `Round${this.data.length - 1} backlightRectOS`)
                            .attr('height', 25)
                            .attr('width', 0)
                            .attr('fill', this.getColor(frame.backgroundColorOS))
                            .attr('stroke', 'gray')
                            .attr('stroke-width', 1)
                            .attr('x', this.xAxisGenerator(0))
                            .attr('y', 0);
                        this.currentGroupOS
                            .append('text')
                            .attr('class', `Round${this.data.length - 1} backlightTextOS`)
                            .attr('font-size', 15)
                            .style('text-anchor', 'middle')
                            .style('fill', 'red')
                            .style('opacity', this.valuesLog ? 1 : 0)
                            .text(frame.backgroundColorOS)
                            .attr('x', this.xAxisGenerator(0.5))
                            .attr('y', 18);
                        break;
                    case MESSAGE_TYPE.STOP_BACKGROUND_DATA_TRANSMISSION:
                        this.data[this.data.length - 1].push(frame);
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        this.data[this.data.length - 1].push(frame);
                        this.currentGroupOD
                            .select('.backlightRectOD')
                            .attr('width', this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp)) - this.startRectPosition);
                        this.currentGroupOS
                            .select('.backlightRectOS')
                            .attr('width', this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp)) - this.startRectPosition);
                        break;
                    case MESSAGE_TYPE.STOP_TEST:
                        this.data[this.data.length - 1]?.push(frame);
                        break;
                    default:
                        break;
                }
            })
        );
        this.subscriptions.push(
            this.round.pipe(skip(1)).subscribe(r => {
                if (r === null) return;
                this.data[r].forEach(frame => {
                    switch (frame.message_type) {
                        case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                            this.backlightChart.selectAll('.secondsBlockOD').remove();
                            this.backlightChart.selectAll('.secondsBlockOS').remove();
                            this.startTimestamp = frame.timestamp / 10000;
                            break;
                        case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                            this.startTimestamp = null;
                            break;
                        case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                            this.startRectPosition = this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp));
                            this.currentGroupOD = this.backlightChart
                                .append('g')
                                .attr('class', `secondsBlockOD`)
                                .attr('transform', `translate(${this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp))},${0})`);
                            this.currentGroupOD
                                .append('rect')
                                .attr('class', `Round${r} backlightRectOD`)
                                .attr('height', 25)
                                .attr('width', 0)
                                .attr('fill', this.getColor(frame.backgroundColorOD))
                                .attr('stroke', 'gray')
                                .attr('stroke-width', 1)
                                .attr('x', this.xAxisGenerator(0))
                                .attr('y', 0);
                            this.currentGroupOD
                                .append('text')
                                .attr('class', `Round${r} backlightTextOD`)
                                .attr('font-size', 15)
                                .style('text-anchor', 'middle')
                                .style('fill', 'red')
                                .style('opacity', this.valuesLog ? 1 : 0)
                                .text(frame.backgroundColorOD)
                                .attr('x', this.xAxisGenerator(0.5))
                                .attr('y', 18);
                            this.currentGroupOS = this.backlightChart
                                .append('g')
                                .attr('class', `secondsBlockOS`)
                                .attr('transform', `translate(${this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp))},${25})`);
                            this.currentGroupOS
                                .append('rect')
                                .attr('class', `Round${r} backlightRectOS`)
                                .attr('height', 25)
                                .attr('width', 0)
                                .attr('fill', this.getColor(frame.backgroundColorOS))
                                .attr('stroke', 'gray')
                                .attr('stroke-width', 1)
                                .attr('x', this.xAxisGenerator(0))
                                .attr('y', 0);
                            this.currentGroupOS
                                .append('text')
                                .attr('class', `Round${r} backlightTextOS`)
                                .attr('font-size', 15)
                                .style('text-anchor', 'middle')
                                .style('fill', 'red')
                                .style('opacity', this.valuesLog ? 1 : 0)
                                .text(frame.backgroundColorOS)
                                .attr('x', this.xAxisGenerator(0.5))
                                .attr('y', 18);

                            break;
                        case MESSAGE_TYPE.DATA_PACKAGE:
                            this.currentGroupOD
                                .select('.backlightRectOD')
                                .attr('width', this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp)) - this.startRectPosition);
                            this.currentGroupOS
                                .select('.backlightRectOS')
                                .attr('width', this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp)) - this.startRectPosition);
                            break;
                        default:
                            break;
                    }
                });
            })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    @HostListener('window:resize', ['$event']) public onResize() {
        select(this.svg.nativeElement)
            .selectAll('*')
            .remove();
        this.ngAfterViewInit();
        if (this.data.length > 0) {
            this.data[this.round.value].forEach(frame => {
                switch (frame.message_type) {
                    case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                        this.backlightChart.selectAll('.secondsBlockOD').remove();
                        this.backlightChart.selectAll('.secondsBlockOS').remove();
                        this.startTimestamp = frame.timestamp / 10000;
                        break;
                    case MESSAGE_TYPE.STOP_PUPIL_DATA_TRANSMISSION:
                        this.startTimestamp = null;
                        break;
                    case MESSAGE_TYPE.START_BACKGROUND_DATA_TRANSMISSION:
                        this.startRectPosition = this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp));
                        this.currentGroupOD = this.backlightChart
                            .append('g')
                            .attr('class', `secondsBlockOD`)
                            .attr('transform', `translate(${this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp))},${0})`);
                        this.currentGroupOD
                            .append('rect')
                            .attr('class', `Round${this.data.length - 1} backlightRectOD`)
                            .attr('height', 25)
                            .attr('width', 0)
                            .attr('fill', this.getColor(frame.backgroundColorOD))
                            .attr('stroke', 'gray')
                            .attr('stroke-width', 1)
                            .attr('x', this.xAxisGenerator(0))
                            .attr('y', 0);
                        this.currentGroupOD
                            .append('text')
                            .attr('class', `Round${this.data.length - 1} backlightTextOD`)
                            .attr('font-size', 15)
                            .style('text-anchor', 'middle')
                            .style('fill', 'red')
                            .style('opacity', this.valuesLog ? 1 : 0)
                            .text(frame.backgroundColorOD)
                            .attr('x', this.xAxisGenerator(0.5))
                            .attr('y', 18);
                        this.currentGroupOS = this.backlightChart
                            .append('g')
                            .attr('class', `secondsBlockOS`)
                            .attr('transform', `translate(${this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp))},${25})`);
                        this.currentGroupOS
                            .append('rect')
                            .attr('class', `Round${this.data.length - 1} backlightRectOS`)
                            .attr('height', 25)
                            .attr('width', 0)
                            .attr('fill', this.getColor(frame.backgroundColorOS))
                            .attr('stroke', 'gray')
                            .attr('stroke-width', 1)
                            .attr('x', this.xAxisGenerator(0))
                            .attr('y', 0);
                        this.currentGroupOS
                            .append('text')
                            .attr('class', `Round${this.data.length - 1} backlightTextOS`)
                            .attr('font-size', 15)
                            .style('text-anchor', 'middle')
                            .style('fill', 'red')
                            .style('opacity', this.valuesLog ? 1 : 0)
                            .text(frame.backgroundColorOS)
                            .attr('x', this.xAxisGenerator(0.5))
                            .attr('y', 18);
                        break;
                    case MESSAGE_TYPE.DATA_PACKAGE:
                        this.currentGroupOD
                            .select('.backlightRectOD')
                            .attr('width', this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp)) - this.startRectPosition);
                        this.currentGroupOS
                            .select('.backlightRectOS')
                            .attr('width', this.xAxisGenerator(this.chartService.getXvalue(this.startTimestamp, frame.timestamp)) - this.startRectPosition);
                        break;
                    default:
                        break;
                }
            });
        }
    }
    ngAfterViewInit() {
        const SVG = select(this.svg.nativeElement);
        const SVGwidth = this.svg.nativeElement.getBoundingClientRect().width 
            ? this.svg.nativeElement.getBoundingClientRect().width 
            : window.innerWidth * this.RESIZE_WIDTH_FACTOR;

        this.xAxisGenerator = scaleLinear()
            .domain([0, this.ROUND_TEST_LENGTH])
            .range([0, SVGwidth - this.padding.left - this.padding.right]);

        SVG.append('g')
            .attr('class', 'xAxis')
            .attr('transform', `translate(${this.padding.left},0)`)
            .attr('color', 'transparent')
            .call(axisBottom(this.xAxisGenerator).ticks(0));
        SVG.append('text')
            .attr('x', 0)
            .attr('y', 21)
            .attr('transform', `translate(${this.padding.left - 40},0)`)
            .attr('font-size', 21)
            .style('text-anchor', 'start')
            .style('fill', 'white')
            .text('OD');
        SVG.append('text')
            .attr('x', 0)
            .attr('y', 46)
            .attr('transform', `translate(${this.padding.left - 40},0)`)
            .attr('font-size', 21)
            .style('text-anchor', 'start')
            .style('fill', 'white')
            .text('OS');
        this.backlightChart = SVG.append('g')
            .attr('class', 'backlightChart')
            .attr('transform', `translate(${this.padding.left},0)`);
    }
    public clear(): void {
        this.backlightChart.selectAll('.secondsBlockOD').remove();
        this.backlightChart.selectAll('.secondsBlockOS').remove();
        this.round.next(null);
        this.data = [];
        this.startTimestamp = null;
    }
    private getColor(color: number): string {
        if (color === 1) color = 255;
        return `rgb(${color},${color},${color})`;
    }
}
