// @ts-nocheck
// let _ = require('lodash')
// let DSP = require('dsp.js')
// let { FFT, Oscillator } = DSP
import * as _ from 'lodash'

// @ts-ignore
import * as DSP from 'dsp.js'

let { FFT, DFT, Oscillator } = DSP

const START_BUFFER_SIZE = 512

// let bufferSize = 2048
// let sampleRate = 300
// let frequency = 3

// let fft = new FFT(bufferSize, sampleRate);
// let osc = new Oscillator(DSP.SAW, frequency, 1.0, bufferSize, sampleRate);
// let signal = osc.generate();

// _.times(256, i => {
//   signal[i] = 0
// })

// fft.forward(signal)
// let peakFreq = fft.getBandFrequency(fft.peakBand);
// console.log("Detected peak: " + peakFreq + " Hz (error " + Math.abs(peakFreq - frequency) + " Hz)");

export function getFFT(values: number[], sampleRate: number) {
  let bufferSize = rightBufferSize(values.length)
  // let sampleRate = 300
  // let frequency = 3

  let fft = new FFT(bufferSize, sampleRate)
  // let osc = new Oscillator(DSP.SAW, frequency, 1.0, bufferSize, sampleRate);
  // let signal = osc.generate();

  let signal = new Float32Array(bufferSize)
  values.forEach((x,i) => signal[i] = x)

  fft.forward(signal)
  // let peakFreq = fft.getBandFrequency(fft.peakBand);
  // console.log("Detected peak: " + peakFreq + " Hz (error " + Math.abs(peakFreq - frequency) + " Hz)");
  // for (var i = 0; i < fft.spectrum.length; i++) {
  //   peakBand = (fft.spectrum[i] > fft.spectrum[peakBand]) ? i : peakBand;
  // }
  return fft
}

function rightBufferSize(n: number) {
  let result = START_BUFFER_SIZE
  while (result < n) {
    result *= 2
  }
  return result
}

// times - seconds
export function getSampleRate(times: number[]) {
  let gotTime = times[times.length - 1] - times[0]
  let gotCount = times.length
  // let gotCount = 0
  // let gotTime = 0
  // let index = 0
  // console.log(times.length)
  // times = _.drop(times, 5000)
  // while (gotTime < 1 && index < times.length - 1) {
  //   gotTime += times[index + 1] - times[index]
  //   gotCount += 1
  //   index += 1
  // }
  return Math.round(gotCount / gotTime)
}
