import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ConfigService } from '../../../_services/general/config.service';

@Component({
    selector: 'about',
    template: require('./about.component.html'),
    styles: [require('./about.component.scss')],
})
export class AboutComponent implements OnInit {
    version = '';

    constructor(private location: Location, private configService: ConfigService) {}

    ngOnInit() {
        this.version = this.configService.version;
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeHandler() {
        this.location.back();
    }
}
