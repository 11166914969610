import { ISmartImage } from '../interfaces/smart-image.interface';
import { RegisterEditField } from './registerEditField.class';
/**
 * An abstract class that describes the way HUB stores images.
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 * Because of mongo db has size limitation (not more than 16mb) for single object all data that has bigger size is stored in DB using gridfs approach.
 */
export abstract class SmartImage implements ISmartImage {
    imageID: string; // ID of file (GridFS)
    imageUrl: string | undefined; // raw image data restored from mongo gridfs
    remark: RegisterEditField<string>; // any metadata that corresponds to image
    constructor() {}
    public get model(): ISmartImage {
        let model: ISmartImage = {
            imageID: this.imageID,
            imageUrl: this.imageID ? undefined : this.imageUrl, // if ID exist then image is already in DB, else image is just uploaded to front and need to be saved to DB
            remark: this.remark.getModel(),
        };
        return model;
    }
    public set model(model: ISmartImage) {
        this.imageID = model.imageID;
        this.imageUrl = model.imageUrl;
        this.remark.setModel(model.remark);
    }
    // method that returns if there is an image already loaded in this inctance or not
    public isEmpty(): boolean {
        // if there is nor imageID (reference to ID in DB) neither imageURL (raw image data) then class is empty
        return !this.imageID && !this.imageUrl;
    }
    public abstract saveToDb(provider?: any): Promise<void>;
}
