import { Injectable } from '@angular/core';
import { DEVICE } from '../../../../../../../../commonout/enum/device';
import { TEST_STATUS } from '../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../commonout/enum/test.command.enum';
import { TestFrontend } from '../../../../_models/tests/testFrontend.class';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { SocketService } from '../../../../_services/general/socket.service';

@Injectable()
export abstract class BulbicamControlComponent {
    public test: TestFrontend;
    public DEVICE: typeof DEVICE = DEVICE;
    public TEST_STATUS: typeof TEST_STATUS = TEST_STATUS;
    public TEST_COMMAND: typeof TEST_COMMAND = TEST_COMMAND;
    public pendingResponse: boolean;

    constructor(
        public bulbicamService: BulbicamService,
        public configService: ConfigService,
        protected socketService: SocketService,
        protected authService: AuthenticationService
    ) {
        this.pendingResponse = false;
    }
    public abstract enable(): void;
    public abstract disable(): void;

    protected stop(): void {
        this.bulbicamService
            .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.STOP, this.socketService.id, {
                username: this.authService.currentUser.username,
            })
            .toPromise();
    }
}
