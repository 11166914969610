// @ts-nocheck
import * as _ from 'lodash';
import { Diagram } from '../Diagram';

interface Point {
    x: number;
    y: number;
}

export class Decorations {
    bgDots: number[][];
    hCentralLine: Point[];
    bgVLines: Point[][];
    bottomBgVLines: Point[][];
    correctnessLines: Point[][];

    constructor (diagram: Diagram) {
        // NOTE: maybe 1000, etc -> diagram.domainX[1] and so on
        let bgDots = _.flatten(_.range(-10, 10 + 1, 1).map((y) => _.range(0, 1000 + 20, 20).map((x) => [x, y])));
        bgDots = bgDots.filter((p) => p[1] !== 0);
        this.bgDots = bgDots;

        this.hCentralLine = [
            { x: 0, y: 0 },
            { x: 1000, y: 0 },
        ];

        this.bgVLines = _.range(0, 1000 + 100, 100).map((coord) => [
            { x: coord, y: -10 },
            { x: coord, y: 10 },
        ]);

        this.bottomBgVLines = _.range(0, 1000 + 100, 100).map((coord) => [
            { x: coord, y: 5 },
            { x: coord, y: 100 },
        ]);

        this.correctnessLines = [diagram.state.correctMargin, diagram.state.incorrectMargin].map((y) => [
            { x: 0, y },
            { x: 1000, y },
        ]);
    }
}
