import { SaccadeBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/saccadesBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { CommonStartStopControlComponent, SaccadeTestChartComponent, SaccadeTestRemarksComponent } from '../../../_components';
import { SaccadeBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/saccadeBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class SaccadeBulbicamTestFrontend extends SaccadeBulbicamTest {
    remarks: SaccadeBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof SaccadeTestRemarksComponent = SaccadeTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof SaccadeTestChartComponent = SaccadeTestChartComponent;
    public readonly bulbicamControlComponent: typeof CommonStartStopControlComponent = CommonStartStopControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new SaccadeBulbicamTestRemarksFrontend();
    }
}

export interface SaccadeBulbicamTestFrontend extends TestFrontend {}
applyMixins(SaccadeBulbicamTestFrontend, [TestFrontend]);
