import { AfterViewInit, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ROLE } from '../../../../../../../commonout/enum/role';
import { IOrder } from '../../../../../../../commonout/interfaces/order.interface';
import { IUser } from '../../../../../../../commonout/interfaces/user';
import { UserService } from '../../../_services/user/user.service';

export abstract class UsersListComponent<T> implements OnInit, OnDestroy, AfterViewInit {
    @ViewChildren('sortable') sortables: QueryList<ElementRef>;
    protected searchForm: FormGroup;
    protected role: ROLE;
    private users: IUser<T>[];
    private subscriptions: Array<Subscription> = [];
    private state = 'finish';
    private currentPage: number = 0;
    private lastPage: number = 0;
    private pageTurningDisabled = false;

    constructor(role: ROLE, private userService: UserService, protected formBuilder: FormBuilder, private renderer: Renderer2) {
        this.role = role;
    }

    abstract buildSearchForm(): void;

    ngOnInit(): void {
        this.buildSearchForm();
        this.subscriptions.push(
            this.searchForm.valueChanges
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    switchMap((term) => {
                        this.currentPage = 0;
                        return this.userService.search<T>(term, { seed: 'lastName', direction: 'asc' }, this.currentPage);
                    }),
                    tap((res: { users: IUser<T>[]; more: boolean }) => {
                        if (!res.more) {
                            this.lastPage = this.currentPage;
                        } else {
                            this.lastPage = this.currentPage + 1;
                        }
                    })
                )
                .subscribe((res) => (this.users = res.users))
        );
    }

    ngAfterViewInit(): void {
        this.sortables.forEach((sortable: ElementRef) => {
            sortable.nativeElement.className = 'none';
        });
        this.getUsers(
            {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                role: this.role,
            },
            {
                seed: 'lastName',
                direction: 'asc',
            },
            0
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getUsers(term: any, order: IOrder, page: number) {
        this.subscriptions.push(
            this.userService.search<T>(term, order, page).subscribe((data) => {
                this.currentPage = page;
                this.pageTurningDisabled = false;

                if (!data.more) {
                    this.lastPage = this.currentPage;
                } else {
                    this.lastPage = this.currentPage + 1;
                }

                this.users = data.users;
            })
        );
    }

    turnPage(page: number) {
        this.pageTurningDisabled = true;
        const searchFormRaw = this.searchForm.getRawValue();
        searchFormRaw.page = page;

        this.getUsers(searchFormRaw, { seed: 'lastName', direction: 'asc' }, page);
    }

    onTurnPrev(page: number) {
        this.turnPage(page);
        this.state = 'finish';

        setTimeout(() => {
            this.state = 'start';
        }, 0);
    }

    onTurnNext(page: number) {
        this.turnPage(page);
        this.state = 'start';

        setTimeout(() => {
            this.state = 'finish';
        }, 0);
    }

    private sortBy(seed: string, order: HTMLDivElement): void {
        const searchFormRaw = this.searchForm.getRawValue();
        const oldOrder: string = order.className;
        this.sortables.forEach((sortable: ElementRef) => {
            sortable.nativeElement.className = 'none';
        });
        switch (oldOrder) {
            case 'asc':
                order.className = 'desc';
                this.renderer.removeClass(order.querySelector('i'), 'aico-down-arrow');
                this.renderer.addClass(order.querySelector('i'), 'aico-up-arrow');
                break;
            case 'desc':
                order.className = 'asc';
                this.renderer.removeClass(order.querySelector('i'), 'aico-up-arrow');
                this.renderer.addClass(order.querySelector('i'), 'aico-down-arrow');
                break;
            case 'none':
                order.className = 'asc';
                this.renderer.removeClass(order.querySelector('i'), 'aico-up-arrow');
                this.renderer.addClass(order.querySelector('i'), 'aico-down-arrow');
                break;
            default:
                break;
        }

        this.getUsers(
            searchFormRaw,
            {
                seed,
                direction: order.className,
            },
            0
        );
    }
}
