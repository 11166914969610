import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'singleselect',
    template: require('./singleselect.component.html'),
    styles: [require('./singleselect.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleselectComponent),
            multi: true,
        },
    ],
})
export class SingleselectComponent implements ControlValueAccessor {
    @ViewChildren('listItem') itemsList: QueryList<ElementRef>;
    @Input() private label: string;
    @Input() private inputClass: string = 'normalInput';
    @Output() isFocused = new EventEmitter<boolean>();
    @Output() tabLocker = new EventEmitter<boolean>();
    private _list: string[];
    public presentedValue: string = ''; // the value shown in component input
    private value: string = ''; // the current value of component
    private preselected: number = null; // index of presentedValue
    public opened: boolean = false;
    private listener: any = null;
    private nativeElement: any;
    private disabled: boolean = false;
    private onChange = (_: any) => {};
    private onTouched = () => {};

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.nativeElement = this.elementRef.nativeElement;
    }
    @HostListener('focus', ['$event']) private onFocus(event: FocusEvent) {
        this.isFocused.emit(true);
        if (!this.listener) this.listener = this.renderer.listen(this.elementRef.nativeElement, 'keyup', this.keyupEventListener.bind(this));
    }
    @HostListener('blur', ['$event']) private onBlur(event: FocusEvent) {
        if (this.opened) {
            this.presentedValue = this.value;
            this.preselected = this._list.indexOf(this.value);
            this.opened = false;
            this.tabLocker.emit(this.opened);
        }
        this.isFocused.emit(false);
        this.listener();
        this.listener = null;
    }
    public onClick(btn: MouseEvent) {
        if (this.disabled) return;
        if (this.opened) {
            this.presentedValue = this.value;
            this.preselected = this._list.indexOf(this.value);
        }
        this.opened = !this.opened;
        this.tabLocker.emit(this.opened);
    }

    @Input() set list(list: string[] | Object) {
        if (Array.isArray(list)) {
            this._list = list;
        } else {
            this._list = Object.values(list);
        }
    }
    writeValue(value: string): void {
        if (value) {
            this.value = value;
            this.presentedValue = value;
            this.preselected = this._list.indexOf(this.value);
        } else {
            this.value = '';
        }
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    public select(value: string, stopPropagation?: boolean) {
        if (this.opened) {
            this.onTouched();
            this.value = value;
            this.presentedValue = value;
            if (!stopPropagation) {
                this.onChange(this.value);
            }
        }
        this.opened = !this.opened;
        this.tabLocker.emit(this.opened);
    }
    private keyupEventListener(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            if (event.key === 'ArrowUp') {
                if (this.preselected === 0) {
                    this.preselected = this._list.length - 1;
                } else {
                    this.preselected--;
                }
                // scroll selected item to container view
                let targetItem: any = this.itemsList.find(item => item.nativeElement.id.toLowerCase() === this._list[this.preselected].toLowerCase()).nativeElement,
                    container: any = targetItem.parentNode;
                if (container.scrollTop > targetItem.offsetTop || container.offsetHeight < targetItem.offsetTop) {
                    container.scrollTop = targetItem.offsetTop;
                }
                // scroll selected item to container view
            } else if (event.key === 'ArrowDown') {
                if (this.preselected === this._list.length - 1) {
                    this.preselected = 0;
                } else {
                    this.preselected++;
                }
                // scroll selected item to container view
                let targetItem: any = this.itemsList.find(item => item.nativeElement.id.toLowerCase() === this._list[this.preselected].toLowerCase()).nativeElement,
                    container: any = targetItem.parentNode;
                if (container.offsetHeight < targetItem.offsetTop + targetItem.offsetHeight) {
                    container.scrollTop = targetItem.offsetTop + targetItem.offsetHeight - container.offsetHeight;
                } else if (!targetItem.offsetTop) {
                    container.scrollTop = 0;
                }
                // scroll selected item to container view
            }
        } else if (event.key === 'Enter') {
            this.select(this.presentedValue);
        } else if (event.key === 'Escape') {
            if (this.opened) {
                this.presentedValue = this.value;
                this.preselected = this._list.indexOf(this.value);
                this.opened = false;
                this.tabLocker.emit(this.opened);
            }
        }
        this.presentedValue = this._list[this.preselected];
    }
}
