import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { axisBottom, axisLeft, line, max, min, scaleLinear, select, Selection } from 'd3';
import { Subscription } from 'rxjs';
import { IChartEdit } from '../../../../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';
import { FunctionalScreeningChartService } from '../../../../../_services/chartServices/functionalScreeningChartService';
import { ProSaccadeChartService } from '../../../../../_services/chartServices/proSaccadeChartService';
import { BulbicamChartComponent } from '../../haploChart.component';
import { ProSaccadeTestChartComponent } from '../../SaccadeMerged/ProSaccadeChart/pro-saccade-test-chart.component';

interface Margins {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

@Component({
    selector: 'functionalScreening-test-chart',
    template: require('./functionalScreening-test-chart.component.html'),
    styles: [require('./functionalScreening-test-chart.component.scss')],
})
export class FunctionalScreeningBulbicamTestChartComponent extends BulbicamChartComponent implements OnInit, OnDestroy, AfterViewInit {
    private isDataRendered = this.chartService.isDataRendered$;

    @ViewChild('functionalScreeningWrapper') private functionalScreeningWrapper: ElementRef;
    @ViewChild('functionalScreeningResults') private functionalScreeningResults: ElementRef;
    @ViewChild('proSaccadeChart') private proSaccadeChart: ProSaccadeTestChartComponent;

    private eyeTrackingBlock: Selection<SVGGElement, unknown, null, undefined>;
    private startStopLegend: Selection<SVGGElement, unknown, null, undefined>;
    private graphBlock: Selection<SVGGElement, unknown, null, undefined>;
    private screenRestrictionBlock: Selection<SVGGElement, unknown, null, undefined>;
    private infoBlock: Selection<SVGGElement, unknown, null, undefined>;
    private wrapper: Selection<SVGGElement, unknown, null, undefined>;
    private wrapperResults: Selection<SVGGElement, unknown, null, undefined>;
    private readonly padding: Margins = { left: 40, top: 15, right: 40, bottom: 15 };
    private sideSquaresLength: number;

    private clientProportions: DOMRect;
    private chosenButton = 1;
    // private data: IFunctionalScreeningTestCamMessage[] = [];
    private firstFrame: ICamMessage | null = null;

    private subscriptions: Subscription[] = [];
    proSaccadeData: any[] = [];
    public startParams: {
        proSaccadesRatioUpdate: () => Promise<void>;
    };

    constructor(private chartService: FunctionalScreeningChartService, private proSaccadeService: ProSaccadeChartService) {
        super();
    }
    ngOnInit(): void {}
    ngAfterViewInit(): void {
        this.wrapper = select(this.functionalScreeningWrapper.nativeElement).attr('height', '100%');
        this.wrapperResults = select(this.functionalScreeningResults.nativeElement).attr('height', '100%');

        this.clientProportions = this.wrapper.node().getBoundingClientRect();
        this.sideSquaresLength = (this.clientProportions.width - this.padding.right * 2 - this.padding.left) / 6;

        this.createAllGroups(this.wrapper);
        this.createInfoGroup(this.wrapperResults);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    public addData(frames: ICamMessage[], skipRender?: boolean): void {
        if (!this.firstFrame) {
            this.firstFrame = frames[0];
        }

        this.chartService.addData(frames);

        if (!skipRender) {
            this.render();

            if (this.chartService.proSaccadeData.length && this.chartService.proSaccadeData.slice(-1)[0].message_type === MESSAGE_TYPE.STOP_TEST) {
                this.proSaccadeChart.clearData();
                const results = this.proSaccadeService.calculateChartResults(this.chartService.proSaccadeData);
                if (!results) {
                    console.error('Eyetracking was failed. Test has bad raw data');
                    return;
                }
                this.proSaccadeChart.drawRecordedChart(results, true);
                if (!this.firstFrame.ratio) {
                    const CalibrationValues = results.trialInfo
                        .map(d => d.calibrationValue)
                        .filter(d => !!d)
                        .sort((a, b) => a - b);
                    const averageCalibrationValue = CalibrationValues[Math.floor(CalibrationValues.length / 2)];
                    this.updateCamMessage({ ...this.firstFrame, ratio: averageCalibrationValue }).then(() => this.startParams.proSaccadesRatioUpdate());
                }
            }

            this.isDataRendered.next(true);
        }
    }
    public setEdits(edits: IChartEdit[]): void {
        // throw new Error('Method not implemented.');
    }
    public clearData(notRendered: boolean): void {
        if (!notRendered) {
            this.eyeTrackingBlock.select(`.strtstplegG`).remove();
            this.eyeTrackingBlock.select(`.eyeTrackingPathOD`).attr('d', '');
            this.eyeTrackingBlock.select(`.eyeTrackingPathOS`).attr('d', '');
            this.eyeTrackingBlock.select(`.eyeTrackingFirstPointOD`).attr('r', 0);
            this.eyeTrackingBlock.select(`.eyeTrackingFirstPointOS`).attr('r', 0);
            this.eyeTrackingBlock.select(`.eyeTrackingLastPointOD`).attr('r', 0);
            this.eyeTrackingBlock.select(`.eyeTrackingLastPointOS`).attr('r', 0);
            this.graphBlock.select(`.graphPathOD`).attr('d', '');
            this.graphBlock.select(`.graphPathOS`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqarePathOD`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqarePathOS`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathOD`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathOS`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareGroupFirstPointOD`).attr('r', 0);
            this.screenRestrictionBlock.select(`.restrictionSqareGroupFirstPointOS`).attr('r', 0);
            this.screenRestrictionBlock.select(`.restrictionSqareGroupMirrorFirstPointOD`).attr('r', 0);
            this.screenRestrictionBlock.select(`.restrictionSqareGroupMirrorFirstPointOS`).attr('r', 0);
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathODForOD`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathOSForOS`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathOSForOD`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathODForOS`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathOSForODEmpty`).attr('d', '');
            this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathOSForOSEmpty`).attr('d', '');

            this.screenRestrictionBlock.select('.differencePath').attr('d', '');
            this.infoBlock.selectAll('g').remove();

            this.graphBlock.select('.xAxis').call(
                axisBottom(
                    scaleLinear()
                        .domain([0, 0])
                        .range([0, 0])
                ).ticks(0)
            );
            this.graphBlock.select('.yAxis').call(
                axisLeft(
                    scaleLinear()
                        .domain([0, 0])
                        .range([0, 0])
                ).ticks(0)
            );
        }

        this.chosenButton = 1;
        this.firstFrame = null;
        this.chartService.clearData();
    }
    private createInfoGroup(target: Selection<any, unknown, null, undefined>): void {
        this.infoBlock = target.append('g').attr('class', 'infoBlock');
        // .attr('transform', `translate(${0}, ${this.padding.top * 6 + this.sideSquaresLength * 4.5 + this.padding.bottom * 6})`);
    }
    private createAllGroups(target: Selection<any, unknown, null, undefined>): void {
        // create all groups wrappers
        this.eyeTrackingBlock = target
            .append('g')
            .attr('class', 'eyeTrackingBlock')
            .attr('transform', `translate(${this.clientProportions.width / 2 - this.padding.right / 2 - this.sideSquaresLength}, ${this.padding.top})`);
        this.graphBlock = target
            .append('g')
            .attr('class', 'graphBlock')
            .attr('transform', `translate(${this.padding.left}, ${this.padding.top * 3 + this.padding.bottom + this.sideSquaresLength})`);
        this.screenRestrictionBlock = target
            .append('g')
            .attr('class', 'screenRestrictionBlock')
            .attr('transform', `translate(${0}, ${this.padding.top * 5 + this.sideSquaresLength * 2 + this.padding.bottom * 4})`);

        this.wrapper.attr('height', `${this.padding.top * 6 + this.sideSquaresLength * 4.5 + this.padding.bottom * 6}px`);

        // initiallize all functions
        const createEyeTrackingSubBlock = (target: Selection<SVGGElement, unknown, null, undefined>, eye: string) => {
            target.append('g').attr('class', `eyeTrackingItem${eye}`);

            target
                .select(`.eyeTrackingItem${eye}`)
                .append('defs')
                .append('clipPath')
                .attr('id', `eyeTrackingMask${eye}`)
                .append('rect')
                .attr('width', this.sideSquaresLength)
                .attr('height', this.sideSquaresLength)
                .attr('x', () => (eye === 'OD' ? 0 : this.sideSquaresLength + this.padding.right))
                .attr('y', this.padding.top);
            target
                .select(`.eyeTrackingItem${eye}`)
                .append('rect')
                .attr('class', `eyeBoundaries${eye}`)
                .attr('width', this.sideSquaresLength)
                .attr('height', this.sideSquaresLength)
                .attr('x', () => (eye === 'OD' ? 0 : this.sideSquaresLength + this.padding.right))
                .attr('y', this.padding.top)
                .attr('fill', 'transparent')
                .attr('stroke', '#4f6c68')
                .attr('stroke-width', 1);

            target
                .select(`.eyeTrackingItem${eye}`)
                .append('path')
                .attr('class', `eyeTrackingPath${eye}`)
                .attr('fill', 'none')
                .attr('stroke', '#2381B3')
                .attr('stroke-width', '1px');

            target
                .select(`.eyeTrackingItem${eye}`)
                .append('circle')
                .attr('class', `eyeTrackingFirstPoint${eye}`)
                .attr('fill', 'yellow');
            target
                .select(`.eyeTrackingItem${eye}`)
                .append('circle')
                .attr('class', `eyeTrackingLastPoint${eye}`)
                .attr('fill', 'red');

            target
                .append('text')
                .text(eye)
                .style('fill', 'white')
                .attr('font-size', '16px')
                .attr('alignment-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('transform', () => {
                    const x = eye === 'OD' ? this.sideSquaresLength / 2 : this.sideSquaresLength + this.padding.right + this.sideSquaresLength / 2;
                    return `translate(${x}, ${0})`;
                });

            target.select(`.eyeTrackingItem${eye}`).attr('clip-path', `url(#eyeTrackingMask${eye})`);
        };

        const createScreenRestrictionSubBlock = (target: Selection<any, unknown, null, undefined>, eye: string) => {
            target.append('g').attr('class', `restrictionSqareGroup${eye}`);
            target.append('g').attr('class', `restrictionSqareGroupMirror${eye}`);

            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('defs')
                .append('clipPath')
                .attr('id', `restrictionsSqareGroupMask${eye}`)
                .append('rect')
                .attr('width', this.sideSquaresLength)
                .attr('height', this.sideSquaresLength)
                .attr('x', () =>
                    eye === 'OD'
                        ? this.clientProportions.width / 2 - this.sideSquaresLength * 2 - this.padding.right / 2
                        : this.clientProportions.width / 2 + this.sideSquaresLength + this.padding.right / 2
                )
                .attr('y', this.padding.top * 3);
            target
                .select(`.restrictionSqareGroupMirror${eye}`)
                .append('defs')
                .append('clipPath')
                .attr('id', `restrictionsSqareGroupMirrorMask${eye}`)
                .append('rect')
                .attr('width', this.sideSquaresLength)
                .attr('height', this.sideSquaresLength)
                .attr('x', () =>
                    eye === 'OD'
                        ? this.clientProportions.width / 2 - this.sideSquaresLength * 2 - this.padding.right / 2
                        : this.clientProportions.width / 2 + this.sideSquaresLength + this.padding.right / 2
                )
                .attr('y', this.padding.top * 3 + this.sideSquaresLength + this.padding.bottom * 2);

            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('rect')
                .attr('class', `restrictionSqareBoundary${eye}`)
                .attr('width', this.sideSquaresLength)
                .attr('height', this.sideSquaresLength)
                .attr('x', () =>
                    eye === 'OD'
                        ? this.clientProportions.width / 2 - this.sideSquaresLength * 2 - this.padding.right / 2
                        : this.clientProportions.width / 2 + this.sideSquaresLength + this.padding.right / 2
                )
                .attr('y', this.padding.top * 3)
                .attr('fill', 'transparent')
                .attr('stroke', '#4f6c68')
                .attr('stroke-width', 1);

            target
                .select(`.restrictionSqareGroupMirror${eye}`)
                .append('rect')
                .attr('class', `restrictionSqareBoundaryMirror${eye}`)
                .attr('width', this.sideSquaresLength)
                .attr('height', this.sideSquaresLength)
                .attr('x', () =>
                    eye === 'OD'
                        ? this.clientProportions.width / 2 - this.sideSquaresLength * 2 - this.padding.right / 2
                        : this.clientProportions.width / 2 + this.sideSquaresLength + this.padding.right / 2
                )
                .attr('y', this.padding.top * 3 + this.sideSquaresLength + this.padding.bottom * 2)
                .attr('fill', 'transparent')
                .attr('stroke', '#4f6c68')
                .attr('stroke-width', 1);

            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('path')
                .attr('class', `restrictionSqarePath${eye}`)
                .attr('fill', 'none')
                .attr('stroke', '#2381B3')
                .attr('stroke-width', '1px')
                .attr('transform', `translate(${0}, ${this.padding.top * 3})`);

            const paths = target.select(`.restrictionSqareGroupMirror${eye}`).append('g');
            const defs = target.select(`.restrictionSqareGroupMirror${eye}`).select('defs');

            defs.append('clipPath')
                .attr('id', `clipPathOSin${eye}`)
                .append('path');

            paths
                .append('path')
                .attr('class', `restrictionSqareMirrorPathODFor${eye}`)
                .attr('fill', 'none')
                // .attr('stroke', '#81c53d')
                .attr('fill', '#81c53d')
                .attr('stroke-width', '1px')
                .attr('transform', `translate(${0}, ${this.sideSquaresLength + this.padding.top * 5})`);

            paths
                .append('path')
                .attr('class', `restrictionSqareMirrorPathOSFor${eye}`)
                .attr('fill', 'none')
                // .attr('stroke', '#f2994a')
                .attr('fill', '#f2994a')
                .attr('stroke-width', '1px')
                .attr('transform', `translate(${0}, ${this.sideSquaresLength + this.padding.top * 5})`);

            paths
                .append('path')
                .attr('class', `restrictionSqareMirrorPathOSFor${eye}Empty`)
                .attr('fill', 'gray')
                .attr('stroke-width', '1px')
                .attr('transform', `translate(${0}, ${this.sideSquaresLength + this.padding.top * 5})`);

            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('circle')
                .attr('class', `restrictionSqareGroupFirstPoint${eye}`)
                .attr('fill', 'yellow')
                .attr('transform', `translate(${0}, ${this.padding.top * 3})`);
            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('circle')
                .attr('class', `restrictionSqareGroupLastPoint${eye}`)
                .attr('fill', 'red')
                .attr('transform', `translate(${0}, ${this.padding.top * 3})`);
            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('circle')
                .attr('class', `restrictionSqareGroupMirrorFirstPoint${eye}`)
                .attr('fill', 'yellow')
                .attr('transform', `translate(${0}, ${this.sideSquaresLength + this.padding.top * 5})`);
            target
                .select(`.restrictionSqareGroup${eye}`)
                .append('circle')
                .attr('class', `restrictionSqareGroupMirrorFirstPoint${eye}`)
                .attr('fill', 'yellow')
                .attr('transform', `translate(${0}, ${this.sideSquaresLength + this.padding.top * 5})`);

            target
                .append('text')
                .text(eye)
                .style('fill', 'white')
                .attr('font-size', '16px')
                .attr('alignment-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .attr('transform', () => {
                    const x =
                        eye === 'OD'
                            ? this.clientProportions.width / 2 - this.padding.right / 2 - this.sideSquaresLength - this.sideSquaresLength / 2
                            : this.clientProportions.width / 2 + this.padding.right / 2 + this.sideSquaresLength + this.sideSquaresLength / 2;
                    return `translate(${x}, ${this.padding.top * 2})`;
                });

            target.select(`.restrictionSqareGroup${eye}`).attr('clip-path', `url(#restrictionsSqareGroupMask${eye})`);
            target.select(`.restrictionSqareGroupMirror${eye}`).attr('clip-path', `url(#restrictionsSqareGroupMirrorMask${eye})`);
        };

        // create all subgroups
        ['OD', 'OS'].forEach(e => {
            createEyeTrackingSubBlock(this.eyeTrackingBlock, e);
            createScreenRestrictionSubBlock(this.screenRestrictionBlock, e);
        });

        this.graphBlock
            .append('g')
            .attr('class', 'yAxis')
            .attr('transform', `translate(${0},${0})`)
            .attr('color', 'white');
        this.graphBlock
            .append('g')
            .attr('class', 'xAxis')
            .attr('transform', `translate(${0},${this.sideSquaresLength})`)
            .attr('color', 'white');

        const xGenerator = scaleLinear()
            .domain([0, 1])
            .range([0, this.clientProportions.width - this.padding.right * 2]);
        const yGenerator = scaleLinear()
            .domain([1, 0])
            .range([this.padding.top, this.sideSquaresLength]);

        this.graphBlock.select('.xAxis').call(axisBottom(xGenerator).ticks(10));
        this.graphBlock.select('.yAxis').call(axisLeft(yGenerator).ticks(10));

        this.graphBlock
            .append('text')
            .text('sec')
            .attr('fill', 'white')
            .attr('transform', `translate(${this.clientProportions.width - 80}, ${this.sideSquaresLength + this.padding.top * 2})`);
        this.graphBlock
            .append('text')
            .text('Pupil(mm)')
            .attr('fill', 'white')
            .attr('transform', `translate(${-20}, ${0})`);
        this.graphBlock
            .append('path')
            .attr('class', `graphPathOD`)
            .attr('fill', 'none')
            .attr('stroke-width', '1px')
            .attr('stroke', '#81c53d');
        this.graphBlock
            .append('path')
            .attr('class', `graphPathOS`)
            .attr('fill', 'none')
            .attr('stroke-width', '1px')
            .attr('stroke', '#f2994a');

        this.screenRestrictionBlock
            .append('text')
            .text('Screen Restriction Visualization')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .attr('transform', `translate(${this.clientProportions.width / 2}, ${0})`);

        this.screenRestrictionBlock.append('g').attr('class', 'differenceGroup');

        this.screenRestrictionBlock
            .select(`.differenceGroup`)
            .append('defs')
            .append('clipPath')
            .attr('id', `differenceMask`)
            .append('rect')
            .attr('width', this.sideSquaresLength)
            .attr('height', this.sideSquaresLength)
            .attr('x', () => this.clientProportions.width / 2 - this.sideSquaresLength / 2)
            .attr('y', this.padding.top * 3);
        this.screenRestrictionBlock
            .select(`.differenceGroup`)
            .append('rect')
            .attr('class', `differenceSqareBoundary`)
            .attr('width', this.sideSquaresLength)
            .attr('height', this.sideSquaresLength)
            .attr('x', () => this.clientProportions.width / 2 - this.sideSquaresLength / 2)
            .attr('y', this.padding.top * 3)
            .attr('fill', 'transparent')
            .attr('stroke', '#4f6c68')
            .attr('stroke-width', 1);
        this.screenRestrictionBlock
            .select('.differenceGroup')
            .append('path')
            .attr('class', `differencePath`)
            .attr('fill', 'none')
            .attr('stroke', '#eb5757')
            .attr('stroke-width', '1px')
            .attr('transform', `translate(${this.clientProportions.width / 2 - this.sideSquaresLength / 2}, ${this.padding.top * 3})`);

        this.screenRestrictionBlock.select(`.differenceGroup`).attr('clip-path', `url(#differenceMask)`);
    }
    private render(): void {
        this.startStopLegend = this.eyeTrackingBlock.append('g').attr('class', 'strtstplegG');

        this.startStopLegend
            .append('circle')
            .attr('class', 'strtstplegRect')
            .attr('cx', 2 * this.sideSquaresLength + 3 * this.padding.right)
            .attr('cy', this.sideSquaresLength / 2 - this.padding.top)
            .attr('r', 5)
            .attr('width', `${this.sideSquaresLength}px`)
            .attr('height', `${this.sideSquaresLength}px`)
            .attr('fill', 'yellow')
            .attr('stroke', '#fff')
            .attr('stroke-width', 1);

        this.startStopLegend
            .append('circle')
            .attr('class', 'strtstplegRect')
            .attr('cx', 2 * this.sideSquaresLength + 3 * this.padding.right)
            .attr('cy', this.sideSquaresLength / 2 + this.padding.top)
            .attr('r', 5)
            .attr('width', `${this.sideSquaresLength}px`)
            .attr('height', `${this.sideSquaresLength}px`)
            .attr('fill', 'red')
            .attr('stroke', '#fff')
            .attr('stroke-width', 1);

        this.startStopLegend
            .append('text')
            .attr('class', 'strtstplegtext')
            .attr('x', 2 * this.sideSquaresLength + 4 * this.padding.right)
            .attr('y', this.sideSquaresLength / 2 - this.padding.top)
            .style('fill', 'white')
            .style('stroke', 'white')
            .style('stroke-width', '0px')
            .attr('font-size', '16px')
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .text('Start');

        this.startStopLegend
            .append('text')
            .attr('class', 'strtstplegtext')
            .attr('x', 2 * this.sideSquaresLength + 4 * this.padding.right)
            .attr('y', this.sideSquaresLength / 2 + this.padding.top)
            .style('fill', 'white')
            .style('stroke', 'white')
            .style('stroke-width', '0px')
            .attr('font-size', '16px')
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .text('Stop');

        ['OD', 'OS'].forEach(e => {
            this.renderTopTraces(e);
            this.renderPupilLines(e);
            this.renderBottomTraces(e);
        });

        if (this.chartService.testIsDone) {
            this.renderInfoBlock();
        }
    }

    private renderTopTraces(eye: string): void {
        const index15 = this.chartService[`trackingGlints${eye}`].findIndex((e: { type: number }) => e.type === 15);
        const index16 = this.chartService[`trackingGlints${eye}`].findIndex((e: { type: number }) => e.type === 16);

        let filteredTopTraces: { x: number; y: number }[] = [...this.chartService[`trackingGlints${eye}`]]
            .filter((el, i) => i > index15 && i < index16)
            .filter(e => e.x !== 0 && e.y !== 0);

        const firstPoint = filteredTopTraces[0];
        const lastPoint = filteredTopTraces[filteredTopTraces.length - 1];

        let range: number[] = [];

        eye === 'OD' ? (range = [0, this.sideSquaresLength]) : (range = [this.sideSquaresLength + this.padding.right, this.sideSquaresLength * 2 + this.padding.right]);

        if (firstPoint) {
            const xGenerator = scaleLinear()
                .domain([firstPoint.x - 100, firstPoint.x + 100])
                .range(range);

            const yGenerator = scaleLinear()
                .domain([firstPoint.y - 100, firstPoint.y + 100])
                .range([this.padding.top, this.sideSquaresLength + this.padding.top]);

            const lineGenerator = line<{ x: number; y: number }>()
                .x(d => xGenerator(d.x))
                .y(d => yGenerator(d.y));

            this.eyeTrackingBlock
                .select(`.eyeTrackingFirstPoint${eye}`)
                .attr('r', 2)
                .attr('cx', xGenerator(firstPoint.x))
                .attr('cy', yGenerator(firstPoint.y));
            this.eyeTrackingBlock
                .select(`.eyeTrackingLastPoint${eye}`)
                .attr('r', 2)
                .attr('cx', xGenerator(lastPoint.x))
                .attr('cy', yGenerator(lastPoint.y));

            this.eyeTrackingBlock
                .select(`.eyeTrackingPath${eye}`)
                .datum(filteredTopTraces)
                .attr('d', lineGenerator);
        }
    }

    private renderPupilLines(eye: string): void {
        const filteredPupils = [...this.chartService[`pupilLines${eye}`]];

        const yValues: number[] = [];
        this.chartService.pupilLinesOD.forEach((e: { x: number; y: number }) => {
            yValues.push(e.y);
        });
        this.chartService.pupilLinesOS.forEach((e: { x: number; y: number }) => {
            yValues.push(e.y);
        });

        const yMax = max(yValues);
        const yMin = min(yValues);

        if (this.chartService.pupilLinesOD.length > 0) {
            const xGenerator = scaleLinear()
                .domain([0, this.chartService.pupilLinesOD[this.chartService.pupilLinesOD.length - 1].x])
                .range([0, this.clientProportions.width - this.padding.right * 2]);
            const yGenerator = scaleLinear()
                .domain([yMax, yMin])
                .range([this.padding.top, this.sideSquaresLength]);
            const lineGenerator = line<{ x: number; y: number }>()
                .x(d => xGenerator(d.x))
                .y(d => yGenerator(d.y));

            this.graphBlock.select('.xAxis').call(axisBottom(xGenerator).ticks(10));
            this.graphBlock.select('.yAxis').call(axisLeft(yGenerator).ticks(10));

            this.graphBlock
                .select('.xAxis')
                .selectAll('.tick')
                .append('path')
                .attr('fill', 'none')
                .attr('stroke', '#4f6c68')
                .attr('stroke-dasharray', 5)
                .attr('opacity', '30%')
                .attr('d', d => (d !== 0 ? `M0, 0, L0, ${-this.sideSquaresLength}` : null));

            this.graphBlock
                .select(`.graphPath${eye}`)
                .datum(filteredPupils)
                .attr('d', lineGenerator);
        }
    }

    private renderBottomTraces(eye: string): void {
        const startingStage: number[] = [];
        const finsishinggStage: number[] = [];
        const arrForSearching = [...this.chartService[`trackingGlints${eye}`]];

        arrForSearching.forEach((el: { type: number }, i: number) => {
            if (el.type === 15) {
                startingStage.push(i);
                arrForSearching.splice(i, 1, {});
            }
            if (el.type === 16) {
                finsishinggStage.push(i);
                arrForSearching.splice(i, 1, {});
            }
        });

        let filteredBottomTraces: { x: number; y: number; type: number }[];
        let index15: number;
        let index16: number;

        switch (this.chosenButton) {
            case 1:
                index15 = startingStage[1];
                index16 = finsishinggStage[1];
                break;
            case 2:
                index15 = startingStage[2];
                index16 = finsishinggStage[2];
                break;
            case 3:
                index15 = startingStage[3];
                index16 = finsishinggStage[3];
                break;
            case 4:
                index15 = startingStage[4];
                index16 = finsishinggStage[4];
                break;
        }

        filteredBottomTraces = [...this.chartService[`trackingGlints${eye}`]].filter((e, i) => i > index15 && i < index16).filter(e => e.x !== 0 && e.y !== 0);

        const firstPoint = filteredBottomTraces[0];
        const lastPoint = filteredBottomTraces[filteredBottomTraces.length - 1];

        if (firstPoint) {
            let range: number[] = [];

            eye === 'OD'
                ? (range = [
                      this.clientProportions.width / 2 - this.sideSquaresLength * 2 - this.padding.right / 2,
                      this.clientProportions.width / 2 - this.sideSquaresLength * 2 - this.padding.right / 2 + this.sideSquaresLength,
                  ])
                : (range = [
                      this.clientProportions.width / 2 + this.sideSquaresLength + this.padding.right / 2,
                      this.clientProportions.width / 2 + this.sideSquaresLength * 2 + this.padding.right / 2,
                  ]);

            const xGenerator = scaleLinear()
                .domain([firstPoint.x - 100, firstPoint.x + 100])
                .range(range);

            const yGenerator = scaleLinear()
                .domain([firstPoint.y - 100, firstPoint.y + 100])
                .range([0, this.sideSquaresLength]);

            const lineGenerator = line<{ x: number; y: number }>()
                .x(d => xGenerator(d.x))
                .y(d => yGenerator(d.y));

            this.screenRestrictionBlock
                .select(`.restrictionSqareGroupFirstPoint${eye}`)
                .attr('r', 2)
                .attr('cx', xGenerator(firstPoint.x))
                .attr('cy', yGenerator(firstPoint.y));

            this.screenRestrictionBlock
                .select(`.restrictionSqareGroupLastPoint${eye}`)
                .attr('r', 2)
                .attr('cx', xGenerator(lastPoint.x))
                .attr('cy', yGenerator(lastPoint.y));

            this.renderMiddleTraces();

            this.screenRestrictionBlock
                .select(`.restrictionSqarePath${eye}`)
                .datum(filteredBottomTraces)
                .attr('d', lineGenerator);

            const renderOverlaidTraces = (filtereteredBottomTraces: { x: number; y: number }[]) => {
                this.screenRestrictionBlock
                    .select(`.restrictionSqareMirrorPath${eye}For${eye}`)
                    .datum(filtereteredBottomTraces)
                    .attr('d', lineGenerator);

                if (eye === 'OS') {
                    this.screenRestrictionBlock
                        .select(`.restrictionSqareMirrorPath${eye}For${eye}Empty`)
                        .datum(filtereteredBottomTraces)
                        .attr('d', lineGenerator);

                    {
                        let filteredOD = [...this.chartService.trackingGlintsOD].filter((e, i) => i > index15 && i < index16).filter(e => e.x !== 0 && e.y !== 0);

                        const firstPoint = filteredOD[0];

                        const xGenerator = scaleLinear()
                            .domain([firstPoint?.x - 100, firstPoint?.x + 100])
                            .range(range);

                        const yGenerator = scaleLinear()
                            .domain([firstPoint?.y - 100, firstPoint?.y + 100])
                            .range([0, this.sideSquaresLength]);

                        const lineGenerator = line<{ x: number; y: number }>()
                            .x(d => xGenerator(d.x))
                            .y(d => yGenerator(d.y));

                        this.screenRestrictionBlock
                            .select(`.restrictionSqareMirrorPathODForOS`)
                            .datum(filteredOD)
                            .attr('d', lineGenerator);
                    }

                    const ODpath = this.screenRestrictionBlock.select(`.restrictionSqareMirrorPathODForOS`).attr('d');
                    this.screenRestrictionBlock
                        .select(`#clipPathOSin${eye}`)
                        .select('path')
                        .attr('d', ODpath);

                    this.screenRestrictionBlock.select(`.restrictionSqareMirrorPath${eye}For${eye}Empty`).attr('clip-path', `url(#clipPathOSin${eye})`);
                }

                if (eye === 'OD') {
                    let filteredOS = [...this.chartService.trackingGlintsOS].filter((e, i) => i > index15 && i < index16).filter(e => e.x !== 0 && e.y !== 0);

                    const firstPoint = filteredOS[0];

                    const xGenerator = scaleLinear()
                        .domain([firstPoint?.x - 100, firstPoint?.x + 100])
                        .range(range);

                    const yGenerator = scaleLinear()
                        .domain([firstPoint?.y - 100, firstPoint?.y + 100])
                        .range([0, this.sideSquaresLength]);

                    const lineGenerator = line<{ x: number; y: number }>()
                        .x(d => xGenerator(d.x))
                        .y(d => yGenerator(d.y));

                    this.screenRestrictionBlock.select('filter').append('path');

                    const ODpath = this.screenRestrictionBlock.select(`.restrictionSqareMirrorPath${eye}For${eye}`).attr('d');
                    this.screenRestrictionBlock
                        .select(`#clipPathOSin${eye}`)
                        .select('path')
                        .attr('d', ODpath);

                    this.screenRestrictionBlock
                        .select(`.restrictionSqareMirrorPathOSForOD`)
                        .datum(filteredOS)
                        .attr('d', lineGenerator);
                    this.screenRestrictionBlock
                        .select(`.restrictionSqareMirrorPathOSForODEmpty`)
                        .datum(filteredOS)
                        .attr('d', lineGenerator)
                        .attr('clip-path', `url(#clipPathOSin${eye})`);
                }
            };

            if (this.chartService.testIsDone) {
                renderOverlaidTraces(filteredBottomTraces);

                this.screenRestrictionBlock.selectAll('.restrictionButtons').remove();

                const buttonsBlock = this.screenRestrictionBlock
                    .append('g')
                    .attr('class', 'restrictionButtons')
                    .attr('transform', `translate(${this.clientProportions.width / 2 - 50}, ${this.padding.top * 5 + this.sideSquaresLength * 2 + this.padding.bottom * 2})`)

                buttonsBlock
                    .selectAll('.switchButton')
                    .data([1, 2, 3, 4])
                    .enter()
                    .append('rect')
                    .attr('id', (d) => `button-rect-${d}`)
                    .attr('fill', d => (d === this.chosenButton ? '#B9C4C3' : 'transparent'))
                    .attr('stroke', '#B9C4C3')
                    .attr('rx', 5)
                    .attr('ry', 5)
                    .attr('width', 25)
                    .attr('height', 30)
                    .attr('x', (d, i) => i * 35)
                    .attr('cursor', 'pointer')
                    .on('click', d => {
                        this.chosenButton = d;
                        ['OD', 'OS'].forEach(e => this.renderBottomTraces(e));
                        this.renderInfoBlock();
                    })
                    .on("mouseover", function (d) {
                        select('.restrictionButtons').select(`#button-rect-${d}`).attr("stroke", "#F5B9A9");
                        select('.restrictionButtons').select(`#button-text-${d}`).attr("fill", "#F5B9A9");
                    })
                    .on("mouseout", (d) => {
                        select('.restrictionButtons').select(`#button-rect-${d}`).attr('fill', d => (d === this.chosenButton ? '#B9C4C3' : 'transparent')).attr('stroke', '#B9C4C3');
                        select('.restrictionButtons').select(`#button-text-${d}`).attr('fill', '#B9C4C3');                    
                    });

                buttonsBlock
                    .selectAll('.switchButtonLabel')
                    .data([1, 2, 3, 4])
                    .enter()
                    .append('text')
                    .attr('id', (d) => `button-text-${d}`)
                    .text(d => d)
                    .attr('fill', '#B9C4C3')
                    .attr('alignment-baseline', 'middle')
                    .attr('text-anchor', 'middle')
                    .attr('font-size', '20px')
                    .attr('transform', (d, i) => `translate(${i * 35 + 25 / 2}, ${15})`)
                    .attr('cursor', 'pointer')
                    .on('click', d => {
                        this.chosenButton = d;
                        ['OD', 'OS'].forEach(e => this.renderBottomTraces(e));
                        this.renderInfoBlock();
                    })
                    .on("mouseover", function (d) {
                        select('.restrictionButtons').select(`#button-rect-${d}`).attr("stroke", "#F5B9A9");
                        select('.restrictionButtons').select(`#button-text-${d}`).attr("fill", "#F5B9A9");
                    })
                    .on("mouseout", (d) => {
                        select('.restrictionButtons').select(`#button-rect-${d}`).attr('fill', d => (d === this.chosenButton ? '#B9C4C3' : 'transparent')).attr('stroke', '#B9C4C3');
                        select('.restrictionButtons').select(`#button-text-${d}`).attr('fill', '#B9C4C3');                    
                    });;
            }
        }
    }

    private renderMiddleTraces(): void {
        let difference: { x: number; y: number }[] = [];

        const startingStage: number[] = [];
        const finsishinggStage: number[] = [];
        const arrForSearching = [...this.chartService.trackingGlintsOD];

        arrForSearching.forEach((el: { type: number; time: number }, i: number) => {
            if (el.type === 15) {
                startingStage.push(i);
                arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0, time: el.time });
            }
            if (el.type === 16) {
                finsishinggStage.push(i);
                arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0, time: el.time });
            }
        });

        let filteredOD: { x: number; y: number; type: number }[];
        let filteredOS: { x: number; y: number; type: number }[];
        let index15: number;
        let index16: number;

        switch (this.chosenButton) {
            case 1:
                index15 = startingStage[1];
                index16 = finsishinggStage[1];
                break;
            case 2:
                index15 = startingStage[2];
                index16 = finsishinggStage[2];
                break;
            case 3:
                index15 = startingStage[3];
                index16 = finsishinggStage[3];
                break;
            case 4:
                index15 = startingStage[4];
                index16 = finsishinggStage[4];
                break;
        }

        filteredOD = [...this.chartService.trackingGlintsOD].filter((e, i) => i > index15 && i < index16);
        filteredOS = [...this.chartService.trackingGlintsOS].filter((e, i) => i > index15 && i < index16);

        filteredOD.forEach((e, i) => {
            if (e.x !== 0 && e.y !== 0 && filteredOS[i].x !== 0 && filteredOS[i].y !== 0) {
                const obj = {
                    x: Math.abs(filteredOS[i].x - e.x),
                    y: Math.abs(filteredOS[i].y - e.y),
                };

                difference.push(obj);
            }
        });

        const firstPoint = difference[0];

        const xGenerator = scaleLinear()
            .domain([firstPoint?.x - 100, firstPoint?.x + 100])
            .range([0, this.sideSquaresLength]);

        const yGenerator = scaleLinear()
            .domain([firstPoint?.y - 100, firstPoint?.y + 100])
            .range([0, this.sideSquaresLength]);

        const lineGenerator = line<{ x: number; y: number }>()
            .x(d => xGenerator(d.x))
            .y(d => yGenerator(d.y));

        this.screenRestrictionBlock
            .select('.differencePath')
            .datum(difference)
            .attr('d', lineGenerator);
    }

    private renderInfoBlock(): void {
        this.infoBlock.selectAll('g').remove();
        this.infoBlock.style('transform', 'translate(0, 40px)')
        const calculatedValues = this.chartService.valuesCalculation({
            startTimestamp: this.chartService.startTimestamp,
            finishTimestamp: this.chartService.finishTimestamp,

            trackingGlintsOD: this.chartService.trackingGlintsOD,
            trackingGlintsOS: this.chartService.trackingGlintsOS,
            pupilLinesOS: this.chartService.pupilLinesOS,
            pupilLinesOD: this.chartService.pupilLinesOD,

            BPGSizeOD: this.chartService.BPGSizeOD,
            BPGSizeOS: this.chartService.BPGSizeOS,
            DPGSizeOD: this.chartService.DPGSizeOD,
            DPGSizeOS: this.chartService.DPGSizeOS,
            positionOD: this.chartService.positionOD,
            positionOS: this.chartService.positionOS,
            IOLOD: this.chartService.IOLOD,
            IOLOS: this.chartService.IOLOS,
            pupilDistance: this.chartService.pupilDistance,
            testIsDone: this.chartService.testIsDone,
            data: this.chartService.data,
        });

        const feedback = this.infoBlock.append('g').attr('class', 'feedback');
        const observations = this.infoBlock
            .append('g')
            .attr('class', 'observations')
            .attr('transform', `translate(${this.clientProportions.width / 3}, ${0})`);
        const recommendations = this.infoBlock
            .append('g')
            .attr('class', 'recommendations')
            .attr('transform', `translate(${(this.clientProportions.width / 3) * 2}, ${0})`);

        feedback
            .append('text')
            .text('Eyetracking Operator Feedback')
            .style('fill', 'white')
            .attr('font-weight', 900)
            .attr('font-size', '16px')
            .attr('transform', `translate(${0}, ${this.padding.top})`);

        observations
            .append('text')
            .text('Medical Observations')
            .style('fill', 'white')
            .attr('font-weight', 900)
            .attr('font-size', '16px')
            .attr('transform', `translate(${0}, ${this.padding.top})`);

        recommendations
            .append('text')
            .text('Test Recommendations')
            .style('fill', 'white')
            .attr('font-weight', 900)
            .attr('font-size', '16px')
            .attr('transform', `translate(${0}, ${this.padding.top})`);

        const feedbackTextInfo: { title: string; first: string; second: string }[] = [
            { title: '', first: 'OD', second: 'OS' },
            { title: '', first: '', second: '' },
            { title: 'Lens', first: `${calculatedValues.lensOD}`, second: `${calculatedValues.lensOS}` },
            { title: '', first: '', second: '' },
            { title: 'Eye Position/px', first: calculatedValues.position.OD, second: calculatedValues.position.OS },
            { title: '', first: '', second: '' },
            { title: 'Glint', first: `${calculatedValues.glintsQuantityOD}%`, second: `${calculatedValues.glintsQuantityOS}%` },
            { title: 'Glint noise hor/px', first: `${calculatedValues.averGlintNoiseHorOD}`, second: `${calculatedValues.averGlintNoiseHorOS}` },
            { title: 'Glint noise ver/px', first: `${calculatedValues.averGlintNoiseVerOD}`, second: `${calculatedValues.averGlintNoiseVerOS}` },
            { title: '', first: '', second: '' },
            { title: 'Pupil', first: `${calculatedValues.pupilsQuantityOD}%`, second: `${calculatedValues.pupilsQuantityOS}%` },
            { title: 'Pupil size noise/mm', first: `${calculatedValues.pupilSizeNoiseOD}mm`, second: `${calculatedValues.pupilSizeNoiseOS}m` },
            { title: 'Pupil centre noise hor/px', first: `${calculatedValues.pupilCentreHorNoiseOD}px`, second: `${calculatedValues.pupilCentreHorNoiseOS}px` },
            { title: 'Pupil centre noise ver/px', first: `${calculatedValues.pupilCentreVerNoiseOD}px`, second: `${calculatedValues.pupilCentreVerNoiseOS}px` },
            { title: '', first: '', second: '' },
            { title: 'DPG candidates 90% ile', first: `${calculatedValues.dpgCand90ileOD}`, second: `${calculatedValues.dpgCand90ileOS}` },
            { title: 'DPG candidates 50% ile', first: `${calculatedValues.dpgCand50ileOD}`, second: `${calculatedValues.dpgCand50ileOS}` },
            { title: 'DPG candidates 10% ile', first: `${calculatedValues.dpgCand10ileOD}`, second: `${calculatedValues.dpgCand10ileOS}` },
            { title: '', first: '', second: '' },
            { title: 'BPG candidates 90% ile', first: `${calculatedValues.bpgCand90ileOD}`, second: `${calculatedValues.bpgCand90ileOS}` },
            { title: 'BPG candidates 50% ile', first: `${calculatedValues.bpgCand50ileOD}`, second: `${calculatedValues.bpgCand50ileOS}` },
            { title: 'BPG candidates 10% ile', first: `${calculatedValues.bpgCand10ileOD}`, second: `${calculatedValues.bpgCand10ileOS}` },
            { title: '', first: '', second: '' },
            { title: 'DP contrast 90% ile', first: `${calculatedValues.dpContr90ileOD}`, second: `${calculatedValues.dpContr90ileOS}` },
            { title: 'DP contrast 50% ile', first: `${calculatedValues.dpContr50ileOD}`, second: `${calculatedValues.dpContr50ileOS}` },
            { title: 'DP contrast 10% ile', first: `${calculatedValues.dpContr10ileOD}`, second: `${calculatedValues.dpContr10ileOS}` },
            { title: '', first: '', second: '' },
            { title: 'BP contrast 90% ile', first: `${calculatedValues.bpContr90ileOD}`, second: `${calculatedValues.bpContr90ileOS}` },
            { title: 'BP contrast 50% ile', first: `${calculatedValues.bpContr50ileOD}`, second: `${calculatedValues.bpContr50ileOS}` },
            { title: 'BP contrast 10% ile', first: `${calculatedValues.bpContr10ileOD}`, second: `${calculatedValues.bpContr10ileOS}` },
            { title: '', first: '', second: '' },
            { title: 'Mask Slippage', first: '', second: '' },
            { title: 'Stage 1', first: `${calculatedValues.maskSlippages.stage1.OD}`, second: `${calculatedValues.maskSlippages.stage1.OS}` },
            { title: 'Stage 2', first: `${calculatedValues.maskSlippages.stage2.OD}`, second: `${calculatedValues.maskSlippages.stage2.OS}` },
            { title: 'Stage 3', first: `${calculatedValues.maskSlippages.stage3.OD}`, second: `${calculatedValues.maskSlippages.stage3.OS}` },
            { title: 'Stage 4', first: `${calculatedValues.maskSlippages.stage4.OD}`, second: `${calculatedValues.maskSlippages.stage4.OS}` },
            { title: '', first: '', second: '' },
            { title: 'BPG Size', first: calculatedValues.BPGSize.OD, second: calculatedValues.BPGSize.OS },
            { title: 'DPG Size', first: calculatedValues.DPGSize.OD, second: calculatedValues.DPGSize.OS },
            { title: 'Pixel/deg', first: `${calculatedValues.pixelPerDegOD} px`, second: `${calculatedValues.pixelPerDegOS} px` },
        ];

        feedback
            .selectAll('.feedbackLabelTitle')
            .data(feedbackTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.title}`)
            .attr('transform', (d, i) => `translate(${0}, ${this.padding.top * 4 + i * 25})`);
        feedback
            .selectAll('.feedbackLabelFirst')
            .data(feedbackTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.first}`)
            .attr('transform', (d, i) => `translate(${200}, ${this.padding.top * 4 + i * 25})`);
        feedback
            .selectAll('.feedbackLabelSecond')
            .data(feedbackTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.second}`)
            .attr('transform', (d, i) => `translate(${270}, ${this.padding.top * 4 + i * 25})`);

        this.wrapper.style('height', `${this.padding.top * 6 + this.sideSquaresLength * 4.5 + this.padding.bottom * 7}px`); // + feedback.node().getBBox().height + this.padding.top);
        this.wrapperResults.style('height', `${feedback.node().getBBox().height + this.padding.top}px`);

        const observationsTextInfo: { title: string; first: string }[] = [
            { title: '', first: '' },
            { title: '', first: '' },
            { title: 'Dilated Patient', first: `${calculatedValues.zeroDivadedBy4 < 90 ? 'negative' : 'positive'}, ${calculatedValues.zeroDivadedBy4}%` },
            { title: '', first: '' },
            { title: 'IOL Patient', first: `${calculatedValues.IOL}%` },
            { title: '', first: '' },
            { title: 'Blinking Frequency', first: `${calculatedValues.blinkingFrequency}` },
            { title: '', first: '' },
            {
                title: 'Eye Motility OD',
                first: `${calculatedValues.motility.stage1.OD}% \u00A0 ${calculatedValues.motility.stage2.OD}% \u00A0 ${calculatedValues.motility.stage3.OD}% \u00A0 ${calculatedValues.motility.stage4.OD}%`,
            },
            {
                title: 'Eye Motility OS',
                first: `${calculatedValues.motility.stage1.OS}% \u00A0 ${calculatedValues.motility.stage2.OS}% \u00A0 ${calculatedValues.motility.stage3.OS}% \u00A0 ${calculatedValues.motility.stage4.OS}%`,
            },
            { title: '', first: '' },
            { title: 'Pupil Distance', first: calculatedValues.pupilDistance },
            { title: '', first: '' },
            { title: 'Phoria / Strabism', first: `OD: \u00A0 ${calculatedValues.phoria.OD.zeroToFirst} px \u00A0  OD: \u00A0 ${calculatedValues.phoria.OD.secondToThird} px` },
            { title: '', first: `OS: \u00A0 ${calculatedValues.phoria.OS.zeroToFirst} px \u00A0  OS: \u00A0 ${calculatedValues.phoria.OS.secondToThird} px` },
        ];

        observations
            .append('rect')
            .attr('width', 35)
            .attr('height', 50)
            .attr('fill', '#4f6c68')
            // .attr('stroke', '#4f6c68')
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('x', () => {
                if (this.chosenButton === 1) {
                    return 198;
                }
                if (this.chosenButton === 2) {
                    return 241;
                }
                if (this.chosenButton === 3) {
                    return 285;
                }
                if (this.chosenButton === 4) {
                    return 330;
                }
            })
            .attr('y', this.padding.top * 4 + 7.3 * 25);

        observations
            .selectAll('.observationsLabelTitle')
            .data(observationsTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.title}`)
            .attr('transform', (d, i) => `translate(${0}, ${this.padding.top * 4 + i * 25})`);

        observations
            .selectAll('.observationsLabelFirst')
            .data(observationsTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.first}`)
            .attr('transform', (d, i) => `translate(${200}, ${this.padding.top * 4 + i * 25})`);

        const recommendationsTextInfo: { title: string; first: string }[] = [
            { title: '(under construction)', first: '' },
            { title: '', first: '' },
            { title: 'Pupil Test', first: '' },
            { title: '', first: '' },
            { title: 'Visual Fields OD', first: '' },
            { title: 'Visual Fields OS', first: '' },
            { title: '', first: '' },
            { title: 'ACOLAPT', first: '' },
            { title: '', first: '' },
            { title: 'Pursuits and Saccades', first: '' },
            { title: '', first: '' },
            { title: 'Nystagmus', first: '' },
        ];

        const pupilAverage = (calculatedValues.pupilsQuantityOD + calculatedValues.pupilsQuantityOS) / 2;
        const statusPupilTest = calculatedValues.zeroDivadedBy4 > 90 ? 'red' : pupilAverage < 85 ? 'orange' : 'green';

        const glintsAverage = (calculatedValues.glintsQuantityOD + calculatedValues.glintsQuantityOS) / 2;
        const statusForOtherTest = glintsAverage > 95 ? 'green' : glintsAverage < 80 ? 'red' : 'orange';

        const recommendationsStatus = [
            { status: statusPupilTest, y: this.padding.top * 7 },
            { status: statusForOtherTest, y: this.padding.top * 10.4 },
            { status: statusForOtherTest, y: this.padding.top * 12 },
            { status: statusForOtherTest, y: this.padding.top * 15.4 },
            { status: statusForOtherTest, y: this.padding.top * 18.6 },
            { status: statusForOtherTest, y: this.padding.top * 22 },
        ];

        recommendations
            .selectAll('.recommendationsLabelTitle')
            .data(recommendationsTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.title}`)
            .attr('transform', (d, i) => `translate(${0}, ${this.padding.top * 4 + i * 25})`);
        recommendations
            .selectAll('.recommendationsLabelFirst')
            .data(recommendationsTextInfo)
            .join('text')
            .style('fill', 'white')
            .attr('font-size', '16px')
            .text(d => `${d.first}`)
            .attr('transform', (d, i) => `translate(${300}, ${this.padding.top * 4 + i * 25})`);
        recommendations
            .selectAll('.indicatorsCircles')
            .data(recommendationsStatus)
            .join('circle')
            .attr('cx', 250)
            .attr('cy', d => d.y)
            .attr('r', 10)
            .style('fill', d => d.status);
    }
}
