// @ts-nocheck
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICamMessage } from '../../../../../../../../commonout/interfaces/charts.model';
import { TranslateService } from '../../../../_services/general/translate.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { Diagram, IncomingMessage } from './diagram/Diagram';

@Component({
    selector: 'antisaccade-test-chart',
    template: require('./antisaccade-test-chart.component.html'),
    styles: [require('../charts.scss'), require('./antisaccade-test-chart.component.scss')],
})
export class AntisaccadeTestChartComponent extends BulbicamChartComponent {
    @ViewChild('targetElement') antisaccadeChart: ElementRef;
    diagram?: Diagram;
    setEdits(x: any) {}

    constructor(private translateService: TranslateService) {
        super();
    }

    // I assume inputData is already set...
    ngAfterViewInit() {
        let target = this.antisaccadeChart.nativeElement;

        this.initChart(target);

        // this.addData([])
        if (this.inputData) {
            this.addData(this.inputData);
        } else {
            // this.addData([])
            // this.render()
        }
    }

    initChart(targetElement: HTMLElement) {
        log('initChart');
        let theme = 'any';
        this.diagram = new Diagram({ svgTarget: targetElement, theme });

        // time counting
        // let point: number|null = null
        // let delta: number|null = null
        // this.countTime = _.debounce(() => {
        //   let now = +new Date() / 1000
        //   if (point == null) {
        //     point = now
        //     console.log(`point is now`)
        //   } else if (!delta) {
        //     delta = now - point
        //     console.log(`delta: ${delta}`)
        //   } else {
        //     delta = now - point
        //     console.log(`oops, another delta: ${delta}`)
        //   }
        // }, 1000, { leading: true, trailing: true })
    }

    addData(data: ICamMessage[]) {
        // log('addData1')
        // if (!this.diagram) return
        // log('addData2')
        // log(data)

        this.diagram.addData(data as IncomingMessage[], true);
        this.diagram.refresh();
        // this.refresh();
        // this.countTime()
    }

    // render () {
    //     if (!this.diagram) return;
    //     log('render');
    // }
    clearData() {
        this.diagram.clearState();
    }
}

function log(x: string | object) {
    return;
    if (typeof x === 'string') x = `antisaccade.${x}`;
    console.log(x);
}
