// @ts-nocheck
// import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
// import { Data } from '@angular/router';
import * as d3 from 'd3';
import * as _ from 'lodash';
// import { Scale, Target } from '../../lib/types'
import { Scale } from '../../lib/types';
// import { TranslateService } from '../../../../_services/general/translate.service';
// import { HaploChartComponent, IChartMargin } from '../haploChart.component';
// import { MESSAGE_TYPE } from '../../../../../../../../commonout/interfaces/charts.model';
import { MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';

type Target<T extends d3.BaseType> = d3.Selection<T, unknown, HTMLElement, unknown>;

// I decouple it from the actual Globals
interface SomeParams {
    svg: any;
    // textDark: string;
    // textLight: string;
    // lightBlue: string;
    // theme: string;
}
// I decouple it from the actual TranslateService
interface UsedTranstate {
    instant: (a: string) => string;
}

interface RawPoint {
    x: number;
    y: number;
}
type ChartName = 'npc' | 'recovery';
type AxisType = d3.Axis<number | { valueOf(): number }>;
type ZoomType = d3.ZoomBehavior<Element, unknown>;
interface DataItem {
    message_type: MESSAGE_TYPE;
    ppdistance?: number;
    diameter?: number;
    z: number;
    timestamp: number; //?
    numtest: number;
    target: string;
}
type TrialsPerEye = {
    OD: {
        1: DataItem[];
        2: DataItem[];
        3: DataItem[];
    };
    OS: {
        1: DataItem[];
        2: DataItem[];
        3: DataItem[];
    };
};
type Data = {
    npc: TrialsPerEye;
    recovery: TrialsPerEye;
};
type TimerHandle = number;
let screen = window.screen;

class State {
    currentTestCnt: number = -100;
    data: Data = {
        npc: {
            OD: {
                1: [],
                2: [],
                3: [],
            },
            OS: {
                1: [],
                2: [],
                3: [],
            },
        },
        recovery: {
            OD: {
                1: [],
                2: [],
                3: [],
            },
            OS: {
                1: [],
                2: [],
                3: [],
            },
        },
    };
}
export class Diagram {
    // almost domain of y axis
    private startingPoint = 670;
    private endingPoint = 30;

    state = new State();

    omgtimer1?: TimerHandle;
    omgtimer2?: TimerHandle;
    omgtimer3?: TimerHandle;
    mousedownID?: TimerHandle;

    private margin: { top: number; left: number; right: number; bottom: number };
    private height: number;
    private width: number;
    private halfwidth: number;
    private chart: any; //Target<any>;
    private svgNPC: Target<SVGSVGElement>;
    private svgRecovery: Target<SVGSVGElement>; // NOTE: nested/multiple svgs is not needed likely (for zoom?)
    private g: Target<SVGGElement>;
    private gNPC: Target<SVGGElement>;
    private gRecovery: Target<SVGGElement>;
    private gNPCY: Target<SVGGElement>;
    private gNPCYFull: Target<SVGGElement>;
    private gRecoveryY: Target<SVGGElement>;
    private gRecoveryYFull: Target<SVGGElement>;
    private lineViewNPC: Target<SVGGElement>;
    private lineViewRecovery: Target<SVGGElement>;
    private x: Scale;
    private y: Scale;
    private yAxis: AxisType;
    private yAxisFull: AxisType;
    private zoomNPC: ZoomType;
    private zoomRecovery: ZoomType;
    private distanceLine: d3.Line<DataItem>;
    private diameterLine: d3.Line<DataItem>;
    private currentChart: ChartName = 'npc'; // incoming data should set it
    private colorsKlasses = ['Orange', 'Green2', 'Blue1'];
    // private colors = ['rgba(14, 168, 143, 0.800)', 'rgba(244, 248, 18, 0.800)', 'rgba(236, 46, 78, 0.800)'];
    // private highlightLineColors = ['rgba(14, 168, 143, 0.250)', 'rgba(244, 248, 18, 0.250)', 'rgba(236, 46, 78, 0.250)'];

    private bestNPC = 0;
    private bestRecovery = 0;

    private theme: string;
    // private textColor: string;

    constructor(private params: SomeParams, private translateService: UsedTranstate, private modalChart: boolean) {
        // this.theme = globals.theme;
        this.theme = 'any';
        this.zoomedNPC = this.zoomedNPC.bind(this);
        this.zoomedRecovery = this.zoomedRecovery.bind(this);
        // this.textColor = this.theme === 'dark' ? this.globals.textDark : this.globals.textLight;
        // this.createChart()
        // }

        // createChart() {
        /*
            Drawing the skeleton of the chart, lines, legend and buttons
        */
        this.chart = d3
            .select(this.params.svg || '#vergenceChart')
            .attr('viewBox', '0 0 ' + screen.width + ' ' + (screen.height * 5) / 6)
            .attr('preserveAspectRatio', 'xMinYMin meet');

        this.g = this.chart.append('g');

        this.margin = {
            top: screen.height * (10 / 100),
            right: screen.width * (5 / 100),
            bottom: 0,
            left: screen.width * (5 / 100),
        };
        this.width = screen.width - this.margin.left - this.margin.right;
        this.height = screen.height * (60 / 100) - this.margin.top;

        this.halfwidth = this.width / 2 - this.margin.left;

        // Range of data which will be represented on the chart
        this.x = d3
            .scaleLinear()
            .domain([-40, 40])
            .range([0, this.halfwidth]);

        this.y = d3
            .scaleLinear()
            .domain([this.endingPoint - 20, this.startingPoint + 20])
            .range([0, this.height]);

        // Preparing the values for the X and Y axis
        this.yAxis = d3
            .axisRight(this.y)
            .tickSize(this.halfwidth)
            .tickPadding(-30 - this.halfwidth);

        this.yAxisFull = d3
            .axisRight(this.y)
            .tickSize(this.halfwidth)
            .tickPadding(-30 - this.halfwidth);

        // Pupil distance legend
        this.chart
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth + ', 20)')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '16')
            .text(this.translateService.instant('Trials vergence') + ': ');

        this.chart
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth + 80) + ', 20)')
            .style('text-anchor', 'middle')
            .style('font-size', '14')
            .text('1st');

        this.chart
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth + 225) + ', 20)')
            .style('text-anchor', 'middle')
            .style('font-size', '14')
            .text('2nd');

        this.chart
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth + 380) + ', 20)')
            .style('text-anchor', 'middle')
            .style('font-size', '14')
            .text('3rd');

        // Drawing the full lines of the legend
        this.chart
            .append('line')
            .attr('class', `s${this.colorsKlasses[0]}`)
            // .style('stroke', this.colors[0])
            .attr('x1', this.halfwidth + 100)
            .attr('y1', 20)
            .attr('x2', this.halfwidth + 155)
            .attr('y2', 20)
            .attr('stroke-width', 2);

        this.chart
            .append('line')
            .attr('class', `s${this.colorsKlasses[1]}`)
            // .style('stroke', this.colors[1])
            .attr('x1', this.halfwidth + 245)
            .attr('y1', 20)
            .attr('x2', this.halfwidth + 300)
            .attr('y2', 20)
            .attr('stroke-width', 2);

        this.chart
            .append('line')
            .attr('class', `s${this.colorsKlasses[2]}`)
            // .style('stroke', this.colors[2])
            .attr('x1', this.halfwidth + 400)
            .attr('y1', 20)
            .attr('x2', this.halfwidth + 455)
            .attr('y2', 20)
            .attr('stroke-width', 2);

        // Pupil diameter legend
        this.chart
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth + ', 40)')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '16')
            .text(this.translateService.instant('Trials pupils') + ': ');

        this.chart
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth + 80) + ', 40)')
            .style('text-anchor', 'middle')
            .style('font-size', '14')
            .text('1st');

        this.chart
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth + 225) + ', 40)')
            .style('text-anchor', 'middle')
            .style('font-size', '14')
            .text('2nd');

        this.chart
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth + 380) + ', 40)')
            .style('text-anchor', 'middle')
            .style('font-size', '14')
            .text('3rd');

        // Drawing the dashed lines of the legend
        this.chart
            .append('line')
            .attr('class', `s${this.colorsKlasses[0]}`)
            // .style('stroke', this.colors[0])
            .attr('x1', this.halfwidth + 100)
            .attr('y1', 40)
            .attr('x2', this.halfwidth + 155)
            .attr('y2', 40)
            .attr('stroke-width', 2)
            .style('stroke-dasharray', '2 2');

        this.chart
            .append('line')
            .attr('class', `s${this.colorsKlasses[1]}`)
            // .style('stroke', this.colors[1])
            .attr('x1', this.halfwidth + 245)
            .attr('y1', 40)
            .attr('x2', this.halfwidth + 300)
            .attr('y2', 40)
            .attr('stroke-width', 2)
            .style('stroke-dasharray', '2 2');

        this.chart
            .append('line')
            .attr('class', `s${this.colorsKlasses[2]}`)
            // .style('stroke', this.colors[2])
            .attr('x1', this.halfwidth + 400)
            .attr('y1', 40)
            .attr('x2', this.halfwidth + 455)
            .attr('y2', 40)
            .attr('stroke-width', 2)
            .style('stroke-dasharray', '2 2');

        // Preparing the chart lines
        this.distanceLine = d3
            .line<DataItem>()
            .defined(d => {
                if (!d.ppdistance) return false;
                if (isNaN(d.z)) return false;
                return true;
            })
            .x(d => {
                return this.x(d.ppdistance || 0);
            })
            .y(d => {
                return this.y(d.z);
            });

        this.diameterLine = d3
            .line<DataItem>()
            .defined(d => {
                if (!d.diameter) return false;
                if (isNaN(d.z)) return false;
                return true;
            })
            .x(d => {
                return this.x(d.diameter || 0);
            })
            .y(d => {
                return this.y(d.z);
            });

        /*
            NPC
        */

        // Positioning the chart
        this.svgNPC = this.g.append('svg');

        this.gNPC = this.svgNPC.append('g').attr('transform', 'translate(' + (this.margin.left + 40) + ',' + (this.margin.top + 20) + ')');

        this.gNPCY = this.gNPC
            .append('g')
            .attr('class', 'axis axis--y')
            .call(this.yAxis);

        this.gNPCYFull = this.gNPC
            .append('g')
            .attr('class', 'axis yNPCFull')
            .call(this.yAxisFull);

        this.gNPC
            .append('line')
            .attr('x1', this.halfwidth / 2)
            .attr('x2', this.halfwidth / 2)
            .attr('y1', 0)
            .attr('y2', this.height)
            .style('stroke', 'rgba(11, 189, 233, 0.4)')
            .style('stroke-width', '1');

        // Adding labels for the axes
        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 2 + ' ,' + (this.height + 35) + ')')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text(this.translateService.instant('Pupil diameter'));

        this.gNPC
            .append('text')
            .attr('y', -30)
            .attr('x', -15)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text(this.translateService.instant('Target distance'));

        this.gNPC
            .append('text')
            .attr('y', -15)
            .attr('x', -15)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text('(mm)');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 2 + ' ,' + -this.margin.top / 3 + ')')
            .style('text-anchor', 'middle')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '1.5vw')
            .text(this.translateService.instant('NPC'));

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth * 3) / 4 + ' , -25)')
            .style('text-anchor', 'middle')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '1vw')
            .text(this.translateService.instant('OD'));

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' , -25)')
            .style('text-anchor', 'middle')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '1vw')
            .text(this.translateService.instant('OS'));

        this.gNPC
            .append('rect')
            .attr('width', this.halfwidth)
            .attr('height', this.height)
            .style('fill', 'rgba(0,0,0,0)');

        // Minimum distances NPC
        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('NPC minimum') + ': ');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gNPC
            .append('text')
            .attr('id', 'firstNPCMin')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'secondNPCMin')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'thirdNPCMin')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // NPC start point average OD
        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter start point avg. OD') + ': ');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gNPC
            .append('text')
            .attr('id', 'firstNPCStartAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'secondNPCStartAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'thirdNPCStartAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // NPC start point average OS
        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter start point avg. OS') + ': ');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gNPC
            .append('text')
            .attr('id', 'firstNPCStartAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'secondNPCStartAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'thirdNPCStartAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // NPC start point average OD
        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter break point avg. OD') + ': ');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gNPC
            .append('text')
            .attr('id', 'firstNPCBreakAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'secondNPCBreakAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'thirdNPCBreakAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // NPC break point average OS
        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter break point avg. OS') + ': ');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gNPC
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gNPC
            .append('text')
            .attr('id', 'firstNPCBreakAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'secondNPCBreakAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gNPC
            .append('text')
            .attr('id', 'thirdNPCBreakAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.lineViewNPC = this.gNPC
            .append('svg')
            .attr('width', this.halfwidth)
            .attr('height', this.height)
            .append('g');

        // Zoom buttons and setup
        this.zoomNPC = d3
            .zoom()
            .scaleExtent([1, 40])
            .translateExtent([
                [0, 0],
                [this.width, this.height],
            ])
            .extent([
                [0, 0],
                [this.width, this.height],
            ])
            .on('zoom', this.zoomedNPC);

        this.gNPC
            .append('text')
            .attr('id', 'zoomNPCPercent')
            .attr('transform', 'translate(' + (this.halfwidth - 110) + ' , -25)')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text('100%');

        this.gNPC
            .append('g')
            .classed('svgButton', true)
            .attr('transform', 'translate(' + (this.halfwidth - 85) + ' , -48) scale(0.35, 0.35)')
            .append('g')
            .style('stroke-width', '4')
            .style('fill', 'rgba(0,0,0,0)')
            .append('path')
            .attr('d', 'M 25 50 L 75 50 M 50 25 M 50 5 A 45 45 0 0 0 50 95 A 45 45 0 1 0 5 50')
            .on('click', () => {
                this.zoomOutNPC();
            })
            .on('mousedown', () => {
                this.mousedown(this.zoomOutNPC);
            })
            .on('mouseup', () => {
                this.mouseup();
            })
            .on('mouseout', () => {
                this.mouseup();
            });

        this.gNPC
            .append('g')
            .classed('svgButton', true)
            .attr('transform', 'translate(' + (this.halfwidth - 35) + ' , -48) scale(0.35, 0.35)')
            .append('g')
            .style('stroke-width', '4')
            .style('fill', 'rgba(0,0,0,0)')
            .append('path')
            .attr('d', 'M 25 50 L 75 50 M 50 25 L 50 75 M 50 5 A 45 45 0 0 0 50 95 A 45 45 0 1 0 5 50')
            .on('click', () => {
                this.zoomInNPC();
            })
            .on('mousedown', () => {
                this.mousedown(this.zoomInNPC);
            })
            .on('mouseup', () => {
                this.mouseup();
            })
            .on('mouseout', () => {
                this.mouseup();
            });

        if (this.modalChart) {
            this.svgNPC.call(this.zoomNPC as any).on('dblclick.zoom', null);
        } else {
            this.svgNPC
                .call(this.zoomNPC as any)
                .on('dblclick.zoom', null)
                .on('wheel.zoom', null);
        }

        /*
            Recovery
        */

        // Positioning the chart
        this.svgRecovery = this.g.append('svg');

        this.gRecovery = this.svgRecovery
            .append('g')
            .attr('transform', 'translate(' + (this.halfwidth + this.margin.left + this.margin.right + 60) + ',' + (this.margin.top + 20) + ')');

        this.gRecoveryY = this.gRecovery
            .append('g')
            .attr('class', 'axis axis--y')
            .call(this.yAxis);

        this.gRecoveryYFull = this.gRecovery
            .append('g')
            .attr('class', 'axis yRecoveryFull')
            .call(this.yAxisFull);

        this.gRecovery
            .append('line')
            .attr('x1', this.halfwidth / 2)
            .attr('x2', this.halfwidth / 2)
            .attr('y1', 0)
            .attr('y2', this.height)
            .style('stroke', 'rgba(11, 189, 233, 0.4)')
            .style('stroke-width', '1');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 2 + ' ,' + (this.height + 35) + ')')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text(this.translateService.instant('Pupil diameter'));

        this.gRecovery
            .append('text')
            .attr('y', -30)
            .attr('x', -15)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text(this.translateService.instant('Target distance'));

        this.gRecovery
            .append('text')
            .attr('y', -20)
            .attr('x', -15)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text('(mm)');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 2 + ' ,' + -this.margin.top / 3 + ')')
            .style('text-anchor', 'middle')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '1.5vw')
            .text(this.translateService.instant('recovery'));

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth * 3) / 4 + ' , -25)')
            .style('text-anchor', 'middle')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '1vw')
            .text(this.translateService.instant('OD'));

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' , -25)')
            .style('text-anchor', 'middle')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '1vw')
            .text(this.translateService.instant('OS'));

        this.gRecovery
            .append('rect')
            .attr('width', this.halfwidth)
            .attr('height', this.height)
            .style('fill', 'rgba(0,0,0,0)');

        // Minimum distances Recovery
        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Recovery minimum') + ': ');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gRecovery
            .append('text')
            .attr('id', 'firstRecoveryMin')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'secondRecoveryMin')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'thirdRecoveryMin')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 65) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // Recovery start point average OD
        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter start point avg. OD') + ': ');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gRecovery
            .append('text')
            .attr('id', 'firstRecoveryStartAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'secondRecoveryStartAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'thirdRecoveryStartAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 85) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // Recovery start point average OS
        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter start point avg. OS') + ': ');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gRecovery
            .append('text')
            .attr('id', 'firstRecoveryStartAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'secondRecoveryStartAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'thirdRecoveryStartAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 105) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // Recovery start point average OD
        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter break point avg. OD') + ': ');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gRecovery
            .append('text')
            .attr('id', 'firstRecoveryBreakAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'secondRecoveryBreakAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'thirdRecoveryBreakAvgOD')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 125) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        // Recovery break point average OS
        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + this.halfwidth / 8 + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'end')
            // .style('fill', this.globals.lightBlue)
            .style('font-size', '14')
            .text(this.translateService.instant('Pupil diameter break point avg. OS') + ': ');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 80) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('1st');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 225) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('2nd');

        this.gRecovery
            .append('text')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 380) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '12')
            .text('3rd');

        this.gRecovery
            .append('text')
            .attr('id', 'firstRecoveryBreakAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 130) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'secondRecoveryBreakAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 275) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.gRecovery
            .append('text')
            .attr('id', 'thirdRecoveryBreakAvgOS')
            .attr('transform', 'translate(' + (this.halfwidth / 8 + 430) + ' ,' + (this.height + 145) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', '13')
            .attr('class', 'stateful-text')
            .text('');

        this.lineViewRecovery = this.gRecovery
            .append('svg')
            .attr('width', this.halfwidth)
            .attr('height', this.height)
            .append('g');

        // Recovery zoom
        this.zoomRecovery = d3
            .zoom()
            .scaleExtent([1, 40])
            .translateExtent([
                [0, 0],
                [this.width, this.height],
            ])
            .extent([
                [0, 0],
                [this.width, this.height],
            ])
            .on('zoom', this.zoomedRecovery);

        this.gRecovery
            .append('text')
            .attr('id', 'zoomRecoveryPercent')
            .attr('transform', 'translate(' + (this.halfwidth - 110) + ' , -25)')
            .style('text-anchor', 'middle')
            // .style('fill', this.textColor)
            .text('100%');

        this.gRecovery
            .append('g')
            .classed('svgButton', true)
            .attr('transform', 'translate(' + (this.halfwidth - 85) + ' , -48) scale(0.35, 0.35)')
            .append('g')
            .style('stroke-width', '4')
            .style('fill', 'rgba(0,0,0,0)')
            .append('path')
            .attr('d', 'M 25 50 L 75 50 M 50 25 M 50 5 A 45 45 0 0 0 50 95 A 45 45 0 1 0 5 50')
            .on('click', () => {
                this.zoomOutRecovery();
            })
            .on('mousedown', () => {
                this.mousedown(this.zoomOutRecovery);
            })
            .on('mouseup', () => {
                this.mouseup();
            })
            .on('mouseout', () => {
                this.mouseup();
            });

        this.gRecovery
            .append('g')
            .classed('svgButton', true)
            .attr('transform', 'translate(' + (this.halfwidth - 35) + ' , -48) scale(0.35, 0.35)')
            .append('g')
            .style('stroke-width', '4')
            .style('fill', 'rgba(0,0,0,0)')
            .append('path')
            .attr('d', 'M 25 50 L 75 50 M 50 25 L 50 75 M 50 5 A 45 45 0 0 0 50 95 A 45 45 0 1 0 5 50')
            .on('click', () => {
                this.zoomInRecovery();
            })
            .on('mousedown', () => {
                this.mousedown(this.zoomInRecovery);
            })
            .on('mouseup', () => {
                this.mouseup();
            })
            .on('mouseout', () => {
                this.mouseup();
            });

        if (this.modalChart) {
            this.svgRecovery.call(this.zoomRecovery as any).on('dblclick.zoom', null);
        } else {
            this.svgRecovery
                .call(this.zoomRecovery as any)
                .on('dblclick.zoom', null)
                .on('wheel.zoom', null);
        }

        d3.selectAll('.axis--y').style('stroke-dasharray', '2 ' + (this.halfwidth / 10 - 2));
    }

    // Helper functions that control zooming
    zoomedNPC() {
        const t = d3.event.transform;
        this.gNPC.select('#zoomNPCPercent').text(Math.floor(t.k * 100) + '%');

        this.lineViewNPC.attr('transform', 'scale(1,' + t.k + ') translate(0,' + t.y / t.k + ')');
        this.lineViewNPC.selectAll('.path').style('stroke-width', 1 / t.k);
        this.lineViewNPC.select('text').attr('transform', 'scale(1, ' + 1 / t.k + ') translate(' + (this.halfwidth - 40) + ', ' + this.y(this.bestNPC) * t.k + ')');

        this.gNPCYFull.call(this.yAxisFull.scale(t.rescaleY(this.y)));
        this.gNPCY.call(this.yAxis.scale(t.rescaleY(this.y)));
    }

    zoomedRecovery() {
        const t = d3.event.transform;
        this.gRecovery.select('#zoomRecoveryPercent').text(Math.floor(t.k * 100) + '%');

        this.lineViewRecovery.attr('transform', 'scale(1,' + t.k + ') translate(0,' + t.y / t.k + ')');
        this.lineViewRecovery.selectAll('.path').style('stroke-width', 1 / t.k);
        this.lineViewRecovery.select('text').attr('transform', 'scale(1, ' + 1 / t.k + ') translate(40, ' + this.y(this.bestRecovery) * t.k + ')');
        this.gRecoveryYFull.call(this.yAxisFull.scale(t.rescaleY(this.y)));
        this.gRecoveryY.call(this.yAxis.scale(t.rescaleY(this.y)));
    }

    zoomInNPC(delta = 0.1) {
        let node = this.svgNPC.node();
        if (!node) return;
        const currentScaleNPC = d3.zoomTransform(node);
        const nextScale = currentScaleNPC.k + delta;
        this.zoomNPC.scaleTo(this.svgNPC as any, nextScale);
    }

    zoomOutNPC() {
        this.zoomInNPC(-0.1);
    }

    zoomInRecovery(delta = 0.1) {
        let node = this.svgNPC.node();
        if (!node) return;
        const currentScale = d3.zoomTransform(node);
        const nextScale = currentScale.k + delta;
        this.zoomRecovery.scaleTo(this.svgRecovery as any, nextScale);
    }

    zoomOutRecovery() {
        this.zoomInRecovery(-0.1);
    }

    mousedown(action: () => void) {
        d3.event.stopPropagation();

        if (this.mousedownID == null) {
            this.mousedownID = setInterval(action.bind(this), 100);
        }
    }

    mouseup() {
        if (this.mousedownID != null) {
            clearInterval(this.mousedownID);
            this.mousedownID = undefined;
        }
    }

    // Helper function for animating dashed lines
    makeBorder(totalLength: number, pattern: string) {
        const patternLength = pattern
            .split(/[\s,]/)
            .map(a => {
                return parseFloat(a) || 0;
            })
            .reduce((a: number, b: number) => {
                return a + b;
            });

        const newBorder = new Array(Math.ceil(totalLength / patternLength)).join(pattern + ' ');

        return newBorder + ' 0, ' + totalLength;
    }

    addNPCData(OD: DataItem[], OS: DataItem[], trialNum: number) {
        const ODDistancePath = this.lineViewNPC
            .append('path')
            .datum(OD)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.distanceLine);

        const OSDistancePath = this.lineViewNPC
            .append('path')
            .datum(OS)
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.distanceLine);

        const ODDiameterPath = this.lineViewNPC
            .append('path')
            .datum(OD)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-dasharray', '1 5')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.diameterLine);

        const OSDiameterPath = this.lineViewNPC
            .append('path')
            .datum(OS)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-dasharray', '1 5')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.diameterLine);

        const totalLengthODDistancePath = getLength(ODDistancePath);
        if (totalLengthODDistancePath) {
            ODDistancePath.attr('stroke-dasharray', totalLengthODDistancePath + ' ' + totalLengthODDistancePath)
                .attr('stroke-dashoffset', totalLengthODDistancePath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }

        const totalLengthOSDistancePath = getLength(OSDistancePath);
        if (totalLengthOSDistancePath) {
            OSDistancePath.attr('stroke-dasharray', totalLengthOSDistancePath + ' ' + totalLengthOSDistancePath)
                .attr('stroke-dashoffset', totalLengthOSDistancePath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }

        const totalLengthODDiameterPath = getLength(ODDiameterPath);
        if (totalLengthODDiameterPath) {
            ODDiameterPath.attr('stroke-dasharray', this.makeBorder(totalLengthODDiameterPath, '1,5'))
                .attr('stroke-dashoffset', totalLengthODDiameterPath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }

        const totalLengthOSDiameterPath = getLength(OSDiameterPath);
        if (totalLengthOSDiameterPath) {
            OSDiameterPath.attr('stroke-dasharray', this.makeBorder(totalLengthOSDiameterPath, '1,5'))
                .attr('stroke-dashoffset', totalLengthOSDiameterPath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }
    }

    addRecoveryData(OD: DataItem[], OS: DataItem[], trialNum: number) {
        const ODDistancePath = this.lineViewRecovery
            .append('path')
            .datum(OD)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.distanceLine);

        const OSDistancePath = this.lineViewRecovery
            .append('path')
            .datum(OS)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.distanceLine);

        const ODDiameterPath = this.lineViewRecovery
            .append('path')
            .datum(OD)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-dasharray', '1 5')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.diameterLine);

        const OSDiameterPath = this.lineViewRecovery
            .append('path')
            .datum(OS)
            .attr('class', 'path')
            .attr('fill', 'none')
            // .attr('stroke', this.colors[trialNum])
            .attr('class', `s${this.colorsKlasses[trialNum]}`)
            .attr('stroke-dasharray', '1 5')
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('stroke-width', 1)
            .attr('d', this.diameterLine);

        // Making the animations
        const totalLengthODDistancePath = getLength(ODDistancePath);
        if (totalLengthODDistancePath) {
            ODDistancePath.attr('stroke-dasharray', totalLengthODDistancePath + ' ' + totalLengthODDistancePath)
                .attr('stroke-dashoffset', totalLengthODDistancePath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }

        const totalLengthOSDistancePath = getLength(OSDistancePath);
        if (totalLengthOSDistancePath) {
            OSDistancePath.attr('stroke-dasharray', totalLengthOSDistancePath + ' ' + totalLengthOSDistancePath)
                .attr('stroke-dashoffset', totalLengthOSDistancePath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }

        const totalLengthODDiameterPath = getLength(ODDiameterPath);
        if (totalLengthODDiameterPath) {
            ODDiameterPath.attr('stroke-dasharray', this.makeBorder(totalLengthODDiameterPath, '1,5'))
                .attr('stroke-dashoffset', totalLengthODDiameterPath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }

        const totalLengthOSDiameterPath = getLength(OSDiameterPath);
        if (totalLengthOSDiameterPath) {
            OSDiameterPath.attr('stroke-dasharray', this.makeBorder(totalLengthOSDiameterPath, '1,5'))
                .attr('stroke-dashoffset', totalLengthOSDiameterPath)
                .transition()
                .duration(1000)
                .ease(d3.easeLinear)
                .attr('stroke-dashoffset', 0);
        }
    }

    drawHighlightedLines(chart: ChartName, trialNum: number, y: number) {
        const lineFunction = d3
            .line<RawPoint>()
            .x(d => {
                return this.x(d.x);
            })
            .y(d => {
                return this.y(d.y);
            });

        const lineData: RawPoint[] = [
            { x: -40, y },
            { x: 40, y },
        ];

        if (chart === 'npc') {
            this.lineViewNPC
                .append('path')
                .datum(lineData)
                .attr('fill', 'none')
                // .attr('stroke', this.highlightLineColors[trialNum - 1])
                .attr('class', `s${this.colorsKlasses[trialNum - 1]}`)
                .attr('stroke-linejoin', 'round')
                .attr('stroke-linecap', 'round')
                .attr('stroke-width', 2)
                .attr('d', lineFunction);
        } else {
            this.lineViewRecovery
                .append('path')
                .datum(lineData)
                .attr('fill', 'none')
                // .attr('stroke', this.highlightLineColors[trialNum - 1])
                .attr('class', `s${this.colorsKlasses[trialNum - 1]}`)
                .attr('stroke-linejoin', 'round')
                .attr('stroke-linecap', 'round')
                .attr('stroke-width', 2)
                .attr('d', lineFunction);
        }
    }

    drawBestDistanceNumber(chart: ChartName, trialNum: number, bestValue: number) {
        if (chart === 'npc') {
            this.bestNPC = bestValue;
            this.lineViewNPC
                .append('text')
                .attr('transform', 'translate(' + (this.halfwidth - 40) + ' ,' + this.y(bestValue) + ')')
                .style('text-anchor', 'middle')
                .style('alignment-baseline', 'middle')
                // .style('fill', this.colors[trialNum - 1])
                .attr('class', `f${this.colorsKlasses[trialNum - 1]}`)
                .style('font-size', '17')
                .text(bestValue.toFixed(2));
        } else {
            this.bestRecovery = bestValue;
            this.lineViewRecovery
                .append('text')
                .attr('transform', 'translate(40,' + this.y(bestValue) + ')')
                .style('text-anchor', 'middle')
                .style('alignment-baseline', 'middle')
                // .style('fill', this.colors[trialNum - 1])
                .attr('class', `f${this.colorsKlasses[trialNum - 1]}`)
                .style('font-size', '17')
                .text(bestValue.toFixed(2));
        }
    }

    // Helper function for finding minimum distance between same index elements of two arrays
    findMinimumDistance(data: Data, chart: string, trial: number): { index: number; distance: number } {
        const OD = data[chart]['OD'][trial].slice();
        const OS = data[chart]['OS'][trial].slice();
        const minimumArray: number[] = [];
        let minimumDistanceIndex;

        for (let i = 0; i < OD.length; i++) {
            if (OD[i] && OD[i].ppdistance && !Number.isNaN(OD[i].ppdistance) && OS[i] && OS[i].ppdistance && !Number.isNaN(OS[i].ppdistance)) {
                minimumArray.push(Number(OD[i].ppdistance) - Number(OS[i].ppdistance));
            }
        }

        if (minimumArray.length === 0) {
            return { index: 0, distance: 0 };
        }

        minimumDistanceIndex = minimumArray.reduce((iMin, x, i, arr) => {
            return x < arr[iMin] ? i : iMin;
        }, 0);

        return { index: minimumDistanceIndex, distance: OD[minimumDistanceIndex] ? OD[minimumDistanceIndex].z : 0 };
    }

    calculateAveragePupilDistance(data: Data, chart: string, eye: string, trial: number, type: string, startingIndex: number): string {
        const selectedData: DataItem[] = data[chart][eye][trial].slice();

        if (!selectedData[startingIndex]) {
            return 'N/A';
        }

        const startingTime = type === 'break' ? selectedData[startingIndex].timestamp / 10000 : selectedData[startingIndex].timestamp / 10000 - 100;
        let sum = 0;
        let numOfPoints = 0;

        selectedData.forEach(point => {
            if (type === 'break') {
                if (point.timestamp / 10000 >= startingTime - 100 && point.timestamp / 10000 <= startingTime + 100) {
                    if (point.diameter) {
                        sum += point.diameter;
                        numOfPoints++;
                    }
                }
            } else {
                if (point.timestamp / 10000 >= startingTime && point.timestamp / 10000 <= startingTime + 200) {
                    if (point.diameter) {
                        sum += point.diameter;
                        numOfPoints++;
                    }
                }
            }
        });

        let result = eye === 'OD' ? sum / numOfPoints + 1.5 : (-1 * sum) / numOfPoints + 1.5;
        return result ? result.toFixed(2) : '-';
    }

    addData(frames: DataItem[]) {
        let OS: DataItem[] = [];
        let OD: DataItem[] = [];

        const length = frames.length;
        let tempFrame: DataItem;

        for (let i = 0; i < length; i++) {
            tempFrame = { ...frames[i] };
            if (tempFrame.ppdistance === 0) tempFrame.ppdistance = undefined;

            if (tempFrame.message_type === MESSAGE_TYPE.START_TEST) {
            } else if (tempFrame.message_type === MESSAGE_TYPE.START_CONVERGENCE_NPC_DATA_TRANSMISSION) {
                this.state.currentTestCnt = tempFrame.numtest;
                this.currentChart = 'npc';
            } else if (tempFrame.message_type === MESSAGE_TYPE.START_CONVERGENCE_RECOVERY_DATA_TRANSMISSION) {
                this.state.currentTestCnt = tempFrame.numtest;
                this.currentChart = 'recovery';
            } else if (tempFrame.message_type === MESSAGE_TYPE.DATA_PACKAGE) {
                if (_.get(this.state.data, [this.currentChart, tempFrame.target, this.state.currentTestCnt + 1])) {
                    this.state.data[this.currentChart][tempFrame.target][this.state.currentTestCnt + 1].push(tempFrame);

                    if (tempFrame.target === 'OD') {
                        if (tempFrame.z === this.startingPoint && OD.length > 0 && OD[OD.length - 1].z === this.startingPoint) {
                            OD[OD.length - 1] = tempFrame;
                        } else {
                            OD.push(tempFrame);
                        }
                    } else {
                        if (tempFrame.diameter) {
                            tempFrame.diameter = tempFrame.diameter * -1;
                        }
                        if (tempFrame.ppdistance) {
                            tempFrame.ppdistance = tempFrame.ppdistance * -1;
                        }

                        if (tempFrame.z === this.startingPoint && OS.length > 0 && OS[OS.length - 1].z === this.startingPoint) {
                            OS[OS.length - 1] = tempFrame;
                        } else {
                            OS.push(tempFrame);
                        }
                    }
                }
            } else if (tempFrame.message_type === MESSAGE_TYPE.STOP_CONVERGENCE_NPC_DATA_TRANSMISSION) {
                this.addNPCData(OD, OS, this.state.currentTestCnt);
                OD = [];
                OS = [];
            } else if (tempFrame.message_type === MESSAGE_TYPE.STOP_CONVERGENCE_RECOVERY_DATA_TRANSMISSION) {
                this.addRecoveryData(OD, OS, this.state.currentTestCnt);
                OD = [];
                OS = [];
            } else {
                /*****************
                 NPC
                 *****************/
                let bestNPCDistance = Number.MAX_SAFE_INTEGER;
                let bestNPCTrial = 1;

                // First trial
                const minimumNPCFirstTrial = this.findMinimumDistance(this.state.data, 'npc', 1);
                d3.select('#firstNPCMin').text(minimumNPCFirstTrial.distance.toFixed(2));
                this.drawHighlightedLines('npc', 1, minimumNPCFirstTrial.distance);
                this.omgtimer1 = setTimeout(() => {
                    d3.select('#firstNPCStartAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OD', 1, 'start', 0));
                    d3.select('#firstNPCStartAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OS', 1, 'start', 0));
                    d3.select('#firstNPCBreakAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OD', 1, 'break', minimumNPCFirstTrial.index));
                    d3.select('#firstNPCBreakAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OS', 1, 'break', minimumNPCFirstTrial.index));
                }, 1000);

                if (bestNPCDistance > minimumNPCFirstTrial.distance) {
                    bestNPCDistance = minimumNPCFirstTrial.distance;
                    bestNPCTrial = 1;
                }

                // Second trial
                const minimumNPCSecondTrial = this.findMinimumDistance(this.state.data, 'npc', 2);
                d3.select('#secondNPCMin').text(minimumNPCSecondTrial.distance.toFixed(2));
                this.drawHighlightedLines('npc', 2, minimumNPCSecondTrial.distance);

                this.omgtimer2 = setTimeout(() => {
                    d3.select('#secondNPCStartAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OD', 2, 'start', 0));
                    d3.select('#secondNPCStartAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OS', 2, 'start', 0));
                    d3.select('#secondNPCBreakAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OD', 2, 'break', minimumNPCSecondTrial.index));
                    d3.select('#secondNPCBreakAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OS', 2, 'break', minimumNPCSecondTrial.index));
                }, 1000);

                if (bestNPCDistance > minimumNPCSecondTrial.distance) {
                    bestNPCDistance = minimumNPCSecondTrial.distance;
                    bestNPCTrial = 2;
                }

                // Third trial
                const minimumNPCThirdTrial = this.findMinimumDistance(this.state.data, 'npc', 3);
                d3.select('#thirdNPCMin').text(minimumNPCThirdTrial.distance.toFixed(2));
                this.drawHighlightedLines('npc', 3, minimumNPCThirdTrial.distance);

                this.omgtimer3 = setTimeout(() => {
                    d3.select('#thirdNPCStartAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OD', 3, 'start', 0));
                    d3.select('#thirdNPCStartAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OS', 3, 'start', 0));
                    d3.select('#thirdNPCBreakAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OD', 3, 'break', minimumNPCThirdTrial.index));
                    d3.select('#thirdNPCBreakAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'npc', 'OS', 3, 'break', minimumNPCThirdTrial.index));
                }, 1000);

                if (bestNPCDistance > minimumNPCThirdTrial.distance) {
                    bestNPCDistance = minimumNPCThirdTrial.distance;
                    bestNPCTrial = 3;
                }

                this.drawBestDistanceNumber('npc', bestNPCTrial, bestNPCDistance);

                /*****************
                 RECOVERY
                 *****************/
                let bestRecoveryDistance = Number.MAX_SAFE_INTEGER;
                let bestRecoveryTrial = 1;

                // First trial
                const minimumRecoveryFirstTrial = this.findMinimumDistance(this.state.data, 'recovery', 1);
                d3.select('#firstRecoveryMin').text(minimumRecoveryFirstTrial.distance.toFixed(2));
                this.drawHighlightedLines('recovery', 1, minimumRecoveryFirstTrial.distance);
                d3.select('#firstRecoveryStartAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OD', 1, 'start', 0));
                d3.select('#firstRecoveryStartAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OS', 1, 'start', 0));
                d3.select('#firstRecoveryBreakAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OD', 1, 'break', minimumRecoveryFirstTrial.index));
                d3.select('#firstRecoveryBreakAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OS', 1, 'break', minimumRecoveryFirstTrial.index));

                if (bestRecoveryDistance > minimumRecoveryFirstTrial.distance) {
                    bestRecoveryDistance = minimumRecoveryFirstTrial.distance;
                    bestRecoveryTrial = 1;
                }

                // Second trial
                const minimumRecoverySecondTrial = this.findMinimumDistance(this.state.data, 'recovery', 2);
                d3.select('#secondRecoveryMin').text(minimumRecoverySecondTrial.distance.toFixed(2));
                this.drawHighlightedLines('recovery', 2, minimumRecoverySecondTrial.distance);
                d3.select('#secondRecoveryStartAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OD', 2, 'start', 0));
                d3.select('#secondRecoveryStartAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OS', 2, 'start', 0));
                d3.select('#secondRecoveryBreakAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OD', 2, 'break', minimumRecoverySecondTrial.index));
                d3.select('#secondRecoveryBreakAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OS', 2, 'break', minimumRecoverySecondTrial.index));

                if (bestRecoveryDistance > minimumRecoverySecondTrial.distance) {
                    bestRecoveryDistance = minimumRecoverySecondTrial.distance;
                    bestRecoveryTrial = 2;
                }

                // Third trial
                const minimumRecoveryThirdTrial = this.findMinimumDistance(this.state.data, 'recovery', 3);
                d3.select('#thirdRecoveryMin').text(minimumRecoveryThirdTrial.distance.toFixed(2));
                this.drawHighlightedLines('recovery', 3, minimumRecoveryThirdTrial.distance);
                d3.select('#thirdRecoveryStartAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OD', 3, 'start', 0));
                d3.select('#thirdRecoveryStartAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OS', 3, 'start', 0));
                d3.select('#thirdRecoveryBreakAvgOD').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OD', 3, 'break', minimumRecoveryThirdTrial.index));
                d3.select('#thirdRecoveryBreakAvgOS').text(this.calculateAveragePupilDistance(this.state.data, 'recovery', 'OS', 3, 'break', minimumRecoveryThirdTrial.index));

                if (bestRecoveryDistance > minimumRecoveryThirdTrial.distance) {
                    bestRecoveryDistance = minimumRecoveryThirdTrial.distance;
                    bestRecoveryTrial = 3;
                }

                this.drawBestDistanceNumber('recovery', bestRecoveryTrial, bestRecoveryDistance);
            }
        }

        if (this.currentChart === 'npc') {
            this.addNPCData(OD, OS, this.state.currentTestCnt);
        } else {
            this.addRecoveryData(OD, OS, this.state.currentTestCnt);
        }
    }
    render() {}
    clearState() {
        this.state = new State();
        this.lineViewNPC.selectAll('*').remove();
        this.lineViewRecovery.selectAll('*').remove();
        this.gNPC.selectAll('.stateful-text').text('');
        this.gRecovery.selectAll('.stateful-text').text('');
        if (this.omgtimer1 != null) clearTimeout(this.omgtimer1);
        if (this.omgtimer2 != null) clearTimeout(this.omgtimer2);
        if (this.omgtimer3 != null) clearTimeout(this.omgtimer3);
    }

    refresh() {
        this.render();
    }
}
function getLength(x: d3.Selection<SVGPathElement, DataItem[], HTMLElement, unknown>): number | null {
    let gotNode = x.node();
    if (gotNode && gotNode.getTotalLength) {
        let gotLength = gotNode.getTotalLength();
        return gotLength;
    }
    return null;
}
