// @ts-nocheck
import * as d3 from 'd3';
import * as lo from '../layout';
import { Target } from '../lib';

interface TargetsParams {
    target?: HTMLElement;
    svgTarget?: HTMLElement;
}
export class Targets {
    svg: Target;
    statics: Target;
    topAgeGroupsTarget: Target;
    topChartTrials: Target;
    topChartDerived0: Target;
    topChartDerived1: Target;
    topChartDerived2: Target;
    topChartDerived3: Target;
    fixedNumbersTarget: Target;
    movableLineTarget: Target;
    trialsTextTarget: Target;
    bottomSubjectPointsTarget: Target;
    buttonsTarget: Target;
    infoTarget: Target;
    buttonsInfoTarget: Target;

    constructor(params: TargetsParams) {
        let { target, svgTarget } = params;
        let svg: Target | undefined;
        if (target) {
            let t = d3.select(target);
            let div = t.append('div').attr('class', 'svg-container');
            svg = div.append('svg');
        } else if (svgTarget) {
            svg = d3.select(svgTarget);
        } else {
            throw Error('svg was not setup');
        }

        svg.attr('viewBox', lo.layout.viewBox()).attr('preserveAspectRatio', 'xMinYMin meet');

        this.svg = svg;
        this.statics = svg.append('g');
        this.topAgeGroupsTarget = svg.append('g');
        this.topChartTrials = svg.append('g');
        this.topChartDerived0 = svg.append('g');
        this.topChartDerived1 = svg.append('g');
        this.topChartDerived2 = svg.append('g');
        this.topChartDerived3 = svg.append('g');
        this.fixedNumbersTarget = svg.append('g');
        // this.infoButtonTarget = svg.append('g');
        this.movableLineTarget = svg.append('g');
        this.trialsTextTarget = svg.append('g');
        this.bottomSubjectPointsTarget = svg.append('g');
        this.buttonsTarget = svg.append('g');
        this.infoTarget = svg.append('g');
        this.buttonsInfoTarget = svg.append('g');
    }
}
