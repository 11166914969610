import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const turnPageAnimation = trigger('turnPage', [
    state(
        'start',
        style({
            opacity: 1,
            transform: 'translateX(0)',
        })
    ),
    state(
        'finish',
        style({
            opacity: 1,
            transform: 'translateX(0)',
        })
    ),
    transition(
        'start => finish',
        animate(
            400,
            keyframes([
                style({
                    opacity: 0.5,
                    transform: 'translateX(-50%)',
                    offset: 0.25,
                }),
                style({
                    opacity: 0,
                    transform: 'translateX(-100%)',
                    offset: 0.5,
                }),
                style({
                    opacity: 0,
                    transform: 'translateX(100%)',
                    offset: 0.51,
                }),
                style({
                    opacity: 0.5,
                    transform: 'translateX(50%)',
                    offset: 0.75,
                }),
                style({
                    opacity: 1,
                    transform: 'translateX(0)',
                    offset: 1,
                }),
            ])
        )
    ),
    transition(
        'finish => start',
        animate(
            400,
            keyframes([
                style({
                    opacity: 0.5,
                    transform: 'translateX(50%)',
                    offset: 0.25,
                }),
                style({
                    opacity: 0,
                    transform: 'translateX(100%)',
                    offset: 0.5,
                }),
                style({
                    opacity: 0,
                    transform: 'translateX(-100%)',
                    offset: 0.51,
                }),
                style({
                    opacity: 0.5,
                    transform: 'translateX(-50%)',
                    offset: 0.75,
                }),
                style({
                    opacity: 1,
                    transform: 'translateX(0)',
                    offset: 1,
                }),
            ])
        )
    ),
]);
