import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IUser } from '../../../../../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../../../../../commonout/interfaces/userFrontend.interface';
import { DryEyeForus3NethraClassicTestFrontend } from '../../../../../../_models/tests/FORUS/3NethraClassic/dryEyeForus3NethraClassicTestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'dry-eye-test-remarks',
    template: require('./dry-eye-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./dry-eye-test-remarks.component.scss')],
})
export class DryEyeForus3NethraClassicTestRemarksComponent extends TestRemarksComponent {
    test: DryEyeForus3NethraClassicTestFrontend;
    private readonly radio: string[] = ['Normal', 'Abnormal'];
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            eyelids: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            meibomianGlands: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            conjuctiva: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            palpebral: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            bulbar: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            tearFilm: this.formBuilder.group({
                OD: '',
                OS: '',
            }),
            images: this.formBuilder.group({
                OD: [],
                OS: [],
            }),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.remarksForm.setValue(
            {
                eyelids: {
                    OD: this.test.remarks.measurements[0].eyelids.OD.value,
                    OS: this.test.remarks.measurements[0].eyelids.OS.value,
                },
                meibomianGlands: {
                    OD: this.test.remarks.measurements[0].meibomianGlands.OD.value,
                    OS: this.test.remarks.measurements[0].meibomianGlands.OS.value,
                },
                conjuctiva: {
                    OD: this.test.remarks.measurements[0].conjuctiva.OD.value,
                    OS: this.test.remarks.measurements[0].conjuctiva.OS.value,
                },
                palpebral: {
                    OD: this.test.remarks.measurements[0].palpebral.OD.value,
                    OS: this.test.remarks.measurements[0].palpebral.OS.value,
                },
                bulbar: {
                    OD: this.test.remarks.measurements[0].bulbar.OD.value,
                    OS: this.test.remarks.measurements[0].bulbar.OS.value,
                },
                tearFilm: {
                    OD: this.test.remarks.measurements[0].tearFilm.OD.value,
                    OS: this.test.remarks.measurements[0].tearFilm.OS.value,
                },
                images: {
                    OD: this.test.remarks.measurements[0].images.OD,
                    OS: this.test.remarks.measurements[0].images.OS,
                },
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
    }
    public convertFormToModel(): void {
        const formGroupValue = this.remarksForm.value,
            user: IUser<any> & IUserFrontend = this.authenticationService.currentUser.getModel(true);
        this.test.remarks.measurements[0].bulbar.OD.registerEdit(formGroupValue.bulbar.OD, user);
        this.test.remarks.measurements[0].bulbar.OS.registerEdit(formGroupValue.bulbar.OS, user);
        this.test.remarks.measurements[0].conjuctiva.OD.registerEdit(formGroupValue.conjuctiva.OD, user);
        this.test.remarks.measurements[0].conjuctiva.OS.registerEdit(formGroupValue.conjuctiva.OS, user);
        this.test.remarks.measurements[0].eyelids.OD.registerEdit(formGroupValue.eyelids.OD, user);
        this.test.remarks.measurements[0].eyelids.OS.registerEdit(formGroupValue.eyelids.OS, user);
        this.test.remarks.measurements[0].meibomianGlands.OD.registerEdit(formGroupValue.meibomianGlands.OD, user);
        this.test.remarks.measurements[0].meibomianGlands.OS.registerEdit(formGroupValue.meibomianGlands.OS, user);
        this.test.remarks.measurements[0].palpebral.OD.registerEdit(formGroupValue.palpebral.OD, user);
        this.test.remarks.measurements[0].palpebral.OS.registerEdit(formGroupValue.palpebral.OS, user);
        this.test.remarks.measurements[0].tearFilm.OD.registerEdit(formGroupValue.tearFilm.OD, user);
        this.test.remarks.measurements[0].tearFilm.OS.registerEdit(formGroupValue.tearFilm.OS, user);
        this.test.remarks.diagnosis.registerEdit(formGroupValue.diagnosis, user);
        this.test.remarks.advice.registerEdit(formGroupValue.advice, user);
        this.test.remarks.laboratoryRequested.registerEdit(formGroupValue.laboratoryRequested, user);
        this.test.remarks.medicinesPrescribed.registerEdit(formGroupValue.medicinesPrescribed, user);
    }
}
