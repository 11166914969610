// @ts-nocheck
// import * as d3 from 'd3';
import * as _ from 'lodash';

import { Target, drawTick } from '..';
import { doTranslate } from '..';
// import { getStyle } from './util'
import { RawPoint } from './util'
import { Layout } from '../Layout'
import { translate, nonSelectable } from '../util'

// styling

const styleDefault = {
  // fillColor: 'transparent',
  // lineColor: '#eee',
  // lineSize: 1,
}

type Style = typeof styleDefault

// const themeOverrides: { [k:string]: Partial<Style> } = {
//   bright: {
//     // lineColor: '#123',
//   },
// }

// interface

type ColorStyle = string | { klass: string }

interface Params<D> {
    // from: RawPoint
    // to: RawPoint
    items: D[]
    isChecked: (d:D) => boolean
    onClick: (d:D) => void
    coloring: (d:D) => string
    naming: (d:D) => string

    newType?: boolean

    target: Target;
    area: Layout;
    styleParams: {
      theme?: string,
      override?: Partial<Style>,
    }
    fontSize?: number
}

// optionals/styling
let defaultProps = {
  tickSize: 0.1,
  noCheckBox: false,
  radio: false,
}

export function Option<D>(given: Params<D> & Partial<typeof defaultProps>) {
    let p = { ...defaultProps, ...given }
    let fontSize = p.fontSize || 24

    type IndexedD = D & {
      index: number,
    }
    type FullD = IndexedD & {
      position: RawPoint,
      color: string,
      text: string,
      isChecked: boolean,
    }

    let width = p.area.width()
    let height = 50

    // let positioningX = (d: IndexedD) => 0
    // let positioningY = (d: IndexedD) => d.index * height
    let positioning = (d: IndexedD) => ({
      x: 0,
      y: d.index * height,
    })

    let items: FullD[] = p.items.map((x, i) => {
      let withIndex = {
        ...x,
        index: i,
      }
      return {
        ...withIndex,
        position: positioning(withIndex),
        color: p.coloring(x),
        text: p.naming(x),
        isChecked: p.isChecked(x),
      }
    })
    let target = p.target
      .selectAll('.option')
      .data(items)
      .join('g')
      .attr('class', 'option')
      .attr('cursor', d => d.text ? 'pointer' : 'default')
      .call(doTranslate(p.area.cornerTL()))
      .on('mousedown', d => p.onClick(d))
      .call(nonSelectable)

    // g: Target, rect: Rect) {
    // let klass = 'options'
    // let selector = `.${klass}`;

    // let real = !!this.text;

    // let target = p.target
    //     .selectAll(selector)
    //     .data([null])
    //     .join('g')
    //     .attr('cursor', real ? 'pointer' : 'default')
    //     .attr('class', klass);
    // .attr('transform', translate(rect))

    let tickScaleNumber = 30; // this.params.tickScale || 30;
    let tickScale = `scale(${tickScaleNumber})`;

    let move = (a: {x:number, y:number}, b: {x:number, y:number}) => ({ x: a.x + b.x, y: a.y + b.y })

    let tickDelta = {
      x: height / 2,
      y: height / 2,
    }
    let textDelta = {
      x: height / 2 * 2,
      y: height / 2,
    }

    let colorClass = (gotColor: string) => {
      let klass = 'sText'
      if (gotColor) klass = ''
      return klass
    }

    if (p.newType) {
      // let delta = 10
      // let deltaY = 3

      // let checked = this.getChecked();
      // let on = checked ? 'on' : 'off'

      let tickDelta = {
        x: 20,
        y: 13,
      }

      let checkedness = (d: any) => {
        let checked = p.isChecked(d)
        return checked ? 'on' : 'off'
      }

      let icon = p.radio ? 'radio' : 'tick';

      target
          .selectAll('.checkbox')
          .data((d:any) => p.noCheckBox ? [] : [d])
          // .data(d => _.compact([p.isChecked(d) ? d : null ]))
          .join('image')
          .attr('href', (d:any) => `/frontend/src/assets/images/diagram-icons/${icon}-${checkedness(d)}.svg`)
          .attr('class', (d:any) => `checkbox ${colorClass(p.coloring(d))}`)
          .attr('transform', (d:any) => translate(move(d.position, tickDelta)))
          // .attr('class', `checkbox ${this.style.optionTick.klass}`)
          // .attr('transform', d => translate(move(d.position, tickDelta)) + ' ' + tickScale);
          // .attr('d', image)
          // .attr('fill', 'none')
          // .attr('stroke', this.params.tickColor ?? this.style.optionTick.color)
          // .attr('stroke-width', this.style.optionTick.size)
          // .attr('transform', translate({ x: delta+padX, y: rect.y + deltaY }))
          .attr('stroke', 'gray')
          .attr('fill', 'gray')
    } else {
      target
          .selectAll('.checkbox')
          .data(d => _.compact([p.isChecked(d) ? d : null ]))
          .join('path')
          .attr('class', (d:any) => `checkbox ${colorClass(p.coloring(d))}`)
          .attr('d', drawTick)
          .attr('fill', 'none')
          .attr('stroke', p.coloring) //, maxId), //'orange')//this.params.tickColor ?? this.style.optionTick.color)
          .attr('stroke-width', p.tickSize) //this.style.optionTick.size)
          .attr('transform', d => translate(move(d.position, tickDelta)) + ' ' + tickScale);
    }

    target
        .selectAll('text')
        .data(d => [d])
        .join('text')
        .text(d => d.text)
        // .style('fill', '#fff') // this.style.comboText.color)
        .style('font-size', fontSize) /// this.style.comboText.size)
        .attr('alignment-baseline', 'middle')
        .attr('transform', d => translateIncrease(move(d.position, textDelta)))
        .attr('class', 'diagram-link-text')
        // .call(position);

/*
    // NOTE: some strange reordering if rects are not transparent
    // let textTarget = target.selectAll('.text-target')
    // .data([null]).join('g').attr('class', 'text-target')

    // let checkboxWidth =

    let marginLeft = this.height / 3;
    if (this.hasCheckbox) marginLeft += this.height / 2 + this.height / 8; // ...

    if (this.hasCheckbox) {
        let delta = this.height / 2;

        let checked = this.getChecked();
        let image = checked ? drawTick : '';

        target
            .selectAll('.checkbox')
            .data([null])
            .join('path')
            .attr('class', 'checkbox')
            .attr('d', image)
            .attr('fill', 'none')
            .attr('stroke', this.params.tickColor ?? this.style.optionTick.color)
            .attr('stroke-width', this.style.optionTick.size)
            .attr('transform', translate({ x: delta, y: rect.y + delta }) + ' ' + checkboxScale);
    }

    let margins = { left: marginLeft };

    let positionCenter = (rect: Rect) => (x: Target) =>
        x
            .attr('x', rect.x + rect.width / 2)
            .attr('y', rect.y + rect.height / 2)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle');
    let positionLeft = (rect: Rect, margins: Margins) => (x: Target) =>
        x
            .attr('x', rect.x + margins.left) // + rect.width / 2)
            .attr('y', rect.y + rect.height / 2)
            // .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle');

    let position = this.textAlign === 'center' ? positionCenter(rect) : positionLeft(rect, margins);

    target
        .selectAll('text')
        .data([null])
        .join('text')
        .text(this.text)
        .attr('fill', this.style.comboText.color)
        .attr('font-size', this.style.comboText.size)
        .call(position);

    target.on(this.eventName, () => {
        if (this.doNotClose) {
            let e = d3.event;
            e.stopPropagation();
        }
        // console.log(e)
        this.handler();
    });

    if (this.preview) {
        let y = rect.y + rect.height / 2;
        let g = target
            .selectAll('.gPreview')
            .data([null])
            .join('g')
            .attr('class', 'gPreview')
            .attr('transform', translate({ x: this.preview.x, y }));
        this.preview.render(g);
    }
    */
  // console.log(p.items)
    // let style = getStyle(styleDefault, themeOverrides, p.styleParams)

    // let x1 = Math.min(p.from.x, p.to.x)
    // let x2 = Math.max(p.from.x, p.to.x)

    // let y1 = Math.min(p.from.y, p.to.y)
    // let y2 = Math.max(p.from.y, p.to.y)

    // p.target
    //     .selectAll('.rectangle')
    //     .data([null])
    //     .join('rect')
    //     .attr('class', 'rectangle')

    //     .call(doTranslate({ x: x1, y: y1 }))
    //     .attr('width', x2 - x1)
    //     .attr('height', y2 - y1)

    //     .attr('fill', style.fillColor)
    //     .attr('stroke-width', style.lineSize)
    //     .attr('stroke', style.lineColor)

    // return {
    //     target,
    //     area,
    //     styleParams,
    // }
}

export function translateIncrease(...points: DeltaCoords[]): string {
  let reducer = (a: Point, b: DeltaCoords) => ({ x: a.x + (b.x || 0), y: a.y + (b.y || 0) });
  let point = points.reduce(reducer, { x: 0, y: 0 });
  let x = point.x + 15;
  let y = point.y + 10;
  return 'translate(' + x + ', ' + y + ')';
}