import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UploadMeasurementsService {
    uploadSubject$: Subject<number> = new Subject<number>();

    getUploadButtonIndex(): Observable<number> {
        return this.uploadSubject$.asObservable();
    }

    setUploadButtonIndex(deviceIndex: number): void {
        this.uploadSubject$.next(deviceIndex);
    }
}
