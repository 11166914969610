import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_TYPE } from '../../../../../../../../../commonout/enum/test-type';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'saccade-merged-test',
    template: require('./saccade-merged-test-controls.component.html'),
    styles: [require('./saccade-merged-test-controls.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class SaccadeMergedTestControlsComponent extends BulbicamControlComponent implements OnInit, OnDestroy {
    public controls: FormGroup;
    private subscriptions: Subscription[];
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService, public formBuilder: FormBuilder) {
        super(bulbicamService, configService, socketService, authService);
        this.subscriptions = [];
    }
    ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
    }
    ngOnInit(): void {
        this.controls = this.formBuilder.group({
            type: ['', Validators.required],
            oculus: [OCULUS.OD, Validators.required],
            training: this.formBuilder.group({
                pro: {
                    value: 4,
                    disabled: true,
                },
                memory: {
                    value: 4,
                    disabled: true,
                },
                anti: {
                    value: 0,
                    disabled: true,
                },
            }),
            stimuli: this.formBuilder.group({
                pro: {
                    value: 60,
                    disabled: true,
                },
                memory: {
                    value: 40,
                    disabled: true,
                },
                anti: {
                    value: 40,
                    disabled: true,
                },
            }),
        });
        this.controls
            .get('type')
            .valueChanges.pipe(startWith(null as string), pairwise())
            .subscribe(async ([prev, next]: ['pro' | 'memory' | 'anti', 'pro' | 'memory' | 'anti']) => {
                if (next === 'anti' && !this.bulbicamService.hasProperRatio()) {
                    setTimeout(() => {
                        this.controls.get('type').setValue(prev);
                    }, 0);
                } else {
                    ['pro', 'memory', 'anti'].forEach(t => {
                        if (next === t) {
                            this.controls.get(`training.${t}`).enable();
                            this.controls.get(`stimuli.${t}`).enable();
                        } else {
                            this.controls.get(`training.${t}`).disable();
                            this.controls.get(`stimuli.${t}`).disable();
                        }
                    });
                }
            });
    }
    public start(): void {
        this.pendingResponse = true;
        this.controls.disable();
        const rawFormValue: {
            command: TEST_COMMAND;
            testType: TEST_TYPE;
            type: string;
            oculus: OCULUS;
            training: {
                pro: number;
                memory: number;
                anti: number;
            };
            stimuli: {
                pro: number;
                memory: number;
                anti: number;
            };
            username: string;
        } = this.controls.getRawValue();
        for (const key in rawFormValue.training) {
            if (Object.prototype.hasOwnProperty.call(rawFormValue.training, key)) {
                if (key === rawFormValue.type) {
                    rawFormValue.stimuli = rawFormValue.stimuli[key];
                    rawFormValue.training = rawFormValue.training[key];
                }
            }
        }
        rawFormValue.command = TEST_COMMAND.START;
        rawFormValue.testType = this.test.type;
        rawFormValue.username = this.authService.currentUser.username;
        this.subscriptions.push(
            this.bulbicamService
                .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, rawFormValue)
                .subscribe(() => {
                    this.test.status = TEST_STATUS.IN_PROGRESS;
                    this.pendingResponse = false;
                })
        );
    }
    public enable(): void {
        this.controls.enable();
    }
    public disable(): void {
        this.controls.disable();
    }
}
