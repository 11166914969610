// @ts-nocheck
import { XY, Target } from '../../lib/types'
import { TextBlock, Area, Line } from '../../lib'
import { Brand as B } from '../../lib'

let defaults = {
}

interface OtherParams {
  point: XY
  target: Target
}

type GivenParams = OtherParams & Partial<typeof defaults>

export function Legend(given: GivenParams) {
  let p = { ...defaults, ...given }

  let dr = 0
  let area = new Area(p.point.x, p.point.y, p.point.x, p.point.y)
    .slide(-dr, dr)


  let gText = p.target
    .selectAll('.textTarget')
    .data([null])
    .join('g')
    .attr('class', 'textTarget')

  let gLine1 = p.target
    .selectAll('.gLine1')
    .data([null])
    .join('g')
    .attr('class', 'gLine1')

  let gLine2 = p.target
    .selectAll('.gLine2')
    .data([null])
    .join('g')
    .attr('class', 'gLine2')

  TextBlock({
    text: 'OD                     OS',
    target: gText,
    area,
    alignTop: true,
    alignRight: true,
    styleParams: { override: {
      fontSize: 16,
      fontWeight: 700,
    }},
  })

  let slide = (p: XY, dx: number, dy: number) => {
    let { x, y } = p
    x += dx
    y += dy
    return {
      x, y
    }
  }
  let stretchDx = (p: XY, dx: number) => {
    let { x, y } = p
    let x2 = x + dx

    let from = { x, y }
    let to = { x: x2, y }
    return {
      from,
      to
    }
  }

  let stretch = 50 - 10
  let mainDx = -98 + 17
  let mainDy = 8-1

  Line({
    ...stretchDx(slide(p.point, mainDx, mainDy), stretch),
    target: gLine1,
    styleParams: { override: {
      lineColor: B.orange,
      lineSize: 2,
    }},
  })

  let dx = -128 + 22

  Line({
    ...stretchDx(slide(p.point, mainDx + dx, mainDy), stretch),
    target: gLine2,
    styleParams: { override: {
      lineColor: B.green,
      lineSize: 2,
    }},
  })
}
