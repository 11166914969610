import { TonometryNidekNT530PTest } from '../../../../../../../../commonout/classes/tests/NIDEK/NT530P/tonometryNidekNT530PTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { TonometryTestRemarksComponent } from '../../../../_components';
import { TonometryTestRemarksFrontend } from '../../../test-remarks/NIDEK/NT530P/tonometryTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class TonometryNidekNt530pTestFrontend extends TonometryNidekNT530PTest {
    remarks: TonometryTestRemarksFrontend;
    public readonly remarksComponent: typeof TonometryTestRemarksComponent = TonometryTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new TonometryTestRemarksFrontend();
    }
}

export interface TonometryNidekNt530pTestFrontend extends TestFrontend {}
applyMixins(TonometryNidekNt530pTestFrontend, [TestFrontend]);
