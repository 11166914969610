// @ts-nocheck
import { getNewClass, doTranslate, nonSelectable } from './util';
import { Target } from './types';

interface Props {
    x: number;
    y: number;
    width: number;
    height: number;
    parent: Target;
}

interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export class InfoButton {
    style = {
        primary: {
            color: 'rgb(171, 190, 197)',
        },
        secondary: {
            color: 'rgb(0, 154, 190)',
        },
    };

    static defaults = {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
    };

    infoVisible = false;
    klass = getNewClass('info-button');

    constructor(public props: Props) {}

    render() {
        this.renderTo(this.props.parent);
    }

    renderTo(target: Target) {
        let g = target
            .selectAll(this.selector)
            .data([null])
            .join('g')
            .attr('class', this.klass)
            .call((t) => this.usePosition(t))
            .call(nonSelectable);

        g.selectAll('circle')
            .data([null])
            .join('circle')
            .attr('r', 25)
            .call((t) => this.useCircleStyle(t));

        g.selectAll('text')
            .data([null])
            .join('text')
            .text('i')
            .call((t) => this.useTextStyle(t));
    }

    // private

    usePosition(target: Target) {
        // TODO: util
        let getCenter = (given: Rect) => {
            let x = given.x + given.width / 2;
            let y = given.y + given.height / 2;
            return { x, y };
        };
        target.call(doTranslate(getCenter(this.props)));
    }

    useTextStyle(target: Target) {
        target
            .attr('fill', this.style.primary.color)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central')
            .style('font-weight', 900)
            .style('font-family', 'Serif')
            .style('font-size', 40)
            // .style('font-size', 70)
            .call((t) => this.useCommonStyle(t));
    }

    useCircleStyle(target: Target) {
        target
            .attr('fill', 'transparent')
            // .attr('stroke', this.style.secondary.color)
            .attr('stroke', this.style.primary.color)
            .attr('stroke-width', 2)
            .call((t) => this.useCommonStyle(t));
    }

    useCommonStyle(target: Target) {
        target
            .attr('cursor', 'pointer')
            .on('mouseenter', () => this.toggleInfo())
            .on('mouseleave', () => this.toggleInfo(false));
    }

    toggleInfo(value?: boolean) {
        if (value === undefined) value = !this.infoVisible;
        this.infoVisible = value;

        let target = this.props.parent.selectAll('.added-info');
        if (this.infoVisible) {
            target.attr('visibility', 'visible');
        } else {
            target.attr('visibility', 'hidden');
        }
    }

    get selector() {
        return `.${this.klass}`;
    }
}
