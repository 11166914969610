import { DryEyeForus3NethraClassicTest } from '../../../../../../../../commonout/classes/tests/FORUS/3NethraClassic/dryEyeForus3NethraClassicTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { DryEyeForus3NethraClassicTestRemarksComponent } from '../../../../_components';
import { DryEyeForus3NethraClassicTestRemarksFrontend } from '../../../test-remarks/FORUS/3NethraClassic/dryEyeTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class DryEyeForus3NethraClassicTestFrontend extends DryEyeForus3NethraClassicTest {
    remarks: DryEyeForus3NethraClassicTestRemarksFrontend;
    public readonly remarksComponent: typeof DryEyeForus3NethraClassicTestRemarksComponent = DryEyeForus3NethraClassicTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new DryEyeForus3NethraClassicTestRemarksFrontend();
    }
}

export interface DryEyeForus3NethraClassicTestFrontend extends TestFrontend {}
applyMixins(DryEyeForus3NethraClassicTestFrontend, [TestFrontend]);
