import * as moment from 'moment';
import { CONSULTATION_TYPE } from '../../../commonout/enum/consultationType';
import { DEVICE } from '../../../commonout/enum/device';
import { DOCTORS_SPECIALITY } from '../../../commonout/enum/doctorsSpeciality';
import { TEST_TYPE } from '../../../commonout/enum/test-type';
import { IExamination } from '../../../commonout/interfaces/examination';
import { RegisterEditField } from './registerEditField.class';
import { Test } from './test.class';

export abstract class Examination implements IExamination {
    _id: string;
    patientId: string;
    timeZone: string;
    createdAt: number;
    lastModified: number;
    synchronizedAt: number;
    lenses: {
        lensholder: RegisterEditField<number>;
        OD: RegisterEditField<number>;
        OS: RegisterEditField<number>;
    };
    tests: Test[];
    enabledDevices: DEVICE[];
    doctorsSpeciality: DOCTORS_SPECIALITY[];
    language: string[];
    consultationType: CONSULTATION_TYPE[];
    doctorsLicence: string[];
    CAMversion: string;
    HUBversion: string;
    externalIPaddress: string;
    constructor() {}
    public get creationDateTime(): string {
        if (this.createdAt) return moment(this.createdAt, 'x').format('YYMMDDHHmmss');
    }
    public getTest<T>(type: TEST_TYPE, device: DEVICE): T {
        const test: unknown = this.tests.find(t => t.type === type && t.device === device);
        return test as T;
    }
    public abstract setModel(model: IExamination): void;
    public getModel(): IExamination {
        let model: IExamination = {
            _id: this._id,
            timeZone: this.timeZone,
            createdAt: this.createdAt,
            enabledDevices: this.enabledDevices,
            lastModified: this.lastModified,
            synchronizedAt: this.synchronizedAt,
            patientId: this.patientId,
            doctorsSpeciality: this.doctorsSpeciality,
            language: this.language,
            consultationType: this.consultationType,
            doctorsLicence: this.doctorsLicence,
            lenses: {
                lensholder: this.lenses.lensholder.getModel(),
                OD: this.lenses.OD.getModel(),
                OS: this.lenses.OS.getModel(),
            },
            tests: this.tests.map(t => t.getModel()),
            CAMversion: this.CAMversion,
            HUBversion: this.HUBversion,
            externalIPaddress: this.externalIPaddress,
        };
        return model;
    }
}
