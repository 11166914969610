import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { IRegisterEditField } from '../../../../../../../commonout/interfaces/register-edit-field.interface';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { ExportService } from '../../../_services/examination/export.service';
import { DatepickerComponent } from '../../general/custom-inputs/datepicker/datepicker.component';
import { SingleselectComponent } from '../../general/custom-inputs/singleselect/singleselect.component';

@Component({
    selector: 'examinations-statistics',
    template: require('./examinations-statistics.component.html'),
    styles: [require('./examinations-statistics.component.scss')],
})
export class ExaminationsStatisticsComponent implements OnInit {
    private filterForm: FormGroup;
    private readonly maxDate: Date = new Date();
    private maxDateForStartDatePicker: Date = new Date();
    private minDateForEndDatePicker: Date;
    // private open: boolean = true; // to prevent bug when changing max date value of min datepicker fires value change event
    private cnt: number = null;
    private range: {
        start: number;
        end: number;
    } = {
        start: null,
        end: null,
    };
    private result: {
        createdAt: number;
        patient: {
            registerID: IRegisterEditField<string>;
            ssn: IRegisterEditField<string>;
            dateOfBirth: IRegisterEditField<number>;
        };
    }[] = [];
    private readonly timeRanges: string[] = ['Today', 'This week', 'Last week', 'This month', 'Last month', 'This year', 'Last year', 'Custom'];
    @ViewChild(SingleselectComponent) private rangeComponent: SingleselectComponent;
    @ViewChild('startPicker') private startPicker: DatepickerComponent;
    @ViewChild('endPicker') private endPicker: DatepickerComponent;
    constructor(private formBuilder: FormBuilder, private examinationService: ExaminationService, private exportService: ExportService) {
        this.filterForm = this.formBuilder.group({
            range: null,
            startDate: null,
            endDate: null,
        });
    }

    ngOnInit() {
        this.filterForm
            .get('range')
            .valueChanges.pipe(
                map((range: string) => {
                    switch (range) {
                        case 'Today':
                            this.range.start = moment()
                                .startOf('day')
                                .valueOf();
                            this.range.end = moment().valueOf();
                            break;
                        case 'This week':
                            this.range.start = moment()
                                .startOf('isoWeek')
                                .valueOf();
                            this.range.end = moment().valueOf();
                            break;
                        case 'Last week':
                            this.range.start = moment()
                                .subtract(1, 'week')
                                .startOf('isoWeek')
                                .valueOf();
                            this.range.end = moment()
                                .subtract(1, 'week')
                                .endOf('isoWeek')
                                .valueOf();
                            break;
                        case 'This month':
                            this.range.start = moment()
                                .startOf('month')
                                .valueOf();
                            this.range.end = moment().valueOf();
                            break;
                        case 'Last month':
                            this.range.start = moment()
                                .subtract(1, 'month')
                                .startOf('month')
                                .valueOf();
                            this.range.end = moment()
                                .subtract(1, 'month')
                                .endOf('month')
                                .valueOf();
                            break;
                        case 'This year':
                            this.range.start = moment()
                                .startOf('year')
                                .valueOf();
                            this.range.end = moment().valueOf();
                            break;
                        case 'Last year':
                            this.range.start = moment()
                                .subtract(1, 'year')
                                .startOf('year')
                                .valueOf();
                            this.range.end = moment()
                                .subtract(1, 'year')
                                .endOf('year')
                                .valueOf();
                            break;
                        default:
                            this.startPicker.open();
                            break;
                    }
                    return;
                })
            )
            .subscribe(() => {
                if (this.filterForm.get('range').value === 'Custom') {
                    this.startPicker.open();
                } else {
                    this.filterForm.get('startDate').setValue(moment(this.range.start).toDate());
                }
            });
        this.filterForm.get('startDate').valueChanges.subscribe((startRange: Date) => {
            this.minDateForEndDatePicker = startRange;
            if (!this.filterForm.get('range').value || this.filterForm.get('range').value === 'Custom') this.endPicker.open();
            else this.filterForm.get('endDate').setValue(moment(this.range.end).toDate());
        });
        this.filterForm.get('endDate').valueChanges.subscribe(async (endRange: Date) => {
            this.maxDateForStartDatePicker = endRange;
            this.rangeComponent.writeValue(this.defineRange());
            this.result = await this.examinationService
                .countByPeriod(
                    moment(this.filterForm.get('startDate').value).valueOf(),
                    moment(endRange)
                        .endOf('day')
                        .valueOf()
                )
                .toPromise();
            this.cnt = this.result.length;
        });
    }

    private defineRange(): string {
        const startRange: number = moment(this.filterForm.get('startDate').value).valueOf(),
            endRange: number = moment(this.filterForm.get('endDate').value)
                .endOf('day')
                .valueOf();
        if (
            startRange ===
            moment()
                .startOf('day')
                .valueOf()
        ) {
            return 'Today';
        } else if (
            startRange ===
                moment()
                    .startOf('isoWeek')
                    .valueOf() &&
            endRange >
                moment()
                    .startOf('day')
                    .valueOf()
        ) {
            return 'This week';
        } else if (
            startRange ===
                moment()
                    .subtract(1, 'week')
                    .startOf('isoWeek')
                    .valueOf() &&
            endRange ===
                moment()
                    .subtract(1, 'week')
                    .endOf('isoWeek')
                    .valueOf()
        ) {
            return 'Last week';
        } else if (
            startRange ===
                moment()
                    .startOf('month')
                    .valueOf() &&
            endRange >
                moment()
                    .startOf('day')
                    .valueOf()
        ) {
            return 'This month';
        } else if (
            startRange ===
                moment()
                    .subtract(1, 'month')
                    .startOf('month')
                    .valueOf() &&
            endRange ===
                moment()
                    .subtract(1, 'month')
                    .endOf('month')
                    .valueOf()
        ) {
            return 'Last month';
        } else if (
            startRange ===
                moment()
                    .startOf('year')
                    .valueOf() &&
            endRange >
                moment()
                    .startOf('day')
                    .valueOf()
        ) {
            return 'This year';
        } else if (
            startRange ===
                moment()
                    .subtract(1, 'year')
                    .startOf('year')
                    .valueOf() &&
            endRange ===
                moment()
                    .subtract(1, 'year')
                    .endOf('year')
                    .valueOf()
        ) {
            return 'Last year';
        } else {
            return 'Custom';
        }
    }

    private print(): void {
        this.exportService.examsReport(this.filterForm.get('startDate').value, this.filterForm.get('endDate').value, this.result);
    }
}
