import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { IConvergenceBulbicamMeasuredData } from '../../../interfaces/measuredData/BulbiCAM/convergence-bulbicam.measuredData.interface';
import { CamTest } from '../../haplotest.class';
import { RegisterEditField } from '../../registerEditField.class';
import { MeasuredData } from '../measuredData.model';

export abstract class ConvergenceBulbicamMeasuredData implements MeasuredData, IConvergenceBulbicamMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public haplotests: CamTest[];
    public nearPointOfConvergence: RegisterEditField<string>;
    public recovery: RegisterEditField<string>;
    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.CONVERGENCE;
        this.haplotests = [];
    }
    public abstract setModel(model: IConvergenceBulbicamMeasuredData): void;
    public getModel(): IConvergenceBulbicamMeasuredData {
        let model: IConvergenceBulbicamMeasuredData = {
            sourceFileName: this.sourceFileName,
            haplotests: this.haplotests.map(htm => htm.model),
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            nearPointOfConvergence: this.nearPointOfConvergence.getModel(),
            recovery: this.recovery.getModel(),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.nearPointOfConvergence.isEmpty() && this.recovery.isEmpty() && this.haplotests.length === 0;
    }
}
