// @ts-nocheck
// state and protocol

function isPointStart(got: any): got is PointStart {
    return got.message_type === 13 && typeof got.targettype === 'number' && typeof got.timestamp === 'number' && typeof got.targetx === 'number' && typeof got.targety === 'number';
}
function isPointTrace(got: any): got is PointTrace {
    return got.message_type === 6 && typeof got.timestamp === 'number' && typeof got.x === 'number' && typeof got.y === 'number' && typeof got.lineType === 'number';
}
function isPointSRT(got: any): got is PointSRT {
    return (
        got.message_type === 14 &&
        typeof got.targettype === 'number' &&
        typeof got.timestamp === 'number' &&
        typeof got.targetx === 'number' &&
        typeof got.targety === 'number' &&
        typeof got.srt === 'number'
    );
}

interface PointStart {
    message_type: number;
    timestamp: number;

    targettype: number;
    targetx: number;
    targety: number;
}

interface PointTrace {
    message_type: number;
    timestamp: number;

    x: number;
    y: number;
    lineType: number;
}

interface PointSRT {
    message_type: number;
    timestamp: number;

    targettype: number;
    targetx: number;
    targety: number;
    srt: number;
}

export class NewState {
    inputHandler(got: any) {
        if (Array.isArray(got)) {
            got.forEach((x) => this.inputHandler(x));
            return;
        }

        got = { ...got };

        if (isPointStart(got)) {
            this.gotPointStart(got);
            return;
        }
        if (isPointTrace(got)) {
            this.gotPointTrace(got);
            return;
        }
        if (isPointSRT(got)) {
            this.gotPointSRT(got);
            return;
        }
    }

    gotPointStart(got: PointStart) {
        let pointData = {
            traces: [] as any,
            point: got,
            srt: 0,
        };
        let key = pointToKey(got);

        this.points[key] = pointData;
        this.currentPoint = pointData;
    }

    gotPointTrace(got: PointTrace) {
        // if (!this.currentPoint) return console.error('no currentPoint');

        if (this.currentPoint) {
            this.currentPoint.traces.push(got);
        }
    }

    points: PointsStore = {};
    currentPoint?: PointData;

    getPointData(got: PointKeyInfo) {
        let key = pointToKey(got);
        let data = this.points[key];
        // if (!data) {}
        return data;
    }

    gotPointSRT(got: PointSRT) {
        let key = pointToKey(got);
        let data = this.points[key];
        if (data) {
            data.srt = got.srt;
        }
    }
}

type PointsStore = { [k: string]: PointData };
interface PointKeyInfo {
    targettype: number;
    targetx: number;
    targety: number;
}

function pointToKey(p: PointKeyInfo) {
    return `${p.targettype}__${p.targetx}__${p.targety}`;
}

interface PointData {
    traces: PointTrace[];
    srt: number;
}
