/**
 * Enum that provides statuses of tests in current moment
 * Strings are used in UI (for example in buttons) that is why if test is DONE you can see "Redo test" button
 */
export enum TEST_STATUS {
    START = 'Start', // test is ready for start and not started before
    IN_PROGRESS = 'In progress', // test is started abd could be paused or stopped
    PAUSE = 'Pause', // test is paused and could be stopped or resumed
    DONE = 'Redo test', // test is finished and ready to be started again
}
