import { Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BlockingType } from '../../../../../../../../commonout/enum/blockingType.enum';
import { RegisterEditFieldFrontend } from '../../../../_models/registerEditFieldFrontend.class';
import { SmartImageFrontend } from '../../../../_models/smartImageFrontend.class';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { FileService } from '../../../../_services/general/file.service';

@Component({
    selector: 'image-carousel',
    template: require('./image-carousel.component.html'),
    styles: [require('./image-carousel.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageCarouselComponent),
            multi: true,
        },
    ],
    host: { '[class.supportRemarks]': 'supportRemarks' },
})
export class ImageCarouselComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() private readonly isUploadable: boolean = false;
    @Input() private readonly isDeletable: boolean = false;
    @Input() private readonly isRotateble: boolean = false;
    @Input() private readonly supportRemarks: boolean = false;
    @Input() private readonly supportZoom: boolean = false;
    @Input() private readonly formControlName: string;
    @Output() delete: EventEmitter<string> = new EventEmitter<string>();
    private value: SmartImageFrontend[] = [];
    private activeImage: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private onChange = (_: any) => {};
    private onTouched = () => {};
    private disabled: boolean = false;
    private fullScreen: boolean = false;
    private zoomModeEnabled: boolean = false;
    private isBusy: boolean = false;
    private imageRemarks = new FormControl({ value: '', disabled: this.supportRemarks });
    @ViewChild('zoomWindow') private zoomWindow: ElementRef<HTMLDivElement>;
    @ViewChild('zoomedImage') private zoomedImage: ElementRef<HTMLDivElement>;
    @ViewChild('target') private target: ElementRef<HTMLDivElement>;
    @ViewChild('slide') private slide: ElementRef<HTMLDivElement>;
    private unlisten: Function;
    private subscriptions: Subscription[] = [];
    private isSizeMeasured: boolean = false;
    constructor(private fileService: FileService, private authService: AuthenticationService, private renderer: Renderer2, private ngZone: NgZone) {}
    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.unlisten = this.renderer.listen(this.slide.nativeElement, 'mousemove', e => this.zoomMoveHandler(e));
        });
        this.subscriptions.push(this.delete.subscribe(() => this.activeImage.next(null)));
        this.subscriptions.push(
            this.activeImage.subscribe(index => {
                if (Number.isInteger(index)) {
                    this.imageRemarks.setValue(this.value[index].remark.value, { emitEvent: false });
                    this.isSizeMeasured = false;
                }
            })
        );
        this.subscriptions.push(
            this.imageRemarks.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: string) => {
                this.value[this.activeImage.value].remark.registerEdit(value, this.authService.currentUser.getModel(true));
                this.onTouched();
                this.onChange(this.value);
            })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.unlisten();
    }
    async writeValue(data: SmartImageFrontend | SmartImageFrontend[]): Promise<void> {
        if (!data) return;
        if (!Array.isArray(data)) data = [data];
        await Promise.all(data.map(i => i.download(this.fileService)));
        this.value = data;
        if (this.value.length === 0) {
            this.activeImage.next(null);
            return;
        }
        if (this.activeImage.value === null) {
            this.activeImage.next(0);
            return;
        }
        if (this.activeImage.value > this.value.length - 1) {
            this.activeImage.next(this.value.length - 1);
            return;
        }
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    private addImage(event: any): void {
        this.isBusy = true;
        if (!this.isUploadable) return;
        const reader = new FileReader();
        reader.onload = async () => {
            const url = `url(${reader.result})`;
            const smartImage: SmartImageFrontend = new SmartImageFrontend();
            smartImage.model = {
                imageID: await this.fileService.saveFile(url),
                imageUrl: url,
                remark: new RegisterEditFieldFrontend(BlockingType.AFTER_DAY_WITH_COMMIT, this.formControlName, ''),
            };
            this.value.push(smartImage);
            this.onTouched();
            this.onChange(this.value);
            this.activeImage.next(this.value.length - 1);
            this.isBusy = false;
        };
        reader.readAsDataURL(event.target.files[0]);
    }
    private nextImage(): void {
        if (this.activeImage.value === this.value.length - 1) return;
        this.isSizeMeasured = false;
        this.onTouched();
        this.activeImage.next(this.activeImage.value + 1);
    }
    private prevImage(): void {
        if (this.activeImage.value === 0) return;
        this.isSizeMeasured = false;
        this.onTouched();
        this.activeImage.next(this.activeImage.value - 1);
    }
    private async rotate(smartImage: SmartImageFrontend): Promise<void> {
        this.isBusy = true;
        await smartImage.rotate90deg();
        this.onChange(this.value);
        this.isBusy = false;
    }
    private zoomMoveHandler(e: any): void {
        if (!this.supportZoom) return;
        if (!this.isSizeMeasured) {
            this.isSizeMeasured = true;
            this.renderer.setStyle(this.zoomWindow.nativeElement, 'width', (this.slide.nativeElement.clientWidth / 3) * 8 + 'px');
            this.renderer.setStyle(this.zoomWindow.nativeElement, 'height', (this.slide.nativeElement.clientHeight / 5) * 8 + 'px');
        }
        const targetWidth: number = this.target.nativeElement.clientWidth + 2,
            targetHeight: number = this.target.nativeElement.clientHeight + 2,
            slideWidth: number = this.slide.nativeElement.clientWidth,
            slideHeight: number = this.slide.nativeElement.clientHeight;
        let layerX: number, layerY: number;
        if (e.srcElement.className.includes('target')) {
            const bounds = this.slide.nativeElement.getBoundingClientRect();
            layerX = e.clientX - bounds.left;
            layerY = e.clientY - bounds.top;
            if (layerX > slideWidth || layerY > slideHeight) {
                this.ngZone.run(() => {
                    this.zoomModeEnabled = false;
                });
                return;
            }
        } else if (e.srcElement.className.includes('slide')) {
            layerX = e.layerX;
            layerY = e.layerY;
        } else {
            return;
        }
        let targetLeft: number = layerX - targetWidth / 2,
            targetTop: number = layerY - targetHeight / 2;
        targetLeft = targetLeft < 0 ? 0 : slideWidth < targetLeft + targetWidth ? slideWidth - targetWidth : targetLeft;
        targetTop = targetTop < 0 ? 0 : slideHeight < targetTop + targetHeight ? slideHeight - targetHeight : targetTop;
        this.renderer.setStyle(this.target.nativeElement, 'left', targetLeft + 'px');
        this.renderer.setStyle(this.target.nativeElement, 'top', targetTop + 'px');

        const leftPers: number = (targetLeft * 100) / targetWidth,
            topPers: number = (targetTop * 100) / targetHeight;

        this.renderer.setStyle(this.zoomedImage.nativeElement, 'left', '-' + (this.zoomWindow.nativeElement.clientWidth * leftPers) / 100 + 'px');
        this.renderer.setStyle(this.zoomedImage.nativeElement, 'top', '-' + (this.zoomWindow.nativeElement.clientHeight * topPers) / 100 + 'px');
    }
}
