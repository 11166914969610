import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IAntSegmentHaagSlitLampMeasuredData } from '../../../../interfaces/measuredData/HAAG/SLITLAMP/ant-segment-haag-slitlamp.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { SmartImage } from '../../../smartImage.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class AntSegmentHaagSlitLampMeasuredData implements MeasuredData, IAntSegmentHaagSlitLampMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public eyelidsEyeLashesAndLacrimalSystem: {
        upperEyelids: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        lowerEyelids: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        upperEyelashes: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        lowerEyelashes: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        lacrimalSystem: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        result: {
            OD: RegisterEditField<string>;
            OS: RegisterEditField<string>;
        };
    };
    public conjunctivaLimbusAndSclera: {
        conjunctiva: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        limbus: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        sclera: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        result: {
            OD: RegisterEditField<string>;
            OS: RegisterEditField<string>;
        };
    };
    public corneaAndAnteriorChamber: {
        cornea: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        anteriorChamberDepth: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        vanHerrickGrading: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        acCellsFlare: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        acFindings: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        result: {
            OD: RegisterEditField<string>;
            OS: RegisterEditField<string>;
        };
    };
    public irisAndLens: {
        iris: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        lensStatus: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        cataractGrading: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        otherCataractTypes: {
            OD: RegisterEditField<string[]>;
            OS: RegisterEditField<string[]>;
        };
        result: {
            OD: RegisterEditField<string>;
            OS: RegisterEditField<string>;
        };
    };
    public images: {
        OD: SmartImage[];
        OS: SmartImage[];
    };
    constructor() {
        this.sourceDevice = DEVICE.HAAG_SLITLAMP;
        this.testType = TEST_TYPE.ANTERIOR_SEGMENT_EVALUATION;
    }
    public getModel(): IAntSegmentHaagSlitLampMeasuredData {
        let model: IAntSegmentHaagSlitLampMeasuredData = {
            sourceFileName: this.sourceFileName,
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            eyelidsEyeLashesAndLacrimalSystem: {
                upperEyelids: {
                    OD: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD.getModel(),
                    OS: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS.getModel(),
                },
                lowerEyelids: {
                    OD: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD.getModel(),
                    OS: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS.getModel(),
                },
                upperEyelashes: {
                    OD: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD.getModel(),
                    OS: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS.getModel(),
                },
                lowerEyelashes: {
                    OD: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD.getModel(),
                    OS: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS.getModel(),
                },
                lacrimalSystem: {
                    OD: this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD.getModel(),
                    OS: this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS.getModel(),
                },
                result: {
                    OD: this.eyelidsEyeLashesAndLacrimalSystem.result.OD.getModel(),
                    OS: this.eyelidsEyeLashesAndLacrimalSystem.result.OS.getModel(),
                },
            },
            conjunctivaLimbusAndSclera: {
                conjunctiva: {
                    OD: this.conjunctivaLimbusAndSclera.conjunctiva.OD.getModel(),
                    OS: this.conjunctivaLimbusAndSclera.conjunctiva.OS.getModel(),
                },
                limbus: {
                    OD: this.conjunctivaLimbusAndSclera.limbus.OD.getModel(),
                    OS: this.conjunctivaLimbusAndSclera.limbus.OS.getModel(),
                },
                sclera: {
                    OD: this.conjunctivaLimbusAndSclera.sclera.OD.getModel(),
                    OS: this.conjunctivaLimbusAndSclera.sclera.OS.getModel(),
                },
                result: {
                    OD: this.conjunctivaLimbusAndSclera.result.OD.getModel(),
                    OS: this.conjunctivaLimbusAndSclera.result.OS.getModel(),
                },
            },
            corneaAndAnteriorChamber: {
                cornea: {
                    OD: this.corneaAndAnteriorChamber.cornea.OD.getModel(),
                    OS: this.corneaAndAnteriorChamber.cornea.OS.getModel(),
                },
                anteriorChamberDepth: {
                    OD: this.corneaAndAnteriorChamber.anteriorChamberDepth.OD.getModel(),
                    OS: this.corneaAndAnteriorChamber.anteriorChamberDepth.OS.getModel(),
                },
                vanHerrickGrading: {
                    OD: this.corneaAndAnteriorChamber.vanHerrickGrading.OD.getModel(),
                    OS: this.corneaAndAnteriorChamber.vanHerrickGrading.OS.getModel(),
                },
                acCellsFlare: {
                    OD: this.corneaAndAnteriorChamber.acCellsFlare.OD.getModel(),
                    OS: this.corneaAndAnteriorChamber.acCellsFlare.OS.getModel(),
                },
                acFindings: {
                    OD: this.corneaAndAnteriorChamber.acFindings.OD.getModel(),
                    OS: this.corneaAndAnteriorChamber.acFindings.OS.getModel(),
                },
                result: {
                    OD: this.corneaAndAnteriorChamber.result.OD.getModel(),
                    OS: this.corneaAndAnteriorChamber.result.OS.getModel(),
                },
            },
            irisAndLens: {
                iris: {
                    OD: this.irisAndLens.iris.OD.getModel(),
                    OS: this.irisAndLens.iris.OS.getModel(),
                },
                lensStatus: {
                    OD: this.irisAndLens.lensStatus.OD.getModel(),
                    OS: this.irisAndLens.lensStatus.OS.getModel(),
                },
                cataractGrading: {
                    OD: this.irisAndLens.cataractGrading.OD.getModel(),
                    OS: this.irisAndLens.cataractGrading.OS.getModel(),
                },
                otherCataractTypes: {
                    OD: this.irisAndLens.otherCataractTypes.OD.getModel(),
                    OS: this.irisAndLens.otherCataractTypes.OS.getModel(),
                },
                result: {
                    OD: this.irisAndLens.result.OD.getModel(),
                    OS: this.irisAndLens.result.OS.getModel(),
                },
            },
            images: {
                OD: this.images.OD.map(i => i.model),
                OS: this.images.OS.map(i => i.model),
            },
        };
        return model;
    }
    public abstract setModel(model: IAntSegmentHaagSlitLampMeasuredData): void;
    public isEmpty(): boolean {
        return (
            this.conjunctivaLimbusAndSclera.conjunctiva.OD.isEmpty() &&
            this.conjunctivaLimbusAndSclera.conjunctiva.OS.isEmpty() &&
            this.conjunctivaLimbusAndSclera.limbus.OD.isEmpty() &&
            this.conjunctivaLimbusAndSclera.limbus.OS.isEmpty() &&
            this.conjunctivaLimbusAndSclera.result.OD.isEmpty() &&
            this.conjunctivaLimbusAndSclera.result.OS.isEmpty() &&
            this.conjunctivaLimbusAndSclera.sclera.OD.isEmpty() &&
            this.conjunctivaLimbusAndSclera.sclera.OS.isEmpty() &&
            this.corneaAndAnteriorChamber.acCellsFlare.OD.isEmpty() &&
            this.corneaAndAnteriorChamber.acCellsFlare.OS.isEmpty() &&
            this.corneaAndAnteriorChamber.acFindings.OD.isEmpty() &&
            this.corneaAndAnteriorChamber.acFindings.OS.isEmpty() &&
            this.corneaAndAnteriorChamber.anteriorChamberDepth.OD.isEmpty() &&
            this.corneaAndAnteriorChamber.anteriorChamberDepth.OS.isEmpty() &&
            this.corneaAndAnteriorChamber.cornea.OD.isEmpty() &&
            this.corneaAndAnteriorChamber.cornea.OS.isEmpty() &&
            this.corneaAndAnteriorChamber.result.OD.isEmpty() &&
            this.corneaAndAnteriorChamber.result.OS.isEmpty() &&
            this.corneaAndAnteriorChamber.vanHerrickGrading.OD.isEmpty() &&
            this.corneaAndAnteriorChamber.vanHerrickGrading.OS.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.result.OD.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.result.OS.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD.isEmpty() &&
            this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS.isEmpty() &&
            this.images.OD.length === 0 &&
            this.images.OS.length === 0 &&
            this.irisAndLens.cataractGrading.OD.isEmpty() &&
            this.irisAndLens.cataractGrading.OS.isEmpty() &&
            this.irisAndLens.iris.OD.isEmpty() &&
            this.irisAndLens.iris.OS.isEmpty() &&
            this.irisAndLens.lensStatus.OD.isEmpty() &&
            this.irisAndLens.lensStatus.OS.isEmpty() &&
            this.irisAndLens.otherCataractTypes.OD.isEmpty() &&
            this.irisAndLens.otherCataractTypes.OS.isEmpty() &&
            this.irisAndLens.result.OD.isEmpty() &&
            this.irisAndLens.result.OS.isEmpty()
        );
    }
}
