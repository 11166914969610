// @ts-nocheck
import { Target } from '../../lib/types'
import { Layout } from '../../lib/Layout'
import { TextBlock } from '../../lib/new/TextBlock'

// import * as d3 from 'd3';

interface Params {
  target: Target
  items: string[]
}

export function PopupLog(p: Params) {
  let g = new Gs(p.target, 'popup-log')

  let items = p.items

  let left = 30 - 5
  let top = 625 - 200
  let right = left
  let bottom = top

  let area = new Layout(left, top, right, bottom, '')

  TextBlock({
    text: items.join('\n'),
    target: g.take,
    alignBottom: true,
    alignLeft: true,
    area,
    styleParams: {
      override: {
        fontSize: 12,
      }
    },
  })
}

class Gs {
  id = 0
  constructor(private target: Target, private name: string) {}

  get take() {
    this.id += 1
    let target = this.target
      .selectAll(`.g-${this.name}-${this.id}`)
      .data([null])
      .join('g')
      .attr('class', `g-${this.name}-${this.id}`)
    return target
  }
}
