import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PostSegmentForus3NethraClassicMeasuredDataFrontend } from '../../../measuredData/FORUS/3NethraClassic/postSegmentForus3NethraDataFrontend.class';

export class PostSegmentForus3NethraClassicTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PostSegmentForus3NethraClassicMeasuredDataFrontend = PostSegmentForus3NethraClassicMeasuredDataFrontend;
    public measurements: PostSegmentForus3NethraClassicMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
