import { TestRemarkFrontend } from '../testRemarkFrontend';
import { SaccadesBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/saccadesBulbicamDataFrontend.class';

export class SaccadeBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof SaccadesBulbicamMeasuredDataFrontend = SaccadesBulbicamMeasuredDataFrontend;
    public measurements: SaccadesBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
