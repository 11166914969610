import { BlockingType } from '../../../commonout/enum/blockingType.enum';
import { IRegisterEditField } from '../../../commonout/interfaces/register-edit-field.interface';
import { Edit } from './edit.model';
/**
 * An abstract class for describing object that holds any data in DB with tracking changes
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 * All argumants in constructor are optional on prder to let user create an empty inctance of this class
 */
export abstract class RegisterEditField<T> implements IRegisterEditField<T> {
    type: BlockingType | undefined; //according to requirements data could be changed later with 2 options: only before 24 hours after creation or anytime
    name: string | undefined; // name of data (what is stored)
    value: T | undefined; // data itself
    lastModified: number | undefined; // when data was modified last time
    edits: Edit<T>[]; // an array of objects that holds all data modifications
    constructor(type?: BlockingType, name?: string, initialValue?: T, lastModified?: number) {
        this.type = type;
        this.name = name;
        this.value = initialValue;
        this.lastModified = lastModified;
        this.edits = [];
    }
    public getModel(): IRegisterEditField<T> {
        let model: IRegisterEditField<T> = {
            type: this.type,
            name: this.name,
            value: this.value,
            lastModified: this.lastModified,
            edits: this.edits,
        };
        return model;
    }
    public setModel(model: IRegisterEditField<T>) {
        this.type = model.type;
        this.name = model.name;
        this.value = model.value;
        this.lastModified = model.lastModified;
        this.edits = model.edits.map(m => new Edit<T>(m));
        return this;
    }
    // method that returns if there is some data is presented in this exact instance
    public isEmpty(): boolean {
        if (Array.isArray(this.value)) {
            // if value is array than it is empty if array length is 0
            return this.value.length === 0;
        } else if (typeof this.value === 'number') {
            // if value is number than it is not empty since any number could not be default value
            return false;
        } else {
            // in any other cases value means that this class is not empty
            return !this.value;
        }
    }
}
