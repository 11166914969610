import { GENDER } from '../../../commonout/enum/gender';
import { RACE } from '../../../commonout/enum/race';
import { OCULUS } from '../enums/oculus.enum';
import { IPatient } from '../interfaces/patient.interface';
import { Examination } from './examination.class';
import { IdcListItem } from './idc/IdcListItem.model';
import { RegisterEditField } from './registerEditField.class';

export abstract class Patient implements IPatient {
    _id?: string;
    registerID: RegisterEditField<string>;
    createdAt: number;
    gender: RegisterEditField<GENDER>;
    race: RegisterEditField<RACE>;
    dateOfBirth: RegisterEditField<number>;
    pupilPupilDistance: RegisterEditField<number>;
    lensValues: {
        sphere: {
            OD: RegisterEditField<number>;
            OS: RegisterEditField<number>;
        };
        cylinder: {
            OD: RegisterEditField<number>;
            OS: RegisterEditField<number>;
        };
        axis: {
            OD: RegisterEditField<number>;
            OS: RegisterEditField<number>;
        };
    };
    city: RegisterEditField<string>;
    country: RegisterEditField<string>;
    phone: RegisterEditField<string>;
    email: RegisterEditField<string>;
    occupation: RegisterEditField<string>;
    maritalStatus: RegisterEditField<string[]>;
    ssn: RegisterEditField<string>;
    insuranceCompany: RegisterEditField<string>;
    insurancePlan: RegisterEditField<string>;
    ocularMedication: RegisterEditField<string>;
    ocularInvestigation: RegisterEditField<string>;
    pastMedicalHistory: {
        selectValue: RegisterEditField<string[]>;
        additionalTextValue: RegisterEditField<string>;
    };
    systemicMedication: RegisterEditField<string>;
    recentInvestigation: RegisterEditField<string>;
    familyHistory: {
        selectValue: RegisterEditField<string[]>;
        additionalTextValue: RegisterEditField<string>;
    };
    birthHistory: {
        selectValue: RegisterEditField<string[]>;
        additionalTextValue: RegisterEditField<string>;
    };
    personalHistory: RegisterEditField<string>;
    allergyHistory: {
        selectValue: RegisterEditField<string[]>;
        additionalTextValue: RegisterEditField<string>;
    };
    mainComplaints: {
        complaint: RegisterEditField<string>;
        oculus: RegisterEditField<OCULUS>;
        duration: RegisterEditField<string>;
    }[];
    pastOcularHistory: {
        condition: RegisterEditField<string>;
        oculus: RegisterEditField<OCULUS>;
        duration: RegisterEditField<string>;
    }[];
    examinations: Examination[];
    diagnosis: RegisterEditField<string>;
    ICD: IdcListItem[];
    constructor() {
        this.examinations = [];
    }

    public abstract setModel(model: IPatient): void;

    public get model(): IPatient {
        let patientModel: IPatient = {
            registerID: this.registerID.getModel(),
            createdAt: this.createdAt,
            gender: this.gender.getModel(),
            race: this.race.getModel(),
            dateOfBirth: this.dateOfBirth.getModel(),
            pupilPupilDistance: this.pupilPupilDistance.getModel(),
            lensValues: {
                sphere: {
                    OD: this.lensValues.sphere.OD.getModel(),
                    OS: this.lensValues.sphere.OS.getModel(),
                },
                cylinder: {
                    OD: this.lensValues.cylinder.OD.getModel(),
                    OS: this.lensValues.cylinder.OS.getModel(),
                },
                axis: {
                    OD: this.lensValues.axis.OD.getModel(),
                    OS: this.lensValues.axis.OS.getModel(),
                },
            },
            city: this.city.getModel(),
            country: this.country.getModel(),
            phone: this.phone.getModel(),
            email: this.email.getModel(),
            occupation: this.occupation.getModel(),
            maritalStatus: this.maritalStatus.getModel(),
            ssn: this.ssn.getModel(),
            insuranceCompany: this.insuranceCompany.getModel(),
            insurancePlan: this.insurancePlan.getModel(),
            ocularMedication: this.ocularMedication.getModel(),
            ocularInvestigation: this.ocularInvestigation.getModel(),
            pastMedicalHistory: {
                selectValue: this.pastMedicalHistory.selectValue.getModel(),
                additionalTextValue: this.pastMedicalHistory.additionalTextValue.getModel(),
            },
            systemicMedication: this.systemicMedication.getModel(),
            recentInvestigation: this.recentInvestigation.getModel(),
            familyHistory: {
                selectValue: this.familyHistory.selectValue.getModel(),
                additionalTextValue: this.familyHistory.additionalTextValue.getModel(),
            },
            birthHistory: {
                selectValue: this.birthHistory.selectValue.getModel(),
                additionalTextValue: this.birthHistory.additionalTextValue.getModel(),
            },
            personalHistory: this.personalHistory.getModel(),
            allergyHistory: {
                selectValue: this.allergyHistory.selectValue.getModel(),
                additionalTextValue: this.allergyHistory.additionalTextValue.getModel(),
            },
            mainComplaints: this.mainComplaints.map(mc => {
                return {
                    complaint: mc.complaint.getModel(),
                    oculus: mc.oculus.getModel(),
                    duration: mc.duration.getModel(),
                };
            }),
            pastOcularHistory: this.pastOcularHistory.map(mc => {
                return {
                    condition: mc.condition.getModel(),
                    oculus: mc.oculus.getModel(),
                    duration: mc.duration.getModel(),
                };
            }),
            examinations: this.examinations.map(e => e.getModel()),
            diagnosis: this.diagnosis.getModel(),
            ICD: this.ICD.map(m => m.model),
        };
        if (this._id) patientModel._id = this._id;
        return patientModel;
    }
}
