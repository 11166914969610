import { DEVICE } from '../../../commonout/enum/device';
import { TEST_STATUS } from '../../../commonout/enum/test-status';
import { TEST_TYPE } from '../../../commonout/enum/test-type';
import { TEST_STAGE } from '../../../commonout/enum/testStage.enum';
import ITest from '../../../commonout/interfaces/test';
import { Examination } from './examination.class';
import { TestRemarks } from './testRemark.class';
/**
 * An abstract class with properties to represent Test entity.
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 * Test entity holds all information that was collected after sertain type of patient's measurement and it's current state.
 */
export abstract class Test implements ITest {
    public readonly type: TEST_TYPE; // represents any of test types from TEST_TYPE enum
    public readonly device: DEVICE; // represents any of devices from DEVICE enum
    public readonly name: string; // test name for representing in user interface
    public stage: TEST_STAGE; // represents any of stages from TEST_STAGE enum
    public status: TEST_STATUS; // represents any of statuses from TEST_STATUS enum
    public remarks: TestRemarks; // see inside
    public examination: Examination; // reference to examination class this test related to
    constructor() {}
    public abstract setModel(model: ITest): void;
    public getModel(): ITest {
        let model: ITest = {
            device: this.device,
            name: this.name,
            stage: this.stage,
            status: this.status,
            type: this.type,
            remarks: this.remarks.getModel(),
        };
        return model;
    }
    // method that returns if there is some data from user or device is presented in this exact test instance
    public isRemarksExist(): boolean {
        return (
            !!this.remarks.result.value ||
            !!this.remarks.diagnosis.value ||
            !!this.remarks.advice.value ||
            !!this.remarks.laboratoryRequested.value ||
            !!this.remarks.medicinesPrescribed.value ||
            !!this.remarks.result_new.OD.value ||
            !!this.remarks.result_new.OS.value ||
            this.remarks.measurements.some(um => {
                return !um.isEmpty();
            })
        );
    }
    // method that returns if this test insnance is equal to the test in argument
    public isEqual(test: Test | ITest): boolean {
        return this.device === test.device && this.type === test.type;
    }
}
