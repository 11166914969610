// @ts-nocheck
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '../../../../_services/general/translate.service';
import { BulbicamChartComponent } from '../haploChart.component';

import { Diagram } from './diagram/Diagram';

@Component({
    selector: 'saccade-test-chart',
    template: require('./saccade-test-chart.component.html'),
    styles: [require('../charts.scss'), require('./saccade-test-chart.component.scss')],
})
export class SaccadeTestChartComponent extends BulbicamChartComponent implements OnInit {
    @ViewChild('saccadeChart') saccadeChart: ElementRef;

    diagram: Diagram;

    constructor(private translateService: TranslateService) {
        super();
        // this.theme = globals.theme;
        // this.zoomed = this.zoomed.bind(this);
        // this.textColor = this.theme === 'dark' ? this.globals.textDark : this.globals.textLight;
    }

    edits: any[] = [];

    ngOnInit() {
        let params = {
            svg: this.saccadeChart.nativeElement,
            theme: 'light',
            edits: this.edits,
            addEdit: (x: any) =>
                this.addEdit(x).then((xs: any) => {
                    this.setEdits(xs);
                    return xs;
                }),
            // this.globals.theme,
            // textColor: this.globals.theme === 'dark' ? this.globals.textDark : this.globals.textLight,
            // globals: this.globals,
            translateService: this.translateService,
        };
        this.diagram = new Diagram(params);

        if (this.inputData) {
            this.addData(this.inputData);
        }
    }

    addData(frames: any) {
        this.diagram.addData(frames);
    }
    public setEdits(xs: any): void {
        this.edits.length = 0;
        this.edits.push(...xs);
        // this.diagram.setEdits(xs)
    }
    clearData() {
        this.diagram.clearState();
    }
}
