import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IIDCComment } from '../../../../../../../../common/interfaces/idc-list-item.interface';
import { IdcListItemComponent } from '../idc-list-item.component';

@Component({
    selector: 'idc-comment-item',
    template: require('./idc-comment-item.component.html'),
    styles: [require('./idc-comment-item.component.scss')],
})
export class IdcCommentItemComponent extends IdcListItemComponent {
    public listItem: IIDCComment;
    constructor(private formBuilder: FormBuilder) {
        super();
    }
    ngOnInit(): void {
        this.form = this.formBuilder.group({
            info: [this.listItem.info, Validators.required],
            comment: [this.listItem.comment, Validators.required],
        });
    }
    public save(): void {
        this.listItem.info = this.form.value.info;
        this.listItem.comment = this.form.value.comment;
        this.onSave(this.listItem);
    }
}
