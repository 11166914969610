// @ts-nocheck
import { d3, Target, Scale } from './lib';

const OD = 0;
const OS = 1;
const POINTS = [
    { side: 0, x: -6.0, y: 6.0, color: 1 },
    //     { side: 0, x: -6.0, y: -6.0, color: 1 },
    //     { side: 0, x: 6.0, y: 6.0, color: 0 },
    //     { side: 0, x: 6.0, y: -6.0, color: 0 },
    //     { side: 0, x: -3.0, y: 15.0, color: 3 },
    //     { side: 0, x: 3.0, y: 15.0, color: 3 },
    //     { side: 0, x: 9.0, y: 15.0, color: 3 },
    //     { side: 0, x: -9.0, y: 15.0, color: 3 },
    //     { side: 0, x: -3.0, y: 21.0, color: 3 },
    //     { side: 0, x: 3.0, y: 21.0, color: 3 },
    //     { side: 0, x: 3.0, y: -15.0, color: 2 },
    //     { side: 0, x: 9.0, y: -15.0, color: 2 },
    //     { side: 0, x: -9.0, y: -15.0, color: 2 },
    //     { side: 0, x: -3.0, y: -15.0, color: 2 },
    //     { side: 0, x: 3.0, y: -21.0, color: 2 },
    //     { side: 0, x: -3.0, y: -21.0, color: 2 },
    //     { side: 0, x: -15.0, y: 3.0, color: 1 },
    //     { side: 0, x: -15.0, y: -3.0, color: 1 },
    //     { side: 0, x: -15.0, y: 9.0, color: 1 },
    //     { side: 0, x: -15.0, y: -9.0, color: 1 },
    //     { side: 0, x: -21.0, y: 3.0, color: 1 },
    //     { side: 0, x: -21.0, y: -3.0, color: 1 },
    //     { side: 0, x: -21.0, y: 9.0, color: 1 },
    //     { side: 0, x: -21.0, y: -9.0, color: 1 },
    //     { side: 0, x: -27.0, y: 3.0, color: 1 },
    //     { side: 0, x: -27.0, y: -3.0, color: 1 },
    //     { side: 1, x: -6.0, y: 6.0, color: 1 },
    //     { side: 1, x: -6.0, y: -6.0, color: 1 },
    //     { side: 1, x: 6.0, y: 6.0, color: 0 },
    //     { side: 1, x: 6.0, y: -6.0, color: 0 },
    //     { side: 1, x: -3.0, y: 15.0, color: 3 },
    //     { side: 1, x: 3.0, y: 15.0, color: 3 },
    //     { side: 1, x: 9.0, y: 15.0, color: 3 },
    //     { side: 1, x: -9.0, y: 15.0, color: 3 },
    //     { side: 1, x: -3.0, y: 21.0, color: 3 },
    //     { side: 1, x: 3.0, y: 21.0, color: 3 },
    //     { side: 1, x: 3.0, y: -15.0, color: 2 },
    //     { side: 1, x: -3.0, y: -15.0, color: 2 },
    //     { side: 1, x: -9.0, y: -15.0, color: 2 },
    //     { side: 1, x: 9.0, y: -15.0, color: 2 },
    //     { side: 1, x: 3.0, y: -21.0, color: 2 },
    //     { side: 1, x: -3.0, y: -21.0, color: 2 },
    //     { side: 1, x: 15.0, y: 3.0, color: 0 },
    //     { side: 1, x: 15.0, y: -3.0, color: 0 },
    //     { side: 1, x: 15.0, y: 9.0, color: 0 },
    //     { side: 1, x: 15.0, y: -9.0, color: 0 },
    //     { side: 1, x: 21.0, y: 3.0, color: 0 },
    //     { side: 1, x: 21.0, y: -3.0, color: 0 },
    //     { side: 1, x: 21.0, y: 9.0, color: 0 },
    //     { side: 1, x: 21.0, y: -9.0, color: 0 },
    //     { side: 1, x: 27.0, y: 3.0, color: 0 },
    //     { side: 1, x: 27.0, y: -3.0, color: 0 },
];
type PointInfos = typeof POINTS;
type PointInfo = PointInfos[number];

interface Points {
    color: number;
    side: number;
    x: number;
    y: number;
}

let join = (data: PointInfos, target: Target, tag: string, marker: [string, string]) =>
    target
        .selectAll(marker[0])
        .data(data)
        .join(tag)
        .attr('class', marker[1]);

let changeDotsColor64 = (points: Points) => {
    points.forEach((el) => {
        if ((el.x === 3 || el.x === -3) && el.y > 0) {
            el.color = 3;
        }
        if ((el.y === 3 || el.y === -3) && el.x < 0) {
            el.color = 1;
        }
        if ((el.y === 3 || el.y === -3) && el.x > 0) {
            el.color = 0;
        }
        if ((el.x < -3 && el.y > 3) || (el.x === -3 && el.y === 3)) {
            el.color = 7;
        }
        if ((el.x === 3 || el.x === -3) && el.y < 0) {
            el.color = 2;
        }
        if ((el.x > 3 && el.y < -3) || (el.x === 3 && el.y === -3)) {
            el.color = 4;
        }
        if ((el.x < -3 && el.y < -3) || (el.x === -3 && el.y === -3)) {
            el.color = 6;
        }
        if ((el.x > 3 && el.y > 3) || (el.x === 3 && el.y === 3)) {
            el.color = 5;
        }
    });
};

let changeDotsColor16 = (points: Points) => {
    points.forEach((el) => {
        if (el.y === 0 && el.x < 0) {
            el.color = 1;
        }
        if (el.y === 0 && el.x > 0) {
            el.color = 0;
        }
        if (el.x === 0 && el.y > 0) {
            el.color = 3;
        }
        if (el.x === 0 && el.y < 0) {
            el.color = 2;
        }
        if (el.x < 0 && el.y > 0) {
            el.color = 7;
        }
        if (el.x > 0 && el.y < 0) {
            el.color = 4;
        }
        if (el.x > 0 && el.y > 0) {
            el.color = 5;
        }
        if (el.x < 0 && el.y < 0) {
            el.color = 6;
        }
    });
};

export interface PointStuff {
    targettype: number;
    targetx: number;
    targety: number;
}
interface DirectionsPreviewParams {
    colors?: string[];
    colorNames?: string[];
    usedPoints: PointStuff[];
    markedPoints: PointStuff[];
    // chosenDirections: number[];
}

export const typeObj = {
    type: '',
    count: 0,
    od: false,
    os: false,
};

export class DirectionsPreview {
    // height = side, width = side + step + side
    side = 200;
    step = 30;
    pointRadius = 10;
    unselectedColor = '#555'; // TODO: style

    os: { x: Scale; y: Scale };
    od: { x: Scale; y: Scale };

    constructor(private params: DirectionsPreviewParams) {
        let xFrom = -30;
        let xTo = 30;
        let yFrom = -25;
        let yTo = 25;

        let scales = (dx: number) => {
            let x = d3
                .scaleLinear()
                .domain([xFrom, xTo])
                .range([dx, dx + this.side]);
            let y = d3
                .scaleLinear()
                .domain([yFrom, yTo])
                .range([this.side, 0]); // bottom corner is with negative values
            return { x, y };
        };
        this.os = scales(0);
        this.od = scales(this.side + this.step);
    }
    render(target: Target, points: PointInfos) {
        let pointsOD = points.filter((x) => x.side === OD);
        let pointsOS = points.filter((x) => x.side === OS);

        if (typeObj.type === 'NEUROFIELD') {
            if (typeObj.count === 64) {
                if (pointsOD.length > 0) {
                    changeDotsColor64(pointsOD);
                }

                if (pointsOS.length > 0) {
                    changeDotsColor64(pointsOS);
                }
            }

            if (typeObj.count === 16) {
                if (pointsOD.length > 0) {
                    changeDotsColor16(pointsOD);
                }

                if (pointsOS.length > 0) {
                    changeDotsColor16(pointsOS);
                }
            }
        }

        let r = this.pointRadius;
        let isUsed = (d: PointInfo) => {
            return !!this.params.usedPoints.find((x) => x.targettype === d.side && x.targetx === d.x && x.targety === d.y);
        };

        let colorIfGiven = (x: number) => (this.params.colors ? this.params.colors[x] : null);
        let omg = (x: string) => (x === 'White' ? x : `${x}VF`);
        let classIfGiven = (x: number) => (this.params.colorNames ? `f${omg(this.params.colorNames[x])}` : '');
        let getClass = (d: PointInfo) => (isUsed(d) ? classIfGiven(d.color) : '');
        let getColor = (d: PointInfo) => (isUsed(d) ? colorIfGiven(d.color) : this.unselectedColor);
        // let getColor = (d: PointInfo) => (this.params.chosenDirections.includes(d.color) ? this.params.colors[d.color] : this.unselectedColor);

        let style = (t: Target) => t.attr('fill', getColor); //.attr('stroke', 'white')
        let addClass = (k: string) => (t: Target) => t.attr('class', (x: any) => `${k} ${getClass(x)}`);
        // t.attr('fill', d => this.params.colors[d.color]) //.attr('stroke', 'white')
        // t.attr('fill', 'orange').attr('stroke', 'white')

        join(pointsOS, target, 'circle', ['.oss', 'oss'])
            .attr('r', r)
            .attr('cx', (d: PointInfo) => this.os.x(d.x))
            .attr('cy', (d: PointInfo) => this.os.y(d.y))
            .call(addClass('oss'))
            .call(style);
        join(pointsOD, target, 'circle', ['.ods', 'ods'])
            // target.selectAll('.ods')
            //   .data(pointsOD)
            //   .join('circle')
            //   .attr('class', 'ods')
            .attr('r', r)
            .attr('cx', (d: PointInfo) => this.od.x(d.x))
            .attr('cy', (d: PointInfo) => this.od.y(d.y))
            .call(addClass('ods'))
            .call(style);

        let markStyle = (t: Target) =>
            t
                .attr('r', r + 5)
                .attr('stroke', 'white')
                .attr('fill', 'none')
                .attr('stroke-width', 5);

        let marksOD = this.params.markedPoints.filter((x) => x.targettype === OD);
        let marksOS = this.params.markedPoints.filter((x) => x.targettype === OS);
        join(marksOD as any, target, 'circle', ['.ods-mark', 'ods-mark'])
            .attr('cx', (d: any) => this.od.x(d.targetx))
            .attr('cy', (d: any) => this.od.y(d.targety))
            .attr('class', 'ods-mark sContrast')
            .call(markStyle);
        join(marksOS as any, target, 'circle', ['.oss-mark', 'oss-mark'])
            .attr('cx', (d: any) => this.os.x(d.targetx))
            .attr('cy', (d: any) => this.os.y(d.targety))
            .attr('class', 'oss-mark sContrast')
            .call(markStyle);
    }
}
