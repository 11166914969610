import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DEVICE } from '../../../../../../../commonout/enum/device';
import { IDeviceSettings } from '../../../../../../../commonout/interfaces/deviceSetting.interface';
import { IAdminFrontendConfigs } from '../../../../../../common/interfaces/frontend-config.interface';
import { IResponse, RESPONSE_STATUS } from '../../../../../../common/interfaces/response.model';
import { ConfigFrontend } from '../../../_models/configFrontend.class';
import { ConfigService } from '../../../_services/general/config.service';
import { ModalService } from '../../../_services/general/modal.service';
import { TranslateService } from '../../../_services/general/translate.service';

@Component({
    selector: 'settings',
    template: require('./settings.component.html'),
    styles: [require('./settings.component.scss')],
})
export class SettingsComponent implements OnInit, OnChanges, OnDestroy {
    private DEVICES: typeof DEVICE = DEVICE;
    private object: Object = Object;
    private settingsForm: FormGroup;
    private settingsModel: ConfigFrontend;
    private supportedLanguages: any[];
    private subscriptions: Array<Subscription> = [];

    constructor(private formBuilder: FormBuilder, private configService: ConfigService, private modalService: ModalService, public translateService: TranslateService) {
        this.supportedLanguages = [
            { display: 'English', value: 'en' },
            { display: 'Magyar', value: 'hu' },
        ];
    }

    ngOnInit(): void {
        this.fillFormGroup();
    }

    ngOnChanges(): void {
        this.fillFormGroup();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    save() {
        if (!this.settingsForm.pristine) {
            this.settingsForm.disable();
            this.modalService.askUser('Save changes?').then(resp => {
                if (resp) {
                    this.update();
                } else {
                    this.settingsForm.enable();
                }
            });
        }
    }

    private fillFormGroup(): void {
        let devicesFormGroup = {};
        this.settingsModel.dynamic.devices.forEach(device => {
            switch (device.type) {
                case DEVICE.HAPLO: {
                    devicesFormGroup[device.type] = this.formBuilder.group({
                        isEnabled: [device.isEnabled],
                        remoteTcpSocketIP: [device.additionalSettings.remoteTcpSocketIP],
                        remoteTcpSocketPort: [device.additionalSettings.remoteTcpSocketPort],
                    });
                    break;
                }
                default:
                    devicesFormGroup[device.type] = this.formBuilder.group({
                        isEnabled: [device.isEnabled],
                        pathToResults: [device.pathToResults],
                    });
                    break;
            }
        });
        this.settingsForm = this.formBuilder.group({
            devices: this.formBuilder.group(devicesFormGroup),
            localization: [this.settingsModel ? this.settingsModel.dynamic.localization : null],
            db: this.formBuilder.group({
                host: [this.settingsModel ? this.settingsModel.dynamic.db.host : null],
                port: [this.settingsModel ? this.settingsModel.dynamic.db.port : null],
                dbname: [this.settingsModel ? this.settingsModel.dynamic.db.dbname : null],
                dbuser: [this.settingsModel ? this.settingsModel.dynamic.db.dbuser : null],
                dbpassword: [this.settingsModel ? this.settingsModel.dynamic.db.dbpassword : null],
            }),
        });
    }

    private async update() {
        const rawConfigModel = this.settingsForm.getRawValue();
        let currentConfigs: IAdminFrontendConfigs = this.settingsModel.model;
        currentConfigs.dynamic.devices = [];

        // SET DEVICES
        for (const deviceName in rawConfigModel.devices) {
            if (rawConfigModel.devices.hasOwnProperty(deviceName)) {
                const element = rawConfigModel.devices[deviceName];
                let deviceSetting: IDeviceSettings;
                switch (deviceName) {
                    case DEVICE.HAPLO: {
                        deviceSetting = {
                            type: deviceName,
                            isConnected: element.isConnected,
                            isEnabled: element.isEnabled,
                            pathToResults: null,
                            additionalSettings: {
                                remoteTcpSocketIP: element.remoteTcpSocketIP,
                                remoteTcpSocketPort: element.remoteTcpSocketPort,
                                testStages: {
                                    SHOWN: [],
                                    HIDDEN: [],
                                },
                            },
                        };
                        break;
                    }
                    default:
                        deviceSetting = {
                            type: deviceName as DEVICE,
                            isConnected: element.isConnected,
                            isEnabled: element.isEnabled,
                            pathToResults: element.pathToResults,
                            additionalSettings: null,
                        };
                        break;
                }
                currentConfigs.dynamic.devices.push(deviceSetting);
            }
        }

        // SET LOCALIZATION
        currentConfigs.dynamic.localization = rawConfigModel.localization;

        // SET DB
        currentConfigs.dynamic.db.host = rawConfigModel.db.host;
        currentConfigs.dynamic.db.port = rawConfigModel.db.port;
        currentConfigs.dynamic.db.dbname = rawConfigModel.db.dbname;
        currentConfigs.dynamic.db.dbuser = rawConfigModel.db.dbuser;
        currentConfigs.dynamic.db.dbpassword = rawConfigModel.db.dbpassword;

        this.configService
            .update(currentConfigs)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    this.ngOnChanges();
                    return throwError('Something bad happened; please try again later.');
                })
            )
            .subscribe((response: IResponse) => {
                if (response.status === RESPONSE_STATUS.ERROR) {
                    this.ngOnChanges();
                }
                this.settingsForm.enable();
            });
    }
}
