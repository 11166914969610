import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../../../commonout/enum/calibration.command.enum';
import { ICalibrationResult } from '../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { IVPosCalibration, IVPosValue } from '../../../../../../../../commonout/interfaces/calibration/vposCalibration.interface';
import { SmartImageFrontend } from '../../../../_models/smartImageFrontend.class';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { FileService } from '../../../../_services/general/file.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { CalibrationTestComponent } from '../calibration-test-component';
import { mockDataTest3 } from '../mockData';

@Component({
    selector: 'vpos-test',
    template: require('./vpos-test.component.html'),
    styles: [require('./vpos-test.component.scss')],
})
export class VPosComponent extends CalibrationTestComponent {
    customOnDestroy(): void {}

    calibrationType: CALIBRATION_TEST = CALIBRATION_TEST.VPOS_TEST;
    public image: FormControl;
    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.DATA_PACKAGE) {
            const smartImage: SmartImageFrontend = new SmartImageFrontend();
            smartImage.model = data.smartImage;
            this.image.setValue(smartImage);

            this.unSavedResult = {
                createdAt: +new Date(),
                result: {
                    osAngle: data.OS_angle,
                    osLength: data.OS_length,
                    odAngle: data.OD_angle,
                    odLength: data.OD_length,
                    image: smartImage,
                },
                isTemp: true,
            };

            this.results.unshift(this.unSavedResult);
        }

        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            this.isTestRunning = false;
            this.isTestDone = true;
        }
    }

    isTestRunning = false;
    isTestDone = false;
    selectedIndex = -1;

    subscriptions: Subscription[] = [];

    results: {
        createdAt: number;
        result: IVPosValue;
        isTemp?: boolean;
    }[];

    unSavedResult: {
        createdAt: number;
        result: IVPosValue;
        isTemp: true;
    } | null = null;

    selectActive(index: number): void {
        if (this.results[index]?.isTemp) return;
        this.selectedIndex = index;

        const smartImage: SmartImageFrontend = new SmartImageFrontend();

        smartImage.model = this.results[index].result.image;
        smartImage.download(this.fileService).then(_ => {
            this.image.setValue(smartImage);
        });
    }

    constructor(bulbicamService: BulbicamService, socketService: SocketService, private fileService: FileService, configService: ConfigService) {
        super(bulbicamService, socketService, configService);
        this.image = new FormControl();

        const subscriber = this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.VPOS_TEST).subscribe(res => {
            this.results = res
                .map((el: ICalibrationResult<IVPosCalibration>) => {
                    return {
                        createdAt: el.createdAt,
                        result: el.results.calibrations,
                    };
                })
                .sort((a, b) => b.createdAt - a.createdAt);
        });
        this.subscriptions.push(subscriber);
    }

    mockData: {
        date: Date;
        os: { range: [number, number]; value: number };
        od: { range: [number, number]; value: number };
    }[] = mockDataTest3;

    toggleTest(): void {
        if (this.isTestRunning) {
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: CALIBRATION_TEST.VPOS_TEST,
                    command: CALIBRATION_COMMAND.STOP,
                })
                .subscribe();
            this.subscriptions.push(sub);
        } else {
            this.socketService.socket.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: CALIBRATION_TEST.VPOS_TEST,
                    command: CALIBRATION_COMMAND.START,
                })
                .subscribe();
            this.subscriptions.push(sub);
            this.selectedIndex = this.selectedIndex === -1 ? this.selectedIndex : this.selectedIndex + 1;
        }
        this.isTestDone = false;

        this.isTestRunning = !this.isTestRunning;
        this.clearTempResult();
    }

    saveResults(): void {
        const smartImage: SmartImageFrontend = new SmartImageFrontend();

        smartImage.model = this.unSavedResult.result.image;
        smartImage.saveToDb(this.fileService);
        const sub = this.bulbicamService
            .saveCalibrationSetting({
                test: this.calibrationType,
                createdAt: +new Date(),
                results: {
                    calibrations: this.unSavedResult.result,
                },
            })
            .pipe(
                concatMap(_ => {
                    return this.bulbicamService.getCalibrationSettings(this.calibrationType);
                })
            )
            .subscribe(res => {
                this.results = res
                    .map((el: ICalibrationResult<IVPosCalibration>) => {
                        return {
                            createdAt: el.createdAt,
                            result: el.results.calibrations,
                        };
                    })
                    .sort((a, b) => b.createdAt - a.createdAt);
            });
        this.subscriptions.push(sub);

        this.clearTempResult();
    }

    clearTempResult() {
        if (this.unSavedResult) {
            this.unSavedResult = null;
            this.results.shift();
            this.selectedIndex--;
        }
    }
}
