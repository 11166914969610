import { MeasuredData } from '../../measuredData.model';
import { IMaculaScanNidekRS330MeasuredData } from '../../../../interfaces/measuredData/NIDEK/RS330/macula-scan-nidek-rs330.measuredData.interface';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { MaculaScan } from '../../../maculaScan.class';

export abstract class MaculaScanNidekRS330MeasuredData implements MeasuredData, IMaculaScanNidekRS330MeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public maculaScan: {
        OD: MaculaScan[];
        OS: MaculaScan[];
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_RS330;
        this.testType = TEST_TYPE.MACULA_SCAN;
        this.maculaScan = {
            OD: [],
            OS: [],
        };
    }
    public abstract setModel(model: IMaculaScanNidekRS330MeasuredData): void;
    public getModel(): IMaculaScanNidekRS330MeasuredData {
        let model: IMaculaScanNidekRS330MeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            maculaScan: {
                OD: this.maculaScan.OD.map((ms) => ms.getModel()),
                OS: this.maculaScan.OS.map((ms) => ms.getModel()),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.maculaScan.OD.length === 0 && this.maculaScan.OS.length === 0;
    }
}
