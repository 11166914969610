// @ts-nocheck
import { Target } from './types';
import * as d3 from 'd3';
import * as _ from 'lodash';

type DeltaCoords = { x?: number; y?: number };
type Point = { x: number; y: number };

export function translate(...points: DeltaCoords[]): string {
    let reducer = (a: Point, b: DeltaCoords) => ({ x: a.x + (b.x || 0), y: a.y + (b.y || 0) });
    let point = points.reduce(reducer, { x: 0, y: 0 });
    return 'translate(' + point.x + ', ' + point.y + ')';
}

let _incrementValue = 0;
let increment = () => {
    _incrementValue += 1;
    return _incrementValue;
};
export function getNewClass(prefix: string): string {
    return `${prefix}-${increment()}`;
}

// nice typechecked setting
//
function apply<T, K extends keyof T>(place: T, key: K, fun: (arg0: T[K]) => T[K]): void {
    place[key] = fun(place[key]);
}

interface HasColor {
    color: string;
}
export function applyColor<T extends HasColor>(place: T, fun: (arg0: string) => string | chroma.Color) {
    return apply(place, 'color', (x) => fun(x).toString());
}

export const fail: (a: string) => never = (text: string) => {
    throw Error(text);
};

export function findClosePoint(points: Point[], xValue: number): Point {
    let result: Point | undefined;
    let prevPoint: Point | undefined;
    points.forEach((p: Point) => {
        if (result) return;
        if (p.x === xValue) return (result = p);
        if (p.x > xValue && prevPoint) return (result = prevPoint);
        prevPoint = p;
    });
    if (!result) result = points[points.length - 1];
    if (!result) result = { x: 0, y: 0 };
    return result;
}

export const noPaths = (x: Target) => x.selectAll('path').remove();

interface Override {
    width?: number;
    gap?: number;
}
export class DirectedLayout {
    swapDirection = false;
    constructor(
        private coordName: string,
        private lengthName: string,
        private x: number,
        private width: number,
        private gap: number,
        private otherProps: object // not sure yet
    ) {
        this.moveBack();
    }
    get(override?: Override) {
        let { x, width, otherProps } = this;

        if (override) {
            if (typeof override.width === 'number') width = override.width;
        }
        let result = { ...otherProps, [this.coordName]: x, [this.lengthName]: width };
        if (this.swapDirection) result[this.coordName] *= -1;
        return result;
    }
    take(override?: Override) {
        this.move(override);
        let result = this.get(override);
        return result;
    }
    move(override?: Override) {
        let { width, gap } = this;
        if (override) {
            if (typeof override.width === 'number') width = override.width;
            if (typeof override.gap === 'number') gap = override.gap;
        }
        this.x += width + gap;
    }
    moveBack(override?: Override) {
        let { width, gap } = this;
        if (override) {
            if (typeof override.width === 'number') width = override.width;
            if (typeof override.gap === 'number') gap = override.gap;
        }
        this.x -= width + gap;
    }
}

export const useTitle = (text: string) => (target: Target) =>
    target
        .selectAll('title')
        .data([null])
        .join('title')
        .text(text);

interface InfoParams {
    at: {
        x: number;
        y: number;
    };
    text: string;
    target: Target;
    klass?: string;
    center?: boolean;
}
export function addInfo(params: InfoParams) {
    let someParams = { center: params.center };
    let x = params.at.x;
    let y = params.at.y;
    addInfoOld(params.target, x, y, params.text, params.klass, someParams);
}

interface InfoSomeParams {
    center?: boolean;
}
const INFO_FONT_SIZE = 25;
export function addInfoOld(target: Target, x: number, y: number, text: string, klass: string = '', params: InfoSomeParams = {}) {
    if (klass === '') {
        klass = getNewClass('info-block');
    }
    klass = `${klass} added-info`;
    let selector = `.${klass}`;
    // console.log(klass, selector)

    let configure: (t: Target) => void = () => {};
    if (params.center) {
        configure = (t: Target) => {
            t.attr('alignment-baseline', 'central');
            t.attr('text-anchor', 'middle');
        };
    }

    let info: Target = target
        .selectAll(selector)
        .data([null])
        .join('g')
        .attr('class', klass)
        .attr('transform', translate({ x, y }))
        .attr('visibility', 'hidden');

    info.selectAll('text')
        .data([text.split('\n')])
        .join('text')
        .attr('fill', 'white')
        .selectAll('tspan')
        .data((d) => d)
        .join('tspan')
        .text((d) => d)
        .attr('dy', INFO_FONT_SIZE)
        .attr('x', 0)
        .attr('font-size', INFO_FONT_SIZE)
        .call(configure);
}

const MAKE_GAP = '';
const makeFat = (x: string) => x && x[0] === '*';
const rawText = (x: string) => x.replace(/^\* /, '');
export function makeSpansGetter<T>(textGetter: (a: T) => string, dy: number) {
    return (t: Target) => {
        return t
            .selectAll('tspan')
            .data((d) => textGetter(d).split('\n'))
            .join('tspan')
            .attr('dy', dy)
            .attr('x', 0)
            .text((d) => (d === MAKE_GAP ? '.' : rawText(d)))
            .style('visibility', (d) => (d === MAKE_GAP ? 'hidden' : 'inherit'))
            .style('font-weight', (d) => (makeFat(d) ? '600' : 'inherit'));
    };
}

interface Drawable {
    x?: number;
    y?: number;
    draw: (t: Target) => void;
}
export const makeSpans = (text: string, dy: number, drawables?: Drawable[]) => (t: Target) => {
    return t
        .selectAll('tspan')
        .data(text.split('\n'))
        .join('tspan')
        .attr('dy', dy)
        .attr('x', 0)
        .text((d) => d);
};
export const makeLines = (text: string, dy: number, drawables?: Drawable[], doReverse: boolean = false) => (t: Target) => {
    let lines = text.split('\n');

    if (doReverse) lines.reverse();
    let data = lines.map((text, index) => ({ text, index }));

    let gs = t
        .selectAll('.text-span')
        .data(data)
        .join('g')
        .attr('class', 'text-span')
        .attr('transform', (d) => translate({ y: dy * d.index, x: 0 }));

    let RE = /@(\d)/;
    // let makeDrawableItem = (x: { text: string, index: number }) => {
    //   let match = text.match(RE)
    //   if (match) {
    //     let index = (parseInt(match[1]) - 1) || 0
    //     let drawable = (drawables || [])[index] || { draw: () => {} }
    //     return [{ drawable, index }]
    //   } else {
    //     return [{ nothing: true }] as any
    //   }
    // }
    let makeTextItem = (x: { text: string; index: number }) => {
        let { text, index } = x;
        let match = text.match(RE);
        if (match) {
            let rest = text.replace(RE, '');
            if (rest) {
                return [{ text: rest, index }];
            } else {
                return []; // no text items
            }
        } else {
            return [{ text, index }];
        }
    };
    // console.log(111)
    // let idk = gs
    //   .selectAll('g')
    //   .data(makeDrawableItem)
    //   .join('g')

    // console.log(idk)
    // console.log(222)
    // idk.each((...x) => {
    //   console.log(x)
    // })
    // .each((d, index, nodes) => {
    //   console.log(d, index, nodes)
    //   let t = nodes[index] as any
    //   // console.log(nodes, index, t)
    //   d.drawable.draw(d3.select(t))
    // })

    gs.selectAll('text')
        .data(makeTextItem)
        .join('text')
        .text((d) => d.text);

    return gs; // unused likely, since .call is used by default
};

interface LT {
    left: number;
    top: number;
}
export const doTranslate = (p: DeltaCoords | LT) => (t: Target) => {
    let pp = p as any;

    if (typeof pp.left === 'number') {
        pp = { x: pp.left, y: pp.top };
    }
    t.attr('transform', translate(pp));
};

export const nonSelectable = (x: Target) =>
    x.attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;');

export const nonInteractive = (x: Target) => x.attr('pointer-events', 'none');
export const ghost = nonInteractive;
export const makeInteractive = (x: Target) => x.attr('pointer-events', 'auto');
export const nonGhost = makeInteractive;

export function eachCons3<T>(xs: T[], f: (prev: T | null, curr: T, nexty: T | null) => void) {
    xs.forEach((curr, i) => {
        let prev = xs[i - 1] || null;
        let nexty = xs[i + 1] || null;
        f(prev, curr, nexty);
    });
}

export interface DispersePoints {
    x: number;
    y: number;
    fixedX: number;
}

// NOTE: disperse over y-axis
export function dispersePoints(numbers: DispersePoints[], disperseStrength: number, disperseDisatanceMax: number, disperseIterations: number) {
    let simulation = d3.forceSimulation();
    simulation.stop();
    // inital ordering of equal values
    let delta = 0.0;
    let incrementDelta = () => (delta += 0.00001);
    let knownValues = {};

    numbers.forEach((one) => {
        let value = one.y;
        let setValue = (given: number) => (one.y = given);
        if (knownValues[value]) {
            incrementDelta();
            value = value + delta;
            setValue(value);
        }
        knownValues[value] = true;
    });
    let disperse = d3
        .forceManyBody()
        .strength(disperseStrength)
        .distanceMax(disperseDisatanceMax);
    simulation.nodes(numbers);
    simulation.force('disperse', disperse);
    _.times(disperseIterations, () => {
        simulation.tick(10);
        numbers.forEach((one) => {
            one.x = one.fixedX;
        });
    });
}

export interface DispersePoints2 {
    x: number;
    y: number;
}
export function dispersePoints2(numbers: DispersePoints2[], disperseStrength: number, disperseDisatanceMax: number, disperseIterations: number, oneIteration: number) {
    let simulation = d3.forceSimulation();
    simulation.stop();
    // inital ordering of equal values
    // let delta = 0.0;
    // let incrementDelta = () => (delta += 0.00001);
    // let knownValues = {};
    // numbers.forEach((one) => {
    //     let value = one.y;
    //     let setValue = (given: number) => (one.y = given);
    //     if (knownValues[value]) {
    //         incrementDelta();
    //         value = value + delta;
    //         setValue(value);
    //     }
    //     knownValues[value] = true;
    // });
    let disperse = d3
        .forceManyBody()
        .strength(disperseStrength)
        .distanceMax(disperseDisatanceMax);
    simulation.nodes(numbers);
    simulation.force('disperse', disperse);
    _.times(disperseIterations, () => {
        simulation.tick(oneIteration);
        // numbers.forEach((one) => {
        //     one.x = one.fixedX;
        // });
    });
}

export function randomColor() {
    let hex = _.times(3, () => _.sample(['3', '6', '9', 'b', 'e'])).join('');
    return `#${hex}`;
}

export type Domain = [number, number];

export interface RawPoint {
  x: number
  y: number
}

let applyClass = (klass: string) => (t: Target) => t.attr('class', klass);
// let getGs = (firstKlass: string, secondKlass: string, target: Target, xs) => {
        //   let gSeries = target
        //       .selectAll(`.${seriesKlass}`)
        //       .data(p.series)
        //       .join('g')
        //       .attr('class', seriesKlass);

        //   let gDataPoints = gSeries
        //       .selectAll(`.${pointsKlass}`)
        //       .data((d: any) => p.pointsMapping(d))
        //       .join('g')
        //       .attr('class', pointsKlass);

        //   return { gSeries, gDataPoints }
        // }
let getG = (target: Target, klass: string) => {
  return target
    .selectAll(`.${klass}`)
    .data([null])
    .join('g')
    .call(applyClass(klass));
}
export function dynamicTargets(root: Target) {
  let i = 0
  return () => {
    i += 1
    return getG(root, `target-${i}`)
  }
}
export function getStyle<S>(style: S, themeOverrides: { [k: string]: Partial<S> }, params?: { theme?: string, override?: Partial<S> }): S {
  params = params || {}
  let override = themeOverrides[params.theme || '']
  if (override) style = { ...style, ...override }
  if (params.override) style = { ...style, ...params.override }
  return style
}

// class Gs {
//   id = 0
//   constructor(private target: Target) {}

//   get take() {
//     this.id += 1
//     let target = this.target
//       .selectAll(`.g-${this.id}`)
//       .data([null])
//       .join('g')
//       .attr('class', `g-${this.id}`)
//     return target
//   }
// }


export class Selector {
  get selectAll() {
    return this.takeSelector
  }
  get class() {
    return this.prevTaken
  }

  index = 0
  get take() {
    this.index += 1
    return this.prevTaken
  }
  get prevTaken() {
    return this.klassFor(this.index - 1)
  }
  get takeSelector() {
    let klass = this.take
    return `.${klass}`
  }

  private klassFor(n: number) {
    return `klass-${n}`
  }
}

export function noop() {}

export const TYPE_OPTIONS = [
    'pendular',
    'jerk',
    '—',
  ]