import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../../../commonout/enum/calibration.command.enum';
import { ICalibrationResult } from '../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { IScreenCalibration } from '../../../../../../../../commonout/interfaces/calibration/screenCalibration.interface';
import { concatMap } from 'rxjs/operators';
import { ConfigService } from '../../../../_services/general/config.service';
@Component({
    selector: 'screen-calibration',
    template: require('./screen-calibration.component.html'),
    styles: [require('./screen-calibration.component.scss')],
})
export class ScreenCalibrationComponent extends CalibrationTestComponent {
    customOnDestroy(): void {
        if (this.configService.camState) {
            this.stopTest();
        }
    }
    calibrationType: CALIBRATION_TEST = CALIBRATION_TEST.SCREEN_CALIBRATION;
    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.buttons.forEach(d => {
                d.isActive = false;
            });
            this.isButtonSelected = false;
            this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        }
    }
    constructor(bulbicamService: BulbicamService, socketService: SocketService, configService: ConfigService) {
        super(bulbicamService, socketService, configService);
        const sub = this.bulbicamService.getCalibrationSettings(this.calibrationType).subscribe(res => {
            this.infoList = res.map((d: ICalibrationResult<IScreenCalibration>) => ({
                createdAt: d.createdAt,
                distance: d.results.distance
            }))
        });
        this.subscriptions.push(sub);
    }

    subscriptions: Subscription[] = [];

    infoList: { 
        createdAt: number,
        distance: number
    }[];

    isButtonSelected = false;
    selectedValue: { date: Date, value: string };

    buttons: { id: number, text: string, isActive: boolean }[] = [
        { id: 0, text: '1m', isActive: false },
        { id: 1, text: '2m', isActive: false },
        { id: 2, text: '3m', isActive: false },
        { id: 3, text: '4m', isActive: false },
        { id: 4, text: '5m', isActive: false },
        { id: 5, text: '6m', isActive: false },
    ];

    selectButton(buttonId: number): void {
        this.buttons.forEach(d => {
            d.isActive = false;
        });
        if (this.isButtonSelected) {
            const sub = this.bulbicamService.sendCalibrationCommand({
                test: CALIBRATION_TEST.SCREEN_CALIBRATION,
                command: CALIBRATION_COMMAND.STOP,
            }).subscribe();
            this.subscriptions.push(sub);
            this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        }
        this.buttons[buttonId].isActive = true;
        this.selectedValue = { date: new Date(), value: this.buttons[buttonId].text };
        this.socketService.socket.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
        const sub = this.bulbicamService.sendCalibrationCommand({
            test: CALIBRATION_TEST.SCREEN_CALIBRATION,
            command: CALIBRATION_COMMAND.START,
            distance: parseInt(this.buttons[buttonId].text)
        }).subscribe();
        this.subscriptions.push(sub);
        this.isButtonSelected = true;
    }

    saveResults(): void {
        const infoValue = this.selectedValue.value;
        const sub = this.bulbicamService.saveCalibrationSetting({
            test: this.calibrationType,
            createdAt: +new Date(),
            results: {
                distance: parseInt(infoValue)
            }
        }).pipe(
            concatMap(_ => {
                return this.bulbicamService.getCalibrationSettings(this.calibrationType);   
            })
        ).subscribe(res => {
            this.infoList = res.map((d: ICalibrationResult<IScreenCalibration>) => ({
                createdAt: d.createdAt,
                distance: d.results.distance
            }))
        });
        this.subscriptions.push(sub);

        this.stopTest();
        // this.isButtonSelected = false;
        // this.buttons.forEach(d => { d.isActive = false; });
    }

    stopTest() {
        const sub = this.bulbicamService.sendCalibrationCommand({
            test: CALIBRATION_TEST.SCREEN_CALIBRATION,
            command: CALIBRATION_COMMAND.STOP,
        }).subscribe();
        this.subscriptions.push(sub);

        this.buttons.forEach(d => {
            d.isActive = false;
        });
        this.isButtonSelected = false;
        this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);
    }

    /**
     * to send commend use
     * this.bulbicamService.sendCalibrationCommand(CALIBRATION_COMMAND.START, CALIBRATION_TEST.TEST1, this.socketService.id, { // any data you need to provide calibration tool with // })
     */
}
