import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { IFixationPayloadData } from '../../../../../../../../common/interfaces/fixationPayloadData.interface';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'fixation-test',
    template: require('./fixation-test-controls.component.html'),
    styles: [require('./fixation-test-controls.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class FixationTestControlsComponent extends BulbicamControlComponent implements OnInit, OnDestroy {
    public controls: FormGroup;
    private subscriptions: Subscription[];
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService, public formBuilder: FormBuilder) {
        super(bulbicamService, configService, socketService, authService);
        this.subscriptions = [];
    }
    ngOnDestroy(): void {
        this.subscriptions.map(s => s.unsubscribe());
    }
    ngOnInit(): void {
        this.controls = this.formBuilder.group({
            testLength: [4, [Validators.required, Validators.min(1), Validators.max(60)]],
            breakLength: [4, [Validators.required, Validators.min(1), Validators.max(60)]],
            repetitions: [10, [Validators.required, Validators.min(1), Validators.max(100)]],
        });
    }
    public start(): void {
        this.pendingResponse = true;
        this.controls.disable();
        const rawFormValue: IFixationPayloadData = this.controls.getRawValue();
        rawFormValue.command = TEST_COMMAND.START;
        rawFormValue.testType = this.test.type;
        rawFormValue.username = this.authService.currentUser.username;
        this.subscriptions.push(
            this.bulbicamService
                .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, rawFormValue)
                .subscribe(() => {
                    this.test.status = TEST_STATUS.IN_PROGRESS;
                    this.pendingResponse = false;
                })
        );
    }
    public enable(): void {
        this.controls.enable();
    }
    public disable(): void {}
}
