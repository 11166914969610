import { ColorTest } from '../../../../../../commonout/classes/tests/colorTest';
import { applyMixins } from '../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../common/enums/test-result-type.enum';
import { ColorTestRemarksComponent } from '../../_components';
import { ColorTestRemarksFrontend } from '../test-remarks/colorTestRemarksFrontend.class';
import { TestFrontend } from './testFrontend.class';

export class ColorTestFrontend extends ColorTest {
    remarks: ColorTestRemarksFrontend;
    public readonly remarksComponent: typeof ColorTestRemarksComponent = ColorTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new ColorTestRemarksFrontend();
    }
}

export interface ColorTestFrontend extends TestFrontend {}
applyMixins(ColorTestFrontend, [TestFrontend]);
