export function xcorr(firstArray: number[], secondArray: number[]): number[] {
    let additionalArray: number[] = [];
    const xcorr = [];
    
    // if arrays lengths are different
    const difference = firstArray.length - secondArray.length;
    const length = firstArray.length * 2 - 1 - difference;

    additionalArray.length = firstArray.length;
    additionalArray.fill(0);

    for (let i = 0; i < length; i++) {
        let xcorrValue = 0;

        // assign the value from the second array to the begining of first array. Order: from the end
        const factor = i + 1;
        secondArray.length - factor >= 0
            ? additionalArray.unshift(secondArray[secondArray.length - factor])
            : additionalArray.unshift(0);

        additionalArray.pop();
        
        // for optimization: doesn't calculate after array has zero
        if (xcorr.length < firstArray.length - 1) {
            for (let j = 0; j < firstArray.length; j++) {
                if (additionalArray[j] === 0) {
                    break;
                }
                xcorrValue += firstArray[j] * additionalArray[j];
            }
        }
        else {
            for (let j = firstArray.length - 1; j >= 0; j--) {
                if (additionalArray[j] === 0) {
                    break;
                }
                xcorrValue += firstArray[j] * additionalArray[j];
            }
        }

        xcorr.push(xcorrValue);
    }

    return xcorr;
}


    
