import { OCULUS } from '../enums/oculus.enum';
import { IVUE_OCT_PROCEDURES } from '../enums/ivue-oct-procedures';
import { IIvueOCTv3217Image } from '../interfaces/measuredData/IVUE/OCT/3dscan-ivue-oct_v3217.measuredData.interface';
import { SmartImage } from './smartImage.class';
/**
 * An abstract class that represents Iview OCT 3217 device result (images)
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 */
export abstract class IvueOctv3217Image implements IIvueOCTv3217Image {
    public filename: string;
    public birthdate: number;
    public createdAt: number;
    public procedure: IVUE_OCT_PROCEDURES;
    public oculus: OCULUS;
    public image: SmartImage;
    constructor() {}
    ssn: string;
    public setModel(model: IIvueOCTv3217Image) {
        this.createdAt = model.createdAt;
        this.birthdate = model.birthdate;
        this.procedure = model.procedure;
        this.oculus = model.oculus;
        this.ssn = model.ssn;
        this.image.model = model.image;
    }
    public get model(): IIvueOCTv3217Image {
        let model: IIvueOCTv3217Image = {
            filename: this.filename,
            birthdate: this.birthdate,
            createdAt: this.createdAt,
            procedure: this.procedure,
            oculus: this.oculus,
            ssn: this.ssn,
            image: this.image.model,
        };
        return model;
    }
}
