// @ts-nocheck
// import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target } from '..';
// import { doTranslate } from '../lib';
import { getStyle, RawPoint } from './util'
import { TimerService } from './TimerService'

// styling

const styleDefault = {
  fillColor: 'transparent',
  lineColor: '#eee',
  lineKlass: '',
  lineSize: 1,
}

type Style = typeof styleDefault

const themeOverrides: { [k:string]: Partial<Style> } = {
  bright: {
    // lineColor: '#123',
  },
}

// interface

interface Params {
    center: RawPoint
    radius: number
    onClick?: (multiplier: number) => void

    target: Target;
    // area: Layout;
    styleParams?: {
      theme?: string,
      override?: Partial<Style>,
    }
}

let timer = new TimerService()

// mouse down and wait parameters
let INITIAL_PAUSE = 500
let INTERVAL = 100
let QUICK_MULTIPLIER = 10

export function Circle(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {})

    p.target
        .selectAll('.circle')
        .data([null])
        .join('ellipse')
        .attr('class', 'circle ' + style.lineKlass)

        .attr('cx', p.center.x)
        .attr('cy', p.center.y)
        .attr('rx', p.radius)
        .attr('ry', p.radius)
        // .call(doTranslate({ x: p.center.x, y: p.center.y }))
        // .attr('width', x2 - x1)
        // .attr('height', y2 - y1)

        .attr('fill', style.fillColor)
        .attr('stroke-width', style.lineSize)
        .attr('stroke', style.lineColor)

        .on('mousedown', (d:any) => {
          p.onClick && p.onClick(1)
          timer.timeout(INITIAL_PAUSE, () => {
            timer.interval(INTERVAL, () => {
              p.onClick && p.onClick(QUICK_MULTIPLIER)
            })
          })
        })
        .on('mouseup', timer.clear)
        .on('mouseleave', timer.clear)

        // .on('mouseenter', (d:any) => d.onEnter && d.onEnter())
        // .on('mouseleave', (d:any) => d.onExit && d.onExit())
        .attr('cursor', p.onClick ? 'pointer' : 'default')
}
