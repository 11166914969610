// @ts-nocheck
import { State } from './State';
import { USE_FAKE_DATA } from '../constants';
import * as _ from 'lodash';

// Note:
// - actual data is different and then I map it to what is there
// - seen is not being sent also

// Assumptions:
// - when "seen" hits zero it is considered a point of user loosing the target

/*
 * Example message sequence:
 *
 * - test start
 *
 *   - run start
 *
 *     - data point
 *     - data point
 *     - ...
 *
 *   - run start
 *
 *     - ...
 *
 * - test info (can be anywhere)
 *
 * - test stop
 */

// Used payload:

interface Common {
    message_type: number;
    timestamp: number;
}

export interface TestStart extends Common {
    runLength: number; // seconds
}

export interface TestStop extends Common {}

export interface TestInfo extends Common {
    info: string[]; // string lines
}

export interface RunStart extends Common {
    eye: number; // 0-od, 1-os, 10-training-od, 11-training-os ~ constants.ts
    //10,11 outdated info
    training: boolean;
}

export interface DataPoint extends Common {
    // actual payload:
    v1: number;
    v2: number;

    pod: number;
    pos: number;

    type: number;

    // I calculate these from whatever I receive
    time_run: number;
    time_test: number;

    mapod: number;
    mapos: number;

    seenPoint: XY;

    v3: number;

    vv1: number;
    vv2: number;
    vv3: number;
    // seen: number // 0..1: 1-seen, 0-point of breaking
}

// Common structure test and message types

const isCommon = (x: any): boolean => typeof x === 'object' && x !== null && typeof x.message_type === 'number' && typeof x.timestamp === 'number';

const isTestStart = (x: any): x is TestStart => x.message_type === 0;

const isTestStop = (x: any): x is TestStop => x.message_type === 1;

const isRunStart = (x: any): x is RunStart => x.message_type === 2;

const isSequenceStop = (x: any): boolean => x.message_type === 3;

const isDataPoint = (x: any): x is DataPoint => x.message_type === 6;

const isTestInfo = (x: any): x is TestInfo => x.message_type === 4;

// Everything together

export class Protocol {
    constructor(private state: State) {}
    input(given: unknown) {
        if (!USE_FAKE_DATA) this.handleInput(given);
    }
    fakeInput(given: unknown) {
        if (USE_FAKE_DATA) showData(given);
        if (USE_FAKE_DATA) this.handleInput(given);
    }
    handleInput(given: unknown): void {
        if (Array.isArray(given)) return given.forEach((x) => this.handleInput(x));

        this.handleInput2(given);

        // let g = given as any
        // if (g && g.message_type === 2) { // 15
        //   // let ignoredKeys = [
        //   //   'timestamp',
        //   //   'message_type',
        //   //   'chartTypeString',
        //   //   'vv1',
        //   //   'vv2',
        //   //   'eye',
        //   // ]
        //   // let keys = Object.keys(g).filter(x => !ignoredKeys.includes(x))
        //   // let info: string[] = ['']
        //   // keys.forEach(key => {
        //   //   let value = g[key]
        //   //   info.push(`${key}: ${value}`)
        //   // })
        //   // let msg = {
        //   //   message_type: 4,
        //   //   timestamp: 0,
        //   //   info,
        //   // }
        //   // this.handleInput2(msg)
        // }
    }

    handleInput2(given: unknown): void {
        let g = given as any;

        if (g && g.message_type === 15) g.message_type = 2;
        if (g && g.message_type === 16) g.message_type = 3;
        if (g && g.message_type === 0) g.runLength = 20;
        // g.seen = 0 //_.random(0, 1)
        g.vv1 = g.v1;
        g.vv2 = g.v2;
        if (!g.vv3) g.vv3 = 0;
        if (g.vv3 > 10000) g.vv3 = 0;
        if (g.vv3 < -10000) g.vv3 = 0;

        // g.pod = _.random(1,1000) == 1 ? _.random(1,10) : 0
        // g.pos = _.random(1,1000) == 1 ? _.random(1,5) : 0
        g.pod = g.pod || 0;
        g.pos = g.pos || 0;

        if (g.logmar && g.logmar > 1) g.logmar = 1;

        if (g.type === 2) {
            g.type = 3;
        } else if (g.type === 3) {
            g.type = 2;
        }

        if (isCommon(given)) {
            // complete state reset on start?
            if (isTestStart(given)) return this.state.onTestStart(given);
            if (isRunStart(given)) return this.state.onRunStart(given);
            if (isDataPoint(given)) return this.state.onDataPoint(given);
            if (isSequenceStop(given)) return this.state.onSequenceStop(given);
            if (isTestStop(given)) return this.state.onTestStop(given);
            if (isTestInfo(given)) return this.state.onTestInfo(given);
        }
        console.log(`unhandled input:`, given);
    }
}

interface XY {
    x: number;
    y: number;
}

function showData(xs: any) {
    console.log(xs.map((x: any) => JSON.stringify(x)).join('\n'));
}
