// @ts-nocheck
import { Target, Layout } from '..';
import { RawPoint } from './util';
import { ADataResult, AData } from './AData';
import { ChartBg } from './ChartBg';

export interface IDataSet<D> {
    id: number;
    dataPoints: D[];
}

interface Params<D> {
    // scale,domain,data stuff
    xFrom?: number;
    yFrom?: number;
    xTo?: number;
    yTo?: number;

    xBaseDomain?: [number, number];
    yBaseDomain?: [number, number];

    nice?: boolean;
    clamp?: boolean;

    series: IDataSet<D>[];
    positioning: (d: D, s: IDataSet<D>) => RawPoint;
    coloring: (d: D, s: IDataSet<D>) => string;
    enabled?: boolean;
    filter?: (d: D) => boolean;

    zeroAtTheTop?: boolean;
    zeroAtTheRight?: boolean;

    target: Target;
    area: Layout;

    showDots?: boolean;
}

export function AChart<D>(p: Params<D>) {
    let aData: any = AData<D>({ ...p });
    return AChart2<D>({ ...p, aData });
}

interface Params2<D> {
    aData: ADataResult<D>;
    showDots?: boolean;
    zeroAtTheTop?: boolean;
    zeroAtTheRight?: boolean;
    target: Target;
    area: Layout;
}

export function AChart2<D>(given: Params2<D>) {
    let p = { ...given };
    if (p.showDots == null) p.showDots = true;

    let {
        flatData,
        domainX,
        domainY,
        xScale,
        yScale,
        mapping,
        filtering,
        enabled,
        series,
        positioning,
        coloring, // omg just passing that stuff through
    } = p.aData;

    let scale = {
        x: p.zeroAtTheRight ? xScale.range(p.area.rangeXSwap()) : xScale.range(p.area.rangeX()),
        y: p.zeroAtTheTop ? yScale.range(p.area.rangeY()) : yScale.range(p.area.rangeYSwap()),
    };

    let makeSeries = (seriesKlass: string, target: Target) => {
        let gSeries = target
            .selectAll(`.${seriesKlass}`)
            .data(enabled ? series : [])
            .join('g')
            .attr('class', seriesKlass);
        return gSeries;
    };
    let makeStructure = (seriesKlass: string, pointsKlass: string, target: Target) => {
        let gSeries = makeSeries(seriesKlass, target);

        let gDataPoints = gSeries
            .selectAll(`.${pointsKlass}`)
            .data((aseries: any) => {
                let series = aseries as IDataSet<D>;
                return series.dataPoints.filter(filtering).map((d) => mapping(d, series));
            })
            .join('g')
            .attr('class', pointsKlass);
        return { gSeries, gDataPoints };
    };

    ChartBg({
        ...p,
        scale,
        show: p.showDots,
    });

    return {
        flatData,
        domainX,
        domainY,
        positioning,
        coloring,
        filtering,
        enabled,
        scale,
        makeSeries,
        makeStructure,
    };
}
