import { DEVICE } from '../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../commonout/enum/test-type';
import { INystagmusEvaluationBulbicamMeasuredData } from '../../../interfaces/measuredData/BulbiCAM/nystagmusEvaliationBulbicam.measuredData.interface';
import { CamTest } from '../../haplotest.class';
import { MeasuredData } from '../measuredData.model';

export abstract class NystagmusEvaluationBulbicamMeasuredData implements MeasuredData, INystagmusEvaluationBulbicamMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public haplotests: CamTest[];
    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.NYSTAGMUS_EVALUATION;
        this.haplotests = [];
    }
    public abstract setModel(model: INystagmusEvaluationBulbicamMeasuredData): void;
    public getModel(): INystagmusEvaluationBulbicamMeasuredData {
        let model: INystagmusEvaluationBulbicamMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            haplotests: this.haplotests.map(htm => htm.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.haplotests.length === 0;
    }
}
