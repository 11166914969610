// @ts-nocheck
// import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target } from './types';
// import { doTranslate } from '../lib';
import { getStyle, RawPoint } from './util';

// styling

const styleDefault = {
    fillColor: 'transparent',
    lineColor: '#eee',
    lineKlass: '',
    lineSize: 1,
    opacity: '100%',
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {
    bright: {
        // lineColor: '#123',
    },
};

// interface

interface Params {
    from: RawPoint;
    to: RawPoint;

    target: Target;
    // area: Layout;
    styleParams?: {
        theme?: string;
        override?: Partial<Style>;
    };
}

// function

export function Line(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

    let x1 = Math.min(p.from.x, p.to.x);
    let x2 = Math.max(p.from.x, p.to.x);

    let y1 = Math.min(p.from.y, p.to.y);
    let y2 = Math.max(p.from.y, p.to.y);

    p.target
        .selectAll('.line')
        .data([null])
        .join('line')
        .attr('class', 'line ' + style.lineKlass)

        .attr('x1', x1)
        .attr('x2', x2)
        .attr('y1', y1)
        .attr('y2', y2)
        // .call(doTranslate({ x: x1, y: y1 }))
        // .attr('width', x2 - x1)
        // .attr('height', y2 - y1)

        .style('fill', style.fillColor)
        .style('stroke-width', style.lineSize)
        .style('stroke', style.lineColor)
        .attr('opacity', style.opacity);
}
