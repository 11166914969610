import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { IDC_ITEM_TYPE } from '../../../../common/enums/idc-item-type.enum';
import { IIDCListItem } from '../../../../common/interfaces/idc-list-item.interface';
import { IdcCommentItemComponent, IdcDiagnosisItemComponent, IdcTagItemComponent } from '../_components';
import { IdcListItemComponent } from '../_components/general/ICD/items/idc-list-item.component';

@Directive({
    selector: '[idc-list-item]',
})
export class IdcListItemDirective implements OnInit {
    @Input() listItem: IIDCListItem;
    @Input() onSave: (listItem: IIDCListItem) => void;
    @Input() onCancel: (listItem: IIDCListItem) => void;
    @Input() onDelete: (listItem: IIDCListItem) => void;
    public instance: IdcListItemComponent;
    constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}
    ngOnInit(): void {
        let idcListItemComponent;
        switch (this.listItem.type) {
            case IDC_ITEM_TYPE.DIAGNOSIS:
                idcListItemComponent = IdcDiagnosisItemComponent;
                break;
            case IDC_ITEM_TYPE.COMMENT:
                idcListItemComponent = IdcCommentItemComponent;
                break;
            case IDC_ITEM_TYPE.TAG:
                idcListItemComponent = IdcTagItemComponent;
                break;
            default:
                break;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<IdcListItemComponent>(idcListItemComponent);
        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent<IdcListItemComponent>(componentFactory);
        this.instance = componentRef.instance;
        this.instance.listItem = this.listItem;
        this.instance.onSave = this.onSave;
        this.instance.onCancel = this.onCancel;
        this.instance.onDelete = this.onDelete;
    }
}
