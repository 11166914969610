import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TestClassProvider, TestHepler } from '../../../../../../commonout/classes/testClass.provider';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_RESULT } from '../../../../../../commonout/enum/test-result.enum';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IGrade } from '../../../../../../commonout/interfaces/grade.interface';
import { IUser } from '../../../../../../commonout/interfaces/user';
import { TEST_RESULT_TYPE } from '../../../../../common/enums/test-result-type.enum';
import { TestFrontend } from '../../_models/tests/testFrontend.class';
import { ConfigService } from '../general/config.service';

@Injectable()
export class TestService {
    public readonly columnsOrder: string[] = ['OD', 'OU', 'OS'];
    constructor(private classesProvider: TestClassProvider, private configService: ConfigService, private http: HttpClient) {}
    public getTestsByDevice(device: DEVICE): TestHepler[] {
        return this.classesProvider.testsProperties.filter(p => p.device === device);
    }
    public isResultButtonsDrown(test: TestFrontend, columnName: string): boolean {
        if (test.result_type === TEST_RESULT_TYPE.NONE) return false;
        if (columnName === 'OD' || columnName === 'OS') {
            if (test.result_type === TEST_RESULT_TYPE.DOUBLE) {
                return true;
            } else {
                return false;
            }
        } else if (columnName === 'OU') {
            if (test.result_type === TEST_RESULT_TYPE.SINGLE) {
                return true;
            } else {
                return false;
            }
        }
    }
    public getResultButtonClass(test: TestFrontend, columnName: string, button: TEST_RESULT): string {
        let className: string;
        switch (columnName) {
            case 'OD':
                if (button === TEST_RESULT.ABNORMAL) {
                    className = 'abnormal ' + (test.remarks.result_new.OD.value === TEST_RESULT.ABNORMAL ? 'on' : 'off');
                } else if (button === TEST_RESULT.NORMAL) {
                    className = 'normal ' + (test.remarks.result_new.OD.value === TEST_RESULT.NORMAL ? 'on' : 'off');
                } else if (button === TEST_RESULT.CAN_NOT_COMPREHEND) {
                    className = 'normal ' + (test.remarks.result_new.OD.value === TEST_RESULT.CAN_NOT_COMPREHEND ? 'on' : 'off');
                }
                break;
            case 'OS':
                if (button === TEST_RESULT.ABNORMAL) {
                    className = 'abnormal ' + (test.remarks.result_new.OS.value === TEST_RESULT.ABNORMAL ? 'on' : 'off');
                } else if (button === TEST_RESULT.NORMAL) {
                    className = 'normal ' + (test.remarks.result_new.OS.value === TEST_RESULT.NORMAL ? 'on' : 'off');
                } else if (button === TEST_RESULT.CAN_NOT_COMPREHEND) {
                    className = 'normal ' + (test.remarks.result_new.OS.value === TEST_RESULT.CAN_NOT_COMPREHEND ? 'on' : 'off');
                }
                break;
            case 'OU':
                if (button === TEST_RESULT.ABNORMAL) {
                    className = 'abnormal ' + (test.remarks.result.value === TEST_RESULT.ABNORMAL ? 'on' : 'off');
                } else {
                    className = 'normal ' + (test.remarks.result.value === TEST_RESULT.NORMAL ? 'on' : 'off');
                }
                break;
            default:
                break;
        }
        // if (test.result_type === TEST_RESULT_TYPE.DOUBLE) {
        //     if ((test.remarks.result_new.OD.isEmpty() && columnName === 'OD') || (test.remarks.result_new.OS.isEmpty() && columnName === 'OS')) {
        //         className += ' invitation' + (button === TEST_RESULT.ABNORMAL ? ' red' : ' green');
        //     }
        // } else if (test.result_type === TEST_RESULT_TYPE.SINGLE) {
        //     if (test.remarks.result.isEmpty()) {
        //         className += ' invitation' + (button === TEST_RESULT.ABNORMAL ? ' red' : ' green');
        //     }
        // } else if (test.result_type === TEST_RESULT_TYPE.NONE) {
        // }
        return className;
    }
    public provideFeedback(payload: IGrade): Observable<IGrade[]> {
        const url = `${this.configService.backendUrl}/grades/set`;
        return this.http.post<IGrade[]>(url, JSON.stringify(payload));
    }
    /**
     *
     * @param payload testType is optional due to let query by exam or by test
     * @returns
     */
    public getFeedbacks(payload: { examinationID: string; testType?: TEST_TYPE; author: IUser<any> }): Observable<IGrade[]> {
        const url = `${this.configService.backendUrl}/grades/get`;
        return this.http.post<IGrade[]>(url, JSON.stringify(payload));
    }
    public upload(examinationID: string, device: DEVICE, timeMark: string): Observable<void> {
        const url = `${this.configService.backendUrl}/measurement/upload`;
        return this.http.post<void>(url, { examinationID, device, timeMark });
    }
}
