// @ts-nocheck
import * as d3 from 'd3';
import { Diagram } from '../Diagram';

interface Point {
    x: number;
    y: number;
}

export class Lines {
    line: d3.Line<Point>;
    linePercent: d3.Line<Point>;

    constructor (ctx: Diagram) {
        let line = d3
            .line<Point>()
            .x((d) => ctx.scale.x(d.x))
            .y((d) => ctx.scale.y(d.y));

        let linePercent = d3
            .line<Point>()
            .x((d) => ctx.scale.x(d.x))
            .y((d) => ctx.scale.yPercent(d.y))
            .curve(d3.curveMonotoneX);
        // .defined((d) => (d.prevY == null) || (d.prevY !== 0))

        this.line = line;
        this.linePercent = linePercent;
    }
}
