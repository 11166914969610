// @ts-nocheck
import * as d3 from 'd3';
import * as _ from 'lodash';

// import { Layout as Area } from '..'
import { IDataSet } from './AChart';
import { Domain, RawPoint } from './util';

interface ADataParams<D> {
    series: IDataSet<D>[];

    enabled?: boolean;
    filter?: (d: D) => boolean;
    positioning: (d: D, s: IDataSet<D>) => RawPoint;
    coloring: (d: D, s: IDataSet<D>) => string;

    // scale,domain configuration
    xFrom?: number;
    yFrom?: number;
    xTo?: number;
    yTo?: number;

    xBaseDomain?: [number, number];
    yBaseDomain?: [number, number];

    nice?: boolean;
    clamp?: boolean;
}

// TODO: passing (dataPoint, series) can be removed
// if where it is used I preprocess d to have link to series

// scope: derives needed stuff from data for AChart
// only scale, domain it seems
export function AData<D>(p: ADataParams<D>) {
    let enabled = p.enabled == null ? true : p.enabled;
    let filtering = p.filter || (() => true);
    if (!enabled) filtering = () => false;

    let mapping = (dataPoint: D, series: IDataSet<D>) => {
        return {
            dataPoint,
            point: p.positioning(dataPoint, series),
            color: p.coloring(dataPoint, series),
        };
    };

    let flatData = _.flatten(
        p.series.map((series) => {
            return series.dataPoints.filter(filtering).map((dataPoint) => {
                return mapping(dataPoint, series);
            });
        })
    );

    let xExtent = d3.extent(flatData, (x) => x.point.x);
    let yExtent = d3.extent(flatData, (x) => x.point.y);
    let domainX: Domain = [p.xFrom ?? (xExtent[0] || 0), p.xTo ?? (xExtent[1] || 0)];
    let domainY: Domain = [p.yFrom ?? (yExtent[0] || 0), p.yTo ?? (yExtent[1] || 0)];

    if (p.xBaseDomain) {
        let xBaseFrom = p.xBaseDomain[0];
        let xBaseTo = p.xBaseDomain[1];

        if (domainX[0] > xBaseFrom) domainX[0] = xBaseFrom;
        if (domainX[1] < xBaseTo) domainX[1] = xBaseTo;
    }

    if (p.yBaseDomain) {
        let yBaseFrom = p.yBaseDomain[0];
        let yBaseTo = p.yBaseDomain[1];

        if (domainY[0] > yBaseFrom) domainY[0] = yBaseFrom;
        if (domainY[1] < yBaseTo) domainY[1] = yBaseTo;
    }

    let xScale = d3.scaleLinear().domain(domainX);
    let yScale = d3.scaleLinear().domain(domainY);

    let clamp = p.clamp ?? true;
    xScale.clamp(clamp);
    yScale.clamp(clamp);

    let doNice: boolean | null = null;
    if (p.nice === true || p.nice === false) doNice = p.nice;

    if (doNice === null) {
        if (!p.xFrom && !p.xTo) xScale = xScale.nice();
        if (!p.yFrom && !p.yTo) yScale = yScale.nice();
    } else {
        if (doNice) {
            xScale.nice();
            yScale.nice();
        }
    }

    return {
        flatData,
        domainX,
        domainY,
        xScale,
        yScale,
        mapping,
        filtering,
        enabled,

        series: p.series,
        positioning: p.positioning,
        coloring: p.coloring,
    };
}

// gosh: can't use AData<D> here cause it is too hard piece of syntax for typescript
export type ADataResult<D> = ReturnType<typeof AData>;
