import { InjectionToken } from '@angular/core';
import { LANG_EN_NAME, LANG_EN_TRANS } from './en';
import { LANG_HU_NAME, LANG_HU_TRANS } from './hu';

export const TRANSLATIONS = new InjectionToken('translations');

const dictionary = {
    [LANG_EN_NAME]: LANG_EN_TRANS,
    [LANG_HU_NAME]: LANG_HU_TRANS,
};

export const TRANSLATION_PROVIDERS = [{ provide: TRANSLATIONS, useValue: dictionary }];
