// @ts-nocheck

import * as _ from 'lodash'
import { Fixation } from './helpers/Fixation';

interface IProtocol {
  input(x: unknown): void
}

export interface DiagramParams {
  svgTarget?: SVGSVGElement;
  targetContainer?: HTMLDivElement;
  theme: string;
  updateStartParams: (x: any) => void;
  requestToken: string;
  apiPrefix: string;
  edits: Edit[];
  addEdit: (x: Edit) => Promise<Edit[]>;
}

export abstract class BaseDiagram<S, P extends IProtocol> {
  throttleRefresh = 100;
  testDuration = 0;

  refresh = _.throttle(() => {
    this.render();
  }, this.throttleRefresh);

  state!: S
  protocol!: P

  constructor(public params: DiagramParams) {
    new Fixation(params.svgTarget, this);
    this.reset();
  }
  get p() {
    return this.params
  }
  set p(x: any) {
    this.params = x
  }
 
  addData (data: unknown) {
    this.testDuration = data[0].lengthInSeconds || 0;
    this.protocol.input(data)
    this.gotData && this.gotData(data)
  }

  abstract gotData(data: any): void
  abstract reset(): void
  abstract render(): void
}