import { INextsightNexyImage } from '../interfaces/measuredData/NEXTSIGHT/NEXY/post-segment-nextsight-nexy.measuredData.interface';
import { OCULUS } from '../enums/oculus.enum';
import { SmartImage } from './smartImage.class';
/**
 * An abstract class that represents Nextsight Nexy device result (images)
 * The class is abstract in order to hold the same functionality for backend and frontend implementations of such entities.
 */
export abstract class NextsightNexyImage implements INextsightNexyImage {
    public eye: OCULUS; // which eye this image related to
    public type: string; // type field from device result file
    public fileName: string; // image file name
    public image: SmartImage; // image itself
    constructor() {}
    public setModel(model: INextsightNexyImage) {
        this.eye = model.eye;
        this.fileName = model.fileName;
        this.type = model.type;
        this.image.model = model.image;
    }
    public get model(): INextsightNexyImage {
        let model: INextsightNexyImage = {
            eye: this.eye,
            type: this.type,
            fileName: this.fileName,
            image: this.image.model,
        };
        return model;
    }
}
