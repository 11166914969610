import { Subject } from 'rxjs';
import { User } from '../../../../../commonout/classes/user.class';
import { IUser } from '../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../commonout/interfaces/userFrontend.interface';

export class UserFrontend<T> extends User<T> implements IUserFrontend {
    token: string;
    refreshToken: string;
    public source: Subject<UserFrontend<T>>;
    constructor() {
        super();
        this.source = new Subject<UserFrontend<T>>();
    }
    public setModel(model: Partial<IUser<T> & IUserFrontend>): void {
        this._id = model._id;
        this.role = model.role;
        this.username = model.username;
        this.roleProperties = model.roleProperties;
        this.token = model.token;
        this.refreshToken = model.refreshToken;
        this.source.next(this);
    }
    public getModel(clearTokens?: boolean): IUser<T> & IUserFrontend {
        let model: IUser<T> & IUserFrontend = {
            _id: this._id,
            role: this.role,
            username: this.username,
            roleProperties: this.roleProperties,
            token: this.token,
            refreshToken: this.refreshToken,
        };
        if (clearTokens) {
            delete model.token;
            delete model.refreshToken;
        }
        return model;
    }
    public set tokens(tokens: { token: string; refreshToken: string }) {
        this.loadWithJWT(tokens.token);
        this.token = tokens.token;
        this.refreshToken = tokens.refreshToken;
    }
}
