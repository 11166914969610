import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BlockingType } from '../../../../../../../../../commonout/enum/blockingType.enum';
import { RegisterEditFieldFrontend } from '../../../../../_models/registerEditFieldFrontend.class';
import { SmartImageFrontend } from '../../../../../_models/smartImageFrontend.class';
import { WebCamImportsTestFrontend } from '../../../../../_models/tests/webCamImportsTestFrontend.class';
import { ExaminationService } from '../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { FileService } from '../../../../../_services/general/file.service';
import { ModalService } from '../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../testRemarksComponent';

@Component({
    selector: 'webcam-imports-test-remarks',
    template: require('./webcam-imports-test-remarks.component.html'),
    styles: [require('../../common-remarks.scss'), require('./webcam-imports-test-remarks.component.scss')],
})
export class WebcamImportsTestRemarksComponent extends TestRemarksComponent {
    test: WebCamImportsTestFrontend;
    private isBusy: boolean = false;
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            images: [],
        });
    }
    private addImage(event: any): void {
        this.isBusy = true;
        const reader = new FileReader();
        reader.onload = () => {
            const url = `url(${reader.result})`;
            this.addDocumentImgUrl(url);
        };
        reader.readAsDataURL(event.target.files[0]);
    }
    public async addDocumentImgUrl(imageUrl: string): Promise<void> {
        this.isBusy = true;
        const sImage: SmartImageFrontend = new SmartImageFrontend();
        sImage.model = {
            imageID: null,
            imageUrl: imageUrl,
            remark: new RegisterEditFieldFrontend(BlockingType.AFTER_DAY_WITH_COMMIT, 'imported document', ''),
        };
        await sImage.saveToDb(this.fileService);
        await sImage.rotate90deg();
        this.test.remarks.measurements[0].images.unshift(sImage);
        this.remarksForm.patchValue({
            images: this.test.remarks.measurements[0].images,
        });
        this.isBusy = false;
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map((m) => m.downloadMediaData(this.fileService)));
        this.remarksForm.setValue(
            {
                images: this.test.remarks.measurements[0].images,
            },
            {
                emitEvent: false,
            }
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {}
}
