import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IpdGlareContrastAcuityArk1sTestFrontend } from '../../../../../../_models/tests/NIDEK/ARK1S/ipd-glare-contrast-acuityArk1sTestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'ipd-glare-contrast-acuity-test-remarks',
    template: require('./ipd-glare-contrast-acuity-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./ipd-glare-contrast-acuity-test-remarks.component.scss')],
})
export class IpdGlareContrastAcuityTestRemarksComponent extends TestRemarksComponent {
    test: IpdGlareContrastAcuityArk1sTestFrontend;
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            dataBlock: this.formBuilder.array([]),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map(m => m.downloadMediaData(this.fileService)));
        this.remarksForm.patchValue(
            {
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
        this.remarksForm.setControl(
            'dataBlock',
            this.formBuilder.array(
                this.test.remarks.measurements
                    .map(m => {
                        return this.formBuilder.group({
                            IPD: this.formBuilder.group({
                                far: {
                                    value: m.IPD.far.value,
                                    disabled: true,
                                },
                                near: {
                                    value: m.IPD.near.value,
                                    disabled: true,
                                },
                            }),
                            CGA: this.formBuilder.group({
                                OD: this.formBuilder.group({
                                    bcva: {
                                        value: m.CGA.OD.bcva.value,
                                        disabled: true,
                                    },
                                    contrast: {
                                        value: m.CGA.OD.contrast.value,
                                        disabled: true,
                                    },
                                    glare: {
                                        value: m.CGA.OD.glare.value,
                                        disabled: true,
                                    },
                                }),
                                OS: this.formBuilder.group({
                                    bcva: {
                                        value: m.CGA.OS.bcva.value,
                                        disabled: true,
                                    },
                                    contrast: {
                                        value: m.CGA.OS.contrast.value,
                                        disabled: true,
                                    },
                                    glare: {
                                        value: m.CGA.OS.glare.value,
                                        disabled: true,
                                    },
                                }),
                            }),
                            OI: this.formBuilder.group({
                                OD: this.formBuilder.group({
                                    COI: {
                                        value: m.OI.OD.COIA.value,
                                        disabled: true,
                                    },
                                    POI: {
                                        value: m.OI.OD.POI.value,
                                        disabled: true,
                                    },
                                    retroImage: {
                                        value: m.OI.OD.RetroImage,
                                        disabled: true,
                                    },
                                }),
                                OS: this.formBuilder.group({
                                    COI: {
                                        value: m.OI.OS.COIA.value,
                                        disabled: true,
                                    },
                                    POI: {
                                        value: m.OI.OS.POI.value,
                                        disabled: true,
                                    },
                                    retroImage: {
                                        value: m.OI.OD.RetroImage,
                                        disabled: true,
                                    },
                                }),
                            }),
                        });
                    })
                    .sort(this.sort)
            )
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {
        this.test.remarks.diagnosis.registerEdit(rawFormValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(rawFormValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(rawFormValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(rawFormValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
