import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { IRecordingToolBulbicamMeasuredData } from '../../../../../../common/interfaces/measuredData/BulbiCAM/recordingToolBulbicam.measuredData.interface';
import { RecordingToolBulbicamMeasuredData } from '../../../../../../common/models/measuredData/BulbiCAM/recordingToolBulbicam.measuredData.model';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { Pager } from '../../../_services/examination/export.service';
import { FileService } from '../../../_services/general/file.service';
import { BulbicamTestFrontend } from '../../haplotestFrontend.class';
import { ITableReportTestFormat } from '../../tableReportTestMode';
import { RecordingToolBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/recordingToolBulbicamTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../measuredDataFrontend';

export class RecordingToolBulbicamMeasuredDataFrontend extends RecordingToolBulbicamMeasuredData implements MeasuredDataFrontend {
    public remarks: RecordingToolBulbicamTestRemarksFrontend;
    public haplotests: BulbicamTestFrontend[];
    constructor() {
        super();
    }
    public setModel(model: IRecordingToolBulbicamMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.haplotests = model.haplotests.map((htm) => {
            const ht = new BulbicamTestFrontend();
            ht.model = htm;
            return ht;
        });
    }
    public async toPDF(
        pdf: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        if (this.haplotests.length > 0) {
            formattedTest.haplotest = this.haplotests[this.haplotests.length - 1].haplotestData;
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
