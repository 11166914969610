// @ts-nocheck
import * as d3 from 'd3';
import { Target } from './lib';
import { nonSelectable } from '../../../lib/util';
// import { Layout } from './lib';

interface Layout {
    viewBox(): string;
}

let useClass = (klass: string) => (t: Target) => t.attr('class', klass);

let G = (target: Target, klass: string) => {
    return target
        .selectAll(`.${klass}`)
        .data([null])
        .join('g')
        .call(useClass(klass));
};

interface TargetsParams {
    target?: HTMLElement;
    svgTarget?: HTMLElement;
    layout: Layout; // specific item - whole(root) layout object
    mode?: number;
    state: { previousMode: number };
    chartsCount?: number;
}
export class Targets {
    private counters: { [k: string]: number } = {};

    svg = this.makeOrUseSvg();
    gDefault = G(this.svg, 'default');
    gPopup = G(this.svg, 'popup');
    gQuick = G(this.svg, 'gQuick');
    gQuick2 = G(this.svg, 'gQuick2');
    gTrace11 = G(this.svg, 'gTrace11');
    gTrace12 = G(this.svg, 'gTrace12');
    gTrace13 = G(this.svg, 'gTrace13');
    gTrace21 = G(this.svg, 'gTrace21');
    gTrace22 = G(this.svg, 'gTrace22');
    gTrace23 = G(this.svg, 'gTrace23');
    gVideo = G(this.svg, 'gVideo');
    gBigVideo = G(this.svg, 'gBigVideo');
    gVideoControls = G(this.svg, 'gVideoControls');

    gLegend = [G(this.svg, 'gLegend1'), G(this.svg, 'gLegend2')];
    gChartLayer = [G(this.svg, 'gChartLayer1'), G(this.svg, 'gChartLayer2')];

    // gTrace3 = G(this.svg, 'gTrace3')
    // gTrace21 = G(this.svg, 'gTrace21')
    // gTrace22 = G(this.svg, 'gTrace22')
    // gTrace23 = G(this.svg, 'gTrace23')

    // these getters can only be used if Target is recreated each render
    get default() {
        return this.nestedG(this.gDefault, 'gDefault');
    }
    get popup() {
        return this.nestedG(this.gPopup, 'gPopup');
    }

    constructor(private params: TargetsParams) {}

    private nestedG(parentG: Target, name: string) {
        let counter = this.counters[name] || 0;
        counter += 1;
        this.counters[name] = counter;

        return G(parentG, `dyn-${counter}`);
    }

    private makeOrUseSvg() {
        let { target, svgTarget } = this.params;
        let svg: Target;
        if (target) {
            let t = d3.select(target);
            let div = t
                .selectAll('.svg-container')
                .data([null])
                .join('div')
                .attr('class', 'svg-container');
            svg = div
                .selectAll('svg')
                .data([null])
                .join('svg');
        } else if (svgTarget) {
            svg = d3.select(svgTarget);
        } else {
            throw Error('svg was not setup');
        }
        this.configureSvg(svg);
        let result = svg;

        result = this.enterMode(result, this.params.mode);

        return result;
    }

    // may be messy because stateful but it seems ok and it works
    enterMode(root: Target, mode: number | undefined) {
        if (mode == null) return root;

        if (mode !== this.params.state.previousMode) {
            root.selectAll('.troot')
                .data([])
                .join('g');
        }

        this.params.state.previousMode = mode;
        let next = root
            .selectAll('.troot')
            .data([null])
            .join('g')
            .attr('class', 'troot');
        return next;
    }

    private configureSvg(svg: Target) {
        svg.attr('viewBox', `0 0 1920 ${this.params?.chartsCount === 6 ? '2100' : '1500'}`)
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .call(nonSelectable);
    }

    get pass() {
        return {
            target: () => {
                return this.default;
            },
        };
    }
}
