import { WebCamImportsTest } from '../../../../../../commonout/classes/tests/WebCamImportsTest';
import { applyMixins } from '../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../common/enums/test-result-type.enum';
import { WebcamImportsTestRemarksComponent } from '../../_components';
import { WebCamImportsTestRemarksFrontend } from '../test-remarks/webCamImportsTestRemarksFrontend.class';
import { TestFrontend } from './testFrontend.class';

export class WebCamImportsTestFrontend extends WebCamImportsTest {
    remarks: WebCamImportsTestRemarksFrontend;
    public readonly remarksComponent: typeof WebcamImportsTestRemarksComponent = WebcamImportsTestRemarksComponent;
    public readonly possible_results: string[] = [];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.NONE;
    constructor() {
        super();
        this.remarks = new WebCamImportsTestRemarksFrontend();
    }
}

export interface WebCamImportsTestFrontend extends TestFrontend {}
applyMixins(WebCamImportsTestFrontend, [TestFrontend]);
