import { VisualFieldMergedBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/visualFieldMergedBulbicamDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class VisualFieldMergedBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof VisualFieldMergedBulbicamMeasuredDataFrontend = VisualFieldMergedBulbicamMeasuredDataFrontend;
    public measurements: VisualFieldMergedBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
