// @ts-nocheck
import { Target } from '../../lib/types'
import { Layout } from '../../lib/Layout'
import { BooleanControl } from '../../lib/new/BooleanControl'

// import * as d3 from 'd3';

// const BUTTON_HEIGHT = 50
// const BUTTON_WIDTH = 100

// interface Rect {
//   left: number
//   top: number
//   right: number
//   bottom: number
// }
interface Params {
  // popupRect: Rect
  target: Target
  clean: boolean
  onClick: () => void
}

export function PopupSave(p: Params) {
  // let { right, bottom } = p.rect
  // let left = right - BUTTON_WIDTH
  // let top = bottom - BUTTON_HEIGHT

  let deltax = p.clean ? 70 : 0

  // let left = 535
  // let left = 450 - 11 + deltax
  // let top = 600 - 6
  let left = 450 - 11 -200 + deltax + 100
  let top = 600 - 6 -200 + 100
  let right = left
  let bottom = top

  let area = new Layout(left, top, right, bottom, '')

  let g = new Gs(p.target, 'popup-save')

  let text = p.clean ? 'Close' : 'Save Change'

  BooleanControl({
    text,
    checked: false,
    target: g.take,
    area,
    onClick: p.onClick,
  })
}

class Gs {
  id = 0
  constructor(private target: Target, private name: string) {}

  get take() {
    this.id += 1
    let target = this.target
      .selectAll(`.g-${this.name}-${this.id}`)
      .data([null])
      .join('g')
      .attr('class', `g-${this.name}-${this.id}`)
    return target
  }
}
