import { PostSegmentNidekAfc330Test } from '../../../../../../../../commonout/classes/tests/NIDEK/AFC330/postSegmentNidekAfc330Test';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PostSegmentNidekAfc330TestRemarksComponent } from '../../../../_components';
import { PostSegmentNidekAfc330TestRemarksFrontend } from '../../../test-remarks/NIDEK/AFC330/postSegmentNidekAfc330TestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PostSegmentNidekAfc330TestFrontend extends PostSegmentNidekAfc330Test {
    remarks: PostSegmentNidekAfc330TestRemarksFrontend;
    public readonly remarksComponent: typeof PostSegmentNidekAfc330TestRemarksComponent = PostSegmentNidekAfc330TestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PostSegmentNidekAfc330TestRemarksFrontend();
    }
}

export interface PostSegmentNidekAfc330TestFrontend extends TestFrontend {}
applyMixins(PostSegmentNidekAfc330TestFrontend, [TestFrontend]);
