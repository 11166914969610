import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'statistics-home',
    template: require('./statistics-home.component.html'),
    styles: [require('./statistics-home.component.scss')],
})
export class StatisticsHomeComponent {
    constructor(private router: Router) {}
}
