// @ts-nocheck
// import { Component, ElementRef, ViewChild } from '@angular/core';
// import * as _ from 'lodash';
// import { TranslateService } from '../../../../_services/general/translate.service';
// import { BulbicamChartComponent } from '../haploChart.component';
// import { Diagram, IncomingMessage } from './diagram/Diagram';

// @Component({
//     selector: 'akids-test-chart',
//     template: require('./akids-test-chart.component.html'),
//     styles: [require('../charts.scss'), require('./akids-test-chart.component.scss')],
// })
// export class Acuity4KidsBulbicamTestChartComponent extends BulbicamChartComponent {
//     @ViewChild('targetElement') acuity1Chart: ElementRef;
//     diagram?: Diagram;

//     setEdits(x:any) {}

//     constructor(private translateService: TranslateService) {
//         super();
//     }

//     ngAfterViewInit() {
//         let target = this.acuity1Chart.nativeElement;

//         this.initChart(target);

//         if (this.inputData) {
//             this.addData(this.inputData);
//         } else {
//         }
//     }

//     initChart(targetElement: HTMLElement) {
//         let theme = 'any'
//         this.diagram = new Diagram({ svgTarget: targetElement, theme });
//     }

//     addData(data: ICamMessage[]) {
//         this.diagram.addData(data as IncomingMessage[], true);
//         this.diagram.refresh();
//     }
//     clearData() {
//         this.diagram.clearState();
//     }
//     get exportData(): null {
//         return null
//         // return this.diagram.exportData()
//     }
// }

import { Component, ElementRef, ViewChild } from '@angular/core';
import { TEST_TYPE } from '../../../../../../../../commonout/enum/test-type';
import { ICamMessage } from '../../../../../../../../commonout/interfaces/charts.model';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { Diagram } from './diagram/Diagram';

@Component({
    selector: 'akids-test-chart',
    template: require('./akids-test-chart.component.html'),
    styles: [require('../charts.scss'), require('./akids-test-chart.component.scss')],
})
export class Acuity4KidsBulbicamTestChartComponent extends BulbicamChartComponent {
    @ViewChild('targetElement') amdChart: ElementRef;
    @ViewChild('targetContainer') targetContainer: ElementRef;
    diagram?: Diagram;
    private readonly type: TEST_TYPE = TEST_TYPE.ACUITY4KIDS;

    // edits: any[] = [];
    public setEdits(got: any): void {
        //     // console.log('got setEdits:', got)
        //     this.diagram.edits = got;
    }

    constructor(private bulbicamService: BulbicamService, private authenticationService: AuthenticationService, private conf: ConfigService) {
        super();
        this.bulbicamService.dataSource.subscribe((payload) => {
            if (this.type === payload.type) this.addData(payload.data);
        });
        // this.bulbicamService.vergenceStaff.createTest = () => {
        //     this.clearData(true);
        //     this.refresh();
        // };
    }

    // I assume inputData is already set...
    ngAfterViewInit() {
        let target = this.amdChart.nativeElement;
        let targetContainer = this.targetContainer.nativeElement;

        this.initChart(target, targetContainer);

        if (this.inputData) {
            this.addData(this.inputData);
            this.diagram.refresh();
        } else {
        }
        this.refresh();
        // this.bulbicamService.vergenceStaff.startParams = (() => {
        //     return this.startParams;
        // }).bind(this);
    }

    initChart(targetElement: HTMLElement, targetContainer: HTMLElement) {
        // log('initChart');
        // let theme = 'any';
        // let { conf } = this;
        // let apiPrefix = conf.backendUrl;
        // let apiPrefix = `/api/v1/haplo`
        this.diagram = new Diagram({
            place: {
                svgTarget: targetElement,
                targetContainer,
            },
            // theme,
            // updateStartParams: (data: any) => {
            //     this.startParams = data;
            // },
            // requestToken: this.authenticationService.currentUser.token,
            // apiPrefix,

            // edits: this.edits,
            // addEdit: (x: any) => this.addEdit(x),
        });
    }

    addData(data: ICamMessage[]) {
        // this.bulbicamService.vergenceStaff.chartCleared = false;
        this.diagram.addData(data);
        this.diagram.refresh();
    }

    refresh = () => {
        this.diagram.refresh();
    };

    clearData(signal: boolean) {
        // this.bulbicamService.vergenceStaff.chartCleared = signal;
        this.diagram.reset();
    }

    get exportData() {
        return this.diagram.exportData;
    }
}

function log(x: string | object) {
    return;
    if (typeof x === 'string') x = `amd.${x}`;
    console.log(x);
}
