import { IvueOctImage } from '../../../../common/models/iVueOctImage.class';
import { SmartImageFrontend } from './smartImageFrontend.class';
import { FileService } from '../_services/general/file.service';

export class IvueOctImageFrontend extends IvueOctImage {
    public image: SmartImageFrontend;
    constructor() {
        super();
        this.image = new SmartImageFrontend();
    }
    public async fetchImage(fileService: FileService): Promise<void> {
        try {
            if (this.image.isDownloaded()) return;
            await this.image.download(fileService);
        } catch (error) {
            console.log(error);
        }
    }
}
