import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SocketService } from '../../../_services/general/socket.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { Subscription, fromEvent } from 'rxjs';
import { map, scan, filter } from 'rxjs/operators';

@Component({
    selector: 'login-u2f',
    template: require('./login-u2f.component.html'),
    styles: [require('./login-u2f.component.scss')],
})
export class LoginU2fComponent implements OnInit, OnDestroy {
    private authForm: FormGroup;
    private error: string;
    private subscriptions: Subscription[];

    constructor(private router: Router, private fb: FormBuilder, private authService: AuthenticationService, private socketService: SocketService) {
        this.subscriptions = [];
    }

    ngOnInit() {
        this.authForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
        this.subscriptions.push(
            fromEvent<KeyboardEvent>(document, 'keypress')
                .pipe(
                    map((e) => e.key),
                    scan((acc: string, value) => acc + value?.toLowerCase()),
                    filter((sequence) => typeof sequence === 'string')
                )
                .subscribe((sequence) => {
                    if (sequence?.endsWith('bhadmin')) {
                        this.router.navigate(['auth', 'bhadmin']);
                    }
                })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private async login() {
        try {
            this.authForm.disable();
            const rawFormValue = this.authForm.getRawValue(),
                publicKey: PublicKeyCredentialRequestOptions = await this.authService.loginWithU2F(rawFormValue).toPromise();
            publicKey.challenge = this.authService.decode(publicKey.challenge as any);
            for (let allowCred of publicKey.allowCredentials) {
                allowCred.id = this.authService.decode(allowCred.id as any);
            }
            const publicKeyCredential: Credential = await navigator.credentials.get({ publicKey }),
                encodedpublicKeyCredential = this.authService.publicKeyCredentialToJSON(publicKeyCredential),
                tokens: {
                    token: string;
                    refreshToken: string;
                } = await this.authService.sendWebAuthResponse(encodedpublicKeyCredential).toPromise();
            this.authService.currentUser.tokens = tokens;
            localStorage.setItem('currentUser', JSON.stringify(this.authService.currentUser.getModel()));
            await this.socketService.connect(this.authService.currentUser.token);
            // localStorage.setItem('greeting', 'true');
            this.router.navigate(['/']);
        } catch (error) {
            this.error = error.error;
        } finally {
            this.authForm.enable();
        }
    }
}
