import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { IOrder } from '../../../../../../../commonout/interfaces/order.interface';
import { IUser } from '../../../../../../../commonout/interfaces/user';
import { USER_ACTION_TARGET } from '../../../../../../common/enums/user-action-target.enum';
import { USER_ACTION } from '../../../../../../common/enums/user-action.enum';
import { IActionSearchTerms } from '../../../../../../common/interfaces/action-search-term.interface';
import { IUserAction } from '../../../../../../common/interfaces/user-action.interface';
import { turnPageAnimation } from '../../../_animations';
import { ActionService } from '../../../_services/general/action.service';
import { UserService } from '../../../_services/user/user.service';

@Component({
    selector: 'users-activity',
    template: require('./users-activity.component.html'),
    styles: [require('./users-activity.component.scss')],
    animations: [turnPageAnimation],
})
export class UsersActivityComponent implements OnInit, OnDestroy, AfterViewInit {
    maxDatepickerDate: Date = new Date();

    @ViewChildren('sortable') sortables: QueryList<ElementRef>;
    private actions: IUserAction[];
    private subscriptions: Array<Subscription> = [];
    private users: Array<IUser<any>> = [];
    private filteredUsers: Array<IUser<any>> = [];
    private ACTIONS = USER_ACTION;
    private TARGETS = USER_ACTION_TARGET;
    private searchForm: FormGroup;
    private state = 'finish';
    private currentPage: number = 0;
    private lastPage: number = 0;
    private pageTurningDisabled = false;

    constructor(private actionService: ActionService, private userService: UserService, private formBuilder: FormBuilder, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.searchForm = this.formBuilder.group({
            user: null,
            action: null,
            target: null,
            targetId: null,
            startDate: moment()
                .subtract(1, 'days')
                .startOf('day')
                .valueOf(),
            endDate: moment().valueOf(),
        });

        this.subscriptions.push(
            this.searchForm.valueChanges
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    switchMap((term) => {
                        this.currentPage = 0;

                        const searchTerm: IActionSearchTerms = {
                            user: term.user,
                            action: term.action,
                            target: term.target,
                            targetId: term.targetId,
                            startDate: moment(term.startDate)
                                .startOf('day')
                                .valueOf(),
                            endDate: moment(term.endDate)
                                .endOf('day')
                                .valueOf(),
                        };

                        return this.actionService.search(searchTerm, { seed: 'timestamp', direction: 'desc' }, this.currentPage);
                    }),
                    tap((res: any) => {
                        if (!res.more) {
                            this.lastPage = this.currentPage;
                        } else {
                            this.lastPage = this.currentPage + 1;
                        }
                    })
                )
                .subscribe((res) => (this.actions = res.actions))
        );
    }

    ngAfterViewInit(): void {
        this.sortables.forEach((sortable: ElementRef) => {
            sortable.nativeElement.className = 'none';
        });

        this.subscriptions.push(
            this.userService.search<any>({}).subscribe((res) => {
                this.users = res.users;
                this.filteredUsers = res.users;
            })
        );

        this.getActions(
            {
                user: this.searchForm.get('user').value,
                action: this.searchForm.get('action').value,
                target: this.searchForm.get('target').value,
                targetId: this.searchForm.get('targetId').value,
                startDate: this.searchForm.get('startDate').value,
                endDate: this.searchForm.get('endDate').value,
            },
            {
                seed: 'timestamp',
                direction: 'desc',
            },
            0
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    getActions(term: IActionSearchTerms, order: IOrder, page: number) {
        term.startDate = moment(term.startDate)
            .startOf('day')
            .valueOf();
        term.endDate = moment(term.endDate)
            .endOf('day')
            .valueOf();

        this.subscriptions.push(
            this.actionService.search(term, order, page).subscribe((data: { actions: IUserAction[]; more: boolean }) => {
                this.currentPage = page;
                this.pageTurningDisabled = false;

                if (!data.more) {
                    this.lastPage = this.currentPage;
                } else {
                    this.lastPage = this.currentPage + 1;
                }

                this.actions = data.actions;
            })
        );
    }

    filterUsers(fullName: HTMLInputElement) {
        const regex = new RegExp(fullName.value, 'gi');
        this.filteredUsers = this.users.filter((user: IUser<any>) => {
            return regex.test(user.roleProperties.firstName + ' ' + user.roleProperties.lastName);
        });

        if (!fullName.value) {
            this.selectUser(null);
        }
    }

    selectUser(user: IUser<any>) {
        this.searchForm.get('user').setValue(user);
    }

    turnPage(page: number) {
        this.pageTurningDisabled = true;
        const searchFormRaw = this.searchForm.getRawValue();
        searchFormRaw.page = page;

        this.getActions(searchFormRaw, { seed: 'timestamp', direction: 'desc' }, page);
    }

    onTurnPrev(page: number) {
        this.turnPage(page);
        this.state = 'finish';

        setTimeout(() => {
            this.state = 'start';
        }, 0);
    }

    onTurnNext(page: number) {
        this.turnPage(page);
        this.state = 'start';

        setTimeout(() => {
            this.state = 'finish';
        }, 0);
    }

    timestampToDate(ts: number): string {
        const d: Date = new Date(ts);
        return d.toLocaleString();
    }

    private sortBy(seed: string, order: HTMLDivElement): void {
        const searchFormRaw = this.searchForm.getRawValue();
        const oldOrder: string = order.className;
        this.sortables.forEach((sortable: ElementRef) => {
            sortable.nativeElement.className = 'none';
        });

        switch (oldOrder) {
            case 'asc':
                order.className = 'desc';
                this.renderer.removeClass(order.querySelector('i'), 'aico-down-arrow');
                this.renderer.addClass(order.querySelector('i'), 'aico-up-arrow');
                break;
            case 'desc':
                order.className = 'asc';
                this.renderer.removeClass(order.querySelector('i'), 'aico-up-arrow');
                this.renderer.addClass(order.querySelector('i'), 'aico-down-arrow');
                break;
            case 'none':
                order.className = 'asc';
                this.renderer.removeClass(order.querySelector('i'), 'aico-up-arrow');
                this.renderer.addClass(order.querySelector('i'), 'aico-down-arrow');
                break;
            default:
                break;
        }

        this.getActions(
            searchFormRaw,
            {
                seed,
                direction: order.className,
            },
            0
        );
    }
}
