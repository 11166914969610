import { DEVICE } from '../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../commonout/enum/test-type';
import { IColorMeasuredData } from '../../interfaces/measuredData/color.measuredData.interface';
import { RegisterEditField } from '../registerEditField.class';
import { MeasuredData } from './measuredData.model';

export abstract class ColorMeasuredData implements MeasuredData, IColorMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public ishihara: {
        OD: RegisterEditField<string>;
        OS: RegisterEditField<string>;
    };
    public withRedFilter: {
        OD: RegisterEditField<string>;
        OS: RegisterEditField<string>;
    };
    public select: RegisterEditField<string[]>;
    constructor() {
        this.sourceDevice = DEVICE.HAPLO;
        this.testType = TEST_TYPE.COLOUR_VISION_TEST;
    }
    public setModel(model: IColorMeasuredData): void {
        this.ishihara.OD.setModel(model.ishihara.OD);
        this.ishihara.OS.setModel(model.ishihara.OS);
        this.withRedFilter.OD.setModel(model.withRedFilter.OD);
        this.withRedFilter.OS.setModel(model.withRedFilter.OS);
        this.select.setModel(model.select);
    }
    public getModel(): IColorMeasuredData {
        let model: IColorMeasuredData = {
            sourceFileName: null,
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            ishihara: {
                OD: this.ishihara.OD.getModel(),
                OS: this.ishihara.OS.getModel(),
            },
            withRedFilter: {
                OD: this.withRedFilter.OD.getModel(),
                OS: this.withRedFilter.OS.getModel(),
            },
            select: this.select.getModel(),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.ishihara.OD.isEmpty() && this.ishihara.OS.isEmpty() && this.select.isEmpty() && this.withRedFilter.OD.isEmpty() && this.withRedFilter.OS.isEmpty();
    }
}
