// @ts-nocheck
// import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target } from '..';
// import { RawPoint } from './util'
import { Layout } from '../Layout'
import { Option } from './Option'
// import { doTranslate } from '../lib';

// styling

// const styleDefault = {
//   fillColor: 'transparent',
//   lineColor: '#eee',
//   lineKlass: '',
//   lineSize: 1,
// }

// type Style = typeof styleDefault

// interface

interface Params {
    text: string
    checked: boolean
    // color: string|{ klass: string }
    color?: string
    onClick: () => void

    noCheckBox?: boolean
    radio?: boolean

    target: Target
    area: Layout
    fontSize?: number
    // style?: Partial<Style>
}

// function

export function BooleanControl(p: Params) {
    // let style = { ...styleDefault, ...style }
    let styleParams = { theme: '' }
    let color = p.color || ''

    Option({
      items: [{}],

      isChecked: () => p.checked,
      onClick: p.onClick,
      coloring: () => color,
      naming: () => p.text,

      noCheckBox: p.noCheckBox,

      newType: true,
      radio: p.radio,

      target: p.target,
      area: p.area,
      styleParams,
      fontSize: p.fontSize,
    })

    // let x1 = Math.min(p.from.x, p.to.x)
    // let x2 = Math.max(p.from.x, p.to.x)

    // let y1 = Math.min(p.from.y, p.to.y)
    // let y2 = Math.max(p.from.y, p.to.y)

    // p.target
    //     .selectAll('.line')
    //     .data([null])
    //     .join('line')
    //     .attr('class', 'line ' + style.lineKlass)

    //     .attr('x1', x1)
    //     .attr('x2', x2)
    //     .attr('y1', y1)
    //     .attr('y2', y2)
    //     // .call(doTranslate({ x: x1, y: y1 }))
    //     // .attr('width', x2 - x1)
    //     // .attr('height', y2 - y1)

    //     .attr('fill', style.fillColor)
    //     .attr('stroke-width', style.lineSize)
    //     .attr('stroke', style.lineColor)
}
