import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class TagsService {
    constructor(private configService: ConfigService, private http: HttpClient) {}

    public getTags(value: string): Observable<string[]> {
        const url = `${this.configService.backendUrl}/tags/${value}`;
        return this.http.get<string[]>(url);
    }

    public setTag(value: string): Observable<string[]> {
        const url = `${this.configService.backendUrl}/insert/${value}`;
        return this.http.get<string[]>(url);
    }
}
