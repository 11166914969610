import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ROLE } from '../../../../../commonout/enum/role';
import { AuthenticationService } from '../_services/general/auth.service';

@Injectable()
export class RouterGuard implements CanActivate, CanLoad {
    constructor(private router: Router, private authService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.currentUser.token) {
            let dashboard: string;
            switch (this.authService.currentUser.role) {
                case ROLE.ADMIN:
                    dashboard = 'dashboard-admin';
                    break;
                case ROLE.DOCTOR:
                case ROLE.STAFF:
                    dashboard = 'dashboard-staff';
                    break;
                default:
                    break;
            }
            this.router.navigate([dashboard]);
            return false;
        } else {
            this.router.navigate(['auth']);
            return false;
        }
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        if (this.authService.currentUser.token) {
            let dashboard: string;
            switch (this.authService.currentUser.role) {
                case ROLE.ADMIN:
                    dashboard = 'dashboard-admin';
                    break;
                case ROLE.DOCTOR:
                    dashboard = 'dashboard-staff';
                    break;
                case ROLE.STAFF:
                    dashboard = 'dashboard-staff';
                    break;
                default:
                    break;
            }
            this.router.navigate([dashboard]);
        } else {
            this.router.navigate(['auth']);
            return false;
        }
    }
}
