import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import { IPtosisCamMessage } from '../../../../../../../../commonout/interfaces/ptosisCamMessage.interface';
import { SmartImageFrontend } from '../../../../_models/smartImageFrontend.class';
import { PtosisBulbicamTestFrontend } from '../../../../_models/tests/BulbiCAM/ptosisBulbicamTestFrontend.class';
import { FileService } from '../../../../_services/general/file.service';
import { BulbicamChartComponent } from '../haploChart.component';

@Component({
    selector: 'ptosis-test-chart',
    template: require('./ptosis-test-chart.component.html'),
    styles: [require('./ptosis-test-chart.component.scss')],
})
export class PtosisBulbicamTestChartComponent extends BulbicamChartComponent implements OnInit, OnDestroy {
    @ViewChild('confirmIcon') private confirmIcon: ElementRef;

    private lensOD: number;
    private lensOS: number;
    private imageSet: IPtosisCamMessage[] = [];
    private subscriptions: Subscription[] = [];
    private activeImage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private cursorType = 'pointer';
    private fullScreen: boolean = false;
    private isBusy: boolean = false;
    private imageRemarks = new FormControl('');
    private zooming$: Observable<MouseEvent>;
    private mouseMovements$: Observable<MouseEvent>;
    private mouseMovementsSubscription: Subscription;
    private imageElementSize = {
        width: 550,
        height: 340,
    };
    private resizeCoefficient = this.imageElementSize.width / 800;
    private allowClick = true;
    private arrows = {
        rightCenterArrow: '',
        rightTopArrow: '',
        rightBottomArrow: '',
        leftCenterArrow: '',
        leftTopArrow: '',
        leftBottomArrow: '',
    };
    startParams: {
        test: PtosisBulbicamTestFrontend;
    };

    constructor(private fileService: FileService) {
        super();
    }
    ngOnInit(): void {
        this.lensOD = +this.startParams.test.examination.patient.lensValues.sphere.OD.value;
        this.lensOS = +this.startParams.test.examination.patient.lensValues.sphere.OS.value;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public clearData(): void {
        this.imageSet = [];
        this.activeImage = new BehaviorSubject<number>(0);
    }

    public setEdits(edits: IChartEdit[]): void {}

    public async addData(frames: IPtosisCamMessage[]): Promise<void> {
        await Promise.all(
            frames.map(async f => {
                const smartImage: SmartImageFrontend = new SmartImageFrontend();
                smartImage.model = f.smartImage;
                await smartImage.download(this.fileService);
                f.smartImage = smartImage;
            })
        );
        this.imageSet = frames;
    }

    private async update(): Promise<void> {
        await this.updateCamMessage<IPtosisCamMessage>(this.imageSet[this.activeImage.value]);
        if (!this.confirmIcon.nativeElement.classList.contains('animate')) {
            this.confirmIcon.nativeElement.classList.add('animate');
            setTimeout(() => {
                this.confirmIcon.nativeElement.classList.remove('animate');
            }, 1700);
        }
    }
    private async delete(frameTS: number): Promise<void> {
        await this.deleteCamMessage(frameTS);
    }

    appearedEl(el: ElementRef): void {
        this.zooming$ = fromEvent(el.nativeElement, 'click');
        this.mouseMovements$ = fromEvent(el.nativeElement, 'mousemove');

        this.subscriptions.push(
            this.activeImage.subscribe(index => {
                this.drawAllArrows();
                if (Number.isInteger(index)) this.imageRemarks.setValue(this.imageSet[index].smartImage.remark.value, { emitEvent: false });
            })
        );

        this.subscriptions.push(
            this.imageRemarks.valueChanges.subscribe(val => {
                this.imageSet[this.activeImage.value].smartImage.remark.value = val;
            })
        );

        this.subscriptions.push(
            this.zooming$.subscribe(() => {
                const clientRect = el.nativeElement.getBoundingClientRect();
                this.imageElementSize = {
                    width: clientRect.width,
                    height: clientRect.width / 1.6,
                };
                this.resizeCoefficient = this.imageElementSize.width / 800;
                this.drawAllArrows();
            })
        );
        this.subscriptions.push(
            this.mouseMovements$.subscribe(event => {
                if (
                    (event.offsetX > (this.imageSet[this.activeImage.value].ODCenterX - 100) * this.resizeCoefficient &&
                        event.offsetX < (this.imageSet[this.activeImage.value].ODCenterX + 100) * this.resizeCoefficient &&
                        event.offsetY > (this.imageSet[this.activeImage.value].ODCenterY - 100) * this.resizeCoefficient &&
                        event.offsetY < (this.imageSet[this.activeImage.value].ODCenterY + 100) * this.resizeCoefficient) ||
                    (event.offsetX > (this.imageSet[this.activeImage.value].OSCenterX - 100) * this.resizeCoefficient &&
                        event.offsetX < (this.imageSet[this.activeImage.value].OSCenterX + 100) * this.resizeCoefficient &&
                        event.offsetY > (this.imageSet[this.activeImage.value].OSCenterY - 100) * this.resizeCoefficient &&
                        event.offsetY < (this.imageSet[this.activeImage.value].OSCenterY + 100) * this.resizeCoefficient)
                ) {
                    this.allowClick = false;
                    this.cursorType = 'default';
                } else {
                    this.allowClick = true;
                    this.cursorType = 'pointer';
                }
            })
        );
    }

    private nextImage(): void {
        if (this.activeImage.value === this.imageSet.length - 1) return;
        this.activeImage.next(this.activeImage.value + 1);
    }
    private prevImage(): void {
        if (this.activeImage.value === 0) return;
        this.activeImage.next(this.activeImage.value - 1);
    }

    private moveVertexPoint(vertex: string): void {
        this.mouseMovementsSubscription = this.mouseMovements$.subscribe((event: MouseEvent) => {
            event.preventDefault();

            this.imageSet[this.activeImage.value][vertex] = event.offsetY / this.resizeCoefficient;

            this.allowClick = false;

            this.drawAllArrows();
        });
    }

    private moveCenterPoint(vertexTopX: string, vertexBottomX: string, centerX: string, centerY: string): void {
        this.mouseMovementsSubscription = this.mouseMovements$.subscribe((event: MouseEvent) => {
            event.preventDefault();
            this.imageSet[this.activeImage.value][centerX] = event.offsetX / this.resizeCoefficient;
            this.imageSet[this.activeImage.value][vertexTopX] = event.offsetX / this.resizeCoefficient;
            this.imageSet[this.activeImage.value][vertexBottomX] = event.offsetX / this.resizeCoefficient;

            this.imageSet[this.activeImage.value][centerY] = event.offsetY / this.resizeCoefficient;

            this.allowClick = false;

            this.drawAllArrows();
        });
    }

    private resizeCircle(size: string, centerX: string, centerY: string): void {
        this.mouseMovementsSubscription = this.mouseMovements$.subscribe((event: MouseEvent) => {
            event.preventDefault();

            const radius = Math.sqrt(
                Math.pow(Math.abs(event.offsetX / this.resizeCoefficient - this.imageSet[this.activeImage.value][centerX]), 2) +
                    Math.pow(Math.abs(event.offsetY / this.resizeCoefficient - this.imageSet[this.activeImage.value][centerY]), 2)
            );

            this.imageSet[this.activeImage.value][size] = radius * 2;

            this.allowClick = false;

            this.drawAllArrows();
        });
    }

    private drawAllArrows(): void {
        this.arrows.rightCenterArrow = this.drawCenterArrow(
            this.imageSet[this.activeImage.value].ODCenterX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODCenterY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODSize * this.resizeCoefficient
        );

        this.arrows.rightTopArrow = this.drawDistanceArrow(
            this.imageSet[this.activeImage.value].ODTopX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODTopY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODCenterX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODCenterY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODSize * this.resizeCoefficient
        );

        this.arrows.rightBottomArrow = this.drawDistanceArrow(
            this.imageSet[this.activeImage.value].ODCenterX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODCenterY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODBottomX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODBottomY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].ODSize * this.resizeCoefficient
        );

        this.arrows.leftCenterArrow = this.drawCenterArrow(
            this.imageSet[this.activeImage.value].OSCenterX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSCenterY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSSize * this.resizeCoefficient
        );

        this.arrows.leftTopArrow = this.drawDistanceArrow(
            this.imageSet[this.activeImage.value].OSTopX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSTopY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSCenterX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSCenterY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSSize * this.resizeCoefficient
        );

        this.arrows.leftBottomArrow = this.drawDistanceArrow(
            this.imageSet[this.activeImage.value].OSCenterX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSCenterY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSBottomX * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSBottomY * this.resizeCoefficient,
            this.imageSet[this.activeImage.value].OSSize * this.resizeCoefficient
        );
    }

    private drawCenterArrow(x: number, y: number, diameter: number): string {
        return `
        M${x - diameter / 2},${y}
        L${x + diameter / 2},${y}
        L${x + diameter / 2 - 5 * this.resizeCoefficient},${y - 2 * this.resizeCoefficient}
        L${x + diameter / 2 - 5 * this.resizeCoefficient},${y + 2 * this.resizeCoefficient}
        L${x + diameter / 2},${y}
        L${x - diameter / 2},${y}
        L${x - diameter / 2 + 5 * this.resizeCoefficient},${y - 2 * this.resizeCoefficient}
        L${x - diameter / 2 + 5 * this.resizeCoefficient},${y + 2 * this.resizeCoefficient}
        `;
    }

    private drawDistanceArrow(topX: number, topY: number, centerX: number, centerY: number, diameter: number): string {
        return `
        M${topX + diameter / 2},${topY}
        L${centerX + diameter / 2},${centerY}
        L${centerX + diameter / 2 + 2 * this.resizeCoefficient},${centerY - 5 * this.resizeCoefficient}
        L${centerX + diameter / 2 - 2 * this.resizeCoefficient},${centerY - 5 * this.resizeCoefficient}
        L${centerX + diameter / 2},${centerY}
        L${topX + diameter / 2},${topY}
        L${topX + diameter / 2 + 2 * this.resizeCoefficient},${topY + 5 * this.resizeCoefficient}
        L${topX + diameter / 2 - 2 * this.resizeCoefficient},${topY + 5 * this.resizeCoefficient}
        `;
    }

    private mouseUp(): void {
        if (this.mouseMovementsSubscription) {
            this.mouseMovementsSubscription.unsubscribe();
        }

        setTimeout(() => {
            this.allowClick = true;
        }, 0);
    }

    private absValue(value: number): number {
        return Math.abs(value);
    }
}
