export const LANG_HU_NAME = 'hu';

const general = {
    Normal: 'Normális',
    Abnormal: 'Abnormális',
    'Can not comprehend': 'Nem értelmezhető',
    OD: 'OD',
    OS: 'OS',
    OU: 'OU',
    ALT: 'ALT',
    open: 'Megnyitás',
    close: 'Kilépés',
    ok: 'OK',
    Yes: 'Igen',
    No: 'Nem',
    create: 'Létrehozás',
    edit: 'Szerkesztés',
    save: 'Mentés',
    submit: 'Elküldés',
    upload: 'Feltöltés',
    delete: 'Törlés',
    remove: 'Eltávolítás',
    cancel: 'Mégsem',
    clear: 'Mindent töröl',
    'add employer': 'Dolgozó hozzáadása',
    'go back': 'Vissza',
    'show report': 'Összefoglaló',
    'table report': 'Táblázatos összefoglaló',
    'view results': 'Eredmények mutatása',
    Start: 'Indítás',
    'In progress': 'Folyamatban',
    Pause: 'Szüneteltetés',
    Stop: 'Stop',
    'Redo test': 'Teszt elkészítése újra',
    'manual input': 'Kézi bevitel',
    'Send to doctor': 'Küldés orvosnak',
    'Send to tech staff': 'Küldés technikusnak',
    'lift up': 'HAPLO emelés',
    'log out': 'Kijelentkezés',
    'change theme': 'Téma változtatása',
    'go to examination': 'Tovább a vizsgálatra',
    compare: 'Összehasonlítás',
    'back table': 'Vissza a táblázatos összefoglalóhoz',
    'page not found': 'Oldal nem található',
};

const validation_messages = {
    'id required validation': 'Adja meg a beteg személyazonosító számát.',
    'first name required validation': 'Adja meg a felhasználó keresztnevét.',
    'last name required validation': 'Adja meg a felhasználó családnevét.',
    'email required validation': 'Adja meg a felhasználó e-mail címét.',
    'email pattern validation': 'Hibás e-mail cím.',
    'employer name required validation': 'Adja meg a munkaadó cégnevét.',
    'phone required validation': 'Adja meg a felhasználó telefonszámát.',
    'phone pattern validation': 'Hibás telefonszám.',
    'role required validation': 'Válassza ki a felhasználó szerepkörét.',
    'username required validation': 'A felhasználónév megadása kötelező.',
    'password required validation': 'A jelszó megadása kötelező.',
    'only letters': 'Csak betűk megadása lehetséges.',
    'user update successful': 'A felhasználót sikeresen frissítette.',
    'user create successful': 'A felhasználót sikeresen létrehozta.',
    'user create update unsuccessful': 'Hiba történt, kérjük, ellenőrizze a megadott adatokat és próbálkozzon újra.',
    'upload results successful': 'Sikeresen frissítette az összes adatot.',
    'upload results unsuccessful': 'Hiba történt az eredmények feltöltésekor. Kérjük, próbálkozzon újra.',
    'delete results successful': 'Minden adatot sikeresen törölt.',
    'delete results unsuccessful': 'Hiba történt az eredmények törlésekor. Kérjük, próbálkozzon újra.',
    'send examination successful': 'A vizsgálatot sikeresen elküldte.',
    'send examination unsuccessful': 'Hiba történt a vizsgálat elküldése közben. Kérjük, próbálkozzon újra.',
};

const charts = {
    Time: 'Idő',
    Speed: 'Sebesség',
    Downtime: 'Állásidő',
    Offline: 'Offline',
    'Pupil diameter break point avg. OS': 'Pupilla-átmérő átlagos töréspont OS',
    'Pupil diameter break point avg. OD': 'Pupilla-átmérő átlagos töréspont OD',
    'Pupil diameter start point avg. OS': 'Pupilla-átmérő átlagos kezdőpont OS',
    'Pupil diameter start point avg. OD': 'Pupilla-átmérő átlagos kezdőpont OD',
    'Recovery minimum': 'Minimum visszatérés',
    'Target distance': 'Céltávolság',
    'Pupil diameter': 'Pupilla-átmérő',
    'Trials vergence': 'Vergencia próba',
    'Trials pupils': 'Pupilla próba',
    NPC: 'NPC',
    'NPC minimum': 'NPC minimum',
    Target: 'Cél',
    'Missing Data': 'Hiányzó adat',
    Unseen: 'Nem látta',
    Seen: 'Látta',
    value: 'Érték',
    'OD Seen/Unseen': 'OD látta/nem látta',
    'OS Seen/Unseen': 'OS látta/nem látta',
    'Binocular Seen/Unseen': 'Két szemmel látta/nem látta',
    Binocular: 'Binokuláris',
    Saccade: 'Szakkád',
    'Peak Velocity': 'Legnagyobb sebesség',
    'Horizontal Saccades': 'Vízszintes szakkádok',
    'Left to Right': 'Balról jobbra',
    'Right to left': 'Jobbról balra',
    'Vertical Saccades': 'Függőleges szakkádok',
    Downwards: 'Lefelé',
    Upwards: 'Felfelé',
};

const tests = {
    test: 'Vizsgálat',
    diagnoses: 'Összefoglalás',
    'Basic visual assessment': 'Egyszerű látásvizsgálat',
    'Structural Assessment (SA)': 'Strukturális vizsgálat (SA)',
    'Visual Function Assessment (VFA)': 'Látásfunkció vizsgálat (VFA)',
    'Binocular Vision Assessment (BVA)': 'Binokuláris látásvizsgálat (BVA)',
    'Additional saccadio tests': 'További Saccadio vizsgálat',
    Lensometry: 'Lencsemérés',
    'Ocular Refraction': 'Fénytörés',
    Tonometry: 'Tonometria',
    Keratometry: 'Keratometria',
    Pachymetry: 'Pachimetria',
    'Anterior and Posterior segment evaluation': 'Elülső és hátsó szegmens értékelése',
    'Anterior chamber angle': 'Elülső csarnok szöge',
    ACA: 'ACA',
    'IPD, Contrast, Glare acuity and Opacity Index': 'IPD-Kontraszt-Fényérzékenység-Opacitásindex',
    'Visual Field evaluation': 'Látástér vizsgálat',
    'Amsler grid test': 'Amsler-rács teszt',
    'Colour vision': 'Színlátás',
    'Accommodation test': 'Akkomodációs teszt',
    'Sensory evaluation': 'Szenzoros értékelés',
    'Cover test': 'Szemtakarásos vizsgálat',
    'Smooth vergence assessment': 'Egyenletes vergencia felmérés',
    'Pupil assessment': 'Pupilla felmérése',
    'Smooth pursuit': 'Egyenletes követés',
    'Saccade test': 'Szakkád vizsgálat',
    'Dry Eye evaluation': 'Szemszárazság értékelés',
    'Nystagmus test': 'Nystagmus teszt',
    'Ptosis evaluation': 'Ptózis Értékelés',
    'with glasses': 'Szemüveggel',
    'without glasses': 'Szemüveg nélkül',
    'normal grid': 'Normál rács',
    'circle grid': 'Körkörös rács',
    'sector grid': 'Szektorális rács',
    'testing method': 'Vizsgálati módszer',
    diagnosis: 'Diagnózis',
    select: 'Kiválasztás',
    remarks: 'Megjegyzés',
    'view diagram': 'Diagram megtekintése',
    diopter: 'Dioptria',
    degree: 'Fok',
    degrees: 'Fokok',
    decimal: 'Decimális',
    axis: 'Tengely',
    Accommodation: 'Akkomodáció',
    Distortion: 'Torzulás',
    Scotoma: 'Látótérkiesés',
    'Anterior segment': 'Elülső szegmens',
    'Posterior segment': 'Hátsó szegmens',
    'Show AI results': 'AI eredmények megtekintése',
    'Back to Anterior/Posterior': 'Vissza az elülsőre/hátsóra',
    'Ishihara plates': 'Ishihara-tábla',
    Ishihara: 'Ishihara',
    'No. of Plate': 'Táblák száma',
    'Normal person': 'Egészséges',
    'Person with Red-Green Deficiencies': 'Vörös-zöld színtévesztés',
    'Person with total Colour Blindness and Weakness': 'Teljes színvakság és színtévesztés',
    'Deutran defect': 'Deután színtévesztés',
    'Protan defect': 'Protán színtévesztés',
    'RG defect': 'RG defektus',
    'Total color vision defect': 'Teljes színtévesztés',
    'With red filter': 'Vörös szűrővel',
    protan: 'Protán',
    duetan: 'Deután',
    strong: 'Erős',
    mild: 'Gyenge',
    'Near point of Convergence': 'Konvergenciaponthoz közel',
    recovery: 'Visszaállás',
    'Magnitude of deviation': 'Deviáció nagysága',
    Horizontal: 'Vízszintes',
    Vertical: 'Függőleges',
    'Base in': 'Bázis be',
    'Base out': 'Bázis ki',
    'Base down': 'Bázis le',
    'Base up': 'Bázis fel',
    PD: 'PD',
    eyelids: 'Szemhéjak',
    'Meibomian glands': 'Meibom-mirigy',
    conjuctiva: 'Kötőhártya',
    palpebral: 'Palpebrális',
    bulbar: 'Bulbáris',
    'Tear Film': 'Könnyfilm',
    'Inter pupillary Distance': 'Interpupilláris távolság',
    'Far pupillary distance': 'Nagy pupillatávolság',
    'Near pupillary distance': 'Közeli pupillatávolság',
    'Contrast and Glare Acuity': 'Kontraszt és fényérzékenység',
    'BCVA Distance': 'BCVA távolság',
    'Contrast acuity': 'Kontraszt alapú élesség',
    'Glare acuity': 'Fényérzékenység',
    'opacity index': 'Opacitás index',
    central: 'Centrális',
    peripheral: 'Periferális',
    'Previous Glass Prescription': 'Előző szemüveg adatai',
    eye: 'Szem',
    sphere: 'Szféra',
    cylinder: 'Cilinder',
    SE: 'SE',
    ADD: 'ADD',
    'Near Sphere': 'Közeli szféra',
    prism: 'Prizma',
    base: 'Bázis',
    'UV Transmittance': 'UV áteresztés',
    'Confidence Index': 'Bizonyossági mutató',
    'Lens Type': 'Lencsetípus',
    'Glass Status': 'Üveg státusza',
    'Single vision': 'Egyfókuszú',
    Bifocals: 'Bifokális',
    Progressives: 'Progresszív',
    'Which Eye': 'Melyik szem',
    type: 'Típus',
    Rotatory: 'Rotátoros',
    'See-Saw': 'Mérleghinta',
    Wandering: 'Kalandozó',
    Jerk: 'Rángó',
    Pendular: 'Inga',
    pattern: 'Minta',
    Torsional: 'Torzionális',
    Frequency: 'Gyakoriság',
    Low: 'Alacsony',
    Moderate: 'Mérsékelt',
    High: 'Magas',
    Amplitude: 'Mérték',
    Fine: 'Megfelelő',
    Large: 'Nagy',
    Medium: 'Közepes',
    Direction: 'Irány',
    Down: 'Le',
    Left: 'Balra',
    Right: 'Jobbra',
    Up: 'Fel',
    Symmetry: 'Szimmetria',
    'Face Turn': 'Arc elfordítása',
    'Head Tilt': 'Fej döntése',
    'Head Nodding': 'Fej bólintása',
    'Dampening on Convergence': 'Lankadó konvergencia',
    'Visual Acuity': 'Látásélesség',
    'Without Glass': 'Üveg nélkül',
    'With Glass': 'Üveggel',
    'Contact Lens': 'Kontaktlencse',
    DV: 'DV',
    NV: 'NV',
    WD: 'WD',
    autorefraction: 'Automatikus refrakció',
    DSph: 'DSph',
    DCyl: 'DCyl',
    BCVA: 'BCVA',
    acceptance: 'Elfogad',
    duochrome: 'Duokróm',
    Response: 'Reakció',
    fogging: 'Homályosítás',
    Balanced: 'Kiegyensúlyozott',
    'Red better': 'Piros jobb',
    'Green better': 'Zöld jobb',
    Add: 'Hozzáad',
    preference: 'Preferencia',
    'Prefers new Rx': 'Preferencia: új Rx',
    'Prefers old Rx': 'Preferencia: korábbi Rx',
    'Prefers Plano': 'Preferencia: Plano',
    'Final glass prescription': 'Végleges szemüveg adatai',
    near: 'Közeli',
    distance: 'Távoli',
    'Average central corneal thickness (microns)': 'Átlagos szaruhártyavastagság (mikron)',
    severity: 'Súlyosság',
    'Palpebral Fissure Height': 'Palpebrális fissura magassága',
    MRO: 'MRO',
    proptosis: 'Prptózis',
    'Chin Elevation': 'Állcsont-emelkedés',
    'Facial Assymetry': 'Arci asszimetria',
    Severe: 'Súlyos',
    size: 'Méret',
    'Reaction to Light': 'Reakció fényre',
    RAPD: 'RAPD',
    'Pupil Morphology': 'Pupilla morfológia',
    Brisk: 'Hirtelen',
    'Not reacting': 'Nem reagál',
    Sluggish: 'Lassú',
    'Ill sustained': 'Nem fenntartott',
    'Slow tonic': 'Lassú tonikus',
    Present: 'Van',
    Absent: 'Nincs',
    'Sphincter atrophy': 'Sphincter-atrófia',
    'Seclusio pupillae': 'Seclusio pupillae',
    'Occlusio pupillae': 'Occlusio pupillae',
    Polycoria: 'Policoria',
    'Sphincter tear': 'Sphincter szakadása',
    'Ability (Scoring)': 'Képesség (pont)',
    'Accuracy (Scoring)': 'Pontosság (pont)',
    'Head and Body Movement': 'Fej- és testmozgás',
    'Completes less than two round trips': 'Két körnél kevesebbet teljesít',
    'Completes two round trips': 'Két kört teljesít',
    'Completes three round trips': 'Három kört teljesít',
    'Completes four round trips Completes five round trips': 'Négy kört teljesít, Öt kört teljesít',
    'Large over - or undershooting is noted one or more times': 'A cél jelentős túl- vagy alullövése',
    'Moderate over - or undershooting noted one or more times': 'A cél mérsékelt túl- vagy alullövése',
    'Constant slight over - or undershooting noted (greater than 50% of the time)': 'A cél állandó, kismértékű túl- vagy alullövése (az idő több, mint 50%-ában)',
    'Intermittent slight over or undershooting noted (less than 50% of the time) No over - or undershooting noted':
        'A cél szakaszos, kismértékű túl- vagy alullövése (az idő kevesebb, mint 50%-ában) Nincs túl- vagy alullövés',
    'Large movement or the head (body) or any time': 'Nagymértékű, vagy bármikori fej- (test)mozgás',
    'Moderate movement of the head (body) of any time': 'Mérsékelt, vagy bármikori fej- (test)mozgás',
    'Slight movement of the head (body) (less than 50% of the time)': 'Kismértékű fej- (test)mozgás, vagy kevesebb mint az idő felében',
    'Intermittent slight over - or undershooting noted (less than 50% of the time)': 'A cél szakaszos, kismértékű túl- vagy alullövése (az idő kevesebb, mint 50%-ában)',
    'No movement of the head (body)': 'Nincs fej- (test)mozgás',
    'OD mm of Hg': 'OD higanymilliméter',
    'OS mm of Hg': 'OS higanymilliméter',
    'Distance worth four dot test': 'Távoli érték négypontos teszttel',
    'Near worth four dot test': 'Közeli érték négypontos teszttel',
    stereopsis: 'Sztereopszis',
    Diplopia: 'Diplópia',
    Fusion: 'Fúzió',
    'Left Suppression': 'Bal szuppresszió',
    'Right Suppression': 'Jobb szuppresszió',
    'Alternate Suppression': 'Váltakozó szuppresszió',
    'Unreliable Response': 'Nem megbízható reakció',
};

const user_patient = {
    id: 'Személyazonosító igazolvány',
    'patient id': 'Beteg személyazonosító igazolványa',
    'first name': 'Keresztnév',
    'last name': 'Családnév',
    ssn: 'Társadalombiztosítási szám (TAJ)',
    'ssn short': 'TAJ',
    gender: 'Neme',
    'birth date': 'Születési dátum',
    email: 'E-mail',
    phone: 'Telefon',
    employer: 'Munkaadó',
    name: 'Név',
    street: 'Utca',
    'postal code': 'Irányítószám',
    city: 'Város',
    country: 'Ország',
    role: 'Szerepkör',
    occupation: 'Foglalkozás',
    'marital status': 'Családi állapot',
    'main complaints': 'Főbb panaszok',
    'insurance company': 'Biztosító társaság',
    'insurance plan': 'Biztosítási terv',
    'past ocular information': '???Korábbi szembetegségek???',
    'past medical information': '???Korábbi betegségek???',
    'family information': '???Betegségek a családban???',
    'birth information': '???Születési háttér???',
    'personal information': '???Személyes háttér???',
    'allergy information': '???Allergiák???',
    other: 'Egyéb',
    ADMIN: 'Admin',
    STAFF: 'Személyzet',
    DOCTOR: 'Orvos',
    DISABLED: 'Fogyatékkal élő',
    MALE: 'Férfi',
    FEMALE: 'Nő',
    Married: 'Házas',
    Single: 'Egyedülálló',
    Regular: 'Általános',
    'Change of glasses': 'Szemüvegcsere',
    'Second opinion for cataract': 'Második szakvélemény: Szürkehályog',
    'Second opinion for glaucoma': 'Második szakvélemény: Zöldhályog',
    'Second opinion for retina': 'Második szakvélemény: Retina',
    'Second opinion for squint': 'Második szakvélemény: Kancsalság',
    'Second opinion for neuro ophthalmology': 'Második szakvélemény:  Neuro-Ophthalmológia',
    'Second opinion for oculoplasty': 'Második szakvélemény: Okuloplasztika',
    'Second opinion for uvea': 'Második szakvélemény: Uvea',
    'Second opinion for Cornea': 'Második szakvélemény: Szaruhártya',
    'Lasik opinion': 'Lasik vélemény',
    'ROP Screening': 'ROP-szűrés',
    'Follow Up': 'További kezelés',
    Others: 'Egyéb',
    Nil: 'Semmi',
    'Blurred distance': 'Homályos távol',
    'Blurred near': 'Homályos közel',
    Squint: 'Kancsalság',
    Headache: 'Fejfájás',
    Redness: 'Pirosság',
    Watering: 'Könnyezés',
    'Diminision of Vision Distance': 'Távolra látás csökkenése',
    'Diminision of Vision Near': 'Rövidlátás csökkenése',
    'Drooping of eyelid': 'Ernyedt szemhéj',
    'Shaking of eyeball': 'Szemremegés',
    'Prominent eyes': 'Dülledt szem',
    'White reflex': 'Fehér fényreflex',
    'Eye pain': 'Szemfájdalmak',
    Itching: 'Viszketés',
    Irritation: 'Irritáció',
    'Dryness of eyes': 'Szermszárazság',
    Cataract: 'Szürkehályog',
    'Diabetic Retinopathy': 'Diabéteszes retinopátia',
    Glaucoma: 'Zöldhályog',
    Keratoconus: 'Keratoconus',
    Myopia: 'Rövidlátás',
    RD: 'RD',
    DM: 'DM',
    'DM + HT': 'DM + HT',
    HT: 'HT',
    IHD: 'IHD',
    'Myopia/High refractive error': 'Rövidlátás / Nagy fénytörési hiba',
    'Diabetic retonipathy': 'Diabéteszes retinopátia',
    'Retinitis pigmentosa': 'Retinitis pigmentosa',
    'Macular Degeneration': 'Makula-degeneráció',
    Caesarian: 'Császármetszés',
    Forceps: 'Fogós szülés',
    'Not aware of any': 'Nem tud róla',
    Sulpha: 'Szulfanamid',
    Penicillin: 'Penicillin',
    Quinolones: 'Kinolonok',
    'Dilatation drops T+': 'Pupillatágító Szemcsepp T+',
    'Dilatation drops Cyclopentolate': 'Pupillatágító Szemcsepp Ciklopentolát',
    'Dilatation drops Homide': 'Pupillatágító Szemcsepp Homide',
    'Dilatation drops Phenylephrine': 'Pupillatágító Szemcsepp Fenilefrin',
    'Dilatation drops Tropicamide': 'Pupillatágító Szemcsepp Tropicamide',
    Anticonvulsants: 'Görcsgátlók',
};

const components = {
    hello: 'Üdvözöljük',
    'search results': 'Keresés eredménye...',
    'bulbihub examination overview': '???Áttekintés???',
    'patient search': 'Beteg keresése',
    all: 'Összes',
    done: 'Kész',
    examination: 'Vizsgálat',
    download: 'Letöltés',
    day: 'Nap',
    days: 'Napok',
    'start date': 'Kezdési dátum',
    'end date': 'Befejezési dátum',
    'server connection': 'Szerverkapcsolat',
    connection: 'Kapcsolat',
    connections: 'Connections',
    'missing values': 'Hiányzó értékek',
    'update password': 'Jelszó frissítése',
    'new password': 'Új jelszó',
    'repeat password': 'Jelszó megismétlése',
    'not equal passwords': 'A két jelszó nem azonos. Kérjük, próbálja újra.',
    'saccadio examination suite': 'Saccadio vizsgálati berendezés',
    'test status rec': 'Vizsgálati státusz',
    control: 'Kontroll',
    'data quality': 'Adatminőség',
    results: 'Eredmények',
    'patient information': '???Beteg-kórtörténet adatbázis???',
    login: 'Bejelentkezés',
    password: 'Jelszó',
    'forgot password': 'Elfelejtette a jelszavát?',
    'error email': 'Az e-mail cím vagy a jelszó hibár. Kérjük, jelentkezzen be újra.',
    '(STAFF)': '%0 %1 (SZEMÉLYZET)',
    '(DOCTOR)': '%0 %1 (ORVOS)',
    '(ADMIN)': '%0 %1 (ADMIN)',
    chat: 'Beszélgetés',
    'chat message placeholder': 'Írja be az üzenetet...',
    'add user': 'Felhasználó hozzáadása',
    users: 'Felhasználók',
    'add image': 'Képek hozzáadása',
    'forgot password title': 'Elfelejtett jelszó',
    'Do you want to remove this image?': 'El szeretné távolítani a képet?',
    'Save changes?': 'Elmenti a módosításokat?',
    'Are you sure you want to remove the selected result?': 'Biztosan el szeretné távolítani a kijelölt eredményt?',
    'Are you sure you want to remove the diagram?': 'Biztosan el szeretné távolítani a diagramot?',
    'password update error': 'Hiba a jelszó frissítésekor',
    'password updated success': 'A jelszót sikeresen frissítette',
    error: 'Hiba',
    'token invalid': 'Érvénytelen kód',
    'link expired': 'A hivatkozás lejárt. A jelszava frissítéséhez kattintson az "Elfelejtette a jelszavát?" gombra a bejelentkezési felületen.',
    'Posterior segment evaluation AI results': 'Hátulsó szegmens értékelés AI eredmények',
    'Disease / Indicator': 'Betegség / Indikátor',
    Prediction: 'Előrejelzés',
    'Confidence Level': 'Bizonyosság szintje',
    'Referable DR': 'Besorolható DR',
    'DR Severity Scale': 'DR súlyossági foka',
    DME: 'DME',
    AMD: 'AMD',
    'Myopic Maculopathy': 'Rövidlátó makulopátia',
    'Stroke / Arteriosclerosis': 'Szívroham / Érelmeszesedés',
    'Glycosylated Hemoglobin': 'Glikált hemoglobin',
    'Biological Age': 'Biológiai életkor',
    'Valid only for 50-90 years': 'Kizárólag 50-90 éves életkorra érvényes',
};

const countries = {
    AF: 'Afganisztán',
    AX: 'Åland-szigetek',
    AL: 'Albánia',
    DZ: 'Algéria',
    AS: 'Amerikai Szamoa',
    VI: 'Amerikai Virgin-szigetek',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktisz',
    AG: 'Antigua és Barbuda',
    AR: 'Argentína',
    AW: 'Aruba',
    AC: 'Ascension-sziget',
    AU: 'Ausztrália',
    AT: 'Ausztria',
    UM: 'Az Amerikai Egyesült Államok lakatlan külbirtokai',
    AZ: 'Azerbajdzsán',
    BS: 'Bahama-szigetek',
    BH: 'Bahrein',
    BD: 'Banglades',
    BB: 'Barbados',
    BY: 'Belarusz',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhután',
    GW: 'Bissau-Guinea',
    BO: 'Bolívia',
    BA: 'Bosznia-Hercegovina',
    BW: 'Botswana',
    BR: 'Brazília',
    IO: 'Brit Indiai-óceáni Terület',
    VG: 'Brit Virgin-szigetek',
    BN: 'Brunei',
    BG: 'Bulgária',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    EA: 'Ceuta és Melilla',
    CL: 'Chile',
    CY: 'Ciprus',
    KM: 'Comore-szigetek',
    CK: 'Cook-szigetek',
    CR: 'Costa Rica',
    CW: 'Curaçao',
    TD: 'Csád',
    CZ: 'Csehország',
    DK: 'Dánia',
    ZA: 'Dél-afrikai Köztársaság',
    KR: 'Dél-Korea',
    SS: 'Dél-Szudán',
    GS: 'Déli-Georgia és Déli-Sandwich-szigetek',
    DG: 'Diego Garcia',
    DM: 'Dominika',
    DO: 'Dominikai Köztársaság',
    DJ: 'Dzsibuti',
    EC: 'Ecuador',
    GQ: 'Egyenlítői-Guinea',
    US: 'Egyesült Államok',
    AE: 'Egyesült Arab Emírségek',
    GB: 'Egyesült Királyság',
    EG: 'Egyiptom',
    CI: 'Elefántcsontpart',
    UN: 'ENSZ',
    ER: 'Eritrea',
    KP: 'Észak-Korea',
    MP: 'Északi Mariana-szigetek',
    EE: 'Észtország',
    ET: 'Etiópia',
    EZ: 'Eurozone',
    FK: 'Falkland-szigetek',
    FO: 'Feröer-szigetek',
    FJ: 'Fidzsi',
    FI: 'Finnország',
    TF: 'Francia Déli Területek',
    GF: 'Francia Guyana',
    PF: 'Francia Polinézia',
    FR: 'Franciaország',
    PH: 'Fülöp-szigetek',
    GA: 'Gabon',
    GM: 'Gambia',
    GH: 'Ghána',
    GI: 'Gibraltár',
    GR: 'Görögország',
    GD: 'Grenada',
    GL: 'Grönland',
    GE: 'Grúzia',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GY: 'Guyana',
    HT: 'Haiti',
    BQ: 'Holland Karib-térség',
    NL: 'Hollandia',
    HN: 'Honduras',
    HK: 'Hongkong KKT',
    HR: 'Horvátország',
    IN: 'India',
    ID: 'Indonézia',
    IQ: 'Irak',
    IR: 'Irán',
    IE: 'Írország',
    IS: 'Izland',
    IL: 'Izrael',
    JM: 'Jamaica',
    JP: 'Japán',
    YE: 'Jemen',
    JE: 'Jersey',
    JO: 'Jordánia',
    KY: 'Kajmán-szigetek',
    KH: 'Kambodzsa',
    CM: 'Kamerun',
    CA: 'Kanada',
    IC: 'Kanári-szigetek',
    CX: 'Karácsony-sziget',
    QA: 'Katar',
    KZ: 'Kazahsztán',
    TL: 'Kelet-Timor',
    KE: 'Kenya',
    CN: 'Kína',
    KG: 'Kirgizisztán',
    KI: 'Kiribati',
    CC: 'Kókusz (Keeling)-szigetek',
    CO: 'Kolumbia',
    CG: 'Kongó - Brazzaville',
    CD: 'Kongó - Kinshasa',
    XK: 'Koszovó',
    CF: 'Közép-afrikai Köztársaság',
    CU: 'Kuba',
    KW: 'Kuvait',
    LA: 'Laosz',
    PL: 'Lengyelország',
    LS: 'Lesotho',
    LV: 'Lettország',
    LB: 'Libanon',
    LR: 'Libéria',
    LY: 'Líbia',
    LI: 'Liechtenstein',
    LT: 'Litvánia',
    LU: 'Luxemburg',
    MK: 'Macedónia',
    MG: 'Madagaszkár',
    HU: 'Magyarország',
    MO: 'Makaó KKT',
    MY: 'Malajzia',
    MW: 'Malawi',
    MV: 'Maldív-szigetek',
    ML: 'Mali',
    MT: 'Málta',
    IM: 'Man-sziget',
    MA: 'Marokkó',
    MH: 'Marshall-szigetek',
    MQ: 'Martinique',
    MR: 'Mauritánia',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexikó',
    MM: 'Mianmar (Burma)',
    FM: 'Mikronézia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongólia',
    ME: 'Montenegró',
    MS: 'Montserrat',
    MZ: 'Mozambik',
    NA: 'Namíbia',
    NR: 'Nauru',
    DE: 'Németország',
    NP: 'Nepál',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niue',
    NF: 'Norfolk-sziget',
    NO: 'Norvégia',
    EH: 'Nyugat-Szahara',
    IT: 'Olaszország',
    OM: 'Omán',
    RU: 'Oroszország',
    AM: 'Örményország',
    PK: 'Pakisztán',
    PW: 'Palau',
    PS: 'Palesztin Terület',
    PA: 'Panama',
    PG: 'Pápua Új-Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairn-szigetek',
    PT: 'Portugália',
    PR: 'Puerto Rico',
    RE: 'Réunion',
    RO: 'Románia',
    RW: 'Ruanda',
    KN: 'Saint Kitts és Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    VC: 'Saint Vincent és a Grenadine-szigetek',
    BL: 'Saint-Barthélemy',
    PM: 'Saint-Pierre és Miquelon',
    SB: 'Salamon-szigetek',
    SV: 'Salvador',
    SM: 'San Marino',
    ST: 'Sao Tomé és Príncipe',
    SC: 'Seychelle-szigetek',
    SL: 'Sierra Leone',
    SX: 'Sint Maarten',
    ES: 'Spanyolország',
    LK: 'Srí Lanka',
    SR: 'Suriname',
    CH: 'Svájc',
    SJ: 'Svalbard és Jan Mayen',
    SE: 'Svédország',
    WS: 'Szamoa',
    SA: 'Szaúd-Arábia',
    SN: 'Szenegál',
    SH: 'Szent Ilona',
    RS: 'Szerbia',
    SG: 'Szingapúr',
    SY: 'Szíria',
    SK: 'Szlovákia',
    SI: 'Szlovénia',
    SO: 'Szomália',
    SD: 'Szudán',
    SZ: 'Szváziföld',
    TJ: 'Tádzsikisztán',
    TW: 'Tajvan',
    TZ: 'Tanzánia',
    TH: 'Thaiföld',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TR: 'Törökország',
    TT: 'Trinidad és Tobago',
    TA: 'Tristan da Cunha',
    TN: 'Tunézia',
    TC: 'Turks- és Caicos-szigetek',
    TV: 'Tuvalu',
    TM: 'Türkmenisztán',
    UG: 'Uganda',
    NC: 'Új-Kaledónia',
    NZ: 'Új-Zéland',
    UA: 'Ukrajna',
    UY: 'Uruguay',
    UZ: 'Üzbegisztán',
    VU: 'Vanuatu',
    VA: 'Vatikán',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis és Futuna',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    CV: 'Zöld-foki Köztársaság',
};

export const LANG_HU_TRANS = {
    ...general,
    ...components,
    ...user_patient,
    ...tests,
    ...charts,
    ...validation_messages,
    ...countries,
};
