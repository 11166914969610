import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TEST_TYPE } from '../../../../../../../../../commonout/enum/test-type';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { ICamMessage } from '../../../../../../../../../commonout/interfaces/charts.model';
import { BulbicamTestFrontend } from '../../../../../_models/haplotestFrontend.class';
import { Acuity4KidsBulbicamTestFrontend } from '../../../../../_models/tests/BulbiCAM/acuity4KidsTestFrontend.class';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'acuity4kids-bulbicam-test-control',
    template: require('./acuity4kids-bulbicam-test-control.component.html'),
    styles: [require('./acuity4kids-bulbicam-test-control.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class Acuity4KidsBulbicamControlComponent extends BulbicamControlComponent {
    public enable(): void {}
    public disable(): void {}
    public test: Acuity4KidsBulbicamTestFrontend;
    private timestampToCompare: number;
    public isMessageFieldShown: boolean;
    public readonly patients: string[] = ['Adult', 'Child', 'Parent testround'];
    public patient = new FormControl(null, Validators.required);
    private boundMessageListener: Function;
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService) {
        super(bulbicamService, configService, socketService, authService);
        this.boundMessageListener = this.messagesHandler.bind(this);
    }
    private start(target: string): void {
        this.pendingResponse = true;
        this.bulbicamService
            .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, {
                target: target,
                patient: this.patient.value,
                username: this.authService.currentUser.username,
            })
            .subscribe(() => {
                this.patient.disable();
                this.pendingResponse = false;
            });
    }
    private localStop() {
        this.socketService.socket.on(this.test.type + 'ChartMessage', this.boundMessageListener);
        this.stop();
    }
    private messagesHandler(messages: ICamMessage[]) {
        let exitType3Message: ICamMessage = messages.find(m => m.exitType === 3);
        if (exitType3Message) {
            this.timestampToCompare = exitType3Message.timestamp;
            this.isMessageFieldShown = true;
            this.socketService.socket.off(this.test.type + 'ChartMessage', this.boundMessageListener);
        }
    }
    private async save(stopMessage: string): Promise<void> {
        const lastTestPosition: number = this.test.remarks.measurements[0].haplotests.length - 1;
        if (lastTestPosition < 0) console.log('Something unexpected happened here.');
        const camDataset: BulbicamTestFrontend = this.test.remarks.measurements[0].haplotests[lastTestPosition],
            responce: { editedMessage: ICamMessage; newFileId: string } = await this.bulbicamService
                .setStopMessage(this.test.examination._id, camDataset.haplotestData, this.timestampToCompare, stopMessage)
                .toPromise();
        this.bulbicamService.dataSource.next({ type: TEST_TYPE.ACUITY4KIDS, data: [responce.editedMessage] });
        camDataset.haplotestData = responce.newFileId;
        this.isMessageFieldShown = false;
        this.patient.enable();
    }
}
