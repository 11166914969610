import { Location } from '@angular/common';
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLE } from '../../../../../../../commonout/enum/role';
import { UserFrontend } from '../../../_models/userFrontend.class';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { UserService } from '../../../_services/user/user.service';

export abstract class UserDetailsComponent<T> implements OnInit {
    private role: ROLE;
    protected user: UserFrontend<T>;
    protected form: FormGroup;
    protected registerMode: boolean;
    protected constructor(
        role: ROLE,
        protected formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private userService: UserService,
        private location: Location,
        private authService: AuthenticationService,
        private router: Router
    ) {
        this.role = role;
        this.user = new UserFrontend<T>();
        this.buildForm();
    }

    async ngOnInit() {
        const id: string = this.route.snapshot.paramMap.get('id'),
            email: string = this.route.snapshot.queryParamMap.get('email'),
            role: string = this.route.snapshot.queryParamMap.get('role');
        if (id) {
            this.registerMode = false;
            this.user.setModel(await this.userService.getUser<T>(id).toPromise());
        } else if (email && role) {
            this.registerMode = true;
            this.user.role = ROLE[role];
            this.user.username = email;
            (<any>this.user.roleProperties).email = email;
        }
        this.buildForm();
    }

    abstract buildForm(): void;
    abstract fillRoleProps(rawFormValue: any): any;

    protected goBack(): void {
        this.location.back();
    }

    private async registerUser(): Promise<void> {
        try {
            this.form.disable();
            const rawFormValue = this.form.getRawValue(),
                model: any = {
                    _id: this.user._id,
                    username: rawFormValue.email,
                    role: this.role,
                    password: rawFormValue.password,
                    roleProperties: this.fillRoleProps(rawFormValue),
                };
            if (this.user._id) {
                this.user.setModel(await this.userService.updateUser<T>(model).toPromise());
            } else {
                this.user.setModel(await this.userService.createUser<T>(model).toPromise());
            }
            this.buildForm();
            this.form.enable();
        } catch (error) {}
    }

    protected async registerKey(): Promise<void> {
        const publicKey: any = await this.userService.registerKey(this.user._id).toPromise();
        publicKey.challenge = this.authService.decode(publicKey.challenge);
        publicKey.user.id = this.authService.decode(publicKey.user.id);
        const credential = await navigator.credentials.create({ publicKey });
        const credentialResponce = this.authService.publicKeyCredentialToJSON(credential);
        await this.authService.sendWebAuthResponse(credentialResponce).toPromise();
        this.router.navigate(['/']);
    }

    protected clear() {
        this.buildForm();
    }
}
