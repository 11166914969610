// @ts-nocheck
export const SMILE_IMG_INDEX = -1

let animal = (x: string) => `/frontend/src/assets/images/Animals images/${x}.png`
export const STIMULI_IMAGES = new Map()
// STIMULI_IMAGES.set(0, animal('Cow')) // the order from figma - not reliable likely
// STIMULI_IMAGES.set(1, animal('Rabbit'))
// STIMULI_IMAGES.set(2, animal('Elephant'))
// STIMULI_IMAGES.set(3, animal('Panda'))
// STIMULI_IMAGES.set(4, animal('Monkey'))
// STIMULI_IMAGES.set(5, animal('Sheep'))
// STIMULI_IMAGES.set(6, animal('Cock'))
// STIMULI_IMAGES.set(7, animal('Cat'))
// STIMULI_IMAGES.set(8, animal('Pig'))
// STIMULI_IMAGES.set(9, animal('Dog')) // remaining...

STIMULI_IMAGES.set(SMILE_IMG_INDEX, animal('smile'))

// order from akids diagram
STIMULI_IMAGES.set(0, animal('Cock'))
STIMULI_IMAGES.set(1, animal('Cow'))
STIMULI_IMAGES.set(2, animal('Dog'))
STIMULI_IMAGES.set(3, animal('Elephant'))
STIMULI_IMAGES.set(4, animal('Monkey'))
STIMULI_IMAGES.set(5, animal('Panda'))
STIMULI_IMAGES.set(6, animal('Pig'))
STIMULI_IMAGES.set(7, animal('Rabbit'))
STIMULI_IMAGES.set(8, animal('Sheep'))
STIMULI_IMAGES.set(9, animal('Cat'))

export const ANIMALS_DOMAIN = [0, 9]
export const SMILES_DOMAIN = [0, 19]
