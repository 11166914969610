/**
 * Enum that provides unique type of tests in HUB
 * order of items is corresponds to order of tests in views
 */
export enum TEST_TYPE {
    LENSOMETRY = 'LENSOMETRY',
    OCULAR_REFRACTION = 'OCULAR_REFRACTION',
    KERATOMETRY = 'KERATOMETRY',
    INTER_PUPILLARY_DISTANCE = 'INTER_PUPILLARY_DISTANCE',
    ACCOMMODATION = 'ACCOMMODATION',
    SMOOTH_PURSUIT = 'SMOOTH_PURSUIT',
    COLOUR_VISION_TEST = 'COLOUR_VISION_TEST',
    TONOMETRY = 'TONOMETRY',
    CONVERGENCE = 'CONVERGENCE',
    NYSTAGMUS_EVALUATION = 'NYSTAGMUS_EVALUATION',
    SACCADES = 'SACCADES',
    POSTERIOR_SEGMENT_EVALUATION = 'POSTERIOR_SEGMENT_EVALUATION',
    PUPILLARY_EVALUATION2 = 'PUPILLARY_EVALUATION2',
    PACHYMETRY = 'PACHYMETRY',
    ANTERIOR_SEGMENT_EVALUATION = 'ANTERIOR_SEGMENT_EVALUATION',
    ANTERIOR_CHAMBER_ANGLE = 'ANTERIOR_CHAMBER_ANGLE',
    DRY_EYE_EVALUATION = 'DRY_EYE_EVALUATION',
    PTOSIS_EVALUATION = 'PTOSIS_EVALUATION',
    VISUAL_FIELD_MERGED = 'VISUAL_FIELD_MERGED',
    MACULA_SCAN = 'MACULA_SCAN',
    THREED_SCAN = 'THREED_SCAN',
    ANTISACCADE = 'ANTISACCADE',
    ACUITY4KIDS = 'ACUITY4KIDS',
    DARK_ADAPTAION_AMD = 'DARK_ADAPTAION_AMD',
    WEBCAM_IMPORTS = 'WEBCAM_IMPORTS',
    RECORDING_TOOL = 'RECORDING_TOOL',
    PURSUITS_AND_SACCADES = 'PURSUITS_AND_SACCADES',
    FUNCTIONAL_SCREENING = 'FUNCTIONAL_SCREENING',
    SACCADE_MERGED = 'SACCADE_MERGED',
    FIXATION_TEST = 'FIXATION_TEST',
    PUPILLARY_EVALUATION = 'PUPILLARY_EVALUATION',
}
