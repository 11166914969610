import { Injectable } from '@angular/core';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

@Injectable()
export class Pupil20DotChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}
    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }
    constructor() {
        super();
    }
    public addData(frames: ICamMessage[]): Promise<void> {
        throw new Error('Method not implemented.');
    }
    public setCamData(frames: ICamMessage[]): void {
        throw new Error('Method not implemented.');
    }
    public export() {
        throw new Error('Method not implemented.');
    }
    public clearData(): void {
        throw new Error('Method not implemented.');
    }
}
