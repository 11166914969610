import { LensometryNidekLM7PTest } from '../../../../../../../../commonout/classes/tests/NIDEK/LM7P/lensometryNidekLM7PTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { LensometryTestRemarksComponent } from '../../../../_components';
import { LensometryTestRemarksFrontend } from '../../../test-remarks/NIDEK/LM7P/lensometryTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class LensometryNidekLm7pTestFrontend extends LensometryNidekLM7PTest {
    remarks: LensometryTestRemarksFrontend;
    public readonly remarksComponent: typeof LensometryTestRemarksComponent = LensometryTestRemarksComponent;
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.NONE;
    constructor() {
        super();
        this.remarks = new LensometryTestRemarksFrontend();
    }
}

export interface LensometryNidekLm7pTestFrontend extends TestFrontend {}
applyMixins(LensometryNidekLm7pTestFrontend, [TestFrontend]);
