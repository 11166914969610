import { OcularRefractionNidekArk1sTest } from '../../../../../../../../commonout/classes/tests/NIDEK/ARK1S/ocularRefractionNidekArk1sTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { OcularRefractionTestRemarksComponent } from '../../../../_components';
import { OcularRefractionArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/ocularRefractionArk1sTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class OcularRefractionNidekArk1sTestFrontend extends OcularRefractionNidekArk1sTest {
    remarks: OcularRefractionArk1sTestRemarksFrontend;
    public readonly remarksComponent: typeof OcularRefractionTestRemarksComponent = OcularRefractionTestRemarksComponent;
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.NONE;
    constructor() {
        super();
        this.remarks = new OcularRefractionArk1sTestRemarksFrontend();
    }
}

export interface OcularRefractionNidekArk1sTestFrontend extends TestFrontend {}
applyMixins(OcularRefractionNidekArk1sTestFrontend, [TestFrontend]);
