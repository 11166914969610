// @ts-nocheck
import * as d3 from 'd3';
// import * as _ from 'lodash';
import { Target } from '..';
// import { RawPoint } from './util'
import { Layout } from '../Layout';
// import { Option } from './Option'
// import { doTranslate } from '../lib';

// styling

const styleDefault = {
    fontFamily: 'DM Sans',
    fontSize: 20,
    // .style('width', '100px')
    // .style('color', '#123')
    // fillColor: 'transparent',
    // lineColor: '#eee',
    // lineKlass: '',
    // lineSize: 1,
};

type Style = typeof styleDefault;

// interface

interface Params {
    value: number;
    step: number;
    min?: number;
    // max?: number
    onChange: (value: number) => void;

    target: Target;
    area: Layout;
    style?: Partial<Style>;
}

// function

export function NumberInput(p: Params) {
    let style = { ...styleDefault, ...(p.style || {}) };
    let outerW = p.area.width();
    let outerH = p.area.height();

    let target = p.target
        .selectAll('foreignObject')
        .data([null])
        .join('foreignObject')
        .attr('x', p.area.left)
        .attr('y', p.area.top)
        .attr('width', `${outerW}px`)
        .attr('height', `${outerH}px`)
        .selectAll('div')
        .data([null])
        .join('xhtml:div')
        .style('width', '100%')
        .style('height', '100%');

    let input = target
        .selectAll('input')
        .data([null])
        .join('xhtml:input')
        .attr('type', 'number')
        .style('width', '100%')
        .style('height', '100%')
        .attr('step', p.step)
        .style('font-size', `${style.fontSize}px`)
        .style('font-family', style.fontFamily)
        .style('color', `#123`)
        .property('value', p.value)
        .on('mousedown', () => d3.event.stopPropagation())
        .on('mousemove', () => d3.event.stopPropagation())
        .on('mouseup', () => d3.event.stopPropagation())
        .on('change', (x) => {
            let value = d3.event.target.valueAsNumber;
            p.onChange(value);
        });

    if (p.min) {
        input.attr('min', p.min);
    }

    // let style = { ...styleDefault, ...style }
    // let styleParams = { theme: '' }
    // let color = p.color || ''

    // Option({
    //   items: [{}],

    //   isChecked: () => p.checked,
    //   onClick: p.onClick,
    //   coloring: () => color,
    //   naming: () => p.text,

    //   target: p.target,
    //   area: p.area,
    //   styleParams,
    // })

    // let x1 = Math.min(p.from.x, p.to.x)
    // let x2 = Math.max(p.from.x, p.to.x)

    // let y1 = Math.min(p.from.y, p.to.y)
    // let y2 = Math.max(p.from.y, p.to.y)

    // p.target
    //     .selectAll('.line')
    //     .data([null])
    //     .join('line')
    //     .attr('class', 'line ' + style.lineKlass)

    //     .attr('x1', x1)
    //     .attr('x2', x2)
    //     .attr('y1', y1)
    //     .attr('y2', y2)
    //     // .call(doTranslate({ x: x1, y: y1 }))
    //     // .attr('width', x2 - x1)
    //     // .attr('height', y2 - y1)

    //     .attr('fill', style.fillColor)
    //     .attr('stroke-width', style.lineSize)
    //     .attr('stroke', style.lineColor)
}
