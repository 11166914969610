import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { IPtosisBulbicamMeasuredData } from '../../../../../../common/interfaces/measuredData/BulbiCAM/ptosis-bulbicam.measuredData.interface';
import { PtosisBulbicamMeasuredData } from '../../../../../../common/models/measuredData/BulbiCAM/ptosisDataBulbicam.model';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { Pager } from '../../../_services/examination/export.service';
import { FileService } from '../../../_services/general/file.service';
import { BulbicamTestFrontend } from '../../haplotestFrontend.class';
import { RegisterEditFieldFrontend } from '../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../tableReportTestMode';
import { PtosisBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/ptosisBulbicamTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../measuredDataFrontend';

export class PtosisBulbicamMeasuredDataFrontend extends PtosisBulbicamMeasuredData implements MeasuredDataFrontend {
    public remarks: PtosisBulbicamTestRemarksFrontend;
    public haplotests: BulbicamTestFrontend[];
    public severity: {
        OD: RegisterEditFieldFrontend<string[]>;
        OS: RegisterEditFieldFrontend<string[]>;
    };
    public palpebralFissureHeight: {
        OD: RegisterEditFieldFrontend<number>;
        OS: RegisterEditFieldFrontend<number>;
    };
    public mro1: {
        OD: RegisterEditFieldFrontend<number>;
        OS: RegisterEditFieldFrontend<number>;
    };
    public mro2: {
        OD: RegisterEditFieldFrontend<number>;
        OS: RegisterEditFieldFrontend<number>;
    };
    public proptosis: {
        OD: RegisterEditFieldFrontend<number>;
        OS: RegisterEditFieldFrontend<number>;
    };
    public chinElevation: RegisterEditFieldFrontend<string>;
    public facialAsymetry: RegisterEditFieldFrontend<string>;
    constructor() {
        super();
        this.severity = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.palpebralFissureHeight = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.mro1 = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.mro2 = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.proptosis = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.chinElevation = new RegisterEditFieldFrontend();
        this.facialAsymetry = new RegisterEditFieldFrontend();
    }
    public setModel(model: IPtosisBulbicamMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.haplotests = model.haplotests?.map(htm => {
            const ht = new BulbicamTestFrontend();
            ht.model = htm;
            return ht;
        });
        this.severity.OD.setModel(model.severity.OD);
        this.severity.OS.setModel(model.severity.OS);
        this.palpebralFissureHeight.OD.setModel(model.palpebralFissureHeight.OD);
        this.palpebralFissureHeight.OS.setModel(model.palpebralFissureHeight.OS);
        this.mro1.OD.setModel(model.mro1.OD);
        this.mro1.OS.setModel(model.mro1.OS);
        this.mro2.OD.setModel(model.mro2.OD);
        this.mro2.OS.setModel(model.mro2.OS);
        this.proptosis.OD.setModel(model.proptosis.OD);
        this.proptosis.OS.setModel(model.proptosis.OS);
        this.chinElevation.setModel(model.chinElevation);
        this.facialAsymetry.setModel(model.facialAsymetry);
    }
    public async toPDF(
        pdf: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        let table = [];
        table.push({
            column1: '',
            column2: { content: 'OD', styles: { halign: 'center' } },
            column3: { content: 'OS', styles: { halign: 'center' } },
        });
        table.push({
            column1: 'Severity',
            column2: this.severity.OD.value,
            column3: this.severity.OS.value,
        });
        table.push({
            column1: 'Palpebral Fissure Height (mm)',
            column2: this.palpebralFissureHeight.OD.value,
            column3: this.palpebralFissureHeight.OS.value,
        });
        table.push({
            column1: 'MRO1 (mm)',
            column2: this.mro1.OD.value,
            column3: this.mro1.OS.value,
        });
        table.push({
            column1: 'MRO2 (mm)',
            column2: this.mro2.OD.value,
            column3: this.mro2.OS.value,
        });
        table.push({
            column1: 'Proptosis',
            column2: this.proptosis.OD.value,
            column3: this.proptosis.OS.value,
        });
        table.push({
            column1: 'Chin Elevation',
            column2: { content: this.chinElevation.value, colSpan: 2 },
            column3: '',
        });
        table.push({
            column1: 'Facial Assymetry',
            column2: { content: this.facialAsymetry.value, colSpan: 2 },
            column3: '',
        });
        ///
        // if (this.images.length > 0) {
        //     await this.downloadMediaData(fileService);
        //     let ratio: number = await new Promise<number>((res) => {
        //         let img = new Image();
        //         img.onload = () => {
        //             let height = img.height;
        //             let width = img.width;
        //             res(width / height);
        //         };
        //         img.src = this.images[0].imageContent;
        //     });
        //     const imageWidth: number = (pdf.internal.pageSize.getWidth() - (pager.margin.left + pager.margin.right)) / 3;
        //     const imageHeight: number = imageWidth / ratio;
        //     this.images.forEach((image, index) => {
        //         if (index % 3 === 0) {
        //             table.push({
        //                 column1: {
        //                     content: '',
        //                     styles: {
        //                         minCellHeight: imageHeight,
        //                         minCellWidth: imageWidth,
        //                     },
        //                 },
        //                 column2: {
        //                     content: '',
        //                     styles: {
        //                         minCellHeight: imageHeight,
        //                         minCellWidth: imageWidth,
        //                     },
        //                 },
        //                 column3: {
        //                     content: '',
        //                     styles: {
        //                         minCellHeight: imageHeight,
        //                         minCellWidth: imageWidth,
        //                     },
        //                 },
        //             });
        //         }
        //     });
        // }
        ///
        // (pdf as any).autoTable({
        //     body: table,
        //     startY: pager.line,
        //     pageBreak: 'auto',
        //     theme: 'grid',
        //     margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
        //     didParseCell: (data: any) => {},
        //     didDrawCell: <CellHookHandler>((data) => {
        //         if (data.section === 'body' && data.row.index > 7) {
        //             if (this.images[data.row.index - 8]) {
        //                 pdf.addImage(this.images[data.row.index - 8].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
        //             }
        //         }
        //     }),
        //     didDrawPage: () => {},
        // });
        // pager.line = (pdf as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        let ODvalues = (this.severity.OD.value || 'x') + ' ' + (this.mro1.OD.value || 'x') + ' ' + (this.mro2.OD.value || 'x') + ' ' + (this.proptosis.OD.value || 'x'),
            OSvalues = (this.severity.OS.value || 'x') + ' ' + (this.mro1.OS.value || 'x') + ' ' + (this.mro2.OS.value || 'x') + ' ' + (this.proptosis.OS.value || 'x'),
            OUvalues = (this.chinElevation.value || 'x') + ' ' + (this.facialAsymetry.value || 'x');

        if (ODvalues !== 'x x x x') {
            formattedTest.OD.values = ODvalues;
        }

        if (OSvalues !== 'x x x x') {
            formattedTest.OS.values = OSvalues;
        }

        if (OUvalues !== 'x x') {
            formattedTest.OU.values = OUvalues;
        }
        if (this.haplotests.length > 0) {
            formattedTest.haplotest = this.haplotests[this.haplotests.length - 1].haplotestData;
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        try {
            // await Promise.all(this.imageSets.map((set) => set.loadMedia(fileService)));
        } catch (error) {
            console.error(error);
        }
    }
}
