import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IUser } from '../../../../../../../../../../commonout/interfaces/user';
import { IUserFrontend } from '../../../../../../../../../../commonout/interfaces/userFrontend.interface';
import { OcularRefractionNidekArk1sTestFrontend } from '../../../../../../_models/tests/NIDEK/ARK1S/ocularRefractionNidekArk1sTestFrontend.class';
import { ExaminationService } from '../../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../../_services/general/auth.service';
import { FileService } from '../../../../../../_services/general/file.service';
import { ModalService } from '../../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../../testRemarksComponent';

@Component({
    selector: 'ocular-refraction-test-remarks',
    template: require('./ocular-refraction-test-remarks.component.html'),
    styles: [require('../../../common-remarks.scss'), require('./ocular-refraction-test-remarks.component.scss')],
})
export class OcularRefractionTestRemarksComponent extends TestRemarksComponent {
    test: OcularRefractionNidekArk1sTestFrontend;
    private readonly preference: string[] = ['Prefers new Rx', 'Prefers old Rx', 'Prefers Plano', 'No improvement with Rx'];
    private readonly response: string[] = ['Balanced', 'Red better', 'Green better'];
    private readonly fogging: string[] = ['Yes', 'No'];
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            dataBlock: this.formBuilder.array([]),
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.enableEventEmission = false;
        await Promise.all(this.test.remarks.measurements.map(m => m.downloadMediaData(this.fileService)));
        this.remarksForm.patchValue(
            {
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
        this.remarksForm.setControl(
            'dataBlock',
            this.formBuilder.array(
                this.test.remarks.measurements
                    .map(m => {
                        return this.formBuilder.group({
                            sourceFileName: { value: m.sourceFileName, disabled: true },
                            visualAcuity: this.formBuilder.group({
                                withoutGlasses: this.formBuilder.group({
                                    OD: this.formBuilder.group({
                                        distance: m.visualAcuity.withoutGlasses.OD.distance.value,
                                        near: m.visualAcuity.withoutGlasses.OD.near.value,
                                        workingDistance: m.visualAcuity.withoutGlasses.OD.workingDistance.value,
                                    }),
                                    OS: this.formBuilder.group({
                                        distance: m.visualAcuity.withoutGlasses.OS.distance.value,
                                        near: m.visualAcuity.withoutGlasses.OS.near.value,
                                        workingDistance: m.visualAcuity.withoutGlasses.OS.workingDistance.value,
                                    }),
                                }),
                                withGlasses: this.formBuilder.group({
                                    OD: this.formBuilder.group({
                                        distance: m.visualAcuity.withGlasses.OD.distance.value,
                                        near: m.visualAcuity.withGlasses.OD.near.value,
                                        workingDistance: m.visualAcuity.withGlasses.OD.workingDistance.value,
                                    }),
                                    OS: this.formBuilder.group({
                                        distance: m.visualAcuity.withGlasses.OS.distance.value,
                                        near: m.visualAcuity.withGlasses.OS.near.value,
                                        workingDistance: m.visualAcuity.withGlasses.OS.workingDistance.value,
                                    }),
                                }),
                                contactLens: this.formBuilder.group({
                                    OD: this.formBuilder.group({
                                        distance: m.visualAcuity.contactLens.OD.distance.value,
                                        near: m.visualAcuity.contactLens.OD.near.value,
                                        workingDistance: m.visualAcuity.contactLens.OD.workingDistance.value,
                                    }),
                                    OS: this.formBuilder.group({
                                        distance: m.visualAcuity.contactLens.OS.distance.value,
                                        near: m.visualAcuity.contactLens.OS.near.value,
                                        workingDistance: m.visualAcuity.contactLens.OS.workingDistance.value,
                                    }),
                                }),
                            }),
                            objectiveRefraction: this.formBuilder.group({
                                OD: this.formBuilder.group({
                                    sphere: { value: m.objectiveRefraction.OD.sphere.value, disabled: true },
                                    cylinder: { value: m.objectiveRefraction.OD.cylinder.value, disabled: true },
                                    axis: { value: m.objectiveRefraction.OD.axis.value, disabled: true },
                                }),
                                OS: this.formBuilder.group({
                                    sphere: { value: m.objectiveRefraction.OS.sphere.value, disabled: true },
                                    cylinder: { value: m.objectiveRefraction.OS.cylinder.value, disabled: true },
                                    axis: { value: m.objectiveRefraction.OS.axis.value, disabled: true },
                                }),
                            }),
                            subjectiveRefraction: this.formBuilder.group({
                                OD: this.formBuilder.group({
                                    sphere: { value: m.subjectiveRefraction.OD.sphere.value, disabled: true },
                                    cylinder: { value: m.subjectiveRefraction.OD.cylinder.value, disabled: true },
                                    axis: { value: m.subjectiveRefraction.OD.axis.value, disabled: true },
                                    distanceVisualAcuity: { value: m.subjectiveRefraction.OD.distanceVisualAcuity.value, disabled: true },
                                }),
                                OS: this.formBuilder.group({
                                    sphere: { value: m.subjectiveRefraction.OS.sphere.value, disabled: true },
                                    cylinder: { value: m.subjectiveRefraction.OS.cylinder.value, disabled: true },
                                    axis: { value: m.subjectiveRefraction.OS.axis.value, disabled: true },
                                    distanceVisualAcuity: { value: m.subjectiveRefraction.OS.distanceVisualAcuity.value, disabled: true },
                                }),
                            }),
                            duochromeTest: this.formBuilder.group({
                                OS: this.formBuilder.group({
                                    response: m.duochromeTest.OS.response.value,
                                    fogging: m.duochromeTest.OS.fogging.value,
                                }),
                                OD: this.formBuilder.group({
                                    response: m.duochromeTest.OD.response.value,
                                    fogging: m.duochromeTest.OD.fogging.value,
                                }),
                            }),
                            add: this.formBuilder.group({
                                OD: this.formBuilder.group({
                                    dsph: { value: m.add.OD.dsph.value, disabled: true },
                                    nva: { value: m.add.OD.nva.value, disabled: true },
                                    cm: m.add.OD.cm.value,
                                    preference: m.add.OD.preference.value,
                                }),
                                OS: this.formBuilder.group({
                                    dsph: { value: m.add.OS.dsph.value, disabled: true },
                                    nva: { value: m.add.OS.nva.value, disabled: true },
                                    cm: m.add.OS.cm.value,
                                    preference: m.add.OS.preference.value,
                                }),
                            }),
                            finalPrescription: this.formBuilder.group({
                                OD: this.formBuilder.group({
                                    sphere: { value: m.finalPrescription.OD.sphere.value, disabled: true },
                                    cylinder: { value: m.finalPrescription.OD.cylinder.value, disabled: true },
                                    axis: { value: m.finalPrescription.OD.axis.value, disabled: true },
                                    distanceVisualAcuity: { value: m.finalPrescription.OD.distanceVisualAcuity.value, disabled: true },
                                    dsph: { value: m.finalPrescription.OD.dsph.value, disabled: true },
                                    cylinderNear: { value: m.finalPrescription.OD.cylinderNear.value, disabled: true },
                                    axisNear: { value: m.finalPrescription.OD.axisNear.value, disabled: true },
                                    nva: { value: m.finalPrescription.OD.nva.value, disabled: true },
                                    cm: m.finalPrescription.OD.cm.value,
                                }),
                                OS: this.formBuilder.group({
                                    sphere: { value: m.finalPrescription.OS.sphere.value, disabled: true },
                                    cylinder: { value: m.finalPrescription.OS.cylinder.value, disabled: true },
                                    axis: { value: m.finalPrescription.OS.axis.value, disabled: true },
                                    distanceVisualAcuity: { value: m.finalPrescription.OS.distanceVisualAcuity.value, disabled: true },
                                    dsph: { value: m.finalPrescription.OS.dsph.value, disabled: true },
                                    cylinderNear: { value: m.finalPrescription.OS.cylinderNear.value, disabled: true },
                                    axisNear: { value: m.finalPrescription.OS.axisNear.value, disabled: true },
                                    nva: { value: m.finalPrescription.OS.nva.value, disabled: true },
                                    cm: m.finalPrescription.OS.cm.value,
                                }),
                            }),
                        });
                    })
                    .sort(this.sort)
            )
        );
        this.enableEventEmission = true;
    }
    public convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void {
        this.test.remarks.measurements.forEach(m => {
            const formItem: any = rawFormValue.dataBlock.find((item: any) => item.sourceFileName === m.sourceFileName),
                user: IUser<any> & IUserFrontend = this.authenticationService.currentUser.getModel(true);
            if (!formItem) throw 'Error managing formarray in ' + this.test.name;
            m.visualAcuity.withoutGlasses.OD.distance.registerEdit(formItem.visualAcuity.withoutGlasses.OD.distance, user);
            m.visualAcuity.withoutGlasses.OD.near.registerEdit(formItem.visualAcuity.withoutGlasses.OD.near, user);
            m.visualAcuity.withoutGlasses.OD.workingDistance.registerEdit(formItem.visualAcuity.withoutGlasses.OD.workingDistance, user);
            m.visualAcuity.withoutGlasses.OS.distance.registerEdit(formItem.visualAcuity.withoutGlasses.OS.distance, user);
            m.visualAcuity.withoutGlasses.OS.near.registerEdit(formItem.visualAcuity.withoutGlasses.OS.near, user);
            m.visualAcuity.withoutGlasses.OS.workingDistance.registerEdit(formItem.visualAcuity.withoutGlasses.OS.workingDistance, user);

            m.visualAcuity.withGlasses.OD.distance.registerEdit(formItem.visualAcuity.withGlasses.OD.distance, user);
            m.visualAcuity.withGlasses.OD.near.registerEdit(formItem.visualAcuity.withGlasses.OD.near, user);
            m.visualAcuity.withGlasses.OD.workingDistance.registerEdit(formItem.visualAcuity.withGlasses.OD.workingDistance, user);
            m.visualAcuity.withGlasses.OS.distance.registerEdit(formItem.visualAcuity.withGlasses.OS.distance, user);
            m.visualAcuity.withGlasses.OS.near.registerEdit(formItem.visualAcuity.withGlasses.OS.near, user);
            m.visualAcuity.withGlasses.OS.workingDistance.registerEdit(formItem.visualAcuity.withGlasses.OS.workingDistance, user);

            m.visualAcuity.contactLens.OD.distance.registerEdit(formItem.visualAcuity.contactLens.OD.distance, user);
            m.visualAcuity.contactLens.OD.near.registerEdit(formItem.visualAcuity.contactLens.OD.near, user);
            m.visualAcuity.contactLens.OD.workingDistance.registerEdit(formItem.visualAcuity.contactLens.OD.workingDistance, user);
            m.visualAcuity.contactLens.OS.distance.registerEdit(formItem.visualAcuity.contactLens.OS.distance, user);
            m.visualAcuity.contactLens.OS.near.registerEdit(formItem.visualAcuity.contactLens.OS.near, user);
            m.visualAcuity.contactLens.OS.workingDistance.registerEdit(formItem.visualAcuity.contactLens.OS.workingDistance, user);

            m.duochromeTest.OD.fogging.registerEdit(formItem.duochromeTest.OD.fogging, user);
            m.duochromeTest.OD.response.registerEdit(formItem.duochromeTest.OD.response, user);
            m.duochromeTest.OS.fogging.registerEdit(formItem.duochromeTest.OS.fogging, user);
            m.duochromeTest.OS.response.registerEdit(formItem.duochromeTest.OS.response, user);

            m.add.OD.cm.registerEdit(formItem.add.OD.cm, user);
            m.add.OD.preference.registerEdit(formItem.add.OD.preference, user);
            m.add.OS.cm.registerEdit(formItem.add.OS.cm, user);
            m.add.OS.preference.registerEdit(formItem.add.OS.preference, user);

            m.finalPrescription.OD.cm.registerEdit(formItem.finalPrescription.OD.cm, user);
            m.finalPrescription.OS.cm.registerEdit(formItem.finalPrescription.OS.cm, user);
        });
        this.test.remarks.diagnosis.registerEdit(rawFormValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(rawFormValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(rawFormValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(rawFormValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
