// @ts-nocheck
import * as d3 from 'd3';
import { translate, getNewClass } from './util';
import { Target } from './types';
import { comboDefaultStyle } from './ComboBox';
import { typeObj } from '../../charts/saccade-test/diagram/DirectionsPreview';

let exampleStyle = {
    optionTick: { color: '', size: 0, klass: '' },
    comboText: { color: '', size: 0 },
};
type OptionStyle = typeof exampleStyle;

interface Preview {
    x: number;
    render: (target: Target) => void;
}
interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}
interface Margins {
    left: number;
}
interface OptionParams {
    klass?: string;
    text: string;
    handler: () => void;
    height: number;
    textAlign?: string;
    getChecked?: () => boolean;
    doNotClose?: boolean;
    preview?: Preview;
    style?: OptionStyle;
    theme?: string;
    tickScale?: number;
    tickColor?: string;
}

function drawArrows(target: Target, color: string, translate: string, rotation: string) {
    target
        .selectAll('path')
        .data([null])
        .join('path')
        .attr('d', 'M0 0 L30 0 L30 -10 L60 7.5 L30 25 L30 15 L0 15 L0 0')
        .attr('stroke-width', 2)
        .attr('stroke', color)
        .attr('fill', color)
        .attr('transform', `translate(${translate}) rotate(${rotation})`);
}

// ugly prly: takes "y" returns "text"
export class GroupOptionArrow {
    eventName = 'mousedown';

    klass: string;
    style: OptionStyle;

    constructor(private params: OptionParams) {
        this.klass = this.params.klass || getNewClass('option');
        this.style = params.style || comboDefaultStyle(params.theme);
    }

    // get style () {
    //     return this.params.style;
    // }
    get text() {
        return this.params.text;
    }
    get handler() {
        return this.params.handler;
    }
    get height() {
        return this.params.height;
    }
    get getChecked() {
        return this.params.getChecked || (() => false);
    }
    get hasCheckbox() {
        return !!this.params.getChecked;
    }
    get preview() {
        return this.params.preview;
    }
    get textAlign() {
        return this.params.textAlign || 'center';
    }
    get doNotClose() {
        return this.params.doNotClose || false;
    }

    render(g: Target, rect: Rect) {
        let klass = this.klass;
        let selector = `.${klass}`;

        let real = !!this.text;

        let padX = 10;
        let marginLeft = 40;

        let target = g
            .selectAll(selector)
            .data([null])
            .join('g')
            .attr('cursor', real ? 'pointer' : 'default')
            .attr('class', klass);

        let tickScale = this.params.tickScale || 30;
        let checkboxScale = `scale(${tickScale})`;

        let margins = { left: marginLeft };

        let positionCenter = (rect: Rect) => (x: Target) =>
            x
                .attr('x', rect.x + rect.width / 2)
                .attr('y', rect.y + rect.height / 2)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle');
        let positionLeft = (rect: Rect, margins: Margins) => (x: Target) =>
            x
                .attr('x', rect.x + margins.left + padX) // + rect.width / 2)
                .attr('y', rect.y + rect.height / 2)
                // .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle');

        let position = this.textAlign === 'center' ? positionCenter(rect) : positionLeft(rect, margins);

        let checked = this.getChecked();
        let on = checked ? 'on' : 'off';

        if (this.text === 'AllOD' || this.text === 'AllOS') {
            const color = on === 'on' ? 'black' : 'gray';

            target
                .selectAll('circle')
                .data([null])
                .join('circle')
                .attr('r', 25)
                .attr('fill', color)
                .attr('transform', 'translate(100, 100)');

            target
                .selectAll('text')
                .data([null])
                .join('text')
                .text('ON/OFF')
                .attr('transform', translate({ x: 78, y: 104 }))
                .attr('font-size', 11);
        }

        target.selectAll('path').remove();

        if ((this.text === 'GreenOD' || this.text === 'GreenOS') && typeObj.type === 'NEUROFIELD') {
            const color = on === 'on' ? '#5f0' : 'gray';

            drawArrows(target, color, '133, 126', '45');
        }

        if ((this.text === 'Blue2OD' || this.text === 'Blue2OS') && typeObj.type === 'NEUROFIELD') {
            const color = on === 'on' ? '#00f' : 'gray';

            drawArrows(target, color, '78, 133', '136');
        }

        if ((this.text === 'PurpleOD' || this.text === 'PurpleOS') && typeObj.type === 'NEUROFIELD') {
            const color = on === 'on' ? '#2D9CDB' : 'gray';

            drawArrows(target, color, '67, 79', '216');
        }

        if ((this.text === 'STD indicators OD' || this.text === 'STD indicators OS') && typeObj.type === 'NEUROFIELD') {
            const color = on === 'on' ? '#88f' : 'gray';

            drawArrows(target, color, '120, 68', '-45');
        }

        if (this.text === 'UpwardsOD' || this.text === 'UpwardsOS') {
            const color = on === 'on' ? '#f2c94c' : 'gray';

            drawArrows(target, color, '90, 61', '-90');
        }

        if (this.text === 'MedialOD' || (this.text === 'MedialOS' && typeObj.type === 'NEUROFIELD')) {
            const color = on === 'on' ? '#f2994a' : 'gray';

            drawArrows(target, color, '64, 110', '-180');
        }

        if (this.text === 'MedialOS' && typeObj.type !== 'NEUROFIELD') {
            const color = on === 'on' ? '#f2994a' : 'gray';

            drawArrows(target, color, '138, 96', '0');
        }

        if (this.text === 'DownwardsOD' || this.text === 'DownwardsOS') {
            const color = on === 'on' ? '#eb5757' : 'gray';

            drawArrows(target, color, '106, 135', '90');
        }

        if (this.text === 'DiagonalOD' || (this.text === 'DiagonalOS' && typeObj.type === 'NEUROFIELD')) {
            const color = on === 'on' ? '#fff' : 'gray';

            drawArrows(target, color, '138, 96', '0');
        }

        if (this.text === 'DiagonalOS' && typeObj.type !== 'NEUROFIELD') {
            const color = on === 'on' ? '#fff' : 'gray';

            drawArrows(target, color, '64, 110', '-180');
        }

        target.on(this.eventName, () => {
            if (this.doNotClose) {
                let e = d3.event;
                e.stopPropagation();
            }
            this.handler();
        });

        if (this.preview) {
            let y = rect.y + rect.height / 2;
            let g = target
                .selectAll('.gPreview')
                .data([null])
                .join('g')
                .attr('class', 'gPreview')
                .attr('transform', translate({ x: this.preview.x, y }));
            this.preview.render(g);
        }
    }
}
