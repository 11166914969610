// @ts-nocheck
// import * as d3 from 'd3';
import * as _ from 'lodash';

import { Target, Layout } from '..';
import { doTranslate } from '..';
import { getStyle } from './util'
import { Brand } from './Brand'

// styling

const styleDefault = {
  // fontColor: '#fff',
  fontColor: '',
  fontSize: 16, //40,
  fontWeight: 600 as number|string,
  fontFamily: Brand.Font,
  fontClass: '',
  // fontFamily: 'Orbitron',
}

type Style = typeof styleDefault

const themeOverrides: { [k:string]: Partial<Style> } = {
  // bright: {
  //   fontColor: '#123',
  // },
  // 'brand-bright': {
  //   fontFamily: Brand.Font,
  //   fontWeight: 400,
  // },
  // 'brand-dark': {
  //   fontFamily: Brand.Font,
  //   fontWeight: 400,

  //   fontColor: Brand.notDark,
  // },
}

// interface

interface Params {
    text: string;
    alignLeft?: boolean;
    alignRight?: boolean;
    alignTop?: boolean;
    show?: boolean;

    target: Target;
    area: Layout;
    styleParams?: {
      theme?: string,
      override?: Partial<Style>,
    }
}

// function

export function Header(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {})
    let show = p.show == null ? true : p.show
    let text = show ? p.text : null

    let toLeft = p.alignLeft && !p.alignRight
    let toRight = p.alignRight && !p.alignLeft

    let anchor = 'middle'
    if (toLeft) anchor = 'start'
    if (toRight) anchor = 'end'

    let point;
    if (!p.alignTop) {
      // middle-ish
      if (toLeft) {
          point = p.area.centerPoint().withX(p.area.left);
      } else if (toRight) {
          point = p.area.centerPoint().withX(p.area.right);
      } else {
          point = p.area.centerPoint();
      }
    } else {
      // top
      if (toLeft) {
          point = p.area.cornerTL();
      } else if (toRight) {
          point = p.area.cornerTR();
      } else {
          point = p.area.centerPoint().withY(p.area.top);
      }
    }
    p.target
        .selectAll('.header')
        .data(_.compact([text]))
        .join('text')
        .attr('class', `header ${style.fontClass}`)
        .text((d) => d)
        .call(doTranslate(point))

        .attr('text-anchor', anchor)
        .attr('dominant-baseline', () => p.alignTop ? 'hanging' : 'middle')

        .style('fill', style.fontColor)
        .style('font-family', style.fontFamily)
        .style('font-size', style.fontSize)
        .style('font-weight', style.fontWeight);
}
