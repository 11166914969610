import { AntSegmentTopconTritonTest } from '../../../../../../../../commonout/classes/tests/TOPCON/TRITON/antSegmentTopconTritonTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { AntSegmentTopconTritonTestRemarksComponent } from '../../../../_components';
import { AntSegmentTopconTritonTestRemarksFrontend } from '../../../test-remarks/TOPCON/TRITON/antSegmentTopconTritonTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class AntSegmentTopconTritonTestFrontend extends AntSegmentTopconTritonTest {
    remarks: AntSegmentTopconTritonTestRemarksFrontend;
    public readonly remarksComponent: typeof AntSegmentTopconTritonTestRemarksComponent = AntSegmentTopconTritonTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new AntSegmentTopconTritonTestRemarksFrontend();
    }
}

export interface AntSegmentTopconTritonTestFrontend extends TestFrontend {}
applyMixins(AntSegmentTopconTritonTestFrontend, [TestFrontend]);
