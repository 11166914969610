import { BlockingType } from '../../../../../commonout/enum/blockingType.enum';
import { IUser } from '../../../../../commonout/interfaces/user';
import { Edit } from '../../../../common/models/edit.model';
import { RegisterEditField } from '../../../../common/models/registerEditField.class';

export class RegisterEditFieldFrontend<T> extends RegisterEditField<T> {
    constructor(type?: BlockingType, name?: string, initialValue?: T, lastModified: number = null) {
        super(type, name, initialValue, lastModified);
    }
    public registerEdit(newValue: T, editor: IUser<any>): void {
        if (!this.isEqual(this.value, newValue)) {
            const edit: Edit<T> = new Edit<T>({
                date: Date.now(),
                editorId: editor._id,
                editor: editor.roleProperties.firstName + ' ' + editor.roleProperties.lastName,
                fieldName: this.name,
                previousValue: this.value,
                currentValue: newValue,
            });
            this.edits.push(edit);
            this.value = newValue;
            this.lastModified = Date.now();
        }
    }
    private isEqual(x: any, y: any): boolean {
        const type1 = typeof x,
            type2 = typeof y;
        if (type1 !== type2) {
            return false;
        } else if (x === null || y === null) {
            return x === y;
        } else if (type1 === 'object') {
            return this.compareObjects(x, y);
        } else {
            return x === y;
        }
    }
    private compareObjects(obj1: object, obj2: object): boolean {
        // Loop through properties in object 1
        for (const p in obj1) {
            if (obj2) {
                // Check property exists on both objects
                if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                    return false;
                }

                switch (typeof obj1[p]) {
                    // Deep compare objects
                    case 'object':
                        if (!this.compareObjects(obj1[p], obj2[p])) {
                            return false;
                        }
                        break;
                    // Compare function code
                    case 'function':
                        if (typeof obj2[p] == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) {
                            return false;
                        }
                        break;
                    // Compare values
                    default:
                        if (obj1[p] != obj2[p]) {
                            return false;
                        }
                }
            }
        }

        // Check object 2 for any extra properties
        for (const p in obj2) {
            if (obj1 && typeof obj1[p] == 'undefined') {
                return false;
            }
        }
        return true;
    }
}
