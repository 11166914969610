import { PostSegmentTopconTritonTest } from '../../../../../../../../commonout/classes/tests/TOPCON/TRITON/postSegmentTopconTritonTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PostSegmentTopconTestRemarksComponent } from '../../../../_components';
import { PostSegmentTopconTritonTestRemarksFrontend } from '../../../test-remarks/TOPCON/TRITON/postSegmentTopconTritonTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PostSegmentTopconTritonTestFrontend extends PostSegmentTopconTritonTest {
    remarks: PostSegmentTopconTritonTestRemarksFrontend;
    public readonly remarksComponent: typeof PostSegmentTopconTestRemarksComponent = PostSegmentTopconTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PostSegmentTopconTritonTestRemarksFrontend();
    }
}

export interface PostSegmentTopconTritonTestFrontend extends TestFrontend {}
applyMixins(PostSegmentTopconTritonTestFrontend, [TestFrontend]);
