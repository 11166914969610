import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { ILensometryMeasuredData } from '../../../../interfaces/measuredData/NIDEK/LM7P/lensometry-nidek-lm7p.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class LensometryMeasuredData implements MeasuredData, ILensometryMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: {
        Sphere: RegisterEditField<string>;
        Cylinder: RegisterEditField<string>;
        Axis: RegisterEditField<string>;
        SE: RegisterEditField<string>;
        ADD: RegisterEditField<string>;
        ADD2: RegisterEditField<string>;
        NearSphere: RegisterEditField<string>;
        NearSphere2: RegisterEditField<string>;
        Prism: RegisterEditField<string>;
        PrismBase: RegisterEditField<string>;
        PrismX: RegisterEditField<string>;
        PrismY: RegisterEditField<string>;
        UVTransmittance: RegisterEditField<string>;
        ConfidenceIndex: RegisterEditField<string>;
        lensType: RegisterEditField<string[]>;
        glassStatus: RegisterEditField<string>;
        remarks: RegisterEditField<string>;
    };
    public OS: {
        Sphere: RegisterEditField<string>;
        Cylinder: RegisterEditField<string>;
        Axis: RegisterEditField<string>;
        SE: RegisterEditField<string>;
        ADD: RegisterEditField<string>;
        ADD2: RegisterEditField<string>;
        NearSphere: RegisterEditField<string>;
        NearSphere2: RegisterEditField<string>;
        Prism: RegisterEditField<string>;
        PrismBase: RegisterEditField<string>;
        PrismX: RegisterEditField<string>;
        PrismY: RegisterEditField<string>;
        UVTransmittance: RegisterEditField<string>;
        ConfidenceIndex: RegisterEditField<string>;
        lensType: RegisterEditField<string[]>;
        glassStatus: RegisterEditField<string>;
        remarks: RegisterEditField<string>;
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_LM7P;
        this.testType = TEST_TYPE.LENSOMETRY;
    }
    public setModel(model: ILensometryMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.OD.Sphere.setModel(model.OD.Sphere);
        this.OD.Cylinder.setModel(model.OD.Cylinder);
        this.OD.Axis.setModel(model.OD.Axis);
        this.OD.SE.setModel(model.OD.SE);
        this.OD.ADD.setModel(model.OD.ADD);
        this.OD.ADD2.setModel(model.OD.ADD2);
        this.OD.NearSphere.setModel(model.OD.NearSphere);
        this.OD.NearSphere2.setModel(model.OD.NearSphere2);
        this.OD.Prism.setModel(model.OD.Prism);
        this.OD.PrismBase.setModel(model.OD.PrismBase);
        this.OD.PrismX.setModel(model.OD.PrismX);
        this.OD.PrismY.setModel(model.OD.PrismY);
        this.OD.UVTransmittance.setModel(model.OD.UVTransmittance);
        this.OD.ConfidenceIndex.setModel(model.OD.ConfidenceIndex);
        this.OD.lensType.setModel(model.OD.lensType);
        this.OD.glassStatus.setModel(model.OD.glassStatus);
        this.OD.remarks.setModel(model.OD.remarks);
        this.OS.Sphere.setModel(model.OS.Sphere);
        this.OS.Cylinder.setModel(model.OS.Cylinder);
        this.OS.Axis.setModel(model.OS.Axis);
        this.OS.SE.setModel(model.OS.SE);
        this.OS.ADD.setModel(model.OS.ADD);
        this.OS.ADD2.setModel(model.OS.ADD2);
        this.OS.NearSphere.setModel(model.OS.NearSphere);
        this.OS.NearSphere2.setModel(model.OS.NearSphere2);
        this.OS.Prism.setModel(model.OS.Prism);
        this.OS.PrismBase.setModel(model.OS.PrismBase);
        this.OS.PrismX.setModel(model.OS.PrismX);
        this.OS.PrismY.setModel(model.OS.PrismY);
        this.OS.UVTransmittance.setModel(model.OS.UVTransmittance);
        this.OS.ConfidenceIndex.setModel(model.OS.ConfidenceIndex);
        this.OS.lensType.setModel(model.OS.lensType);
        this.OS.glassStatus.setModel(model.OS.glassStatus);
        this.OS.remarks.setModel(model.OS.remarks);
    }
    public getModel(): ILensometryMeasuredData {
        let model: ILensometryMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: {
                Sphere: this.OD.Sphere.getModel(),
                Cylinder: this.OD.Cylinder.getModel(),
                Axis: this.OD.Axis.getModel(),
                SE: this.OD.SE.getModel(),
                ADD: this.OD.ADD.getModel(),
                ADD2: this.OD.ADD2.getModel(),
                NearSphere: this.OD.NearSphere.getModel(),
                NearSphere2: this.OD.NearSphere2.getModel(),
                Prism: this.OD.Prism.getModel(),
                PrismBase: this.OD.PrismBase.getModel(),
                PrismX: this.OD.PrismX.getModel(),
                PrismY: this.OD.PrismY.getModel(),
                UVTransmittance: this.OD.UVTransmittance.getModel(),
                ConfidenceIndex: this.OD.ConfidenceIndex.getModel(),
                lensType: this.OD.lensType.getModel(),
                glassStatus: this.OD.glassStatus.getModel(),
                remarks: this.OD.remarks.getModel(),
            },
            OS: {
                Sphere: this.OS.Sphere.getModel(),
                Cylinder: this.OS.Cylinder.getModel(),
                Axis: this.OS.Axis.getModel(),
                SE: this.OS.SE.getModel(),
                ADD: this.OS.ADD.getModel(),
                ADD2: this.OS.ADD2.getModel(),
                NearSphere: this.OS.NearSphere.getModel(),
                NearSphere2: this.OS.NearSphere2.getModel(),
                Prism: this.OS.Prism.getModel(),
                PrismBase: this.OS.PrismBase.getModel(),
                PrismX: this.OS.PrismX.getModel(),
                PrismY: this.OS.PrismY.getModel(),
                UVTransmittance: this.OS.UVTransmittance.getModel(),
                ConfidenceIndex: this.OS.ConfidenceIndex.getModel(),
                lensType: this.OS.lensType.getModel(),
                glassStatus: this.OS.glassStatus.getModel(),
                remarks: this.OS.remarks.getModel(),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.OD.ADD.isEmpty() &&
            this.OD.ADD2.isEmpty() &&
            this.OD.Axis.isEmpty() &&
            this.OD.ConfidenceIndex.isEmpty() &&
            this.OD.Cylinder.isEmpty() &&
            this.OD.NearSphere.isEmpty() &&
            this.OD.NearSphere2.isEmpty() &&
            this.OD.Prism.isEmpty() &&
            this.OD.PrismBase.isEmpty() &&
            this.OD.PrismX.isEmpty() &&
            this.OD.PrismY.isEmpty() &&
            this.OD.SE.isEmpty() &&
            this.OD.Sphere.isEmpty() &&
            this.OD.UVTransmittance.isEmpty() &&
            this.OD.glassStatus.isEmpty() &&
            this.OD.lensType.isEmpty() &&
            this.OD.remarks.isEmpty() &&
            this.OS.ADD.isEmpty() &&
            this.OS.ADD2.isEmpty() &&
            this.OS.Axis.isEmpty() &&
            this.OS.ConfidenceIndex.isEmpty() &&
            this.OS.Cylinder.isEmpty() &&
            this.OS.NearSphere.isEmpty() &&
            this.OS.NearSphere2.isEmpty() &&
            this.OS.Prism.isEmpty() &&
            this.OS.PrismBase.isEmpty() &&
            this.OS.PrismX.isEmpty() &&
            this.OS.PrismY.isEmpty() &&
            this.OS.SE.isEmpty() &&
            this.OS.Sphere.isEmpty() &&
            this.OS.UVTransmittance.isEmpty() &&
            this.OS.glassStatus.isEmpty() &&
            this.OS.lensType.isEmpty() &&
            this.OS.remarks.isEmpty()
        );
    }
}
