import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ROLE } from '../../../../../../../commonout/enum/role';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
    selector: 'invite-user',
    template: require('./invite-user.component.html'),
    styles: [require('./invite-user.component.scss')],
})
export class InviteUserComponent implements OnInit {
    private readonly ROLE: typeof ROLE = ROLE;
    private form: FormGroup;
    constructor(private router: Router, private authService: AuthenticationService, private formBuilder: FormBuilder, private location: Location) {}
    ngOnInit(): void {
        this.form = this.formBuilder.group({
            role: ['', Validators.required],
            email: [
                null,
                [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)],
            ],
        });
    }
    private async registerUser() {
        try {
            const rawFormValue = this.form.getRawValue();
            await this.authService.inviteUser(rawFormValue).toPromise();
            this.router.navigate(['/']);
        } catch (error) {
            console.log(error);
        }
    }
    private clear() {
        this.form.reset();
    }
    private goBack(): void {
        this.location.back();
    }
}
