import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConvergenceBulbicamTestFrontend } from '../../../../../_models/tests/BulbiCAM/convergenceBulbicamTestFrontend.class';
import { ExaminationService } from '../../../../../_services/examination/examination.service';
import { TestService } from '../../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { FileService } from '../../../../../_services/general/file.service';
import { ModalService } from '../../../../../_services/general/modal.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { TestRemarksComponent } from '../../testRemarksComponent';

@Component({
    selector: 'convergence-test-remarks',
    template: require('./convergence-test-remarks.component.html'),
    styles: [require('../../common-remarks.scss'), require('./convergence-test-remarks.component.scss')],
})
export class ConvergenceBulbicamTestRemarksComponent extends TestRemarksComponent {
    test: ConvergenceBulbicamTestFrontend;
    constructor(
        authenticationService: AuthenticationService,
        examinationService: ExaminationService,
        modalService: ModalService,
        fileService: FileService,
        socketService: SocketService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        super(authenticationService, fileService, examinationService, modalService, socketService);
        this.remarksForm = this.formBuilder.group({
            nearPointOfConvergence: '',
            recovery: '',
            diagnosis: '',
            advice: '',
            laboratoryRequested: '',
            medicinesPrescribed: '',
        });
    }
    public async customOnDestroy(): Promise<void> {}
    public async customOnInit(): Promise<void> {
        this.remarksForm.setValue(
            {
                nearPointOfConvergence: this.test.remarks.measurements[0].nearPointOfConvergence.value,
                recovery: this.test.remarks.measurements[0].recovery.value,
                diagnosis: this.test.remarks.diagnosis.value,
                advice: this.test.remarks.advice.value,
                laboratoryRequested: this.test.remarks.laboratoryRequested.value,
                medicinesPrescribed: this.test.remarks.medicinesPrescribed.value,
            },
            {
                emitEvent: false,
            }
        );
    }
    public convertFormToModel(): void {
        const formGroupValue = this.remarksForm.value;
        this.test.remarks.measurements[0].nearPointOfConvergence.registerEdit(formGroupValue.nearPointOfConvergence, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.measurements[0].recovery.registerEdit(formGroupValue.recovery, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.diagnosis.registerEdit(formGroupValue.diagnosis, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.advice.registerEdit(formGroupValue.advice, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.laboratoryRequested.registerEdit(formGroupValue.laboratoryRequested, this.authenticationService.currentUser.getModel(true));
        this.test.remarks.medicinesPrescribed.registerEdit(formGroupValue.medicinesPrescribed, this.authenticationService.currentUser.getModel(true));
    }
}
