import { PupillaryEvaluationTonorefIIITest } from '../../../../../../../../commonout/classes/tests/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIITest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { PupillaryEvaluationTonorefIIITestRemarksComponent } from '../../../../_components';
import { PupillaryEvaluationTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIITestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class PupillaryEvaluationTonorefIIITestFrontend extends PupillaryEvaluationTonorefIIITest {
    remarks: PupillaryEvaluationTonorefIIITestRemarksFrontend;
    public readonly remarksComponent: typeof PupillaryEvaluationTonorefIIITestRemarksComponent = PupillaryEvaluationTonorefIIITestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PupillaryEvaluationTonorefIIITestRemarksFrontend();
    }
}

export interface PupillaryEvaluationTonorefIIITestFrontend extends TestFrontend {}
applyMixins(PupillaryEvaluationTonorefIIITestFrontend, [TestFrontend]);
