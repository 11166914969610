// @ts-nocheck
import { Area } from '../lib/Area';
import { Target } from '../lib/Target';
import { TextBlock } from '../lib/TextBlock';
import { VTable } from '../lib/VTable';
import { Menu } from '../lib/Menu';
import { TextInput } from '../lib/TextInput';
import { doTranslate, translate, TYPE_OPTIONS, ghost } from '../lib/util';
import * as d3 from 'd3';
import * as _ from 'lodash';

type Renderable = (t: Target) => void;

export interface SquareData {
    n: number;
    x: number;
    y: number;
    active: boolean;

    // text: string
    columns: string[];
    icons: Renderable[];
}

interface Props {
    rows: number;
    cols: number;
    data: SquareData[];

    showMenu: boolean;
    onRightClick?: (n: number) => void;
    onChoice?: (x: SquareData) => void;
    onRemove?: () => void;
    onRecordFully?: () => void;
    onToggleMenu?: () => void;
    onMenu?: (i: string) => void;

    area: Area;
    target: Target;
    menuTarget: Target;
}

let defaultProps = {
    canRemove: false,
    showMenuButton: false,
};

type FullProps = Props & Partial<typeof defaultProps>;

export function Squares(given: FullProps) {
    let p = { ...defaultProps, ...given };

    let g = p.target
        .selectAll('g')
        .data([null])
        .join('g')
        .call(doTranslate(p.area))
        .on('contextmenu', () => {
            d3.event.preventDefault();
        });

    g.selectAll('.border')
        .data([null])
        .join('rect')
        .attr('width', p.area.w)
        .attr('height', p.area.h)
        .attr('class', `border sLayer`)
        .attr('fill', 'transparent');

    let w = p.area.w / p.cols;
    let h = p.area.h / p.rows;

    g.selectAll('.piece')
        .data((d) => p.data)
        .join('rect')
        .attr('x', (d) => d.x * w)
        .attr('y', (d) => d.y * h)
        .attr('width', w - 1)
        .attr('height', h - 1)
        .attr('class', (d) => `${makeClass(d)} piece`)
        .on('mousedown', (d) => {
            let isRightButton = d3.event.which === 3;
            if (isRightButton) return;
            p.onChoice && p.onChoice(d);
        });

    let m = 3; // text margin
    let gText = g
        .selectAll('.gText')
        .data((d) => p.data)
        .join('g')
        .attr('class', 'gText')
        .attr('transform', (d) => translate({ x: d.x * w + m, y: d.y * h + m }));
    // .attr('transform', d => translate({ x: d.x * w - w/2, y: d.y * h - h/2 }))

    gText.each((x, index, tt) => {
        let t = d3.select(tt[index] as any);
        let step = w / x.columns.length;
        x.columns.forEach((text, i) => {
            let dx = step * i;

            // special case inside
            let fontSize = 12;
            // if (['OD','OS'].includes(text.trim())) fontSize = 24

            // more special case stuff
            // let onRightClick: any = () => { p.onRightClick && p.onRightClick(i) }
            // let isGhost = false
            // if (i < 1) isGhost = true
            // if (!x.active) isGhost = true
            let isGhost = true;

            TextBlock({
                text: text,
                alignLeft: true,
                alignTop: true,
                i: i,

                // onRightClick: isGhost ? undefined : onRightClick,
                ghost: isGhost,

                target: t,
                area: new Area(dx, 0, dx, 0, ''),

                styleParams: {
                    override: {
                        fontSize,
                        fontWeight: 700,
                    },
                },
            });
        });
    });

    let selected = p.data.find((x) => x.active) as any;

    // let rx = 5
    let rr = 9;
    let pad = 3;

    let close = g
        .selectAll('.remove')
        .data(selected && p.canRemove ? [null] : [])
        .join('g')
        .attr('class', 'remove')
        .attr('transform', () => translate({ x: selected.x * w + w - rr - pad, y: selected.y * h + rr + pad - 1 }));

    // .attr('y', d => d.y * h)
    // .attr('width', w-1)
    // .attr('height', h-1)

    close
        .selectAll('.circle')
        .data([null])
        .join('rect')
        // .attr('rx', rx)
        .attr('x', -rr)
        .attr('y', -rr)
        .attr('width', rr * 2)
        .attr('height', rr * 2)
        // .join('circle')
        // .attr('r', 15)
        .attr('class', 'fLayer sLayer')
        .style('cursor', 'pointer')
        .on('mousedown', p.onRemove || (() => {}));

    let icon = 'cross';
    let iconPath = `/frontend/src/assets/images/diagram-icons/${icon}.svg`;

    close
        .selectAll('image')
        .data([null])
        .join('image')
        .attr('href', iconPath)
        .attr('x', -7)
        .attr('y', -7)
        // .style('font-size', 24)
        // .style('dominant-baseline', 'middle')
        // .style('text-anchor', 'middle')
        .call(ghost);

    // close
    //   .selectAll('text')
    //   .data([null])
    //   .join('text')
    //   .text('x')
    //   .style('font-size', 24)
    //   .style('dominant-baseline', 'middle')
    //   .style('text-anchor', 'middle')
    //   .call(ghost)

    // let save = g.selectAll('.save')

    if (selected) {
        let x = selected.x * w + w;
        let y = selected.y * h + h;

        p.menuTarget.call(doTranslate(p.area));

        saveButton(
            g,
            x,
            y,
            w,
            h,
            () => {
                p.onRecordFully && p.onRecordFully();
            },
            {
                show: true,
                toggle: () => {
                    p.onToggleMenu && p.onToggleMenu();
                },
            },
            p
        );
    }
}

interface More {
    show: boolean;
    toggle: () => void;
}

function saveButton(target: Target, x: number, y: number, w: number, h: number, onClick: () => void, more: More, p: FullProps) {
    // not at all width,height
    // let r = 10
    // let w = 40
    // let h = 20
    y += 6;
    let buttonH = 30;
    // let area = Area.xywh(x + w/2, y - h/2, w, h)
    let area = Area.xywh(x - w, y - buttonH, w, buttonH);

    let columns: any = [
        {
            at: 1,
            rows: [
                {
                    text: 'save',
                    bg: {},
                    onClick,
                },
            ],
        },
    ];
    if (p.showMenuButton) {
        columns.push({
            at: 2 - 0.2,
            rows: [
                {
                    text: '>',
                    bg: {
                        wr: 13,
                    },
                    onClick: more.toggle,
                },
            ],
        });
    }
    let table = {
        rowHeaders: [''],
        columns,
    };

    VTable({
        domainX: [0, 2],
        ...table,
        showRects: true,
        // r,
        rx: w / 2,
        ry: buttonH / 2,

        target,
        area,
    });

    let menuH = 80;
    let menuW = 75;
    area = Area.xywh(x, y - menuH, menuW, menuH).slide(7, 0);

    Menu({
        items: TYPE_OPTIONS,
        show: p.showMenu,
        onChoice: (s) => {
            p.onMenu && p.onMenu(s);
        },

        target: p.menuTarget,
        area,
    });

    area = area.takeBottom(30);

    let textTarget = p.menuTarget
        .selectAll('.text-field')
        .data(p.showMenu ? [null] : [])
        .join('g');

    TextInput({
        value: '',
        onChange: (s) => {
            p.onMenu && p.onMenu(s);
        },

        area,
        target: textTarget,
    });
}

function makeClass(d: SquareData) {
    if (d.active) {
        // return 'fContrast sContrast'
        // return 'fLayer sContrast'
        return 'fLayer sLayerFrame';
    } else {
        // return 'fLayer sContrast'
        return 'fLayer sLayer';
    }
}
