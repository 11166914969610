import { DEVICE } from '../../../../enum/device';
import { TEST_TYPE } from '../../../../enum/test-type';
import { TEST_STAGE } from '../../../../enum/testStage.enum';

export class AccommodationNidekArk1sTest {
    public readonly type: TEST_TYPE = TEST_TYPE.ACCOMMODATION;
    public readonly device: DEVICE = DEVICE.NIDEK_ARK1S;
    public readonly name: string = 'Accommodation test';
    public stage: TEST_STAGE;
}
