import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TagsService } from '../../../../../_services/general/tags.service';

@Component({
    selector: 'add-tag',
    template: require('./add-tag.component.html'),
    styles: [require('./add-tag.component.scss')],
})
export class AddTagComponent implements OnInit, OnDestroy {
    @Output() tag = new EventEmitter<string>();
    public tags: string[] = [];
    public input: FormControl = new FormControl();
    @ViewChild('inputRef') inputRef: ElementRef;
    private sunscriptions: Subscription[] = [];
    constructor(private tagsService: TagsService) {}
    ngOnInit(): void {
        this.input.valueChanges.subscribe(async v => {
            if (!v) {
                this.tags = [];
            } else {
                const tags: string[] = await this.tagsService.getTags(v).toPromise();
                this.tags = tags;
            }
        });
    }
    ngOnDestroy(): void {
        this.sunscriptions.map(s => s.unsubscribe());
    }
    onBlur(state: { toggleStatus: boolean }) {
        setTimeout(() => {
            state.toggleStatus = false;
        }, 500);
    }
    public async addNewTag(state: { toggleStatus: boolean }): Promise<void> {
        try {
            const tags: string[] = await this.tagsService.setTag(this.input.value).toPromise();
            this.tags = tags;
            setTimeout(() => {
                state.toggleStatus = true;
            }, 600);
        } catch (error) {
            console.log(error);
        }
    }
    public async addTag(tag: string): Promise<void> {
        try {
            this.input.setValue('');
            this.tag.emit(tag);
        } catch (error) {
            console.log(error);
        }
    }
}
