import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { GENDER } from '../../../../../../../commonout/enum/gender';
import { PatientService } from '../../../_services/patient/patient.service';
import { DatepickerComponent } from '../../general/custom-inputs/datepicker/datepicker.component';
import { SingleselectComponent } from '../../general/custom-inputs/singleselect/singleselect.component';

@Component({
    selector: 'patients-statistics',
    template: require('./patients-statistics.component.html'),
    styles: [require('./patients-statistics.component.scss')],
})
export class PatientsStatisticsComponent implements OnInit {
    private filterForm: FormGroup;
    private readonly maxDate: Date = new Date();
    private maxDateForStartDatePicker: Date = new Date();
    private minDateForEndDatePicker: Date;
    private open: boolean = true; // to aviod bug when changing max date value of min datepicker fires value change event
    private creationTimeCnt: number = null;
    private genderCnt: number = null;
    private ageCnt: number = null;
    private readonly timeRanges: string[] = ['Today', 'This week', 'Last week', 'This month', 'Last month', 'This year', 'Last year', 'Custom'];
    private readonly gender: GENDER[] = [GENDER.FEMALE, GENDER.MALE, GENDER.OTHER];
    private readonly ageRange: string[] = (() => {
        let arr: string[] = [];
        for (let index = 0; index < 121; index++) {
            arr.push(index.toString());
        }
        return arr;
    })();
    @ViewChild(SingleselectComponent) private rangeComponent: SingleselectComponent;
    @ViewChild('startPicker') private startPicker: DatepickerComponent;
    @ViewChild('endPicker') private endPicker: DatepickerComponent;
    constructor(private formBuilder: FormBuilder, private patientService: PatientService) {
        this.filterForm = this.formBuilder.group({
            range: null,
            startDate: null,
            endDate: null,
            gender: null,
            startAge: null,
            endAge: null,
        });
    }

    ngOnInit() {
        // date range sector
        this.filterForm
            .get('range')
            .valueChanges.pipe(
                map((range: string) => {
                    let start: number, end: number;
                    switch (range) {
                        case 'Today':
                            start = moment()
                                .startOf('day')
                                .valueOf();
                            end = moment().valueOf();
                            break;
                        case 'This week':
                            start = moment()
                                .startOf('isoWeek')
                                .valueOf();
                            end = moment().valueOf();
                            break;
                        case 'Last week':
                            start = moment()
                                .subtract(1, 'week')
                                .startOf('isoWeek')
                                .valueOf();
                            end = moment()
                                .subtract(1, 'week')
                                .endOf('isoWeek')
                                .valueOf();
                            break;
                        case 'This month':
                            start = moment()
                                .startOf('month')
                                .valueOf();
                            end = moment().valueOf();
                            break;
                        case 'Last month':
                            start = moment()
                                .subtract(1, 'month')
                                .startOf('month')
                                .valueOf();
                            end = moment()
                                .subtract(1, 'month')
                                .endOf('month')
                                .valueOf();
                            break;
                        case 'This year':
                            start = moment()
                                .startOf('year')
                                .valueOf();
                            end = moment().valueOf();
                            break;
                        case 'Last year':
                            start = moment()
                                .subtract(1, 'year')
                                .startOf('year')
                                .valueOf();
                            end = moment()
                                .subtract(1, 'year')
                                .endOf('year')
                                .valueOf();
                            break;
                        default:
                            this.startPicker.open();
                            break;
                    }
                    return { start, end };
                })
            )
            .subscribe((range) => {
                this.open = false;
                this.filterForm.get('startDate').setValue(moment(range.start).toDate());
                this.filterForm.get('endDate').setValue(moment(range.end).toDate());
            });
        this.filterForm.get('startDate').valueChanges.subscribe((startRange) => {
            this.minDateForEndDatePicker = startRange;
            if (this.open) this.endPicker.open();
        });
        this.filterForm.get('endDate').valueChanges.subscribe(async (endRange) => {
            this.open = false;
            this.maxDateForStartDatePicker = endRange;
            setTimeout(() => {
                this.open = true;
            }, 0);
            this.rangeComponent.writeValue(this.defineRange());
            this.creationTimeCnt = (
                await this.patientService
                    .getPatientsByQuery({
                        startDate: moment(this.filterForm.get('startDate').value).valueOf(),
                        endDate: moment(endRange)
                            .endOf('day')
                            .valueOf(),
                        gender: null,
                        startAge: null,
                        endAge: null,
                    })
                    .toPromise()
            ).cnt;
            this.filterForm.get('gender').updateValueAndValidity();
            this.filterForm.get('startAge').updateValueAndValidity();
            this.filterForm.get('endAge').updateValueAndValidity();
        });
        // gender sector
        this.filterForm.get('gender').valueChanges.subscribe(async (gender: GENDER) => {
            this.genderCnt = (
                await this.patientService
                    .getPatientsByQuery({
                        startDate: moment(this.filterForm.get('startDate').value).valueOf(),
                        endDate: moment(this.filterForm.get('endDate').value)
                            .endOf('day')
                            .valueOf(),
                        gender: gender,
                        startAge: null,
                        endAge: null,
                    })
                    .toPromise()
            ).cnt;
            this.filterForm.get('startAge').updateValueAndValidity();
            this.filterForm.get('endAge').updateValueAndValidity();
        });
        // age sector
        this.filterForm.get('startAge').valueChanges.subscribe(async (startAge: string) => {
            this.ageCnt = (
                await this.patientService
                    .getPatientsByQuery({
                        startDate: moment(this.filterForm.get('startDate').value).valueOf(),
                        endDate: moment(this.filterForm.get('endDate').value)
                            .endOf('day')
                            .valueOf(),
                        gender: this.filterForm.get('gender').value,
                        startAge: +startAge,
                        endAge: +this.filterForm.get('endAge').value,
                    })
                    .toPromise()
            ).cnt;
        });
        this.filterForm.get('endAge').valueChanges.subscribe(async (endAge: string) => {
            this.ageCnt = (
                await this.patientService
                    .getPatientsByQuery({
                        startDate: moment(this.filterForm.get('startDate').value).valueOf(),
                        endDate: moment(this.filterForm.get('endDate').value)
                            .endOf('day')
                            .valueOf(),
                        gender: this.filterForm.get('gender').value,
                        startAge: +this.filterForm.get('startAge').value,
                        endAge: +endAge,
                    })
                    .toPromise()
            ).cnt;
        });
    }

    private defineRange(): string {
        const startRange: number = moment(this.filterForm.get('startDate').value).valueOf(),
            endRange: number = moment(this.filterForm.get('endDate').value)
                .endOf('day')
                .valueOf();
        if (
            startRange ===
            moment()
                .startOf('day')
                .valueOf()
        ) {
            return 'Today';
        } else if (
            startRange ===
                moment()
                    .startOf('isoWeek')
                    .valueOf() &&
            endRange >
                moment()
                    .startOf('day')
                    .valueOf()
        ) {
            return 'This week';
        } else if (
            startRange ===
                moment()
                    .subtract(1, 'week')
                    .startOf('isoWeek')
                    .valueOf() &&
            endRange ===
                moment()
                    .subtract(1, 'week')
                    .endOf('isoWeek')
                    .valueOf()
        ) {
            return 'Last week';
        } else if (
            startRange ===
                moment()
                    .startOf('month')
                    .valueOf() &&
            endRange >
                moment()
                    .startOf('day')
                    .valueOf()
        ) {
            return 'This month';
        } else if (
            startRange ===
                moment()
                    .subtract(1, 'month')
                    .startOf('month')
                    .valueOf() &&
            endRange ===
                moment()
                    .subtract(1, 'month')
                    .endOf('month')
                    .valueOf()
        ) {
            return 'Last month';
        } else if (
            startRange ===
                moment()
                    .startOf('year')
                    .valueOf() &&
            endRange >
                moment()
                    .startOf('day')
                    .valueOf()
        ) {
            return 'This year';
        } else if (
            startRange ===
                moment()
                    .subtract(1, 'year')
                    .startOf('year')
                    .valueOf() &&
            endRange ===
                moment()
                    .subtract(1, 'year')
                    .endOf('year')
                    .valueOf()
        ) {
            return 'Last year';
        } else {
            console.log(
                'Start control - ',
                this.filterForm.get('startDate').value,
                ', start - ',
                moment()
                    .subtract(1, 'week')
                    .startOf('isoWeek')
            );
            console.log(
                'End control - ',
                this.filterForm.get('endDate').value,
                ', end - ',
                moment()
                    .subtract(1, 'week')
                    .endOf('isoWeek')
            );

            return 'Custom';
        }
    }
}
