import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { IpdGlareContrastAcuityArk1sMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/ARK1S/ipdGlareContrastAcuityArk1sData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { IpdGlareContrastAcuityArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/ipd-glare-contrast-acuityArk1sTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class IpdGlareContrastAcuityArk1sMeasuredDataFrontend extends IpdGlareContrastAcuityArk1sMeasuredData implements MeasuredDataFrontend {
    public remarks: IpdGlareContrastAcuityArk1sTestRemarksFrontend;
    public IPD: {
        far: RegisterEditFieldFrontend<string>;
        near: RegisterEditFieldFrontend<string>;
    };
    public CGA: {
        OD: {
            bcva: RegisterEditFieldFrontend<string>;
            contrast: RegisterEditFieldFrontend<string>;
            glare: RegisterEditFieldFrontend<string>;
        };
        OS: {
            bcva: RegisterEditFieldFrontend<string>;
            contrast: RegisterEditFieldFrontend<string>;
            glare: RegisterEditFieldFrontend<string>;
        };
    };
    public OI: {
        OD: {
            COIA: RegisterEditFieldFrontend<string>;
            POI: RegisterEditFieldFrontend<string>;
            RetroImage: SmartImageFrontend;
        };
        OS: {
            COIA: RegisterEditFieldFrontend<string>;
            POI: RegisterEditFieldFrontend<string>;
            RetroImage: SmartImageFrontend;
        };
    };
    constructor() {
        super();
        this.IPD = {
            far: new RegisterEditFieldFrontend(),
            near: new RegisterEditFieldFrontend(),
        };
        this.CGA = {
            OD: {
                bcva: new RegisterEditFieldFrontend(),
                contrast: new RegisterEditFieldFrontend(),
                glare: new RegisterEditFieldFrontend(),
            },
            OS: {
                bcva: new RegisterEditFieldFrontend(),
                contrast: new RegisterEditFieldFrontend(),
                glare: new RegisterEditFieldFrontend(),
            },
        };
        this.OI = {
            OD: {
                COIA: new RegisterEditFieldFrontend(),
                POI: new RegisterEditFieldFrontend(),
                RetroImage: new SmartImageFrontend(),
            },
            OS: {
                COIA: new RegisterEditFieldFrontend(),
                POI: new RegisterEditFieldFrontend(),
                RetroImage: new SmartImageFrontend(),
            },
        };
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        let table = [];
        table.push({
            column1: { colSpan: 2, content: 'Inter Pupillary Distance (mm)', styles: { halign: 'center' } },
            column2: '',
            column3: { colSpan: 4, content: 'Contrast and glare acuity (Decimal)', styles: { halign: 'center' } },
            column4: '',
            column5: '',
            column6: '',
        });
        table.push({
            column1: 'Far Pupillary Distance',
            column2: this.IPD.far.value,
            column3: '',
            column4: 'BCVA Distance',
            column5: 'Contrast Acuity',
            column6: 'Glare Acuity',
        });
        table.push({
            column1: 'Near Pupillary Distance',
            column2: this.IPD.near.value,
            column3: 'OD',
            column4: this.CGA.OD.bcva.value,
            column5: this.CGA.OD.contrast.value,
            column6: this.CGA.OD.glare.value,
        });
        table.push({
            column1: { colSpan: 2, content: '' },
            column2: '',
            column3: 'OS',
            column4: this.CGA.OS.bcva.value,
            column5: this.CGA.OS.contrast.value,
            column6: this.CGA.OS.glare.value,
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;

        table = [];
        table.push({
            key: { colSpan: 4, content: 'Opacity Index (%)', styles: { halign: 'center' } },
            value: '',
            key1: '',
            value1: '',
        });
        table.push({
            key: { colSpan: 2, content: 'OD', styles: { halign: 'center' } },
            value: '',
            key1: { colSpan: 2, content: 'OS', styles: { halign: 'center' } },
            value1: '',
        });
        table.push({
            key: 'Central Opacity Index',
            value: this.OI.OD.COIA.value,
            key1: 'Central Opacity Index',
            value1: this.OI.OS.COIA.value,
        });
        table.push({
            key: 'Peripheral Opacity Index',
            value: this.OI.OD.POI.value,
            key1: 'Peripheral Opacity Index',
            value1: this.OI.OS.POI.value,
        });

        await this.downloadMediaData(fileService);

        if (!this.OI.OD.RetroImage.isEmpty() || !this.OI.OS.RetroImage.isEmpty()) {
            let ratio: number = (await this.OI.OD.RetroImage.getRatio()) || (await this.OI.OS.RetroImage.getRatio());
            table.push({
                key: {
                    colSpan: 2,
                    content: '',
                    styles: {
                        minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                        minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                    },
                },
                value: {
                    content: '',
                    styles: {
                        minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                        minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                    },
                },
                key1: {
                    colSpan: 2,
                    content: '',
                    styles: {
                        minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                        minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                    },
                },
                value1: {
                    content: '',
                    styles: {
                        minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                        minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                    },
                },
            });
        }
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: <CellHookHandler>(data => {
                if (this.OI.OD.RetroImage.imageUrl && data.section === 'body' && data.row.index === 4 && data.column.dataKey === 'key') {
                    document.addImage(this.OI.OD.RetroImage.imageUrl, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                } else if (this.OI.OS.RetroImage.imageUrl && data.section === 'body' && data.row.index === 4 && data.column.dataKey === 'key1') {
                    document.addImage(this.OI.OS.RetroImage.imageUrl, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                }
            }),
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values =
            (this.CGA.OD.bcva.value || 'x') +
            '/' +
            (this.CGA.OD.contrast.value || 'x') +
            '/' +
            (this.CGA.OD.glare.value || 'x') +
            '/' +
            (this.OI.OD.COIA.value || 'x') +
            '/' +
            (this.OI.OD.POI.value || 'x');
        formattedTest.OS.values =
            (this.CGA.OS.bcva.value || 'x') +
            '/' +
            (this.CGA.OS.contrast.value || 'x') +
            '/' +
            (this.CGA.OS.glare.value || 'x') +
            '/' +
            (this.OI.OS.COIA.value || 'x') +
            '/' +
            (this.OI.OS.POI.value || 'x');
        formattedTest.OU.values = (this.IPD.far.value || 'x') + '/' + (this.IPD.near.value || 'x');
        if (!this.OI.OD.RetroImage.isEmpty()) {
            formattedTest.OD.images.push(this.OI.OD.RetroImage.imageUrl);
        }
        if (!this.OI.OS.RetroImage.isEmpty()) {
            formattedTest.OS.images.push(this.OI.OS.RetroImage.imageUrl);
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        try {
            await Promise.all([this.OI.OD.RetroImage.download(fileService), this.OI.OS.RetroImage.download(fileService)]);
        } catch (error) {
            console.error(error);
        }
    }
}
