import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { OcularRefractionTonorefIIIMeasuredDataFrontend } from '../../../measuredData/NIDEK/TonorefIII/ocularRefractionTonorefIIIMeasuredDataFrontend.class';

export class OcularRefractionTonorefIIITestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof OcularRefractionTonorefIIIMeasuredDataFrontend = OcularRefractionTonorefIIIMeasuredDataFrontend;
    public measurements: OcularRefractionTonorefIIIMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
