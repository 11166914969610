// @ts-nocheck
import { State } from './State'
import { USE_FAKE_DATA } from '../constants'

// Assumptions:
// -

/*
 * Example message sequence:
 *
 * - test start
 *
 *   - run start
 *
 *     - data point
 *     - data point
 *     - ...
 *
 *   - run start
 *
 *     - ...
 *
 * - test stop
 */


// Used payload:

interface Common {
  message_type: number
  timestamp: number
}

export interface TestStart {
  minz: number
}

export interface RunStart extends Common {
  stimuli: number
  // -1 - default stimulus, 0..10 - animal images
}

export interface DataPoint extends Common {
  // actual payload:
  ppdistance?: number // it was missing in the old test sometimes I beleive
  z?: number // temp stuff

  ODdiameter: number
  OSdiameter: number

  //OD/OSeyex: number
  //OD/OSeyey: number
  //OD/OStargetx: number

  // I calculate these from whatever I receive
  osPoint?: DataPoint
  // defined: boolean
  stimuli_dist: number
  eyes_dist: number  // actually: convergence angle

  od_pd: number
  os_pd: number

  break?: boolean
  reverse?: boolean
  fake_dist: number // always growing but based on :stimuli_dist
  time: number // first step, only cause time != timestamp normally
}


// Common structure test and message types

const isCommon = (x: any): boolean =>
  (typeof x === 'object' && x !== null) &&
    typeof x.message_type === 'number' &&
    typeof x.timestamp === 'number'

const isTestStart = (x: any): x is TestStart =>
  x.message_type === 0

const isTestStop = (x: any): boolean =>
  x.message_type === 1

const isRunStart = (x: any): x is RunStart =>
  x.message_type === 15
  // x.message_type === 2

const isRunStop = (x: any): boolean =>
  x.message_type === 16
  // x.message_type === 3

const isDataPoint = (x: any): x is DataPoint =>
  x.message_type === 6

const isGoBack = (x: any): boolean =>
  x.message_type === 9


// Everything together

export class Protocol {
  constructor(private state: State) {
  }
  input(given: unknown) {
    if (!USE_FAKE_DATA) this.handleInput(given)
  }
  fakeInput(given: unknown) {
    if (USE_FAKE_DATA) this.handleInput(given)
  }
  handleInput (given: unknown): void {
    if (Array.isArray(given)) return given.forEach(x => this.input(x))

    if (isCommon(given)) {
      // state reset on start?
      if (isTestStart(given)) return this.state.onTestStart(given)

      if (isRunStart(given)) return this.state.onRunStart(given)
      if (isDataPoint(given)) return this.state.onDataPoint(given)
      if (isRunStop(given)) return
      if (isTestStop(given)) return this.state.onTestStop()
      if (isGoBack(given)) return this.state.onGoBack()
    }
    console.log(`unhandled input:`, given)
  }
}
