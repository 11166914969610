// @ts-nocheck
import * as d3 from 'd3';
// import * as _ from 'lodash';
import { Target } from '..';
// import { RawPoint } from './util'
import { Layout } from '../Layout';
// import { Option } from './Option'
// import { doTranslate } from '../lib';

// styling

// type Style = typeof styleDefault;

// interface

interface Params {
    // value: number;
    // step: number;
    // min?: number;
    // // max?: number
    // onChange: (value: number) => void;
    src: string;
    onPosition?: (pos: number, duration: number) => void;
    controls?: boolean,
    rate?: number,

    target: Target;
    area: Layout;
    // style?: Partial<Style>;
}

// function

export function VideoControl(p: Params) {
    p.rate = p.rate || 1
    let outerW = p.area.width();
    let outerH = p.area.height();

    let target = p.target
        .selectAll('foreignObject')
        .data([null])
        .join('foreignObject')
        .attr('x', p.area.left)
        .attr('y', p.area.top)
        .attr('width', `${outerW}px`)
        .attr('height', `${outerH}px`)
        .selectAll('div')
        .data([null])
        .join('xhtml:div')
        .style('width', '100%')
        .style('height', '100%')
        // .style('width', `${outerW - 100}px`)
        // .style('height', `${outerH - 100}px`)

    let input = target
        .selectAll('video')
        .data([null])
        .join('xhtml:video')
        .attr('src', p.src)
        // .attr('width', p.area.w)
        // .attr('height', p.area.h)
        .on('mousedown', (x) => {
          let video = d3.event.currentTarget
          if (video.paused) {
            video.playbackRate = p.rate
            video.play()
          } else {
            video.pause()
          }
        })
        // .attr('type', 'video/mp4')
        // .attr('type', 'number')
        .style('width', '100%')
        .style('height', '100%')
        // .on('seeking', () => {
        //   console.log('seeking')
        // })
        .on('timeupdate', () => {
            let pos = d3.event.target.currentTime;
            const duration = d3.event?.target?.duration;
            p.onPosition && p.onPosition(pos, duration);
        });
    if (p.controls) {
        input.attr('controls', 'controls')
    }
    // .style('margin', '0')
    // .style('padding', '0')
    // .attr('step', p.step)
    // .style('font-size', `${style.fontSize}px`)
    // .style('font-family', style.fontFamily)
    // .style('color', `#123`)
    // .property('value', p.value)
    // .on('mousedown', () => d3.event.stopPropagation())
    // .on('mousemove', () => d3.event.stopPropagation())
    // .on('mouseup', () => d3.event.stopPropagation())
    // .on('change', (x) => {
    //     let value = d3.event.target.valueAsNumber;
    //     p.onChange(value);
    // });
    // video.addEventListener('seeking', (event) => {
    //   console.log('Video is seeking a new position.');
    // });
    let node = input && input.node();
    if (node) (node as any).muted = true;
    // 0.0625 - 16.0

    // if (p.min) {
    //     input.attr('min', p.min);
    // }

    // let style = { ...styleDefault, ...style }
    // let styleParams = { theme: '' }
    // let color = p.color || ''

    // Option({
    //   items: [{}],

    //   isChecked: () => p.checked,
    //   onClick: p.onClick,
    //   coloring: () => color,
    //   naming: () => p.text,

    //   target: p.target,
    //   area: p.area,
    //   styleParams,
    // })

    // let x1 = Math.min(p.from.x, p.to.x)
    // let x2 = Math.max(p.from.x, p.to.x)

    // let y1 = Math.min(p.from.y, p.to.y)
    // let y2 = Math.max(p.from.y, p.to.y)

    // p.target
    //     .selectAll('.line')
    //     .data([null])
    //     .join('line')
    //     .attr('class', 'line ' + style.lineKlass)

    //     .attr('x1', x1)
    //     .attr('x2', x2)
    //     .attr('y1', y1)
    //     .attr('y2', y2)
    //     // .call(doTranslate({ x: x1, y: y1 }))
    //     // .attr('width', x2 - x1)
    //     // .attr('height', y2 - y1)

    //     .attr('fill', style.fillColor)
    //     .attr('stroke-width', style.lineSize)
    //     .attr('stroke', style.lineColor)
}
