// @ts-nocheck
export class Point {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
    xOnly(): Point {
        return new Point(this.x, 0);
    }
    yOnly(): Point {
        return new Point(0, this.y + 20);
    }
    dx(dx: number): Point {
        return new Point(this.x + dx, this.y);
    }
    dy(dy: number): Point {
        return new Point(this.x, this.y + dy);
    }
    withX(x: number): Point {
        return new Point(x, this.y);
    }
    withY(y: number): Point {
        return new Point(this.x, y);
    }
}
