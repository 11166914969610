import { TEST_TYPE } from '../../../../commonout/enum/test-type';
import { IDoctorRoleProperties } from '../../../../commonout/interfaces/roleProperties/doctorRoleProperties.interface';
import { ITechStaffRoleProperties } from '../../../../commonout/interfaces/roleProperties/techStaffRoleProperties.interface';
import { IUser } from '../../../../commonout/interfaces/user';
import { IDC_ITEM_TYPE } from '../../enums/idc-item-type.enum';
import { IIDCListItem } from '../../interfaces/idc-list-item.interface';

export abstract class IdcListItem implements IIDCListItem {
    type: IDC_ITEM_TYPE;
    timestamp: number;
    testType: TEST_TYPE;
    creator: IUser<ITechStaffRoleProperties | IDoctorRoleProperties>;
    deleted: IUser<ITechStaffRoleProperties | IDoctorRoleProperties>;
    constructor() {}
    public abstract get model(): IIDCListItem;
    public abstract set model(model: IIDCListItem);
}
