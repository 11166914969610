import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IPupillaryEvaluationNidekTonorefIIIMeasuredData } from '../../../../interfaces/measuredData/NIDEK/TonorefIII/pupillaryEvaluation-nidek-tonorefIII.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class PupillaryEvaluationTonorefIIIMeasuredData implements MeasuredData, IPupillaryEvaluationNidekTonorefIIIMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: {
        pupilSize: {
            type: RegisterEditField<string>;
            sizeValue: RegisterEditField<string>;
        };
        reactionToLight: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
        rapo: {
            type: RegisterEditField<string>;
        };
        pupilMorphology: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
    };
    public OS: {
        pupilSize: {
            type: RegisterEditField<string>;
            sizeValue: RegisterEditField<string>;
        };
        reactionToLight: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
        rapo: {
            type: RegisterEditField<string>;
        };
        pupilMorphology: {
            type: RegisterEditField<string>;
            select: RegisterEditField<string[]>;
        };
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_TONOREFIII;
        this.testType = TEST_TYPE.PUPILLARY_EVALUATION;
    }
    public abstract setModel(model: IPupillaryEvaluationNidekTonorefIIIMeasuredData): void;
    public getModel(): IPupillaryEvaluationNidekTonorefIIIMeasuredData {
        let model: IPupillaryEvaluationNidekTonorefIIIMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: {
                pupilSize: {
                    type: this.OD.pupilSize.type.getModel(),
                    sizeValue: this.OD.pupilSize.sizeValue.getModel(),
                },
                reactionToLight: {
                    type: this.OD.reactionToLight.type.getModel(),
                    select: this.OD.reactionToLight.select.getModel(),
                },
                rapo: {
                    type: this.OD.rapo.type.getModel(),
                },
                pupilMorphology: {
                    type: this.OD.pupilMorphology.type.getModel(),
                    select: this.OD.pupilMorphology.select.getModel(),
                },
            },
            OS: {
                pupilSize: {
                    type: this.OS.pupilSize.type.getModel(),
                    sizeValue: this.OS.pupilSize.sizeValue.getModel(),
                },
                reactionToLight: {
                    type: this.OS.reactionToLight.type.getModel(),
                    select: this.OS.reactionToLight.select.getModel(),
                },
                rapo: {
                    type: this.OS.rapo.type.getModel(),
                },
                pupilMorphology: {
                    type: this.OS.pupilMorphology.type.getModel(),
                    select: this.OS.pupilMorphology.select.getModel(),
                },
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return (
            this.OD.pupilMorphology.select.isEmpty() &&
            this.OD.pupilMorphology.type.isEmpty() &&
            this.OD.rapo.type.isEmpty() &&
            this.OD.reactionToLight.select.isEmpty() &&
            this.OD.reactionToLight.type.isEmpty() &&
            this.OD.pupilSize.type.isEmpty() &&
            this.OD.pupilSize.sizeValue.isEmpty() &&
            this.OS.pupilMorphology.select.isEmpty() &&
            this.OS.pupilMorphology.type.isEmpty() &&
            this.OS.rapo.type.isEmpty() &&
            this.OS.reactionToLight.select.isEmpty() &&
            this.OS.reactionToLight.type.isEmpty() &&
            this.OS.pupilSize.type.isEmpty() &&
            this.OS.pupilSize.sizeValue.isEmpty()
        );
    }
}
