import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ModalService } from '../../../_services/general/modal.service';
import { UserService } from '../../../_services/user/user.service';

@Component({
    selector: 'update-password',
    template: require('./update-password.component.html'),
    styles: [require('./update-password.component.scss')],
})
export class UpdatePasswordComponent implements OnInit {
    private token: string = null;
    private form: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private userService: UserService
    ) {
        this.form = this.formBuilder.group(
            {
                newPassword: ['', Validators.required],
                confirmedNewPassword: ['', Validators.required],
            },
            { validator: this.areEqual }
        );
    }

    async ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
        const responce: { message: string } = await this.authService.restorePasswordTokenIsValid(this.token).toPromise();
        if (responce?.message) {
            this.modalService.confirm(responce.message).then(() => {
                this.router.navigate(['/auth']);
            });
        }
    }
    private areEqual(group: any): any {
        return group.controls.newPassword.value === group.controls.confirmedNewPassword.value
            ? null
            : {
                  areEqual: true,
              };
    }

    private async updatePassword(): Promise<void> {
        const newPassword: string = this.form.get('newPassword').value,
            publicKey: any = await this.userService.registerKey(this.token + '<SP>' + newPassword).toPromise();
        publicKey.challenge = this.authService.decode(publicKey.challenge);
        publicKey.user.id = this.authService.decode(publicKey.user.id);
        const credential = await navigator.credentials.create({ publicKey });
        const credentialResponce = this.authService.publicKeyCredentialToJSON(credential);
        await this.authService.sendWebAuthResponse(credentialResponce).toPromise();
        await this.modalService.confirm('Password and U2F key binding are updated. Please, connect to administrator to keep number of registered U2F keys updated.');
        this.router.navigate(['/']);
    }
}
