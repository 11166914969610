import { TestRemarkFrontend } from '../testRemarkFrontend';
import { ConvergenceBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/convergenceBulbicamDataFrontend.class';

export class ConvergenceBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof ConvergenceBulbicamMeasuredDataFrontend = ConvergenceBulbicamMeasuredDataFrontend;
    public measurements: ConvergenceBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
