import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { IAntSegmentHaagSlitLampMeasuredData } from '../../../../../../../common/interfaces/measuredData/HAAG/SLITLAMP/ant-segment-haag-slitlamp.measuredData.interface';
import { AntSegmentHaagSlitLampMeasuredData } from '../../../../../../../common/models/measuredData/HAAG/SLITLAMP/antSegmentHaagSlitlampData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { AntSegmentHaagSlitlampTestRemarksFrontend } from '../../../test-remarks/HAAG/SLITLAMP/antSegmentHaagSlitlampTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class AntSegmentHaagSlitLampMeasuredDataFrontend extends AntSegmentHaagSlitLampMeasuredData implements MeasuredDataFrontend {
    public remarks: AntSegmentHaagSlitlampTestRemarksFrontend;
    public eyelidsEyeLashesAndLacrimalSystem: {
        upperEyelids: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        lowerEyelids: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        upperEyelashes: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        lowerEyelashes: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        lacrimalSystem: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        result: {
            OD: RegisterEditFieldFrontend<string>;
            OS: RegisterEditFieldFrontend<string>;
        };
    };
    public conjunctivaLimbusAndSclera: {
        conjunctiva: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        limbus: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        sclera: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        result: {
            OD: RegisterEditFieldFrontend<string>;
            OS: RegisterEditFieldFrontend<string>;
        };
    };
    public corneaAndAnteriorChamber: {
        cornea: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        anteriorChamberDepth: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        vanHerrickGrading: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        acCellsFlare: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        acFindings: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        result: {
            OD: RegisterEditFieldFrontend<string>;
            OS: RegisterEditFieldFrontend<string>;
        };
    };
    public irisAndLens: {
        iris: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        lensStatus: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        cataractGrading: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        otherCataractTypes: {
            OD: RegisterEditFieldFrontend<string[]>;
            OS: RegisterEditFieldFrontend<string[]>;
        };
        result: {
            OD: RegisterEditFieldFrontend<string>;
            OS: RegisterEditFieldFrontend<string>;
        };
    };
    public images: {
        OD: SmartImageFrontend[];
        OS: SmartImageFrontend[];
    };
    constructor() {
        super();
        this.eyelidsEyeLashesAndLacrimalSystem = {
            upperEyelids: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            lowerEyelids: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            upperEyelashes: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            lowerEyelashes: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            lacrimalSystem: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            result: {
                OD: new RegisterEditFieldFrontend<string>(),
                OS: new RegisterEditFieldFrontend<string>(),
            },
        };
        this.conjunctivaLimbusAndSclera = {
            conjunctiva: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            limbus: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            sclera: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            result: {
                OD: new RegisterEditFieldFrontend<string>(),
                OS: new RegisterEditFieldFrontend<string>(),
            },
        };
        this.corneaAndAnteriorChamber = {
            cornea: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            anteriorChamberDepth: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            vanHerrickGrading: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            acCellsFlare: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            acFindings: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            result: {
                OD: new RegisterEditFieldFrontend<string>(),
                OS: new RegisterEditFieldFrontend<string>(),
            },
        };
        this.irisAndLens = {
            iris: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            lensStatus: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            cataractGrading: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            otherCataractTypes: {
                OD: new RegisterEditFieldFrontend<string[]>(),
                OS: new RegisterEditFieldFrontend<string[]>(),
            },
            result: {
                OD: new RegisterEditFieldFrontend<string>(),
                OS: new RegisterEditFieldFrontend<string>(),
            },
        };
        this.images = {
            OD: [],
            OS: [],
        };
    }
    public setModel(model: IAntSegmentHaagSlitLampMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD.setModel(model.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD);
        this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS.setModel(model.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS);
        this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD.setModel(model.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD);
        this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS.setModel(model.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS);
        this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD.setModel(model.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD);
        this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS.setModel(model.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS);
        this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD.setModel(model.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD);
        this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS.setModel(model.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS);
        this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD.setModel(model.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD);
        this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS.setModel(model.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS);
        this.eyelidsEyeLashesAndLacrimalSystem.result.OD.setModel(model.eyelidsEyeLashesAndLacrimalSystem.result.OD);
        this.eyelidsEyeLashesAndLacrimalSystem.result.OS.setModel(model.eyelidsEyeLashesAndLacrimalSystem.result.OS);
        this.conjunctivaLimbusAndSclera.conjunctiva.OD.setModel(model.conjunctivaLimbusAndSclera.conjunctiva.OD);
        this.conjunctivaLimbusAndSclera.conjunctiva.OS.setModel(model.conjunctivaLimbusAndSclera.conjunctiva.OS);
        this.conjunctivaLimbusAndSclera.limbus.OD.setModel(model.conjunctivaLimbusAndSclera.limbus.OD);
        this.conjunctivaLimbusAndSclera.limbus.OS.setModel(model.conjunctivaLimbusAndSclera.limbus.OS);
        this.conjunctivaLimbusAndSclera.sclera.OD.setModel(model.conjunctivaLimbusAndSclera.sclera.OD);
        this.conjunctivaLimbusAndSclera.sclera.OS.setModel(model.conjunctivaLimbusAndSclera.sclera.OS);
        this.conjunctivaLimbusAndSclera.result.OD.setModel(model.conjunctivaLimbusAndSclera.result.OD);
        this.conjunctivaLimbusAndSclera.result.OS.setModel(model.conjunctivaLimbusAndSclera.result.OS);
        this.corneaAndAnteriorChamber.cornea.OD.setModel(model.corneaAndAnteriorChamber.cornea.OD);
        this.corneaAndAnteriorChamber.cornea.OS.setModel(model.corneaAndAnteriorChamber.cornea.OS);
        this.corneaAndAnteriorChamber.anteriorChamberDepth.OD.setModel(model.corneaAndAnteriorChamber.anteriorChamberDepth.OD);
        this.corneaAndAnteriorChamber.anteriorChamberDepth.OS.setModel(model.corneaAndAnteriorChamber.anteriorChamberDepth.OS);
        this.corneaAndAnteriorChamber.vanHerrickGrading.OD.setModel(model.corneaAndAnteriorChamber.vanHerrickGrading.OD);
        this.corneaAndAnteriorChamber.vanHerrickGrading.OS.setModel(model.corneaAndAnteriorChamber.vanHerrickGrading.OS);
        this.corneaAndAnteriorChamber.acCellsFlare.OD.setModel(model.corneaAndAnteriorChamber.acCellsFlare.OD);
        this.corneaAndAnteriorChamber.acCellsFlare.OS.setModel(model.corneaAndAnteriorChamber.acCellsFlare.OS);
        this.corneaAndAnteriorChamber.acFindings.OD.setModel(model.corneaAndAnteriorChamber.acFindings.OD);
        this.corneaAndAnteriorChamber.acFindings.OS.setModel(model.corneaAndAnteriorChamber.acFindings.OS);
        this.corneaAndAnteriorChamber.result.OD.setModel(model.corneaAndAnteriorChamber.result.OD);
        this.corneaAndAnteriorChamber.result.OS.setModel(model.corneaAndAnteriorChamber.result.OS);
        this.irisAndLens.iris.OD.setModel(model.irisAndLens.iris.OD);
        this.irisAndLens.iris.OS.setModel(model.irisAndLens.iris.OS);
        this.irisAndLens.lensStatus.OD.setModel(model.irisAndLens.lensStatus.OD);
        this.irisAndLens.lensStatus.OS.setModel(model.irisAndLens.lensStatus.OS);
        this.irisAndLens.cataractGrading.OD.setModel(model.irisAndLens.cataractGrading.OD);
        this.irisAndLens.cataractGrading.OS.setModel(model.irisAndLens.cataractGrading.OS);
        this.irisAndLens.otherCataractTypes.OD.setModel(model.irisAndLens.otherCataractTypes.OD);
        this.irisAndLens.otherCataractTypes.OS.setModel(model.irisAndLens.otherCataractTypes.OS);
        this.irisAndLens.result.OD.setModel(model.irisAndLens.result.OD);
        this.irisAndLens.result.OS.setModel(model.irisAndLens.result.OS);
        this.images = {
            OD: model.images.OD.map(i => {
                const image: SmartImageFrontend = new SmartImageFrontend();
                image.model = i;
                return image;
            }),
            OS: model.images.OS.map(i => {
                const image: SmartImageFrontend = new SmartImageFrontend();
                image.model = i;
                return image;
            }),
        };
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        try {
            let table = [];
            table.push({
                measurement: { content: 'Eyelids, Eyelashes And Lacrimal System', styles: { halign: 'left', fontStyle: 'bold' } },
                OD: { content: this.eyelidsEyeLashesAndLacrimalSystem.result.OD.value, styles: { halign: 'center' } },
                OS: { content: this.eyelidsEyeLashesAndLacrimalSystem.result.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Upper Eyelids', styles: { halign: 'left' } },
                OD: { content: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD.value, styles: { halign: 'center' } },
                OS: { content: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Lower Eyelids', styles: { halign: 'left' } },
                OD: { content: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD.value, styles: { halign: 'center' } },
                OS: { content: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Upper Eyelashes', styles: { halign: 'left' } },
                OD: { content: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD.value, styles: { halign: 'center' } },
                OS: { content: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Lower Eyelashes', styles: { halign: 'left' } },
                OD: { content: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD.value, styles: { halign: 'center' } },
                OS: { content: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Lacrimal System', styles: { halign: 'left' } },
                OD: { content: this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD.value, styles: { halign: 'center' } },
                OS: { content: this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS.value, styles: { halign: 'center' } },
            });

            table.push({
                measurement: { content: 'Conjuctiva, Limbus And Sclera', styles: { halign: 'left', fontStyle: 'bold' } },
                OD: { content: this.conjunctivaLimbusAndSclera.result.OD.value, styles: { halign: 'center' } },
                OS: { content: this.conjunctivaLimbusAndSclera.result.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Conjunctiva', styles: { halign: 'left' } },
                OD: { content: this.conjunctivaLimbusAndSclera.conjunctiva.OD.value, styles: { halign: 'center' } },
                OS: { content: this.conjunctivaLimbusAndSclera.conjunctiva.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Limbus', styles: { halign: 'left' } },
                OD: { content: this.conjunctivaLimbusAndSclera.limbus.OD.value, styles: { halign: 'center' } },
                OS: { content: this.conjunctivaLimbusAndSclera.limbus.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Sclera', styles: { halign: 'left' } },
                OD: { content: this.conjunctivaLimbusAndSclera.sclera.OD.value, styles: { halign: 'center' } },
                OS: { content: this.conjunctivaLimbusAndSclera.sclera.OS.value, styles: { halign: 'center' } },
            });

            table.push({
                measurement: { content: 'Cornea And Anterior Chamber', styles: { halign: 'left', fontStyle: 'bold' } },
                OD: { content: this.corneaAndAnteriorChamber.result.OD.value, styles: { halign: 'center' } },
                OS: { content: this.corneaAndAnteriorChamber.result.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Cornea', styles: { halign: 'left' } },
                OD: { content: this.corneaAndAnteriorChamber.cornea.OD.value, styles: { halign: 'center' } },
                OS: { content: this.corneaAndAnteriorChamber.cornea.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Anterior Chamber Depth', styles: { halign: 'left' } },
                OD: { content: this.corneaAndAnteriorChamber.anteriorChamberDepth.OD.value, styles: { halign: 'center' } },
                OS: { content: this.corneaAndAnteriorChamber.anteriorChamberDepth.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Van Herrick Grading', styles: { halign: 'left' } },
                OD: { content: this.corneaAndAnteriorChamber.vanHerrickGrading.OD.value, styles: { halign: 'center' } },
                OS: { content: this.corneaAndAnteriorChamber.vanHerrickGrading.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'AC Cells/Flare', styles: { halign: 'left' } },
                OD: { content: this.corneaAndAnteriorChamber.acCellsFlare.OD.value, styles: { halign: 'center' } },
                OS: { content: this.corneaAndAnteriorChamber.acCellsFlare.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'AC Findings', styles: { halign: 'left' } },
                OD: { content: this.corneaAndAnteriorChamber.acFindings.OD.value, styles: { halign: 'center' } },
                OS: { content: this.corneaAndAnteriorChamber.acFindings.OS.value, styles: { halign: 'center' } },
            });

            table.push({
                measurement: { content: 'Iris And Lens', styles: { halign: 'left', fontStyle: 'bold' } },
                OD: { content: this.irisAndLens.result.OD.value, styles: { halign: 'center' } },
                OS: { content: this.irisAndLens.result.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Iris', styles: { halign: 'left' } },
                OD: { content: this.irisAndLens.iris.OD.value, styles: { halign: 'center' } },
                OS: { content: this.irisAndLens.iris.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Lens Status', styles: { halign: 'left' } },
                OD: { content: this.irisAndLens.lensStatus.OD.value, styles: { halign: 'center' } },
                OS: { content: this.irisAndLens.lensStatus.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Cataract Grading (PSC, NS and CC)', styles: { halign: 'left' } },
                OD: { content: this.irisAndLens.cataractGrading.OD.value, styles: { halign: 'center' } },
                OS: { content: this.irisAndLens.cataractGrading.OS.value, styles: { halign: 'center' } },
            });
            table.push({
                measurement: { content: 'Other Cataract Types', styles: { halign: 'left' } },
                OD: { content: this.irisAndLens.otherCataractTypes.OD.value, styles: { halign: 'center' } },
                OS: { content: this.irisAndLens.otherCataractTypes.OS.value, styles: { halign: 'center' } },
            });
            (document as any).autoTable({
                body: table,
                startY: pager.line,
                pageBreak: 'auto',
                theme: 'grid',
                margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                didParseCell: <CellHookHandler>(data => {
                    if (data.section === 'head') {
                        if (data.row.index === 0 || data.row.index === 1) {
                            data.cell.styles.fillColor = [74, 96, 117];
                        }
                    }
                }),
                didDrawPage: () => {},
            });
            pager.line = (document as any).lastAutoTable.finalY;
            // images
            if (this.images.OD.length > 0 || this.images.OS.length > 0) {
                let head = [],
                    table = [];
                head.push({
                    OD: { content: 'OD', styles: { halign: 'center' } },
                    OS: { content: 'OS', styles: { halign: 'center' } },
                });
                await this.downloadMediaData(fileService);
                let length: number = this.images.OD.length > this.images.OS.length ? this.images.OD.length : this.images.OS.length;
                for (let index = 0; index < length; index++) {
                    let ratio: number = await new Promise<number>(res => {
                        let img = new Image();
                        img.onload = () => {
                            let height = img.height;
                            let width = img.width;
                            res(width / height);
                        };
                        img.src = this.images.OD[index] ? this.images.OD[index].imageContent : this.images.OS[index].imageContent;
                    });
                    table.push({
                        OD: {
                            content: '',
                            styles: {
                                minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                                minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                            },
                        },
                        OS: {
                            content: '',
                            styles: {
                                minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                                minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                            },
                        },
                    });
                }
                (document as any).autoTable({
                    head: head,
                    body: table,
                    startY: pager.line,
                    pageBreak: 'auto',
                    theme: 'grid',
                    margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                    didDrawCell: <CellHookHandler>(data => {
                        if (data.section === 'body' && this.images.OD[data.row.index] && data.column.dataKey === 'OD') {
                            document.addImage(this.images.OD[data.row.index].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                        } else if (data.section === 'body' && this.images.OS[data.row.index] && data.column.dataKey === 'OS') {
                            document.addImage(this.images.OS[data.row.index].imageContent, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                        }
                    }),
                    didDrawPage: () => {},
                });
                pager.line = (document as any).lastAutoTable.finalY;
            }
        } catch (error) {
            console.log(error);
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.conditions = {
            eyelidsEyeLashesAndLacrimalSystem: {
                upperEyelids: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OD,
                lowerEyelids: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OD,
                upperEyelashes: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OD,
                lowerEyelashes: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OD,
                lacrimalSystem: this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OD,
                result: this.eyelidsEyeLashesAndLacrimalSystem.result.OD,
            },
            conjunctivaLimbusAndSclera: {
                conjunctiva: this.conjunctivaLimbusAndSclera.conjunctiva.OD,
                limbus: this.conjunctivaLimbusAndSclera.limbus.OD,
                sclera: this.conjunctivaLimbusAndSclera.sclera.OD,
                result: this.conjunctivaLimbusAndSclera.result.OD,
            },
            corneaAndAnteriorChamber: {
                cornea: this.corneaAndAnteriorChamber.cornea.OD,
                anteriorChamberDepth: this.corneaAndAnteriorChamber.anteriorChamberDepth.OD,
                vanHerrickGrading: this.corneaAndAnteriorChamber.vanHerrickGrading.OD,
                acCellsFlare: this.corneaAndAnteriorChamber.acCellsFlare.OD,
                acFindings: this.corneaAndAnteriorChamber.acFindings.OD,
                result: this.corneaAndAnteriorChamber.result.OD,
            },
            irisAndLens: {
                iris: this.irisAndLens.iris.OD,
                lensStatus: this.irisAndLens.lensStatus.OD,
                cataractGrading: this.irisAndLens.cataractGrading.OD,
                otherCataractTypes: this.irisAndLens.otherCataractTypes.OD,
                result: this.irisAndLens.result.OD,
            },
        };
        formattedTest.OS.conditions = {
            eyelidsEyeLashesAndLacrimalSystem: {
                upperEyelids: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelids.OS,
                lowerEyelids: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelids.OS,
                upperEyelashes: this.eyelidsEyeLashesAndLacrimalSystem.upperEyelashes.OS,
                lowerEyelashes: this.eyelidsEyeLashesAndLacrimalSystem.lowerEyelashes.OS,
                lacrimalSystem: this.eyelidsEyeLashesAndLacrimalSystem.lacrimalSystem.OS,
                result: this.eyelidsEyeLashesAndLacrimalSystem.result.OS,
            },
            conjunctivaLimbusAndSclera: {
                conjunctiva: this.conjunctivaLimbusAndSclera.conjunctiva.OS,
                limbus: this.conjunctivaLimbusAndSclera.limbus.OS,
                sclera: this.conjunctivaLimbusAndSclera.sclera.OS,
                result: this.conjunctivaLimbusAndSclera.result.OS,
            },
            corneaAndAnteriorChamber: {
                cornea: this.corneaAndAnteriorChamber.cornea.OS,
                anteriorChamberDepth: this.corneaAndAnteriorChamber.anteriorChamberDepth.OS,
                vanHerrickGrading: this.corneaAndAnteriorChamber.vanHerrickGrading.OS,
                acCellsFlare: this.corneaAndAnteriorChamber.acCellsFlare.OS,
                acFindings: this.corneaAndAnteriorChamber.acFindings.OS,
                result: this.corneaAndAnteriorChamber.result.OS,
            },
            irisAndLens: {
                iris: this.irisAndLens.iris.OS,
                lensStatus: this.irisAndLens.lensStatus.OS,
                cataractGrading: this.irisAndLens.cataractGrading.OS,
                otherCataractTypes: this.irisAndLens.otherCataractTypes.OS,
                result: this.irisAndLens.result.OS,
            },
        };
        this.images.OD.forEach(si => formattedTest.OD.images.push(si.imageUrl));
        this.images.OS.forEach(si => formattedTest.OS.images.push(si.imageUrl));
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.images.OD.map(si => si.download(fileService)));
        await Promise.all(this.images.OS.map(si => si.download(fileService)));
    }
}
