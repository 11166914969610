import { Acuity4KidsBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/acuity4KidsBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { Acuity4KidsBulbicamControlComponent, Acuity4KidsBulbicamTestChartComponent, Acuity4kidsBulbicamTestRemarksComponent } from '../../../_components';
import { Acuity4KidsBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/acuity4KidsBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class Acuity4KidsBulbicamTestFrontend extends Acuity4KidsBulbicamTest {
    remarks: Acuity4KidsBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof Acuity4kidsBulbicamTestRemarksComponent = Acuity4kidsBulbicamTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof Acuity4KidsBulbicamTestChartComponent = Acuity4KidsBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof Acuity4KidsBulbicamControlComponent = Acuity4KidsBulbicamControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new Acuity4KidsBulbicamTestRemarksFrontend();
    }
}

export interface Acuity4KidsBulbicamTestFrontend extends TestFrontend {}
applyMixins(Acuity4KidsBulbicamTestFrontend, [TestFrontend]);
