import { MeasuredData } from '../../measuredData.model';
import { IPostSegmentTopconTritonMeasuredData } from '../../../../interfaces/measuredData/TOPCON/TRITON/post-segment-topcon-triton.measuredData.interface';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { SmartImage } from '../../../smartImage.class';

export abstract class PostSegmentTopconTritonMeasuredData implements MeasuredData, IPostSegmentTopconTritonMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: SmartImage[];
    public OS: SmartImage[];
    constructor() {
        this.sourceDevice = DEVICE.TOPCON_TRITON;
        this.testType = TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION;
    }
    public abstract setModel(model: IPostSegmentTopconTritonMeasuredData): void;
    public getModel(): IPostSegmentTopconTritonMeasuredData {
        let model: IPostSegmentTopconTritonMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.map((i) => i.model),
            OS: this.OS.map((i) => i.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.length === 0 && this.OS.length === 0;
    }
}
