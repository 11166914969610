import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

interface PupilSizeInfo {
    pupilSizeArray?: number[];
    minPupilSize?: [number, number];
    maxPupilSize?: [number, number];
    pupilSizeDetails?: PercentileInfo;
    selectedEye?: number;
}

interface PercentileInfo {
    p5?: number;
    p25?: number;
    p50?: number;
    p75?: number;
    p95?: number;
    mean?: number;
    cov?: number;
    measurementUnits?: string;
}

interface DetailsInfo {
    fixationInfo?: PercentileInfo;
    amplitudeInfo?: PercentileInfo;
    peakVelInfo?: PercentileInfo;
    infoList?: { trial: number; fixation: number; amplitude: number; peakVelocity: number; isAccepted?: boolean }[];
    maxFixation?: number;
    total?: number;
}

export interface TrialInfo {
    trial?: number;
    trialDur?: number;
    calibratedCoords?: [number, number][];
    velocityCoords?: [number, number][];
    type?: string;
    result?: string;
    latency?: number;
    sn?: { left: [number, number]; right: [number, number] }[];
    an?: number[];
    fixation?: number[];
    peakVelocity?: number[];
    timeCoord?: number;
    isCalibrationSuccessful?: boolean;
    pupilSizeCoords?: [number, number][];
}

export interface ChartData {
    isRendered?: boolean;
    chartType: string;
    realTimeDataCoordinates?: [number, number][];
    trialInfo?: TrialInfo[];
    data?: ICamMessage[];
    detailsInfo?: DetailsInfo;
    pupilSizeInfo?: PupilSizeInfo;
    testDuration?: number;
    eye?: string;
    threshold?: number;
    moving_average?: number;
    glintNMinus?: number;
}

@Injectable({
    providedIn: 'root',
})
export class FixationChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}
    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }
    testResults: ChartData | null;
    private data: ICamMessage[] = [];
    infoList: { trial: number; fixation: number; amplitude: number; peakVelocity: number; isAccepted?: boolean }[];
    accepted: boolean[] = [];
    isAcceptedListChanged = false;
    realTimeStartPoint: ICamMessage | null;
    charData$: BehaviorSubject<ChartData> = new BehaviorSubject({
        chartType: 'no-content',
    });
    isDataRendered$ = new BehaviorSubject(false);
    averageRatio$ = new BehaviorSubject(0);

    constructor() {
        super();
    }

    public addData(frames: ICamMessage[]): Promise<void> {
        this.isDataRendered$.next(false);

        const targettype = this._selectRawType(frames.find(d => d?.targettype)?.targettype);
        const data = frames.filter(el => el[targettype]);

        this.data.push(...frames);

        if (data.length) {
            if (!this.realTimeStartPoint) {
                this.realTimeStartPoint = data.find(d => d[targettype]);
            }

            const realTimePoints: [number, number][] = this._calculateRealTimeCoordinates(data, this.realTimeStartPoint, targettype);
            this.charData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: realTimePoints,
                data: this.data,
            });
        } else {
            this.charData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: [],
                data: this.data,
            });
        }

        return new Promise((res, rej) => {
            this.isDataRendered$.pipe(filter(data => Boolean(data))).subscribe(() => res());
        });
    }

    public setCamData(frames: ICamMessage[]): void {
        let { moving_average, glintNMinus } = frames[0];

        if (!moving_average) moving_average = 2;
        if (!glintNMinus) glintNMinus = 5;

        this.testResults = this.calculateChartResults(frames, moving_average, glintNMinus);
    }

    getChartData(frames: ICamMessage[], range: number, nMinus: number) {
        const chartData = this.calculateChartResults(frames, range, nMinus);

        this.charData$.next(chartData);
    }

    calculateChartResults(frames: ICamMessage[], range: number, nMinus: number): ChartData | null {
        let ratio = this.averageRatio$.getValue() || frames[0]?.ratio;

        if (frames[0]?.accepted) {
            this.accepted = JSON.parse(frames[0].accepted);
        } else {
            this.accepted = [];
        }

        if (!frames[0].accepted || !frames[0]?.ratio || !frames[0]?.threshold) {
            this.isAcceptedListChanged = true;
        }

        if (!frames.length) {
            console.error('Bad Data');
            return null;
        }

        if (!ratio) {
            console.error('Cannot get ratio value from pro saccade test');
            return null;
        }

        const targettype = this._selectRawType(frames[1].targettype);
        const data = frames.slice(1, frames.length - 1).filter(el => el[targettype]);

        if (data.length) {
            const testDuration = this.getXvalue(data[0].timestamp / 10000, data[data.length - 1].timestamp);
            const trials = frames.slice(-2)[0].trial;
            let trialInfo: TrialInfo[] = [];

            for (let i = 0; i <= trials; i++) {
                trialInfo.push({ trial: i });
            }

            let pupilSizeInfo: PupilSizeInfo = {};

            trialInfo = this._calculateCalibratedCoordinates(frames, trialInfo, ratio, targettype);
            [trialInfo, pupilSizeInfo] = this.calculateSizeCoordinates(frames, trialInfo);
            trialInfo = this._calculateVelocityCoordinates(frames, trialInfo, range, nMinus);

            const threshold = frames[0].threshold ? frames[0].threshold : 15;

            if (frames[0].threshold) {
                trialInfo = this._calculateTrialInfo(frames, trialInfo, threshold);
            } else {
                trialInfo = this._calculateTrialInfo(frames, trialInfo);
            }

            const detailsInfo = this._calculateDetails(trialInfo);

            pupilSizeInfo = this.calculatePupilSizeDetails(pupilSizeInfo);

            const chartData = {
                chartType: 'recorded',
                trialInfo,
                detailsInfo,
                pupilSizeInfo,
                testDuration: Math.ceil(testDuration),
                data: frames,
                eye: targettype.slice(3, 5),
                threshold: threshold,
                moving_average: +range,
                glintNMinus: +nMinus,
            };

            return chartData;
        } else {
            console.error('Bad Data');
            return null;
        }
    }

    public export() {
        if (!this.testResults) {
            console.error('No exported Data');
            return null;
        }
        const exportedData = {
            details: {
                eye: this.testResults.eye,
                percentile_25_deg: this.testResults.detailsInfo.amplitudeInfo.p25,
                percentile_25_degs: this.testResults.detailsInfo.peakVelInfo.p25,
                percentile_25_ms: this.testResults.detailsInfo.fixationInfo.p25,
                percentile_50_deg: this.testResults.detailsInfo.amplitudeInfo.p50,
                percentile_50_degs: this.testResults.detailsInfo.peakVelInfo.p50,
                percentile_50_ms: this.testResults.detailsInfo.fixationInfo.p50,
                percentile_75_deg: this.testResults.detailsInfo.amplitudeInfo.p75,
                percentile_75_degs: this.testResults.detailsInfo.peakVelInfo.p75,
                percentile_75_ms: this.testResults.detailsInfo.fixationInfo.p75,
                mean_deg: this.testResults.detailsInfo.amplitudeInfo.mean,
                mean_degs: this.testResults.detailsInfo.peakVelInfo.mean,
                mean_ms: this.testResults.detailsInfo.fixationInfo.mean,
                cov_deg: this.testResults.detailsInfo.amplitudeInfo.cov,
                cov_degs: this.testResults.detailsInfo.peakVelInfo.cov,
                cov_ms: this.testResults.detailsInfo.fixationInfo.cov,
                max_fixation: this.testResults.detailsInfo.maxFixation,
                pupil_distance_5: this.testResults.pupilSizeInfo.pupilSizeDetails.p5,
                pupil_distance_95: this.testResults.pupilSizeInfo.pupilSizeDetails.p95,
                pupil_distance_mean: this.testResults.pupilSizeInfo.pupilSizeDetails.mean,
            },
            thresold: this.testResults.threshold,
            moving_average: +this.testResults.moving_average,
            glintNMinus: +this.testResults.glintNMinus,
            trialsInfo: this.testResults.detailsInfo.infoList.map(d => ({
                trial: d.trial + 1,
                saccade: d.amplitude,
                peak_velocity: d.peakVelocity,
                fixation: d.fixation,
                status: d.isAccepted ? 'accepted' : 'disregarded',
            })),
        };
        return exportedData;
    }

    public clearData(): void {
        this.averageRatio$ = new BehaviorSubject(0);
        this.isAcceptedListChanged = false;
        this.data = [];
        this.realTimeStartPoint = null;
        this.charData$.next({
            chartType: 'cleared',
        });
    }

    private _calculateRealTimeCoordinates(data: ICamMessage[], realTimeStartPoint: ICamMessage, targettype: string): [number, number][] {
        const realTimePoints: [number, number][] = data.map(d => {
            const xPoint = this.getXvalue(realTimeStartPoint.timestamp / 10000, d.timestamp);
            const yRawPoint = this._calibrateRealTime(d, realTimeStartPoint[targettype], targettype);
            const yPoint = yRawPoint;

            return [xPoint, yPoint];
        });

        return realTimePoints;
    }

    private _calculateCalibratedCoordinates(frames: ICamMessage[], trialInfo: any[], ratio: number, targettype: string): TrialInfo[] {
        return trialInfo.map(trial => {
            const firstFrame = frames.find(d => d.trial === trial.trial && d.stage === 0);
            const trialFrames = frames.filter(d => d.trial === trial.trial && d.stage === 0 && this.getXvalue(firstFrame.timestamp / 10000, d.timestamp) >= 1);

            const [left, right] = [trialFrames[0], trialFrames.slice(-1)[0]];

            const calibrated: [number, number][] = trialFrames.map(d => {
                const startFrame = trialFrames.find(d => d[targettype]);

                const xPoint = this.getXvalue(trialFrames[0].timestamp / 10000, d.timestamp);
                const yPointTemp = d[targettype] ? this._calibrateX(d, startFrame, ratio, targettype) : null;
                const yPoint = yPointTemp > 5 || yPointTemp < -5 ? 5 * Math.sign(yPointTemp) : yPointTemp;

                return [xPoint, yPoint];
            });

            return {
                ...trial,
                trialDur: this.getXvalue(left.timestamp / 10000, right.timestamp),
                calibratedCoords: calibrated,
            };
        });
    }

    private _calculateVelocityCoordinates(frames: ICamMessage[], trialInfo: TrialInfo[], amountToleftRight: number = 3, differenceAmount: number = 5): TrialInfo[] {
        const averangeRange = amountToleftRight * 2 + 1;
        const rangeArray = (array: number[], neighbours: number, pivot: number) => {
            const range = neighbours * 2 + 1;
            if (pivot < 0) return [];
            const startInd = pivot - range / 2 > 0 ? pivot - Math.floor(range / 2) : 0;
            const lastInd = pivot + Math.ceil(range / 2);
            return array.slice(startInd, lastInd);
        };
        return trialInfo.map(trial => {
            let prev = 0;
            let calibrationValues = trial.calibratedCoords.map(d => d[1]);
            const movingAverage: number[] = trial.calibratedCoords.map((d, i) => {
                // const tempArray: number[] = trial.calibratedCoords
                //     .slice(i - 2 >= 0 ? i - 2 : 0, i + 3)
                //     .filter((d) => d[1] !== null)
                //     .map((d) => d[1]);

                const tempArray = rangeArray(calibrationValues, averangeRange, i);

                const average = this.calculateAverage(tempArray);

                return average;
            });

            const velocityCoordsTemp: [number, number][] = trial.calibratedCoords.map((d, ind) => {
                if (ind - differenceAmount <= 0) {
                    return [d[0], d[1] || null];
                }

                const [prevX, currX, prevY, currY] = [movingAverage[ind], movingAverage[ind - differenceAmount], d[1], trial.calibratedCoords[ind - differenceAmount][1]];
                if (prevX !== null && currX !== null && prevY !== null && currY !== null) {
                    prev = Math.abs((movingAverage[ind] - movingAverage[ind - differenceAmount]) / (d[0] - trial.calibratedCoords[ind - differenceAmount][0]));

                    return [d[0], prev];
                }

                return [d[0], null];
            });

            const velocityCoords: [number, number][] = velocityCoordsTemp.map((d, ind) => {
                // return !(ind % 5) ? d : velocityCoordsTemp[ind - (ind % 5)];
                return d;
            });
            return {
                ...trial,
                velocityCoords,
            };
        });
    }

    private _calculateTrialInfo(frames: ICamMessage[], trialInfo: TrialInfo[], minThreshold: number = 15): TrialInfo[] {
        return trialInfo.map(trial => {
            const firstFrame = frames.find(d => d.trial === trial.trial && d.stage === 0);
            const trialFrames = frames.filter(d => d.trial === trial.trial && d.stage === 0 && this.getXvalue(firstFrame.timestamp / 10000, d.timestamp) >= 1);
            const temp: [number, number][] = [];

            let sN: { left: [number, number]; right: [number, number] }[] = [];
            let aN: number[] = [];
            let peakVelocity: number[] = [];
            let fixation: number[] = [];

            for (let i = 0; i < trialFrames.length; i++) {
                if (trial.velocityCoords[i][1] >= minThreshold) {
                    const first = i;
                    while (i < trialFrames.length && trial.velocityCoords[i][1] >= minThreshold) {
                        i++;
                    }
                    temp.push([first, --i]);
                }
            }

            if (temp.length) {
                for (let i = 0; i <= temp.length; i++) {
                    if (i < temp.length) {
                        const [first, last] = [temp[i][0], temp[i][1]];

                        const fixationValue = i
                            ? trial.calibratedCoords[first][0] - trial.calibratedCoords[temp[i - 1][0]][0]
                            : trial.calibratedCoords[first][0] - trial.calibratedCoords[0][0];
                        fixation.push(fixationValue * 1000);
                        sN.push({
                            left: [trial.calibratedCoords[first][0], trial.calibratedCoords[first][1]],
                            right: [trial.calibratedCoords[last][0], trial.calibratedCoords[last][1] ? trial.calibratedCoords[last][1] : trial.calibratedCoords[first][1]],
                        });
                        // [x, y, value]
                        aN.push(Math.abs(trial.calibratedCoords[first][1] - trial.calibratedCoords[last][1]));
                        peakVelocity.push(Math.max(0, ...trial.velocityCoords.slice(first, last === first ? last + 1 : last).map(d => d[1])));
                    }
                    if (i === temp.length) {
                        fixation.push((trial.calibratedCoords.slice(-1)[0][0] - trial.calibratedCoords[temp.slice(-1)[0][0]][0]) * 1000);
                        sN.push({
                            left: [null, null],
                            right: [null, null],
                        });
                        aN.push(null);
                        peakVelocity.push(null);
                    }
                }
            } else {
                fixation.push((trial.calibratedCoords.slice(-1)[0][0] - trial.calibratedCoords[0][0]) * 1000);
                sN.push({
                    left: [null, null],
                    right: [null, null],
                });
                aN.push(null);
                peakVelocity.push(null);
            }

            return {
                ...trial,
                peakVelocity: peakVelocity,
                sn: sN,
                an: aN,
                fixation: fixation,
            };
        });
    }

    private _calculateDetails(trialInfo: TrialInfo[], threshold: number = 15): DetailsInfo {
        const fixationArray = [].concat(...trialInfo.map(d => d.fixation));
        const amplitudeArray = [].concat(...trialInfo.map(d => d.an));
        const peakVelArray = [].concat(...trialInfo.map(d => d.peakVelocity));

        const fixationArraySorted = fixationArray
            .slice()
            .sort((a, b) => a - b)
            .filter(d => d);
        const amplitudeArraySorted = amplitudeArray
            .slice()
            .sort((a, b) => a - b)
            .filter(d => d);
        const peakVelArraySorted = peakVelArray
            .slice()
            .sort((a, b) => a - b)
            .filter(d => d);

        const [fixationMean, amplitudeMean, peakVelMean] = [
            this.calculateAverage(fixationArraySorted),
            this.calculateAverage(amplitudeArraySorted),
            this.calculateAverage(peakVelArraySorted),
        ];

        const [fixationSD, amplitudeSD, peakVelSD] = [this.calculateSD(fixationArraySorted), this.calculateSD(amplitudeArraySorted), this.calculateSD(peakVelArraySorted)];

        const fixationInfo: PercentileInfo = {
            p25: this.getPercentile(fixationArraySorted, 25),
            p50: this.getPercentile(fixationArraySorted, 50),
            p75: this.getPercentile(fixationArraySorted, 75),
            mean: fixationMean,
            cov: fixationSD / fixationMean,
            measurementUnits: 'ms',
        };

        const amplitudeInfo: PercentileInfo = {
            p25: this.getPercentile(amplitudeArraySorted, 25),
            p50: this.getPercentile(amplitudeArraySorted, 50),
            p75: this.getPercentile(amplitudeArraySorted, 75),
            mean: amplitudeMean,
            cov: amplitudeSD / amplitudeMean,
            measurementUnits: 'deg',
        };

        const peakVelInfo: PercentileInfo = {
            p25: this.getPercentile(peakVelArraySorted, 25),
            p50: this.getPercentile(peakVelArraySorted, 50),
            p75: this.getPercentile(peakVelArraySorted, 75),
            mean: peakVelMean,
            cov: peakVelSD / peakVelMean,
            measurementUnits: 'deg/s',
        };

        this.infoList = fixationArray.map((d, ind) => {
            return {
                trial: ind,
                fixation: d,
                amplitude: amplitudeArray[ind],
                peakVelocity: peakVelArray[ind],
                isAccepted: this.accepted.length === fixationArray.length ? this.accepted[ind] : true,
            };
        });

        if (this.accepted.length !== fixationArray.length) {
            this.isAcceptedListChanged = true;
        }

        let buffarray: any[] = [];
        const temtArray: any[] = [];
        this.infoList.slice().forEach(d => {
            buffarray.push(d);

            if (d.amplitude === null && d.peakVelocity === null) {
                temtArray.push(buffarray);
                buffarray = [];
            }
        });

        this.infoListArray = temtArray.map(d => {
            let buff = 0;
            let included: any[] = [];
            let excluded: any[] = [];
            d.forEach((el: any, ind: number) => {
                buff += el.fixation;
                if (el.isAccepted) {
                    included.push({
                        trial: el.trial,
                        dur: buff - (included.length ? included.slice(-1)[0].coor : 0),
                        i: ind,
                        coor: buff,
                    });
                } else {
                    excluded.push({
                        trial: el.trial,
                        dur: el.fixation,
                        i: ind,
                        coor: buff,
                    });
                }
            });

            return {
                included,
                excluded,
            };
        });

        let newMaxFix = 0;

        this.infoListArray?.forEach((d: { included: any[]; excluded: any[] }) => {
            if (!d.included.length) {
                newMaxFix = 10000;
                return;
            }
        });

        if (!newMaxFix) {
            newMaxFix = Math.max(
                ...this.infoListArray.map((d: { included: { dur?: number }[]; excluded: any[] }) => {
                    return Math.max(...d.included.map(el => el.dur));
                })
            );
        }
        return {
            fixationInfo,
            amplitudeInfo,
            peakVelInfo,
            infoList: this.infoList,
            maxFixation: newMaxFix,
        };
    }

    updateDetails(
        filteredList: {
            trial: number;
            fixation: number;
            amplitude: number;
            peakVelocity: number;
            isAccepted?: boolean;
        }[]
    ): { fixationInfo: PercentileInfo; amplitudeInfo: PercentileInfo; peakVelInfo: PercentileInfo } {
        const fixationArray = filteredList.filter(d => d.isAccepted).map((d: any) => d.fixation);
        const amplitudeArray = filteredList.filter(d => d.isAccepted).map((d: any) => d.amplitude);
        const peakVelArray = filteredList.filter(d => d.isAccepted).map((d: any) => d.peakVelocity);

        const fixationArraySorted = fixationArray
            .slice()
            .sort((a: any, b: any) => a - b)
            .filter((d: any) => d);
        const amplitudeArraySorted = amplitudeArray
            .slice()
            .sort((a: any, b: any) => a - b)
            .filter((d: any) => d);
        const peakVelArraySorted = peakVelArray
            .slice()
            .sort((a: any, b: any) => a - b)
            .filter((d: any) => d);

        const [fixationMean, amplitudeMean, peakVelMean] = [
            this.calculateAverage(fixationArraySorted),
            this.calculateAverage(amplitudeArraySorted),
            this.calculateAverage(peakVelArraySorted),
        ];

        const [fixationSD, amplitudeSD, peakVelSD] = [this.calculateSD(fixationArraySorted), this.calculateSD(amplitudeArraySorted), this.calculateSD(peakVelArraySorted)];

        const fixationInfo: PercentileInfo = {
            p25: this.getPercentile(fixationArraySorted, 25),
            p50: this.getPercentile(fixationArraySorted, 50),
            p75: this.getPercentile(fixationArraySorted, 75),
            mean: fixationMean,
            cov: fixationSD / fixationMean,
            measurementUnits: 'ms',
        };

        const amplitudeInfo: PercentileInfo = {
            p25: this.getPercentile(amplitudeArraySorted, 25),
            p50: this.getPercentile(amplitudeArraySorted, 50),
            p75: this.getPercentile(amplitudeArraySorted, 75),
            mean: amplitudeMean,
            cov: amplitudeSD / amplitudeMean,
            measurementUnits: 'deg',
        };

        const peakVelInfo: PercentileInfo = {
            p25: this.getPercentile(peakVelArraySorted, 25),
            p50: this.getPercentile(peakVelArraySorted, 50),
            p75: this.getPercentile(peakVelArraySorted, 75),
            mean: peakVelMean,
            cov: peakVelSD / peakVelMean,
            measurementUnits: 'deg/s',
        };

        return {
            fixationInfo,
            amplitudeInfo,
            peakVelInfo,
        };
    }

    updateTrialInfo(threshold: number): void {
        const chartDataValue = this.charData$.getValue();

        const updatedTrialInfo = this._calculateTrialInfo(chartDataValue.data, chartDataValue.trialInfo, threshold);
        const updatedDetails = this._calculateDetails(updatedTrialInfo, threshold);

        this.accepted = updatedDetails.infoList.map(d => d.isAccepted);

        this.charData$.next({
            ...chartDataValue,
            trialInfo: updatedTrialInfo,
            detailsInfo: updatedDetails,
        });
    }

    infoListArray: any[] = [];

    updateTrialInfoList(trialIndex: number): void {
        const chartDataValue = this.charData$.getValue();

        // this.infoList.splice(trialIndex, 1, {...this.infoList[trialIndex], isAccepted: !this.infoList[trialIndex].isAccepted});
        this.accepted[trialIndex] = !this.accepted[trialIndex];

        const infoListFiltered = chartDataValue.detailsInfo.infoList.slice();
        infoListFiltered.splice(trialIndex, 1, { ...infoListFiltered[trialIndex], isAccepted: !infoListFiltered[trialIndex].isAccepted });
        this.isAcceptedListChanged = true;

        this.infoListArray?.forEach((d: { included: any[]; excluded: any[] }, ind: number) => {
            if (d.included.map(d => d.trial).includes(trialIndex) || d.excluded.map(d => d.trial).includes(trialIndex)) {
                if (infoListFiltered[trialIndex].isAccepted) {
                    const removed = d.excluded.splice(
                        d.excluded.findIndex(d => d.trial === trialIndex),
                        1
                    );
                    d.included.push(removed[0]);
                } else {
                    const removed = d.included.splice(
                        d.included.findIndex(d => d.trial === trialIndex),
                        1
                    );
                    d.excluded.push(removed[0]);
                }
            }
        });

        this.infoListArray?.forEach(d => {
            d.included.sort((a: { trial?: number }, b: { trial?: number }) => a.trial - b.trial);
            d.excluded.sort((a: { trial?: number }, b: { trial?: number }) => a.trial - b.trial);
        });

        this.infoListArray?.forEach((d: { included: any[]; excluded: any[] }) => {
            d.included = d.included.map((el: { trial: number; dur: number; i: number; coor: number }, ind) => ({ ...el, dur: el.coor - (ind ? d.included[ind - 1].coor : 0) }));
        });

        let newMaxFix = 0;
        this.infoListArray?.forEach((d: { included: any[]; excluded: any[] }) => {
            if (!d.included.length) {
                newMaxFix = 10000;
                return;
            }
        });

        if (!newMaxFix) {
            newMaxFix = Math.max(
                ...this.infoListArray.map((d: { included: { dur?: number }[]; excluded: any[] }) => {
                    return Math.max(...d.included.map(el => el.dur));
                })
            );
        }

        const updatedDetails = this.updateDetails(infoListFiltered);

        this.charData$.next({
            ...chartDataValue,
            detailsInfo: {
                ...updatedDetails,
                maxFixation: newMaxFix,
                infoList: infoListFiltered,
                total: chartDataValue.detailsInfo.total,
            },
        });
    }

    private calculateSizeCoordinates(frames: ICamMessage[], trialInfo: TrialInfo[]): [TrialInfo[], PupilSizeInfo] {
        const selectedPupil = this.selectEyeType(frames[1].targettype);
        const pupilSize: number[] = frames.map(d => d?.[selectedPupil] || 0).filter(d => d > 0);

        let [minSize, maxSize, averageSize] = [Math.min(...pupilSize), Math.max(...pupilSize), this.calculateAverage(pupilSize)];
        const averageSizeFloor = Math.floor(averageSize);

        const [lowerSize, upperSize] = [averageSizeFloor % 2 ? averageSizeFloor - 1 : averageSizeFloor, averageSizeFloor % 2 ? averageSizeFloor + 1 : averageSizeFloor + 2];

        [minSize, maxSize] = [Math.min(...[minSize, lowerSize]), Math.max(...[maxSize, upperSize])];

        const updatedTrialInfo = trialInfo.map(trial => {
            const firstFrame = frames.find(d => d.trial === trial.trial && d.stage === 0);
            const trialFrames = frames.filter(d => d.trial === trial.trial && d.stage === 0 && this.getXvalue(firstFrame.timestamp / 10000, d.timestamp) >= 1);

            const sizeCoords: [number, number][] = trial.calibratedCoords.map((d, ind) => {
                const pupilSize = trialFrames[ind]?.[selectedPupil] || -1;
                return [d[0], pupilSize];
            });

            return {
                ...trial,
                pupilSizeCoords: sizeCoords,
            };
        });

        const updatedDetails: PupilSizeInfo = {
            pupilSizeArray: pupilSize,
            minPupilSize: [minSize, lowerSize],
            maxPupilSize: [maxSize, upperSize],
            selectedEye: frames[1].targettype,
        };

        return [updatedTrialInfo, updatedDetails];
    }

    private calculatePupilSizeDetails(pupilSizeInfo: PupilSizeInfo): PupilSizeInfo {
        const pupilSizeArraySorted = pupilSizeInfo.pupilSizeArray.slice().sort((a, b) => a - b);

        return {
            ...pupilSizeInfo,
            pupilSizeDetails: {
                p5: this.getPercentile(pupilSizeArraySorted, 5),
                mean: this.calculateAverage(pupilSizeArraySorted),
                p95: this.getPercentile(pupilSizeArraySorted, 95),
            },
        };
    }

    private _selectRawType(targettype: number): 'rawODx' | 'rawOSx' {
        return targettype === 1 ? 'rawOSx' : 'rawODx';
    }

    private _calibrateX(current: ICamMessage, raw: ICamMessage, ratio: number, targettype: string): number {
        const rawDeg = 0;
        const rawX = raw[targettype];

        const calibratedX = rawDeg + (current[targettype] - rawX) / ratio;

        return calibratedX;
    }

    private _calibrateRealTime(current: ICamMessage, startPoint: number, targettype: string): number {
        const currentRaw = current[targettype];

        return 0.3 * (currentRaw - startPoint);
    }

    findMiddleCalibrated(data: ICamMessage[], trial: number, targettype: string): ICamMessage | null {
        const currentTrialFirstSnapshot = data.find(d => d.trial === trial && d.stage === 0);
        const result = data.find(
            d =>
                d.trial === trial &&
                d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000 > 975 &&
                d.timestamp / 10000 - currentTrialFirstSnapshot.timestamp / 10000 < 1025
        );

        return result[targettype] ? result : null;
    }

    private selectEyeType(targettype: number): string {
        return targettype === 1 ? 'OSPupilSize' : 'ODPupilSize';
    }
}
