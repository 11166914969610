import { MaculaScanNidekRs330Test } from '../../../../../../../../commonout/classes/tests/NIDEK/RS330/maculaScanNidekRs330Test';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { MaculaNidekRs330TestRemarksComponent } from '../../../../_components';
import { MaculaScanNidekRs330TestRemarksFrontend } from '../../../test-remarks/NIDEK/RS330/maculaScanNidekRs330TestRemarksFrontend.class copy';
import { TestFrontend } from '../../testFrontend.class';

export class MaculaScanNidekRs330TestFrontend extends MaculaScanNidekRs330Test {
    remarks: MaculaScanNidekRs330TestRemarksFrontend;
    public readonly remarksComponent: typeof MaculaNidekRs330TestRemarksComponent = MaculaNidekRs330TestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new MaculaScanNidekRs330TestRemarksFrontend();
    }
}

export interface MaculaScanNidekRs330TestFrontend extends TestFrontend {}
applyMixins(MaculaScanNidekRs330TestFrontend, [TestFrontend]);
