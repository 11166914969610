import { CamTest } from '../../../../common/models/haplotest.class';

export class BulbicamTestFrontend extends CamTest {
    constructor() {
        super();
    }
    public getVersions(): string {
        let versions = '';
        // for (const key in this.version) {
        //     if (this.version.hasOwnProperty(key)) {
        //         const value = this.version[key];
        //         switch (key) {
        //             case 'haploversion':
        //                 versions += '\r\n' + 'HAP:\xa0' + value;
        //                 break;
        //             case 'swversion':
        //                 versions += ' V:\xa0' + value + ';\r\n';
        //                 break;
        //             case 'lensholderversion':
        //                 versions += '\r\n' + 'LH:\xa0' + value + ';';
        //                 break;
        //             case 'foamthickness':
        //                 versions += '\r\n' + 'Foam:\xa0' + value + ';';
        //                 break;
        //             case 'exposuretime':
        //                 versions += '\r\n' + 'ExpT:\xa0' + value + ';';
        //                 break;
        //             case 'patientlens':
        //                 versions += '\r\n' + 'PL:\xa0' + value + ';';
        //                 break;
        //             case 'aperture':
        //                 versions += '\r\n' + 'Ap:\xa0' + value + ';';
        //                 break;
        //             case 'saccadiohubversion':
        //                 versions += '\r\n' + 'SW:\xa0' + value + ';';
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // }
        return versions;
    }
}
