import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { ThreeDScanIvueOCTMeasuredDataFrontend } from '../../../measuredData/IVUE/OCT/3dscanIvueOctDataFrontend';

export class ThreeDScanIcueOctTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof ThreeDScanIvueOCTMeasuredDataFrontend = ThreeDScanIvueOCTMeasuredDataFrontend;
    public measurements: ThreeDScanIvueOCTMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
