export class TimestampToDatepickerValueConverter {
    convert(timestamp: number): string | null {
        let datepickerValue: string = null;

        try {
            const date = new Date(timestamp);

            if (isNaN(date.getTime())) {
                throw new Error('Invalid Date');
            }

            datepickerValue =
                ('0' + date.getHours()).slice(-2) + ':' +
                ('0' + date.getMinutes()).slice(-2) + ':' +
                ('0' + date.getSeconds()).slice(-2);
        } catch (error) {
            datepickerValue = null;
        }

        return datepickerValue;
    }
}
