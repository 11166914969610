// @ts-nocheck
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICamMessage } from '../../../../../../../../commonout/interfaces/charts.model';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { Diagram } from './diagram/Diagram';

@Component({
    selector: 'vergence-test-chart',
    template: require('./vergence-test-chart.component.html'),
    styles: [require('../charts.scss'), require('./vergence-test-chart.component.scss')],
})
export class VergenceBulbicamTestChartComponent extends BulbicamChartComponent {
    @ViewChild('targetElement') vergenceChart: ElementRef;
    @ViewChild('targetContainer') targetContainer: ElementRef;
    diagram?: Diagram;

    // edits: any[] = [];
    public setEdits(got: any): void {
        //     // console.log('got setEdits:', got)
        //     this.diagram.edits = got;
    }

    constructor(private bulbicamService: BulbicamService, private authenticationService: AuthenticationService, private conf: ConfigService) {
        super();
        // this.bulbicamService.vergenceStaff.createTest = () => {
        //     this.clearData(true);
        //     this.refresh();
        // };
    }

    // I assume inputData is already set...
    ngAfterViewInit() {
        let target = this.vergenceChart.nativeElement;
        let targetContainer = this.targetContainer.nativeElement;

        this.initChart(target, targetContainer);

        if (this.inputData) {
            this.addData(this.inputData);
            this.diagram.refresh();
        } else {
        }
        this.refresh();
        // this.bulbicamService.vergenceStaff.startParams = (() => {
        //     return this.startParams;
        // }).bind(this);
    }

    initChart(targetElement: HTMLElement, targetContainer: HTMLElement) {
        // log('initChart');
        // let theme = 'any';
        // let { conf } = this;
        // let apiPrefix = conf.backendUrl;
        // let apiPrefix = `/api/v1/haplo`
        this.diagram = new Diagram({
            place: {
                svgTarget: targetElement,
                targetContainer,
            },
            // theme,
            // updateStartParams: (data: any) => {
            //     this.startParams = data;
            // },
            // requestToken: this.authenticationService.currentUser.token,
            // apiPrefix,

            // edits: this.edits,
            // addEdit: (x: any) => this.addEdit(x),
        });
    }

    addData(data: ICamMessage[]) {
        // this.bulbicamService.vergenceStaff.chartCleared = false;
        this.diagram.addData(data);
        this.diagram.refresh();
    }

    refresh = () => {
        this.diagram.refresh();
    };

    clearData(signal: boolean) {
        // this.bulbicamService.vergenceStaff.chartCleared = signal;
        this.diagram.reset();
    }

    get exportData() {
        return this.diagram.exportData;
    }
}

function log(x: string | object) {
    return;
    if (typeof x === 'string') x = `vergence.${x}`;
    console.log(x);
}
