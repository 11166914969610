import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GENDER } from '../../../../../../../commonout/enum/gender';
import { RACE } from '../../../../../../../commonout/enum/race';
import { country_keys } from '../../../../../../common/countries/countries';
import { OCULUS } from '../../../../../../common/enums/oculus.enum';
import { IPatient } from '../../../../../../common/interfaces/patient.interface';
import { FocusDirective } from '../../../_directives';
import { PatientFrontend } from '../../../_models';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ModalService } from '../../../_services/general/modal.service';
import { TranslateService } from '../../../_services/general/translate.service';
import { PatientService } from '../../../_services/patient/patient.service';

@Component({
    selector: 'patient-details',
    template: require('./patient-details.component.html'),
    styles: [require('./patient-details.component.scss')],
})
export class PatientDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren(FocusDirective) private controls: QueryList<FocusDirective>;
    private focusedControl: number = 1;
    public patient: PatientFrontend;
    public patientForm: FormGroup;
    private maxDatepickerDate: Date = new Date();
    private subscriptions: Subscription[] = [];
    private isFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private lockTab: boolean = false;
    private readonly GENDER: typeof GENDER = GENDER;
    private readonly RACE: typeof RACE = RACE;
    private readonly OCULUS: typeof OCULUS = OCULUS;
    private readonly countries: string[] = country_keys.map((shortName: string) => this.translateService.instant(shortName));
    private readonly maritalStatus: string[] = ['Married', 'Single'];
    private readonly pastOcularHistory: string[] = ['Nil', 'Cataract', 'Diabetic Retinopathy', 'Glaucoma', 'Keratoconus', 'Myopia', 'RD'];
    private readonly pastMedicalHistory: string[] = ['Nil', 'pmhDM', 'DM + HT', 'pmhHT', 'IHD'];
    private readonly familyHistory: string[] = ['Nil', 'Glaucoma', 'Myopia/High refractive error', 'Diabetic retonipathy', 'Retinitis pigmentosa', 'Macular Degeneration'];
    private readonly birthHistory: string[] = ['Normal', 'Caesarian', 'Forceps'];
    private readonly allergyHistory: string[] = [
        'Not aware of any',
        'Sulpha',
        'Penicillin',
        'Quinolones',
        'Dilatation drops T+',
        'Dilatation drops Cyclopentolate',
        'Dilatation drops Homide',
        'Dilatation drops Phenylephrine',
        'Dilatation drops Tropicamide',
        'Anticonvulsants',
        'Others',
    ];

    constructor(
        private location: Location,
        private formBuilder: FormBuilder,
        private patientService: PatientService,
        private examinationService: ExaminationService,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private translateService: TranslateService
    ) {
        this.patientForm = this.formBuilder.group({
            registerID: ['', Validators.required],
            gender: [''],
            race: [''],
            dateOfBirth: [
                null,
                function(control: any): any {
                    if (control.value && !moment(control.value, 'DD.MM.YYYY', true).isValid()) {
                        return { invalidDate: true };
                    }
                    return null;
                },
            ],
            city: ['', Validators.pattern(/[^0-9]+$/)],
            country: ['', Validators.pattern(/[^0-9]+$/)],
            phone: ['', Validators.pattern(/^\+?[\d()\-\s]*$/)],
            email: ['', Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)],
            occupation: '',
            maritalStatus: '',
            ssn: '',
            insuranceCompany: '',
            insurancePlan: '',
            ocularMedication: '',
            ocularInvestigation: '',
            pastMedicalHistory: this.formBuilder.group({
                selectValue: '',
                additionalTextValue: '',
            }),
            systemicMedication: '',
            recentInvestigation: '',
            familyHistory: this.formBuilder.group({
                selectValue: '',
                additionalTextValue: '',
            }),
            birthHistory: this.formBuilder.group({
                selectValue: '',
                additionalTextValue: '',
            }),
            personalHistory: '',
            allergyHistory: this.formBuilder.group({
                selectValue: '',
                additionalTextValue: '',
            }),
            mainComplaints: this.formBuilder.array([]),
            pastOcularHistory: this.formBuilder.array([]),
            diagnosis: '',
        });
    }
    ngAfterViewInit(): void {
        this.controls.first.elementRef.nativeElement.focus();
    }
    async ngOnInit() {
        await this.patientService.fetchDefaultCountry();
        this.patient = new PatientFrontend();
        const patientId = this.route.snapshot.params['patientId'];
        if (patientId) {
            const patientModel: IPatient = await this.patientService.getPatient(patientId).toPromise();
            this.patient.setModel(patientModel);
        }
        this.patientForm.reset({
            registerID: this.patient.registerID.value,
            gender: this.patient.gender.value,
            race: this.patient.race.value,
            dateOfBirth: this.patient.dateOfBirth.value ? moment(this.patient.dateOfBirth.value as number).format('DD.MM.YYYY') : null,
            city: this.patient.city.value,
            country: this.patient.country.value,
            phone: this.patient.phone.value,
            email: this.patient.email.value,
            occupation: this.patient.occupation.value,
            maritalStatus: this.patient.maritalStatus.value,
            ssn: this.patient.ssn.value,
            insuranceCompany: this.patient.insuranceCompany.value,
            insurancePlan: this.patient.insurancePlan.value,
            ocularMedication: this.patient.ocularMedication.value,
            ocularInvestigation: this.patient.ocularInvestigation.value,
            pastMedicalHistory: {
                selectValue: this.patient.pastMedicalHistory.selectValue.value,
                additionalTextValue: this.patient.pastMedicalHistory.additionalTextValue.value,
            },
            systemicMedication: this.patient.systemicMedication.value,
            recentInvestigation: this.patient.recentInvestigation.value,
            familyHistory: {
                selectValue: this.patient.familyHistory.selectValue.value,
                additionalTextValue: this.patient.familyHistory.additionalTextValue.value,
            },
            birthHistory: {
                selectValue: this.patient.birthHistory.selectValue.value,
                additionalTextValue: this.patient.birthHistory.additionalTextValue.value,
            },
            personalHistory: this.patient.personalHistory.value,
            allergyHistory: {
                selectValue: this.patient.allergyHistory.selectValue.value,
                additionalTextValue: this.patient.allergyHistory.additionalTextValue.value,
            },
            diagnosis: this.patient.diagnosis.value,
        });
        this.patientForm.setControl(
            'mainComplaints',
            this.formBuilder.array(
                this.patient.mainComplaints.map(mc => {
                    return this.formBuilder.group({
                        complaint: mc.complaint.value,
                        oculus: this.formBuilder.control(mc.oculus.value),
                        duration: mc.duration.value,
                    });
                })
            )
        );
        this.patientForm.setControl(
            'pastOcularHistory',
            this.formBuilder.array(
                this.patient.pastOcularHistory.map(poh => {
                    return this.formBuilder.group({
                        condition: this.formBuilder.control(poh.condition.value),
                        oculus: this.formBuilder.control(poh.oculus.value),
                        duration: poh.duration.value,
                    });
                })
            )
        );
    }

    ngOnDestroy() {
        this.subscriptions.map(s => s.unsubscribe());
    }

    private save() {
        this.update();
    }

    async update() {
        try {
            const action = this.patient._id ? 'update' : 'create',
                formValues = this.patientForm.getRawValue();
            this.patient.update(formValues, this.authenticationService.currentUser.getModel(true));

            if (action === 'create') {
                const patientModel: IPatient = await this.patientService.create(this.patient.model).toPromise();

                const examination: ExaminationFrontend = await this.examinationService.create(patientModel._id).toPromise();
                this.router.navigate(['/dashboard-staff/test-list', examination._id]);
            } else {
                await this.patientService.update(this.patient.model).toPromise();
                this.location.back();
            }
        } catch (error) {
            console.log(error);
        }
    }

    private goBack(): void {
        if (this.isFocused.value) {
            this.isFocused.next(false);
            this.lockTab = true;
            if (this.patientForm.dirty) {
                this.modalService.askUser('Exit without saving changes?').then(resp => {
                    if (resp) {
                        this.location.back();
                    }
                });
            } else {
                this.location.back();
            }
        }
    }

    private async addComplaint(): Promise<void> {
        (this.patientForm.get('mainComplaints') as FormArray).push(
            this.formBuilder.group({
                complaint: '',
                oculus: '',
                duration: '',
            })
        );
        this.patientForm.markAsDirty();
    }

    private async addPastOcularHistory(): Promise<void> {
        (this.patientForm.get('pastOcularHistory') as FormArray).push(
            this.formBuilder.group({
                condition: '',
                oculus: '',
                duration: '',
            })
        );
        this.patientForm.markAsDirty();
    }

    @HostListener('document:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (this.isFocused.value && event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        } else if (this.isFocused.value && event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.goBack();
        } else if (event.key === 'Tab') {
            event.preventDefault();
            event.stopPropagation();
            if (this.lockTab) return;
            if (event.shiftKey) {
                if (this.focusedControl === 1) {
                    this.focusedControl = this.controls.length;
                } else {
                    this.focusedControl = this.focusedControl - 1;
                }
            } else {
                if (this.focusedControl === this.controls.length) {
                    this.focusedControl = 1;
                } else {
                    this.focusedControl = this.focusedControl + 1;
                }
            }
            this.onFocused(this.focusedControl);
        } else if (!this.isFocused.value && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
            // prevent scrolling when some other window is in focus
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
        }
    }
    private onFocused(focusedElement: number): void {
        this.focusedControl = focusedElement;
        this.controls.find(e => e.focus === focusedElement).elementRef.nativeElement.focus();
    }
}
