import { Component, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import saveAs from 'file-saver';
import { from } from 'rxjs';
import { concatMap, filter, toArray } from 'rxjs/operators';
import { IResponse } from '../../../../../../common/interfaces/response.model';
import { BulbicamTestFrontend } from '../../../_models/haplotestFrontend.class';
import { TestFrontend } from '../../../_models/tests/testFrontend.class';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { ExportService } from '../../../_services/examination/export.service';
import { TestService } from '../../../_services/examination/test.service';
import { AuthenticationService } from '../../../_services/general/auth.service';

@Component({
    selector: 'new-export-button',
    template: require('./new-export-button.component.html'),
    styles: [require('./new-export-button.component.scss')],
})
export class NewExportButtonComponent {
    @Input() test: TestFrontend;
    @Input() checked: FormArray;
    public format: FormControl = new FormControl('tsv');
    public output: FormControl = new FormControl('single');
    public isMenuOpened: boolean = false;
    constructor(private exportService: ExportService, private bulbicamService: BulbicamService, private testService: TestService, private authService: AuthenticationService) {}
    public async export(): Promise<void> {
        let camTests: BulbicamTestFrontend[] = await from(this.checked.controls)
            .pipe(
                filter(fGroup => fGroup.get('checked').value),
                concatMap(async fGroup => {
                    const camTest: BulbicamTestFrontend = fGroup.get('camTest').value;
                    const chartDataResponse: IResponse = await this.bulbicamService.getHaplotestData(camTest.haplotestData);
                    camTest.rawData = chartDataResponse.data;
                    camTest.grades = fGroup.get('grade').value ? [fGroup.get('grade').value] : [];
                    return camTest;
                }),
                toArray()
            )
            .toPromise();
        if (camTests.length === 0) return;
        const res: { content: Blob; filename: string }[] = await this.exportService.toCompressedTsv(this.test, camTests);
        res.forEach(data => {
            saveAs(data.content, data.filename);
        });
    }
}
