import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, from, Subject, Subscription } from 'rxjs';
import { concatMap, filter, mergeMap } from 'rxjs/operators';
import { Grade } from '../../../../../../../commonout/classes/grade.class';
import { TestHepler } from '../../../../../../../commonout/classes/testClass.provider';
import { DEVICE } from '../../../../../../../commonout/enum/device';
import { CamTest } from '../../../../../../common/models/haplotest.class';
import { TestDirective } from '../../../_directives';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { TestFrontend } from '../../../_models/tests/testFrontend.class';
import { TestService } from '../../../_services/examination/test.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ConfigService } from '../../../_services/general/config.service';
import { TestRemarksComponent } from '../../tests/remarks/testRemarksComponent';

@Component({
    selector: 'examinations-list',
    template: require('./examinations-list.component.html'),
    styles: [require('./examinations-list.component.scss')],
})
export class ExaminationsListComponent implements OnInit, OnDestroy {
    public testsOrder: TestHepler[];
    private subscriptions: Subscription[];
    @Input() examinations: BehaviorSubject<ExaminationFrontend[]>;
    @Output() registerIdClicked = new EventEmitter<string>();
    @ViewChild(TestDirective) test: TestDirective;
    public isRemarksShown: Subject<boolean> = new Subject();
    private feedbacks: Grade[];
    public DEVICE: typeof DEVICE = DEVICE;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private testService: TestService,
        private authService: AuthenticationService,
        public configService: ConfigService
    ) {
        this.subscriptions = [];
        this.isRemarksShown.next(false);
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.examinations.subscribe(async exams => {
                if (exams) this.feedbacks = await this.collectGradesForShownExams(exams);
            })
        );
        this.subscriptions.push(
            this.isRemarksShown.subscribe(async isShown => {
                if (!isShown) this.feedbacks = await this.collectGradesForShownExams(this.examinations.value);
            })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private async collectGradesForShownExams(examinations: ExaminationFrontend[]): Promise<Grade[]> {
        let feedbacks: Grade[] = [];
        await from(examinations)
            .pipe(
                mergeMap(exam => this.testService.getFeedbacks({ examinationID: exam._id, author: this.authService.currentUser.getModel(true) })),
                filter(grades => grades.length > 0),
                concatMap(grades => (feedbacks = feedbacks.concat(grades.map(g => new Grade().setModel(g)))))
            )
            .toPromise();
        return feedbacks;
    }

    public getTest(test: TestHepler, examination: ExaminationFrontend): TestFrontend {
        return examination.tests.find(t => t.type === test.type && t.device === test.device);
    }
    public showRemarks(test: TestFrontend): void {
        if (!test.isRemarksExist()) return;
        this.isRemarksShown.next(true);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<TestRemarksComponent>(test.remarksComponent),
            viewContainerRef: ViewContainerRef = this.test.viewContainerRef;
        viewContainerRef.clear();
        setTimeout(() => {
            const componentRef = viewContainerRef.createComponent<TestRemarksComponent>(componentFactory);
            componentRef.instance.test = test;
        }, 100);
    }
    public getFeedbackIconColor(test: TestFrontend, feedbackType: 'recordingGrade' | 'clinicalGrade'): 'red' | 'green' {
        if (test.device !== DEVICE.HAPLO) return;
        let measurements: CamTest[] = [];
        test.remarks.measurements[0]['haplotests'].forEach((ht: CamTest) => (measurements = measurements.concat(ht)));
        let gradesCnt: number = 0;
        measurements.forEach(m => {
            let feedback = this.feedbacks?.filter(f => f.examinationID === test.examination._id && f.testType === test.type).find(f => f.measurementCreatedAt === m.createdAt);
            if (feedback?.hasGrade(feedbackType)) gradesCnt += 1;
        });
        return gradesCnt === measurements.length ? 'green' : 'red';
    }
}
