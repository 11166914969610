import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { KeratometryTonorefIIIMeasuredDataFrontend } from '../../../measuredData/NIDEK/TonorefIII/keratometryTonorefIIIMeasuredDataFrontend.class';

export class KeratometryTonorefIIITestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof KeratometryTonorefIIIMeasuredDataFrontend = KeratometryTonorefIIIMeasuredDataFrontend;
    public measurements: KeratometryTonorefIIIMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
