import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { TestRemarksComponent } from '../_components/tests/remarks/testRemarksComponent';
import { TestFrontend } from '../_models/tests/testFrontend.class';

@Directive({
    selector: '[test-details]',
})
export class TestDetailsDirective implements OnInit {
    @Input() test: TestFrontend;
    public instance: TestRemarksComponent;
    constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}
    ngOnInit(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory<TestRemarksComponent>(this.test.remarksComponent);
        this.viewContainerRef.clear();
        const componentRef = this.viewContainerRef.createComponent<TestRemarksComponent>(componentFactory);
        this.instance = componentRef.instance;
        this.instance.test = this.test;
    }
}
