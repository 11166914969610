import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { from } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { TEST_RESULT } from '../../../../../../commonout/enum/test-result.enum';
import { TestRemarks } from '../../../../../common/models/testRemark.class';
import { BulbicamService } from '../../_services/examination/bulbiCam.service';
import { Pager } from '../../_services/examination/export.service';
import { FileService } from '../../_services/general/file.service';
import { MeasuredDataFrontend } from '../measuredData/measuredDataFrontend';
import { RegisterEditFieldFrontend } from '../registerEditFieldFrontend.class';
import { TestFrontend } from '../tests/testFrontend.class';

export abstract class TestRemarkFrontend extends TestRemarks {
    public measurements: MeasuredDataFrontend[];
    public result: RegisterEditFieldFrontend<string>;
    public result_new: {
        OD: RegisterEditFieldFrontend<TEST_RESULT>;
        OS: RegisterEditFieldFrontend<TEST_RESULT>;
    };
    public diagnosis: RegisterEditFieldFrontend<string>;
    public advice: RegisterEditFieldFrontend<string>;
    public laboratoryRequested: RegisterEditFieldFrontend<string>;
    public medicinesPrescribed: RegisterEditFieldFrontend<string>;
    public test: TestFrontend;
    constructor() {
        super();
        this.result = new RegisterEditFieldFrontend();
        this.result_new = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.diagnosis = new RegisterEditFieldFrontend();
        this.advice = new RegisterEditFieldFrontend();
        this.laboratoryRequested = new RegisterEditFieldFrontend();
        this.medicinesPrescribed = new RegisterEditFieldFrontend();
    }
    public get lastMeasurement(): MeasuredDataFrontend {
        return this.measurements[this.measurements.length - 1];
    }
    public async toPDF(
        document: jsPDF,
        pager: Pager,
        fileService: FileService,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        bulbicamService: BulbicamService
    ): Promise<void> {
        try {
            let head = [];
            head.push({
                column1: { content: 'Testing method:', styles: { halign: 'left' } },
                column2: { content: this.measurements[0].sourceDevice, styles: { halign: 'right' } },
            });
            (document as any).autoTable({
                head: head,
                startY: pager.line,
                pageBreak: 'auto',
                theme: 'grid',
                margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                didParseCell: <CellHookHandler>(data => {
                    if (data.section === 'head') {
                        if (data.row.index === 0 || data.row.index === 1) {
                            data.cell.styles.fillColor = [74, 96, 117];
                        }
                    }
                }),
                didDrawPage: () => {},
            });
            pager.line = (document as any).lastAutoTable.finalY;

            await from(this.measurements)
                .pipe(
                    concatMap(measurement => measurement.toPDF(document, pager, fileService, componentFactoryResolver, viewContainerRef, bulbicamService)),
                    toArray()
                )
                .toPromise();

            head = [];
            head.push({
                column1: { content: 'Result:', styles: { halign: 'left' } },
                column2: { content: this.result.value, styles: { halign: 'right' } },
            });
            head.push({
                column1: { content: 'Diagnosis:', styles: { halign: 'left' } },
                column2: { content: this.diagnosis.value, styles: { halign: 'right' } },
            });
            (document as any).autoTable({
                head: head,
                startY: pager.line,
                pageBreak: 'auto',
                theme: 'grid',
                margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                didParseCell: <CellHookHandler>(data => {
                    if (data.section === 'head') {
                        if (data.row.index === 0 || data.row.index === 1) {
                            data.cell.styles.fillColor = [74, 96, 117];
                        }
                    }
                }),
                didDrawPage: () => {},
            });
            pager.line = (document as any).lastAutoTable.finalY;
        } catch (error) {
            console.log(error);
        }
    }
}
