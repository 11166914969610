import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PachymetryMeasuredDataFrontend } from '../../../measuredData/NIDEK/NT530P/pachymetryDataFrontend.class';

export class PachymetryTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PachymetryMeasuredDataFrontend = PachymetryMeasuredDataFrontend;
    public measurements: PachymetryMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
