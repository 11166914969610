// @ts-nocheck
import { Target, XY } from '../lib/types'
import { doTranslate } from '../lib/util'
import { Layout } from '../lib/Layout'
import { TextBlock } from '../lib/TextBlock'

interface Params {
  target: Target,
  point: XY,
  width: number
  height: number
  textDy?: number

  title?: string
  unitX?: string
  unitY?: string
  also?: string[]
  drawables?: Drawable[]
}

interface Drawable {
  x?: number
  y?: number
  draw: (t: Target) => void
}

export function Legend1(p: Params) {
  let klass = 'Legend1'
  let selector = `.${klass}`
  let addedClass = 'hide-on-hover'
  let textDy = p.textDy || 0

  let x = p.point.x - p.width
  let y = p.point.y

  let drawables = p.drawables || []

  let target = p.target
    .selectAll(selector)
    .data([null]) //p.data])
    .join('g')
    .attr('class', `${klass} ${addedClass}`)
    .call(doTranslate({ x, y }))

  target
    .selectAll('rect')
    .data([null])
    .join('rect')
    .attr('width', p.width)
    .attr('height', p.height)
    .attr('class', 'fBg sBorder')

  let pieces: string[] = []
  if (p.title) pieces.push(`${p.title}, \n`) // odd way
  if (p.unitY) pieces.push(`↑${p.unitY}`)
  if (p.unitX) pieces.push(`→${p.unitX}`)
  if (p.also) pieces = pieces.concat(p.also)
  let text = pieces.join(', ')
  text = text.replace(/, \n,\s*/g, '\n')
  let area = new Layout(0, 0, p.width, p.height, '').slide(10,textDy) //6)
  TextBlock({
    text,
    dy: 20,
    alignTop: true,
    alignLeft: true,
    target,
    area,
    drawables,
    styleParams: {
      theme: 'any',
    },
  })
}
