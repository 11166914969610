import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import ITest from '../../../../../../commonout/interfaces/test';
import { IResponse, RESPONSE_STATUS } from '../../../../../common/interfaces/response.model';
import { ConfigService } from '../../_services/general/config.service';

export interface IFileDeletePayload {
    examinationID: string;
    device: DEVICE;
    testType: TEST_TYPE;
    sourceFileName?: string;
    target?: string;
    targetCriteria?: string;
    imageID?: string;
    haploMeasurementID?: string;
}

@Injectable()
export class FileService {
    // storage to hold (fileID <=> file_content) relations to avoid asking backend multiple times for the same data
    private fileStorage: Map<string, string>;
    constructor(private http: HttpClient, private configService: ConfigService) {
        this.fileStorage = new Map<string, string>();
    }
    public updateFile(id: string, file: string): Observable<{ newID: string }> {
        if (!file || !id) {
            return;
        }
        const url = `${this.configService.backendUrl}/url/update`,
            payload = { id, file };
        return this.http.post<{ newID: string }>(url, payload);
    }
    public saveFile(file: string | void): Promise<string> {
        if (!file) {
            return;
        }

        const url = `${this.configService.backendUrl}/url/save`,
            payload = { file };

        return this.http
            .post<IResponse>(url, payload)
            .pipe(
                map(response => {
                    if (response.status === RESPONSE_STATUS.ERROR) {
                        throw response.message;
                    }
                    return response.data;
                }),
                catchError(error => {
                    throw error;
                })
            )
            .toPromise();
    }

    public async getFileContentByFileID(fileId: string): Promise<string> {
        if (this.fileStorage.has(fileId)) {
            return this.fileStorage.get(fileId);
        } else {
            const url = `${this.configService.backendUrl}/url/file`;
            return this.http
                .post<string>(url, { fileId: fileId })
                .toPromise()
                .then(objectsArray => {
                    this.fileStorage.set(fileId, objectsArray[0][fileId]);
                    return objectsArray[0][fileId];
                })
                .catch(err => {
                    console.log(err);
                    return '';
                });
        }
    }

    public async getFilesContentByFilesID(filesId: string[]): Promise<object[]> {
        // separation of ID's already been downloaded and not
        const existingFiles: object[] = [],
            absentFiles: string[] = [];
        filesId.forEach(id => {
            if (this.fileStorage.has(id)) {
                let obj = {};
                obj[id] = this.fileStorage.get(id);
                existingFiles.push(obj);
            } else {
                absentFiles.push(id);
            }
        });
        // if there is files not downloaded yet then ask backend for those content
        if (absentFiles.length > 0) {
            const url = `${this.configService.backendUrl}/url/file`;
            return this.http
                .post<object[]>(url, { fileId: absentFiles })
                .toPromise()
                .then(responce => {
                    responce.forEach(data => {
                        for (const key in data) {
                            if (data.hasOwnProperty(key)) {
                                const imageUrl: string = data[key];
                                // add to storage
                                this.fileStorage.set(key, imageUrl);
                                // add to prepared result
                                existingFiles.push(data);
                            }
                        }
                    });
                    return existingFiles;
                })
                .catch(err => {
                    console.log(err);
                    return [];
                });
        }
        // if all files were downloaded before that just return them
        return existingFiles;
    }
    public deleteFile(fileInfo: IFileDeletePayload): Observable<ITest> {
        return this.http.post<ITest>(`${this.configService.backendUrl}/measurement/delete`, fileInfo);
    }
    public get logo(): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>(res => {
            let img = new Image();
            img.onload = () => {
                res(img);
            };
            img.src = `${this.configService.backendStatic}/images/logo_full_light.png`;
        });
    }
}
