// @ts-nocheck
import { State, DataSet } from './State';
import { RawPoint } from '../lib';

/*
 * NOTE: may be outdated
 * Example message sequence:
 *
 * - started test
 *
 * - start sequence (has stimuli info)
 * - pos data point | spv data point | phase marker (any of these separatedly in any order)
 *   (spv data point - these are computed points, sent separatedly, can be added by frontend possibly)
 *   (phase markers - one per eye as other stuff)
 * - ...
 * - ...
 *
 * - start sequence
 * - ...
 *
 * - start sequence
 * - ...
 *
 * - finished test
 */

// Below are details of what is being sent

interface Common {
    message_type: number;
    timestamp: number;
}
export interface StartSequence extends Common {
    name: string;
}
export interface StopSequence extends Common {
    filename: string;
    originalfilename?: string;

    degtopx: number; // old

    degtopxHOS: number;
    degtopxHOD: number;
    degtopxVOS: number;
    degtopxVOD: number;
}
export interface PosDataPoint extends Common {
    h_od: number; // horizontal angle
    v_od: number; // vertical angle
    t_od: number; // torsion angle

    h_os: number;
    v_os: number;
    t_os: number;

    // is not sent, filled at frontend:
    ODhPoint: RawPoint;
    ODvPoint: RawPoint;
    ODtPoint: RawPoint;

    OShPoint: RawPoint;
    OSvPoint: RawPoint;
    OStPoint: RawPoint;

    ODtrackingPoint: RawPoint;
    OStrackingPoint: RawPoint;

    dataSet: DataSet;
    ownType: 'pos';

    odPresent: boolean;
    osPresent: boolean;

    index: number;
}
// export interface PosDataPoint extends Common {
//   targettype: number // od=0 / os=1

//   h: number // horizontal angle
//   v: number // vertical angle
//   t: number // torsion angle

//   // is not sent, filled at frontend:
//   hPoint: RawPoint,
//   vPoint: RawPoint,
//   tPoint: RawPoint,

//   dataSet: DataSet,
//   ownType: 'pos',
// }
export interface SpvDataPoint extends Common {
    targettype: number; // od=0 / os=1

    spv: number; // slow-phase-velocity (computed point, can be added at any time, even by frontend itself)

    // is not sent, filled at frontend:
    spvPoint: RawPoint;

    dataSet: DataSet;
    ownType: 'spv';
}
export interface PhaseMarkerPoint extends Common {
    targettype: number; // od=0 / os=1

    phase: number;

    // is not sent, filled at frontend:
    phasePoint: RawPoint & { text: string };

    dataSet: DataSet;
    ownType: 'phase';
}

const PHASES = new Map<number, string>();
PHASES.set(0, 'Start');
PHASES.set(1, 'Stop');

export let lengthInSeconds = 4;

export function phaseName(p: PhaseMarkerPoint) {
    return PHASES.get(p.phase) ?? '?';
}

export function isPhaseMarkerPoint(given: DataPoint): given is PhaseMarkerPoint {
    let x = given as any;
    return typeof x.phase === 'number';
}
export function isSpvDataPoint(given: DataPoint): given is SpvDataPoint {
    let x = given as any;
    return typeof x.spv === 'number';
}
export function isPosDataPoint(given: DataPoint): given is PosDataPoint {
    let x = given as any;
    let result =
        typeof x.h_od === 'number' &&
        typeof x.v_od === 'number' &&
        typeof x.t_od === 'number' &&
        typeof x.h_os === 'number' &&
        typeof x.v_os === 'number' &&
        typeof x.t_os === 'number';
    return result;

    // return typeof x.h === 'number' &&
    //     typeof x.v === 'number' &&
    //     typeof x.t === 'number'
}
export type DataPoint = PosDataPoint | SpvDataPoint | PhaseMarkerPoint;
// export interface DataPoint extends Common {
//   targettype: number // od=0 / os=1

//   // there are two possible params sets:
//   // either this:
//   h: number // horizontal angle
//   v: number // vertical angle
//   t: number // torsion angle

//   // or this:
//   spv: number // slow-phase-velocity (computed point, can be added at any time, even by frontend itself)

//   // this is calculated and not sent by camera app
//   hPoint: RawPoint,
//   vPoint: RawPoint,
//   tPoint: RawPoint,
//   spvPoint: RawPoint,
//   dataSet: DataSet,
//   ownType: 'pos'|'spv', // relaxing type system by keeping two types here
// }

// names of known stimuli
// (ideally add-only list after it is in use, no protocol versions distinction)
//
const STIMULI = new Map<number, string>();
STIMULI.set(0, 'Gray background');
STIMULI.set(1, 'Vertical lines');
STIMULI.set(2, 'Horizontal lines');
STIMULI.set(3, 'Moving images');

// export function stimuliName (x: number) {
//   return STIMULI.get(x) ?? '?';
// }
export function stimuliName(x: string) {
    return x;
}

// Below are predicates that are used to categorize incoming messages into expected types
// isCommon test is applied to every message
// NOTE: they test only for what is needed for charts

const isCommon = (x: any): boolean => typeof x === 'object' && x !== null && typeof x.message_type === 'number' && typeof x.timestamp === 'number';

const isStartedTest = (x: any): boolean => x.message_type === 0;

const isFinishedTest = (x: any): boolean => x.message_type === 1 || x.message_type === 13 || x.message_type === 14;

const isStartSequence = (x: any): x is StartSequence => x.message_type === 15 && typeof x.name === 'string';

const isStopSequence = (x: any): x is StopSequence =>
    x.message_type === 16 &&
    // typeof x.degtopx === 'number' &&
    typeof x.filename === 'string';

const isDataPoint = (x: any): x is DataPoint => {
    let matched = 0;
    // if (isPhaseMarkerPoint(x)) matched += 1
    // if (isSpvDataPoint(x)) matched += 1
    if (isPosDataPoint(x)) matched += 1;

    return x.message_type === 6 && matched === 1;
    // typeof x.targettype === 'number' && matched === 1
};
// function onlyOneMatches(xs: (() => boolean)[]) {
//   let matched = 0
//   xs.forEach(x => x())
//   return matched === 1
// }
// (
//   typeof x.h === 'number' &&
//   typeof x.v === 'number' &&
//   typeof x.t === 'number'
// ) || (
//   typeof x.spv === 'number'
// )

export interface TrackingPoint extends Common {
    x: number;
    y: number;
}
const isTrackingPoint = (x: Common): x is TrackingPoint => x.message_type === 6;

// code that uses these definitions

export class Protocol {
    constructor(private state: State) {}
    input(given: unknown): void {
        if (Array.isArray(given)) return given.forEach((x) => this.input(x));

        if (isCommon(given)) {
            if (isStartedTest(given)) return (this.state.lengthInSeconds = given.lengthInSeconds); // ... who handles that state reset?
            if (isFinishedTest(given)) return;
            if (isStartSequence(given)) return this.state.gotStartSequence(given);
            if (isStopSequence(given)) return this.state.gotStopSequence(given);
            if (isDataPoint(given)) return this.state.gotDataPoint(given);
        }
        console.log(`unhandled input:`, given);
    }
}
