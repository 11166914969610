import { Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Grade } from '../../../../../../../../commonout/classes/grade.class';
import { DEVICE } from '../../../../../../../../commonout/enum/device';
import { TEST_STATUS } from '../../../../../../../../commonout/enum/test-status';
import { TEST_TYPE } from '../../../../../../../../commonout/enum/test-type';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../../../commonout/interfaces/charts.model';
import { IGrade } from '../../../../../../../../commonout/interfaces/grade.interface';
import ITest from '../../../../../../../../commonout/interfaces/test';
import { IResponse } from '../../../../../../../common/interfaces/response.model';
import { BulbicamControlComponent } from '../../../../_components';
import { BulbicamControlDirective } from '../../../../_directives/bulbiCamControl.directive';
import { BulbicamTestFrontend } from '../../../../_models/haplotestFrontend.class';
import { PtosisBulbicamMeasuredDataFrontend } from '../../../../_models/measuredData/BulbiCAM/ptosisBulbicamDataFrontend.class';
import { TestFrontend } from '../../../../_models/tests/testFrontend.class';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { TestService } from '../../../../_services/examination/test.service';
import { AuthenticationService } from '../../../../_services/general/auth.service';
import { ConfigService } from '../../../../_services/general/config.service';
import { FileService } from '../../../../_services/general/file.service';
import { ModalService } from '../../../../_services/general/modal.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { BulbicamChartComponent } from '../haploChart.component';

@Component({
    selector: 'chart-wrapper',
    template: require('./chart-wrapper.component.html'),
    styles: [require('./chart-wrapper.component.scss')],
})
export class ChartWrapperComponent implements OnInit, OnDestroy {
    private DEVICE: typeof DEVICE = DEVICE;
    public TEST_TYPE: typeof TEST_TYPE = TEST_TYPE;
    public TEST_STATUS: typeof TEST_STATUS = TEST_STATUS;
    private subscriptions: Subscription[];
    @Input() test: TestFrontend;
    @ViewChild(BulbicamControlDirective)
    private bulbicamControlTemplate: BulbicamControlDirective;
    @ViewChild('wrapperChartContainer', { read: ViewContainerRef })
    wrapperChartContainer: ViewContainerRef;
    private bulbicamControlComponent: BulbicamControlComponent;
    public bulbicamChartComponent: BulbicamChartComponent;
    public selected: number;
    public form: FormGroup;
    private grades: Grade[] = [];
    private boundMessageListener: Function;
    constructor(
        private socketService: SocketService,
        private modalService: ModalService,
        private fileService: FileService,
        private configService: ConfigService,
        private bulbicamService: BulbicamService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private testService: TestService
    ) {
        this.subscriptions = [];
        this.selected = null;
        this.boundMessageListener = this.messagesHandler.bind(this);
    }
    public ngOnInit(): void {
        // !!! avoid making things that should be done once for exam here!! because it will do those things for each test
        if (this.test.bulbicamChartComponent) {
            const bulbicamChartComponentFactory = this.componentFactoryResolver.resolveComponentFactory(this.test.bulbicamChartComponent),
                bulbicamChartComponentRef = this.wrapperChartContainer.createComponent(bulbicamChartComponentFactory);
            this.bulbicamChartComponent = bulbicamChartComponentRef.instance as any;
            this.bulbicamChartComponent.addEdit = this.addEdit.bind(this);
            this.bulbicamChartComponent.updateCamMessage = this.updateCamMessage.bind(this);
            this.bulbicamChartComponent.deleteCamMessage = this.deleteCamMessage.bind(this);
            switch (this.test.type) {
                case TEST_TYPE.FUNCTIONAL_SCREENING:
                    this.bulbicamChartComponent.startParams = {
                        proSaccadesRatioUpdate: this.updateRatio.bind(this),
                    };
                    break;
                case TEST_TYPE.PTOSIS_EVALUATION:
                    this.bulbicamChartComponent.startParams = {
                        test: this.test,
                    };
                    break;
                default:
                    break;
            }
        }
        if (this.test.bulbicamControlComponent) {
            const bulbicamControlComponentFactory = this.componentFactoryResolver.resolveComponentFactory(this.test.bulbicamControlComponent),
                bulbicamControlComponentRef = this.bulbicamControlTemplate.viewContainerRef.createComponent(bulbicamControlComponentFactory);
            this.bulbicamControlComponent = bulbicamControlComponentRef.instance as any;
            this.bulbicamControlComponent.test = this.test;
        }
        this.updateForm();
        this.socketService.socket.on(this.test.type + 'ChartMessage', this.boundMessageListener);
        this.testService
            .getFeedbacks({
                examinationID: this.test.examination._id,
                testType: this.test.type,
                author: this.authService.currentUser.getModel(true),
            })
            .subscribe(grades => {
                this.grades = grades.map(g => new Grade().setModel(g));
                this.updateForm();
            });
    }
    private messagesHandler(messages: ICamMessage[]) {
        if (messages.find(m => m.message_type === MESSAGE_TYPE.START_TEST)) {
            this.selected = null;
            this.bulbicamChartComponent.clearData(true);
        }
        if (messages.find(m => m.message_type === MESSAGE_TYPE.STOP_TEST)) {
            const stopMessage: ICamMessage = messages.find(m => m.message_type === MESSAGE_TYPE.STOP_TEST);
            this.test.examination.setModel(stopMessage.examinationModel);
            this.bulbicamControlComponent.enable();
            this.selected = (<any>this.test.remarks.measurements[0]).haplotests.length - 1;
            this.provideFeedback();
            this.updateForm();
        }
        this.bulbicamChartComponent.addData(messages);
    }
    ngOnDestroy(): void {
        this.socketService.socket?.off(this.test.type + 'ChartMessage', this.boundMessageListener);
        this.subscriptions.forEach(s => s.unsubscribe());
        this.wrapperChartContainer.clear();
    }
    public checkExport(i: number): void {
        this.measures.controls.forEach((fGroup: FormGroup, index: number) => fGroup.get('checked').setValue(i === index));
    }
    get measures(): FormArray {
        return this.form.get('measures') as FormArray;
    }
    get camTests(): BulbicamTestFrontend[] {
        return (this.test.remarks.measurements[0] as any).haplotests;
    }
    public async deleteChart(haploMeasurementID: string, index: number): Promise<void> {
        const answer: boolean = await this.modalService.askUser('Are you sure you want to remove the measurement?');
        if (answer) {
            const testModel: ITest = await this.fileService
                .deleteFile({
                    device: this.test.device,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    haploMeasurementID: haploMeasurementID,
                })
                .toPromise();
            if (index === this.selected) {
                this.selected = null;
                this.bulbicamChartComponent.clearData(true);
            }
            this.test.setModel(testModel);
            this.updateForm();
            await this.bulbicamService.collectProSaccadesRatio(this.test.examination);
        }
    }
    private updateForm(): void {
        const fArray = this.camTests.map(t =>
            this.formBuilder.group({
                checked: false,
                camTest: t,
                grade: this.grades.find(g => g.measurementCreatedAt === t.createdAt), // each measurement has only single grade from one author. Each grade contain all types of possible grades
            })
        );
        this.form = this.formBuilder.group({
            measures: this.formBuilder.array(fArray),
        });
    }
    public async downloadChart(bulbicamTest: BulbicamTestFrontend): Promise<void> {
        const pad_with_zeroes = function(numberToPad: number, length: number): string {
            let my_string: string = '' + numberToPad;
            while (my_string.length < length) {
                my_string = '0' + my_string;
            }
            return my_string;
        };
        const saveData = (function() {
            let a: any = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            return function(data: string, fileName: string) {
                let blob = new Blob([data], { type: 'octet/stream' });
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            };
        })();
        const chartDataResponse: IResponse = await this.bulbicamService.getHaplotestData(bulbicamTest.haplotestData);
        const messages: ICamMessage[] = chartDataResponse.data;
        let logFormat: string[] = messages.map(m => {
            const stringfiedChartMessage: string = JSON.stringify(m);
            return `<SP>${pad_with_zeroes(stringfiedChartMessage.length, 8)}${stringfiedChartMessage}\n`;
        });
        saveData(logFormat.join(''), `${bulbicamTest.createdAt}.log`);
    }
    public async showDiagram(bulbicamTest: BulbicamTestFrontend, selected: number): Promise<void> {
        if (this.selected === selected) {
            return;
        }
        this.selected = selected;
        this.bulbicamChartComponent.clearData(false);
        const chartDataResponse: IResponse = await this.bulbicamService.getHaplotestData(bulbicamTest.haplotestData);
        this.bulbicamChartComponent.setEdits(bulbicamTest.edits);
        if ((this.bulbicamChartComponent as any).setCamData) {
            (this.bulbicamChartComponent as any).setCamData(chartDataResponse.data);
        } else {
            this.bulbicamChartComponent.addData(chartDataResponse.data);
        }
        if (!this.form.get(`measures.${this.selected}.grade`).value)
            // if any grade already ixists we don't need to show feedback popup by default
            this.provideFeedback();
    }
    private async addEdit(edit: IChartEdit): Promise<IChartEdit[]> {
        try {
            edit.date = Date.now();
            edit.editorId = this.authService.currentUser._id;
            edit.editor = this.authService.currentUser.roleProperties.firstName + ' ' + this.authService.currentUser.roleProperties.lastName;
            const targetData: {
                examinationID: string;
                testType: TEST_TYPE;
                haploMeasurementID: number;
                edit: IChartEdit;
            } = {
                examinationID: this.test.examination._id,
                testType: this.test.type,
                haploMeasurementID: (<any>this.test.remarks.measurements[0]).haplotests[this.selected].haplotestData,
                edit: edit,
            };
            const chartEdits: IChartEdit[] = await this.bulbicamService.saveChartEdit(targetData).toPromise();
            (<any>this.test.remarks.measurements[0]).haplotests[this.selected].edits = chartEdits;
            return chartEdits;
        } catch (error) {
            console.log(error);
        }
    }
    private async updateCamMessage<T>(frame: T): Promise<void> {
        const responce: { newID: string } = await this.bulbicamService
            .updateCamMessage<T>({
                examinationID: this.test.examination._id,
                testType: this.test.type,
                haploMeasurementID: (<any>this.test.remarks.measurements[0]).haplotests[this.selected].haplotestData,
                newMessage: frame,
            })
            .toPromise();
        (<PtosisBulbicamMeasuredDataFrontend>this.test.remarks.measurements[0]).haplotests[this.selected].haplotestData = responce.newID;
    }
    private async deleteCamMessage(frameTS: number): Promise<void> {
        const responce: { newID: string } = await this.bulbicamService
            .deleteCamMessage({
                examinationID: this.test.examination._id,
                testType: this.test.type,
                haploMeasurementID: (<any>this.test.remarks.measurements[0]).haplotests[this.selected].haplotestData,
                messageToDeleteTS: frameTS,
            })
            .toPromise();
        (<PtosisBulbicamMeasuredDataFrontend>this.test.remarks.measurements[0]).haplotests[this.selected].haplotestData = responce.newID;
    }
    public async bugReport(reasone: 'crash' | 'freeze'): Promise<void> {
        await this.testService
            .provideFeedback({
                _id: null,
                examinationID: this.test.examination._id,
                testType: this.test.type,
                measurementCreatedAt: this.test.remarks.measurements[0]['haplotests'][this.selected]?.createdAt || null,
                createdAt: this.test.remarks.measurements[0]['haplotests'][this.selected]?.createdAt || Date.now(),
                crash: reasone === 'crash' ? true : false,
                freeze: reasone === 'freeze' ? true : false,
                clinicalGrade: null,
                recordingGrade: null,
                comment: null,
                author: this.authService.currentUser.getModel(true),
            })
            .toPromise();
    }
    private async provideFeedback(): Promise<void> {
        if (this.configService.feedbackService) {
            this.grades = await this.modalService
                .provideFeedback({
                    _id: null,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    measurementCreatedAt: this.test.remarks.measurements[0]['haplotests'][this.selected].createdAt,
                    createdAt: Date.now(),
                    recordingGrade: null,
                    clinicalGrade: null,
                    crash: null,
                    freeze: null,
                    comment: null,
                    author: this.authService.currentUser.getModel(true),
                })
                .then(grades => grades.map(g => new Grade().setModel(g)));
            this.updateForm();
        }
    }
    public async editFeedback(grade: IGrade): Promise<void> {
        await this.modalService.provideFeedback(grade);
        this.updateForm();
    }
    public showSubtype(bulbicamTest: BulbicamTestFrontend): string {
        switch (bulbicamTest.subtype) {
            case 'MEMORYSACCADE':
                return 'Memory';
            case 'PROSACCADE':
                return 'Pro';
            case 'ANTISACCADE':
                return 'Anti';
            case 'SACCADES':
                return 'Saccades';
            case 'SMOOTH_PURSUIT':
                return 'Smooth pursuit';
            default:
                return bulbicamTest.subtype;
        }
    }
    private async updateRatio(): Promise<void> {
        await this.bulbicamService.collectProSaccadesRatio(this.test.examination);
    }
}
