import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { PupillaryEvaluationTonorefIIIMeasuredDataFrontend } from '../../../measuredData/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIIMeasuredDataFrontend.class';

export class PupillaryEvaluationTonorefIIITestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PupillaryEvaluationTonorefIIIMeasuredDataFrontend = PupillaryEvaluationTonorefIIIMeasuredDataFrontend;
    public measurements: PupillaryEvaluationTonorefIIIMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
