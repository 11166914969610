import { Layout } from '../lib';

// types start
export type ColorGroup = [string, (x: number) => boolean, string];
export type ID = number;
export type MyEdit = Pick<IChartEdit, 'messageTimestamp' | 'fieldName' | 'previousValue' | 'currentValue'>;
export type Target = d3.Selection<any, any, any, any>;
// types end

// enum start
export enum ShowState {
    HIDDEN,
    SHOWN,
    FIXED,
}

export enum InputStatus {
    UNSEEN = 1,
    NODATA = 2,
    SEEN = 3,
}
// enum end

// interface start
export interface XY {
    x: number;
    y: number;
}

export interface RawPoint {
    x: number
    y: number
}

export interface Params {
    svg: SVGSVGElement;
    emitChange(delta: any): void; //{ timestamp: number })
    edits: IChartEdit[];
    addEdit(edit: MyEdit): Promise<IChartEdit[]>;
}

export interface BooleanParams {
    text: string
    checked: boolean
    // color: string|{ klass: string }
    color?: string
    onClick: () => void

    noCheckBox?: boolean
    radio?: boolean

    target: Target
    area: Layout
    fontSize?: number
    // style?: Partial<Style>
}

export interface OptionParams {
    // from: RawPoint
    // to: RawPoint
    items: any[]
    isChecked: (d:any) => boolean
    onClick: (d:any) => void
    coloring: (d:any) => string
    naming: (d:any) => string

    newType?: boolean

    target: Target;
    area: Layout;
    styleParams: {
      theme?: string,
      override?: any,
    }
    fontSize?: number
}

export interface ITargetData {
    xCoor: number;
    yCoor: number;
    type: 'OS' | 'OD';
    tempFrame?: any;

    srt: number; // this is being switched: srt or tat, temp behavior, gotta see what direction is chosen
    actualSrt: number;
    actualTat: number;
    actualDelta: number;

    colorRect: string; //'#e2584d' | '#ff9933' | '#47ff9a' | '#2ee881' | '#14ce67';
    status: number;
    originalStatus: number;
    timestamp: number;
    icon: IIconData;
}

export interface IChartEdit {
    date: number;
    editorId: string;
    editor: string;
    messageTimestamp: number;
    fieldName: string;
    previousValue: string | number | Array<string> | Array<object>;
    currentValue: string | number | Array<string> | Array<object>;
}

export interface IIconData {
    path: string;
    legendXOffset: number;
    legendYOffset: number;
    targetXOffset: number;
    targetYOffset: number;
}

export interface IRawData {
    chartType: number;
    message_type: number;
    timestamp: number;
    x: number;
    y: number;
}

export interface SettingsOption {
    id: number;
    name: string;
}

interface Selection {
    //eye: string
    //id: number
    points: any[];
    lasso: Lasso;
}

export interface Group {
    id: ID;
    eye: string;
    // text: string
    position: XY;
    color: string;
    other?: Object;

    // nothing -> editing -> lasso -> selection
    editing: boolean;
    lasso?: Lasso;
    selection?: Selection;
}

export interface Lasso {
    // active: boolean
    // eye: string

    xFrom: number;
    xTo: number;

    yFrom: number;
    yTo: number;
}

export interface IFixationPoint {
    x: number;
    y: number;
    color: string;
}
export interface ITargetData {
    xCoor: number;
    yCoor: number;
    type: 'OS' | 'OD';
    tempFrame?: any;

    srt: number; // this is being switched: srt or tat, temp behavior, gotta see what direction is chosen
    actualSrt: number;
    actualTat: number;
    actualDelta: number;

    colorRect: string; //'#e2584d' | '#ff9933' | '#47ff9a' | '#2ee881' | '#14ce67';
    status: number;
    originalStatus: number;
    timestamp: number;
    icon: IIconData;
}

export interface PCParams {
    target: Target;
    status: number;
    originalStatus: number;
    id: number;
    makeSeen: (id: number) => void;
    makeUnseen: (id: number) => void;
    makeNoData: (id: number) => void;
}

export interface PSParams {
    // popupRect: Rect
    target: Target;
    clean: boolean;
    onClick: () => void;
}

export interface PLParams {
    target: Target;
    items: string[];
}
// interface end


export const instantColor: ColorGroup[] = [
    ['< 500', (x) => x < 500, '#2d9cdb'],
    ['500-600', (x) => x < 600, '#88c0df'],
    ['600-700', (x) => x < 700, '#81c53d'],
    ['700-800', (x) => x < 800, '#85ea85'],
    ['800-900', (x) => x < 900, '#fbf47d'],
    ['900-1100', (x) => x < 1100, '#f2c94c'],
    ['1100-1300', (x) => x < 1300, '#f2994a'],
    ['> 1300', (_x) => true, '#eb5757'],
];
const colorGroup: ColorGroup[] = [
    ['< 200', (x) => x < 200, '#2d9cdb'],
    ['200-300', (x) => x < 300, '#88c0df'],
    ['300-400', (x) => x < 400, '#81c53d'],
    ['400-500', (x) => x < 500, '#85ea85'],
    ['500-600', (x) => x < 600, '#fbf47d'],
    ['600-800', (x) => x < 800, '#f2c94c'],
    ['800-1000', (x) => x < 1000, '#f2994a'],
    ['> 1000', (_x) => true, '#eb5757'],
];

export const initSelected: any = {
    Training: 'OFF',
    Onset: 'Linear 3500ms',
    Flicker: 'OFF',
    Background: [127, 127, 127],
    Stimulus: [255, 255, 255],
    'Additional waiting': 400,
    'Green Dot': 'Classic',
    'Animation color': 'OFF',
    'Animation movement': 'OFF',
    'Animation size': 'OFF',
    'Animation reading': 'OFF',
};

export const getSelected = (arr: any) => {
    if (!arr) return initSelected;
    return {
        Training: arr.training ? 'ON' : 'OFF',
        Onset: (arr.onsetType || '') + ' ' + (arr.onsetTime || 0) + 'ms',
        Flicker: arr.stFlickerStatus ? arr.stFlickerFrequency : 'OFF',
        Background: [arr.bgr || 0, arr.bgg || 0, arr.bgb || 0],
        Stimulus: [arr.str || 0, arr.stg || 0, arr.stb || 0],
        'Additional waiting': arr.onsetAdditionalWaitingStatus ? arr.onsetAdditionalWaitingLength : 'OFF',
        'Green Dot': arr.greenDotAnimationStatus ? 'Concentric' : 'Classic',
        'Animation color': arr.color ? 'ON' : 'OFF',
        'Animation movement': arr.movement ? 'ON' : 'OFF',
        'Animation size': arr.size ? 'ON' : 'OFF',
        'Animation reading': arr.reading ? 'ON' : 'OFF',
    };
};

export const getProps = (data: any) => {
    console.log(data);
    let Onset = data.onset.onsetType;
    if (data.onset.onsetTime[Onset]) Onset += ' ' + data.onset.onsetTime[Onset] + 'ms';

    let Flicker = data.stimulusFlicker.status || 'OFF';
    if (Flicker !== 'OFF') Flicker += ' ' + data.stimulusFlicker.frequency;

    const selected = {
        Training: data.training ? 'ON' : 'OFF',
        Onset,
        Flicker,
        Background: Object.keys(data.bgColor).map((k) => data.bgColor[k]),
        Stimulus: Object.keys(data.stimulusColor).map((k) => data.stimulusColor[k]),
        'Additional waiting': 400,
        'Green Dot': data.greenDot + (data.greenDot === 'Concentric' ? ' ' + data.dotValue : ''),
        'Animation color': data.color ? 'ON' : 'OFF',
        'Animation movement': data.movement ? 'ON' : 'OFF',
        'Animation size': data.size ? 'ON' : 'OFF',
        'Animation reading': data.reading ? 'ON' : 'OFF',
    };

    return {
        colorGroups: data.onset.onsetType === 'Instant' ? colorGroup : instantColor,
        selected,
    };
};

export const xIcon = {
    href: '/frontend/src/assets/images/diagram-icons/eye-closed.svg',
    scale: '1.4, 1.4',
    path: 'M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z',
    legendXOffset: 27,
    legendYOffset: 3,
    targetXOffset: 9 + 2,
    targetYOffset: 9 + 2,
};

export const checkMarkIcon = {
    href: '/frontend/src/assets/images/diagram-icons/eye-open.svg',
    scale: '1.4, 1.4',
    path: 'M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z',
    legendXOffset: 27,
    legendYOffset: 3,
    targetXOffset: 9 + 2,
    targetYOffset: 8 + 3,
};

export const COLOR_UNSEEN = '#c0392b';
export const COLOR_MISSING_DATA = '#808e9b';
