// @ts-nocheck
export const USE_FAKE_DATA = false;
// export const USE_FAKE_DATA = true

export const OD = 0;
export const OS = 1;
export const TRAIN_OD = 10;
export const TRAIN_OS = 11;

export const EYE_OD = OD;
export const EYE_OS = OS;

// d is arbitrary but consistant distance between logmar points
// const LOGMAR_DATA = [
//   { v: -0.3, d: 0 },
//   { v: -0.2, d: 106 },
//   { v: -0.1, d: 136 },
//   { v: 0.0, d: 173 },
//   { v: 0.1, d: 221 },
//   { v: 0.2, d: 272 },
//   { v: 0.3, d: 347 },
//   { v: 0.4, d: 434 },
//   { v: 0.5, d: 582 },
//   { v: 0.6, d: 685 },
//   { v: 0.7, d: 877 },
//   { v: 0.8, d: 1099 },
//   { v: 0.9, d: 1384 },
//   // { v: 1.0, d: 2582 },
//   { v: 1.0, d: 1750 },
// ]

// const LOGMAR_DATA = [
//   { v: -0.3, d: 0 },
//   { v: -0.2, d: 107 },
//   { v: -0.1, d: 137 },
//   { v: 0.0, d: 176 },
//   { v: 0.1, d: 220 },
//   { v: 0.2, d: 277 },
//   { v: 0.3, d: 351 },
//   { v: 0.4, d: 435 },
//   { v: 0.5, d: 587 },
//   { v: 0.6, d: 690 },
//   { v: 0.7, d: 880 },
//   { v: 0.8, d: 1100 },
//   { v: 0.9, d: 1395 },
//   { v: 1.0, d: 1744 },
// ]
// converts :v to :d
export const LOGMAR_TO_VIRTUAL = (x: number) => {
    // let a = 101.260927
    // let r = 3.134468
    // let a = 171.236196
    // let r = 2.325815

    let a = 173.669978;
    let r = 2.311546;
    return a * Math.exp(r * x);
};
const LOGMAR_VALUES = [-0.3, -0.2, -0.1, 0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0];
const LOGMAR_DATA = LOGMAR_VALUES.map((v) => {
    let d = LOGMAR_TO_VIRTUAL(v);
    return { v, d };
});
//   { v: -0.3, d: 0 },
//   { v: -0.2, d: 107 },
//   { v: -0.1, d: 137 },
//   { v: 0.0, d: 176 },
//   { v: 0.1, d: 220 },
//   { v: 0.2, d: 277 },
//   { v: 0.3, d: 351 },
//   { v: 0.4, d: 435 },
//   { v: 0.5, d: 587 },
//   { v: 0.6, d: 690 },
//   { v: 0.7, d: 880 },
//   { v: 0.8, d: 1100 },
//   { v: 0.9, d: 1395 },
//   { v: 1.0, d: 1744 },
// ]
export const LOGMAR_VIRTUAL_DOMAIN = [
    0,
    1744,
    // 1750,
    // 2582,
];
const NO_TICKS = [-0.2, -0.1, 0.1].map((x) => x.toString());
export const LOGMAR_TICKS = LOGMAR_DATA.map((x) => {
    return { value: x.d, text: x.v.toString() };
}).filter((x) => !NO_TICKS.includes(x.text));

/*
107
137
176
220
277
351
435
587
690
880
1100
1395
1744
*/

// export const MIN_CONTRAST = 0.001

export const I_ACUITY = 0;
export const I_CONTRAST = 1;
export const I_CONTRAST2 = 2;
export const I_FREQ = 3; // Protocol.ts modifies incoming stuff
