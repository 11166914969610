import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { PatientSearchComponent } from '../patient-search/patient-search.component';
import { PatientsOverviewComponent } from '../patients-overview/patients-overview.component';

@Component({
    selector: 'dashboard-staff',
    template: require('./dashboard-staff.component.html'),
    styles: [require('./dashboard-staff.component.scss')],
})
export class DashboardStaffComponent implements AfterViewInit, OnDestroy {
    @ViewChild(PatientsOverviewComponent) private patientsOverviewComponent: PatientsOverviewComponent;
    @ViewChild(PatientSearchComponent) private patientSearchComponent: PatientSearchComponent;
    private examinations: BehaviorSubject<ExaminationFrontend[]>;
    private subscriptions: Subscription[];
    private isSearchPerformed: boolean = false;
    constructor(private authService: AuthenticationService, private examinationService: ExaminationService) {
        this.examinations = new BehaviorSubject<ExaminationFrontend[]>([]);
        this.subscriptions = [];
    }
    ngAfterViewInit(): void {
        this.subscriptions.push(
            this.patientSearchComponent.searchTermsSource.subscribe(terms => {
                let savedTerms: any = localStorage.getItem('searchTerms');
                if (savedTerms) {
                    savedTerms = JSON.parse(savedTerms);
                    if (terms.id !== savedTerms.id || terms.dateOfBirth !== savedTerms.dateOfBirth || terms.ssn !== savedTerms.ssn) {
                        localStorage.setItem('searchTerms', JSON.stringify(terms));
                        this.patientsOverviewComponent.paginatorComponent.pageSource.next(1);
                    } else {
                        const savedPage: number = Number.parseInt(localStorage.getItem('savedPage'));
                        this.patientsOverviewComponent.paginatorComponent.pageSource.next(savedPage);
                    }
                } else {
                    localStorage.setItem('searchTerms', JSON.stringify(terms));
                    this.patientsOverviewComponent.paginatorComponent.pageSource.next(1);
                }
            })
        );
        this.subscriptions.push(
            this.patientsOverviewComponent.paginatorComponent.pageSource
                .pipe(
                    switchMap(page => {
                        const terms: { id: string; dateOfBirth: Date; ssn: string } = this.patientSearchComponent.searchTermsSource.value;
                        setTimeout(() => {
                            this.isSearchPerformed = !!terms.id || !!terms.dateOfBirth || !!terms.ssn;
                        }, 0);
                        return this.examinationService.search({
                            registerID: terms.id,
                            dateOfBirth: terms.dateOfBirth?.toDateString(),
                            ssn: terms.ssn,
                            date: '',
                            page: page,
                        });
                    }),
                    map((patientsSearchResult: { examinations: ExaminationFrontend[]; examinationsCnt: number }) => {
                        patientsSearchResult.examinations.sort((e1, e2) => {
                            if (moment(e1.createdAt, 'YYMMDDHHmmss') > moment(e2.createdAt, 'YYMMDDHHmmss')) {
                                return -1;
                            } else if (moment(e1.createdAt, 'YYMMDDHHmmss') < moment(e2.createdAt, 'YYMMDDHHmmss')) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                        return patientsSearchResult;
                    })
                )
                .subscribe((patientsSearchResult: { examinations: ExaminationFrontend[]; examinationsCnt: number }) => {
                    this.patientsOverviewComponent.paginatorComponent.totalItemsCnt = patientsSearchResult.examinationsCnt;
                    this.examinations.next(patientsSearchResult.examinations);
                })
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
