/* https://github.com/umpirsky/country-list/blob/master/data/en/country.json */
export const LANG_EN_NAME = 'en';

const general = {
    Normal: 'Normal',
    Abnormal: 'Abnormal',
    'Can not comprehend': 'Can not comprehend',
    OD: 'OD',
    OS: 'OS',
    OU: 'OU',
    ALT: 'ALT',
    open: 'Open',
    close: 'Close',
    ok: 'OK',
    Yes: 'Yes',
    No: 'No',
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    save: 'Save',
    submit: 'Submit',
    upload: 'Upload',
    delete: 'Delete',
    remove: 'Remove',
    'cancel return': 'Cancel / Return',
    clear: 'Clear',
    'add employer': 'Add Employer',
    'go back': 'Go Back',
    'show report': 'Show Report',
    'table report': 'Table Report',
    'view results': 'View Results',
    Start: 'Start',
    End: 'End',
    'In progress': 'In progress',
    Pause: 'Pause',
    Stop: 'Stop',
    'Redo test': 'Redo Test',
    'manual input': 'Manual Input',
    'Send to doctor': 'Send to Doctor',
    'Send to tech staff': 'Send to Tech Staff',
    'lift up': 'HAPLO Lift Up',
    'log out': 'Log Out',
    'change theme': 'Change Theme',
    'go to examination': 'Go to Examination',
    compare: 'Compare',
    'back table': 'Back to Table Report',
    'page not found': 'Page Not Found',
    'upload emr': 'Upload to EMR',
    'Change Language': 'Change Language',
    patient: 'Patient',
    'textarea placeholder': 'Type here...',
    about: 'About HUB',
    statistics: 'Statistics',
    report: 'Report',
    'back to bulbihub examination overview': 'Back to BulbiHUB Examination Overview',
    user: 'User',
    Action: 'Action',
    read: 'Read',
    actions: 'Actions',
    'admin dashboard': 'Admin Dashboard',
    'admin user': 'Add/Edit User',
    'admin users-list': 'Users List',
    'admin actions': 'Actions Log',
    'admin settings': 'Settings',
    'add template diagnosis': 'Add Template Diagnosis',
    'template diagnoses': 'Template Diagnoses',
    cancel: 'Cancel',
    loading: 'Loading',
    reports: 'Reports',
    'minute(s)': 'minute(s)',
    'hour(s)': 'hour(s)',
    'day(s)': 'day(s)',
    'week(s)': 'week(s)',
    'month(s)': 'month(s)',
    'year(s)': 'year(s)',
    slider: 'Slider',
    ready: 'Ready',
    timestamp: 'Timestamp',
    background: 'Background',
    PDF: 'PDF',
    CSV: 'CSV',
    export: 'Export',
    property: 'Property',
    username: 'Username',
    'doctors speciality': 'Doctors Speciality',
    'doctors language': 'Doctors Language',
    'examination type': 'Examination Type',
    'doctors licence': 'Doctors Licence',
};

const validation_messages = {
    'id required validation': `Please input patient's ID.`,
    'first name required validation': `Please input user's first name.`,
    'last name required validation': `Please input user's last name.`,
    'email required validation': `Please input user's email.`,
    'email pattern validation': 'Invalid email address.',
    'employer name required validation': `Please input employer's company name.`,
    'phone required validation': `Please input user's phone number.`,
    'phone pattern validation': 'Invalid phone number.',
    'role required validation': `Please select user's role.`,
    'username required validation': 'Username is required',
    'password required validation': 'Password is required',
    'only letters': 'Only letters are allowed.',
    'user update successful': 'User successfully updated.',
    'user create successful': 'User successfully created.',
    'user create update unsuccessful': 'Something went wrong please check your input and try again.',
    'upload results successful': 'All results were uploaded successfully.',
    'upload results unsuccessful': 'Something went wrong while uploading the results. Please try again.',
    'delete results successful': ' All results were deleted successfully.',
    'delete results unsuccessful': 'Something went wrong while deleting the results. Please try again.',
    'send examination successful': 'The examination was sent successfully.',
    'send examination unsuccessful': 'Something went wrong while sending the examination. Please try again.',
    'duplicate email': 'A user with this email already exists in database.',
    'image deleted success': 'The image was deleted successfully.',
    'image deleted error': 'An error has occured while deleting the image.',
    'chart deleted success': 'The chart was deleted successfully.',
    'chart deleted error': 'An error has occured while deleting the chart.',
    'max length validation': `The input is too long.`,
    'file reading unsuccessful': 'Something went wrong while reading the results from the HDD. Please reload the page.',
    'image slider deleted success': 'The image slider was deleted successfully.',
    'image slider deleted error': 'An error has occured while deleting the image slider.',
    'chart transfer unsuccessful': 'Something went wrong with the Haplo connection.',
    'id exists validation': 'The registerID already exists please choose another one.',
    'haplo connection button error': 'Please check the Haplo connection.',
    'haplo lens not ready': 'Please mark the Haplo lenses as ready.',
    'chart stopped success': 'The Haplo test has been successfully stopped.',
    'chart stopped error': 'Something went wrong while stopping the Haplo test.',
    'ai analysis error': 'Something went wrong during the AI analysis.',
};

const charts = {
    Time: 'Time',
    Speed: 'Speed',
    Downtime: 'Downtime',
    Offline: 'Offline',
    'Pupil diameter break point avg. OS': 'Break point avg. OS',
    'Pupil diameter break point avg. OD': 'Break point avg. OD',
    'Pupil diameter start point avg. OS': 'Start point avg. OS',
    'Pupil diameter start point avg. OD': 'Start point avg. OD',
    'Recovery minimum': 'Recovery minimum',
    'Target distance': 'Target distance',
    'Pupil diameter': 'Pupil diameter',
    'Trials vergence': 'Trials vergence',
    'Trials pupils': 'Trials pupils',
    NPC: 'NPC',
    'NPC minimum': 'NPC minimum',
    Target: 'Target',
    'Missing Data': 'Missing Data',
    Unseen: 'Unseen',
    Seen: 'Seen',
    value: 'value',
    'OD Seen/Unseen': 'OD Seen/Unseen',
    'OS Seen/Unseen': 'OS Seen/Unseen',
    'Binocular Seen/Unseen': 'Binocular Seen/Unseen',
    Binocular: 'Binocular',
    Saccade: 'Saccade',
    'Peak Velocity': 'Peak Velocity',
    'Horizontal Saccades': 'Horizontal Saccades',
    'Left to Right': 'Left to Right',
    'Right to left': 'Right to left',
    'Vertical Saccades': 'Vertical Saccades',
    Downwards: 'Downwards',
    Upwards: 'Upwards',
};

const tests = {
    test: 'Test',
    diagnoses: 'Diagnoses',
    'Basic visual assessment': 'Basic Visual Assessment',
    'Structural Assessment (SA)': 'Structural Assessment (SA)',
    'Visual Function Assessment (VFA)': 'Visual Function Assessment (VFA)',
    'Binocular Vision Assessment (BVA)': 'Binocular Vision Assessment (BVA)',
    'Additional saccadio tests': 'Additional Saccadio Tests',
    Lensometry: 'Lensometry',
    'Ocular Refraction': 'Ocular Refraction',
    Tonometry: 'Tonometry',
    Keratometry: 'Keratometry',
    Pachymetry: 'Pachymetry',
    'Anterior and Posterior segment evaluation': 'Anterior and Posterior Segment Evaluation',
    'Anterior chamber angle': 'Anterior Chamber Angle',
    ACA: 'ACA',
    'IPD, Contrast, Glare acuity and Opacity Index': 'IPD-Contrast-Glare Acuity-Opacity Index',
    'Visual Field evaluation': 'Visual Field Evaluation',
    'Amsler grid test': 'Amsler Grid Test',
    'Colour vision': 'Colour Vision',
    'Accommodation test': 'Accommodation Test',
    'Sensory evaluation': 'Sensory Evaluation',
    'Cover test': 'Cover Test',
    'Smooth vergence assessment': 'Smooth Vergence Assessment',
    'Pupil assessment': 'Pupil Assessment',
    'Smooth pursuit': 'Smooth Pursuit',
    'Saccade test': 'Saccade Test',
    'Dry Eye evaluation': 'Dry Eye Evaluation',
    'Nystagmus test': 'Nystagmus Test',
    'Ptosis and proptosis evaluation': 'Ptosis and Proptosis Evaluation',
    'with glasses': 'With Glasses',
    'without glasses': 'Without Glasses',
    'normal grid': 'Normal Grid',
    'circle grid': 'Circle Grid',
    'sector grid': 'Sector Grid',
    'testing method': 'Testing Method',
    diagnosis: 'Diagnosis',
    select: 'Select',
    remarks: 'Remarks',
    'view diagram': 'View Diagram',
    diopter: 'Diopter',
    degree: 'Degree',
    degrees: 'Degrees',
    decimal: 'Decimal',
    axis: 'Axis',
    Accommodation: 'Accommodation',
    Distortion: 'Distortion',
    Scotoma: 'Scotoma',
    'Anterior segment': 'Anterior Segment',
    'Posterior segment': 'Posterior Segment',
    'Show AI results': 'Show AI Results',
    'Back to Anterior/Posterior': 'Back to Anterior/Posterior',
    'Ishihara plates': 'Ishihara Plates',
    Ishihara: 'Ishihara',
    'No. of Plate': 'No. of Plate',
    'Normal person': 'Normal Person',
    'Person with Red-Green Deficiencies': 'Person with Red-Green Deficiencies',
    'Person with total Colour Blindness and Weakness': 'Person with Total Colour Blindness and Weakness',
    'Deutran defect': 'Deutran Defect',
    'Protan defect': 'Protan Defect',
    'RG defect': 'RG Defect',
    'Total color vision defect': 'Total Colour Vision Defect',
    'With red filter': 'With Red Filter',
    protan: 'Protan',
    duetan: 'Duetan',
    strong: 'Strong',
    mild: 'Mild',
    'Near point of Convergence': 'Near point of Convergence',
    recovery: 'Recovery',
    'Magnitude of deviation': 'Magnitude of Deviation',
    Horizontal: 'Horizontal',
    Vertical: 'Vertical',
    'Base in': 'Base In',
    'Base out': 'Base Out',
    'Base down': 'Base Down',
    'Base up': 'Base Up',
    PD: 'PD',
    eyelids: 'Eyelids',
    'Meibomian glands': 'Meibomian Glands',
    conjuctiva: 'Conjuctiva',
    palpebral: 'Palpebral',
    bulbar: 'Bulbar',
    'Tear Film': 'Tear Film',
    'Inter pupillary Distance': 'Inter Pupillary Distance',
    'Far pupillary distance': 'Far Pupillary Distance',
    'Near pupillary distance': 'Near Pupillary Distance',
    'Contrast and Glare Acuity': 'Contrast and Glare Acuity',
    'BCVA Distance': 'BCVA Distance',
    'Contrast acuity': 'Contrast Acuity',
    'Glare acuity': 'Glare Acuity',
    'opacity index': 'Opacity Index',
    central: 'Central',
    peripheral: 'Peripheral',
    'Previous Glass Prescription': 'Previous Glass Prescription',
    eye: 'Eye',
    sphere: 'Sphere',
    cylinder: 'Cylinder',
    SEV: 'SE',
    ADD: 'ADD',
    'Near Sphere': 'Near Sphere',
    prism: 'Prism',
    base: 'base',
    'UV Transmittance': 'UV Transmittance',
    'Confidence Index': 'Confidence Index',
    'Lens Type': 'Lens Type',
    'Glass Status': 'Glass Status',
    'Single vision': 'Single vision',
    Bifocals: 'Bifocals',
    Progressives: 'Progressives',
    'Which Eye': 'Which Eye',
    type: 'Type',
    Rotatory: 'Rotatory',
    'See-Saw': 'See-Saw',
    Wandering: 'Wandering',
    Jerk: 'Jerk',
    Pendular: 'Pendular',
    pattern: 'Pattern',
    Torsional: 'Torsional',
    Frequency: 'Frequency',
    Low: 'Low',
    Moderate: 'Moderate',
    High: 'High',
    Amplitude: 'Amplitude',
    Fine: 'Fine',
    Large: 'Large',
    Medium: 'Medium',
    Direction: 'Direction',
    Down: 'Down',
    Left: 'Left',
    Right: 'Right',
    Up: 'Up',
    Symmetry: 'Symmetry',
    'Face Turn': 'Face Turn',
    'Head Tilt': 'Head Tilt',
    'Head Nodding': 'Head Nodding',
    'Dampening on Convergence': 'Dampening on Convergence',
    'Visual Acuity': 'Visual Acuity',
    'Without Glass': 'Without Glass',
    'With Glass': 'With Glass',
    'Contact Lens': 'Contact Lens',
    DV: 'DV',
    NV: 'NV',
    WD: 'WD',
    autorefraction: 'Autorefraction',
    DSph: 'DSph',
    DCyl: 'DCyl',
    BCVA: 'BCVA',
    acceptance: 'Acceptance',
    duochrome: 'Duochrome',
    Response: 'Response',
    fogging: 'Fogging',
    Balanced: 'Balanced',
    'Red better': 'Red better',
    'Green better': 'Green better',
    Add: 'Add',
    preference: 'Preference',
    'Prefers new Rx': 'Prefers new Rx',
    'Prefers old Rx': 'Prefers old Rx',
    'Prefers Plano': 'Prefers Plano',
    'Final glass prescription': 'Final Glass Prescription',
    near: 'Near',
    distance: 'Distance',
    'Average central corneal thickness (microns)': 'Average central corneal thickness (microns)',
    severity: 'Severity',
    'Palpebral Fissure Height': 'Palpebral Fissure Height',
    MRO: 'MRO',
    proptosis: 'Proptosis',
    'Chin Elevation': 'Chin Elevation',
    'Facial Assymetry': 'Facial Assymetry',
    Severe: 'Severe',
    size: 'Size',
    'Reaction to Light': 'Reaction to Light',
    RAPD: 'RAPD',
    'Pupil Morphology': 'Pupil Morphology',
    Brisk: 'Brisk',
    'Not reacting': 'Not reacting',
    Sluggish: 'Sluggish',
    'Ill sustained': 'Ill sustained',
    'Slow tonic': 'Slow tonic',
    Present: 'Present',
    Absent: 'Absent',
    'Sphincter atrophy': 'Sphincter atrophy',
    'Seclusio pupillae': 'Seclusio pupillae',
    'Occlusio pupillae': 'Occlusio pupillae',
    Polycoria: 'Polycoria',
    'Sphincter tear': 'Sphincter tear',
    'Ability (Scoring)': 'Ability (Scoring)',
    'Accuracy (Scoring)': 'Accuracy (Scoring)',
    'Head and Body Movement': 'Head and Body Movement',
    'Completes less than two round trips': 'Completes less than two round trips',
    'Completes two round trips': 'Completes two round trips',
    'Completes three round trips': 'Completes three round trips',
    'Completes four round trips Completes five round trips': 'Completes four round trips Completes five round trips',
    'Large over - or undershooting is noted one or more times': 'Large over - or undershooting is noted one or more times',
    'Moderate over - or undershooting noted one or more times': 'Moderate over - or undershooting noted one or more times',
    'Constant slight over - or undershooting noted (greater than 50% of the time)': 'Constant slight over - or undershooting noted (greater than 50% of the time)',
    'Intermittent slight over or undershooting noted (less than 50% of the time) No over - or undershooting noted':
        'Intermittent slight over or undershooting noted (less than 50% of the time) No over - or undershooting noted',
    'Large movement or the head (body) or any time': 'Large movement or the head (body) or any time',
    'Moderate movement of the head (body) of any time': 'Moderate movement of the head (body) of any time',
    'Slight movement of the head (body) (less than 50% of the time)': 'Slight movement of the head (body) (less than 50% of the time)',
    'Intermittent slight over - or undershooting noted (less than 50% of the time)': 'Intermittent slight over - or undershooting noted (less than 50% of the time)',
    'No movement of the head (body)': 'No movement of the head (body)',
    'OD mm of Hg': 'OD mm of Hg',
    'OS mm of Hg': 'OS mm of Hg',
    'Distance worth four dot test': 'Distance worth four dot test',
    'Near worth four dot test': 'Near worth four dot test',
    stereopsis: 'Stereopsis',
    Diplopia: 'Diplopia',
    Fusion: 'Fusion',
    'Left Suppression': 'Left Suppression',
    'Right Suppression': 'Right Suppression',
    'Alternate Suppression': 'Alternate Suppression',
    'Unreliable Response': 'Unreliable Response',
    eyelidsEyeLashesAndLacrimalSystem: 'Eyelids, Eyelashes and Lacrimal System',
    upperEyelids: 'Upper Eyelids',
    lowerEyelids: 'Lower Eyelids',
    upperEyelashes: 'Upper Eyelashes',
    lowerEyelashes: 'Lower Eyelashes',
    lacrimalSystem: 'Lacrimal System',
    conjunctivaLimbusAndSclera: 'Conjuctiva, Limbus and Sclera',
    conjunctiva: 'Conjunctiva',
    limbus: 'Limbus',
    sclera: 'Sclera',
    corneaAndAnteriorChamber: 'Cornea and Anterior Chamber',
    cornea: 'Cornea',
    anteriorChamberDepth: 'Anterior Chamber Depth',
    vanHerrickGrading: 'Van Herrick Grading',
    acCellsFlare: 'AC Cells/Flare',
    acFindings: 'AC Findings',
    irisAndLens: 'Iris and Lens',
    iris: 'Iris',
    lensStatus: 'Lens Status',
    cataractGrading: 'Cataract Grading (PSC, NS and CC)',
    otherCataractTypes: 'Other Cataract Types',
    'Baggy Lid': 'Baggy Lid',
    Blepharitis: 'Blepharitis',
    Chalazion: 'Chalazion',
    Ectropion: 'Ectropion',
    Entropion: 'Entropion',
    Lagophthalmos: 'Lagophthalmos',
    'Lid Edema': 'Lid Edema',
    'Lid Mass': 'Lid Mass',
    Stye: 'Stye',
    Swelling: 'Swelling',
    Disitichiasis: 'Disitichiasis',
    Madarosis: 'Madarosis',
    Meibomitis: 'Meibomitis',
    Poliosis: 'Poliosis',
    Trichiasis: 'Trichiasis',
    Dacryocystitis: 'Dacryocystitis',
    'Lacrimal Fistula': 'Lacrimal Fistula',
    'Lacrimal Sac Mass': 'Lacrimal Sac Mass',
    'Lacrimal Sac Swelling': 'Lacrimal Sac Swelling',
    'ROPLAS Negative': 'ROPLAS Negative',
    'ROPLAS Positive': 'ROPLAS Positive',
    'Bitot Spots': 'Bitot Spots',
    Concretions: 'Concretions',
    Congestion: 'Congestion',
    Discharge: 'Discharge',
    Edema: 'Edema',
    Follicles: 'Follicles',
    'Foreign Body': 'Foreign Body',
    Papillae: 'Papillae',
    Pinguecula: 'Pinguecula',
    Pterygium: 'Pterygium',
    'Subconjunctival Hemorrhage': 'Subconjunctival Hemorrhage',
    Dermoid: 'Dermoid',
    'Herberts pits': `Herbert's pits`,
    Ischemia: 'Ischemia',
    Mass: 'Mass',
    Pannus: 'Pannus',
    Scarring: 'Scarring',
    'Trantas Dots': 'Trantas Dots',
    Vascularisation: 'Vascularisation',
    'Acute Hydrops': 'Acute Hydrops',
    Arcus: 'Arcus',
    'Band Shaped Keratopathy': 'Band Shaped Keratopathy',
    'Corneal Bullae': 'Corneal Bullae',
    'DM folds': 'DM Folds',
    Dystrophy: 'Dystrophy',
    Ectasia: 'Ectasia',
    'Epithelial defect': 'Epithelial Defect',
    'Fleischers ring': `Fleischer's Ring`,
    'Fluorescein stain': 'Fluorescein Stain',
    'Foreign body': 'Foreign Body',
    Guttata: 'Guttata',
    'Haabs striae': `Haab's Striae`,
    Haze: 'Haze',
    'Keratic precipitates': 'Keratic Precipitates',
    'Krukenberg spindles': 'Krukenberg Spindles',
    Leucoma: 'Leucoma',
    'Loose/protruding sutures': 'Loose/Protruding Sutures',
    Megalocornea: 'Megalocornea',
    Microcornea: 'Microcornea',
    Opacity: 'Opacity',
    'Post DSEK': 'Post DSEK',
    'Post LASIK': 'Post LASIK',
    'Post PK': 'Post PK',
    'Post RK': 'Post RK',
    'Prominent corneal nerves': 'Prominent Corneal Nerves',
    SPKs: 'SPKs',
    Scar: 'Scar',
    'Sutures intact': 'Sutures Intact',
    Thinning: 'Thinning',
    'Total corneal opacity': 'Total Corneal Opacity',
    Ulceration: 'Ulceration',
    Vascularization: 'Vascularization',
    'Vogts striae': `Vogt's Striae`,
    Deep: 'Deep',
    Flat: 'Flat',
    Irregular: 'Irregular',
    Shallow: 'Shallow',
    Slit: 'Slit',
    'AC Cells 0': 'AC Cells 0',
    'AC Cells 1': 'AC Cells 1',
    'AC Cells 2': 'AC Cells 2',
    'AC Cells 3': 'AC Cells 3',
    'AC Cells 4': 'AC Cells 4',
    'AC Flare 0': 'AC Flare 0',
    'AC Flare 1': 'AC Flare 1',
    'AC Flare 2': 'AC Flare 2',
    'AC Flare 3': 'AC Flare 3',
    'AC Flare 4': 'AC Flare 4',
    Trace: 'Trace',
    Fibrin: 'Fibrin',
    Hyphema: 'Hyphema',
    Hypopyon: 'Hypopyon',
    'Inverse Hypopyon': 'Inverse Hypopyon',
    Aniridia: 'Aniridia',
    Coloboma: 'Coloboma',
    Corectopia: 'Corectopia',
    Heterochromia: 'Heterochromia',
    Iridodonesis: 'Iridodonesis',
    'Iris pigments': 'Iris Pigments',
    Iridodialysis: 'Iridodialysis',
    'Koeppe nodules': 'Koeppe nodules',
    'Peripheral anterior synechiae': 'Peripheral Anterior Synechiae',
    'Peripheral iridectomy': 'Peripheral Iridectomy',
    'Posterior synechiae': 'Posterior Synechiae',
    Pseudoexfoliation: 'Pseudoexfoliation',
    Rubeosis: 'Rubeosis',
    'Transillumination defects': 'Transillumination Defects',
    'YAG PI patent': 'YAG PI patent',
    'YAG PI not patent': 'YAG PI not patent',
    Aphakia: 'Aphakia',
    Dislocation: 'Dislocation',
    Microphakia: 'Microphakia',
    Microspherophakia: 'Microspherophakia',
    'Pseudophakia with PCO': 'Pseudophakia with PCO',
    'Pseudophakia without PCO': 'Pseudophakia without PCO',
    Spherophakia: 'Spherophakia',
    Subluxation: 'Subluxation',
    'NS 1': 'NS 1',
    'NS 2': 'NS 2',
    'NS 3': 'NS 3',
    'NS 4': 'NS 4',
    'PSC 1': 'PSC 1',
    'PSC 2': 'PSC 2',
    'PSC 3': 'PSC 3',
    'PSC 4': 'PSC 4',
    'Anterior cortical cataract': 'Anterior Cortical Cataract',
    'Posterior cortical cataract': 'Posterior Cortical Cataract',
    'Anterior polar cataract': 'Anterior Polar Cataract',
    'Complicated cataract': 'Complicated Cataract',
    'Congenital cataract': 'Congenital Cataract',
    'Hypermature cataract': 'Hypermature Cataract',
    'Immature cataract': 'Immature Cataract',
    'Posterior polar cataract': 'Posterior Polar Cataract',
    'Total cataract': 'Total Cataract',
    'Traumatic cataract': 'Traumatic Cataract',
    'No improvement with Rx': 'No improvement with Rx',
    'macula images': 'Macula Images',
    triggers: 'Triggers',
    observations: 'Observations',
};

const user_patient = {
    id: 'ID',
    'patient id': 'Patient ID',
    'first name': 'First Name',
    'last name': 'Last Name',
    ssn: 'Social Security Number (SSN)',
    'ssn short': 'SSN',
    gender: 'Gender',
    'birth date': 'Birth date',
    email: 'E-mail',
    phone: 'Phone',
    employer: 'Employer',
    name: 'Name',
    street: 'Street',
    'postal code': 'Postal Code',
    city: 'City',
    country: 'Country',
    role: 'Role',
    occupation: 'Occupation',
    'marital status': 'Marital Status',
    'main complaints': 'Main Complaints',
    'insurance company': 'Insurance Company',
    'insurance plan': 'Insurance Plan',
    'past ocular information': 'Past Ocular Information',
    'past medical information': 'Past Medical Information',
    'family information': 'Family Information',
    'birth information': 'Birth Information',
    'personal information': 'Personal Information',
    'allergy information': 'Allergy Information',
    other: 'Other',
    ADMIN: 'Admin',
    STAFF: 'Staff',
    DOCTOR: 'Doctor',
    DISABLED: 'Disabled',
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
    Married: 'Married',
    Single: 'Single',
    Regular: 'Regular',
    'Change of glasses': 'Change of Glasses',
    'Second opinion for cataract': 'Second Opinion for Cataract',
    'Second opinion for glaucoma': 'Second Opinion for Glaucoma',
    'Second opinion for retina': 'Second Opinion for Retina',
    'Second opinion for squint': 'Second Opinion for Squint',
    'Second opinion for neuro ophthalmology': 'Second Opinion for Neuro Ophthalmology',
    'Second opinion for oculoplasty': 'Second Opinion for Oculoplasty',
    'Second opinion for uvea': 'Second Opinion for Uvea',
    'Second opinion for Cornea': 'Second Opinion for Cornea',
    'Lasik opinion': 'Lasik Opinion',
    'ROP Screening': 'ROP Screening',
    'Follow Up': 'Follow Up',
    Others: 'Others',
    Nil: 'Nil',
    'Blurred distance': 'Blurred distance',
    'Blurred near': 'Blurred near',
    Squint: 'Squint',
    Headache: 'Headache',
    Redness: 'Redness',
    Watering: 'Watering',
    'Diminision of Vision Distance': 'Diminision of Vision Distance',
    'Diminision of Vision Near': 'Diminision of Vision Near',
    'Drooping of eyelid': 'Drooping of eyelid',
    'Shaking of eyeball': 'Shaking of eyeball',
    'Prominent eyes': 'Prominent eyes',
    'White reflex': 'White reflex',
    'Eye pain': 'Eye pain',
    Itching: 'Itching',
    Irritation: 'Irritation',
    'Dryness of eyes': 'Dryness of eyes',
    Cataract: 'Cataract',
    'Diabetic Retinopathy': 'Diabetic Retinopathy',
    Glaucoma: 'Glaucoma',
    Keratoconus: 'Keratoconus',
    Myopia: 'Myopia',
    RD: 'RD',
    pmhDM: 'DM', // modified
    'DM + HT': 'DM + HT',
    pmhHT: 'HT', // modified
    IHD: 'IHD',
    'Myopia/High refractive error': 'Myopia/High refractive error',
    'Diabetic retonipathy': 'Diabetic retonipathy',
    'Retinitis pigmentosa': 'Retinitis pigmentosa',
    'Macular Degeneration': 'Macular Degeneration',
    Caesarian: 'Caesarian',
    Forceps: 'Forceps',
    'Not aware of any': 'Not aware of any',
    Sulpha: 'Sulpha',
    Penicillin: 'Penicillin',
    Quinolones: 'Quinolones',
    'Dilatation drops T+': 'Dilatation drops T+',
    'Dilatation drops Cyclopentolate': 'Dilatation drops Cyclopentolate',
    'Dilatation drops Homide': 'Dilatation drops Homide',
    'Dilatation drops Phenylephrine': 'Dilatation drops Phenylephrine',
    'Dilatation drops Tropicamide': 'Dilatation drops Tropicamide',
    Anticonvulsants: 'Anticonvulsants',
    'ocular medication': 'Ocular Medication',
    'ocular investigation': 'Ocular Investigation',
    'systemic medication': 'Systemic Medication',
    'recent investigation': 'Recent Investigation',
    'haplo lens': 'Haplo Lens',
};

const components = {
    hello: 'Hello',
    'search results': 'Search results...',
    'bulbihub examination overview': 'BulbiHUB Examination overview',
    'patient search': 'Patient search',
    all: 'All',
    done: 'Done',
    examination: 'Examination',
    download: 'Download',
    day: 'Day',
    days: 'Days',
    'start date': 'Start Date',
    'end date': 'End Date',
    'server connection': `Server's connection`,
    connection: 'Connection',
    connections: 'Connections',
    'missing values': 'Missing Values',
    'update password': 'Update Password',
    'new password': 'New Password',
    'repeat password': 'Repeat Password',
    'not equal passwords': 'The two passwords are not equal. Please try again.',
    'saccadio examination suite': 'Saccadio Examination Suite',
    'test status rec': 'Test Status Rec',
    control: 'Control',
    'data quality': 'Data Quality',
    results: 'Results',
    'patient information': 'Patient Information',
    login: 'Login',
    password: 'Password',
    'forgot password': 'Forgot password?',
    'error email': 'The email address or password is invalid. Please try to log in again.',
    '(STAFF)': '%0 %1 (STAFF)',
    '(DOCTOR)': '%0 %1 (DOCTOR)',
    '(ADMIN)': '%0 %1 (ADMIN)',
    chat: 'Chat',
    'chat message placeholder': 'Type message...',
    'add user': 'Add User',
    users: 'Users',
    'add image': 'Add Images',
    'forgot password title': 'Forgot Password',
    'Do you want to remove this image?': 'Do you want to remove this image?',
    'Save changes?': 'Save changes?',
    'Are you sure you want to remove the selected result?': 'Are you sure you want to remove the selected result?',
    'Are you sure you want to remove the diagram?': 'Are you sure you want to remove the diagram?',
    'password update error': 'Password update error.',
    'password updated success': 'Password updated successfully.',
    error: 'Error.',
    'token invalid': 'Token is invalid.',
    'link expired': 'Link is expired. If you still want to restore password please click "Forgot password" on the Login page.',
    'Posterior segment evaluation AI results': 'Posterior segment evaluation AI results',
    'Disease / Indicator': 'Disease / Indicator',
    Prediction: 'Prediction',
    'Confidence Level': 'Confidence Level',
    'Referable DR': 'Referable DR',
    'DR Severity Scale': 'DR Severity Scale',
    DME: 'DME',
    AMD: 'AMD',
    'Myopic Maculopathy': 'Myopic Maculopathy',
    'Stroke / Arteriosclerosis': 'Stroke / Arteriosclerosis',
    'Glycosylated Hemoglobin': 'Glycosylated Hemoglobin',
    'Biological Age': 'Biological Age',
    'Valid only for 50-90 years': 'Valid only for 50-90 years',
    'diagnosis placeholder': 'Enter a diagnosis...',
    devices: 'Devices',
    localization: 'Localization',
    haplo: 'Haplo',
    database: 'Database',
    'haplo enabled': 'Enable HAPLO',
    host: 'Host',
    port: 'Port',
    'Do you want to remove this image slider?': 'Do you want to remove this image slider?',
};

const devices = {
    HAPLO: 'Haplo',
    NIDEK_AFC330: 'Nidek AFC330',
    NIDEK_ARK1S: 'Nidek ARK1S',
    NIDEK_LM7P: 'Nidek LM7P',
    NIDEK_NT530P: 'Nidek NT530P',
    FORUS_3NETHRA: 'Forus 3NETHRA',
    TOPCON_TRITON: 'Topcon TRITON',
    TOPCON_MAESTRO: 'Topcon MAESTRO',
    HAAG_SLITLAMP: 'Haag SLITLAMP',
};

const countries = {
    AF: 'Afghanistan',
    AX: '\u00c5land Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AC: 'Ascension Island',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia & Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    IC: 'Canary Islands',
    CV: 'Cape Verde',
    BQ: 'Caribbean Netherlands',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    EA: 'Ceuta & Melilla',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo - Brazzaville',
    CD: 'Congo - Kinshasa',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'C\u00f4te d\u2019Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Cura\u00e7ao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DK: 'Denmark',
    DG: 'Diego Garcia',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    EZ: 'Eurozone',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong SAR China',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR China',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar (Burma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'R\u00e9union',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'S\u00e3o Tom\u00e9 & Pr\u00edncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia & South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barth\u00e9lemy',
    SH: 'St. Helena',
    KN: 'St. Kitts & Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre & Miquelon',
    VC: 'St. Vincent & Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard & Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TA: 'Tristan da Cunha',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks & Caicos Islands',
    TV: 'Tuvalu',
    UM: 'U.S. Outlying Islands',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    UN: 'United Nations',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis & Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
};

export const LANG_EN_TRANS = {
    ...general,
    ...components,
    ...user_patient,
    ...tests,
    ...charts,
    ...validation_messages,
    ...devices,
    ...countries,
};
