import { AntSegmentForus3NethraClassicMeasuredData } from '../../../../../../../common/models/measuredData/FORUS/3NethraClassic/antSegmentForus3NethraClassicData.model';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { IAntSegmentForus3NethraClassicMeasuredData } from '../../../../../../../common/interfaces/measuredData/FORUS/3NethraClassic/ant-segment-forus3Nethra-classic.measuredData.interface';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import { AntSegmentForus3NethraClassicTestRemarksFrontend } from '../../../test-remarks/FORUS/3NethraClassic/antSegmentForus3NethraClassicTestRemarksFrontend.class';

export class AntSegmentForus3NethraClassicMeasuredDataFrontend extends AntSegmentForus3NethraClassicMeasuredData implements MeasuredDataFrontend {
    public remarks: AntSegmentForus3NethraClassicTestRemarksFrontend;
    constructor() {
        super();
    }
    public setModel(model: IAntSegmentForus3NethraClassicMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.OD = model.OD.map((i) => {
            const imageClass: SmartImageFrontend = new SmartImageFrontend();
            imageClass.model = i;
            return imageClass;
        });
        this.OS = model.OS.map((i) => {
            const imageClass: SmartImageFrontend = new SmartImageFrontend();
            imageClass.model = i;
            return imageClass;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        this.OD.forEach((si) => formattedTest.OD.images.push(si.imageUrl));
        this.OS.forEach((si) => formattedTest.OS.images.push(si.imageUrl));
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.OD.map((si: SmartImageFrontend) => si.download(fileService)));
        await Promise.all(this.OS.map((si: SmartImageFrontend) => si.download(fileService)));
    }
}
