// @ts-nocheck
import * as _ from 'lodash';

import { Protocol } from './Protocol';

// NOTE: refactoring ideas
// - State vs DataState

const START_STIM_DIST = 670;
const MID_STIM_DIST = 70;

const chartTypeString = 'DARK_ADAPTAION_AMD';

export class FakeData {
    nRun = 4;
    nPoint = 1000;

    runLength = 20;

    timeSec = +new Date() / 1000;
    output: any;

    //
    // somewhat dsl-y look with different naming-cases
    //
    generateOutput() {
        this.output = [];

        this.TestStart();

        this.TrainnigInterval(10);
        this.TrainnigInterval(11);

        _.times(this.nRun, (id) => {
            [0, 1].forEach((eye) => {
                this.RunStart(eye);

                _.times(this.nPoint, (i) => {
                    this.DataPoint(i);
                });

                this.RunStop();
            });
        });

        this.TestInfo();
        this.TestStop();
    }

    TrainnigInterval(eye: number) {
        this.RunStart(eye);
        this.timeSec += 5;
        this.RunStop();
    }

    TestStart() {
        this.do0({
            message_type: 0,
            runLength: this.runLength,
        });
    }
    TestInfo() {
        this.do0({
            message_type: 4,
            info: [
                ['111', 111],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
                ['111', 111],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
                ['', ''],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
                ['111', '111'],
            ],
        });
    }
    TestStop() {
        this.do0({ message_type: 1 });
    }
    RunStart(eye: number) {
        this.do0({
            eye,
            message_type: 2,
        });
    }
    RunStop() {
        this.do0({
            message_type: 3,
        });
    }

    // get lastMessage() {
    //   return _.last(this.output)
    // }

    private do(x: any, delta = 1) {
        this.output.push({
            timestamp: secToProtocolTime(this.timeSec),
            chartTypeString, // optional for the fake data
            ...x,
        });
        this.timeSec += delta;
    }
    private do0(x: any) {
        this.do(x, 0);
    }

    public fill(protocol: Protocol) {
        this.generateOutput();
        protocol.fakeInput(this.output);

        // this.output.forEach((x:any) => {
        //   protocol.fakeInput(x)
        // })
    }

    DataPoint(i: number) {
        this.updateCurve(i);
        let y = this.curveKoeff * i + this.curveFree;
        y = Math.max(y, 0);

        let v1 = _.random(0, 10, true);
        let v2 = _.random(0, 150, true);

        this.do(
            {
                message_type: 6,
                // seen: y,
                v1,
                v2,
            },
            this.runLength / this.nPoint
        );
    }

    updateCurve(i: number) {
        if (i === 0) this.initCurve();
    }

    // the curve is from (0,1) to (stepsToZero,0) to (n,0)
    // y = 1 - x/stepsToZero => b = 1, k = -1/stepsToZero
    curveFree = 1;
    curveKoeff = 0;
    initCurve() {
        let stepsToZero = _.random(this.nPoint) + 1;
        this.curveKoeff = -1 / stepsToZero;
    }
}

function secToProtocolTime(sec: number) {
    return sec * 10_000_000;
}
