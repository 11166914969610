import { FixationBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/fixationBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { FixationBulbicamTestChartComponent, FixationTestControlsComponent, FixationTestRemarksComponent } from '../../../_components';
import { FixationBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/fixationBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class FixationBulbicamTestFrontend extends FixationBulbicamTest {
    remarks: FixationBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof FixationTestRemarksComponent = FixationTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof FixationBulbicamTestChartComponent = FixationBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof FixationTestControlsComponent = FixationTestControlsComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new FixationBulbicamTestRemarksFrontend();
    }
}

export interface FixationBulbicamTestFrontend extends TestFrontend {}
applyMixins(FixationBulbicamTestFrontend, [TestFrontend]);
