import { MeasuredData } from '../../measuredData.model';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IPostSegmentNidekAFC330MeasuredData } from '../../../../interfaces/measuredData/NIDEK/AFC330/post-segment-nidek-afc330.measuredData.interface';
import { SmartImage } from '../../../smartImage.class';

export abstract class PostSegmentNidekAfc330MeasuredData implements MeasuredData, IPostSegmentNidekAFC330MeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public posterior: {
        OD: SmartImage[];
        OS: SmartImage[];
    };
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_AFC330;
        this.testType = TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION;
        this.posterior = {
            OD: [],
            OS: [],
        };
    }
    public abstract setModel(model: IPostSegmentNidekAFC330MeasuredData): void;
    public getModel(): IPostSegmentNidekAFC330MeasuredData {
        let model: IPostSegmentNidekAFC330MeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            posterior: {
                OD: this.posterior.OD.map((i) => i.model),
                OS: this.posterior.OS.map((i) => i.model),
            },
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.posterior.OD.length === 0 && this.posterior.OS.length === 0;
    }
}
