// @ts-nocheck
import 'typeface-dm-sans';

export const Brand = {
    /////
    // from brand book
    /////
    Dusk: '#03272B',
    CalmGreen: '#4f6c68',
    Salmon: '#f2ae9c',
    PalePink: '#f1c8c8',

    Font: 'DM Sans',
    // QuoteFont: '', // not used in ui probably

    /////
    // from recommendations from figma (possibly wrong or old)
    /////
    dark: '#03272b',
    notDark: '#dce2e1', // alternative color, very different, like for text on background

    darkGreen: '#395955',
    notDarkGreen: '#fafafa',

    // fish: '#f5b9a9', // salmon name is taken by some other color
    // notFish: '#ffffff',

    red: '#eb5757',
    orange: '#f2994a',
    yellow: '#f2c94c',
    green: '#81c53d',
    blue: '#2d9cdb',
};
