import { Component } from '@angular/core';
import { ConfigService } from '../../../_services/general/config.service';

@Component({
    selector: 'connection',
    styles: [require('./connection.component.scss')],
    template: require('./connection.component.html'),
})
export class ConnectionComponent {
    constructor(private configService: ConfigService) {}
}
