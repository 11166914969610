// @ts-nocheck
import { translate, getNewClass } from './util';
import { Target } from './types';
import { GroupOption } from './GroupOption';
import { drawBird } from './drawable';
// import * as d3 from 'd3'

// style
import * as _ from 'lodash';
import { colors } from './colors';
import { applyColor, nonSelectable } from './util';
import { Layout } from './Layout';

export function comboDefaultStyle(theme: string = 'dark') {
    let fontColor = '#333';
    let defaultStyle = {
        comboText: {
            color: '',
            klass: 'fLayerFontX',
            // color: fontColor,
            size: 16,
        },
        comboBg: {
            color: '', //transparent',
            klass: 'fLayer',
            // color: 'white',
        },
        comboBorder: {
            // color: fontColor,
            // klass: 'sText',
            color: '', //undefined as any, //'rgba(0,0,0,0)',
            klass: 'sLayer',
        },
        comboBird: {
            color: '', //fontColor,
            length: 10,
            width: 1.5,
            klass: 'sText',
        },
        optionTick: {
            size: 0.1,
            klass: 'sText',
            color: '', //#333',
        },
    };
    let dark = _.cloneDeep(defaultStyle);
    let darkFontColor = colors['text-dark'];
    let darkBorderColor = colors['light-blue'];

    applyColor(dark.comboText, (x) => darkFontColor);
    applyColor(dark.comboBorder, (x) => darkBorderColor);
    // applyColor(dark.comboBird, (x) => darkBorderColor);
    // applyColor(dark.comboBg, (x) => colors['dark']);
    applyColor(dark.optionTick, (x) => colors['light-blue']);

    let borderless = _.cloneDeep(dark);
    applyColor(borderless.comboBorder, (x) => 'transparent');
    borderless.comboBorder.klass = '';

    let neutral = _.cloneDeep(dark);
    // applyColor(neutral.comboBorder, (x) => '#aaa');
    // applyColor(neutral.comboBird, (x) => '#aaa');

    let style = { default: defaultStyle, dark, borderless, neutral };

    return style[theme] || style.default;
}

// it could be default style but nah
let exampleStyle = {
    comboBorder: { color: '', klass: '' },
    comboBg: { color: '', klass: '' },
    comboText: { color: '', size: 0, klass: '' },
    comboBird: { length: 0, width: 0, color: '', klass: '' },
};
type ComboStyle = typeof exampleStyle;

interface ComboParams {
    klass?: string;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
    title?: string;
    target?: Target;
    children?: GroupOption[];
    style?: ComboStyle;
    theme?: string;
    isOpened?: boolean;
    alwaysOpened?: boolean;

    area?: Layout;
}

//
// it is stateful so keep instance somewhere and use it
//
export class GroupBox {
    eventName = 'mousedown';

    klass: string;
    target?: Target;
    title: string;
    style: ComboStyle;

    isOpened: boolean = false;

    constructor(public params: ComboParams) {
        this.klass = params.klass || getNewClass('combo-box');
        this.target = params.target;
        this.title = params.title || '';
        this.style = params.style || comboDefaultStyle(params.theme);
        if (params.isOpened) {
            this.isOpened = true;
        }
        if (params.area) {
            let { area } = params;

            if (area.width) {
                this.params.width = area.width();
            }

            if (area.height) {
                this.params.height = area.height();
            }

            if (area.left) {
                this.params.x = area.left;
            }

            if (area.top) {
                this.params.y = area.top;
            }
        }
    }

    get width() {
        return this.params.width || 100;
    }
    get height() {
        return this.params.height || 0;
    }
    get x() {
        return this.params.x || 0;
    }
    get y() {
        return this.params.y || 0;
    }
    get children() {
        return this.params.children || [];
    }
    // get style () {
    //     return this.params.style;
    // }

    handler = () => {
        this.isOpened = !this.isOpened;
        this.render();
    };

    render = (givenTarget: Target | undefined = undefined) => {
        if (!this.target) this.target = givenTarget;
        let { x, y, width, height, target } = this;
        let showBird = !this.params.alwaysOpened;

        if (!target) return console.log('rendering without target');

        let g = target
            .selectAll(this.selector)
            .data([null])
            .join('g')
            .attr('class', () => this.klass)
            .attr('transform', translate({ x, y }))
            .attr('cursor', showBird ? 'pointer' : 'default')
            .on(this.eventName, this.handler)
            .call(nonSelectable);

        // g.selectAll('.rect')
        //     .data([null])
        //     .join('rect')
        //     .attr('class', `rect ${this.style.comboBorder.klass} ${this.style.comboBg.klass}`)
        //     // .attr('x', x)
        //     // .attr('y', y)
        //     .attr('width', width)
        //     .attr('height', height)
        //     // .style('stroke', this.style.comboBorder.color)
        //     .style('fill', this.style.comboBg.color);

        let buttonWidth = 50;

        let button = g
            .selectAll('.button')
            .data([null])
            .join('g')
            .attr('class', 'button')
            .attr('transform', translate({ x: width - buttonWidth }));

        // button.selectAll('rect')
        //   .data([null])
        //   .join('rect')
        // // let
        //   // .attr('x', width - buttonWidth)
        //   // .attr('y', y) // + height - 30)
        //   .attr('width', buttonWidth)
        //   .attr('height', height)
        //   // .attr('stroke', this.style.comboBorder.color)
        //   // .attr('fill', this.style.comboBg.color)
        //   .attr('stroke', 'transparent')
        //   .attr('fill', 'transparent')

        let deltaX = showBird ? -buttonWidth : 0;

        // let birdLength = this.style.comboBird.length;

        // let birdData: null[] = showBird ? [null] : [];
        // button
        //     .selectAll('.bird')
        //     .data(birdData)
        //     .join('path')
        //     .attr('class', 'bird ' + this.style.comboBird.klass)
        //     .attr('d', () => drawBird({ length: birdLength }))
        //     // .attr('transform', 'scale(10)')
        //     .attr('transform', translate({ x: buttonWidth / 2, y: height / 2 + birdLength / 2 }))
        //     .style('stroke', this.style.comboBird.color)
        //     .style('fill', 'transparent')
        //     .style('stroke-width', this.style.comboBird.width);
        // .attr('stroke-width', 1.7)
        // .attr('stroke-width', 2)
        // + ' scale(10)')

        this.renderChildren(g);

        g.selectAll('.cb-title')
            .data([null])
            .join('text')
            // .attr('class', `cb-title ${this.style.comboText.klass}`)
            .attr('class', `cb-title`)
            .attr('x', () => {
                if (this.title === 'Directions') {
                    return -15;
                }

                return (width + deltaX) / 2;
            })
            .attr('y', height / 2)
            .text(_.upperCase(this.title))
            .style('fill', this.style.comboText.color)
            .style('font-size', this.style.comboText.size)
            .style('font-weight', 700)
            .style('text-anchor', 'middle')
            .style('alignment-baseline', 'middle');
    };

    renderChildren(g: Target) {
        let { isOpened } = this;
        if (this.params.alwaysOpened) isOpened = true;
        let data: null[] = isOpened ? [null] : [];

        let gChildren = g
            .selectAll('.children')
            .data(data)
            .join('g')
            .attr('class', 'children')
            .attr('transform', () => translate({ y: this.height }));

        if (!isOpened) return;

        let rect = { x: 0, y: 0, width: this.width, height: 0 };
        this.children.forEach((one) => {
            rect.height = one.height;
            one.render(gChildren, rect);
            rect.y += rect.height;
        });

        // if (this.params.title === 'Eyes Type') {
        //     let gBackgroundRect = gChildren
        //         .selectAll('rect')
        //         .data([null])
        //         .join('rect');

        //     let dy = 25 + 30;

        //     gBackgroundRect
        //         // .attr('y', this.height)
        //         .attr('y', -dy)
        //         .attr('width', rect.width)
        //         .attr('height', rect.y + dy)
        //         .attr('class', `${this.style.comboBorder.klass} ${this.style.comboBg.klass}`)
        //         // .style('stroke', this.style.comboBorder.color)
        //         .attr('rx', 10)
        //         .style('fill', this.style.comboBg.color);
        // }
    }

    get selector() {
        return `.${this.klass}`;
    }
}
