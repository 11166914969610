// @ts-nocheck
import { Target } from '../types'

export type Domain = [number, number];

export interface RawPoint {
  x: number
  y: number
}

let applyClass = (klass: string) => (t: Target) => t.attr('class', klass);
// let getGs = (firstKlass: string, secondKlass: string, target: Target, xs) => {
        //   let gSeries = target
        //       .selectAll(`.${seriesKlass}`)
        //       .data(p.series)
        //       .join('g')
        //       .attr('class', seriesKlass);

        //   let gDataPoints = gSeries
        //       .selectAll(`.${pointsKlass}`)
        //       .data((d: any) => p.pointsMapping(d))
        //       .join('g')
        //       .attr('class', pointsKlass);

        //   return { gSeries, gDataPoints }
        // }
let getG = (target: Target, klass: string) => {
  return target
    .selectAll(`.${klass}`)
    .data([null])
    .join('g')
    .call(applyClass(klass));
}
export function dynamicTargets(root: Target) {
  let i = 0
  return () => {
    i += 1
    return getG(root, `target-${i}`)
  }
}
export function getStyle<S>(style: S, themeOverrides: { [k: string]: Partial<S> }, params?: { theme?: string, override?: Partial<S> }): S {
  params = params || {}
  let override = themeOverrides[params.theme || '']
  if (override) style = { ...style, ...override }
  if (params.override) style = { ...style, ...params.override }
  return style
}

// class Gs {
//   id = 0
//   constructor(private target: Target) {}

//   get take() {
//     this.id += 1
//     let target = this.target
//       .selectAll(`.g-${this.id}`)
//       .data([null])
//       .join('g')
//       .attr('class', `g-${this.id}`)
//     return target
//   }
// }


export class Selector {
  get selectAll() {
    return this.takeSelector
  }
  get class() {
    return this.prevTaken
  }

  index = 0
  get take() {
    this.index += 1
    return this.prevTaken
  }
  get prevTaken() {
    return this.klassFor(this.index - 1)
  }
  get takeSelector() {
    let klass = this.take
    return `.${klass}`
  }

  private klassFor(n: number) {
    return `klass-${n}`
  }
}

export function noop() {}
