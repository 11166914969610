import { RecordingToolBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/recordingToolBulbicamMeasuredDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class RecordingToolBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof RecordingToolBulbicamMeasuredDataFrontend = RecordingToolBulbicamMeasuredDataFrontend;
    public measurements: RecordingToolBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
