import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'paginator',
    template: require('./paginator.component.html'),
    styles: [require('./paginator.component.scss')],
})
export class PaginatorComponent implements OnInit, OnDestroy, AfterViewInit {
    private pagesCnt: number;
    private page: BehaviorSubject<number>;
    private readonly itemsPerPage: number = 8;
    private _totalItemsCnt: BehaviorSubject<number>;
    constructor(private ref: ChangeDetectorRef) {
        this.ref.detach();
        this.pagesCnt = 8;
        const savedPage: number = Number.parseInt(localStorage.getItem('savedPage'));
        this.page = new BehaviorSubject<number>(isNaN(savedPage) ? 1 : savedPage);
        this._totalItemsCnt = new BehaviorSubject<number>(0);
    }
    ngAfterViewInit(): void {
        this.ref.detectChanges();
    }
    ngOnInit(): void {
        this.page.subscribe((p) => {
            localStorage.setItem('savedPage', p.toString());
        });
        this._totalItemsCnt.subscribe((itemsCnt) => {
            this.pagesCnt = Math.ceil(itemsCnt / this.itemsPerPage);
            this.ref.detectChanges();
        });
    }
    ngOnDestroy(): void {}
    public get pageSource(): BehaviorSubject<number> {
        return this.page;
    }
    public set totalItemsCnt(cnt: number) {
        this._totalItemsCnt.next(cnt);
    }
    private start(): void {
        if (this.page.value !== 1) {
            this.page.next(1);
            this.ref.detectChanges();
        }
    }
    private prev(): void {
        if (this.page.value !== 1) {
            this.page.next(this.page.value - 1);
            this.ref.detectChanges();
        }
    }
    private next(): void {
        if (this.page.value < this.pagesCnt) {
            this.page.next(this.page.value + 1);
            this.ref.detectChanges();
        }
    }
    private end(): void {
        if (this.page.value < this.pagesCnt) {
            this.page.next(this.pagesCnt);
            this.ref.detectChanges();
        }
    }
}
