import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TEST_RESULT } from '../../../../../../commonout/enum/test-result.enum';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { OCULUS } from '../../../../../common/enums/oculus.enum';
import { TEST_RESULT_TYPE } from '../../../../../common/enums/test-result-type.enum';
import { IResponse } from '../../../../../common/interfaces/response.model';
import { ITableReportTestFormat } from '../../_models/tableReportTestMode';
import { TestFrontend } from '../../_models/tests/testFrontend.class';
import { BulbicamService } from '../../_services/examination/bulbiCam.service';
import { DiagnosisService } from '../../_services/examination/diagnosis.service';
import { ExaminationService } from '../../_services/examination/examination.service';
import { TestService } from '../../_services/examination/test.service';
import { AuthenticationService } from '../../_services/general/auth.service';
import { FileService } from '../../_services/general/file.service';
import { BulbicamChartComponent } from '../tests/charts/haploChart.component';

@Component({
    selector: 'test-preview',
    template: require('./test-preview.component.html'),
    styles: [require('./test-preview.component.scss')],
})
export class TestPreviewComponent implements OnInit {
    @Input() test: TestFrontend;
    @Input() index: number;
    private TEST_TYPE: typeof TEST_TYPE = TEST_TYPE;
    private TEST_RESULT: typeof TEST_RESULT = TEST_RESULT;
    private result_type: typeof TEST_RESULT_TYPE = TEST_RESULT_TYPE;
    private tableReport: ITableReportTestFormat;
    // images sector
    private bigImageUrl: string;
    private alignImageClass: OCULUS;
    // charts sector
    @ViewChild('previewChartContainer', { read: ViewContainerRef }) previewChartContainer: ViewContainerRef;
    private bulbicamChartComponent: BulbicamChartComponent;
    private chartData: ICamMessage[];
    private isChartShown: boolean;
    // diagnosis sector
    private diagnosisControl: FormControl;
    private isDiagnosisShown: string;
    private diagnosesTemplates: string[];

    constructor(
        private fileService: FileService,
        private bulbicamService: BulbicamService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private authService: AuthenticationService,
        private examinationService: ExaminationService,
        private diagnosisService: DiagnosisService,
        private testService: TestService
    ) {}
    async ngOnInit(): Promise<void> {
        this.tableReport = await this.test.remarks.lastMeasurement?.getTableReport(this.fileService);
        this.diagnosisControl = new FormControl(this.test.remarks.diagnosis.value);
        this.diagnosesTemplates = await this.diagnosisService.getDiagnoses(this.test.type).toPromise();
    }
    private showImage(oculus: OCULUS, imageUrl: string): void {
        this.alignImageClass = oculus;
        this.bigImageUrl = imageUrl;
    }
    private async onResultButtonClick(columnName: string, result: TEST_RESULT): Promise<void> {
        if (result === TEST_RESULT.NORMAL || result === TEST_RESULT.CAN_NOT_COMPREHEND) {
            const otherEyeResult: TEST_RESULT = (columnName === 'OD' ? this.test.remarks.result_new.OS.value : this.test.remarks.result_new.OD.value) as TEST_RESULT;
            if ((columnName === 'OU' || otherEyeResult === TEST_RESULT.NORMAL || otherEyeResult === TEST_RESULT.CAN_NOT_COMPREHEND) && !this.test.remarks.diagnosis.isEmpty()) {
                this.test.remarks.diagnosis.registerEdit('', this.authService.currentUser);
            }
            this.diagnosisControl.setValue('');
            if (columnName === 'OU') {
                this.test.remarks.result.registerEdit(result, this.authService.currentUser);
            } else if (columnName === 'OD') {
                this.test.remarks.result_new.OD.registerEdit(result, this.authService.currentUser);
            } else if (columnName === 'OS') {
                this.test.remarks.result_new.OS.registerEdit(result, this.authService.currentUser);
            }
            await this.examinationService.updateTest(this.test).toPromise();
        } else if (result === TEST_RESULT.ABNORMAL) {
            this.isDiagnosisShown = columnName;
        }
    }
    private async setDiagnosis(save: HTMLInputElement): Promise<void> {
        if (this.diagnosisControl.value) {
            this.test.remarks.diagnosis.registerEdit(this.diagnosisControl.value, this.authService.currentUser);
            if (this.isDiagnosisShown === 'OU') {
                this.test.remarks.result.registerEdit(TEST_RESULT.ABNORMAL, this.authService.currentUser);
            } else if (this.isDiagnosisShown === 'OD') {
                this.test.remarks.result_new.OD.registerEdit(TEST_RESULT.ABNORMAL, this.authService.currentUser);
            } else if (this.isDiagnosisShown === 'OS') {
                this.test.remarks.result_new.OS.registerEdit(TEST_RESULT.ABNORMAL, this.authService.currentUser);
            }
            await this.examinationService.updateTest(this.test).toPromise();
            if (save.checked && !this.diagnosesTemplates.some(t => t === this.diagnosisControl.value)) {
                this.diagnosesTemplates.push(this.diagnosisControl.value);
                this.diagnosesTemplates = await this.diagnosisService.update({ testType: this.test.type, diagnoses: this.diagnosesTemplates }).toPromise();
            }
        }
        this.isDiagnosisShown = null;
    }
    private applyDiagnosis(diagnosis: string): void {
        this.diagnosisControl.setValue(diagnosis);
    }
    private async removeDiagnosisItem(diagnosis: string): Promise<void> {
        let index: number = this.diagnosesTemplates.indexOf(diagnosis);
        this.diagnosesTemplates.splice(index, 1);
        this.diagnosesTemplates = await this.diagnosisService.update({ testType: this.test.type, diagnoses: this.diagnosesTemplates }).toPromise();
    }
    private async showChart() {
        this.isChartShown = true;
        if (this.tableReport.haplotest) {
            const bulbicamChartComponentFactory = this.componentFactoryResolver.resolveComponentFactory(this.test.bulbicamChartComponent),
                bulbicamChartComponentRef = this.previewChartContainer.createComponent(bulbicamChartComponentFactory);
            this.bulbicamChartComponent = bulbicamChartComponentRef.instance as any;
            const chartDataResponse: IResponse = await this.bulbicamService.getHaplotestData(this.tableReport.haplotest);
            this.chartData = chartDataResponse.data;
            this.bulbicamChartComponent.addData(this.chartData);
        }
    }
    private closeChart() {
        this.previewChartContainer.clear();
        this.isChartShown = false;
    }
}
