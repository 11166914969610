import { TestRemarkFrontend } from '../testRemarkFrontend';
import { PupillaryEvaluation2BulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/pupillaryEvaluation2BulbicamDataFrontend.class';

export class PupillaryEvaluation2BulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof PupillaryEvaluation2BulbicamMeasuredDataFrontend = PupillaryEvaluation2BulbicamMeasuredDataFrontend;
    public measurements: PupillaryEvaluation2BulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
