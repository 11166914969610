import { NgModule } from '@angular/core';
import {
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
} from '@angular/material';

const matElements = [
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
];

@NgModule({
    imports: matElements,
    exports: matElements,
})
export class MaterialModule {}
