import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CsvModule } from '@ctrl/ngx-csv';
import 'hammerjs';
import '../../../assets/css/buttons.scss';
import '../../../assets/css/checkbox.scss';
import '../../../assets/css/datepicker.scss';
import '../../../assets/css/input.scss';
import '../../../assets/css/naButton.scss';
import '../../../assets/css/radiobuttons.scss';
import '../../../assets/css/scroll.scss';
import '../../../assets/css/styles.scss';
import '../../../assets/css/textarea.scss';
import '../../../assets/css/toggleButtons.scss';
import { AboutComponent, ConfirmationComponent, ConnectionComponent, MenuComponent, PageNotFoundComponent, RootComponent, SimpleQuestionModalComponent } from '../../_components';
import { AuthGuard, RoleGuard, RouterGuard } from '../../_guards';
import { AiDataHelperClass } from '../../_helpers';
import { TokenInterceptor } from '../../_interceptors';
import { TRANSLATION_PROVIDERS } from '../../_languages/translation';
import { BacklightChartService } from '../../_services/chartServices/backLightChartService';
import { FunctionalScreeningChartService } from '../../_services/chartServices/functionalScreeningChartService';
import { MemorySaccadeChartService } from '../../_services/chartServices/memorySaccadeChartService';
import { MyPupil20TestChartService } from '../../_services/chartServices/myPupil20TestChartService';
import { Pupil20DotChartService } from '../../_services/chartServices/pupil20DotChartService';
import { Pupil20TableChartService } from '../../_services/chartServices/pupil20TableChartService';
import { PupilDiameterChartService } from '../../_services/chartServices/pupilDiameterChartService';
import { SaccadeChartService } from '../../_services/chartServices/pursuitSaccadesServices/saccadesTestServices/saccadesChartService';
import { SaccadeParsingService } from '../../_services/chartServices/pursuitSaccadesServices/saccadesTestServices/saccadesParsingService';
import { VelocityVerticalComputingService } from '../../_services/chartServices/pursuitSaccadesServices/saccadesTestServices/velocityComputingService/velocityComputingService';
import { VelocitySaccadeAnalyticsService } from '../../_services/chartServices/pursuitSaccadesServices/saccadesTestServices/velocityComputingService/velocitySaccadeAnalyticsService';
import { SmoothPursuitChartService } from '../../_services/chartServices/pursuitSaccadesServices/smoothPursuitTestServices/smoothPursuitChartService';
import { SaccadesCalibratedChartService } from '../../_services/chartServices/saccadesCalibratedChartService';
import { BulbicamService } from '../../_services/examination/bulbiCam.service';
import { UploadMeasurementsService } from '../../_services/examination/upload-measurements.service';
import { AuthenticationService } from '../../_services/general/auth.service';
import { ConfigService } from '../../_services/general/config.service';
import { ConnectionStatisticsService } from '../../_services/general/connection-statistics.service';
import { FileService } from '../../_services/general/file.service';
import { ModalService } from '../../_services/general/modal.service';
import { SocketService } from '../../_services/general/socket.service';
import { TranslateService } from '../../_services/general/translate.service';
import { PatientService } from '../../_services/patient/patient.service';
import { UserService } from '../../_services/user/user.service';
import { SharedModule } from '../shared.module/shared.module';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
    imports: [AppRoutingModule, BrowserAnimationsModule, BrowserModule, CsvModule, HttpClientModule, SharedModule],
    exports: [SharedModule],
    declarations: [AboutComponent, ConfirmationComponent, ConnectionComponent, MenuComponent, PageNotFoundComponent, RootComponent, SimpleQuestionModalComponent],
    entryComponents: [],
    providers: [
        AuthenticationService,
        ConfigService,
        SocketService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService, authenticationService: AuthenticationService, socketService: SocketService) => {
                // Order HAS a value!!!
                return () =>
                    new Promise<void>(async (res, rej) => {
                        try {
                            await configService.load(); // Order HAS a value!!!
                            await socketService.load();
                            res();
                        } catch (error) {
                            rej(error);
                        }
                    });
            },
            deps: [ConfigService, AuthenticationService, SocketService], // Order HAS a value!!!
            multi: true,
        },
        TokenInterceptor,
        AiDataHelperClass,
        BacklightChartService,
        PupilDiameterChartService,
        Pupil20TableChartService,
        Pupil20DotChartService,
        MyPupil20TestChartService,
        FunctionalScreeningChartService,
        SaccadesCalibratedChartService,
        SmoothPursuitChartService,
        MemorySaccadeChartService,
        SaccadeChartService,
        SaccadeParsingService,
        VelocityVerticalComputingService,
        VelocitySaccadeAnalyticsService,
        ConnectionStatisticsService,
        ModalService,
        PatientService,
        FileService,
        UserService,
        TranslateService,
        BulbicamService,
        UploadMeasurementsService,
        TRANSLATION_PROVIDERS,
        AuthGuard,
        RoleGuard,
        RouterGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
    ],
    bootstrap: [RootComponent],
})
export class AppModule {}
