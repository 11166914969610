// @ts-nocheck
import * as _ from 'lodash'
import * as moment from 'moment'

const FLAG_NAME = 'userIsOutlier'

function formatValue(n: any): string {
  if (n === 0) return 'Normal'
  if (n === 1) return 'Outlier'
  return 'unknown'
}

interface Props {
  edits: IChartEdit[]
  addEdit: (edit: MyEdit) => Promise<IChartEdit[]>
}

export class LogData {
  constructor(private p: Props) {
  }

  getValue(id: number) {
    let edits = this.p.edits || []
    let log = edits.filter(x => x.messageTimestamp === id)
    log = _.sortBy(log, x => x.date)
    let entry = _.last(log)
    if (!entry) return null
    return entry.currentValue
  }

  recordEdit(id: number, from: number, to: number) {
    let record = {
      messageTimestamp: id,
      fieldName: FLAG_NAME,
      previousValue: from,
      currentValue: to,
    }

    return this.addEdit(record)
    // .then((edits: IChartEdit[]) => {
    //   this.edits = edits
    //   // console.log(JSON.stringify(edits))
    // })
    .catch(() => {
      console.error('addEdit returned an error')
    })
  }

  addEdit(edit: MyEdit): Promise<IChartEdit[]> {
    return this.p.addEdit(edit)
  }
  get edits(): IChartEdit[] {
    return this.p.edits
  }
  // set edits(value: IChartEdit[]) {
  //   this.p = {
  //     ...this.p,
  //     edits: value,
  //   }
  // }

    //   // let edits: IChartEdit[] = []
    //   let item = edit as any
    //   item.editor = _.sample(['Alex', 'Me'])
    //   item.date = +new Date()
    //   let edits: IChartEdit[] = this.edits.concat([item])
    //   return Promise.resolve(edits)
    // }
    // edits: IChartEdit[] = []

    // XXX: temp
    // addEdit(edit: MyEdit): Promise<IChartEdit[]> {
    //   // let edits: IChartEdit[] = []
    //   let item = edit as any
    //   item.editor = _.sample(['Alex', 'Me'])
    //   item.date = +new Date()
    //   let edits: IChartEdit[] = this.edits.concat([item])
    //   return Promise.resolve(edits)
    // }
    // edits: IChartEdit[] = []
    // edits: any[] = [{"messageTimestamp":15982830021762312,"fieldName":"userSeen","previousValue":3,"currentValue":1,"editor":"Alex","date":1599036788569},{"messageTimestamp":15982830038839100,"fieldName":"userSeen","previousValue":3,"currentValue":2,"editor":"Alex","date":1599036790661},{"messageTimestamp":15982830038839100,"fieldName":"userSeen","previousValue":2,"currentValue":3,"editor":"Me","date":1599036797339}]
    // edits: any[] = [{"messageTimestamp":15982830021762312,"fieldName":"userSeen","previousValue":3,"currentValue":1,"editor":"Alex","date":1599036788569},{"messageTimestamp":15982830038839100,"fieldName":"userSeen","previousValue":3,"currentValue":2,"editor":"Alex","date":1599036790661},{"messageTimestamp":15982830038839100,"fieldName":"userSeen","previousValue":2,"currentValue":3,"editor":"Me","date":1599036797339},{"messageTimestamp":15982830038839100,"fieldName":"userSeen","previousValue":3,"currentValue":2,"editor":"Me","date":1599037553016}]

    logItems(id: number): string[] {
      let items = this.edits
      .filter(x => x.messageTimestamp === id)
      items = _.sortBy(items, x => x.date)
      return items.map(x => this.formatLogItem(x))
    }
    formatLogItem(x: IChartEdit): string {
      // let f = (x:any) => this.formatValue(parseInt(x))
      let f = formatValue
      let text = ''
      text = text.concat(`${f(x.previousValue)} → ${f(x.currentValue)}`)
      text = text.concat(', ')
      text = text.concat((moment as any)(x.date).format('YY-MM-DD HH:mm'))
      text = text.concat(' by ')
      text = text.concat(x.editor)
      return text
    }
}

// XXX: temp
interface IChartEdit {
    date: number;
    editorId: string;
    editor: string;
    messageTimestamp: number;
    fieldName: string;
    previousValue: string | number | Array<string> | Array<object>;
    currentValue: string | number | Array<string> | Array<object>;
}
type MyEdit = Pick<IChartEdit, 'messageTimestamp'|'fieldName'|'previousValue'|'currentValue'>
