import { TestRemarkFrontend } from '../testRemarkFrontend';
import { PtosisBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/ptosisBulbicamDataFrontend.class';

export class PtosisBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType = PtosisBulbicamMeasuredDataFrontend;
    public measurements: PtosisBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
