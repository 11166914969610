import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEVICE } from '../../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../../commonout/enum/test-type';
import { TEST_COMMAND } from '../../../../../../../commonout/enum/test.command.enum';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../../commonout/interfaces/charts.model';
import { IExamination } from '../../../../../../../commonout/interfaces/examination';
import { IPatient } from '../../../../../../common/interfaces/patient.interface';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { BulbicamService } from '../../../_services/examination/bulbiCam.service';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ConfigService } from '../../../_services/general/config.service';
import { SocketService } from '../../../_services/general/socket.service';
import { PatientService } from '../../../_services/patient/patient.service';

@Component({
    selector: 'lens-form',
    styles: [require('./lens-form.component.scss')],
    template: require('./lens-form.component.html'),
})
export class LensFormComponent implements OnInit, OnDestroy {
    public localStorage: Storage = localStorage;
    public skipShowing: FormControl = new FormControl();
    public maskDemoActive: boolean;
    public lensholderActive: boolean;
    public ppdActive: boolean;
    public lensesTooltipImagePosition: Object;
    public lensesTooltipImageName: string;
    public lensesTooltipContent: string;
    public refractionActive: boolean;
    public lensesActive: boolean;
    @Input() examination: ExaminationFrontend;
    private subscriptions: Subscription[];
    private lensForm: FormGroup;
    private readonly REFR_LENS_COEFFICIENT: number = 6;
    private errorsContainer: { error: string; status: boolean; order: number }[] = [
        {
            error: 'camEnabled',
            status: false,
            order: 1,
        },
        {
            error: 'sphericalOD',
            status: false,
            order: 2,
        },
        {
            error: 'sphericalOS',
            status: false,
            order: 3,
        },
        {
            error: 'PD',
            status: false,
            order: 4,
        },
        {
            error: 'LH',
            status: false,
            order: 5,
        },
        {
            error: 'isMetadataSent',
            status: false,
            order: 6,
        },
        {
            error: 'isResponceFromCamReceived',
            status: false,
            order: 7,
        },
    ];
    private connectionError: Subject<{ error: string; status: boolean }>;
    private boundMessageListener: Function;
    constructor(
        private configService: ConfigService,
        private bulbicamService: BulbicamService,
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private examinationService: ExaminationService,
        private patientService: PatientService,
        private socketService: SocketService
    ) {
        this.boundMessageListener = this.messagesHandler.bind(this);
        this.subscriptions = [];
        this.lensForm = this.formBuilder.group({
            sphericalOD: ['', Validators.required],
            sphericalOS: ['', Validators.required],
            cylinderOD: '',
            cylinderOS: '',
            axisOD: '',
            axisOS: '',
            lensOD: [{ value: '', disabled: true }],
            lensOS: [{ value: '', disabled: true }],
            PD: [null, Validators.required],
            LH: ['', Validators.required],
            isDilated: false,
        });
        this.connectionError = new Subject();
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.bulbicamService.isMetadataProvided.next(false);
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.connectionError.subscribe(errorObject => {
                if (this.errorsContainer.some(e => e.error === errorObject.error)) this.errorsContainer.find(e => e.error === errorObject.error).status = errorObject.status;
            })
        );
        this.subscriptions.push(
            this.configService.connectedEnvironment.subscribe(settings => {
                this.connectionError.next({ error: 'camEnabled', status: settings.find(s => s.type === DEVICE.HAPLO).isEnabled });
            })
        );
        this.patchForm();
        this.subscriptions.push(
            this.bulbicamService.isMetadataProvided.subscribe(value => {
                if (!localStorage.getItem('skipShowing') || localStorage.getItem('skipShowing') !== this.authService.currentUser._id) this.maskDemoActive = value;
                this.connectionError.next({ error: 'isResponceFromCamReceived', status: value });
            })
        );
        this.subscriptions.push(
            this.configService.getDeviceSettings(DEVICE.HAPLO).subscribe(state => {
                if (state.isEnabled && !this.bulbicamService.isMetadataProvided.value) this.provideMetadata(this.lensForm.get('isDilated').value);
                else this.bulbicamService.isMetadataProvided.next(false);
            })
        );
        this.subscriptions.push(
            this.lensForm.get('sphericalOD').valueChanges.subscribe(async val => {
                this.examination.patient.lensValues.sphere.OD.registerEdit(val, this.authService.currentUser.getModel(true));
                const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                this.examination.patient.setModel(patientModel);
                this.connectionError.next({ error: 'sphericalOD', status: true });
                const cylinderOD: number = this.lensForm.get('cylinderOD').value;
                let lensOD: string = null;
                if (cylinderOD == null) {
                    lensOD = (Number.parseFloat(val) + this.REFR_LENS_COEFFICIENT).toFixed(2);
                } else {
                    lensOD = (Number.parseFloat(val) + (cylinderOD === 0 ? 0 : cylinderOD / 2) + this.REFR_LENS_COEFFICIENT).toFixed(2);
                }
                this.lensForm.patchValue({
                    lensOD: lensOD,
                });
                this.setLensesTooltipContent();
            })
        );
        this.subscriptions.push(
            this.lensForm.get('cylinderOD').valueChanges.subscribe(async val => {
                this.examination.patient.lensValues.cylinder.OD.registerEdit(val, this.authService.currentUser.getModel(true));
                const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                this.examination.patient.setModel(patientModel);
                const sphericalOD: number = this.lensForm.get('sphericalOD').value;
                if (sphericalOD !== null) {
                    let lensOD: string = (sphericalOD + (val === 0 ? 0 : Number.parseFloat(val) / 2) + this.REFR_LENS_COEFFICIENT).toFixed(2);
                    this.lensForm.patchValue({
                        lensOD: lensOD,
                    });
                    this.setLensesTooltipContent();
                }
            })
        );
        this.subscriptions.push(
            this.lensForm
                .get('axisOD')
                .valueChanges.pipe(debounceTime(2000))
                .subscribe(async val => {
                    if (Number.parseInt(val) >= 0 && Number.parseInt(val) <= 180) {
                        this.examination.patient.lensValues.axis.OD.registerEdit(val, this.authService.currentUser.getModel(true));
                        const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                        this.examination.patient.setModel(patientModel);
                    }
                })
        );
        this.subscriptions.push(
            this.lensForm.get('sphericalOS').valueChanges.subscribe(async val => {
                this.examination.patient.lensValues.sphere.OS.registerEdit(val, this.authService.currentUser.getModel(true));
                const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                this.examination.patient.setModel(patientModel);
                this.connectionError.next({ error: 'sphericalOS', status: true });
                const cylinderOS: number = this.lensForm.get('cylinderOS').value;
                let lensOS: string = null;
                if (cylinderOS === null) {
                    lensOS = (Number.parseFloat(val) + this.REFR_LENS_COEFFICIENT).toFixed(2);
                } else {
                    lensOS = (Number.parseFloat(val) + (cylinderOS === 0 ? 0 : cylinderOS / 2) + this.REFR_LENS_COEFFICIENT).toFixed(2);
                }
                this.lensForm.patchValue({
                    lensOS: lensOS,
                });
                this.setLensesTooltipContent();
            })
        );
        this.subscriptions.push(
            this.lensForm.get('cylinderOS').valueChanges.subscribe(async val => {
                this.examination.patient.lensValues.cylinder.OS.registerEdit(val, this.authService.currentUser.getModel(true));
                const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                this.examination.patient.setModel(patientModel);
                const sphericalOS: number = this.lensForm.get('sphericalOS').value;
                if (sphericalOS !== null) {
                    let lensOS: string = (sphericalOS + (val === 0 ? 0 : Number.parseFloat(val) / 2) + this.REFR_LENS_COEFFICIENT).toFixed(2);
                    this.lensForm.patchValue({
                        lensOS: lensOS,
                    });
                    this.setLensesTooltipContent();
                }
            })
        );
        this.subscriptions.push(
            this.lensForm
                .get('axisOS')
                .valueChanges.pipe(debounceTime(2000))
                .subscribe(async val => {
                    if (Number.parseInt(val) >= 0 && Number.parseInt(val) <= 180) {
                        this.examination.patient.lensValues.axis.OS.registerEdit(val, this.authService.currentUser.getModel(true));
                        const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                        this.examination.patient.setModel(patientModel);
                    }
                })
        );
        this.subscriptions.push(
            this.lensForm.get('lensOD').valueChanges.subscribe(async val => {
                this.examination.lenses.OD.registerEdit(val, this.authService.currentUser.getModel(true));
                const examinationModel: IExamination = await this.examinationService.update(this.examination.getModel()).toPromise();
                this.examination.setModel(examinationModel);
                this.provideMetadata(this.lensForm.get('isDilated').value);
                this.setLensesTooltipContent();
            })
        );
        this.subscriptions.push(
            this.lensForm.get('lensOS').valueChanges.subscribe(async val => {
                this.examination.lenses.OS.registerEdit(val, this.authService.currentUser.getModel(true));
                const examinationModel: IExamination = await this.examinationService.update(this.examination.getModel()).toPromise();
                this.examination.setModel(examinationModel);
                this.provideMetadata(this.lensForm.get('isDilated').value);
                this.setLensesTooltipContent();
            })
        );
        this.subscriptions.push(
            this.lensForm
                .get('PD')
                .valueChanges.pipe(debounceTime(500))
                .subscribe(async val => {
                    this.examination.patient.pupilPupilDistance.registerEdit(val, this.authService.currentUser.getModel(true));
                    const patientModel: IPatient = await this.patientService.update(this.examination.patient.model).toPromise();
                    this.examination.patient.setModel(patientModel);
                    this.connectionError.next({ error: 'PD', status: true });
                    this.provideMetadata(this.lensForm.get('isDilated').value);
                })
        );
        this.subscriptions.push(
            this.lensForm.get('LH').valueChanges.subscribe(async val => {
                this.examination.lenses.lensholder.registerEdit(val, this.authService.currentUser.getModel(true));
                const examinationModel: IExamination = await this.examinationService.update(this.examination.getModel()).toPromise();
                this.examination.setModel(examinationModel);
                this.connectionError.next({ error: 'LH', status: true });
                this.provideMetadata(this.lensForm.get('isDilated').value);
            })
        );
        this.subscriptions.push(
            this.lensForm.get('isDilated').valueChanges.subscribe(async isDilated => {
                this.provideMetadata(isDilated);
            })
        );
        this.subscriptions.push(
            this.bulbicamService.lastCommand.subscribe((command: { testType: TEST_TYPE; command: TEST_COMMAND }) => {
                if (!command) return;
                switch (command.command) {
                    case TEST_COMMAND.START:
                        this.socketService.socket.on(command.testType + 'ChartMessage', this.boundMessageListener);
                        break;
                    default:
                        break;
                }
            })
        );
    }
    private messagesHandler(messages: ICamMessage[]) {
        if (messages.find(m => m.message_type === MESSAGE_TYPE.START_TEST)) {
            this.lensForm.disable({ emitEvent: false });
        } else if (messages.find(m => m.message_type === MESSAGE_TYPE.STOP_TEST)) {
            this.lensForm.enable({ emitEvent: false });
            this.socketService.socket.off(this.bulbicamService.lastCommand.value.testType + 'ChartMessage', this.boundMessageListener);
        }
    }
    private async provideMetadata(isDilated: boolean): Promise<void> {
        if (this.configService.camState && this.lensForm.valid) {
            this.connectionError.next({ error: 'isMetadataSent', status: true });
            await this.bulbicamService.sendExaminationMetadata(this.examination._id, isDilated).toPromise();
        }
    }
    private patchForm(): void {
        const valueForPatching = {
            sphericalOD: this.examination.patient.lensValues.sphere.OD.value,
            sphericalOS: this.examination.patient.lensValues.sphere.OS.value,
            cylinderOD: this.examination.patient.lensValues.cylinder.OD.value,
            cylinderOS: this.examination.patient.lensValues.cylinder.OS.value,
            axisOD: this.examination.patient.lensValues.axis.OD.value,
            axisOS: this.examination.patient.lensValues.axis.OS.value,
            lensOD: this.examination.lenses.OD.value,
            lensOS: this.examination.lenses.OS.value,
            PD: this.examination.patient.pupilPupilDistance.value,
            LH: this.examination.lenses.lensholder.value,
        };
        for (const key in valueForPatching) {
            if (Object.prototype.hasOwnProperty.call(valueForPatching, key)) {
                const element = valueForPatching[key];
                if (element || element === 0) this.connectionError.next({ error: key, status: element });
            }
        }
        this.lensForm.patchValue(valueForPatching, { emitEvent: false });
        this.setLensesTooltipContent();
    }
    private getErrorKeyDescription(error: { error: string; status: boolean }): string {
        switch (error.error) {
            case 'camEnabled':
                return 'Connection with CAM is ' + (error.status ? 'established' : 'not established') + '.';
            case 'sphericalOD':
                return 'Sphere OD required value is ' + (error.status ? 'entered' : 'not entered') + '.';
            case 'sphericalOS':
                return 'Sphere OS required value is ' + (error.status ? 'entered' : 'not entered') + '.';
            case 'PD':
                return 'Pupil distance required value is ' + (error.status ? 'entered' : 'not entered') + '.';
            case 'LH':
                return 'Lensholder size required value is ' + (error.status ? 'entered' : 'not entered') + '.';
            case 'isMetadataSent':
                return 'Lens values data is ' + (error.status ? 'provided' : 'not provided') + ' to CAM.';
            case 'isResponceFromCamReceived':
                return 'Confirmation about receiving Lens values data is ' + (error.status ? 'received' : 'not received') + ' from CAM.';
            default:
                break;
        }
    }
    private setLensesTooltipContent(): void {
        const ODval: number = Number.parseFloat(this.lensForm.get('lensOD').value),
            OSval: number = Number.parseFloat(this.lensForm.get('lensOS').value);
        this.lensesTooltipContent = ODval == 6 && OSval === 6 ? 'Emmetrope Patient: Use +6dptr preinstalled Lensholders' : `Insert OD ${ODval} and OS ${OSval} lenses`;
        this.lensesTooltipImageName = ODval == 6 && OSval === 6 ? 'emmetropeLH' : 'notEmmetropeLH';
        this.lensesTooltipImagePosition = ODval == 6 && OSval === 6 ? { left: '440px' } : { left: '545px', top: '70px' };
        if (this.lensForm.get('sphericalOS').value !== null && this.lensForm.get('sphericalOD').value !== null && !localStorage.getItem('skipShowing')) {
            this.lensesActive = true;
            setTimeout(() => {
                this.lensesActive = false;
            }, 5000);
        }
    }
    public close(): void {
        if (this.skipShowing.value) localStorage.setItem('skipShowing', this.authService.currentUser._id);
        this.maskDemoActive = false;
    }
}
