import { Component, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cylinder-chooser',
    template: require('./cylinder-chooser.component.html'),
    styles: [require('./cylinder-chooser.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CylinderChooserComponent),
            multi: true,
        },
    ],
})
export class CylinderChooserComponent implements ControlValueAccessor {
    @Input() private label: string;
    @Input() private required: boolean;
    @Output() isOpened = new EventEmitter<boolean>();
    public opened: boolean = false;
    public value: number;
    public readonly Array: ArrayConstructor = Array;
    public readonly math: Math = Math;
    public readonly isInteger = Number.isInteger;
    public readonly refractionsStep: number = 0.25;
    private disabled: boolean = false;
    private onChange = (_: any) => {};
    private onTouched = () => {};
    private subscriptions: Array<Subscription> = [];
    constructor() {}
    writeValue(value: number): void {
        this.value = value;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    @HostListener('click', ['$event'])
    open(event: Event) {
        this.opened = !this.opened;
        this.isOpened.next(this.opened);
    }
    setValue(v: number): void {
        this.value = v;
        this.opened = false;
        this.isOpened.next(this.opened);
        this.onChange(this.value);
    }
    @HostListener('blur', ['$event']) onBlur(event: FocusEvent) {
        this.opened = false;
        this.isOpened.next(this.opened);
    }
}
