import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { PostSegmentForus3NethraClassicMeasuredData } from '../../../../../../../common/models/measuredData/FORUS/3NethraClassic/postSegmentForus3NethraData.model';
import { IPostSegmentForus3NethraClassicMeasuredData } from '../../../../../../../common/interfaces/measuredData/FORUS/3NethraClassic/post-segment-forus3Nethra-classic.measuredData.interface';
import { PostSegmentForus3NethraClassicTestRemarksFrontend } from '../../../test-remarks/FORUS/3NethraClassic/postSegmentForus3NathraClassicTestRemarksFrontend.class';

export class PostSegmentForus3NethraClassicMeasuredDataFrontend extends PostSegmentForus3NethraClassicMeasuredData implements MeasuredDataFrontend {
    public remarks: PostSegmentForus3NethraClassicTestRemarksFrontend;
    constructor() {
        super();
        this.OD = [];
        this.OS = [];
    }
    public setModel(model: IPostSegmentForus3NethraClassicMeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.OD = model.OD.map((m) => {
            const i = new SmartImageFrontend();
            i.model = m;
            return i;
        });
        this.OS = model.OS.map((m) => {
            const i = new SmartImageFrontend();
            i.model = m;
            return i;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: this.OD.map((i) => i.imageUrl),
                ai: [],
            },
            OS: {
                values: '',
                images: this.OS.map((i) => i.imageUrl),
                ai: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.OD.map((si: SmartImageFrontend) => si.download(fileService)));
        await Promise.all(this.OS.map((si: SmartImageFrontend) => si.download(fileService)));
    }
}
