import { MeasuredData } from '../../measuredData.model';
import { IPostSegmentForus3NethraClassicMeasuredData } from '../../../../interfaces/measuredData/FORUS/3NethraClassic/post-segment-forus3Nethra-classic.measuredData.interface';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { SmartImage } from '../../../smartImage.class';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';

export abstract class PostSegmentForus3NethraClassicMeasuredData implements MeasuredData, IPostSegmentForus3NethraClassicMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: SmartImage[];
    public OS: SmartImage[];
    constructor() {
        this.sourceDevice = DEVICE.FORUS_3NETHRA;
        this.testType = TEST_TYPE.POSTERIOR_SEGMENT_EVALUATION;
    }
    public abstract setModel(model: IPostSegmentForus3NethraClassicMeasuredData): void;
    public getModel(): IPostSegmentForus3NethraClassicMeasuredData {
        let model: IPostSegmentForus3NethraClassicMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.map((i) => i.model),
            OS: this.OS.map((i) => i.model),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.length === 0 && this.OS.length === 0;
    }
}
