import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable()
export class ConnectionStatisticsService {
    constructor(private configService: ConfigService, private http: HttpClient) {}

    /**
     * @function getPeriod
     * @param start - start period timestamp
     * @param end - end period timestamp
     *
     * @returns Proise of Objects with array of IConnectionStatistics objects
     */
    getPeriod(start: number, end: number): Promise<any> {
        const url = `${this.configService.backendUrl}/connection-statistics`,
            payload = {
                start: start,
                end: end,
            };
        return this.http
            .post(url, payload)
            .toPromise()
            .then((res: any) => res.data);
    }

    /**
     * @function getCurrentSpeed
     * @returns Promise of Object with "data" property filled with statistics of the first record
     *
     */
    getCurrentSpeed(): Promise<any> {
        const url = `${this.configService.backendUrl}/current-connection-speed`;
        return this.http
            .post(url, null)
            .toPromise()
            .then((res: any) => res.data);
    }
}
