import { ThreeDScanIvueOctTest } from '../../../../../../../../commonout/classes/tests/IVUE/OCT/3dscanIvueOctTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { ThreeDScanIvueOctTestRemarksComponent } from '../../../../_components';
import { ThreeDScanIcueOctTestRemarksFrontend } from '../../../test-remarks/IVUE/OCT/ThreeDScanIvueOctTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class ThreeDScanIvueOctTestFrontend extends ThreeDScanIvueOctTest {
    remarks: ThreeDScanIcueOctTestRemarksFrontend;
    public readonly remarksComponent: typeof ThreeDScanIvueOctTestRemarksComponent = ThreeDScanIvueOctTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new ThreeDScanIcueOctTestRemarksFrontend();
    }
}

export interface ThreeDScanIvueOctTestFrontend extends TestFrontend {}
applyMixins(ThreeDScanIvueOctTestFrontend, [TestFrontend]);
