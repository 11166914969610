import { DarkAdaptationAmdBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/darkAdaptationAmdBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { DarkAdaptationAmdBulbicamControlComponent, DarkAdaptationAmdBulbicamTestChartComponent, DarkAdaptationAmdBulbicamTestRemarksComponent } from '../../../_components';
import { DarkAdaptationAmdBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/darkAdaptationAmdBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class DarkAdaptationAmdBulbicamTestFrontend extends DarkAdaptationAmdBulbicamTest {
    remarks: DarkAdaptationAmdBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof DarkAdaptationAmdBulbicamTestRemarksComponent = DarkAdaptationAmdBulbicamTestRemarksComponent;
    public readonly bulbicamChartComponent = DarkAdaptationAmdBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof DarkAdaptationAmdBulbicamControlComponent = DarkAdaptationAmdBulbicamControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new DarkAdaptationAmdBulbicamTestRemarksFrontend();
    }
}

export interface DarkAdaptationAmdBulbicamTestFrontend extends TestFrontend {}
applyMixins(DarkAdaptationAmdBulbicamTestFrontend, [TestFrontend]);
