import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import { IPursuitSaccadesCamMessage, IChartData, ISaccadeResult, RANGE_TYPE, SACCADE_RESULT } from '../../../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { CHART_HEIGHT, TICKS_COLOR, TICKS_FONT_SIZE, AXIS_COLOR, MOVEMENT_LINE_COLOR, DASHED_LINES_COLOR, VERTICAL_RESULTS_TEXT_COLOR } from '../../chartStyles.constants';
import { Y_SCALE_MAX_VALUE, X_TIMESCALE_MAX_VALUE, COUNT_OF_DEGREES_TICKS, ILine } from '../../generic-types';

export const TRESHOLD_VALUE = 30;

@Component({
  selector: 'app-saccade-vertical-chart',
  template: require('./saccade-vertical-chart.component.html'),
  styles: [require('../../saccade-pursuit-charts-styles.scss')]
})
export class SmoothSaccadeVerticalChartComponent implements OnInit, AfterViewInit {

    @ViewChild('chart') private svgElement: ElementRef;
    @Input() public data: IPursuitSaccadesCamMessage[];
    @Input() public width = 1400;
    @Input() public height = CHART_HEIGHT;
    @Input() public margin = 50;

    private calibratedFrames: IPursuitSaccadesCamMessage[];
    private svg: d3.Selection<SVGElement, unknown, null, undefined>;
    private svgInner: d3.Selection<SVGElement, unknown, null, undefined>;
    private yScaleAngle: ScaleLinear<number, number>;
    private yScaleStimuli: ScaleLinear<number, number>;
    private xScale: ScaleLinear<number, number>;
    private counter = 0;

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.initializeInitialChart();
    }

    public buildRecordedChart(chartData: IChartData) {
        this.height = CHART_HEIGHT;

        if (d3.select('#verticalMovementChartContent').empty()) {
            this.configureSVG();
        }
        this.calibratedFrames = chartData.movementFrames

        this.initializeChart(chartData.movementFrames);
        this.drawRawMovementData(chartData.movementFrames);
        this.drawGreenLines([ ...chartData.movementFrames ] );
        this.drawCalibrationDots([ ...chartData.movementFrames ], chartData.verticalResults);
    }

    private initializeInitialChart(): void {
        this.configureSVG();

        this.yScaleAngle = d3
          .scaleLinear()
          .domain([-Y_SCALE_MAX_VALUE, Y_SCALE_MAX_VALUE])
          .range([0, this.height - 2 * this.margin]);

        const angleAxisY = this.svgInner
          .append('g')
          .attr('id', 'y-axisAngle')
          .style('transform', 'translate(' + this.margin.toString() + 'px, 0)');

        this.xScale = d3
          .scaleLinear()
          .domain([0, X_TIMESCALE_MAX_VALUE]);

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', 'x-axis')
          .style('transform', 'translate(0, ' + (this.height - 2 * this.margin).toString() + 'px)');

        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'verticalChartPoints');

        this.width = this.svgElement.nativeElement.getBoundingClientRect().width;

        this.xScale.range([this.margin, this.width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxisAngle = d3
          .axisLeft(this.yScaleAngle)
          .ticks(COUNT_OF_DEGREES_TICKS)

        angleAxisY
          .call(yAxisAngle)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        angleAxisY
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private configureSVG() {
        this.svg = d3
          .select(this.svgElement.nativeElement)
          .attr('height', this.height)
          .attr('width', '100%')
          .attr('id', 'verticalMovementChart') as d3.Selection<SVGElement, unknown, null, undefined>;

        this.svgInner = this.svg
          .append('g')
          .attr('id', 'verticalMovementChartContent')
          .style('transform', 'translate(' + this.margin.toString() + 'px, ' + this.margin.toString() + 'px)');
    }

    private initializeChart(data: IPursuitSaccadesCamMessage[]): void {
        d3.select('#verticalMovementTitle').style('display', 'block');
        d3.select('#verticalMovementChartContent').select('#y-axisAngle').remove();
        d3.select('#verticalMovementChartContent').select('#x-axis').remove();

        const domainValues = d3.extent(this.calibratedFrames, d => d.calibrationAngleOS).reverse();
        const extremumValue = Math.abs(domainValues[0]) > Math.abs(domainValues[1])
          ? Math.abs(domainValues[0])
          : Math.abs(domainValues[1]);

        this.yScaleAngle = d3
          .scaleLinear()
          .domain([extremumValue, -extremumValue])
          .range([0, this.height - 2 * this.margin]);

        this.yScaleStimuli = d3
          .scaleLinear()
          .domain(d3.extent(this.calibratedFrames, d => d.stimuliOSy).reverse())
          .range([0, this.height - 2 * this.margin]);

        const angleAxisY = this.svgInner
          .append('g')
          .attr('id', 'y-axisAngle')
          .style('transform', 'translate(' + this.margin.toString() + 'px, 0)');

        this.xScale = d3
          .scaleLinear()
          .domain([0, d3.max(data, d => d.pointX)]);

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', 'x-axis')
          .style('transform', 'translate(0, ' + (this.height - 2 * this.margin).toString() + 'px)');
        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'verticalChartPoints');

        const width = this.svgElement.nativeElement.getBoundingClientRect().width
          ? this.svgElement.nativeElement.getBoundingClientRect().width
          : this.width;

        this.xScale.range([this.margin, width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxisAngle = d3
          .axisLeft(this.yScaleStimuli)
          .ticks(COUNT_OF_DEGREES_TICKS)

        angleAxisY
          .call(yAxisAngle)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        angleAxisY
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private drawRawMovementData(data: IPursuitSaccadesCamMessage[]): void {
        const points: [number, number][] = data.map(d => [
            this.xScale(d.pointX),
            this.yScaleAngle(d.calibrationAngleOS),
        ]);

        this.drawLineOnChart(points, { id: 'line', color: MOVEMENT_LINE_COLOR });
    }

    private drawLineOnChart(points: [number, number][],
    lineStyle: ILine): void {
        const line = d3
          .line()
          .x(d => d[0])
          .y(d => d[1])
          .curve(d3.curveMonotoneX);

        this.svgInner
          .append('path')
          .attr('id', lineStyle.id)
          .attr('d', line(points))
          .style('fill', 'none')
          .style('stroke', lineStyle.color)
          .style('stroke-width', '2px');
    }

    private drawGreenLines(data: IPursuitSaccadesCamMessage[]) {
        while (data.length > 1) {
            const startIndex = data.findIndex(f => f.calibrationGlintData);
            const slicedFrames = data.slice(startIndex);
            let endIndex = slicedFrames
              .findIndex(f => f.calibrationGlintData?.calibationGlintData !== slicedFrames[0].calibrationGlintData?.calibationGlintData)
              + startIndex;

            if (endIndex < startIndex) {
                endIndex = data.length - 1;
            }

            const greenLinePoints: [number, number][] = data
              .slice(startIndex, endIndex)
              .map(d => [
                  this.xScale(d.pointX),
                  this.yScaleAngle(data[endIndex - 1].calibrationAngleOS),
              ]);

            this.drawLineOnChart(greenLinePoints, { id: 'greenLine', color: 'green' });

            data.splice(0, endIndex);
        }
    }

    private drawCalibrationDots(data: IPursuitSaccadesCamMessage[], testResults: ISaccadeResult[]) {
        const radius = 5;
        testResults = testResults.filter(f => f.type !== RANGE_TYPE.AVERAGE);

        while (data.length > 1) {
            const startIndex = data.findIndex(f => f.calibrationGlintData);
            const slicedFrames = data.slice(startIndex);
            let endIndex = slicedFrames
              .findIndex(f => f.calibrationGlintData?.calibationGlintData !== slicedFrames[0].calibrationGlintData?.calibationGlintData)
              + startIndex;

            const firstSaccadeIndex = data.slice(startIndex)
              .findIndex(f => f.pupilvelocity > TRESHOLD_VALUE) + startIndex;

            const lastSaccadeIndex = data.slice(firstSaccadeIndex)
              .findIndex(f => f.pupilvelocity < TRESHOLD_VALUE) + firstSaccadeIndex;

            if (startIndex === -1) { break; }

            if (endIndex < startIndex) {
                endIndex = data.length - 1;
            }
              
            this.svgInner
              .append('circle')
              .attr('cx', this.xScale(data[lastSaccadeIndex].pointX))
              .attr('cy', this.yScaleAngle(data[lastSaccadeIndex].calibrationAngleOS))
              .attr('r', radius)
              .attr('fill', 'violet');

            this.svgInner
              .append('circle')
              .attr('cx', this.xScale(data[startIndex].pointX))
              .attr('cy', this.yScaleAngle(data[startIndex].calibrationAngleOS))
              .attr('r', radius)
              .attr('fill', 'red');

            this.svgInner
              .append('circle')
              .attr('cx', this.xScale(data[endIndex - 1].pointX))
              .attr('cy', this.yScaleAngle(data[endIndex - 1].calibrationAngleOS))
              .attr('r', radius)
              .attr('fill', 'red');

            this.svgInner.append("line")
              .attr('id', 'velocityDashedLine')
              .attr("x1", this.xScale(data[startIndex].pointX))
              .attr("y1", 0)
              .attr("x2", this.xScale(data[startIndex].pointX))
              .attr("y2", this.height - this.margin * 2)
              .style("stroke-dasharray", ("3, 3"))
              .style("stroke-width", 2)
              .style("stroke", DASHED_LINES_COLOR)
              .style("fill", "none");

            this.svgInner.append("line")
              .attr('id', 'velocityDashedLine')
              .attr("x1", this.xScale(data[endIndex].pointX))
              .attr("y1", 0)
              .attr("x2", this.xScale(data[endIndex].pointX))
              .attr("y2", this.height - this.margin * 2)
              .style("stroke-dasharray", ("3, 3"))
              .style("stroke-width", 2)
              .style("stroke", DASHED_LINES_COLOR)
              .style("fill", "none");
              
            const index = this.counter;

            const result = testResults[index].result === SACCADE_RESULT.ACCEPT
              ? `L ${testResults[index].latency}ms \n A ${testResults[index].amplitude}%`
              : `${testResults[index].result}`;

            this.svgInner
              .append('text')
              .attr('x', this.xScale(data[lastSaccadeIndex].pointX))
              .attr('y', this.yScaleAngle(data[lastSaccadeIndex].calibrationAngleOS) - 10)
              .style('text-anchor', 'middle')
              .style('font-weight', 'bold')
              .style('font-size', '12px')
              .attr('fill', VERTICAL_RESULTS_TEXT_COLOR)
              .text(result);

            data.splice(0, endIndex);
            
            this.counter++;
        }
        this.counter = 0;
    }
}
