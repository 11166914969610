import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[tooltip]',
})
export class TooltipDirective implements OnInit {
    @Input() condition: boolean = true;
    @Input() content: string;
    private element: any;
    @HostListener('mouseover', ['$event']) async onMouseHover(event: MouseEvent) {
        if (this.condition && this.content) this.renderer.appendChild(this._ef.nativeElement, this.element);
    }
    @HostListener('mouseleave') hideTooltip() {
        if (this.condition && this.content) this.renderer.removeChild(this._ef.nativeElement, this.element);
    }

    constructor(private _ef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        let h4 = this.renderer.createElement('h4');
        this.renderer.appendChild(h4, this.renderer.createText(this.content));
        this.element = this.renderer.createElement('div');
        this.renderer.appendChild(this.element, h4);
        this.renderer.setAttribute(this.element, 'class', 'versionsTooltip');
    }
}
