import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { axisBottom, axisLeft, drag, easeLinear, event, Line, line, ScaleLinear, scaleLinear, select, Selection } from 'd3';
import * as d3 from  'd3';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CalibratedData, CHART_SUBTYPE, ISmoothPursuitChartData, SmoothPursuitPoint, SmoothPursuitRealTimePoint, StimuliResults, WindowData } from '../../../../../../../../common/interfaces/smoothPursuitTestMessage.interface';
import { FocusDirective } from '../../../../../_directives';

@Component({
    selector: 'smooth-pursuit-chart',
    template: require('./smooth-pursuit-chart.component.html'),
    styles: [require('./smooth-pursuit-chart.component.scss')],
})
export class SmoothPursuitChartComponent implements AfterViewInit, OnInit, OnDestroy {

    isFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    lockTab: boolean = false;
    focusedControl: number = 1;
    @ViewChildren(FocusDirective) controls: QueryList<FocusDirective>;

    smoothComponentRender$ = new BehaviorSubject<ISmoothPursuitChartData>(null);

    @ViewChild('smoothPursuitChart') private svg: ElementRef;

    public chartData: ISmoothPursuitChartData;
    public testResults: StimuliResults[] = [];
    
    private chartsOffset: { firstChartOffset: number, secondChartOffset: number, thirdChartOffset: number};
    private subscriptions: Subscription[] = [];
    private switchStimuli = {
        stimuliFirst: true,
        stimuliSecond: true,
        stimuliFourth: true,
    };
    private velocityChartChosen = {
        First: true,
        Second: true,
        Fourth: true,
    };

    private legendData: Map<string, string> = new Map<string, string>();

    private SVG: Selection<SVGGElement, unknown, null, undefined>;
    private SVG_height: number;
    private single_Chart_height: number;
    private SVG_width: number;

    private calibratedData: CalibratedData[] = [];
    private OD_stimuli: SmoothPursuitPoint[] = [];
    private OD_horizontal: SmoothPursuitPoint[] = [];
    private OS_stimuli: SmoothPursuitPoint[] = [];
    private OS_horizontal: SmoothPursuitPoint[] = [];
    private runtimeGraph: Selection<SVGGElement, unknown, null, undefined>;
    private gainChart: Selection<SVGGElement, unknown, null, undefined>;
    private gainInner: Selection<SVGGElement, unknown, null, undefined>;
    private wrapper: Selection<SVGGElement, unknown, null, undefined>;
    private yAxisGeneratorOS: ScaleLinear<any, any>;
    private xAxisLaunching: ScaleLinear<any, any>;
    private xAxisGainGenerator: ScaleLinear<any, any>;
    private yAxisGainGenerator: ScaleLinear<any, any>;
    private isInitialRender: boolean;
    private isSwitchingVelocityChart = false;
    private draggableButtonValuesObj = {
        draggableButtonValueFirst: 200,
        draggableButtonValueSecond: 200,
        draggableButtonValueFourth: 200,
    };
    private zeroOD: number;
    private zeroOS: number;
    private skipRender: boolean = false;
    private topOffset: number;

    private readonly padding: { left: number; top: number; right: number; bottom: number } = { left: 40, top: 15, right: 40, bottom: 10 };
    private readonly legendHeight: number = 20;
    private readonly defaultChartHeight = 400;
    private readonly width = 1400;

    constructor() { }

    ngOnInit(): void {
        this.subscriptions.push(this.smoothComponentRender$.subscribe(async (data) => {
            if (data) {
                this.OD_horizontal = data.horizontalOD;
                this.OD_stimuli = data.stimuliOD;
                this.OS_horizontal = data.horizontalOS;
                this.OS_stimuli = data.stimuliOS;
                this.zeroOD = data.zeroOD;
                this.zeroOS = data.zeroOS;

                await this.render();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngAfterViewInit() {
        this.SVG = select(this.svg.nativeElement);
        (this.SVG_width = this.svg.nativeElement.getBoundingClientRect().width 
            ? this.svg.nativeElement.getBoundingClientRect().width
            : this.width),
            (this.SVG_height = 2000),
            (this.single_Chart_height = (this.SVG_height - this.legendHeight - this.padding.top * 3 - this.padding.bottom * 2) / 9);

        this.yAxisGeneratorOS = scaleLinear()
            .domain([30, -30])
            .range([0, this.single_Chart_height]);

        this.legendData.set('lightgray', 'stimuli');
        this.legendData.set('lightgreen', 'OD hor');
        this.legendData.set('aquamarine', 'OD ver');
        this.legendData.set('yellow', 'OS hor');
        this.legendData.set('beige', 'OS hor');

        // offset between charts. Two charts with have chartHeight / 1.5 and one has chartHeight
        this.chartsOffset = {
            firstChartOffset: 0,

            secondChartOffset: this.single_Chart_height + (this.single_Chart_height / 1.5) * 2 + this.legendHeight
                + this.padding.bottom + this.padding.top * 2 + 100,
                
            thirdChartOffset: this.single_Chart_height * 2 + (this.single_Chart_height / 1.5) * 4 + this.legendHeight 
                + this.padding.bottom * 2 + this.padding.top * 3 + 150
        }

        this.topOffset = this.legendHeight + this.padding.top * 3;

        // runtime rendering
        this.wrapper = this.SVG.append('g')
            .attr('class', 'wrapper');

        this.runtimeGraph = this.wrapper.append('g')
            .attr('class', 'runtimeGraph')
            .attr('transform', `translate(${this.padding.left},${0})`);
        this.runtimeGraph
            .append('g')
            .attr('class', 'yAxisLaunching')
            .attr('transform', `translate(${this.padding.left},${this.topOffset})`)
            .attr('color', 'white')
            .call(axisLeft(this.yAxisGeneratorOS).ticks(10));
        this.runtimeGraph
            .append('g')
            .attr('class', 'xAxisLaunching')
            .attr('transform', `translate(${this.padding.left},${this.topOffset + this.single_Chart_height})`)
            .attr('color', 'white');
        this.runtimeGraph
            .append('g')
            .attr('transform', `translate(${this.padding.left},${this.topOffset + this.single_Chart_height / 2})`)
            .append('path')
            .attr('class', 'middlePath')
            .attr('d', `M0, 0 L${this.SVG_width - this.padding.right * 3}, 0`)
            .attr('stroke', '#2D9CDB')
            .attr('stroke-width', 1);
        this.runtimeGraph
            .append('text')
            .text('px')
            .attr('class', 'runTimeLegends')
            .attr('fill', 'white')
            .attr('transform', `translate(${this.padding.left},${this.legendHeight + this.padding.top * 2})`);
        this.runtimeGraph
            .append('text')
            .text('sec')
            .attr('class', 'runTimeLegends')
            .attr('fill', 'white')
            .attr(
                'transform',
                `translate(${this.SVG_width - this.padding.right * 2},${this.legendHeight + this.padding.top * 2 + this.single_Chart_height + this.padding.bottom * 3.5})`
            );
        this.runtimeGraph
            .append('path')
            .attr('class', 'runTimeLineOD')
            .attr('fill', 'none')
            .attr('stroke', '#81c53d')
            .attr('stroke-width', '1px')
            .attr('transform', `translate(${this.padding.left},${this.topOffset})`);
        this.runtimeGraph
            .append('path')
            .attr('class', 'runTimeLineOS')
            .attr('fill', 'none')
            .attr('stroke', '#f2994a')
            .attr('stroke-width', '1px')
            .attr('transform', `translate(${this.padding.left},${this.topOffset})`);
        this.runtimeGraph
            .append('path')
            .attr('class', 'runTimeLineStimuli')
            .attr('fill', 'none')
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('transform', `translate(${this.padding.left},${this.topOffset})`);

        this.wrapper.attr('height', this.defaultChartHeight);

        ['First', 'Second', 'Fourth'].forEach(e => this.createAllGroups(e));
    }

    private async render(): Promise<void> {
        this.runtimeGraph.select('.yAxisLaunching').attr('color', 'white');
        this.runtimeGraph.select('.xAxisLaunching').attr('color', 'white');
        this.runtimeGraph.selectAll('.runTimeLegends').attr('fill', 'white');
        this.runtimeGraph.select('.middlePath').attr('d', `M0, 0 L${this.SVG_width - this.padding.right * 3}, 0`);
        this.runtimeGraph.select('.yAxisLaunching').call(axisLeft(this.yAxisGeneratorOS).ticks(10));
        this.wrapper.attr('height', this.defaultChartHeight);

        if (this.zeroOD !== undefined && this.zeroOS !== undefined) {
            const horizontalOD = _.cloneDeep(this.OD_horizontal);
            const horizontalOS = _.cloneDeep(this.OS_horizontal);
            const stimuli = _.cloneDeep(this.OD_stimuli);

            horizontalOD.forEach(e => {
                e.amplitude = e.amplitude - this.zeroOD;
                if (e.amplitude > 100 || e.amplitude < -100) {
                    e.amplitude = null;
                }
            });
            horizontalOS.forEach(e => {
                e.amplitude = e.amplitude - this.zeroOS;
                if (e.amplitude > 100 || e.amplitude < -100) {
                    e.amplitude = null;
                }
            });
            stimuli.forEach(e => {
                e.amplitude = -e.amplitude;
            });

            const start = horizontalOD[0].time;
            const finish = horizontalOD[horizontalOD.length - 1].time;
            this.xAxisLaunching = scaleLinear()
                .domain([start, finish])
                .range([0, this.SVG_width - this.padding.right * 3]);

            this.runtimeGraph.select('.xAxisLaunching').call(axisBottom(this.xAxisLaunching).ticks(30));

            const lineGenerator = line<{ time: number; amplitude: number }>()
                .x(d => this.xAxisLaunching(d.time))
                .y(d => this.yAxisGeneratorOS(d.amplitude));

            this.runtimeGraph
                .selectAll('.runTimeLineOD')
                .datum(horizontalOD)
                .attr('d', lineGenerator);
            this.runtimeGraph
                .selectAll('.runTimeLineOS')
                .datum(horizontalOS)
                .attr('d', lineGenerator);
            this.runtimeGraph
                .selectAll('.runTimeLineStimuli')
                .datum(stimuli)
                .attr('d', lineGenerator);
        }
    }

    public clear(): void {
        this.wrapper.attr('height', this.defaultChartHeight);
        this.OD_stimuli = [];
        this.OD_horizontal = [];
        this.OS_stimuli = [];
        this.OS_horizontal = [];
        this.testResults = [];
        this.draggableButtonValuesObj = {
            draggableButtonValueFirst: 200,
            draggableButtonValueSecond: 200,
            draggableButtonValueFourth: 200,
        };
        this.zeroOD = undefined;
        this.zeroOS = undefined;
        this.velocityChartChosen = {
            First: true,
            Second: true,
            Fourth: true,
        };

        this.runtimeGraph.select('.runTimeLineOD').attr('d', '');
        this.runtimeGraph.select('.runTimeLineOS').attr('d', '');
        this.runtimeGraph.select('.runTimeLineStimuli').attr('d', '');
        this.runtimeGraph.select('.middlePath').attr('d', '');
        this.runtimeGraph.select('.yAxisLaunching').attr('color', 'transparent');
        this.runtimeGraph.select('.yAxisLaunching').call(axisLeft(this.yAxisGeneratorOS).ticks(0));
        this.runtimeGraph.select('.xAxisLaunching').attr('color', 'transparent');
        if (this.xAxisLaunching !== undefined) {
            this.runtimeGraph.select('.xAxisLaunching').call(axisBottom(this.xAxisLaunching).ticks(0));
        }
        this.runtimeGraph.selectAll('.runTimeLegends').attr('fill', 'transparent');

        const clearPaths = (which: string) => {
            const offset = Object.keys(this.chartsOffset)[this.mapChartNumber(which)];

            this[`smoothPursuitChartOD${which}`].select(`.OD_stimuli${which}`).attr('d', '');
            this[`smoothPursuitChartOD${which}`].select(`.OD_horizontal${which}`).attr('d', '');
            this[`smoothPursuitChartOD${which}`].select(`.OD_vertical${which}`).attr('d', '');
            this[`smoothPursuitChartOS${which}`].select(`.OS_stimuli${which}`).attr('d', '');
            this[`smoothPursuitChartOS${which}`].select(`.OS_horizontal${which}`).attr('d', '');
            this[`smoothPursuitChartOS${which}`].select(`.OS_vertical${which}`).attr('d', '');
            this[`smoothPursuitChartOS${which}`].select(`.OS_stimuliFirstCycle${which}`).attr('d', '');
            this[`smoothPursuitChartOD${which}`].select(`.OD_stimuli_phaseShift${which}`).attr('d', '');
            this[`smoothPursuitChartOS${which}`].select(`.OS_stimuli_phaseShift${which}`).attr('d', '');
            this[`smoothPursuitVelocityChart${which}`].select('.velocityChartButtons').remove();
            this[`smoothPursuitVelocityChart${which}`].select('.velocityChart').remove();
            this[`smoothPursuitVelocityChart${which}`].select('.gainChart').remove();
            this.wrapper.select(`.smoothPursuitWrapper${which}`).attr('transform', `translate(0, 0)`);
            this.wrapper.select(`.labelForSaccadeOD${which}`).text('').attr('transform',`translate(0, 0)`);
            this.wrapper.select(`.labelForSaccadeOS${which}`).text('').attr('transform',`translate(0, 0)`);
            this.wrapper.select(`.unitsXAxis${which}`).text('');
            this.wrapper.select(`.unitsXAxis${which}`).attr('transform', `translate(0, 0)`);
            this.wrapper.select(`.xAxis${which}`).attr('transform', `translate(${this.padding.left}, ${this.legendHeight +
                this.padding.top * 3 +
                this.padding.bottom * 2 +
                this.single_Chart_height * 1.25 + 
                this.chartsOffset[offset]})`
            );
            this[`units${which}`].selectAll('*').remove();
        };

        ['First', 'Second', 'Fourth'].forEach(e => clearPaths(e));
    }

    public buildRecordedCharts(data: ISmoothPursuitChartData, skipRender: boolean = false): void {
        if (data && data.calibratedData.length > 0) {
            this.chartData = data;
            this.calibratedData = data.calibratedData;
    
            this.skipRender = skipRender;
        }

        ['First', 'Second', 'Fourth'].forEach(e => 
            this.renderSaccadeCharts(e, this.calibratedData[this.mapChartNumber(e)]));
    }

    private createAllGroups(which: string): void {
        // Axies
        const offset = Object.keys(this.chartsOffset)[this.mapChartNumber(which)];

        this[`units${which}`] = this.wrapper.append('g')
            .attr('class', `xAxis${which}`)
            .attr(
                'transform',
                `translate(${this.padding.left},${this.legendHeight +
                    this.padding.top * 3 +
                    this.padding.bottom * 2 +
                    this.single_Chart_height * 1.25 +
                    this.chartsOffset[offset]})`
            )
            .attr('color', 'white');
        
        this.wrapper.append('text')
            .attr('class', `labelForSaccadeOD${which}`)
            .style('fill', 'white')
            .attr('x', this.padding.right * 2.5)
            .attr('y', this.legendHeight + this.padding.top + this.chartsOffset[offset] + this.single_Chart_height / 2)
            .style('font-size', '15px')
            .attr('alignment-baseline', 'middle');
        this.wrapper.append('text')
            .attr('class', `labelForSaccadeOS${which}`)
            .style('fill', 'white')
            .attr('x', this.padding.right * 2.5)
            .attr('y', this.legendHeight + this.padding.top + this.padding.bottom + this.single_Chart_height + this.chartsOffset[offset] + 40)
            .style('font-size', '15px')
            .attr('alignment-baseline', 'middle');
        this.wrapper.append('text')
            .attr('class', `unitsXAxis${which}`)
            .style('fill', 'white')
            .attr('x', this.SVG_width - this.padding.right)
            .attr('y', this.topOffset + this.single_Chart_height * 1.5 + this.padding.bottom * 5 + this.chartsOffset[offset] + this.single_Chart_height / 1.5)
            .style('font-size', '15px')
            .attr('alignment-baseline', 'middle');

        // Wrappers
        this[`smoothPursuitGroupWrapper`] = this.wrapper.append('g')
            .attr('class', `smoothPursuitWrapper${which}`)

        this[`smoothPursuitChartOD${which}`] = this[`smoothPursuitGroupWrapper`].append('g')
            .attr('class', 'smoothPursuitChart')
            .attr('transform', `translate(${this.padding.left},${this.legendHeight + this.padding.top + this.chartsOffset[offset]})`)
            .attr('height', this.single_Chart_height);
        this[`smoothPursuitChartOS${which}`] = this[`smoothPursuitGroupWrapper`].append('g')
            .attr('class', 'smoothPursuitChart')
            .attr(
                'transform',
                `translate(${this.padding.left},${this.legendHeight + this.padding.top + this.single_Chart_height / 1.5 + this.chartsOffset[offset]})`
            );
        this[`smoothPursuitVelocityChart${which}`] = this[`smoothPursuitGroupWrapper`].append('g')
            .attr('class', 'velocityChartGroup')
            .attr('transform', `translate(${this.padding.left},${this.topOffset + this.single_Chart_height * 1.5 + this.padding.bottom * 2 + this.chartsOffset[offset]})`)
            .attr('height', this.single_Chart_height);

        this.appendPaths(which);
    }

    private appendPaths(which: string): void {
        this[`smoothPursuitChartOD${which}`]
            .append('path')
            .attr('class', `OD_stimuli${which}`)
            .attr('fill', 'none')
            .attr('stroke', '#585755')
            .attr('stroke-width', '1px');
        this[`smoothPursuitChartOD${which}`]
            .append('path')
            .attr('class', `OD_horizontal${which}`)
            .attr('fill', 'none')
            .attr('stroke', 'lightgreen')
            .attr('stroke-width', '1px');
        this[`smoothPursuitChartOD${which}`]
            .append('path')
            .attr('class', `OD_vertical${which}`)
            .attr('fill', 'none')
            .attr('stroke', 'aquamarine')
            .attr('stroke-width', '1px');
        this[`smoothPursuitChartOD${which}`]
            .append('path')
            .attr('class', `OD_stimuli_phaseShift${which}`)
            .attr('fill', 'none')
            .attr('stroke', 'cyan')
            .attr('stroke-width', '1px')
            .style("stroke-dasharray", ("15, 23"));

        this[`smoothPursuitChartOS${which}`]
            .append('path')
            .attr('class', `OS_stimuli${which}`)
            .attr('fill', 'none')
            .attr('stroke', '#585755')
            .attr('stroke-width', '1px');
        this[`smoothPursuitChartOS${which}`]
            .append('path')
            .attr('class', `OS_horizontal${which}`)
            .attr('fill', 'none')
            .attr('stroke', 'yellow')
            .attr('stroke-width', '1px');
        this[`smoothPursuitChartOS${which}`]
            .append('path')
            .attr('class', `OS_vertical${which}`)
            .attr('fill', 'none')
            .attr('stroke', 'beige')
            .attr('stroke-width', '1px');
        this[`smoothPursuitChartOS${which}`]
            .append('path')
            .attr('class', `OS_stimuli_phaseShift${which}`)
            .attr('fill', 'none')
            .attr('stroke', 'cyan')
            .attr('stroke-width', '1px')
            .style("stroke-dasharray", ("15, 23"));
    }

    private renderSaccadeCharts(which: string, calibratedData: CalibratedData): void {
        if (!this.skipRender) {
            this.wrapper.select(`.labelForSaccadeOD${which}`).text('OD');
            this.wrapper.select(`.labelForSaccadeOS${which}`).text('OS');
            this.wrapper.select(`.unitsXAxis${which}`).text('sec');

            this.runtimeGraph.select('.runTimeLineOD').attr('d', '');
            this.runtimeGraph.select('.runTimeLineOS').attr('d', '');
            this.runtimeGraph.select('.runTimeLineStimuli').attr('d', '');
            this.runtimeGraph.select('.middlePath').attr('d', '');
            this.runtimeGraph.select('.yAxisLaunching').attr('color', 'transparent');
            this.runtimeGraph.select('.yAxisLaunching').call(axisLeft(this.yAxisGeneratorOS).ticks(0));
            this.runtimeGraph.select('.xAxisLaunching').attr('color', 'transparent');
            if (this.xAxisLaunching !== undefined) {
                this.runtimeGraph.select('.xAxisLaunching').call(axisBottom(this.xAxisLaunching).ticks(0));
            }
            this.runtimeGraph.selectAll('.runTimeLegends').attr('fill', 'transparent');
        }

        // it doesn't matter which array to choose, x value would be always the same
        const startFrequency: { x: number }[] = [];
        const finishFrequency: { x: number }[] = [];
        let parsedStimuliFrames: SmoothPursuitPoint[] = _.cloneDeep(this.chartData?.parsedStimuli);

        let stimuliOD: SmoothPursuitPoint[] = _.cloneDeep(calibratedData?.stimuliAmplitudeOD);
        let horizontalOD: SmoothPursuitPoint[] = _.cloneDeep(calibratedData?.eyeAmplitudeOD);

        let stimuliOS: SmoothPursuitPoint[] = _.cloneDeep(calibratedData?.stimuliAmplitudeOS);
        let horizontalOS: SmoothPursuitPoint[] = _.cloneDeep(calibratedData?.eyeAmplitudeOS);

        parsedStimuliFrames.forEach(e => {
            e.type === 15 ? startFrequency.push({x: e.time}) : null;
            e.type === 16 ? finishFrequency.push({x: e.time}) : null;
        });
        
        let start: number;
        let finish: number;

        if (startFrequency.length !== 0) {
            if (which === 'First' && finishFrequency.length > 0) {
                start = 0;
                finish = startFrequency[1].x;
            }
            if (which === 'Second') {
                start = finishFrequency[0].x;
                finish = startFrequency[2].x;
            }
            if (which === 'Fourth') {
                start = startFrequency[2].x;
                finish = finishFrequency[3].x;
            }
        }
        
        const xAxisGenerator = scaleLinear()
            .domain([start, finish])
            .range([this.padding.right * 2.5, this.SVG_width - this.padding.left - this.padding.right]);
        const yAxisGenerator = scaleLinear()
            .domain([30, -30])
            .range([0, this.single_Chart_height]);

        const lineGeneratorOD = line<{ x: number; y: number }>()
            .x(d => xAxisGenerator(d.x))
            .y(d => yAxisGenerator(d.y));
        const lineGeneratorOS = line<{ x: number; y: number }>()
            .x(d => xAxisGenerator(d.x))
            .y(d => yAxisGenerator(d.y));

        if (!this.skipRender) {
            this[`units${which}`].append('g').call(axisBottom(xAxisGenerator).ticks(10));
        }

        //building
        if (!this.skipRender) {
            if (this.switchStimuli[`stimuli${which}`]) {
                this.transition(this[`smoothPursuitChartOD${which}`].select(`.OD_stimuli${which}`),
                                stimuliOD.map(s => ({ x: s.time, y: s.amplitude })), lineGeneratorOD);
                this.transition(this[`smoothPursuitChartOS${which}`].select(`.OS_stimuli${which}`),
                                stimuliOS.map(s => ({ x: s.time, y: s.amplitude })), lineGeneratorOS);
            }
            this.transition(this[`smoothPursuitChartOD${which}`].select(`.OD_horizontal${which}`),
                            horizontalOD.map(s => ({ x: s.time, y: s.amplitude })), lineGeneratorOD);
            this.transition(this[`smoothPursuitChartOS${which}`].select(`.OS_horizontal${which}`),
                            horizontalOS.map(s => ({ x: s.time, y: s.amplitude })), lineGeneratorOS);
        } 

        if (!this.skipRender) {
            this.isInitialRender = true;
            this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
        }
    }

    private transition(
        node: Selection<SVGGeometryElement, unknown, null, undefined>,
        data: { x: number; y: number }[],
        lineGen: Line<{
            x: number;
            y: number;
        }>
    ) {
        lineGen.defined((d, i) => d.y < 30 && d.y > -30);
        // 0.2hz
        node.datum(data).attr('d', lineGen);

        let OD_stimuli_length = node.node().getTotalLength();
        node.attr('stroke-dasharray', OD_stimuli_length + ' ' + OD_stimuli_length)
            .attr('stroke-dashoffset', OD_stimuli_length)
            .transition()
            .duration(500)
            .ease(easeLinear)
            .attr('stroke-dashoffset', 0);
    }

    private renderVelocityGroup(
        which: string,
        horizontalOD: SmoothPursuitRealTimePoint[],
        horizontalOS: SmoothPursuitRealTimePoint[],
        xAxisGenerator: ScaleLinear<number, number>,
        stimuliOD: SmoothPursuitRealTimePoint[]
    ): void {
        this[`smoothPursuitVelocityChart${which}`].select('.velocityChartButtons').remove();
        this[`smoothPursuitVelocityChart${which}`].select('.velocityChart').remove();
        this[`smoothPursuitVelocityChart${which}`].selectAll('g').remove();

        const velocityChartButtons = this[`smoothPursuitVelocityChart${which}`].append('g').attr('class', 'velocityChartButtons');
        const velocityChart = this[`smoothPursuitVelocityChart${which}`]
            .append('g')
            .attr('class', 'velocityChart')
            .attr('height', this[`smoothPursuitVelocityChart${which}`].node().getAttribute('height'))
        
        const chosenEye: string = this[`chosenODforVelocity${which}`] ? 'OD' : 'OS';

        const handleClick = (d: string) => {
            if (this[`chosenODforVelocity${which}`] && d === 'OD') return;
            if (!this[`chosenODforVelocity${which}`] && d === 'OS') return;
            if (this[`chosenODforVelocity${which}`] && d === 'OS') {
                this[`chosenODforVelocity${which}`] = false;
                this.isSwitchingVelocityChart = true;
                this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
            }
            if (!this[`chosenODforVelocity${which}`] && d === 'OD') {
                this[`chosenODforVelocity${which}`] = true;
                this.isSwitchingVelocityChart = true;
                this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
            }

        };

        if (this.velocityChartChosen[which]) {
            velocityChartButtons
                .append('rect')
                .data(['OD'])
                .attr('x', -40)
                .attr('y', 0)
                .attr('width', 87)
                .attr('height', 25)
                .attr('rx', 5)
                .attr('ry', 5)
                .attr('fill', () => (chosenEye === 'OD' ? '#81c53d' : 'transparent'))
                .attr('stroke', '#81c53d')
                .attr('cursor', 'pointer')
                .on('click', handleClick);
            velocityChartButtons
                .append('rect')
                .data(['OS'])
                .attr('x', -40)
                .attr('y', 30)
                .attr('width', 87)
                .attr('height', 25)
                .attr('rx', 5)
                .attr('ry', 5)
                .attr('fill', () => (chosenEye === 'OD' ? 'transparent' : '#f2994a'))
                .attr('stroke', '#f2994a')
                .attr('cursor', 'pointer')
                .on('click', handleClick);
            velocityChartButtons
                .append('text')
                .data(['OD'])
                .attr('x', -5)
                .attr('y', 15)
                .text('OD')
                .style('fill', 'white')
                .style('font-size', '15px')
                .attr('alignment-baseline', 'middle')
                .attr('cursor', 'pointer')
                .on('click', handleClick);
            velocityChartButtons
                .append('text')
                .data(['OS'])
                .attr('x', -5)
                .attr('y', 45)
                .text('OS')
                .style('fill', 'white')
                .style('font-size', '15px')
                .attr('alignment-baseline', 'middle')
                .attr('cursor', 'pointer')
                .on('click', handleClick);
        }

        velocityChartButtons
            .append('rect')
            .attr('x', -40)
            .attr('y', 140)
            .attr('width', 87)
            .attr('height', 25)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', () => (this.switchStimuli[`stimuli${which}`] ? '#88f' : 'transparent'))
            .attr('stroke', '#88f')
            .attr('cursor', 'pointer')
            .on('click', () => {
                this.switchStimuli[`stimuli${which}`] = !this.switchStimuli[`stimuli${which}`];
                this[`smoothPursuitChartOD${which}`].select(`.OD_stimuli${which}`).attr('d', '');
                this[`smoothPursuitChartOS${which}`].select(`.OS_stimuli${which}`).attr('d', '');
                this.renderSaccadeCharts(which, this.calibratedData[this.mapChartNumber(which)]);
            });

        velocityChartButtons
            .append('text')
            .attr('x', -20)
            .attr('y', 154)
            .text('Stimuli')
            .style('fill', 'white')
            .style('font-size', '15px')
            .attr('alignment-baseline', 'middle')
            .attr('cursor', 'pointer')
            .on('click', () => {
                this.switchStimuli[`stimuli${which}`] = !this.switchStimuli[`stimuli${which}`];
                this[`smoothPursuitChartOD${which}`].select(`.OD_stimuli${which}`).attr('d', '');
                this[`smoothPursuitChartOS${which}`].select(`.OS_stimuli${which}`).attr('d', '');
                this.renderSaccadeCharts(which, this.calibratedData[this.mapChartNumber(which)]);
            });
       
        velocityChartButtons
            .append('rect')
            .attr('x', -40)
            .attr('y', 80)
            .attr('width', 87)
            .attr('height', 25)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', () => (this.velocityChartChosen[which] ? '#2D9CDB' : 'transparent'))
            .attr('stroke', '#2D9CDB')
            .attr('cursor', 'pointer')
            .on('click', () => {
                if (!this.velocityChartChosen[which]) {
                    this.velocityChartChosen[which] = true;
                    this.isSwitchingVelocityChart = false;
                    this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
                }
            });
        velocityChartButtons
            .append('rect')
            .attr('x', -40)
            .attr('y', 110)
            .attr('width', 87)
            .attr('height', 25)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', () => (!this.velocityChartChosen[which] ? '#2D9CDB' : 'transparent'))
            .attr('stroke', '#2D9CDB')
            .attr('cursor', 'pointer')
            .on('click', () => {
                if (this.velocityChartChosen[which]) {
                    this.velocityChartChosen[which] = false;
                    this.isSwitchingVelocityChart = false;
                    this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
                }
            });
        velocityChartButtons
            .append('text')
            .attr('x', -24)
            .attr('y', 93)
            .text('Velocity')
            .style('fill', 'white')
            .style('font-size', '15px')
            .attr('alignment-baseline', 'middle')
            .attr('cursor', 'pointer')
            .on('click', () => {
                if (!this.velocityChartChosen[which]) {
                    this.velocityChartChosen[which] = true;
                    this.isSwitchingVelocityChart = false;
                    this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
                }
            });
        velocityChartButtons
            .append('text')
            .attr('x', -35)
            .attr('y', 123)
            .text('Frequency')
            .style('fill', 'white')
            .style('font-size', '15px')
            .attr('alignment-baseline', 'middle')
            .attr('cursor', 'pointer')
            .on('click', () => {
                if (this.velocityChartChosen[which]) {
                    this.velocityChartChosen[which] = false;
                    this.isSwitchingVelocityChart = false;
                    this.renderVelocityGroup(which, horizontalOD, horizontalOS, xAxisGenerator, stimuliOD);
                }
            });

        const assignMarginToElements = (element: Selection<d3.BaseType, unknown, HTMLElement, undefined>) => {
            if (element.empty()) { return [0, 0]; }
            
            var split = element.attr('transform')?.replace('translate', '').replace('(', '').replace(')', '').split(',') || ['0', '0'];
            var x = parseInt(split[0]);
            var y = parseInt(split[1]);

            this.velocityChartChosen[which]
                ? element.attr('transform', `translate(${x},${y - this.single_Chart_height})`)
                : element.attr('transform', `translate(${x},${y + this.single_Chart_height})`);
        } 

        // change charts depends on selected button
        if (this.velocityChartChosen[which] && this.isSwitchingVelocityChart === false) {
            this.buildVelocityChart(velocityChart, chosenEye, chosenEye === 'OD' ? horizontalOD : horizontalOS, which, xAxisGenerator);

            const nextCharts = d3.selectAll(`.smoothPursuitWrapper${which} ~ *`);

            if (!this.isInitialRender) {
                nextCharts.each(function (el) {
                    assignMarginToElements(d3.select(this));
                });
            }

            this.wrapper.select(`.unitsXAxis${which}`).text('sec');
        }
        else if (this.isSwitchingVelocityChart === false) {
            this.buildGainCharts(which);

            const nextCharts = d3.selectAll(`.smoothPursuitWrapper${which} ~ *`);

            nextCharts.each(function (p) {
                assignMarginToElements(d3.select(this));
            });

            this.wrapper.select(`.unitsXAxis${which}`).text('sec');

            this.isInitialRender = false;
        }
        else if (this.isSwitchingVelocityChart) {
            this.buildVelocityChart(velocityChart, chosenEye, chosenEye === 'OD' ? horizontalOD : horizontalOS, which, xAxisGenerator);
            this.wrapper.select(`.unitsXAxis${which}`).text('sec');
        }

        this.testResults = this.chartData?.gainChartData?.testResults;

        const height = (this.wrapper.node() as SVGElement).getBoundingClientRect().height;
        height !== 0 
            ? this.SVG.attr('height', height + this.topOffset)
            : this.SVG.attr('height', this.single_Chart_height * 9 + this.topOffset);
    }

    private buildVelocityChart(
        target: Selection<SVGGElement, unknown, null, undefined>,
        eye: string,
        horizontalArr: SmoothPursuitPoint[],
        which: string,
        xAxisGenerator: ScaleLinear<number, number>
    ): void {
        target.selectAll('g').remove();

        const height = +target.node().getAttribute('height');

        let velocityData: { x: number; y: number; type: number; frequency?: number }[] = [];

        let finishFrequency = horizontalArr.find(e => e.type === 16) ;
        let startFrequency = horizontalArr.find(e => e.type === 15);

        startFrequency = startFrequency
            ? startFrequency
            : horizontalArr[0];
        finishFrequency = finishFrequency
            ? finishFrequency
            : horizontalArr.slice(-1)[0];

        if (which === 'Fourth') {
            const startIndex = horizontalArr.findIndex(e => e.type === 15);

            startFrequency = horizontalArr.slice(startIndex + 1).find(e => e.type === 15);
        }

        horizontalArr.forEach((e, i) => {
            if (i >= 10) {
                const velocity = Math.abs((e.amplitude - horizontalArr[i - 10].amplitude) / 
                    (e.time - horizontalArr[i - 10].time));

                const obj = {
                    x: e.time,
                    y: velocity,
                    frequency: e.frequency,
                    type: e.type,
                };

                velocityData.push(obj);
            }
        });

        const maxVelocity = 300;

        velocityData = velocityData.filter(e => e.y <= maxVelocity);

        const yAxisGenerator: ScaleLinear<any, any> = scaleLinear()
            .domain([maxVelocity, 0])
            .range([0, height]);
        const yAxisGeneratorReverted: ScaleLinear<any, any> = scaleLinear()
            .domain([0, height])
            .range([maxVelocity, 0]);

        const lineGenerator: Line<{ x: number; y: number }> = line<{ x: number; y: number }>()
            .x(d => xAxisGenerator(d.x))
            .y(d => yAxisGenerator(d.y));

        target
            .append('g')
            .attr('class', 'xAxisVelocity')
            .attr('transform', `translate(${0},${height})`)
            .attr('color', 'white')
            .call(axisBottom(xAxisGenerator).ticks(25));

        target
            .append('g')
            .attr('class', 'yAxisVelocity')
            .attr('transform', `translate(${this.padding.right * 2.5},${0})`)
            .attr('color', 'white')
            .call(axisLeft(yAxisGenerator).ticks(5));

        target
            .append('g')
            .append('text')
            .attr('class', 'velocityYAxisLabel')
            .text('deg/25 ms')
            .attr('fill', 'white')
            .attr('font-size', '12px')
            .attr('x', this.padding.right)
            .attr('y', -20);

        target
            .append('g')
            .attr('class', 'velocityChartPath')
            .append('path')
            .datum(velocityData)
            .attr('d', lineGenerator)
            .attr('stroke', 'white')
            .attr('fill', 'transparent');

        const draggableButton = target.append('g').attr('class', 'draggableButton');

        const dragHandler = drag()
            .container(target.node())
            .on('drag', () => {
                const value = yAxisGeneratorReverted(event.y).toFixed(0);
                if (value <= maxVelocity && value >= 0) {
                    this.draggableButtonValuesObj[`draggableButtonValue${which}`] = value;
                    this.buildVelocityChart(target, eye, horizontalArr, which, xAxisGenerator);
                }
            });

        draggableButton
            .append('rect')
            .attr('x', this.padding.right * 1.5)
            .attr('y', String(yAxisGenerator(0)) === 'NaN' ? null : yAxisGenerator(this.draggableButtonValuesObj[`draggableButtonValue${which}`]) - 10)
            .attr('alignment-baseline', 'bottom')
            .attr('width', 40)
            .attr('height', 20)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('fill', () => (eye === 'OD' ? '#81c53d' : '#f2994a'))
            .attr('cursor', 'grab')
            .call(dragHandler);
        draggableButton
            .append('text')
            .text(`${this.draggableButtonValuesObj[`draggableButtonValue${which}`]}`)
            .attr('x', this.padding.right * 1.5 + 10)
            .attr('y', String(yAxisGenerator(0)) === 'NaN' ? null : yAxisGenerator(this.draggableButtonValuesObj[`draggableButtonValue${which}`]) + 3)
            .attr('alignment-baseline', 'center')
            .attr('fill', 'white')
            .attr('font-size', '10px')
            .attr('cursor', 'grab')
            .call(dragHandler);

        draggableButton
            .append('path')
            .attr(
                'd',
                `M${this.padding.right * 1.5} ${String(yAxisGenerator(0)) === 'NaN' ? 0 : yAxisGenerator(this.draggableButtonValuesObj[`draggableButtonValue${which}`])} L${this
                    .SVG_width -
                    this.padding.left -
                    this.padding.right} ${String(yAxisGenerator(0)) === 'NaN' ? 0 : yAxisGenerator(this.draggableButtonValuesObj[`draggableButtonValue${which}`])}`
            )
            .attr('stroke', () => (eye === 'OD' ? '#81c53d' : '#f2994a'))
            .attr('stroke-dasharray', 5);

        const verticalLines = target.append('g').attr('class', 'verticalLines');
        let thresholdVelocityData = velocityData.filter(e => e.y >= this.draggableButtonValuesObj[`draggableButtonValue${which}`]);

        // remove lines that are very tight
        const highestData = [...thresholdVelocityData].sort((a, b) => b.y - a.y);

        highestData.forEach(e => {
            const foundElement = thresholdVelocityData.find(item => e.x === item.x);

            if (foundElement) {
                thresholdVelocityData = thresholdVelocityData.filter(el => el.x === e.x || el.x > e.x + 0.05 || el.x < e.x - 0.05);
            }
        });

        verticalLines.append('g').attr('class', `frequency${which}`);

        verticalLines.append('g').attr('class', 'distanceBetweenEventsGroup');

        if (startFrequency) {
            verticalLines
                .selectAll('.frequencyBeginning')
                .data([startFrequency])
                .enter()
                .append('path')
                .attr('d', d => {
                    return `M${xAxisGenerator(d.time)},${yAxisGenerator(600)} L${xAxisGenerator(d.time)},${yAxisGenerator(0)}`;
                })
                .attr('stroke', '#2D9CDB')
                .attr('stroke-width', 3);
        }
        if (finishFrequency) {
            verticalLines
                .selectAll('.frequencyFinish')
                .data([finishFrequency])
                .enter()
                .append('path')
                .attr('d', d => {
                    return `M${xAxisGenerator(d.time)},${yAxisGenerator(600)} L${xAxisGenerator(d.time)},${yAxisGenerator(0)}`;
                })
                .attr('stroke', '#88f')
                .attr('stroke-width', 3);
        }

        const buldVerticalLines = (
            target: Selection<SVGGElement, unknown, null, undefined>,
            frequency: { x: number; y: number; frequency?: number }[],
            className: string,
            which: string,
            eye: string
        ): void => {
            const firstValueAfterFinish = frequency.findIndex(el => el.x > finishFrequency.time);

            target
                .select(className)
                .selectAll('.verticalLine')
                .data(frequency)
                .join('path')
                .attr('d', d => {
                    return `M${xAxisGenerator(d.x)},${yAxisGenerator(d.y)} L${xAxisGenerator(d.x)},${height + 20}`;
                })
                .attr('stroke', (d, i) => {
                    if (i === 0 || i === firstValueAfterFinish) {
                        return '#f5f';
                    }

                    return eye === 'OD' ? '#81c53d' : '#f2994a';
                });

            const distanceToFirstLine = [{ x1: startFrequency.time, x2: frequency[0].x }];

            // distance between beginning of frequency and first line
            target
                .select(className)
                .selectAll('.distanceToFirstLine')
                .data(distanceToFirstLine)
                .join('path')
                .attr('d', d => {
                    const x1 = xAxisGenerator(d.x1);
                    const x2 = xAxisGenerator(d.x2);
                    const y = height + 20;

                    return `M${x1} ${y}
                            L${x1 + 4}, ${y + 2}
                            L${x1 + 4}, ${y - 2}
                            L${x1}, ${y}
                            L${x1 + 4}, ${y}
                            L${x2}, ${y}
                            L${x2 - 4}, ${y + 2}
                            L${x2 - 4}, ${y - 2}
                            L${x2}, ${y}
                            `;
                })
                .attr('stroke', '#2D9CDB')
                .attr('fill', '#2D9CDB');

            target
                .select(className)
                .selectAll('.distanceToFirstLineLabel')
                .data(distanceToFirstLine)
                .join('text')
                .text(d => `${((d.x2 - d.x1) * 1000).toFixed(0)} ms`)
                .attr('x', d => xAxisGenerator(d.x2) - (xAxisGenerator(d.x2) - xAxisGenerator(d.x1)) / 2)
                .attr('y', height + 30)
                .attr('fill', 'white')
                .attr('font-size', '10px');

            // distance between frequency finish and first line after
            if (firstValueAfterFinish !== -1) {
                const distanceToFirstLineAfterFinsih = [{ x1: finishFrequency.time, x2: frequency[firstValueAfterFinish].x }];

                target
                    .select(className)
                    .selectAll('.distanceToFirstLine')
                    .data(distanceToFirstLineAfterFinsih)
                    .join('path')
                    .attr('d', d => {
                        const x1 = xAxisGenerator(d.x1);
                        const x2 = xAxisGenerator(d.x2);
                        const y = height + 20;

                        return `M${x1} ${y}
                                 L${x1 + 4}, ${y + 2}
                                 L${x1 + 4}, ${y - 2}
                                 L${x1}, ${y}
                                 L${x1 + 4}, ${y}
                                 L${x2}, ${y}
                                 L${x2 - 4}, ${y + 2}
                                 L${x2 - 4}, ${y - 2}
                                 L${x2}, ${y}
                                 `;
                    })
                    .attr('stroke', '#88f')
                    .attr('fill', '#88f');

                target
                    .select(className)
                    .selectAll('.distanceToFirstLineLabel')
                    .data(distanceToFirstLineAfterFinsih)
                    .join('text')
                    .text(d => `${((d.x2 - d.x1) * 1000).toFixed(0)} ms`)
                    .attr('x', d => xAxisGenerator(d.x2) - (xAxisGenerator(d.x2) - xAxisGenerator(d.x1)) / 2)
                    .attr('y', height + 30)
                    .attr('fill', 'white')
                    .attr('font-size', '10px');
            }

            let secondTillLastValues = [...frequency];
            secondTillLastValues.splice(0, 1);
            secondTillLastValues = secondTillLastValues.filter(el => el.x < finishFrequency.time);

            const highestValues = [...secondTillLastValues].sort((a, b) => b.y - a.y);

            highestValues.forEach(e => {
                const foundElement = secondTillLastValues.find(item => e.x === item.x);

                if (foundElement) {
                    secondTillLastValues = secondTillLastValues.filter(el => {
                        return el.x === e.x || xAxisGenerator(el.x) > xAxisGenerator(e.x) + 23 || xAxisGenerator(el.x) < xAxisGenerator(e.x) - 23;
                    });
                }
            });

            target
                .select(className)
                .selectAll('circle')
                .data(secondTillLastValues)
                .join('circle')
                .attr('class', 'circleWrapper')
                .attr('cx', d => xAxisGenerator(d.x))
                .attr('cy', height - 20)
                .attr('r', 13)
                .attr('fill', () => (eye === 'OD' ? '#81c53d' : '#f2994a'));
            target
                .select(className)
                .selectAll('.otherLinesLabel')
                .data(secondTillLastValues)
                .join('text')
                .text((d, i) => {
                    const index = frequency.findIndex(e => e.x === d.x);
                    return which === 'Fourth' ? `${d.frequency.toFixed(2)}` : index + 1;
                })
                .attr('x', d => (which === 'Fourth' ? xAxisGenerator(d.x) - 10 : xAxisGenerator(d.x) - 4))
                .attr('y', height - 16)
                .attr('fill', 'white')
                .attr('font-size', () => (which === 'Fourth' ? '10px' : '14px'));
        };

        let processedFrequency: {
            x: number;
            y: number;
            type: number;
            frequency?: number;
        }[];

        startFrequency && finishFrequency 
            ? (processedFrequency = thresholdVelocityData
                .filter(e => e.x >= startFrequency.time && e.x <= finishFrequency.time + 1)) 
            : null;

        processedFrequency && processedFrequency.length > 0 
            ? buldVerticalLines(verticalLines, processedFrequency, `.frequency${which}`, which, eye) 
            : null;
    }

    private buildGainCharts(which: string): void {
        this.gainChart = this[`smoothPursuitVelocityChart${which}`]
            .append('g')
            .attr('id', `gainChart${which}`)
            .attr('class', 'gainChart')
            .attr('height', this[`smoothPursuitVelocityChart${which}`].node().getAttribute('height'));

        const gainChartData = this.chartData?.gainChartData?.stimulusData || [];

        const firstPointOD = gainChartData[this.mapChartNumber(which)].firstWindow.pointOD;
        const inBetweenPointsOD = gainChartData[this.mapChartNumber(which)].windowsInBetween.map(el => el.pointOD);

        const firstPointOS = gainChartData[this.mapChartNumber(which)].firstWindow.pointOS;
        const inBetweenPointsOS = gainChartData[this.mapChartNumber(which)].windowsInBetween.map(el => el.pointOS);

        const topOffset = this.single_Chart_height + this.padding.bottom + 30; 

        this.initializeGainChart(which, firstPointOD, inBetweenPointsOD, CHART_SUBTYPE.OD);
        this.initializeGainChart(which, firstPointOS, inBetweenPointsOS, CHART_SUBTYPE.OS, topOffset);
    }

    private initializeGainChart(
        which: string, 
        firstPoint: WindowData, 
        inBetweenPoints: WindowData[], 
        chartType: CHART_SUBTYPE,
        offsetTop = 0
    ): void {
        const start = this.chartData?.gainChartData?.stimulusData[this.mapChartNumber(which)]?.xAxisStart || 0;
        const end = this.chartData.gainChartData?.stimulusData[this.mapChartNumber(which)]?.xAxisEnd || 60;
        
        this.gainInner = this.gainChart
            .append('g')
            .attr('id', 'gainChartOS')
            .style('transform', `translate(0,${offsetTop}px)`);
        const height = +this.gainChart.node().getAttribute('height');

        this.yAxisGainGenerator = scaleLinear()
            .domain([1, -1])
            .range([0, height]);

        this.xAxisGainGenerator = scaleLinear()
            .domain([start, end])
            .range([0, this.SVG_width - this.padding.left - this.padding.right - (this.padding.right * 2.5)]);

        this.gainInner
            .append('g')
            .attr('class', 'yAxisFrequency')
            .attr('transform', `translate(${this.padding.right * 2.5},${0})`)
            .attr('color', 'white')
            .call(axisLeft(this.yAxisGainGenerator).ticks(10).tickFormat(d3.format(".0%")));

        this.gainInner
            .append('g')
            .attr('class', 'xAxisFrequency')
            .attr('color', 'white')
            .attr('transform', `translate(${this.padding.right * 2.5},${height})`)
            .call(axisBottom(this.xAxisGainGenerator));   
    
        this.drawPoints(which, chartType, firstPoint, inBetweenPoints);
    }

    private drawPoints(
        which: string, 
        chartType: CHART_SUBTYPE, 
        firstPoint: WindowData, 
        inBetweenPoints: WindowData[]
    ): void {
        const correlationCoorY = firstPoint.corelation ? firstPoint.corelation : 0;

        this.gainInner
            .append('circle')
            .attr('cx', this.xAxisGainGenerator(firstPoint.time))
            .attr('cy', this.yAxisGainGenerator(correlationCoorY))
            .attr('r', 7)
            .attr('fill', 'grey')
            .style('stroke', 'black')
            .attr('transform', `translate(${this.padding.right * 2.5},${0})`);

        const textOffsetY = 20;
        const textOffsetX = 85;

        const firstWindow = this.chartData?.gainChartData?.stimulusData[this.mapChartNumber(which)]?.firstWindow;

        const timeShift = chartType === 'OS'
            ? firstWindow.timeShiftOS
            : firstWindow.timeShiftOD;
        
        const phaseShift = chartType === 'OS'
            ? firstWindow.phaseShiftOS
            : firstWindow.phaseShiftOD;

        this.gainInner
            .append('text')
            .attr('x', this.xAxisGainGenerator(firstPoint.time) + textOffsetX)
            .attr('y', this.yAxisGainGenerator(correlationCoorY) - textOffsetY)
            .style('font-family', 'DMSans')
            .style('font-size', '12px')
            .style('fill', 'white')
            .text(`${(firstPoint.corelation * 100)?.toFixed(1)}%`);

        this.gainInner
            .append('text')
            .attr('x', this.xAxisGainGenerator(firstPoint.time) + textOffsetX)
            .attr('y', this.yAxisGainGenerator(correlationCoorY) + textOffsetY)
            .style('font-weight', 'bold')
            .style('font-size', '13px')
            .style('fill', 'white')
            .text(`Ts ${timeShift.toFixed(2)}`);

        this.gainInner
            .append('text')
            .attr('x', this.xAxisGainGenerator(firstPoint.time) + textOffsetX)
            .attr('y', this.yAxisGainGenerator(correlationCoorY) + textOffsetY + 20)
            .style('font-weight', 'bold')
            .style('font-size', '13px')
            .style('fill', 'white')
            .text(`Ps ${phaseShift.toFixed(2)}`);

        const inBetweenWindow = this.chartData?.gainChartData?.stimulusData[this.mapChartNumber(which)].windowsInBetween;

        inBetweenWindow.forEach(window => {
            const timeShift = chartType === 'OS'
                ? window.timeShiftOS
                : window.timeShiftOD;
        
            const phaseShift = chartType === 'OS'
                ? window.phaseShiftOS
                : window.phaseShiftOD;

            const element = chartType === 'OS'
                ? window.pointOS
                : window.pointOD;

            const correlationCoorY = element.corelation ? element.corelation : 0;

            this.gainInner
                .append('text')
                .attr('x', this.xAxisGainGenerator(element.time) + textOffsetX)
                .attr('y', this.yAxisGainGenerator(correlationCoorY) + textOffsetY)
                .style('font-weight', 'bold')
                .style('font-size', '13px')
                .style('fill', 'white')
                .text(`Ts ${timeShift.toFixed(2)}`);
            this.gainInner
                .append('text')
                .attr('x', this.xAxisGainGenerator(element.time) + textOffsetX)
                .attr('y', this.yAxisGainGenerator(correlationCoorY) + textOffsetY + 20)
                .style('font-weight', 'bold')
                .style('font-size', '13px')
                .style('fill', 'white')
                .text(`Ps ${phaseShift.toFixed(2)}`);

            inBetweenPoints.forEach(element => {
                const correlationCoorY = element.corelation ? element.corelation : 0;

                this.gainInner
                    .append('circle')
                    .attr('cx', this.xAxisGainGenerator(element.time))
                    .attr('cy', this.yAxisGainGenerator(correlationCoorY))
                    .attr('r', 7)
                    .attr('fill', 'red')
                    .style('stroke', 'white')
                    .attr('transform', `translate(${this.padding.right * 2.5},${0})`);
    
                this.gainInner
                    .append('text')
                    .attr('x', this.xAxisGainGenerator(element.time) + textOffsetX)
                    .attr('y', this.yAxisGainGenerator(correlationCoorY) - textOffsetY)
                    .style('font-family', 'DMSans')
                    .style('font-weight', 'bold')
                    .style('font-size', '12px')
                    .style('fill', 'white')
                    .text(`${(element.corelation * 100)?.toFixed(1)}%`);
            });
        });
    }

    private mapChartNumber(which: string) {
        switch(which) {
            case 'First': 
                return 0;
            case 'Second': 
                return 1;
            case 'Fourth': 
                return 2;
        }
    }
}
