import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../general/config.service';
import { Observable } from 'rxjs';
import { ITestDiagnoses } from '../../../../../common/interfaces/diagnosis.model';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';

@Injectable()
export class DiagnosisService {
    constructor(private configService: ConfigService, private http: HttpClient) {}
    getDiagnoses(testType: TEST_TYPE): Observable<string[]> {
        const url = `${this.configService.backendUrl}/giagnosis/${testType}`;
        return this.http.get<string[]>(url);
    }
    update(diagnoses: ITestDiagnoses): Observable<string[]> {
        const url = `${this.configService.backendUrl}/giagnosis/update`;
        return this.http.post<string[]>(url, diagnoses);
    }
}
