import { Component, ElementRef, forwardRef, HostListener, Input, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, startWith, throttleTime } from 'rxjs/operators';

@Component({
    selector: 'autocomplete',
    template: require('./autocomplete.component.html'),
    styles: [require('./autocomplete.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true,
        },
    ],
})
export class AutocompleteComponent implements ControlValueAccessor {
    @ViewChildren('listItem') itemsList: QueryList<ElementRef>;
    @Input() private label: string;
    @Input() private scrollTo: string;
    @Input() private inputClass: string = 'normalInput';
    @Input() public isScrollable: boolean = true;
    private preselected: number = null; // index of presentedValue
    public opened: boolean = false;
    private filteredList = new BehaviorSubject<string[]>([]);
    private term = new FormControl({ value: '', disabled: false });
    private _list: string[] = [];
    private disabled: boolean = false;
    private onChange = (_: any) => {};
    private onTouched = () => {};

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.term.valueChanges
            .pipe(
                startWith(this._list),
                throttleTime(20),
                map(() => this.term.value),
                distinctUntilChanged(),
                map(search => {
                    if (search) {
                        return this._list.filter(item => {
                            return new RegExp(search, 'gi').test(item);
                        });
                    } else {
                        return this._list;
                    }
                })
            )
            .subscribe(filteredItems => {
                this.preselected = null;
                this.filteredList.next(filteredItems);
            });
    }
    @Input() set list(list: string[] | Object) {
        if (Array.isArray(list)) {
            this._list = list;
        } else {
            this._list = Object.values(list);
        }
        this.filteredList.next(this._list);
    }
    @HostListener('document:click', ['$event']) clickout(event: any) {
        if (this.elementRef.nativeElement.contains(event.target)) {
        } else {
            if (this.opened) {
                if (!this._list.includes(this.term.value)) {
                    this.term.setValue('');
                }
                this.preselected = null;
                this.opened = false;
            }
        }
    }
    public onClick(btn: MouseEvent) {
        this.opened = !this.opened;
        if (this.opened) {
            this.scrollToMiddle();
            this.preselected = this._list.indexOf(this.term.value);
        }
    }
    writeValue(value: string): void {
        if (value) {
            this.term.setValue(value);
        } else {
            this.term.setValue('');
        }
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.disabled ? this.term.disable() : this.term.enable();
    }
    private select(value: string, stopPropagation?: boolean) {
        if (this.opened) {
            this.onTouched();
            this.term.setValue(value);
            if (!stopPropagation) {
                this.onChange(this.term.value);
            }
        }
        this.opened = !this.opened;
    }
    private scrollToMiddle(): void {
        const targetItem: any = this.itemsList.find(item => item.nativeElement.innerText.trim() === this.scrollTo)?.nativeElement;
        if (this.scrollTo && targetItem) {
            setTimeout(() => {
                targetItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 250);
        }
    }
}
