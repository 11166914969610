import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DEVICE } from '../../../../../../commonout/enum/device';
import { ENVIRONMENTS } from '../../../../../../commonout/enum/environments';
import { THEME } from '../../../../../../commonout/enum/theme.enum';
import { IDeviceSettings } from '../../../../../../commonout/interfaces/deviceSetting.interface';
import { IAdminFrontendConfigs, IUserFrontendConfigs } from '../../../../../common/interfaces/frontend-config.interface';
import { IResponse, RESPONSE_STATUS } from '../../../../../common/interfaces/response.model';
const conf = require('../../../../../dist/saccadioweb/common/properties/frontend.properties.json');

@Injectable()
export class ConfigService {
    private _urls: any;
    private _configs: BehaviorSubject<IAdminFrontendConfigs> = new BehaviorSubject<IAdminFrontendConfigs>(null);
    public connectedEnvironment: BehaviorSubject<IDeviceSettings[]>;
    public commonServerConnectionStatus: BehaviorSubject<boolean>;
    public version: string;
    public mode: ENVIRONMENTS;
    public theme: BehaviorSubject<THEME>;
    public feedbackService: boolean;
    public localization: string;
    public source: string;
    public exportSource: string;
    public synchronizationRole: string;
    public communicator: {
        isEnabled: boolean;
        link: string;
    };
    public isDevTestsShown: BehaviorSubject<boolean>;
    public ICD: {
        apiServerUrl: string;
        autoBind: boolean;
        height: number;
        icdMinorVersion: string;
        apiSecured: boolean;
    };
    constructor(private http: HttpClient) {
        this._urls = {
            backendUrl: conf.server.protocol + '://' + conf.server.IP + ':' + conf.server.port + '/api/v1',
            backendSocketPoint: conf.server.protocol + '://' + conf.server.IP + ':' + conf.server.port,
            backendStatic: conf.server.protocol + '://' + conf.server.IP + ':' + conf.server.port + '/static',
        };
        this.connectedEnvironment = new BehaviorSubject<IDeviceSettings[]>([]);
        this.commonServerConnectionStatus = new BehaviorSubject<boolean>(false);
        this.theme = new BehaviorSubject<THEME>(null);
        this.isDevTestsShown = new BehaviorSubject<boolean>(false);
    }

    public getDeviceSettings(device: DEVICE): Observable<IDeviceSettings> {
        return this.connectedEnvironment.pipe(map(env => env.find(d => d.type === device)));
    }

    public get camState(): boolean {
        return this.connectedEnvironment.value.find(e => e.type === DEVICE.HAPLO).isEnabled;
    }

    get backendUrl(): string {
        return this._urls.backendUrl;
    }

    get backendSocketPoint(): string {
        return this._urls.backendSocketPoint;
    }

    get backendStatic(): string {
        return this._urls.backendStatic;
    }

    public load(): Promise<void> {
        return new Promise<void>((res, rej) => {
            try {
                const url = `${this._urls.backendUrl}/admin/config`;
                this.http
                    .get<IUserFrontendConfigs>(url, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    .pipe(
                        catchError(error => {
                            throw error;
                        })
                    )
                    .subscribe((configs: IUserFrontendConfigs) => {
                        this.connectedEnvironment.next(configs.connectedEnvironment);
                        this.version = configs.version;
                        this.mode = configs.mode;
                        this.localization = configs.localization;
                        this.theme.next(configs.theme);
                        this.feedbackService = configs.feedbackService;
                        this.source = configs.source;
                        this.exportSource = configs.exportSource;
                        this.synchronizationRole = configs.synchronizationRole;
                        this.communicator = configs.communicator;
                        this.ICD = configs.ICD;
                        res();
                    });
            } catch (error) {
                rej(error);
            }
        });
    }

    public update(config: IAdminFrontendConfigs): Observable<IResponse> {
        const url = `${this._urls.backendUrl}/admin/config`;
        return this.http.post<IResponse>(url, config).pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError('Something bad happened; please try again later.');
            }),
            map(responce => {
                if (responce.status === RESPONSE_STATUS.SUCCESS) {
                    this._configs.next(config);
                }
                return responce;
            })
        );
    }

    public async changeTheme(): Promise<void> {
        const url = `${this._urls.backendUrl}/admin/config/change-theme`;
        this.theme.next(await (await this.http.get<{ theme: THEME }>(url).toPromise()).theme);
    }
}
