// @ts-nocheck
import { Target } from '../../lib/types'
import { Layout } from '../../lib/Layout'
import { BooleanControl } from '../../lib/new/BooleanControl'

// import * as d3 from 'd3';

interface Params {
  target: Target
  status: number,
  originalStatus: number,
  onChoice: (choice: number) => void,
}

export function PopupControls(p: Params) {
  let g = new Gs(p.target)

  let dy = 40 + 10 - 10
  let dx = 10 //- 30

  let option1 = `Keep in data set`
  let option2 = `Remove from data set`

  let checked1 = p.status === 0
  let checked2 = p.status === 1

  // if (p.originalStatus === 0) option1 += ' *'
  // if (p.originalStatus === 1) option2 += ' *'

  let fontSize = 18
  let gap = 30
  BooleanControl({
    text: option1,
    checked: checked1,
    target: g.take,
    area: new Layout(dx, 0+dy, dx+100, 50+dy, ''),
    onClick: () => { if (!checked1) p.onChoice(0) },
    fontSize,
  })
  BooleanControl({
    text: option2,
    checked: checked2,
    target: g.take,
    area: new Layout(dx, 0+gap+dy, dx+100, 50+gap+dy, ''),
    onClick: () => { if (!checked2) p.onChoice(1) },
    fontSize,
  })
}

class Gs {
  id = 0
  constructor(private target: Target) {}

  get take() {
    this.id += 1
    let target = this.target
      .selectAll(`.g-${this.id}`)
      .data([null])
      .join('g')
      .attr('class', `g-${this.id}`)
    return target
  }
}
