import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    SaccadeHorizontalChartComponent,
    SaccadeMovementChartComponent,
    SmoothSaccadeRealTimeChartComponent,
    SmoothSaccadeVelocityChartComponent,
    SmoothSaccadeVerticalChartComponent,
} from '../../..';
import { TEST_TYPE } from '../../../../../../../../commonout/enum/test-type';
import { IChartEdit } from '../../../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../../../commonout/interfaces/charts.model';
import { IChartData, IPursuitSaccadesCamMessage } from '../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { ISmoothPursuitTestCamMessage } from '../../../../../../../common/interfaces/smoothPursuitTestMessage.interface';
import { SaccadeChartService } from '../../../../_services/chartServices/pursuitSaccadesServices/saccadesTestServices/saccadesChartService';
import { SmoothPursuitChartService } from '../../../../_services/chartServices/pursuitSaccadesServices/smoothPursuitTestServices/smoothPursuitChartService';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { BulbicamChartComponent } from '../haploChart.component';
import { HORIZONTAL_LINE_COLOR, VERTICAL_VELOCITY_LINE_COLOR } from './chartStyles.constants';
import { SmoothPursuitChartComponent } from './smooth-pursuit-chart/smooth-pursuit-chart.component';

export enum CHART_TYPE {
    SMOOTH_PURSUIT,
    SACCADES,
}

@Component({
    selector: 'app-smooth-saccade-merged-test-chart',
    template: require('./smooth-saccade-merged-test-chart.component.html'),
    styles: [require('./saccade-pursuit-charts-styles.scss')],
})
export class SmoothSaccadeMergedTestChartComponent extends BulbicamChartComponent implements OnInit, OnDestroy {
    @ViewChild('realTimeElement') realTimeChild: SmoothSaccadeRealTimeChartComponent;
    @ViewChild('horizontalVelocityElement') horizontalVelocityChild: SmoothSaccadeVelocityChartComponent;
    @ViewChild('verticalVelocityElement') verticalVelocityChild: SmoothSaccadeVelocityChartComponent;
    @ViewChild('verticalMovementElement') verticalMovementChild: SmoothSaccadeVerticalChartComponent;
    @ViewChild('horizontalMovementElement') horizontalMovementChild: SaccadeHorizontalChartComponent;
    @ViewChild('movementElement') movementChild: SaccadeMovementChartComponent;
    @ViewChild('smoothPursuitElement') smoothChild: SmoothPursuitChartComponent;

    proSaccadeAverageRatio: number;

    public frames: IPursuitSaccadesCamMessage[] | ISmoothPursuitTestCamMessage[];
    public hideSaccades: boolean;
    public hideSmoothPursuit: boolean;

    private saccadesChartData: IChartData = { framesData: [] };
    private subscriptions: Subscription[] = [];

    public readonly verticalVelocityLine = VERTICAL_VELOCITY_LINE_COLOR;
    public readonly horizontalVelocityLine = HORIZONTAL_LINE_COLOR;

    constructor(private chartService: SaccadeChartService, private smoothPursuitService: SmoothPursuitChartService, private camService: BulbicamService) {
        super();
    }

    ngOnInit() {
        const smoothSub = this.smoothPursuitService.charData$.subscribe(data => {
            if (data.chartType === 'real-time' && data.frames.length !== 0) {
                this.smoothChild.smoothComponentRender$.next({
                    horizontalOD: data.horizontalOD,
                    stimuliOD: data.stimuliOD,
                    horizontalOS: data.horizontalOS,
                    stimuliOS: data.stimuliOS,
                    verticalOD: data.verticalOD,
                    verticalOS: data.verticalOS,
                    zeroOD: data.zeroOD,
                    zeroOS: data.zeroOS,
                });

                if (data.frames[data.frames?.length - 1].message_type === MESSAGE_TYPE.STOP_TEST) {
                    this.setCamData(data.frames);
                }
            }

            if (data.chartType === 'cleared') {
                this.smoothChild.clear();
            }

            if (data.chartType === 'recorded') {
                this.smoothChild.buildRecordedCharts(data);
            }
        });

        const sub = this.chartService.charData$.subscribe(data => {
            if (data.chartType === 'real-time' && data.framesData.length !== 0) {
                if (this.saccadesChartData?.framesData.length !== 0) {
                    this.saccadesChartData.framesData = this.saccadesChartData?.framesData.concat(data.framesData);
                } else {
                    this.saccadesChartData.framesData.push(...data.framesData);
                }
                const isLast = data.framesData[data.framesData?.length - 1].message_type === MESSAGE_TYPE.STOP_TEST;

                data.framesData.pop();
                this.buildRealTimeChart(data);

                if (isLast) {
                    this.setCamData(this.saccadesChartData.framesData);
                }
            }

            if (data.chartType === 'cleared') {
            }

            if (data.chartType === 'recorded') {
                this.buildSaccadesCharts(data);
            }
        });

        this.subscriptions.push(sub, smoothSub);
    }

    ngOnDestroy(): void {
        this.smoothPursuitService.charData$ = new BehaviorSubject({
            chartType: 'no-content',
        });
        this.chartService.charData$ = new BehaviorSubject({
            chartType: 'no-content',
        });
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public addData(frames: ICamMessage[]): void {
        if (frames.find(f => f.target === TEST_TYPE.SACCADES)) {
            this.showSaccades(CHART_TYPE.SACCADES);
            this.chartService.addData(frames);
        } else if (frames.find(f => f.target === TEST_TYPE.SMOOTH_PURSUIT)) {
            this.showSaccades(CHART_TYPE.SMOOTH_PURSUIT);
            this.smoothPursuitService.addData(frames as ISmoothPursuitTestCamMessage[]);
        }
    }

    public setCamData(frames: IPursuitSaccadesCamMessage[]): void {
        this.frames = frames;

        if (frames.find(f => f.target === TEST_TYPE.SACCADES)) {
            this.showSaccades(CHART_TYPE.SACCADES);
            this.chartService.getChartData([...frames] as IPursuitSaccadesCamMessage[]);
        } else if (frames.find(f => f.target === TEST_TYPE.SMOOTH_PURSUIT)) {
            this.showSaccades(CHART_TYPE.SMOOTH_PURSUIT);
            if (this.camService.hasProperRatio()) {
                if (frames[0].ratio) {
                    this.proSaccadeAverageRatio = frames[0].ratio;
                } else {
                    this.proSaccadeAverageRatio = 
                        this.camService.proSaccadesRatioValue[this.camService.proSaccadesRatioValue.length - 1];
                    frames[0].ratio = this.proSaccadeAverageRatio;
                    this.updateCamMessage(frames[0]);
                }

                this.smoothPursuitService.averageRatio$.next(this.proSaccadeAverageRatio);
                this.smoothPursuitService.getChartData(frames as ISmoothPursuitTestCamMessage[]);
            }
        }

        this.saccadesChartData = { framesData: [] };
    }

    public setEdits(edits: IChartEdit[]): void {}
    public clearData(): void {
        this.chartService.clearData();
        this.smoothPursuitService.clearData();
    }

    private buildRealTimeChart(data: IChartData): void {
        this.realTimeChild.buildRealTimeChart(data.framesData);
    }

    private buildSaccadesCharts(data: IChartData): void {
        this.realTimeChild.hideChart();

        const verticalChartData: IChartData = {
            movementFrames: data.verticalFrames,
            verticalResults: data.verticalResults,
        };
        const horizontalChartData: IChartData = {
            movementFrames: data.horizontalFrames,
            horizontalResults: data.horizontalResults,
        };

        const verticalVelocityChartData: IChartData = {
            movementFrames: data.verticalVelocityFrames,
            verticalResults: data.verticalResults,
            horizontalResults: data.horizontalResults,
        };
        const horizontalVelocityChartData: IChartData = {
            movementFrames: data.horizontalVelocityFrames,
            verticalResults: data.verticalResults,
            horizontalResults: data.horizontalResults,
        };

        const movementChartData: IChartData = {
            framesData: [...this.frames] as IPursuitSaccadesCamMessage[],
            movementFrames: data.movementFrames,
            separatedFrames: { verticalFrames: [...data.verticalFrames], horizontalFrames: [...data.horizontalFrames] },
            pursuitSaccadesTestResults: data.pursuitSaccadesTestResults,
        };

        this.verticalVelocityChild.buildRecordedChart(verticalVelocityChartData);
        this.horizontalVelocityChild.buildRecordedChart(horizontalVelocityChartData);
        this.verticalMovementChild.buildRecordedChart(verticalChartData);
        this.horizontalMovementChild.buildRecordedChart(horizontalChartData);
        this.movementChild.buildRecordedChart(movementChartData);
    }

    private showSaccades(type: CHART_TYPE): void {
        if (type === CHART_TYPE.SACCADES) {
            this.hideSaccades = false;
            this.hideSmoothPursuit = true;
        } else {
            this.hideSaccades = true;
            this.hideSmoothPursuit = false;
        }
    }
}
