// @ts-nocheck
import { AData, AChart2, AxisLeft, AxisBottom, Area, AxisRight, Target, ChartLines } from '..';

// NOTE: experimental

interface Params {
    area: Area;
    series: Series[];
    g: { g: Target };
}

export class Render {
    constructor(private p: Params) {}

    // a bit constained typing
    // also old order of params
    _coloring?: (point: XYT, ds: Series) => string;
    coloring(f: any) {
        this._coloring = f;
    }

    // NOTE: duplication to have types inferred...
    aData = AData<XYT>({
        series: this.p.series,
        positioning: (x) => x,
        coloring: this._coloring || ((x) => ''),
    });
    ctx = AChart2<XYT>({
        aData: this.aData as any, // ...
        ...this.place,
    });

    // NOTE: duplication to have types inferred...
    updateAData() {
        this.aData = AData<XYT>({
            series: this.p.series,
            positioning: (x) => x,
            coloring: this._coloring || ((x) => ''),
        });
        this.updateCtx();
    }
    updateCtx() {
        this.ctx = AChart2<XYT>({
            aData: this.aData as any, // ...
            ...this.place,
        });
    }

    axisLeft() {
        AxisLeft({
            ...this.ctx,
            ...this.place,
        });
    }

    axisBottom(p: AxisParams = {}) {
        AxisBottom({
            ...this.ctx,
            ...this.place,
            ...p,
        });
    }

    axisRight(p: AxisParams = {}) {
        AxisRight({
            ...this.ctx,
            ...this.place,
            ...p,
        });
    }

    lines(p: LinesParams = {}) {
        let style: any = {
            class: this._coloring ? '' : 'sWhite2',
        };
        if (p.size) style.lineSize = p.size;
        if (p.dasharray) style.dasharray = p.dasharray;
        ChartLines({
            ...this.ctx,
            ...this.place,
            styleParams: { override: style },
        });
    }

    aLine(p: ALineParams) {
        let parentAData = this.aData;
        let xDomain = parentAData.xScale.domain();

        let y = p.y || 0;
        let minX = xDomain[0] || 0;
        let maxX = xDomain[1] || 0;

        let series = [
            {
                id: 0,
                dataPoints: [
                    {
                        x: minX,
                        y,
                    },
                    {
                        x: maxX,
                        y,
                    },
                ],
            },
        ];
        let aData = AData<XYT>({
            series,
            positioning: (x) => x,
            coloring: (x) => '',
        });
        aData = { ...aData, yScale: parentAData.yScale, xScale: parentAData.xScale };

        let ctx = AChart2<XYT>({
            aData: aData as any,
            ...this.place,
        });
        let style: any = {
            class: 'sWhite2',
        };
        if (p.size) style.lineSize = p.size;
        ChartLines({
            ...ctx,
            ...this.place,
            styleParams: { override: style },
        });
    }

    get place() {
        return {
            area: this.area,
            target: this.g,
        };
    }

    get area() {
        return this.p.area;
    }

    get g() {
        return this.p.g.g;
    }
}

interface Series {
    id: number;
    dataPoints: XYT[];
}

interface XYT {
    x: number;
    y: number;
    text?: string;
}

interface AxisParams {
    grid?: boolean;
    arrow?: string;
    ticks?: number;
}

interface LinesParams {
    size?: number;
    dasharray?: string;
}

interface ALineParams {
    // x: number
    y: number;
    size?: number;
}
