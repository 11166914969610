import { Component, HostBinding, OnDestroy, OnInit, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../../_services/general/modal.service';
import { FocusDirective } from '../../../../_directives';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'modal',
    template: require('./simple-question-modal.component.html'),
    styles: [require('./simple-question-modal.component.scss')],
})
export class SimpleQuestionModalComponent implements OnInit, OnDestroy {
    @ViewChildren(FocusDirective) private controls: QueryList<FocusDirective>;
    @HostBinding('class.isVisible') private question: string;
    private listener: any;
    private activeControl: number;
    private subscription: Subscription[] = [];

    constructor(private modalService: ModalService, private renderer: Renderer2) {}

    ngOnInit() {
        this.subscription.push(
            this.modalService.questionAnnounced$
                .pipe(
                    tap(() => {
                        this.activeControl = 2;
                        this.controls.find((e) => e.focus === this.activeControl).elementRef.nativeElement.focus();
                        this.listener = this.renderer.listen('document', 'keydown', this.keyboardHandler.bind(this));
                    })
                )
                .subscribe((q) => (this.question = q))
        );
    }

    ngOnDestroy() {
        this.subscription.map((s) => s.unsubscribe());
    }

    apply(): void {
        this.question = null;
        this.listener();
        this.modalService.answer = true;
    }

    cancel(): void {
        this.question = null;
        this.listener();
        this.modalService.answer = false;
    }

    private keyboardHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.cancel();
        } else if (event.key === 'Tab' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            event.stopPropagation();
            this.activeControl = this.activeControl === 1 ? 2 : 1;
            this.controls.find((e) => e.focus === this.activeControl).elementRef.nativeElement.focus();
        }
    }
}
