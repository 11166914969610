import { Directive, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[focus]',
})
export class FocusDirective {
    @Input() public focus: number;
    @Output() public focused: EventEmitter<number> = new EventEmitter<number>();
    constructor(public elementRef: ElementRef) {}
    @HostListener('click', ['$event']) private clickEvent(event: MouseEvent) {
        this.focused.emit(this.focus);
    }
}
