import { FunctionalScreeningBulbicamMeasuredDataFrontend } from '../../measuredData/BulbiCAM/functionalScreeningBulbicamMeasuredDataFrontend.class';
import { TestRemarkFrontend } from '../testRemarkFrontend';

export class FunctionalScreeningBulbicamTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof FunctionalScreeningBulbicamMeasuredDataFrontend = FunctionalScreeningBulbicamMeasuredDataFrontend;
    public measurements: FunctionalScreeningBulbicamMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
