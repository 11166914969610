// @ts-nocheck
import { Target } from '../../lib/types'
import { nonInteractive, makeInteractive } from '../../lib/util'
import { drawCrossWithRadius } from '../../lib/drawable'
import { DrawAnimated } from '../../lib/DrawAnimated'
import * as d3 from 'd3'
import { PopupControls } from './PopupControls'
import { PopupSave } from './PopupSave'
import { PopupLog } from './PopupLog'
import { applyFont } from './style'
import { Layout } from '../../lib/Layout'
import { TextBlock } from '../../lib/new/TextBlock'

interface Variable<T> {
  set(x: T): void
  get(): T
}


interface RequiredProps {
  target: Target
  centerColor: string  // this.color[this.color.length - 1])
  closeCrossClick: () => void
  formStatus: Variable<number|undefined>
  pointData: any
  drawAnimated: Variable<DrawAnimated|undefined>
  drawAnimated2: Variable<DrawAnimated|undefined>
  refresh: () => void
  id: number
  isOutlier: boolean
  originalIsOutlier: boolean
  changePoint: (id: number, value: number) => void
  logItems: any
}

let defaultProps = {
  show: true,
  roundCorner: 10,
  width: 450 + 100,
  height: 450 + 100,
  lineWidth: 1,
  crossPadding: 21,
  crossRadius: 8,
  x: 0,
  y: 0,
  timestampScale: 10000,
  minRawDataValue: -75,
  maxRawDataValue: 75,
  fixationRadius: 3,
  title: '',
}
type OptionalProps = Partial<typeof defaultProps>
type InputProps = RequiredProps & OptionalProps
type FinalProps = RequiredProps & Required<OptionalProps>

export function Popup(got: InputProps) {
  let p: FinalProps = { ...defaultProps, ...got }
  let gRoot = p.target
    .selectAll('.Popup').data([null])
    .join('g').attr('class', 'Popup')
    .attr('transform', `translate(${p.x}, ${p.y})`)

  if (p.show) {
    gRoot
      .style('opacity', 1)
      .call(makeInteractive)
  } else {
    gRoot
      .style('opacity', 0)
      .call(nonInteractive)
  }

  gRoot
    .selectAll('rect')
    .data([null])
    .join('rect')
    // .attr('id', 'tooltip')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', p.width)
    .attr('height', p.height)
    .attr('rx', p.roundCorner)
    .attr('ry', p.roundCorner)
    // .style('fill', 'none')
    // .style('stroke', 'none')
    // .style('stroke-dasharray', this.makeCorners(10, this.tooltipWidth, this.tooltipHeight))
    .attr('class', 'layerBg')

  let crossMargin = 25
  let crossTopMargin = 50
  let deltaTop = crossTopMargin - crossMargin
  // Vertical central line
  gRoot
      .selectAll('.vline').data([null])
      .join('line').attr('class', 'vline')
      // .attr('id', 'tooltipVertical')
      .attr('x1', p.width / 2)
      .attr('y1', crossTopMargin)
      .attr('x2', p.width / 2)
      .attr('y2', p.height - crossMargin)
      .attr('stroke-width', 1)
      // .style('stroke', 'none');

  let crossHairCenterX = p.width / 2 //+ deltaTop
  let crossHairCenterY = p.height / 2 //+ deltaTop

  // Horizontal central line
  gRoot
      .selectAll('.hline').data([null])
      .join('line').attr('class', 'hline')
      // .attr('id', 'tooltipHorizontal')
      .attr('x1', crossMargin)
      .attr('y1', crossHairCenterY)
      .attr('x2', p.width - crossMargin)
      .attr('y2', crossHairCenterY)
      .attr('stroke-width', 1)
      // .style('stroke', 'none');

  // sector
  let sectorLength = p.width - crossMargin * 2 - crossHairCenterX
  sectorLength *= 0.5
  let sectorX2 = crossHairCenterX + sectorLength
  gRoot
      .selectAll('.sector1').data([null])
      .join('line').attr('class', 'sector1 sWhite')
      // .attr('id', 'sectorLine1')
      .attr('x1', crossHairCenterX)
      .attr('y1', crossHairCenterY)
      .attr('x2', sectorX2)
      .attr('y2', crossHairCenterY)
      .attr('stroke-width', 1)
      .style('transform-origin', `${crossHairCenterX}px ${crossHairCenterY}px`)
      .style('transform', 'rotate(0deg)')

  gRoot
      .selectAll('.sector2').data([null])
      .join('line').attr('class', 'sector2 sWhite')
      // .attr('id', 'sectorLine2')
      .attr('x1', crossHairCenterX)
      .attr('y1', crossHairCenterY)
      .attr('x2', sectorX2)
      .attr('y2', crossHairCenterY)
      .attr('stroke-width', 1)
      .style('transform-origin', `${crossHairCenterX}px ${crossHairCenterY}px`)
      .style('transform', 'rotate(180deg)')

  gRoot
      .selectAll('.center').data([null])
      .join('circle').attr('class', 'center')
      // .attr('id', 'tooltipHorizontal')
      .attr('r', 10)
      .attr('cx', p.width / 2)
      .attr('cy', p.height / 2)
      // .attr('class', 'fGreen2')
      .style('stroke', 'none')
      .style('fill', p.centerColor)
      // this.color[this.color.length - 1])
      // .attr('stroke-width', 1)
      // .style('stroke', 'none');

  let textX = p.width / 2 // - 50 - 37
  let textY = 20 //l30 +5 //+11
  gRoot
      .selectAll('.top-text').data([null])
      .join('g').attr('class', 'top-text')
      .attr('id', 'topText')
      .attr('transform', `translate(${textX}, ${textY})`)
  // gRoot
  //     .selectAll('.top-text').data([null])
  //     .join('text').attr('class', 'top-text layerText')
  //     // .attr('transform', 'translate(175, 20)')
  //     .attr('transform', `translate(${textX}, ${textY})`)
  //     .attr('id', 'topText')
  //     .style('text-anchor', 'start')
  //     .style('font-size', '18px')
  //     // .attr('class', 'layerText')
  //     .call(applyFont)

  gRoot
      .selectAll('.status-icon').data([null])
      .join('g').attr('class', 'status-icon')
      .attr('transform', `translate(${textX - 18}, ${textY - 7})`)
      // .attr('id', 'itemStatus')

  gRoot
      .selectAll('.line-shadow').data([null])
      .join('path').attr('class', 'line-shadow sWhite2')
      .attr('id', 'tooltipLineShadow')
      // .attr('class', 'sWhite2')
      .attr('fill', 'none')
      // .attr('stroke', 'none')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 0.6) //this.tooltipLineWidth - 0.3)
  gRoot
      .selectAll('.line-shadow2').data([null])
      .join('path').attr('class', 'line-shadow2 sWhite2')
      .attr('id', 'tooltipLineShadow2')
      // .attr('class', 'sWhite2')
      .attr('fill', 'none')
      // .attr('stroke', 'none')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 0.6) //this.tooltipLineWidth - 0.3)

    gRoot
      .selectAll('.line').data([null])
      .join('path').attr('class', 'line sYellow2')
      .attr('id', 'tooltipLine')
      // .attr('class', 'sYellow2')
      .attr('fill', 'none')
      // .attr('stroke', 'none')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', p.lineWidth)
      // .attr('stroke-width', 0.5);

    gRoot
      .selectAll('.dots').data([null])
      .join('g').attr('class', 'dots fYellow2')
      // .append('g')
      .attr('id', 'tooltipDots')
      // .attr('class', 'fYellow2')
      // .attr('stroke', 'none')

    gRoot
      .selectAll('.dots2').data([null])
      .join('g').attr('class', 'dots2 fGreen')
      // .append('g')
      .attr('id', 'tooltipDots2')
      // .attr('class', 'fYellow2')
      // .attr('stroke', 'none')

    gRoot
      .selectAll('.focusPoint').data([null])
      .join('circle').attr('class', 'focusPoint')
      // .append('circle')
      .attr('id', 'tooltipFocusPoint')
      // .attr('fill', 'none');

    // this.tooltipGroup
    //   .selectAll('.focus1').data([null])
    //   .join('circle').attr('class', 'focus1')
    //   // .attr('id', 'tooltipFocusPoint-1')
    //   .attr('fill', 'none');
    // this.tooltipGroup
    //   .selectAll('.focus2').data([null])
    //   .join('circle').attr('class', 'focus2')
    //   // .append('circle')
    //   // .attr('id', 'tooltipFocusPoint-2')
    //   .attr('fill', 'none');

  let crossPadding = p.crossPadding
  let crossRadius = p.crossRadius
  let crossG = gRoot
    .selectAll('.crossG').data([null])
    .join('g').attr('class', 'crossG')
    .attr('transform', `translate(${p.width - crossRadius - crossPadding}, ${crossPadding + crossRadius})`)
    // .attr('id', 'tooltipCloseCross')

  crossG
    .selectAll('.cross').data([null])
    .join('path').attr('class', 'cross sText')
    // .append('path')
    // .attr('class', 'sText')
    .attr('stroke-width', 2)
    // .attr('fill', 'none')
    .attr('d', drawCrossWithRadius(crossRadius))

  let areaRadius = crossRadius + 10
  crossG
    .selectAll('.cross-area').data([null])
    .join('rect').attr('class', 'cross-area')
    .style('opacity', 0)
    .attr('x', -areaRadius)
    .attr('y', -areaRadius)
    .attr('width', 2 * areaRadius)
    .attr('height', 2 * areaRadius)
    .on('mousedown', () => {
        p.closeCrossClick()
    })
    .attr('cursor','pointer')

  let gPopup = gRoot
    .selectAll('.gPopup').data([null])
    .join('g').attr('class', 'gPopup')

  let status = p.isOutlier ? 1 : 0
  let originalStatus = p.originalIsOutlier ? 1 : 0
  let id = p.id

  renderPopupControls(gPopup, p, status, originalStatus, id)
  renderALine(gRoot, p.pointData, p)
}

function renderALine(gRoot: Target, pointData: any, p: FinalProps) {
  let rawData = pointData ? pointData.traces : []
  let xScale = d3
      .scaleLinear()
      .domain([p.minRawDataValue, p.maxRawDataValue])
      .range([0, p.width])
      // .clamp();

  let yScale = d3
      .scaleLinear()
      .domain([p.minRawDataValue, p.maxRawDataValue])
      .range([0, p.height])
      // .clamp();
  let rawDataLine = d3
      // .line()
      .line<{x:number, y:number}>()
      .defined((d: any) => {
          if (d.x == null) return false
          if (d.y == null) return false
          if (Number.isNaN(d.x)) return false
          if (Number.isNaN(d.y)) return false

//                 if (d.x < this.minRawDataValue || d.y < this.minRawDataValue) {
//                     return false;
//                 }

//                 if (d.x > this.maxRawDataValue || d.y > this.maxRawDataValue) {
//                     return false;
//                 }

          return true;
      })
      .x((d: any) => {
          return xScale(d.x);
      })
      .y((d: any) => {
          return yScale(d.y);
      });

    let lineData: typeof rawData = []

    let fixationPoint: any = null
    rawData.forEach((x:any) => {
      if (x.lineType === 1) {
          fixationPoint = { x: x.x, y: x.y, color: x.lineColor };
          fixationPoint.color = '#eb5757' // design
      } else {
          lineData.push(x);
      }
    })
    let lineData2 = lineData.map((x:any) => {
      return { ...x, x: x.x2, y: x.y2 }
    })
    // XXX: temp
    // lineData2 = lineData2.filter(x => (x.x != null) && (x.y != null))

    let scaledData = lineData.map((x:any) => {
      return {
        ...x,
        timestamp: x.timestamp / p.timestampScale,
      }
    })
    let scaledData2 = lineData2.map((x:any) => {
      return {
        ...x,
        timestamp: x.timestamp / p.timestampScale,
      }
    })
    let prevAnimation = p.drawAnimated.get()
    if (prevAnimation) {
      prevAnimation.destroy()
    }
    let prevAnimation2 = p.drawAnimated2.get()
    if (prevAnimation2) {
      prevAnimation2.destroy()
    }
    gRoot
        .select('#tooltipLineShadow')
        .datum(lineData)
        .attr('d', rawDataLine);
    gRoot
        .select('#tooltipLineShadow2')
        .datum(lineData2)
        .attr('d', rawDataLine);

    if (fixationPoint) {
      gRoot
         .select('#tooltipFocusPoint')
         .attr('cx', xScale(fixationPoint.x))
         .attr('cy', yScale(fixationPoint.y))
         .attr('r', p.fixationRadius)
         .attr('fill', fixationPoint.color);
    }

    // gRoot.select('#topText').text(p.title)
    let textx = 0
    let texty = 0
    let area = new Layout(textx, texty, textx, texty, '')
    TextBlock({
      text: p.title,
      target: gRoot.select('#topText'),
      area,
      styleParams: {
        override: {
          fontSize: 18,
        }
      },
    })

    let drawAnimated = new DrawAnimated(
      scaledData,
      (data: any) => {
        if (gRoot.empty()) return false
        gRoot
            .select('#tooltipLine')
            .datum(data)
            .attr('d', rawDataLine);

        gRoot
            .select('#tooltipDots')
            .selectAll('circle')
            .data(data)
            .join('circle')
            .attr('r',2)
            .attr('cx', (d:any) => xScale(d.x))
            .attr('cy', (d:any) => yScale(d.y))
            // .attr('stroke', '#fa0')
            // .attr('stroke-width', 1.5)
            // .attr('d', rawDataLine);
        return true
      }
    )
    let drawAnimated2 = new DrawAnimated(
      scaledData2,
      (data: any) => {
        if (gRoot.empty()) return false
        gRoot
            .select('#tooltipLine2')
            .datum(data)
            .attr('d', rawDataLine);

        gRoot
            .select('#tooltipDots2')
            .selectAll('circle')
            .data(data)
            .join('circle')
            .attr('r',2)
            .attr('cx', (d:any) => xScale(d.x))
            .attr('cy', (d:any) => yScale(d.y))
            // .attr('stroke', '#fa0')
            // .attr('stroke-width', 1.5)
            // .attr('d', rawDataLine);
        return true
      }
    )
    drawAnimated.start()
    p.drawAnimated.set(drawAnimated)

    drawAnimated2.start()
    p.drawAnimated2.set(drawAnimated2)
}

function renderPopupControls(gPopup: Target, p: FinalProps, status: number, originalStatus: number, id: number) {
    let oldStatus = p.formStatus.get()
    if (oldStatus != null) status = oldStatus

    PopupControls({
      target: gPopup,
      status,
      originalStatus,
      // id,
      onChoice: (choice: any) => {
        p.formStatus.set(choice)
        p.refresh()
        // this.setFormStatus(InputStatus.SEEN - 1) // omg
        // this.changePoint(id, InputStatus.SEEN)
      },
    })
    PopupSave({
      target: gPopup,
      clean: p.formStatus.get() == null,
      onClick: () => {
        let value = p.formStatus.get()
        if (value == null) {
          p.closeCrossClick()
        } else {
          p.changePoint(id, value)
        }
      },
    })
    PopupLog({
      target: gPopup,
      items: p.logItems,
    })
}
