// @ts-nocheck
// more optimized ma - just more straightforward code
// params: value key, result key
function doMa2<T>(xs: T[], wing: number, vk: string, rk: string) {
  let averages = new Array(xs.length)
  let divisors = new Array(xs.length)

  xs.forEach((x, index) => {
    let value = x[vk]
    // let value = x
    let from = Math.max(index - wing, 0)
    let to = Math.min(index + wing, xs.length-1)
    for (let i = from; i <= to; i++) {
      averages[i] = (averages[i] || 0) + value
    }
    divisors[index] = to - from + 1
  })

  xs.forEach((x,i) => {
    x[rk] = averages[i] / divisors[i]
  })
}
function doMa2x2<T>(xs: T[], wing: number, vk: string, rk: string, vk2: string, rk2: string) {
  let averages = new Array(xs.length)
  let divisors = new Array(xs.length)

  let averages2 = new Array(xs.length)

  xs.forEach((x, index) => {
    let value = x[vk]
    let value2 = x[vk2]
    // let value = x
    let from = Math.max(index - wing, 0)
    let to = Math.min(index + wing, xs.length-1)
    for (let i = from; i <= to; i++) {
      averages[i] = (averages[i] || 0) + value
      averages2[i] = (averages2[i] || 0) + value2
    }
    divisors[index] = to - from + 1
  })

  xs.forEach((x,i) => {
    x[rk] = averages[i] / divisors[i]
    x[rk2] = averages2[i] / divisors[i]
  })
}

// possible optimization over sliceMaBothSides to not have data passed around
function doMaBothSides<T>(xs: T[], wing: number, fun: (a:T, b:T[]) => void) {
  let results: T[][] = []
  let tail: T[] = []
  let queue: Tail3<T>[] = []

  xs.forEach(x => {
    ;[...queue].forEach(q => q.push(x)) // does splice inside that

    let slice = tail.concat(x)
    queue.push(new Tail3(wing, slice, (t) => {
      fun(t.item, t.around)

      let index = queue.indexOf(t)
      if (index === -1) return console.error('unexpected')
      queue.splice(index, 1)
    }))

    // for the next cycle
    tail.push(x)
    if (tail.length > wing) tail.shift()
    // if (tail.length > wing) tail = tail.slice(1)
  })

  ;[...queue].forEach(t => t.finish()) // does splice inside that
}

function sliceMaBothSides<T>(xs: T[], wing: number): T[][] {
  let results: T[][] = []
  let tail: T[] = []
  let queue: Tail2<T>[] = []

  xs.forEach(x => {
    queue.forEach(q => q.push(x))
    queue = queue.filter(x => !x.done)

    let slice = tail.concat(x)
    results.push(slice)
    queue.push(new Tail2(wing, slice))

    // for the next cycle
    tail.push(x)
    if (tail.length > wing) tail.shift()
  })

  return results
}

class Tail2<T> {
  constructor(
    private count: number,
    private place: T[],
  ) {}

  get done() {
    return this.count === 0
  }

  push(t: T) {
    if (this.done) return
    this.place.push(t)
    this.count -= 1
  }
}

class Tail3<T> {
  constructor(
    private count: number,
    public around: T[],
    private onFinish: (x:Tail3<T>) => void,
  ) {
    this.item = around[around.length - 1]
  }

  item: T

  finished = false

  push(t: T) {
    if (this.finished) return console.error('unexpected')

    this.around.push(t)
    this.count -= 1
    if (this.count === 0) this.finish()
  }

  finish() {
    if (this.finished) return console.error('unexpected')
    this.finished = true
    this.onFinish(this)
  }
}

export { doMaBothSides, sliceMaBothSides, doMa2, doMa2x2 }
// module.exports = { doMaBothSides, sliceMaBothSides, doMa2 }
