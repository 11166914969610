import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ICamMessage } from '../../../../../../../../../commonout/interfaces/charts.model';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { BulbicamChartComponent } from '../../haploChart.component';
import { MergedAntiSaccadeTestChartComponent } from '../AntiSaccadeChart/anti-saccade-test-chart.component';
import { MemorySaccadeTestChartComponent } from '../MemorySaccadeChart/memory-saccade-test-chart.component';
import { ProSaccadeTestChartComponent } from '../ProSaccadeChart/pro-saccade-test-chart.component';

export enum SUB_TYPES {
    MEMORY_SACCADE = 'MEMORYSACCADE',
    PRO_SACCADE = 'PROSACCADE',
    ANTI_SACCADE = 'ANTISACCADE',
}

@Component({
    selector: 'saccade-merged-test-chart',
    template: require('./saccade-merged-test-chart.component.html'),
    styles: [require('./saccade-merged-test-chart.component.scss')],
})
export class SaccadeMergedBulbicamTestChartComponent extends BulbicamChartComponent implements AfterViewInit, OnDestroy {
    @ViewChild('memorySaccadeChart') private memorySaccadeChart: MemorySaccadeTestChartComponent;
    @ViewChild('proSaccadeChart') private proSaccadeChart: ProSaccadeTestChartComponent;
    @ViewChild('antiSaccadeChart') private antiSaccadeChart: MergedAntiSaccadeTestChartComponent;
    subscriptions: Subscription[] = [];
    chartType = '';
    isDrawn = false;
    firstFrame: ICamMessage | null;
    mapper = new Map([
        ['MEMORYSACCADE', 'memorySaccadeChart'],
        ['PROSACCADE', 'proSaccadeChart'],
        ['ANTISACCADE', 'antiSaccadeChart'],
    ]);

    constructor(private camService: BulbicamService) {
        super();
    }

    ngAfterViewInit(): void {
        let qwe = (d: string) => {
            if (this.firstFrame) {
                if (this.firstFrame.target === SUB_TYPES.PRO_SACCADE && !this.firstFrame?.ratio) {
                    this.updateCamMessage({ ...this.firstFrame, ratio: this.proSaccadeChart.averageRatio, accepted: d });
                } else if (this.firstFrame.target === SUB_TYPES.ANTI_SACCADE && !this.firstFrame?.ratio) {
                    this.updateCamMessage({ ...this.firstFrame, ratio: this.antiSaccadeChart.proSaccadeAverageRatio, accepted: d });
                } else {
                    this.updateCamMessage({ ...this.firstFrame, accepted: d });
                }
            }
        };

        const proSub = this.proSaccadeChart.accepted$.subscribe(qwe);
        const memorySub = this.memorySaccadeChart.accepted$.subscribe(qwe);
        const antiSub = this.antiSaccadeChart.accepted$.subscribe(qwe);
        this.subscriptions.push(...[proSub, memorySub, antiSub]);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.proSaccadeChart.clearData();
        this.memorySaccadeChart.clearData();
        this.antiSaccadeChart.clearData();
        this.isDrawn = false;
        this.firstFrame = null;
    }

    public clearData(): void {
        this.proSaccadeChart.clearData();
        this.memorySaccadeChart.clearData();
        this.antiSaccadeChart.clearData();
        this.isDrawn = false;
        this.firstFrame = null;
    }

    // пока не парься
    public setEdits(edits: any): void {}
    // функция для внесения данных частично (передача данных с кама в реальном времени)
    public async addData(frames: ICamMessage[]): Promise<void> {
        if (frames[0].message_type === 0) {
            this.firstFrame = frames[0];
            this.chartType = frames[0].target;
        }

        if (!this.isDrawn) {
            this.isDrawn = true;
            this[this.mapper.get(this.chartType)].initializeChart('real-time');
            this[this.mapper.get(this.chartType)].drawAxis();
        }

        this?.[this.mapper.get(this.chartType)].addData(frames);
    }
    // функция для внесения данных в полном объеме за раз (передача данных из БД)
    public setCamData(frames: ICamMessage[]): void {
        if (frames[0].message_type === 0) {
            this.firstFrame = frames[0];
        }

        this.chartType = frames[0].target;
        this?.[this.mapper.get(this.chartType)].setCamData(frames);
    }
}
