import { ICamMessage } from "../../../commonout/interfaces/charts.model";

export interface ISmoothPursuitChartData {
    chartType?: string,
    frames?: ISmoothPursuitTestCamMessage[],
    gainChartData?: GainChartData,
    calibratedData?: CalibratedData[],
    parsedStimuli?: SmoothPursuitPoint[],
    horizontalOD?: SmoothPursuitPoint[],
    horizontalOS?: SmoothPursuitPoint[],
    stimuliOD?: SmoothPursuitPoint[],
    stimuliOS?: SmoothPursuitPoint[],
    verticalOD?: SmoothPursuitPoint[],
    verticalOS?: SmoothPursuitPoint[],
    zeroOD?: number,
    zeroOS?: number,
}

export interface GainChartData {
    testResults?: StimuliResults[],
    stimulusData?: GainChartStimuliData[]
}

export interface GainChartStimuliData {
    xAxisStart?: number,
    xAxisEnd?: number,
    firstWindow?: CorrelationData,
    windowsInBetween?: CorrelationData[],
}

export interface CorrelationData {
    pointOD: WindowData, 
    pointOS: WindowData,
    timeShiftOD: number,
    timeShiftOS: number,
    phaseShiftOD: number,
    phaseShiftOS: number,
}

export interface WindowData {    
    time?: number,
    corelation?: number;
    startTime?: number,
    endTime?: number 
}

export interface StimuliResults {
    stimuliName?: string,
    windowsResultOD?: WindowResult[],
    windowsResultOS?: WindowResult[],
}

export interface WindowResult { 
    timeShift: number,
    phaseShift: number,
    gain: number,
    startTime: number,
    endTime: number
}

export interface CalibratedData {
    stimuliAmplitudeOD?: SmoothPursuitPoint[],
    stimuliAmplitudeOS?: SmoothPursuitPoint[],
    eyeAmplitudeOD?: SmoothPursuitPoint[],
    eyeAmplitudeOS?: SmoothPursuitPoint[]
}

export interface ISmoothPursuitTestCamMessage extends ICamMessage {
    timestamp: number;
    eyeODx: number;
    eyeODy: number;
    eyeOSx: number;
    eyeOSy: number;
    calibratedeyeODx: number;
    calibratedeyeODy: number;
    calibratedeyeOSx: number;
    calibratedeyeOSy: number;
    stimuliODx: number;
    stimuliODy: number;
    stimuliOSx: number;
    stimuliOSy: number;
    frequency: number;
    rawEyeODy: number;
    rawEyeODx: number;
    rawEyeOSx: number;
    rawEyeOSy: number;
}

export interface SmoothPursuitPoint {
    time?: number;
    amplitude?: number;
    frequency?: number;
    type?: number;
}

export interface SmoothPursuitRealTimePoint { x?: number; y?: number; type?: number; frequency?: number }

export enum CHART_SUBTYPE {
    OS = 'OS',
    OD = 'OD'
}
