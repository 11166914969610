import * as jsPDF from 'jspdf';
import { CellHookHandler } from 'jspdf-autotable';
import { AccommodationArk1sMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/ARK1S/accommodationArk1sData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { AccommodationArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/accommodationArk1sTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class AccommodationArk1sMeasuredDataFrontend extends AccommodationArk1sMeasuredData implements MeasuredDataFrontend {
    public sphere: {
        OD: RegisterEditFieldFrontend<string>;
        OS: RegisterEditFieldFrontend<string>;
    };
    public image: {
        OD: SmartImageFrontend;
        OS: SmartImageFrontend;
    };
    public remarks: AccommodationArk1sTestRemarksFrontend;
    constructor() {
        super();
        this.sphere = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.image = {
            OD: new SmartImageFrontend(),
            OS: new SmartImageFrontend(),
        };
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        try {
            let table = [];
            table.push({
                OD: { colSpan: 2, content: 'Accomodation (diopter)', styles: { halign: 'center' } },
                OS: '',
            });
            table.push({
                OD: { content: 'OD', styles: { halign: 'center' } },
                OS: { content: 'OS', styles: { halign: 'center' } },
            });
            table.push({
                OD: this.sphere.OD.value,
                OS: this.sphere.OS.value,
            });

            await this.downloadMediaData(fileService);

            if (!this.image.OD.isEmpty() || !this.image.OS.isEmpty()) {
                let ratio: number = (await this.image.OD.getRatio()) || (await this.image.OS.getRatio());
                table.push({
                    OD: {
                        content: '',
                        styles: {
                            minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                            minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                        },
                    },
                    OS: {
                        content: '',
                        styles: {
                            minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                            minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                        },
                    },
                });
            }
            (document as any).autoTable({
                body: table,
                startY: pager.line,
                pageBreak: 'auto',
                theme: 'grid',
                margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
                didParseCell: (data: any) => {},
                didDrawCell: <CellHookHandler>(data => {
                    if (this.image.OD.imageUrl && data.section === 'body' && data.row.index === 3 && data.column.dataKey === 'OD') {
                        document.addImage(this.image.OD.imageUrl, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                    } else if (this.image.OS.imageUrl && data.section === 'body' && data.row.index === 3 && data.column.dataKey === 'OS') {
                        document.addImage(this.image.OS.imageUrl, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                    }
                }),
                didDrawPage: () => {},
            });
            pager.line = (document as any).lastAutoTable.finalY;
        } catch (error) {
            console.log(error);
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values = '' + this.sphere.OD.value || 'x';
        formattedTest.OS.values = '' + this.sphere.OS.value || 'x';
        if (!this.image.OD.isEmpty()) {
            formattedTest.OD.images.push(this.image.OD.imageUrl);
        }
        if (!this.image.OS.isEmpty()) {
            formattedTest.OS.images.push(this.image.OS.imageUrl);
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        try {
            await Promise.all([this.image.OD.download(fileService), this.image.OS.download(fileService)]);
        } catch (error) {
            console.error(error);
        }
    }
}
