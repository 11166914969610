import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/general/auth.service';
import { ConfigService } from '../../_services/general/config.service';
import { ModalService } from '../../_services/general/modal.service';
import { TranslateService } from '../../_services/general/translate.service';

@Component({
    selector: 'root',
    styles: [require('./root.component.scss')],
    template: require('./root.component.html'),
})
export class RootComponent implements OnInit, OnDestroy {
    private homeLink: string;
    private subscriptions: Subscription[] = [];
    private loading: boolean = true;

    constructor(
        private translateService: TranslateService,
        private configService: ConfigService,
        private authService: AuthenticationService,
        private renderer: Renderer2,
        private router: Router,
        public modalService: ModalService
    ) {
        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                this.loading = true;
            }
            if (event instanceof NavigationEnd) {
                this.loading = false;
            }
            // Set loading state to false in both of the below events to hide the spinner in case a request fails
            if (event instanceof NavigationCancel) {
                this.loading = false;
            }
            if (event instanceof NavigationError) {
                this.loading = false;
            }
        });
    }

    ngOnInit() {
        this.translateService.enableFallback(true);
        this.renderer.addClass(document.body, this.configService.theme.value);
        this.configService.theme.subscribe(theme => {
            this.renderer.removeAttribute(document.body, 'class');
            this.renderer.addClass(document.body, theme);
        });
        this.subscriptions.push(
            fromEvent<KeyboardEvent>(document, 'keypress')
                .pipe(
                    map(e => e.key),
                    scan((acc: string, value) => acc + value?.toLowerCase()),
                    filter(sequence => typeof sequence === 'string')
                )
                .subscribe(sequence => {
                    if (sequence?.endsWith('hideenter')) {
                        this.configService.isDevTestsShown.next(false);
                    } else if (sequence?.endsWith('showenter')) {
                        this.configService.isDevTestsShown.next(true);
                    }
                })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
