import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { concatMap, filter, map, scan } from 'rxjs/operators';
import { ENVIRONMENTS } from '../../../../../../../../../commonout/enum/environments';
import { IChartEdit } from '../../../../../../../../../commonout/interfaces/chartEdit.interface';
import { MESSAGE_TYPE } from '../../../../../../../../../commonout/interfaces/charts.model';
import { IPupil20TestCamMessage } from '../../../../../../../../common/interfaces/pupil2.0TestMessage.interface';
import { BacklightChartService } from '../../../../../_services/chartServices/backLightChartService';
import { MyPupil20TestChartService } from '../../../../../_services/chartServices/myPupil20TestChartService';
import { Pupil20DotChartService } from '../../../../../_services/chartServices/pupil20DotChartService';
import { Pupil20TableChartService } from '../../../../../_services/chartServices/pupil20TableChartService';
import { PupilDiameterChartService } from '../../../../../_services/chartServices/pupilDiameterChartService';
import { BulbicamChartComponent } from '../../haploChart.component';
import { BacklightChartComponent } from '../backlight-chart/backlight-chart.component';
import { PupilDiameterChartComponent } from '../pupil-diameter-chart/pupil-diameter-chart.component';
import { Pupil20DotChartComponent } from '../pupil2.0-dot-chart/pupil2.0-dot-chart.component';
import { Pupil20TableComponent } from '../pupil2.0-table/pupil2.0-table.component';

enum PUPIL_TEST_STAGES {
    START_TEST = 'START_TEST',
    FIRST_STAGE = 'FIRST_STAGE',
    SECOND_STAGE = 'SECOND_STAGE',
    THIRD_STAGE = 'THIRD_STAGE',
    FOURTH_STAGE = 'FOURTH_STAGE',
    FIFTH_STAGE = 'FIFTH_STAGE',
    STOP_TEST = 'STOP_TEST',
    NONE = 'NONE',
}
enum VIEW {
    EXTENDED = 'EXTENDED',
    NORMAL = 'NORMAL',
}

@Component({
    selector: 'my-pupil2.0-test-chart',
    template: require('./my-pupil2.0-test-chart.component.html'),
    styles: [require('./my-pupil2.0-test-chart.component.scss')],
})
export class MyPupil20BulbicamTestChartComponent extends BulbicamChartComponent implements OnInit, OnDestroy {
    private testStage: PUPIL_TEST_STAGES = PUPIL_TEST_STAGES.NONE;
    private dataSource: Subject<IPupil20TestCamMessage[]> = new Subject<IPupil20TestCamMessage[]>();
    private subscriptions: Subscription[] = [];
    public run: Subject<number> = new Subject<number>();
    @ViewChild('firstPupilDiameterChart') public firstDiameterChart: PupilDiameterChartComponent;
    @ViewChild(Pupil20TableComponent) public pupil20TableComponent: Pupil20TableComponent;
    @ViewChild(Pupil20DotChartComponent) public pupil20DotChartComponent: Pupil20DotChartComponent;
    @ViewChild('secondPupilDiameterChart') public secondDiameterChart: PupilDiameterChartComponent;
    @ViewChild('secondBacklightChart') private secondBacklightChart: BacklightChartComponent;
    @ViewChild('thirdBacklightChart') private thirdBacklightChart: BacklightChartComponent;
    @ViewChild('fourthBacklightChart') private fourthBacklightChart: BacklightChartComponent;
    @ViewChild('thirdPupilDiameterChart') public thirdDiameterChart: PupilDiameterChartComponent;
    @ViewChild('fourthPupilDiameterChart') public fourthDiameterChart: PupilDiameterChartComponent;
    public mode: ENVIRONMENTS = ENVIRONMENTS.PRODUCTION;
    public view: VIEW = VIEW.NORMAL;
    public RAPD: string;

    public dotChartStages = ['THIRD_STAGE','FOURTH_STAGE','FIFTH_STAGE'];
    public firstPupilDiameterStages = ['NONE','FIRST_STAGE','SECOND_STAGE','STOP_TEST'];

    private currentRound: number = 0;
    private skipRender: boolean = false;
    constructor(
        private myPupil20TestChartService: MyPupil20TestChartService,
        private backlightChartService: BacklightChartService,
        private pupilDiameterChartService: PupilDiameterChartService,
        private pupil20TableChartService: Pupil20TableChartService,
        private pupil20DotChartService: Pupil20DotChartService,
        private renderer: Renderer2
    ) {
        super();
    }
    ngOnInit(): void {
        this.subscriptions.push(this.dataSource.pipe(concatMap(f => f)).subscribe(v => this.frameHandler(v)));
        this.subscriptions.push(
            this.run.subscribe(i => {
                this.currentRound = i;
                this.firstDiameterChart.round.next(i);
                this.pupil20TableComponent.run.next(i);
            })
        );
        this.subscriptions.push(
            fromEvent<KeyboardEvent>(document, 'keypress')
                .pipe(
                    map(e => e.key),
                    scan((acc: string, value) => acc + value?.toLowerCase()),
                    filter(sequence => typeof sequence === 'string')
                )
                .subscribe(sequence => {
                    if (sequence?.endsWith('devmode')) {
                        this.mode = ENVIRONMENTS.TEST;
                    } else if (sequence?.endsWith('normalmode')) {
                        this.mode = ENVIRONMENTS.PRODUCTION;
                    }
                    localStorage.setItem('testenv', this.mode);
                })
        );
        this.mode = ENVIRONMENTS[localStorage.getItem('testenv')] || ENVIRONMENTS.PRODUCTION;
        this.view = VIEW[localStorage.getItem('pupil2.0view')] || VIEW.NORMAL;
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    changeView(): void {
        if (this.view === VIEW.NORMAL) {
            this.view = VIEW.EXTENDED;
        } else {
            this.view = VIEW.NORMAL;
        }
        localStorage.setItem('pupil2.0view', this.view);
        setTimeout(() => {
            this.pupil20DotChartComponent.onResize();
            this.firstDiameterChart.onResize();
            this.secondBacklightChart.onResize();
            this.thirdBacklightChart.onResize();
            this.fourthBacklightChart.onResize();
            this.secondDiameterChart.onResize();
            this.thirdDiameterChart.onResize();
            this.fourthDiameterChart.onResize();
        }, 0);
    }
    public clearData(): void {
        this.firstDiameterChart.clear();
        this.pupil20TableComponent.clear();
        this.pupil20DotChartComponent.clear();
        this.secondDiameterChart.clear();
        this.secondBacklightChart.clear();
        this.thirdBacklightChart.clear();
        this.fourthBacklightChart.clear();
        this.thirdDiameterChart.clear();
        this.fourthDiameterChart.clear();
        this.currentRound = 0;
    }
    public setEdits(edits: IChartEdit[]): void {}
    public addData(frames: IPupil20TestCamMessage[], skipRender: boolean = false): void {
        this.skipRender = skipRender;
        if (this.skipRender) {
            this.firstDiameterChart = new PupilDiameterChartComponent(this.pupilDiameterChartService);
            this.pupil20TableComponent = new Pupil20TableComponent(this.renderer, this.pupil20TableChartService, this.myPupil20TestChartService);
            this.pupil20DotChartComponent = new Pupil20DotChartComponent(this.myPupil20TestChartService);
            this.secondDiameterChart = new PupilDiameterChartComponent(this.pupilDiameterChartService);
            this.secondBacklightChart = new BacklightChartComponent(this.backlightChartService);
            this.thirdBacklightChart = new BacklightChartComponent(this.backlightChartService);
            this.fourthBacklightChart = new BacklightChartComponent(this.backlightChartService);
            this.thirdDiameterChart = new PupilDiameterChartComponent(this.pupilDiameterChartService);
            this.fourthDiameterChart = new PupilDiameterChartComponent(this.pupilDiameterChartService);
            this.ngOnInit();
        }
        this.myPupil20TestChartService.addData(frames);
        this.dataSource.next(frames);
        if (this.skipRender) {
            this.ngOnDestroy();
        }
    }
    private frameHandler(frame: IPupil20TestCamMessage): void {
        switch (frame.message_type) {
            case MESSAGE_TYPE.START_TEST:
                this.testStage = PUPIL_TEST_STAGES.START_TEST;
                break;
            case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                switch (this.testStage) {
                    case PUPIL_TEST_STAGES.START_TEST:
                        this.testStage = PUPIL_TEST_STAGES.FIRST_STAGE;
                        break;
                    case PUPIL_TEST_STAGES.FIRST_STAGE:
                        this.firstDiameterChart.clearChart();
                        this.testStage = PUPIL_TEST_STAGES.SECOND_STAGE;
                        break;
                    case PUPIL_TEST_STAGES.SECOND_STAGE:
                        this.testStage = PUPIL_TEST_STAGES.THIRD_STAGE;
                        break;
                    case PUPIL_TEST_STAGES.THIRD_STAGE:
                        this.testStage = PUPIL_TEST_STAGES.FOURTH_STAGE;
                        break;
                    case PUPIL_TEST_STAGES.FOURTH_STAGE:
                        this.testStage = PUPIL_TEST_STAGES.FIFTH_STAGE;
                        break;
                    default:
                        break;
                }
                break;
            case MESSAGE_TYPE.STOP_TEST:
                this.testStage = PUPIL_TEST_STAGES.STOP_TEST;
                break;
            default:
                break;
        }
        switch (this.testStage) {
            case PUPIL_TEST_STAGES.START_TEST:
                this.firstDiameterChart.framesSource.next(frame);
                this.pupil20TableComponent.framesSource.next(frame);
                this.pupil20DotChartComponent.framesSource.next(frame);
                this.secondDiameterChart.framesSource.next(frame);
                this.secondBacklightChart.framesSource.next(frame);
                this.thirdBacklightChart.framesSource.next(frame);
                this.fourthBacklightChart.framesSource.next(frame);
                this.thirdDiameterChart.framesSource.next(frame);
                this.fourthDiameterChart.framesSource.next(frame);
                break;
            case PUPIL_TEST_STAGES.FIRST_STAGE:
            case PUPIL_TEST_STAGES.SECOND_STAGE:
                this.firstDiameterChart.framesSource.next(frame);
                this.pupil20TableComponent.framesSource.next(frame);
                break;
            case PUPIL_TEST_STAGES.THIRD_STAGE:
                this.secondDiameterChart.framesSource.next(frame);
                this.secondBacklightChart.framesSource.next(frame);
                this.pupil20DotChartComponent.framesSource.next(frame);
                break;
            case PUPIL_TEST_STAGES.FOURTH_STAGE:
                this.thirdDiameterChart.framesSource.next(frame);
                this.thirdBacklightChart.framesSource.next(frame);
                this.pupil20DotChartComponent.framesSource.next(frame);
                break;
            case PUPIL_TEST_STAGES.FIFTH_STAGE:
                this.fourthDiameterChart.framesSource.next(frame);
                this.fourthBacklightChart.framesSource.next(frame);
                this.pupil20DotChartComponent.framesSource.next(frame);
                break;
            case PUPIL_TEST_STAGES.STOP_TEST:
                this.firstDiameterChart.framesSource.next(frame);
                this.pupil20TableComponent.framesSource.next(frame);
                this.pupil20DotChartComponent.framesSource.next(frame);
                this.secondDiameterChart.framesSource.next(frame);
                this.secondBacklightChart.framesSource.next(frame);
                this.thirdBacklightChart.framesSource.next(frame);
                this.fourthBacklightChart.framesSource.next(frame);
                this.thirdDiameterChart.framesSource.next(frame);
                this.fourthDiameterChart.framesSource.next(frame);
                break;
            default:
                break;
        }
    }
    public setCamData(measurement: IPupil20TestCamMessage[]): void {
        measurement.forEach(frame => {
            switch (frame.message_type) {
                case MESSAGE_TYPE.START_TEST:
                    this.testStage = PUPIL_TEST_STAGES.START_TEST;
                    break;
                case MESSAGE_TYPE.START_PUPIL_DATA_TRANSMISSION:
                    switch (this.testStage) {
                        case PUPIL_TEST_STAGES.START_TEST:
                            this.testStage = PUPIL_TEST_STAGES.FIRST_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.FIRST_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.SECOND_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.SECOND_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.THIRD_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.THIRD_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.FOURTH_STAGE;
                            break;
                        case PUPIL_TEST_STAGES.FOURTH_STAGE:
                            this.testStage = PUPIL_TEST_STAGES.FIFTH_STAGE;
                            break;
                        default:
                            break;
                    }
                    break;
                case MESSAGE_TYPE.STOP_TEST:
                    this.testStage = PUPIL_TEST_STAGES.STOP_TEST;
                    break;
                default:
                    break;
            }
            switch (this.testStage) {
                case PUPIL_TEST_STAGES.START_TEST:
                    this.firstDiameterChart.framesSource.next(frame);
                    this.pupil20TableComponent.framesSource.next(frame);
                    this.pupil20DotChartComponent.framesSource.next(frame);
                    this.secondDiameterChart.framesSource.next(frame);
                    this.secondBacklightChart.framesSource.next(frame);
                    this.thirdBacklightChart.framesSource.next(frame);
                    this.fourthBacklightChart.framesSource.next(frame);
                    this.thirdDiameterChart.framesSource.next(frame);
                    this.fourthDiameterChart.framesSource.next(frame);
                break;
                case PUPIL_TEST_STAGES.FIRST_STAGE:
                case PUPIL_TEST_STAGES.SECOND_STAGE:
                    this.firstDiameterChart.framesSource.next(frame);
                    this.pupil20TableComponent.framesSource.next(frame);
                    break;
                case PUPIL_TEST_STAGES.THIRD_STAGE:
                    this.secondDiameterChart.framesSource.next(frame);
                    this.secondBacklightChart.framesSource.next(frame);
                    this.pupil20DotChartComponent.framesSource.next(frame);
                    break;
                case PUPIL_TEST_STAGES.FOURTH_STAGE:
                    this.thirdDiameterChart.framesSource.next(frame);
                    this.thirdBacklightChart.framesSource.next(frame);
                    this.pupil20DotChartComponent.framesSource.next(frame);
                    break;
                case PUPIL_TEST_STAGES.FIFTH_STAGE:
                    this.fourthDiameterChart.framesSource.next(frame);
                    this.fourthBacklightChart.framesSource.next(frame);
                    this.pupil20DotChartComponent.framesSource.next(frame);
                    break;
                case PUPIL_TEST_STAGES.STOP_TEST:
                    this.firstDiameterChart.framesSource.next(frame);
                    this.pupil20TableComponent.framesSource.next(frame);
                    this.pupil20DotChartComponent.framesSource.next(frame);
                    this.secondDiameterChart.framesSource.next(frame);
                    this.secondBacklightChart.framesSource.next(frame);
                    this.thirdBacklightChart.framesSource.next(frame);
                    this.fourthBacklightChart.framesSource.next(frame);
                    this.thirdDiameterChart.framesSource.next(frame);
                    this.fourthDiameterChart.framesSource.next(frame);
                    break;
                default:
                    break;
            }
        });
    }
}
