// @ts-nocheck
// import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target, makeSpansGetter } from '..';
// import { doTranslate } from '../lib';
import { getStyle, RawPoint } from './util'
import { Scale2D } from '../types'
import { translate, nonInteractive } from '../util'
import { Brand } from './Brand'

// styling

const styleDefault = {
  // fontColor: '#fff',
  // fontWeight: 600,
  // fontFamily: 'Orbitron',
  fontFamily: Brand.Font,
  width: 130,
  height: 50,
  // gap: 5,
  gap: 8,

  bgColor: '#eee',
  // bgColor: Brand.darkGreen,
  frameColor: 'rgba(0,0,0,0.8)',

  fontSize: 18,
  fontColor: '#123',
  // fontColor: Brand.notDarkGreen,
}

type Style = typeof styleDefault

const themeOverrides: { [k:string]: Partial<Style> } = {
  bright: {
    // fontColor: '#123',
  },
}

// interface

interface PopupInfo {
  near: RawPoint
  text: string,
  atBottom?: boolean,
}
interface Params {
    // text: string;
    // alignLeft?: boolean;
    items: PopupInfo[]

    // from context
    scale: Scale2D
    alignLeft?: boolean,
    paddingLeft?: number,
    lineSize?: number,
    // makeStructure: (a: string, b: string, c: Target) => { gSeries: Target, gDataPoints: Target }

    target: Target;
    // area: Layout;
    styleParams: {
      theme: string,
      override?: Partial<Style>,
    }
}

// function

export function Popup(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams)

    let target = p.target
      .selectAll('.popup')
      .data(p.items)
      .join('g')
      .attr('class', 'popup')
      .attr('transform', d => translate({ x: p.scale.x(d.near.x), y: p.scale.y(d.near.y) }))
      .call(nonInteractive)

    let centerByY = !p.alignLeft

    let yPosition = (d: PopupInfo) => {
      let value = -style.gap - style.height
      if (d.atBottom) value = style.gap
      return value
    }

    target
        .selectAll('rect')
        .data(d => [d])
        .join('rect')

        .attr('x', d => -style.width / 2)
        .attr('y', yPosition)
        .attr('width', style.width)
        .attr('height', style.height)
        .attr('fill', style.bgColor)
        .attr('stroke', style.frameColor)

    target
        .selectAll('text')
        .data(d => [d])
        .join('text')
        .style('fill', style.fontColor)
        .attr('y', yPosition)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', centerByY ? 'middle' : 'start')
        .attr('font-size', style.fontSize)
        .attr('font-family', style.fontFamily)
        .attr('transform', translate({ x: centerByY ? 0 : -style.width / 2 + (p.paddingLeft || 0) }))
        .call(makeSpansGetter((d: PopupInfo) => d.text, p.lineSize ?? style.fontSize))
}
