import { IpdGlareContrastNidekAcuityArk1sTest } from '../../../../../../../../commonout/classes/tests/NIDEK/ARK1S/ipd-glare-contrast-acuityNidekArk1sTest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { IpdGlareContrastAcuityTestRemarksComponent } from '../../../../_components';
import { IpdGlareContrastAcuityArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/ipd-glare-contrast-acuityArk1sTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class IpdGlareContrastAcuityArk1sTestFrontend extends IpdGlareContrastNidekAcuityArk1sTest {
    remarks: IpdGlareContrastAcuityArk1sTestRemarksFrontend;
    public readonly remarksComponent: typeof IpdGlareContrastAcuityTestRemarksComponent = IpdGlareContrastAcuityTestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.NONE;
    constructor() {
        super();
        this.remarks = new IpdGlareContrastAcuityArk1sTestRemarksFrontend();
    }
}

export interface IpdGlareContrastAcuityArk1sTestFrontend extends TestFrontend {}
applyMixins(IpdGlareContrastAcuityArk1sTestFrontend, [TestFrontend]);
