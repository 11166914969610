// @ts-nocheck
import * as d3 from 'd3';
import * as lo from '../layout';
import { Scale } from '../lib';
import { Diagram } from '../Diagram';

export class Scales {
    x: Scale;
    y: Scale;
    yPercent: Scale;
    bottomX: Scale;
    bottomY: Scale;

    constructor (diagram: Diagram) {
        let x = d3
            .scaleLinear()
            .domain(diagram.state.domainX)
            .range(lo.topChart.rangeX());

        let y = d3
            .scaleLinear()
            .domain(diagram.state.domainY)
            .range(lo.topChart.rangeYSwap());

        let yPercent = d3
            .scaleLinear()
            .domain([-1, 1])
            .range(lo.topChart.rangeYSwap());

        this.x = x;
        this.y = y;
        this.yPercent = yPercent;

        let bottomX = d3
            .scaleLinear()
            .domain(diagram.state.domainBottomX)
            .range(lo.bottomChart.rangeX());

        let bottomY = d3
            .scaleLinear()
            .domain(diagram.state.domainBottomY)
            .range(lo.bottomChart.rangeY());

        this.bottomX = bottomX;
        this.bottomY = bottomY;
    }
}
