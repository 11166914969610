// @ts-nocheck

import * as d3 from 'd3';
import { delay, getAxis, pause, play } from "../const";

export class Fixation {

  constructor(g, parent) {
    this.parent = parent;
    this.area = g;
    this.chart = d3.select(g).append('g').attr('id', 'fixation-dots');
    this.rect = this.chart.append('rect')
      .attr('class', 'fixation-rect')
      .style('fill-opacity', 0,5)
      .style('stroke', 'rgba(0, 0, 0, 0.3)');
    g.addEventListener('mouseup', e => this.mouseUp(e));
    g.addEventListener('mousedown', e => this.mouseDown(e));
    g.addEventListener('mousemove', e => this.mouseMove(e));
  }

  delRect() {
    this.rect.attr('width', 0).attr('height', 0);
    if(this.c) this.c.width = 0;
    this.chart.selectAll('.fixation-one').remove();
  }

  mouseDown(e) {
    if((e.target.className.baseVal !== 'layer') || this.run) return;
    this.delRect();
    this.start = {x: e.clientX, y: e.clientY};
    this.move = true;
  }
  
  mouseMove(e) {
    if(!this.move || this.run) return;
    const viewBox = this.area.getAttribute('viewBox')?.split(' ');
    const chartC = this.area.getBoundingClientRect();
    this.scaleX = parseInt(viewBox[2]) / chartC.width;
    this.scaleY = parseInt(viewBox[3]) / chartC.height;
    const main = this.area.getElementsByClassName('dyn-11')[0].getBoundingClientRect();
    
    this.c = {
      x: (this.start.x - chartC.left) * this.scaleX,
      y: (main.y - chartC.top) * this.scaleY,
      width: (e.clientX - this.start.x) * this.scaleX,
      height: main.height * 2 * this.scaleY,
    }

    if(this.c.width < 0 || this.c.height < 0) return;
    Object.keys(this.c).forEach(k => this.rect.attr(k, this.c[k]));
  }

  mouseUp(e) {
    this.move = false;
    const chartC = this.area.getBoundingClientRect();
    if(e.target.className.baseVal === 'dots-init') return;
    if(e.target.className.baseVal !== 'layer' && !this.c?.width) return;
    if(!this.c?.width || !this.c?.height || this.run) return;

    const axC = this.area.getElementsByClassName('bottom-axis')[0].getBoundingClientRect();
    let sec = {
      start: (this.c.x - (axC.x - chartC.left) * this.scaleX) * (4 / (axC.width * this.scaleX)),
      duration: this.c.width * (4 / (axC.width * this.scaleX))
    }
    sec.end = sec.start + sec.duration;

    const dots = document.getElementsByName('fixation-dots');
    
    const data = [...dots[0].__data__.filteredPoints].filter(k => ((k.time < sec.end) && (k.time > sec.start)) )
    
    this.fixation(data);
  }

  getScale(points, eye, range) {
    let dX = [];
    let dY = [];
    
    for(let i = 0; i < points.length; i++) {
      let p = points[i];
      if(i === 0) {
        dX[0] = dX[1] = p[eye+'hPoint'].y;
        dY[0] = dY[1] = p[eye+'vPoint'].y;
      }
      if(p[eye+'hPoint'].y < dX[0]) dX[0] = p[eye+'hPoint'].y;
      if(p[eye+'hPoint'].y > dX[1]) dX[1] = p[eye+'hPoint'].y;
      if(p[eye+'vPoint'].y < dY[0]) dY[0] = p[eye+'vPoint'].y;
      if(p[eye+'vPoint'].y > dY[1]) dY[1] = p[eye+'vPoint'].y;
    }

    dX[0] = dX[0] * -1;
    dX = dX[0] > dX[1] ? [dX[0] * -1, dX[0]] : [dX[1] * -1, dX[1]];
    dY[0] = dY[0] * -1;
    dY = dY[0] > dY[1] ? [dY[0] * -1, dY[0]] : [dY[1] * -1, dY[1]];
    
    return {
      x: d3.scaleLinear().domain(dX).range(range.x),
      y: d3.scaleLinear().domain(dY).range(range.y)
    };
  }

  fixation(points) {
    
    let troot = this.area.getElementsByClassName('troot')[0];
    troot = troot.getBoundingClientRect();
    const top = troot.height * this.scaleY + 80;
    this.area.setAttribute('viewBox', '0 0 1920 '+ (top + 550));

    const {time, playButton, playRect} = getAxis(this.chart, points, top);
    this.time = time; this.playButton = playButton;
    
    playRect.on('click', () => this.clickPlay());

    const dif = {
      OD: { h: points[0].ODhPoint.y, v: points[0].ODvPoint.y },
      OS: { h: points[0].OShPoint.y, v: points[0].OSvPoint.y }
    }
    points = [...points].map(k => ({
      ...k,
      ODhPoint: {
        y: k['ODhPoint'].y - dif.OD.h
      },
      ODvPoint: {
        y: k['ODvPoint'].y - dif.OD.v
      },
      OShPoint: {
        y: k['OShPoint'].y - dif.OS.h
      },
      OSvPoint: {
        y: k['OSvPoint'].y - dif.OS.v
      }
    }));
   
    let scale = 83;
    const getAx = (one: any) => {
      let res = [
        {cx: scale * one.ODhPoint.y + 800, cy: scale * one.ODvPoint.y + top + 250},
        {cx: scale * one.OShPoint.y + 1500, cy: scale * one.OSvPoint.y + top + 250}
      ];
      return res;
    }
    
    this.chart.selectAll('.fixation-one').remove();
    for(let i = 0; i < points.length; i++) {
      let ent = getAx(points[i]);
      this.chart.append('circle').attr('class', 'fixation-one')
        .attr('cx', ent[0].cx)
        .attr('cy', ent[0].cy)
        .attr('r', 5)
        .style('fill', 'none')
        .style('stroke', '#fff');
      this.chart.append('circle').attr('class', 'fixation-one')
        .attr('cx', ent[1].cx)
        .attr('cy', ent[1].cy)
        .attr('r', 5)
        .style('fill', 'none')
        .style('stroke', '#fff');
    }
  }

  setTime(time) {
    this.time._groups[0][0].innerHTML = time;
  }

  async clickPlay() {
    const arr = this.chart.selectAll('.fixation-one')._groups[0];

    const cleanDots = () => {
      for(let i =0; i < arr.length; i ++) {
        arr[i].style.stroke = 'none';
        arr[i].style.fill = 'grey';
        arr[i].style.opacity = 1;
      }
    }

    const prevent = async () => {
      this.run = false;
      await delay(100);
      this.playButton.html(play);
      this.setTime('0.00 sec');
      for(let i =0; i < arr.length; i ++) {
        arr[i].style.stroke = '#ffffff';
        arr[i].style.fill = 'none';
        arr[i].style.opacity = 1;
      }
    }

    const show = (index, fill) => {
      cleanDots();
      let opacity = 0.1;
      for(let i = (index - 9); i < (index + 1); i++) {
        if(arr[i]) {
          arr[i].style.fill = fill;
          arr[i].style.opacity = opacity;
        }
        opacity += 0.1;
      }
    }

    const duration = 50;
    if(!this.run) {
      this.run = true;
      this.playButton.html(pause);
      let t = 0;
      for(let i =0; i < arr.length; i ++) {
        if(!this.run) return;
        if(i % 2 === 0) {
          await delay(duration);
          t += duration;
        }
        this.setTime(parseFloat(parseInt(t / 1000) + '.' + (t % 1000)).toFixed(2) + ' sec');
        arr[i].style.stroke = 'none';
        if(i % 2 === 0) show(i, 'rgb(175, 240, 91)');
        else show(i, 'rgb(255, 94, 99)');
      }
      prevent();
    }
    else prevent();
  }

}