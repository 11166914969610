// @ts-nocheck
import { MySet } from '../lib/Variable'

export interface SettingsOption {
  id: number,
  name: string,
}

// interface VPU { // video position update
//   position: number,
//   now: number,
// }

// probably odd way of doing it
// (there should be a way to deduplicate it)
// (not sure if types is possible then though)
//
export class Settings {
  videoPosition = 0
  haveVideo = false
  lastVideoUsed = ''

  // videoPositionUpdate: VPU|null = null
  // updateVideoPosition(position: number) {
  //   let now = +new Date() / 1000
  //   this.videoPositionUpdate = { // useless synchronization?
  //     now,
  //     position
  //   }
  // }
  // applyVideoPositionUpdate() {
  //   let upd = this.videoPositionUpdate
  //   if (!upd) return undefined
  //   let then = upd.now
  //   let now = +new Date() / 1000

  //   let delta = now - then // useless?
  //   let result = upd.position + delta
  //   this.videoPositionUpdate = null
  //   this.videoPosition = result
  //   return result
  // }

  nextId = -1

  showODOption = {
    id: this.nextId += 1,
    name: 'OD',
  }
  showOSOption = {
    id: this.nextId += 1,
    name: 'OS',
  }
  showDotsOption = {
    id: this.nextId += 1,
    name: 'Show dots',
  }
  showHOption = {
    id: this.nextId += 1,
    name: 'Show H',
  }
  showVOption = {
    id: this.nextId += 1,
    name: 'Show V',
  }
  showTOption = {
    id: this.nextId += 1,
    name: 'Show T',
  }

  // for the list
  options: SettingsOption[] = [
    this.showODOption,
    this.showOSOption,
    // this.showDotsOption,

    // this.showHOption,
    // this.showVOption,
    // this.showTOption,
  ]

  // for the code
  get showOD() {
    return this.selected.has(this.showODOption.id)
  }
  get showOS() {
    return this.selected.has(this.showOSOption.id)
  }
  get showDots() {
    return this.selected.has(this.showDotsOption.id)
  }

  get showH() {
    return this.selected.has(this.showHOption.id)
  }
  get showV() {
    return this.selected.has(this.showVOption.id)
  }
  get showT() {
    return this.selected.has(this.showTOption.id)
  }

  selected = new MySet<number>();

  // initial values
  constructor() {
    this.selected.add(this.showODOption.id)
    this.selected.add(this.showOSOption.id)

    this.selected.add(this.showHOption.id)
    this.selected.add(this.showVOption.id)
    // this.selected.add(this.showTOption.id)
  }

  rotationOD = 0
  rotationOS = 0
  glowTime = 100

  rotationStep = 1

  updateRotationOD(n: number) {
    let value = this.rotationOD
    value += n * this.rotationStep
    // if (value > 360) value = 360
    // if (value < 0) value = 0
    if (value < 0) value = 360 + value
    if (value > 360) value = value - 360
    this.rotationOD = value
  }

  updateRotationOS(n: number) {
    let value = this.rotationOS
    value += n * this.rotationStep
    if (value < 0) value = 360 + value
    if (value > 360) value = value - 360
    this.rotationOS = value
  }

  updateGlowTime(n: number) {
    this.glowTime += n * 10
    if (this.glowTime < 0) this.glowTime = 0
  }
}
