import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IPachymetryMeasuredData } from '../../../../interfaces/measuredData/NIDEK/NT530P/pachymetry-nidek-nt530p.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class PachymetryMeasuredData implements MeasuredData, IPachymetryMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: RegisterEditField<string>;
    public OS: RegisterEditField<string>;
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_NT530P;
        this.testType = TEST_TYPE.PACHYMETRY;
    }
    public setModel(model: IPachymetryMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.OD.setModel(model.OD);
        this.OS.setModel(model.OS);
    }
    public getModel(): IPachymetryMeasuredData {
        let model: IPachymetryMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.getModel(),
            OS: this.OS.getModel(),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.isEmpty() && this.OS.isEmpty();
    }
}
