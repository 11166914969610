import { Component, OnDestroy, OnInit } from '@angular/core';
import { IIDCDiagnosis } from '../../../../../../../../common/interfaces/idc-list-item.interface';
import { IdcListItemComponent } from '../idc-list-item.component';

@Component({
    selector: 'idc-diagnosis-item',
    template: require('./idc-diagnosis-item.component.html'),
    styles: [require('./idc-diagnosis-item.component.scss')],
})
export class IdcDiagnosisItemComponent extends IdcListItemComponent implements OnInit, OnDestroy {
    public listItem: IIDCDiagnosis;
    constructor() {
        super();
    }
}
