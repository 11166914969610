import { DEVICE } from '../../../../../../commonout/enum/device';
import { TEST_TYPE } from '../../../../../../commonout/enum/test-type';
import { IPachymetryNidekTonorefIIIMeasuredData } from '../../../../interfaces/measuredData/NIDEK/TonorefIII/pachymetry-nidek-tonorefIII.measuredData.interface';
import { RegisterEditField } from '../../../registerEditField.class';
import { MeasuredData } from '../../measuredData.model';

export abstract class PachymetryTonorefIIIMeasuredData implements MeasuredData, IPachymetryNidekTonorefIIIMeasuredData {
    public sourceDevice: DEVICE;
    public sourceFileName: string;
    public testType: TEST_TYPE;
    public OD: RegisterEditField<string>;
    public OS: RegisterEditField<string>;
    constructor() {
        this.sourceDevice = DEVICE.NIDEK_TONOREFIII;
        this.testType = TEST_TYPE.PACHYMETRY;
    }
    public setModel(model: IPachymetryNidekTonorefIIIMeasuredData): void {
        this.sourceFileName = model.sourceFileName;
        this.OD.setModel(model.OD);
        this.OS.setModel(model.OS);
    }
    public getModel(): IPachymetryNidekTonorefIIIMeasuredData {
        let model: IPachymetryNidekTonorefIIIMeasuredData = {
            sourceDevice: this.sourceDevice,
            testType: this.testType,
            sourceFileName: this.sourceFileName,
            OD: this.OD.getModel(),
            OS: this.OS.getModel(),
        };
        return model;
    }
    public isEmpty(): boolean {
        return this.OD.isEmpty() && this.OS.isEmpty();
    }
}
