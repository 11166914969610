import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

interface PercentileInfo {
    p5?: number;
    p25?: number;
    p50?: number;
    p75?: number;
    p95?: number;
    mean?: number;
    cov?: number;
    measurementUnits?: string;
}

export interface TrialInfo {
    trial?: number;
    latency?: number;
    sn?: [number, number, number][];
    fn?: [number, number, number][];
    peakVelocity?: number[];
    timeCoord?: number;
    isCalibrationSuccessful?: boolean;
    status?: string;
    calibrationValue?: number;
}

export interface DetailsInfo {
    latencyInfo?: PercentileInfo;
    amplitudeInfo?: PercentileInfo;
    peakVelInfo?: PercentileInfo;
}

export interface ChartData {
    isRendered?: boolean;
    chartType: string;
    realTimeDataCoordinates?: [number, number, boolean][];
    dataCoordinates?: [number, number][];
    greenLinesCoordinates?: [number, number][][];
    redLinesCoordinates?: number[];
    redDotsCoordinates?: [number, number][];
    velocityDiagramCoordinates?: [number, number][];
    trialInfo?: TrialInfo[];
    detailsInfo?: DetailsInfo;
    data?: ICamMessage[];
    testDuration?: number;
    eye?: string;
}

@Injectable({
    providedIn: 'root',
})
export class ProSaccadeChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}
    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }
    types = ['accepted', 'disregarded'];

    private data: ICamMessage[] = [];
    accepted: string[] = [];
    isAcceptedListChanged: boolean = false;
    realTimeStartPoint: ICamMessage | null = null;
    chartData$: BehaviorSubject<ChartData> = new BehaviorSubject({
        chartType: 'no-content',
    });
    isDataRendered$ = new BehaviorSubject(false);
    averageRatio$ = new BehaviorSubject(0);
    testResults: ChartData | null;

    constructor() {
        super();
    }

    public addData(frames: ICamMessage[]): Promise<void> {
        this.isDataRendered$.next(false);

        const targettype = this._selectRawType(frames.find(d => d?.targettype)?.targettype);
        const data = frames.filter(el => el[targettype]);
        this.data.push(...frames.filter(d => !d.training));
        if (data.length) {
            if (!this.realTimeStartPoint) {
                this.realTimeStartPoint = data.find(d => d[targettype]);
            }

            const realTimePoints: [number, number, boolean][] = this._calculateRealTimeCoordinates(data, this.realTimeStartPoint, targettype);
            this.chartData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: realTimePoints,
                data: this.data,
            });
        } else {
            this.chartData$.next({
                chartType: 'real-time',
                realTimeDataCoordinates: [],
                data: this.data,
            });
        }

        return new Promise((res, _) => {
            this.isDataRendered$.pipe(filter(data => Boolean(data))).subscribe(() => res());
        });
    }

    public setCamData(frames: ICamMessage[]): void {
        this.testResults = this.calculateChartResults(frames);
    }

    getChartData(frames: ICamMessage[]) {
        const dataResults = this.calculateChartResults(frames);
        this.chartData$.next(dataResults);
    }

    calculateChartResults(frames: ICamMessage[]): ChartData | null {
        frames = frames.filter(d => !d.training);

        if (frames[0]?.accepted) {
            this.accepted = JSON.parse(frames[0].accepted);
        } else {
            this.isAcceptedListChanged = true;
            this.accepted = [];
        }

        if (!frames.length) {
            console.error('Bad Data');
            return null;
        }
        const targettype = this._selectRawType(frames[1].targettype);
        const data = frames.filter(el => el[targettype]);

        if (data.length) {
            const testDuration = this.getXvalue(data[0].timestamp / 10000, data[data.length - 1].timestamp);
            const trials = frames.slice(-2)[0].trial;

            const [greenPoints, redCirclePoints, redPoints] = this._calculateDotsCoordinates(frames, trials);
            const [calibratedCoordinates, trialInfoTemp] = this._calculateCalibratedCoordinates(frames, trials, targettype);
            const velocityCoordinates = this._calculateVelocityCoordinates(frames, calibratedCoordinates);
            const degPerSec = velocityCoordinates.map(d => d[1]);
            const trialInfo = this._calculateTrialInfo(frames, calibratedCoordinates, degPerSec, trialInfoTemp, trials);

            const detailsInfo = this.calculateDetails(trialInfo);

            const chartData = {
                eye: targettype.slice(3, 5),
                chartType: 'recorded',
                dataCoordinates: calibratedCoordinates,
                greenLinesCoordinates: greenPoints,
                redLinesCoordinates: redPoints,
                redDotsCoordinates: redCirclePoints,
                testDuration: Math.ceil(testDuration),
                velocityDiagramCoordinates: velocityCoordinates,
                trialInfo,
                detailsInfo,
            };

            return chartData;
        } else {
            console.error('Bad Data');
            return null;
        }
    }

    public export() {
        if (!this.testResults) {
            console.error('No exported Data');
            return null;
        }

        const exportedData = {
            eye: this.testResults.eye,
            details: {
                latency_percentile_25: this.testResults.detailsInfo.latencyInfo.p25,
                latency_percentile_50: this.testResults.detailsInfo.latencyInfo.p50,
                latency_percentile_75: this.testResults.detailsInfo.latencyInfo.p75,
                latency_mean: this.testResults.detailsInfo.latencyInfo.mean,
                latency_cov: this.testResults.detailsInfo.latencyInfo.cov,
                accuracy_percentile_25: this.testResults.detailsInfo.amplitudeInfo.p25,
                accuracy_percentile_50: this.testResults.detailsInfo.amplitudeInfo.p50,
                accuracy_percentile_75: this.testResults.detailsInfo.amplitudeInfo.p75,
                accuracy_mean: this.testResults.detailsInfo.amplitudeInfo.mean,
                accuracy_cov: this.testResults.detailsInfo.amplitudeInfo.cov,
                peak_velocity_percentile_25: this.testResults.detailsInfo.peakVelInfo.p25,
                peak_velocity_percentile_50: this.testResults.detailsInfo.peakVelInfo.p50,
                peak_velocity_percentile_75: this.testResults.detailsInfo.peakVelInfo.p75,
                peak_velocity_mean: this.testResults.detailsInfo.peakVelInfo.mean,
                peak_velocity_cov: this.testResults.detailsInfo.peakVelInfo.cov,
            },
            trials: this.testResults.trialInfo.map(d => ({
                trial: d.trial + 1,
                latency: d.latency || null,
                accuracy: d.fn?.[0]?.[2] || null,
                peak_velocity: d.peakVelocity?.[0] || null,
                is_accepted: d.status,
            })),
        };

        return exportedData;
    }

    public clearData(): void {
        this.data = [];
        this.isAcceptedListChanged = false;
        this.realTimeStartPoint = null;
        this.chartData$.next({
            chartType: 'cleared',
        });
    }

    private _calculateRealTimeCoordinates(data: ICamMessage[], realTimeStartPoint: ICamMessage, targettype: string): [number, number, boolean][] {
        const realTimePoints: [number, number, boolean][] = data.map(d => {
            const xPoint = this.getXvalue(realTimeStartPoint.timestamp / 10000, d.timestamp);
            const yPoint = this._calibrateRealTime(d, realTimeStartPoint[targettype], targettype);

            return [xPoint, yPoint, !!d.training];
        });

        return realTimePoints;
    }

    private _calculateDotsCoordinates(frames: ICamMessage[], trials: number): [[number, number][][], [number, number][], number[]] {
        const data = frames.slice(1, -1);
        let greenCoordinates: [number, number][][] = [];
        let redCircleCoordinates: [number, number][] = [];
        let redCoordinates: number[] = [];
        const start = data[0].trial;
        for (let i = start; i <= trials; i++) {
            //take the coords for middle line
            let greenPoints: [number, number][] = data
                .filter(el => el.trial === i && el.stage === 0)
                .map(d => [this.getXvalue(data[0].timestamp / 10000, d.timestamp), this._calibrateDots(d)]);
            greenCoordinates.push(greenPoints);

            //take the coords for peripheral line
            greenPoints = data.filter(el => el.trial === i && el.stage === 1).map(d => [this.getXvalue(data[0].timestamp / 10000, d.timestamp), this._calibrateDots(d)]);

            greenCoordinates.push(greenPoints);

            // take the first frame of trial plus 1000ms and first frame of next trial (or last frame if it is last trial)
            const redCirclePoints: [number, number][] = [
                data.findIndex(el => el.trial === i && el.stage === 0),
                i === trials ? data.length - 1 : data.findIndex(el => el.trial === i + 1 && el.stage === 0),
            ].map((d, ind) => [this.getXvalue(data[0].timestamp / 10000, ind ? data[d - 1].timestamp : data[d].timestamp + 10000000), this._calibrateDots(data[ind ? d - 1 : d])]);

            redCircleCoordinates.push(...redCirclePoints);
        }
        redCoordinates.push(0);
        redCoordinates.push(...redCircleCoordinates.map(d => d[0]));

        return [greenCoordinates, redCircleCoordinates, redCoordinates];
    }

    private _calculateCalibratedCoordinates(frames: ICamMessage[], trials: number, targettype: string): [[number, number][], TrialInfo[]] {
        let coordinates: [number, number][] = [];
        let trialInfo: TrialInfo[] = [];
        const data = frames.slice(1, frames.length - 1);
        let currentRatio = 0;
        let closest = data.find(d => d[targettype]);
        let calibratingPoint = data[0];
        const startTrial = data[0].trial;
        let ratioArray = [];

        for (let i = startTrial; i <= trials; i++) {
            trialInfo.push({ trial: i - startTrial });
            const [firstInd, lastInd] = this._calculateSliceIndexes(data, i, startTrial);

            let middle = this.findMiddleCalibrated(data, i, targettype);
            let peripheral = this.findPeripheralCalibrated(data, i, targettype);
            calibratingPoint = middle ? middle : calibratingPoint;

            if (middle && peripheral) {
                currentRatio = this._calculateRatio(middle, peripheral, targettype);
                ratioArray.push(currentRatio);
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    isCalibrationSuccessful: true,
                    calibrationValue: currentRatio,
                };
            } else {
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    isCalibrationSuccessful: false,
                    calibrationValue: null,
                };
            }

            if (!currentRatio) {
                coordinates.push(...data.slice(firstInd, lastInd).map(d => <[number, number]>[0, 0]));
                continue;
            }

            const points: [number, number][] = data.slice(firstInd, lastInd).map(d => {
                const xPoint = this.getXvalue(data[0].timestamp / 10000, d.timestamp);
                closest = d[targettype] ? d : closest;
                const yPointTemp = this._calibrateX(closest, calibratingPoint, currentRatio, targettype);
                const yPoint = yPointTemp > 12 || yPointTemp < -12 ? 12 * Math.sign(yPointTemp) : yPointTemp;

                return [xPoint, yPoint];
            });
            coordinates.push(...points);
        }

        this.averageRatio$.next(this.calculateAverage(ratioArray));

        return [coordinates, trialInfo];
    }

    private _calculateVelocityCoordinates(frames: ICamMessage[], calibrated: [number, number][]): [number, number][] {
        const data = frames.slice(1, frames.length - 1);
        const calibrabedX = calibrated.map(d => d[1]);
        const degpersec: number[] = [];

        for (let i = 0; i < data.length; i++) {
            const tempArray: number[] = calibrated
                .slice(i - 2 >= 0 ? i - 2 : 0, i + 3)
                .filter(d => d[1] !== null)
                .map(d => d[1]);

            const average = this.calculateAverage(tempArray);

            const timeSub = (data[i].timestamp / 10000 - data[i - 4 > 0 ? i - 5 : 0]?.timestamp / 10000) | 1;
            degpersec.push((Math.abs(average - calibrabedX[i - 4 > 0 ? i - 5 : 0]) / timeSub) * 1000);
        }

        const velPoints: [number, number][] = data.map((d, ind) => {
            const xPoint = calibrated[ind][0];
            const degValue = degpersec[ind];
            const yPoint = degValue || 0;

            return [xPoint, yPoint];
        });

        return velPoints;
    }

    private _calculateTrialInfo(frames: ICamMessage[], calibrated: [number, number][], degPerSec: number[], trialInfo: TrialInfo[], trials: number): TrialInfo[] {
        const data = frames.slice(1, frames.length - 1);
        const startTrial = data[0].trial;

        for (let i = startTrial; i <= trials; i++) {
            const [firstInd, lastInd] = [data.findIndex(d => d.trial === i && d.stage === 1), data.map(d => d.trial).lastIndexOf(i)];
            const temp: [number, number][] = [];
            if (trialInfo[i - startTrial].isCalibrationSuccessful) {
                for (let j = firstInd; j < lastInd; j++) {
                    // find range where velocity is more than 30
                    if (degPerSec[j] >= 30) {
                        const first = j - 1;
                        while (degPerSec[j] >= 30) {
                            j++;
                        }
                        temp.push([first, j]);
                    }
                }
            }
            const elem = data.find(d => d.trial === i);

            trialInfo[i - startTrial] = {
                ...trialInfo[i - startTrial],
                timeCoord: this.getXvalue(data[0].timestamp / 10000, elem.timestamp) + 1,
                status: this.accepted.length ? this.accepted[i - startTrial] : 'accepted',
            };
            if (temp.length) {
                let sN: [number, number, number][] = [];
                let fN: [number, number, number][] = [];
                let peakVelocity: number[] = [];
                let latency: number;
                latency = this.getXvalue(data[firstInd - 1].timestamp / 10000, data[temp[0][0] - 1].timestamp) * 1000;
                temp.slice(0, 3).forEach(d => {
                    const [first, last] = [d[0], d[1]];
                    // [x, y, value]
                    sN.push([calibrated[first - 1][0], calibrated[first - 1][1], this.getXvalue(data[firstInd - 1].timestamp / 10000, data[first - 1].timestamp) * 1000]);
                    sN.push([calibrated[last - 1][0], calibrated[last - 1][1], this.getXvalue(data[firstInd - 1].timestamp / 10000, data[last - 1].timestamp) * 1000]);
                    fN.push([calibrated[last - 1][0], calibrated[last - 1][1], (calibrated[last - 1][1] / this._calibrateDots(data[last - 1])) * 100]);
                    peakVelocity.push(Math.max(...degPerSec.slice(first - 1, last + 1)));
                });
                trialInfo[i - startTrial] = {
                    ...trialInfo[i - startTrial],
                    latency: latency,
                    peakVelocity: peakVelocity,
                    sn: sN,
                    fn: fN,
                };
            }
        }

        return trialInfo;
    }

    private calculateDetails(trialInfo: TrialInfo[]): DetailsInfo {
        const latencyArray = [].concat(...trialInfo.filter(d => d.status === 'accepted').map(d => d.latency));
        const amplitudeArray = [].concat(...trialInfo.filter(d => d.status === 'accepted').map(d => d?.fn?.[0]?.[2]));
        const peakVelArray = [].concat(...trialInfo.filter(d => d.status === 'accepted').map(d => d.peakVelocity));

        const latencyArraySorted = latencyArray
            .filter(d => !!d)
            .slice()
            .sort((a, b) => a - b);
        const amplitudeArraySorted = amplitudeArray
            .filter(d => !!d)
            .slice()
            .sort((a, b) => a - b);
        const peakVelArraySorted = peakVelArray
            .filter(d => !!d)
            .slice()
            .sort((a, b) => a - b);

        const [latencyMean, amplitudeMean, peakVelMean] = [
            this.calculateAverage(latencyArraySorted),
            this.calculateAverage(amplitudeArraySorted),
            this.calculateAverage(peakVelArraySorted),
        ];

        const [latencySD, amplitudeSD, peakVelSD] = [this.calculateSD(latencyArraySorted), this.calculateSD(amplitudeArraySorted), this.calculateSD(peakVelArraySorted)];

        const latencyInfo: PercentileInfo = {
            p25: this.getPercentile(latencyArraySorted, 25),
            p50: this.getPercentile(latencyArraySorted, 50),
            p75: this.getPercentile(latencyArraySorted, 75),
            mean: latencyMean,
            cov: latencySD / latencyMean,
            measurementUnits: 'ms',
        };

        const amplitudeInfo: PercentileInfo = {
            p25: this.getPercentile(amplitudeArraySorted, 25),
            p50: this.getPercentile(amplitudeArraySorted, 50),
            p75: this.getPercentile(amplitudeArraySorted, 75),
            mean: amplitudeMean,
            cov: amplitudeSD / amplitudeMean,
            measurementUnits: '%',
        };

        const peakVelInfo: PercentileInfo = {
            p25: this.getPercentile(peakVelArraySorted, 25),
            p50: this.getPercentile(peakVelArraySorted, 50),
            p75: this.getPercentile(peakVelArraySorted, 75),
            mean: peakVelMean,
            cov: peakVelSD / peakVelMean,
            measurementUnits: 'deg/s',
        };

        return {
            latencyInfo,
            amplitudeInfo,
            peakVelInfo,
        };
    }

    private _selectRawType(targettype: number): 'rawODx' | 'rawOSx' {
        return targettype === 1 ? 'rawOSx' : 'rawODx';
    }

    private _calibrateDots(raw: ICamMessage): number {
        if (raw.stage === 1) {
            return raw.targetDotX;
        }

        return 0;
    }

    private _calculateRatio(currentPhase: ICamMessage, nextPhase: ICamMessage, targettype: string): number {
        if (!currentPhase || !nextPhase) {
            return 0;
        }

        const sub = currentPhase[targettype] - nextPhase[targettype];
        const ratio = Math.abs(sub / 10);

        return ratio;
    }

    private _calibrateX(current: ICamMessage, raw: ICamMessage, ratio: number, targettype: string): number {
        const rawDeg = this._calibrateDots(raw);
        const rawX = raw[targettype];

        const calibratedX = rawDeg + (current[targettype] - rawX) / ratio;

        return calibratedX;
    }

    private _calculateSliceIndexes(data: ICamMessage[], trial: number, startTrial: number = 0): number[] {
        const currentTrialFirstFrame = data.find(d => d.trial === trial);
        const nextTrialFirstFrame = data.find(d => d.trial === trial + 1);

        if (trial === startTrial) {
            return [
                0,
                data.findIndex(
                    d =>
                        d.trial === trial + 1 &&
                        Math.abs(d.timestamp / 10000 - nextTrialFirstFrame.timestamp / 10000) > 975 &&
                        Math.abs(d.timestamp / 10000 - nextTrialFirstFrame.timestamp / 10000) < 1025
                ),
            ];
        }

        if (trial === data[data.length - 1].trial) {
            return [
                data.findIndex(
                    d =>
                        d.trial === trial &&
                        Math.abs(d.timestamp / 10000 - currentTrialFirstFrame.timestamp / 10000) > 975 &&
                        Math.abs(d.timestamp / 10000 - currentTrialFirstFrame.timestamp / 10000) < 1025
                ),
                data.length,
            ];
        }

        return [
            data.findIndex(
                d =>
                    d.trial === trial &&
                    Math.abs(d.timestamp / 10000 - currentTrialFirstFrame.timestamp / 10000) > 975 &&
                    Math.abs(d.timestamp / 10000 - currentTrialFirstFrame.timestamp / 10000) < 1025
            ),
            data.findIndex(
                d =>
                    d.trial === trial + 1 &&
                    Math.abs(d.timestamp / 10000 - nextTrialFirstFrame.timestamp / 10000) > 975 &&
                    Math.abs(d.timestamp / 10000 - nextTrialFirstFrame.timestamp / 10000) < 1025
            ),
        ];
    }

    private _calibrateRealTime(current: ICamMessage, startPoint: number, targettype: string): number {
        const currentRaw = current[targettype];

        return 0.3 * (currentRaw - startPoint);
    }

    findMiddleCalibrated(data: ICamMessage[], trial: number, targettype: string): ICamMessage | null {
        const currentTrialFirstFrame = data.find(d => d.trial === trial && d.stage === 0);
        const result = data.find(
            d => d.trial === trial && d.timestamp / 10000 - currentTrialFirstFrame.timestamp / 10000 > 975 && d.timestamp / 10000 - currentTrialFirstFrame.timestamp / 10000 < 1025
        );

        return result?.[targettype] ? result : null;
    }

    findPeripheralCalibrated(data: ICamMessage[], trial: number, targettype: string): ICamMessage | null {
        const index = data.map(d => d.trial).lastIndexOf(trial);

        return data[index - 1][targettype] ? data[index - 1] : null;
    }

    updateTrialInfo(trialIndex: number, type: string) {
        const chartDataValue = this.chartData$.getValue();

        if (!this.accepted.length) {
            this.accepted = chartDataValue.trialInfo.map(d => d.status);
        }

        this.isAcceptedListChanged = true;

        this.accepted[trialIndex] = type;

        const updateTrialInfo = chartDataValue.trialInfo;
        updateTrialInfo.splice(trialIndex, 1, { ...chartDataValue.trialInfo[trialIndex], status: this.accepted[trialIndex] });
        const updatedDetails = this.calculateDetails(updateTrialInfo);

        this.chartData$.next({
            ...chartDataValue,
            trialInfo: updateTrialInfo,
            detailsInfo: updatedDetails,
        });
    }
}
