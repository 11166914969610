import * as jsPDF from 'jspdf';
import { LensometryMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/LM7P/lensometryData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { LensometryTestRemarksFrontend } from '../../../test-remarks/NIDEK/LM7P/lensometryTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class LensometryMeasuredDataFrontend extends LensometryMeasuredData implements MeasuredDataFrontend {
    public remarks: LensometryTestRemarksFrontend;
    public OD: {
        Sphere: RegisterEditFieldFrontend<string>;
        Cylinder: RegisterEditFieldFrontend<string>;
        Axis: RegisterEditFieldFrontend<string>;
        SE: RegisterEditFieldFrontend<string>;
        ADD: RegisterEditFieldFrontend<string>;
        ADD2: RegisterEditFieldFrontend<string>;
        NearSphere: RegisterEditFieldFrontend<string>;
        NearSphere2: RegisterEditFieldFrontend<string>;
        Prism: RegisterEditFieldFrontend<string>;
        PrismBase: RegisterEditFieldFrontend<string>;
        PrismX: RegisterEditFieldFrontend<string>;
        PrismY: RegisterEditFieldFrontend<string>;
        UVTransmittance: RegisterEditFieldFrontend<string>;
        ConfidenceIndex: RegisterEditFieldFrontend<string>;
        lensType: RegisterEditFieldFrontend<string[]>;
        glassStatus: RegisterEditFieldFrontend<string>;
        remarks: RegisterEditFieldFrontend<string>;
    };
    public OS: {
        Sphere: RegisterEditFieldFrontend<string>;
        Cylinder: RegisterEditFieldFrontend<string>;
        Axis: RegisterEditFieldFrontend<string>;
        SE: RegisterEditFieldFrontend<string>;
        ADD: RegisterEditFieldFrontend<string>;
        ADD2: RegisterEditFieldFrontend<string>;
        NearSphere: RegisterEditFieldFrontend<string>;
        NearSphere2: RegisterEditFieldFrontend<string>;
        Prism: RegisterEditFieldFrontend<string>;
        PrismBase: RegisterEditFieldFrontend<string>;
        PrismX: RegisterEditFieldFrontend<string>;
        PrismY: RegisterEditFieldFrontend<string>;
        UVTransmittance: RegisterEditFieldFrontend<string>;
        ConfidenceIndex: RegisterEditFieldFrontend<string>;
        lensType: RegisterEditFieldFrontend<string[]>;
        glassStatus: RegisterEditFieldFrontend<string>;
        remarks: RegisterEditFieldFrontend<string>;
    };
    constructor() {
        super();
        this.OD = {
            Sphere: new RegisterEditFieldFrontend(),
            Cylinder: new RegisterEditFieldFrontend(),
            Axis: new RegisterEditFieldFrontend(),
            SE: new RegisterEditFieldFrontend(),
            ADD: new RegisterEditFieldFrontend(),
            ADD2: new RegisterEditFieldFrontend(),
            NearSphere: new RegisterEditFieldFrontend(),
            NearSphere2: new RegisterEditFieldFrontend(),
            Prism: new RegisterEditFieldFrontend(),
            PrismBase: new RegisterEditFieldFrontend(),
            PrismX: new RegisterEditFieldFrontend(),
            PrismY: new RegisterEditFieldFrontend(),
            UVTransmittance: new RegisterEditFieldFrontend(),
            ConfidenceIndex: new RegisterEditFieldFrontend(),
            lensType: new RegisterEditFieldFrontend(),
            glassStatus: new RegisterEditFieldFrontend(),
            remarks: new RegisterEditFieldFrontend(),
        };
        this.OS = {
            Sphere: new RegisterEditFieldFrontend(),
            Cylinder: new RegisterEditFieldFrontend(),
            Axis: new RegisterEditFieldFrontend(),
            SE: new RegisterEditFieldFrontend(),
            ADD: new RegisterEditFieldFrontend(),
            ADD2: new RegisterEditFieldFrontend(),
            NearSphere: new RegisterEditFieldFrontend(),
            NearSphere2: new RegisterEditFieldFrontend(),
            Prism: new RegisterEditFieldFrontend(),
            PrismBase: new RegisterEditFieldFrontend(),
            PrismX: new RegisterEditFieldFrontend(),
            PrismY: new RegisterEditFieldFrontend(),
            UVTransmittance: new RegisterEditFieldFrontend(),
            ConfidenceIndex: new RegisterEditFieldFrontend(),
            lensType: new RegisterEditFieldFrontend(),
            glassStatus: new RegisterEditFieldFrontend(),
            remarks: new RegisterEditFieldFrontend(),
        };
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table = [];
        table.push({
            Eye: 'Eye',
            Sphere: 'Sphere',
            Cylinder: 'Cylinder',
            Axis: 'Axis',
            SE: 'SE',
            ADD: 'ADD',
            ADD2: 'ADD 2',
            NearSphere: 'Near sphere',
            NearSphere2: 'Near sphere 2',
            Prism: 'Prism',
            PrismBase: 'Prism base',
            PrismX: 'Prism X',
            PrismY: 'Prism Y',
            UVTransmittance: 'UV transmittance',
            ConfidenceIndex: 'Confidence index',
        });
        table.push({
            Eye: 'OD',
            Sphere: this.OD.Sphere.value,
            Cylinder: this.OD.Cylinder.value,
            Axis: this.OD.Axis.value,
            SE: this.OD.SE.value,
            ADD: this.OD.ADD.value,
            ADD2: this.OD.ADD2.value,
            NearSphere: this.OD.NearSphere.value,
            NearSphere2: this.OD.NearSphere2.value,
            Prism: this.OD.Prism.value,
            PrismBase: this.OD.PrismBase.value,
            PrismX: this.OD.PrismX.value,
            PrismY: this.OD.PrismY.value,
            UVTransmittance: this.OD.UVTransmittance.value,
            ConfidenceIndex: this.OD.ConfidenceIndex.value,
        });
        table.push({
            Eye: 'OS',
            Sphere: this.OS.Sphere.value,
            Cylinder: this.OS.Cylinder.value,
            Axis: this.OS.Axis.value,
            SE: this.OS.SE.value,
            ADD: this.OS.ADD.value,
            ADD2: this.OS.ADD2.value,
            NearSphere: this.OS.NearSphere.value,
            NearSphere2: this.OS.NearSphere2.value,
            Prism: this.OS.Prism.value,
            PrismBase: this.OS.PrismBase.value,
            PrismX: this.OS.PrismX.value,
            PrismY: this.OS.PrismY.value,
            UVTransmittance: this.OS.UVTransmittance.value,
            ConfidenceIndex: this.OS.ConfidenceIndex.value,
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
        //////////
        table = [];
        table.push({
            Eye: 'Eye',
            lensType: 'Lens type',
            glassStatus: 'Glass status',
            remarks: 'Remarks',
        });
        table.push({
            Eye: 'OD',
            lensType: this.OD.lensType.value,
            glassStatus: this.OD.glassStatus.value,
            remarks: this.OD.remarks.value,
        });
        table.push({
            Eye: 'OS',
            lensType: this.OS.lensType.value,
            glassStatus: this.OS.glassStatus.value,
            remarks: this.OS.remarks.value,
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values =
            (this.OD.Sphere.value || 'x') +
            '/' +
            (this.OD.Cylinder.value || 'x') +
            '/' +
            (this.OD.Axis.value || 'x') +
            '/' +
            (this.OD.SE.value || 'x') +
            '/' +
            (this.OD.ADD.value || 'x') +
            '/' +
            (this.OD.ADD2.value || 'x') +
            '/' +
            (this.OD.NearSphere.value || 'x') +
            '/' +
            (this.OD.NearSphere2.value || 'x') +
            '/' +
            (this.OD.Prism.value || 'x') +
            '/' +
            (this.OD.PrismBase.value || 'x') +
            '/' +
            (this.OD.PrismX.value || 'x') +
            '/' +
            (this.OD.PrismY.value || 'x') +
            '/' +
            (this.OD.UVTransmittance.value || 'x') +
            '/' +
            (this.OD.ConfidenceIndex.value || 'x') +
            '/' +
            (this.OD.lensType.value || 'x') +
            '/' +
            (this.OD.glassStatus.value || 'x') +
            '/' +
            (this.OD.remarks.value || 'x');

        formattedTest.OS.values =
            (this.OS.Sphere.value || 'x') +
            '/' +
            (this.OS.Cylinder.value || 'x') +
            '/' +
            (this.OS.Axis.value || 'x') +
            '/' +
            (this.OS.SE.value || 'x') +
            '/' +
            (this.OS.ADD.value || 'x') +
            '/' +
            (this.OS.ADD2.value || 'x') +
            '/' +
            (this.OS.NearSphere.value || 'x') +
            '/' +
            (this.OS.NearSphere2.value || 'x') +
            '/' +
            (this.OS.Prism.value || 'x') +
            '/' +
            (this.OS.PrismBase.value || 'x') +
            '/' +
            (this.OS.PrismX.value || 'x') +
            '/' +
            (this.OS.PrismY.value || 'x') +
            '/' +
            (this.OS.UVTransmittance.value || 'x') +
            '/' +
            (this.OS.ConfidenceIndex.value || 'x') +
            '/' +
            (this.OS.lensType.value || 'x') +
            '/' +
            (this.OS.glassStatus.value || 'x') +
            '/' +
            (this.OS.remarks.value || 'x');
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
