import { Injectable } from '@angular/core';
import { ascending, max, mean, min, polygonArea, quantile } from 'd3';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IChartEdit } from '../../../../../../commonout/interfaces/chartEdit.interface';
import { ICamMessage, MESSAGE_TYPE } from '../../../../../../commonout/interfaces/charts.model';
import { IRawExportData } from '../../../../../common/interfaces/rawExportData.interface';
import { ChartService } from './chartService';

export interface CalculatedValues {
    pupilsQuantityOD: number;
    pupilsQuantityOS: number;
    glintsQuantityOD: number;
    glintsQuantityOS: number;
    maskSlippages: { stage1: { OD: number; OS: number }; stage2: { OD: number; OS: number }; stage3: { OD: number; OS: number }; stage4: { OD: number; OS: number } };
    pixelPerDegOD: number;
    pixelPerDegOS: number;
    zeroDivadedBy4: number;
    blinkingFrequency: string;
    phoria: {
        OD: { zeroToFirst: string; secondToThird: string };
        OS: { zeroToFirst: string; secondToThird: string };
    };
    motility: {
        stage1: { OD: string; OS: string };
        stage2: { OD: string; OS: string };
        stage3: { OD: string; OS: string };
        stage4: { OD: string; OS: string };
    };
    BPGSize: { OD: string; OS: string };
    DPGSize: { OD: string; OS: string };
    position: { OD: string; OS: string };
    IOL: string;
    pupilDistance: string;
    pupilSizeNoiseOD: number;
    pupilSizeNoiseOS: number;
    pupilCentreHorNoiseOD: number;
    pupilCentreHorNoiseOS: number;
    pupilCentreVerNoiseOD: number;
    pupilCentreVerNoiseOS: number;
    dpgCand90ileOD: number;
    dpgCand50ileOD: number;
    dpgCand10ileOD: number;
    dpgCand90ileOS: number;
    dpgCand50ileOS: number;
    dpgCand10ileOS: number;
    bpgCand90ileOD: number;
    bpgCand50ileOD: number;
    bpgCand10ileOD: number;
    bpgCand90ileOS: number;
    bpgCand50ileOS: number;
    bpgCand10ileOS: number;
    bpContr90ileOD: number;
    bpContr50ileOD: number;
    bpContr10ileOD: number;
    bpContr90ileOS: number;
    bpContr50ileOS: number;
    bpContr10ileOS: number;
    dpContr90ileOD: number;
    dpContr50ileOD: number;
    dpContr10ileOD: number;
    dpContr90ileOS: number;
    dpContr50ileOS: number;
    dpContr10ileOS: number;
    averGlintNoiseHorOD: number;
    averGlintNoiseHorOS: number;
    averGlintNoiseVerOD: number;
    averGlintNoiseVerOS: number;
    lensOD: string;
    lensOS: string;
}

@Injectable()
export class FunctionalScreeningChartService extends ChartService {
    public setEdits(edits: IChartEdit[]): void {}
    // data: any[] = [];
    testResults: any;
    isDataRendered$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    startTimestamp: number = undefined;
    finishTimestamp: number = undefined;

    trackingGlintsOD: { x: number; y: number; type: number; time: number }[] = [];
    trackingGlintsOS: { x: number; y: number; type: number; time: number }[] = [];
    pupilLinesOS: { x: number; y: number; type: number }[] = [];
    pupilLinesOD: { x: number; y: number; type: number }[] = [];

    BPGSizeOD: number[] = [];
    BPGSizeOS: number[] = [];
    DPGSizeOD: number[] = [];
    DPGSizeOS: number[] = [];
    positionOD: number[] = [];
    positionOS: number[] = [];
    IOLOD: number[] = [];
    IOLOS: number[] = [];
    pupilDistance: number[] = [];
    testIsDone = false;
    proSaccadeData: any[] = [];

    data: ICamMessage[] = [];
    // private firstFrame: IFunctionalScreeningTestCamMessage | null = null;

    public getRawExport(): IRawExportData {
        throw new Error('Method not implemented.');
    }
    constructor() {
        super();
    }
    public addData(frames: ICamMessage[]): Promise<void> {
        this.isDataRendered$.next(false);

        this.data.push(...frames);

        this.collectData(frames);

        return new Promise((res, rej) => {
            this.isDataRendered$.pipe(filter(data => Boolean(data))).subscribe(() => res());
        });
    }
    public setCamData(frames: ICamMessage[]): void {
        let startTimestamp: number = undefined;
        let finishTimestamp: number = undefined;

        let trackingGlintsOD: { x: number; y: number; type: number; time: number }[] = [];
        let trackingGlintsOS: { x: number; y: number; type: number; time: number }[] = [];
        let pupilLinesOS: { x: number; y: number; type: number }[] = [];
        let pupilLinesOD: { x: number; y: number; type: number }[] = [];

        let BPGSizeOD: number[] = [];
        let BPGSizeOS: number[] = [];
        let DPGSizeOD: number[] = [];
        let DPGSizeOS: number[] = [];
        let positionOD: number[] = [];
        let positionOS: number[] = [];
        let IOLOD: number[] = [];
        let IOLOS: number[] = [];
        let pupilDistance: number[] = [];
        let testIsDone = false;

        for (let e of frames) {
            if (e.target === 'PROSACCADE') {
                continue;
            }

            switch (e.message_type) {
                case MESSAGE_TYPE.DATA_PACKAGE:
                    if (!startTimestamp) startTimestamp = e.timestamp / 10000;

                    BPGSizeOD.push(e.bpgAreaOD);
                    BPGSizeOS.push(e.bpgAreaOS);
                    DPGSizeOD.push(e.dpgAreaOD);
                    DPGSizeOS.push(e.dpgAreaOS);
                    positionOD.push(e.distToOptOD);
                    positionOS.push(e.distToOptOS);
                    IOLOD.push(e.IOLOD ? 1 : 0);
                    IOLOS.push(e.IOLOS ? 1 : 0);
                    pupilDistance.push(e.IPD);

                    trackingGlintsOD.push({
                        x: (e.bpgxOD + e.dpgxOD) / 2,
                        y: (e.bpgyOD + e.dpgyOD) / 2,
                        type: e.message_type,
                        time: this.getXvalue(startTimestamp, e.timestamp),
                    });
                    trackingGlintsOS.push({
                        x: (e.bpgxOS + e.dpgxOS) / 2,
                        y: (e.bpgyOS + e.dpgyOS) / 2,
                        type: e.message_type,
                        time: this.getXvalue(startTimestamp, e.timestamp),
                    });
                    pupilLinesOD.push({ x: this.getXvalue(startTimestamp, e.timestamp), y: e.measurementOD, type: e.message_type });
                    pupilLinesOS.push({ x: this.getXvalue(startTimestamp, e.timestamp), y: e.measurementOS, type: e.message_type });
                    break;
                case MESSAGE_TYPE.STOP_TEST:
                    testIsDone = true;
                    break;
                case MESSAGE_TYPE.START_SEQUENCE:
                    trackingGlintsOD.push({
                        x: (e.bpgxOD + e.dpgxOD) / 2,
                        y: (e.bpgyOD + e.dpgyOD) / 2,
                        type: e.message_type,
                        time: this.getXvalue(startTimestamp, e.timestamp),
                    });
                    trackingGlintsOS.push({
                        x: (e.bpgxOS + e.dpgxOS) / 2,
                        y: (e.bpgyOS + e.dpgyOS) / 2,
                        type: e.message_type,
                        time: this.getXvalue(startTimestamp, e.timestamp),
                    });
                    break;
                case MESSAGE_TYPE.CONFIGURATION_PARAMS:
                    trackingGlintsOD.push({
                        x: (e.bpgxOD + e.dpgxOD) / 2,
                        y: (e.bpgyOD + e.dpgyOD) / 2,
                        type: e.message_type,
                        time: this.getXvalue(startTimestamp, e.timestamp),
                    });
                    trackingGlintsOS.push({
                        x: (e.bpgxOS + e.dpgxOS) / 2,
                        y: (e.bpgyOS + e.dpgyOS) / 2,
                        type: e.message_type,
                        time: this.getXvalue(startTimestamp, e.timestamp),
                    });
                    finishTimestamp = this.getXvalue(startTimestamp, e.timestamp);
                    break;
                default:
                    break;
            }
        }

        this.testResults = this.valuesCalculation({
            startTimestamp,
            finishTimestamp,

            trackingGlintsOD,
            trackingGlintsOS,
            pupilLinesOS,
            pupilLinesOD,

            BPGSizeOD,
            BPGSizeOS,
            DPGSizeOD,
            DPGSizeOS,
            positionOD,
            positionOS,
            IOLOD,
            IOLOS,
            pupilDistance,
            testIsDone,
            data: frames,
        });
    }
    public export() {
        return this.testResults;
    }
    public clearData(): void {
        this.trackingGlintsOD = [];
        this.trackingGlintsOS = [];
        this.pupilLinesOD = [];
        this.pupilLinesOS = [];
        this.testIsDone = false;
        this.startTimestamp = undefined;
        this.finishTimestamp = undefined;
        this.data = [];
        this.proSaccadeData = [];
        // bug fix about recalculation value
        this.pupilDistance = [];
    }

    collectData(frames: ICamMessage[]): void {
        for (let e of frames) {
            if (e.message_type === MESSAGE_TYPE.STOP_TEST) {
                this.testIsDone = true;
            }

            if (e.target === 'PROSACCADE') {
                this.proSaccadeData.push(e);
                continue;
            }

            switch (e.message_type) {
                case MESSAGE_TYPE.DATA_PACKAGE:
                    if (!this.startTimestamp) this.startTimestamp = e.timestamp / 10000;

                    this.BPGSizeOD.push(e.bpgAreaOD);
                    this.BPGSizeOS.push(e.bpgAreaOS);
                    this.DPGSizeOD.push(e.dpgAreaOD);
                    this.DPGSizeOS.push(e.dpgAreaOS);
                    this.positionOD.push(e.distToOptOD);
                    this.positionOS.push(e.distToOptOS);
                    this.IOLOD.push(e.IOLOD ? 1 : 0);
                    this.IOLOS.push(e.IOLOS ? 1 : 0);
                    this.pupilDistance.push(e.IPD);

                    this.trackingGlintsOD.push({
                        x: (e.bpgxOD + e.dpgxOD) / 2,
                        y: (e.bpgyOD + e.dpgyOD) / 2,
                        type: e.message_type,
                        time: this.getXvalue(this.startTimestamp, e.timestamp),
                    });
                    this.trackingGlintsOS.push({
                        x: (e.bpgxOS + e.dpgxOS) / 2,
                        y: (e.bpgyOS + e.dpgyOS) / 2,
                        type: e.message_type,
                        time: this.getXvalue(this.startTimestamp, e.timestamp),
                    });
                    this.pupilLinesOD.push({ x: this.getXvalue(this.startTimestamp, e.timestamp), y: e.measurementOD, type: e.message_type });
                    this.pupilLinesOS.push({ x: this.getXvalue(this.startTimestamp, e.timestamp), y: e.measurementOS, type: e.message_type });
                    break;
                case MESSAGE_TYPE.STOP_TEST:
                    this.testIsDone = true;
                    break;
                case MESSAGE_TYPE.START_SEQUENCE:
                    this.trackingGlintsOD.push({
                        x: (e.bpgxOD + e.dpgxOD) / 2,
                        y: (e.bpgyOD + e.dpgyOD) / 2,
                        type: e.message_type,
                        time: this.getXvalue(this.startTimestamp, e.timestamp),
                    });
                    this.trackingGlintsOS.push({
                        x: (e.bpgxOS + e.dpgxOS) / 2,
                        y: (e.bpgyOS + e.dpgyOS) / 2,
                        type: e.message_type,
                        time: this.getXvalue(this.startTimestamp, e.timestamp),
                    });
                    break;
                case MESSAGE_TYPE.CONFIGURATION_PARAMS:
                    this.trackingGlintsOD.push({
                        x: (e.bpgxOD + e.dpgxOD) / 2,
                        y: (e.bpgyOD + e.dpgyOD) / 2,
                        type: e.message_type,
                        time: this.getXvalue(this.startTimestamp, e.timestamp),
                    });
                    this.trackingGlintsOS.push({
                        x: (e.bpgxOS + e.dpgxOS) / 2,
                        y: (e.bpgyOS + e.dpgyOS) / 2,
                        type: e.message_type,
                        time: this.getXvalue(this.startTimestamp, e.timestamp),
                    });
                    this.finishTimestamp = this.getXvalue(this.startTimestamp, e.timestamp);
                    break;
                default:
                    break;
            }
        }
    }

    public valuesCalculation(chartData: {
        startTimestamp: number;
        finishTimestamp: number;

        trackingGlintsOD: { x: number; y: number; type: number; time: number }[];
        trackingGlintsOS: { x: number; y: number; type: number; time: number }[];
        pupilLinesOS: { x: number; y: number; type: number }[];
        pupilLinesOD: { x: number; y: number; type: number }[];

        BPGSizeOD: number[];
        BPGSizeOS: number[];
        DPGSizeOD: number[];
        DPGSizeOS: number[];
        positionOD: number[];
        positionOS: number[];
        IOLOD: number[];
        IOLOS: number[];
        pupilDistance: number[];
        testIsDone: boolean;
        data: ICamMessage[];
    }): CalculatedValues {
        // lens OD/OS
        const lensOD: string =
            chartData.data[0]['lensOD'] < 0 ? chartData.data[0]['lensOD'] : chartData.data[0]['lensOD'] > 0 ? `+${chartData.data[0]['lensOD']}` : chartData.data[0]['lensOD'];
        const lensOS: string =
            chartData.data[0]['lensOS'] < 0 ? chartData.data[0]['lensOS'] : chartData.data[0]['lensOS'] > 0 ? `+${chartData.data[0]['lensOS']}` : chartData.data[0]['lensOS'];

        // calculate quantity of pupils
        let pupilLinesOD = 0;
        let pupilLinesOS = 0;
        chartData.pupilLinesOD.forEach((e: { x: number; y: number }) => {
            if (e.x !== 0 && e.y !== 0) pupilLinesOD += 1;
        });
        chartData.pupilLinesOS.forEach((e: { x: number; y: number }) => {
            if (e.x !== 0 && e.y !== 0) pupilLinesOS += 1;
        });
        const pupilsQuantityOD = pupilLinesOD === 0 ? 0 : +((pupilLinesOD / chartData.pupilLinesOD.length) * 100).toFixed(0);
        const pupilsQuantityOS = pupilLinesOS === 0 ? 0 : +((pupilLinesOS / chartData.pupilLinesOS.length) * 100).toFixed(0);

        // calculate glint noise horizontal OD
        const middleHorGlintsOD: number[] = chartData.data
            .filter(frame => frame.bpgxOD !== null && !isNaN(frame.bpgxOD) && frame.dpgxOD !== null && !isNaN(frame.dpgxOD))
            .map(frame => (frame.dpgxOD + frame.bpgxOD) / 2);
        const glintNoiseHorOD: number[] = middleHorGlintsOD.map((glint, i, glints) => {
            if (i === 0) return;
            return Math.abs(glint - glints[i - 1]);
        });
        const averGlintNoiseHorOD: number = Number.parseFloat((glintNoiseHorOD.slice(1).reduce((partialSum, a) => partialSum + a, 0) / glintNoiseHorOD.length).toFixed(2));

        // calculate glint noise horizontal OS
        const middleHorGlintsOS: number[] = chartData.data
            .filter(frame => frame.bpgxOS !== null && !isNaN(frame.bpgxOS) && frame.dpgxOS !== null && !isNaN(frame.dpgxOS))
            .map(frame => (frame.dpgxOS + frame.bpgxOS) / 2);
        const glintNoiseHorOS: number[] = middleHorGlintsOS.map((glint, i, glints) => {
            if (i === 0) return;
            return Math.abs(glint - glints[i - 1]);
        });
        const averGlintNoiseHorOS: number = Number.parseFloat((glintNoiseHorOS.slice(1).reduce((partialSum, a) => partialSum + a, 0) / glintNoiseHorOS.length).toFixed(2));

        // calculate glint noise vertical OD
        const middleVerGlintsOD: number[] = chartData.data
            .filter(frame => frame.bpgyOD !== null && !isNaN(frame.bpgyOD) && frame.dpgyOD !== null && !isNaN(frame.dpgyOD))
            .map(frame => (frame.dpgyOD + frame.bpgyOD) / 2);
        const glintNoiseVerOD: number[] = middleVerGlintsOD.map((glint, i, glints) => {
            if (i === 0) return;
            return Math.abs(glint - glints[i - 1]);
        });
        const averGlintNoiseVerOD: number = Number.parseFloat((glintNoiseVerOD.slice(1).reduce((partialSum, a) => partialSum + a, 0) / glintNoiseVerOD.length).toFixed(2));

        // calculate glint noise vertical OS
        const middleVerGlintsOS: number[] = chartData.data
            .filter(frame => frame.bpgyOS !== null && !isNaN(frame.bpgyOS) && frame.dpgyOS !== null && !isNaN(frame.dpgyOS))
            .map(frame => (frame.dpgyOS + frame.bpgyOS) / 2);
        const glintNoiseVerOS: number[] = middleVerGlintsOS.map((glint, i, glints) => {
            if (i === 0) return;
            return Math.abs(glint - glints[i - 1]);
        });
        const averGlintNoiseVerOS: number = Number.parseFloat((glintNoiseVerOS.slice(1).reduce((partialSum, a) => partialSum + a, 0) / glintNoiseVerOS.length).toFixed(2));

        // calculate Pupil size noise OD
        const measurementOD: number[] = chartData.data.filter(frame => frame.measurementOD > 0).map(frame => frame.measurementOD);
        const measurementOD_Diff: number[] = measurementOD.map((pupilSize, i, array) => {
            if (i === 0) return;
            return Math.abs(pupilSize - array[i - 1]);
        });
        const pupilSizeNoiseOD: number = Number.parseFloat((measurementOD_Diff.slice(1).reduce((partialSum, a) => partialSum + a, 0) / measurementOD_Diff.length).toFixed(2));

        // calculate Pupil size noise OS
        const measurementOS: number[] = chartData.data.filter(frame => frame.measurementOS > 0).map(frame => frame.measurementOS);
        const measurementOS_Diff: number[] = measurementOS.map((pupilSize, i, array) => {
            if (i === 0) return;
            return Math.abs(pupilSize - array[i - 1]);
        });
        const pupilSizeNoiseOS: number = Number.parseFloat((measurementOS_Diff.slice(1).reduce((partialSum, a) => partialSum + a, 0) / measurementOS_Diff.length).toFixed(2));

        // calculate pupil centre noise horizontal OD
        const pupilCentreHorOD: number[] = chartData.data.filter(frame => frame.pupilxOD > 0).map(frame => frame.pupilxOD);
        const pupilCentreHorOD_Diff: number[] = pupilCentreHorOD.map((ODhorCentre, i, array) => {
            if (i === 0) return;
            return Math.abs(ODhorCentre - array[i - 1]);
        });
        const pupilCentreHorNoiseOD: number = Number.parseFloat(
            (pupilCentreHorOD_Diff.slice(1).reduce((partialSum, a) => partialSum + a, 0) / pupilCentreHorOD_Diff.length).toFixed(2)
        );

        // calculate pupil centre noise horizontal OS
        const pupilCentreHorOS: number[] = chartData.data.filter(frame => frame.pupilxOS > 0).map(frame => frame.pupilxOS);
        const pupilCentreHorOS_Diff: number[] = pupilCentreHorOS.map((OShorCentre, i, array) => {
            if (i === 0) return;
            return Math.abs(OShorCentre - array[i - 1]);
        });
        const pupilCentreHorNoiseOS: number = Number.parseFloat(
            (pupilCentreHorOS_Diff.slice(1).reduce((partialSum, a) => partialSum + a, 0) / pupilCentreHorOS_Diff.length).toFixed(2)
        );

        // calculate pupil centre noise vertical OD
        const pupilCentreVerOD: number[] = chartData.data.filter(frame => frame.pupilyOD > 0).map(frame => frame.pupilyOD);
        const pupilCentreVerOD_Diff: number[] = pupilCentreVerOD.map((ODverCentre, i, array) => {
            if (i === 0) return;
            return Math.abs(ODverCentre - array[i - 1]);
        });
        const pupilCentreVerNoiseOD: number = Number.parseFloat(
            (pupilCentreVerOD_Diff.slice(1).reduce((partialSum, a) => partialSum + a, 0) / pupilCentreVerOD_Diff.length).toFixed(2)
        );

        // calculate pupil centre noise vertical OS
        const pupilCentreVerOS: number[] = chartData.data.filter(frame => frame.pupilyOS > 0).map(frame => frame.pupilyOS);
        const pupilCentreVerOS_Diff: number[] = pupilCentreVerOS.map((OShorCentre, i, array) => {
            if (i === 0) return;
            return Math.abs(OShorCentre - array[i - 1]);
        });
        const pupilCentreVerNoiseOS: number = Number.parseFloat(
            (pupilCentreVerOS_Diff.slice(1).reduce((partialSum, a) => partialSum + a, 0) / pupilCentreVerOS_Diff.length).toFixed(2)
        );

        // calculate DPG candidates OD
        const dpgCandidatesOD: number[] = chartData.data
            .map(d => d.dpgCandidatesOD)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const dpgCand90ileOD: number = quantile(dpgCandidatesOD, 0.9);
        const dpgCand50ileOD: number = quantile(dpgCandidatesOD, 0.5);
        const dpgCand10ileOD: number = quantile(dpgCandidatesOD, 0.1);

        // calculate DPG candidates OS
        const dpgCandidatesOS: number[] = chartData.data
            .map(d => d.dpgCandidatesOS)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const dpgCand90ileOS: number = quantile(dpgCandidatesOS, 0.9);
        const dpgCand50ileOS: number = quantile(dpgCandidatesOS, 0.5);
        const dpgCand10ileOS: number = quantile(dpgCandidatesOS, 0.1);

        // calculate BPG candidates OD
        const bpgCandidatesOD: number[] = chartData.data
            .map(d => d.bpgCandidatesOD)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const bpgCand90ileOD: number = quantile(bpgCandidatesOD, 0.9);
        const bpgCand50ileOD: number = quantile(bpgCandidatesOD, 0.5);
        const bpgCand10ileOD: number = quantile(bpgCandidatesOD, 0.1);

        // calculate BPG candidates OS
        const bpgCandidatesOS: number[] = chartData.data
            .map(d => d.bpgCandidatesOS)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const bpgCand90ileOS: number = quantile(bpgCandidatesOS, 0.9);
        const bpgCand50ileOS: number = quantile(bpgCandidatesOS, 0.5);
        const bpgCand10ileOS: number = quantile(bpgCandidatesOS, 0.1);

        // calculate DP contrast OD
        const dpContrastOD: number[] = chartData.data
            .map(d => d.DPcontrastOD)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const dpContr90ileOD: number = quantile(dpContrastOD, 0.9);
        const dpContr50ileOD: number = quantile(dpContrastOD, 0.5);
        const dpContr10ileOD: number = quantile(dpContrastOD, 0.1);

        // calculate DP contrast OS
        const dpContrastOS: number[] = chartData.data
            .map(d => d.DPcontrastOS)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const dpContr90ileOS: number = quantile(dpContrastOS, 0.9);
        const dpContr50ileOS: number = quantile(dpContrastOS, 0.5);
        const dpContr10ileOS: number = quantile(dpContrastOS, 0.1);

        // calculate BP contrast OD
        const bpContrastOD: number[] = chartData.data
            .map(d => d.BPcontrastOD)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const bpContr90ileOD: number = quantile(bpContrastOD, 0.9);
        const bpContr50ileOD: number = quantile(bpContrastOD, 0.5);
        const bpContr10ileOD: number = quantile(bpContrastOD, 0.1);

        // calculate BP contrast OS
        const bpContrastOS: number[] = chartData.data
            .map(d => d.BPcontrastOS)
            .filter(d => d !== null && !isNaN(d))
            .sort(ascending);
        const bpContr90ileOS: number = quantile(bpContrastOS, 0.9);
        const bpContr50ileOS: number = quantile(bpContrastOS, 0.5);
        const bpContr10ileOS: number = quantile(bpContrastOS, 0.1);

        // calculate quantity of glints
        let glintsOD = 0;
        let glintsOS = 0;
        chartData.trackingGlintsOD.forEach((e: { x: number; y: number }) => {
            if (e.x !== 0 && e.y !== 0) glintsOD += 1;
        });
        chartData.trackingGlintsOS.forEach((e: { x: number; y: number }) => {
            if (e.x !== 0 && e.y !== 0) glintsOS += 1;
        });
        const glintsQuantityOD = glintsOD === 0 ? 0 : +((glintsOD / chartData.trackingGlintsOD.length) * 100).toFixed(0);
        const glintsQuantityOS = glintsOS === 0 ? 0 : +((glintsOS / chartData.trackingGlintsOS.length) * 100).toFixed(0);

        // calculate mask slippage
        const firstAndLastPoints = {
            stage1: { firstPointOD: { x: 0, y: 0 }, firstPointOS: { x: 0, y: 0 }, lastPointOD: { x: 0, y: 0 }, lastPointOS: { x: 0, y: 0 } },
            stage2: { firstPointOD: { x: 0, y: 0 }, firstPointOS: { x: 0, y: 0 }, lastPointOD: { x: 0, y: 0 }, lastPointOS: { x: 0, y: 0 } },
            stage3: { firstPointOD: { x: 0, y: 0 }, firstPointOS: { x: 0, y: 0 }, lastPointOD: { x: 0, y: 0 }, lastPointOS: { x: 0, y: 0 } },
            stage4: { firstPointOD: { x: 0, y: 0 }, firstPointOS: { x: 0, y: 0 }, lastPointOD: { x: 0, y: 0 }, lastPointOS: { x: 0, y: 0 } },
        };

        const totalXValuesOD: number[] = [];
        const totalXValuesOS: number[] = [];

        const findFirstAndLastPoints = (eye: string, which: number) => {
            const startingStage: number[] = [];
            const finsishinggStage: number[] = [];
            const arrForSearching = [...chartData[`trackingGlints${eye}`]];

            arrForSearching.forEach((el: { type: number }, i: number) => {
                if (el.type === 15) {
                    startingStage.push(i);
                    arrForSearching.splice(i, 1, {});
                }
                if (el.type === 16) {
                    finsishinggStage.push(i);
                    arrForSearching.splice(i, 1, {});
                }
            });

            let filteredBottomTraces: { x: number; y: number; type: number }[];
            let index15: number;
            let index16: number;

            switch (which) {
                case 1:
                    index15 = startingStage[1];
                    index16 = finsishinggStage[1];
                    break;
                case 2:
                    index15 = startingStage[2];
                    index16 = finsishinggStage[2];
                    break;
                case 3:
                    index15 = startingStage[3];
                    index16 = finsishinggStage[3];
                    break;
                case 4:
                    index15 = startingStage[4];
                    index16 = finsishinggStage[4];
                    break;
            }

            filteredBottomTraces = [...chartData[`trackingGlints${eye}`]].filter((e, i) => i > index15 && i < index16).filter(e => e.x !== 0 && e.y !== 0);

            if (eye === 'OD') {
                filteredBottomTraces.forEach(e => {
                    totalXValuesOD.push(e.x);
                });
            }
            if (eye === 'OS') {
                filteredBottomTraces.forEach(e => {
                    totalXValuesOS.push(e.x);
                });
            }

            firstAndLastPoints[`stage${which}`][`firstPoint${eye}`] = filteredBottomTraces[0];
            firstAndLastPoints[`stage${which}`][`lastPoint${eye}`] = filteredBottomTraces[filteredBottomTraces.length - 1];
        };

        const maskSlippages = {
            stage1: { OD: 0, OS: 0 },
            stage2: { OD: 0, OS: 0 },
            stage3: { OD: 0, OS: 0 },
            stage4: { OD: 0, OS: 0 },
        };

        const calculateMaskSlippages = (eye: string, stage: number) => {
            maskSlippages[`stage${stage}`][`${eye}`] = (
                Math.sqrt(
                    Math.pow(firstAndLastPoints[`stage${stage}`][`lastPoint${eye}`]?.x - firstAndLastPoints[`stage${stage}`][`firstPoint${eye}`]?.x, 2) +
                        Math.pow(firstAndLastPoints[`stage${stage}`][`lastPoint${eye}`]?.y - firstAndLastPoints[`stage${stage}`][`firstPoint${eye}`]?.y, 2)
                ) | 0
            ).toFixed(1);
        };

        [
            { eye: 'OD', stage: 1 },
            { eye: 'OD', stage: 2 },
            { eye: 'OD', stage: 3 },
            { eye: 'OD', stage: 4 },
            { eye: 'OS', stage: 1 },
            { eye: 'OS', stage: 2 },
            { eye: 'OS', stage: 3 },
            { eye: 'OS', stage: 4 },
        ].forEach(e => {
            findFirstAndLastPoints(e.eye, e.stage);
            calculateMaskSlippages(e.eye, e.stage);
        });

        // find pixel/deg values
        const maxValueOD = max(totalXValuesOD);
        const minValueOD = min(totalXValuesOD);
        const maxValueOS = max(totalXValuesOS);
        const minValueOS = min(totalXValuesOS);

        const pixelPerDegOD = +Math.abs(maxValueOD - minValueOD).toFixed(0);
        const pixelPerDegOS = +Math.abs(maxValueOS - minValueOS).toFixed(0);

        // calculate dilated patient

        const findPupilLines0and4 = (): number => {
            const startingStage: number[] = [];
            const finsishinggStage: number[] = [];
            const arrForSearching: { x: number; y: number; type: number }[] = [...chartData.trackingGlintsOD];

            arrForSearching.forEach((el, i) => {
                if (el.type === 15) {
                    startingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
                if (el.type === 16) {
                    finsishinggStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
            });

            const filteredPupilLinesStage0 = [
                ...[...chartData.pupilLinesOD].filter((e, i) => i > startingStage[0] && i < finsishinggStage[0]),
                ...[...chartData.pupilLinesOS].filter((e, i) => i > startingStage[0] && i < finsishinggStage[0]),
            ];

            const filteredPupilLinesStage4 = [
                ...[...chartData.pupilLinesOD].filter((e, i) => i > startingStage[4] && i < finsishinggStage[4]),
                ...[...chartData.pupilLinesOS].filter((e, i) => i > startingStage[4] && i < finsishinggStage[4]),
            ];

            const pupilLinesStage0: number[] = [];
            const pupilLinesStage4: number[] = [];

            filteredPupilLinesStage0.forEach(e => pupilLinesStage0.push(e.y));
            filteredPupilLinesStage4.forEach(e => pupilLinesStage4.push(e.y));

            return +((mean(pupilLinesStage0) / mean(pupilLinesStage4)) * 100).toFixed(0);
        };

        const zeroDivadedBy4 = findPupilLines0and4();

        // calculate blinking frequency
        const calculateAmountOfBlinks = (eye: string): number => {
            const filteredTrackingGlints = [...chartData[`trackingGlints${eye}`]].filter(e => e.type !== 15 && e.type !== 16);

            const zeroValueIndices: number[] = [];
            const arrForSearching = [...filteredTrackingGlints];

            arrForSearching.forEach((el, i) => {
                if (el.x === 0 && el.y === 0) {
                    zeroValueIndices.push(i);
                    arrForSearching.splice(i, 1, {});
                }
            });

            let valueForCounting = 1;
            const zeroValuesPerTime: number[] = [];

            zeroValueIndices.forEach((e, i) => {
                if (zeroValueIndices[i + 1] === e + 1) {
                    valueForCounting += 1;
                } else {
                    zeroValuesPerTime.push(valueForCounting);
                    valueForCounting = 1;
                }
            });

            return [...zeroValuesPerTime].filter(e => e >= 20 && e <= 200).length;
        };

        let totalAmountOfBlinks = 0;

        ['OD', 'OS'].forEach(e => (totalAmountOfBlinks += calculateAmountOfBlinks(e)));
        const blinkingFrequency = totalAmountOfBlinks === 0 ? 'no blinks' : `${(chartData.finishTimestamp / totalAmountOfBlinks).toFixed(1)} s/blink`;

        // finding motility
        const motility = {
            stage1: { OD: '', OS: '' },
            stage2: { OD: '', OS: '' },
            stage3: { OD: '', OS: '' },
            stage4: { OD: '', OS: '' },
        };

        const findMotility = (eye: string, stage: number) => {
            const startingStage: number[] = [];
            const finsishingStage: number[] = [];
            const arrForSearching: { x: number; y: number; type: number }[] = [...chartData[`trackingGlints${eye}`]];

            arrForSearching.forEach((el, i) => {
                if (el.type === 15) {
                    startingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
                if (el.type === 16) {
                    finsishingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
            });

            const polygonArrayOD: [number, number][] = [...chartData.trackingGlintsOD]
                .filter((e, i) => i > startingStage[stage] && i < finsishingStage[stage])
                .filter(e => e.x !== 0 && e.y !== 0)
                .map(e => [e.x, e.y]);
            const polygonArrayOS: [number, number][] = [...chartData.trackingGlintsOS]
                .filter((e, i) => i > startingStage[stage] && i < finsishingStage[stage])
                .filter(e => e.x !== 0 && e.y !== 0)
                .map(e => [e.x, e.y]);

            const areaOD = Math.abs(polygonArea(polygonArrayOD));
            const areaOS = Math.abs(polygonArea(polygonArrayOS));

            if (eye === 'OD') {
                let val = (areaOD / (areaOD + areaOS)) * 100 * 2;
                val > 99 ? (val = 99) : null;

                return (motility[`stage${stage}`].OD = val.toFixed(0));
            }
            if (eye === 'OS') {
                let val = (areaOS / (areaOD + areaOS)) * 100 * 2;
                val > 99 ? (val = 99) : null;

                return (motility[`stage${stage}`].OS = val.toFixed(0));
            }
        };

        [
            { eye: 'OD', stage: 1 },
            { eye: 'OD', stage: 2 },
            { eye: 'OD', stage: 3 },
            { eye: 'OD', stage: 4 },
            { eye: 'OS', stage: 1 },
            { eye: 'OS', stage: 2 },
            { eye: 'OS', stage: 3 },
            { eye: 'OS', stage: 4 },
        ].forEach(e => {
            findMotility(e.eye, e.stage);
        });

        // Phoria / Strobism
        const findPhoriaAndStrobism = (eye: string) => {
            const startingStage: number[] = [];
            const finsishingStage: number[] = [];
            const arrForSearching: { x: number; y: number; type: number }[] = [...chartData[`trackingGlints${eye}`]];

            arrForSearching.forEach((el, i) => {
                if (el.type === 15) {
                    startingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
                if (el.type === 16) {
                    finsishingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
            });

            const betweenZeroAndFirstStage = [...chartData[`trackingGlints${eye}`]]
                .filter(e => e.x !== 0 && e.y !== 0)
                .filter(
                    (e: { x: number; y: number; type: number; time: number }) =>
                        e.time >= chartData[`trackingGlints${eye}`][finsishingStage[0]]?.time - 0.1 && e.time <= chartData[`trackingGlints${eye}`][startingStage[1]]?.time + 0.1
                );

            const betweenSecondAndThirdStage = [...chartData[`trackingGlints${eye}`]]
                .filter(e => e.x !== 0 && e.y !== 0)
                .filter(
                    (e: { x: number; y: number; type: number; time: number }) =>
                        e.time >= chartData[`trackingGlints${eye}`][finsishingStage[2]]?.time - 0.1 && e.time <= chartData[`trackingGlints${eye}`][startingStage[3]]?.time + 0.1
                );

            const firstPointZeroStage = betweenZeroAndFirstStage[0];
            const lastPointZeroStage = betweenZeroAndFirstStage[betweenZeroAndFirstStage.length - 1];

            const firstPointSecondStage = betweenSecondAndThirdStage[0];
            const lastPointSecondStage = betweenSecondAndThirdStage[betweenSecondAndThirdStage.length - 1];

            const distanceZeroToFirstStage = Math.sqrt(Math.pow(lastPointZeroStage?.x - firstPointZeroStage?.x, 2) + Math.pow(lastPointZeroStage?.y - firstPointZeroStage?.y, 2));
            const distanceSecondToThirdStage = Math.sqrt(
                Math.pow(lastPointSecondStage?.x - firstPointSecondStage?.x, 2) + Math.pow(lastPointSecondStage?.y - firstPointSecondStage?.y, 2)
            );

            phoria[`${eye}`].zeroToFirst = distanceZeroToFirstStage.toFixed(1);
            phoria[`${eye}`].secondToThird = distanceSecondToThirdStage.toFixed(1);
        };

        const phoria = {
            OD: { zeroToFirst: '', secondToThird: '' },
            OS: { zeroToFirst: '', secondToThird: '' },
        };

        ['OD', 'OS'].forEach(e => findPhoriaAndStrobism(e));

        //BPG, DPG sizes, pupil distance, positions and IOLs
        const BPGSize = {
            OD: '',
            OS: '',
        };

        const DPGSize = {
            OD: '',
            OS: '',
        };

        const position = {
            OD: '',
            OS: '',
        };

        let IOL = '';

        let pupilDistance = '';

        const findSizes = () => {
            const startingStage: number[] = [];
            const finsishingStage: number[] = [];
            const arrForSearching: { x: number; y: number; type: number }[] = [...chartData.trackingGlintsOD];

            arrForSearching.forEach((el, i) => {
                if (el.type === 15) {
                    startingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
                if (el.type === 16) {
                    finsishingStage.push(i);
                    arrForSearching.splice(i, 1, { x: 0, y: 0, type: 0 });
                }
            });

            const filteredBPGSizeOD = [...chartData.BPGSizeOD].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);
            const filteredBPGSizeOS = [...chartData.BPGSizeOS].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);
            const filteredDPGSizeOD = [...chartData.DPGSizeOD].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);
            const filteredDPGSizeOS = [...chartData.DPGSizeOS].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);
            const filteredpositionOD = [...chartData.positionOD].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);
            const filteredpositionOS = [...chartData.positionOS].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);
            const filteredPupilDistance = [...chartData.pupilDistance].filter((e, i) => i > startingStage[0] && i < finsishingStage[0]);

            BPGSize.OD = (filteredBPGSizeOD.reduce((acc, num) => acc + num, 0) / filteredBPGSizeOD.length).toFixed(1);
            BPGSize.OS = (filteredBPGSizeOS.reduce((acc, num) => acc + num, 0) / filteredBPGSizeOS.length).toFixed(1);

            DPGSize.OD = (filteredDPGSizeOD.reduce((acc, num) => acc + num, 0) / filteredDPGSizeOD.length).toFixed(1);
            DPGSize.OS = (filteredDPGSizeOS.reduce((acc, num) => acc + num, 0) / filteredDPGSizeOS.length).toFixed(1);

            position.OD = (filteredpositionOD.reduce((acc, num) => acc + num, 0) / filteredpositionOD.length).toFixed(1);
            position.OS = (filteredpositionOS.reduce((acc, num) => acc + num, 0) / filteredpositionOS.length).toFixed(1);

            IOL = ([...chartData.IOLOD, ...chartData.IOLOS].reduce((acc, num) => acc + num, 0) / chartData.IOLOD.length).toFixed(1);

            pupilDistance = (filteredPupilDistance.reduce((acc, num) => acc + num, 0) / filteredPupilDistance.length).toFixed(1);
        };

        findSizes();

        return {
            pupilsQuantityOD,
            pupilsQuantityOS,
            glintsQuantityOD,
            glintsQuantityOS,
            maskSlippages,
            pixelPerDegOD,
            pixelPerDegOS,
            zeroDivadedBy4,
            blinkingFrequency,
            phoria,
            motility,
            BPGSize,
            DPGSize,
            pupilDistance,
            position,
            IOL,
            pupilSizeNoiseOD,
            pupilSizeNoiseOS,
            pupilCentreHorNoiseOD,
            pupilCentreHorNoiseOS,
            pupilCentreVerNoiseOD,
            pupilCentreVerNoiseOS,
            dpgCand90ileOD,
            dpgCand50ileOD,
            dpgCand10ileOD,
            dpgCand90ileOS,
            dpgCand50ileOS,
            dpgCand10ileOS,
            bpgCand90ileOD,
            bpgCand50ileOD,
            bpgCand10ileOD,
            bpgCand90ileOS,
            bpgCand50ileOS,
            bpgCand10ileOS,
            bpContr90ileOD,
            bpContr50ileOD,
            bpContr10ileOD,
            bpContr90ileOS,
            bpContr50ileOS,
            bpContr10ileOS,
            dpContr90ileOD,
            dpContr50ileOD,
            dpContr10ileOD,
            dpContr90ileOS,
            dpContr50ileOS,
            dpContr10ileOS,
            averGlintNoiseHorOD,
            averGlintNoiseHorOS,
            averGlintNoiseVerOD,
            averGlintNoiseVerOS,
            lensOD,
            lensOS,
        };
    }
}
