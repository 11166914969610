import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import { ThreeDScanIvueOctMeasuredData } from '../../../../../../../common/models/measuredData/IVUE/OCT/3dscanIvueOctData.model';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { ThreeDScanIcueOctTestRemarksFrontend } from '../../../test-remarks/IVUE/OCT/ThreeDScanIvueOctTestRemarksFrontend.class';
import { IvueOctImageFrontend } from '../../../ivueOctResultFileFrontend.class';
import { I3DScanIvueOctMeasuredData } from '../../../../../../../common/interfaces/measuredData/IVUE/OCT/3dscan-ivue-oct.measuredData.interface';
import { OCULUS } from '../../../../../../../common/enums/oculus.enum';

export class ThreeDScanIvueOCTMeasuredDataFrontend extends ThreeDScanIvueOctMeasuredData implements MeasuredDataFrontend {
    public remarks: ThreeDScanIcueOctTestRemarksFrontend;
    public images: IvueOctImageFrontend[];
    constructor() {
        super();
    }
    public setModel(model: I3DScanIvueOctMeasuredData): void {
        this.images = model.images.map(model => {
            const image: IvueOctImageFrontend = new IvueOctImageFrontend();
            image.setModel(model);
            return image;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        try {
        } catch (error) {
            console.log(error);
        }
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: this.images.filter(i => i.oculus === OCULUS.OD).map(i => i.image.imageUrl),
            },
            OS: {
                values: '',
                images: this.images.filter(i => i.oculus === OCULUS.OS).map(i => i.image.imageUrl),
            },
            OU: {
                values: '',
                images: [],
            },
        };
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.images.map(ni => ni.fetchImage(fileService)));
    }
}
