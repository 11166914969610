// @ts-nocheck
import * as _ from 'lodash'

// import { State, DataSet } from './State'
import { Protocol } from './Protocol'

// NOTE: refactoring ideas
// - State vs DataState

const START_STIM_DIST = 670
const MID_STIM_DIST = 70

export class FakeData {
  nDataSet = 9
  nDataPoint = 100

  timeSec = +new Date()/1000
  output: any

  //
  // somewhat dsl-y look with different naming-cases
  //
  generateOutput() {
    this.output = []

    this.TestStart()

    _.times(this.nDataSet, id => {
      this.RunStart()

      _.times(this.nDataPoint, i => {
        this.DataPoint(i)
      })

      this.RunStop()
    })

    this.TestStop()
  }

  speedOd = 0
  breakMiddle = 0
  breakOd1 = 0
  breakOd2 = 0
  distOd = 0
  stimuliDist = 0
  stimuliDistSpeed = 0
  initCurve() {
    this.stimuliDist = START_STIM_DIST
    this.distOd = 100
    this.breakMiddle = Math.floor(this.nDataPoint / 2)
    let sizeOd = Math.floor(_.random(this.nDataPoint / 5, this.nDataPoint / 3))
    this.breakOd1 = this.breakMiddle - sizeOd
    this.breakOd2 = this.breakMiddle + sizeOd
  }
  shrinking() {
    return -this.growing()
  }
  growing() {
    return _.random(0.5,1.5)
  }
  closer() {
    return -this.further()
  }
  further() {
    return (START_STIM_DIST - MID_STIM_DIST) / this.nDataPoint * 2
  }
  updateCurve(i: number) {
    if (i === 0) this.initCurve()
    if (i === 0) this.speedOd = this.shrinking() * 3
    if (i === this.breakOd1) this.speedOd = this.growing() * 0.5
    if (i === this.breakMiddle) this.speedOd = this.shrinking() * 0.5
    if (i === this.breakOd2) this.speedOd = this.growing() * 2

    if (i === 0) this.stimuliDistSpeed = this.closer()
    if (i === this.breakMiddle) this.stimuliDistSpeed = this.further()

    if (i === this.breakMiddle) {
      this.BreakPoint()
    }

    this.distOd += this.speedOd
    // this.stimuliDist = i
    this.stimuliDist += this.stimuliDistSpeed
  }

  prevOdPd = 6
  get odPd() {
    let upd = _.random(1, 7, true)
    this.prevOdPd = (upd + this.prevOdPd) / 2
    return this.prevOdPd
  }

  prevOsPd = 6
  get osPd() {
    let upd = _.random(1, 7, true)
    this.prevOsPd = (upd + this.prevOsPd) / 2
    return this.prevOsPd
  }

  BreakPoint() {
    this.do({
      message_type: 9,
    })
  }

  DataPoint(i: number) {
    this.updateCurve(i)
    let stimuli_dist = this.stimuliDist
    let eyes_dist = this.distOd
    this.do({
      message_type: 6,
      stimuli_dist,
      eyes_dist,
      // stimuli_dist: 0, //_.random(100, 600),
      // eyes_dist: 0, //_.random(50, 250),
      // od_pd: _.random(1, 6, true),
      // os_pd: _.random(1, 6, true),
      od_pd: this.odPd,
      os_pd: this.osPd,
    })
  }
  runId = 0
  RunStart() {
    this.do({
      stimuli: this.runId,
      // message_type: 2,
      message_type: 15,
    })
    this.runId += 1
  }
  RunStop() {
    this.do({
      // message_type: 3,
      message_type: 16,
    })
  }
  TestStart() {
    this.do({
      message_type: 0,
    })
  }
  TestStop() {
    this.do({
      message_type: 1,
    })
  }

  do(x: any) {
    this.output.push({
      timestamp: secToProtocolTime(this.timeSec),
      ...x,
    })
    this.timeSec += 1
  }

  fill(protocol: Protocol) {
    this.generateOutput()
    this.output.forEach((x:any) => {
      protocol.fakeInput(x)
    })
  }
}

function secToProtocolTime(sec: number) {
  return sec * 10_000_000
}
