import { ConvergenceBulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/convergenceBulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { ConvergenceBulbicamTestRemarksComponent, ConvergenceStartStopControlComponent, VergenceBulbicamTestChartComponent } from '../../../_components';
import { ConvergenceBulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/convergenceBulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class ConvergenceBulbicamTestFrontend extends ConvergenceBulbicamTest {
    remarks: ConvergenceBulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof ConvergenceBulbicamTestRemarksComponent = ConvergenceBulbicamTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof VergenceBulbicamTestChartComponent = VergenceBulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof ConvergenceStartStopControlComponent = ConvergenceStartStopControlComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.SINGLE;
    constructor() {
        super();
        this.remarks = new ConvergenceBulbicamTestRemarksFrontend();
    }
}

export interface ConvergenceBulbicamTestFrontend extends TestFrontend {}
applyMixins(ConvergenceBulbicamTestFrontend, [TestFrontend]);
