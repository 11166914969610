/**
 * Described race field of patient record in DB
 */
export enum RACE {
    AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN INDIAN OR ALASKA NATIVE',
    ASIAN = 'ASIAN',
    BLACK_OR_AFRICAN_AMERICAN = 'BLACK OR AFRICAN AMERICAN',
    NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE HAWAIIAN OR OTHER PACIFIC ISLANDER',
    WHITE = 'WHITE',
    HISPANIC_OR_LATINO_OR_SPANISH_ORIGIN = 'HISPANIC OR LATINO OR SPANISH ORIGIN',
    MULTI_ETHNIC_ORIGIN_OR_OTHER = 'MULTI ETHNIC ORIGIN OR OTHER',
}
