// @ts-nocheck
import * as _ from 'lodash'
import * as d3 from 'd3'
import chroma from 'chroma-js'
import { sliceMaBothSides, doMaBothSides, doMa2, doMa2x2 } from './ma'
import { STIMULI_IMAGES, SMILE_IMG_INDEX } from '../../../lib/new/constants'

import { RunStart, DataPoint, TestStart, TestStop, TestResult } from './Protocol'
// import { USE_FAKE_DATA, EYE_OD, EYE_OS, I_ACUITY, I_CONTRAST, I_FREQ, I_CONTRAST2 } from '../constants'
import { UiState } from './UiState'
// import { Timing } from './timing'

import { getFFT, getSampleRate } from './fft'

const ALOT = 10_000

let swap = (xs: number[]) => {
  return [xs[1], xs[0]]
}

let getY = (group: any, point: any) => {
  let y = group.yParam(point)
  y = group.scale(y)
  return y
}

// 0.015 -> 2.0
// 0.9   -> 0.2
// let contrastF
// if (true) {
//   let x1 = 0.9
//   let y1 = 0.2
//   let x2 = 0.015
//   let y2 = 2.0
//   let b = (y2*x1 - y1*x2) / (y1 - y2)
//   let a = (x1 + b) / y1
//   // NOTE: error somewhere, not used anyway
//   contrastF = (x:number) => a * x + b
// }
// export const ACUITY = {
//   type: I_ACUITY,
//   range: [0, 1],
//   rangeOutput: [0, 1],
//   viewRange: swap([1, -0.3]), // on the diagram
//   arrow: 'logmar (wip)',
//   scale: 0 as any,
//   yParam: (x:any) => x.logmar,
// }
// export const CONTRAST = {
//   type: I_CONTRAST,
//   // range: [0, 1],
//   // rangeOutput: [contrastF(0), contrastF(1)]
//   // range: [0.015, 0.9],
//   // rangeOutput: [contrastF(0.015), contrastF(0.9)]

//   // range: [0.015, 0.9],
//   // rangeOutput: [2.0, 0.2],
//   // viewRange: swap([0.2, 2.0]), // on the diagram

//   range: [0.015, 0.9],
//   rangeOutput: [0.015, 0.9],
//   // viewRange: swap([1, 0]), // on the diagram
//   viewRange: swap([1, 0.001]), // on the diagram

//   arrow: 'cont.',
//   scale: 0 as any,
//   yParam: (x:any) => x.targetColorR,
// }
// export const CONTRAST2 = {
//   type: I_CONTRAST2,
//   range: [0.015, 0.9],
//   rangeOutput: [0.015, 0.9],
//   // viewRange: swap([1, 0]), // on the diagram
//   viewRange: swap([1, 0.001]), // on the diagram

//   arrow: 'cont(2).',
//   scale: 0 as any,
//   yParam: (x:any) => x.targetColorR,
// }
// export const PUPIL = {
//   viewRange: CONTRAST.viewRange.map(x => x * 10)
// }
// export const FREQ = {
//   type: I_FREQ,
//   range: [1, 10],
//   rangeOutput: [1, 10],
//   viewRange: swap([1, 10]), // on the diagram
//   arrow: 'freq.',
//   scale: 0 as any,
//   yParam: (x:any) => x.frequency,
// }
// ;[ACUITY, CONTRAST, FREQ, CONTRAST2].forEach(x => {
//   x.scale = d3.scaleLinear().domain(x.range).range(x.rangeOutput)
// })
// let getGroup = (x:any) => {
//   if (x.type === I_ACUITY) return ACUITY
//   if (x.type === I_CONTRAST) return CONTRAST
//   if (x.type === I_FREQ) return FREQ
//   if (x.type === I_CONTRAST2) return CONTRAST2
//   throw 'unknown type'
// }




const MIN_FREQ = 0.1
const FREQ_MA = 0

const M = 0.1 // interval margin
const EMPTY_LINE = [{x:0,y:0}, {x:0,y:0}]

const USE_NEXT_MA = true // use the fastest version
const USE_DO_MA = true // ignore this if the param above is enabled

export class State {
  nextId = 0
  dataSets: DataSet[] = []
  minz = 0
  startTime = 0
  runLength = 0
  info: string[] = []
  showRapdLines = false
  done = false
  // there is unused trash likely...

  ui = new UiState()

  patientType = ''

  testResult = {
    od: '',
    os: '',
    ou: '',
  }
  get text() {
    let { od, os, ou } = this.testResult
    if (ou) return ou
    if (!od && !os) return 'No results yet.'
    return `OD: ${od}\nOS: ${os}.`
  }

  fill(newDataSets: DataSet[]) {
    this.dataSets = newDataSets
  }

  onTestResult(x: TestResult) {
    if (x.exitType === 3) {
      this.testResult.ou = x.stopMessage || '...'
    } else {
      let eye = ''
      if (x.eye === 0) {
        eye = 'od'
      } else if (x.eye === 1) {
        eye = 'os'
      }
      if (eye) {
        this.testResult[eye] = `exit-type = ${x.exitType}`
      }
    }
  }

  onTestStart(x: TestStart) {
    this.startTime = toSeconds(x.timestamp)
    this.onRunStart(x as any)
    this.patientType = x.patient
    // this.runLength = x.runLength
  }

  onRunStart(given: RunStart) {
    this.dataSet?.finalize(given)

    let one = new DataSet(this.nextId, this, toSeconds(given.timestamp), given.eye)
    // nesting...

    this.nextId += 1
    this.dataSets.push(one)

    // if (!given.training && !this.actualZeroTime) {
    //   this.actualZeroTime = toSeconds(given.timestamp) - this.startTime
    // }
  }
  // actualZeroTime = 0

  onDataPoint(given: DataPoint) {
    this.dataSet?.addPoint(given)
  }

  // onTestStop(given: TestStop) {
  //   this.dataSet?.finalize(given)
  //   this.finalize()
  //   this.done = true
  // }

  // onTestInfo(given: TestInfo) {
  //   this.info.push(...given.info)
  // }

  get dataSet() {
    return _.last(this.dataSets)
  }

  get viewDataSets() {
    return this.dataSets
  }
}

interface ParentContext {
  startTime: number
  patientType: string
}

abstract class BaseDataSet<D> {
  constructor(public id: number, public parent: ParentContext, public startTime: number, public eye: number) {}
  endTime?: number

  dataPoints: D[] = []

  abstract preprocessPoint(d: D): void

  // type?: number

  addPoint(x: D) {
    x = { ...x }
    this.preprocessPoint(x)
    this.dataPoints.push(x)
    // if (this.type == null) this.type = x.type
  }
}

export class DataSet extends BaseDataSet<DataPoint> {
  finalize(point: { timestamp: number }) {
  }

  preprocessPoint(x: DataPoint) {
    // x.time_run = toSeconds(x.timestamp) - this.startTime
    x.time_test = toSeconds(x.timestamp) - this.parent.startTime
    if (this.parent.patientType === 'Child') {
      x.imageHref = STIMULI_IMAGES.get(x.image)
    } else {
      x.imageHref = STIMULI_IMAGES.get(SMILE_IMG_INDEX)
    }
  }
}

function toSeconds(timestamp: number) {
  return timestamp / 10_000_000
}
