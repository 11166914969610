// @ts-nocheck
// import * as d3 from 'd3';
import * as _ from 'lodash';

import { Target } from '..';
import { getStyle } from './util'
import { Scale2D } from '../types'
import { translate, nonInteractive, nonSelectable } from '../util'
import { STIMULI_IMAGES } from './constants'


// styling

const styleDefault = {
  class: '',
  dx: 0,
  dy: 0,

  w: 50, // image size
  h: 50,

  forceX: undefined as number|undefined,
}

type Style = typeof styleDefault

interface Params {
  // gDataPoints: Target
  makeStructure: (a: string, b: string, c: Target) => ({ gSeries: Target, gDataPoints: Target })
  scale: Scale2D

  onEnter?: (d: any) => void
  onExit?: () => void
  onClick?: (d: any) => void
  onMove?: (d: any) => void
  onUp?: (d: any) => void

  filter?: (d: any) => boolean

  withText?: boolean

  target: Target
  style?: Partial<Style>
}

// function

export function ChartImages(p: Params) {
  let style = { ...styleDefault, ...(p.style || {}) }

  let filter = p.filter || (() => true)

  let { gDataPoints } = p.makeStructure('chart-images-series', 'chart-images-points', p.target)

  let klass = style.class || ''

  let points = gDataPoints
    .selectAll('.images')
    .data((d) => _.compact([filter(d) ? d : null]))
    .join('image')
    .attr('class', `images ${klass}`)

    .attr('href', (d:any) => img(d))
    .attr('width', style.w)
    .attr('height', style.h)
    .attr('x', (d:any) => style.forceX ?? p.scale.x(d.point.x) - style.w/2 + style.dx)
    .attr('y', (d:any) => p.scale.y(d.point.y) - style.h/2 + style.dy)

  let { onEnter, onExit, onClick, onMove, onUp } = p
  if (onEnter) {
    points.on('mouseenter', (d) => {
      onEnter && onEnter(d)
    })
  }
  if (onExit) {
    points.on('mouseleave', () => {
      onExit && onExit()
    })
  }
  if (onClick) {
    points.on('mousedown', (d) => {
      onClick && onClick(d)
    })
  }
  if (onMove) {
    points.on('mousemove', (d) => {
      onMove && onMove(d)
    })
  }
  if (onUp) {
    points.on('mouseup', (d) => {
      onUp && onUp(d)
    })
  }
}

// ugly, gotta pass it better
function img(d: any) {
  return d.dataPoint.imageHref || STIMULI_IMAGES.get(d.dataPoint.image)
}
