// @ts-nocheck
// import * as d3 from 'd3';
// import * as _ from 'lodash';

import { Target, Layout } from '..';
// import { doTranslate } from '..';
// import { getStyle } from './util'
import { Brand } from './Brand'
import { Header } from './Header'
import { Circle } from './Circle'
import { nonSelectable } from './util'
import { Icon } from './Icon'

// styling

const styleDefault = {
  // fontColor: '#fff',
  fontColor: '',
  fontSize: 40,
  fontWeight: 600 as number|string,
  fontFamily: Brand.Font,
  // fontFamily: 'Orbitron',
}

const RADIUS = 25

type Style = typeof styleDefault


// interface

interface Params {
    text: string;
    alignLeft?: boolean;
    alignTop?: boolean;
    show?: boolean;
    textSize?: number;
    radius?: number;
    onChange: (n: number) => void

    reposition?: number //...

    target: Target;
    area: Layout;
    styleParams: {
      theme: string,
      override?: Partial<Style>,
    }
}

// function

export function MoreLess(p: Params) {
    // let style = getStyle(styleDefault, themeOverrides, p.styleParams)
    // let show = p.show == null ? true : p.show
    // let text = show ? p.text : null

    let buttonSize = 50
    if (p.textSize) buttonSize = (p.area.width() - p.textSize) / 2
    // let moreText = '+'
    // let lessText = '-'

    let { area } = p
    area = area.copyRect('')
    let leftArea = area.takeLeft(buttonSize)
    let rightArea = area.takeRight(buttonSize)

    let nestG = (target: any, name: string) =>
      target
        .selectAll(`.${name}`)
        .data([null])
        .join('g')
        .attr('class', name)

    p.target.call(nonSelectable)

    let target1 = nestG(p.target, 't1')
    let target2 = nestG(p.target, 't2')
    let target3 = nestG(p.target, 't3')

    if (p.reposition === 1) {
      let dx = 10
      leftArea = leftArea.slide(100+dx, 0)
      rightArea = rightArea.slide(30+dx*2, 0)
    }

    // Header({
    //   text: '-',
    //   area: leftArea.slide(0,2),
    //   target: target1,
    //   styleParams: buttonStyle,
    //   // alignLeft: false,
    //   // alignRight: true,
    // })
    Icon({
      icon: 'zoomOut',
      at: leftArea.mid.dx(-10).dy(-10),
      width: 20,
      height: 20,
      target: target1,
      // onClick: (n: number) => p.onChange(-1 * n),
    })
    Header({
      text: p.text,
      area: area,
      target: target2,
      styleParams: p.styleParams,
    })
    Icon({
      icon: 'zoomIn',
      at: rightArea.mid.dx(-10).dy(-10),
      width: 20,
      height: 20,
      target: target3,
    })
    // Header({
    //   text: '+',
    //   area: rightArea,
    //   target: target3,
    //   styleParams: buttonStyle,
    //   // alignLeft: true,
    //   // alignRight: false,
    // })

    Circle({
      // center: { x: leftArea.right - 7, y: leftArea.centerY() - 3 },
      // center: { x: leftArea.right - 5, y: leftArea.centerY() - 3 },
      center: { x: leftArea.centerX(), y: leftArea.centerY() },
      radius: p.radius || RADIUS,
      target: target1,
      onClick: (n: number) => p.onChange(-1 * n),
      styleParams: { override: {
        lineColor: 'transparent',
      }},
    })
    Circle({
      // center: { x: rightArea.left + 11, y: rightArea.centerY() - 3 },
      // center: { x: rightArea.left + 8, y: rightArea.centerY() - 3 },
      center: { x: rightArea.centerX(), y: rightArea.centerY() },
      radius: p.radius || RADIUS,
      target: target3,
      onClick: (n: number) => p.onChange(1 * n),
      styleParams: { override: {
        lineColor: 'transparent',
      }},
    })
}
