// @ts-nocheck
type TimerHandle = number
type TimerAction = () => void

export interface Timer {
  isInterval: boolean
  handle: TimerHandle
}

// the idea is to have abstracted storage for handles
// and just clear them in the single place with no plumbing everywhere
// also milliseconds are the first param cause wtf is wrong with js
//
// possibly I want to have cancellation if there is an error also
export class TimerService {
  timers: Timer[] = []

  interval(ms: number, action: TimerAction) {
    let handle = setInterval(action, ms)
    this.timers.push({
      handle,
      isInterval: true,
    })
  }

  timeout(ms: number, action: TimerAction) {
    let handle = setTimeout(action, ms)
    this.timers.push({
      handle,
      isInterval: false,
    })
  }

  clear = () => {
    this.timers.forEach(timer => {
      if (timer.isInterval) {
        clearInterval(timer.handle)
      } else {
        clearTimeout(timer.handle)
      }
    })
    this.timers = []
  }
}
