import { PupillaryEvaluation2BulbicamTest } from '../../../../../../../commonout/classes/tests/BulbiCAM/pupillaryEvaluation2BulbicamTest';
import { applyMixins } from '../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../common/enums/test-result-type.enum';
import { MyPupil20BulbicamTestChartComponent, PupillaryEvaluation2BulbicamTestRemarksComponent, PupillaryEvaluation2TestControlsComponent } from '../../../_components';
import { PupillaryEvaluation2BulbicamTestRemarksFrontend } from '../../test-remarks/BulbiCAM/pupillaryEvaluation2BulbicamTestRemarksFrontend.class';
import { TestFrontend } from '../testFrontend.class';

export class PupillaryEvaluation2BulbicamTestFrontend extends PupillaryEvaluation2BulbicamTest {
    remarks: PupillaryEvaluation2BulbicamTestRemarksFrontend;
    public readonly remarksComponent: typeof PupillaryEvaluation2BulbicamTestRemarksComponent = PupillaryEvaluation2BulbicamTestRemarksComponent;
    public readonly bulbicamChartComponent: typeof MyPupil20BulbicamTestChartComponent = MyPupil20BulbicamTestChartComponent;
    public readonly bulbicamControlComponent: typeof PupillaryEvaluation2TestControlsComponent = PupillaryEvaluation2TestControlsComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.DOUBLE;
    constructor() {
        super();
        this.remarks = new PupillaryEvaluation2BulbicamTestRemarksFrontend();
    }
}

export interface PupillaryEvaluation2BulbicamTestFrontend extends TestFrontend {}
applyMixins(PupillaryEvaluation2BulbicamTestFrontend, [TestFrontend]);
