import { Component, forwardRef, Input, ElementRef, HostListener, Output, EventEmitter, Renderer2, ViewChild, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material';

@Component({
    selector: 'datepicker',
    template: require('./datepicker.component.html'),
    styles: [require('./datepicker.component.scss')],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
    @Input() private label: string;
    @Input() private placeholder: string;
    @Input() private maxDate: Date;
    @Input() private minDate: Date;
    @Input() private inputClass: string;
    @Output() isFocused = new EventEmitter<boolean>();
    @Output() tabLocker = new EventEmitter<boolean>();
    @ViewChild('picker') private picker: MatDatepicker<Date>;
    private listener: any;
    private nativeElement: any;
    private disabled: boolean = false;
    private onChange = (_: any) => {};
    private onTouched = () => {};

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.nativeElement = this.elementRef.nativeElement;
    }
    ngOnInit(): void {
        this.picker.openedStream.subscribe(() => {
            this.tabLocker.emit(true);
        });
        this.picker.closedStream.subscribe(() => {
            this.tabLocker.emit(false);
        });
    }
    @HostListener('focus', ['$event']) public onFocus(event: FocusEvent) {
        this.isFocused.emit(true);
        this.listener = this.renderer.listen(this.elementRef.nativeElement, 'keyup', this.keyupEventListener.bind(this));
    }
    @HostListener('blur', ['$event']) public onBlur(event: FocusEvent) {
        this.isFocused.emit(false);
        this.listener();
    }
    writeValue(value: Date): void {
        this.picker.select(value);
    }
    registerOnChange(fn: (_: any) => {}): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    public select(event: MatDatepickerInputEvent<Date>, stopPropagation?: boolean) {
        this.onTouched();
        if (!stopPropagation) {
            this.onChange(event.value);
        }
    }
    public open(): void {
        this.picker.open();
    }
    private keyupEventListener(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            if (this.picker.opened) {
                this.picker.close();
            } else {
                this.picker.open();
            }
        } else if (event.key === 'Escape') {
            if (this.picker.opened) {
                this.picker.close();
            }
        }
    }
    @HostListener('click', ['$event'])
    clickEvent(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.picker.opened) {
            this.picker.close();
        } else {
            this.picker.open();
        }
    }
}
