export const CHART_HEIGHT = 500;
export const AXIS_COLOR = 'white';
export const TICKS_COLOR = '#dce2e1';
export const TICKS_FONT_SIZE = 15;
export const MOVEMENT_LINE_COLOR = 'white';
export const HORIZONTAL_LINE_COLOR = 'rgb(0 199 255)';
export const VERTICAL_LINE_COLOR = 'white';
export const REAL_TIME_LINE_COLOR = 'blue'
export const HORIZONTAL_VELOCITY_LINE_COLOR = 'rgb(0 199 255)';
export const VERTICAL_VELOCITY_LINE_COLOR = 'white';
export const DASHED_LINES_COLOR = 'red';
export const VERTICAL_RESULTS_TEXT_COLOR = 'rgb(0 199 255)';
export const HORIZONTAL_RESULTS_TEXT_COLOR = 'white';