import { OCULUS } from '../../enums/oculus.enum';
import { IDC_ITEM_TYPE } from '../../enums/idc-item-type.enum';
import { IIDCDiagnosis } from '../../interfaces/idc-list-item.interface';
import { IdcListItem } from './IdcListItem.model';

export class IdcDiagnosisItem extends IdcListItem implements IIDCDiagnosis {
    OCULUS: OCULUS;
    iNo: number;
    uri: string;
    code: string;
    title: string;
    bestMatchText: string;
    searchQuery: string;
    constructor() {
        super();
        this.type = IDC_ITEM_TYPE.DIAGNOSIS;
    }
    public get model(): IIDCDiagnosis {
        return {
            OCULUS: this.OCULUS,
            bestMatchText: this.bestMatchText,
            code: this.code,
            iNo: this.iNo,
            searchQuery: this.searchQuery,
            title: this.title,
            uri: this.uri,
            creator: this.creator,
            deleted: this.deleted,
            testType: this.testType,
            timestamp: this.timestamp,
            type: this.type,
        };
    }
    public set model(m: IIDCDiagnosis) {
        this.OCULUS = m.OCULUS;
        this.bestMatchText = m.bestMatchText;
        this.code = m.code;
        this.iNo = m.iNo;
        this.searchQuery = m.searchQuery;
        this.title = m.title;
        this.uri = m.uri;
        this.creator = m.creator;
        this.deleted = m.deleted;
        this.testType = m.testType;
        this.timestamp = m.timestamp;
    }
}
