// @ts-nocheck
// // import { Layout as Area } from './lib';
import { Layout } from '../lib/Layout';
import * as _ from 'lodash';

// eslint-disable-next-line
import { layoutPreview } from './lib';

const GAP = 50;
const MARGIN = 50;

const HEADER = 50;

const VIDEO_SECTION = 320 - 5; //l- 40 //- 40;

const GLOW_CONTROL = 300;

const MID_LINE = GAP; // * 2;

// const SEQUENCES_SIZE = 300;
const RESULT_SIZE = 320 + 100 + 50;
// const SETTINGS_SIZE = 400;
// const SETTINGS_SIZE = 350;

const TITLES_W = 150;
const TITLE_H = 40;

const USED_HEIGHT = 3 / 4;

const TRACKERS_SIZE = 40;

const CONTROLS_ROW = 50;

const CONTROL_HEADER = 100;

const VIDEO_CONTROLS_WIDTH = 50;

const NEW_CONTROL_SIZE = 300;

// const W = window.screen.width
// const H = window.screen.height
const W = 1920;
const H = 1080;

interface LayoutParams {
    chartsCount?: number;
    additionalHeight: number;
}
export function makeLayout(p: LayoutParams) {
    let COUNT = p.chartsCount || 2;

    let width = W;
    let height = H * USED_HEIGHT;

    let layout = new Layout(0, 0, width, height + p.additionalHeight, 'layout');
    layout.takeLeft(MARGIN);
    layout.takeRight(MARGIN);
    layout.takeTop(MARGIN);
    layout.takeBottom(MARGIN + 10);

    let resultPart = layout.takeLeft(RESULT_SIZE, 'resultPart');
    resultPart.takeRight(GAP);
    let cornerButtons = resultPart.takeTop(50);
    cornerButtons.takeLeft(GAP);
    cornerButtons.takeRight(GAP);

    let resultHeight = (resultPart.h - GAP) / 2;
    let resultTop = resultPart.takeTop(resultHeight, 'resultTop');
    resultPart.takeTop(GAP);
    let resultBottom = resultPart.takeTop(resultHeight - 1, 'resultBottom');

    let videoSection = layout.takeTop(VIDEO_SECTION, 'videoSection');
    videoSection.takeLeft(GAP - 10); // no idea
    // layout.takeTop(GAP)

    // let newControlPart = videoSection.takeLeft(NEW_CONTROL_SIZE, 'newControlPart')
    // newControlPart.takeRight(GAP-10)

    // layout.takeTop(GAP)
    // let controlsSection = layout.takeTop(CONTROLS_ROW, 'controlsSection')

    // controlsSection.takeLeft(GAP-10) // same
    // // layout.takeTop(GAP)

    // let glowControl = controlsSection.takeRight(GLOW_CONTROL, 'glowControl')
    // glowControl = glowControl.slide(35, 0)
    // let glowControlHeader = glowControl.takeLeft(CONTROL_HEADER, 'glowControlHeader')
    // controlsSection.takeLeft(GLOW_CONTROL)

    // let leftControl = controlsSection.takeLeft(x => x.w / 2, 'leftControl')
    // let rightControl = controlsSection.takeAll('rightControl')

    // let spread = 20
    // leftControl.takeRight(spread)
    // rightControl.takeLeft(spread)

    // let videoControls = new Layout(leftControl.right,leftControl.top,rightControl.left,rightControl.bottom,'videoControls')
    // videoControls = videoControls.padX((VIDEO_CONTROLS_WIDTH - videoControls.w) / 2)

    // let leftControlHeader = leftControl.takeRight(CONTROL_HEADER, 'leftControlHeader')
    // let rightControlHeader = rightControl.takeLeft(CONTROL_HEADER, 'rightControlHeader')

    // leftControl.takeLeft(x => x.w * 1/10)
    // rightControl.takeRight(x => x.w * 1/10)

    // leftControl = leftControl.slide(30,0)
    // rightControl = rightControl.slide(-30,0)

    // glowControlHeader = glowControlHeader.slide(0, 1)
    // leftControlHeader = leftControlHeader.slide(0, 1)
    // rightControlHeader = rightControlHeader.slide(0, 1)

    // const D_SLIDE = -30
    // const D_SLIDE2 = -23
    // const D_SLIDE = 0 //-20
    // const D_SLIDE2 = 0 //-33
    // const SIDE_SLIDE = 30
    // const Y_SLIDE = 10
    // let leftCompass = new Layout(leftControl.left, leftControl.top, leftControl.left + SIDE_SLIDE, leftControl.top + SIDE_SLIDE, '').slide(D_SLIDE, Y_SLIDE)
    // let rightCompass = new Layout(rightControl.right-SIDE_SLIDE, rightControl.top, rightControl.right, rightControl.top + SIDE_SLIDE, '').slide(D_SLIDE2, Y_SLIDE)

    // let sequencesPart = videoSection.takeLeft(SEQUENCES_SIZE)

    // let rightPart = videoSection.takeRight(x => SEQUENCES_SIZE, 'rightPart')
    // videoSection.takeRight(MID_LINE)

    let leftTracking = videoSection.takeLeft(VIDEO_SECTION, 'leftTracking');
    let rightTracking = videoSection.takeRight(VIDEO_SECTION, 'rightTracking');

    let size = 30;
    let pad = 10;
    let o: any = leftTracking; // relative thing used to put stuff nearby
    // may be refactoring into nice utility functions
    let CONTROL_W = 200;
    let CONTROL_H = 25;
    let D_COMPASS = 175;
    let DX = 30;

    let leftControl = Layout.xywh(o.right + pad, o.bottom - CONTROL_H, CONTROL_W, CONTROL_H).slide(4, 0);
    let leftCompass = Layout.xywh(o.right + pad, o.bottom - size, size, size).slide(D_COMPASS, 0);
    o = rightTracking;

    let rightControl = Layout.xywh(o.left - pad - CONTROL_W, o.bottom - CONTROL_H, CONTROL_W, CONTROL_H).slide(DX, 0);
    let rightCompass = Layout.xywh(o.left - pad - size, o.bottom - size, size, size).slide(-D_COMPASS, 0);
    let rightUnderTracking = Layout.xywh(o.left, o.bottom + pad, 300 - 10, 23);
    let o1 = leftCompass;
    let o2 = rightCompass;
    let oCenterX = (o1.right + o2.left) / 2;
    let oR = 30;

    let step = 15;
    let videoControls = Layout.xywh(oCenterX - oR, o1.top, 2 * oR, o1.h).slide(0, -step);
    let actualSpeed = videoControls.slide(0, step * 2);

    videoSection.takeBottom(50); // to uncover space for controls

    // console.log(videoControls)
    // right,leftControl.top,rightControl.left,rightControl.bottom,'videoControls')
    // videoControls = videoControls.padX((VIDEO_CONTROLS_WIDTH - videoControls.w) / 2)

    videoSection.takeLeft(GAP);
    videoSection.takeRight(GAP);
    // videoSection.takeRight(GAP)

    let empty2 = new Layout(0, 0, 0, 0, 'empty');
    // let canvasSection = empty2
    // let canvasSection = videoSection.takeBottom(x => x.height() / 2, 'canvasSection')

    // let rightPart = layout.takeRight(x => SEQUENCES_SIZE, 'rightPart')
    // layout.takeRight(MID_LINE)

    // let rightPart = layout.takeLeft(x => SEQUENCES_SIZE, 'rightPart')
    // layout.takeRight(MID_LINE)

    // let resultPart = layout.takeLeft(RESULT_SIZE, 'resultPart')
    // resultPart.takeRight(GAP)
    // let resultHeight = (resultPart.h - GAP) / 2
    // let resultOd = resultPart.takeTop(resultHeight, 'resultOd')
    // resultPart.takeTop(GAP)
    // let resultOs = resultPart.takeTop(resultHeight-1, 'resultOs')

    let chartsPart = layout.takeAll('chartsPart');

    // let sequencesPart = chartsPart.takeLeft(SEQUENCES_SIZE)
    // let sequencesHeader = sequencesPart.takeTop(HEADER, 'sequencesHeader')

    // let titlesPart = chartsPart.takeLeft(TITLES_W, 'titlesPart')
    // chartsPart.takeLeft(MARGIN)

    chartsPart.takeLeft(50);
    chartsPart.takeTop(30);

    // let COUNT = 5
    let chartSize = (chartsPart.height() - TRACKERS_SIZE - (COUNT - 1) * GAP) / COUNT;

    let trackersTop = chartsPart.takeTop(TRACKERS_SIZE, 'trackersTop');

    trackersTop.takeLeft(TRACKERS_SIZE);

    let trackersLeftArea = chartsPart.takeLeft(TRACKERS_SIZE, 'trackersLeftArea');
    let trackersLeft = trackersLeftArea.takeTop(chartSize, 'trackersLeft');

    // let chart1 = chartsPart.takeTop(chartSize, 'chart1')
    // chartsPart.takeTop(GAP)
    // let chart2 = chartsPart.takeTop(chartSize, 'chart2')
    // chartsPart.takeTop(GAP)
    // let chart3 = chartsPart.takeTop(chartSize, 'chart3')
    // chartsPart.takeTop(GAP)
    // let chart4 = chartsPart.takeTop(chartSize, 'chart4')
    // chartsPart.takeTop(GAP)
    // let chart5 = chartsPart.takeTop(chartSize, 'chart5')

    let charts: Layout[] = [];
    let titles: Layout[] = [];
    let infos: Layout[] = [];
    _.times(COUNT, (i) => {
        let chart = chartsPart.takeTop(chartSize, 'chart' + i);
        if (i < COUNT - 1) chartsPart.takeTop(GAP);
        chart.top += 40;
        charts.push(chart);

        // let part = titlesPart.takeTop(chartSize, 'title' + i)
        // if (i < COUNT - 1) titlesPart.takeTop(GAP)

        // let header = part.takeTop(TITLE_H, 'header' + i)
        // let info = part.takeAll('info' + i)

        // titles.push(header)
        // infos.push(info)
    });

    // chartsPart.takeTop(GAP)
    // let settingsPart = rightPart

    // let settingsPart = rightPart.takeTop(SETTINGS_SIZE, 'settingsPart')
    // let settingsHeader = settingsPart.takeTop(HEADER, 'settingsHeader')
    // settingsHeader.takeLeft(10) // to align to list items

    // let leftHeader = chartsPart.takeTop(HEADER, 'leftHeader')
    // let rightHeader = rightPart.takeTop(HEADER, 'rightHeader')
    // rightHeader.takeLeft(10) // to align to list items

    // let empty = new Layout(0, 0, 0, 0, 'empty');
    let ex = width * 2;
    let ey = height * 2;
    let empty = new Layout(ex, ey, ex, ey, 'empty');

    // let temp0 = charts[0]
    // let temp1 = charts[1]
    // charts[0] = temp1
    // charts[1] = temp0

    // let bigVideo = new Layout(
    //   resultTop.left,
    //   charts[0].top,
    //   charts[1].right,
    //   charts[1].bottom,
    // 'bigVideo')

    return {
        layout,
        empty,

        videoSection,
        // canvasSection,
        leftTracking,
        rightTracking,

        // chartsPart,
        // chart1,
        // chart2,
        // chart3,
        // chart4,
        // chart5,

        titles,
        infos,
        charts,

        // titles1: titles.shift(),
        // titles2: titles.shift(),
        // titles3: titles.shift(),
        // titles4: titles.shift(),
        // titles5: titles.shift(),

        // rightPart,
        // leftHeader,
        // rightHeader,
        // settingsHeader,
        // settingsPart: rightPart,

        // sequencesPart,
        // sequencesHeader,

        trackersLeft,
        trackersTop,

        resultTop,
        resultBottom,

        // glowControl,

        // glowControlHeader,
        // leftControlHeader,
        // rightControlHeader,

        leftCompass,
        rightCompass,

        videoControls,
        // newControlPart,

        rightUnderTracking,
        rightControl,
        leftControl,
        actualSpeed,
        cornerButtons,
        // bigVideo,
    };
}

export type ChartLayout = ReturnType<typeof makeLayout>;

// layoutPreview(makeLayout({}).layout, { fontSize: 50, forceStrength: -10000, forceDistance: 100 });
