import * as jsPDF from 'jspdf';
import { ColorMeasuredData } from '../../../../../common/models/measuredData/colorData.model';
import { Pager } from '../../_services/examination/export.service';
import { FileService } from '../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../tableReportTestMode';
import { ColorTestRemarksFrontend } from '../test-remarks/colorTestRemarksFrontend.class';
import { MeasuredDataFrontend } from './measuredDataFrontend';

export class ColorMeasuredDataFrontend extends ColorMeasuredData implements MeasuredDataFrontend {
    public remarks: ColorTestRemarksFrontend;
    public ishihara: {
        OD: RegisterEditFieldFrontend<string>;
        OS: RegisterEditFieldFrontend<string>;
    };
    public withRedFilter: {
        OD: RegisterEditFieldFrontend<string>;
        OS: RegisterEditFieldFrontend<string>;
    };
    public select: RegisterEditFieldFrontend<string[]>;
    constructor() {
        super();
        this.ishihara = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.withRedFilter = {
            OD: new RegisterEditFieldFrontend(),
            OS: new RegisterEditFieldFrontend(),
        };
        this.select = new RegisterEditFieldFrontend();
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        let ODvalues: string = (this.select.value || 'x') + ' ' + (this.ishihara.OD.value || 'x') + ' ' + (this.withRedFilter.OD.value || 'x');
        let OSvalues: string = (this.select.value || 'x') + ' ' + (this.ishihara.OS.value || 'x') + ' ' + (this.withRedFilter.OS.value || 'x');
        if (ODvalues !== 'x x x') {
            formattedTest.OD.values = ODvalues;
        }
        if (OSvalues !== 'x x x') {
            formattedTest.OS.values = OSvalues;
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
