import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { ModalService } from '../../../_services/general/modal.service';

@Component({
    selector: 'forgot-password',
    template: require('./forgot-password.component.html'),
    styles: [require('./forgot-password.component.scss')],
})
export class ForgotPasswordComponent {
    private error: string;
    constructor(private router: Router, private authService: AuthenticationService, private modalService: ModalService) {}

    private async submit(email: string): Promise<void> {
        try {
            let responce: { message: string } = await this.authService.restorePassword(email).toPromise();
            await this.modalService.confirm(responce.message);
            this.router.navigate(['/auth']);
        } catch (error) {
            this.error = error.error;
        }
    }
}
