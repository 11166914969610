import { OcularRefractionTonorefIIITest } from '../../../../../../../../commonout/classes/tests/NIDEK/TonorefIII/ocularRefractionTonorefIIITest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { OcularRefractionTonorefIIITestRemarksComponent } from '../../../../_components';
import { OcularRefractionTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/ocularRefractionArk1sTestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class OcularRefractionTonorefIIITestFrontend extends OcularRefractionTonorefIIITest {
    remarks: OcularRefractionTonorefIIITestRemarksFrontend;
    public readonly remarksComponent: typeof OcularRefractionTonorefIIITestRemarksComponent = OcularRefractionTonorefIIITestRemarksComponent;
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.NONE;
    constructor() {
        super();
        this.remarks = new OcularRefractionTonorefIIITestRemarksFrontend();
    }
}

export interface OcularRefractionTonorefIIITestFrontend extends TestFrontend {}
applyMixins(OcularRefractionTonorefIIITestFrontend, [TestFrontend]);
