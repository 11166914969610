import { TestRemarkFrontend } from './testRemarkFrontend';
import { ColorMeasuredDataFrontend } from '../measuredData/colorDataFrontend.class';

export class ColorTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof ColorMeasuredDataFrontend = ColorMeasuredDataFrontend;
    public measurements: ColorMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
