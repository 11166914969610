import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { MaculaScanNidekRS330MeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/RS330/maculaScanNidekRS330Data.model';
import { IMaculaScanNidekRS330MeasuredData } from '../../../../../../../common/interfaces/measuredData/NIDEK/RS330/macula-scan-nidek-rs330.measuredData.interface';
import { MaculaScanFrontend } from '../../../maculaScanFrontend';
import { MaculaScanNidekRs330TestRemarksFrontend } from '../../../test-remarks/NIDEK/RS330/maculaScanNidekRs330TestRemarksFrontend.class copy';

export class MaculaScanNidekRS330MeasuredDataFrontend extends MaculaScanNidekRS330MeasuredData implements MeasuredDataFrontend {
    public remarks: MaculaScanNidekRs330TestRemarksFrontend;
    constructor() {
        super();
    }
    public setModel(model: IMaculaScanNidekRS330MeasuredData) {
        this.sourceFileName = model.sourceFileName;
        this.maculaScan.OD = model.maculaScan.OD.map((sm) => {
            const s = new MaculaScanFrontend();
            s.setModel(sm);
            return s;
        });
        this.maculaScan.OS = model.maculaScan.OS.map((sm) => {
            const s = new MaculaScanFrontend();
            s.setModel(sm);
            return s;
        });
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {}
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
                maculaImages: [],
                ai: [],
            },
            OS: {
                values: '',
                images: [],
                maculaImages: [],
                ai: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        for (const ms of this.maculaScan.OD) {
            formattedTest.OD.maculaImages = (ms as MaculaScanFrontend).smartImages.map((si) => si.imageUrl);
            break;
        }
        for (const ms of this.maculaScan.OS) {
            formattedTest.OS.maculaImages = (ms as MaculaScanFrontend).smartImages.map((si) => si.imageUrl);
            break;
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        await Promise.all(this.maculaScan.OD.map((ms: MaculaScanFrontend) => ms.downloadMedia(fileService)));
        await Promise.all(this.maculaScan.OS.map((ms: MaculaScanFrontend) => ms.downloadMedia(fileService)));
    }
}
