import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { AntSegmentHaagSlitLampMeasuredDataFrontend } from '../../../measuredData/HAAG/SLITLAMP/antSegmentHaagSlitlampDataFrontend.class';

export class AntSegmentHaagSlitlampTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof AntSegmentHaagSlitLampMeasuredDataFrontend = AntSegmentHaagSlitLampMeasuredDataFrontend;
    public measurements: AntSegmentHaagSlitLampMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
