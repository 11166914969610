// @ts-nocheck
// import * as _ from 'lodash';
import * as d3 from 'd3';
import { BaseDiagram } from './BaseDiagram';
import { Protocol, DataPoint, stimuliName, isPosDataPoint } from './helpers/Protocol';
import { State, RectSelection } from './helpers/State';
import { makeLayout, ChartLayout } from './ui/layout';
import { Targets } from './ui/Targets';
import { Brand } from './lib/Brand';
import { ConfigView, NEAR, FAR } from './helpers/ConfigView';
import { VideoControl } from './lib/VideoControl';
import { Squares, SquareData } from './helpers/Squares';
import { TrackPair } from './lib/TrackPair';
import { VTable } from './lib/VTable';
import { drawPreview, drawArrow } from './lib/drawable';
import { DiagramButton } from './lib/DiagramButton';
import { MoreLess } from './lib/MoreLess';
import { Header } from './lib/Header';
import { AChart2, IDataSet } from './lib/AChart';
import { AData } from './lib/AData';
import { ChartDots } from './lib/ChartDots';
import { Rectangle } from './lib/Rectangle';
import { Line } from './lib/Line';
import { AxisLeft, AxisBottom } from './lib/Axes';
import { ChartLines } from './lib/ChartLines';
import { TextBlock } from './lib/TextBlock';
import { Layout as Area } from './lib/Layout';
import { ChartBg } from './lib/ChartBg';
import { doTranslate } from './lib/util';
import { Target } from './lib/types';
import { Legend } from './helpers/Legend';
import { Legend1 } from './helpers/Legend1';
import { VALUE_KEYS, OPTIONAL_FIELDS } from './helpers/ManualStorage';
import { Compass } from './helpers/Compass';
import { Choice } from './helpers/CornerButtons';
import { GROUPS_ORDER, DOTS_ORDER, VALUES_ORDER, processExportValue, EYES_ORDER } from './helpers/exportRelated';
import { getColor } from './ui/colorScale';

let TRACK_LEFT_ARM = 50;
const RATE = 16; //dup
const OD = 'OD';
const OS = 'OS';
type Lo = ChartLayout;
type G = Targets;
const B = Brand;
const COLUMN1 = `\nFREQ:\nAMP:\nAXIS:\nTYPE:`;
const MAIN_DOT_RADIUS = 3;
const LINE_SIZE = 2;
const MAIN_COLOR = 'fYellow';
const TAIL_COLOR = 'sGreen';

// XXX: just copied
interface IChartEdit {
	date: number;
	editorId: string;
	editor: string;
	messageTimestamp: number;
	fieldName: string;
	previousValue: string | number | Array<string> | Array<object>;
	currentValue: string | number | Array<string> | Array<object>;
}
type MyEdit = Pick<IChartEdit, 'messageTimestamp' | 'fieldName' | 'previousValue' | 'currentValue'>;


export class Diagram extends BaseDiagram<State, Protocol> {

	gotData(x: any) {
		this.state.showConfig = false;
	}

	reset() {
		this.state = new State({ updateStartParams: this.params.updateStartParams });
		this.state.doUpdateStartParams();
		this.protocol = new Protocol(this.state);
	}

	popupSpvItems(chatId: number) {
		if (this.state.selectedChart.get() !== chatId) return [];

		let items: any = [];
		let dataPoint = this.state.selectedPoint.get();
		if (dataPoint) {
			let point = dataPoint['spvPoint'];
			let xText = `${point.x.toFixed(2)} sec.`;
			let yText = `${point.y.toFixed(2)} deg/sec.`;
			let odText = ''; //`${ttToText(dataPoint.targettype)}`
			let seriesText = `${stimuliName(dataPoint.dataSet.stimuli)}`;
			let text = [yText, xText, seriesText + ', ' + odText].join('\n');
			let popup = {
				near: point,
				text,
			};
			items.push(popup);
		}
		return items;
	}

	popupItems(char: 'h' | 'v' | 't', chatId: number) {
		if (this.state.selectedChart.get() !== chatId) return [];

		let items: any = [];
		let dataPoint = this.state.selectedPoint.get();
		if (dataPoint) {
			// let eye = 'OD'
			let point = dataPoint[char + 'Point'];
			let xText = `${point.x.toFixed(2)} sec.`;
			let yText = `${point.y.toFixed(2)} deg.`;
			let odText = ''; //`${ttToText(dataPoint.targettype)}`
			let seriesText = `${stimuliName(dataPoint.dataSet.stimuli)}`;
			let text = [yText, xText, seriesText + ', ' + odText].join('\n');
			let popup = {
				near: point,
				text,
			};
			items.push(popup);
		}
		return items;
	}

	selectPoint = (chartId: number) => (x: { dataPoint: DataPoint }) => {
		this.state.selectedChart.set(chartId);
		this.state.selectedPoint.set(x.dataPoint);
		this.refresh();
	};
	deSelectPoint = (chartId: number) => () => {
		this.state.selectedChart.set(null);
		this.state.selectedPoint.set(null);
		this.refresh();
	};

	renderTracks(
		lo: ChartLayout,
		g: Targets,
		xScale: any,
		yScale: any,
		dragFirst: string,
		dragSecond: string,
		dragHandle: string,
		chartIndex: number,
		dragFirstV: string,
		dragSecondV: string,
		dragHandleV: string,
		lastTouchedSaved: boolean,
		lastTauchedTracker1VSaved: string
	) {

		let valueScaleH = d3
			.scaleLinear()
			.range(xScale.domain())
			.domain([0, 1]);

		let valueScaleV = d3
			.scaleLinear()
			.range(yScale.domain())
			.domain([1, 0]);

		let hTrackPair = _.cloneDeep(lo.charts[chartIndex]);
		hTrackPair.top -= 40;

		let vTrackPair = _.cloneDeep(lo.charts[chartIndex]);

		let opacity = '100%';

		if (!lastTouchedSaved) {
			opacity = '50%';
		}

		// vertical;

		TrackPair({
			v1: this.state[dragFirstV],
			v2: this.state[dragSecondV],
			// show: this.state.cornerButtons.showOne,
			direction: 'v',
			valueScale: valueScaleV,

			drawDx: -TRACK_LEFT_ARM,

			dragHandle: this.state[dragHandleV],
			onDragStart: (n: number) => {
				this.state[dragHandleV] = n;

				this.state.lastTauchedTracker1 = dragFirst;
				this.state.lastTauchedTracker2 = dragSecond;
				this.state.lastTauchedTracker1V = dragFirstV;
				this.state.lastTauchedTracker2V = dragSecondV;

				this.state.lastTouchedSaved = dragFirst;
				this.state.lastTauchedTracker1VSaved = dragFirstV;

				this.refresh();
			},
			onDragEnd: (n: number) => {
				this.state[dragHandleV] = 0;

				// this.state.recordManualChanges()
				this.refresh();
			},
			onDrag: (n: number, value: number) => {
				if (n === 1) {
					this.state[dragFirstV] = value;
				}
				if (n === 2) {
					this.state[dragSecondV] = value;
				}
				if (n === 0) return;
				this.refresh();
			},

			area: vTrackPair,
			...g.pass,
			opacity: opacity,
		});

		if (lastTauchedTracker1VSaved.match(/sec4/) && chartIndex === 0) {
			opacity = '100%';
		}

		if (lastTauchedTracker1VSaved.match(/sec34/) && chartIndex === 2) {
			opacity = '100%';
		}

		if (lastTauchedTracker1VSaved.match(/sec64/) && chartIndex === 4) {
			opacity = '100%';
		}

		if (chartIndex === 0 || chartIndex === 2 || chartIndex === 4) {
			TrackPair({
				v1: this.state[dragFirst],
				v2: this.state[dragSecond],
				valueScale: valueScaleH,
				// show: this.state.cornerButtons.showOne,

				dragHandle: this.state[dragHandle],
				onDragStart: (n: number) => {
					this.state[dragHandle] = n;

					this.state.lastTauchedTracker1 = dragFirst;
					this.state.lastTauchedTracker2 = dragSecond;
					this.state.lastTauchedTracker1V = dragFirstV;
					this.state.lastTauchedTracker2V = dragSecondV;

					this.state.lastTouchedSaved = dragFirst;
					this.state.lastTauchedTracker1VSaved = dragFirstV;

					this.refresh();
				},
				onDragEnd: (n: number) => {
					this.state[dragHandle] = 0;

					//   this.state.recordManualChanges()
					this.refresh();
				},
				onDrag: (n: number, value: number) => {
					if (n === 1) {
						this.state[dragFirst] = value;
					}
					if (n === 2) {
						this.state[dragSecond] = value;
					}
					if (n === 0) return;
					this.refresh();
				},

				area: hTrackPair,
				...g.pass,
				opacity: opacity,
			});
		}
	}

	// no throttling, no slow code preferably
	quickRender(duration: number = 0) {
		let { lo, g } = this.lastRenderingContext;
		let styleParams = { theme: '' }; // ...

		this.renderHLine(lo.charts[0], g.gQuick, this.lastTopAData, duration);
		this.renderHLine(lo.charts[1], g.gQuick2, this.lastBottomAData, duration);
		this.renderTrace(lo, g.gTrace11, g.gTrace12, g.gTrace13, styleParams, 'od');
		this.renderTrace(lo, g.gTrace21, g.gTrace22, g.gTrace23, styleParams, 'os');
		this.renderVideoControls();
		// this.(lo,g.gTrace21, g.gTrace22, g.gTrace23, styleParams, 'os')
	}

	renderHLine(area: Area, target: Target, lastAData: any, duration: number = 0) {
		// let target = g.gQuick
		if (!area) return; // called before rendering (defensive a bit)
		if (!lastAData) return; // called before rendering (defensive a bit)

		// let area = lo.charts[0]
		// let { lastTopAData } = this // part of rendering context or so?

		let positioning = (x: any) => x;
		let series: any = [];

		let frame = this.state.settings.videoPosition;
		let point = this.state.getPointByFrame(frame, duration);
		let x = point.timestamp;

		series.push({
			id: 0,
			dataPoints: [
				{
					x,
					y: 0,
					text: '',
				},
			],
		});

		let aData: any = AData<DataPoint>({
			series,
			positioning: positioning,
			coloring: () => 'white',
		});

		aData = {
			...aData,
			domainX: lastAData.domainX,
			xScale: lastAData.xScale,
		};
		let ctx = AChart2<DataPoint>({
			aData,
			showDots: false,

			target,
			area,
		});

		ChartDots({
			...ctx,
			shape: 'hline',

			target,
			styleParams: {
				theme: '',
				override: {
					lineSize: 3,
					dasharray: '5 5',
				},
			},
		});
	}

	lastRenderingContext: { lo: Lo; g: G } = {} as any;

	render() {
		if (true) {
			let { targetContainer } = this.params;
			let target = targetContainer ? d3.select(targetContainer) : (d3.select(this.params.target as any /*devenv*/).select('.svg-container') as any);

			if (this.state.showBigVideo) {
				target
					.selectAll('svg')
					.style('display', 'none')
					.style('position', 'relative');
				this.renderBigVideo(target);

				return;
			} else {
				target.selectAll('.big-video').remove();
				target.selectAll('svg').style('display', 'inline');
			}
		}

		let mode = this.state.showConfig ? 1 : 2;

		let chartsCount = 0;
		let additionalHeight = 200;

		chartsCount = 2;

		if (this.state.lengthInSeconds === 34) {
			chartsCount = 4;
			additionalHeight = 600;
		}

		if (this.state.lengthInSeconds === 64) {
			chartsCount = 6;
			additionalHeight = 1200;
		}
		
		let lo = makeLayout({ chartsCount, additionalHeight });

		let g = new Targets({
			...this.params,
			layout: lo.layout,
			mode,
			state: this.state,
			chartsCount
		});

		this.lastRenderingContext = { lo, g };
		let styleParams = { theme: this.params.theme };

		if (this.state.showConfig) {
			return ConfigView({
				state: this.state,
				g,
				refresh: () => {
					this.refresh();
				},
			});
		}

		let videoTarget = g.gVideo;

		if (videoTarget.selectAll('video').empty()) {
			let src = this.getVideoUrl();
			this.state.settings.lastVideoUsed = src;
			VideoControl({
				target: videoTarget,
				area: lo.videoSection,
				src,
				rate: RATE,
				onPosition: (pos, duration) => {
					pos = Math.floor(pos);
					if (this.state.settings.videoPosition === pos) return;
					this.state.settings.videoPosition = pos;
					this.quickRender(duration);
				},
			});
		} else {
			let src = this.getVideoUrl();
			if (this.state.settings.lastVideoUsed !== src) {
				videoTarget.selectAll('video').attr('src', src);
				this.state.settings.lastVideoUsed = src;
			}
		}

		let titles = ['H', 'V'];

		let ti = '\n→ time, sec';
		let infos = [
			'Horizontal part\n↑ angle, deg' + ti,
			'Vertical part\n↑ angle,deg' + ti,
		];

		let ids = 0;
		let chartsOrder = [
			{
				id: ids += 1,
				char: 'h',
				spv: false,
				show: this.state.settings.showH,
				title: titles[ids - 1],
				info: infos[ids - 1],
			},
			{
				id: ids += 1,
				char: 'v',
				spv: false,
				show: this.state.settings.showV,
				title: titles[ids - 1],
				info: infos[ids - 1],
			}
		];

		let chartAreaIds = 0;
		let getChartArea = () => {
			let area = lo.charts[chartAreaIds];
			chartAreaIds += 1;
			return area;
		};

		let lastAData: any;
		let topXScale = null as any;
		let topYScale = null as any;

		let renderPosChart = (chartId: number, show: boolean, char: 'h' | 'v' | 't', seconds: number, lastTouchedSaved: boolean) => {
			let area = show ? getChartArea() : lo.empty;
			let positioning = (d: DataPoint) => d[char + 'Point'];
			let series = [] as any;
			let ods = [] as any;
			let oss = [] as any;
			this.state.seconds = seconds;

			let { chosenSeries } = this.state;
			
			// filter points needed range
			const chosenSeriesFiltered = [];
			if (seconds === 4) {
				for (let i = chosenSeries[0].dataPoints.length - 1; i >= 0; i--) {
					if (chosenSeries[0].dataPoints[i].timestamp <= 4) chosenSeriesFiltered.push(chosenSeries[0].dataPoints[i]);
				}
				chosenSeries[0].dataPoints = chosenSeriesFiltered;
			}

			if (seconds === 34) {
				for (let i = chosenSeries[0].dataPoints.length - 1; i >= 0; i--) {
					if (chosenSeries[0].dataPoints[i].timestamp >= 4 && chosenSeries[0].dataPoints[i].timestamp <= 34) chosenSeriesFiltered.push(chosenSeries[0].dataPoints[i]);
				}
				chosenSeries[0].dataPoints = chosenSeriesFiltered;
			}

			if (seconds === 64) {
				for (let i = chosenSeries[0].dataPoints.length - 1; i >= 0; i--) {
					if (chosenSeries[0].dataPoints[i].timestamp >= 34) chosenSeriesFiltered.push(chosenSeries[0].dataPoints[i]);
				}
				chosenSeries[0].dataPoints = chosenSeriesFiltered;
			}

			for (let i = chosenSeries.length - 1; i >= 0; i--) {
				for (let k = chosenSeries[i].dataPoints.length - 1; k >= 0; k--) {
					let x = chosenSeries[i].dataPoints[k];
					if (!isPosDataPoint(x)) return;
					if (x.odPresent) {
						ods.push({
							hPoint: x.ODhPoint,
							vPoint: x.ODvPoint,
							tPoint: x.ODtPoint,
							...x,
						});
					}
					if (x.osPresent) {
						oss.push({
							hPoint: x.OShPoint,
							vPoint: x.OSvPoint,
							tPoint: x.OStPoint,
							...x,
						});
					}
				}
			}

			if (this.state.cornerButtons.showOD) {
				series.push({
					id: 0,
					dataPoints: ods,
				});
			}
			
			if (this.state.cornerButtons.showOS) {
				series.push({
					id: 1,
					dataPoints: oss,
				});
			}

			let aData: any = AData<DataPoint>({
				enabled: show,
				filter: (x: DataPoint) => x.ownType === 'pos', // || x.ownType === 'phase',
				series, //: this.state.chosenSeries,
				positioning: positioning,
				coloring: (point, series) => getColor(series.id, this.state.maxId),
				yFrom: chosenSeries[0].domainY.min,
				yTo: chosenSeries[0].domainY.max,
			});
			lastAData = aData;

			// TODO: thinking and refactoring opportunity
			if (chartId === 1 && seconds === 4) {
				this.lastTopAData = lastAData;
			}
			if (chartId === 2 && seconds === 4) {
				this.lastBottomAData = lastAData;
			}

			let { xScale, yScale } = aData;
			topXScale = xScale;
			topYScale = yScale;

			let ctx = AChart2<DataPoint>({
				aData,
				showDots: false,
				target: g.default,
				area,
			});
			ChartBg({
				scale: { x: aData.xScale, y: aData.yScale },
				target: g.default,
				ticksCountY: 5,
			});
			if (chartId === 1 && seconds === 4) {
				let legendPoint = {
					x: aData.xScale.range()[1],
					y: aData.yScale.range()[1],
				};
				Legend({
					point: legendPoint,
					target: g.default,
				});
			}

			let opacity = '100%';
			if (!lastTouchedSaved) {
				opacity = '50%';
			}

			AxisLeft({
				...ctx,
				grid: true,
				ticks: 5,
				area,
				target: g.default,
				styleParams: {
					override: {
						opacity: opacity,
					},
				},
			});

			AxisBottom({
				...ctx,
				grid: true,
				area,
				target: g.default,
				styleParams: {
					override: {
						opacity: opacity,
					},
				},
			});
			let dotsFiltering = (x: { dataPoint: DataPoint }) => {
				if (!this.state.settings.showDots) return false;
				return x.dataPoint.ownType === 'pos';
			};

			ChartLines({
				...ctx,
				target: g.default,
				styleParams: {
					...styleParams,
					override: {
						opacity: opacity,
					},
				},
			});

			ChartDots({
				...ctx,
				shape: 'dot',
				filter: dotsFiltering,
				target: g.default,
				styleParams: {
					...styleParams,
					override: {
						dotRadius: 4,
						lineSize: 5,
						fillColor: 'fill',
					},
				},
				onEnter: this.selectPoint(chartId),
				onExit: this.deSelectPoint(chartId),
			});

			let gLayer = g.default;
			if (show) {
				let klass = 'layer';
				gLayer
					.selectAll(`.${klass}`)
					.data([null])
					.join('rect')
					.attr('class', klass)
					.attr('width', area.width())
					.attr('height', area.height())
					.attr('fill', 'transparent')
					// dragable rectangle functionality
					// .on('mousedown', (x: any) => this.layerMouseDown(area, xScale, yScale, true, d3.event))
					// .on('mouseup', (x: any) => this.layerMouseUp(area, xScale, yScale, true))
					// .on('mousemove', (e: any) => this.layerMouseMove(area, xScale, yScale, true, d3.event))
					// .on('mouseleave', (x: any) => this.layerMouseLeave(area, xScale, yScale, true))
					// .on('contextmenu', () => {
					// 	d3.event.stopPropagation();
					// 	d3.event.preventDefault();
					// })
					// .attr('cursor', 'crosshair')
					// .call(doTranslate({ x: area.left, y: area.top }));
			}
		};

		let drag1 = 1;
		let drag2 = 2;
		let handle = 1;
		this.renderResultSquares(lo, g);

		chartsOrder.forEach(({ id, char, spv, show, title, info }, i) => {
			let lastTouchedSaved = this.state.lastTouchedSaved === 'drag1sec4';
			if (spv) {
			} else {
				if (i === 1) {
					drag1 = 3;
					drag2 = 4;
					handle = 2;
				}
				if (lastTouchedSaved) {
					if (i === 0 && this.state.lastTauchedTracker1VSaved.match(/drag3/)) {
						lastTouchedSaved = false;
					}
					if (i === 1 && this.state.lastTauchedTracker1VSaved.match(/drag1/)) {
						lastTouchedSaved = false;
					}
				}
				renderPosChart(id, show, char as 'h' | 'v' | 't', 4, lastTouchedSaved);
				this.renderLegends(lo, g, 0, 1, lastTouchedSaved);
				this.renderArrows(0, 1, lastTouchedSaved);
				this.renderTracks(
					lo,
					g,
					topXScale,
					topYScale,
					'drag1sec4',
					'drag2sec4',
					'dragHandleSec4',
					i,
					`drag${drag1}Vsec4`,
					`drag${drag2}Vsec4`,
					`dragHandleV${handle}sec4`,
					lastTouchedSaved,
					this.state.lastTauchedTracker1VSaved
				);
				this.renderTracksLines(lo, g, topXScale, topYScale, 'drag1sec4', 'drag2sec4', i, `drag${drag1}Vsec4`, `drag${drag2}Vsec4`, lastTouchedSaved);
			}
		});

		if (this.state.lengthInSeconds === 34 || this.state.lengthInSeconds === 64) {
			let chartIndex = 2;
			let drag1 = 1;
			let drag2 = 2;
			let handle = 1;
			chartsOrder.forEach(({ id, char, spv, show, title, info }, i) => {
				let lastTouchedSaved = this.state.lastTouchedSaved === 'drag1sec34';
				if (i === 1) {
					chartIndex = 3;
					drag1 = 3;
					drag2 = 4;
					handle = 2;
				}
				if (lastTouchedSaved) {
					if (i === 0 && this.state.lastTauchedTracker1VSaved.match(/drag3/)) {
						lastTouchedSaved = false;
					}
					if (i === 1 && this.state.lastTauchedTracker1VSaved.match(/drag1/)) {
						lastTouchedSaved = false;
					}
				}
				renderPosChart(id, show, char as 'h' | 'v' | 't', 34, lastTouchedSaved);
				this.renderLegends(lo, g, 2, 3, lastTouchedSaved);
				this.renderArrows(2, 3, lastTouchedSaved);
				this.renderTracks(
					lo,
					g,
					topXScale,
					topYScale,
					'drag1sec34',
					'drag2sec34',
					'dragHandleSec34',
					chartIndex,
					`drag${drag1}Vsec34`,
					`drag${drag2}Vsec34`,
					`dragHandleV${handle}sec34`,
					lastTouchedSaved,
					this.state.lastTauchedTracker1VSaved
				);
				this.renderTracksLines(lo, g, topXScale, topYScale, 'drag1sec34', 'drag2sec34', chartIndex, `drag${drag1}Vsec34`, `drag${drag2}Vsec34`, lastTouchedSaved);
			});
		}

		if (this.state.lengthInSeconds === 64) {
			let chartIndex = 4;
			let drag1 = 1;
			let drag2 = 2;
			let handle = 1;

			chartsOrder.forEach(({ id, char, spv, show, title, info }, i) => {
				let lastTouchedSaved = this.state.lastTouchedSaved === 'drag1sec64';
				if (i === 1) {
					chartIndex = 5;
					drag1 = 3;
					drag2 = 4;
					handle = 2;
				}

				if (lastTouchedSaved) {
					if (i === 0 && this.state.lastTauchedTracker1VSaved.match(/drag3/)) {
						lastTouchedSaved = false;
					}
					if (i === 1 && this.state.lastTauchedTracker1VSaved.match(/drag1/)) {
						lastTouchedSaved = false;
					}
				}
				renderPosChart(id, show, char as 'h' | 'v' | 't', 64, lastTouchedSaved);
				this.renderLegends(lo, g, 4, 5, lastTouchedSaved);
				this.renderArrows(4, 5, lastTouchedSaved);
				this.renderTracks(
					lo,
					g,
					topXScale,
					topYScale,
					'drag1sec64',
					'drag2sec64',
					'dragHandleSec64',
					chartIndex,
					`drag${drag1}Vsec64`,
					`drag${drag2}Vsec64`,
					`dragHandleV${handle}sec64`,
					lastTouchedSaved,
					this.state.lastTauchedTracker1VSaved
				);
				this.renderTracksLines(lo, g, topXScale, topYScale, 'drag1sec64', 'drag2sec64', chartIndex, `drag${drag1}Vsec64`, `drag${drag2}Vsec64`, lastTouchedSaved);
			});
		}
		this.renderControls();
		this.renderRotations(lo, g);
		this.quickRender();
		this.renderSomeHeaders();
	}
	lastTopAData: any = null;
	lastBottomAData: any = null;

	renderSomeHeaders() {
		let { lo, g } = this.lastRenderingContext;

		let toArea = (x: XY) => {
			let l = x.x;
			let t = x.y;
			let r = x.x;
			let b = x.y;
			return new Area(l, t, r, b, '');
		};
		TextBlock({
			text: 'OD',
			target: g.default,
			area: toArea(lo.leftTracking.tm).slide(0, -20),
		});
		TextBlock({
			text: 'OS',
			target: g.default,
			area: toArea(lo.rightTracking.tm).slide(0, -20),
		});
	}

	renderArrows(first: number, second: number, lastTouchedSaved: boolean) {
		let { lo, g } = this.lastRenderingContext;
		let padding = 1;
		let addedSize = 10;
		let tipdx = 10;
		let opacity = '100%';
		if (!lastTouchedSaved) {
			opacity = '50%';
		}

		if (true) {
			let target = g.default;
			let area = lo.charts[first];
			let x0 = area.left - padding;
			let y0 = area.bottom;
			let dx = area.h + addedSize;
			target
				.selectAll('.arrow')
				.data([null])
				.join('path')
				.attr('class', 'arrow fCompass1 sCompass1')
				.attr('stroke-width', 2)
				.attr(
					'd',
					drawArrow({
						x0,
						y0,
						dx,
						tipdx,
					})
				)
				.attr('transform', `rotate(-90 ${x0} ${y0})`)
				.attr('opacity', opacity);
		}
		if (true) {
			let target = g.default;
			let area = lo.charts[second];
			let x0 = area.left - padding;
			let y0 = area.bottom;
			let dx = area.h + addedSize;
			target
				.selectAll('.arrow')
				.data([null])
				.join('path')
				.attr('class', 'arrow fCompass2 sCompass2')
				.attr('stroke-width', 2)
				.attr(
					'd',
					drawArrow({
						x0,
						y0,
						dx,
						tipdx,
					})
				)
				.attr('transform', `rotate(-90 ${x0} ${y0})`)
				.attr('opacity', opacity);
		}
	}

	renderBigVideo(target: any) {
		let { lo } = this.lastRenderingContext;
		let src = this.getVideoOriginalUrl();

		let width = lo.layout.initialRight;
		let height = lo.layout.initialBottom;

		target = target
			.selectAll('.big-video')
			.data([null])
			.join('xhtml:div')
			.attr('class', 'big-video');

		target
			.selectAll('.video')
			.data([null])
			.join('xhtml:video')
			.attr('class', 'video big')
			.attr('src', src)
			.attr('controls', 'controls')
			.attr('autoplay', 'autoplay')
			.attr('loop', 'loop')
			.style('object-fit', 'contain')
			.attr('width', width)
			.attr('height', height)
			.style('padding', 0)
			.style('margin', 0)
			.style('z-index', 1);

		target
			.selectAll('.close')
			.data([null])
			.join('xhtml:div')
			.attr('class', 'close')
			.text('x')
			.style('font-size', '50px')
			.style('font-color', '#345')
			.style('-webkit-text-stroke', 'white')
			.style('position', 'absolute')
			.style('z-index', 10)
			.style('left', 0)
			.style('top', 0)
			.style('cursor', 'pointer')
			.style('padding', 0)
			.style('margin', 0)
			.on('click', () => {
				this.state.showBigVideo = false;
				this.refresh();
			});
	}

	updateVideoPosition(frame: number) {
		let { g } = this.lastRenderingContext;
		if (!g) return;
		let element = g.gVideo.selectAll('video').node() as any;
		if (!element) return;
		element.currentTime = frame;
	}
	videoPrevFrame() {
		let { g } = this.lastRenderingContext;
		if (!g) return;
		let element = g.gVideo.selectAll('video').node() as any;
		if (!element) return;
		element.currentTime -= 1;
	}
	videoNextFrame() {
		let { g } = this.lastRenderingContext;
		if (!g) return;
		let element = g.gVideo.selectAll('video').node() as any;
		if (!element) return;
		element.currentTime += 1;
	}

	videoTogglePlay() {
		let { g } = this.lastRenderingContext;		
		if (!g) return;
		let element = g.gVideo.selectAll('video').node() as any;
		if (!element) return;
		let video = element;
		if (video.paused) {
			video.playbackRate = RATE;
			video.play();
		} else {
			video.pause();
		}
	}

	get isPlaying() {
		let { g } = this.lastRenderingContext;
		if (!g) return false;
		let element = g.gVideo.selectAll('video').node() as any;
		if (!element) return false;
		let video = element;
		return !video.paused;
	}

	renderVideoControls() {
		let { lo, g } = this.lastRenderingContext;
		let area = lo.videoControls.slide(0, 5);
		let target = g.gVideoControls;
		let isPlaying = this.isPlaying;
		let icon = isPlaying ? 'pause' : 'play';
		let iconPath = `/frontend/src/assets/images/diagram-icons/${icon}.svg`;

		let table = {
			rowHeaders: [''],
			columns: [
				{
					at: 0,
					rows: [
						{
							text: '-1',
							onClick: () => {
								this.videoPrevFrame();
							},
						},
					],
				},
				{
					at: 1,
					rows: [
						{
							// text: `⏯`,
							// 5B6 ▶/▶️
							text: '',
							image: iconPath,
							width: 16,
							height: 16,
							x: -8,
							onClick: () => {
								this.videoTogglePlay();
							},
						},
					],
				},
				{
					at: 2,
					rows: [
						{
							text: '+1',
							onClick: () => {
								this.videoNextFrame();
							},
						},
					],
				},
			],
		};

		VTable({
			domainX: [0, 2],
			...table,
			target,
			area,
			styleParams: {
				override: {},
			},
		});
	}

	renderRotations(lo: ChartLayout, g: Targets) {
		Compass({
			rotationDeg: this.state.settings.rotationOS,
			area: lo.rightCompass,
			target: g.default,
		});
		Compass({
			rotationDeg: this.state.settings.rotationOD,
			area: lo.leftCompass,
			target: g.default,
		});
	}

	renderResultSquares(lo: ChartLayout, g: Targets) {
		let allRecordings = this.state.recordings;
		let activeRecording = this.state.activeRecording;

		let allSquares = allRecordings.map((x) => this.state.mapRecordingToSquare(x));
		let activeSquare = this.state.mapRecordingToSquare(activeRecording);

		let headerSlide = 10;

		const applySavedChanges = (
			sec: number,
			firstTracker: string,
			firstTrackerValue: number,
			secondTracker: string,
			secondTrackerValue: number,
			firstTrackerV: string,
			firstTrackerVValue: number,
			secondTrackerV: string,
			secondTrackerVValue: number,
			lastTauchedTracker1VDefinition: string,
			uiXDomain: number[],
			uiYDomain: number[],
			rectSelection: RectSelection
		) => {
			this.state[firstTracker] = firstTrackerValue;
			this.state[secondTracker] = secondTrackerValue;
			this.state[firstTrackerV] = firstTrackerVValue;
			this.state[secondTrackerV] = secondTrackerVValue;
			this.state.lastTauchedTracker1VSaved = lastTauchedTracker1VDefinition;
			this.state[`uiXDomain${sec}`] = uiXDomain;
			this.state[`uiYDomain${sec}`] = uiYDomain;

			if (rectSelection.done) {
				this.state.rectSelection = rectSelection;
				this.state.chosenRange = sec;
			} else {
				this.state.rectSelection = null;
				this.state.chosenRange = 0;
			}
		};

		const functionWrapper = (e: any, eye: string, sec: number) => {
			let firstTrackerV = `drag1Vsec${sec}`;
			let secondTrackerV = `drag2Vsec${sec}`;

			if (e.values[eye].lastTauchedTracker1VDefinition === `drag3Vsec${sec}`) {
				firstTrackerV = `drag3Vsec${sec}`;
				secondTrackerV = `drag4Vsec${sec}`;
			}

			const rectSelection: RectSelection = {};
			rectSelection.done = false;

			if (e.values[eye].start.length > 0 || e.values[eye].finish.length > 0) {
				rectSelection.done = true;
				rectSelection.start = {
					x: e.values[eye].start[0],
					y: e.values[eye].start[1],
				};
				rectSelection.finish = {
					x: e.values[eye].finish[0],
					y: e.values[eye].finish[1],
				};
			}

			applySavedChanges(
				sec,
				`drag1sec${sec}`,
				e.values[eye].lastTauchedTracker1,
				`drag2sec${sec}`,
				e.values[eye].lastTauchedTracker2,
				firstTrackerV,
				e.values[eye].lastTauchedTracker1V,
				secondTrackerV,
				e.values[eye].lastTauchedTracker2V,
				e.values[eye].lastTauchedTracker1VDefinition,
				e.values[eye].uiXDomain,
				e.values[eye].uiYDomain,
				rectSelection
			);
		};

		if (true) {
			let n = 0; // top or bottom 3x3 squares group
			let area = lo.resultBottom;

			let data: any = this.getSquaresData(n, allSquares, activeSquare);

			if (data.length > 0 && this.state.initializeSaved) {
				data.forEach((e) => {
					if (e.name === activeSquare.name) {
						if (this.state.cornerButtons.choice === 2 && (!e.values.OD.empty || !e.values.OS.empty)) {
							const eye = !e.values.OD.empty ? 'OD' : 'OS';
							this.state.lastTouchedSaved = e.values[eye].range;
							if (this.state.lastTouchedSaved === 'drag1sec4') {
								functionWrapper(e, eye, 4);
							}
							if (this.state.lastTouchedSaved === 'drag1sec34') {
								functionWrapper(e, eye, 34);
							}
							if (this.state.lastTouchedSaved === 'drag1sec64') {
								functionWrapper(e, eye, 64);
							}
						}

						if (this.state.cornerButtons.choice === 0 && !e.values.OD.empty) {
							this.state.lastTouchedSaved = e.values.OD.range;
							if (this.state.lastTouchedSaved === 'drag1sec4') {
								functionWrapper(e, 'OD', 4);
							}
							if (this.state.lastTouchedSaved === 'drag1sec34') {
								functionWrapper(e, 'OD', 34);
							}
							if (this.state.lastTouchedSaved === 'drag1sec64') {
								functionWrapper(e, 'OD', 64);
							}
						}

						if (this.state.cornerButtons.choice === 1 && !e.values.OS.empty) {
							this.state.lastTouchedSaved = e.values.OS.range;
							if (this.state.lastTouchedSaved === 'drag1sec4') {
								functionWrapper(e, 'OS', 4);
							}
							if (this.state.lastTouchedSaved === 'drag1sec34') {
								functionWrapper(e, 'OS', 34);
							}
							if (this.state.lastTouchedSaved === 'drag1sec64') {
								functionWrapper(e, 'OS', 64);
							}
						}
					}
				});
				this.state.initializeSaved = !this.state.initializeSaved;
			}

			Squares({
				data,
				rows: 3,
				cols: 3,
				canRemove: true,
				showMenu: this.state.squares.showMenu,
				showMenuButton: this.showMenuButton,
				onChoice: (square) => {
					this.state.initializeSaved = true;
					this.state.lastTouchedSaved = '';
					this.squareChoice(square);
				},
				onRemove: () => {
					this.recordClearFlag();
				},
				onRecordFully: async () => {
					await this.storeSquareFully();
					this.refresh();
				},
				onToggleMenu: () => {
					this.toggleSquaresMenu();
				},
				onMenu: async (s: string) => {
					await this.storeType(s);
					this.toggleSquaresMenu();
				},
				area,
				target: g.default,
				menuTarget: g.default,
			});

			Header({
				text: FAR,
				alignLeft: true,
				alignTop: true,

				target: g.default,
				area: area.slide(headerSlide, headerSlide),
				styleParams: {
					override: {
						fontSize: 24,
					},
				},
			});
		}

		if (true) {
			let n = 1;
			let area = lo.resultTop;

			let data: any = this.getSquaresData(n, allSquares, activeSquare);

			if (data.length > 0 && this.state.initializeSaved) {
				data.forEach((e) => {
					if (e.name === activeSquare.name) {
						if (this.state.cornerButtons.choice === 2 && (!e.values.OD.empty || !e.values.OS.empty)) {
							const eye = !e.values.OD.empty ? 'OD' : 'OS';
							this.state.lastTouchedSaved = e.values[eye].range;
							if (this.state.lastTouchedSaved === 'drag1sec4') {
								functionWrapper(e, eye, 4);
							}
							if (this.state.lastTouchedSaved === 'drag1sec34') {
								functionWrapper(e, eye, 34);
							}
							if (this.state.lastTouchedSaved === 'drag1sec64') {
								functionWrapper(e, eye, 64);
							}
						}

						if (this.state.cornerButtons.choice === 0 && !e.values.OD.empty) {
							this.state.lastTouchedSaved = e.values.OD.range;
							if (this.state.lastTouchedSaved === 'drag1sec4') {
								functionWrapper(e, 'OD', 4);
							}
							if (this.state.lastTouchedSaved === 'drag1sec34') {
								functionWrapper(e, 'OD', 34);
							}
							if (this.state.lastTouchedSaved === 'drag1sec64') {
								functionWrapper(e, 'OD', 64);
							}
						}

						if (this.state.cornerButtons.choice === 1 && !e.values.OS.empty) {
							this.state.lastTouchedSaved = e.values.OS.range;
							if (this.state.lastTouchedSaved === 'drag1sec4') {
								functionWrapper(e, 'OS', 4);
							}
							if (this.state.lastTouchedSaved === 'drag1sec34') {
								functionWrapper(e, 'OS', 34);
							}
							if (this.state.lastTouchedSaved === 'drag1sec64') {
								functionWrapper(e, 'OS', 64);
							}
						}
					}
				});
				this.state.initializeSaved = !this.state.initializeSaved;
			}

			Squares({
				data,
				rows: 3,
				cols: 3,
				canRemove: true,
				showMenu: this.state.squares.showMenu,
				showMenuButton: this.showMenuButton,
				onChoice: (square) => {
					this.state.initializeSaved = true;
					this.state.lastTouchedSaved = '';
					this.squareChoice(square);
				},
				onRemove: () => {
					this.recordClearFlag();
				},
				onRecordFully: async () => {
					await this.storeSquareFully();
					this.refresh();
				},
				onToggleMenu: () => {
					this.toggleSquaresMenu();
				},
				onMenu: async (s: string) => {
					await this.storeType(s);
					this.toggleSquaresMenu();
				},
				area,
				target: g.default,
				menuTarget: g.default,
			});

			Header({
				text: NEAR,
				alignLeft: true,
				alignTop: true,
				target: g.default,
				area: area.slide(headerSlide, headerSlide),
				styleParams: {
					override: {
						fontSize: 24,
					},
				},
			});
		}
	}

	getExportSquareData() {
		let allRecordings = this.state.recordings;
		let activeRecording = this.state.activeRecording;
		console.log(activeRecording, 'active');
		
		let allSquares = allRecordings.map((x) => this.state.mapRecordingToSquare(x));
		let activeSquare = this.state.mapRecordingToSquare(activeRecording);
		let data1 = this.getSquaresData(0, allSquares, activeSquare);
		let data2 = this.getSquaresData(1, allSquares, activeSquare);
		return data1.concat(data2);
	}

	getSquaresData(n: number, allSquares: any, activeSquare: any) {
		let { showOD, showTwo } = this.state.cornerButtons;
		let makeColumns = (column: string, od: string, os: string) => {
			let columns: string[];
			let isSingle = od.length > 2 && od.substring(2) === os.substring(2);
			let makeBoth = (x: string) => x.replace(/OD|OS/, `OU`);
			if (showTwo) {
				if (isSingle) {
					columns = [column, '', makeBoth(od), '', '', ''];
				} else {
					columns = [column, '', od, '', '', os, '', ''];
				}
			} else {
				if (showOD) {
					let text = isSingle ? makeBoth(od) : od;
					columns = [column, '', '', text, '', ''];
				} else {
					let text = isSingle ? makeBoth(os) : os;
					columns = [column, '', '', text, '', ''];
				}
			}
			return columns;
		};

		let data = [] as any;
		_.times(3, (x) => {
			_.times(3, (y) => {
				let active = activeSquare.n === n && x === activeSquare.x && y === activeSquare.y;
				let found = allSquares.find((o: any) => o.x === x && o.y === y && o.n === n);
				if (found) {
					let square = { x, y, n, active, name: found.name };

					let getText = (eye: string) => {
						let value = this.getValue({
							...square,
							eye,
						});

						if (value.type == null) value.type = ''; // NOTE: ref
						let v = squareText({ ...value, eye });
						return [v, value];
					};
					let [textOd, valueOd] = getText(OD);
					let [textOs, valueOs] = getText(OS);
					let columns = makeColumns(COLUMN1, textOd, textOs);
					data.push({
						...square,
						columns,
						values: {
							OD: valueOd,
							OS: valueOs,
						},
					});
				}
			});
		});

		return data;
	}

	get showMenuButton() {
		return true;
	}

	toggleSquaresMenu() {
		this.state.squares.showMenu = !this.state.squares.showMenu;
		this.render();
	}

	async storeType(s: string) {
		let isOS = this.state.cornerButtons.showOS;
		let isOD = this.state.cornerButtons.showOD;

		if (isOD) {
			await this.storeNystagmusType('OD', s);
		}
		if (isOS) {
			await this.storeNystagmusType('OS', s);
		}
	}

	async storeSquareFully() {
		try {
			let isOS = this.state.cornerButtons.showOS;
			let isOD = this.state.cornerButtons.showOD;

			this.state.lastTouchedSaved = this.state.lastTauchedTracker1;

			if (isOD) {
				await this.recordManualChanges('OD');
			}
			if (isOS) {
				await this.recordManualChanges('OS');
			}
		} catch (e) {
			console.error(e);
		}
	}

	async squaresRightClick(i: number) {
		let isOD = false;
		let isOS = false;

		if (this.state.cornerButtons.showBoth) {
			isOD = true;
			isOS = true;
		}

		// columns order differs
		if (i === 3) {
			if (this.state.cornerButtons.showOD) {
				isOD = true;
			} else if (this.state.cornerButtons.showOS) {
				isOS = true;
			}
		}
		if (i === 2) {
			isOD = true;
		}
		if (i === 5) {
			isOS = true;
		}

		if (isOD) {
			await this.recordManualChanges('OD');
		}
		if (isOS) {
			await this.recordManualChanges('OS');
		}

		if (isOS || isOD) this.refresh();
	}

	squareChoice(square: SquareData) {
		this.state.squares.showMenu = false;

		this.state.dataSets.forEach((x, i) => {
			let xSquare = this.state.mapRecordingToSquare(x);
			let match = squareMatch(xSquare, square);
			if (match) {
				this.state.selectedSeriesIndex = i;
				this.refresh();
				return true;
			}
		});
	}

	squareToPoint(square: Sq) {
		return this.state.dataSets.find((x, i) => {
			let xSquare = this.state.mapRecordingToSquare(x);
			return squareMatch(xSquare, square);
		});
	}

	renderTracksLines(
		lo: ChartLayout,
		g: Targets,
		xScale: any,
		yScale: any,
		dragFirst: string,
		dragSecond: string,
		chartNumber: number,
		dragFirstV: string,
		dragSecondV: string,
		lastTouchedSaved: boolean
	) {
		let coloring = (x: any) => 'white';
		let positioning = (x: any) => x;

		let area = lo.charts[chartNumber];
		let xDomain = xScale.domain();
		let yDomain = yScale.domain();

		if (chartNumber === 0 || chartNumber === 1) {
			this.state.uiXDomain4 = xDomain;
			this.state.uiYDomain4 = yDomain;
		}

		if (chartNumber === 2 || chartNumber === 3) {
			this.state.uiXDomain34 = xDomain;
			this.state.uiYDomain34 = yDomain;
		}

		if (chartNumber === 4 || chartNumber === 5) {
			this.state.uiXDomain64 = xDomain;
			this.state.uiYDomain64 = yDomain;
		}

		let x0 = xDomain[0];
		let y0 = yDomain[0];
		let yn = yDomain[1];
		let x1 = this.state[dragFirst] * (xDomain[1] - xDomain[0]) + xDomain[0];
		let x2 = this.state[dragSecond] * (xDomain[1] - xDomain[0]) + xDomain[0];
		let y1 = this.state[dragFirstV] * (yDomain[1] - yDomain[0]) + yDomain[0];
		let y2 = this.state[dragSecondV] * (yDomain[1] - yDomain[0]) + yDomain[0];

		let id = 0;
		let series = [] as any;
		let addLine = (xa: any, ya: any, xb: any, yb: any) => {
			id += 1;
			series.push({
				id,
				dataPoints: [
					{ x: xa, y: ya },
					{ x: xb, y: yb },
				],
			});
		};

		let invScaleY = yScale.copy().domain([yDomain[1], yDomain[0]]);

		let opacity = '100%';

		if (!lastTouchedSaved) {
			opacity = '50%';
		}

		Line({
			from: {
				x: xScale(x0) - TRACK_LEFT_ARM,
				y: invScaleY(y1),
			},
			to: {
				x: xScale(Math.max(x1, x2)),
				y: invScaleY(y1),
			},
			target: g.default,
			styleParams: {
				override: {
					lineKlass: 'sContrast',
					opacity: opacity,
				},
			},
		});
		Line({
			from: {
				x: xScale(x0) - TRACK_LEFT_ARM,
				y: invScaleY(y2),
			},
			to: {
				x: xScale(Math.max(x1, x2)),
				y: invScaleY(y2),
			},
			target: g.default,
			styleParams: {
				override: {
					lineKlass: 'sContrast',
					opacity: opacity,
				},
			},
		});

		if (true) {
			addLine(x1, y0, x1, yn);
			addLine(x2, y0, x2, yn);
		}

		let aData: any = AData<any>({
			series,
			positioning,
			coloring,
			xFrom: xDomain[0],
			xTo: xDomain[1],
			yFrom: yDomain[1],
			yTo: yDomain[0],
		});

		let ctx = AChart2<DataPoint>({
			showDots: false,
			aData,
			target: g.default,
			area,
		});
		ChartLines({
			...ctx,
			target: g.default,
			styleParams: {
				theme: '',
				override: {
					lineSize: 1,
					class: 'sContrast',
					opacity: opacity,
				},
			},
		});
	}

	chartMousePosition(area: Area, xScale: any, yScale: any) {
		let pos = d3.mouse(d3.event.currentTarget);
		let [x, y] = pos;
		x += area.left;
		y += area.top; // there may be a better way
		let xx = xScale.invert(x);
		let yy = yScale.invert(y);
		return { x: xx, y: yy };
	}

	// L is disabled also
	// L - draggable by the right mouse button L-shaped thingy
	layerMouseDown(area: Area, xScale: any, yScale: any, L: boolean, e: any) {
		if (area.name === 'chart0' || area.name === 'chart1') {
			this.state.chosenRange = 4;
			this.state.lastTauchedTracker1 = 'drag1sec4';
		}

		if (area.name === 'chart2' || area.name === 'chart3') {
			this.state.chosenRange = 34;
			this.state.lastTauchedTracker1 = 'drag1sec34';
		}

		if (area.name === 'chart4' || area.name === 'chart5') {
			this.state.chosenRange = 64;
			this.state.lastTauchedTracker1 = 'drag1sec64';
		}

		let isRightButton = d3.event.which === 3;
		let { x, y } = this.chartMousePosition(area, xScale, yScale);

		if (isRightButton) {
			if (this.state.rectSelection && this.state.rectSelection.done) {
				this.state.rectSelection = null;
				this.refresh();
				return;
			}
			if (!L) return;
			this.state.startRectSelection(x, y);
			return;
		}

		let frame = this.state.getFrameByTime(x);
		let { index } = frame;
		this.updateVideoPosition(index);
		// this.refresh()
	}

	layerMouseMove(area: Area, xScale: any, yScale: any, L: boolean, e: any) {
		if (!this.state.inProgressRectSelection) return
		let { x, y } = this.chartMousePosition(area, xScale, yScale)
		this.state.updateRectSelection(x, y)
		this.refresh()
	}

	layerMouseUp(area: Area, xScale: any, yScale: any, L: boolean) {
		if (!this.state.inProgressRectSelection) return;
		let { x, y } = this.chartMousePosition(area, xScale, yScale);
		this.state.updateRectSelection(x, y);
		this.state.finishRectSelection();
		this.refresh();
	}

	layerMouseLeave(area: Area, xScale: any, yScale: any, L: boolean) {
		this.layerMouseUp(area, xScale, yScale, L);
	}

	dotColoring = (point: DataPoint, series: IDataSet<DataPoint>) => getColor(series.id, this.state.maxId);

	renderLeftInfo(
		ui: { lo: ChartLayout; g: Targets; styleParams: { theme: string } },
		p: { show: boolean; title: string; info: string; getTitleArea: () => Area; getInfoArea: () => Area }
	) {
		Header({
			text: p.title,
			alignLeft: true,
			alignTop: true,
			show: p.show,
			target: ui.g.default,
			area: p.show ? p.getTitleArea() : ui.lo.empty,
			styleParams: ui.styleParams,
		});
		TextBlock({
			text: p.info,
			alignLeft: true,
			alignTop: true,
			show: p.show,
			target: ui.g.default,
			area: p.show ? p.getInfoArea() : ui.lo.empty,
			styleParams: ui.styleParams,
		});
	}

	renderControls() {
		let { lo, g } = this.lastRenderingContext;
		let styleParams = { theme: '' }; // wanna refactor this out for sure
		let fontSize = 16;
		let styles = {
			...styleParams,
			override: { fontSize, fontWeight: 400 },
		};
		let textSize = 1;
		let radius = 13;
		if (true) {
			let area = lo.cornerButtons;
			let target = g.default;
			let { choice } = this.state.cornerButtons;
			let table = {
				rowHeaders: [''],
				columns: [
					{
						at: 0,
						rows: [
							{
								text: 'OD',
								chosen: choice === Choice.OD,
								onClick: () => {
									this.state.cornerButtons.choice = Choice.OD;
									this.state.initializeSaved = true;
									this.state.lastTouchedSaved = '';
									this.refresh();
								},
							},
						],
					},
					{
						at: 1,
						rows: [
							{
								text: 'OS',
								chosen: choice === Choice.OS,
								onClick: () => {
									this.state.cornerButtons.choice = Choice.OS;
									this.state.initializeSaved = true;
									this.state.lastTouchedSaved = '';
									this.refresh();
								},
							},
						],
					},
					{
						at: 2,
						rows: [
							{
								text: 'OU',
								chosen: choice === Choice.Both,
								onClick: () => {
									this.state.cornerButtons.choice = Choice.Both;
									this.state.initializeSaved = true;
									this.state.lastTouchedSaved = '';
									this.refresh();
								},
							},
						],
					},
				],
			};

			VTable({
				domainX: [0, 2],
				showRects: true,
				addKlass: 'diagram-button',
				...table,

				target,
				area,
			});
		}
		if (true) {
			let area = lo.actualSpeed;
			let target = g.default;
			let table = {
				rowHeaders: [''],
				columns: [
					{
						at: 1,
						rows: [
							{
								text: 'Actual speed',
								onClick: () => {
									this.state.showBigVideo = !this.state.showBigVideo;
									this.refresh();
								},
							},
						],
					},
				],
			};

			VTable({
				domainX: [0, 2],
				...table,

				target,
				area,
			});
		}
		if (true) {
			// let area = lo.newControlPart.takeTop(50)
			let area = lo.rightControl;
			Header({
				text: 'OS',
				alignLeft: true,

				target: g.default,
				area,
				styleParams: styles,
			});
			MoreLess({
				text: `${this.state.settings.rotationOS} deg`,
				textSize,
				radius,
				onChange: (n) => {
					this.state.updateRotationOS(n);
					this.refresh();
				},

				target: g.default,
				area,
				styleParams: styles,
			});
		}
		if (true) {
			// let area = lo.newControlPart.takeTop(50)
			let area = lo.leftControl;
			Header({
				text: 'OD',
				alignLeft: true,

				target: g.default,
				area,
				styleParams: styles,
			});
			MoreLess({
				text: `${this.state.settings.rotationOD} deg`,
				textSize,
				radius,
				onChange: (n) => {
					this.state.updateRotationOD(n);
					this.refresh();
				},

				target: g.default,
				area,
				styleParams: styles,
			});

			// OD RAW/PoS button
			DiagramButton({
				target: g.default,
				area: area.slide(-270, 37),
				rx: 50,
				ry: 15,
				text: this.state.posOrRawOD === 'RAW' ? 'PoS' : 'RAW',
				onClick: () => {
					this.state.posOrRawOD = this.state.posOrRawOD === 'PoS' ? 'RAW' : 'PoS';
					this.refresh();
				},
			});

			// OS RAW/PoS button
			DiagramButton({
				target: g.default,
				area: area.slide(750, 37),
				rx: 50,
				ry: 15,
				text: this.state.posOrRawOS === 'RAW' ? 'PoS' : 'RAW',
				onClick: () => {
					this.state.posOrRawOS = this.state.posOrRawOS === 'PoS' ? 'RAW' : 'PoS';
					this.refresh();
				},
			});

			DiagramButton({
				target: g.default,
				area: area.slide(0, 40),
				rx: 50,
				ry: 15,
				text: '+90deg',
				// text: '+90°',
				onClick: () => {
					this.state.updateRotationOD(90);
					this.refresh();
				},
			});

			//! button for reducing amount of data
			if (this.testDuration > 4) {
				DiagramButton({
					target: g.default,
					area: area.slide(300, 40),
					rx: 50,
					ry: 15,
					text: this.state.amountOfDataText,
					onClick: () => {
						this.state.reduce = !this.state.reduce;
						this.state.amountOfDataText = this.state.amountOfDataText === 'full data' ? 'reduce data' : 'full data';
						this.refresh();
					},
				});
			}
		}
		if (true) {
			// let area = lo.newControlPart.takeTop(50)
			let area = lo.rightUnderTracking.slide(40, 0);
			let headerArea = area.slide(30, 0);
			Header({
				// text: 'Tail',
				text: 'Glow time',
				alignLeft: true,

				target: g.default,
				area: headerArea.slide(0, 30),
				styleParams: styles,
			});

			MoreLess({
				text: `${this.state.settings.glowTime} ms`,
				textSize,
				radius,
				onChange: (n) => {
					this.state.settings.updateGlowTime(n);
					this.refresh();
				},
				reposition: 1,

				target: g.default,
				area: area.takeRight(200).slide(-30, 30),
				styleParams: styles,
			});
		}
		this.renderVideoControls();
	}

	renderTrace(lo: ChartLayout, target1: Target, target2: Target, target3: Target, styleParams: { theme: string }, eye: any) {
		let ui = { lo, styleParams };
		let chartTarget = target1;
		let glowTarget = target2;
		let dotTarget = target3;

		if (!this.state.willRestartTraceAnimation) return; /////////

		let chartParams = {};

		let area = ui.lo.leftTracking;
		if (eye === 'os') area = ui.lo.rightTracking;

		let series = this.state.tracesView;

		let viewRadius = 7.5; // 10x from VF used view radius

		if (this.state.posOrRawOD === 'RAW' && eye === 'od') {
			series[0].dataPoints.forEach((el) => {
				el.od.x = el.h_od;
				el.od.y = el.v_od;
			});
		}

		if (this.state.posOrRawOD === 'PoS' && eye === 'od') {
			series[0].dataPoints.forEach((el) => {
				viewRadius = 75;
				el.od.x = el.h_od_pos;
				el.od.y = el.v_od_pos;
			});
		}

		if (this.state.posOrRawOS === 'RAW' && eye === 'os') {
			series[0].dataPoints.forEach((el) => {
				el.os.x = el.h_os;
				el.os.y = el.v_os;
			});
		}
		if (this.state.posOrRawOS === 'PoS' && eye === 'os') {
			series[0].dataPoints.forEach((el) => {
				viewRadius = 75;
				el.os.x = el.h_os_pos;
				el.os.y = el.v_os_pos;
			});
		}

		let points: XY[] = [];
		let one = series[0];
		if (one) {
			points = one.dataPoints.map((x: any) => x[eye]);
		}
		let medianX = d3.median(points.map((x) => x.x));
		let medianY = d3.median(points.map((x) => x.y));

		let positioning = (got: BothTracking) => ({ ...got[eye], time: got.time });
		let coloring = (x: BothTracking) => '';

		let aData: any = AData<BothTracking>({
			series,
			positioning: positioning,
			coloring: coloring,
			xFrom: medianX && medianX - viewRadius,
			xTo: medianX && medianX + viewRadius,
			yFrom: medianY && medianY - viewRadius,
			yTo: medianY && medianY + viewRadius,
		});

		let { xScale, yScale } = aData;
		let ctx = AChart2<BothTracking>({
			showDots: false,
			aData,
			...chartParams,

			target: chartTarget,
			area,
		});

		Rectangle({
			from: {
				x: aData.xScale.range()[0],
				y: aData.yScale.range()[0],
			},
			to: {
				x: aData.xScale.range()[1],
				y: aData.yScale.range()[1],
			},
			target: chartTarget,
			styleParams: {
				override: {
					lineKlass: 'sLayer',
				},
			},
		});

		AxisLeft({
			...ctx,
			grid: true,
			ticks: 0,
			area,
			target: chartTarget,
			styleParams: ui.styleParams,
		});
		AxisBottom({
			...ctx,
			grid: true,
			ticks: 0,
			area,
			target: chartTarget,
			styleParams: ui.styleParams,
		});

		ChartLines({
			...ctx,
			filtering: (x: any) => {
				let result = x[`${eye}Present`];
				return result;
			},
			target: chartTarget,
			styleParams: {
				...ui.styleParams,
				override: {
					class: 'sCompass1',
					lineSize: 0.6,
					round: true,
				},
			},
		});

		let frame = this.state.settings.videoPosition;
		let point = this.state.getPointByFrame(frame);
		let theTime = point.timestamp;

		let coords = series[0].dataPoints.map((x: any) => positioning(x));
		let found: any;
		let tail: any[] = [];

		coords.forEach((x: any) => {
			if (x.time === theTime) {
				found = x;
				return true;
			}
		});

		let { glowTime } = this.state.settings;
		let tailTo = (found && found.time) || 0;
		let tailFrom = tailTo - glowTime / 1000.0;
		tailFrom &&
			tailTo &&
			coords.forEach((x: any) => {
				if (x.time >= tailFrom && x.time <= tailTo) {
					tail.push(x);
				}
			});
		if (true) {
			let series = [
				{
					id: 0,
					dataPoints: tail,
				},
			];
			let aData: any = AData<XY>({
				series,
				positioning: (x: XY) => x,
				coloring: () => '',
			});
			aData = { ...aData, xScale, yScale };
			let ctx = AChart2<XY>({
				showDots: false,
				aData,
				...chartParams,
				target: glowTarget,
				area,
			});
			ChartLines({
				...ctx,
				target: glowTarget,
				styleParams: {
					...ui.styleParams,
					override: {
						class: TAIL_COLOR,
						lineSize: LINE_SIZE,
					},
				},
			});
		}
		if (found) {
			let series = [
				{
					id: 0,
					dataPoints: [found],
				},
			];
			let aData: any = AData<XY>({
				series,
				positioning: (x: XY) => x,
				coloring: () => '',
			});
			aData = { ...aData, xScale, yScale };
			let ctx = AChart2<XY>({
				showDots: false,
				aData,
				...chartParams,
				target: dotTarget,
				area,
			});
			ChartDots({
				...ctx,
				target: dotTarget,
				styleParams: {
					...ui.styleParams,
					override: {
						class: MAIN_COLOR,
						dotRadius: MAIN_DOT_RADIUS,
					},
				},
			});
		}		
	}

	renderLegends(lo: ChartLayout, g: Targets, firstChart: number, secondChart: number, lastTouchedSaved: boolean) {
		// this.renderLegend1(lo, g)
    // this.renderLegend2(lo, g)

		let toArea = (x: XY) => {
			let l = x.x;
			let t = x.y;
			let r = x.x;
			let b = x.y;
			return new Area(l, t, r, b, '');
		};
		let first = lo.charts[firstChart];
		let second = lo.charts[secondChart];
		let opacity = '100%';
		if (!lastTouchedSaved) {
			opacity = '30%';
		}
		TextBlock({
			text: 'sec',
			alignTop: true,
			alignRight: true,
			styleParams: {
				override: {
					fontWeight: 700,
					fontSize: 16,
					opacity: opacity,
				},
			},
			target: g.default,
			area: toArea(first.br).slide(10, 45),
		});
		TextBlock({
			text: 'sec',
			alignTop: true,
			alignRight: true,
			styleParams: {
				override: {
					fontWeight: 700,
					fontSize: 16,
					opacity: opacity,
				},
			},
			target: g.default,
			area: toArea(second.br).slide(10, 45),
		});
		let target = g.default;
		TextBlock({
			text: 'deg',
			styleParams: {
				override: {
					fontWeight: 700,
					fontSize: 16,
					opacity: opacity,
				},
			},
			area: toArea(first.ml).slide(-55, 0),
			target,
		});
		target.selectAll('text').attr('transform', 'rotate(270)');
		target = g.default;
		TextBlock({
			text: 'deg',
			styleParams: {
				override: {
					fontWeight: 700,
					fontSize: 16,
					opacity: opacity,
				},
			},
			target,
			area: toArea(second.ml).slide(-55, 0),
		});
		target.selectAll('text').attr('transform', 'rotate(270)');
	}
	renderLegend1(lo: ChartLayout, g: Targets) {
		let padding = 0;
		let width = 120 - 5;
		let height = 74 + 18;
		let area = lo.charts[0];
		let target = g.default;
		let point = area
			.cornerTR()
			.dx(-padding)
			.dy(padding);
		let od = {
			draw: drawPreview({
				colors: [B.green],
				strokeWidth: 3,
				timesCount: 1,
				timesStep: 1,
			}),
		};
		let os = {
			draw: drawPreview({
				colors: [B.orange],
				strokeWidth: 3,
				timesCount: 1,
				timesStep: 1,
			}),
		};
		Legend1({
			target,
			point,
			width,
			height,
			textDy: 8,
			title: 'Horizontal',
			unitX: 'sec',
			unitY: 'deg',
			also: this.getLegendAlso(),
			drawables: [od, os],
		});
	}
	renderLegend2(lo: ChartLayout, g: Targets) {
		let padding = 0;
		let width = 120 - 5;
		let height = 74 + 18;
		let area = lo.charts[1];
		let target = g.default;
		let point = area
			.cornerTR()
			.dx(-padding)
			.dy(padding);

		let od = {
			draw: drawPreview({
				colors: [B.green],
				strokeWidth: 3,
				timesCount: 1,
				timesStep: 1,
			}),
		};
		let os = {
			draw: drawPreview({
				colors: [B.orange],
				strokeWidth: 3,
				timesCount: 1,
				timesStep: 1,
			}),
		};
		Legend1({
			target,
			point,
			width,
			height,
			textDy: 8,
			title: 'Vertical',
			unitX: 'sec',
			unitY: 'deg',
			also: this.getLegendAlso(),
			drawables: [od, os],
		});
	}

	getLegendAlso() {
		let items: string[] = [];
		if (this.state.cornerButtons.showOD) {
			items.push('\n', '@1 OD - green');
		}
		if (this.state.cornerButtons.showOS) {
			items.push('\n', '@2 OS - orange');
		}
		return items;
	}

	getValue(x: any) {
		let { eye } = x;
		let point = this.squareToPoint(x);
		if (!point) {
			console.error('cant find pint');
			return;
		}
		let id = point.startPoint.timestamp;

		let got = this.getActualFromLog(id, eye);
		let isEmpty = false;

		let result: any = {};
		Object.entries(got).forEach(([k, v]) => {
			let key = k.slice(2); // OD/OS prefix
			result[key] = v;
		});

		Object.entries(result).forEach(([k, v]) => {
			if (result[k] == null) {
				if (OPTIONAL_FIELDS.includes(k)) {
					result[k] = '';
					return;
				}
				result[k] = 0;
				isEmpty = true;
			}
		});
		result.empty = isEmpty;
		return result;
	}

	async storeNystagmusType(eye: string, type: string) {
		let newRecord = {
			value: {
				type,
			},
			place: this.state.getPlace(eye),
		};
		let changes = Object.entries(newRecord.value).map(([k, v]) => {
			return [`${eye}${k}`, v];
		});

		let id = this.getEyeSquareId(eye, newRecord.place);

		for (let one of changes) {
			let [k, v] = one;
			await this.recordEdit(k, id, 0, v);
		}
	}

	async recordManualChanges(eye: string) {
		try {
			let newRecord = this.state.manualRecord(eye);

			let changes = Object.entries(newRecord.value).map(([k, v]) => {
				return [`${eye}${k}`, v];
			});

			let id = this.getEyeSquareId(eye, newRecord.place);

			for (let one of changes) {
				let [k, v] = one as any;

				if (VALUE_KEYS.includes(k.substr(2))) {
					await this.recordEdit(k, id, 0, v);
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	getEyeSquareId(eye: string, place?: any) {
		place = place || this.state.getPlace(eye);
		let point = this.squareToPoint(place);
		if (!point) {
			console.error('cant find pint');
			return 0;
		}
		let id = point.startPoint.timestamp;
		return id;
	}

	async recordClearFlag() {
		this.state.lastTouchedSaved = '';
		this.state.lastTauchedTracker1VSaved = '';
		for (let eye of this.state.cornerButtons.eyesChosen) {
			await this.recordClearFlagEye(eye);
		}
		this.refresh();
	}

	async recordClearFlagEye(eye: string) {
		let flag = `${eye}-clear`;
		let id = this.getEyeSquareId(eye);
		await this.recordEdit(flag, id, +new Date(), +new Date());
	}

	// ManualStorage record fields I guess as fieldNames
	//
	async recordEdit(fieldName: string, id: number, from: any, to: any) {
		try {
			let record = {
				messageTimestamp: id,
				fieldName,
				previousValue: from,
				currentValue: to,
			};

			let result = await this.addEdit(record)
				.then((edits: IChartEdit[]) => {
					this.edits = edits;
					// console.log(JSON.stringify(edits))
				})
				.catch(() => {
					console.error('addEdit returned an error');
				});
			return result;
		} catch (e) {
			console.error(e);
		}
	}

	addEdit(edit: MyEdit): Promise<IChartEdit[]> {
		return this.p.addEdit(edit);
	}
	get edits(): IChartEdit[] {
		return this.p.edits;
	}
	set edits(value: IChartEdit[]) {
		this.p = {
			...this.p,
			edits: value,
		};
	}

	getActualFromLog(id: number, eye: string) {
		let keys = VALUE_KEYS.map((x) => `${eye}${x}`);

		let edits = this.edits || [];

		let log = edits.filter((x) => x.messageTimestamp === id);
		let midLog = _.sortBy(log, (x) => x.date);

		let stuff = [...midLog];
		stuff.reverse();
		let clearFlag = `${eye}-clear`;
		let lastClear = stuff.find((x) => x.fieldName === clearFlag) as any;

		if (lastClear) {
			let remove = true;
			midLog = midLog.filter((x) => {
				if (!remove) return true;

				if (x.fieldName === clearFlag && x.currentValue === lastClear.currentValue) remove = false;
				return false;
			});
		}

		let results: any = {};
		keys.forEach((key) => {
			let smallLog = midLog.filter((x) => x.fieldName === key);
			let entry = _.last(smallLog);
			let value = entry ? entry.currentValue : null;
			results[key] = value;
		});

		return results;
	}

	getVideoUrl() {
		let series = this.state.chosenSeries;
		let one = series[0];
		let filename = 'unexpected-input.mp4';
		if (one != null) {
			filename = one.filename;
		}
		let url = `${this.params.apiPrefix}/haplo/video/${filename}?token=${this.params.requestToken}`;
		return url;
	}
	getVideoOriginalUrl() {
		let series = this.state.chosenSeries;
		let one = series[0];
		let filename = 'unexpected-input.mp4';
		if (one != null) {
			filename = one.originalfilename || one.filename;
		}
		let url = `${this.params.apiPrefix}/haplo/video/${filename}?token=${this.params.requestToken}`;
		return url;
	}

	exportData() {
		let squaresResults = this.getExportSquareData();
		let rawRows = [] as any;

		this.state.dataSets.forEach((ds: any) => {
			let { stimuli } = ds;
			ds.dataPoints.forEach((one: any) => {
				let { timestamp, h_od, h_os, v_os, v_od } = one;
				if (!one.odPresent) {
					h_od = 0;
					v_od = 0;
				}
				if (!one.osPresent) {
					h_os = 0;
					v_os = 0;
				}
				let f = (x: number) => (x !== 0 ? x.toFixed(3) : 0);
				let row = {
					stimuli,
					time: timestamp,
					h_od: f(h_od),
					v_od: f(v_od),
					h_os: f(h_os),
					v_os: f(v_os),
				};
				rawRows.push(row);
			});
		});

		let groups = GROUPS_ORDER.map((group) => {
			let relevantPoints = squaresResults.filter(matching(group.match));

			let points = DOTS_ORDER.map((dot) => {
				let found = relevantPoints.find(matching(dot.match));
				let point = { values: { OD: {}, OS: {} } } as any;
				if (found) {
					point = found;
				}
				let allEmpty = !found;

				let values = VALUES_ORDER.map((valueParam) => {
					let { key } = valueParam;

					let result = EYES_ORDER.map((eye) => processExportValue(key, point.values[eye][key], allEmpty ? true : point.values[eye].empty));
					return result;
				});
				return {
					...dot,
					values,
				};
			});
			return {
				...group,
				points,
			};
		});

		return {
			valuesOrder: VALUES_ORDER.map((x) => x.title),
			eyesOrder: EYES_ORDER,
			groups,
			rawRows,
		};
	}

	oldExportData() {
		let data = this.getExportSquareData();
		let points = [] as any;

		data.forEach((point: any) => {
			let eyes = [] as any;
			let pushThat = (name: string, data: any[]) => {
				eyes.push({
					name,
					frequency: data[0],
					amplitude: data[1],
					axis: data[2],
					type: data[3],
				});
			};
			let { x, y, n } = point;
			let square = { x, y, n };
			let ods = VALUE_KEYS.map((k) => point.values.OD[k]);
			let oss = VALUE_KEYS.map((k) => point.values.OS[k]);
			let hasOd = !point.values.OD.empty;
			let hasOs = !point.values.OS.empty;
			if (hasOd && hasOs) {
				let eq = !ods.find((v1, i) => {
					let v2 = oss[i];
					return v1 !== v2;
				});
				if (eq) {
					pushThat(`${OD}=${OS}`, ods);
				} else {
					pushThat(OD, ods);
					pushThat(OS, oss);
				}
			} else if (hasOd || hasOs) {
				let name = hasOd ? OD : OS;
				let stuff = hasOd ? ods : oss;
				pushThat(name, stuff);
			} else {}
			if (eyes.length) {
				points.push({
					name: point.name,
					square,
					eyes,
				});
			}
		});
		return {
			points,
		};
	}
}

interface XY {
	x: number;
	y: number;
}

function squareText(d: any) {
	let eye = d.eye;
	if (d.empty) return eye;
	let f = d.frequency.toFixed(2);
	let a = d.amplitude.toFixed(2);
	// return `Freq: ${f} Hz\nAmp: ${a}'\nAxis: ${d.axis*60}'\nBidir: ${d.bidirectional ? 'Y' : 'N'}`
	let type = d.type;
	return `${eye}\n${f} Hz\n${a}°\n${d.axis}°\n${type}`;
}

interface Sq {
	x: number;
	y: number;
	n: number;
}

function squareMatch(a: Sq, b: Sq) {
	return a.x === b.x && a.y === b.y && a.n === b.n;
}

function matching(matchObj: object) {
	let checks = Object.entries(matchObj);
	return (x: object) => {
		return !checks.find(([k, v]) => x[k] !== v);
	};
}
