// @ts-nocheck
import * as d3 from 'd3';
import { translate, getNewClass } from './util';
import { Target } from './types';
import { comboDefaultStyle } from './ComboBox';

let exampleStyle = {
    optionTick: { color: '', size: 0, klass: '' },
    comboText: { color: '', size: 0 },
};
type OptionStyle = typeof exampleStyle;

interface Preview {
    x: number;
    render: (target: Target) => void;
}
interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}
interface Margins {
    left: number;
}
interface OptionParams {
    klass?: string;
    text: string;
    handler: () => void;
    height: number;
    textAlign?: string;
    getChecked?: () => boolean;
    doNotClose?: boolean;
    preview?: Preview;
    style?: OptionStyle;
    theme?: string;
    tickScale?: number;
    tickColor?: string;
}
// ugly prly: takes "y" returns "text"
export class GroupOption {
    eventName = 'mousedown';

    klass: string;
    style: OptionStyle;

    constructor(private params: OptionParams) {
        this.klass = this.params.klass || getNewClass('option');
        this.style = params.style || comboDefaultStyle(params.theme);
    }

    // get style () {
    //     return this.params.style;
    // }
    get text() {
        return this.params.text;
    }
    get handler() {
        return this.params.handler;
    }
    get height() {
        return this.params.height;
    }
    get getChecked() {
        return this.params.getChecked || (() => false);
    }
    get hasCheckbox() {
        return !!this.params.getChecked;
    }
    get preview() {
        return this.params.preview;
    }
    get textAlign() {
        return this.params.textAlign || 'center';
    }
    get doNotClose() {
        return this.params.doNotClose || false;
    }

    render(g: Target, rect: Rect) {
        let klass = this.klass;
        let selector = `.${klass}`;

        let real = !!this.text;

        let padX = 10;
        let marginLeft = 40;

        let target = g
            .selectAll(selector)
            .data([null])
            .join('g')
            .attr('cursor', real ? 'pointer' : 'default')
            .attr('class', klass);
        // .attr('transform', translate(rect))

        target
            .selectAll('rect')
            .data([null])
            .join('rect')
            .attr('x', rect.x)
            .attr('y', rect.y)
            .attr('width', rect.width)
            .attr('height', rect.height)
            .attr('fill', 'transparent')
            .attr('stroke-opacity', 'transparent');

        // NOTE: some strange reordering if rects are not transparent
        // let textTarget = target.selectAll('.text-target')
        // .data([null]).join('g').attr('class', 'text-target')

        // let checkboxWidth =
        let tickScale = this.params.tickScale || 30;
        let checkboxScale = `scale(${tickScale})`;

        // let marginLeft = this.height / 3;
        // if (this.hasCheckbox) marginLeft += this.height / 2 + this.height / 8; // ...

        if (this.hasCheckbox) {
            let delta = 10;
            let deltaY = 3;

            let checked = this.getChecked();
            let on = checked ? 'on' : 'off';

            target
                .selectAll('.checkbox')
                .data([null])
                .join('image')
                .attr('href', `/frontend/src/assets/images/diagram-icons/tick-${on}.svg`)
                .attr('class', `checkbox ${this.style.optionTick.klass}`)
                // .attr('d', image)
                // .attr('fill', 'none')
                // .attr('stroke', this.params.tickColor ?? this.style.optionTick.color)
                // .attr('stroke-width', this.style.optionTick.size)
                .attr('transform', translate({ x: delta + padX, y: rect.y + deltaY - 10 }))
                .attr('stroke', 'gray')
                .attr('fill', 'gray')
                .attr('width', 25);

            // let image = checked ? drawTick : '';

            // target
            //     .selectAll('.checkbox')
            //     .data([null])
            //     .join('path')
            //     .attr('class', `checkbox ${this.style.optionTick.klass}`)
            //     .attr('d', image)
            //     .attr('fill', 'none')
            //     .attr('stroke', this.params.tickColor ?? this.style.optionTick.color)
            //     .attr('stroke-width', this.style.optionTick.size)
            //     .attr('transform', translate({ x: delta+padX, y: rect.y + delta }) + ' ' + checkboxScale);
        }

        let margins = { left: marginLeft };

        let positionCenter = (rect: Rect) => (x: Target) =>
            x
                .attr('x', rect.x + rect.width / 2)
                .attr('y', rect.y + rect.height / 2)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle');
        let positionLeft = (rect: Rect, margins: Margins) => (x: Target) =>
            x
                .attr('x', rect.x + margins.left + padX) // + rect.width / 2)
                .attr('y', rect.y + rect.height / 2)
                // .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle');

        let position = this.textAlign === 'center' ? positionCenter(rect) : positionLeft(rect, margins);

        target
            .selectAll('text')
            .data([null])
            .join('text')
            .text(this.text)
            // .attr('fill', this.style.comboText.color)
            .attr('font-size', this.style.comboText.size)
            .attr('class', 'fLayerFontX')
            .call(position);

        target.on(this.eventName, () => {
            if (this.doNotClose) {
                let e = d3.event;
                e.stopPropagation();
            }
            // console.log(e)
            this.handler();
        });

        if (this.preview) {
            let y = rect.y + rect.height / 2;
            let g = target
                .selectAll('.gPreview')
                .data([null])
                .join('g')
                .attr('class', 'gPreview')
                .attr('transform', translate({ x: this.preview.x, y }));
            this.preview.render(g);
        }
    }
}
