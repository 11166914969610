import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TEST_STATUS } from '../../../../../../../../../commonout/enum/test-status';
import { TEST_COMMAND } from '../../../../../../../../../commonout/enum/test.command.enum';
import { OCULUS } from '../../../../../../../../common/enums/oculus.enum';
import { BulbicamService } from '../../../../../_services/examination/bulbiCam.service';
import { AuthenticationService } from '../../../../../_services/general/auth.service';
import { ConfigService } from '../../../../../_services/general/config.service';
import { SocketService } from '../../../../../_services/general/socket.service';
import { BulbicamControlComponent } from '../bulbicam-control-component';

@Component({
    selector: 'darkAdaptationAmd-bulbicam-test-control',
    template: require('./darkAdaptationAmd-bulbicam-test-control.component.html'),
    styles: [require('./darkAdaptationAmd-bulbicam-test-control.component.scss')],
    host: { '[class.pendingResponse]': 'pendingResponse' },
})
export class DarkAdaptationAmdBulbicamControlComponent extends BulbicamControlComponent {
    public enable(): void {}
    public disable(): void {}
    private OCULUS: typeof OCULUS = OCULUS;
    private visualAcuity: FormControl = new FormControl(true);
    private contrastWithoutBleach: FormControl = new FormControl(true);
    private contrastWithBleach: FormControl = new FormControl(true);
    private bleachRecovery: FormControl = new FormControl(true);
    constructor(bulbicamService: BulbicamService, configService: ConfigService, socketService: SocketService, authService: AuthenticationService) {
        super(bulbicamService, configService, socketService, authService);
    }
    private start(target: OCULUS): void {
        this.pendingResponse = true;
        this.bulbicamService
            .sendCommand(this.test.examination.getModel(), this.test.type, this.test.device, TEST_COMMAND.START, this.socketService.id, {
                target: target,
                visualAcuity: this.visualAcuity.value,
                contrastWithoutBleach: this.contrastWithoutBleach.value,
                contrastWithBleach: this.contrastWithBleach.value,
                bleachRecovery: this.bleachRecovery.value,
                username: this.authService.currentUser.username,
            })
            .subscribe(() => {
                this.test.status = TEST_STATUS.IN_PROGRESS;
                this.pendingResponse = false;
            });
    }
    private formIsValid(): boolean {
        return this.visualAcuity.value || this.contrastWithoutBleach.value || this.contrastWithBleach.value || this.bleachRecovery.value;
    }
}
