import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3';
import { BehaviorSubject } from 'rxjs';
import { IPursuitSaccadesCamMessage, IPursuitSaccadeTestResults, ISaccadeResult, SACCADE_RESULT, IChartData, CHART_SUBTYPE, RANGE_TYPE } from '../../../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { FocusDirective } from '../../../../../../_directives';
import { ChartService } from '../../../../../../_services/chartServices/chartService';
import { SaccadeChartService } from '../../../../../../_services/chartServices/pursuitSaccadesServices/saccadesTestServices/saccadesChartService';
import { CHART_HEIGHT, HORIZONTAL_LINE_COLOR, VERTICAL_LINE_COLOR, TICKS_COLOR, TICKS_FONT_SIZE, AXIS_COLOR } from '../../chartStyles.constants';
import { Y_SCALE_MAX_VALUE, X_TIMESCALE_MAX_VALUE, COUNT_OF_DEGREES_TICKS, ILine } from '../../generic-types';


export enum MOVEMENT_TYPE {
    VERTICAL,
    HORIZONTAL
}

export const TRESHOLD_VALUE = 30;

@Component({
  selector: 'app-saccade-movement-chart',
  template: require('./saccade-movement-chart.component.html'),
  styles: [require('./saccade-movement-chart.component.scss')]
})
export class SaccadeMovementChartComponent implements AfterViewInit {

    isFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    lockTab: boolean = false;
    focusedControl: number = 1;
    @ViewChildren(FocusDirective) controls: QueryList<FocusDirective>;

    @ViewChild('chart') private svgElement: ElementRef;
    @Input() public data: IPursuitSaccadesCamMessage[];
    @Input() public width = 1400;
    @Input() public height = CHART_HEIGHT;
    @Input() public margin = 50;
    @Output() updateEvent = new EventEmitter<IPursuitSaccadesCamMessage>();

    public testResults: IPursuitSaccadeTestResults;

    private calibratedFrames: IPursuitSaccadesCamMessage[];
    private frames: IPursuitSaccadesCamMessage[];
    private svg: d3.Selection<SVGElement, unknown, null, undefined>;
    private svgInner: d3.Selection<SVGElement, unknown, null, undefined>;
    private yScaleStimuli: ScaleLinear<number, number>;
    private yScaleAngle: ScaleLinear<number, number>;
    private yScalePoint: number;
    private xScale: ScaleLinear<number, number>;
    private lastPoint: [number, number];
    private counter = 0;
    private verticalSaccadesResults: ISaccadeResult[] = [];
    private horizontalSaccadesResults: ISaccadeResult[] = [];
    private amplitudes: number[] = [];
    private latencies: number[] = [];
    private peaksVelocity: number[] = [];

    private readonly legend: {
      trendName: string;
      defaultState: boolean;
      trendClass: string;
      trendColour: string;
    }[] = [
        {
            trendName: 'OS horizontal calibrated',
            defaultState: true,
            trendClass: 'calibrated_OS_horizontal',
            trendColour: HORIZONTAL_LINE_COLOR,
        },
        {
            trendName: 'OS vertical calibrated',
            defaultState: true,
            trendClass: 'calibrated_OS_vertical',
            trendColour: VERTICAL_LINE_COLOR,
        },
    ];

    constructor(private saccadeService: SaccadeChartService) { }

    ngAfterViewInit(): void {
        this.initializeInitialChart();
        this.drawLegendLines();
        
    }
    types: string[] = Object.values(SACCADE_RESULT);
    previousValue = '';

    public changeResult(result: string, rangeId: number): void {    
        if (result === 'accepted' && this.previousValue !== "accepted") {
            this.testResults.accept++;
            this.testResults.rangeTestResults[rangeId].accept++;
            if (this.previousValue === "error patient") {
              this.testResults.errorPatient--;
              this.testResults.rangeTestResults[rangeId].errorPatient--;
            } else if (this.previousValue === "error system") {
              this.testResults.errorSystem--;
              this.testResults.rangeTestResults[rangeId].errorSystem--;
            }
        }
        else if (result === 'error patient' && this.previousValue !== "error patient") {
            this.testResults.errorPatient++;
            this.testResults.rangeTestResults[rangeId].errorPatient++;
            if (this.previousValue === "accepted") {
              this.testResults.accept--;
              this.testResults.rangeTestResults[rangeId].accept--;
            } else if (this.previousValue === "error system") {
              this.testResults.errorSystem--;
              this.testResults.rangeTestResults[rangeId].errorSystem--;
            }
        }
        else if (result === 'error system' && this.previousValue !== "error system") {
            this.testResults.errorSystem++;
            this.testResults.rangeTestResults[rangeId].errorSystem++;
            if (this.previousValue === "error patient") {
              this.testResults.errorPatient--;
              this.testResults.rangeTestResults[rangeId].errorPatient--;
            } else if (this.previousValue === "accepted") {
              this.testResults.accept--;
              this.testResults.rangeTestResults[rangeId].accept--;
            }
        }

        const saccadesTestResults = this.testResults.rangeTestResults[rangeId]?.saccadesTestResults;

        saccadesTestResults?.forEach(saccadeResult => {
            if (saccadeResult.result === "accepted") {
                this.peaksVelocity.push(saccadeResult.peakVelocity);
                this.amplitudes.push(saccadeResult.amplitude);
                this.latencies.push(saccadeResult.latency);
            }
        });

        // changing average
        saccadesTestResults[saccadesTestResults.length - 1].latency = this.saccadeService.calculateAverage(this.latencies);
        saccadesTestResults[saccadesTestResults.length - 1].peakVelocity = this.saccadeService.calculateAverage(this.peaksVelocity);
        saccadesTestResults[saccadesTestResults.length - 1].amplitude = this.saccadeService.calculateAverage(this.amplitudes);

        this.frames[0].testResults = this.testResults;
        this.updateEvent.next(this.frames[0]);

        this.peaksVelocity = [];
        this.amplitudes = [];
        this.latencies = [];
    }

    public buildRecordedChart(chartData: IChartData) {
        this.height = CHART_HEIGHT;

        if (d3.select('#movementChartContent').empty()) {
            this.configureSVG();
        }

        this.frames = chartData.movementFrames;
        this.testResults = chartData.movementFrames[0].testResults;
        this.calibratedFrames = chartData.movementFrames;

        this.initializeChart(chartData.framesData);
        this.drawMovementData([ ...chartData.movementFrames ]);
        this.drawGreenLines([ ...chartData.separatedFrames.verticalFrames ], MOVEMENT_TYPE.VERTICAL);
        this.drawGreenLines([ ...chartData.separatedFrames.horizontalFrames ], MOVEMENT_TYPE.HORIZONTAL);

        this.testResults.rangeTestResults.forEach(f => {
            if (f.type === CHART_SUBTYPE.VERTICAL) {
                this.verticalSaccadesResults = this.verticalSaccadesResults.concat(f.saccadesTestResults);
            } 
            else {
                this.horizontalSaccadesResults = this.horizontalSaccadesResults.concat(f.saccadesTestResults);
            }
        });

        this.drawCalibrationDots([ ...chartData.separatedFrames.verticalFrames ],
           this.verticalSaccadesResults, 
           MOVEMENT_TYPE.VERTICAL);
        this.drawCalibrationDots([ ...chartData.separatedFrames.horizontalFrames ], 
          this.horizontalSaccadesResults, 
          MOVEMENT_TYPE.HORIZONTAL);

        this.horizontalSaccadesResults = [];
        this.verticalSaccadesResults = [];
    }

    private initializeInitialChart(): void {
        this.configureSVG();

        this.yScaleStimuli = d3
          .scaleLinear()
          .domain([-Y_SCALE_MAX_VALUE, Y_SCALE_MAX_VALUE])
          .range([0, this.height - 2 * this.margin]);

        const angleAxisY = this.svgInner
          .append('g')
          .attr('id', 'y-axisAngle')
          .style('transform', 'translate(' + this.margin.toString() + 'px, 0)');

        this.xScale = d3
          .scaleLinear()
          .domain([0, X_TIMESCALE_MAX_VALUE]);

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', 'x-axis')
          .style('transform', 'translate(0, ' + (this.height - 2 * this.margin).toString() + 'px)');
          
        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'verticalChartPoints');

        this.width = this.svgElement.nativeElement.getBoundingClientRect().width;

        this.xScale.range([this.margin, this.width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxisAngle = d3
          .axisLeft(this.yScaleStimuli)
          .ticks(COUNT_OF_DEGREES_TICKS)

        angleAxisY
          .call(yAxisAngle)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        angleAxisY
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private drawLegendLines(): void {
      const legend = this.svg.append('g')
        .attr('class', 'legend')
        .attr('transform', `translate(${this.margin},${0})`);
      legend
        .selectAll('line')
        .data(this.legend)
        .enter()
        .append('line')
        .attr('x1', (data: any, i: number) => {
            if ([0, 1, 2].includes(i)) {
                return 100;
            } else if ([3, 4, 5].includes(i)) {
                return 400;
            } else if ([6, 7, 8].includes(i)) {
                return 700;
            } else if ([9, 10, 11].includes(i)) {
                return 1000;
            }
        })
        .attr('y1', (data: any, i: number) => {
            if ([0, 3, 6, 9].includes(i)) {
                return 10;
            } else if ([1, 4, 7, 10].includes(i)) {
                return 40;
            } else if ([2, 5, 8, 11].includes(i)) {
                return 70;
            }
        })
        .attr('x2', (data: any, i: number) => {
            if ([0, 1, 2].includes(i)) {
                return 130;
            } else if ([3, 4, 5].includes(i)) {
                return 430;
            } else if ([6, 7, 8].includes(i)) {
                return 730;
            } else if ([9, 10, 11].includes(i)) {
                return 1030;
            }
        })
        .attr('y2', (data: any, i: number) => {
            if ([0, 3, 6, 9].includes(i)) {
                return 10;
            } else if ([1, 4, 7, 10].includes(i)) {
                return 40;
            } else if ([2, 5, 8, 11].includes(i)) {
                return 70;
            }
        })
        .attr('stroke', (data: { trendColour: any; }) => data.trendColour)
        .attr('stroke-width', 3);

      legend
        .selectAll('text')
        .data(this.legend)
        .enter()
        .append('text')
        .style('fill', (data: { defaultState: any; }) => (data.defaultState ? 'green' : 'gray'))
        .style('cursor', 'pointer')
        .attr('x', (color: any, i: number) => {
            if ([0, 1, 2].includes(i)) {
                return 135;
            } else if ([3, 4, 5].includes(i)) {
                return 435;
            } else if ([6, 7, 8].includes(i)) {
                return 735;
            } else if ([9, 10, 11].includes(i)) {
                return 1035;
            }
        })
        .attr('y', (data: any, i: number) => {
            if ([0, 3, 6, 9].includes(i)) {
                return 10;
            } else if ([1, 4, 7, 10].includes(i)) {
                return 40;
            } else if ([2, 5, 8, 11].includes(i)) {
                return 70;
            }
        })
        .text((data: { trendName: any; }) => data.trendName)
        .style('font-size', '15px')
        .attr('alignment-baseline', 'middle');
    }

    private configureSVG() {
        this.svg = d3
          .select(this.svgElement.nativeElement)
          .attr('height', this.height)
          .attr('width', '100%')
          .attr('id', 'movementChart') as d3.Selection<SVGElement, unknown, null, undefined>;

        this.svgInner = this.svg
          .append('g')
          .attr('id', 'movementChartContent')
          .style('transform', 'translate(' + this.margin.toString() + 'px, ' + this.margin.toString() + 'px)');
    }

    private initializeChart(data: IPursuitSaccadesCamMessage[]): void {
        d3.select('#saccadeTestResultsTable').style('display', 'block');
        d3.select('#movementChartContent').select('#y-axisAngle').remove();
        d3.select('#movementChartContent').select('#x-axis').remove();

        const domainValues = d3.extent(this.calibratedFrames, d => d.calibrationAngleOS).reverse();
        const extremumValue = Math.abs(domainValues[0]) > Math.abs(domainValues[1])
          ? Math.abs(domainValues[0])
          : Math.abs(domainValues[1]);

        this.yScaleAngle = d3
          .scaleLinear()
          .domain([extremumValue, -extremumValue])
          .range([0, this.height - 2 * this.margin]);

        this.yScaleStimuli = d3
          .scaleLinear()
          .domain(d3.extent(this.calibratedFrames, d => d.stimuliOS).reverse())
          .range([0, this.height - 2 * this.margin]);

        const angleAxisY = this.svgInner
          .append('g')
          .attr('id', 'y-axisAngle')
          .style('transform', 'translate(' + this.margin.toString() + 'px, 0)');

        this.xScale = d3
          .scaleLinear()
          .domain([0, d3.max(data, d => d.pointX)]);

        const timeAxisX = this.svgInner
          .append('g')
          .attr('id', 'x-axis')
          .style('transform', 'translate(0, ' + (this.height - 2 * this.margin).toString() + 'px)');

        this.svgInner = this.svgInner
          .append('g')
          .attr('id', 'movementChartPoints');

        const width = this.svgElement.nativeElement.getBoundingClientRect().width
          ? this.svgElement.nativeElement.getBoundingClientRect().width
          : this.width;

        this.xScale.range([this.margin, width - 2 * this.margin]);

        const xAxis = d3
          .axisBottom(this.xScale);

        timeAxisX
          .call(xAxis)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        timeAxisX
          .select('.domain')
          .attr('stroke', AXIS_COLOR);

        const yAxisAngle = d3
          .axisLeft(this.yScaleStimuli)
          .ticks(COUNT_OF_DEGREES_TICKS)

        angleAxisY
          .call(yAxisAngle)
          .attr('stroke', TICKS_COLOR)
          .attr('font-size', TICKS_FONT_SIZE);

        angleAxisY
          .select('.domain')
          .attr('stroke', AXIS_COLOR);
    }

    private drawMovementData(data: IPursuitSaccadesCamMessage[]): void {
        const verticalFramesCount = data.findIndex(f => f.stimuliOSx !== 0);

        const verticalPoints: [number, number][] = data.slice(0, verticalFramesCount - 1).map(d => [
          this.xScale(d.pointX),
          this.yScaleAngle(d.calibrationAngleOS),
        ]);

        this.lastPoint = verticalPoints[verticalPoints.length - 1];

        this.drawLineOnChart(verticalPoints, { id: 'line', color: VERTICAL_LINE_COLOR });

        const horizontalPoints: [number, number][] = data.slice(verticalFramesCount).map(d => [
          this.xScale(d.pointX),
          this.yScaleAngle(d.calibrationAngleOS),
        ]);

        horizontalPoints.unshift(this.lastPoint);

        this.drawLineOnChart(horizontalPoints, { id: 'line', color: HORIZONTAL_LINE_COLOR });
    }

    private drawLineOnChart(points: [number, number][],
    lineStyle: ILine): void {
        const line = d3
          .line()
          .x(d => d[0])
          .y(d => d[1])
          .curve(d3.curveMonotoneX);

        this.svgInner
          .append('path')
          .attr('id', lineStyle.id)
          .attr('d', line(points))
          .style('fill', 'none')
          .style('stroke', lineStyle.color)
          .style('stroke-width', '2px');
    }

    private drawGreenLines(data: IPursuitSaccadesCamMessage[], type: MOVEMENT_TYPE) {
        while (data.length > 1) {
            const startIndex = data.findIndex(f => f.calibrationGlintData);
            const slicedFrames = data.slice(startIndex);
            let endIndex = slicedFrames
              .findIndex(f => f.calibrationGlintData?.calibationGlintData !== slicedFrames[0].calibrationGlintData?.calibationGlintData)
              + startIndex;

            if (endIndex < startIndex) {
                endIndex = data.length - 1;
            }

            const greenLinePoints: [number, number][] = data
              .slice(startIndex, endIndex)
              .map(d => [
                  this.xScale(d.pointX),
                  this.yScaleAngle(data[endIndex - 1].calibrationAngleOS)
              ]);

            this.drawLineOnChart(greenLinePoints, { id: 'greenLine', color: 'green' });

            data.splice(0, endIndex);
        }
    }

    private drawCalibrationDots(data: IPursuitSaccadesCamMessage[], testResults: ISaccadeResult[], type: MOVEMENT_TYPE) {
        const radius = 5;

        testResults = testResults.filter(f => f.type !== RANGE_TYPE.AVERAGE);

        while (data.length > 1) {
            const startIndex = data.findIndex(f => f.calibrationGlintData);
            const slicedFrames = data.slice(startIndex);
            let endIndex = slicedFrames
              .findIndex(f => f.calibrationGlintData?.calibationGlintData !== slicedFrames[0].calibrationGlintData?.calibationGlintData)
              + startIndex;

            const firstSaccadeIndex = data.slice(startIndex)
              .findIndex(f => f.pupilvelocity > TRESHOLD_VALUE) + startIndex;

            const lastSaccadeIndex = data.slice(firstSaccadeIndex)
              .findIndex(f => f.pupilvelocity < TRESHOLD_VALUE) + firstSaccadeIndex;

            if (startIndex === -1) { break; }

            if (endIndex < startIndex) {
                endIndex = data.length - 1;
            }
              
            this.svgInner
              .append('circle')
              .attr('cx', this.xScale(data[lastSaccadeIndex].pointX))
              .attr('cy', this.yScaleAngle(data[lastSaccadeIndex].calibrationAngleOS))
              .attr('r', radius)
              .attr('fill', 'violet');

            this.yScalePoint = this.yScaleAngle(data[startIndex].calibrationAngleOS);

            this.svgInner
              .append('circle')
              .attr('cx', this.xScale(data[startIndex].pointX))
              .attr('cy', this.yScalePoint)
              .attr('r', radius)
              .attr('fill', 'red');

            this.yScalePoint = this.yScaleAngle(data[endIndex - 1].calibrationAngleOS);

            this.svgInner
              .append('circle')
              .attr('cx', this.xScale(data[endIndex].pointX))
              .attr('cy', this.yScalePoint)
              .attr('r', radius)
              .attr('fill', 'red');

            const index = this.counter;

            this.yScalePoint = this.yScaleAngle(data[lastSaccadeIndex].calibrationAngleOS);

            const result = testResults[index].result === SACCADE_RESULT.ACCEPT
              ? `L ${testResults[index].latency}ms \n A ${testResults[index].amplitude}%`
              : `${testResults[index].result}`;

            this.svgInner
              .append('text')
              .attr('x', this.xScale(data[lastSaccadeIndex].pointX))
              .attr('y', this.yScalePoint - 10)
              .style('text-anchor', 'middle')
              .style('font-weight', 'bold')
              .style('font-size', '12px')
              .attr('fill', 'white')
              .text(result);

            data.splice(0, endIndex);

            this.counter++;
        }
        this.counter = 0;
    }

    onFocused(focusedElement: number): void {
        this.focusedControl = focusedElement;
        this.controls.find(e => e.focus === focusedElement).elementRef.nativeElement.focus();
    }
}
