import { SmartImage } from '../../../../common/models/smartImage.class';
import { FileService } from '../_services/general/file.service';
import { RegisterEditFieldFrontend } from './registerEditFieldFrontend.class';

export class SmartImageFrontend extends SmartImage {
    remark = new RegisterEditFieldFrontend<string>();
    constructor() {
        super();
    }
    public isDownloaded(): boolean {
        return !!this.imageUrl;
    }
    public async download(fileService: FileService): Promise<void> {
        try {
            if (this.isDownloaded()) return;
            if (this.isEmpty()) return;
            this.imageUrl = await fileService.getFileContentByFileID(this.imageID);
        } catch (error) {
            console.log(error);
        }
    }
    public get imageContent(): string {
        return this.imageUrl.slice(4, this.imageUrl.length - 1);
    }
    public async getRatio(): Promise<number> {
        return new Promise<number>(res => {
            if (!this.imageUrl) res(null);
            let img = new Image();
            img.onload = () => {
                let height = img.height;
                let width = img.width;
                res(width / height);
            };
            img.src = this.imageUrl;
        });
    }
    public async saveToDb(fileService: FileService): Promise<void> {
        try {
            if (this.imageUrl && !this.imageID) {
                this.imageID = await fileService.saveFile(this.imageUrl);
            }
        } catch (error) {
            throw error;
        }
    }
    public async update(fileService: FileService): Promise<void> {
        try {
            if (this.imageUrl && this.imageID) {
                const oldID: string = this.imageID,
                    responce: { newID: string } = await fileService.updateFile(oldID, this.imageUrl).toPromise();
                this.imageID = responce.newID;
            } else {
                throw new Error();
            }
        } catch (error) {
            throw error;
        }
    }
    public async rotate90deg(): Promise<void> {
        return new Promise<void>(res => {
            const image = new Image();
            image.onload = async () => {
                const canvas = document.createElement('canvas'),
                    ctx = canvas.getContext('2d');
                canvas.width = image.height;
                canvas.height = image.width;
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate(Math.PI / 2);
                ctx.drawImage(image, -image.width / 2, -image.height / 2);
                this.imageUrl = `url(${canvas.toDataURL()})`;
                res();
            };
            image.src = this.imageContent;
        });
    }
}
