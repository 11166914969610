import { TestRemarkFrontend } from '../../testRemarkFrontend';
import { LensometryMeasuredDataFrontend } from '../../../measuredData/NIDEK/LM7P/lensometryDataFrontend.class';

export class LensometryTestRemarksFrontend extends TestRemarkFrontend {
    public measuredDataType: typeof LensometryMeasuredDataFrontend = LensometryMeasuredDataFrontend;
    public measurements: LensometryMeasuredDataFrontend[];
    constructor() {
        super();
    }
}
