// @ts-nocheck
export function applyFont(t: any) {
    t.style('font-family', 'DM Sans');
}

export function axisStyle(t: any) {
    applyFont(t);
    t.selectAll('text').style('font-size', `${16}px`);
}

export function applyFont2(t: any) {
    applyFont(t);
    t.style('font-size', `${12}px`)
    t.style('font-weight', 700)
}
