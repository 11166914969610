import { Injectable } from '@angular/core';
import { IPursuitSaccadesCamMessage, SeparatedFrames } from '../../../../../../../common/interfaces/pursuitSaccadesTestMessage.interface';
import { ISmoothPursuitTestCamMessage } from '../../../../../../../common/interfaces/smoothPursuitTestMessage.interface';

const INDEX_FOR_CHECK = 5;

@Injectable()
export class SaccadeParsingService {

    private firstTimestamp: number;

    public parseFrames(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] | ISmoothPursuitTestCamMessage[] {

        if (frames[0].message_type === 0) {
            frames.shift();
        }
        if (frames[frames.length - 1].message_type === 1) {
            frames.pop();
        }
        
        frames.forEach((frame) => {
            frame.seconds = this.convertTimestampToSeconds(frame.timestamp);

            this.firstTimestamp = this.firstTimestamp ?? frame.seconds;

            frame.pointX = frame.seconds - this.firstTimestamp;
        });
        
        this.firstTimestamp = null;

        return frames;
    }

    public parseRealTimeFrames(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] {
        if (frames[0].message_type === 0) {
            frames.shift();
        }
        
        frames.forEach((frame) => {
            frame.seconds = this.convertTimestampToSeconds(frame.timestamp);

            this.firstTimestamp = this.firstTimestamp ?? frame.seconds;

            frame.pointX = frame.seconds - this.firstTimestamp;
        });
        
        return frames;
    }

    public convertTimestampToSeconds(timestamp: number) {
        return new Date(timestamp/1e4).getTime() / 1000;
    }

    // remove elements when patient blinks or maybe it was flash
    public removeZeroElements(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] {
        if (frames.length === 0 ) { return frames; }

        for (let i = frames.length - 1; i >= 0; --i) {
            if (frames[i].eyeOSx === 0 || frames[i].eyeOSx === 1000 || frames[i].eyeOSy === 0 || frames[i].eyeOSy === 1000)
                frames.splice(i, 1)
            else
                frames[i].pointY = frames[i].stimuliOSy;
        }

        return frames;
    }

    public separateFrames(frames: IPursuitSaccadesCamMessage[]): SeparatedFrames {
        const index = frames.findIndex(f => f.stimuliOSy !== 0);
        const endIndex = frames.slice(index).findIndex(f => f.stimuliOSy === 0) + index;

        const separatedFrames: SeparatedFrames = {
            verticalFrames: frames.splice(0, endIndex + 1),
            horizontalFrames: frames
        };

        return separatedFrames;
    }

    public tuneHorizontalFrames(horizontalFrames: IPursuitSaccadesCamMessage[]) {
        horizontalFrames.forEach(frame => {
            frame.stimuliOS = frame.stimuliOSx;
            frame.eyeOS = frame.eyeOSx;
        });
    }

    public tuneVerticalFrames(verticalFrames: IPursuitSaccadesCamMessage[]) {
        verticalFrames.forEach(frame => {
            frame.stimuliOS = frame.stimuliOSy;
            frame.eyeOS = frame.eyeOSy;
        });
    }

    public removeHorizontalFrames(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] {
        const firstHorizontalIndex = frames.findIndex(f => f.stimuliOSx !== 0);
        const count = frames.length - firstHorizontalIndex;
        frames.splice(firstHorizontalIndex, count);

        return frames;
    }

    public removeVerticalFrames(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] {
        const verticalFramesCount = frames.findIndex(f => f.stimuliOSx !== 0);
        if (verticalFramesCount === -1) {
            return [];
        }
        else {
            frames.splice(0, verticalFramesCount - 1);
            return frames;
        }
    }

    public tuneFrames(frames: IPursuitSaccadesCamMessage[]): IPursuitSaccadesCamMessage[] {
        if (frames.length === 0) { return frames; }
        const points: IPursuitSaccadesCamMessage[] = []

        frames.forEach((frame, index) => {
          if (index % INDEX_FOR_CHECK == 0 && index != 0) {
            const lastFrames = frames.slice(index - INDEX_FOR_CHECK, index);

            const maxVelocityFrame = lastFrames
              .reduce((max, obj) => (max.pupilvelocity > obj.pupilvelocity) ? max : obj);
            points.push(maxVelocityFrame);
          }
        });

        points.push(frames[frames.length - 1]);

        return points;
    }
}
