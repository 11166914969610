// @ts-nocheck
import * as _ from 'lodash'
import { Props } from './Diagram'

interface IProtocol {
  // constructor(private state: S)
  input(x: unknown): void
}

export abstract class BaseDiagram<S, P extends IProtocol> {
  throttleRefresh = 100; // ms

  // slowRefresh = _.throttle(() => {
  //     this.render();
  // }, 500);

  refresh = _.throttle(() => {
      this.render();
  }, this.throttleRefresh);

  // get p() {
  //   return (this as any).params
  // }

  state!: S
  protocol!: P

  // less abstract...
  constructor(public params: Props) {
    this.reset()
  }
  get p() {
    return this.params
  }
  set p(x: any) {
    this.params = x
  }
  // state = new State()
  // protocol = new Protocol(this.state)

  addData (data: unknown) {
    this.protocol.input(data)
    // this.gotData && this.gotData(data)
  }

  // abstract gotData(data: any): void

  abstract reset(): void
  // clearState() {
    // this.state = new State()
    // this.protocol = new Protocol(this.state)
  // }

  abstract render(): void
}
