import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TestHepler } from '../../../../../../../commonout/classes/testClass.provider';
import { TEST_STAGE } from '../../../../../../../commonout/enum/testStage.enum';
import { ExaminationFrontend } from '../../../_models/examinationFrontend.class';
import { TestService } from '../../../_services/examination/test.service';
import { ConfigService } from '../../../_services/general/config.service';

@Component({
    selector: 'tests-order',
    template: require('./tests-order.component.html'),
    styles: [require('./tests-order.component.scss')],
})
export class TestsOrderComponent implements OnInit, OnDestroy {
    @Input() examinations: Subject<ExaminationFrontend[]>;
    @Output() presentTests = new EventEmitter<TestHepler[]>();
    private subscribtions: Subscription[];
    constructor(private configService: ConfigService, private testService: TestService) {
        this.subscribtions = [];
    }
    ngOnInit(): void {
        this.subscribtions.push(
            combineLatest([this.examinations, this.configService.isDevTestsShown.pipe(distinctUntilChanged())]).subscribe(([examinations, isDevTestsShown]) => {
                let tests: TestHepler[] = [];
                if (examinations && examinations.length > 0) {
                    examinations.forEach((examination) => {
                        examination.tests.forEach((test) => {
                            if (!tests.some((t) => t.type === test.type && t.device === test.device)) {
                                tests.push({
                                    device: test.device,
                                    name: test.name,
                                    type: test.type,
                                    stage: test.stage,
                                });
                            }
                        });
                    });
                } else {
                    this.configService.connectedEnvironment.value
                        .filter((d) => d.isConnected)
                        .forEach((d) => {
                            tests = tests.concat(this.testService.getTestsByDevice(d.type));
                        });
                }
                if (!isDevTestsShown) tests = tests.filter((t) => t.stage === TEST_STAGE.SHOWN);
                tests.sort((t1, t2) => {
                    return ExaminationFrontend.deviceSorter(t1, t2) || ExaminationFrontend.sorterByStage(t1, t2) || ExaminationFrontend.customSorter(t1, t2);
                });
                this.presentTests.emit(tests);
            })
        );
    }
    ngOnDestroy(): void {
        this.subscribtions.forEach((s) => s.unsubscribe());
    }
}
