import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IGrade } from '../../../../../../../../commonout/interfaces/grade.interface';
import { TestService } from '../../../../_services/examination/test.service';
import { ModalService } from '../../../../_services/general/modal.service';

@Component({
    selector: 'test-feedback',
    template: require('./test-feedback.component.html'),
    styles: [require('./test-feedback.component.scss')],
})
export class TestFeedbackComponent implements OnInit, OnDestroy {
    @HostBinding('class.isVisible') isVisible: boolean;
    public form: FormGroup;
    private recordingGrades: any[] = [1, 2, 3, 4, 5, "Don't know", 'Not relevant', 'Just testing'];
    private clinicalValueGrades: any[] = ['Misleading', 'Not helpful', 'Helpful', 'High', 'Very high', 'Doubtful value', "Don't know", 'Not relevant'];
    private payload: IGrade;
    private subscription: Subscription[] = [];
    public isDelayed: boolean = false;
    public timer: any;

    constructor(private modalService: ModalService, private testService: TestService, private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            recordingGrade: '',
            clinicalValueGrade: '',
            comment: '',
        });
        this.subscription.push(
            this.modalService.showFeedbackSignalSource$.subscribe(payload => {
                this.payload = payload;
                this.form.setValue({
                    recordingGrade: this.payload.recordingGrade,
                    clinicalValueGrade: this.payload.clinicalGrade,
                    comment: this.payload.comment,
                });
                if (!this.payload._id) {
                    setTimeout(() => {
                        this.isVisible = true;
                    }, 2000);
                } else {
                    this.isVisible = true;
                }
            })
        );
        this.modalService.feedbackDoneSignal.subscribe(() => {
            this.payload = null;
            this.isVisible = false;
            this.form.reset();
        });
    }

    ngOnDestroy() {
        this.subscription.map(s => s.unsubscribe());
    }

    @HostListener('wheel', ['$event']) onWindowScroll(event: any) {
        if (event.deltaY < 0) {
            this.modalService.feedbackScrollEvent.next('up');
        } else if (event.deltaY > 0) {
            this.modalService.feedbackScrollEvent.next('down');
        }
    }

    public async save(): Promise<void> {
        const formValue: {
            recordingGrade: string;
            clinicalValueGrade: string;
            comment: string;
        } = this.form.getRawValue();
        this.payload.recordingGrade = formValue.recordingGrade;
        this.payload.clinicalGrade = formValue.clinicalValueGrade;
        this.payload.comment = formValue.comment;
        const newGrades: IGrade[] = await this.testService.provideFeedback(this.payload).toPromise();
        this.modalService.feedbackDoneSignal.next(newGrades);
    }
    public delay(): void {
        this.isDelayed = true;
        this.timer = setTimeout(() => {
            this.isDelayed = false;
        }, 30000);
    }
    public restore(): void {
        clearTimeout(this.timer);
        this.isDelayed = false;
    }
    clear(): void {
        this.form.get('clinicalValueGrade').setValue('', { emitEvent: false });
        this.form.get('recordingGrade').setValue('', { emitEvent: false });
    }
}
