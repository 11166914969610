import { AcaMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/NT530P/acaData.model';
import * as jsPDF from 'jspdf';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';
import { CellHookHandler } from 'jspdf-autotable';
import { AcaTestRemarksFrontend } from '../../../test-remarks/NIDEK/NT530P/acaTestRemarksFrontend.class';
import { SmartImageFrontend } from '../../../smartImageFrontend.class';

export class AcaMeasuredDataFrontend extends AcaMeasuredData implements MeasuredDataFrontend {
    OD: SmartImageFrontend;
    OS: SmartImageFrontend;
    public remarks: AcaTestRemarksFrontend;
    constructor() {
        super();
        this.OD = new SmartImageFrontend();
        this.OS = new SmartImageFrontend();
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        let table = [];
        table.push({
            OD: { content: 'OD', styles: { halign: 'center' } },
            OS: { content: 'OS', styles: { halign: 'center' } },
        });

        await this.downloadMediaData(fileService);

        if (!this.OD.isEmpty() || !this.OS.isEmpty()) {
            let ratio: number = (await this.OD.getRatio()) || (await this.OS.getRatio());
            table.push({
                OD: {
                    content: '',
                    styles: {
                        minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.left) / ratio,
                        minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.left,
                    },
                },
                OS: {
                    content: '',
                    styles: {
                        minCellHeight: (document.internal.pageSize.getWidth() / 2 - pager.margin.right) / ratio,
                        minCellWidth: document.internal.pageSize.getWidth() / 2 - pager.margin.right,
                    },
                },
            });
        }
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: <CellHookHandler>((data) => {
                if (this.OD.imageUrl && data.section === 'body' && data.row.index === 1 && data.column.dataKey === 'OD') {
                    document.addImage(this.OD.imageUrl, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                } else if (this.OS.imageUrl && data.section === 'body' && data.row.index === 1 && data.column.dataKey === 'OS') {
                    document.addImage(this.OS.imageUrl, 'JPEG', data.cell.x + 1, data.cell.y + 1, data.cell.width - 2, data.cell.height - 2);
                }
            }),
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        if (!this.OD.isEmpty()) {
            formattedTest.OD.images.push(this.OD.imageUrl);
        }
        if (!this.OS.isEmpty()) {
            formattedTest.OS.images.push(this.OS.imageUrl);
        }
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        try {
            await Promise.all([this.OD.download(fileService), this.OS.download(fileService)]);
        } catch (error) {
            console.error(error);
        }
    }
}
