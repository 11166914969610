import { IpdNidekTonorefIIITest } from '../../../../../../../../commonout/classes/tests/NIDEK/TonorefIII/ipd-nidek-TonoferIIITest';
import { applyMixins } from '../../../../../../../../commonout/mixin';
import { TEST_RESULT_TYPE } from '../../../../../../../common/enums/test-result-type.enum';
import { IpdNidekTonorefIIITestRemarksComponent } from '../../../../_components';
import { IpdNidekTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/ipdNidekTonorefIIITestRemarksFrontend.class';
import { TestFrontend } from '../../testFrontend.class';

export class IpdNidekTonorefIIITestFrontend extends IpdNidekTonorefIIITest {
    remarks: IpdNidekTonorefIIITestRemarksFrontend;
    public readonly remarksComponent: typeof IpdNidekTonorefIIITestRemarksComponent = IpdNidekTonorefIIITestRemarksComponent;
    public readonly possible_results: string[] = ['Normal', 'Abnormal'];
    public readonly result_type: TEST_RESULT_TYPE = TEST_RESULT_TYPE.NONE;
    constructor() {
        super();
        this.remarks = new IpdNidekTonorefIIITestRemarksFrontend();
    }
}

export interface IpdNidekTonorefIIITestFrontend extends TestFrontend {}
applyMixins(IpdNidekTonorefIIITestFrontend, [TestFrontend]);
