import * as jsPDF from 'jspdf';
import { KeratometryArk1sMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/ARK1S/keratometry-nidek-ark1s.measuredData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { KeratometryArk1sTestRemarksFrontend } from '../../../test-remarks/NIDEK/ARK1S/keratometryArk1sTestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class KeratometryArk1sMeasuredDataFrontend extends KeratometryArk1sMeasuredData implements MeasuredDataFrontend {
    public remarks: KeratometryArk1sTestRemarksFrontend;
    public R1: {
        OD: {
            mm: RegisterEditFieldFrontend<string>;
            diopter: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
        };
        OS: {
            mm: RegisterEditFieldFrontend<string>;
            diopter: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
        };
    };
    public R2: {
        OD: {
            mm: RegisterEditFieldFrontend<string>;
            diopter: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
        };
        OS: {
            mm: RegisterEditFieldFrontend<string>;
            diopter: RegisterEditFieldFrontend<string>;
            axis: RegisterEditFieldFrontend<string>;
        };
    };
    constructor() {
        super();
        this.R1 = {
            OD: {
                mm: new RegisterEditFieldFrontend(),
                diopter: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
            },
            OS: {
                mm: new RegisterEditFieldFrontend(),
                diopter: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
            },
        };
        this.R2 = {
            OD: {
                mm: new RegisterEditFieldFrontend(),
                diopter: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
            },
            OS: {
                mm: new RegisterEditFieldFrontend(),
                diopter: new RegisterEditFieldFrontend(),
                axis: new RegisterEditFieldFrontend(),
            },
        };
    }
    public async toPDF(document: jsPDF, pager: Pager): Promise<void> {
        let table = [];
        table.push({
            Rs: '',
            ODRmm: { colSpan: 3, content: 'OD', styles: { halign: 'center' } },
            ODKd: '',
            ODAxisD: '',
            OSRmm: { colSpan: 3, content: 'OS', styles: { halign: 'center' } },
            OSKd: '',
            OSAxisD: '',
        });
        table.push({
            Rs: '',
            ODRmm: { content: 'R mm' },
            ODKd: { content: 'K (diopter)' },
            ODAxisD: { content: 'Axis (degree)' },
            OSRmm: { content: 'R mm' },
            OSKd: { content: 'K (diopter)' },
            OSAxisD: { content: 'Axis (degree)' },
        });
        table.push({
            Rs: { content: 'R1' },
            ODRmm: { content: this.R1.OD.mm.value },
            ODKd: { content: this.R1.OD.diopter.value },
            ODAxisD: { content: this.R1.OD.axis.value },
            OSRmm: { content: this.R1.OS.mm.value },
            OSKd: { content: this.R1.OS.diopter.value },
            OSAxisD: { content: this.R1.OS.axis.value },
        });
        table.push({
            Rs: { content: 'R2' },
            ODRmm: { content: this.R2.OD.mm.value },
            ODKd: { content: this.R2.OD.diopter.value },
            ODAxisD: { content: this.R2.OD.axis.value },
            OSRmm: { content: this.R2.OS.mm.value },
            OSKd: { content: this.R2.OS.diopter.value },
            OSAxisD: { content: this.R2.OS.axis.value },
        });
        (document as any).autoTable({
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didParseCell: (data: any) => {},
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OD.values =
            'R1-' +
            (this.R1.OD.axis.value || 'x') +
            '/' +
            (this.R1.OD.diopter.value || 'x') +
            '/' +
            (this.R1.OD.mm.value || 'x') +
            ' R2-' +
            (this.R2.OD.axis.value || 'x') +
            '/' +
            (this.R2.OD.diopter.value || 'x') +
            '/' +
            (this.R2.OD.mm.value || 'x');

        formattedTest.OS.values =
            'R1-' +
            (this.R1.OS.axis.value || 'x') +
            '/' +
            (this.R1.OS.diopter.value || 'x') +
            '/' +
            (this.R1.OS.mm.value || 'x') +
            ' R2-' +
            (this.R2.OS.axis.value || 'x') +
            '/' +
            (this.R2.OS.diopter.value || 'x') +
            '/' +
            (this.R2.OS.mm.value || 'x');
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {}
}
