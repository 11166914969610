import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { CALIBRATION_COMMAND, CALIBRATION_TEST } from '../../../../../../../../commonout/enum/calibration.command.enum';
import { BulbicamService } from '../../../../_services/examination/bulbiCam.service';
import { SocketService } from '../../../../_services/general/socket.service';
import { CalibrationTestComponent } from '../calibration-test-component';
import { CalibrationChartService } from '../calibrationService';
import { mockDataTest5 } from '../mockData';
import { ISensorCalibration, ISensorValue } from '../../../../../../../../commonout/interfaces/calibration/sensorCalibration.interface';
import { concatMap } from 'rxjs/operators';
import { ICalibrationResult } from '../../../../../../../../commonout/interfaces/calibration/calibrationData.interface';
import { ConfigService } from '../../../../_services/general/config.service';

@Component({
    selector: 'sensor-test',
    template: require('./sensor-test.component.html'),
    styles: [require('./sensor-test.component.scss')],
})
export class SensorTestComponent extends CalibrationTestComponent implements AfterViewInit {
    customOnDestroy(): void {}

    calibrationType: CALIBRATION_TEST = CALIBRATION_TEST.SENSOR_TEST;
    redColor = '#EB5757';
    yellowColor = '#F2C94C';
    blueColor = '#2D9CDB';

    osFrontColor = '#bb0f6d';
    osMidColor = '#00b031';
    osRearColor = '#d5d939';

    odRearColor = '#e48932';
    odMidColor = '#00dcdb';
    odFrontColor = '#e60527';

    width: number;
    height: number;
    selectedIndex = -1;
    async messageHandler(data: any): Promise<void> {
        if (data.message_type === CALIBRATION_COMMAND.START) {
            this.unSavedResult = {
                createdAt: +new Date,
                result: {
                    calibrations: []
                }
            }
        }
        
        if (data.message_type === CALIBRATION_COMMAND.DATA_PACKAGE) {
            this.unSavedResult.result.calibrations.push(
                {
                    xPoint: data?.x,
                    yFrontPoint: data?.y1,
                    yMidPoint: data?.y2,
                    yRearPoint: data?.y3,
                    eye: data?.eye
                }
            );
        }

        if (data.message_type === CALIBRATION_COMMAND.STOP) {
            this.socketService.socket.off(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);

            const [odSubArray, osSubArray] = [
                this.unSavedResult.result.calibrations.filter(d => d.eye === 0),
                this.unSavedResult.result.calibrations.filter(d => d.eye === 1),
            ];
            const [
                odRearHalfThreshold, odMidHalfThreshold, odFrontHalfThreshold,
                osRearHalfThreshold, osMidHalfThreshold, osFrontHalfThreshold,
            ] = [
                this.calculateHalfThreshold(this.unSavedResult.result.calibrations, 0, 'yRearPoint'),
                this.calculateHalfThreshold(this.unSavedResult.result.calibrations, 0, 'yMidPoint'),
                this.calculateHalfThreshold(this.unSavedResult.result.calibrations, 0, 'yFrontPoint'),
                this.calculateHalfThreshold(this.unSavedResult.result.calibrations, 1, 'yRearPoint'),
                this.calculateHalfThreshold(this.unSavedResult.result.calibrations, 1, 'yMidPoint'),
                this.calculateHalfThreshold(this.unSavedResult.result.calibrations, 1, 'yFrontPoint'),
            ];

            this.unSavedResult = {
                ...this.unSavedResult,
                result: {
                    calibrations: this.unSavedResult.result.calibrations,
                    odRearHalfThreshold, odMidHalfThreshold, odFrontHalfThreshold,
                    osRearHalfThreshold, osMidHalfThreshold, osFrontHalfThreshold,
                    osSampleSize: osSubArray.length,
                    odSampleSize: odSubArray.length,
                },
                isTemp: true
            }

            this.results.unshift(this.unSavedResult);
    
            this.drawChart(0);

            this.isTestRunning = false;
            this.isTestDone = true;
        }
    }
    isTestRunning = false;
    isTestDone = false;

    subscriptions: Subscription[] = [];

    mockData: {
        date: Date;
        os: { halfThreshold: number; ss: number };
        od: { halfThreshold: number; ss: number };
    }[] = mockDataTest5;
    mockChartData: { timestamp: number; rawValue: number }[];

    @ViewChild('svgChart') svgChart: ElementRef;

    results: {
        createdAt: number,
        result: ISensorCalibration,
        isTemp?: boolean;
    }[] = [];

    unSavedResult: {
        createdAt: number,
        result: ISensorCalibration,
        isTemp?: boolean,
    } | null = null;

    margin = 20;

    svg: any;
    svgInner: any;

    yScale: any;
    xScale: any;
    yAxis: any;
    xAxis: any;

    constructor(bulbicamService: BulbicamService, socketService: SocketService, private service: CalibrationChartService, configService: ConfigService) {
        super(bulbicamService, socketService, configService);
        const sub = this.bulbicamService.getCalibrationSettings(CALIBRATION_TEST.SENSOR_TEST).subscribe(res => {
            this.results = res.map((el: ICalibrationResult<ISensorCalibration>) => {                
                return {
                    createdAt: el.createdAt,
                    result: {
                        ...el.results
                    }
                }
            }).sort((a, b) => b.createdAt - a.createdAt);
        });
        this.subscriptions.push(sub);
    }

    ngAfterViewInit(): void {
        this.width = this.svgChart.nativeElement.getBoundingClientRect().width;
        this.height = this.svgChart.nativeElement.getBoundingClientRect().height;
        this.initChart(this.width, this.height);
    }

    initChart(width: number = 300, height: number): void {
        this.svg = d3
            .select(this.svgChart?.nativeElement)
            .attr('id', 'svgtest')
            .attr('width', width)
            .attr('height', height);

        this.svgInner = this.svg.append('svg').style('transform', `translate(${this.margin}px, ${this.margin}px)`);

        this.yScale = d3
            .scaleLinear()
            .domain([0.1, 0])
            .range([2 * this.margin, height - this.margin]);

        this.xScale = d3
            .scaleLinear()
            .domain([0, 1])
            .range([2 * this.margin, width - 2 * this.margin]);

        this.yAxis = this.svgInner
            .append('g')
            .attr('id', 'y-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(${2 * this.margin}px, -20px)`);

        this.xAxis = this.svgInner
            .append('g')
            .attr('id', 'x-axis')
            .attr('class', 'axis-grey')
            .attr('stroke', 'grey')
            .style('transform', `translate(0px, ${height - 2 * this.margin}px)`);

        const yAxis = d3.axisLeft(this.yScale);
        const xAxis = d3.axisBottom(this.xScale);

        this.yAxis.call(yAxis);
        this.xAxis.call(xAxis);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`RGB`)
            .attr('dx', this.xScale(1))
            .attr('dy', this.yScale(0))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(0px, 15px)`);

        this.svgInner
            .append('text')
            .attr('id', 'text-label')
            .text(`Sensor Response`)
            .attr('dx', this.xScale(0))
            .attr('dy', this.yScale(0.1))
            .attr('stroke', 'white')
            .attr('stroke-width', '1px')
            .attr('font-size', '12px')
            .style('transform', `translate(-25px, -30px)`);
    }

    drawChart(index: number): void {
        d3.selectAll('#line').remove();
        d3.selectAll('#haflThresholdCircle').remove();

        const lineDefined = d3.line().defined(function(d) {
            return d[1] !== null;
        });

        const result = this.results[index].result;

        this.yScale = d3
            .scaleLinear()
            .domain([
                Math.max(
                    Math.max(...result.calibrations.map(d => d.yRearPoint)),
                    Math.max(...result.calibrations.map(d => d.yMidPoint)),
                    Math.max(...result.calibrations.map(d => d.yFrontPoint)),
                ),
                0
            ])
            .range([2 * this.margin, this.height - this.margin]);

        const yAxis = d3.axisLeft(this.yScale);

        this.yAxis.call(yAxis);

        this.xScale = d3
            .scaleLinear()
            .domain([0, Math.max(...result.calibrations.map(d => d.xPoint))])
            .range([2 * this.margin, this.width - 2 * this.margin]);

        const xAxis = d3.axisBottom(this.xScale);

        this.xAxis.call(xAxis);

        const odRearPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 0).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yRearPoint)];
        });

        const odMidPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 0).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yMidPoint)];
        });

        const odFrontPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 0).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yFrontPoint)];
        });

        const osRearPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 1).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yRearPoint)];
        });

        const osMidPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 1).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yMidPoint)];
        });

        const osFrontPoints: [number, number][] = this.results[index].result.calibrations.filter(d => d.eye === 1).map(d => {
            return [this.xScale(d.xPoint), this.yScale(d.yFrontPoint)];
        });

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.odRearColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odRearPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.odMidColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odMidPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.odFrontColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(odFrontPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.osRearColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osRearPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.osMidColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osMidPoints));

        this.svgInner
            .append('path')
            .attr('id', `line`)
            .style('fill', 'none')
            .style('stroke', this.osFrontColor)
            .style('stroke-width', '1px')
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('d', lineDefined(osFrontPoints));    
        
        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result.odFrontHalfThreshold.xValue))
            .attr('cy', this.yScale(result.odFrontHalfThreshold.yValue))
            .attr('r', 3)
            .attr('stroke', this.odFrontColor)
            .attr('fill', this.odFrontColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');
        
        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result.odMidHalfThreshold.xValue))
            .attr('cy', this.yScale(result.odMidHalfThreshold.yValue))
            .attr('r', 3)
            .attr('stroke', this.odMidColor)
            .attr('fill', this.odMidColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');
            
        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result.odRearHalfThreshold.xValue))
            .attr('cy', this.yScale(result.odRearHalfThreshold.yValue))
            .attr('r', 3)
            .attr('stroke', this.odRearColor)
            .attr('fill', this.odRearColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');
        
        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result.osFrontHalfThreshold.xValue))
            .attr('cy', this.yScale(result.osFrontHalfThreshold.yValue))
            .attr('r', 3)
            .attr('stroke', this.osFrontColor)
            .attr('fill', this.osFrontColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result.osMidHalfThreshold.xValue))
            .attr('cy', this.yScale(result.osMidHalfThreshold.yValue))
            .attr('r', 3)
            .attr('stroke', this.osMidColor)
            .attr('fill', this.osMidColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');

        this.svgInner
            .append('circle')
            .attr('id', 'haflThresholdCircle')
            .attr('cx', this.xScale(result.osRearHalfThreshold.xValue))
            .attr('cy', this.yScale(result.osRearHalfThreshold.yValue))
            .attr('r', 3)
            .attr('stroke', this.osRearColor)
            .attr('fill', this.osRearColor)
            .style('transform', `translate(0px, ${-this.margin}px)`)
            .attr('stroke-width', '2px');
    }

    toggleTest(): void {

        if (this.isTestRunning) {
            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: CALIBRATION_TEST.SENSOR_TEST,
                    command: CALIBRATION_COMMAND.STOP,
                })
                .subscribe();
            this.subscriptions.push(sub);
        } else {
            this.socketService.socket.on(this.calibrationType + 'CalibrationMessage', this.bindedMessageHandler);

            const sub = this.bulbicamService
                .sendCalibrationCommand({
                    test: CALIBRATION_TEST.SENSOR_TEST,
                    command: CALIBRATION_COMMAND.START,
                })
                .subscribe();
            this.subscriptions.push(sub);
            this.selectedIndex = this.selectedIndex === -1 ? this.selectedIndex : this.selectedIndex + 1;

        }

        this.isTestRunning = !this.isTestRunning;
        this.clearTempResult()

    }

    saveResults(): void {        
        const sub = this.bulbicamService.saveCalibrationSetting({
            test: this.calibrationType,
            createdAt: +new Date(),
            results: {
                ...this.unSavedResult.result
            }
        }).pipe(
            concatMap(_ => {
                return this.bulbicamService.getCalibrationSettings(this.calibrationType)
            })
        ).subscribe(res => {
            this.results = res.map((el: ICalibrationResult<ISensorCalibration>) => {
                return {
                    createdAt: el.createdAt,
                    result: {
                        ...el.results
                    }
                }
                
            }).sort((a, b) => b.createdAt - a.createdAt);
        })
        this.subscriptions.push(sub);

        this.clearTempResult();
        this.isTestDone = false;
    }

    selectActive(index: number): void {
        if (this.results[index]?.isTemp) return;
        this.selectedIndex = index;
        this.drawChart(index);
    }

    clearTempResult() {
        if (this.unSavedResult) {
            this.unSavedResult = null;
            this.results.shift();
            this.selectedIndex--;
        }
    }

    calculateHalfThreshold(array: ISensorValue[], eye: number, side: string): { xValue: number, yValue: number } {
        const filteredArray = array.filter(d => d.eye === eye);
        const halfLength = Math.floor(filteredArray.length / 2) - 1;

        return { xValue: filteredArray[halfLength].xPoint, yValue: filteredArray[halfLength][side] };
    }
}
