// @ts-nocheck
import * as d3 from 'd3'

import { makeLayout } from '../ui/configViewLayout'
import { Target } from '../lib/types'
import { Header } from '../lib/Header'
import { VTable } from '../lib/VTable'
import { AData } from '../lib/AData'
import { AChart2 } from '../lib/AChart'
import { AxisLeft, AxisBottom } from '../lib/Axes'
import { ChartDots } from '../lib/ChartDots'
import { State } from '../helpers/State'
import { Layout } from '../lib/Layout'
import { doTranslate } from '../lib/util'

export const FAR = '6m'
export const NEAR = '40cm'

const RADIUS = 10

interface ConfigParams {
  state: State
    // showConfig: boolean,
    // configActiveSection: number,
    // configValue: any,
    // changeConfigValue: (name: string) => void
  // }
  g: { default: Target }
  refresh: () => void
  // sty
}

export function ConfigView(p: ConfigParams) {
  let delta = ([a, b]: any) => b - a

  let domain = p.state.configActiveSection === 1 ? (
    p.state.configDomain
  ) : (
    p.state.configDomainManual
  )

  let lo = makeLayout({
    dx: delta(domain.od.x),
    dy: delta(domain.od.y),
  })
  let { g } = p

  let F = '[    ]'
  let T = '[✗]'
  // let T = '[✓]'
  let TT = '[●]'
  let FF = '[   ]'
  // let TT = '[○]'

  // Header({
  //   text: `${T} Predefined Points`,
  //   alignLeft: true,

  //   target: g.default,
  //   area: lo.leftHeader,
  // })
  Header({
    text: 'OS',

    target: g.default,
    area: lo.centerHeader,
  })
  Header({
    text: 'OD',

    target: g.default,
    area: lo.rightHeader,
  })

  let isManual = p.state.configActiveSection === 2

  let mark1 = TT
  let mark2 = FF
  if (isManual) {
    mark1 = FF
    mark2 = TT
  }

  let target0 = g.default
  let target1 = g.default
  let target2 = g.default

  let firstColumnNames = [
    'p1up',
    'p1down',
    'p1central',
    'p1left',
    'p1right',
  ]
  let secondColumnNames = [
    'p2up',
    'p2down',
    'p2central',
    'p2left',
    'p2right',
  ]
  let manualColumnNames = [
    'mod',
    'mos',
  ]

  let changeConfigValue = (name: string) => {
    p.state.changeConfigValue(name)
    p.state.doUpdateStartParams()
    p.refresh()
  }

  let c = p.state.configValue
  let makeCell = (t: string, f: string, active: boolean) => (name: string) => {
    return {
      text: c[name] ? t : f,
      onClick: () => {
        // if (!active) return
        changeConfigValue(name)
      }
    }
  }
  let firstColumn = firstColumnNames
    .map(makeCell(T,F, !isManual))
  let secondColumn = secondColumnNames
    .map(makeCell(T,F, !isManual))
  let manualColumn = manualColumnNames
    .map(makeCell(TT,FF, isManual))

  // firstColumn: any = firstColumn.map((x,i) => {
  //   return {
  //     text: x,
  //     onClick: () => {
  //       p.state.changeConfigValue(name)
  //     }
  //   }
  // })

  let radioButton = (name: string, caption: string) => {
    let tick = c[name] ? TT : FF
    let text = `${tick} ${caption}`
    return {
      text,
      onClick: () => {
        changeConfigValue(name)
      }
    }
  }

  let table0 = {
    rowHeaders: [`Stimulus`,'',''],
    columns: [
      { at: 0,  rows: ['',
        radioButton('dot', 'Dot'),
        radioButton('medium', 'Medium'),
      ] },
      { at: 1.15,  rows: ['',
        radioButton('pediatric', 'Pediatric'),
        radioButton('large', 'Large'),
      ] },
    ],
  } as any

  let table1 = {
    rowHeaders: [`${mark1}  Select predefined points`, 'Gaze', 'Up', 'Down', 'Central', 'Left', 'Right'],
    columns: [
      { at: 1,  rows: ['', NEAR, ...firstColumn] },
      { at: 2,  rows: ['', FAR, ...secondColumn] },
    ],
  } as any

  // let table2 = {
  //   rowHeaders: [`${mark2}  Set stimuli manually`, `OD`, `OS`],
  //   columns: [
  //     { at: 1,  rows: ['', ...manualColumn] },
  //   ],
  // } as any

  table1.rowHeaders[0] = {
    text: table1.rowHeaders[0],
    onClick: () => {
      p.state.configActiveSection = 1
      p.state.doUpdateStartParams()
      p.refresh()
    }
  }
  // table2.rowHeaders[0] = {
  //   text: table2.rowHeaders[0],
  //   onClick: () => {
  //     p.state.configActiveSection = 2
  //     p.state.doUpdateStartParams()
  //     p.refresh()
  //   }
  // }

  VTable({
    domainX: [0, 2],
    ...table0,

    alignLeft: true,
    target: target0,
    area: lo.leftZero,
  })

  VTable({
    domainX: [0, 2],
    ...table1,

    target: target1,
    area: lo.leftFirst,
  })

  // VTable({
  //   domainX: [0, 2],
  //   ...table2,

  //   target: target2,
  //   area: lo.leftSecond,
  // })

  let light = target1
  let dark = target2
  if (isManual) {
    light = target2
    dark = target1
  }
  light.node().classList.remove('sLayerBg')
  dark.node().classList.add('sLayerBg')

  let odseries = [] as any
  let ods = { id: 0, dataPoints: [] as any }
  let osseries = [] as any
  let oss = { id: 0, dataPoints: [] as any }
  odseries.push(ods)
  osseries.push(oss)

  let osInteractive = false
  let odInteractive = false
  if (p.state.configActiveSection === 1) {
    Object.entries(p.state.configPredefinedPoints).forEach(([k,y],i) => {
      if (!p.state.configValue[k]) return
      ods.dataPoints.push(y.od)
      oss.dataPoints.push(y.os)
    })
  } else {
    if (p.state.configValue.mod) {
      ods.dataPoints.push(p.state.configManualPoints.od)
      odInteractive = true
    } else {
      oss.dataPoints.push(p.state.configManualPoints.os)
      osInteractive = true
    }
  }

  RenderDots({
    state: p.state,
    g,
    area: lo.centerPart,
    xdomain: domain.os.x,
    ydomain: domain.os.y,
    series: osseries,
    refresh: p.refresh,
    showAxes: isManual,
    interactive: osInteractive,
  })

  RenderDots({
    state: p.state,
    g,
    area: lo.rightPart,
    xdomain: domain.od.x,
    ydomain: domain.od.y,
    series: odseries,
    refresh: p.refresh,
    pinks: true,
    showAxes: isManual,
    interactive: odInteractive,
  })
}

// array [x,y]
type AXY = [number, number] | number[]

interface DotsParams {
  state: State
  g: { default: Target }
  area: Layout
  xdomain: number[]
  ydomain: number[]
  series: { id: number, dataPoints: AXY[] }[]
  refresh: () => void
  pinks?: boolean
  showAxes: boolean,
  interactive: boolean,
}

function RenderDots(p: DotsParams) {
  // if (p.pinks) PinkDots(p)

  let { g, area, series } = p

  let positioning = ([x,y]: AXY) => ({ x, y })
  let aData: any = AData<AXY>({
    series,
    positioning: positioning,
    coloring: () => '',

    xFrom: p.xdomain[0],
    xTo: p.xdomain[1],

    yFrom: p.ydomain[0],
    yTo: p.ydomain[1],
  })

  let ctx = AChart2<AXY>({
    aData,
    target: g.default,
    area,
  })
  AxisLeft({
    ...ctx,
    grid: true,
    enabled: p.showAxes,
    // ticks: 5,

    area,
    target: g.default,
  })
  AxisBottom({
    ...ctx,
    grid: true,
    enabled: p.showAxes,

    area,
    target: g.default,
  })

  ChartDots({
    ...ctx,
    shape: 'dot',

    target: g.default,
    styleParams: {
      theme: '',
      override: {
        dotRadius: RADIUS,
        class: 'fContrast',
      }
    },
  })

  let dragStart = () => {
    if (!p.state.willDrag) return
    let position = d3.mouse(d3.event.currentTarget)
    p.state.dotDragging = {
    }
    draggingPossibly()
  }
  let draggingPossibly = () => {
    let { dotDragging } = p.state
    if (!dotDragging) return
    let pos = d3.mouse(d3.event.currentTarget)
    let x = aData.xScale.invert(pos[0] + area.left)
    let y = aData.yScale.invert(pos[1] + area.top)
    p.state.moveDraggedPoint(x, y)
    p.refresh()
  }
  let dragEnd = () => {
    let { dotDragging } = p.state
    if (!dotDragging) return
    p.state.dotDragging = null
    p.state.doUpdateStartParams()
  }

  let gLayer = g.default
  let klass = 'layer'
  let present = true

  gLayer
    .selectAll(`.${klass}`)
    .data(p.interactive ? [null] : [])
    .join('rect')
    .attr('class', klass)
    .attr('width', area.width())
    .attr('height', area.height())
    .attr('fill', 'transparent')
    // .attr('display', present ? undefined : 'none')
    .on('mousemove', draggingPossibly)
    .on('mousedown', dragStart)
    .on('mouseup', dragEnd)
    .on('mouseleave', dragEnd)
    .call(doTranslate({ x: area.left, y: area.top }))
}

function PinkDots(p: DotsParams) {
  let { g, area } = p

  let series = [{
    id: 0,
    dataPoints: [
      [-5, 0],
      [-15, 0],
    ]
  }]

  let positioning = ([x,y]: AXY) => ({ x, y })
  let aData: any = AData<AXY>({
    series,
    positioning: positioning,
    coloring: () => '',

    xFrom: p.xdomain[0],
    xTo: p.xdomain[1],

    yFrom: p.ydomain[0],
    yTo: p.ydomain[1],
  })

  let ctx = AChart2<AXY>({
    aData,
    target: g.default,
    area,
  })

  ChartDots({
    ...ctx,
    shape: 'dot',

    target: g.default,
    styleParams: {
      theme: '',
      override: {
        dotRadius: RADIUS,
        class: 'fPurple',
      }
    },
  })
}
