// @ts-nocheck
import * as d3 from 'd3';
import * as _ from 'lodash';

import { Target, Layout } from '..';
import {
    doTranslate,
    translate,
    // drawArrow,
    Area,
} from '..';
import { getStyle } from './util';
import { Scale2D } from '../types';
import { Point } from '../Point';
import { Brand } from './Brand';

function drawArrow(a: any) {
    return '';
}

// styling

// let darkFontColor = colors['text-dark'];
// let darkBorderColor = colors['light-blue'];
// applyColor(dark.line, (x) => darkBorderColor);
// applyColor(dark.font, (x) => darkFontColor);
// applyColor(dark.axesText, (x) => '#aaa');

const styleDefault = {
    fontColor: '',
    // fontColor: '#aaa',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: Brand.Font,
    // fontFamily: 'inherit',
    // fontFamily: 'Orbitron',

    lineColor: 'transparent',
    // lineColor: '#aaa',
    // lineColor: Brand.notDark,

    tickSize: 5,
    tickPadding: 5,
    tickWidth: 1,
    opacity: '100%',

    gridOpacity: 0.2,
};

type Style = typeof styleDefault;

const themeOverrides: { [k: string]: Partial<Style> } = {
    bright: {
        fontColor: '#333',
        lineColor: '#333',
    },
};

export enum SCALE_TYPE {
    VERTICAL_SCALE,
    HORIZONTAL_SCALE
}

// interface

type Mapped = any;

export interface Tick {
    value: number;
    text: string;
}
type RawTickFormat = (coordValue: number, pointsIfYouNeedThemToMapBackThatCoordValue: Mapped[]) => string;

interface Params {
    point?: Point;
    ownTicks?: (usedValues: number[], flatData: Mapped[]) => Tick[];
    moveOut?: number;

    ticks?: number;
    // tickFormat?: (a:Mapped) => string
    rawTickFormat?: RawTickFormat;
    grid?: boolean;

    noText?: boolean;
    noTicks?: boolean;

    // from context
    scale: Scale2D;
    flatData: Mapped[];
    enabled?: boolean; // passed anyway from context, can be overwritten
    fromTest?: string;

    arrow?: Arrow;
    arrowReverse?: Arrow;

    target: Target;
    area: Layout;
    styleParams?: {
        theme?: string;
        override?: Partial<Style>;
    };
}
type Arrow = string;

// function

export function AxisLeft(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

    // let area: Area = this.context.get('area');
    // let target: Target = this.context.get('target');
    // let style: Style = this.context.get('style');
    // let scale: Scale2D = this.context.get('scale');
    // let flatData: Mapped[] = this.context.get('flatData');

    let point = p.point ?? p.area.cornerBL();

    if (p.moveOut != null) point = point.dx(-p.moveOut);

    if (p.noText) {
        p.rawTickFormat = () => '';
    }

    let axis = d3
        .axisLeft(p.scale.y)
        .tickFormat((x) => (x.toString().length === 1 ? `${x}.0` : x))
        .tickSize(style.tickSize)
        .tickPadding(style.tickPadding);

    if (p.ticks !== undefined) axis.ticks(p.ticks);

    // let { tickFormat } = this.props
    // if (p.tickFormat) {
    //   axis.tickFormat((numberLike) => {
    //     let point = p.flatData.find(x => x.point.y === numberLike)
    //     if (!point) return ''
    //     if (!p.tickFormat) return ''
    //     return p.tickFormat(point)
    //   })
    // }

    // console.log(scale.y.ticks())
    // if (this.props.useTickValues) {
    //   axis.tickValues(yValues)
    // }

    if (p.rawTickFormat) {
        let fun = p.rawTickFormat as RawTickFormat; // my devenv typescript is a mess
        axis.tickFormat((x) => fun(x.valueOf(), p.flatData));
    }

    if (p.ownTicks) {
        let yValues: number[] = _.uniq(p.flatData.map((x) => x.point.y));

        let ticks = p.ownTicks(yValues, p.flatData);

        // console.log(ticks);

        let textMapping = ticks.reduce((a, x) => ({ ...a, [x.value]: x.text }), {});
        axis.tickValues(ticks.map((x) => x.value));
        axis.tickFormat((x) => textMapping[x.valueOf()] ?? '?');
    }
    // if (this.props.ownTicks) {
    //     let yValues: number[] = _.uniq(flatData.map((x) => x.point.y));
    //     let ticks = this.props.ownTicks(yValues, flatData);
    //     let textMapping = ticks.reduce((a, x) => ({ ...a, [x.value]: x.text }), {});
    //     axis.tickValues(ticks.map((x) => x.value));
    //     axis.tickFormat((x) => textMapping[x.valueOf()] ?? '?');
    // }

    let enabled = p.enabled == null ? true : p.enabled;

    p.target
        .selectAll('.axis-left')
        .data(_.compact([enabled ? 1 : null]))
        .join('g')
        .attr('class', 'axis-left')
        .call(doTranslate(point.xOnly()))
        .call((t: Target) => axis(t))
        .call(styleAxes(style))
        .attr('opacity', style.opacity);

    if (p.grid) {
        axis.tickFormat('' as any);
        axis.tickSize(-p.area.width() - (p.moveOut || 0));
        p.target
            .selectAll('.left-axis-grid')
            .data([null])
            .join('g')
            .attr('class', 'left-axis-grid')
            .call(doTranslate(point.xOnly()))
            .call((t: Target) => axis(t))
            .call(styleAxes(style, true));
    }

    if (p.fromTest === 'ACOLAPT') {
        addArrow(p, AcodaptTL, AcodaptTL);
    } else {
        addArrow(p, TL, TL);
    }
}
interface XY {
    x: number;
    y: number;
}
interface Corner {
    point: (a: Area) => XY;
    arrowrotate: number;
    textx: number;
    texty: number;
    textanchor: string;
    fontWeight?: number;
}
const TL = {
    point: (x: Area) => x.tl,
    arrowrotate: -90,
    textx: 7,
    texty: -5,
    textanchor: 'start',
};

const AcodaptTL = {
    point: (x: Area) => x.tl,
    arrowrotate: -90,
    textx: 0,
    texty: -77,
    textanchor: 'middle',
    fontWeight: 900,
};

const TR = {
    point: (x: Area) => x.tr,
    arrowrotate: -90,
    textx: -7,
    texty: -5,
    textanchor: 'end',
};
const BL = {
    point: (x: Area) => x.bl,
    arrowrotate: -180,
    textx: -7 - 10,
    texty: -5,
    textanchor: 'start',
};
const BR = {
    point: (x: Area) => x.br,
    arrowrotate: 0,
    textx: +7 + 10,
    texty: -5,
    textanchor: 'end',
};

function addArrow(p: Params, corner: Corner, reverseCorner: Corner, scaleType = SCALE_TYPE.VERTICAL_SCALE) {
    let arrows = [] as { arrow: Arrow; corner: Corner }[];
    if (p.arrow != null) {
        arrows.push({
            arrow: p.arrow,
            corner: corner,
        });
    }
    if (p.arrowReverse != null) {
        arrows.push({
            arrow: p.arrowReverse,
            corner: reverseCorner,
        });
    }

    let gArrow = p.target
        .selectAll('.arrow')
        .data(arrows)
        .join('g')
        .attr('class', 'arrow')
        .attr('transform', (d) => translate(d.corner.point(p.area)));
    // .call(doTranslate(corner.point(p.area)))

    gArrow
        .selectAll('path')
        .data((d) => [d])
        .join('path')
        .attr(
            'd',
            drawArrow({
                x0: 0,
                y0: 0,
                dx: 15,
                tipdx: 5,
            })
        )
        .attr('class', 'sContrast fNone')
        .style('stroke-width', 2.0)
        .style('opacity', 0.6)
        .attr('transform', (d) => `rotate(${d.corner.arrowrotate}) translate(-1, 0.5)`);

    const translateY = scaleType === SCALE_TYPE.VERTICAL_SCALE ? -15 : 60;

    gArrow
        .selectAll('text')
        .data((d) => [d])
        .join('text')
        .text((d) => d.arrow)
        .call(doTranslate({ x: corner.textx, y: corner.texty }))
        .attr('transform', (d) => translate({ x: d.corner.textx, y: translateY }))
        .attr('text-anchor', (d) => d.corner.textanchor)
        .attr('font-size', (d) => d.corner.fontSize)
        .attr('font-weight', (d) => d.corner.fontWeight);
}

export function AxisRight(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

    let point = p.point ?? p.area.cornerBR();

    if (p.moveOut != null) point = point.dx(p.moveOut);

    if (p.noText) {
        p.rawTickFormat = () => '';
    }

    let axis = d3
        .axisRight(p.scale.y)
        .tickSize(style.tickSize)
        .tickPadding(style.tickPadding);

    if (p.ticks !== undefined) axis.ticks(p.ticks);
    // let { tickFormat } = this.props
    // if (p.tickFormat) {
    //   axis.tickFormat((numberLike) => {
    //     let point = p.flatData.find(x => x.point.y === numberLike)
    //     if (!point) return ''
    //     if (!p.tickFormat) return ''
    //     return p.tickFormat(point)
    //   })
    // }

    // console.log(scale.y.ticks())
    // if (this.props.useTickValues) {
    //   axis.tickValues(yValues)
    // }

    if (p.rawTickFormat) {
        let fun = p.rawTickFormat as RawTickFormat; // my devenv typescript is a mess
        axis.tickFormat((x) => fun(x.valueOf(), p.flatData));
    }

    if (p.ownTicks) {
        let yValues: number[] = _.uniq(p.flatData.map((x) => x.point.y));
        let ticks = p.ownTicks(yValues, p.flatData);

        let textMapping = ticks.reduce((a, x) => ({ ...a, [x.value]: x.text }), {});
        axis.tickValues(ticks.map((x) => x.value));
        axis.tickFormat((x) => textMapping[x.valueOf()] ?? '?');
    }
    // if (this.props.ownTicks) {
    //     let yValues: number[] = _.uniq(flatData.map((x) => x.point.y));
    //     let ticks = this.props.ownTicks(yValues, flatData);
    //     let textMapping = ticks.reduce((a, x) => ({ ...a, [x.value]: x.text }), {});
    //     axis.tickValues(ticks.map((x) => x.value));
    //     axis.tickFormat((x) => textMapping[x.valueOf()] ?? '?');
    // }

    let enabled = p.enabled == null ? true : p.enabled;

    p.target
        .selectAll('.axis-right')
        .data(_.compact([enabled ? 1 : null]))
        .join('g')
        .attr('class', 'axis-right')
        .call(doTranslate(point.xOnly()))
        .call((t: Target) => axis(t))
        .call(styleAxes(style));

    if (p.grid) {
        axis.tickFormat('' as any);
        axis.tickSize(-p.area.width() - (p.moveOut || 0));
        p.target
            .selectAll('.right-axis-grid')
            .data([null])
            .join('g')
            .attr('class', 'right-axis-grid')
            .call(doTranslate(point.xOnly()))
            .call((t: Target) => axis(t))
            .call(styleAxes(style, true));
    }

    addArrow(p, TR, TR);
}

export function AxisBottom(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

    let point = p.point ?? p.area.cornerBL();
    if (p.moveOut != null) point = point.dy(p.moveOut);

    if (p.noText) {
        p.rawTickFormat = () => '';
    }

    let axis = d3
        .axisBottom(p.scale.x)
        .tickSize(style.tickSize)
        .tickPadding(style.tickPadding);

    if (p.ticks !== undefined) axis.ticks(p.ticks);
    if (p.rawTickFormat) {
        let fun = p.rawTickFormat as RawTickFormat; // my devenv typescript is a mess
        axis.tickFormat((x) => fun(x.valueOf(), p.flatData));
    }

    if (p.ownTicks) {
        let xValues: number[] = _.uniq(p.flatData.map((x) => x.point.x));
        let ticks = p.ownTicks(xValues, p.flatData);

        let textMapping = ticks.reduce((a, x) => ({ ...a, [x.value]: x.text }), {});
        axis.tickValues(ticks.map((x) => x.value));
        axis.tickFormat((x) => textMapping[x.valueOf()] ?? '?');
    }

    let enabled = p.enabled == null ? true : p.enabled;

    p.target
        .selectAll('.bottom-axis')
        .data(_.compact([enabled ? 1 : null]))
        .join('g')
        .attr('class', 'bottom-axis')
        .call(doTranslate(point.yOnly()))
        .call((t: Target) => axis(t))
        .call(styleAxes(style))
        .attr('opacity', style.opacity);

    if (p.grid) {
        axis.tickFormat('' as any);
        axis.tickSize(-p.area.height() - (p.moveOut || 0));
        p.target
            .selectAll('.bottom-axis-grid')
            .data([null])
            .join('g')
            .attr('class', 'bottom-axis-grid')
            .call(doTranslate(point.yOnly()))
            .call((t: Target) => axis(t))
            .call(styleAxes(style, true));
    }

    if (p.noTicks) {
        p.target.selectAll('.bottom-axis .tick line').remove();
    }

    addArrow(p, BR, BL, SCALE_TYPE.HORIZONTAL_SCALE);
}

export function AxisTop(p: Params) {
    let style = getStyle(styleDefault, themeOverrides, p.styleParams || {});

    let point = p.point ?? p.area.cornerTL();
    if (p.moveOut != null) point = point.dy(-p.moveOut);

    if (p.noText) {
        p.rawTickFormat = () => '';
    }

    let axis = d3
        .axisTop(p.scale.x)
        .tickSize(style.tickSize)
        .tickPadding(style.tickPadding);

    if (p.ticks !== undefined) axis.ticks(p.ticks);
    if (p.rawTickFormat) {
        let fun = p.rawTickFormat as RawTickFormat; // my devenv typescript is a mess
        axis.tickFormat((x) => fun(x.valueOf(), p.flatData));
    }

    if (p.ownTicks) {
        let xValues: number[] = _.uniq(p.flatData.map((x) => x.point.x));
        let ticks = p.ownTicks(xValues, p.flatData);

        let textMapping = ticks.reduce((a, x) => ({ ...a, [x.value]: x.text }), {});
        axis.tickValues(ticks.map((x) => x.value));
        axis.tickFormat((x) => textMapping[x.valueOf()] ?? '?');
    }

    let enabled = p.enabled == null ? true : p.enabled;

    p.target
        .selectAll('.top-axis')
        .data(_.compact([enabled ? 1 : null]))
        .join('g')
        .attr('class', 'top-axis')
        .call(doTranslate(point.yOnly()))
        .call((t: Target) => axis(t))
        .call(styleAxes(style));

    if (p.grid) {
        axis.tickFormat('' as any);
        axis.tickSize(-p.area.height() - (p.moveOut || 0));
        p.target
            .selectAll('.top-axis-grid')
            .data([null])
            .join('g')
            .attr('class', 'top-axis-grid')
            .call(doTranslate(point.yOnly()))
            .call((t: Target) => axis(t))
            .call(styleAxes(style, true));
    }

    if (p.noTicks) {
        p.target.selectAll('.top-axis .tick line').remove();
    }

    addArrow(p, TR, TL);
}

function styleAxes(style: Style, grid: boolean = false) {
    let opacity = grid ? style.gridOpacity : 1;

    return (x: Target) => {
        if (grid) {
            x.attr('pointer-events', 'none');
        }
        // x.call(noPaths)
        x.selectAll('text')
            .style('font-family', style.fontFamily)
            .style('font-size', style.fontSize)
            .style('fill', style.fontColor);
        x.selectAll('line')
            .style('opacity', opacity)
            .style('stroke-width', style.tickWidth)
            .style('stroke', style.lineColor);
        x.selectAll('path')
            .style('opacity', opacity)
            .style('stroke-width', style.tickWidth)
            .style('stroke', style.lineColor);
    };
}
