import * as jsPDF from 'jspdf';
import { IpdNidekTonorefIIIMeasuredData } from '../../../../../../../common/models/measuredData/NIDEK/TonorefIII/ipdNidekTonorefIIIMeasuredData.model';
import { Pager } from '../../../../_services/examination/export.service';
import { FileService } from '../../../../_services/general/file.service';
import { RegisterEditFieldFrontend } from '../../../registerEditFieldFrontend.class';
import { ITableReportTestFormat } from '../../../tableReportTestMode';
import { IpdNidekTonorefIIITestRemarksFrontend } from '../../../test-remarks/NIDEK/TonorefIII/ipdNidekTonorefIIITestRemarksFrontend.class';
import { MeasuredDataFrontend } from '../../measuredDataFrontend';

export class IpdNidekTonorefIIIMeasuredDataFrontend extends IpdNidekTonorefIIIMeasuredData implements MeasuredDataFrontend {
    public remarks: IpdNidekTonorefIIITestRemarksFrontend;
    public IPD: {
        far: RegisterEditFieldFrontend<string>;
        near: RegisterEditFieldFrontend<string>;
    };
    constructor() {
        super();
        this.IPD = {
            far: new RegisterEditFieldFrontend(),
            near: new RegisterEditFieldFrontend(),
        };
    }
    public async toPDF(document: jsPDF, pager: Pager, fileService?: FileService): Promise<void> {
        let head = [],
            table = [];
        head.push({
            distance: { colSpan: 2, content: 'Inter Pupillary Distance (mm)', styles: { halign: 'center' } },
            value: '',
        });
        table.push({
            distance: 'Far Pupillary Distance',
            value: this.IPD.far.value,
        });
        table.push({
            distance: 'Near Pupillary Distance',
            value: this.IPD.near.value,
        });
        (document as any).autoTable({
            head: head,
            body: table,
            startY: pager.line,
            pageBreak: 'auto',
            theme: 'grid',
            margin: { top: pager.margin.top, right: pager.margin.right, bottom: pager.margin.bottom, left: pager.margin.left },
            didDrawCell: (data: any) => {},
            didDrawPage: () => {},
        });
        pager.line = (document as any).lastAutoTable.finalY;
    }
    public async getTableReport(fileService: FileService): Promise<ITableReportTestFormat> {
        await this.downloadMediaData(fileService);
        const formattedTest: ITableReportTestFormat = {
            haplotest: null,
            OD: {
                values: '',
                images: [],
            },
            OS: {
                values: '',
                images: [],
            },
            OU: {
                values: '',
                images: [],
            },
        };
        formattedTest.OU.values = (this.IPD.far.value || 'x') + '/' + (this.IPD.near.value || 'x');
        return formattedTest;
    }
    public async downloadMediaData(fileService: FileService): Promise<void> {
        try {
        } catch (error) {
            console.error(error);
        }
    }
}
