import { ElementRef, HostListener, Injectable, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { TEST_RESULT } from '../../../../../../../commonout/enum/test-result.enum';
import ITest from '../../../../../../../commonout/interfaces/test';
import { OCULUS } from '../../../../../../common/enums/oculus.enum';
import { TestFrontend } from '../../../_models/tests/testFrontend.class';
import { ExaminationService } from '../../../_services/examination/examination.service';
import { AuthenticationService } from '../../../_services/general/auth.service';
import { FileService } from '../../../_services/general/file.service';
import { ModalService } from '../../../_services/general/modal.service';
import { SocketService } from '../../../_services/general/socket.service';

@Injectable()
export abstract class TestRemarksComponent implements OnInit, OnDestroy {
    @Input() selectedImage: string = null;
    public test: TestFrontend;
    protected remarksForm: FormGroup;
    protected enableEventEmission: boolean = true;
    protected subscriptions: Subscription[];
    @ViewChild('diagnosis') protected diagnosis: ElementRef;
    private boundMessageListener: Function;
    protected constructor(
        protected authenticationService: AuthenticationService,
        protected fileService: FileService,
        private examinationService: ExaminationService,
        private modalService: ModalService,
        public socketService: SocketService
    ) {
        this.subscriptions = [];
        this.boundMessageListener = this.messagesHandler.bind(this);
    }

    abstract convertFormToModel(rawFormValue: { dataBlock: any; diagnosis: string; advice: string; laboratoryRequested: string; medicinesPrescribed: string }): void;

    ngOnInit() {
        this.remarksForm.valueChanges
            .pipe(
                filter(() => this.enableEventEmission),
                map(() => this.remarksForm.getRawValue()),
                debounceTime(1000),
                startWith(null),
                pairwise(),
                distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
                switchMap((rawFormValue: any[]) => {
                    this.convertFormToModel(rawFormValue[1]);
                    if (this.test.remarks.diagnosis.isEmpty()) {
                        if (!this.test.remarks.result.isEmpty()) {
                            this.test.remarks.result.value = '';
                            this.test.remarks.result.edits.splice(-1, 1);
                        }
                        if (!this.test.remarks.result_new.OD.isEmpty()) {
                            this.test.remarks.result_new.OD.value = null;
                            this.test.remarks.result_new.OD.edits.splice(-1, 1);
                        }
                        if (!this.test.remarks.result_new.OS.isEmpty()) {
                            this.test.remarks.result_new.OS.value = null;
                            this.test.remarks.result_new.OS.edits.splice(-1, 1);
                        }
                    }
                    return this.examinationService.updateTest(this.test);
                })
            )
            .subscribe(test => {
                this.test.setModel(test);
                this.customOnInit();
            });
        this.socketService.socket.on('DeviceResultUploaded', this.boundMessageListener);
        this.customOnInit();
    }

    public abstract customOnInit(): Promise<void>;
    public abstract customOnDestroy(): Promise<void>;
    private async messagesHandler(test: ITest) {
        if (test.device === this.test.device && test.type === this.test.type) {
            this.test.setModel(test);
            await this.customOnInit();
        }
    }
    ngOnDestroy() {
        this.socketService.socket?.off('DeviceResultUploaded', this.boundMessageListener);
        this.subscriptions.map(s => s.unsubscribe());
        this.customOnDestroy();
    }

    public async removeModal() {
        return this.modalService.askUser('Are you sure you want to remove the selected result?').then(
            (resp: any) => {
                return resp;
            },
            (error: any) => console.log(error)
        );
    }

    protected sortResArray() {} //DELETE

    protected sort(r1: FormGroup, r2: FormGroup) {
        if (Number(r1.value.date) < Number(r2.value.date)) {
            return -1;
        }
        if (Number(r1.value.date) > Number(r2.value.date)) {
            return 1;
        }
        return 0;
    }

    private async onResultButtonClick(columnName: string, result: TEST_RESULT): Promise<void> {
        if (result === TEST_RESULT.NORMAL || result === TEST_RESULT.CAN_NOT_COMPREHEND) {
            const otherEyeResult: TEST_RESULT = (columnName === 'OD' ? this.test.remarks.result_new.OS.value : this.test.remarks.result_new.OD.value) as TEST_RESULT;
            if ((columnName === 'OU' || otherEyeResult === TEST_RESULT.NORMAL || otherEyeResult === TEST_RESULT.CAN_NOT_COMPREHEND) && !this.test.remarks.diagnosis.isEmpty()) {
                this.test.remarks.diagnosis.registerEdit('', this.authenticationService.currentUser.getModel(true));
            }
            if (columnName === 'OU') {
                this.test.remarks.result.registerEdit(result, this.authenticationService.currentUser.getModel(true));
            } else if (columnName === 'OD') {
                this.test.remarks.result_new.OD.registerEdit(result, this.authenticationService.currentUser.getModel(true));
            } else if (columnName === 'OS') {
                this.test.remarks.result_new.OS.registerEdit(result, this.authenticationService.currentUser.getModel(true));
            }
            await this.examinationService.updateTest(this.test).toPromise();
        } else if (result === TEST_RESULT.ABNORMAL) {
            if (columnName === 'OU') {
                this.test.remarks.result.registerEdit(result, this.authenticationService.currentUser.getModel(true));
            } else if (columnName === 'OD') {
                this.test.remarks.result_new.OD.registerEdit(result, this.authenticationService.currentUser.getModel(true));
            } else if (columnName === 'OS') {
                this.test.remarks.result_new.OS.registerEdit(result, this.authenticationService.currentUser.getModel(true));
            }
            this.diagnosis.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            this.diagnosis.nativeElement.focus();
        }
    }

    private async onDeletedImage(imageID: string): Promise<void> {
        const answer = await this.removeModal();
        if (answer) {
            const testModel: ITest = await this.fileService
                .deleteFile({
                    device: this.test.device,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    imageID: imageID,
                })
                .toPromise();
            this.test.setModel(testModel);
            this.customOnInit();
            this.remarksForm.markAsDirty();
        }
    }

    private async onDeletedMeasurement(i: number) {
        const answer = await this.removeModal();
        if (answer) {
            const testModel: ITest = await this.fileService
                .deleteFile({
                    device: this.test.device,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    sourceFileName: this.test.remarks.measurements[i].sourceFileName,
                })
                .toPromise();
            this.test.setModel(testModel);
            this.remarksForm.markAsDirty();
            this.customOnInit();
        }
    }

    private async onDeletedScan(oculus: OCULUS, index: number): Promise<void> {
        const answer = await this.removeModal();
        if (answer) {
            const testModel: ITest = await this.fileService
                .deleteFile({
                    device: this.test.device,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    target: 'scan',
                    targetCriteria: (this.test.remarks.measurements[0].getModel() as any).maculaScan[oculus][index].createdAt,
                })
                .toPromise();
            this.test.setModel(testModel);
            this.customOnInit();
        }
    }

    private async onDeletedTargetImage(imageID: string): Promise<void> {
        const answer = await this.removeModal();
        if (answer) {
            const testModel: ITest = await this.fileService
                .deleteFile({
                    device: this.test.device,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    target: 'report',
                    targetCriteria: imageID,
                })
                .toPromise();
            this.test.setModel(testModel);
            this.customOnInit();
        }
    }

    private async deleteChart(haploMeasurementID: string): Promise<void> {
        const answer: boolean = await this.modalService.askUser('Are you sure you want to remove the diagram?');
        if (answer) {
            const testModel: ITest = await this.fileService
                .deleteFile({
                    device: this.test.device,
                    examinationID: this.test.examination._id,
                    testType: this.test.type,
                    haploMeasurementID: haploMeasurementID,
                })
                .toPromise();
            this.test.setModel(testModel);
            this.remarksForm.markAsDirty();
            this.customOnInit();
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onKeydoonEscapeHandlerwnHandler() {
        if (this.selectedImage) {
            this.selectedImage = null;
        }
    }
}
